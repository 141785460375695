<template>
	<div class="app-container">
		<el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px">
			<el-form-item label="菜单名称" prop="title">
				<el-input v-model="queryParams.title" placeholder="请输入显示标题" clearable size="small" @keyup.enter.native="handleQuery" />
			</el-form-item>
			<el-form-item>
				<el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
				<el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
			</el-form-item>
		</el-form>

		<el-row :gutter="10" class="mb8">
			<el-col :span="1.5">
				<el-button type="primary" plain icon="el-icon-plus" size="mini" @click="handleAdd" v-permission="['/system/menu/add']">新增 </el-button>
			</el-col>
			<right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>
		</el-row>

		<el-table v-loading="loading" :data="menuList" row-key="id" default-expand-all :tree-props="{ children: 'children', hasChildren: 'hasChildren' }" style="width:100%" stripe border>
      <el-table-column label="显示标题" show-overflow-tooltip width="180" sortable>
        <template #default="scope">
<!--          <i :class="scope.row.icon"></i>-->
          <template v-if="scope.row.icon">
            <svg-icon :icon-class="scope.row.icon" />
          </template>
          <span class="ml10">{{ $t(scope.row.title) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="组件名称" align="center" prop="component" width="180" sortable/>
<!--      <el-table-column prop="icon" label="图标" align="center" width="100">-->
<!--        <template slot-scope="scope">-->
<!--          <svg-icon :icon-class="scope.row.icon" />-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column label="后端url" align="center" prop="menuUrl" width="180" sortable />
			<el-table-column label="前端路径" align="center" prop="menuPath" width="180" sortable/>
			<el-table-column label="排序" align="center" width="120" prop="sort" />
			<el-table-column label="隐藏" align="center" prop="hidden">
        <template slot-scope="scope">
          <el-switch disabled
              v-model="scope.row.hidden"
              active-color="#13ce66"
              inactive-color="#ff4949">
          </el-switch>
        </template>
      </el-table-column>
			<el-table-column label="操作" align="center" width="120" class-name="small-padding fixed-width">
				<template slot-scope="scope">
					<el-button size="mini" type="text" icon="el-icon-edit" @click="handleUpdate(scope.row)" v-permission="['/system/menu/edit']">修改 </el-button>
					<el-button v-if="scope.row.menuName !== 'system_menu' && scope.row.menuName !== 'system_management'"
                     size="mini" type="text" icon="el-icon-delete" @click="handleDelete(scope.row)" v-permission="['/system/menu/remove']">删除 </el-button>
				</template>
			</el-table-column>
		</el-table>

    <!-- 添加或修改菜单管理对话框 -->
    <el-dialog :title="title" :visible.sync="open" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="90px">
        <el-row :gutter="35">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">
            <el-form-item label="上级菜单" prop="parentid">
              <treeselect v-model="form.parentId" :options="menuOptions"
                          :normalizer="normalizer" placeholder="请选择上级菜单"/>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
            <el-form-item label="菜单标题" prop="title">
              <el-input v-model="form.title" placeholder="请输入菜单标题"/>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
            <el-form-item label="菜单名称" prop="menuname">
              <el-input v-model="form.menuName" placeholder="请输入菜单名称"/>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
            <el-form-item label="后端url" prop="menuurl">
              <el-input v-model="form.menuUrl" placeholder="请输入后端menu服务匹配url"/>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
            <el-form-item label="前端path" prop="menupath">
              <el-input v-model="form.menuPath" placeholder="请输入前端path"/>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
            <el-form-item label="组件名称" prop="component">
              <el-input v-model="form.component" placeholder="请输入组件名称"/>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
            <el-form-item label="排序" prop="sort">
              <el-input v-model="form.sort" placeholder="请输入排序"/>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
            <el-form-item label="隐藏">
              <el-switch
                  v-model="form.hidden"
                  active-color="#13ce66"
                  inactive-color="#ff4949">
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0"
                  v-if="!form.hidden">
            <el-form-item label="图标">
              <el-popover
                  placement="bottom-start"
                  width="460"
                  trigger="click"
                  @show="$refs['iconSelect'].reset()"
              >
                <IconSelect ref="iconSelect" @selected="selected" />
                <el-input slot="reference" v-model="form.icon" placeholder="点击选择图标" readonly>
                  <svg-icon
                      v-if="form.icon"
                      slot="prefix"
                      :icon-class="form.icon"
                      class="el-input__icon"
                      style="height: 32px;width: 16px;"
                  />
                  <i v-else slot="prefix" class="el-icon-search el-input__icon" />
                </el-input>
              </el-popover>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
            <el-form-item label="重定向">
              <el-input v-model="form.redirect" placeholder="重定向url"/>
            </el-form-item>
          </el-col>
<!--          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">-->
<!--            <el-form-item label="标签页显示">-->
<!--              <el-switch-->
<!--                  v-model="form.affix"-->
<!--                  active-color="#13ce66"-->
<!--                  inactive-color="#ff4949">-->
<!--              </el-switch>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">-->
<!--            <el-form-item label="显示面包屑">-->
<!--              <el-switch-->
<!--                  v-model="form.breadcrumb"-->
<!--                  active-color="#13ce66"-->
<!--                  inactive-color="#ff4949">-->
<!--              </el-switch>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">-->
<!--            <el-form-item label="左侧菜单高亮" prop="activemenu">-->
<!--              <el-input v-model="form.activeMenu" placeholder="请输入左侧菜单高亮"/>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
            <el-form-item label="启用">
              <el-switch
                  v-model="form.menuEnabled"
                  active-color="#13ce66"
                  inactive-color="#ff4949">
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
            <el-form-item label="外链">
              <el-switch
                  v-model="form.isFrame"
                  active-color="#13ce66"
                  inactive-color="#ff4949">
              </el-switch>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  listMenu,
  getMenu,
  delMenu,
  addMenu,
  updateMenu,
  exportMenu } from "@/api/sys/menu";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import RightToolbar from "@/components/RightToolbar"
import permission from '@/directive/permission/index.js' // 权限判断指令
import IconSelect from "@/components/IconSelect";

// 表查询配置
const dbOrderBy = 'sort asc'
// 分页配置
const pageSize = 500
const pageCurrent = 1

export default {
  name: "Menu",
  components: {
    Treeselect,
    RightToolbar,
    IconSelect,
  },
  directives: { permission },
  data() {
    return {
      // 遮罩层
      loading: true,
      // 显示搜索条件
      showSearch: true,
      // 菜单管理表格数据
      menuList: [],
      // 菜单管理树选项
      menuOptions: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 查询参数
      where: {
        query: '',
        filters: []
      },
      orderby: dbOrderBy,
      options: {
        pageSize,
        pageCurrent
      },
      // 查询参数
      queryParams: {
        sort: null,
        component: null,
        hidden: null,
        redirect: null,
        title: null,
        icon: null,
        affix: null,
        breadcrumb: null,
      },
      // 表单参数
      form: {
      }
      ,
      // 表单校验
      rules: {
        parentId: [
          {
            required: true,
            message: "父类id不能为空", trigger: "blur" }
        ],
        component: [
          {
            required: true,
            message: "组件名称不能为空", trigger: "blur" }
        ],
        sort: [
          {
            required: true,
            message: "排序不能为空", trigger: "blur" }
        ],
        hidden: [
          {
            required: true,
            message: "是否隐藏不能为空", trigger: "blur" }
        ],
        title: [
          {
            required: true,
            message: "左侧导航栏和面包屑显示名称不能为空", trigger: "blur" }
        ],
        isframe: [
          {
            required: true,
            message: "是否为外链不能为空", trigger: "blur" }
        ]
      }
    }
        ;
  },
  created() {
    this._filter = {}
    this.getList();
  },
  methods: {
    // 选择图标
    selected(name) {
      this.form.icon = name;
    },
    /** 查询菜单管理列表 */
    getList() {
      this.loading = true;
      if(this.queryParams.title) {
        this._filter['title'] = {
          type: 'search',
          value: this.queryParams.title,
        }
      }else{
        this._filter = {};
      }
      const filters = this.parseFilters(this._filter)
      if (filters && filters.length > 0) {
        this.where.filters = filters
      } else {
        // 如果过滤条件为空，设置空数组
        this.where.filters = []
      }
      const params = {
        pageNum: this.options.pageCurrent,
        pageSize: this.options.pageSize,
        where: this.where,
        order: this.orderby
      }
      listMenu(params).then(response => {
        this.menuList = this.handleTree(response.data.records, "id", "parentId");
        console.log(this.menuList)
        this.loading = false;
      })
      ;
    },
    /** 转换菜单管理数据结构 */
    normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.id,
        label: node.title,
        children: node.children
      };
    },
    /** 查询部门下拉树结构 */
    getTreeselect() {
      const params = {
        pageNum: 1,
        pageSize: 10000,
        where: null,
        order: this.orderby
      }

      listMenu(params).then(response => {
        this.menuOptions = [];
        const data = { id : 0,
                title:'顶级菜单',
                children :  []
              };
      data.children = this.handleTree(response.data.records, "id", "parentId");
      this.menuOptions.push(data);
      });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {};
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.getTreeselect();
      this.open = true;
      this.title = "添加菜单管理";
      this.form.component = 'layout/routerView/parent';
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      this.getTreeselect();
      // if (row != null) {
      //   this.form.parentid = row.id;
      // }
      getMenu(row.id).then(response => {
        this.form = response.data;
        console.log(this.form);
        this.open = true;
        this.title = "修改菜单管理";
      });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate(valid => {
        if(valid) {
          if (this.form.id != null) {
            updateMenu(this.form).then(response => {
              this.msgSuccess("修改成功");
              this.open = false;
              this.getList();
            });
          } else {
            addMenu(this.form).then(response => {
              this.msgSuccess("新增成功");
              this.open = false;
              this.getList();
            });
          }
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm('是否确认删除菜单"' + row.title + '"的数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return delMenu([row.id]);
      }).then(()=> {
        this.getList();
        this.msgSuccess("删除成功");
      })
    }
  }
};
</script>
