<template>
	<el-container class="layout-container flex-center">
<!--		<Header v-if="!isUniapp"/>-->
    <Header/>
		<el-container class="layout-mian-height-50">
			<Aside />
			<div class="flex-center layout-backtop">
				<TagsView v-if="isTagsview" />
				<Main />
			</div>
		</el-container>
		<el-backtop target=".layout-backtop .el-main .el-scrollbar__wrap"></el-backtop>
	</el-container>
</template>

<script>
import Aside from '@/views/layout/component/aside.vue';
import Header from '@/views/layout/component/header.vue';
import Main from '@/views/layout/component/main.vue';
import TagsView from '@/views/layout/navBars/tagsView/tagsView.vue';
import { Local } from '@/utils/storage.js';
export default {
	name: 'layoutClassic',
	components: { Aside, Header, Main, TagsView },
	computed: {
		// 获取布局配置信息
		getThemeConfig() {
			return this.$store.state.themeConfig.themeConfig;
		},
    isTagsview(){
      let { isTagsview } = this.$store.state.themeConfig.themeConfig;
      // console.log("isTagsview")
      // console.log(Local.get("isUniapp"));
      // if(Local.get("isUniapp")) isTagsview = false
      return isTagsview
    },
    // isUniapp(){
    //   return Local.get("isUniapp")? true: false
    // }
	},
};
</script>
