<template>
  <div class="app-container">
    <SalaryList :listType="listType"></SalaryList>
  </div>
</template>

<script>
import SalaryList from "@/views/pages/cb/components/salaryList"

export default {
  name: "CbSalary",
  components: {
    SalaryList,
  },
  data() {
    return {
      listType: "approve"
    };
  },
  created() {
  },
  methods:{

  },
};
</script>

<style scoped>

</style>
