<template>
	<div>
		<!-- 选定职位的应聘人员 -->
		<el-table v-loading="positionApplicantsLoading" :data="positionApplicants" style="width: 100%" stripe border>
			<!-- <el-table-column type="selection" width="55" align="center" /> -->
			<el-table-column type="expand">
				<template slot-scope="props">
					<el-form label-position="left" inline class="demo-table-expand">
						<el-row>
							<el-col v-if="props.row.gender">性别：{{ props.row.gender }}</el-col>
							<el-col v-if="props.row.birthday">出生年月：{{ props.row.birthday }}</el-col>
							<el-col v-if="props.row.workYears">工作年限：{{ props.row.workYears }} 年</el-col>
							<el-col v-if="props.row.marriageStatus">婚姻状态：{{ props.row.marriageStatus }}</el-col>
							<el-col v-if="props.row.politicalStatus">政治面貌{{ props.row.politicalStatus }}</el-col>
							<el-col v-if="props.row.skills">工作技能：{{ props.row.skills }}</el-col>
							<el-col v-if="props.row.livingAddr">住址：{{ props.row.livingAddr }}</el-col>
							<el-col v-if="props.row.phoneNumber">电话号码：{{ props.row.phoneNumber }}</el-col>
							<el-col v-if="props.row.hobbies">爱好：{{ props.row.hobbies }}</el-col>
						</el-row>

						<div v-if="props.row.educationExp != null">
							<el-row style="font-size: 15px; font-weight: bold; color: red">教育经历</el-row>
							<el-row v-for="(edu, index) in JSON.parse(props.row.educationExp)" :key="index">
								<el-col>
									<span v-if="edu.endDate" class="splitter"> {{ edu.startDate }}至{{ edu.endDate }} </span>
									<span v-else class="splitter"> {{ edu.startDate }}至{{ edu.endDate }} </span>
									<span class="splitter"> {{ edu.school }}</span>
									<span class="splitter">{{ edu.major }}</span>
									<span class="splitter">{{ edu.academic }}</span>
									<span> {{ edu.degree }}</span>
								</el-col>
							</el-row>
						</div>
						<div v-if="props.row.workExp != null">
							<el-row style="font-size: 15px; font-weight: bold; color: blue">工作经历</el-row>
							<el-row v-for="(work, index) in JSON.parse(props.row.workExp)" :key="index">
								<el-col>
									<span v-if="work.endDate" class="splitter"> {{ work.startDate }}至{{ work.endDate }} </span>
									<span v-else class="splitter"> {{ work.startDate }} </span>
									<span class="splitter"> {{ work.workUnit }}</span>
									<span class="splitter">{{ work.department }}</span>
									<span class="splitter">{{ work.workPosition }}</span>
									<span> {{ work.workContent }}</span>
								</el-col>
							</el-row>
						</div>
					</el-form>
				</template>
			</el-table-column>
			<el-table-column label="姓名" align="center" prop="name" sortable width="150px" :show-overflow-tooltip="true"> </el-table-column>
			<el-table-column label="申请职位" align="center" prop="positionName" sortable width="150px" :show-overflow-tooltip="true" />
			<el-table-column label="状态" align="center" prop="status" width="80px" sortable />
			<el-table-column label="手机号码" align="center" prop="phoneNumber" width="120px" sortable />
			<el-table-column label="年龄" align="center" prop="birthday" sortable>
				<template slot-scope="scope">
					<span>{{ GetYears(scope.row.birthday) }}</span>
				</template>
			</el-table-column>
			<el-table-column label="工作年限" align="center" prop="workYears" width="100px" sortable> </el-table-column>
			<el-table-column label="性别" align="center" prop="gender" width="80px" sortable />
			<el-table-column label="学历" align="center" prop="highestDegree" sortable />
			<el-table-column label="投递时间" align="center" prop="appliedDate" width="190px" sortable> </el-table-column>
			<el-table-column label="操作" align="center" min-width="150" fixed="right">
				<template slot-scope="scope">
					<el-button
						size="mini"
						type="text"
						:loading="scope.row.loading"
						:disabled="scope.row.status != '申请'"
						v-permission="['/recruit/positionApplicants/notify']"
						icon="el-icon-wind-power"
						@click="NotifyInterview(scope.row)"
						>通知面试</el-button
					>
					<el-button
						size="mini"
						type="text"
						:disabled="!(scope.row.status == '面试邀请' || scope.row.status == '面试中')"
						@click="doInterview(scope.row)"
						v-permission="['/recruit/positionApplicants/interview']"
						>面试</el-button
					>
					<el-button
						size="mini"
						type="text"
						:disabled="scope.row.status != '面试通过'"
						@click="openHiringDialog(scope.row)"
						v-permission="['/recruit/positionApplicants/hiring']"
						>录用</el-button
					>
					<el-button
						size="mini"
						type="text"
						:disabled="scope.row.status != '录用'"
						@click="openEmployeeConversion(scope.row)"
						v-permission="['/recruit/positionApplicants/conversion']"
						>转为员工</el-button
					>
				</template>
			</el-table-column>
		</el-table>
		<el-dialog title="在线面试" :visible.sync="showInterviewDialog" width="60%" modal-append-to-body>
			<el-form :model="interview" :gutter="24" label-width="100px">
				<el-row>
					<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
						<el-form-item label="面试时间" prop="lastDateTime" :rules="[{ required: true, message: '面试时间不为空', trigger: 'blur' }]">
							<el-date-picker
								clearable
								v-model="interview.lastDateTime"
								class="select-datePicker-width"
								type="datetime"
								value-format="yyyy-MM-dd 00:00:00"
								placeholder="选择面试时间"
							>
							</el-date-picker>
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
						<el-form-item label="面试地点" prop="place" :rules="[{ required: true, message: '面试地点不为空。', trigger: 'blur' }]">
							<el-input v-model="interview.place" clearable placeholder="请输入面试地点" />
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
						<el-form-item label="面试官" prop="interviewers" :rules="[{ required: true, message: '面试官不为空', trigger: 'blur' }]">
							<el-input v-model="interview.interviewers" clearable placeholder="请输入面试官，多人空格隔开" />
						</el-form-item>
					</el-col>
					<el-col el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
						<el-form-item label="面试流程" prop="interviewRemarks">
							<el-steps v-if="selectedPositionId" direction="vertical" style="line-height: 0px">
								<el-step v-for="(item, index) in interviewProcess" :key="index" :title="item.stepName" :description="item.stepContent"> </el-step>
							</el-steps>
							<el-tag v-else>没有定义职位面试流程。</el-tag>
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
						<el-form-item label="面试评语" prop="remarks">
							<el-input
								v-model="interview.remarks"
								:autosize="{ minRows: 6, maxRows: 10 }"
								maxlength="1000"
								show-word-limit
								type="textarea"
								clearable
								placeholder="请输入面试评语"
							/>
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
						<el-form-item label="面试结果" prop="result" :rules="[{ required: true, message: '面试结果不能为空', trigger: 'blur' }]">
							<el-select v-model="interview.result" placeholder="请选择">
								<el-option v-for="item in interviewResults" :key="item.value" :label="item.label" :value="item.value"> </el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
						<el-form-item label="下载简历" prop="result" :rules="[{ required: true, message: '面试结果不能为空', trigger: 'blur' }]">
							<el-link :loading = "downloadLoading" @click="downloadResume(selectedRow.resumeURL)">{{selectedRow.resumeOrginalName}}</el-link>
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: right">
						<el-button type="primary" :loading="postInterviewLoading" @click="postInterview()">提交</el-button>
						<el-button type="primary" @click="close()">关闭</el-button>
					</el-col>
				</el-row>
			</el-form>
		</el-dialog>
		<el-dialog title="员工录用" :visible.sync="showHiringDialog" width="60%" modal-append-to-body>
			<el-form :gutter="24" label-width="100px">
				<el-row>
					<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
						<el-form-item label="录用结果" prop="result" :rules="[{ required: true, message: '面试结果不能为空', trigger: 'blur' }]">
							<el-select v-model="hiringResult" placeholder="请选择">
								<el-option v-for="item in hiringResults" :key="item.value" :label="item.label" :value="item.value"> </el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: right">
						<el-button type="primary" :loading="postHiringLoading" @click="submitHiringResult()">提交</el-button>
						<el-button type="primary" @click="closeHiringDialog()">关闭</el-button>
					</el-col>
				</el-row>
			</el-form>
		</el-dialog>

		<el-dialog title="员工转换" :visible.sync="showConversionDialog" width="30%" 	modal-append-to-body>
			<employee-conversion  v-if="showConversionDialog" :positionId="selectedRow.positionId" :resumeId="selectedRow.resumeId" @close="closeEmployeeConversion" ></employee-conversion>
		</el-dialog>
	</div>
</template>

<script>
import { GetPositionApplicants, updateInterview, getPositionInterviewProcess } from '@/api/recruit/positionManagement';
import { download } from '@/api/recruit/register';
import permission from '@/directive/permission/index.js'; // 权限判断指令
import employeeConversion from "./employeeConversion.vue"
export default {
	name: 'PositionApplicants',
	directives: { permission },
	components:{
		employeeConversion
	},
	data() {
		return {
			positionApplicants: [],
			positionApplicantsLoading: false,
			applyingLoading: false,
			showInterviewDialog: false,
			postInterviewLoading: false,
			showHiringDialog: false,
			postHiringLoading: false,
			showConversionDialog:false,

			interview: {
				lastDateTime: '',
				place: '',
				interviewers: '',
				remarks: '',
				result: '',
			},
			selectedPositionId: '',
			selectedRow: {},
			hiringResult: '',
			interviewProcess: {},
			downloadLoading:false,
			interviewResults: [
				{
					value: '面试中',
					label: '面试中',
				},
				{
					value: '面试通过',
					label: '面试通过',
				},
				{
					value: '面试不通过',
					label: '面试不通过',
				},
			],
			hiringResults: [
				{
					value: '录用',
					label: '录用',
				},
				{
					value: '不录用',
					label: '不录用',
				},
			],
		};
	},

	created() {},
	mounted() {
		this.query();
	},

	methods: {
		query() {
			this.positionApplicantsLoading = true;
			GetPositionApplicants(this.$route.params.positionId)
				.then((response) => {
					this.positionApplicants = response.data;
					this.positionApplicantsLoading = false;

					// 为每一行添加loading变量为false，为后面的按妞用它
					this.positionApplicants.map((item) => {
						this.$set(item, 'loading', false);
						return item;
					});
				})
				.catch((err) => {
					this.positionApplicantsLoading = false;
				});
		},
		NotifyInterview(row) {
			let appliedPerson = {};
			appliedPerson.id = row.id;
			appliedPerson.positionId = row.positionId;
			appliedPerson.status = '面试邀请';
			let that = this;
			row.loading = true;
			updateInterview(appliedPerson)
				.then((response) => {
					row.loading = false;
					row.status = '面试邀请';
					that.msgSuccess('通知成功。');
				})
				.catch((err) => {
					row.loading = false;
					//  that.msgError('申请失败，' + err);
				});
		},
		openHiringDialog(row) {
			this.showHiringDialog = true;
			this.selectedRow = row;
		},
		closeHiringDialog() {
			this.showHiringDialog = false;
		},
		submitHiringResult() {
			let appliedPerson = {};
			appliedPerson.id = this.selectedRow.id;
			appliedPerson.positionId = this.selectedRow.positionId;
			appliedPerson.status = this.hiringResult;
			let that = this;
			this.postHiringLoading = true;
			updateInterview(appliedPerson)
				.then((response) => {
					that.postHiringLoading = false;
					this.selectedRow.status = that.hiringResult;
					that.msgSuccess('提交成功。');
				})
				.catch((err) => {
					that.postHiringLoading = false;
					that.msgError('提交失败，' + err);
				});
		},
		doInterview(row) {
			this.showInterviewDialog = true;
			if (row.interviewResults) {
				this.interview = JSON.parse(row.interviewResults);
			} else {
				this.interview = {
					lastDateTime: '',
					place: '',
					interviewers: '',
					remarks: '',
					result: '',
				};
			}

			// get interview process
			this.selectedRow = row;
			this.selectedPositionId = row.positionId;
			if (this.selectedPositionId) {
				getPositionInterviewProcess(this.selectedPositionId)
					.then((response) => {
						this.interviewProcess = response.data;
					})
					.catch((err) => {
						this.msgError('获取面试流程失败，' + err);
					});
			}
		},
		close() {
			this.showInterviewDialog = false;
		},
		postInterview() {
			let appliedPerson = {};
			appliedPerson.positionId = this.selectedRow.positionId;
			appliedPerson.id = this.selectedRow.id;
			appliedPerson.interviewResults = this.interview;
			appliedPerson.status = this.interview.result;

			let that = this;
			this.postInterviewLoading = true;
			updateInterview(appliedPerson)
				.then((response) => {
					this.postInterviewLoading = false;
					this.selectedRow.interviewResults = this.interview;
					this.selectedRow.status = this.interview.result;
					that.msgSuccess('提交成功。');
				})
				.catch((err) => {
					this.postInterviewLoading = false;
					that.msgError('提交失败，' + err);
				});
		},
		GetYears(dateParam) {
			if (dateParam != null && dateParam != '') {
				let currentDate = new Date();
				let dateParam2 = new Date(dateParam);
				let diff = currentDate.getTime() - dateParam2.getTime();
				let days = Math.floor(diff / (24 * 3600 * 1000));
				let years = Math.ceil(days / 365);
				return years;
			} else {
				return '';
			}
		},
		
		downloadResume(filePath) {
			if (filePath != null && filePath.length > 0) {
				this.downloadLoading =true;
				download(filePath)
					.then((res) => {
						this.downloadLoading = false;
						if (res) {
							let resData = res.data;
							var blob = new Blob([resData], { type: 'application/octet-stream' });

							const fileName = this.selectedRow.resumeOrginalName;
							if ('download' in document.createElement('a')) {

								// 非IE下载
								const elink = document.createElement('a');
								elink.download = fileName;
								elink.style.display = 'none';
								elink.href = URL.createObjectURL(blob);
								document.body.appendChild(elink);
								elink.click();
								URL.revokeObjectURL(elink.href); // 释放URL 对象
								document.body.removeChild(elink);
							} else {
								// IE10+下载
								navigator.msSaveBlob(blob, fileName);
							}
						}
					})
					.catch((e) => {
						this.downloadLoading = false;
						this.msgError(`后端下载文件出错 & ${e}`);
					});
			} else {
				this.msgError("没有上传个人简历。");
			}
		},
		openEmployeeConversion(row){
			this.showConversionDialog = true;
			this.selectedRow = row;
		},
		closeEmployeeConversion(){
			this.showConversionDialog = false;
		},
	},
};
</script>

<style lang="less" scoped>
#PointerIcon {
	transform: rotate(90deg); // 旋转45度
}
.splitter {
	margin-right: 15px;
}

/deep/ .is-wait {
	color: palevioletred !important;
}

/deep/ .is-text {
	color: blue !important;
	border-color: blue !important;
}

/deep/ .el-step__line {
	background-color: blue !important;
}
/deep/ .el-link{
	display: initial;
}
</style>