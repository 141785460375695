<template>
  <div class="app-container">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="年假调休假调整" name="first">
        <!-- <h3 align="center">年 假 调 休 假 调 整
          <el-popover
              placement="top"
              title="设置步骤："
              width="268"
              trigger="click">
            <p v-if="companyList.length > 1">选择要调整的公司；</p><br><p>选择要调整的员工（可多选）；</p><br>
            <p>输入批量设置的天数，设置；</p><br><p>保存，则保存所有更改</p><br><p>取消，则保存前的更改恢复到更改前状态</p>
            <el-button type="text" round slot="reference">？</el-button>
          </el-popover>
        </h3> -->
        <el-form :inline="true" label-width="68px">
<!--          <el-form-item label="年假"><el-input v-model="annualDays" style="width: 68px" placeholder="天数"></el-input></el-form-item>-->
          <el-form-item>
            <el-button size="mini" type="primary" @click="setAnnual" class="button-strap-gap">设置年假</el-button>
            <el-button size="mini" type="primary" @click="cleanLastAnnual" class="button-strap-gap">结余年假清零</el-button>
            <el-button size="mini" type="primary" @click="addToLastAnnual" class="button-strap-gap">年假转入结余</el-button>
            <el-button size="mini" type="primary" @click="cleanLieuLeave" class="button-strap-gap">结算调休假</el-button>
            <el-button size="mini" type="primary" @click="saveAnnual" class="button-strap-gap">保存</el-button>
            <el-button size="mini" type="primary" @click="cancelAnnualChange" class="button-strap-gap">取消</el-button>
             <el-popover
              placement="top"
              title="设置步骤："
              width="268"
              trigger="click">
            <p v-if="companyList.length > 1">选择要调整的公司；</p><br><p>选择要调整的员工（可多选）；</p><br>
            <p>输入批量设置的天数，设置；</p><br><p>保存，则保存所有更改</p><br><p>取消，则保存前的更改恢复到更改前状态</p>
            <el-button type="text" round slot="reference">？</el-button>
          </el-popover>
          </el-form-item>
        </el-form>
        <!-- <el-row :gutter="35">
          <el-col :xs="24" :sm="24" :md="18" :lg="16" :xl="12" class="mb20">
          </el-col>
        </el-row> -->
        <el-row v-if="companyList.length > 1" :gutter="35">
          <el-col :xs="24" :sm="24" :md="18" :lg="16" :xl="12" class="mb20">
            选择公司:<treeselect v-model="companyId" :options="companyOptions"
                          :normalizer="normalizerCompany" placeholder="请选择公司" @select="selectCompany"/>
          </el-col>
        </el-row>
        <!-- <el-row :gutter="35">
          <el-col :xs="24" :sm="24" :md="18" :lg="16" :xl="12" class="mb20">
          </el-col>
        </el-row> -->
        <el-row :gutter="35">
          <el-col :xs="24" :sm="24" :md="18" :lg="16" :xl="12" class="mb20">
            <el-input v-model="treeSearchName" placeholder="请输入查找字符" clearable size="small" prefix-icon="el-icon-search" style="margin-bottom: 20px" />
      
            <el-tree show-checkbox class="flow-tree" :data="employTree" :props="defaultProps" node-key="treeId" style="height:100% !important;"
                :expand-on-click-node="false" :filter-node-method="filterNode" ref="tree" default-expand-all highlight-current>
                    <span class="custom-tree-node" slot-scope="{ node, data }">
                      <span style="min-width:100px;">{{ node.label }}</span>
                      <span v-if="data.className=='employ'">
                        年假<el-input @change="employMap[data.id].annualDaysCg=true" v-model="employMap[data.id].annualDays" :class="employMap[data.id].annualDaysCg?'annualInputCg':'annualInput'" style="width: 50px"/>
                        结余<el-input @change="employMap[data.id].lastAnnualCg=true" v-model="employMap[data.id].lastAnnual" :class="employMap[data.id].lastAnnualCg?'annualInputCg':'annualInput'" style="width: 50px"/>
                        <el-popover
                            placement="right"
                            width="378"
                            trigger="click">
                          <el-table :data="LieuList" :loading="lieuLoading">
                            <el-table-column align="right" property="days" label="结算天数"></el-table-column>
                            <el-table-column property="status" label="状态"></el-table-column>
                            <el-table-column property="amount" label="结算金额"></el-table-column>
                            <el-table-column property="createBy" label="提交人"></el-table-column>
                            <el-table-column property="createTime" width="180px" label="提交时间"></el-table-column>
                          </el-table>
<!--                          <el-button slot="reference">click 激活</el-button>-->
                          <el-button slot="reference" type="text" size="mini" @click="showLieuDetail(data.id)">调休假</el-button>
                        </el-popover>
                        <el-input @change="employMap[data.id].otLeaveCg=true" v-model="employMap[data.id].otLeave" :class="employMap[data.id].otLeaveCg?'annualInputCg':'annualInput'" style="width: 50px"/>
                      </span>
                      <span v-if="data.className=='company'">
                        <el-button type="primary" size="mini" @click="expandAll">
                          <template v-if="treeExpandAll">全部折叠</template>
                          <template v-if="!treeExpandAll">全部展开</template>
                        </el-button>
                      </span>
                    </span>
            </el-tree>
            
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="假期及假期调班设置" name="specialDay">
        <el-row :gutter="35">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb20">
            <AttSpecialDay></AttSpecialDay>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="加班调休权重设置" name="second">
        <!-- <h3 align="center">加 班 调 休 权 重 设 置
          <el-popover
              placement="top"
              title="设置步骤："
              width="268"
              trigger="click">
            <p v-if="companyList.length > 1">选择要调整的公司；</p><br><p>选择要调整的员工（可多选）；</p><br>
            <p>输入批量设置的倍数，设置；</p><br><p>保存，则保存所有更改</p><br><p>取消，则保存前的更改恢复到更改前状态</p>
            <br><p>设置加班权重后，加班申请审批通过将自动乘以权重转换成调休假，如果需要折算成工资，可以在“年假调休假调整”中调整</p>
            <br><p>休息日：休息日加班一天可以调休几天，比如周末</p>
            <br><p>节假日：节假日加班一天可以调休几天，比如国庆、春节</p>
            <el-button type="text" round slot="reference">？</el-button>
          </el-popover>
        </h3> -->
        <el-form :inline="true" label-width="68px">
          <el-form-item label="权重倍数"><el-input v-model="otWeight" size="mini" style="width: 88px;margin-bottom:0px !improtant;" placeholder="权重倍数"></el-input></el-form-item>
          <el-form-item>
            <el-button size="mini" type="primary" @click="setRestOt" class="button-strap-gap">设置休息日加班</el-button>
            <el-button size="mini" type="primary" @click="setHolidayOt" class="button-strap-gap">设置节假日加班</el-button>
            <el-button size="mini" type="primary" @click="saveOtWeight" class="button-strap-gap">保存</el-button>
            <el-button size="mini" type="primary" @click="cancelOtWeight" class="button-strap-gap">取消</el-button>
            <el-popover
              placement="top"
              title="设置步骤："
              width="268"
              trigger="click">
            <p v-if="companyList.length > 1">选择要调整的公司；</p><br><p>选择要调整的员工（可多选）；</p><br>
            <p>输入批量设置的倍数，设置；</p><br><p>保存，则保存所有更改</p><br><p>取消，则保存前的更改恢复到更改前状态</p>
            <br><p>设置加班权重后，加班申请审批通过将自动乘以权重转换成调休假，如果需要折算成工资，可以在“年假调休假调整”中调整</p>
            <br><p>休息日：休息日加班一天可以调休几天，比如周末</p>
            <br><p>节假日：节假日加班一天可以调休几天，比如国庆、春节</p>
            <el-button type="text" round slot="reference">？</el-button>
          </el-popover>
          </el-form-item>
        </el-form>
        <!-- <el-row :gutter="35">
          <el-col :xs="24" :sm="24" :md="18" :lg="16" :xl="12" class="mb20">
          </el-col>
        </el-row> -->
        <el-row v-if="companyList.length > 1" :gutter="35">
          <el-col :xs="24" :sm="24" :md="18" :lg="16" :xl="12" class="mb20">
            选择公司:<treeselect v-model="companyIdOt" :options="companyOptions" 
                             :normalizer="normalizerCompany" placeholder="请选择公司" @select="selectCompanyOt"/>
          </el-col>
        </el-row>
        <!-- <el-row :gutter="35">
          <el-col :xs="24" :sm="24" :md="18" :lg="16" :xl="12" class="mb20">
          </el-col>
        </el-row> -->
        <el-row :gutter="35">
          <el-col :xs="24" :sm="24" :md="18" :lg="16" :xl="12" class="mb20">
            <el-input v-model="treeSearchNameOt" placeholder="请输入查找字符" clearable size="small" prefix-icon="el-icon-search" style="margin-bottom: 20px" />
            <el-tree show-checkbox class="flow-tree" :data="employTreeOt" :props="defaultProps" node-key="treeId" style="height:100% !important;"
                     :expand-on-click-node="false" :filter-node-method="filterNode" ref="treeOt" default-expand-all highlight-current>
                    <span class="custom-tree-node" slot-scope="{ node, data }">
                      <span style="min-width:100px;">{{ node.label }}</span>
                      <span v-if="data.className=='employ'">
                        休息日<el-input @change="employMapOt[data.id].otRestWeightCg=true" v-model="employMapOt[data.id].otRestWeight" :class="employMapOt[data.id].otRestWeightCg?'annualInputCg':'annualInput'" style="width: 50px"/>
                        节假日<el-input @change="employMapOt[data.id].otHolidayWeightCg=true" v-model="employMapOt[data.id].otHolidayWeight" :class="employMapOt[data.id].otHolidayWeightCg?'annualInputCg':'annualInput'" style="width: 50px"/>
<!--                        ，调休<div v-if="'按固定金额'==employMapOt[data.id].lieuType">按固定金额</div>-->
<!--                        <div v-else-if="'按固定金额'==employMapOt[data.id].lieuType">按固定金额</div>-->
<!--                        <div v-else>按月工资</div>-->
<!--                        <el-select v-model="employMapOt[data.id].lieuType" style="width: 50px">-->
<!--                          <el-option key="按月工资" label="按月工资" value="按月工资"/>-->
<!--                          <el-option key="按固定金额" label="按月工资" value="按固定金额"/>-->
<!--                        </el-select>-->
<!--                        <el-input v-model="employMapOt[data.id].lieuValue" :class="employMapOt[data.id].lieuValue?'annualInputCg':'annualInput'" style="width: 50px"/>结算-->
                      </span>
                      <span v-if="data.className=='company'">
                        <el-button type="primary" size="mini" @click="expandAllOt">
                          <template v-if="treeExpandAllOt">全部折叠</template>
                          <template v-if="!treeExpandAllOt">全部展开</template>
                        </el-button>
                      </span>
                    </span>
            </el-tree>
          </el-col>
        </el-row>
      </el-tab-pane>
    
    </el-tabs>

<!--    <div slot="footer" class="dialog-footer">-->
<!--      <el-button type="primary" @click="submitForm" >确 定</el-button>-->
<!--      <el-button @click="cancel">关 闭</el-button>-->
<!--    </div>-->

<!--    <el-dialog :title="title" :visible.sync="open" width="80%" append-to-body>-->
<!--      <div>-->
<!--        <el-form ref="form" :model="form" class="employ" label-width="150px">-->
<!--          <el-form-item label="结算天数" prop="days">-->
<!--            <el-input v-model="form.days" style="width: 68px" placeholder="结算天数"></el-input>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="结算方式" prop="lieuType">-->
<!--            <el-switch style="display: block" v-model="form.lieuType" active-color="#13ce66"-->
<!--                inactive-color="#ff4949" active-text="按月工资" inactive-text="按固定金额">-->
<!--            </el-switch>-->
<!--          </el-form-item>-->
<!--          <el-form-item label="结算方式" prop="lieuValue">-->
<!--            <el-input v-model="form.lieuValue" style="width: 68px" placeholder=""></el-input>-->
<!--          </el-form-item>-->
<!--        </el-form>-->
<!--        <div slot="footer" class="dialog-footer" style="text-align: center">-->
<!--          <el-button type="primary" @click="submitForm" v-permission="['/cb/cbSalary/add']">确 定</el-button>-->
<!--          <el-button @click="cancel">取 消</el-button>-->
<!--        </div>-->
<!--      </div>-->

<!--    </el-dialog>-->
  </div>
</template>

<script>
import {listSysSetting,updateSysSetting} from "@/api/sys/sysSetting";
import {listCompany, getTreeselectCompany} from "@/api/org/company";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { showLoading, hideLoading } from '@/utils/reqloading';
import { getOrgEmpTree, normalizerEmployMultiSelectTree} from '@/views/pages/employ/components/empOrgTree';
import AttSpecialDay from '../specialDay'
import {updateEmployList} from "@/api/employ/employ";
import {settle} from "@/api/cb/cbSettleLieu";
import {listCbSettleLieu} from "@/api/cb/cbSettleLieu";

export default {
  name: "index",
  components: {
    Treeselect,
    AttSpecialDay,
  },
  watch:{
    companyId(n,o){
      // this.form.type=this.attType;
    },
    treeSearchName(val) {
      this.$refs.tree.filter(val);
    },
    treeSearchNameOt(val) {
      this.$refs.treeOt.filter(val);
    },
  },
  data() {
    return {
      LieuList:[],
      lieuLoading: false,
      activeName:'first',
      treeExpandAll: true,
      treeExpandAllOt: true,
      otWeight: null,
      annualDays: null,
      lastAnnual: null,
      otLeave: null,
      treeSearchName: null,
      treeSearchNameOt: null,
      companyId: null,
      companyIdOt: null,
      companyOptions: [],
      companyList: [],
      employTree: [],
      employList: [],
      employMap: {},
      employTreeOt: [],
      employListOt: [],
      employMapOt: {},
      defaultProps: {
        children: "children",
        label: "name"
      },

      // 表单参数
      form: {
      },
      // 表单校验
      rules: {
      //   annualDays: [
      //     {
      //       required: true,
      //       message: "年假天数不能为空", trigger: "blur" }
      //   ],
      }
    };
  },
  created() {
    showLoading();
    this.companyId = this.$store.getters.userInfo.companyId;
    this.companyIdOt = this.$store.getters.userInfo.companyId;
    getOrgEmpTree(this.companyId, true,(tree, companyList, departmentList, employList )=>{
      this.employTree = tree;
      this.employList = employList;
      this.copyEmployListToMap();
      this.employTreeOt = tree;
      this.employListOt = employList;
      this.copyEmployListToMapOt();
      hideLoading();
    });
    getTreeselectCompany(null, (list, options) => {
      this.companyList = list;
      this.companyOptions = options;
    });
  },
  methods: {
    expandAll() {
      this.treeExpandAll = !this.treeExpandAll;
      var nodes = this.$refs.tree.store.nodesMap;
      for (var i in nodes) {
        nodes[i].expanded = this.treeExpandAll;
      }
    },
    expandAllOt() {
      this.treeExpandAllOt = !this.treeExpandAllOt;
      var nodes = this.$refs.treeOt.store.nodesMap;
      for (var i in nodes) {
        nodes[i].expanded = this.treeExpandAllOt;
      }
      // this.collapseAllOt(this.employTreeOt);
    },
    // collapseAllOt(data) {
    //   let self = this;
    //   data.forEach((el) => {
    //     self.$refs.treeOt.store.nodesMap[el.id].expanded = false;
    //     el.children && el.children.length > 0
    //         ? self.collapseAllOt(el.children)
    //         : ""; // 子级递归
    //   });
    // },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    setAnnual(){
      let checkedNodes = this.$refs.tree.getCheckedNodes();
      if(!checkedNodes || checkedNodes.length == 0){
        this.$alert('没有选择需要调整的员工，请先选择要调整的员工。', '警告', {
          confirmButtonText: '确定',
        });
        return;
      }
      this.$prompt('请输入年假天数', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^\d+(\.\d+)?$/,
        inputErrorMessage: '年假天数格式不正确'
      }).then(({ value }) => {
        let annualDays = parseFloat(value);
        console.log(annualDays)
        if(annualDays >= 0){
          checkedNodes.forEach( node =>{
            if(node.className == "employ"){
              if(this.employMap[node.id].annualDays != annualDays){
                this.employMap[node.id].annualDays = annualDays;
                this.employMap[node.id].annualDaysCg = true;
              }
            }
          });
        }else{
          this.msgError("年假只能输入大于等于0的数")
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    },
    cleanLastAnnual(){
      let checkedNodes = this.$refs.tree.getCheckedNodes();
      if(!checkedNodes || checkedNodes.length == 0){
        this.$alert('没有选择需要调整的员工，请先选择要调整的员工。', '警告', {
          confirmButtonText: '确定',
        });
        return;
      }
      checkedNodes.forEach( node =>{
        if(node.className == "employ"){
          if(this.employMap[node.id].lastAnnual != 0){
            this.employMap[node.id].lastAnnual = 0;
            this.employMap[node.id].lastAnnualCg = true;
          }
        }
      });
    },
    addToLastAnnual(){
      let checkedNodes = this.$refs.tree.getCheckedNodes();
      if(!checkedNodes || checkedNodes.length == 0){
        this.$alert('没有选择需要调整的员工，请先选择要调整的员工。', '警告', {
          confirmButtonText: '确定',
        });
        return;
      }
      checkedNodes.forEach( node =>{
        if(node.className == "employ"){
          if(this.employMap[node.id].annualDays != 0){
            this.employMap[node.id].lastAnnual = parseFloat(this.employMap[node.id].annualDays)+ parseFloat(this.employMap[node.id].lastAnnual);
            this.employMap[node.id].annualDays = 0;
            this.employMap[node.id].lastAnnualCg = true;
            this.employMap[node.id].annualDaysCg = true;
          }
        }
      });
    },
    cleanLieuLeave(){
      // 先检查是否有更改，有更改需先保存更改才能结算调休假。
      for(let key in this.employMap){
        const v = this.employMap[key];
        if(v.annualDaysCg || v.lastAnnualCg || v.otLeaveCg){
          this.$alert('假期设置有更改，请先保存更改再结算调休假。如需保存，请在此框关闭后点击保存按钮', '警告', {
            confirmButtonText: '确定',
          });
          return;
        }
      }
      let checkedNodes = this.$refs.tree.getCheckedNodes();
      if(!checkedNodes || checkedNodes.length == 0){
        this.$alert('没有选择需要调整的员工，请先选择要调整的员工。', '警告', {
          confirmButtonText: '确定',
        });
        return;
      }

      this.$prompt('请输入结算天数，不够天数则结算剩余所有调休假。比如：某员工调休假剩余3天，结算5天，则此员工只结算剩余的3天调休假。', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^\d+(\.\d+)?$/,
        inputErrorMessage: '结算天数格式不正确'
      }).then(({ value }) => {
        let lieuDays = parseFloat(value);
        console.log(lieuDays)
        if(lieuDays >= 0){
          let empIds = [];
          checkedNodes.forEach( node =>{
            if(node.className == "employ"){
              empIds.push(node.id);
            }
          });
          showLoading();
          let data = {ids: empIds,salaryDate:null,days:lieuDays};
          settle(data).then(response => {
            console.log(response.data);
            if(response.data){
              this.employList = response.data;
              this.copyEmployListToMap();
              hideLoading();
              this.msgSuccess("保存成功")
            }
          });
        }else{
          this.msgError("年假只能输入大于等于0的数")
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    },
    // 显示结算历史记录
    showLieuDetail(id){
      this.lieuLoading = true;
      console.log("empId="+id);
      let filter = {};
      filter['empId'] = {
        type: 'eq',
        value: id,
      }
      const params = this.getQueryParam(filter, 1, 20, '');
      // showLoading();
      listCbSettleLieu(params).then(response => {
        console.log(response.data);
        if(response.data){
          this.LieuList = response.data.records;
        }
        this.lieuLoading = false;
        // hideLoading();
      });
    },
    saveAnnual(){
      console.log(this.employMap);
      let changeList = [];
      for(let key in this.employMap){
        const v = this.employMap[key];
        if(v.annualDaysCg || v.lastAnnualCg || v.otLeaveCg){
          changeList.push(v);
        }
      }
      console.log("changed list=");
      console.log(changeList);
      showLoading();
      updateEmployList(changeList).then(response => {
        console.log(response.data);
        if(response.data){
          this.employList = response.data;
          this.copyEmployListToMap();
          hideLoading();
          this.msgSuccess("保存成功")
        }
      });
    },
    cancelAnnualChange(){
      this.copyEmployListToMap();
    },
    setRestOt(){
      let otWeight = parseFloat(this.otWeight);
      console.log(otWeight)
      if(otWeight >= 0){
        let checkedNodes = this.$refs.treeOt.getCheckedNodes();
        checkedNodes.forEach( node =>{
          if(node.className == "employ"){
            if(this.employMapOt[node.id].otRestWeight != otWeight){
              this.employMapOt[node.id].otRestWeight = otWeight;
              this.employMapOt[node.id].otRestWeightCg = true;
            }
          }
        });
      }else{
        this.msgError("权重倍数只能输入大于等于0的数");
      }
    },
    setHolidayOt(){
      let otWeight = parseFloat(this.otWeight);
      console.log(otWeight)
      if(otWeight >= 0){
        let checkedNodes = this.$refs.treeOt.getCheckedNodes();
        checkedNodes.forEach( node =>{
          if(node.className == "employ"){
            if(this.employMapOt[node.id].otHolidayWeight != otWeight){
              this.employMapOt[node.id].otHolidayWeight = otWeight;
              this.employMapOt[node.id].otHolidayWeightCg = true;
            }
          }
        });
      }else{
        this.msgError("权重倍数只能输入大于等于0的数");
      }
    },
    saveOtWeight(){
      console.log(this.employMapOt);
      let changeList = [];
      for(let key in this.employMapOt){
        const v = this.employMapOt[key];
        if(v.otRestWeightCg || v.otHolidayWeightCg){
          changeList.push(v);
        }
      }
      console.log("changed list=");
      console.log(changeList);
      showLoading();
      updateEmployList(changeList).then(response => {
        console.log(response.data);
        if(response.data){
          this.employListOt = response.data;
          this.copyEmployListToMapOt();
          hideLoading();
          this.msgSuccess("保存成功")
        }
      });
    },
    cancelOtWeight(){
      this.copyEmployListToMapOt();
    },
    copyEmployListToMap(){
      let eMap = {};
      this.employList.forEach( e =>{
        eMap[e.id] = {"id":e.id,"annualDays":e.annualDays, "lastAnnual":e.lastAnnual, "otLeave":e.otLeave, "annualDaysCg":false, "lastAnnualCg":false, "otLeaveCg":false}
      })
      this.employMap = eMap;
    },
    copyEmployListToMapOt(){
      let eMap = {};
      this.employListOt.forEach( e =>{
        eMap[e.id] = {"id":e.id,"otRestWeight":e.otRestWeight, "otHolidayWeight":e.otHolidayWeight, "otRestWeightCg":false, "otHolidayWeightCg":false}
      })
      this.employMapOt = eMap;
    },
    /** 转换公司数据结构 */
    normalizerCompany(node) {
      return this.normalizer(node,'id','companyName');
    },
    selectCompany(node, instanceId) {
      this.companyId = node.id;
      this.changeCompany();
    },
    selectCompanyOt(node, instanceId) {
      this.companyIdOt = node.id;
      this.changeCompanyOt();
    },
    changeCompany(){
      getOrgEmpTree(this.companyId, true,(tree, companyList, departmentList, employList )=>{
        this.employTree = tree;
        this.employList = employList;
        this.copyEmployListToMap();
        console.log('this.employMap');
        console.log(this.employMap);
        hideLoading();
      });
    },
    changeCompanyOt(){
      getOrgEmpTree(this.companyIdOt, true,(tree, companyList, departmentList, employList )=>{
        this.employTreeOt = tree;
        this.employListOt = employList;
        this.copyEmployListToMapOt();
        console.log('this.employMapOt');
        console.log(this.employMapOt);
        hideLoading();
      });
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },

    cancel(){
      this.bus.$emit('onCurrentContextmenuClick', {
        id: 1,
        path: this.$route.path,
      });
    },
  }
}
</script>

<style scoped>
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

.annualInput >>> input{
  /*width: 50px;*/
  height: 26px;
}
.annualInputCg >>> input{
  /*width: 50px;*/
  height: 26px;
  color: #c75151;
  background-color: antiquewhite;
}
.el-form-item{
  margin-bottom: 0px;
}

</style>
