<template>
  <div class="app-container">
        <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px">
          <el-form-item label="流程类型" prop="status">
            <el-select v-model="queryParams.type" clearable placeholder="流程类型"
                       size="small">
              <el-option v-for="item in bpmFlowType" :key="item.value" :label="item.label" :value="item.value">
              </el-option>

<!--              <el-option label="转正申请" value="Official"/>-->
<!--              <el-option label="离职申请" value="Leave"/>-->
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
            <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
          </el-form-item>
        </el-form>

    <el-row :gutter="10" class="mb8">
<!--      <el-col :span="1.5">-->
<!--        <el-button-->
<!--            type="warning"-->
<!--            plain-->
<!--            icon="el-icon-download"-->
<!--            size="mini"-->
<!--            @click="handleExport"-->
<!--            v-permission="['/bpm/official/export']"-->
<!--        >导出-->
<!--        </el-button>-->
<!--      </el-col>-->
      <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>
    </el-row>

    <el-table v-loading="loading" :data="todoList" @selection-change="handleSelectionChange" style="width:100%" stripe border>
      <!--      <el-table-column type="selection" width="55" align="center"/>-->
      <el-table-column label="流程ID" align="center" prop="processInstanceId" show-tooltip-when-overflow/>
<!--      <el-table-column label="待办事项" align="center" prop="processDefinitionId" :formatter="processDef" sortable/>-->
      <el-table-column label="流程名称" align="center" prop="processDefinitionName" width="120" sortable/>
      <el-table-column label="申请人" align="center" prop="startUserId" width="120" sortable/>
      <el-table-column label="业务ID" align="center" prop="businessKey" width="120" sortable/>
      <el-table-column label="申请时间" align="center" prop="startTime" width="180" sortable>
        <template slot-scope="scope">
          {{ convertUTCTimeToLocalTime(scope.row.startTime) }}
        </template>
      </el-table-column>
<!--      <el-table-column label="任务步骤" align="center" prop="name"/>-->
<!--      <el-table-column label="任务状态" align="center" prop="assignee">-->
<!--        <template slot-scope="scope">-->
<!--          <span>{{ scope.row.assignee? "已签收": "未签收" }}</span>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="showFlowDetail(scope.row)"
          >审批进度
          </el-button>
          <el-button
              size="mini"
              type="text"
              icon="el-icon-view"
              @click="showTask(scope.row)"
          >查看
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
        v-show="total>0"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
    />

    <!-- 添加或修改转正申请对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="80%" append-to-body>
      <FormDetail :dialogType="dialogType" :businessType="businessType" :processInstanceId="processInstanceId" @close="close"></FormDetail>
<!--      <template v-if="'Official' == dialogType">-->
<!--        <official-edit :type="businessType" :id="businessKey" :isReadOnly="true" :isShowClose="true" @close="close"></official-edit>-->
<!--      </template>-->
<!--      <template v-if="'Depart' == dialogType">-->
<!--        <depart-edit :type="businessType" :id="businessKey" :isReadOnly="true" :isShowClose="true" @close="close"></depart-edit>-->
<!--      </template>-->
<!--      <template v-if="'Transfer' == dialogType">-->
<!--        <TransferEdit :type="businessType" :id="businessKey" :isReadOnly="true" :isShowClose="true" @close="close"></TransferEdit>-->
<!--      </template>-->
    </el-dialog>
    <el-dialog :title="title" :visible.sync="openFlowDetail" width="80%" append-to-body>
      <FlowDetail :process-instance-id-in="processInstanceId"></FlowDetail>
      <div style="text-align: center"><el-button @click="openFlowDetail=false">关 闭</el-button></div>
    </el-dialog>
  </div>

</template>

<script>
import {myApply} from "@/api/bpm/todoList.js";
import RightToolbar from "@/components/RightToolbar"
import permission from '@/directive/permission/index.js' // 权限判断指令
import Pagination from "@/components/Pagination";
import OfficialEdit from "../official/officialEdit";
import FlowDetail from "../todo/flowDetail";
import FormDetail from "../components/formDetail";
import DepartEdit from "../depart/departEdit";
import TransferEdit from "../transfer/transferEdit";
import { showLoading, hideLoading } from '@/utils/reqloading';
import {DICT_TYPE, getDictDataLabel, getDictDatas} from "@/utils/dict";
// 表查询配置
const dbOrderBy = ''

export default {
  name: "index",
  components: {
    DepartEdit,
    RightToolbar,
    Pagination,
    OfficialEdit,
    TransferEdit,
    FlowDetail,
    FormDetail,
  },
  directives: { permission },
  data() {
    return {
      // 遮罩层
      loading: true,
      // 查询参数
      where: {
        query: '',
        filters: []
      },


      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 待办事项列表
      todoList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      openFlowDetail: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        orderby: dbOrderBy,
        type: null,
      },
      dialogType: 'Official',
      businessKey: null,
      businessType: null,
      processInstanceId: null,
      dictYesNo: getDictDatas(DICT_TYPE.COM_YES_NO),
      bpmFlowType: getDictDatas(DICT_TYPE.BPM_FLOW_TYPE),

    };
  },
  created() {
    this._filter = {};
    this.getList();
  },
  methods: {
    /** 查询转正申请列表 */
    getQuery(){
      if(this.queryParams.type) {
        this.where={query : this.queryParams.type};
      }else{
        this.where={};
      }
      const params = {
        pageNum: this.queryParams.pageNum,
        pageSize: this.queryParams.pageSize,
        where: this.where,
        order: this.orderby
      }

      return params;
    },
    getList() {
      this.loading = true;
      const params = this.getQuery();
      myApply(params).then(response => {
        console.log(response);
        this.todoList = response.data.records;
        this.total = response.data.total;
        this.loading = false;
      });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {};
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.queryParams.type = null;
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },
    processDef(row, column){
      console.log(row);
      const def = row.processDefinitionKey;
      return getDictDataLabel(DICT_TYPE.BPM_FLOW_TYPE,def);
    },
    // 显示任务
    showTask(row){
      console.log(row);
      const def = row.processDefinitionKey;
      this.dialogType = def;
      if("" == getDictDataLabel(DICT_TYPE.BPM_FLOW_TYPE,this.dialogType)){
        this.dialogType = "selfFlow";
      }
      this.businessType = "view";
      // this.businessKey = row.businessKey;
      this.processInstanceId = row.processInstanceId;
      this.open = true;
    },
    showFlowDetail(row){
      console.log(row.processInstanceId);
      this.processInstanceId = row.processInstanceId;
      this.openFlowDetail = true;
    },

    close(){
      this.open = false;
    },
    /** 任务签收操作 */
    handleClaim(row) {
      const id = row.id;
      showLoading();
      claimTask(id).then(() => {
        this.getList();
        hideLoading();
        this.msgSuccess("签收成功");
      });
    },
    /** 任务办理操作 */
    getTaskFormKey(row) {
      const route = {
        name: row.formKey,
        params: row,
      }
      this.$router.replace(route);
      // const taskId = row.id;
      // const data = {
      //   taskId : taskId
      // }
      //
      // getTaskFormKey(data).then(response => {
      //   const resp = response.data;
      //   const path = resp.formKey;
      //   const taskId = resp.id;
      //   const businessKey =  resp.businessKey;
      //   const route = {
      //     path: path,
      //     query: {
      //       businessKey: businessKey,
      //       taskId:taskId,
      //       processInstanceId : resp.processInstanceId
      //     }
      //   }
      //   this.$router.replace(route);
      // });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate(valid => {
            if(valid) {
              // if (this.form.id != null) {
              //   updateOfficial(this.form).then(response => {
              //     this.msgSuccess("修改成功");
              //     this.open = false;
              //     this.getList();
              //   });
              // } else {
              //   addOfficial(this.form).then(response => {
              //     this.msgSuccess("新增成功");
              //     this.open = false;
              //     this.getList();
              //   });
              // }
            }
          }
      );
    },

    /** 导出按钮操作 */
    handleExport() {
      const params = this.getQuery();
      this.$confirm('是否确认导出所有转正申请数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        // return exportOfficial(params);
      }).then(response => {
        this.download(response.msg);
      })
    }
  }
};
</script>

<style scoped>

</style>
