import request from '@/utils/request'

export function myApply(query) {
    return request({
        url: '/bpm/todolist/myApply',
        method: 'post',
        data: query
    })
}

export function myDoneTasks(query) {
    return request({
        url: '/bpm/todolist/myDoneTasks',
        method: 'post',
        data: query
    })
}

export function myTasks(query) {
    return request({
        url: '/bpm/todolist/myTasks',
        method: 'post',
        data: query
    })
}


export function claimTask(taskId) {
    return request({
        url: '/bpm/todolist/claimTask?taskId='+taskId,
        method: 'post',
    })
}

export function unclaimTask(taskId) {
    return request({
        url: '/bpm/todolist/unclaimTask?taskId='+taskId,
        method: 'post',
    })
}

export function taskSteps(processInstanceId) {
    return request({
        url: '/bpm/todolist/taskSteps?processInstanceId='+processInstanceId,
        method: 'get',
    })
}

export function processRelatedEmploy(processInstanceId) {
    return request({
        url: '/bpm/todolist/processRelatedEmploy?processInstanceId='+processInstanceId,
        method: 'get',
    })
}

export function processHistorySteps(id) {
    return request({
        url: '/bpm/todolist/historySteps?id='+id,
        method: 'get'
    })
}


export function getHighlightImg(id) {
    return request({
        url: '/bpm/todolist/highlightImg?processInstanceId='+id,
        method: 'get'
    })
}
