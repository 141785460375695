<template>
  <div class="app-container" style="padding-left: 0px; padding-right: 0px;">
    <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px">
      <el-form-item label="公司名称" prop="companyId">
        <treeselect v-model="queryParams.companyId" style="width: 198px"
                    :options="companyOptions"
                    :normalizer="normalizerCompany" placeholder="请选择所属公司"/>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>
    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button type="primary" plain icon="el-icon-plus" size="mini"
                   @click="handleAdd" v-permission="['/cb/cbTaxSetting/add']" >新增 </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button type="success" plain icon="el-icon-edit" size="mini" :disabled="single"
                   @click="handleUpdate" v-permission="['/cb/cbTaxSetting/edit']" >修改 </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button type="danger" plain icon="el-icon-delete" size="mini"
            :disabled="multiple" @click="handleDelete" v-permission="['/cb/cbTaxSetting/delete']" >删除
        </el-button>
      </el-col>
      <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>
    </el-row>

    <el-table v-loading="loading" :data="cbTaxSettingList" @selection-change="handleSelectionChange" stripe border>
      <el-table-column type="selection" width="55" align="center"/>
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-table :data="props.row.levelRate" stripe border>
            <el-table-column label="税率（%）" align="center" prop="rate"/>
            <el-table-column label="应纳税所得额不超过（元）" align="center" prop="amount"/>
            <el-table-column label="速算扣除（元）" align="center" prop="speedDeduct"/>
          </el-table>
        </template>
      </el-table-column>
      <el-table-column label="名称" align="center" prop="name"/>
      <el-table-column label="所属公司" align="center" prop="companyId" show-overflow-tooltip width="210" sortable>
        <template slot-scope="scope">
          {{companyFormat(scope.row, null)}}
        </template>
      </el-table-column>
      <el-table-column label="个税起征点" align="center" prop="startTax"/>
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button size="mini" type="text" icon="el-icon-document-copy"
              @click="handleCopy(scope.row)" v-permission="['/cb/cbTemplate/add']">复制
          </el-button>
          <el-button size="mini" type="text" icon="el-icon-edit"
              @click="handleUpdate(scope.row)" v-permission="['/cb/cbTaxSetting/edit']" >修改
          </el-button>
          <el-button v-if="scope.row.id != 1" size="mini" type="text"
              icon="el-icon-delete" @click="handleDelete(scope.row)" v-permission="['/cb/cbTaxSetting/delete']">删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
        v-show="total>0"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
    />

    <!-- 添加或修改税率对话框 -->

    <el-dialog :title="title" :visible.sync="open" append-to-body :width="editDialogSize">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-row :gutter="35">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
            <el-form-item label="名称" prop="name">
              <el-input v-model="form.name" placeholder="请输入名称"/>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
            <el-form-item label="公司" prop="companyId">
              <treeselect :disabled="form.id>0" v-model="form.companyId" :options="companyOptions"
                          :normalizer="normalizerCompany" placeholder="请选择公司"/>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
            <el-form-item label="起征点" prop="startTax">
              <el-input v-model="form.startTax" type="number" placeholder="请输入名称"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10" class="mb8">
          <el-col :span="1.5">
            <el-button
                type="primary"
                plain
                icon="el-icon-plus"
                size="mini"
                @click="handleAddItem"
                v-permission="['/cb/cbTaxSetting/add','/cb/cbTaxSetting/edit']"
            >新增税率
            </el-button>
          </el-col>
        </el-row>
        <el-table :data="form.levelRate" stripe border>
          <el-table-column label="税率（%）" align="center" prop="rate">
            <template slot-scope="scope">
              <el-input v-model="scope.row.rate" type="number" placeholder="税率（%），填入3表示3%"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="应纳税所得额不超过（元）" align="center" prop="amount">
            <template slot-scope="scope">
              <el-input v-model="scope.row.amount" type="number" placeholder="应纳税所得额不超过"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="速算扣除（元）" align="center" prop="companyPercent">
            <template slot-scope="scope">
              <el-input v-model="scope.row.speedDeduct" type="number" placeholder="速算扣除"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
            <template slot-scope="scope">
              <el-button
                  size="mini"
                  type="text"
                  icon="el-icon-delete"
                  @click="handleDeleteItem(scope.row)"
                  v-permission="['/cb/cbTaxSetting/add','/cb/cbTaxSetting/edit']"
              >删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button type="primary" @click="submitForm" v-permission="['/cb/cbTaxSetting/add', '/cb/cbTaxSetting/edit']">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  listCbTaxSetting,
  getCbTaxSetting,
  delCbTaxSetting,
  addCbTaxSetting,
  updateCbTaxSetting,
  exportCbTaxSetting } from "@/api/cb/cbTaxSetting";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import RightToolbar from "@/components/RightToolbar"
import permission from '@/directive/permission/index.js' // 权限判断指令
import Pagination from "@/components/Pagination";
import {listCompany} from "@/api/org/company";
import { showLoading, hideLoading } from '@/utils/reqloading';
import { sortBy } from "@/utils/tools";
import { Local } from '@/utils/storage.js';

// 表查询配置
const dbOrderBy = ''

export default {
  name: "index",
  components: {
    Treeselect,RightToolbar,Pagination,
  },
  directives: { permission },
  data() {
    return {
      // 公司树选项
      companyList: [],
      companyOptions: [],
      row: null,
      // 遮罩层
      loading: true,
      // 查询参数
      where: {
        query: '',
        filters: []
      },
      clockRange: false,

      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 班次管理表格数据
      cbTaxSettingList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        orderby: dbOrderBy,
        name: null,
        type: null,
      },
      // 表单参数
      form: {
        levelRate:[],
        isRest: false,
      },
      editDialogSize:"60%",
      // 表单校验
      rules: {
        companyId: [
          {
            required: true,
            message: "公司不能为空", trigger: "blur" }
        ],
        name: [
          {
            required: true,
            message: "名称不能为空", trigger: "blur" }
        ],
        startTax:[
          {
            required: true,
            message: "个税起征点不能为空", trigger: "blur" }
        ],
      },

    };
  },
  created() {
    this.getTreeselectCompany();
    this._filter = {};
    this.getList();
    let uniapp =Local.get("isUniapp")?true:false;
    if(uniapp)
    {
      this.editDialogSize="90%";
    }
  },
  methods: {
    handleAddItem(){
      if(!this.form.levelRate){
        this.$set(this.form,"levelRate", []);
      }
      this.form.levelRate.push({rate:null,amount:null,speedDeduct:null});
    },
    handleDeleteItem(row){
      let index = this.form.levelRate.indexOf(row);
      if (index > -1) {
        this.form.levelRate.splice(index, 1);
      }
    },
    /** 查询公司下拉树结构 */
    getTreeselectCompany() {
      const params = {
        pageNum: 1,
        pageSize: 500,
        where: null,
        order: ''
      }
      listCompany(params).then(response => {
        this.companyList = response.data.records;
        this.companyOptions = this.handleTree(response.data.records, "id", "parentId");
      }) ;
    },
    /** 查询班次管理列表 */
    getQuery(){
      this._filter = {};
      if(this.queryParams.name) {
        this._filter['name'] = {
          type: 'search',
          value: this.queryParams.name,
        }
      }
      return this.getQueryParam(this._filter,this.queryParams.pageNum,this.queryParams.pageSize,this.orderby)
    },
    getList() {
      this.loading = true;
      const params = this.getQuery();
      listCbTaxSetting(params).then(response => {
        this.cbTaxSettingList = response.data.records;
        this.total = response.data.total;
        this.loading = false;
      })
      ;
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {};
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },
    handleCopy(row) {
      this.reset();
      const id = row.id || this.ids
      getCbTaxSetting(id).then(response => {
        this.form = response.data;
        this.form.id = null;
        this.form.name = this.form.name + "-复制";
        this.form.companyId = this.$store.getters.userInfo.companyId
        this.open = true;
        this.title = "复制税率";
      });
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加税率";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      // if(row.id){
      //   this.row = row;
      // }else{
      //   this.row = {};
      //   this.row.id = this.ids;
      // }
      this.reset();
      const id = row.id || this.ids
      getCbTaxSetting(id).then(response => {
        this.form = response.data;
        this.open = true;
        this.title = "修改税率";
      });

    },

    close(arg){
      this.open = false;
      if(arg.refresh){
        this.getList();
      }
    },

    /** 转换公司数据结构 */
    normalizerCompany(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.id,
        label: node.companyName,
        children: node.children
      };
    },
    companyFormat(row, column) {
      // console.log(row);
      for(var i = 0; i < this.companyList.length; i++){
        const item =  this.companyList[i];
        if(item.id == row.companyId){
          return item.companyName;
        }
      }
      return "";
    },
    getCompanyCode(companyId){
      // console.log(companyId)
      // console.log(this.companyList)
      for(var i = 0; i < this.companyList.length; i++){
        const item =  this.companyList[i];
        if(item.id == companyId){
          return item.code;
        }
      }
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate(valid => {
            if(valid) {
              let levelRate = [];
              let rateErr = null;
              for(let i = 0; i < this.form.levelRate.length; i++){
                let item =  this.form.levelRate[i];
                if(item.rate < 1 || item.rate > 100) {
                  rateErr = "税率必须在1-100之间，请检查税率设置";
                  break;
                }
                if(item.amount < 0) {
                  rateErr = "税率应纳税所得额不能小于0，请检查应纳税所得额设置";
                  break;
                }
                if(item.speedDeduct < 0) {
                  rateErr = "速算扣除不能小于0，请检查速算扣除设置";
                  break;
                }
                levelRate.push({rate:item.rate, amount:item.amount,speedDeduct:item.speedDeduct});
              };
              if(rateErr){
                this.msgError(rateErr);
                return;
              }
              this.form.levelRate = levelRate.sort(sortBy('rate',true));
              this.form.companyCode = this.getCompanyCode(this.form.companyId);
              console.log(this.form);
              showLoading();
              if (this.form.id != null) {
                updateCbTaxSetting(this.form).then(response => {
                  this.msgSuccess("修改成功");
                  hideLoading();
                  this.open = false;
                  this.getList();
                });
              } else {
                addCbTaxSetting(this.form).then(response => {
                  this.msgSuccess("新增成功");
                  hideLoading();
                  this.open = false;
                  this.getList();
                });
              }
            }
          }
      );
    },

    /** 删除按钮操作 */
    handleDelete(row) {
      let ids = [];
      if(row.id){
        ids = [row.id];
      }else{
        ids = this.ids;
      }
      for(let i = 0; i < ids.length; i++){
        if(ids[i] == 1) {
          this.msgError("默认税率不能删除");
          return;
        }
      }
      this.$confirm('是否确认删除选中的班次管理数据?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return delCbTaxSetting(ids);
      }).then(() => {
        this.getList();
        this.msgSuccess("删除成功");
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      const params = this.getQuery();
      this.$confirm('是否确认导出所有班次管理数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return exportCbTaxSetting(params);
      }).then(response => {
        this.download(response.msg);
      })
    }
  }
};
</script>

<style scoped>
.el-form-item{
  margin-bottom: 10px;
}

</style>
