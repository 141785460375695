<template>
  <div>
    <h2 align="center">调 动 调 岗 申 请</h2>
    <el-form ref="form" :model="form" :rules="rules" label-width="120px">
      <el-row v-if="type == 'apply'" :gutter="35">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb20">
          <el-form-item label="调出人员" prop="times">
            <el-radio-group v-model="outFromType">
              <el-radio-button label="search">查找</el-radio-button>
              <el-radio-button label="select">选择</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <template v-if="outFromType=='select'">
      <el-row :gutter="35">
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
          <el-form-item label="调出部门" prop="outDepartmentId">
            <treeselect ref="outDepartment" :disabled="isEmploy || isReadOnly" v-model="form.outDepartmentId" :options="departmentOptions"
                        :normalizer="normalizerDepartment" placeholder="请选择调出部门"/>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
          <el-form-item label="调出岗位" prop="outPositionId">
            <el-select ref="outPosition" :disabled="isEmploy || isReadOnly" clearable v-model="form.outPositionId" placeholder="请选择岗位" style="width:100%">
              <el-option
                  v-for="dict in positionList"
                  :key="dict.id"
                  :label="dict.name"
                  :value="dict.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="35">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb20">
          <el-form-item v-if="isEmploy && type=='apply'" label="调动人员" prop="name">
            <el-input disabled v-model="employ.name" placeholder="请输入内容"/>
          </el-form-item>
          <el-form-item v-else-if="type!='apply'" label="调动人员" prop="name">
            <el-input disabled v-model="form.transferUserIdNames" placeholder="请输入内容"/>
          </el-form-item>
          <el-form-item v-else-if="!isEmploy" label="调动人员" prop="transferUserIds">
            <el-select :disabled="isReadOnly" multiple filterable clearable v-model="transferUserIds" placeholder="请选择调动人员" style="width:100%">
              <el-option
                  v-for="dict in transferEmploy"
                  :key="dict.userId"
                  :label="dict.name"
                  :value="dict.userId"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      </template>
      <template v-if="outFromType=='search'">
        <el-row :gutter="35">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb20">
            <el-form-item v-if="isEmploy" label="调动人员" prop="name">
              <el-input disabled v-model="employ.name" placeholder="请输入内容"/>
            </el-form-item>
            <el-form-item v-if="!isEmploy" label="调动人员" prop="transferUserIds">
              <el-select style="width: 100%" v-model="transferUserIds" filterable remote reserve-keyword placeholder="请输入调动人员姓名" :remote-method="searchTransferEmploy"
                         @change="selectEmploy" :loading="loading">
                <el-option
                    v-for="item in transferEmploy"
                    :key="item.userId"
                    :label="item.name+'('+item.idNum+')'"
                    :value="item.userId">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="35">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
            <el-form-item label="调出部门" prop="outDepartmentId">
              <treeselect ref="outDepartment" disabled v-model="form.outDepartmentId" :options="departmentOptions"
                          :normalizer="normalizerDepartment" placeholder="请选择调出部门"/>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
            <el-form-item label="调出岗位" prop="outPositionId">
              <el-select ref="outPosition" disabled clearable v-model="form.outPositionId" placeholder="请选择岗位" style="width:100%">
                <el-option
                    v-for="dict in positionList"
                    :key="dict.id"
                    :label="dict.name"
                    :value="dict.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </template>
      <el-row :gutter="35">
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
          <el-form-item label="调入部门" prop="inDepartmentId">
            <treeselect :disabled="isReadOnly" v-model="form.inDepartmentId" :options="departmentOptions"
                        :normalizer="normalizerDepartment" placeholder="请选择调出部门"/>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
          <el-form-item label="调入岗位" prop="inPositionId">
            <el-select :disabled="isReadOnly" clearable v-model="form.inPositionId" placeholder="请选择岗位" style="width:100%">
              <el-option
                  v-for="dict in positionList"
                  :key="dict.id"
                  :label="dict.name"
                  :value="dict.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="35">
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
          <el-form-item label="调入时间" prop="transferDate">
            <el-date-picker :disabled="isReadOnly" clearable size="small"
                            v-model="form.transferDate"
                            type="date"
                            value-format="yyyy-MM-dd"
                            placeholder="选择调入新岗位时间">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
          <el-form-item label="临时借调" prop="isTemp">
            <el-select :disabled="isReadOnly" v-model="form.isTemp" clearable placeholder="是否临时借调" size="small">
              <el-option v-for="item in dictYesNo" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col v-if="form.isTemp == 1" :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
          <el-form-item label="调回时间" prop="returnDate">
            <el-date-picker :disabled="isReadOnly" clearable size="small"
                            v-model="form.returnDate"
                            type="date"
                            value-format="yyyy-MM-dd"
                            placeholder="选择调回时间">
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="35">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb20">
          <el-form-item label="调动原因" prop="transferReason">
            <el-input :disabled="isReadOnly" v-model="form.transferReason" type="textarea" placeholder="请输入内容"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="35">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb20">
          <el-form-item label="附件" prop="attachedFiles">
            <template v-if="form.attachedFiles">
              <el-tag v-for="item in form.attachedFiles"
                      :key="item.name"
                      @click="download(item)">
                <el-link type="primary" target="_blank">
                  {{item.name}}
                </el-link>
              </el-tag>
            </template>
            <template v-if="!form.id">
              <el-upload
                  action
                  class="upload-demo"
                  ref="upload"
                  multiple
                  :limit="20"
                  :on-exceed="handleExceed"
                  :http-request="uploadFile"
                  :on-preview="handlePreview"
                  :on-remove="handleRemove"
                  :before-upload="beforeUpload"
                  :on-change="handleChange"
                  :file-list="fileList"
                  :auto-upload="false">
                <el-button slot="trigger" size="small" type="primary">上传文件</el-button>
                <!--                <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">上传到服务器</el-button>-->
                <!--                <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>-->
              </el-upload>
              <!--              <el-input disabled v-model="form.url" placeholder="请选择文件"/>-->
            </template>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="35" v-if="!form.id">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb20">
          <el-form-item label="抄送给" prop="cc" style="display:block !important;">
            <template v-if="!form.id">
              <treeselect :disabled="isReadOnly" v-model="cc" :options="employTree"
                          :multiple="true" :flat="true" :disable-branch-nodes="true" noChildrenText="没有员工"
                          :normalizer="normalizerNode" placeholder="请选择抄送人员"/>
            </template>
            <template v-if="form.id">
              <span>{{form.otherInfo? form.otherInfo.ccName:"无"}}</span>
            </template>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="35">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb20">
          <el-form-item label="相关人员">
            <el-popover
                placement="right"
                width="300"
                trigger="click">
              <Approves :approvesOrder="approvesOrder" :otherInfo="form.otherInfo"></Approves>
              <el-button type="text" slot="reference">相关人员</el-button>
            </el-popover>
          </el-form-item>
        </el-col>
      </el-row>

    </el-form>
    <div slot="footer" class="dialog-footer" style="text-align: center">
      <el-button v-if="taskStep == 'apply'" type="primary" @click="submitForm" v-permission="['/bpm/transfer/apply', '/bpm/transfer/approve']">提 交</el-button>
      <el-button v-if="isShowClose && isReadOnly" @click="cancel">关 闭</el-button>
    </div>

  </div>
</template>

<script>
import permission from '@/directive/permission/index.js'; // 权限判断指令
import {
  listTransfer,
  getTransfer,
  getByProcessInstanceId,
  delTransfer,
  addTransfer,
  updateTransfer,
  exportTransfer } from "@/api/bpm/transfer";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { showLoading, hideLoading } from '@/utils/reqloading';
import {getTreeselectDepartment} from "@/api/org/department";
import { getTreeselectPosition } from '@/api/org/position';
import {DICT_TYPE, getDictDataLabel, getDictDatas} from "@/utils/dict";
import {checkRole} from "@/utils/role";
import {getEmployByUserId,getEmploysByDepPos, listSimpleEmploy} from '@/api/employ/employ';
import {strToIntArray, getEmpNamesStrFromListByIds} from "@/utils/tools";
import {fileDownload} from "@/utils/fileUtils";
import {download} from "@/api/comapi/comapi.js";
import Approves from "../components/approves"
import { getOrgEmpTree, normalizerEmployMultiSelectTree} from '@/views/pages/employ/components/empOrgTree';

export default {
  name: "transferEdit",
  props: {
    type: String,
    row: Object,
    id: String,
    processInstanceId: String,
    departmentOptionsIn: Array,
    positionOptionsIn: Array,
    isReadOnly: Boolean,
    isShowClose: Boolean,
  },
  components: {
    Treeselect,
    Approves,
  },
  directives: { permission },
  watch:{
    row(n,o){ //n为新值,o为旧值;
      //if(n === o) return;
      this.load();
    },
    id(){
      this.load();
    },
    processInstanceId(){
      this.load();
    },
    "form.outDepartmentId": function (){
      this.loadTransferEmploy();
    },
    "form.outPositionId": function (){
      this.loadTransferEmploy();
    },
    outFromType(){
      this.form.outDepartmentId = null;
      this.form.outPositionId = null;
      this.transferEmploy = [];
      this.transferUserIds = [];
    },
  },
  data() {
    return {
      // 遮罩层
      outFromType: 'select',
      loading: true,
      employTree: [],
      cc: null,
      fileList:[],
      formData: null,
      taskStep: 'apply',
      isEmploy: false,  // 是否是员工，员工只能申请自己调动
      employ:{},
      transferEmploy:[],
      transferUserIds:[],
      // 表单参数
      form: {
        outDepartmentId: null,
        outPositionId: null,
      },
      // 表单校验
      rules: {
        outDepartmentId: [
          {
            required: true,
            message: "调出部门不能为空", trigger: "blur" },
        ],
        outPositionId: [
          {
            required: true,
            message: "调出岗位不能为空", trigger: "blur" },
        ],
        inDepartmentId: [
          {
            required: true,
            message: "调入部门不能为空", trigger: "blur" },
        ],
        inPositionId: [
          {
            required: true,
            message: "调入岗位不能为空", trigger: "blur" },
        ],
        transferUserIds: [
          {
            required: true,
            message: "调动人员不能为空", trigger: "blur" },
        ],
        transferDate: [
          {
            required: true,
            message: "调入时间不能为空", trigger: "blur" },
        ],
        isTemp: [
          {
            required: true,
            message: "是否临时借调不能为空", trigger: "blur" },
        ],

      },
      // 部门管理树选项
      departmentList: [],
      departmentOptions: [],
      positionList: [],
      positionOptions: [],
      dictYesNo: getDictDatas(DICT_TYPE.COM_YES_NO),
      approvesOrder: {"outDM":"调出部门领导", "inDM":"调入部门领导", "HRManager":"人事经理"},
    };
  },
  created() {
    this.load();
    if(this.type){
      this.taskStep = this.type;
    }
  },
  methods: {
    loadTransferEmploy(){
      if(this.outFromType == 'select'){
        if(this.type == 'apply'){
          this.transferUserIds = [];
        }
        if(this.form.outDepartmentId && this.form.outPositionId){
          console.log("loadTransferEmploy");
          console.log(this.form.outDepartmentId)
          console.log(this.form.outPositionId)
          getEmploysByDepPos(this.form.outDepartmentId,this.form.outPositionId, (list)=>{
            console.log("loadTransferEmploy call back:");
            console.log(list);
            this.transferEmploy = list;
          });
        }else{
          this.transferEmploy = [];
        }
      }
    },
    searchTransferEmploy(query){
      if (query !== '') {
        this.loading = true;
        let filter = {};
        filter['name'] = {
          type: 'search',
          value: query,
        }
        const params = this.getQueryParam(filter, 1, 20, '');
        listSimpleEmploy(params).then(response=>{
          console.log(response.data.records);
          this.transferEmploy = response.data.records;
          this.loading = false;
        });
      } else {
        this.transferEmploy = [];
      }
    },
    selectEmploy(val){
      console.log(val)
      let employ = null;
      for(let i = 0; i < this.transferEmploy.length; i++){
        if(val == this.transferEmploy[i].userId){
          employ = this.transferEmploy[i];
          break;
        }
      }
      if(null != employ){
        this.form.outDepartmentId = employ.departmentId;
        this.form.outPositionId = employ.positionId;
        // this.$refs["outDepartment"]
        // this.$refs["outPostion"]
      //   this.selfFlow.userNames.push({username:user.username,name:user.name});
      //   this.selfFlow.active = this.selfFlow.userNames.length;
      }
      // console.log(instanceId)
    },

    load() {
      this.reset();
      console.log("load...")
      this.companyId = this.$store.getters.userInfo.companyId;

      if (this.departmentOptionsIn) {
        this.departmentOptions = this.departmentOptionsIn;
      } else {
        this.departmentOptions = [];
        this.getDepartment(this.companyId);
      }
      if (this.positionOptionsIn) {
        this.positionOptions = this.positionOptionsIn;
      } else {
        this.positionOptions = [];
        this.getPositison(this.companyId);
      }

      if(this.type == 'apply'){
        console.log("apply..");
        this.form.name = this.$store.getters.userInfo.employ.name;
        this.form.userId = this.$store.getters.userInfo.id;
        // 人事和经理可以查看所有人申请，员工只能看自己申请
        const role = ["Admin","GM","HRManager","CAdmin","ShiftJob","DM"];
        if(checkRole(role)){
          this.isEmploy = false;
        }else{
          this.isEmploy = true;
          getEmployByUserId(this.$store.getters.userInfo.id, (e)=>{
            if(e){
              this.employ = e;
              this.form.employId = e.id;
              this.form.name = e.name;
              this.form.outDepartmentId = e.departmentId;
              this.form.outPositionId = e.positionId;
              this.transferUserIds = [e.userId];
              console.log("this.form.transferUserIds::");
              console.log(this.transferUserIds);
            }else{
              this.msgError("此账号不是员工，不能申请OA流程");
            }
          });
        }
        if(!this.employTree || this.employTree.length <= 0){
          getOrgEmpTree(this.$store.getters.userInfo.companyId, true,(tree, companyList, departmentList, employList )=>{
            this.employTree = tree;
            this.employList = employList;
            hideLoading();
          });
        }
        this.initForm();
      }else {
        if(this.id) {
          console.log("id=");
          console.log(this.id);
          getTransfer(this.id).then(response => {
            console.log("this.form.transferUserIds:");
            console.log(response.data.transferUserIds);
            this.form = response.data;
            console.log(this.form.transferUserIds);
            const ids = response.data.transferUserIds
            this.transferUserIds = strToIntArray(ids); //ids.split(",");
            console.log(this.transferUserIds);
            this.initForm();
          });
        }else if(this.row){
          console.log("row=");
          console.log(this.row);
          this.form = this.row;
          const ids = this.form.transferUserIds
          this.transferUserIds = strToIntArray(ids); //ids.split(",");
        }else if(this.processInstanceId){
          console.log("processInstanceId="+this.processInstanceId);
          getByProcessInstanceId(this.processInstanceId).then(response => {
            console.log("this.form.transferUserIds:");
            console.log(response.data.transferUserIds);
            this.form = response.data;
            console.log(this.form.transferUserIds);
            const ids = response.data.transferUserIds
            this.transferUserIds = strToIntArray(ids); //ids.split(",");
            console.log(this.transferUserIds);
            this.initForm();
          });
        }else{
          console.log("no arg");
        }
      }
    },
    // 重新加载数据后，初识不能从后台返回的数据
    initForm(){

    },
    selectOutDepartmentId(value){
      console.log("selectOutDepartmentId");
      console.log(value);
      console.log(this.form.outDepartmentId);
    },

    /** 转换部门数据结构 */
    normalizerDepartment(node) {
      return this.normalizer(node);
    },

    /** 查询公司下拉树结构 */
    getDepartment(companyId) {
      getTreeselectDepartment(companyId, false, (list, options) => {
        this.departmentList = list;
        this.departmentOptions = options;
      });
    },
    positionFormat(id) {
      console.log(id);
      for(var i = 0; i < this.positionList.length; i++){
        const item =  this.positionList[i];
        // console.log("id:"+item.id+",depid:"+row.departmentId+", name :"+item.name+",ee="+(item.id == row.departmentId));
        if(item.id == id){
          return item.name;
        }
      }
      return null;
    },
    normalizerPosition(node) {
      return this.normalizer(node);
    },
    /** 查询岗位下拉树结构 */
    getPositison(companyId) {
      getTreeselectPosition(companyId, false, (list, options) => {
        this.positionList = list;
        this.positionOptions = options;
      });
    },
    normalizerNode(node) {
      return normalizerEmployMultiSelectTree(node);
    },

    // 取消按钮
    cancel() {
      this.$emit("close", {refresh:false,isSuccess:true});
    },
    // 表单重置
    reset() {
      this.form = {};
    },
    /** 提交按钮 */
    submitForm() {
      // this.$refs.upload.submit();
      if(this.type == 'apply'){
        let self = this;
        console.log(this.transferUserIds);
        if(this.outFromType == 'select') {
          this.form.transferUserIds = this.transferUserIds.join(",");
          this.form.transferUserIdNames = getEmpNamesStrFromListByIds(this.transferEmploy,this.transferUserIds);
        }else if(this.outFromType == 'search') {
          this.form.transferUserIds = this.transferUserIds;
          this.form.transferUserIdNames = getEmpNamesStrFromListByIds(this.transferEmploy,[this.transferUserIds]);
        }
        this.form.outPositionName =  this.positionFormat(this.form.outPositionId);
        this.form.inPositionName =  this.positionFormat(this.form.inPositionId);
        this.$refs["form"].validate(valid => {
          if (valid) {
            if (this.form.id == null) {
              if(this.cc){
                this.form.cc = this.cc.join(",");
                this.form.ccName = getEmpNamesStrFromListByIds(this.employList, this.cc);
              }
              showLoading();
              // console.log(params);
              // const _file = params.file;
              self.formData = new FormData();
              //uploadFile会逐个加入到formData
              self.$refs.upload.submit();

              // formData.append('files', _file);
              self.formData.append('transfer', JSON.stringify(this.form)); // isOverride自定义的其他参数
              addTransfer(self.formData).then(response => {
                console.log("addTransfer return:")
                console.log(response)
                hideLoading();
                this.form = response.data;
                this.$alert(response.msg, '操作成功', {
                  confirmButtonText: '确定',
                  callback: action => {
                    this.showSubmit = false;
                    this.bus.$emit('onCurrentContextmenuClick', {
                      id: 1,
                      path: this.$route.path,
                    });
                  }
                });
                // this.msgSuccess(response.msg);
              });
            }
          }
        });
      }else{

      }
      // this.form.status = 2;
    },

    handleExceed(files, fileList) {
      this.msgError(`当前限制选择 20 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件.`);
    },

    handleRemove(file, fileList) {
      console.log("handleRemove");
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log("handlePreview");
      console.log(file);
    },

    handleChange(file, fileList) {
      console.log("handleChange");
      //this.currentContract.contractPictures = fileList;
      // 非自动上传，before-upload 不起作用，改到这里验证。
      let isValidFile = this.beforeUpload(file);
      if (!isValidFile) {
        this.$refs.upload.handleRemove(file);
      }
    },
    beforeUpload(file) {
      console.log("beforeUpload");
      var fileType = file.name.substring(file.name.lastIndexOf('.') + 1);
      const jpg = fileType === 'jpg' || fileType === 'JPG';
      const jpeg = fileType === 'jpeg' || fileType === 'JPEG';
      const isLt2M = file.size / 1024 / 1024 < 5;
      const isEmpty = file.size === 0;
      // if (!jpg && !jpeg) {
      //   this.msgError('上传文件只能是png,jpeg格式!');
      // }
      if (!isLt2M) {
        this.msgError('上传文件大小不能超过 5MB!');
      }
      // if (isEmpty) {
      //   this.msgError('上传文件大小不能为空文件!');
      // }
      // return (jpeg || jpg) && isLt2M && !isEmpty;
      return isLt2M;
    },

    async uploadFile(params) {
      console.log("uploadFile");
      console.debug(params);
      this.formData.append('files', params.file);

    },

    download(row) {
      fileDownload(row,download);
    },
  },
}
</script>

<style scoped>

</style>
