import request from '@/utils/request'
import {parseFilters, handleTree} from "@/utils/tools";

// 查询调动调岗历史列表
export function listTransferhis(query) {
    return request({
        url: '/bpm/transferHis/findAll',
        method: 'post',
        data: query
    })
}

// 查询调动调岗历史详细
export function getTransferhis(id) {
    return request({
        url: '/bpm/transferHis/findOne?id=' + id,
        method: 'post'
    })
}

// 新增调动调岗历史
export function addTransferhis(data) {
    return request({
        url: '/bpm/transferHis/add',
        method: 'post',
        data: data
    })
}

// 修改调动调岗历史
export function updateTransferhis(data) {
    return request({
        url: '/bpm/transferHis/edit',
        method: 'post',
        data: data
    })
}

// 删除调动调岗历史
export function delTransferhis(id) {
    return request({
        url: '/bpm/transferHis/remove',
        method: 'post',
        data: id
    })
}

// 导出调动调岗历史
export function exportTransferhis(query) {
    return request({
        url: '/bpm/transferHis/export',
        method: 'post',
        data: query
    })
}

/** 查询部门下拉树结构 */
export function getTransferhisByUserId(userId, callback) {
    let _filter = {};
    if(userId) {
        _filter['transferUserId'] = {
            type: 'eq',
            value: userId,
        }
    }
    let where = {};
    const filters = parseFilters(_filter)
    if (filters && filters.length > 0) {
        where.filters = filters
    } else {
        // 如果过滤条件为空，设置空数组
        where.filters = []
    }
    const params = {
        pageNum: 1,
        pageSize: 1000,
        where: where,
        order: ' transferDate desc '
    }
    console.log(params)
    listTransferhis(params).then(response => {
        const list = response.data.records;
        callback(list);
    });
}
