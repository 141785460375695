<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px">
      <el-form-item label="表名" prop="tableName">
        <el-input
            v-model="queryParams.tableName"
            placeholder="请输入表名"
            clearable
            size="small"
            @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="表记录id" prop="recordId">
        <el-input
            v-model="queryParams.recordId"
            placeholder="请输入表记录id"
            clearable
            size="small"
            @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="操作类型" prop="operation">
        <el-select v-model="queryParams.operation" placeholder="请选择操作类型" clearable size="small">
          <el-option v-for="item in getDictDatas(DICT_TYPE.SYS_EHRTRACE_OP)" :key="item.value" :label="item.label" :value="item.value"/>
        </el-select>
      </el-form-item>
      <el-form-item label="操作用户" prop="opUser">
        <el-input
            v-model="queryParams.opUser"
            placeholder="请输入操作用户"
            clearable
            size="small"
            @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="ip" prop="ip">
        <el-input
            v-model="queryParams.ip"
            placeholder="请输入ip"
            clearable
            size="small"
            @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="操作时间" prop="opDateTime">
        <el-date-picker clearable size="small"
                        v-model="queryParams.opDateTime"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="选择操作时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="操作内容" prop="obj">
        <el-input
            v-model="queryParams.obj"
            placeholder="请输入操作内容包括"
            clearable
            size="small"
            @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button type="primary" plain icon="el-icon-s-operation" size="mini"
            @click="compareRecords" v-permission="['/system/ehrtrace/export']" >比较
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button type="warning" plain icon="el-icon-download"
            size="mini" @click="handleExport" v-permission="['/system/ehrtrace/export']" >导出
        </el-button>
      </el-col>
      <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>
    </el-row>

    <el-table v-loading="loading" :data="ehrtraceList" @selection-change="handleSelectionChange" stripe border>
      <el-table-column type="selection" width="55" align="center"/>
      <el-table-column label="主键ID" align="center" prop="id"/>
      <el-table-column label="表名" align="center" prop="tableName" width="120"/>
<!--      <el-table-column label="类名" align="center" prop="className"/>-->
      <el-table-column label="表记录id" align="center" prop="recordId"/>
      <el-table-column label="操作类型" align="center" prop="operation">
        <template slot-scope="scope">
          {{getDictDataLabel(DICT_TYPE.SYS_EHRTRACE_OP,scope.row.operation)}}
        </template>
      </el-table-column>
      <el-table-column label="操作用户" align="center" prop="opUser" width="120"/>
      <el-table-column label="ip" align="center" prop="ip" width="120"/>
      <el-table-column label="操作时间" align="center" prop="opDateTime" width="180">
<!--        <template slot-scope="scope">-->
<!--          <span>{{ parseTime(scope.row.opDateTime, '{y}-{m}-{d}') }}</span>-->
<!--        </template>-->
      </el-table-column>
      <el-table-column label="操作内容" align="center" prop="obj" min-width="400px" show-overflow-tooltip=""/>
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width" fixed="right">
        <template slot-scope="scope">
          <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="handleView(scope.row)"
          >查看
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
        v-show="total>0"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
    />
    <!-- 添加或修改操作日志对话框 -->
    <el-dialog title="记录比较" :visible.sync="openComp" append-to-body>
      <el-row>
        <div style="text-align: center">
          <el-switch v-model="viewChanged" active-text="只看改变项" inactive-text="看全部项"></el-switch>
        </div>
      </el-row>
      <el-row>
        <el-table :data="viewChanged? compCgArr:compArr" stripe border>>
          <el-table-column label="更新数据项" align="center" prop="itemName"/>
          <el-table-column v-for="i in compLen" :label="'更新数据值'+i" align="center" :prop="'v'+(i-1)" :key="'value'+i"/>
        </el-table>
      </el-row>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button @click="openComp = false;">关 闭</el-button>
      </div>
    </el-dialog>
    <!-- 添加或修改操作日志对话框 -->
    <el-dialog :title="title" :visible.sync="open" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-row :gutter="35">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
            <el-form-item label="表名" prop="tableName">
              <el-input v-model="form.tableName" placeholder="请输入表名"/>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
            <el-form-item label="类名" prop="className">
              <el-input v-model="form.className" placeholder="请输入类名"/>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
            <el-form-item label="表记录id" prop="recordId">
              <el-input v-model="form.recordId" placeholder="请输入表记录id"/>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
            <el-form-item label="操作类型" prop="operation">
              <el-input v-model="form.operation" placeholder="请输入操作类型"/>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
            <el-form-item label="操作用户" prop="opUser">
              <el-input v-model="form.opUser" placeholder="请输入操作用户"/>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
            <el-form-item label="ip" prop="ip">
              <el-input v-model="form.ip" placeholder="请输入ip"/>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
            <el-form-item label="操作时间" prop="opDateTime">
              <el-date-picker clearable size="small"
                              v-model="form.opDateTime"
                              type="date"
                              value-format="yyyy-MM-dd"
                              placeholder="选择操作时间">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb20">
            <el-form-item label="操作内容" prop="obj">
              <el-input v-model="form.obj" type="textarea" placeholder="请输入内容"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb20">
            <el-table :data="getDetails(form.obj)" stripe border>>
              <el-table-column label="更新数据项" align="center" prop="itemName"/>
              <el-table-column label="更新数据值" align="center" prop="itemValue"/>
            </el-table>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer" align="center">
        <el-button @click="cancel">关 闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  listEhrtrace,
  getEhrtrace,
  exportEhrtrace } from "@/api/sys/ehrtrace";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import RightToolbar from "@/components/RightToolbar"
import permission from '@/directive/permission/index.js' // 权限判断指令
import Pagination from "@/components/Pagination";
import {DICT_TYPE} from "@/utils/dict";

// 表查询配置
const dbOrderBy = ''

export default {
  name: "Ehrtrace",
  components: {
    Treeselect,
    RightToolbar,
    Pagination,
  },
  directives: { permission },
  data() {
    return {
      // 遮罩层
      loading: true,
      // 查询参数
      where: {
        query: '',
        filters: []
      },
      selectedRows:[],
      compArr:[],
      compCgArr:[],
      compLen: 0,
      openComp: false,
      viewChanged: false,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 操作日志表格数据
      ehrtraceList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        orderby: dbOrderBy,
        tableName: null,
        className: null,
        recordId: null,
        operation: null,
        opUser: null,
        ip: null,
        opDateTime: null,
        obj: null
      },
      // 表单参数
      form: {
      }
      ,
      // 表单校验
      rules: {
      }
    }
        ;
  },
  created() {
    this._filter = {};
    this.getList();
  },
  methods: {
    parseDetailObj(arr,pkey,obj){
      for (let key in obj) {
        let fkey = pkey+"."+key;
        let objV = obj[key]?obj[key]:""
        if(typeof objV == 'object' && obj ){
          this.parseDetailObj(arr,fkey,objV);
        }else{
          arr.push({itemName:fkey, itemValue: objV});
        }
      }
    },
    getDetails(str){
      let arr = [];
      try {
        var obj=JSON.parse(str);
        if(typeof obj == 'object' && obj ){
          for (let key in obj) {
            let objV = obj[key]?obj[key]:""
            if(typeof objV == 'object' && obj ){
              this.parseDetailObj(arr,key,objV);
            }else{
              arr.push({itemName:key, itemValue: objV});
            }
          }
          return arr;
        }else{
          return arr;
        }

      } catch(e) {
        // console.log('error：'+str+'!!!'+e);
        return arr;
      }
    },

    parseCompObj(arr,pkey,obj,arrIdx,len){
      for (let key in obj) {
        let fkey = pkey+"."+key;
        let objV = obj[key]?obj[key]:""
        if(typeof objV == 'object' && obj ){
          this.parseCompObj(arr,fkey,objV);
        }else{
          if(!arr[fkey]){
            arr[fkey] = [];
            for(let j = 0; j < len; j++){
              arr[fkey].push("");
            }
          }
          arr[fkey][arrIdx]= objV;
        }
      }
    },
    compareRecords(){
      if(!this.selectedRows || this.selectedRows.length < 1) {
        this.$message({type: 'warning', message: "请选择需要比较的记录"});
        return [];
      }
      this.compLen =  this.selectedRows.length;
      let arr = [];
      let objArr = {};
      // 先将选择对象转换成 字段名：[v1,v2...]
      for(let i = 0; i < this.selectedRows.length; i++){
        const objStr = this.selectedRows[i].obj;
        // console.log(objStr);
        var obj=JSON.parse(objStr);
        // console.log(obj)
        if(typeof obj == 'object' && obj ){
          for (let key in obj) {
            let objV = obj[key]?obj[key]:""
            // 如果值是json对象
            if(typeof objV == 'object' && obj ){
              this.parseCompObj(objArr,key,objV, i);
            }else{
              if(!objArr[key]) {
                objArr[key] = [];
                for(let j = 0; j < this.compLen; j++){
                  objArr[key].push("");
                }
              }
              objArr[key][i]= objV;
            }
          }
        }
      }
      // 将对象转换成数组和 值改变字段的数组
      let changedArr = [];
      // 添加前面基本字段
      let item = this.getObjItem("表名","tableName");
      arr.push(item);
      changedArr.push(item);
      item = this.getObjItem("表记录id","recordId");
      arr.push(item);
      changedArr.push(item);
      item = this.getObjItem("操作类型","operation");
      arr.push(item);
      changedArr.push(item);
      item = this.getObjItem("操作用户","opUser");
      arr.push(item);
      changedArr.push(item);
      item = this.getObjItem("操作ip地址","ip");
      arr.push(item);
      changedArr.push(item);
      item = this.getObjItem("操作时间","opDateTime");
      arr.push(item);
      changedArr.push(item);
      // 添加内容字段
      for(let key in objArr){
        let item = {itemName:key}
        const values = objArr[key];
        let preV = values[0];
        let changed = false;
        for(let i = 0; i < values.length; i++){
          item["v"+i] = values[i];
          if(preV != values[i]){
            // console.log("value changed: prev="+preV+", valuei="+values[i]);
            changed = true;
          }
          preV = values[i];
        }
        if(changed) changedArr.push(item);
        arr.push(item);
        this.compArr = arr;
        this.compCgArr = changedArr;
        this.openComp = true;
        console.log(" compare arr...")
        console.log(arr)
        console.log(changedArr);
        console.log(" compare changedArr...")
      }
    },
    getObjItem(itemName, filedName){
      let item = {itemName: itemName};
      for(let i = 0; i < this.selectedRows.length; i++) {
        item["v"+i] = this.selectedRows[i][filedName];
      }
      return item;
    },
    handleView(row){
      this.form = row;
      this.open = true;
    },
    /** 查询操作日志列表 */
    getQuery(){
      this._filter = {};
      if(this.queryParams.tableName) {
        this._filter['tableName'] = {
          type: 'search',
          value: this.queryParams.tableName,
        }
      }
      if(this.queryParams.className) {
        this._filter['className'] = {
          type: 'search',
          value: this.queryParams.className,
        }
      }
      if(this.queryParams.recordId) {
        this._filter['recordId'] = {
          type: 'search',
          value: this.queryParams.recordId,
        }
      }
      if(this.queryParams.operation) {
        this._filter['operation'] = {
          type: 'search',
          value: this.queryParams.operation,
        }
      }
      if(this.queryParams.opUser) {
        this._filter['opUser'] = {
          type: 'search',
          value: this.queryParams.opUser,
        }
      }
      if(this.queryParams.ip) {
        this._filter['ip'] = {
          type: 'search',
          value: this.queryParams.ip,
        }
      }
      if(this.queryParams.opDateTime) {
        this._filter['opDateTime'] = {
          type: 'search',
          value: this.queryParams.opDateTime,
        }
      }
      if(this.queryParams.obj) {
        this._filter['obj'] = {
          type: 'search',
          value: this.queryParams.obj,
        }
      }
      const filters = this.parseFilters(this._filter)
      if (filters && filters.length > 0) {
        this.where.filters = filters
      } else {
        // 如果过滤条件为空，设置空数组
        this.where.filters = []
      }
      const params = {
        pageNum: this.queryParams.pageNum,
        pageSize: this.queryParams.pageSize,
        where: this.where,
        order: this.orderby
      }

      return params;
    },
    getList() {
      this.loading = true;
      const params = this.getQuery();
      listEhrtrace(params).then(response => {
        this.ehrtraceList = response.data.records;
        this.total = response.data.total;
        this.loading = false;
      })
      ;
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {};
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.selectedRows = selection;
      this.ids = selection.map(item => item.id)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },

    /** 导出按钮操作 */
    handleExport() {
      const params = this.getQuery();
      this.$confirm('是否确认导出所有操作日志数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return exportEhrtrace(params);
      }).then(response => {
        this.download(response.msg);
      })
    }
  }
};
</script>

<style scoped>

</style>
