<template>
  <div class="app-container" >
    <el-row :gutter="20">
      <!--部门选择-->
      <el-col :span="4" :xs="24" v-if="isCompnayVisible">
        <div class="head-container">
          <!--公司选择-->

          <el-input
              v-model="companyName"
              placeholder="请输入公司名称"
              clearable
              size="small"
              prefix-icon="el-icon-search"
              style="margin-bottom: 20px"
          />
        </div>
        <div class="head-container">
          <el-tree
              :data="companyOptions"
              :props="defaultProps"
              :expand-on-click-node="false"
              :filter-node-method="filterNode"
              ref="tree"
              default-expand-all
              @node-click="handleNodeClick"
              highlight-current
          />
        </div>
      </el-col>
      <el-col :span="20" :xs="24">
        <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px">
          <el-form-item label="岗位编号" prop="num">
            <el-input
                v-model="queryParams.num"
                placeholder="请输入岗位编号"
                clearable
                size="small"
                @keyup.enter.native="handleQuery"
            />
          </el-form-item>
          <el-form-item label="岗位名称" prop="name">
            <el-input
                v-model="queryParams.name"
                placeholder="请输入岗位名称"
                clearable
                size="small"
                @keyup.enter.native="handleQuery"
            />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
            <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
          </el-form-item>
        </el-form>

        <el-row :gutter="10" class="mb8">
          <el-col :span="1.5">
            <el-button
                type="primary"
                plain
                icon="el-icon-plus"
                size="mini"
                :disabled="this.companyId == null ? true: false"
                @click="handleAdd"
                v-permission="['/org/position/add']"
            >新增
            </el-button>
          </el-col>
          <el-col :span="1.5">
            <el-button
                type="success"
                plain
                icon="el-icon-edit"
                size="mini"
                :disabled="single"
                @click="handleUpdate"
                v-permission="['/org/position/edit']"
            >修改
            </el-button>
          </el-col>
          <el-col :span="1.5">
            <el-button
                type="danger"
                plain
                icon="el-icon-delete"
                size="mini"
                :disabled="multiple"
                @click="handleDelete"
                v-permission="['/org/position/remove']"
            >删除
            </el-button>
          </el-col>
<!--          <el-col :span="1.5">-->
<!--            <el-button-->
<!--                type="warning"-->
<!--                plain-->
<!--                icon="el-icon-download"-->
<!--                size="mini"-->
<!--                @click="handleExport"-->
<!--                v-permission="['/org/position/export']"-->
<!--            >导出-->
<!--            </el-button>-->
<!--          </el-col>-->
          <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>
        </el-row>

        <el-table v-loading="loading" :data="positionOptions"
                  row-key="id"
                  default-expand-all
                  :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                  style="width:100%" stripe border
                  @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55" align="center"/>
          <el-table-column label="岗位编号" align="center" prop="num" sortable/>
          <el-table-column label="岗位名称" align="center" prop="name" width="180" sortable/>
          <el-table-column label="编制人数" align="center" prop="planCount" sortable/>
          <el-table-column label="在编人数" align="center" prop="realCount"/>
          <el-table-column label="缺编人数" align="center">
            <template slot-scope="scope"><span v-if="scope.row.planCount&&(scope.row.planCount-scope.row.realCount)>0" style="color: #c75151">
              {{scope.row.planCount - scope.row.realCount}}</span></template>
          </el-table-column>

<!--          <el-table-column label="所属部门" align="center" prop="companyId" :formatter="departmentFormat">-->
<!--            <template slot-scope="scope">-->
<!--              <el-tag type="info" :disable-transitions="false">-->
<!--                {{departmentFormat(scope.row, null)}}-->
<!--              </el-tag>-->
<!--            </template>-->
<!--          </el-table-column>-->
          <el-table-column label="岗级" align="center" prop="postLevel" :formatter="postLevelFormat" sortable/>
          <el-table-column label="描述" align="center" prop="introduction" show-overflow-tooltip/>
<!--          <el-table-column label="排序顺序" align="center" prop="seq"/>-->
          <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
            <template slot-scope="scope">
              <el-button
                  size="mini"
                  type="text"
                  icon="el-icon-edit"
                  @click="handleUpdate(scope.row)"
                  v-permission="['/org/position/edit']"
              >修改
              </el-button>
              <el-button
                  size="mini"
                  type="text"
                  icon="el-icon-delete"
                  @click="handleDelete(scope.row)"
                  v-permission="['/org/position/remove']"
              >删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>

<!--        <pagination-->
<!--            v-show="total>0"-->
<!--            :total="total"-->
<!--            :page.sync="queryParams.pageNum"-->
<!--            :limit.sync="queryParams.pageSize"-->
<!--            @pagination="getList"-->
<!--        />-->
      </el-col>
    </el-row>

    <!-- 添加或修改岗位管理对话框 -->
    <el-dialog :title="title" :visible.sync="open" append-to-body>
      <PositionEdit :row="row" :companyId="companyId"
                      :companyOptionsIn="companyOptions" :positionOptionsIn="positionOptions"  @close="close"></PositionEdit>
    </el-dialog>
  </div>
</template>

<script>
import {
  listPosition,
  getPosition,
  delPosition,
  addPosition,
  updatePosition,
  exportPosition } from "@/api/org/position";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import RightToolbar from "@/components/RightToolbar"
import permission from '@/directive/permission/index.js' // 权限判断指令
import {getTreeselectCompany} from "@/api/org/company";
import PositionEdit from "./positionEdit";

// 表查询配置
const dbOrderBy = ''

export default {
  name: "Position",
  components: {
    Treeselect,
    RightToolbar,
    PositionEdit,
  },
  directives: { permission },
  data() {
    return {
      isCompnayVisible: false,
      row: null,
      // 遮罩层
      loading: true,
      // 查询参数
      where: {
        query: '',
        filters: []
      },
      companyId: 1,
      companyList: [],

      // 公司树选项
      companyOptions: [],
      //
      postLevelOptions: [],
      defaultProps: {
        children: "children",
        label: "companyName"
      },
      companyName: undefined,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 岗位管理表格数据
      positionList: [],
      positionOptions: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 500,
        orderby: dbOrderBy,
        num: null,
        name: null,

        companyId: null,
      },

    };
  },
  watch: {
    // 根据名称筛选部门树
    companyName(val) {
      this.$refs.tree.filter(val);
    },
  },
  created() {
    if(this.$store.getters.userInfo.constParentCompanyId == this.$store.getters.userInfo.companyId){
      this.isCompnayVisible = true;
    }
    this._filter = {};
    this.companyId = this.$store.getters.userInfo.companyId;
    this.queryParams.companyId = this.$store.getters.userInfo.companyId;
    this.getDicts("org_position_level").then(response => {
      this.postLevelOptions = response.data;
    });

    getTreeselectCompany(null, (list, options) =>{
      this.companyList = list;
      if(this.companyList.length > 1){
        this.isCompnayVisible = true;
      }
      this.companyOptions = options;
    });

    this.getList();
  },
  methods: {
    /** 查询岗位管理列表 */
    getQuery(){
      this._filter = {};
      if(this.queryParams.companyId) {
        this._filter['companyId'] = {
          type: 'eq',
          value: this.queryParams.companyId,
        }
      }
      if(this.queryParams.num) {
        this._filter['num'] = {
          type: 'search',
          value: this.queryParams.num,
        }
      }
      if(this.queryParams.name) {
        this._filter['name'] = {
          type: 'search',
          value: this.queryParams.name,
        }
      }
      const filters = this.parseFilters(this._filter)
      if (filters && filters.length > 0) {
        this.where.filters = filters
      } else {
        // 如果过滤条件为空，设置空数组
        this.where.filters = []
      }
      const params = {
        pageNum: this.queryParams.pageNum,
        pageSize: 1000,
        where: this.where,
        order: this.orderby
      }

      return params;
    },
    getList() {
      this.loading = true;
      this.postionEmpty = '没有数据';
      const params = this.getQuery();
      listPosition(params).then(response => {
        this.positionList = response.data.records;
        this.positionOptions = this.handleTree(response.data.records, "id", "pid");
        // this.total = response.data.total;
        this.loading = false;
      });
    },

    // 岗级类型，字典代码字典翻译
    postLevelFormat(row, column) {
      return this.selectDictLabel(this.postLevelOptions, row.postLevel);
    },

    /** 转换公司数据结构 */
    normalizerCompany(node) {
      return this.normalizer(node,'id','companyName');
    },


    // 筛选节点
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    // 节点单击事件
    handleNodeClick(data) {
      console.log(data);
      this.queryParams.companyId = data.id;
      this.companyId = data.id;
      this.getList();
    },
    companyFormat(row, column) {
      console.log(row.companyId);
      for(var i = 0; i < this.companyList.length; i++){
        const item =  this.companyList[i];
        console.log("id:"+item.id+",depid:"+row.companyId+", name :"+item.name+",ee="+(item.id == row.companyId));
        if(item.id == row.companyId){
          return item.companyName;
        }
      }
      return "";
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.row = null;
      this.open = true;
      this.title = "添加岗位";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      if(row.id){
        this.row = row;
      }else{
        this.row = {};
        this.row.id = this.ids;
      }
      this.open = true;
      this.title = "修改岗位";
    },

    // 提交确认后关闭对话框
    close(arg){
      this.open = false;
      if(arg.refresh){
        this.getList();
      }
    },

    /** 删除按钮操作 */
    handleDelete(row) {
      let ids = [];
      if(row.id){
        ids = [row.id];
      }else{
        ids = this.ids;
      }
      this.$confirm('是否确认删除选中岗位数据?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return delPosition(ids);
      }).then(() => {
        this.getList();
        this.msgSuccess("删除成功");
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      const params = this.getQuery();
      this.$confirm('是否确认导出所有岗位管理数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return exportPosition(params);
      }).then(response => {
        this.download(response.msg);
      })
    }
  }
};
</script>

