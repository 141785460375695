<template>
	<div>
		<div>
			<!-- 增加，合同，保密协议，培训协议等 -->
			<el-form ref="currentContract" :model="currentContract" label-width="auto" :rules="rules">
				<el-row>
					<el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
						<el-form-item label="合同状态" prop="contractStatus">
							<el-select v-model="currentContract.contractStatus" :disabled="this.isReadOnly" placeholder="请选择合同状态">
								<el-option v-for="item in contractStatuses" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue" />
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
						<el-form-item label="合同类型" prop="contractType">
							<el-select v-model="currentContract.contractType" :disabled="this.isReadOnly" placeholder="请选择合同类型">
								<el-option v-for="item in contractTypes" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue" />
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
						<el-form-item label="合同编号方式" prop="contractIdEncoding">
							<el-select v-model="contractIdEncoding" :disabled="!this.isAddContract" placeholder="请选择合同编号方式">
								<el-option v-for="item in constractIdEncodings" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue" />
							</el-select>
							<!-- <el-button style="margin-left: 5px; padding-left: 10px; padding-right: 10px">新增</el-button> -->
						</el-form-item>
					</el-col>

					<el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
						<el-form-item label="合同编号" prop="contractId" label-width="95px">
							<el-input v-model="currentContract.contractId" style="width: 57%" :disabled="!this.isAddContract"></el-input>
							<el-button
								style="margin-left: 5px; padding-left: 10px; padding-right: 10px"
								@click="generateContractNumber"
								:loading="loading"
								:disabled="this.isUpdateContract"
								>生成</el-button
							>
						</el-form-item>
					</el-col>
				</el-row>

				<el-row>
					<el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
						<el-form-item label="起始日期" prop="contractStart">
							<el-date-picker
								v-model="currentContract.contractStart"
								value-format="yyyy-MM-dd"
								:readonly="this.isReadOnly"
								align="left"
								type="date"
								placeholder="选择日期"
							>
							</el-date-picker>
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
						<el-form-item label="终止日期" prop="contractEnd">
							<el-date-picker
								v-model="currentContract.contractEnd"
								value-format="yyyy-MM-dd"
								:readonly="this.isReadOnly"
								align="left"
								type="date"
								placeholder="选择日期"
							>
							</el-date-picker>
						</el-form-item>
					</el-col>
				</el-row>
				<div>
					<!-- "image/png,image/jpg,image/jpeg,image/gif" -->
					<el-upload
						class="upload-demo"
						ref="upload"
						action=" "
						:on-preview="handlePreview"
						:file-list="fileList"
						:before-upload="beforeUpload"
						:http-request="uploadFile"
						:auto-upload="true"
						:on-change="handleChange"
						:limit="20"
						list-type="picture-card"
						:multiple="true"
						:show-file-list="true"
						:on-exceed="handleExceed"
						:disabled="this.isReadOnly"
					>
						<el-button slot="trigger" size="small" type="primary">选取文件</el-button>
						<!-- <el-button style="margin-left: 10px" size="small" type="success" @click="submitUpload">上传到服务器</el-button> -->

						<div slot="tip" class="el-upload__tip">每个上传文件且不超过50Mb</div>
						<!-- <div slot="file" slot-scope="{file}" >
							<img src=""  class="" />
							<span class="el-upload-list__item-actions">
								<span
								class="el-upload-list__item-delete"
								@click="handleDownLoad(file)"
								>
									<i class="el-icon-download" />
								</span>
							</span>
						</div> -->

						<div slot="file" slot-scope="{ file }" style="height: 100%">
							<pdf v-if="file.name.endsWith('.pdf')" :src="file.url" :page="1"></pdf>
							<img v-else class="el-upload-list__item-thumbnail" style="object-fit: cover" :src="file.url" alt="" />
							<span class="el-upload-list__item-actions">
								<span class="el-upload-list__item-preview" @click="handlePreview(file)">
									<i class="el-icon-zoom-in"></i>
								</span>
								<span class="el-upload-list__item-delete" @click="handleDownLoad(file)">
									<i class="el-icon-download"></i>
								</span>
								<span class="el-upload-list__item-delete" @click="handleRemove(file)">
									<i class="el-icon-delete"></i>
								</span>
								<span>
									<i>{{ file.name }}</i>
								</span>
							</span>
						</div>
					</el-upload>
				</div>
				<!-- <div v-if="!isAddContract" style="padding-left: 25px">
					<span style="font-size: 15px; font-weight: bold">文件列表</span>
					<ul id="ul" style="list-style: none">
						<li v-for="(item, index) in fileList" :key="index">
							<h9>{{ item.name }}</h9>
						</li>
					</ul>

				 <el-table :data="fileList" style="width:60%" stripe >
						<el-table-column label="文件名" prop="name" >

						</el-table-column>
					</el-table>
				</div> 
				-->
				<el-dialog :visible.sync="dialogVisible" @close="closePreviewDialog">
					<div v-if="dialogPdfName.endsWith('.pdf')">
						<!-- <Pdf :src="pdfSrc" :page="10"></Pdf> -->
						<pdf v-for="item in numPages" :key="item" :src="pdfSrc" :page="item" />
					</div>
					<div v-else>
						<img width="100%" :src="dialogImageUrl" alt="" />
					</div>
				</el-dialog>

				<div style="margin-top: 10px; text-align: center">
					<!-- <span slot="footer" class="dialog-footer"> -->
					<el-button type="primary" plain v-if="isAdd" :loading="adding" @click="submitUpload">增 加</el-button>
					<el-button type="primary" plain v-if="isUpdate" :loading="updating" @click="editContract">修 改</el-button>
					<el-button @click="cancel">取 消</el-button>
					<!-- </span> -->
				</div>
			</el-form>
		</div>
	</div>
</template>

<script>
import { listEmploy, getEmploy, downloadCertificatePictureApi } from '@/api/employ/employ';
import { uploadContract, downloadFileByPath, updateContract, generateContractNo } from '@/api/employ/contractDocument';
import { graphic, number } from 'echarts';
import Pdf from 'vue-pdf';
import CMapReaderFactory from 'vue-pdf/src/CMapReaderFactory.js';
export default {
	components: {
		Pdf,
	},
	data() {
		let contractInit = {};
		let contractFileList = [];
		if (this.isAddContract == true) {
			contractInit = {
				contractId: '',
				contractType: '',
				contractStatus: '',
				contractStart: '',
				contractEnd: '',
				createdDate: '',
				latestUpdateDate: '',
				contractPictures: [],
			};
		} else {
			contractInit = this.contractProp;
			if (contractInit.contractPictures != null && contractInit.contractPictures != undefined) {
				contractFileList = contractInit.contractPictures.map((p) => {
					return { name: p.fileName, url: p.url };
				});
			}
		}
		// let initReadOnly = this.isView;
		// if (this.isView) {

		// }
		return {
			currentContract: contractInit,
			currentEmployee: null,

			isReadOnly: this.isView,
			isAdd: this.isAddContract,
			isUpdate: this.isUpdateContract,
			dialogTile: this.title,
			contractIdEncoding: '',
			contractTypes: [],
			contractStatuses: [],
			constractIdEncodings: [],
			fileListInit: contractFileList,
			fileList: [],
			serverFileList:new Map(),
			newFileList: new Map(),
			formData: new FormData(),
			loading: false,
			adding: false,
			updating: false,
			dialogImageUrl: '',
			dialogVisible: false,
			pdfUrl: '',
			dialogPdfName: '',
			numPages: 1,
			pickerOptions: {
				disabledDate(time) {
					return time.getTime() > Date.now();
				},
			},
			pickerOptions2: {
				disabledDate(time) {
					return time.getTime() < Date.now();
				},
			},
			rules: {
				contractStatus: [{ required: true, message: '请选择合同状态', trigger: 'blur' }],
				contractType: [{ required: true, message: '请选择合同类型', trigger: 'blur' }],
				contractId: [{ required: true, message: '请产生合同ID', trigger: 'blur' }],
				contractStart: [{ required: true, message: '请选择合同起始日期', trigger: 'blur' }],
				contractEnd: [{ required: true, message: '请选择合同终止日期', trigger: 'blur' }],
			},
		};
	},
	props: {
		employeeId: number,
		currentContractId: String,
		isView: Boolean,
		isAddContract: Boolean,
		isUpdateContract: Boolean,
		contractProp: Object,
	},
	created() {
		this.load();
	},
	// watch: {
	// 	contractProp(vulue) {
	// 		this.currentContract=vulue;
	// 		this.fileList = this.currentContract.contractPictures.map(p=>{return {name:p.fileName,url:p.url} });
	// 	},
	// },
	methods: {
		load() {
			getEmploy(this.employeeId).then((response) => {
				// let self = this;
				this.currentEmployee = response.data;
				// if (self.currentEmployee.contracts != null && self.currentEmployee.contracts.length > 0) {
				// 	self.currentContract = self.currentEmployee.contracts.find((p) => p.contractId == self.currentContractId);
				// }
			});

			this.getDicts('contract_type').then((response) => {
				this.contractTypes = response.data;
			});
			this.getDicts('contract_status').then((response) => {
				this.contractStatuses = response.data;
			});

			this.getDicts('contract_id_encoding').then((response) => {
				this.constractIdEncodings = response.data;
			});

			this.downloadContratFiles();
		},
		downloadContratFiles() {
			if (this.fileListInit != null) {
				this.fileListInit.forEach((item) => {
					downloadFileByPath(item.url)
						.then((res) => {
							if (res) {
								let resData = res.data;
								var blob = new Blob([resData], { type: 'application/octet-stream' });
								let tempUrl = URL.createObjectURL(blob);
								this.fileList.push({ name: item.name, url: tempUrl });
								this.serverFileList.set(item.name, item.url);
							} else {
								this.msgError('后端合同下载文件出错');
							}
						})
						.catch((e) => {
							this.loading = false;
							this.msgError(`后端下载文件出错 & ${e}`);
						});
				});
			}
		},
		uploadFile(params) {
			console.debug(params);
			//this.formData.append('files', params.file);
			this.newFileList.set(params.file.name, params.file)
			this.fileList.push({name:params.file.name,url:URL.createObjectURL(params.file)});
			//this.fileList[this.fileList.length-1].status='ready';
		},
		generateContractNumber() {
			if (this.contractIdEncoding == null || this.contractIdEncoding == '') {
				this.msgError('请先选择合同号码产生方式。');
				return;
			}
			this.loading = true;
			let encoding = this.constractIdEncodings.filter((item) => item.dictValue == this.contractIdEncoding);
			generateContractNo(this.employeeId, encoding[0].listClass, this.currentContract.contractType)
				.then((res) => {
					this.loading = false;
					if (res.success) {
						this.currentContract.contractId = res.data;
					} else {
						this.msgError('产生合同编号出错' + res.msg);
					}
				})
				.catch((e) => {
					this.loading = false;
					this.msgError(`产生合同编号出错， & ${e}`);
				});
		},
		generateContractNo() {
			if (this.contractIdEncoding == null || this.contractIdEncoding == '') {
				this.msgError('请先选择合同号码产生方式。');
			} else {
				let idEncoding = this.contractIdEncoding;
				let newContractNo = '';
				let propNames = Object.keys(this.currentEmployee);

				propNames.push('contractType');
				propNames.push('flowCode');
				let idEncodings = idEncoding.split('-');
				for (let iter = 0; iter < idEncodings.length; iter++) {
					let key = idEncodings[iter];
					if (key == 'flowCode') {
						let maxSeq = 0;

						// 2位流水码
						if (this.currentEmployee.contracts != null && this.currentEmployee.contracts != undefined && this.currentEmployee.contracts.length > 0) {
							let filterContracts = this.currentEmployee.contracts.filter((item) => {
								return item.contractType == this.currentContract.contractType;
							});
							if (filterContracts != null && filterContracts.length > 0) {
								for (let index = 0; index < filterContracts.length; index++) {
									let contract = filterContracts[index];
									let contractId = contract.contractId;
									if (contractId != null && contractId != '' && contractId.length > 2) {
										let contractFlowCodeString = contractId.substring(contractId.length - 2, contractId.length);
										let contractFlowCode = parseInt(contractFlowCodeString);
										if (contractFlowCode != NaN) {
											if (contractFlowCode > maxSeq) {
												maxSeq = contractFlowCode;
											}
										}
									}
								}
							}
						}
						if (maxSeq < 10) {
							newContractNo += '0' + (maxSeq + 1);
						} else {
							newContractNo += maxSeq;
						}
					} else if (key == 'contractType') {
						newContractNo += this.currentContract.contractType;
					} else {
						let foundIndex = propNames.indexOf(key);
						if (foundIndex > 0) {
							newContractNo += this.currentEmployee[key];
						}
					}
				}

				this.currentContract.contractId = newContractNo;
			}
		},
		cancel() {
			this.$emit('closedialog', { refresh: false, isSuccess: true });
		},
		handleExceed(files, fileList) {
			this.msgError(`当前限制选择 20 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件.`);
		},
		handleRemove(file) {
			let foundIndex = this.fileList.findIndex((p) => p.name == file.name);
			if (foundIndex >= 0) {
				this.fileList.splice(foundIndex, 1);
			} 
			let foundFile = this.newFileList.get(file.name);
			if(foundFile){
				this.newFileList.delete(file.name)
			}
			// let files = this.formData.getAll("files");
			// if(files){
			// 	foundIndex = files.findIndex((p) => p.name == file.name);
			// 	if (foundIndex >= 0) {
			// 		files.splice(foundIndex, 1);
			// 		this.formData.delete();
			// 	} 
			// }
			// else {
			// 	this.$refs.upload.handleRemove(file);
			// }
			return;
		},
		handleChange(file, fileList) {
			//this.currentContract.contractPictures = fileList;
			// 非自动上传，before-upload 不起作用，改到这里验证。
			// let isValidFile = this.beforeUpload(file);
			// if (!isValidFile) {
			// 	this.$refs.upload.handleRemove(file);
			// }
		},
		beforeUpload(file) {
			var fileType = file.name.substring(file.name.lastIndexOf('.') + 1);
			const jpg = fileType === 'jpg' || fileType === 'JPG';
			const jpeg = fileType === 'jpeg' || fileType === 'JPEG';
			const pdf = fileType == 'pdf' || fileType == 'PDF';
			const gif = fileType === 'gif';
			const png = fileType === 'png';
			const isLt50M = file.size / 1024 / 1024 < 50;
			const isEmpty = file.size === 0;
			if (!jpg && !jpeg && !pdf && !gif && !png) {
				this.msgError('文件类型错误，上传文件只能是png,jpeg,gif,pdf格式!');
			}
			if (!isLt50M) {
				this.msgError('上传文件大小不能超过 50MB!');
			}
			if (isEmpty) {
				this.msgError('上传文件大小不能为空文件!');
			}
			return (jpeg || jpg || png || gif || pdf) && isLt50M && !isEmpty;
		},
		handlePreview(file) {
			if (file.name.endsWith('.pdf')) {
				//this.pdfSrc = file.url;
				this.dialogPdfName = file.name;
				this.dialogImageUrl = '';
				this.pdfSrc = Pdf.createLoadingTask({ url: file.url, CMapReaderFactory }); //解决中文乱码问题
				this.pdfSrc.promise.then((pdf) => {
					this.numPages = pdf.numPages;
				});
			} else {
				this.dialogImageUrl = file.url;
				this.pdfSrc = '';
			}

			this.dialogVisible = true;
		},
		handleDownLoad(file) {
		
			if(!this.serverFileList.has(file.name)){
				this.msgError('前端没找到上传的文件！');
			}
			let fileUrl = this.serverFileList.get(file.name);
			if (fileUrl != null && fileUrl.length > 0) {
				this.$confirm('确定下载该员工的合同文件?', '确认', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
				}).then((response) => {
					downloadCertificatePictureApi(fileUrl)
						.then((res) => {
							if (res) {
								let resData = res.data;
								var blob = new Blob([resData], { type: 'application/octet-stream' });
								const fileName = file.name;
								if ('download' in document.createElement('a')) {
									// 非IE下载

									const elink = document.createElement('a');
									elink.download = fileName;
									elink.style.display = 'none';
									elink.href = URL.createObjectURL(blob);
									document.body.appendChild(elink);
									elink.click();
									URL.revokeObjectURL(elink.href); // 释放URL 对象
									document.body.removeChild(elink);
								} else {
									// IE10+下载
									navigator.msSaveBlob(blob, fileName);
								}
							}
						})
						.catch((e) => {
							this.msgError(`后端下载文件出错 & ${e}`);
						});
				});
			} else {
				this.msgError('文件路径为空。');
			}
		},
		submitUpload() {
			this.$refs.currentContract.validate((valid) => {
				let self = this;
				if (valid) {
					self.formData = new FormData();
					for (const file of self.newFileList.values()) {
							self.formData.append('files',file)
						}
					//uploadFile会逐个加入到formData
					//self.$refs.upload.submit();

					// let files = self.formData.getAll("files");
					// if(files.length >1)
					// {
					// 	files.sort(self.sortData);
					// }

					// //后台接口
					if (self.isAddContract) {
						self.currentContract.createdDate = '';
						self.currentContract.latestUpdateDate = '';
						self.formData.append('contract', JSON.stringify(self.currentContract));
						self.formData.append('employId', self.employeeId);

						self.adding = true;
						uploadContract(self.formData)
							.then((response) => {
								self.adding = false;
								if (response.code == 200) {
									self.currentContract.contractPictures.push(response.data);
									self.msgSuccess('增加成功');
									self.$emit('closedialog', { refresh: true, isSuccess: true });
								} else {
									self.msgError(response.message);
								}
							})
							.catch((err) => {
								self.adding = false;
								self.msgError(err);
							});
					} else {
					}
				} else {
					this.msgError('有非法输入或必填项未填写，请检查!');
				}
			});
		},
		sortData(a, b) {
			let aName = a.name.split('.')[0];
			let bName = b.name.split('.')[0];
			if (Number.isNaN(aName) || Number.isNaN(bName)) {
				return aName > bName;
			} else {
				return parseInt(aName) - parseInt(bName);
			}
		},
		editContract() {
			let self = this;
			this.$refs.currentContract.validate((valid) => {
				if (valid) {
					self.formData = new FormData();
					for (const file of self.newFileList.values()) {
							self.formData.append('files',file)
						}

					// 新增的合同文件, picture也就是文件，最初以为上传扫描图片
					// let newFiles = self.$refs.upload.uploadFiles.filter((item) => item.status == 'ready');
					// newFiles.forEach((file) => formData.append('files', file.raw));
					self.formData.append('contentType', 'multipart/form-data;');

					// 移除的合同文件
					self.currentContract.contractPictures.forEach(function (element) {
						let foundIndex = self.$refs.upload.uploadFiles.findIndex((item) => item.name == element.fileName);
						if (foundIndex == -1) {
							let removedIndex = self.currentContract.contractPictures.findIndex((item) => item.fileName == element.fileName);
							if (removedIndex > 0) {
								self.currentContract.contractPictures.splice(removedIndex, 1);
							}
						}
					});

					self.formData.append('contract', JSON.stringify(self.currentContract));
					self.formData.append('employId', self.employeeId);

					self.updating = true;
					updateContract(self.formData)
						.then((response) => {
							self.updating = false;
							if (response.code == 200) {
								self.msgSuccess('修改成功');
								self.$emit('closedialog', { refresh: true, isSuccess: true });
							} else {
								self.msgError(response.message);
							}
						})
						.catch((err) => {
							self.updating = false;
							self.msgError(err);
						});
				} else {
					this.msgError('有非法输入或必填项未填写，请检查!');
				}
			});
		},
	},
	closePreviewDialog() {
		this.dialogImageUrl = '';
		this.pdfUrl = '';
		this.dialogVisible = false;
	},
};
</script>

<style></style>