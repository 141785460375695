<template>
	<div>
		<el-form :model="suggestionBox" label-width="80px" label-position="right" :inline="true" ref="form">
			<el-row :gutter="24">
				<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb10" style="text-align: center">
					<span>意见箱</span>
				</el-col>

				<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">
					<el-form-item label="标题" class="mb5">
						<el-input v-model="suggestionBox.title"></el-input>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">
					<el-form-item label="意见" >
						<div style="min-width:450px;">
							<el-input
							clearable
							:autosize="{ minRows: 5, maxRows: 10 }"
							maxlength="1000"
							show-word-limit
							type="textarea"
							v-model="suggestionBox.suggestion"
							placeholder="请写下你的意见"
						/>
						</div>
						
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" >
					<div style="margin-left: 80px; margin-bottom: 10px">
						<el-upload
							ref="elUpload"
							multiple=""
							class="avatar-uploader"
							action="uploadAttachmentPicture()"
							:limit="pictureLimit"
							:show-file-list="true"
							:file-list="fileList"
							v-loading="loading"
							:element-loading-text="loadingText"
							element-loading-spinner="el-icon-loading"
							list-type="picture-card"
							:on-success="handleAvatarSuccess"
							:http-request="
								(res) => {
									return uploadAttachmentPicture(res);
								}
							"
							:on-preview="handlePictureCardPreview"
							:auto-upload="true"
							accept=".jpg,.jpeg,.bmp,.png,application/pdf"
							:before-upload="beforeAvatarUpload"
							:on-exceed="handleExceed"
							:on-remove="handleRemove"
						>
							<i class="el-icon-plus avatar-uploader-icon"></i>
							<div slot="file" slot-scope="{ file }" v-loading="file.status == 'uploading'" style="height: 100%">
								<pdf v-if="file != null && file.name != null && file.name.endsWith('.pdf')" :src="file.url" :page="1"></pdf>
								<img v-else class="el-upload-list__item-thumbnail" style="object-fit: cover" :src="file.url" alt="" />
								<span class="el-upload-list__item-actions">
									<span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
										<i class="el-icon-zoom-in"></i>
									</span>

									<span v-if="isNew" class="el-upload-list__item-delete" @click="handleRemove(file)">
										<i class="el-icon-delete"></i>
									</span>
								</span>
							</div>
						</el-upload>
						<el-dialog :visible.sync="dialogVisible" @close="closeDialog">
							<div v-if="dialogPdfName != null && dialogPdfName.endsWith('.pdf')">
								<pdf :src="pdfUrl" :page="1"></pdf>
							</div>
							<div v-else>
								<img width="100%" :src="dialogImageUrl" alt="" />
							</div>
						</el-dialog>
						<p style="display:inline;">上传意见, 支持jpg、png、bpm、pdf格式，单个文件不超过50M。</p>
					</div>
				</el-col>
				<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0" v-if="this.display">
					<el-form-item label=" " class="mb5">
						<el-button type="primary" size="small" :loading="this.saving" @click="submitSuggestion" :disabled="submitButtonDisabled">提交</el-button>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
	</div>
</template>

<script>
import { uploadPicture, downloadPicture, deletePicture,getSuggestion,addSuggestion } from '@/api/sys/suggestionBox';
import Pdf from 'vue-pdf';
export default {
	name: 'suggestionBox',
	 components: {
		Pdf,
	},
	data() {
	
		return {
			suggestionBox: {
				suggestion: '',
				uploadPictures: [],
				title: '',
			},
			uploadFileList: [],
			fileList:[],
			pictureLimit: 5,

			pdfUrl: "",
			dialogPdfName: "",
			dialogImageUrl: '',
			dialogVisible: false,
			saving:false,
			submitButtonDisabled:false,

			display:true,
			loading:false,
			loadingText:'',
		};
		
	},
	props: {
		selectSuggestionId: Number,
		isNew: {type:Boolean,default:true},
	},
	mounted() {},

	created() {
		if(this.selectSuggestionId){
			this.display=false;
			let that = this;
		    getSuggestion(this.selectSuggestionId)
				.then((res) => {
					if (res) {
						let resData = res.data;
						let uploadPictures = resData.uploadPictures;
						that.suggestionBox.title = resData.title;
						that.suggestionBox.suggestion = resData.suggestion;
						uploadPictures.forEach(item=>{
							if(item.value != null && item.value.length > 0)
							{
								that.downloadAttachmentPictures(item.name,item.value);
							}
						});
					} else {
						this.msgError(`后端查找建议为空`);
					}
				})
				.catch((e) => {
					this.msgError(`后端查找建议出错 & ${e}`);
				});
		}
		
		
	},
	methods: {
		submitSuggestion() {
			// save data to DB
			this.saving=true;
			let that = this;
			this.suggestionBox.uploadPictures=this.uploadFileList;
			addSuggestion(this.suggestionBox)
				.then((response) => {
					that.saving=false;
					if (response.success) {
						that.submitButtonDisabled = true;
						that.msgSuccess('提交成功');
					} else {
						that.msgError('提交失败,' + response.msg);
					}
			});
		},

		uploadAttachmentPicture(params) {
			if (params != undefined) {
				this.formData = new FormData();
				this.formData.append('file', params.file);

				let self = this;
				this.loading = true;
				this.loadingText="上传中......";
				uploadPicture(this.formData)
					.then((response) => {
						self.loading = false;
						if (response.code == 200) {
							let serverFilePath = response.data;
							self.uploadFileList.push({ name: params.file.name, value: serverFilePath });
							self.$message.success('上传成功!');
						} else {
							this.$message.error('上传失败：');
						}
					})
					.catch((err) => {
						this.$message.error('上传失败：' + err);
						this.loading=false;
					});
			}
		},

		handleAvatarSuccess(res, file) {
			//this.form.photo = URL.createObjectURL(file.name);
		},
		beforeAvatarUpload(file) {
			const isJPG = file.type === 'image/jpeg' || file.type === 'image/jpg';
			const isPng = file.type === 'image/png';
			const isBmp = file.type === 'application/bmp';
			const ispdf = file.type === 'application/pdf';
			const isLt50M = file.size / 1024 / 1024 < 50;
			if (!isJPG && !isPng && !ispdf && !isBmp) {
				this.$message.error('上传图片只能是 JPG,JPEG,PNG,BMP,PDF 格式!');
				file.status = 'fail';
			}
			if (!isLt50M) {
				this.$message.error('上传图片大小不能超过 50MB!');
				file.status = 'fail';
			}
			return (isJPG || isPng || ispdf || isBmp) && isLt50M;
		},
		downloadAttachmentPictures(fileName,filePath) {
			let that = this;
			this.loading = true;
			this.loadingText="下载中......";
			downloadPicture(filePath)
				.then((res) => {
					that.loading = false;
					if (res) {
						let resData = res.data;
						var blob = new Blob([resData], { type: 'application/octet-stream' });
						// currentComponent.certificateUrl =URL.createObjectURL(blob);
						let blobUrl = URL.createObjectURL(blob);
						that.fileList.push({ name: fileName, url: blobUrl });
						that.uploadFileList.push({ name: fileName, value: filePath });
					} else {
						that.msgError(`后端下载文件出错`);
					}
				})
				.catch((e) => {
					this.msgError(`后端下载文件出错 & ${e}`);
					this.loading = false;
				});
					
		},
		handleExceed(files, fileList) {
			this.msgError(
				`当前限制选择 ${this.pictureLimit} 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件.`
			);
		},
		handleRemove(file) {
			if (file && file.raw && file.raw.status == 'fail') {
				return;
			}
			let that = this;
			this.$confirm('确定移除吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
			.then(() => {
				let filePath = '';

				for (let iter = 0; iter < that.uploadFileList.length; iter++) {
					let index = that.uploadFileList.findIndex((item) => {
						return item.name == file.name;
					});
					if (index > -1) {
						filePath = that.uploadFileList[index].value;
						that.uploadFileList.splice(index, 1);
							
					}

					index = this.$refs.elUpload.uploadFiles.findIndex(e => e.uid === file.uid);
					if(index > -1){
						this.$refs.elUpload.uploadFiles.splice(index, 1);
					}
				}

				if (filePath == '') {
					that.$message.error('前端没有找到删除的文件！');
					return;
				}

				deletePicture(filePath)
					.then((response) => {
						if (response.success) {
							that.$message.success('删除成功!');
						} else {
							that.$message.error('后台删除失败：' + response.message);
						}
					})
					.catch((err) => {
						that.$message.error('后台删除失败：' + err);
					});
			})
			.catch(() => {
				that.msgError('删除失败!');
			});
		},
		// handlePictureCardPreview(file) {
		// 	this.dialogImageUrl = file;
		// 	this.dialogVisible = true;
		// },
		handlePictureCardPreview(file) {
			if (file.name.endsWith('.pdf')) {
				this.pdfUrl = file.url;
				this.dialogPdfName = file.name;
				this.dialogImageUrl = '';
			} else {
				this.dialogImageUrl = file.url;
				this.dialogPdfName = '';
				this.pdfUrl = '';
			}

			this.dialogVisible = true;
		},
		closeDialog() {
			this.dialogImageUrl = '';
			this.dialogVisible = false;
		},
		
	},
};
</script>

<style lang="scss" scoped>

</style>