<template>
	<div>
		<div>
			<!-- 增加，合同，保密协议，培训协议等 -->
			<el-form class="employ" label-width="100px">
				<el-row>
					<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
						<el-form-item label="员工号码">
							<el-input v-model="workNum" readonly="true"></el-input>
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
						<el-form-item label="员工姓名">
							<el-input v-model="employeeName" readonly="true"></el-input>
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
						<el-form-item label="公司名称">
							<el-input v-model="company" readonly="true"></el-input>
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
						<el-form-item label="部门名称">
							<el-input v-model="department" readonly="true"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
		</div>

		<!-- tab, 表格,显示所有的合同，保密协议，培训协议等 -->
		<div>
			<el-row :gutter="10" class="mb8">
				<el-col :span="1.5">
					<el-button type="primary" plain icon="el-icon-plus" size="mini" @click="handleAdd">新增 </el-button>
				</el-col>
				<el-col :span="1.5">
					<el-button
						type="success"
						plain
						icon="el-icon-edit"
						size="mini"
						:disabled="!single"
						@click="handleUpdate"
						v-permission="['/employ/employ/edit']"
						>修改
					</el-button>
				</el-col>
				<el-col :span="1.5">
					<el-button
						type="danger"
						plain
						icon="el-icon-delete"
						size="mini"
						:disabled="!multiple"
						@click="handleDelete"
						v-permission="['/employ/employ/remove']"
						>删除
					</el-button>
				</el-col>
				<!-- <el-col :span="1.5">
					<el-button
						type="warning"
						plain
						icon="el-icon-download"
						size="mini"
						:disabled="!single"
						@click="handleExport"
						v-permission="['/employ/employ/export']"
						>导出
					</el-button>
				</el-col> -->
				<!-- <right-toolbar :showSearch.sync="showSearch" @queryTable="contractList"></right-toolbar> -->
			</el-row>
			<el-table v-loading="loading" :data="contractList" @selection-change="handleSelectionChange" stripe border>
				<el-table-column type="selection" width="55" align="center" />
				<el-table-column label="合同编号" align="center" prop="contractId" sortable >
					<template slot-scope="scope" >
						<el-button size="mini" type="text"
							@click="handleView(scope.row)">
							{{ scope.row.contractId }}
						</el-button>
					</template>
				</el-table-column>
				<el-table-column label="起始日期" align="center" prop="contractStart"  sortable />
				<el-table-column label="终止日期" align="center" prop="contractEnd" sortable />
				<el-table-column label="类型" align="center" prop="contractType" width="80%" sortable/>
				<el-table-column label="状态" align="center" prop="contractStatus" width="80%"  sortable />
				<el-table-column label="建立日期" align="center" prop="createdDate" sortable />
				<el-table-column label="最近更新日期" align="center" prop="latestUpdateDate" sortable />
			</el-table>
		</div>
		<el-dialog :title="dialogTitle" :visible.sync="dialogVisible" v-if="dialogVisible" :width="editDialogWidth" :close-on-click-modal="false">
			<contract-edit
				:employeeId="id"
				:contractProp="selectContract"
				:isAddContract="isAddContract"
				:isUpdateContract="isUpdateContract"
				:isView="isView"
				@closedialog="close"
			>
			</contract-edit>
		</el-dialog>
	</div>
</template>

<script>
import { listEmploy, getEmploy, updateEmploy } from '@/api/employ/employ';
import { download,GetContractList,deleteContracts } from '@/api/employ/contractDocument';
import Pagination from '@/components/Pagination';
import contractEdit from './contractEdit.vue';
import permission from '@/directive/permission/index.js'; // 权限判断指令
import {Local} from '@/utils/storage.js'
export default {
	directives: { permission },
	components: { contractEdit },
	created() {
		// user router
		this.selectEmployeeId = this.$route.params.selectEmployeeId;
		if (this.selectEmployeeId != '' && this.selectEmployeeId != undefined) {
			this.load(this.selectEmployeeId);
		}
		this.uniApp=Local.get("isUniapp")?true:false;
		if(this.uniApp)
        {
            this.editDialogWidth = "90%";
        }
	},
	data() {
		return {
			contractList: [],
			workNum: '',
			company: '',
			department: '',
			id: '',
			employeeName:'',
			// 非单个禁用
			single: false,
			// 非多个禁用
			multiple: false,
			dialogTitle: '',
			dialogVisible: false,
			selectedEmployee: null,
			currentContractId: null,
			isView: false,
			isAddContract: false,
			isUpdateContract: false,
			loading: false,
			selectContract: null,
			selectedPdfName: '',
			contractStatuses:null,
			uniApp:false,
			editDialogWidth:"60%",
		};
	},

	methods: {
		load(employeeId) {
		
			// getEmploy(this.selectEmployeeId).then((response) => {
			// 	this.selectedEmployee = response.data;
			// 	this.contractList = this.selectedEmployee.contracts;
			// 	this.company = this.selectedEmployee.companyId;
			// 	this.department = this.selectedEmployee.departmentId;
			// 	this.workNum = this.selectedEmployee.workNum;
			// 	this.id = this.selectedEmployee.id;
			// });

			GetContractList(this.selectEmployeeId).then((response) => {
				this.contractList = response.data.contracts;
				this.company = response.data.companyName;
				this.department = response.data.departmentName;
				this.workNum = response.data.workNum;
				this.id = response.data.id;
				this.employeeName = response.data.employeeName;
			}).catch(err=>this.msgError(err));
			
		},

		/** 新增按钮操作 */
		handleAdd() {
			this.dialogTitle = '增加合同/协议';
			this.isAddContract = true;
			this.isUpdateContract = false;
			this.isView = false;
			this.selectedEmployee = null;
			this.dialogVisible = true;
			this.currentContractId = '';
		},

		/** 修改按钮操作 */
		handleUpdate(row) {
			this.currentContractId = this.ids.toString();
			getEmploy(this.id)
				.then((response) => {
					let self = this;
					let currentEmployee = response.data;
					if (currentEmployee.contracts != null && currentEmployee.contracts.length > 0) {
						self.selectContract = currentEmployee.contracts.find((p) => p.contractId == this.currentContractId);
					}
					this.dialogTitle = '修改合同/协议';
					this.dialogVisible = true;
					this.isAddContract = false;
					this.isUpdateContract = true;
					this.isView = false;
				})
				.catch((error) => {
					this.msgError('异常:' + error);
				});
		},
		handleView(row){
			getEmploy(this.id)
				.then((response) => {
					let self = this;
					let currentEmployee = response.data;
					if (currentEmployee.contracts != null && currentEmployee.contracts.length > 0) {
						self.selectContract = currentEmployee.contracts.find((p) => p.contractId == row.contractId);
					}
					this.dialogTitle = '查阅合同/协议';
					this.dialogVisible = true;
					this.isAddContract = false;
					this.isUpdateContract = false;
					this.isView = true;
				})
				.catch((error) => {
					this.msgError('异常:' + error);
				});
		},
		/** 删除按钮操作 */
		handleDelete() {
			this.$confirm('是否确认删除选中的员工合同数据?', '警告', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
						
					let employeeContractsForm = {};
					employeeContractsForm.employId = this.id;
					employeeContractsForm.contractNoList = this.ids;

					deleteContracts(employeeContractsForm).then((response) => {
						if (response.success) {
							this.contractList = response.data;
							this.msgSuccess('修改成功');
						} else {
							this.msgError('修改失败,' + response.msg);
						}
					});

				
				})
				
		},
		/** 导出按钮操作 */
		handleExport() {
			if (this.selectedPdfName.length > 0) {
				this.$confirm('是否确认导出该员工的合同?', '警告', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
				}).then((response) => {
					this.loading = true;
					download(this.id, this.ids.toString())
						.then((res) => {
							this.loading = false;
							if (res) {
								let resData = res.data;
								var blob = new Blob([resData], { type: 'application/octet-stream' });

								if ('download' in document.createElement('a')) {
									// 非IE下载
									const fileName = this.selectedPdfName;

									const elink = document.createElement('a');
									elink.download = fileName;
									elink.style.display = 'none';
									elink.href = URL.createObjectURL(blob);
									document.body.appendChild(elink);
									elink.click();
									URL.revokeObjectURL(elink.href); // 释放URL 对象
									document.body.removeChild(elink);
								} else {
									// IE10+下载
									navigator.msSaveBlob(blob, fileName);
								}
							}
						})
						.catch((e) => {
							this.loading = false;
							this.msgError(`后端下载文件出错 & ${e}`);
						});
				});
			} else {
				this.msgError('这份合同没有pdf文件或者没上传。');
			}
		},

		// 多选框选中数据
		handleSelectionChange(selection) {
			this.ids = selection.map((item) => item.contractId);
			this.single = selection.length == 1;
			this.multiple = selection.length >= 1;
			if (this.single) {
				let selectedContract = selection.find((item) => item.contractId == this.ids);
				if (selectedContract.contractPictures != null && selectedContract.contractPictures != undefined) {
					let foundContracPdf = selectedContract.contractPictures.find((item) => item.fileName.indexOf('.pdf') > 0);
					this.selectedPdfName = foundContracPdf != null && foundContracPdf != undefined ? foundContracPdf.fileName : '';
				}
			}
		},
		// 提交确认后关闭对话框
		close(arg) {
			this.dialogVisible = false;
			if (arg.refresh) {
				// refresh contracts
				this.load(this.id);
			}
		},
	},
};
</script>

<style>
</style>