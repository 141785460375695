import request from '@/utils/request'

// 查询用户人脸列表
export function listFaceRecognize(query) {
    return request({
        url: '/sys/faceRecognize/findAll',
        method: 'post',
        data: query
    })
}

// 查询用户人脸详细
export function getFaceRecognize(id) {
    return request({
        url: '/sys/faceRecognize/findOne?id=' + id,
        method: 'post'
    })
}

// 导出用户人脸
export function exportFaceRecognize(query) {
    return request({
        url: '/sys/faceRecognize/export',
        method: 'post',
        data: query
    })
}
