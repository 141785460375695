<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px">
      <el-form-item label="公司名称" prop="companyId">
        <treeselect v-model="queryParams.companyId" style="width: 198px"
                    :options="companyOptions"
                    :normalizer="normalizerCompany" placeholder="请选择所属公司"/>
      </el-form-item>
      <el-form-item label="姓名" prop="empName">
        <el-input
            v-model="queryParams.empName"
            placeholder="请输入姓名"
            clearable
            size="small"
            @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="审批状态" prop="status">
        <el-select v-model="queryParams.status">
          <el-option label="未上传" value="0"></el-option>
          <el-option label="通过" value="1"></el-option>
          <el-option label="未通过" value="2"></el-option>
          <el-option label="待审核" value="3"></el-option>
        </el-select>
        <!--        <el-input v-model="queryParams.name" placeholder="请输入班次名称" clearable  @keyup.enter.native="handleQuery" />-->
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>
    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button type="success" plain icon="el-icon-edit" size="mini" :disabled="single"
                   @click="handleApprove">批量通过</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button type="danger" plain icon="el-icon-delete" size="mini" :disabled="multiple"
                   @click="handleDisapprove">批量拒绝</el-button>
      </el-col>
      <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>
    </el-row>

    <el-table v-loading="loading" :data="userFaceList" @selection-change="handleSelectionChange" stripe border>
      <el-table-column type="selection" width="55" align="center"/>
      <el-table-column label="员工姓名" align="center" prop="empName"/>
      <el-table-column label="所属公司" align="center" prop="companyId" show-overflow-tooltip width="210" sortable>
        <template slot-scope="scope">
          {{companyFormat(scope.row, null)}}
        </template>
      </el-table-column>
      <el-table-column label="人脸相似度%" align="center" prop="facePercent">
        <template slot-scope="scope">{{scope.row.facePercent?scope.row.facePercent/10.0:""}}</template>
      </el-table-column>
      <el-table-column label="认证通过相似度%" align="center" prop="faceAllow">
        <template slot-scope="scope">{{scope.row.faceAllow?scope.row.faceAllow/10.0:""}}</template>
      </el-table-column>
      <el-table-column label="审批状态" align="center" prop="status">
        <template slot-scope="scope">
          <span v-if="scope.row.status===0" style="color: #f8ac59">未上传</span>
          <span v-else-if="scope.row.status===1" style="color: green">通过</span>
          <span v-else-if="scope.row.status===2" style="color: #d0033d">未通过</span>
          <span v-else-if="scope.row.status===3" style="color: #f3d720">待审批</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button size="mini" type="text" icon="el-icon-edit" @click="handleView(scope.row)">查看审批</el-button>
          <el-button size="mini" type="text" icon="el-icon-edit" @click="handleApprove(scope.row)">通过</el-button>
          <el-button size="mini" type="text" icon="el-icon-delete" @click="handleDisapprove(scope.row)">拒绝</el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
        v-show="total>0"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
    />

    <!-- 添加或修改班次管理对话框 -->
    <el-dialog title="审核照片" :visible.sync="open" append-to-body>
      <Edit :row.sync="row" @close="close"></Edit>
    </el-dialog>
  </div>
</template>

<script>
import {
  listUserFace,
  getUserFace,
  delUserFace,
  approvedById,
  disapprovedById,
  exportUserFace } from "@/api/sys/userFace";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import RightToolbar from "@/components/RightToolbar"
import permission from '@/directive/permission/index.js' // 权限判断指令
import Pagination from "@/components/Pagination";
import Edit from "./edit";
import {listCompany} from "@/api/org/company";

// 表查询配置
const dbOrderBy = ''

export default {
  name: "photoApprove",
  components: {
    Treeselect,RightToolbar,Pagination,Edit,
  },
  directives: { permission },
  data() {
    return {
      // 公司树选项
      companyList: [],
      companyOptions: [],
      row: null,
      // 遮罩层
      loading: true,
      // 查询参数
      where: {
        query: '',
        filters: []
      },
      clockRange: false,

      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 班次管理表格数据
      userFaceList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        orderby: dbOrderBy,
        status: null,
        companyId: null,
        empName: null,
      },
      // 表单参数
      form: {
        isRest: false,
      },

    };
  },
  created() {
    this.getTreeselectCompany();
    this._filter = {};
    this.getList();
  },
  methods: {
    /** 查询公司下拉树结构 */
    getTreeselectCompany() {
      const params = {
        pageNum: 1,
        pageSize: 500,
        where: null,
        order: ''
      }
      listCompany(params).then(response => {
        this.companyList = response.data.records;
        this.companyOptions = this.handleTree(response.data.records, "id", "parentId");
      }) ;
    },
    /** 查询班次管理列表 */
    getQuery(){
      this._filter = {};
      if(this.queryParams.companyId) {
        this._filter['companyId'] = {
          type: 'eq',
          value: this.queryParams.companyId,
        }
      }
      if(this.queryParams.empName) {
        this._filter['empName'] = {
          type: 'search',
          value: this.queryParams.empName,
        }
      }
      if(this.queryParams.status) {
        this._filter['status'] = {
          type: 'eq',
          value: this.queryParams.status,
        }
      }
      return this.getQueryParam(this._filter,this.queryParams.pageNum,this.queryParams.pageSize,this.orderby)
    },
    getList() {
      this.loading = true;
      const params = this.getQuery();
      listUserFace(params).then(response => {
        this.userFaceList = response.data.records;
        this.total = response.data.total;
        this.loading = false;
      });
    },
    handleView(row){
      this.row = row;
      this.open = true;
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {};
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },

    handleApprove(row) {
      let ids = [];
      if(row.id){
        ids = [row.id];
      }else{
        ids = this.ids;
      }
      this.$confirm('是否确认选中的照片全部审批通过?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return approvedById(ids);
      }).then(() => {
        this.getList();
        this.msgSuccess("审批完成");
      })

    },
    handleDisapprove(row) {
      let ids = [];
      if(row.id){
        ids = [row.id];
      }else{
        ids = this.ids;
      }
      this.$confirm('是否确认选中的照片全部审批不通过?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return disapprovedById(ids);
      }).then(() => {
        this.getList();
        this.msgSuccess("审批完成");
      })
    },

    close(arg){
      this.open = false;
      if(arg.refresh){
        this.getList();
      }
    },

    /** 转换公司数据结构 */
    normalizerCompany(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.id,
        label: node.companyName,
        children: node.children
      };
    },
    companyFormat(row, column) {
      // console.log(row);
      for(var i = 0; i < this.companyList.length; i++){
        const item =  this.companyList[i];
        if(item.id == row.companyId){
          return item.companyName;
        }
      }
      return "";
    },
    getCompanyCode(companyId){
      // console.log(companyId)
      // console.log(this.companyList)
      for(var i = 0; i < this.companyList.length; i++){
        const item =  this.companyList[i];
        if(item.id == companyId){
          return item.code;
        }
      }
    },

    /** 导出按钮操作 */
    handleExport() {
      const params = this.getQuery();
      this.$confirm('是否确认导出所有班次管理数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return exportUserFace(params);
      }).then(response => {
        this.download(response.msg);
      })
    }
  }
};
</script>

<style scoped>


</style>
