<template>
	<div>
		<el-form :model="queryParameters" ref="queryParameters" class="employ" label-width="80px">
			<el-row :gutter="24">
				<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
					<el-form-item label="公司">
						<el-select v-model="companySelect" clearable @clear="handleClearable" placeholder="请选择" collapse-tags ref="companySelected">
							<el-option :value="queryParameters.companyId" :label="companySelect" style="height: auto">
								<el-tree
									:data="companyOptions"
									:props="defaultProps"
									:expand-on-click-node="false"
									ref="tree"
									show-checkbox
									node-key="id"
									default-expand-all
									@node-click="handleNodeClick"
									@check="handleNodeClick"
									highlight-current
								/>
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
					<el-form-item label="起始时间">
						<el-date-picker v-model="queryParameters.startDate" value-format="yyyy-MM-dd" align="left" type="date" placeholder="选择日期">
						</el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
					<el-form-item label="终止时间">
						<el-date-picker v-model="queryParameters.endDate" value-format="yyyy-MM-dd" align="left" type="date" placeholder="选择日期">
						</el-date-picker>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<el-tabs type="border-card">
			<el-tab-pane label="培训详细记录">
				<el-form label-width="100px">
					<el-row>
						<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
							<el-form-item label="培训计划名称">
								<el-input v-model="traningName" placeholder="培训计划名称" clearable />
							</el-form-item>
						</el-col>
						<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
							<el-form-item label=" ">
								<el-button v-on:click="query" size="mini" icon="el-icon-search" plain type="primary">查询</el-button>
								<el-button v-on:click="exportTrainingDetail" :loading="downloadTrainingDetailLoading" size="mini" icon="el-icon-download" plain type="primary">导出</el-button>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
				<el-table v-loading="loading" :data="trainingList" style="width: 100%" max-height="100%" stripe border>
					<el-table-column label="编号ID" align="center" prop="id" v-if="false" />
					<el-table-column label="公司" align="center" prop="companyName" sortable min-width="100" :show-overflow-tooltip="true"> </el-table-column>
					<el-table-column label="员工姓名" align="center" prop="employeeName" sortable min-width="150" :show-overflow-tooltip="true" />
					<el-table-column label="培训名称" align="center" prop="trainingName" sortable min-width="100" :show-overflow-tooltip="true"> </el-table-column>
					<el-table-column label="培训类型" align="center" prop="trainingType" sortable  min-width="80" />
					<el-table-column label="培训开始时间" align="center" prop="createTime" min-width="150" sortable :show-overflow-tooltip="true" />
					<el-table-column label="培训结束时间" align="center" prop="updateTime" min-width="150" sortable :show-overflow-tooltip="true" />
					<el-table-column label="分数" align="center" prop="score"  min-width="100" sortable :show-overflow-tooltip="true" />
        			<el-table-column label="等级" align="center" prop="level"  min-width="80" sortable :show-overflow-tooltip="true" />	 
					<el-table-column label="评价" align="center" prop="comments" sortable min-width="100" :show-overflow-tooltip="true"/>
					<el-table-column label="五星数" align="center" prop="stars" sortable min-width="100" />
					<el-table-column label="是否完成" align="center" prop="completed" sortable min-width="100">
						<template slot-scope="scope">
							<span>{{ completeStatus(scope.row) }}</span>
						</template>
					</el-table-column>
					<el-table-column label="重测" align="center">
						<template slot-scope="scope">
							<el-button size="mini" type="text" icon="el-icon-edit" @click="retest(scope.row)"> </el-button>
						</template>
					</el-table-column> 
				</el-table>
				<pagination
					v-show="total > 0"
					:total="total"
					:page.sync="queryParameters.pageNum"
					:limit.sync="queryParameters.pageSize"
					@pagination="query"
				/>
			</el-tab-pane>
			<el-tab-pane label="统计">
				<div style="margin-bottom: 10px">
					<el-button v-on:click="calculateTrainingStatistics" size="mini" icon="el-icon-search" plain type="primary">统计</el-button>
					<el-button v-on:click="exportTrainingStatistics" size="mini" :loading="downloadStatisticLoading" icon="el-icon-download" plain type="primary">导出</el-button>
				</div>

				<el-table v-loading="statisticsLoading" :data="statisticsResults" style="width: 100%" max-height="100%" stripe border>
					<el-table-column label="公司" align="center" prop="companyName" sortable min-width="100" :show-overflow-tooltip="true"> </el-table-column>
					<el-table-column label="培训名称" align="center" prop="trainingName" sortable min-width="100" :show-overflow-tooltip="true"> </el-table-column>
					<el-table-column label="培训开始时间" align="center" prop="createdDate" min-width="150" sortable :show-overflow-tooltip="true">
						<template slot-scope="scope">
							<span>{{ formatDate(scope.row.createdDate) }}</span>
						</template>
					</el-table-column>
					<el-table-column label="培训结束时间" align="center" prop="endDate" min-width="150" sortable :show-overflow-tooltip="true">
						<template slot-scope="scope">
							<span>{{ formatDate(scope.row.endDate) }}</span>
						</template>
					</el-table-column>
					<el-table-column label="完成培训人数" align="center" prop="completedCount"  min-width="100" />
					<el-table-column label="未完成培训人数" align="center" prop="uncompletedCount"  min-width="120" />
					<el-table-column label="总评分" align="center" prop="grade" sortable min-width="100" />
				</el-table>
				<pagination
					v-show="statisticsResultsCount > 0"
					:total="statisticsResultsCount"
					:page.sync="queryParameters.pageNum"
					:limit.sync="queryParameters.pageSize"
					@pagination="calculateTrainingStatistics"
				/>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
import permission from '@/directive/permission/index.js'; // 权限判断指令
import { getTreeselectCompany } from '@/api/org/company';
import Pagination from '@/components/Pagination';
import { findAllTrainings, calculateTraining ,exportTrainingStatistics,exportTrainingDetail,deleteTraining} from '@/api/training/myTraining';
export default {
	name: 'trainingStatistics',
	components: {
		Pagination,
	},
	data() {
		return {
			trainingList: [],
			isCompnayVisible: false,
			companyList: [],
			total: 0,
			loading: false,
			companySelect: '',
			// 公司树选项
			companyOptions: [],
			defaultProps: {
				children: 'children',
				label: 'companyName',
			},
			queryParameters: {
				startDate: '',
				endDate: '',
				name: '',
				companyId: '',
				pageSize: 10,
				pageNum: 1,
			},

			where: {
				query: '',
				filters: [],
			},
			companyId: '',
			traningName: '',
			statisticsLoading: false,
			statisticsResults: [],
			statisticsResultsCount: 0,
			downloadStatisticLoading:false,
			downloadTrainingDetailLoading:false,
		};
	},
	created() {
		this.load();
		//this.query();
	},
	mounted() {},

	methods: {
		load() {
			getTreeselectCompany(null, (list, options) => {
				this.companyList = list;
				if (this.companyList.length > 1) {
					this.isCompnayVisible = true;
				}
				this.companyOptions = options;
			});
		},
		// 节点单击事件
		handleNodeClick(data) {
			this.queryParameters.companyId = data.id;
			this.companyId = data.id;
			this.companySelect = data.companyName;
			let selectArray = [];
			selectArray.push(this.companyId);
			this.$refs.tree.setCheckedKeys(selectArray);

			this.$refs.companySelected.blur();
		},
		handleClearable() {
			this.$refs.tree.setCheckedKeys([]);
			this.companyId = null;
			this.companySelect = '';
		},
		retest(row){
			
			let that = this;
			this.$confirm('确定要重新测试?', "警告", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			}).then(function () {
				let ids = [row.id];
				return deleteTraining(ids);
			}).then(() => {
				that.query();
				that.msgSuccess("删除成功");
			})
		},
		generateQueryParams(detail) {
			let filter = {};
			if (this.companyId) {
				filter['companyId'] = {
					type: 'eq',
					value: this.companyId,
				};
			}
			if (this.queryParameters.id) {
				filter['trainingId'] = {
					type: 'eq',
					value: this.queryParameters.id,
				};
			}
			if (detail) {
				if (this.traningName) {
					filter['trainingName'] = {
						type: 'search',
						value: this.traningName,
					};
				}
			}

			if (this.queryParameters.startDate && this.queryParameters.endDate) {
				let tempDate = this.queryParameters.startDate.replace(/-/g, '/'); //必须把日期'-'转为'/'
				var startDateTimeStamp = new Date(tempDate).getTime();
				tempDate = this.queryParameters.endDate.replace(/-/g, '/');
				var endDateTimeStamp = new Date(tempDate).getTime();
				filter['createdDate'] = {
					type: 'timestamp',
					value: [startDateTimeStamp, endDateTimeStamp],
				};
			}

			const filters = this.parseFilters(filter);
			if (filters && filters.length > 0) {
				this.where.filters = filters;
			} else {
				// 如果过滤条件为空，设置空数组
				this.where.filters = [];
			}
			const params = {
				pageNum: this.queryParameters.pageNum,
				pageSize: this.queryParameters.pageSize,
				where: this.where,
				order: '',
			};
			return params;
		},
		query() {
			let params = this.generateQueryParams(1);
			this.loading = true;
			findAllTrainings(params)
				.then((response) => {
					this.trainingList = response.data.records;
					this.total = response.data.total;
					this.loading = false;
				})
				.catch((err) => {
					this.msgError('查询失败，' + err);
				});
		},
		calculateTrainingStatistics() {
			let params = this.generateQueryParams(0);
			this.statisticsLoading = true;
			calculateTraining(params)
				.then((response) => {
					this.statisticsResults = response.data.records;
					this.statisticsResultsCount = response.data.total;
					this.statisticsLoading = false;
				})
				.catch((err) => {
					this.msgError('统计失败，' + err);
				});
		},
		completeStatus(row) {
			if (row.completed) {
				return '是';
			} else {
				return '否';
			}
		},
		formatDate(dateStr) {
			if (dateStr != '' && dateStr.length > 0) {
				let dotIndex = dateStr.indexOf('.');
				if (dotIndex > -1) {
					dateStr = dateStr.substring(0, dotIndex);
					dateStr = dateStr.replace('T', ' ');
				}
				return dateStr;
			} else {
				return dateStr;
			}
		},
		exportTrainingStatistics() {
			const params = this.generateQueryParams(0);
			let that = this;
			this.$confirm('是否确认导出所有员工培训签到统计信息?', '警告', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(function () {
					that.downloadStatisticLoading = true;
					return exportTrainingStatistics(params);
				})
				.then((result) => {
					that.downloadStatisticLoading=false;
					if (result) {
						let blob = new Blob([result.data], {
							type: result.data.type,
						});
						let filename = (result.headers.filename && decodeURI(result.headers.filename)) || '培训统计表.xlsx';
						if ('download' in document.createElement('a')) {
							const link = document.createElement('a');
							link.style.display = 'none';
							link.href = URL.createObjectURL(blob);
							link.setAttribute('download', filename);
							document.body.appendChild(link);
							link.click();
							document.body.removeChild(link);
						} else {
							navigator.msSaveBlob(blob, filename);
						}
					}
				})
				.catch((e) => {
					that.downloadStatisticLoading=false;
					that.msgError(`后端下载文件出错 & ${e}`);
				});
		},
		exportTrainingDetail() {
			const params = this.generateQueryParams(1);
			let that = this;
			this.$confirm('是否确认导出所有员工培训信息?', '警告', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(function () {
					that.downloadTrainingDetailLoading = true;
					return exportTrainingDetail(params);
				})
				.then((result) => {
					that.downloadTrainingDetailLoading=false;
					if (result) {
						let blob = new Blob([result.data], {
							type: result.data.type,
						});
						let filename = (result.headers.filename && decodeURI(result.headers.filename)) || '培训签到表.xlsx';
						if ('download' in document.createElement('a')) {
							const link = document.createElement('a');
							link.style.display = 'none';
							link.href = URL.createObjectURL(blob);
							link.setAttribute('download', filename);
							document.body.appendChild(link);
							link.click();
							document.body.removeChild(link);
						} else {
							navigator.msSaveBlob(blob, filename);
						}
					}
				})
				.catch((e) => {
					that.downloadTrainingDetailLoading=false;
					that.msgError(`后端下载文件出错 & ${e}`);
				});
		},
	},
};
</script>

<style lang="scss" scoped>
</style>