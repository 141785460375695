<template>
  <div class="login">
    <template v-if="model=='login'" ref="login">
      <div class="login-weaper">
        <div class="login-left">
          <div class="login-time">{{ time.txt }}</div>
          <div class="login-left-box">
            <div>
              <div class="logo">{{ getThemeConfig.globalViceTitle }}</div>
              <h2 class="title">{{ getGlobalTitle }}</h2>
              <div class="msg">
                <div class="msg-author">
                  <span>{{ quotations.name }}</span>
                  <span>{{ quotations.comeFrom }}</span>
                </div>
                <div class="msg-txt">{{ quotations.content }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="login-right">
          <div class="login-main">
            <h4 class="login-title">{{ getGlobalTitle }}</h4>
            <el-form class="el-form login-form" ref="loginForm" :model="loginForm" :rules="loginRules">
              <el-form-item style="margin-left: 0px" prop="loginType">
                <el-radio-group tabindex="1" v-model="loginForm.loginType" v-on:keyup.enter.native="goToUserNameInput">
                  <el-radio label="mobile">手机号登录</el-radio>
                  <el-radio label="username">账号登陆</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item style="margin-left: 0px" prop="userName">
                <el-input
                    type="text"
                    ref="userName"
                    :placeholder="'mobile'==loginForm.loginType? '请输入手机号':'请输入用户名'"
                    prefix-icon="el-icon-user"
                    v-model="loginForm.userName"
                    tabindex="2"
                    clearable
                    autocomplete="on"
                    autofocus
                    v-on:keyup.enter.native="goToNextInput"
                >
                </el-input>
              </el-form-item>
              <el-tooltip v-model="capsTooltip" content="Caps lock is On" placement="right" manual>
                <el-form-item style="margin-left: 0px" prop="password">
                  <el-input
                      type="password"
                      ref="password"
                      :placeholder="$t('message.login.placeholder2')"
                      prefix-icon="el-icon-lock"
                      v-model="loginForm.password"
                      autocomplete="off"
                      @keyup.native="checkCapslock"
                      @keyup.enter.native="submitForm"
                      @blur="capsTooltip = false"
                      :show-password="true"
                  >
                  </el-input>
                </el-form-item>
              </el-tooltip>
              <!--						<el-form-item style="margin-left: 0px" prop="code">-->
              <!--							<div class="el-row" span="24">-->
              <!--								<div class="el-col el-col-16">-->
              <!--									<el-input-->
              <!--										type="text"-->
              <!--										maxlength="4"-->
              <!--										:placeholder="$t('message.login.placeholder3')"-->
              <!--										prefix-icon="el-icon-position"-->
              <!--										v-model="loginForm.code"-->
              <!--										clearable-->
              <!--										autocomplete="off"-->
              <!--									></el-input>-->
              <!--								</div>-->
              <!--								<div class="el-col el-col-8">-->
              <!--									<div class="login-code">-->
              <!--										<span class="login-code-img">1234</span>-->
              <!--									</div>-->
              <!--								</div>-->
              <!--							</div>-->
              <!--						</el-form-item>-->

              <el-form-item style="margin: 40px 0px 0">
                <el-button type="primary" class="login-submit" @click="submitForm" :loading="submit.loading">
                  <span>{{ $t('message.login.btnText') }}</span>
                </el-button>
              </el-form-item>
            </el-form>

            <div class="login-menu">
              <a href="javascript:;" @click="register">注 册</a>
              <a href="javascript:;" @click="recruit">招 聘</a>
              <a href="/static/JtEhr.apk" >APP 下载</a>
              <!--						<a href="javascript:;">{{ $t('message.login.link.one1') }}</a>-->
              <!--						<a href="javascript:;">{{ $t('message.login.link.one2') }}</a>-->
              <img style="width:60px;height:60px;" src="/static/download2D.png">
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="model=='register'" ref="login">
          <div class="register" style="width:100%;">
<!--            <el-row>-->
<!--              <el-col :span="24">-->
<!--                {{ getThemeConfig.globalTitle }}-->
<!--              </el-col>-->
<!--            </el-row>-->
            <el-row gutter="35">
              <el-col >
                <register :isWeb="true" @close="registerClose" ></register>
              </el-col>
            </el-row>
          </div>
    </template>
    <template v-else-if="model=='recruit'">
      <div class="recruitDialog" style="width:100%;">
        <recruit-list :isWeb="true" @close="recruitClose" ></recruit-list>
      </div>
    </template>
    <div class="vue-particles">
<!--      <vue-particles color="#dedede" shapeType="star" linesColor="#dedede" hoverMode="grab" clickMode="push"-->
<!--                     style="height: 100%"></vue-particles>-->
    </div>
   
  </div>
</template>
<script>
import {verifyAccount} from '@/utils/validate';
import Register from './register';
// import { Session } from '@/utils/storage';
import {formatDate, formatAxis} from '@/utils/formatTime';
import {PrevLoading} from '@/utils/loading.js';
import {quotationsList} from './mock';
import store from "../../store";
import RecruitList from '../pages/recruit/recruitList.vue';
import { Local } from '@/utils/storage.js';
// import { listAllCompany } from '@/api/org/company';

export default {
  name: 'login',
  components: {
    Register,
    RecruitList,
  },
  data() {
    const validateUsername = (rule, value, callback) => {
      // verifyAccount(rule,value,callback);
      // if (!validUsername(value)) {
      //   callback(new Error('请输入正确的用户名'));
      // } else {
      //   callback()
      // }
      callback();
    }
    const validatePassword = (rule, value, callback) => {
      // if (value.length < 6) {
      //   callback(new Error('The password can not be less than 6 digits'))
      // } else {
      //   callback()
      // }
      callback()
    }
    return {
      quotationsList,
      quotations: {},
      isView: false,
      submit: {
        loading: false,
      },
      loginForm: {
        loginType: 'mobile',
        userName: '',
        password: '',
        code: '',
      },
      loginRules: {
        userName: [{required: true, trigger: 'blur', validator: validateUsername}],
        password: [{required: true, trigger: 'blur', validator: validatePassword}]
      },
      passwordType: 'password',
      capsTooltip: false,
      // loading: false,
      showDialog: false,
      redirect: undefined,
      otherQuery: {},
      time: {
        txt: '',
        fun: null,
      },
      model: 'login',
      // title: '',
    };
  },
  computed: {
    // 获取当前时间
    currentTime() {
      return formatAxis(new Date());
    },
    // 获取布局配置信息
    getThemeConfig() {
      return this.$store.state.themeConfig.themeConfig;
    },
    getGlobalTitle() {
      return this.$store.state.themeConfig.globalTitle;
    },
  },
  created() {

    // let fullUrl = window.location.href;
    // console.log("fullUrl="); // 输出完整的URL
    // console.log(fullUrl); // 输出完整的URL
    // let suburl = fullUrl.substring(fullUrl.indexOf("://")+3,fullUrl.length);
    // console.log(suburl); // 输出完整的URL
    // let subDomain = suburl.substring(0,suburl.indexOf("/"));
    // console.log(subDomain); // 输出完整的URL
    // let _filter = {};
    // _filter['website'] = {
    //   type: 'rlike',
    //   value: subDomain,
    // }
    // const filters = this.parseFilters(_filter);
    // let where = {filters:[]};
    // if (filters && filters.length > 0) {
    //   where.filters = filters
    // } else {
    //   // 如果过滤条件为空，设置空数组
    //   where.filters = []
    // }
    // const params = {
    //   pageNum: 1,
    //   pageSize: 1000,
    //   where: where,
    //   order: this.orderby,
    // };
    // let _this = this;
    // listAllCompany(params).then((response) => {
    //   if(response.data.records && response.data.records.length>0){
    //     let company = response.data.records[0];
    //     _this.title = company.companyName+"信息化管理系统";
    //   }else{
    //     _this.title = '集团信息化管理系统';
    //   }
    // });

    this.initTime();
    console.log(" login page =  this.$route.query.token")
    let token = this.$route.query.token;
    console.log(this.$route.query.token)
    let pages = this.$route.query.pages
    let uniApp=Local.get("isUniapp")?true:false;
    if(uniApp){
      console.log("uniapp already login. direct route to page "+pages)
      console.log("uni.postMessageuni.postMessageuni.postMessageuni.postMessageuni.postMessageuni.postMessageuni.postMessage")
      this.$router.push(pages);
      setTimeout(function (){
        console.log("uni.postMessageuni.postMessageuni.postMessageuni.postMessageuni.postMessageuni.postMessageuni.postMessage")
        uni.postMessage({
          data: { uniAppReady: true}
        })
      },200)
      // uni.postMessage({
      //   data: { uniAppReady: true}
      // })
    }else if(token){
      this.submitToken(token,pages);
    }
  },
  mounted() {
    this.initRandomQuotations();
  },
  methods: {
    goToUserNameInput(e){
      this.$refs.userName.$el.querySelector('input').focus();
    },
    goToNextInput(e){
      this.$refs.password.$el.querySelector('input').focus();
    },
    checkCapslock(e) {
      const {key} = e
      this.capsTooltip = key && key.length === 1 && (key >= 'A' && key <= 'Z')
    },
    // 随机语录
    initRandomQuotations() {
      this.quotations = this.quotationsList[Math.floor(Math.random() * this.quotationsList.length)];
    },
    // 初始化左上角时间显示
    initTime() {
      this.time.txt = formatDate(new Date(), 'YYYY-mm-dd HH:MM:SS WWW QQQQ');
      this.time.fun = setInterval(() => {
        this.time.txt = formatDate(new Date(), 'YYYY-mm-dd HH:MM:SS WWW QQQQ');
      }, 1000);
    },
    register() {
      // window.location.href = "/views/pages/system/user/register";
      // this.$router.replace("/register");
      this.model = 'register';
    },
     recruit() {
      this.model = 'recruit';
    },
    registerClose(){
      this.model = 'login';
    },

  recruitClose(){
      this.model = 'login';
    },

    submitToken(token,pages){
      console.log("submitToken")
      console.log(pages)
      this.$store.dispatch('user/uLoginToken', token).then(() => {
        console.log("aaaaaaaaaaaaaaaaa*****************&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&")
        // console.log(this.redirect)
        console.log(this.redirect)
        console.log(this.otherQuery)
        console.log("aaaaaaaaaaaaaaaaa*****************&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&")
        PrevLoading.start();
        this.$router.push(pages);
        setTimeout(function (){
          console.log("uni.postMessageuni.postMessageuni.postMessageuni.postMessageuni.postMessageuni.postMessageuni.postMessage")
          uni.postMessage({
            data: { uniAppReady: true}
          })
        },200)
        // window.location.href = `${window.location.origin}${window.location.pathname}`;
        // window.location.href = `${window.location.origin}${pages}`;
        // this.$store.dispatch('user/getInfo').then(()=>{
        //
        //   PrevLoading.start();
        //   this.$router.push(pages);
        // })
        // window.location.href = `${window.location.origin}${window.location.pathname}`;
        // window.location.href = `${window.location.origin}${window.location.pathname}`;
      }).catch(() => {
        this.loading = false;
        this.submit.loading = false
      })
    },
    // 登录按钮点击
    submitForm() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.submit.loading = true
          this.$store.dispatch('user/login', this.loginForm)
              .then(() => {
                console.log("aaaaaaaaaaaaaaaaa*****************&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&")
                console.log(this.redirect)
                console.log(this.otherQuery)
                // let toHome = {
                //   "path": this.redirect || '/',
                //   "name": "/",
                //   "redirect": "/home",
                //   "component": "layout/index",
                //   query: this.otherQuery
                // };
                // this.$router.push(toHome);
                // this.loading = false
                PrevLoading.start();
                window.location.href = `${window.location.origin}${window.location.pathname}`;
                // window.location.href = `${window.location.origin}${window.location.pathname}`;
              })
              .catch(() => {
                this.loading = false;
                this.submit.loading = false
              })
        } else {
          this.loading = false;
          this.submit.loading = false;
          // console.log('error submit!!')
          return false
        }
      })

      // let defaultAuthPageList = [];
      // let defaultAuthBtnList = [];
      // // admin 页面权限标识，对应路由 meta.auth
      // let adminAuthPageList = ['admin'];
      // // admin 按钮权限标识
      // let adminAuthBtnList = ['btn.add', 'btn.del', 'btn.edit', 'btn.link'];
      // // test 页面权限标识，对应路由 meta.auth
      // let testAuthPageList = ['test'];
      // // test 按钮权限标识
      // let testAuthBtnList = ['btn.add', 'btn.link'];
      // if (this.loginForm.userName === 'admin') {
      // 	defaultAuthPageList = adminAuthPageList;
      // 	defaultAuthBtnList = adminAuthBtnList;
      // } else {
      // 	defaultAuthPageList = testAuthPageList;
      // 	defaultAuthBtnList = testAuthBtnList;
      // }
      // const userInfos = {
      // 	userName: this.loginForm.userName === 'admin' ? 'admin' : 'test',
      // 	photo:
      // 		this.loginForm.userName === 'admin'
      // 			? 'https://ss0.bdstatic.com/70cFvHSh_Q1YnxGkpoWK1HF6hhy/it/u=1813762643,1914315241&fm=26&gp=0.jpg'
      // 			: 'https://ss1.bdstatic.com/70cFuXSh_Q1YnxGkpoWK1HF6hhy/it/u=317673774,2961727727&fm=26&gp=0.jpg',
      // 	time: new Date().getTime(),
      // 	authPageList: defaultAuthPageList,
      // 	authBtnList: defaultAuthBtnList,
      // };
      // // 存储 token 到浏览器缓存
      // Session.set('token', Math.random().toString(36).substr(0));
      // // 存储用户信息到浏览器缓存
      // Session.set('userInfo', userInfos);
      // // 存储用户信息到vuex
      // this.$store.dispatch('userInfos/setUserInfos', userInfos);
      // PrevLoading.start();
      // window.location.href = `${window.location.origin}${window.location.pathname}`;
      // setTimeout(() => {
      // 	this.$message.success(`${this.currentTime}，${this.$t('message.login.signInText')}`);
      // }, 300);
    },
    
  },
  destroyed() {
    clearInterval(this.time.fun);
  },
  
};
</script>

<style scoped lang="scss">

.register{
  //margin: auto;
  margin-top: auto;
  //margin-bottom: 30px;
  margin-left: 30px;
  margin-right: 30px;
  height: 90%;
  overflow-x: hidden;
  //overflow-y: auto;
  //display: flex;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
  border: none;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

}
.recruitDialog{
  margin-top: auto;
  margin-left: 30px;
  margin-right: 30px;
  height: 98%;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
  border: none;
}
.login {
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: flex;
  position: relative;

  .vue-particles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(ellipse at top left, rgba(105, 155, 200, 1) 0%, rgba(181, 197, 216, 1) 57%);
  }

  .login-weaper {
    margin: auto;
    height: 500px;
    display: flex;
    box-sizing: border-box;
    position: relative;
    z-index: 1;
    border: none;
    box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);

    .login-left {
      width: 491px;
      padding: 20px;
      font-size: 16px;
      color: #fff;
      position: relative;
      background-color: rgba(64, 158, 255, 0.8);
      display: flex;
      flex-direction: column;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;

      .login-time {
        width: 100%;
        color: #fff;
        opacity: 0.9;
        font-size: 14px;
        overflow: hidden;
      }

      .login-left-box {
        flex: 1;
        overflow: hidden;
        position: relative;
        z-index: 1;
        display: flex;
        align-items: center;
        padding: 80px 45px;

        .logo {
          font-size: 22px;
          margin-bottom: 15px;
        }

        .title {
          color: #fff;
          font-weight: 300;
          letter-spacing: 2px;
          font-size: 16px;
        }

        .msg {
          color: #fff;
          font-size: 13px;
          margin-top: 35px;

          .msg-author {
            opacity: 0.6;

            span:last-of-type {
              margin-left: 15px;
            }
          }

          .msg-txt {
            margin-top: 15px;
            line-height: 22px;
          }
        }
      }
    }

    .login-right {
      width: 491px;
      padding: 20px;
      position: relative;
      align-items: center;
      display: flex;
      background-color: rgba(255, 255, 255, 1);
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;

      .login-main {
        margin: 0 auto;
        width: 70%;

        .login-title {
          color: #333;
          margin-bottom: 40px;
          font-weight: 500;
          font-size: 22px;
          text-align: center;
          letter-spacing: 4px;
        }

        .login-form {
          margin: 10px 0;

          i {
            color: #333;
          }

          .el-form-item {
            margin-bottom: 20px !important;
            display: block !important;
            .login-code {
              display: flex;
              align-items: center;
              justify-content: space-around;
              margin: 0 0 0 10px;
              user-select: none;

              .login-code-img {
                margin-top: 2px;
                width: 100px;
                height: 38px;
                border: 1px solid #dcdfe6;
                color: #333;
                font-size: 14px;
                font-weight: 700;
                letter-spacing: 5px;
                line-height: 38px;
                text-indent: 5px;
                text-align: center;
                cursor: pointer;
                transition: all ease 0.2s;
                border-radius: 4px;

                &:hover {
                  border-color: #c0c4cc;
                  transition: all ease 0.2s;
                }
              }
            }

            .login-submit {
              width: 100%;
              height: 45px;
              letter-spacing: 2px;
              font-weight: 300;
            }
          }
        }

        .login-menu {
          margin-top: 30px;
          width: 100%;
          text-align: left;

          a {
            color: #999;
            font-size: 12px;
            margin: 0 8px;
            text-decoration: none;

            &:hover {
              color: #1e9fff;
              text-decoration: underline;
            }
          }
        }
        
      }
    }
  }
}
</style>
