<template>
<div style="margin-left: 28px">
  <div>
    审批人员：
  </div>
  <div>
    <el-timeline>
      <template v-if="approvesOrder">
      <el-timeline-item
          v-for="(activity, index) in approvesOrder"
          :key="index"
          :timestamp="activity">
        {{otherInfo?otherInfo[index+"Name"]? otherInfo[index+"Name"]:otherInfo[index] :""}}
      </el-timeline-item>
      </template>
      <template v-if="otherInfo && otherInfo.assigneeUserNames">
        <el-timeline-item
            v-for="(activity, index) in otherInfo.assigneeUserNames"
            :key="index"
            :timestamp="activity.name+'审批'">
          {{activity.name+'('+activity.username+')'}}
        </el-timeline-item>
      </template>
    </el-timeline>
  </div>
  <div v-if="otherInfo && otherInfo['ccName']">
    抄送：{{otherInfo['ccName']}}
  </div>


</div>
</template>

<script>
export default {
  name: "approves",
  props: {
    otherInfo: Object,
    approvesOrder: Object,
  },
  watch:{
    otherInfo(){
      this.load();
    },
    // approvesOrder(){
    //   this.load();
    // },
  },
  activated() {
    // this.tinymceFlag++;
  },
  data(){
    return {
    };
  },
  created() {
    this.load();
  },
  methods: {
    load(){

    },

  },

}
</script>

<style scoped>

</style>
