<template>
  <div>
<!--    <el-steps v-if="transferhisList && transferhisList.length > 0" direction="vertical">-->
<!--      <el-step v-for="item in transferhisList" :key="item.id" :title="item.transferDate"-->
<!--               :description="'从部门【'+item.outDepartmentName+'】，岗位【'+item.outPositionName+'】调出2'">-->
<!--        <template  slot-scope="scope">-->
<!--          <el-button @click="showDetail(scope.item.transferFormId)">查看申请情况</el-button>-->
<!--        </template>-->
<!--      </el-step>-->
<!--    </el-steps>-->
    <div v-if="transferhisList && transferhisList.length > 0">
      <h2>调 动 情 况</h2>
      <div class="radio">
        排序：
        <el-radio-group v-model="reverse">
          <el-radio :label="true">倒序</el-radio>
          <el-radio :label="false">正序</el-radio>
        </el-radio-group>
      </div>

      <el-timeline :reverse="reverse">
        <el-timeline-item
            v-for="(item, index) in transferhisList"
            :key="index"
            :timestamp="item.transferDate">
          {{'从部门【'+item.outDepartmentName+'】'}} {{item.outPositionName?'，岗位【'+item.outPositionName+'】':""}} 调出。
          <el-button v-if="item.transferFormId" type="text" @click="showDetail(item.transferFormId)">查看申请</el-button>
        </el-timeline-item>
      </el-timeline>

<!--      <ul>-->
<!--        <li v-for="item in transferhisList" :key="item.id">-->
<!--          <h3>{{item.transferDate}}</h3>-->
<!--          <span>-->
<!--            {{'从部门【'+item.outDepartmentName+'】，岗位【'+item.outPositionName+'】调出2'}}-->
<!--            <el-button @click=" viewDetail=true; businessKey = ''+item.transferFormId;">查看申请</el-button>-->
<!--          </span>-->
<!--&lt;!&ndash;          <el-popover&ndash;&gt;-->
<!--&lt;!&ndash;              placement="right"&ndash;&gt;-->
<!--&lt;!&ndash;              width="400"&ndash;&gt;-->
<!--&lt;!&ndash;              trigger="click">&ndash;&gt;-->
<!--&lt;!&ndash;            <TransferEdit type="view" :id="item.transferFormId" :isReadOnly="true" :isShowClose="true"></TransferEdit>&ndash;&gt;-->
<!--&lt;!&ndash;            <el-button slot="reference">查看申请</el-button>&ndash;&gt;-->
<!--&lt;!&ndash;          </el-popover>&ndash;&gt;-->
<!--&lt;!&ndash;          <template  slot-scope="scope">&ndash;&gt;-->
<!--&lt;!&ndash;            <h5>scope.item.transferDate</h5>&ndash;&gt;-->
<!--&lt;!&ndash;          </template>&ndash;&gt;-->
<!--        </li>-->
<!--      </ul>-->
    </div>
    <el-dialog
        width="50%"
        title="调动申请"
        :visible.sync="viewDetail"
        append-to-body>
      <TransferEdit type="view" :id="businessKey" :isReadOnly="true" :isShowClose="true" @close="close"></TransferEdit>
    </el-dialog>
<!--    <div v-if="viewDetail">-->
<!--    </div>-->
    <div v-if="!transferhisList || transferhisList.length <= 0"><h1>没有调动记录</h1></div>
  </div>
</template>

<script>
import {getTransferhisByUserId} from '@/api/bpm/transferHis';
import TransferEdit from '../transfer/transferEdit';

export default {
  name: "transferHisTrace",
  components: {
    TransferEdit,
  },
  props: {
    userId: Number,
  },
  watch:{
    userId(){
      this.load();
    },
  },
  activated() {
    // this.tinymceFlag++;
  },
  data(){
    return {
      reverse: true,
      transferhisList:[],
      viewDetail: false,
      businessKey: null,
    };
  },
  created() {
    this.load();
  },
  methods: {
    load(){
      getTransferhisByUserId(this.userId, (list)=>{
        console.log("viewTransferHis return:");
        console.log(list);
        this.transferhisList = list;
      });
    },
    getDescription(item){
      return item.outDepartmentId;
    },
    showDetail(formId){
      console.log("showDetail")
      this.businessKey = ''+formId;
      this.viewDetail = true;
    },
    close(){
      this.viewDetail = false;
    },
  },
}
</script>

<style scoped>

</style>
