<template>
  <div>
<!--    <tree-transfer  :from_data='fromData' :to_data='toData' node_key="treeId" pid="treePid" :defaultProps="defaultProps"-->
<!--                    @addBtn='add' @removeBtn='remove' :mode='mode' height='540px' filter openAll>-->
<!--    </tree-transfer>-->
    <div slot="footer" class="dialog-footer" style="text-align: center">
      <el-button type="primary" @click="submitAdjust" >提 交</el-button>
      <el-button type="primary" @click="cancel">取 消</el-button>
      <el-popover
          placement="top"
          title="员工迁出步骤："
          width="200"
          trigger="click">
        <p>1.左侧选择框勾选”✔“要调整的员工（可多选，上方会列出待迁出员工）；</p><p>2.右侧选择需要迁入的部门（单选，选中后会变背景颜色，并且上方会显示迁入部门）；</p>
        <p>3.点击中间右移按钮”>>“；</p><p>4.提示迁出信息，确定则迁出员工会移到右边对应部门</p>
        <p>5.如果中间觉得迁入部门操作有错误，可以再右侧选择框勾选”✔“需要还原的员工，点击”<<“按钮则还原到原来的部门</p>
        <p>6.最后点击”提交“按钮保存调整结果</p>
        <el-button type="text" round slot="reference">？</el-button>
      </el-popover>

    </div>
    <div style="text-align: center">
      <el-checkbox v-model="isTransfer">记录调动轨迹</el-checkbox>
      <span>&nbsp;&nbsp;</span>
      <el-date-picker ref="transferDate" :disabled="!isTransfer" v-model="transferDate" type="date"
                      value-format="yyyy-MM-dd"
                      placeholder="选择调动日期"/>
    </div>
    <el-row align="middle" justify="center">

      <el-col :xs="24" :sm="11" :md="11" :lg="11" :xl="11" class="mb0">
        <span>待迁移员工，迁出人员：</span><span style="color: #1890ff">{{checkedOutEmp?checkedOutEmp:"未选择迁出人员"}}</span>
        <el-tree
            :data="fromData"
            :props="defaultProps"
            node-key="treeId"
            :load="loadEmployFrom"
            lazy
            :expand-on-click-node="true"
            :filter-node-method="filterNodeFrom"
            ref="fromTree"
            show-checkbox
            @node-click="handleNodeClickFrom"
            @check-change="selectFromChange"
            class = "fromTree"
            highlight-current
        />
      </el-col>
      <el-col :xs="24" :sm="2" :md="2" :lg="2" :xl="2" class="mb0" align="middle">
        <div style="margin-top: 150px">
          <el-row>
            <el-button circle type="primary" icon="el-icon-d-arrow-right" @click="handleToRight"></el-button>
          </el-row>
          <el-row>
            <el-button circle type="primary" icon="el-icon-d-arrow-left" @click="handleToLeft"></el-button>
          </el-row>
        </div>
      </el-col>
      <el-col :xs="24" :sm="11" :md="11" :lg="11" :xl="11" class="mb0">
        <span>迁移目标部门，选中的迁入部门是：</span><span style="color: #1890ff">{{selectToNode?selectToNode.name:"未选择迁入部门"}}</span>
        <el-tree
            :data="toData"
            :props="defaultProps"
            node-key="treeId"
            :load="loadEmployTo"
            lazy
            :expand-on-click-node="true"
            :filter-node-method="filterNodeTo"
            ref="toTree"
            show-checkbox
            highlight-current
            @node-click="handleNodeClickTo"
            highlight-current
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import treeTransfer from 'el-tree-transfer'
import {getOrgTree, getOrgTreeWithData} from '../components/empOrgTree';
import { showLoading, hideLoading } from '@/utils/reqloading';
import { updateBatchEmploys,listSimpleEmploy,transferBatchEmploys} from '@/api/employ/employ';
import {listDepartment} from "@/api/org/department";

export default {
  name: "employAdjust",
  components: {
    // OrgTree,
    treeTransfer,
  },
  props: {
    changeId: Number,
  },
  data() {
    return {
      isTransfer: false,
      transferDate: null,
      fromData: [],
      toData: [],
      selectFromNode: null,
      selectToNode: null,
      checkedOutEmp: null,
      companyId: null,
      data: [],
      value: [],
      allEmploys:[],
      moveEmploys: [],
      defaultProps: {
        children: "children",
        label: "name",
        isLeaf: 'isLeaf',
      },
      timeout:  null
    };
  },
  created() {
  },
  mounted() {
    this.load();
  },
  watch:{
    changeId(val){
      this.load();
    },
  },

  methods: {
    load(){
      this.companyId = this.$store.getters.userInfo.companyId;
      // this.selectedCompanyId = this.$store.getters.userInfo.companyId;
      // showLoading();
      this.fromData = [{name: this.$store.getters.userInfo.companyName,
        className: 'company',
        treeId: 'company'+this.$store.getters.userInfo.companyId,
        id: this.$store.getters.userInfo.companyId,
      }];
      this.toData = [{name: this.$store.getters.userInfo.companyName,
        className: 'company',
        treeId: 'company'+this.$store.getters.userInfo.companyId,
        id: this.$store.getters.userInfo.companyId,
      }];
    //   getOrgTree(this.companyId, false, (tree, companyList, departmentList, employList )=>{
    //     this.fromData = tree;
    //     console.log(this.fromData);
    //
    //     this.allDepartmentOptions = [];
    //     const dataDepartment = { id:0,
    //       name:'顶级部门',
    //       children: []
    //     };
    //     dataDepartment.children = this.handleTree(departmentList, "id", "pid");
    //     this.allDepartmentOptions.push(dataDepartment);
    //
    //     this.companyOptions = [];
    //     this.companyOptions = this.handleTree(companyList, "id", "pid");
    //
    //     this.employList = employList;
    //     this.toData = getOrgTreeWithData(companyList,departmentList,null);
    //     hideLoading();
    //   });
    },
    loadEmployFrom(node, resolve){
      console.log(node);
      if(node && node.data){
        let data = node.data;
        console.log(data);
        if('company' == data.className){
          this.getDepartment(data.id,0,data.treeId,(list)=>{
            resolve(list);
          });
        }else if("department"  == data.className){
          this.getDepartmentAndEmploy(data.id,data.treeId,(list)=>{
            resolve(list);
          });
        }else{
          return resolve([]);
        }
      }
      // console.log(resolve);
    },
    getDepartment(companyId, pid, treePid, callback){
      let departmentList = null;
      let filter = {};
      filter['companyId'] = {
        type: 'eq',
        value: companyId,
      }
      filter['pid'] = {
        type: 'eq',
        value: pid,
      }
      const depParams = this.getQueryParam(filter, 1, 1000, ' id asc ');
      listDepartment(depParams).then(response => {
        departmentList = response.data.records;
        departmentList.forEach(item=> {
          item.className = item.classType
          item.treeId = item.classType+item.id;
          item.treePid = treePid;
          item.nodeContent = "管理岗："+(item.managerPostName || '')+"";
        });
        callback(departmentList);
      });

    },
    getDepartmentAndEmploy(parentDepartmentId,treePid, callback){
      let employList = null;
      let departmentList = null;
      let list = [];
      let filter = {};
      filter['departmentId'] = {
        type: 'eq',
        value: parentDepartmentId,
      }
      const empParams = this.getQueryParam(filter, 1, 5000, ' id asc ');
      let rtEmploy = listSimpleEmploy(empParams).then(response => {
        employList = response.data.records;
        return response;
      });
      filter = {};
      filter['pid'] = {
        type: 'eq',
        value: parentDepartmentId,
      }
      const depParams = this.getQueryParam(filter, 1, 1000, ' id asc ');
      let rtDepartment = listDepartment(depParams).then(response => {
        departmentList = response.data.records;
        return response;
      });
      Promise.all([rtDepartment,rtEmploy]).then(()=>{
        employList.forEach(item=> {
          item.className = 'employ';
          item.treeId = item.className+item.id;
          item.isLeaf = true;
          item.treePid = treePid;
          list.push(item);
        });
        departmentList.forEach(item=> {
          item.className = item.classType
          item.treeId = item.classType+item.id;
          item.treePid = treePid;
          list.push(item);
        });
        callback(list);
      });
    },
    loadEmployTo(node, resolve){
      if(node && node.data){
        let data = node.data;
        console.log(data);
        if('company' == data.className){
          this.getDepartment(data.id,0,data.treeId,(list)=>{
            resolve(list);
          });
        }else if("department"  == data.className){
          this.getDepartment(data.companyId,data.id,data.treeId,(list)=>{
            resolve(list);
          });
        }else{
          return resolve([]);
        }
      }
    },
    submitAdjust(){
      console.log(this.moveEmploys);
      if(!this.moveEmploys || this.moveEmploys.length <= 0){
        this.$alert("没有需要迁移的人员", '错误', {
          confirmButtonText: '确定',
        });
        return;
      }
      if(this.isTransfer && !this.transferDate){
        this.$alert("请选择调动日期，调动日期不能为空", '错误', {
          confirmButtonText: '确定',
        });
        this.$refs["transferDate"].blur();
        return;
      }
      let that = this;
      this.$confirm(this.isTransfer?'此次调动为真实会记录调动轨迹，调动日期是：'+this.transferDate:"此次调动为普通操作，不记录调动轨迹", "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        let updateEmploys = [];
        showLoading();
        if(that.isTransfer){
          that.moveEmploys.map( employ=>{
            // const updateEmploy = {id: employ.id, departmentId: employ.newDepartmentId, companyId: employ.newCompanyId};
            const updateEmploy = {id: employ.id, departmentId: employ.newDepartmentId, oldDepartmentId: employ.departmentId, userId:employ.userId, positionId:employ.positionId};
            updateEmploys.push(updateEmploy);

          });
          console.log("updateEmploys:");
          console.log(updateEmploys);
          transferBatchEmploys({transferDate:that.transferDate, employs:updateEmploys}).then(response => {
            that.msgSuccess("调动完成");
            that.$emit("submitAdjust", {data: updateEmploys});
            hideLoading();
          });
        }else{
          that.moveEmploys.map( employ=>{
            // const updateEmploy = {id: employ.id, departmentId: employ.newDepartmentId, companyId: employ.newCompanyId};
            const updateEmploy = {id: employ.id, departmentId: employ.newDepartmentId};
            updateEmploys.push(updateEmploy);

          });
          console.log("updateEmploys:");
          console.log(updateEmploys);
          updateBatchEmploys(updateEmploys).then(response => {
            that.msgSuccess("迁移完成");
            that.$emit("submitAdjust", {data: updateEmploys});
            hideLoading();
          });
        }
      });
    },
    cancel(){
      this.$emit("cancel");
    },
    loadNode(){

    },
    filterNodeFrom(){

    },
    handleNodeClickFrom(){
      console.log(this.moveEmploys);
    },
    selectFromChange(){
      let checkedFromNodes = this.$refs["fromTree"].getCheckedNodes();
      console.log(checkedFromNodes);
      let checkedFromEmploy = [];
      if(checkedFromNodes){
        checkedFromEmploy = checkedFromNodes.filter(item=> {return item.className=='employ'});
        console.log(checkedFromEmploy);
      }
      let employNames = '';
      checkedFromEmploy.map(employ => {
        employNames = employNames + ", " + employ.name
      });
      employNames = employNames.substr(1, employNames.length);
      this.checkedOutEmp = employNames;
    },
    filterNodeTo(data){
      this.selectFromNode = data;
    },
    handleNodeClickTo(data){
      if("department" == data.className){
        this.selectToNode = data;
      }
      console.log("select To node:");
      console.log(this.selectToNode);
    },
    handleToRight(){
      if(!this.selectToNode){
        this.$alert('没有选择目标部门，请选择右边待迁移目标部门', '错误', {
          confirmButtonText: '确定',
        });
        return;
      }
      if(this.selectToNode.className != 'department'){
        this.$alert('选择的不是部门，请选择右边待迁移目标部门', '错误', {
          confirmButtonText: '确定',
        });
        return;
      }
      // 选中节点数据
      let checkedFromNodes = this.$refs["fromTree"].getCheckedNodes();
      console.log(checkedFromNodes);
      let checkedFromEmploy = [];
      if(checkedFromNodes){
        checkedFromEmploy = checkedFromNodes.filter(item=> {return item.className=='employ'});
        console.log(checkedFromEmploy);
      }
      if(!checkedFromEmploy || checkedFromEmploy.length <=0 ){
        this.$alert('没有选择迁移的员工，请选择左边员工', '错误', {
          confirmButtonText: '确定',
        });
        return;
      }
      let employNames = '';
      let isSameDepartment = false;
      let isSameCompany = true;
      checkedFromEmploy.map(employ => {
        employNames = employNames + ", " + employ.name
        if(employ.departmentId == this.selectToNode.id) isSameDepartment = true;
        if(employ.companyId != this.selectToNode.companyId) isSameCompany = false;
      });
      if(isSameDepartment){
        this.$alert('请不要将员工迁移至和原部门相同部门', '错误', {
          confirmButtonText: '确定',
        });
        return;
      }
      if(!isSameCompany){
        this.$alert('暂时不能跨公司迁移员工', '错误', {
          confirmButtonText: '确定',
        });
        return;
      }
      employNames = employNames.substr(1, employNames.length);
      // checkedFromEmploy.map(employ=>{
      //   employNames
      // });
      //
      this.$confirm('确定将员工：'+employNames+', 迁移至部门【'+this.selectToNode.name+'】', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        checkedFromEmploy.map(employ=>{
          employ.newDepartmentId = this.selectToNode.id;
          // employ.newCompanyId = this.selectToNode.companyId;
          console.log("this.selectToNode.children")
          console.log(this.selectToNode.children)
          if(!this.selectToNode.children){
            this.$set(this.selectToNode, 'children', []);
          }
          this.selectToNode.children.push(employ);
          this.moveEmploys.push(employ);
          this.$refs["fromTree"].remove(employ)
        });
        // 清空左边选择
        this.$refs["fromTree"].setCheckedKeys([]);
        console.log("迁移后的新员工：")
        console.log(this.moveEmploys);
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          this.selectFromChange();
        }, 500);

      }).catch((e) => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });

    },
    handleToLeft(){
      // 选中节点数据
      let checkedToNodes = this.$refs["toTree"].getCheckedNodes();
      console.log(checkedToNodes);
      let checkedToEmploy = [];
      if(checkedToNodes){
        checkedToEmploy = checkedToNodes.filter(item=> {return item.className=='employ'});
        console.log(checkedToEmploy);
      }
      if(!checkedToEmploy || checkedToEmploy.length <=0 ){
        this.$alert('没有选择退回的员工，请选择右边需要取消迁移的员工', '错误', {
          confirmButtonText: '确定',
        });
        return;
      }
      checkedToEmploy.map(employ=>{
        console.log("backemploy:")
        console.log(employ);
        const parent = this.$refs["fromTree"].getNode(employ.treePid);
        console.log("parent:")
        console.log(parent)
        // if(!parent.data.children){
        //   this.$set(parent.data, 'children', []);
        // }
        // parent.data.children.push(employ);
        // this.moveEmploys.delete(employ)
        this.$refs["fromTree"].append(employ,parent);
        this.$refs["toTree"].remove(employ)
      });

      let newEmploys = [];
      for(let i = 0; i < this.moveEmploys.length; i++){
        const e = this.moveEmploys[i];
        let finded = false;
        for(let j = 0; j < checkedToEmploy.length; j++){
          if(e.id == checkedToEmploy[j].id){
            finded = true;
            break;
          }
        }
        if(!finded){
          newEmploys.push(e);
        }
      }
      console.log("移除后的员工列表：")
      console.log(newEmploys);
      this.moveEmploys = newEmploys;
      // const newEmploys = this.moveEmploys.filter( item=> {
      //   checkedToEmploy.map item.id
      // });

      // 清空左边选择
      this.$refs["toTree"].setCheckedKeys([]);
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.selectFromChange();
      }, 500);

    },
  }

}
</script>

<style lang="less" scoped>
/deep/ .el-tree-node:focus > .el-tree-node__content {
  background-color: #4ba5f6 !important;
}
/deep/ .el-tree-node.is-current>.el-tree-node__content{
  background-color: #4ba5f6 !important;
}
</style>
