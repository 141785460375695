<template>
  <div>
    <SelfFlowEdit type="apply" :isShowClose="true"></SelfFlowEdit>
  </div>

</template>

<script>
import SelfFlowEdit from "./selfFlowEdit";
export default {
  name: "apply",
  components:{
    SelfFlowEdit,
  }
}
</script>

<style scoped>

</style>
