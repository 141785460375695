<template>
	<div>
		<!-- 宫格组件 -->
		<div v-if="orgList&&orgList.length>0">
			<div class="title" ><span class="title-seperator"></span><span>规划管理</span></div>
			<div>
				<el-row>
					<el-col :span="6" v-for="(listItem, listIndex) in orgList" :index="listIndex" :key="listIndex">
						<div @click="clickOrg(listIndex)">
							<div class="button-icon icon-red">
								<span :class="'hyicon-' + listItem.name + ' hyicon iconfont'"></span>
							</div>
							<div class="button-style">
								<el-button class="grid-text" type="text"> {{ listItem.title }}</el-button>
							</div>
						</div>
					</el-col>
				</el-row>
			</div>
		</div>

		<div v-if="employList&&employList.length>0">
			<div class="title"><span class="title-seperator"></span><span>员工管理</span></div>
			<el-row>
				<el-col :span="6" v-for="(listItem,listIndex) in employList" :index="listIndex" :key="listIndex">
					<div @click="clickEmploy(listIndex)">
						<div class="button-icon icon-red">
							<span :class="'hyicon-' + listItem.name + ' hyicon iconfont'"></span>
						</div>
						<div class="button-style">
							<el-button class="grid-text" type="text"> {{ listItem.title }}</el-button>
						</div>
					</div>
				</el-col>
			</el-row>
		</div>

		<div v-if="trainList&&trainList.length>0">
			<div class="title"><span class="title-seperator"></span><span>培训管理</span></div>
			<el-row>
				<el-col :span="6" v-for="(listItem,listIndex) in trainList" :index="listIndex" :key="listIndex">
					<div @click="clickTrain(listIndex)">
						<div class="button-icon icon-red">
							<span :class="'hyicon-' + listItem.name + ' hyicon iconfont'"></span>
						</div>
						<div class="button-style">
							<el-button class="grid-text" type="text"> {{ listItem.title }}</el-button>
						</div>
					</div>
				</el-col>
			</el-row>
		</div>

		<div v-if="attendanceList&&attendanceList.length>0">
			<div class="title"><span class="title-seperator"></span><span>班制考勤</span></div>
			<el-row>
				<el-col :span="6" v-for="(listItem,listIndex) in attendanceList" :index="listIndex" :key="listIndex">
					<div @click="clickAttendance(listIndex)">
						<div class="button-icon icon-green">
							<span :class="'hyicon-' + listItem.name + ' hyicon iconfont'"></span>
						</div>
						<div class="button-style">
							<el-button class="grid-text" type="text"> {{ listItem.title }}</el-button>
						</div>
					</div>
				</el-col>
			</el-row>
		</div>

		<div v-if="performanceList&&performanceList.length>0">
			<div class="title"><span class="title-seperator"></span><span>绩效管理</span></div>
			<el-row>
				<el-col :span="6" v-for="(listItem,listIndex) in performanceList" :index="listIndex" :key="listIndex">
					<div @click="clickPerformance(listIndex)">
						<div class="button-icon icon-green">
							<span :class="'hyicon-' + listItem.name + ' hyicon iconfont'"></span>
						</div>
						<div class="button-style">
							<el-button class="grid-text" type="text"> {{ listItem.title }}</el-button>
						</div>
					</div>
				</el-col>
			</el-row>
		</div>


		<div v-if="salaryList&&salaryList.length>0">
			<div class="title"><span class="title-seperator"></span><span>薪酬福利</span></div>
			<el-row>
				<el-col :span="6" v-for="(listItem,listIndex) in salaryList" :index="listIndex" :key="listIndex">
					<div @click="clickSalary(listIndex)">
						<div class="button-icon icon-green">
							<span :class="'hyicon-' + listItem.name + ' hyicon iconfont'"></span>
						</div>
						<div class="button-style">
							<el-button class="grid-text" type="text"> {{ listItem.title }}</el-button>
						</div>
					</div>
				</el-col>
			</el-row>
		</div>

		<div v-if="relationList&&relationList.length>0">
			<div class="title"><span class="title-seperator"></span><span>员工关系</span></div>
			<el-row>
				<el-col :span="6" v-for="(listItem,listIndex) in relationList"  :index="listIndex" :key="listIndex">
					<div @click="clickRelation(listIndex)">
						<div class="button-icon icon-green">
							<span :class="'hyicon-' + listItem.name + ' hyicon iconfont'"></span>
						</div>
						<div class="button-style">
							<el-button class="grid-text" type="text"> {{ listItem.title }}</el-button>
						</div>
					</div>
				</el-col>
			</el-row>
		</div>
		<div v-if="oaList&&oaList.length>0">
			<div class="title"><span class="title-seperator"></span><span>OA系统</span></div>
			<el-row>
				<el-col :span="6" v-for="(listItem,listIndex) in oaList"  :index="listIndex" :key="listIndex">
					<div @click="clickOa(listIndex)">
						<div class="button-icon icon-blue">
							<span :class="'hyicon-' + listItem.name + ' hyicon iconfont'"></span>
						</div>
						<div class="button-style">
							<el-button class="grid-text" type="text"> {{ listItem.title }}</el-button>
						</div>
					</div>
				</el-col>
			</el-row>
		</div>
		<div v-if="recruitList && recruitList.length > 0">
			<div class="title"><span class="title-seperator"></span><span>员工招聘</span></div>
			<el-row>
				<el-col :span="6" v-for="(listItem, listIndex) in recruitList" :index="listIndex" :key="listIndex">
					<div @click="clickRecruit(listIndex)">
						<div class="button-icon icon-blue">
							<span :class="'hyicon-' + listItem.name + ' hyicon iconfont'"></span>
						</div>
						<div class="button-style">
							<el-button class="grid-text" type="text"> {{ listItem.title }}</el-button>
						</div>
					</div>
				</el-col>
			</el-row>
		</div>
		<div v-if="sysList&&sysList.length>0">
			<div class="title"><span class="title-seperator"></span><span>系统管理</span></div>
			<el-row>
				<el-col :span="6" v-for="(listItem,listIndex) in sysList" :index="listIndex" :key="listIndex">
					<div @click="clickSys(listIndex)">
						<div class="button-icon icon-blue">
							<span :class="'hyicon-' + listItem.name + ' hyicon iconfont'"></span>
						</div>
						<div class="button-style">
							<el-button class="grid-text" type="text"> {{ listItem.title }}</el-button>
						</div>
					</div>
				</el-col>
			</el-row>
		</div>
	</div>
</template>

<script>
import { Local } from '@/utils/storage.js';
import { getToken, removeToken } from '@/utils/auth'; // get token from cookie
import { PrevLoading } from '@/utils/loading.js';
import * as uni from '@/utils/uni.webview.1.5.2.js';
// import UGrid from "uview-ui";
// import UGridItem from "uview-ui";
// import USection from "uview-ui";

export default {
	name: 'index',
	// components: {UGrid, UGridItem, USection},
	data() {
		return {
			checkPath: '/appwork',
			myUni: null,
			sysList: [],
			orgList: [],
			employList: [],
			trainList: [],
			attendanceList: [],
			performanceList: [],
			salaryList: [],
			relationList: [],
			oaList: [],
			recruitList: [],
			iconColor: '#FF0000',
			iconColor2: 'rgb(64 223 36)',
			iconColor3: 'rgb(0 149 255)',
		};
	},
	created() {
		this.initMenu();
	},
	methods: {
		initMenu() {
			let menus = this.$store.getters.menus;
			console.log(menus);
			menus.forEach((item) => {
				if ('system_management' == item.name) {
					this.sysList = this.parseMenuChild(item.children);
				} else if ('org_management' == item.name) {
					this.orgList = this.parseMenuChild(item.children);
					console.log('orgList=');
					console.log(this.orgList);
				} else if ('employ_management' == item.name) {
					this.employList = this.parseMenuChild(item.children);
				} else if ('train_manage' == item.name) {
					this.trainList = this.parseMenuChild(item.children);
				} else if ('attendance' == item.name) {
					this.attendanceList = this.parseMenuChild(item.children);
				} else if ('performance' == item.name) {
					this.performanceList = this.parseMenuChild(item.children);
				} else if ('salary' == item.name) {
					this.salaryList = this.parseMenuChild(item.children);
				} else if ('relation' == item.name) {
					this.relationList = this.parseMenuChild(item.children);
				} else if ('oa' == item.name) {
					this.oaList = this.parseMenuChild(item.children);
				} else if ('employee_recruit' == item.name) {
					this.recruitList = this.parseMenuChild(item.children);
					// }else if("employee_recruit"==item.name){
					//   this.orgList = this.parseMenuChild(item.children);
					// }else if("employee_recruit"==item.name){
					//   this.orgList = this.parseMenuChild(item.children);
					// }else if("employee_recruit"==item.name){
					//   this.orgList = this.parseMenuChild(item.children);
				}
			});
		},
		parseMenuChild(childs) {
			let list = [];
			childs.forEach((item) => {
				if (item.meta.isUniApp) list.push({ path: item.path, name: item.meta.uniIcon, title: item.meta.title });
			});
			return list;
		},
		clickOrg(idx) {
			this.goToWebview(idx, this.orgList);
		},
		clickSys(idx) {
			this.goToWebview(idx, this.sysList);
		},
		clickEmploy(idx) {
			this.goToWebview(idx, this.employList);
		},
		clickTrain(idx) {
			this.goToWebview(idx, this.trainList);
		},
		clickAttendance(idx) {
			this.goToWebview(idx, this.attendanceList);
		},
		clickPerformance(idx) {
			this.goToWebview(idx, this.performanceList);
		},
		clickSalary(idx) {
			this.goToWebview(idx, this.salaryList);
		},
		clickRelation(idx) {
			this.goToWebview(idx, this.relationList);
		},
		clickOa(idx) {
			this.goToWebview(idx, this.oaList);
		},
		clickRecruit(idx) {
			this.goToWebview(idx, this.recruitList);
		},
		goToWebview(idx, list) {
			console.log('goToWebview, idx=' + idx);
			console.log(list[idx]);
			let path = list[idx] ? list[idx].path : null;
			let title = list[idx] ? list[idx].title : null;
			console.log(path);
			this.$router.push(path);
		},
	},
};
</script>

<style scoped>

.button-icon {
	text-align: center;
}
.button-icon span {
	font-size: x-large !important;
}
.icon-red {
	color: red;
}

.icon-green {
	color: rgb(64, 233, 36) ;
}

.icon-blue {
	color: rgb(0, 149, 255) ;
}


.button-style {
	text-align: center;
	padding-top: 1px;
}

.button-style button {
	padding-top: 1px;
}
.title{
	text-align: left;
	margin: 5px 0px;


}
.grid-text{
	color: black;
}
.title-seperator{
	border-color:  rgb(0, 149, 255) ;
	border-style: solid;
	margin-left: 5px;
	margin-right: 5px;
	border-width: thin;
	height: 50%;

	border-radius: 2px;
}

</style>
