<template>
	<div>
		<el-form :model="queryParameters" ref="queryParameters" class="employ" label-width="auto">
			<el-row :gutter="24">
				<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
					<el-form-item label="最高学历">
						<el-select v-model="queryParameters.education" clearable placeholder="请选择最高学历" style="width: 100% !important">
							<el-option label="初中" value="初中" />
							<el-option label="高中" value="高中" />
							<el-option label="大专" value="大专" />
							<el-option label="本科" value="本科" />
							<el-option label="硕士" value="硕士" />
							<el-option label="博士" value="博士" />
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
					<el-form-item label="状态">
						<el-select v-model="queryParameters.status" clearable placeholder="请选择状态" style="width: 100% !important">
							<el-option v-for="item in resumeStatus" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue" />
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
					<el-form-item label="居住地">
						<el-input v-model="queryParameters.livingPlace" clearable placeholder="请输入居住地"></el-input>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
					<el-form-item label="工作技能">
						<el-input v-model="queryParameters.skills" clearable placeholder="请输入工作技能"></el-input>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
					<el-form-item label="最小年龄">
						<el-select v-model="queryParameters.minAge" clearable placeholder="请选择最小年龄" style="width: 100% !important">
							<el-option v-for="item in ages" :key="item" :label="item" :value="item" />
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
					<el-form-item label="最大年龄">
						<el-select v-model="queryParameters.maxAge" clearable placeholder="请选择最大年龄" style="width: 100% !important">
							<el-option v-for="item in ages" :key="item" :label="item" :value="item" />
						</el-select>
					</el-form-item>
				</el-col>

				<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
					<el-form-item label="性别">
						<el-select v-model="queryParameters.gender" clearable placeholder="请选择性别" style="width: 100% !important">
							<el-option label="男" value="男" />
							<el-option label="女" value="女" />
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
					<el-form-item label="职位">
						<el-input v-model="queryParameters.recruitPosition" clearable placeholder="请输入查询的职位"></el-input>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
					<el-form-item label="姓名">
						<el-input v-model="queryParameters.name" clearable placeholder="请输入查询的姓名"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row :gutter="10" >
				<el-col :span="1.5" class="mb8">
					<el-button icon="el-icon-search" size="mini" plain type="primary" :loading="loading" @click="query">搜索</el-button>
				</el-col>
				<el-col :span="1.5" class="mb8">
					<el-button icon="el-icon-apple" size="mini" :disabled="!single" plain type="primary" @click="openInterview"
						>面试邀请</el-button
					>
				</el-col>
			</el-row>
		</el-form>

		<el-table v-loading="loading" :data="resumes" style="width: 100%" @selection-change="handleSelectionChange" stripe border>
			<el-table-column type="selection" width="55" align="center" />
			<el-table-column label="姓名" align="center" prop="name" sortable width="150px" show-overflow-tooltip="true"> </el-table-column>
			<el-table-column label="姓别" align="center" prop="gender" width="150px" sortable :show-overflow-tooltip="true"> </el-table-column>
			<el-table-column label="婚姻状态" align="center" prop="marriageStatus" min-width="60" />
			<el-table-column label="工作年限" align="center" prop="workYears" width="150px" sortable :show-overflow-tooltip="true" />
			<!-- <el-table-column label="政治面貌" align="center" prop="politicalStatus" sortable :show-overflow-tooltip="true"> </el-table-column> -->
			<el-table-column label="住址" align="center" prop="livingAddr" sortable> </el-table-column>
			<el-table-column label="应聘职位" align="center" prop="positionName" sortable :show-overflow-tooltip="true"> </el-table-column>
			<el-table-column label="求职状态" align="center" prop="status" />
			<el-table-column label="操作" align="center" min-width="50" fixed="right">
				<template slot-scope="scope">
					<el-button size="mini" type="text" icon="el-icon-postcard" @click="OpenResume(scope.row)" v-permission="['/TrainingPlan/edit']"
						>详细</el-button
					>
					<!-- <el-button size="mini" type="text" icon="el-icon-apple" @click="notifyInterview(scope.row)" v-permission="['/TrainingPlan/edit']">通知面试</el-button> -->
				</template>
			</el-table-column>
		</el-table>
		<pagination v-show="total > 0" :total="total" :page.sync="queryParameters.pageNum" :limit.sync="queryParameters.pageSize" @pagination="query" />
		<el-dialog title="详细简历" :visible.sync="openDetailDialog" :width="uniApp ? '95%' : '80%'" :append-to-body="true">
			<resume-register v-if="openDetailDialog" :ReadOnly="true" :RowData="selectedResume" @close="closeResume"></resume-register>
		</el-dialog>
		<el-dialog title="选择职位" :visible.sync="openInterviewDialog" :width="uniApp ? '90%' : '30%'" :append-to-body="true">
			<el-form>
				<el-row>
					<el-form-item label="公司名称">
						<el-select
							v-model="companySelect"
							style="width: 100%"
							clearable
							@clear="handleClearable"
							placeholder="请选择"
							collapse-tags
							ref="companySelected"
						>
							<el-option :value="companyId" :label="companySelect" style="height: auto">
								<el-tree
									:data="companyOptions"
									:props="defaultProps"
									:expand-on-click-node="false"
									ref="tree"
									show-checkbox
									node-key="id"
									default-expand-all
									@node-click="handleNodeClick"
									@check="handleNodeClick"
									highlight-current
								/>
							</el-option>
						</el-select>
					</el-form-item>
				</el-row>
				<el-row>
					<el-form-item label="职位">
						<el-select v-model="selectPositionId" clearable placeholder="请选择状态" style="width: 100% !important">
							<el-option v-for="position in releasedPositions" :key="position.id" :label="position.positionName" :value="position.id" />
						</el-select>
					</el-form-item>
				</el-row>
				<el-row>
					<el-button  type="primary" :loading="interviewLoading"  icon="el-icon-postcard" @click="notifyInterview()">发送面试邀请</el-button>
					<el-button  type="primary" icon="el-icon-close" @click="closeInterview()">关闭</el-button>
				</el-row>
			</el-form>
		</el-dialog>
	</div>
</template>

<script>
import { findAllResume } from '@/api/recruit/register';
import { findAllPositionNames,applyPosition } from '@/api/recruit/positionManagement';
import { getTreeselectCompany } from '@/api/org/company';
import { Local } from '@/utils/storage.js';
import resumeRegister from './resumeRegister.vue';
import Pagination from '@/components/Pagination';
export default {
	name: 'recruitReport',
	components: {
		Pagination,
		resumeRegister,
	},
	data() {
		return {
			resumeStatus: [],
			resumes: [],
			ages: [],
			queryParameters: {
				name: '',
				education: '',
				status: '',
				livingPlace: '',
				recruitPosition:'',
				skills: '',
				gender: '',
				name:'',
				maxAge: '',
				minAage: '',
				pageSize: 10,
				pageNum: 1,
			},
			where: {
				query: '',
				filters: [],
			},
			loading: false,
			total: 0,
			uniApp: false,
			openDetailDialog: false,
			selectedResume: {},
			ids: [],
			single: false,
			selectResume: Object,
			interviewLoading: false,
			releasedPositions:[],
			companySelect: '',
			companyId: '',
			isCompnayVisible: false,
			// 公司树选项
			companyOptions: [],
			companyList: [],
			defaultProps: {
				children: 'children',
				label: 'companyName',
			},
			selectPositionId:'',
			openInterviewDialog:false,
		};
	},
	created() {
		

		for (let index = 18; index < 80; index++) {
			this.ages.push(index);
		}
		this.uniApp = Local.get('isUniapp') ? true : false;
	},
	mounted() {
		getTreeselectCompany(null, (list, options) => {
			this.companyList = list;
			if (this.companyList.length > 1) {
				this.isCompnayVisible = true;
			}
			this.companyOptions = options;
		});
		this.getDicts('resume_position_status').then((response) => {
			this.resumeStatus = response.data;
		});
	},

	methods: {
		// 节点单击事件
		handleNodeClick(data) {

			this.queryParameters.companyId = data.id;
			this.companyId = data.id;
			this.companySelect = data.companyName;
			let selectArray = [];
			selectArray.push(this.companyId);
			this.getAllPositionNames(this.companyId);
			this.$refs.tree.setCheckedKeys(selectArray);
			this.$refs.companySelected.blur();
		},
		
		handleClearable()
		{
				this.$refs.tree.setCheckedKeys([]);
				this.companyId=null;
				this.companySelect='';
		},
		query() {
			let filter = {};
			if (this.queryParameters.education) {
				filter['highestDegree'] = {
					type: 'eq',
					value: this.queryParameters.education,
				};
			}
			if (this.queryParameters.gender) {
				filter['gender'] = {
					type: 'eq',
					value: this.queryParameters.gender,
				};
			}

			if (this.queryParameters.maxAge) {
				filter['age'] = {
					type: 'lt',
					value: this.queryParameters.maxAge,
				};
			}

			if (this.queryParameters.minAage) {
				filter['age'] = {
					type: 'gt',
					value: this.queryParameters.minAage,
				};
			}

			if (this.queryParameters.status) {
				filter['status'] = {
					type: 'eq',
					value: this.queryParameters.status,
				};
			}

			if (this.queryParameters.livingPlace) {
				filter['livingAddr'] = {
					type: 'search',
					value: this.queryParameters.livingPlace,
				};
			}
			if (this.queryParameters.recruitPosition) {
				filter['positionName'] = {
					type: 'search',
					value: this.queryParameters.recruitPosition,
				};
			}
			

			if (this.queryParameters.name) {
				filter['name'] = {
					type: 'search',
					value: this.queryParameters.name,
				};
			}

			if (this.queryParameters.skills) {
				filter['skills'] = {
					type: 'search',
					value: this.queryParameters.skills,
				};
			}

			const filters = this.parseFilters(filter);
			if (filters && filters.length > 0) {
				this.where.filters = filters;
			} else {
				// 如果过滤条件为空，设置空数组
				this.where.filters = [];
			}
			const params = {
				pageNum: this.queryParameters.pageNum,
				pageSize: this.queryParameters.pageSize,
				where: this.where,
				order: '',
			};
			this.loading = true;
			findAllResume(params)
				.then((response) => {
					this.resumes = response.data.records;
					this.total = response.data.total;
					this.loading = false;
				})
				.catch((err) => {
					this.loading = false;
					this.msgError('搜索失败，' + err);
				});
		},
		handleSelectionChange(selection) {
			this.ids = selection.map((item) => item.id);
			this.single = selection.length == 1;
			this.selectResume = this.resumes.filter((item) => item.id == this.ids[0])[0];
		},
		openInterview(){
			this.openInterviewDialog = true;
		},
		closeInterview(){
			this.openInterviewDialog = false;
		},
		notifyInterview() {
			if(this.releasedPositions==null || this.releasedPositions.length == 0 )
			{
				this.msgError('此公司没有发布的职位，请核实。' );
				return;
			}
			let appliedPerson = {};
			appliedPerson.positionName = this.releasedPositions.filter(item=>item.id==this.selectPositionId)[0].positionName;
			appliedPerson.positionId = this.selectPositionId;
			appliedPerson.resumeId = this.selectResume.id;
			appliedPerson.resumeName = this.selectResume.name;
			appliedPerson.status = '面试邀请';
			let that = this;
			this.interviewLoading = true;
			applyPosition(appliedPerson)
				.then((response) => {
					that.interviewLoading = false;
					that.msgSuccess('面试通知成功。');
				})
				.catch((err) => {
					that.interviewLoading = false;
					//  that.msgError('申请失败，' + err);
				});
		},
		getResume() {},
		OpenResume(row) {
			this.openDetailDialog = true;
			this.selectedResume = row;
			// this.selectedResume.workExp = JSON.parse(row.workExp);
			// THIS.selectedResume.educationExp = JSON.parse(row.educationExp);
		},
		closeResume(params) {
			this.openDetailDialog = false;
		},

		getAllPositionNames(companyId){
			
			findAllPositionNames(companyId)
				.then((response) => {
					this.releasedPositions = response.data;
				})
				.catch((err) => {
					this.msgError('获取职位失败，' + err);
				});
		},
	},
};
</script>

<style lang="scss" scoped>
</style>