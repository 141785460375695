<template>
	<el-collapse-item title="自助信息" :name="this.collapseStatus" :class="collapseSetting()">
		<el-row v-for="(selfModel, index) in selfInfoModel" :key="index" :class="newAddedAfterLocked(selfModel)" style="padding-top:10px;">
			<el-row>
				<el-row>
					<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
						<el-form-item label="名称">
							<el-input v-model="selfModel.name" :readonly="canEdit(selfModel)"></el-input>
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
						<el-form-item label="值">
							<el-input v-model="selfModel.value" :readonly="canEdit(selfModel)"></el-input>
						</el-form-item>
					</el-col>

					<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
						<el-form-item label=" ">
							<el-button
								plain
								class="employ-form-delete-button"
								:id="selfModel.name"
								v-bind:disabled="canEdit(selfModel)"
								v-on:click="deleteSelfInfo($event, selfModel.name)"
								type="danger"
								icon="el-icon-delete"
								>删除</el-button
							>
						</el-form-item>
					</el-col>
				</el-row>
				<div
					style="
						width: 80%;
						border-top: 1px solid;
						color: #ebeef5;
						height: 10px;
						margin-left: 10%;
						border-bottom-width: 1px;
						border-left-width: 1px;
						text-align: center;
					"
				></div>
			</el-row>
		</el-row>

		<el-row>
			<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
				<el-form-item label=" ">
					<el-button v-on:click="addSelfInfo" type="primary" v-bind:disabled="enableAdd"  plain icon="el-icon-plus">增加</el-button>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
				<el-form-item label=" ">
					<el-button v-on:click="edit" type="primary" v-bind:disabled="hasLocked?true:this.isReadOnly" plain icon="el-icon-edit">编辑</el-button>
					<el-button v-on:click="save" plain v-bind:disabled="this.isReadOnly" type="primary" :loading="saving" icon="el-icon">保存</el-button>
				</el-form-item>
			</el-col>
		</el-row>
	</el-collapse-item>
</template>


<script>
export default {
	data() {
		let readonlyInit = true;
		let selfInfoInit = new Array();
		let initAddItem = false;
		if (this.selfInfo == null) {
			selfInfoInit.push({
				name: '',
				value: null,
				newAdded:this.locked?true:false,
			});
			readonlyInit = false;
			initAddItem=true;
		} else {
			selfInfoInit = this.selfInfo;
		}

		if(this.locked){
			readonlyInit=true;
		}
		else{
			readonlyInit = this.isReadOnly;
		}


		return {
			selfInfoModel: selfInfoInit,
			readonly: readonlyInit,
			hasLocked:this.locked,
			enableAdd:this.enableAddButton,
			saving: false,
			initItem:initAddItem,
		};
	},
	props: { selfInfo: Array, id: Number, isReadOnly: Boolean, collapseStatus: String ,enableAddButton:Boolean ,locked:Boolean},
	watch: {
			isReadOnly(newValue,oldV) {
			this.readonly = newValue;
			//this.hasLocked = newValue;
			},
			locked(newValue,oldV) {
				this.hasLocked = newValue;
			},
		},
	computed: {
        newAddedAfterLocked() {
            return function(currentItem){
                return {employ_item_update:this.locked&&currentItem.newAdded && !this.initItem &&!this.isReadOnly};         
            }           
        },
		canEdit(){
			 return function(currentItem){
				 let returnValue = true;
				 if(this.hasLocked){
					 returnValue =currentItem.newAdded&&!this.isReadOnly?false:true;
				 }
				 else{
					returnValue = this.readonly;
				 }
                return returnValue;      
            }   
		} ,
		collapseSetting(){
			return function(){
				if(this.selfInfoModel !=null && this.selfInfoModel.length >0)
				{
					if(this.locked)
					{
						let newAddResults = this.selfInfoModel.filter(item=>item.newAdded==true);
						if(newAddResults.length > 0)
						{
							if(this.initItem)
							{
								return {callpse_header_unfill:true};
							}
							else{
								return {callpse_header_newAdd:true};
							}
						}
						else
						{
							return {callpse_header_fill:true};
						}
						
					}
					else
					{
						if(this.initItem)
						{
							return {callpse_header_unfill:true};
						}
						else{
							return {callpse_header_fill:true};
						}
					}
					
				}
				else
				{
					return {callpse_header_unfill:true};
				}
				
			}
		},
    },
	methods: {
		
		save() {
			// save data to DB
			// this.$emit("submitSpouseInfo",this.spouseInfoInit);
			let savingSelfInfo = {};
			savingSelfInfo.id = this.id;
			savingSelfInfo.selfInfo = this.selfInfoModel;
			this.saving = true;
			this.$emit('updatePartialEmployeeInfo', { data: savingSelfInfo }, (response) => {
				this.saving = false;
				if (response.success) {
					this.msgSuccess('修改成功');
					this.readonly = true;
				} else {
					this.msgError('修改失败,' + response.msg);
				}
			});
		},
		edit() {
			this.readonly = false;
		},
		addSelfInfo() {
			this.readonly = false;
			this.selfInfoModel.push({
				name: '',
				value: null,
				newAdded:true,
			});
		},
		deleteSelfInfo(event, name) {
			let self = this;
			if (event.currentTarget.id == name) {
				this.$confirm('确定要删除吗?', '确认', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'error',
					// type: 'warning'
				})
					.then(() => {
						if (self.selfInfoModel.length > 0) {
							let foundIndex = self.selfInfoModel.findIndex((x) => x.name === name);
							if (foundIndex > -1) {
								self.selfInfoModel.splice(foundIndex, 1);
							}
						}
					})
					.catch(() => {
						this.msgError('删除发生异常!');
					});
			}
		},
	},
};
</script>

<style>
</style>