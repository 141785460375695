<template>
	<el-collapse-item title="证书" :name="this.collapseStatus" :class="collapseSetting()">
		<el-form class="employ" label-width="150px">
		<el-row v-for="(certificateModel,index) in certificatesModel" :key="index" :class="newAddedAfterLocked(certificateModel)">
			<el-row>
				<el-row>
				
					<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
						<el-form-item label="证书名称">
							<el-input v-model="certificateModel.name" :readonly="canEdit(certificateModel)"></el-input>
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
						<el-form-item label="证书号码">
							<el-input v-model="certificateModel.number" :readonly="canEdit(certificateModel)"></el-input>
						</el-form-item>
					</el-col>
				
					<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
						<el-form-item label="证书签发日期">
							<el-date-picker  v-model="certificateModel.issueDate" value-format="yyyy-MM-dd" :readonly="canEdit(certificateModel)" align="left" type="date" placeholder="选择日期" >
							</el-date-picker>
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
						<el-form-item label="证书到期日期">
								<el-date-picker  v-model="certificateModel.expireDate" value-format="yyyy-MM-dd" :readonly="canEdit(certificateModel)" align="left" type="date" placeholder="选择日期" >
							</el-date-picker>
						</el-form-item>
					</el-col>
			
					<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
						<el-form-item label="证书签发单位">
							<el-input v-model="certificateModel.issuedUnit" :readonly="canEdit(certificateModel)"></el-input>
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
						<el-form-item label="备注">
							<el-input v-model="certificateModel.remarks" :readonly="canEdit(certificateModel)"></el-input>
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">
							<div style="margin-left: 150px; margin-bottom: 10px">
							<el-upload
								:disabled="canEdit(certificateModel)"
								ref="elUpload"
								multiple=""
								class="avatar-uploader"
								action="uploadCertifcatePicture()"
								:limit="pictureLimit"
								:show-file-list="true"
								:file-list= "certificateModel.fileList"
								list-type="picture-card"
								:on-success="handleAvatarSuccess"
								:http-request="(res)=>{return uploadCertifcatePicture(res,certificateModel)}"
								:on-preview="handlePictureCardPreview"
								:auto-upload="true"
								accept=".jpg,.jpeg,.bmp,.png,application/pdf"
								:before-upload="beforeAvatarUpload"
								:on-exceed="handleExceed"
								:on-remove="handleRemove"
							>
								<i class="el-icon-plus avatar-uploader-icon"></i>
								<div slot="file" slot-scope="{ file }" v-loading="file.status == 'uploading'" style="height: 100%">
									<pdf v-if="file!=null&&file.name!=null&&file.name.endsWith('.pdf')" :src="file.url" :page="1"></pdf>
									<img v-else class="el-upload-list__item-thumbnail" style="object-fit: cover" :src="file.url" alt="" />
									<span class="el-upload-list__item-actions">
										<span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
											<i class="el-icon-zoom-in"></i>
										</span>

										<span  class="el-upload-list__item-delete" @click="handleRemove(file)">
											<i class="el-icon-delete"></i>
										</span>
									</span>
								</div>
							</el-upload>
							<el-dialog :visible.sync="dialogVisible" @close="closeDialog">
								<div v-if="dialogPdfName.endsWith('.pdf')">
									<pdf :src="pdfUrl" :page="1"></pdf>
								</div>
								<div v-else>
									<img width="100%" :src="dialogImageUrl" alt="" />
								</div>
							</el-dialog>
							<p style="transform:scale(0.6);margin-left:-230px;">上传离职证明, 支持jpg、png、bpm、pdf格式，单个文件不超过50M。</p>
							
						</div>
					</el-col>
				
					<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
						<el-form-item label=" "> 
							<el-button plain class="employ-form-delete-button" :id="certificateModel.name" v-bind:disabled="canEdit(certificateModel)"   v-on:click="deleteCertificate($event,certificateModel.name)" type="danger" icon="el-icon-delete">删除</el-button>						
						</el-form-item>
					</el-col>
				</el-row>
				<div style="width: 80%;border-top: 1px solid;color:#ebeef5;height:10px;margin-left:10%; border-bottom-width: 1px;border-left-width: 1px;text-align: center;">
				</div>
			
			</el-row>
		</el-row>
		<el-row>
			<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
				<el-form-item label=" ">
					<el-button v-on:click="addCertificate" type="primary" plain 	v-bind:disabled="enableAdd"  icon="el-icon-plus">增加</el-button>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
				<el-form-item label=" ">
					<el-button v-on:click="edit" type="primary" 	v-bind:disabled="hasLocked?true:this.isReadOnly"  plain icon="el-icon-edit">编辑</el-button>
					<el-button v-on:click="save" plain v-bind:disabled="this.isReadOnly" :loading="saving" type="primary" icon="el-icon">保存</el-button>
				</el-form-item>
			</el-col>
		</el-row>
		</el-form>
	</el-collapse-item>
</template>


<script>
import { uploadCertifcatePictureApi,downloadCertificatePictureApi,deletePicture} from "@/api/employ/employ";
import Pdf from 'vue-pdf';
export default {
	components: {
		Pdf,
	},
	data() {
		let readonlyInit = true;
		let certificatesInit = new Array();
		let initAddItem = false;
		if (this.certificates == null||(this.certificates != null&&this.certificates.length==0)) {
			certificatesInit.push({
				name: null,
				number: null,
				issueDate: null,
				expireDate: null,
				issuedUnit: null,	
				remarks: null,
				certificateUrl:null,
				fileList:[],
				uploadFileList:[],
				certificateOriginalFileName:null,
				certificateTempUrl:null,
				newAdded:this.locked?true:false,
			});

			initAddItem=true;
		} else {
			certificatesInit = this.certificates;
		}
	
		//  isReadOnly=true for view, others for edit. Users can't eidt if locked, but they can add 
		if(this.isReadOnly || this.locked)
		{
			readonlyInit = true;
		}
		else{
			readonlyInit = false;
		}


		return {
			certificatesModel: certificatesInit,
			readonly: readonlyInit,
			enableAdd:this.enableAddButton,
			hasLocked:this.locked,
			saving:false,
			initItem:initAddItem,
			dialogImageUrl: '',
			dialogVisible:false,
			dialogPdfName :'',
			pdfUrl:'',
			pictureLimit:5,
			pickerOptions1: {
                    disabledDate(time) {
                        return time.getTime() > Date.now();
                    }
            }
		};
	},
	props: { certificates: Array, id: Number,isReadOnly:Boolean,collapseStatus:String ,locked:Boolean ,enableAddButton:Boolean },
	watch: {
		isReadOnly(newValue,oldV) {
			this.readonly = newValue;
			//this.hasLocked = newValue;
		},
		locked(newValue,oldV) {
			this.hasLocked = newValue;
		},
	},
	created(){ 
		if (this.certificatesModel !=null && this.certificatesModel.length >0) {
				this.certificatesModel.forEach(item=>{
					if(item.certificateUrl != null && item.certificateUrl.length > 0)
					{
						if(item.fileList == undefined || item.fileList == null)
						{
							this.$set(item, 'fileList', new Array());
							this.$set(item, 'uploadFileList', new Array());
						}
						this.downloadCertificatePictures(item);
					}
				});

				this.$forceUpdate()
		}
	},
	computed: {
        newAddedAfterLocked() {
            return function(currentItem){
                return {employ_item_update:this.locked&&currentItem.newAdded && !this.initItem &&!this.isReadOnly};         
            }           
        },
		canEdit(){
			 return function(currentItem){
				 let returnValue = true;
				 if(this.hasLocked){
					 returnValue =currentItem.newAdded&&!this.isReadOnly?false:true;
				 }
				 else{
					returnValue = this.readonly;
				 }
                return returnValue;      
            }   
		} ,
		collapseSetting(){
			return function(){
				if(this.certificatesModel !=null && this.certificatesModel.length >0 && this.certificatesModel[0].certificateUrl != '[]')
				{
					if(this.locked)
					{
						let newAddResults = this.certificatesModel.filter(item=>item.newAdded==true);
						if(newAddResults.length > 0)
						{
							if(this.initItem)
							{
								return {callpse_header_unfill:true};
							}
							else{
								return {callpse_header_newAdd:true};
							}
						}
						else
						{
							return {callpse_header_fill:true};
						}
						
					}
					else
					{
						if(this.initItem)
						{
							return {callpse_header_unfill:true};
						}
						else{
							return {callpse_header_fill:true};
						}
					}
					
				}
				else
				{
					return {callpse_header_unfill:true};
				}
				
			}
		},

    },
	methods: {
		save() {
			// save data to DB
			let savingCertificates = {};
			savingCertificates.id = this.id;
			//savingCertificates.certificates = this.certificatesModel;
			savingCertificates.certificates = new Array();
			this.certificatesModel.forEach(item=>{savingCertificates.certificates.push({...item})});
			let jsonObject = {};
			savingCertificates.certificates.forEach(item=>{
				//item.certificateUrl=item.certificateTempUrl
		
				item.certificateUrl = JSON.stringify(item.uploadFileList);
				item.certificateOriginalFileName=null;
				item.certificateTempUrl=null;
				item.uploadFileList=null;
				});
				
			
			this.saving=true;
			this.$emit('updatePartialEmployeeInfo', { data: savingCertificates }, (response) => {
				this.saving=false;
				if (response.success) {
					this.msgSuccess('修改成功');
					this.readonly = true;
				} else {
					this.msgError('修改失败,' + response.msg);
				}
			});
		},
		edit() {
			this.readonly = false;
		},
		addCertificate() {
			this.readonly = false;
			this.certificatesModel.push({
				name: '',
				number: null,
				issueDate: null,
				expireDate: null,
				issuedUnit: null,	
				remarks: null,
				certificateUrl:null,
				certificateOriginalFileName:null,
				certificateTempUrl:null,
				newAdded:true,
				fileList:[],
				uploadFileList:[],
			});
		},
		deleteCertificate(event, name) {
			let self = this;
			if (event.currentTarget.id == name) {
				this.$confirm('确定要删除吗?', '确认', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'error',
					// type: 'warning'
				})
				.then(() => {
					if (self.certificatesModel.length > 0) {
						let foundIndex = self.certificatesModel.findIndex((x) => x.name === name);
						if (foundIndex > -1) {
							self.certificatesModel.splice(foundIndex, 1);
						}
					}
				})
				.catch(() => {
					this.msgError('删除发生异常!');
				});
			}
			
		},
		
		uploadCertifcatePicture(params,currentDataModel)
		{
			if(params != undefined)
			{
				this.formData = new FormData();
				this.formData.append('file', params.file);
				currentDataModel.certificateOriginalFileName = params.file.name;
				let self = this;
				uploadCertifcatePictureApi(this.formData)
					.then((response) => {
						if (response.status == 200) {
							let resData = response.data;
							//currentDataModel.certificateTempUrl= response.headers["content-disposition"].split('=')[1];
							//var blobUrl = new Blob([resData], { type: 'application/octet-stream' });

							var blob = new Blob([resData], { type: 'application/octet-stream' });
							var blobUrl = URL.createObjectURL(blob);
							currentDataModel.fileList.push({name: params.file.name, url: blobUrl});
							self.$forceUpdate()
							let serverFilePath = response.headers["content-disposition"].split('=')[1];
							currentDataModel.uploadFileList.push({name: params.file.name, url: serverFilePath});
							self.$message.success('上传成功!');
						}
						else{
							this.$message.error('上传失败：');
						}
					})
					.catch((err) => {
						this.$message.error('上传失败：'+err);

					});
			}
		},

		handleAvatarSuccess(res, file) {
			//this.form.photo = URL.createObjectURL(file.name);
		},
		beforeAvatarUpload(file) {
			const isJPG = file.type === 'image/jpeg' || file.type === 'image/jpg';
			const isPng = file.type === 'image/png';
			const isBmp = file.type === 'application/bmp';
			const ispdf = file.type === 'application/pdf';
			const isLt50M = file.size / 1024 / 1024 < 50;
			if (!isJPG && !isPng && !ispdf && !isBmp) {
				this.$message.error('上传图片只能是 JPG,JPEG,PNG,BMP,PDF 格式!');
				file.status='fail';
			}
			if (!isLt50M) {
					this.$message.error('上传图片大小不能超过 50MB!');
					file.status='fail';
			}
			return (isJPG || isPng || ispdf || isBmp) && isLt50M;
		},
		downloadCertificatePictures(currentComponent)
		{
			if(currentComponent.certificateUrl!=null && currentComponent.certificateUrl.length > 0)
			{
				// download 
				if(currentComponent.certificateUrl.startsWith('[{'))
				{
					// array
					let certifcateArray = JSON.parse(currentComponent.certificateUrl);
					for(var certificateIter in certifcateArray){
						let certificate=certifcateArray[certificateIter];
						downloadCertificatePictureApi(certificate.url)
						.then((res) => {
							if (res) {
								let resData = res.data;
								var blob = new Blob([resData], { type: 'application/octet-stream' });
								// currentComponent.certificateUrl =URL.createObjectURL(blob);
								let blobUrl = URL.createObjectURL(blob);
								currentComponent.fileList.push({name: certificate.name, url: blobUrl});
								currentComponent.uploadFileList.push({name: certificate.name, url: certificate.url});
							}
							else
							{
								this.msgError(`后端下载文件出错 & ${e}`);
							}
						})
						.catch((e) => {
							this.loading = false;
							this.msgError(`后端下载文件出错 & ${e}`);
						});
					}
				}
				else
				{
					if (currentComponent.certificateUrl != '[]') {
						// not empty array 
						downloadCertificatePictureApi(currentComponent.certificateUrl)
						.then((res) => {
							if (res) {
								let resData = res.data;
								var blob = new Blob([resData], { type: 'application/octet-stream' });
								// currentComponent.certificateUrl =URL.createObjectURL(blob);
								let blobUrl = URL.createObjectURL(blob);
								currentComponent.fileList.push({name: currentComponent.certificateOriginalFileName, url: blobUrl});
								currentComponent.uploadFileList.push({name: currentComponent.certificateOriginalFileName, url: currentComponent.certificateUrl});
							}
							else
							{
								this.msgError(`后端下载文件出错 & ${e}`);
							}
						})
						.catch((e) => {
							this.loading = false;
							this.msgError(`后端下载文件出错 & ${e}`);
						});
					}
				}

				this.$forceUpdate();
			}
		},
		handleExceed(files, fileList) {
			this.msgError(
				`当前限制选择 ${this.pictureLimit} 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件.`
			);
		},
		handleRemove(file, fileList) {
			if(file && file.raw && file.raw.status=="fail")
			{
				return;
			}
			let that = this;
			this.$confirm('确定移除吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					let filePath = '';

					for (let iter = 0; iter < that.certificatesModel.length; iter++) {
						const certificate = that.certificatesModel[iter];
						let index =  certificate.fileList.findIndex((item) => {
								return item == file;
							});
							if (index > -1) {
								certificate.fileList.splice(index, 1);
							}

							index =  certificate.uploadFileList.findIndex((item) => {
								return item.name == file.name;
							});
							if (index > -1) {
								filePath = certificate.uploadFileList[index].url;
								certificate.uploadFileList.splice(index, 1);
							}
					}
					
					if (filePath == '') {
						that.$message.error('前端没有找到删除的文件！');
						return;
					}
					
					deletePicture(filePath)
						.then((response) => {
							if (response.success) {
								that.$message.success('删除成功!');
							} else {
								that.$message.error('后台删除失败：' + response.message);
							}
						})
						.catch((err) => {
							that.$message.error('后台删除失败：' + err);
						});
				})
				.catch(() => {
					that.msgError('删除失败!');
				});
		},
		// handlePictureCardPreview(file) {
		// 	this.dialogImageUrl = file;
		// 	this.dialogVisible = true;
		// },
		handlePictureCardPreview(file) {
			if (file.name.endsWith('.pdf')) {
				this.pdfUrl = file.url;
				this.dialogPdfName = file.name;
				this.dialogImageUrl='';
			} else {
				this.dialogImageUrl = file.url;
				this.dialogPdfName = '';
				this.pdfUrl = '';
			}
			
			this.dialogVisible = true;
		},
		closeDialog() {
			this.dialogImageUrl = '';
			this.dialogVisible = false;
		},
	},
};
</script>

<style>
</style>