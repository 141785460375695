<template>
	<el-collapse-item title="语言等级" :name="this.collapseStatus" :class="collapseSetting()">
		<el-form class="employ" ref="dataFrom" :model="dataFrom" label-width="150px">
			<el-row>
				<el-row>
					<el-row v-for="(languageLevelModel, index) in dataFrom.languageLevelsModel" :key="index" 	:class="newAddedAfterLocked(languageLevelModel)">
						<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
							<el-form-item label="语言">
								<el-input v-model="languageLevelModel.languageName" :readonly="canEdit(languageLevelModel)"></el-input>
							</el-form-item>
						</el-col>
						<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
							<el-form-item label="级别">
								<el-input v-model="languageLevelModel.level" :readonly="canEdit(languageLevelModel)"></el-input>
							</el-form-item>
						</el-col>

						<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
							<el-form-item label="听力">
								<el-select v-model="languageLevelModel.lestening" placeholder="请选择" :disabled="canEdit(languageLevelModel)">
									<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
							<el-form-item label="口语">
								<el-select v-model="languageLevelModel.speaking" placeholder="请选择" :disabled="canEdit(languageLevelModel)">
									<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
								</el-select>
							</el-form-item>
						</el-col>

						<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
							<el-form-item label="写作">
								<el-select v-model="languageLevelModel.writing" placeholder="请选择" :disabled="canEdit(languageLevelModel)">
									<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
						<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
							<el-form-item
								label="使用年数"
								:prop="'languageLevelsModel.' + index + '.serviceYears'"
								:rules="[
									{
										validator: (rule, value, callback) => {
											serviceYearsValidator(rule, value, callback);
										},
										trigger: 'blur',
									},
								]"
							>
								<el-input v-model="languageLevelModel.serviceYears" :readonly="canEdit(languageLevelModel)"></el-input>
							</el-form-item>
						</el-col>

						<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
							<div style="margin-left: 150px; margin-bottom: 10px">
								<el-upload
									:disabled="readonly"
									class="avatar-uploader"
									action="uploadCertifcatePicture()"
									:show-file-list="false"
									:on-success="handleAvatarSuccess"
									:http-request="
										(res) => {
											return uploadCertifcatePicture(res, languageLevelModel);
										}
									"
									:auto-upload="true"
									accept=".jpg,.jpeg,.png,.bmp,application/pdf"
									:before-upload="beforeAvatarUpload"
								>
									<div v-if="languageLevelModel.certificateUrl">
										<pdf v-if="languageLevelModel.certificateOriginalFileName!=null&&languageLevelModel.certificateOriginalFileName.endsWith('.pdf')" :src="languageLevelModel.certificateUrl" :page="1" class="avatar"></pdf>
										<img v-else :src="languageLevelModel.certificateUrl" class="avatar" />
									</div>
									
									<i v-else class="el-icon-plus avatar-uploader-icon"></i>
								</el-upload>
									<el-dialog :visible.sync="dialogVisible" @close="closeDialog">
										<pdf v-if="languageLevelModel.certificateOriginalFileName!=null&&languageLevelModel.certificateOriginalFileName.endsWith('.pdf')" :src="dialogImageUrl" :page="1"></pdf>
										<img v-else width="100%" :src="dialogImageUrl" alt="" />
									</el-dialog>
								<p class="scaleFont">支持jpg、jpeg、bmp、png、pdf格式，大小不超过50M。</p>
								<el-button plain type="primary" v-if="languageLevelModel.certificateUrl" size="mini" icon="el-icon-zoom-in" @click="handlePictureCardPreview(languageLevelModel.certificateUrl)">预览</el-button>
	
							</div>
						</el-col>
						<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
							<el-form-item label=" ">
								<el-button
									plain
									class="employ-form-delete-button"
									:id="languageLevelModel.languageName"
									v-bind:disabled="canEdit(languageLevelModel)"
									v-on:click="deleteLanguage($event, languageLevelModel.languageName)"
									type="danger"
									icon="el-icon-delete"
									>删除</el-button
								>
							</el-form-item>
						</el-col>
					</el-row>
					<div
						style="
							width: 80%;
							border-top: 1px solid;
							color: #ebeef5;
							height: 10px;
							margin-left: 10%;
							border-bottom-width: 1px;
							border-left-width: 1px;
							text-align: center;
						"
					></div>
				</el-row>
			</el-row>
			<el-row>
				<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
					<el-form-item label=" ">
						<el-button v-on:click="addLanguage" type="primary" :disabled="enableAdd" plain icon="el-icon-plus">增加</el-button>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
					<el-form-item label=" ">
						<el-button v-on:click="edit" type="primary" plain v-bind:disabled="hasLocked?true:this.isReadOnly" icon="el-icon-edit">编辑</el-button>
						<el-button v-on:click="save" plain v-bind:disabled="readonly" type="primary" :loading="saving" icon="el-icon">保存</el-button>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
	</el-collapse-item>
</template>


<script>
import { uploadCertifcatePictureApi, downloadCertificatePictureApi } from '@/api/employ/employ';
import Pdf from 'vue-pdf';
export default {
	components: {
		Pdf,
	},
	data() {
		let readonlyInit = true;
		let languageLevelsInit = new Array();
		let initAddItem = false;
		if (this.languageLevels == null || (this.languageLevels != null&&this.languageLevels.length==0)) {
			languageLevelsInit.push({
				languageName: '',
				level: null,
				lestening: null,
				speaking: null,
				writing: null,
				serviceYears: null,
				certificateUrl: null,
				certificateOriginalFileName: null,
				certificateTempUrl: null,
				newAdded: this.locked?true:false
			});

			initAddItem=true;
		} else {
			languageLevelsInit = this.languageLevels;
		}

		//  isReadOnly=true for view, others for edit. Users can't eidt if locked, but they can add 
		if(this.isReadOnly || this.locked)
		{
			readonlyInit = true;
		}
		else{
			readonlyInit = false;
		}

		return {
			dataFrom: { languageLevelsModel: languageLevelsInit },
			readonly: this.isReadOnly,
			enableAdd:this.enableAddButton,
			hasLocked:this.locked,
			initItem:initAddItem,
			saving: false,
			dialogImageUrl: '',
			dialogVisible:false,
			//rules:{serviceYears:[{ required: false, pattern: /^\d{1,3}$/, message: '请输入1-3位整数', trigger: 'blur' }]},
			options: [
				{ value: '一般', label: '一般' },
				{ value: '良好', label: '良好' },
				{ value: '优秀', label: '优秀' },
			],
		};
	},
	props: { languageLevels: Array, id: Number, isReadOnly: Boolean, collapseStatus: String, locked: Boolean ,enableAddButton:Boolean },
	watch: {
		isReadOnly(newValue,oldV) {
			this.readonly = newValue;
			//this.hasLocked = newValue;
		},
		locked(newValue,oldV) {
			this.hasLocked = newValue;
		},
		
	},
	created() {
		if (this.languageLevels != null && this.languageLevels.length > 0) {
			this.languageLevels.forEach((item) => {
				if (item.certificateUrl != null && item.certificateUrl.length > 0) {
					this.downloadCertificatePictures(item, item.certificateUrl);
				}
			});

			this.$forceUpdate();
		}
	},
	computed: {
        newAddedAfterLocked() {
            return function(currentItem){
                return {employ_item_update:this.locked&&currentItem.newAdded&!this.isReadOnly};         
            }           
        },
		canEdit(){
			 return function(currentItem){
				 let returnValue = true;
				 if(this.hasLocked){
					 returnValue =currentItem.newAdded&&!this.isReadOnly?false:true;
				 }
				 else{
					returnValue = this.readonly;
				 }
                return returnValue;      
            }   
		} ,
		collapseSetting(){
			return function(){
				if(this.dataFrom.languageLevelsModel !=null && this.dataFrom.languageLevelsModel.length >0)
				{
					if(this.locked)
					{
						let newAddResults = this.dataFrom.languageLevelsModel.filter(item=>item.newAdded==true);
						if(newAddResults.length > 0)
						{
							if(this.initItem)
							{
								return {callpse_header_unfill:true};
							}
							else{
								return {callpse_header_newAdd:true};
							}
						}
						else
						{
							return {callpse_header_fill:true};
						}
						
					}
					else
					{
						if(this.initItem)
						{
							return {callpse_header_unfill:true};
						}
						else{
							return {callpse_header_fill:true};
						}
					}
					
				}
				else
				{
					return {callpse_header_unfill:true};
				}
				
			}
		},
    },
	methods: {
		save() {
			this.$refs.dataFrom.validate((valid) => {
				let self = this;
				if (valid) {
					// save data to DB
					// this.$emit("submitSpouseInfo",this.spouseInfoInit);
					let savinglanguageLevels = {};
					savinglanguageLevels.id = this.id;
					//savinglanguageLevels.languageLevels = this.dataFrom.languageLevelsModel;
					savinglanguageLevels.languageLevels = new Array();
					self.dataFrom.languageLevelsModel.forEach((item) => {
						savinglanguageLevels.languageLevels.push({ ...item });
					});
					savinglanguageLevels.languageLevels.forEach((item) => {
						item.certificateUrl = item.certificateTempUrl;
					});
					this.saving = true;
					this.$emit('updatePartialEmployeeInfo', { data: savinglanguageLevels }, (response) => {
						this.saving = false;
						if (response.success) {
							this.msgSuccess('修改成功');
							this.readonly = true;
						} else {
							this.msgError('修改失败,' + response.msg);
						}
					});
				} else {
					this.msgError('有非法输入或必填项未填写，请检查!');
				}
			});
		},
		edit() {
			this.readonly = false;
			if(this.hasLocked)
			{
				// 解锁员工
				this.hasLocked = false;
				this.$emit('unlock');
			}
			
		},
		addLanguage() {
			this.readonly = false;
			this.dataFrom.languageLevelsModel.push({
				languageName: '',
				level: null,
				lestening: null,
				speaking: null,
				writing: null,
				serviceYears: null,
				certificateUrl: null,
				certificateOriginalFileName: null,
				certificateTempUrl: null,
				newAdded: true,
			});
		},
		deleteLanguage(event, languageName) {
			let self = this;
			if (event.currentTarget.id == languageName) {
				this.$confirm('确定要删除吗?', '确认', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'error',
					// type: 'warning'
				})
					.then(() => {
						if (self.dataFrom.languageLevelsModel.length > 0) {
							let foundIndex = self.dataFrom.languageLevelsModel.findIndex((x) => x.languageName === languageName);
							if (foundIndex > -1) {
								self.dataFrom.languageLevelsModel.splice(foundIndex, 1);
							}
						}
					})
					.catch(() => {
						this.msgError('删除发生异常!');
					});
			}
		},
		serviceYearsValidator(rule, value, callback) {
			// 必需每个分支都要有返回，否则进不去保存的validate方法。
			if (!/^\d{1,3}$/.test(value)) {
				return callback(new Error('请输入1-3位的整数。'));
			} else {
				return callback();
			}
		},
		uploadCertifcatePicture(params, currentDataModel) {
			if (params != undefined) {
				this.formData = new FormData();
				this.formData.append('file', params.file);
				currentDataModel.certificateOriginalFileName = params.file.name;
				let self = this;
				uploadCertifcatePictureApi(this.formData)
					.then((response) => {
						if (response.status == 200) {
							let resData = response.data;
							currentDataModel.certificateTempUrl = response.headers['content-disposition'].split('=')[1];
							var blob = new Blob([resData], { type: 'application/octet-stream' });
							currentDataModel.certificateUrl = URL.createObjectURL(blob);
							this.$forceUpdate();
							this.$message.success('上传成功!');
						} else {
							this.$message.error('上传失败：');
						}
					})
					.catch((err) => {
						this.$message.error('上传失败：' + err);
					});
			}
		},

		handleAvatarSuccess(res, file) {
			//this.form.photo = URL.createObjectURL(file.name);
		},
		beforeAvatarUpload(file) {
			const isJPG = file.type === 'image/jpeg' || file.type === 'image/jpg';
			const isPng = file.type === 'image/png';
			const isBmp = file.type === 'application/bmp';
			const ispdf = file.type === 'application/pdf';
			const isLt50M = file.size / 1024 / 1024 < 50;
			if (!isJPG && !isPng && !ispdf && !isBmp) {
				this.$message.error('上传图片只能是 JPG,JPEG,PNG,BMP,PDF 格式!');
			}
			if (!isLt50M) {
				this.$message.error('上传图片大小不能超过 50MB!');
			}
			return  (isJPG || isPng || ispdf || isBmp) && isLt50M;

		},
		downloadCertificatePictures(currentComponent, filePath) {
			if (filePath != null && filePath.length > 0) {
				// download
				downloadCertificatePictureApi(filePath)
					.then((res) => {
						if (res) {
							let resData = res.data;
							var blob = new Blob([resData], { type: 'application/octet-stream' });
							currentComponent.certificateUrl = URL.createObjectURL(blob);
						} else {
							this.msgError(`后端下载文件出错 & ${e}`);
						}
					})
					.catch((e) => {
						this.loading = false;
						this.msgError(`后端下载文件出错 & ${e}`);
					});
			}
		},
		handlePictureCardPreview(file) {
			this.dialogImageUrl = file;
			this.dialogVisible = true;
		},
		closeDialog() {
			this.dialogImageUrl = '';
			this.dialogVisible = false;
		},
	},
};
</script>

<style>
</style>