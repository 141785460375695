<template>
	<el-collapse-item title="配偶信息" :name="this.collapseStatus" :class="collapseSetting()">
		<el-form class="employ" label-width="150px">
		<el-row>
			<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
				<el-form-item label="姓名">
					<el-input v-model="spouseInfoModel.name" :readonly="readonly" ref="spouseName"></el-input>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
				<el-form-item label="出生年月">
					<el-date-picker  v-model="spouseInfoModel.birthDay" value-format="yyyy-MM-dd" :readonly="readonly" align="left" type="date" placeholder="选择日期" >
					</el-date-picker>
				</el-form-item>
			</el-col>
	
			<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
				<el-form-item label="民族">
					<el-input v-model="spouseInfoModel.nation" :readonly="readonly"></el-input>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
				<el-form-item label="籍贯">
					<el-input v-model="spouseInfoModel.nativPlace" :readonly="readonly"></el-input>
				</el-form-item>
			</el-col>
		
			<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
				<el-form-item label="参加工作时间">
                    <el-date-picker v-model="spouseInfoModel.startWorkDate"  value-format="yyyy-MM-dd"  :readonly="readonly" align="left" type="date" placeholder="选择日期" >
					</el-date-picker>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
				<el-form-item label="政治面目">
					<el-input v-model="spouseInfoModel.policitalStatus" :readonly="readonly"></el-input>
				</el-form-item>
			</el-col>
	
			<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
				<el-form-item label="学历">
					<el-input v-model="spouseInfoModel.education" :readonly="readonly"></el-input>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
				<el-form-item label="专业技术职务">
					<el-input v-model="spouseInfoModel.majorPosition" :readonly="readonly"></el-input>
				</el-form-item>
			</el-col>
	
			<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
				<el-form-item label="毕业院校">
					<el-input v-model="spouseInfoModel.graduateSchool" :readonly="readonly"></el-input>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
				<el-form-item label="院校专业">
					<el-input v-model="spouseInfoModel.major" :readonly="readonly"></el-input>
				</el-form-item>
			</el-col>
		
			<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
				<el-form-item label="工作单位">
					<el-input v-model="spouseInfoModel.workUnit" :readonly="readonly"></el-input>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
				<el-form-item label="岗位">
					<el-input v-model="spouseInfoModel.workPosition" :readonly="readonly"></el-input>
				</el-form-item>
			</el-col>
	
			<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
				<el-form-item label="备注">
					<el-input v-model="spouseInfoModel.remarks" :readonly="readonly"></el-input>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
				<el-form-item label=" ">
					<el-button v-on:click="eidit" icon="el-icon-edit" plain v-bind:disabled="readonly"   type="primary">编辑</el-button>
					<el-button v-on:click="save" v-bind:disabled="readonly" :loading="saving" icon="el-icon" plain  type="primary">保存</el-button>
				</el-form-item>
			</el-col>
		</el-row>
		</el-form>
	</el-collapse-item>
</template>


<script>
export default {
	data() {
		let readonlyInit = true;
		let spouseInfoInit = null;
		if (this.spouseInfo == null) {
			spouseInfoInit = {
				name: '',
				birthDay: null,
				nation: null,
				nativPlace: null,
				startWorkDate: null,
				policitalStatus: null,
				education: null,
				majorPosition: null,
				graduateSchool: null,
				major: null,
				workUnit: null,
				workPosition: null,
				remarks: null,
			};
			readonlyInit = false;
		}
		else{
			spouseInfoInit = this.spouseInfo;
		}
		
		if(this.locked){
			readonlyInit=true;
		}
		else{
			readonlyInit = this.isReadOnly;
		}


		return {
			spouseInfoModel: spouseInfoInit,
			readonly: readonlyInit,
			saving:false,
            pickerOptions1: {
                    disabledDate(time) {
                        return time.getTime() > Date.now();
                    }
            }
		};
	},
	props: {spouseInfo:Object , id:Number,isReadOnly:Boolean, collapseStatus:String,locked:Boolean},
	watch: {
		isReadOnly(newValue,oldV) {
			this.readonly = newValue;
		},
		locked(newValue, oldV) {
			this.hasLocked = newValue;
			if (this.hasLocked) {
				this.readonly = true;
			}
			else
			{
				this.readonly = false;
			}
		},
	},
	computed:{
			collapseSetting(){
			return function(){
				if(this.spouseInfoModel.name != null && this.spouseInfoModel.name.length > 0 )
				{
					return {callpse_header_fill:true};
				}
				else
				{
					return {callpse_header_unfill:true};
				}
				
			}
		},
	},
	methods: {
		save() {
			// save data to DB
			// this.$emit("submitSpouseInfo",this.spouseInfoInit);
			let savingSpouseInfo = {};
			savingSpouseInfo.id = this.id;
			savingSpouseInfo.spouseInfo = this.spouseInfoModel;
			this.saving = true;
			this.$emit('updatePartialEmployeeInfo', { data: savingSpouseInfo }, (response) => {
				this.saving = false;
				if (response.success) {
					this.msgSuccess('修改成功');
					this.readonly = true;
				} else {
					this.msgError('修改失败,' + response.msg);
				}
			});
		},
		eidit() {
			this.readonly = false;
			this.$refs.spouseName.focus();
		},
	},
};
</script>

<style>
</style>