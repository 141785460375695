<template>
	<el-header class="layout-header" :height="setHeaderHeight" >
		<NavBarsIndex />
	</el-header>
</template>

<script>
import NavBarsIndex from '@/views/layout/navBars/index.vue';
import { Local } from '@/utils/storage.js';
export default {
	name: 'layoutHeader',
	components: { NavBarsIndex },
	data() {
		return {};
	},
	computed: {
		// 设置顶部 header 的具体高度
		setHeaderHeight() {
			let { isTagsview, layout } = this.$store.state.themeConfig.themeConfig;
			// if (isTagsview && layout !== 'classic' && !this.isUniapp) return '84px';
      if (isTagsview && layout !== 'classic') return '84px';
			else return '50px';
		},
    // isUniapp(){
    //   return Local.get("isUniapp")? true: false
    // },
	},
};
</script>
