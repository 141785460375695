import Vue from 'vue';
// import * as uni from './utils/uni.webview.1.5.2.js'
import VueAMap from 'vue-amap';
import App from './App.vue';
import router from './router';
import store from './store';

import 'vue-orgchart/dist/style.min.css'
import './icons' // icon

// import Particles from 'vue-particles';
import Element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/theme/index.scss';
import '@/theme/haoya.scss';
import { i18n } from '@/i18n/index.js';
import { globalComponentSize } from '@/utils/componentSize.js';
import { parseFilters, getQueryParam, parseTime, resetForm, addDateRange, selectDictLabel, selectDictLabels, download, handleTree, normalizer } from "@/utils/tools";
import {convertUTCTimeToLocalTime,convertUTCTimeToLocalDate} from "@/utils/formatTime";
import { getDicts } from "@/api/sys/sysdict";
import  './customerfonts/iconfont.css';

// 代码高亮插件
// import hljs from 'highlight.js'
// import 'highlight.js/styles/github-gist.css'
import {DICT_TYPE, getDictDataLabel, getDictDatas} from "@/utils/dict";

// import uni
// document.addEventListener("UniAppJSBridgeReady", function() {
// 	console.log("UniAppJSBridgeReady ................................ uni=");
// 	console.log(uni);
// 	Vue.prototype.myUni = uni
// 	console.log("UniAppJSBridgeReady ................................Vue.prototype.myUni.postMessage=");
// 	console.log(Vue.prototype.myUni);
// 	console.log(Vue.prototype.myUni.postMessage);
//
// });

// 全局方法挂载
Vue.prototype.getDicts = getDicts
Vue.prototype.parseTime = parseTime
Vue.prototype.resetForm = resetForm
Vue.prototype.addDateRange = addDateRange
Vue.prototype.selectDictLabel = selectDictLabel
Vue.prototype.selectDictLabels = selectDictLabels
Vue.prototype.download = download
Vue.prototype.handleTree = handleTree
Vue.prototype.normalizer = normalizer
Vue.prototype.parseFilters = parseFilters
Vue.prototype.getQueryParam = getQueryParam
Vue.prototype.convertUTCTimeToLocalTime = convertUTCTimeToLocalTime
Vue.prototype.convertUTCTimeToLocalDate = convertUTCTimeToLocalDate

Vue.prototype.getDictDatas = getDictDatas
Vue.prototype.getDictDataLabel = getDictDataLabel
Vue.prototype.DICT_TYPE = DICT_TYPE


Vue.prototype.msgSuccess = function (msg) {
	this.$message({ showClose: true, message: msg, type: "success" });
}

Vue.prototype.msgError = function (msg) {
	this.$message({ showClose: true, message: msg, type: "error" });
}

Vue.prototype.msgWarning = function (msg) {
	this.$message({ showClose: true, message: msg, type: "warning" });
}

Vue.prototype.msgInfo = function (msg) {
	this.$message({ showClose: true, message: msg, type: "info" });
}

// 引入高德地图
Vue.use(VueAMap);
// 加入密钥，否则报错 INVALID_USER_SCODE
window._AMapSecurityConfig = {
	securityJsCode: 'da5ec74e5d2b98b202da4c4850f2ceb2'
}
VueAMap.initAMapApiLoader({
	key: '7173d2b2a051c8d1cfa2d955e02b3b28',
	plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor', 'AMap.Geolocation'],
	// 默认高德 sdk 版本为 1.4.4
	uiVersion: '1.1',
	v: '1.4.4'
});

// Vue.use(hljs.vuePlugin);

// bpmnProcessDesigner 需要引入
// 引入highlight，代码高亮插件
// import { vuePlugin } from "@/highlight";
// import "highlight.js/styles/atom-one-dark-reasonable.css";
// Vue.use(vuePlugin);
import MyPD from "@/components/bpmnProcessDesigner/package/index.js";
Vue.use(MyPD);
import "@/components/bpmnProcessDesigner/package/theme/index.scss";
import "bpmn-js/dist/assets/diagram-js.css";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn.css";
import "bpmn-js/dist/assets/bpmn-font/css/bpmn-codes.css";

import Print from 'vue-print-nb'

Vue.use(Print);//注册
Vue.prototype.webPrint = Print;

import VideoPlayer from 'vue-video-player'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
Vue.use(VideoPlayer)


// Vue.use(Particles);
Vue.use(Element, { i18n: (key, value) => i18n.t(key, value), size: globalComponentSize });


Vue.config.productionTip = false;
Vue.prototype.bus = new Vue();

new Vue({
	router,
	store,
	i18n,
	render: (h) => h(App),
}).$mount('#app');

