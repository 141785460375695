import request from '@/utils/request'

// 查询用户管理列表
export function listUser(query) {
    return request({
        url: '/user/findAll',
        method: 'post',
        data: query
    })
}

// 查询用户管理详细
export function getUser(id) {
    return request({
        url: '/user/findOne?id=' + id,
        method: 'post'
    })
}

// 新增用户管理
export function addUser(data) {
    return request({
        url: '/user/add',
        method: 'post',
        data: data
    })
}

// 修改用户管理
export function updateUser(data) {
    return request({
        url: '/user/edit',
        method: 'post',
        data: data
    })
}

// 删除用户管理
export function delUser(id) {
    return request({
        url: '/user/remove',
        method: 'post',
        data: id
    })
}

// 导出用户管理
export function registUser(data) {
    return request({
        url: '/user/regist',
        method: 'post',
        data: data
    })
}

// 导出用户管理
export function exportUser(query) {
    return request({
        url: '/user/export',
        method: 'post',
        data: query
    })
}

export function updateAvatar(formData){
    return request({
        url: '/user/updateAvatar',
        method: 'post',
        data: formData,
    })
}
