<template>
  <div class="app-container">
    <el-row :gutter="35">
      <template></template>
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
        <span></span>
      </el-col>
    </el-row>
    <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="88px">
      <el-row :gutter="35">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">
          <el-form-item label="查询项：">
            <el-select v-model="queryParams.fieldText" placeholder="查询项" @change="fieldChange">
              <el-option
                  v-for="item in fieldList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <el-select v-model="queryParams.funcText" ref="queryFunc" placeholder="查询方式">
              <el-option
                  v-for="item in funcTextList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <el-input v-if="queryValueType == 'input'" v-model="queryParams.fieldValue" placeholder="查询内容"></el-input>
            <el-select v-if="queryValueType == 'select'" v-model="queryParams.fieldValue" placeholder="查询内容">
              <el-option
                  v-for="item in queryValueOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
            <el-date-picker v-if="queryValueType == 'date'"
                v-model="queryParams.fieldValue"
                align="right"
                type="date"
                placeholder="选择日期"
                format="yyyy 年 MM 月 dd 日"
                value-format="yyyy-MM-dd">
            </el-date-picker>
            <el-date-picker v-if="queryValueType == 'datetime'"
                v-model="queryParams.fieldValue"
                align="right"
                type="datetime"
                placeholder="选择日期时间"
              >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="">
            <el-button type="primary" round size="mini" @click="addQueryField">添加</el-button>
            <el-button type="primary" round size="mini" @click="addQueryOr">或者</el-button>
            <el-button type="primary" round size="mini" @click="addQueryAnd">并且</el-button>
            <el-button type="primary" round size="mini" @click="addQueryLeft"> ( </el-button>
            <el-button type="primary" round size="mini" @click="addQueryRight"> ) </el-button>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">
          <el-form-item label="查询条件：">
            <el-tag v-for="(tag,i) in whereTextTag"
              :key="i" closable :type="tag.type" @close="closeWhereTextTag(tag)">
              {{tag.name}}
            </el-tag>
          </el-form-item>
        </el-col>
<!--        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">-->
<!--          <el-form-item label="SQL：">-->
<!--            <el-tag v-for="(tag,i) in whereTextTag"-->
<!--                    :key="i" closable :type="tag.type">-->
<!--              {{tag.value}}-->
<!--            </el-tag>-->
<!--          </el-form-item>-->
<!--        </el-col>-->
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">
          <el-form-item label="排序项：">
            <el-select v-model="queryParams.orderValue" placeholder="排序项">
              <el-option
                  v-for="item in orderFieldList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <el-select v-model="queryParams.orderDirectValue" ref="queryFunc" placeholder="排序方式">
              <el-option
                  v-for="item in orderDirectList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <el-button type="primary" round size="mini" @click="addOrderField">添加</el-button>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">
          <el-form-item label="排序方式：">
            <el-tag v-for="(tag,i) in orderTextTag"
                    :key="i" closable :type="tag.type" @close="closeOrderTextTag(tag)">
              {{tag.name}}
            </el-tag>
          </el-form-item>
        </el-col>

      </el-row>
    </el-form>
    <el-row>
      <el-col class="mb0">
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
        <el-button type="primary" icon="el-icon-d-arrow-left" size="mini" @click="changeQueryType()">普通查询</el-button>
      </el-col>
    </el-row>

  </div>
</template>

<script>
import {FIELD_VALUE_TYPE, FUNC_SELECTS, getFuncSelect} from './dynamicField';

export default {
  name: "dynamicQuery",
  props: {
    fieldsIn: Array,
    orderFieldsIn: Array,
    // ts:
  },
  data() {
    return {
      funcTextMap : [],
      fieldList: [],
      funcTextList: [],
      form: {queryText:"", queryValue:""},
      queryValueType: "input",
      queryValueOptions: [],
      queryParams:{
        fieldText:"",
        funcText:"",
        fieldValue: '',
        orderValue: '',
        orderDirectValue:"",
      },
      selectedField: null,
      whereTextTag: [],
      orderFieldList: [],
      orderTextTag: [],
      orderDirectList:[
        {label: "升序", value: "asc"},
        {label: "降序", value: "desc"},
      ],
      pickerOptions: {
        // disabledDate(time) {
        //   return time.getTime() > Date.now();
        // },
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: '一周前',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }]
      },
    }
  },
  created() {
    console.log("created")
    this.fieldList = this.fieldsIn;
    this.orderFieldList = this.orderFieldsIn;
    // this.queryParams.funcTextList = [];

  },
  methods:{
    changeQueryType(){
      this.$emit('queryTypeToNormal',{});
    },
    addQueryField(){
      console.log(this.queryParams.fieldText);
      // 查找添加的field
      let field = this.selectedField;
      if(null == field || field == undefined){
        this.$message({
          showClose: true,
          message: '请先选择需要添加的查询项',
          type: 'error'
        });
        return;
      }

      let func = null;
      for(let j in field.funcList){
        if(field.funcList[j].value == this.queryParams.funcText){
          func = field.funcList[j];
          break;
        }
      }
      if(null == func){
        this.$message({
          showClose: true,
          message: '请选择查询方式',
          type: 'error'
        });
        return;
      }
      let tagValue = "";
      let fieldValue = "";
      if(field.type == FIELD_VALUE_TYPE.SELECT){
        for(let j in field.options){
          if(field.options[j].value == this.queryParams.fieldValue){
            tagValue = ""+field.options[j].label+"";
            fieldValue = ""+field.options[j].value+"";
            break;
          }
        }
      }else if(field.type == FIELD_VALUE_TYPE.DATE){
        tagValue = this.queryParams.fieldValue;
        fieldValue = this.queryParams.fieldValue;
      }else{
        tagValue = this.queryParams.fieldValue;
        fieldValue = this.queryParams.fieldValue;
      }
      if("" == tagValue){
        this.$message({
          showClose: true,
          message: '请输入查询内容',
          type: 'error'
        });
        return;
      }
      let tagField = {name:"", type:""};
      tagField.name = field.label +" "+ func.label +" '"+tagValue+"'";
      if(func.value.indexOf('like') >= 0){
        tagField.value = field.value +" "+ func.value +" '%"+fieldValue+"%'";
      }else{
        tagField.value = field.value +" "+ func.value +" '"+fieldValue+"'";
      }
      // let whereField = {name:"", type:""};
      // whereField.name = field.value +" "+ func.value +" '"+fieldValue+"'";
      this.whereTextTag.push(tagField);
    },
    addQueryOr(){
      this.whereTextTag.push({name:"或者", type:"success", value: "or"});
    },
    addQueryAnd(){
      this.whereTextTag.push({name:"并且", type:"success", value: "and"});
    },
    addQueryLeft(){
      this.whereTextTag.push({name:"(", type:"warning", value: "("});
    },
    addQueryRight(){
      this.whereTextTag.push({name:")", type:"warning", value: ")"});
    },
    closeWhereTextTag(tag){
      this.whereTextTag.splice(this.whereTextTag.indexOf(tag), 1);
      // console.log(this.whereTextTag)
    },
    addOrderField(){
      let orderField = null;
      for(let i in this.orderFieldList) {
        if (this.orderFieldList[i].value == this.queryParams.orderValue) {
          orderField = this.orderFieldList[i];
          break;
        }
      }
      let orderDirect = null;
      for(let i in this.orderDirectList) {
        if (this.orderDirectList[i].value == this.queryParams.orderDirectValue) {
          orderDirect = this.orderDirectList[i];
          break;
        }
      }
      let tagField = {name:"", value:"", type:""};
      tagField.name = orderField.label +" "+ orderDirect.label;
      tagField.value = orderField.value +" "+ orderDirect.value;
      this.orderTextTag.push(tagField);
    },
    closeOrderTextTag(tag){
      this.orderTextTag.splice(this.orderTextTag.indexOf(tag), 1);
      // console.log(this.orderTextTag)
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      let where = " 1 = 1 ";
      let isFirst = true;
      let isPreTagField = false;
      let tagBracket = [];
      for(let i in this.whereTextTag){
        if(isFirst){
          where = where + " and ";
          isFirst = false;
        }
        if(this.whereTextTag[i].value == "or" || this.whereTextTag[i].value == "and"){
          if(isPreTagField){
            isPreTagField = false;
          }else{
            this.$notify.error({
              title: '错误',
              message: '【'+this.whereTextTag[i].name+'】之前缺少查询项条件',
              duration: 0
            });
            this.whereTextTag[i].type = 'danger';
            return;
          }
        }else if(this.whereTextTag[i].value == "(" || this.whereTextTag[i].value == ")"){
          if(this.whereTextTag[i].value == "("){
            tagBracket.push("(");
          }else{
            if(tagBracket.length > 0) {
              tagBracket.pop();
            }else {
              this.$notify.error({
                title: '错误',
                message: '当前括号不匹配！',
                duration: 0
              });
              this.whereTextTag[i].type = 'danger';
              return;
            }
          }
        }else{
          if(isPreTagField){
            this.$notify.error({
              title: '错误',
              message: '【'+this.whereTextTag[i].name+'】之前缺少逻辑运算符“或者”或“并且”',
              duration: 0
            });
            this.whereTextTag[i].type = 'danger';
            return;
          }else{
            isPreTagField = true;
          }
        }
        where = where + this.whereTextTag[i].value+" ";
      }
      if(tagBracket.length > 0) {
        this.$notify.error({
          title: '错误',
          message: '括号不匹配，缺少 ）， 请检查',
          duration: 0
        });
        return;
      }
      let orderBy = "";
      isFirst = true;
      for(let i in this.orderTextTag){
        if(isFirst){
          orderBy = orderBy + this.orderTextTag[i].value+" ";
          isFirst = false;
        }else{
          orderBy = orderBy + " , " + this.orderTextTag[i].value+" ";
        }
      }

      console.log(where);
      this.$emit("queryDynamic", {dynamicWhere:where, orderBy: orderBy, refresh:false,isSuccess:true});
      // this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.whereTextTag = [];
      // this.resetForm("queryForm");
      // this.handleQuery();
    },
    fieldChange(value){
      for(let i in this.fieldList){
        if(this.fieldList[i].value == value){
          // this.$refs["queryFunc"].clear();
          this.selectedField = this.fieldList[i];
          // 设置查询函数
          this.queryParams.funcText = "";
          this.funcTextList = this.fieldList[i].funcList;
          // 设置查询值类型
          this.queryParams.fieldValue = "";
          this.queryValueType = this.fieldList[i].type;
          if(this.queryValueType == "select"){
            this.queryValueOptions = this.fieldList[i].options;
          }
          return;
        }
      }
    },
  },

}
</script>

<style scoped>

</style>
