<template>
<div>fddaa</div>
</template>

<script>
export default {
name: "cbTaxSettingEdit"
}
</script>

<style scoped>

</style>
