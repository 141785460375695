<template>
  <div>
    <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="auto">
      <el-form-item label="考勤日期" >
        <el-date-picker type="month" clearable size="small" v-model="queryParams.clockDateStart" value-format="yyyy-MM" placeholder="选择开始日期"/>
        至
        <el-date-picker type="month" clearable size="small" v-model="queryParams.clockDateEnd" value-format="yyyy-MM" placeholder="选择结束日期"/>
      </el-form-item>
      <el-form-item label="员工姓名">
        <el-input v-model="queryParams.empName" placeholder="请输入员工姓名" clearable size="small" @keyup.enter.native="handleQuery"/>
      </el-form-item>
      <el-form-item label="考勤状态">
        <el-select v-model="queryParams.attType" multiple placeholder="请选择考勤状态" clearable size="small">
          <el-option v-for="item in getDictDatas(DICT_TYPE.ATT_STATISTIC_TYPE)" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled">
          </el-option>
        </el-select>
        <!--          <el-input v-model="queryParams.attType" placeholder="请输入考勤状态" clearable size="small" @keyup.enter.native="handleQuery"/>-->
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">统计</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>
    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button type="primary" plain icon="el-icon-search" size="mini" @click="queryPreMonth" class="button-strap-gap" >上月统计</el-button>
        <el-button type="primary" plain icon="el-icon-search" size="mini" @click="queryThisMonth" class="button-strap-gap" >本月统计</el-button>
        <el-button type="primary" plain icon="el-icon-search" size="mini" @click="queryThisYear" class="button-strap-gap mb10" >今年统计</el-button>
        <el-button type="primary" plain icon="el-icon-search" size="mini" @click="queryPreYear" class="button-strap-gap" >去年统计</el-button>
        <el-button type="primary" plain icon="el-icon-download" size="mini" @click="exportExcel" class="button-strap-gap" >导出</el-button>
        <!--      <el-button type="primary" plain icon="el-icon-search" size="mini" @click="statisticByEmp" >考勤日统计</el-button>-->
      </el-col>
      <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>
    </el-row>
    <el-table id="table_month_excel" ref="staticEmp" v-loading="staticEmpLoading" :data="statisticEmployList" @selection-change="handleSelectionChange" height="518" stripe border>
      <el-table-column type="selection" width="35" align="center"/>
      <el-table-column label="公司名称" align="center" width="160px">
        <template slot-scope="scope">{{ getCompanyName(scope.row.companyId)}}</template>
      </el-table-column>
      <el-table-column label="部门名称" align="center" prop="depName" width="160px">
        <template slot-scope="scope">{{ getDepartmentName(scope.row.depId)}}</template>
      </el-table-column>
      <el-table-column label="员工姓名" align="center" prop="empName" width="80px" fixed/>
      <!--            <el-table-column label="日期" align="center">-->
      <el-table-column align="center" v-for="day in startEndDays" :key="day" :label="day" width="130px" >
        <template slot-scope="scope">
          <template v-if="scope.row[day]">
            <span>{{scope.row[day]["lateTimes"]? "迟到"+scope.row[day]["lateTimes"]+"次,":""}}</span>
            <span>{{scope.row[day]["earlyTimes"]? "早退"+scope.row[day]["earlyTimes"]+"次,":""}}</span>
            <span>{{scope.row[day]["leaveTimes"]? "请假"+scope.row[day]["leaveTimes"]+"次,":""}}</span>
            <span>{{scope.row[day]["absentDay"]? "旷工"+(scope.row[day]["absentDay"]).toFixed(2)+"天":""}}</span>
          </template>
          <template v-else>
            <span></span>
          </template>
        </template>
      </el-table-column>
      <!--            </el-table-column>-->
    </el-table>

  </div>
</template>

<script>
import {
  listAttStatisticDay, count, clockOnOff, getAttStatisticDay, updateAttStatisticDay,
  exportAttStatisticDay, statisticByEmpMonth } from "@/api/attendance/attStatisticDay";
import { showLoading, hideLoading } from '@/utils/reqloading';
import {formatDate} from "@/utils/formatTime";
import {getFirstLastDayOfMonth, getDayAll, getMonthAll} from "@/utils/dateTimeUtils";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { LOAD_CHILDREN_OPTIONS } from '@riophae/vue-treeselect'
import RightToolbar from "@/components/RightToolbar"
import permission from '@/directive/permission/index.js' // 权限判断指令
import Pagination from "@/components/Pagination";
import {checkRole} from "@/utils/role";
import {getAllOrgTreeWithParent} from "../../org/components/orgtree";
import ClockDetail from "./clockDetail";
import {DICT_TYPE, getDictDatas} from "@/utils/dict";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";


export default {
  name: "statisticMonth",
  props:{
    depId: Array,
    companyList: Array,
    departmentList: Array,
  },
  components: {
    RightToolbar,
    ClockDetail,
  },
  directives: { permission },
  data() {
    return {
      statisticEmployList: [],
      startEndDays: [],
      staticEmpLoading: false,
      absentCount: 0,
      attStatisticDayList: null,
      clockDetailData: null,
      searchLoading: false,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      open: false,
      // 查询参数
      queryParams: {
        empName: null,
        attType: null,
        clockDateStart: null,
        clockDateEnd: null,
      },
    }
  },
  created() {
    // const [start, end] = getFirstLastDayOfMonth(new Date());
    // this.queryParams.clockDateStart = start;
    // this.queryParams.clockDateEnd = end;
  },
  methods: {
    getList(){
      this.statisticByEmp();
    },
    statisticByEmp() {
      this.staticEmpLoading = true;
      // let params = this.getQuery();
      let start = this.queryParams.clockDateStart;
      let end = this.queryParams.clockDateEnd;
      if(start.length < 8){
        start = start + "-01";
      }
      if(end.length < 8){
        const [first, last] = getFirstLastDayOfMonth(end);
        console.log(last)
        end = last;
      }
      let params = {
        start: start, end: end, depIdList: this.depId,
        empName: this.queryParams.empName, attType: this.queryParams.attType
      }
      console.log(params);
      this.startEndDays = getMonthAll(this.queryParams.clockDateStart, this.queryParams.clockDateEnd);
      statisticByEmpMonth(params).then(response => {
        let records = response.data;
        console.log("aabb")
        console.log(records);
        this.staticEmpLoading = false;
        if(!records || records.length <= 0){
          this.msgInfo("没有考勤统计信息");
          this.statisticEmployList = [];
          //解决表格列乱排布问题
          let self = this
          this.$nextTick(function () {
            self.$refs.staticEmp.doLayout();
          })
          return;
        }
        let keys = Object.keys(records);
        let list = [];
        if (!keys || keys.length <= 0) {
          this.msgInfo("没有考勤统计信息");
          this.statisticEmployList = [];
          // //解决表格列乱排布问题
          // let self = this
          // this.$nextTick(function () {
          //   self.$refs.staticEmp.doLayout();
          // })
          return;
        }
        keys.forEach(k => {
          let empList = records[k];
          console.log(empList);
          empList.forEach(e => {
            list.push(e);
          });
        });
        console.log(list);
        // this.total = response.data.total;
        this.statisticEmployList = list;
        //解决表格列乱排布问题
        let self = this
        this.$nextTick(function () {
          self.$refs.staticEmp.doLayout();
        })
        this.staticEmpLoading = false;
      });

    },
    queryThisYear(){
      let date = new Date();
      this.queryParams.clockDateStart = date.getFullYear()+ "-01-01";
      this.queryParams.clockDateEnd = date.getFullYear()+"-12-31";
      this.getList();
    },
    queryPreYear(){
      let date = new Date();
      let preYear = date.getFullYear() - 1;
      this.queryParams.clockDateStart = preYear + "-01-01";
      this.queryParams.clockDateEnd = preYear + "-12-31";
      this.getList();
    },
    queryPreMonth(){
      let date = new Date();
      let preMonth = new Date(date.setMonth(date.getMonth() - 1));
      const [start, end] = getFirstLastDayOfMonth(preMonth);
      this.queryParams.clockDateStart = start;
      this.queryParams.clockDateEnd = end;
      this.getList();
    },
    queryThisMonth(){
      const [start, end] = getFirstLastDayOfMonth(new Date());
      this.queryParams.clockDateStart = start;
      this.queryParams.clockDateEnd = end;
      this.getList();
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },
    normalizerDepartment(node) {
      return this.normalizer(node);
    },
    getDepartmentName(id){
      //console.log(id);
      for(let i = 0; i < this.departmentList.length; i++){
        if(id == this.departmentList[i].id) return this.departmentList[i].name;
      }
      return "";
    },
    getCompanyName(id){
      for(let i = 0; i < this.companyList.length; i++){
        if(id == this.companyList[i].id) return this.companyList[i].name;
      }
      return "";
    },
    exportExcel(){
      this.$confirm("确定导出到Excel？","导出提示",{
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info",
      }).then(()=>{
        setTimeout(()=>{
          var xlsxParam = { raw: true }  // 导出的内容只做解析，不进行格式转换
          let tables = document.getElementById('table_month_excel');   //这里是绑定元素，输入你要导出的table表格的id
          // 从表生成工作簿对象 :使用了fixed,导致导出数据叠加了 去除其中的el-table__fixed
          var fix = tables.querySelector('.el-table__fixed');
          let table_book = null;
          if (fix) {
            table_book = XLSX.utils.table_to_book(tables.removeChild(fix),xlsxParam);
            tables.appendChild(fix);
          } else {
            table_book = XLSX.utils.table_to_book(tables,xlsxParam);
          }
          // let table_book = XLSX.utils.table_to_book(tables,xlsxParam);
          const table_write = XLSX.write(table_book, {
            bookType: "xlsx",
            bookSST: true,
            type: "array",
          });
          try {
            let title = "考勤月统计";
            FileSaver.saveAs(
                new Blob([table_write], { type: "application/octet-stream" }),
                `${title}.xlsx`   //这里是文件名
            );
          } catch (e) {
            if (typeof console !== "undefined") console.log(e, table_write);
          }
          return table_write;
        },1000)
      });
    },

  },

}
</script>

<style scoped>

</style>
