import { render, staticRenderFns } from "./attendanceGroupEdit.vue?vue&type=template&id=227c956f&scoped=true"
import script from "./attendanceGroupEdit.vue?vue&type=script&lang=js"
export * from "./attendanceGroupEdit.vue?vue&type=script&lang=js"
import style0 from "./attendanceGroupEdit.vue?vue&type=style&index=0&id=227c956f&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "227c956f",
  null
  
)

export default component.exports