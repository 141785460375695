<template>
<div class="app-container">
  <el-form ref="form" :model="form" :rules="rules" label-width="60px" >
    <el-row :gutter="24">
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" >
        <el-form-item label="名称" prop="name">
          <el-input v-model="form.name" :readonly="isReadOnly" placeholder="请输入名称"/>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="24">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" >
        <el-form-item label="内容" prop="content">
          <el-input type="textarea" rows="3" style="width:100%;" :readonly="isReadOnly" v-model="form.content" placeholder="请输入考核内容描述"/>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row v-if="!isReadOnly" :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button type="primary" plain icon="el-icon-plus" size="mini"
            @click="handleAddItem" v-permission="['/kpi/template/add','/kpi/template/edit']"
        >新增项目
        </el-button>
      </el-col>
    </el-row>
    <el-table :data="form.items" style="width: 100%;margin-bottom: 20px;" row-key="id" :indent='indents'
              border default-expand-all :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
      <el-table-column prop="level" label="层级" width="168">
        <template slot-scope="scope">
          <span>{{scope.row.level}}级</span>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="考核项目(指标)" width="168">
        <template slot-scope="scope">
          <el-input v-if="!isReadOnly" v-model="scope.row.name" placeholder="请输入考核项目（指标）"></el-input>
          <span v-else>{{scope.row.name}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="score" label="权重(分)" width="88">
        <template slot-scope="scope">
          <el-input v-if="!isReadOnly" v-model="scope.row.score" type="number" placeholder="请输入权重(分)"></el-input>
          <span v-else>{{scope.row.score}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="content" label="考核内容及定义" min-width="198">
        <template slot-scope="scope">
          <el-input v-if="!isReadOnly" type="textarea" rows="2" v-model="scope.row.content" placeholder="请输入考核内容及定义"></el-input>
          <span v-else>{{scope.row.content}}</span>
        </template>
      </el-table-column>
      <el-table-column v-if="!isReadOnly" label="操作" align="center" class-name="small-padding fixed-width" width="136">
        <template slot-scope="scope">
          <el-button size="mini" type="text" icon="el-icon-plus"
                     @click="handleAddSubItem(scope.row)" v-permission="['/kpi/template/add','/kpi/template/edit']"
          >子项</el-button>
          <el-button size="mini" type="text" icon="el-icon-delete"
              @click="handleDeleteItem(scope.row)" v-permission="['/kpi/template/add','/kpi/template/edit']"
          >删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-form>
  <div slot="footer" class="dialog-footer" style="text-align: center">
    <el-button v-if="!isReadOnly" type="primary" @click="submitForm" :loading="saving"
               v-permission="['/kpi/template/add', '/kpi/template/edit']">保 存</el-button>
    <el-button @click="cancel">关 闭</el-button>
  </div>
</div>
</template>

<script>
import {listKpiTemplate,getKpiTemplate,delKpiTemplate,addKpiTemplate,
  updateKpiTemplate,exportKpiTemplate } from "@/api/kpi/template";
import permission from '@/directive/permission/index.js'; // 权限判断指令

export default {
  name: "kpiTemplateEdit",
  directives: { permission },

  data() {
    return {
      indents: 18,
      // 遮罩层
      saving: false,
      loading: true,
      // 查询参数
      where: {
        query: '',
        filters: []
      },
      isReadOnly: true,
      // 表单参数
      form: {
      },
      defaultProps: {
        children: "children",
        label: "name"
      },
      // 表单校验
      rules: {
        name: [
          {
            required: true,
            message: "名称不能为空", trigger: "blur" }
        ],
        content: [
          {
            required: true,
            message: "内容描述不能为空", trigger: "blur" }
        ],
      }
    };
  },
  created() {
  },
  mounted() {
    if (this.$route.query) {
      const {id, readOnly} = this.$route.query;
      this.form = {};
      this.form.id = id;
      if(readOnly) this.isReadOnly = readOnly;
      else this.isReadOnly = false;
    }

    this.load();
  },

  methods: {
    genId(){
      if(this.form.maxItemId){
        this.form.maxItemId++;
      }else{
        this.form.maxItemId = 1;
      }
      return this.form.maxItemId;
    },
    handleAddItem(){
      if(!this.form.items){
        this.$set(this.form,"items", []);
      }
      this.form.items.push({id:this.genId(),level:1,name:"",score:0,content:"",children:[]});
    },
    handleAddSubItem(row){
      if(!row.children){
        this.$set(row,"children", []);
      }
      let defautScore = row.score;
      let child = row.children
      if(child.length>0){
        for(let i = 0; i < child.length; i++){
          defautScore -= child[i].score;
        }
      }
      row.children.push({id:this.genId(),level:row.level+1,name:"",score:defautScore,content:"",children:[]});
    },
    handleDeleteItem(row){
      this.deleteSubItem(this.form.items,row);
      // var index = this.form.items.indexOf(row);
      // if (index > -1) {
      //   this.form.items.splice(index, 1);
      // }
    },
    deleteSubItem(items, row) {
      console.log(items);
      var index = items.indexOf(row);
      if (index > -1) {
        items.splice(index, 1);
        return true;
      }else{
        for(let i =0; i<items.length; i++) {
          if(this.deleteSubItem(items[i].children, row)){
            return true;
          }
        }
        return false;
      }
    },
    // deleteSubItem(items, row){
    //   if(item && item.children && item.children.length>0){
    //     let index = item.children.indexOf(row);
    //     if (index > -1) {
    //       this.form.items.splice(index, 1);
    //     }else{
    //       let childs = item.children;
    //       for(let i =0; i<childs.length; i++) {
    //         return this.deleteSubItem(subItem, row);
    //       }
    //     }
    //   }
    // },
    load() {
      if(this.form && this.form.id){
        this.handleUpdate(this.form.id);
      }else{
        this.handleAdd();
      }
    },

    // 取消按钮
    cancel() {
      // this.$emit("close", {refresh:false,isSuccess:true});
      this.bus.$emit('onCurrentContextmenuClick', {
        id: 1,
        path: this.$route.path,
      });
    },
    // 表单重置
    reset() {
      this.form = {};
      this.resetForm("form");
    },

    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.form = {}
    },
    /** 修改按钮操作 */
    handleUpdate(id) {
      this.reset();
      getKpiTemplate(id).then(response => {
        this.form = response.data;
      });
    },
    // copyItems(items){
    //   let id = 1;
    //   if(items && items.length > 0){
    //     let rt = [];
    //     for(let i = 0; i < items.length; i++){
    //       let item = {id:id++,level:items[i].level,name:items[i].name,score:items[i].score,content:items[i].content};
    //       item.children = this.copyItems(items[i].children);
    //       rt.push(item);
    //     }
    //     return rt;
    //   }else{
    //     return [];
    //   }
    // },
    validateScore(item){
      if(item && item.children && item.children.length>0){
        let sumScore = 0;
        let childs = item.children;
        for(let i =0; i<childs.length; i++){
          const subValid = this.validateScore(childs[i])
          if(subValid){
            return subValid;
          }
          sumScore = Number(sumScore) + Number(childs[i].score);
        }
        if(sumScore != item.score){
          return "考核项【"+item.name+"】的权重分为"+item.score+"，不等于子项的权重分之和"+sumScore+"，请修正。"
        }else{
          return null;
        }
      }else{
        return null;
      }
    },
    /** 提交按钮 */
    submitForm() {
      this.form.companyId = this.$store.getters.userInfo.companyId;
      this.$refs["form"].validate(valid => {
            if(valid) {
              console.log(this.form);
              const items = this.form.items;
              if(items && items.length>0){
                for(let i = 0; i < items.length; i++){
                  const v = this.validateScore(items[i]);
                  if(v) {
                    this.msgError(v);
                    return;
                  }
                }
              }
              this.saving = true;
              if (this.form.id != null) {
                updateKpiTemplate(this.form).then(response => {
                  this.msgSuccess("修改成功");
                  this.form = response.data;
                  this.saving = false;
                  // this.$emit("close", {refresh:true,isSuccess:true});
                  // this.reset();
                });
              } else {
                addKpiTemplate(this.form).then(response => {
                  this.msgSuccess("新增成功");
                  this.form = response.data;
                  this.saving = false;
                  // this.$emit("close", {refresh:true,isSuccess:true});
                  // this.reset();
                });
              }
            }
          }
      );
    },

  },
}
</script>

<style scoped>

</style>
