<template>
  <div>
    <OvertimeEdit type="apply" :isShowClose="true"></OvertimeEdit>
  </div>
</template>

<script>
import OvertimeEdit from "./overtimeEdit";
export default {
  name: "apply",
  components:{
    OvertimeEdit,
  }
}
</script>

<style scoped>

</style>
