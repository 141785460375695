import request from '@/utils/request'
// Find all test papers
export function findAllTestPaperResults(query) {
    return request({
        url: '/TrainingTestResults/findAll',
        method: 'post',
        data: query
    })
}

// 导出培训签到统计信息
export function exportTrainingTestPaperScores(query) {
    return request({
        url: '/TrainingTestResults/exportTrainingTestPaperScores',
        method: 'post',
        data: query,
        responseType: 'blob',
    })
}

// Find  test paper
export function findTestPaperResults(id) {
    return request({
        url: '/TrainingTestResults/findOne?id=' + id,
        method: 'post',
    })
}


// Add new test paper
export function addTestedPaperResults(data) {
    return request({
        url: '/TrainingTestResults/add',
        method: 'post',
        data: data
    })
}


// delete test paper
export function deleteTestResult(ids) {
    return request({
        url: '/TrainingTestResults/remove',
        method: 'post',
        data: ids
    })
}

