// 定义内容
export default {
	router: {
		home: 'home',
		system: 'system',
		systemMenu: 'systemMenu',
		systemUser: 'systemUser',
		limits: 'limits',
		limitsFrontEnd: 'FrontEnd',
		limitsFrontEndPage: 'FrontEndPage',
		limitsFrontEndBtn: 'FrontEndBtn',
		limitsBackEnd: 'BackEnd',
		limitsBackEndEndPage: 'BackEndEndPage',
		menu: 'menu',
		menu1: 'menu1',
		menu11: 'menu11',
		menu12: 'menu12',
		menu121: 'menu121',
		menu122: 'menu122',
		menu13: 'menu13',
		menu2: 'menu2',
		funIndex: 'function',
		funTagsView: 'funTagsView',
		funSignCanvas: 'Online signature',
		funCountup: 'countup',
		funEchartsTree: 'echartsTree',
		funSelector: 'funSelector',
		funWangEditor: 'wangEditor',
		funCropper: 'cropper',
		funMindMap: 'G6 MindMap',
		funQrcode: 'qrcode',
		funEchartsMap: 'EchartsMap',
		funPrintJs: 'PrintJs',
		funClipboard: 'Copy cut',
		funScreenShort: 'screenCapture',
		pagesIndex: 'pages',
		pagesFiltering: 'Filtering',
		pagesFilteringDetails: 'FilteringDetails',
		pagesFilteringDetails1: 'FilteringDetails1',
		pagesIocnfont: 'iconfont icon',
		pagesElement: 'element icon',
		pagesAwesome: 'awesome icon',
		pagesCityLinkage: 'CityLinkage',
		pagesFormAdapt: 'FormAdapt',
		pagesListAdapt: 'ListAdapt',
		pagesWaterfall: 'Waterfall',
		pagesSteps: 'Steps',
		chartIndex: 'chartIndex',
		personal: 'personal',
		tools: 'tools',
		layoutLinkView: 'LinkView',
		layoutIfameView: 'IfameView',
	},
	user: {
		title0: 'Component size',
		title1: 'Language switching',
		title2: 'Menu search',
		title3: 'Layout configuration',
		title4: 'news',
		title5: 'Full screen on',
		title6: 'Full screen off',
		dropdownDefault: 'default',
		dropdownMedium: 'medium',
		dropdownSmall: 'small',
		dropdownMini: 'mini',
		dropdown1: 'home page',
		dropdown2: 'Personal Center',
		dropdownChgPwd: 'Change Password',
		dropdown3: '404',
		dropdown4: '401',
		dropdown5: 'Log out',
		dropdown6: 'Code warehouse',
		searchPlaceholder: 'Menu search: support Chinese, routing path',
		newTitle: 'notice',
		newBtn: 'All read',
		newGo: 'Go to the notification center',
		newDesc: 'No notice',
		logOutTitle: 'Tips',
		logOutMessage: 'This operation will log out. Do you want to continue?',
		logOutConfirm: 'determine',
		logOutCancel: 'cancel',
		logOutExit: 'Exiting',
		logOutSuccess: 'Exit successfully!',
	},
	tagsView: {
		refresh: 'refresh',
		close: 'close',
		closeOther: 'closeOther',
		closeAll: 'closeAll',
		fullscreen: 'fullscreen',
	},
	notFound: {
		foundTitle: 'Wrong address input, please re-enter the address~',
		foundMsg: 'You can check the web address first, and then re-enter or give us feedback.',
		foundBtn: 'Back to home page',
	},
	noAccess: {
		accessTitle: 'You are not authorized to operate~',
		accessMsg: 'Contact information: add QQ group discussion 665452019',
		accessBtn: 'Reauthorization',
	},
	layout: {
		configTitle: 'Layout configuration',
		oneTitle: 'Global Themes',
		twoTitle: 'Menu / top bar',
		twoTopBar: 'Top bar background',
		twoMenuBar: 'Menu background',
		twoColumnsMenuBar: 'Column menu background',
		twoTopBarColor: 'Top bar default font color',
		twoMenuBarColor: 'Menu default font color',
		twoColumnsMenuBarColor: 'Default font color bar menu',
		twoIsTopBarColorGradual: 'Top bar gradient',
		twoIsMenuBarColorGradual: 'Menu gradient',
		twoIsMenuBarColorHighlight: 'Menu font highlight',
		threeTitle: 'Interface settings',
		threeIsCollapse: 'Menu horizontal collapse',
		threeIsUniqueOpened: 'Menu accordion',
		threeIsFixedHeader: 'Fixed header',
		threeIsClassicSplitMenu: 'Classic layout split menu',
		threeIsLockScreen: 'Open the lock screen',
		threeLockScreenTime: 'screen locking(s/s)',
		fourTitle: 'Interface display',
		fourIsShowLogo: 'Sidebar logo',
		fourIsBreadcrumb: 'Open breadcrumb',
		fourIsBreadcrumbIcon: 'Open breadcrumb icon',
		fourIsTagsview: 'Open tagsview',
		fourIsTagsviewIcon: 'Open tagsview Icon',
		fourIsCacheTagsView: 'Enable tagsview cache',
		fourIsSortableTagsView: 'Enable tagsview drag',
		fourIsFooter: 'Open footer',
		fourIsGrayscale: 'Grey model',
		fourIsInvert: 'Color weak mode',
		fourIsWartermark: 'Turn on watermark',
		fourWartermarkText: 'Watermark copy',
		fiveTitle: 'Other settings',
		fiveTagsStyle: 'Tagsview style',
		fiveAnimation: 'page animation',
		fiveColumnsAsideStyle: 'Column style',
		fiveColumnsAsideLayout: 'Column layout',
		sixTitle: 'Layout switch',
		sixDefaults: 'One',
		sixClassic: 'Two',
		sixTransverse: 'Three',
		sixColumns: 'Four',
		tipText: 'Click the button below to copy the layout configuration to `/src/store/modules/themeConfig.js` It has been modified in.',
		copyText: 'replication configuration',
		copyTextSuccess: 'Copy succeeded!',
		copyTextError: 'Copy failed!',
	},
};
