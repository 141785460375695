import request from '@/utils/request'

// 查询排班列表
export function listAttGrpClsSch(query) {
    return request({
        url: '/attendance/attGrpClsSch/findAll',
        method: 'post',
        data: query
    })
}

// 查询排班详细
export function getAttGrpClsSch(id) {
    return request({
        url: '/attendance/attGrpClsSch/findOne?id=' + id,
        method: 'post'
    })
}

// 新增排班
export function addAttGrpClsSch(data) {
    return request({
        url: '/attendance/attGrpClsSch/add',
        method: 'post',
        data: data
    })
}

// 修改排班
export function updateAttGrpClsSch(data) {
    return request({
        url: '/attendance/attGrpClsSch/edit',
        method: 'post',
        data: data
    })
}

// 删除排班
export function delAttGrpClsSch(id) {
    return request({
        url: '/attendance/attGrpClsSch/remove',
        method: 'post',
        data: id
    })
}

// 导出排班
export function exportAttGrpClsSch(query) {
    return request({
        url: '/attendance/attGrpClsSch/export',
        method: 'post',
        data: query
    })
}
