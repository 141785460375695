import request from '@/utils/request'

// 查询离职申请列表
export function listSelfFlow(query) {
    return request({
        url: '/bpm/selfFlow/findAll',
        method: 'post',
        data: query
    })
}
// 查询转正申请详细
export function initApply(type) {
    return request({
        url: '/bpm/selfFlow/initApply?type='+type,
        method: 'get'
    })
}

// 修改转正申请
export function completeTask(data) {
    return request({
        url: '/bpm/selfFlow/completeTask',
        method: 'post',
        data: data
    })
}
// 查询离职申请详细
export function getSelfFlow(id) {
    return request({
        url: '/bpm/selfFlow/findOne?id=' + id,
        method: 'post'
    })
}

export function getByProcessInstanceId(processInstanceId) {
    return request({
        url: '/bpm/selfFlow/getByProcessInstanceId?processInstanceId=' + processInstanceId,
        method: 'post'
    })
}

// 新增离职申请
export function addSelfFlow(data) {
    return request({
        url: '/bpm/selfFlow/add',
        method: 'post',
        data: data
    })
}

// // 修改离职申请
// export function updateSelfFlow(data) {
//     return request({
//         url: '/bpm/selfFlow/edit',
//         method: 'post',
//         data: data
//     })
// }

// 删除离职申请
export function delSelfFlow(id) {
    return request({
        url: '/bpm/selfFlow/remove',
        method: 'post',
        data: id
    })
}

// 导出离职申请
export function exportSelfFlow(query) {
    return request({
        url: '/bpm/selfFlow/export',
        method: 'post',
        data: query
    })
}
