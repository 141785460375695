import request from '@/utils/request'
// Find all suggestion box
export function findAllSuggestions(query) {
    return request({
        url: '/SuggestionBox/findAll',
        method: 'post',
        data: query
    })
}

// find one suggestion
export function getSuggestion(id) {
    return request({
        url: '/SuggestionBox/findOne?id=' + id,
        method: 'post'
    })
}

// Add new suggestion
export function addSuggestion(data) {
    return request({
        url: '/SuggestionBox/add',
        method: 'post',
        data: data
    })
}

// update suggestion
export function updateSuggestion(data) {
    return request({
        url: '/SuggestionBox/edit',
        method: 'post',
        data: data
    })
}

// delete suggestion
export function deleteSuggestion(ids) {
    return request({
        url: '/SuggestionBox/remove',
        method: 'post',
        data: ids
    })
}


// 上传图片
export function uploadPicture(data) {
    return request({
        url: '/SuggestionBox/uploadPicture',
        method: 'post',
        data: data
    })
}

// 下载图片
export function downloadPicture(filePath) {
    return request({
        url: '/SuggestionBox/downloadPicture?filePath='+filePath,
        method: 'get',
        responseType: 'blob'
    })
}

// 删除图片
export function deletePicture(filePath) {
    return request({
        url: '/SuggestionBox/deletePicture?filePath='+filePath,
        method: 'post',
    })
}
