import request from '@/utils/request'
import {parseFilters, handleTree} from "@/utils/tools";

export function updateCompanyDepartment(data){
    return request({
        url: '/org/planCount/updateCompanyDepartment',
        method: 'post',
        data: data
    })

}

export function updatePositions(data){
    return request({
        url: '/org/planCount/updatePositions',
        method: 'post',
        data: data
    })

}
