import request from '@/utils/request'

// 新增培训信息
export function addTraining(data) {
    return request({
        url: '/MyTraining/add',
        method: 'post',
        data: data
    })
}

// 更新培训信息
export function updateMyTraining(data) {
    return request({
        url: '/MyTraining/edit',
        method: 'post',
        data: data
    })
}

// 查找培训信息
export function findTrainingRecord(data) {
    return request({
        url: '/MyTraining/findTrainingRecord?userId='+data.userId+'&courseId='+data.courseId,
        method: 'post',
        data: data
    })
}

// 查找培训信息
export function getCheckInList(data) {
    return request({
        url: '/MyTraining/findCheckInList?courseId='+data.courseId,
        method: 'post',
        data: data
    })
}

// 查找所有的培训信息
export function findAllTrainings(query) {
    return request({
        url: '/MyTraining/findAll',
        method: 'post',
        data: query
    })
}

// 统计所有的培训信息
export function calculateTraining(query) {
    return request({
        url: '/MyTraining/calculateTraining',
        method: 'post',
        data: query
    })
}


// 导出培训签到统计信息
export function exportTrainingStatistics(query) {
    return request({
        url: '/MyTraining/exportTrainingStatistics',
        method: 'post',
        data: query,
        responseType: 'blob',
    })
}

// 导出培训签到信息
export function exportTrainingDetail(query) {
    return request({
        url: '/MyTraining/exportTrainingDetail',
        method: 'post',
        data: query,
        responseType: 'blob',
    })
}

// 导出培训签到信息
export function exportTrainingCheckInList(query) {
    return request({
        url: '/MyTraining/exportTrainingCheckInList?trainingId='+query.trainingId,
        method: 'post',
        data: query,
        responseType: 'blob',
    })
}

// delete training
export function deleteTraining(ids) {
    return request({
        url: '/MyTraining/remove',
        method: 'post',
        data: ids
    })
}
