<template>
	<div>
		<el-form :model="queryParameters" ref="queryParameters" class="employ" label-width="100px">
			<el-row :gutter="24">
				<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
					<el-form-item label="公司">
						<el-select v-model="companySelect" clearable @clear="handleClearable" placeholder="请选择"
							collapse-tags ref="companySelected">
							<el-option :value="queryParameters.companyId" :label="companySelect" style="height: auto">
								<el-tree :data="companyOptions" :props="defaultProps" :expand-on-click-node="false"
									ref="tree" show-checkbox node-key="id" default-expand-all
									@node-click="handleNodeClick" @check="handleNodeClick" highlight-current/>
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
					<el-form-item label="起始时间">
						<el-date-picker v-model="queryParameters.startDate" value-format="yyyy-MM-dd 00:00:00"
							align="left" type="date" placeholder="选择日期">
						</el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
					<el-form-item label="终止时间">
						<el-date-picker v-model="queryParameters.endDate" value-format="yyyy-MM-dd 24:00:00"
							align="left" type="date" placeholder="选择日期">
						</el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
					<el-form-item label="计划名称">
						<el-input v-model="queryParameters.name" placeholder="培训计划名称" clearable />
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
					<el-form-item label=" " style="margin-bottom:0px !important;">
						<el-button v-on:click="query" size="mini" icon="el-icon-search" plain
							type="primary">查询</el-button>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<el-row :gutter="10" class="mb8">
			<el-col :span="1.5" style="margin-top:15px;">
				<el-button type="primary" plain icon="el-icon-plus" size="mini" @click="handleAdd"
					v-permission="['/TrainingPlan/add']">新增 </el-button>
			</el-col>
			<el-col :span="1.5" style="margin-top:15px;">
				<el-button type="success" plain icon="el-icon-edit" size="mini" :disabled="!single"
					@click="handleUpdate2" v-permission="['/TrainingPlan/edit']">修改
				</el-button>
			</el-col>
			<el-col :span="1.5" style="margin-top:15px;">
				<el-button type="success" plain icon="el-icon-thumb" size="mini" :loading="releaseLoading"
					:disabled="!enableReleaseButton" @click="releasePlan" v-permission="['/TrainingPlan/edit']">发布
				</el-button>
			</el-col>
			<el-col :span="1.5" style="margin-top:15px;">
				<el-button type="success" plain icon="el-icon-s-check" size="mini" :loading="checkInLoading"
					:disabled="!enableCheckInButton" @click="checkInOnline2">签到
				</el-button>
			</el-col>
			<el-col :span="1.5" style="margin-top:15px;">
				<el-button type="success" plain icon="el-icon-film" size="mini" class="btn baseManageBtn" title="签到完成"
					:disabled="!enableCheckInButton" @click="finishTrainingPlan2">完成
				</el-button>
			</el-col>
			<el-col :span="1.5" style="margin-top:15px;">
				<el-button type="success" plain icon="el-icon-data-line" size="mini" :disabled="!single" title="我的签到记录"
					@click="GetcheckInRecords2">签到记录
				</el-button>
			</el-col>
			<el-col :span="1.5" style="margin-top:15px;">
				<el-button type="success" plain icon="el-icon-tickets" size="mini" :disabled="!single"
					@click="GetStatistics2" v-permission="['/MyTraining/checkinStatistics']">培训统计
				</el-button>
			</el-col>
			<el-col :span="1.5" style="margin-top:15px;">
				<el-button type="danger" plain icon="el-icon-delete" size="mini" :disabled="!multiple"
					@click="handleDelete" v-permission="['/TrainingPlan/delete']">删除
				</el-button>
			</el-col>
		</el-row>
		<el-row :gutter="24">
			<el-table v-loading="loading" :data="trainingPlanList" style="width: 100%;" max-height="100%"
				@selection-change="handleSelectionChange" stripe border>
				<el-table-column type="selection" width="55" align="center" />
				<el-table-column label="编号ID" align="center" prop="id" v-if="false" />
				<el-table-column label="培训计划名称" align="center" prop="name" sortable min-width="150"
					:show-overflow-tooltip="true" />
				<el-table-column label="培训地点" align="center" prop="trainingPlace" sortable min-width="150"
					:show-overflow-tooltip="true" />
				<el-table-column label="培训类型" align="center" prop="trainingType" sortable min-width="100">
				</el-table-column>
				<el-table-column label="受训公司" align="center" prop="companyName" min-width="150" sortable
					:show-overflow-tooltip="true" />
				<el-table-column label="培训机构" align="center" prop="trainingAgencyName" min-width="150" sortable
					:show-overflow-tooltip="true" />
				<el-table-column label="培训讲师" align="center" prop="trainingTeacherName" sortable min-width="100" />
				<el-table-column label="创建日期" align="center" prop="createTime" sortable min-width="100">
					<template slot-scope="scope">
						<span>{{ parseTime(scope.row.createTime) }}</span>
					</template>
				</el-table-column>
				<el-table-column label="起始时间" align="center" prop="trainingStart" sortable min-width="160" />
				<el-table-column label="终止时间" align="center" prop="trainingEnd" sortable min-width="160" />
				<el-table-column label="状态" align="center" prop="status" sortable min-width="80">
					<template slot-scope="scope">
						<div v-html="GetStatus(scope.row)"></div>
					</template>
				</el-table-column>
				<el-table-column label="操作" align="center" min-width="150" fixed="right">
					<template slot-scope="scope">
						<el-button size="mini" class="btn baseManageBtn" title="编辑" type="text" icon="el-icon-edit"
							@click="handleUpdate(scope.row)" v-permission="['/TrainingPlan/edit']"></el-button>
						<el-button size="mini" class="btn baseManageBtn" title="签到" :disabled="disableButton(scope.row)"
							type="text" icon="el-icon-s-check" @click="checkInOnline(scope.row)"></el-button>
						<el-button size="mini" class="btn baseManageBtn" title="签到完成"
							:disabled="disableButton(scope.row)" type="text" icon="el-icon-film"
							@click="finishTrainingPlan(scope.row)"></el-button>
						<el-button size="mini" class="btn baseManageBtn" title="签到记录" type="text"
							icon="el-icon-data-line" @click="GetcheckInRecords(scope.row)"></el-button>
						<el-button size="mini" class="btn baseManageBtn" title="统计" type="text" icon="el-icon-tickets"
							@click="GetStatistics(scope.row)" v-permission="['/MyTraining/checkinStatistics']">
						</el-button>
					</template>
				</el-table-column>
			</el-table>
		</el-row>
		<pagination v-show="total > 0" :total="total" :page.sync="queryParameters.pageNum"
			:limit.sync="queryParameters.pageSize" @pagination="query" />

		<el-dialog :title="title" :visible.sync='showTrainingEdit' width="uniApp?'95%':'50%'"
			:append-to-body='true' :close-on-click-modal="false">
			<training-plan-edit v-if="showTrainingEdit" :companyOptions="companyOptions"
				:currentTrainingPlan="selectTrainingPlan" :isNewTrainingPlan="newTrainingPlan"
				@close="closeTrainingEdit">
			</training-plan-edit>
		</el-dialog>
		<el-dialog title="签到记录" :visible.sync='showCheckInRecords' width="30%" :append-to-body='true' :close-on-click-modal="false">
			<check-in-records v-if="showCheckInRecords" :traningPlanId="selectTrainingPlan.id"
				@close="closeCheckInRecords">
			</check-in-records>
		</el-dialog>
		<el-dialog title="签到列表" :visible.sync='showCheckinList' width="70%" :append-to-body='true' :close-on-click-modal="false">
			<checkin-list v-if="showCheckinList" :traningPlanId="selectTrainingPlan.id" @close="closeCheckinList">
			</checkin-list>
		</el-dialog>
		<el-dialog title="签到完成培训" :visible.sync='showFinishTraining' width="50%" :append-to-body='true' :close-on-click-modal="false">
			<finish-training v-if="showFinishTraining" :currentTraining="selectTrainingPlan"
				@close="closeFinishTraining">
			</finish-training>
		</el-dialog>

	</div>
</template>

<script>
import permission from '@/directive/permission/index.js'; // 权限判断指令
import { getTreeselectCompany } from "@/api/org/company";
import Pagination from "@/components/Pagination";
import { findAllTrainingPlans, deleteTrainingPlan, releaseTrainingPlan } from "@/api/training/trainingPlan"
import { addTraining } from '@/api/training/myTraining';
import trainingPlanEdit from "./trainingPlanEdit.vue"
import checkInRecords from "./checkInRecords.vue"
import checkinList from "./checkinList.vue"
import finishTraining from "./finishTraining.vue"
import { Local } from '@/utils/storage.js'

export default {
	name: 'trainingPlanList',
	components: {
		Pagination,
		trainingPlanEdit,
		checkInRecords,
		checkinList,
		finishTraining
	},
	directives: { permission },
	data() {
		return {
			trainingPlanList: [],
			isCompnayVisible: false,
			companyList: [],
			total: 0,
			loading: false,
			companySelect: '',
			// 公司树选项
			companyOptions: [],
			defaultProps: {
				children: 'children',
				label: 'companyName',
			},
			queryParameters: {
				startDate: '',
				endDate: '',
				name: '',
				companyId: '',
				pageSize: 10,
				pageNum: 1,
			},
			where: {
				query: '',
				filters: [],
			},
			title: "",
			showTrainingEdit: false,
			selectTrainingPlan: null,
			newTrainingPlan: false,
			uniApp: false,
			ids: [],
			single: false,
			multiple: false,
			statusColor: "{color:yellow}",
			checkIn: false,
			showCheckInRecords: false,
			enableCheckInButton: false,
			showCheckinList: false,
			showFinishTraining: false,
			enableReleaseButton: false,
			releaseLoading: false,
			checkInLoading: false,
		};
	},

	mounted() {

	},
	created() {
		this.uniApp = Local.get("isUniapp") ? true : false;
		this.load();
		this.query();
	},

	methods: {
		load() {
			getTreeselectCompany(null, (list, options) => {
				this.companyList = list;
				if (this.companyList.length > 1) {
					this.isCompnayVisible = true;
				}
				this.companyOptions = options;
			});
		},
		// 节点单击事件
		handleNodeClick(data) {
			this.queryParameters.companyId = data.id;
			this.companyId = data.id;
			this.companySelect = data.companyName;
			let selectArray = [];
			selectArray.push(this.companyId);
			this.$refs.tree.setCheckedKeys(selectArray);

			this.$refs.companySelected.blur();
		},
		handleClearable() {
			this.$refs.tree.setCheckedKeys([]);
			this.companyId = null;
			this.companySelect = '';
		},
		query() {
			let filter = {};
			if (this.companyId) {
				filter['companyId'] = {
					type: 'eq',
					value: this.companyId,
				};
			}
			if (this.queryParameters.name) {
				filter['name'] = {
					type: 'search',
					value: this.queryParameters.name,
				};
			}

			if (this.queryParameters.startDate && this.queryParameters.endDate) {
				let tempDate = this.queryParameters.startDate.replace(/-/g, '/'); //必须把日期'-'转为'/'
				var startDateTimeStamp = new Date(tempDate).getTime();
				tempDate = this.queryParameters.endDate.replace(/-/g, '/');
				var endDateTimeStamp = new Date(tempDate).getTime();
				filter['createTime'] = {
					type: 'timestamp',
					value: [startDateTimeStamp, endDateTimeStamp],
				};
			}

			const filters = this.parseFilters(filter);
			if (filters && filters.length > 0) {
				this.where.filters = filters;
			} else {
				// 如果过滤条件为空，设置空数组
				this.where.filters = [];
			}
			const params = {
				pageNum: this.queryParameters.pageNum,
				pageSize: this.queryParameters.pageSize,
				where: this.where,
				order: '',
			};
			this.loading = true;
			findAllTrainingPlans(params).then((response) => {
				this.trainingPlanList = response.data.records;
				console.log("planlist");
				console.log(this.trainingPlanList);
				this.total = response.data.total;
				this.loading = false;

			});
		},

		handleAdd() {
			this.showTrainingEdit = true;
			this.selectTrainingPlan = null;
			this.title = "增加培训计划";
			this.newTrainingPlan = true;
		},

		handleUpdate(row) {
			this.selectTrainingPlan = row;
			this.showTrainingEdit = true;
			this.courseEditTitle = "更新培训计划";
			this.newTrainingPlan = false;
		},
		handleUpdate2() {
			let findTrainingPlans = this.trainingPlanList.filter(item => item.id == this.ids[0]);
			this.selectTrainingPlan = findTrainingPlans[0];
			this.showTrainingEdit = true;
			this.courseEditTitle = "更新培训计划";
			this.newTrainingPlan = false;
		},
		handleDelete() {
			let that = this;
			this.$confirm('确定删除选中的培训计划?', "警告", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			}).then(function () {
				return deleteTrainingPlan(that.ids);
			}).then(() => {
				that.query();
				that.msgSuccess("删除成功");
			})
		},
		releasePlan() {
			let that = this;
			this.$confirm('确定要发布此培训计划?', "警告", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			}).then(function () {
				that.releaseLoading = true;
				return releaseTrainingPlan(that.ids);
			}).then(() => {
				that.releaseLoading = false;
				that.query();
				that.msgSuccess("发布成功");
			})
		},
		handleSelectionChange(selection) {
			this.ids = selection.map(item => item.id)
			this.single = selection.length == 1
			this.multiple = selection.length >= 1;
			let findTrainingPlans = this.trainingPlanList.filter(item => item.id == this.ids[0]);
			this.selectTrainingPlan = findTrainingPlans[0];
			this.enableCheckInButton = this.single && !this.disableButton(this.selectTrainingPlan) && findTrainingPlans[0].status == '已发布';
			//this.enableReleaseButton = this.single && this.disableButton(this.selectTrainingPlan) && findTrainingPlans[0].status == '未发布';
			this.enableReleaseButton = this.single && findTrainingPlans[0].status == '未发布';
		},

		closeTrainingEdit(params) {
			this.showTrainingEdit = false;
			if (params.refresh) {
				this.query();
			}

		},
		parseTime(rowDateTime) {
			if (rowDateTime != null && rowDateTime.indexOf('T') > 0) {
				return rowDateTime.substr(0, 10);
			}
			else {
				return "";
			}
		},
		GetStatus(row) {
			let now = new Date();
			let str = row.trainingStart.replace(/-/g, "/");
			let startDate = new Date(str);
			let endDate = new Date(row.trainingEnd.replace(/-/g, "/"));
			if (row.status == '已发布') {
				if (now >= startDate && now <= endDate) {
					return "<span style='color:green'>进行中</span>";
				}
				else {
					if (now > endDate) {
						return "<span style='color:red'>已结束</span>";
					}
					else {
						return "<span style='color:blue'>" + row.status + "</span>";
					}
				}
			}
			else
			{
				return "<span style='color:blue'>" + row.status + "</span>";
			}

		},
		disableButton(row) {
			let now = new Date();
			let str = row.trainingStart.replace(/-/g, "/");
			let startDate = new Date(str);
			let endDate = new Date(row.trainingEnd.replace(/-/g, "/"));
			if (now >= startDate && now <= endDate) {
				return false;
			}
			else {
				return true;
			}
		},
		checkInOnline2() {
			let findTrainingPlans = this.trainingPlanList.filter(item => item.id == this.ids[0]);
			this.selectTrainingPlan = findTrainingPlans[0];
			this.checkInOnline(this.selectTrainingPlan);
		},
		checkInOnline(row) {
			let myTraining = {};
			myTraining.userId = this.$store.getters.userInfo.id;
			myTraining.employeeName = this.$store.getters.userInfo.username;
			myTraining.trainingName = row.name;
			myTraining.trainingType = "培训计划";
			myTraining.trainingId = row.id;
			myTraining.completed = false;
			this.checkInLoading = true;
			addTraining(myTraining)
				.then((response) => {
					this.checkInLoading = false;
					this.msgSuccess("签到成功。");
					console.log('签到成功。');
				})
				.catch((err) => {
					this.checkInLoading = false;
					this.msgError("签到失败，");
					console.log('签到失败，' + err);
				});
		},
		finishTrainingPlan(row) {
			this.selectTrainingPlan = row;
			this.showFinishTraining = true;
		},
		finishTrainingPlan2() {
			let findTrainingPlans = this.trainingPlanList.filter(item => item.id == this.ids[0]);
			this.selectTrainingPlan = findTrainingPlans[0];
			this.showFinishTraining = true;
		},
		closeCheckInRecords() {
			this.showCheckInRecords = false;
		},
		closeCheckinList() {
			this.showCheckinList = false;
		},
		closeFinishTraining() {
			this.showFinishTraining = false;
		},
		GetcheckInRecords(row) {
			this.selectTrainingPlan = row;
			this.showCheckInRecords = true;
		},
		GetcheckInRecords2() {
			let findTrainingPlans = this.trainingPlanList.filter(item => item.id == this.ids[0]);
			this.selectTrainingPlan = findTrainingPlans[0];
			this.showCheckInRecords = true;
		},
		GetStatistics(row) {
			this.selectTrainingPlan = row;
			this.showCheckinList = true;

		},
		GetStatistics2() {
			let findTrainingPlans = this.trainingPlanList.filter(item => item.id == this.ids[0]);
			this.selectTrainingPlan = findTrainingPlans[0];
			this.showCheckinList = true;
		}
	},
};
</script>

<style lang="scss" scoped>

</style>
