import request from '@/utils/request'


//新增简历
export function addResume(data) {
 return request({
    url: '/resume/add',
     method: 'post',
    data: data
 })
}

//新增简历
export function editResume(data) {
    return request({
       url: '/resume/edit',
        method: 'post',
       data: data
    })
   }

export function login(data) {
    return request({
       url: '/resume/login?userName='+data.userName+'&password='+data.password,
        method: 'post',
    })
   }
   

//新增简历 教育经历
export function addResumeEducationExp(educationExp) {
    return request({
        url: '/resume/add',
        method: 'post',
        data: educationExp
    })
}

//新增简历 工作经历
export function addResumeWorkExp(workExp) {
    return request({
        url: '/resume/add',
        method: 'post',
        data: workExp
    })
}

export function findAllResume(query) {
    return request({
        url: '/resume/findAll',
        method: 'post',
        data: query
    })
}

export function findOne(id) {
    return request({
        url: '/resume/findOne?id=' + id,
        method: 'post'
    })
}

// 上传个人相片
export function uploadPersonalPhoto(data) {
    return request({
        url: '/resume/UploadPersonalPhoto',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}

// 上传个人相片
export function uploadResume(data) {
    return request({
        url: '/resume/uploadResume',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}

// 下载个人简历
export function download(filePath) {
    return request({
        url: '/resume/download?filePath='+filePath,
        method: 'get',
        responseType: 'blob'
        // data: data
    })
}