<template>
<div>
  <el-tabs v-model="activeNames" >
    <el-tab-pane label=" 考 勤 扣 除" name="attInfo">
      <!--            <el-button type="primary" plain icon="el-icon-plus" size="mini"-->
      <!--                       @click="handleAddAttItem" v-permission="['/cb/cbTemplate/add','/cb/cbTemplate/edit']"-->
      <!--            >新增项目-->
      <!--            </el-button>-->
      <el-popover placement="top-start"
                  title="考勤扣除规则及说明"
                  width="350" trigger="click">
        <p>注意：<br>
        考勤项目中“调休结算”请使用负数值，因为“调休结算”是补发放金额。
        </p><br>
        <p>扣款方式：“固定值”每次/每天考勤项目按照固定金额计算，比如迟到每次按照20元扣除；“比例(倍)”每次/每天考勤项目按照比例计算，比如病假按照0.6倍扣除，事假按照1倍扣除</p><br>
        <p></p><br>
        <el-button style="margin-bottom:10px;" type="primary" plain icon="el-icon-question" size="mini"
                   slot="reference"
        >帮助
        </el-button>
      </el-popover>
      <template v-if="salaryInfo.attInfo && salaryInfo.attInfo.attItems">
        <el-table :data="salaryInfo.attInfo.attItems" stripe border>
          <el-table-column label="考勤项目" align="center" prop="attName">
            <template slot-scope="scope">
              <span v-if="!scope.row.editable">{{scope.row.attName}}</span>
              <el-input v-if="scope.row.editable" v-model="scope.row.attName" placeholder=""></el-input>
            </template>
          </el-table-column>
          <el-table-column label="扣款方式" align="center" prop="attType">
            <template slot-scope="scope">
              <el-select :disabled="!editable" v-model="scope.row.attType" placeholder="请选择扣款方式" clearable size="small">
                <el-option key="固定值" label="固定值(元)" value="固定值"/>
                <el-option key="比例" label="比例(倍)" value="比例"/>
              </el-select>
              <!--                  <el-input v-model="scope.row.attType" placeholder=""></el-input>-->
            </template>
          </el-table-column>
          <el-table-column label="扣款值" align="center" prop="attValue">
            <template slot-scope="scope">
              <el-input :disabled="!editable" v-model.number="scope.row.attValue" type="number" placeholder=""></el-input>
            </template>
          </el-table-column>
          <!--              <el-table-column label="操作" align="center" class-name="small-padding fixed-width">-->
          <!--                <template slot-scope="scope">-->
          <!--                  <el-button v-if="scope.row.editable" size="mini" type="text"-->
          <!--                             icon="el-icon-delete" @click="handleDeleteAttItem(scope.row)"-->
          <!--                             v-permission="['/cb/cbTemplate/add','/cb/cbTemplate/edit']"-->
          <!--                  >删除-->
          <!--                  </el-button>-->
          <!--                </template>-->
          <!--              </el-table-column>-->
        </el-table>
      </template>
    </el-tab-pane>
    <el-tab-pane label=" 薪 资 构 成" name="salaryInfo">
      <el-row>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" >
          <el-form-item label="添加保险" prop="insuranceId">
            <el-select :disabled="!editable" ref="insuranceSel" v-model.number="salaryInfo.insuranceId"
                       placeholder="请选择保险" clearable size="large" @change="insuranceChange">
              <el-option v-for="item in insuranceList" :key="item.id" :label="item.name" :value="item.id"/>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" >
          <el-form-item label="添加公积金" prop="fundId">
            <el-select :disabled="!editable" ref="fundSel" v-model.number="salaryInfo.fundId"
                       placeholder="请选择保险" clearable size="large" @change="fundChange">
              <el-option v-for="item in fundList" :key="item.id" :label="item.name" :value="item.id"/>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10" class="mb8">
        <el-col :span="1.5">
          <el-button v-if="editable" type="primary" plain icon="el-icon-plus" size="mini"
                     @click="handleAddItem" v-permission="['/cb/cbTemplate/add','/cb/cbTemplate/edit']"
          >新增项目
          </el-button>
          <span>&nbsp;</span>
          <el-popover placement="top-start"
                      title="工资计算规则及说明"
                      width="350" trigger="click">
            <p>支付总工资=总工资(合同工资)+其他项(过节费等临时工资)<br>
              总工资(合同工资)=工资(项目和)+浮动值(奖金等,只能有一个浮动值)<br>
              所以也可以是：支付总工资=工资(项目和)+浮动值(奖金等,只能有一个浮动值)+其他项(社保公积金，过节费，水电代扣等工资项)</p><br>
            <p>工资项目类型: <br>&nbsp;&nbsp;&nbsp;&nbsp;
              "工资"是指一般工资项目, 比如基本工资, 补贴等这些固定值和系统计算的考勤扣除金额等. 考勤扣除项目不需要添加，系统自动计算 <br>&nbsp;&nbsp;&nbsp;&nbsp;
              "浮动值"是指奖金项目, 它有且只能有一项, 它的值是由每位员工设置了总工资后, 由总工资减去所有工资项目和所得.<br>&nbsp;&nbsp;&nbsp;&nbsp;
              "其他项"是不参与总工资计算浮动值的部分. 比如社保公积金，过节费，水电代扣等工资项</p><br>
            <p>增减项: 是指此工资项目的运算是加还是减</p><br>
            <p>值类型: "设置值"设置固定工资金额; "系统计算值"由系统计算得到,比如浮动工资奖金; "引用值"则是来源其他地方的设置,比如社保公积金等.</p><br>
            <p>考勤比例发放: "考勤比例发放"为"是"，则此项工资最后发放金额会根据当月出勤天数比例计算此项当月金额，比如餐补800元上班出勤了才有，那么应出勤22天，实际出勤18天则只发放800*(18/22)=654.55元；"否"则不会，此项工资不受出勤天数影响，比如当月社保缴纳，或者比如中秋节过节费200元等不受请假影响照样发放。</p><br>
            <el-button type="primary" plain icon="el-icon-question" size="mini"
                       slot="reference"
            >帮助
            </el-button>
          </el-popover>
        </el-col>
      </el-row>
      <el-table :data="salaryInfo.itemInfo" show-summary :summary-method="getSummaries" stripe border>
        <el-table-column label="项目名" align="center" prop="itemName" min-width="100">
          <template slot-scope="scope">
            <el-input :disabled="!editable || '引用值'==scope.row.valueType" v-model="scope.row.itemName" placeholder="缴纳项目名"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="项目类型" align="center" prop="itemType" min-width="60">
          <template slot-scope="scope">
            <el-select :disabled="!editable || '引用值'==scope.row.valueType" v-model="scope.row.itemType" placeholder="请选择项目类型" clearable
                       size="small" @change="itemTypeChange(scope.row)">
              <el-option v-for="item in getDictDatas(DICT_TYPE.CB_TEMPLATE_SALARY_TYPE)" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled"/>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="增减项" align="center" prop="plusMinus" min-width="60">
          <template slot-scope="scope">
            <el-select :disabled="!editable" v-model="scope.row.plusMinus" placeholder="请选择增减项" clearable
                       size="small">
              <el-option v-for="item in getDictDatas(DICT_TYPE.CB_TEMPLATE_SALARY_PLUSMINUS)" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled"/>
            </el-select>
          </template>
        </el-table-column>
<!--        <el-table-column label="值类型" align="center" prop="valueType">-->
<!--          <template slot-scope="scope">-->
<!--            <el-select :disabled="true" v-model="scope.row.valueType" placeholder="请选择值类型" clearable-->
<!--                       size="small">-->
<!--              <el-option v-for="item in getDictDatas(DICT_TYPE.CB_TEMPLATE_SALARY_VALUE)" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled"/>-->
<!--            </el-select>-->
<!--          </template>-->
<!--        </el-table-column>-->
        <el-table-column label="考勤比例发放" align="center" prop="isAttend" min-width="60">
          <template slot-scope="scope">
            <el-select :disabled="!editable || '引用值'==scope.row.valueType" v-model="scope.row.isAttend" placeholder="请选择是否考勤比例发放" clearable
                       size="small">
              <el-option :key="true" label="是" :value="true"/>
              <el-option :key="false" label="否" :value="false"/>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="是否扣税" align="center" prop="isTax" min-width="60">
          <template slot-scope="scope">
            <el-select :disabled="!editable || '引用值'==scope.row.valueType" v-model="scope.row.isTax" placeholder="请选择是否参与扣税" clearable
                       size="small">
              <el-option :key="true" label="是" :value="true"/>
              <el-option :key="false" label="否" :value="false"/>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="金额(元)" align="center" prop="amount" min-width="80">
          <template slot-scope="scope">
            <el-input v-if="'系统计算值'!=scope.row.valueType" :disabled="!editable || '引用值'==scope.row.valueType" v-model.number="scope.row.amount"
                      placeholder="金额(元)"></el-input>
            <span v-if="'系统计算值'==scope.row.valueType">{{scope.row.amount}}</span>
          </template>
        </el-table-column>
        <el-table-column label="显示顺序" align="center" prop="orderNum" min-width="60">
          <template slot-scope="scope">
            <el-input :disabled="!editable" v-model.number="scope.row.orderNum" placeholder="显示顺序"></el-input>
<!--            <span v-if="'系统计算值'==scope.row.valueType">{{scope.row.orderNum}}</span>-->
          </template>
        </el-table-column>
        <el-table-column label="工资条显示" align="center" prop="showToEmp" min-width="60">
          <template slot-scope="scope">
            <el-select :disabled="!editable" v-model="scope.row.showToEmp" placeholder="请选择是否在工资条中显示" clearable
                       size="small">
              <el-option :key="true" label="是" :value="true"/>
              <el-option :key="false" label="否" :value="false"/>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column v-if="editable" label="操作" align="center" class-name="small-padding fixed-width">
          <template slot-scope="scope">
            <el-button :disabled="'引用值'==scope.row.valueType"
                       size="mini"
                       type="text"
                       icon="el-icon-delete"
                       @click="handleDeleteItem(scope.row)"
                       v-permission="['/cb/cbTemplate/add','/cb/cbTemplate/edit']"
            >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <template>
        <span>总工资={{totalSalaryText}}</span>
      </template>
    </el-tab-pane>
  </el-tabs>
</div>
</template>

<script>
import permission from '@/directive/permission/index.js' // 权限判断指令
import {DICT_TYPE} from "@/utils/dict";
import {listCbTemplate } from "@/api/cb/cbTemplate";
import {sortBy} from "@/utils/tools";

export default {
  name: "SalaryEdit",
  props: {
    editable: Boolean,
    salaryInfoIn: Object,
    companyIdIn: Number,
    salaryTempId: Number,
    contractSalary: Number,
  },
  directives: { permission },

  watch: {
    "editable": function (n, o) {
      console.log("editable change new="+n)
      if(n){
        if(!this.salaryInfo.attInfo){
          this.$set(this.salaryInfo,"attInfo", {});
        }
        if(!this.salaryInfo.attInfo.attItems || this.salaryInfo.attInfo.attItems.length<=0){
          this.$set(this.salaryInfo.attInfo,"attItems", this.attItems);
        }
      }
    },
    "companyIdIn": function (n, o) {
      console.log("this.form.companyId o=" + o + ",n=" + n);
      if (!this.salaryInfo.id) {
        // 新增,改公司,重新设置社保公积金
        this.$set(this.salaryInfo, 'insuranceId', null);
        this.$set(this.salaryInfo, 'fundId', null);
        this.getCbTemplateList(this.companyIdIn);
      } else {
        // 更新不能改公司
        this.getCbTemplateList(this.companyIdIn);
      }
    },
    "salaryInfoIn": function (n, o) {
      console.log("watch form.salaryInfoIn old = ")
      console.log(o);
      console.log("watch form.salaryInfoIn new = ")
      console.log(n);
      if(this.salaryInfoIn){
        this.salaryInfo = this.salaryInfoIn;
        if(!this.salaryInfo.attInfo || this.salaryInfo.attInfo.length<=0){
          this.$set(this.salaryInfo,"attInfo", this.attItems);
        }
      }else{
        this.salaryInfo = {companyId:null,insuranceId:null,fundId:null,attInfo:this.attItems,itemInfo:[]};
      }
    },
    "salaryInfo.insuranceId": function (n, o) {
      if (o) {
        console.log("watch form.insuranceId old = " + o)
        this.removeItemByRefId(o);
      }
      // if(this.form.insuranceId){
      //   let insurance = this.getItemByRefId(n);
      //   this.addRefItem(insurance);
      // }
    },
    "salaryInfo.fundId": function (n, o) {
      if (o) {
        console.log("watch form.fundId old = " + o)
        this.removeItemByRefId(o);
      }
    },
  },
  created() {
    console.log("created")
    // console.log(this.salaryInfoIn);
    console.log(this.companyIdIn);
    if(this.companyIdIn){
      this.getCbTemplateList(this.companyIdIn);
    }
    if(this.salaryInfoIn){
      this.salaryInfo = this.salaryInfoIn;
      if(!this.salaryInfo.companyId){
        this.salaryInfo.companyId = this.companyIdIn;
      }
      if(!this.salaryInfo.insuranceId){
        this.salaryInfo.insuranceId = null;
      }
      if(!this.salaryInfo.fundId){
        this.salaryInfo.fundId = null;
      }
    }
  },
  mounted() {
    // console.log("mounted")
    // console.log(this.salaryInfoIn);
  },
  data() {
    return {
      activeNames: 'attInfo',
      salaryInfo: {companyId:null,insuranceId:null,fundId:null,attInfo:{},itemInfo:[]},
      cbSalaryTemplateList: [],
      cbTemplateList: [],
      insuranceList: [],
      fundList: [],
      attItems: DICT_TYPE.ATT_ITEMS,

    }
  },
  computed:{
    totalSalaryText(){
      // if(this.contractSalary&&this.salaryInfo&&this.salaryInfo.itemInfo) {
      let text = '';
      let salary = '';
      let sum = 0;
      let floatItem = ''
      if(this.salaryInfo&&this.salaryInfo.itemInfo) {
        let itemInfo = this.salaryInfo.itemInfo;
        let salaryItemInfo = itemInfo.sort(sortBy('orderNum',true));
        let isFirst = true;
        salaryItemInfo.forEach(item=>{
          if(isFirst){
            isFirst = false;
            if (item.plusMinus == '增项') {
              text = text + item.itemName;
              salary = salary + this.getItemAmountValue(item);
            } else {
              text = text + ' - ' + item.itemName;
              salary = salary + ' - ' + this.getItemAmountValue(item);
            }
          }else{
            if (item.plusMinus == '增项') {
              text = text + ' + ' + item.itemName;
              salary = salary + ' + ' + this.getItemAmountValue(item);
            } else {
              text = text + ' - ' + item.itemName;
              salary = salary + ' - ' + this.getItemAmountValue(item);
            }
          }
          // 求和
          const value = Number(item.amount);
          if (!isNaN(value)) {
            if (item.plusMinus == '增项') {
              sum += value;
            } else {
              sum -= value;
            }
          }else{
            floatItem += item.itemName + ",";
          }
        });
      }
      if(floatItem&&floatItem.length>0){
        text = text + " = " + salary + ' = ' + sum + '元(不包括：'+floatItem.substring(0,floatItem.length-1)+')';
      }else{
        text = text + " = " + salary + ' = ' + sum + '元';
      }
      return text;
    }
  },

  methods: {
    getItemAmountValue(item){
      const value = Number(item.amount);
      if (!isNaN(value)) {
        return value;
      }else{
        return item.itemName;
      }
    },
    getSummaries(param) {
      if(this.contractSalary&&this.salaryInfo&&this.salaryInfo.itemInfo){
        this.calculateFloatItem(this.salaryInfo.itemInfo);
      }
      const {columns, data} = param;
      // console.log("columns=")
      // console.log(columns)
      // console.log("data=")
      // console.log(data)
      const sums = [];
      if(this.contractSalary){
        sums[0] = '总工资';
      }else {
        sums[0] = '总工资（不包括浮动项）';
      }
      let val = 0;
      let extVal = 0;
      data.map(item => {
        // console.log(item);
        const value = Number(item.amount);
        if (!isNaN(value)) {
          if (item.plusMinus == '增项') {
            val += value;
          } else {
            val -= value;
          }
          if(DICT_TYPE.CB_TEMPLATE_SALARY_TYPE_EXTRA == item.itemType){
            if (item.plusMinus == '增项') {
              extVal += value;
            } else {
              extVal -= value;
            }
          }
        }
      });
      sums[5] = "合计："+val;//+"(其中包含"+extVal+DICT_TYPE.CB_TEMPLATE_SALARY_TYPE_EXTRA+")";
      sums[6] = "包含"+extVal+DICT_TYPE.CB_TEMPLATE_SALARY_TYPE_EXTRA+"";
      if(this.contractSalary){
        sums[3] = this.contractSalary+"(合同总工资)"
      }
      return sums;
    },
    itemTypeChange(row) {
      if (DICT_TYPE.CB_TEMPLATE_SALARY_TYPE_FLOAT == row.itemType) {
        row.plusMinus = "增项";
        row.valueType = "系统计算值";
      } else if (DICT_TYPE.CB_TEMPLATE_SALARY_TYPE_SALARY == row.itemType || DICT_TYPE.CB_TEMPLATE_SALARY_TYPE_EXTRA == row.itemType) {
        // row.plusMinus = "增项";
        row.valueType = "设置值";
      }
    },
    addRefItem(ref) {
      let itemInfo = this.salaryInfo.itemInfo;
      if (ref) {
        if (!itemInfo) itemInfo = [];
        let amount = 0;
        if (ref.itemInfo && ref.itemInfo.length > 0) {
          ref.itemInfo.forEach(item => {
            amount += item.baseAmount * item.empPercent;
          })
        }
        console.log("******* add ref item");
        console.log(ref);
        itemInfo.push({
          itemName: ref.name, itemType: DICT_TYPE.CB_TEMPLATE_SALARY_TYPE_EXTRA, plusMinus: "减项", valueType: "引用值",
          isAttend: false, isTax: true, showToEmp: true, refId: ref.id, amount: amount / 100, refType:ref.type
        });
        console.log(itemInfo)
        this.$set(this.salaryInfo,"itemInfo",itemInfo);
      }
    },
    removeItemByRefId(refId) {
      if (!this.salaryInfo.itemInfo || this.salaryInfo.itemInfo.length < 1) return;
      for (let i = 0; i < this.salaryInfo.itemInfo.length; i++) {
        if (this.salaryInfo.itemInfo[i].valueType == "引用值" &&
            this.salaryInfo.itemInfo[i].refId == refId) {
          this.salaryInfo.itemInfo.splice(i, 1);
        }
      }
    },
    removeRefItem() {
      if (!this.salaryInfo.itemInfo || this.salaryInfo.itemInfo.length < 1) return;
      for (let i = 0; i < this.salaryInfo.itemInfo.length;) {
        if (this.salaryInfo.itemInfo[i].valueType == "引用值") {
          this.salaryInfo.itemInfo.splice(i, 1);
        } else {
          i++;
        }
      }
    },
    getItemByRefId(refId) {
      console.log("getItemByRefId = " + refId)
      for (let i = 0; i < this.cbTemplateList.length; i++) {
        console.log(this.cbTemplateList[i].id)
        if (this.cbTemplateList[i].id == refId) {
          return this.cbTemplateList[i];
        }
      }
      console.log("not find getItemByRefId")
      return null;
    },
    insuranceChange(val) {
      console.log("insuranceChange")
      console.log(val)
      this.$set(this.salaryInfo,"insuranceId",val);
      if (val) {
        let insurance = this.getItemByRefId(val);
        this.addRefItem(insurance);
      }
    },
    fundChange(val) {
      console.log("fundChange")
      console.log(val)
      this.$set(this.salaryInfo,"fundId",val);
      if (val) {
        let fund = this.getItemByRefId(val);
        this.addRefItem(fund);
      }
    },
    getCbTemplateList(companyId) {
      let filter = {};
      filter['companyId'] = {
        type: 'eq',
        value: companyId,
      };
      const params = this.getQueryParam(filter, 1, 1000, '');
      listCbTemplate(params).then(response => {
        this.cbTemplateList = response.data.records;
        let insuranceList = [];
        let fundList = [];
        this.cbTemplateList.forEach(item => {
          if ("社保" == item.type) {
            insuranceList.push(item);
          } else if ("住房公积金" == item.type) {
            fundList.push(item);
          }
        });
        this.insuranceList = insuranceList;
        this.fundList = fundList;
        // 检查form 是否设置社保公积金,设置这添加item项目
        // this.removeRefItem();
        // if (this.salaryInfo.insuranceId) {
        //   let insurance = this.getItemByRefId(this.salaryInfo.insuranceId);
        //   this.addRefItem(insurance);
        // }
        // if (this.salaryInfo.fundId) {
        //   let fund = this.getItemByRefId(this.salaryInfo.fundId);
        //   this.addRefItem(fund);
        // }
      });
    },
    handleAddItem() {
      if (!this.salaryInfo.itemInfo) {
        this.$set(this.salaryInfo, "itemInfo", []);
      }
      this.salaryInfo.itemInfo.push({itemName: "", itemType: "", plusMinus: "", valueType: "", refId: null, amount: null});
    },
    handleDeleteItem(row) {
      var index = this.salaryInfo.itemInfo.indexOf(row);
      if (index > -1) {
        this.salaryInfo.itemInfo.splice(index, 1);
      }
    },
    handleAddAttItem() {
      if (!this.salaryInfo.attInfo.attItems) {
        this.$set(this.salaryInfo.attInfo, "attItems", []);
      }
      this.salaryInfo.attInfo.attItems.push({attName: "", attType: "", attValue: "", editable: true});
    },
    handleDeleteAttItem(row) {
      var index = this.salaryInfo.attInfo.attItems.indexOf(row);
      if (index > -1) {
        this.salaryInfo.attInfo.attItems.splice(index, 1);
      }
    },
    calculateFloatItem(salaryItemInfo){
      let floatItem = null;
      let val = 0;
      salaryItemInfo.forEach(item=>{
        if(item.itemType == DICT_TYPE.CB_TEMPLATE_SALARY_TYPE_FLOAT){
          floatItem = item;
        }else if(item.itemType == DICT_TYPE.CB_TEMPLATE_SALARY_TYPE_SALARY){
          // 浮动工资是总工资-工资项和（不包括额外工资）
          const value = Number(item.amount);
          if (!isNaN(value)) {
            if (item.plusMinus == '增项') {
              val += value;
            } else {
              val -= value;
            }
          }
        }
      });
      if(floatItem){
        const amount = this.contractSalary - val;
        this.$set(floatItem, "amount", amount);
      }
    },

  }
}
</script>

<style scoped>

</style>
