import Vue from 'vue';
import store from '../store';
import VueRouter from 'vue-router';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { Message } from 'element-ui'
import { Session } from '@/utils/storage';
import { PrevLoading } from '@/utils/loading.js';
import { getToken, removeToken } from '@/utils/auth' // get token from cookie
import { Local } from '@/utils/storage.js';
// import {Local} from "../utils/storage";
/* Layout */
// import Layout from '@/views/layout'
const whiteList = ['/login', '/uniapp', '/appwork', '/auth-redirect','/appDownload'] // no redirect whitelist

// 解决 `element ui` 导航栏重复点菜单报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
	// console.log("rote puth path:"+location)
	return originalPush.call(this, location).catch((err) => err);
};

// 安装 VueRouter 插件
Vue.use(VueRouter);

// 定义静态路由
const staticRoutes = [
	{
		path: '/login',
		name: 'login',
		component: () => import('@/views/login'),
		meta: {
			title: '登录',
		},
	},
	{
		path: '/uniapp',
		name: 'uniapp',
		component: () => import('@/views/login/uniapp.vue'),
		meta: {
			title: '跳转',
		},
	},
	{
		path: '/appwork',
		name: 'appwork',
		component: () => import('@/views/pages/work/index.vue'),
		meta: {
			title: '工作台',
		},
	},
	{
		path: '/register',
		name: 'register',
		component: () => import('@/views/login/register.vue'),
		meta: {
			title: '注册',
		},
	},
	{
		path: '/recruit',
		name: 'recruit',
		component: () => import('@/views/pages/recruit/recruitList.vue'),
		meta: {
			title: '招聘',
		},
	},
	{
		path: '/appDownload',
		name: 'appDownload',
		component: () => import('@/views/login/appDownload.vue'),
		meta: {
			title: 'app下载',
		},
	},
	// {
	// 	path: "/personal",
	// 	name: "personal",
	// 	component: () => import('@/views/personal/index.vue'),
	// 	meta: {
	// 		title: "message.router.personal",
	// 		isLink: "",
	// 		isHide: false,
	// 		isKeepAlive: true,
	// 		isAffix: true,
	// 		isIframe: false,
	// 		icon: "iconfont icon-gerenzhongxin"
	// 	}
	// },

	{
		path: '/404',
		name: 'notFound',
		component: () => import('@/views/error/404.vue'),
		meta: {
			title: '找不到此页面',
		},
	},
	{
		path: '/401',
		name: 'noPower',
		component: () => import('@/views/error/401.vue'),
		meta: {
			title: '没有权限',
		},
	},
	// {
	// 	"path": "/pages",
	// 	"name": "pagesIndex",
	// 	component: () => import('@/views/layout/routerView/parent'),
	// 	"redirect": "/pages/formAdapt",
	// 	"meta": {
	// 		"title": "message.router.pagesIndex",
	// 		"isLink": "",
	// 		"isHide": false,
	// 		"isKeepAlive": true,
	// 		"isAffix": false,
	// 		"isIframe": false,
	// 		"auth": ["admin", "test"],
	// 		"icon": "iconfont icon-fuzhiyemian"
	// 	},
	// 	"children": [
	// 		{
	// 			"path": "/pages/formAdapt",
	// 			"name": "pagesFormAdapt",
	// 			"component":  () => import('@/views/pages/formAdapt/index'),
	// 			"meta": {
	// 				"title": "message.router.pagesFormAdapt",
	// 				"isLink": "",
	// 				"isHide": false,
	// 				"isKeepAlive": true,
	// 				"isAffix": false,
	// 				"isIframe": false,
	// 				"auth": ["admin", "test"],
	// 				"icon": "iconfont icon-biaodan"
	// 			}
	// 		},
	// 	]
	// },
	// {
	// 	path: '/',
	// 	component: Layout,
	// 	redirect: '/dashboard',
	// 	name: '首页',
	// 	children: [
	// 		{
	// 			path: 'dashboard',
	// 			// eslint-disable-next-line no-undef
	// 			component: () => import('@/views/home/index'),
	// 			name: 'Dashboard',
	// 			meta: { title: '首页', icon: 'dashboard', affix: true }
	// 		}
	// 	]
	// },

];

// 加载静态路由
const createRouter = () =>
	new VueRouter({
		routes: staticRoutes,
	});

// 创建路由
const router = createRouter();

// 加载 loading
PrevLoading.start();

// 多级嵌套数组处理成一维数组
export function formatFlatteningRoutes(arr) {
	if (arr.length <= 0) return false;
	for (let i = 0; i < arr.length; i++) {
		if (arr[i].children) {
			arr = arr.slice(0, i + 1).concat(arr[i].children, arr.slice(i + 1));
		}
	}
	return arr;
}

// 处理 tagsViewList 数据，默认路由全部缓存
// isKeepAlive 处理 `name` 值，进行路由缓存
export function formatTwoStageRoutes(arr) {
	if (arr.length <= 0) return false;
	const newArr = [];
	const cacheList = [];
	arr.forEach((v) => {
		newArr.push({ ...v });
		cacheList.push(v.name);
		store.dispatch('keepAliveNames/setCacheKeepAlive', cacheList);
	});
	return newArr;
}

// 判断路由 auth 中是否包含当前登录用户权限字段
export function hasAuth(auths, route) {
	if (route.meta && route.meta.auth) return auths.some((auth) => route.meta.auth.includes(auth));
	else return true;
}

// 递归过滤有权限的路由
export function setFilterMenuFun(routes, auth) {
	const menu = [];
	routes.forEach((route) => {
		const item = { ...route };
		if (hasAuth(auth, item)) {
			if (item.children) item.children = setFilterMenuFun(item.children, auth);
			menu.push(item);
		}
	});
	return menu;
}

// 缓存多级嵌套数组处理后的一维数组(tagsView、菜单搜索中使用：未过滤隐藏的(isHide))
export function setCacheTagsViewRoutes(arr) {
	// 先处理有权限的路由，否则 tagsView、菜单搜索中无权限的路由也将显示
	let authsRoutes = setFilterMenuFun(arr, store.state.userInfos.userInfos.authPageList);
	// 添加到 vuex setTagsViewRoutes 中
	store.dispatch('tagsViewRoutes/setTagsViewRoutes', formatTwoStageRoutes(formatFlatteningRoutes(authsRoutes)));
}

// 递归处理多余的 layout : <router-view>，让需要访问的组件保持在第一层 layout 层。
// 因为 `keep-alive` 只能缓存二级路由
// 默认初始化时就执行
export function keepAliveSplice(to) {
	if (to.matched && to.matched.length > 2) {
		to.matched.map((v, k) => {
			if (v.components.default instanceof Function) {
				v.components.default().then((components) => {
					if (components.default.name === 'parent') {
						to.matched.splice(k, 1);
						router.push({ path: to.path, query: to.query });
						keepAliveSplice(to);
					}
				});
			} else {
				if (v.components.default.name === 'parent') {
					to.matched.splice(k, 1);
					keepAliveSplice(to);
				}
			}
		});
	}
}

// 处理后端返回的 `component` 路径，拼装实现懒加载
export function loadView(path) {
	// return (resolve) => require([`@/views/${path}.vue`], resolve);
	return () => Promise.resolve(require(`@/views/${path}`));
}

// 递归处理每一项 `component` 中的路径
export function dynamicRouter(view) {
	if (view.component) view.component = loadView(view.component);
	if (view.children) view.children.map((item) => dynamicRouter(item));
	return view;
}

// 添加动态路由，`{ path: '*', redirect: '/404' }` 防止页面刷新，静态路由丢失问题
// next({ ...to, replace: true }) 动态路由 addRoute 完毕后才放行，防止刷新时 NProgress 进度条加载2次
// 文档地址：https://router.vuejs.org/zh/api/#router-addroutes
export function loadMenu(router, to, next) {
	resetRouter();
	// 读取用户信息，获取对应权限进行判断
	let menus = store.getters.menus;
	console.log(menus);
	let data = {
		"path": "/",
		"name": "首页",
		"redirect": "/home",
		"component": "layout/index",
		"children": []
	};
	data.children = menus;
	store.dispatch('userInfos/setUserInfos');
	store.dispatch('routesList/setRoutesList', setFilterMenuFun(data.children, store.state.userInfos.userInfos.authPageList));
	data.children = store.state.routesList.routesList;
	console.log("begin add route...");
	[dynamicRouter(data), { path: '*', redirect: '/404' }].forEach((route) => {
		console.log({ ...route })
		router.addRoute({ ...route });
	});
	// let newRouters = [dynamicRouter(data)];
	// router.addRoutes(newRouters);
	console.log("end add route...");
	setCacheTagsViewRoutes(JSON.parse(JSON.stringify(data.children)));
	next({ ...to, replace: true });
}

// 重置路由
export function resetRouter() {
	router.matcher = createRouter().matcher;
}

// 延迟关闭进度条
export function delayNProgressDone(time = 300) {
	setTimeout(() => {
		NProgress.done();
	}, time);
}

// 动态加载后端返回路由路由(模拟数据)
export function getRouterList(router, to, next) {
	// if (!Session.get('userInfo')) return false;
	loadMenu(router, to, next);

}

// 路由加载前
router.beforeEach(async(to, from, next) => {
	keepAliveSplice(to);
	NProgress.configure({ showSpinner: false });
	if (to.meta.title && to.path !== '/login') NProgress.start();
	let hasToken = getToken();
	console.log("route hasToken="+hasToken)
	console.log("route to.path="+to.path)
	if (to.path === '/login' && !hasToken) {
		NProgress.start();
		next();
		delayNProgressDone();
	} else if(to.path=='/register'){
		NProgress.start();
		next();
		delayNProgressDone();
	} else if(to.path=='/recruit'){
		NProgress.start();
		next();
		delayNProgressDone();
	} else if(to.path=='/uniapp'){
		next();
	} else {
		if (!hasToken) {
			/* has no token*/
			if (whiteList.indexOf(to.path) !== -1) {
				// in the free login whitelist, go directly
				next()
			} else{
				NProgress.start();
				// next('/login');
				let uniApp = Local.get("isUniapp") ? true : false;
				console.log("route to login uniApp="+uniApp)
				if(uniApp){
					Local.clear();
					console.log("current is uniapp, post logout msg..................................")
					uni.postMessage({data: { logOut: true}})
				}
				next(`/login?redirect=${to.path}`)
				Session.clear();
				removeToken();

				// Local.clear();
				delayNProgressDone();
			}
		} else {
			// determine whether the user has obtained his permission roles through getInfo
			const hasRoles = store.getters.roles && store.getters.roles.length > 0
			if (hasRoles) {
				next()
				delayNProgressDone(0);
			} else {
				console.log("hasRolse is false")
				try {
					// get user info
					// note: roles must be a object array! such as: ['admin'] or ,['developer','editor']
					console.log("get user info")
					const { roles, username, avatar } = await store.dispatch('user/getInfo')
					var rnames = []
					roles.forEach(({ roleName }, idx) => {
						rnames[idx] = roleName
					})
					const userInfos = {
						userName: username,
						photo: avatar,
						time: new Date().getTime(),
						authPageList: [],
						authBtnList: [],
					};
					// 存储用户信息到浏览器缓存
					Session.set('userInfo', userInfos);
					// 存储用户信息到vuex
					store.dispatch('userInfos/setUserInfos', userInfos);

					getRouterList(router, to, next);
					// next({ ...to, replace: true });
					// // generate accessible routes map based on roles
					// const accessRoutes = await store.dispatch('permission/generateRoutes', rnames)
					//
					// // dynamically add accessible routes
					// router.addRoutes(accessRoutes)

					// hack method to ensure that addRoutes is complete
					// set the replace: true, so the navigation will not leave a history record
					// next({ ...to, replace: true })
				} catch (error) {
					// remove token and go to login page to re-login
					await store.dispatch('user/resetToken')
					Message.error(error || 'Has Error')
					next(`/login?redirect=${to.path}`)
					delayNProgressDone(0);
				}
			}
			// if (Object.keys(store.state.routesList.routesList).length <= 0) {
			// 	getRouterList(router, to, next);
			// } else {
			// 	next();
			// 	delayNProgressDone(0);
			// }
		}
	}
});

// 路由加载后
router.afterEach(() => {
	PrevLoading.done();
	delayNProgressDone();
});

// 导出路由
export default router;
