<template>
	<div>
		<el-form ref="form" :model="form" label-width="100px">
			<el-row>
				<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" >
					<el-form-item  label="所属公司" prop="company" :rules="[{ required: true, message: '讲师所属公司不为空。', trigger: 'blur' }]" >
						<el-select v-model="form.company" clearable :disabled="formReadOnly" @clear="handleClearable" placeholder="请选择" collapse-tags ref="teacherCompanySelected" >
							<el-option :value="form.companyId" :label="form.company" style="height: auto">
								<el-tree
									:data="companyOptions"
									:props="defaultProps"
									:expand-on-click-node="false"
									ref="tree"
									show-checkbox
									node-key="id"
									default-expand-all
									@node-click="handleNodeClick"
									@check="handleNodeClick"
									highlight-current
								/>
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" >
					<el-form-item label="姓名" prop="name" :rules="[{ required: true, message: '用户名不能为空', trigger: 'blur' }]">
						<el-input :readonly="formReadOnly" v-model="form.name" placeholder="请输入用户名" />
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" >
					<el-form-item label="性别" prop="gender" :rules="[{ required: true, message: '性别不为空。', trigger: 'blur' }]" >
						<el-select :disabled="formReadOnly" v-model="form.gender" placeholder="请选择性别">
							<el-option label="男" value="男" />
							<el-option label="女" value="女" />
							<el-option label="未知" value="未知" />
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
					<el-form-item label="手机号" prop="telephone" :rules="[{ required: true, message: '手机号不能为空', trigger: 'blur' },{ required: false, validator: validatorMobilePhone, trigger: 'blur' }]">
						<el-input v-model="form.telephone" :readonly="formReadOnly" placeholder="请输入手机号" />
					</el-form-item>
				</el-col>
			
				<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" >
					<el-form-item label="讲师类型" prop="type" :rules="[{ required: true, message: '讲师类型不为空。', trigger: 'blur' }]" >
						<el-select v-model="form.type" placeholder="请选择课程类型" :disabled="formReadOnly">
							<el-option v-for="item in teacherTypes" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue" />
						</el-select>
						
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" >
					<el-form-item  v-if="form.type==1" label="讲师公司" prop="company" :rules="[{ required: true, message: '公司不为空。', trigger: 'blur' }]" >
						<!-- <el-input v-model="course.companyName" readonly="true"></el-input> -->
						<el-select v-model="form.teacherCompany" clearable :disabled="formReadOnly" @clear="handleClearable" placeholder="请选择" collapse-tags ref="teacherCompanySelected2" >
							<el-option :value="form.teacherCompanyId" :label="form.company" style="height: auto">
								<el-tree
									:data="companyOptions"
									:props="defaultProps"
									:expand-on-click-node="false"
									ref="tree"
									show-checkbox
									node-key="id"
									default-expand-all
									@node-click="handleNodeClick2"
									@check="handleNodeClick2"
									highlight-current
								/>
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-else  label="公司" prop="teacherCompany" :rules="[{ required: true, message: '讲师的公司不为空。', trigger: 'blur' }]" >
						<el-input v-model="form.teacherCompany" :readonly="formReadOnly" placeholder="请输入讲师的公司名"></el-input>
					</el-form-item>
				</el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" >
					<el-form-item label="部门" v-if="form.type==1"  prop="departmentId" :rules="[{ required: true, message: '部门不为空。', trigger: 'blur' }]" >
						<treeselect
							
							:disabled="formReadOnly"
							v-model="form.departmentId"
							:options="departmentOptions"
							:normalizer="normalizerDepartment"
							placeholder="请选择所属部门"
						/>
					</el-form-item>
					<el-form-item v-else label="部门" prop="department" :rules="[{ required: true, message: '部门不为空。', trigger: 'blur' }]" >
						<el-input  v-model="form.department" :readonly="formReadOnly" placeholder="请输入部门名"></el-input>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" >
					<el-form-item  v-if="form.type==1"  label="岗位" prop="positionId" :rules="[{ required: true, message: '岗位不为空。', trigger: 'blur' }]" >
						<el-select
							:disabled="formReadOnly"
							clearable
							v-model="form.positionId"
							placeholder="请选择岗位"
							style="width: 100%"
						>
							<el-option v-for="dict in positionList" :key="dict.id" :label="dict.name" :value="dict.id"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item v-else label="岗位" prop="position" :rules="[{ required: true, message: '岗位不为空。', trigger: 'blur' }]" >
						<el-input  v-model="form.position" :readonly="formReadOnly" placeholder="请输入岗位名"></el-input>
					</el-form-item>
				</el-col>
					<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" >
					<el-form-item label="是否在职" prop="leaving" :rules="[{ required: true, message: '是否在职必填', trigger: 'blur' }]" >
						<el-checkbox v-model="form.leaving" :disabled="formReadOnly"></el-checkbox>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
                <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" >
                    <div style="margin-left: 100px; margin-bottom: 10px ;margin-top: 10px">
                            <el-upload
                                class="avatar-uploader"
                                action="uploadPersonalPhoto()"
                                :show-file-list="false"
								v-loading="pictureLoading"
                                :on-success="handleAvatarSuccess"
                                :http-request="uploadPersonalPhoto"
                                :auto-upload="true"
                                :disabled="formReadOnly"
                                accept="image/jpeg,image/png"
                                :before-upload="beforeAvatarUpload"
                            >
                                <img v-if="form.photoUrl" :src="form.photoUrl" class="avatar" />
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                            <label style="font-size: large; font-weight: 520px">员工证相片</label>
                    </div>
                </el-col>
                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" >
					<el-form-item label=" ">
						<el-button v-if="isNewTeacher" v-on:click="addTeacher" :loading="addLoading" plain type="primary">增加</el-button>
						<el-button v-if="updateTeacher" v-on:click="updateTeacherHandle" :loading="updateLoading" plain type="primary">更新</el-button>
						<el-button style="margin-top: 10px" type="primary" plain @click="cancel">关 闭</el-button>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
	</div>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import permission from '@/directive/permission/index.js'; // 权限判断指令
import { getTreeselectCompany } from '@/api/org/company';
import { getTreeselectDepartment } from '@/api/org/department';
import { getTreeselectPosition } from '@/api/org/position';
import { UploadPersonalPicture} from "@/api/employ/employ";
import {DICT_TYPE,getDictLabelFromData, getDictDataLabel, getDictDatas} from "@/utils/dict";
import {downloadPersonalPhoto} from '@/api/employ/employ';
import { addTrainingTeacher, updateTrainingTeacher,UploadTeacherPicture } from '@/api/training/trainingTeacher';

export default {
	name: 'teacher',
	components: {
		Treeselect,
	},
	
	data() {
        let teacher = null;
        if (this.initTeacher == null) {
            teacher = {
                name:"",
                gender:"",
                telephone:"",
                leaving:false,
                type:"",
                companyId:'',
                departmentId:null,
                positionId:null,
                photoUrl:"",
				company:"",
				department:"",
				position:""
            };
        }
        else{
            teacher = {...this.initTeacher};
        }
		return {
            form:teacher,
            isNewTeacher:this.newTeacher,
            formReadOnly:this.initReadonly,
            addLoading:false,
            updateLoading:false,
            teacherTypes:[],
            companyOptions: [],
			companyList: [],
			departmentOptions: [],
      		positionList:[],
			positionOptions: [],
            defaultProps: {
				children: 'children',
				label: 'companyName',
			},
			tempTeacherPhotoUrl:"",
			pictureLoading:false,
        };
	},
    props:{initTeacher:Object,initReadonly:Boolean,newTeacher:Boolean,updateTeacher:Boolean},
	mounted() {
       
    },
    created(){
        this.loading();
    },
	methods: {
        loading(){
            this.getDicts('teacher_type').then((response) => {
				this.teacherTypes = response.data;
			});

			getTreeselectCompany(null, (list, options) => {
				this.companyList = list;
				this.companyOptions = options;
			});

			if(this.initTeacher != null)
			{
				this.getDepartmentPosition();
				this.downloadPersonalPhoto();
			}
		
			
        },

        // 节点单击事件
		handleNodeClick(data) {
			 this.form.companyId = data.id;
			 this.form.company = data.companyName;
			let selectArray = [];
			selectArray.push(data.id);
			this.$refs.tree.setCheckedKeys(selectArray);
			this.$refs.teacherCompanySelected.blur()
			
			//this.getDepartmentPosition();
            
		},
		// 节点单击事件
		handleNodeClick2(data) {
			 this.form.teacherCompanyId = data.id;
			 this.form.teacherCompany = data.companyName;
			let selectArray = [];
			selectArray.push(data.id);
			this.$refs.tree.setCheckedKeys(selectArray);
			this.$refs.teacherCompanySelected2.blur()
			
			this.getDepartmentPosition();
            
		},
		getDepartmentPosition(){
			getTreeselectDepartment(this.form.companyId, false, (list, options) => {
				this.departmentList = list;
				this.departmentOptions = options;
			});
            
            getTreeselectPosition(this.form.companyId, false, (list, options) => {
				this.positionList = list;
				this.positionOptions = options;
			});
		},
         addTeacher(){
			 let that = this;
			this.$refs['form'].validate((valid) => {
				if (valid) {
					// that.course.companyName = that.currentCompanyName;
					// that.course.companyId = that.currentCompanyId;
					//that.form.belongToCompanyId = this.$store.getters.userInfo.companyId;
					that.addLoading = true;
					if(that.form.type==1){
						that.form.department = that.departmentList.find(item=>item.id==that.form.departmentId).name;
						that.form.position = that.positionList.find(item=>item.id==that.form.positionId).name;
					}
					let currentForm = {...that.form};
					currentForm.photoUrl = that.tempTeacherPhotoUrl;
					addTrainingTeacher(currentForm)
						.then((response) => {
							that.addLoading = false;
							that.msgSuccess('添加讲师成功。');
							that.$emit('close', { refresh: true, isSuccess: true });
						})
						.catch((err) => {
							that.addLoading = false;
							that.msgError('添加讲师失败，' + err);
						});
				} else {
					that.msgError('有非法输入或必填项未填写，请检查!');
				}
			});
        },
		updateTeacherHandle(arg){
			 let that = this;
			this.$refs['form'].validate((valid) => {
				if (valid) {
					that.updateLoading = true;
					if(that.form.type==1){
						that.form.department = that.departmentList.find(item=>item.id==that.form.departmentId).name;
						that.form.position = that.positionList.find(item=>item.id==that.form.positionId).name;
					}
					let currentForm = {...that.form};
					currentForm.photoUrl = that.tempTeacherPhotoUrl;
					updateTrainingTeacher(currentForm)
						.then((response) => {
							that.updateLoading = false;
							that.msgSuccess('编辑讲师成功。');
							that.$emit('close', { refresh: true, isSuccess: true });
						})
						.catch((err) => {
							that.updateLoading = false;
							that.msgError('编辑讲师失败，' + err);
						});
				} else {
					that.msgError('有非法输入或必填项未填写，请检查!');
				}
			});
			
		},
		
        cancel(){
          this.$emit('close', { refresh: false, isSuccess: true });  
        },
		uploadPersonalPhoto(params) {
			this.formData = new FormData();
			this.formData.append('file', params.file);
			let self = this;
			this.pictureLoading = true;
			UploadTeacherPicture(this.formData)
				.then((response) => {
					if (response.status == 200) {
						let resData = response.data;
						self.tempTeacherPhotoUrl= response.headers["content-disposition"].split('=')[1];
						var blob = new Blob([resData], { type: 'application/octet-stream' });
						self.form.photoUrl =URL.createObjectURL(blob);
						this.$forceUpdate()
						this.$message.success('上传成功!');
					}
					else{
						this.$message.error('上传失败：');
					}
					this.pictureLoading = false;
				})
				.catch((err) => {
					this.pictureLoading = false;
					this.$message.error('上传失败：'+err);

				});
		},

		handleAvatarSuccess(res, file) {
			//this.form.photo = URL.createObjectURL(file.name);
		},
		beforeAvatarUpload(file) {
			const isJPG = file.type === 'image/jpeg';
			const isPng = file.type === 'image/png'
			const isLt10M = file.size / 1024 / 1024 < 10;
			if (!isJPG && !isPng) {
				this.$message.error('上传头像图片只能是 JPG,JPEG,PNG 格式!');
			}
			if (!isLt10M) {
				this.$message.error('上传头像图片大小不能超过 10MB!');
			}
			return (isJPG || isPng) && isLt10M;
		},
		downloadPersonalPhoto() {
			if (this.form.photoUrl != null && this.form.photoUrl.length > 0) {
				// download
				this.tempTeacherPhotoUrl = this.form.photoUrl;
				this.pictureLoading=true;
				downloadPersonalPhoto(this.form.photoUrl)
					.then((res) => {
						if (res) {
							let resData = res.data;
							var blob = new Blob([resData], { type: 'application/octet-stream' });
							this.form.photoUrl = URL.createObjectURL(blob);
						} else {
							this.msgError(`后端下载文件出错 & ${e}`);
						}
						this.pictureLoading = false;
					})
					.catch((e) => {
						this.pictureLoading = false;
						this.msgError(`后端下载文件出错 & ${e}`);
					});
			}
		},
		handleClearable()
		{
				this.$refs.tree.setCheckedKeys([]);
				this.form.companyId=null;
				this.form.company='';
		},
		/** 转换部门数据结构 */
		normalizerDepartment(node) {
			return this.normalizer(node);
		},
		validatorMobilePhone (rule, value, callback) {
			if (value === '' || value == null) {
				//callback(new Error('手机号不能为空'));
				callback();
			} else if (!/^((13[0-9])|(14[5|7])|(15([0-3]|[5-9]))|(18[0,5-9]))\d{8}$/.test(value)) {
				callback(new Error('手机号格式错误，11位数字。'));
			} else {
				callback();
			}
		},
    },
};
</script>

<style lang="scss" scoped>
</style>