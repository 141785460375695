
import request from '@/utils/request'

// Find current company all trainings
export function findAllTrainingPlans(query) {
    return request({
        url: '/TrainingPlan/findAll',
        method: 'post',
        data: query
    })
}

export function findAllTrainingPlanNames() {
    return request({
        url: '/TrainingPlan/findAllTrainingPlanNames',
        method: 'get'
    })
}

export function getTraingPlan(id) {
    return request({
        url: '/TrainingPlan/findOne?id=' + id,
        method: 'post'
    })
}

export function releaseTrainingPlan(id) {
    return request({
        url: '/TrainingPlan/releaseTrainingPlan?trainingPlanId=' + id,
        method: 'post'
    })
}


export function getEmployees(companyId) {
    return request({
        url: '/TrainingPlan/getEmployees?companyId=' + companyId,
        method: 'post'
    })
}

// 新增培训计划
export function addTrainingPlan(data) {
    return request({
        url: '/TrainingPlan/add',
        method: 'post',
        data: data
    })
}

// 更新培训计划
export function updateTrainingPlan(data) {
    return request({
        url: '/TrainingPlan/edit',
        method: 'post',
        data: data
    })
}

// 删除培训计划
export function deleteTrainingPlan(ids) {
    return request({
        url: '/TrainingPlan/remove',
        method: 'post',
        data: ids
    })
}
