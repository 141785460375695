import request from '@/utils/request'

// 查询考勤组管理列表
export function listAttendanceGroup(query) {
    return request({
        url: '/attendance/attendanceGroup/findAll',
        method: 'post',
        data: query
    })
}
// 查询我管理的考勤组管理列表
export function listAttGroupWithMe(query) {
    return request({
        url: '/attendance/attendanceGroup/listAttGroupWithMe',
        method: 'post',
        data: query
    })
}

// 查询考勤组管理详细
export function getAttendanceGroup(id) {
    return request({
        url: '/attendance/attendanceGroup/findOne?id=' + id,
        method: 'post'
    })
}
export function getGroupByEmpId(id) {
    return request({
        url: '/attendance/attendanceGroup/getGroupByEmpId?id=' + id,
        method: 'post'
    })
}

// 新增考勤组管理
export function addAttendanceGroup(data) {
    return request({
        url: '/attendance/attendanceGroup/add',
        method: 'post',
        data: data
    })
}

// 修改考勤组管理
export function updateAttendanceGroup(data) {
    return request({
        url: '/attendance/attendanceGroup/edit',
        method: 'post',
        data: data
    })
}

// 保存排班
export function attArrange(data) {
    return request({
        url: '/attendance/attendanceGroup/attArrange',
        method: 'post',
        data: data
    })
}

// 删除考勤组管理
export function delAttendanceGroup(id) {
    return request({
        url: '/attendance/attendanceGroup/remove',
        method: 'post',
        data: id
    })
}

// 导出考勤组管理
export function exportAttendanceGroup(query) {
    return request({
        url: '/attendance/attendanceGroup/export',
        method: 'post',
        data: query
    })
}
