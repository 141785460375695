import request from '@/utils/request'

// 查询调动调岗列表
export function listTransfer(query) {
    return request({
        url: '/bpm/transfer/findAll',
        method: 'post',
        data: query
    })
}

// 初始化调动调岗
export function initApply() {
    return request({
        url: '/bpm/transfer/initApply',
        method: 'get'
    })
}

// 修改转正申请
export function completeTask(data) {
    return request({
        url: '/bpm/transfer/completeTask',
        method: 'post',
        data: data
    })
}

// 查询调动调岗详细
export function getTransfer(id) {
    return request({
        url: '/bpm/transfer/findOne?id=' + id,
        method: 'post'
    })
}

// 查询调动调岗详细
export function getByProcessInstanceId(processInstanceId) {
    return request({
        url: '/bpm/transfer/getByProcessInstanceId?processInstanceId=' + processInstanceId,
        method: 'post'
    })
}

// 新增调动调岗
export function addTransfer(data) {
    return request({
        url: '/bpm/transfer/add',
        method: 'post',
        data: data
    })
}

// 修改调动调岗
export function updateTransfer(data) {
    return request({
        url: '/bpm/transfer/edit',
        method: 'post',
        data: data
    })
}

// 删除调动调岗
export function delTransfer(id) {
    return request({
        url: '/bpm/transfer/remove',
        method: 'post',
        data: id
    })
}

// 导出调动调岗
export function exportTransfer(query) {
    return request({
        url: '/bpm/transfer/export',
        method: 'post',
        data: query
    })
}
