<template>
	<div>
		<el-form ref="form" :model="form" label-width="100px">
			<el-row>
				<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" >
					<el-form-item  label="公司" prop="company" :rules="[{ required: true, message: '公司不为空。', trigger: 'blur' }]" >
						<!-- <el-input v-model="course.companyName" readonly="true"></el-input> -->
						<el-select v-model="form.company" clearable :disabled="formReadOnly" @clear="handleClearable" placeholder="请选择" collapse-tags ref="teacherCompanySelected" >
							<el-option :value="form.companyId" :label="form.company" style="height: auto">
								<el-tree
									:data="companyOptions"
									:props="defaultProps"
									:expand-on-click-node="false"
									ref="tree"
									show-checkbox
									node-key="id"
									default-expand-all
									@node-click="handleNodeClick"
									@check="handleNodeClick"
									highlight-current
								/>
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" >
					<el-form-item label="机构名称" prop="name" :rules="[{ required: true, message: '机构名称不能为空', trigger: 'blur' }]">
						<el-input :readonly="formReadOnly" v-model="form.name" placeholder="请输入机构名称" />
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
					<el-form-item label="机构地址" prop="address" :rules="[{ required: true, message: '机构地址不能为空', trigger: 'blur' }]">
						<el-input v-model="form.address" :readonly="formReadOnly" placeholder="请输入机构地址" />
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
					<el-form-item  label="办公电话" prop="officeTelephone" :rules="[{ required: true, message: '机构办公电话不为空。', trigger: 'blur' },{ validator: isTelOrFax, trigger: 'blur' }]" >
						<el-input v-model="form.officeTelephone" :readonly="formReadOnly" placeholder="请输入机构电话"></el-input>
					</el-form-item>
				</el-col>
                <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" >
					
					<el-form-item  label="联系人" prop="contactor" :rules="[{ required: true, message: '机构联系不为空。', trigger: 'blur' }]" >
						<el-input  v-model="form.contactor" :readonly="formReadOnly" placeholder="请输入机构联系人"></el-input>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" >
					
					<el-form-item  label="联系人手机" prop="contactPhone" :rules="[{ required: true, message: '机构联系人手机号码不为空。', trigger: 'blur' },{ required: false, validator: validatorMobilePhone, trigger: 'blur' }]" >
						<el-input  v-model="form.contactPhone" :readonly="formReadOnly" placeholder="请输入机构联系人手机号码"></el-input>
					</el-form-item>
				</el-col>
					
			</el-row>
			<el-row>
                <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" >
                    <div style="margin-left: 100px; margin-bottom: 10px ;margin-top: 10px">
                            <el-upload
                                class="avatar-uploader"
                                action="uploadAgencyPhoto()"
                                :show-file-list="false"
								v-loading="pictureLoading"
                                :on-success="handleAvatarSuccess"
                                :http-request="uploadAgencyPhoto"
                                :auto-upload="true"
                                :disabled="formReadOnly"
                                accept="image/jpeg,image/png"
                                :before-upload="beforeAvatarUpload"
                            >
                                <img v-if="form.photoUrl" :src="form.photoUrl" class="avatar" />
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                            <label style="font-size: large; font-weight: 520px">机构相片</label>
                    </div>
                </el-col>
                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" >
					<el-form-item label=" ">
						<el-button v-if="isNewAgency" v-on:click="addAgency" :loading="addLoading" plain type="primary">增加</el-button>
						<el-button v-if="updateAgency" v-on:click="updateAgencyHandle" :loading="updateLoading" plain type="primary">更新</el-button>
						<el-button style="margin-top: 10px" type="primary" plain @click="cancel">关 闭</el-button>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
	</div>
</template>

<script>

import permission from '@/directive/permission/index.js'; // 权限判断指令
import {DICT_TYPE,getDictLabelFromData, getDictDataLabel, getDictDatas} from "@/utils/dict";
import {downloadPersonalPhoto} from '@/api/employ/employ';
import { addTrainingAgency, updateTrainingAgency,UploadAgancyPicture } from '@/api/training/trainingAgency';
import { getTreeselectCompany } from '@/api/org/company';
export default {
	name: 'agency',
	components: {

	},
	
	data() {
		
        let agency = null;
        if (this.initAgency == null) {
            agency = {
                name:"",
                address:"",
                officeTelephone:"",
                officeTelephone:'',
                contactor:"",
                contactPhone:'',
				company:"",
				companyId:'',
            };
        }
        else{
            agency = {...this.initAgency};
        }
		return {
            form:agency,
            isNewAgency:this.newAgency,
            formReadOnly:this.initReadonly,
            addLoading:false,
            updateLoading:false,
			tempAgencyPhotoUrl:"",
			pictureLoading:false,
			companyOptions: [],
			companyList: [],
            defaultProps: {
				children: 'children',
				label: 'companyName',
			},
        };
	},
    props:{initAgency:Object,initReadonly:Boolean,newAgency:Boolean,updateAgency:Boolean},
	mounted() {
       
    },
    created(){
        this.loading();
    },
	methods: {
        loading(){
			getTreeselectCompany(null, (list, options) => {
				this.companyList = list;
				this.companyOptions = options;
			});

			this.downloadPersonalPhoto();
        },

		// 节点单击事件
		handleNodeClick(data) {
			 this.form.companyId = data.id;
			 this.form.company = data.companyName;
			let selectArray = [];
			selectArray.push(data.id);
			this.$refs.tree.setCheckedKeys(selectArray);
			this.$refs.teacherCompanySelected.blur()
		},
		handleClearable()
		{
				this.$refs.tree.setCheckedKeys([]);
				this.form.companyId=null;
				this.form.company='';
		},
		
         addAgency(){
			 let that = this;
			this.$refs['form'].validate((valid) => {
				if (valid) {
					//that.form.belongToCompanyId = this.$store.getters.userInfo.companyId;
					let currentForm = {...that.form};
					currentForm.photoUrl = that.tempAgencyPhotoUrl;
					that.addLoading = true;
					addTrainingAgency(currentForm)
						.then((response) => {
							that.addLoading = false;
							that.msgSuccess('添加机构成功。');
							that.$emit('close', { refresh: true, isSuccess: true });
						})
						.catch((err) => {
							that.addLoading = false;
							that.msgError('添加机构失败，' + err);
						});
				} else {
					that.msgError('有非法输入或必填项未填写，请检查!');
				}
			});
        },
		updateAgencyHandle(arg){
			 let that = this;
			this.$refs['form'].validate((valid) => {
				if (valid) {
					let currentForm = {...that.form};
					currentForm.photoUrl = that.tempAgencyPhotoUrl;
					that.addLoading = true;
					updateTrainingAgency(currentForm)
						.then((response) => {
							that.addLoading = false;
							that.msgSuccess('编辑机构成功。');
							that.$emit('close', { refresh: true, isSuccess: true });
						})
						.catch((err) => {
							that.addLoading = false;
							that.msgError('编辑机构失败，' + err);
						});
				} else {
					that.msgError('有非法输入或必填项未填写，请检查!');
				}
			});
			
		},
		
        cancel(){
          this.$emit('close', { refresh: false, isSuccess: true });  
        },
		uploadAgencyPhoto(params) {
			this.formData = new FormData();
			this.formData.append('file', params.file);
			let self = this;
			this.pictureLoading = true;
			UploadAgancyPicture(this.formData)
				.then((response) => {
					this.pictureLoading = false;
					if (response.status == 200) {
						let resData = response.data;
						self.tempAgencyPhotoUrl= response.headers["content-disposition"].split('=')[1];
						var blob = new Blob([resData], { type: 'application/octet-stream' });
						self.form.photoUrl =URL.createObjectURL(blob);
						this.$forceUpdate()
						this.$message.success('上传成功!');
					}
					else{
						this.$message.error('上传失败：');
					}
				})
				.catch((err) => {
					this.pictureLoading = false;
					this.$message.error('上传失败：'+err);
				});
		},

		handleAvatarSuccess(res, file) {
			//this.form.photo = URL.createObjectURL(file.name);
		},
		beforeAvatarUpload(file) {
			const isJPG = file.type === 'image/jpeg';
			const isPng = file.type === 'image/png'
			const isLt10M = file.size / 1024 / 1024 < 10;
			if (!isJPG && !isPng) {
				this.$message.error('上传头像图片只能是 JPG,JPEG,PNG 格式!');
			}
			if (!isLt10M) {
				this.$message.error('上传头像图片大小不能超过 10MB!');
			}
			return (isJPG || isPng) && isLt10M;
		},
		downloadPersonalPhoto() {
			if (this.form.photoUrl != null && this.form.photoUrl.length > 0) {
				// download
				this.tempAgencyPhotoUrl = this.form.photoUrl;
				this.pictureLoading = true;
				downloadPersonalPhoto(this.form.photoUrl)
					.then((res) => {
						if (res) {
							let resData = res.data;
							var blob = new Blob([resData], { type: 'application/octet-stream' });
							this.form.photoUrl = URL.createObjectURL(blob);
						} else {
							this.msgError(`后端下载文件出错 & ${e}`);
						}
						this.pictureLoading=false;
					})
					.catch((e) => {
						this.pictureLoading = false;
						this.msgError(`后端下载文件出错 & ${e}`);
					});
			}
		},
		
		
		validatorMobilePhone (rule, value, callback) {
			if (value === '' || value == null) {
				//callback(new Error('手机号不能为空'));
				callback();
			} else if (!/^((13[0-9])|(14[5|7])|(15([0-3]|[5-9]))|(18[0,5-9]))\d{8}$/.test(value)) {
				callback(new Error('手机号格式错误，11位数字。'));
			} else {
				callback();
			}
		},
		isTelOrFax (rule, value, callback) {
				if (!value) {
					callback();
				} else {
					const reg = /^(\d{3,4}-)?\d{7,8}$/;
					const isTel = reg.test(value);
					if (value.length < 10 || value.length > 13 || !isTel ) {//判断传真字符范围是在10到13
						callback(new Error("座机或传真格式如:075-1234567"));
					} else {
						callback();
					}
				}

			}
    },
};
</script>

<style lang="scss" scoped>
</style>