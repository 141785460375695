<template>
  <div>
    <el-tabs type="border-card">
      <el-tab-pane label="历史跟踪">
        <el-steps :active="stepActive" simple finish-status="success">
          <el-step :title="stepTitle(item)" icon="el-icon-edit" v-for="(item) in handleTask.historyTask"></el-step>
        </el-steps>
        <br/>
        <el-steps direction="vertical" :active="stepActive" space="65px">
          <el-step :title="stepTitle(item)" :description="stepDes(item, handleTask)"
                   v-for="(item) in handleTask.historyTask"></el-step>
        </el-steps>
      </el-tab-pane>
      <el-tab-pane label="流程图">
        <div v-html="highlightSvgUrl"></div>
      </el-tab-pane>
      <el-tab-pane label="相关人">
        <div>
          <h3>相 关 人</h3>
          <el-timeline>
            <template v-for="(activity, index) in approvesOrder">
            <el-timeline-item v-if="processVarMap && processVarMap[index] && processVarMap[index].valueLabel"
                :key="index"
                :timestamp="activity">
              {{processVarMap[index].valueLabel}}
            </el-timeline-item>
            </template>
          </el-timeline>

<!--          <ul>-->
<!--            <li v-for="item in processVarMap" :key="item.id">-->
<!--              <h3>{{item.label}} - {{item.valueLabel}}({{item.value}})</h3>-->
<!--            </li>-->
<!--          </ul>-->
        </div>
<!--        <el-table v-loading="loading" :data="processVarMap" style="width:100%" stripe border>-->
<!--          <el-table-column label="姓名" align="center" prop="name" sortable width="auto" min-width="150"  :show-overflow-tooltip='true'/>-->
<!--          <el-table-column label="姓名" align="center" prop="name" sortable width="auto" min-width="150"  :show-overflow-tooltip='true'/>-->
<!--          <el-table-column label="姓名" align="center" prop="name" sortable width="auto" min-width="150"  :show-overflow-tooltip='true'/>-->
<!--        </el-table>-->
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import {taskSteps, getHighlightImg, processRelatedEmploy} from "@/api/bpm/todoList.js";
import { showLoading, hideLoading } from '@/utils/reqloading';
import {getStepActive, getStepDes, getStepTitle} from "@/views/pages/bpm/bpmUtils";

export default {
  name: "flowDetail",
  props: {
    processInstanceIdIn: String,
  },
  watch:{
    processInstanceIdIn(){
      console.log("watch")
      console.log("this.processInstanceId");
      console.log(this.processInstanceId);

      this.load();
    },
  },
  computed: {
    stepActive: function () {
      console.log("stepActive");
      console.log(this.handleTask);
      let idx = 0;
      for (let i = 0; i < this.handleTask.historyTask.length; i++) {
        if (this.handleTask.historyTask[i].status === 1) {
          idx = idx + 1;
        } else {
          break;
        }
      }
      return idx;
    },
    stepTitle() {
      return getStepTitle;
    },
    stepDes() {
      return getStepDes;
    }
  },

  data() {
    return {
      processInstanceId: null,
      handleTask: {
        historyTask: [],
        userNameMap: {},
      },
      processVarMap:{},
      highlightSvgUrl: "",
      taskStep: null,
      loading: true,
      approvesOrder: {"dm":"部门领导","outDM":"调出部门领导","inDM":"调入部门领导", "Official":"人事专员", "hr":"人事专员",
        "finance":"财务专员", "HRManager":"人事经理", "gm":"总经理"},
    }
  },
  mounted() {
    this.processInstanceId = this.processInstanceIdIn;
    console.log("create");
    console.log("this.processInstanceId");
    console.log(this.processInstanceId);

    this.load();
  },
  methods: {
    load(){
      this.processInstanceId = this.processInstanceIdIn;
      console.log("this.processInstanceId");
      console.log(this.processInstanceId);
      this.getHighlightImg(this.processInstanceId);
      showLoading();
      taskSteps(this.processInstanceId).then(response => {
        console.log(response.data);
        this.handleTask = response.data;
        hideLoading();
      });
      processRelatedEmploy(this.processInstanceId).then(response => {
        console.log(response.data);
        this.loading = false;
        this.processVarMap = response.data;
        hideLoading();
      });
    },
    getHighlightImg(processInstanceId) {
      getHighlightImg(processInstanceId).then(response => {
        this.highlightSvgUrl = response.data
      })
    },
    // close() {
    //   this.$emit("close", {refresh: false, isSuccess: true});
    // },
  },
}
</script>

<style scoped>

</style>
