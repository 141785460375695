<template>
    <div>

		<el-form :model="queryParameters" class="employ" ref="queryParameters" label-width="120px">
			<el-row :gutter="24">
				<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">
					<el-form-item label="课程分类名称">
                        <el-input v-model="newCourseType" style="width: 180px" clearable >
                            <el-button :loading="addLoading" slot="append" @click="addCourseType">增加</el-button>
                        </el-input>
					</el-form-item>
				</el-col>
            </el-row>
        </el-form>
        <el-table :data="courseTypes"  style="width:100%"  v-loading="loading" stripe border>
			<el-table-column label="课程分类名称" align="center" prop="dictLabel" min-width="70%"/>
			<el-table-column label="操作" align="center" prop="remark" min-width="30%">
				<template slot-scope="scope">
					<el-button size="mini" type="text" icon="el-icon-edit" @click="updateCourseType(scope.row)" v-permission="['/employ/employ/edit']"> </el-button>
					<el-button size="mini" type="text" icon="el-icon-delete" @click="handleDelete(scope.row)" v-permission="['/employ/employ/remove']">
					</el-button>
				</template>
			</el-table-column>
		</el-table>

		<el-button style="margin-top: 10px;float:right;" type="primary" plain @click="cancel">关 闭</el-button>
        <el-dialog title="输入新的分类名称" :visible.sync='openInputDialog' :width="uniApp?'60%':'20%'" :modal="false" >
            <el-input v-model="updatedCourseType"></el-input>
            <el-button @click="handleUpdate" style="margin-top:15px;">更新</el-button>
    	</el-dialog>
    </div>
</template>

<script>
import permission from '@/directive/permission/index.js' // 权限判断指令
import { addSysdict ,updateSysdict,delSysdict} from '@/api/sys/sysdict';
import {Local} from '@/utils/storage.js'
export default {
    name: 'CourseType',
    directives: { permission },
    data() {

        return {
            newCourseType:"",
            courseTypes:this.initCorseTypes,
            addLoading:false,
            openInputDialog:false,
            updatedCourseType:"",
            selectRow:null,
            loading:false,
            uniApp:false,
        };
    },
    mounted() {
       
    },
    created() {
        this.uniApp=Local.get("isUniapp")?true:false;
		this.getList();
	},
    methods: {
        updateCourseType(row)
        {
            this.openInputDialog = true;
            this.selectRow = row;
            this.updatedCourseType = row.dictLabel;
        },
        handleUpdate(){
            let row = this.selectRow;
            let foundElement = null;
            this.courseTypes.forEach(element => {
                if(element.id==row.id){
                    foundElement = element;
                }
            });
            if(foundElement != null)
            {
                foundElement.dictLabel = this.updatedCourseType;
                 updateSysdict(foundElement).then(response => {
                  this.msgSuccess("修改成功");
                  this.openInputDialog = false;
                  this.getList();
                });
            }
            else    
            {
                this.msgError("找不到选择的课程分类，id:"+row.id);
            }
            
        },
        handleDelete(row){
            this.$confirm('是否确认删除课程分类"' + row.dictLabel + '"?', "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(function () {
                return delSysdict([row.id]);
            }).then(() => {
                this.getList();
                this.msgSuccess("删除成功");
            })

        },
        addCourseType(){
            let max = -1;
            this.courseTypes.forEach(element => {
                if(parseInt(element.dictValue)  > max){
                    max= parseInt(element.dictValue);
                }
            });
            max = max+1;
            var formData = {};
            formData.pid = 0;
            formData.dictSort = max;
            formData.dictLabel = this.newCourseType;
            formData.dictValue = max;
            formData.dictType = "trainingCourse_type";
            formData.cssClass = "";
            formData.listClass = "";
            formData.isDefault = "N";
            formData.status = "0";
            formData.createBy = this.$store.state.user.userInfo.username;
            formData.updateBy = "";
            formData.remark =  "";
            this.addLoading = true;
            addSysdict(formData).then(response => {
                this.msgSuccess("新增成功");
                this.getList();
                this.addLoading = false;
            }).catch((err) => {
							this.addLoading = false;
							this.msgError('添加机构失败，' + err);
						});;
        },
        cancel()
        {
            this.$emit('close', { refresh: false, isSuccess: true });
        },
        getList(){
               this.loading = true;
               this.getDicts('trainingCourse_type').then((response) => {
                    this.loading = false;
                    this.courseTypes = response.data;
			});
        }
    },
};
</script>

<style lang="scss" scoped>
 .el-dialog__header  {
    background-color: aliceblue;
  }
  /*body背景色*/
//   .el-dialog__body  {
//     background-color: #141d4d;
//   }

</style>