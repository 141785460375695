<template>
	<div>
	<el-tabs v-model="activeName" type="border-card">
    <el-tab-pane label="培训" name="first">
		<div>
			<span style="font-size:medium;margin-right:15px;">课程名称：{{this.courseData.courseName}}</span>
			<el-tag style="margin-bottom:15px;" type="success">{{completed?"完成":"未完成"}}</el-tag>
			<!-- <el-tag style="margin-bottom:15px;" v-else type="warning">未完成</el-tag> -->
		</div>
		<div v-if="pdf == true" v-loading="loading" element-loading-text="加载中">
			<!-- <iframe style="width: 100%; height: 100%" :src="documentUrl"></iframe> -->
			<Pdf ref="pdf" v-for="i in pdfTotal" :key="i" :src="documentUrl" :page="i" @page-loaded="handlePdfLoaded"> </Pdf>
		</div>

		<div v-else-if="doc == true">
			<!-- <iframe frameborder="0"
                    :src="'https://view.officeapps.live.com/op/view.aspx?src=' + this.documentUrl"
                    style="width: 100%; height: 100%;" >
            </iframe> -->
			<div ref="word" v-loading="loading" element-loading-text="加载中" style="min-height: 150px"></div>
		</div>
		<div v-else-if="text == true" v-loading="loading">
			<!-- <iframe frameborder="0" :src="documentUrl" style="width: 100%; height: 100%"> </iframe> -->
			<el-input type="textarea" :rows="30" placeholder="" v-model="txtPreview" style="min-height: 150px"> </el-input>
		</div>
		<div v-else-if="picture == true" v-loading="loading">
			<img :src="documentUrl" style="width: 100%; height: 100%; min-height: 150px" />
		</div>
	</el-tab-pane>
		<el-tab-pane label="测试" name="second">
			<div v-if="this.courseData.testPaperId">
        		<testing-paper :selectTestPaperId="this.courseData.testPaperId" :courseId="this.courseData.id" :courseName="this.courseData.courseName" :testCount="initTestCount" :testerId="this.currentUserId"  ></testing-paper>
    		</div>
			<div v-else>没有测试题，直接去评价。</div>
		</el-tab-pane>
		<el-tab-pane label="评价" name="third">
			<div>
				<course-evaluation :currentCourse="courseData" :courseCompleted="completed" @completeCourse="completeCourse"></course-evaluation>
			</div>
		</el-tab-pane>
	</el-tabs>
		
	</div>
</template>

<script>
import { getResourceUrl } from '@/api/training/trainingCourse';
import { downloadPersonalPhoto } from '@/api/employ/employ';
import { defaultOptions, renderAsync } from 'docx-preview';
import Pdf from 'vue-pdf';
import courseEvaluation from './courseEvaluation.vue';
import testingPaper from '../testPaper/testingPaper.vue';
import {addTraining,findTrainingRecord} from '@/api/training/myTraining';
import { findlastTest } from '@/api/training/trainingTestPaper';
export default {
	name: 'DocumentPlayer',
	components: {
		Pdf,
		courseEvaluation,
		testingPaper,
	},
	data() {
		return {
			documentUrl: '',
			pdf: false,
			doc: false,
			text: false,
			picture: false,
			courseData: this.$route.params.courseData,
			options: {
				className: 'docx', // 默认和文档样式类的类名/前缀
				inWrapper: true, // 启用围绕文档内容渲染包装器
				ignoreWidth: false, // 禁止页面渲染宽度
				ignoreHeight: false, // 禁止页面渲染高度
				ignoreFonts: false, // 禁止字体渲染
				breakPages: true, // 在分页符上启用分页
				ignoreLastRenderedPageBreak: true, //禁用lastRenderedPageBreak元素的分页
				experimental: false, //启用实验性功能（制表符停止计算）
				trimXmlDeclaration: true, //如果为真，xml声明将在解析之前从xml文档中删除
				debug: false, // 启用额外的日志记录
			},
			loading: true,
			pdfTotal: 1,
			txtPreview: '',
			completed:false,
			activeName:'first',
			initTestCount:-1,
			currentUserId:'',
		};
	},
	created() {
		this.currentUserId= this.$store.getters.userInfo.id;
		if (this.courseData.courseUrl != null && this.courseData.courseUrl.length > 0) {
			this.GetCourseStatus();
			let index = this.courseData.courseUrl.indexOf('.');
			let that = this;
			if (index > 0) {
				let extension = this.courseData.courseUrl.substr(index + 1);
				if (extension == 'pdf') {
					this.pdf = true;
					this.loading = true;
					getResourceUrl(this.courseData.courseUrl)
						.then((res) => {
							if (res) {
								let task = Pdf.createLoadingTask(res.data);
								this.documentUrl = task;
								this.handlePdfTask(task);
							} else {
								that.msgError(`后端下载文件出错 & ${res.msg}`);
							}
							that.loading = false;
							that.AddTraining();
						})
						.catch((e) => {
							that.loading = false;
							that.msgError(`后端下载文件出错 & ${e}`);
						});
				} else if (extension == 'doc' || extension == 'docx') {
					this.doc = true;
					this.loading = true;
					downloadPersonalPhoto(this.courseData.courseUrl)
						.then((res) => {
							if (res) {
								let resData = res.data;
								var blob = new Blob([resData], { type: 'application/octet-stream' });
								let blobUrl = URL.createObjectURL(blob);
								that.documentUrl = blobUrl;

								renderAsync(blob, that.$refs.word, null, that.options);
								that.loading = false;
								that.AddTraining();
							} else {
								that.loading = false;
								that.msgError(`后端下载文件出错 & ${e}`);
							}
						})
						.catch((e) => {
							this.loading = false;
							that.msgError(`后端下载文件出错 & ${e}`);
						});
				} else if (extension == 'txt') {
					this.text = true;
					this.loading = true;
					let self = this;
					downloadPersonalPhoto(this.courseData.courseUrl)
						.then((res) => {
							if (res) {
								let resData = res.data;
								var blob = new Blob([resData], { type: 'application/octet-stream' });
								const reader = new FileReader();
								reader.onload = function () {
									self.txtPreview = reader.result; //获取的数据data
									self.dialogvisibleview = true;
									console.log('reader.result', reader.result);
								};
								reader.readAsText(blob);
								self.AddTraining();
							}
							self.loading = false;
						})
						.catch((e) => {
							this.loading = false;
							that.msgError(`后端下载文件出错 & ${e}`);
						});
				} else if (
					extension == 'jpeg' ||
					extension == 'JPEG' ||
					extension == 'jpg' ||
					extension == 'JPG' ||
					extension == 'png' ||
					extension == 'git'
				) {
					this.picture = true;
					this.loading = true;
					getResourceUrl(this.courseData.courseUrl)
						.then((res) => {
							if (res) {
								this.documentUrl = res.data;
							} else {
								this.msgError(`后端下载文件出错 & ${res.msg}`);
							}
							this.loading = false;
							this.AddTraining();
						})
						.catch((e) => {
							this.loading = false;
							this.msgError(`后端下载文件出错 & ${e}`);
						});
				} else {
				}
			} else {
				this.msgError(` & ${this.courseData.courseUrl}格式错误，没有扩展名。`);
			}
		} else {
			this.msgError('文件URL为空。');
		}
	},
	mounted() {},

	methods: {
		handlePdfTask(task) {
			task.promise
				.then((Pdf) => {
					this.pdfTotal = Pdf.numPages;
				})
				.catch((err) => {
					this.msgError('pdf 加载失败'+err);
				});
		},
		handlePdfLoaded(e) {
			if (e === this.pdfTotal) {
				this.$nextTick(() => {
					setTimeout(() => {
						this.loading = false;
					}, 500);
				});
			}
		},
		AddTraining()
		{
			let myTraining={};
			myTraining.userId =this.$store.getters.userInfo.id;
			myTraining.employeeName=this.$store.getters.userInfo.username;
			myTraining.trainingName = this.courseData.courseName;
			myTraining.trainingType = "在线学习";
			myTraining.trainingId= this.courseData.id;
			myTraining.completed=false;
			addTraining(myTraining)
			.then((response) => {
				console.log('记录培训成功。');
			})
			.catch((err) => {
				console.log('记录培训失败，');
			});
		},
		findlastTestCount(){
			if(this.courseData.testPaperId && this.completed){
				let testPaperId = this.courseData.testPaperId;
				let userId = this.$store.getters.userInfo.id;
				let courseId = this.courseData.id;
				findlastTest(testPaperId,courseId,userId)
				.then((response) => {
					if(response.data)
					{
						this.initTestCount = response.data;
						this.$forceUpdate();
					}
				})
				.catch((err) => {
					this.msgError(err);
				});
			}
		},
		GetCourseStatus()
		{
			let myTraining={};
			myTraining.userId =this.$store.getters.userInfo.id;
			myTraining.courseId= this.courseData.id;
			findTrainingRecord(myTraining)
			.then((response) => {
				if(response.data)
				{
					this.completed=response.data.completed;
				}
				else
				{
					this.completed=false;
				}
				this.findlastTestCount();
				console.log('获取记录培训成功。');
			})
			.catch((err) => {
				console.log('获取记录培训失败，');
			});
		},
		completeCourse(){
			this.completed=true;
		}
	},
};
</script>

<style lang="scss" scoped>
.employ .flagClass .el-loading-spinner {
	position: relative;
	top: 350px;
}
</style>