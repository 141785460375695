import request from '@/utils/request'

// 
// export function getSelf() {
//     return request({
//         url: '/employ/self/getSelf',
//         method: 'post'
//     })
// }

// 上传员工合同的图片
export function uploadContract(data) {
    return request({
        url: '/employ/ContractDocument/uploadContractFiles',
        method: 'post',
        data: data
    })
}

// 上传员工合同的图片
export function uploadBatchContracts(data) {
    return request({
        url: '/employ/ContractDocument/uploadBatchContracts',
        method: 'post',
        data: data
    })
}



export function updateContract(data) {
    return request({
        url: '/employ/ContractDocument/updateContract',
        method: 'post',
        data: data
    })
}


export function downloadFileByPath(filePath) {
    return request({
        url: '/employ/ContractDocument/downloadFileByPath?filePath='+filePath,
        method: 'get',
        responseType: 'blob'
    })
}


// 下载合同
export function download(employeeId,contractId) {
    return request({
        url: '/employ/ContractDocument/download?employeeId='+employeeId+'&contractId='+contractId,
        method: 'get',
        responseType: 'blob'
        // data: data
    })
}

// Get contract list
export function GetContractList(id) {
    return request({
        url: '/employ/ContractDocument/GetContractList?employId='+id,
        method: 'post'
    })
}

// Get contract list
export function generateContractNo(id,encoding,contractType) {
    return request({
        url: '/employ/ContractDocument/GenerateContractNo?employeeId='+id+'&encoding='+encoding+'&contractType='+contractType,
        method: 'post'
    })
}

export function deleteContracts(data) {
    return request({
        url: '/employ/ContractDocument/deleteContracts',
        method: 'post',
        data: data
    })
}

