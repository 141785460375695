<template>
	<div>
		<el-tabs v-model="activeName" type="border-card">
			<el-tab-pane label="培训" name="first">
				<div>
					<span style="font-size: medium; margin-right: 15px">课程名称：{{ this.courseData.courseName }}</span>
					<el-tag style="margin-bottom: 15px" type="danger">{{ completed ? '完成' : '未完成' }} </el-tag>
					<el-tag style="margin-bottom: 15px; margin-left: 15px; color: red">注意：看完评价并提交以示完成！</el-tag>
					<!-- <el-tag style="margin-bottom: 15px" v-else type="warning">未完成</el-tag> -->
				</div>
				<template>
					<div class="demo">
						<video-player
							class="video-player vjs-custom-skin"
							ref="videoPlayer"
							:playsinline="true"
							:options="playerOptions"
							@play="onPlayerPlay($event)"
							@pause="onPlayerPause($event)"
							@ended="onPlayerEnded($event)"
							@waiting="onPlayerWaiting($event)"
							@timeupdate="onPlayerTimeupdate($event)"
							@statechanged="playerStateChanged($event)"
							@canplaythrough="onPlayerCanplaythrough($event)"
							@ready="playerReadied"
						>
						</video-player>
					</div>
				</template>
			</el-tab-pane>
			<el-tab-pane label="测试" name="second">
				<div v-if="this.courseData.testPaperId">
        			<testing-paper :selectTestPaperId="this.courseData.testPaperId" :courseId="this.courseData.id" :courseName="this.courseData.courseName" :testCount="initTestCount" :testerId="this.currentUserId" ></testing-paper>
    			</div>
				<div v-else>没有测试题，直接去评价。</div>
			</el-tab-pane>
			<el-tab-pane label="评价" name="third">
				<div>
					<course-evaluation :currentCourse="courseData" :courseCompleted="completed" @completeCourse="completeCourse"> </course-evaluation>
				</div>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
import { videoPlayer } from 'vue-video-player';
import 'video.js/dist/video-js.css';
import { getResourceUrl } from '@/api/training/trainingCourse';
import { addTraining, findTrainingRecord } from '@/api/training/myTraining';
import courseEvaluation from './courseEvaluation.vue';
import testingPaper from '../testPaper/testingPaper.vue';
import { findlastTest } from '@/api/training/trainingTestPaper';

export default {
	name: 'EhrWebVue2TrainingPlan',
	components: {
		videoPlayer,
		courseEvaluation,
		testingPaper,
	},
	data() {
		return {
			activeName: 'first',
			courseData: null,
			courseUrl: '',
			completed: false,
			playerOptions: {
				playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
				autoplay: true, // 如果为true,浏览器准备好时开始回放。
				muted: false, // 默认情况下将会消除任何音频。
				loop: false, // 是否视频一结束就重新开始。
				preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
				language: 'zh-CN',
				aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
				fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
				sources: [
					{
						type: 'video/mp4', // 类型
						src: '', //require("./11.mp4") // url地址
					},
				],
				poster: '/static/training.jpg', // 封面地址
				notSupportedMessage: '正在载入视频。。。。。。', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
				controlBar: {
					timeDivider: true, // 当前时间和持续时间的分隔符
					durationDisplay: true, // 显示持续时间
					remainingTimeDisplay: false, // 是否显示剩余时间功能
					fullscreenToggle: true, // 是否显示全屏按钮
				},
			},
			initTestCount:-1,
			currentUserId:'',
		};
	},

	mounted() {},

	created() {
		this.currentUserId= this.$store.getters.userInfo.id;
		this.courseData = this.$route.params.courseData;
		if (this.courseData != null && this.courseData.courseUrl != null) {
			
			//    downloadPersonalPhoto(this.courseData.courseUrl)
			//     .then((res) => {
			//         if (res) {
			//             let resData = res.data;
			//             var blob = new Blob([resData], { type: 'application/octet-stream' });
			//             let blobUrl = URL.createObjectURL(blob);
			//             this.playerOptions['sources'][0]['src'] = blobUrl;
			//         } else {
			//             this.msgError(`后端下载文件出错 & ${e}`);
			//         }
			//     })
			//     .catch((e) => {
			//         //this.loading = false;
			//         this.msgError(`后端下载文件出错 & ${e}`);
			//     });

			if (this.courseData.courseUrl != null && this.courseData.courseUrl.indexOf('/') >= 0) {
				this.GetCourseStatus();
				let that = this;
				let fileType = '';
				let index = this.courseData.courseUrl.indexOf('.');
				let extension = this.courseData.courseUrl.substring(index + 1);
				if (extension == 'avi') {
					fileType = 'video/avi';
				} else if (extension == 'mpg') {
					fileType = 'video/mpg';
				} else if (extension == 'wav') {
					fileType = 'video/wav';
				} else if (extension == 'mp4') {
					fileType = 'video/mp4';
				} else {
					fileType = 'video/mp4';
				}

				getResourceUrl(this.courseData.courseUrl)
					.then((res) => {
						if (res) {
							this.playerOptions['sources'][0]['type'] = fileType;
							this.playerOptions['sources'][0]['src'] = res.data;
							this.$refs.videoPlayer.player.play();
						} else {
							this.msgError(`后端下载文件出错 & ${res.msg}`);
						}
					})
					.catch((e) => {
						//this.loading = false;
						this.msgError(`后端下载文件出错 & ${e}`);
					});
			} else {
				this.msgError(`错误的文件路径格式 & ${this.courseData.courseUr}`);
			}
		}
	},
	// beforeRouteLeave(){
	//     this.$refs.videoPlayer.player.pause(), remove, 会引起视频关不掉，页面死。
	// },
	methods: {
		// 播放回调
		onPlayerPlay(player) {
			let myTraining = {};
			myTraining.userId = this.$store.getters.userInfo.id;
			myTraining.employeeName = this.$store.getters.userInfo.name;
			myTraining.trainingName = this.courseData.courseName;
			myTraining.trainingId = this.courseData.id;
			myTraining.completed = false;
			myTraining.trainingType = "在线学习";
			addTraining(myTraining)
				.then((response) => {
					console.log('记录培训成功。');
				})
				.catch((err) => {
					console.log('记录培训失败，');
				});
		},

		// 暂停回调
		onPlayerPause(player) {
			console.log('player pause!', player);
			document.querySelector('.vjs-progress-control').style.pointerEvents = 'none';
		},

		// 视频播完回调
		onPlayerEnded(player) {
			console.log(player);
		},
		// DOM元素上的readyState更改导致播放停止
		onPlayerWaiting(player) {
			let time = localStorage.getItem('cacheTime');
			if (player.cache_.currentTime - Number(time) > 0.1) {
				this.current = Number(time);
				this.playerReadied(player);
			} else {
				this.current = player.cache_.currentTime;
			}
		},

		// 已开始播放回调
		onPlayerPlaying($event) {
			// console.log(player)
		},

		// 当播放器在当前播放位置下载数据时触发
		onPlayerLoadeddata($event) {
			// console.log(player)
		},

		// // 当前播放位置发生变化时触发。
		onPlayerTimeupdate(player) {
			this.playTime = player.cache_.currentTime;
			let playTime = player.cache_.currentTime;
			setTimeout(function () {
				localStorage.setItem('cacheTime', playTime);
			}, 500);

			let time = localStorage.getItem('cacheTime');
			if (player.cache_.currentTime - Number(time) > 2) {
				this.current = Number(time);
				this.playerReadied(player);
			} else {
				this.current = player.cache_.currentTime;
			}
		},

		//媒体的readyState为HAVE_FUTURE_DATA或更高
		onPlayerCanplay(player) {
			// console.log('player Canplay!', player)
		},

		//媒体的readyState为HAVE_ENOUGH_DATA或更高。这意味着可以在不缓冲的情况下播放整个媒体文件。
		onPlayerCanplaythrough(player) {
			// console.log('player Canplaythrough!', player)
		},

		//播放状态改变回调
		playerStateChanged(playerCurrentState) {
			// console.log('player current update state', playerCurrentState)
		},

		//将侦听器绑定到组件的就绪状态。与事件监听器的不同之处在于，如果ready事件已经发生，它将立即触发该函数。。
		playerReadied(player) {
			// console.log('example player 1 readied', player);
			player.currentTime(this.current);
		},
		GetCourseStatus() {
			let myTraining = {};
			myTraining.userId = this.$store.getters.userInfo.id;
			myTraining.courseId = this.courseData.id;
			findTrainingRecord(myTraining)
				.then((response) => {
					if (response.data) {
						this.completed = response.data.completed;
					} else {
						this.completed = false;
					}
					this.findlastTestCount();
					console.log('获取记录培训成功。');
				})
				.catch((err) => {
					console.log('获取记录培训失败，');
				});
		},
		completeCourse() {
			this.completed = true;
		},

		findlastTestCount(){
			if(this.courseData.testPaperId && this.completed){
				let testPaperId = this.courseData.testPaperId;
				let userId = this.$store.getters.userInfo.id;
				let courseId = this.courseData.id;
				findlastTest(testPaperId,courseId,userId)
				.then((response) => {
					if(response.data)
					{
						this.initTestCount = response.data;
						this.$forceUpdate();
					}
				})
				.catch((err) => {
					this.msgError(err);
				});
			}
		},
	},
};
// type："video/webm"   // 可以播放，用ogg也可打开
// type："video/ogg"    // 可以播放，用webm也可打开
// type："video/3gp"    // 可以播放
// type："video/mp4"    // 可以播放
// type："video/avi"    // 打不开
// type："video/flv"    // 打不开
// type："video/mkv"    // 打不开
// type："video/mov"    // 打不开
// type："video/mpg"    // 打不开
// type："video/swf"    // 打不开
// type："video/ts"     // 打不开
// type："video/wmv"    // 打不开
// type："video/vob"    // 没转化
// type："video/mxf"    // 转化出错
// type: "video/rm"     // 转化出错复制代码
// this.$refs.videoPlayer.player.play() // 播放
// this.$refs.videoPlayer.player.pause() // 暂停
// this.$refs.videoPlayer.player.src(src) // 重置进度条复制代码
</script>

<style lang="scss" scoped>
.vjs-custom-skin > .video-js .vjs-big-play-button {
	background-color: rgba(0, 0, 0, 0.45);
	font-size: 3em;
	/* border-radius: 50%; */
	height: 1.5em !important;
	line-height: 1.5em !important;
	margin-top: -1em !important;
}
/*这里用了第三方vue-video-player插件，但这个插件有bug，设置globalSetting:{controls:true}隐藏进度条不生效，故可设置插件样式进行隐藏vjs-progress-contro*/
.vjs-progress-control {
	/*visibility:hidden;       // 隐藏进度条*/
}
</style>