<template>
  <div>
    <el-row>
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">
        <div style="text-align: center; font-width: bolder; font-size: larger;">员工：{{form.empName}}
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col v-if="photoUrl.url" :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">
        <div style="text-align: center; font-width: bolder; font-size: larger;">
          <el-image style="width: 100px; height: 100px; align-content: center;" :src="photoUrl.url" :preview-src-list="srcList">
          </el-image>
        </div>
      </el-col>
    </el-row>
<!--    <el-row>-->
<!--      <el-col v-for="photo in photoUrl" :key="photo.url" :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">-->
<!--        <el-image style="width: 100px; height: 100px; align-content: center;" :src="photo.url" :preview-src-list="srcList">-->
<!--        </el-image>-->
<!--      </el-col>-->
<!--    </el-row>-->
<!--    <el-row>-->
<!--      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">-->

<!--      </el-col>-->
<!--    </el-row>-->
    <div style="text-align: center">
      <el-button @click="cancel">关 闭</el-button>
    </div>
  </div>
</template>

<script>
import {getPreviewFileUrl} from "@/api/comapi/comapi";
import { showLoading, hideLoading } from '@/utils/reqloading';
import {
  listUserFace,
  getUserFace,
  delUserFace,
  approvedById,
  disapprovedById,
  exportUserFace } from "@/api/sys/userFace";

export default {
  name: "edit",
  props:{
    row: Object,
  },
  computed: {
    srcList(){
      let urlList = [];
      urlList.push(this.photoUrl.url);
      console.log("srcList")
      console.log(urlList)
      return urlList;
    }
  },
  watch: {
    row(){
      console.log("row changed")
      this.load();
    },
  },
  data() {
    return {
      form:{},
      photoUrl: {},
    }
  },
  created() {
    this.load();
  },
  methods:{
    load(){
      this.form = this.row;
      this.photoUrl = {};
      console.log(this.form);
      this.getPhotoPreviewUrl();
    },
    getPhotoPreviewUrl(){
      console.log("watch form.photoAddr before:")
      if(this.form.faceImgUrl && this.form.faceImgUrl.length > 0){
        getPreviewFileUrl(this.form.faceImgUrl).then(resp=>{
          this.photoUrl = {url:resp.data, realUrl:this.form.faceImgUrl};
        });
      }

    },

    cancel(){
      this.$emit("close", {refresh:false,isSuccess:true});
    },
  }
}
</script>

<style scoped>

</style>
