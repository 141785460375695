<template>
  <div>
    <el-form ref="form" :model="form" :rules="rules" label-width="118px">
      <el-row :gutter="35">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" >
          <el-form-item label="名称说明" prop="name">
            <el-input :disabled="true" v-model="form.name" placeholder="请输入名称说明"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="35">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" >
          <el-form-item label="选择巡逻员" prop="empId" :rules="{ required: true, message: '必须选择员工', trigger: ['blur', 'change'] }">
            <el-input :disabled="true" v-model="form.empName" placeholder="请输入巡逻员"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-card class="box-card">
        <div v-for="item in form.patrolItems" :key="form.patrolItems.indexOf(item)" class="text item">
<!--          <div style="padding: 3px; text-align: center;">-->
<!--            <span style="padding-left: 5px; font-weight: bolder;">巡逻点{{form.patrolItems.indexOf(item)+1}}</span>-->
<!--          </div>-->
          <el-divider>巡逻点{{form.patrolItems.indexOf(item)+1}}</el-divider>
          <div>
            <div v-if="item.fixAdd" style="display: inline-flex;">
              <el-form-item label="指定地点：" prop="fixAdd">
                  {{item.addr.addr}}，有效范围{{item.addr.range}}（米）
              </el-form-item>
            </div>
            <div v-if="item.fixTime" style="display: inline-flex">
              <el-form-item label="指定时间范围：" prop="fixTime">
                  <span>{{item.startTime}}-{{item.endTime}}</span>
              </el-form-item>
            </div>
            <div v-if="item.photoUrl">
              <div>
                <el-form-item label="审核状态：" prop="photoApproved">
                  <el-radio-group v-model="item.photoApproved">
                    <el-radio :label="1">通过</el-radio>
                    <el-radio :label="3">不通过</el-radio>
                  </el-radio-group>
                </el-form-item>
              </div>
              <div v-if="item.photoApproved == 3">
                <el-form-item label="审核不通过原因：" prop="reason">
                  <el-input v-model="item.reason" placeholder="请输入审核不通过原因"/>
                </el-form-item>
              </div>
              <Button class="uni-button" v-if="item.showPhoto" @click="hiddenPhoto(item)">隐藏照片</Button>
              <Button class="uni-button" v-if="!item.showPhoto" @click="showPhoto(item)">查看照片</Button>
              <el-image v-if="item.showPhoto" fit="scale-down" :src="item.showPhotoUrl" style="height:360px;"></el-image>
            </div>
            <div v-else style="padding: 3px; text-align: center; font-weight: bolder;color: red">
              <span>未上传照片</span>
            </div>
          </div>
        </div>
      </el-card>
    </el-form>
    <div slot="footer" class="dialog-footer" style="text-align: right">
      <el-button v-if="!isReadOnly" type="primary" @click="submitForm" v-permission="['/attendance/attPatrolEmp/add', '/attendance/attPatrolEmp/edit']">确 定</el-button>
      <el-button @click="cancel">取 消</el-button>
    </div>

  </div>
</template>

<script>
import permission from '@/directive/permission/index.js'; // 权限判断指令
import {listSimpleEmploy} from '@/api/employ/employ';
import {
  listAttPatrolEmp,
  getAttPatrolEmp,
  delAttPatrolEmp,
  addAttPatrolEmp,
  approvePhoto,
  exportAttPatrolEmp } from "@/api/attendance/attPatrolEmp";
import {getPreviewFileUrl} from '@/api/comapi/comapi';

export default {
  name: "appprovePhoto",
  props: {
    row: Object,
    isReadOnly: Boolean,
  },
  directives: { permission },
  watch:{
    row(n,o){ //n为新值,o为旧值;
      //if(n === o) return;
      this.load();
    },
    "form.empId"(val){
      console.log("form.empId="+val)
      this.changeEmpId(val);
    },

  },
  data() {
    return {
      // 遮罩层
      loading: true,

      // 表单参数
      form: {
        empId: null,
        patrolItems: [],
        times: 0,
      },

      makerPosition: [114.027868, 22.60775],
      addr: '',
      currentPatrol: null,

      // 表单校验
      rules: {
        name: [
          {
            required: true,
            message: "名称说明不能为空", trigger: "blur" }
        ],
        times: [
          {
            required: true,
            message: "巡逻打卡次数不能为空", trigger: "blur" }
        ],
      }
    };
  },
  mounted() {
    this.load();
  },
  methods:{

    load() {
      this.reset();
      if(this.row && this.row.id){
        this.handleUpdate(this.row);
      }else{
        this.handleAdd();
      }
    },

    // 取消按钮
    cancel() {
      // this.open = false;
      // this.reset();
      this.$emit("close", {refresh:false,isSuccess:true});
    },
    // 表单重置
    reset() {
      this.form = {times:0,patrolItems:[]};
      this.resetForm("form");
    },

    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.form = {times:0,patrolItems:[]}

    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.id || this.ids
      getAttPatrolEmp(id).then(response => {
        this.form = response.data;
      });
    },
    /** 提交按钮 */
    submitForm() {
      this.form.companyId = this.$store.getters.userInfo.companyId;
      console.log(this.form)
      this.$refs["form"].validate(valid => {
            if(valid) {
              if (this.form.id != null) {
                approvePhoto(this.form).then(response => {
                  this.msgSuccess("修改成功");
                  this.$emit("close", {refresh:true,isSuccess:true});
                  this.reset();
                });
              } else {
                console.log("");
              }
            }
          }
      );
    },
    showPhoto(item){
      this.$set(item,"showPhoto",true);
      if(item.showPhotoUrl){
        console.log(item.showPhotoUrl)
      }else{
        getPreviewFileUrl(item.photoUrl).then(resp=> {
          console.log("*******  getPreviewFileUrl resp=")
          console.log(resp.data)
          this.$set(item,"showPhotoUrl",resp.data);
        });
      }
    },
    hiddenPhoto(item){
      this.$set(item,"showPhoto",false);
    },

  },
}
</script>

<style scoped>
.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both
}


</style>
