<template>
  <div>
    <el-tabs v-model="activeName">
      <el-tab-pane label="人脸审核" name="photoApprove">
        <PhotoApprove></PhotoApprove>
      </el-tab-pane>
      <el-tab-pane label="认证记录" name="recognizeList">
        <RecognizeList></RecognizeList>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import PhotoApprove from "./photoApprove";
import RecognizeList from "./recognizeList"
export default {
  name: "index",
  components: {PhotoApprove,RecognizeList},
  data() {
    return {
      activeName: 'photoApprove',
    }
  },
}
</script>

<style scoped>

</style>
