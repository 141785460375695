<template>
  <div class="container">
    <el-form>
			<el-row :gutter="24" style="margin-left:0px;margin-right:0px;">
          <el-col :xs="24">
             <img src="/static/brandLogo.jpg">
          </el-col>
      </el-row>
      <el-row :gutter="24" style="margin-left:0px;margin-right:0px;">
        <el-col :xs="24">
             <p class="company-text">诚云企App主要用于实现对员工的智能化数字管理，全流程协助人事工作，提升企业整体人员运行管理效率，为企业提供优质完善的资源解决方案。</p>
        </el-col>
      </el-row>
      <el-row :gutter="24" style="margin-left:0px;margin-right:0px;">
        <el-col :xs="24">
          <el-link class="download-button" href="/static/JtEhr.apk" type="info" @click.native="download" >App下载</el-link>
        </el-col>
      </el-row>
    </el-form>
    <div :class="clickDownloadLink()">
       <div class="mobileDownloadTip">
        <img style="width:100px;height:100px;" src="/static/downloadTip.png">
       </div>
    </div>
    
  </div>
</template>

<script>
export default {
  name: 'AppDownload',

  data() {
    return {
        downloadTip:false,
    };
  },

  mounted() {
    
  },
  computed: {
   clickDownloadLink(){
    return function(){
       if(this.downloadTip){
          return {displayTips:true}; 
       }
       else{
           return {hideTips:true}; 
       }
    }
   }
  },
  methods: {
    download(){
      let ua = window.navigator.userAgent.toLowerCase();
        if(ua.match(/MicroMessenger/i) == 'micromessenger'){
            this.downloadTip=true;
            return false
        } 
        else{
          return true;
        }
    }
  },
};
</script>
<style lang="scss" scoped>
    .mobileDownloadTip{
        position: absolute;
        top: 0px;
        right: 0px;
        width:120px;
        height:100px;
    }
    .container{
      min-height: 100%;
      width: 100%;
      background-color: rgba(64, 158, 255, 1.0);
      height: 100%;
      padding-top: 100px;
      text-align: center;
      overflow: hidden;
       position: relative;
    }
    .company-text{
      text-align: justify;
      padding: 10px 10%;
      color: white;
    }
    .download-button {
    border-color: white;
    padding-left: 20px;
    padding-right: 20px;
    border-style: solid;
    border-width: thin;
    color: white;
}

  .displayTips{
    display: block;
  }
  .hideTips{
    display: none;
  }
</style>