<template>
	<el-form :model="testPaper" label-width="80px" label-position="right" :inline="true" ref="form">
		<el-row :gutter="24">
			<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb10" style="text-align:center;">
				<span>{{ this.testPaper.companyName }}-{{ this.testPaper.paperName }}(总分{{ this.testPaper.totalScore }}分)</span>
			</el-col>
		</el-row>
		<el-row :gutter="24" v-if="this.testPaper.singleSelections&&this.testPaper.singleSelections.length>0">
			<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb10">
				<span class="tag-group__title" style="font-weight: 600;padding-left:40px;">
					{{this.sequence[this.questionTypes.indexOf('S')]}}单项选择题 共{{ this.singleTotal }}题，每题{{ this.singleScore }}分</span>
			</el-col>
			<el-col v-for="singleSelection in testPaper.singleSelections" :key="singleSelection.seq"
				:xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb5">
				
				<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item :label="singleSelection.seq + '.  '" class="mb5"><span>{{ singleSelection.question }}</span></el-form-item>
				</el-col>
				<el-radio-group v-model="singleSelection.result" :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="display:block !important;">
					<el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" v-if="singleSelection.selectionA">
						<el-radio label="A" class="mb5 paddingLeft"><span>A.</span><div class="optionDiv"> {{ singleSelection.selectionA }}</div></el-radio>
					</el-col>
					<el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" v-if="singleSelection.selectionB">
						<el-radio label="B" class="mb5 paddingLeft"><span>B.</span><div class="optionDiv"> {{ singleSelection.selectionB }}</div></el-radio>
					</el-col>
					<el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" v-if="singleSelection.selectionC ">
						<el-radio label="C" class="mb5 paddingLeft"><span>C.</span><div class="optionDiv"> {{ singleSelection.selectionC }}</div></el-radio>
					</el-col>
					<el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" v-if="singleSelection.selectionD ">
						<el-radio label="D" class="mb5 paddingLeft"><span>D.</span><div class="optionDiv"> {{ singleSelection.selectionD }}</div></el-radio>
					</el-col>
					<el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" v-if="singleSelection.selectionE">
						<el-radio label="E" class="mb5 paddingLeft"><span>E.</span><div class="optionDiv"> {{ singleSelection.selectionE }}</div></el-radio>
					</el-col>
				</el-radio-group>
				<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-if="testCount>0">
					<el-form-item label="答案" class="mb5 answerpaddingLeft">
						<el-input v-model="singleSelection.answer" class="answerWidth"  readonly></el-input>
					</el-form-item>
					<el-form-item label="分值" class="mb5 answerpaddingLeft">
						<el-input v-model="singleSelection.score" class="answerWidth"  readonly></el-input>
					</el-form-item>
					<el-form-item label="得分" class="mb5 answerpaddingLeft">
						<el-input v-model="singleSelection.gotScore" class="answerWidth"  readonly></el-input>
					</el-form-item>
				</el-col>
			</el-col>
		</el-row>
		<el-row :gutter="24" v-if="this.testPaper.multipleSelections && this.testPaper.multipleSelections.length>0">
			<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb10">
				<span class="tag-group__title" style="font-weight: 600;padding-left:40px;">
					{{this.sequence[this.questionTypes.indexOf('M')]}}多项选择题 共{{ this.multipleTotal }}题，每题{{ this.multipleScore }}分</span>
			</el-col>
			<el-col v-for="multipleSelection in testPaper.multipleSelections" :key="multipleSelection.seq"
				:xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb5">
				<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
					<el-form-item :label="multipleSelection.seq + '.  '" class="mb5"><span>{{multipleSelection.question}}</span></el-form-item>
				</el-col>
				<el-checkbox-group v-model="multipleSelection.result" >
					<el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" v-if="multipleSelection.selectionA ">
						<el-checkbox label="A" key="A" class="mb5 paddingLeft"><span>A.</span><div class="optionDiv"> {{ multipleSelection.selectionA }}</div></el-checkbox>
					</el-col>
					<el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" v-if="multipleSelection.selectionB">
						<el-checkbox label="B" key="B"  class="mb5 paddingLeft"><span>B.</span><div class="optionDiv"> {{ multipleSelection.selectionB }}</div></el-checkbox>
					</el-col>
					<el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" v-if="multipleSelection.selectionC ">
						<el-checkbox label="C" key="C"   class="mb5 paddingLeft"><span>C.</span><div class="optionDiv"> {{ multipleSelection.selectionC }}</div></el-checkbox>
					</el-col>
					<el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" v-if="multipleSelection.selectionD ">
						<el-checkbox label="D" key="D"   class="mb5 paddingLeft"><span>D.</span><div class="optionDiv"> {{ multipleSelection.selectionD }}</div></el-checkbox>
					</el-col>
					<el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" v-if="multipleSelection.selectionE">
						<el-checkbox label="E" key="E"  class="mb5 paddingLeft"><span>E.</span><div class="optionDiv"> {{ multipleSelection.selectionE }}</div></el-checkbox>
					</el-col>
				</el-checkbox-group>
				<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-if="testCount>0">
					<el-form-item label="答案" class="mb5 answerpaddingLeft">
						<el-input v-model="multipleSelection.answer" class="answerWidth"  readonly ></el-input>
					</el-form-item>
					<el-form-item label="分值" class="mb5 answerpaddingLeft">
						<el-input v-model="multipleSelection.score" class="answerWidth"  readonly ></el-input>
					</el-form-item>
					<el-form-item label="得分" class="mb5 answerpaddingLeft">
						<el-input v-model="multipleSelection.gotScore" class="answerWidth"  readonly ></el-input>
					</el-form-item>
				</el-col>
			</el-col>
		</el-row>
		<el-row :gutter="24" v-if="this.testPaper.trueOrFalses && this.testPaper.trueOrFalses.length>0">
			<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb10">
				<span class="tag-group__title" style="font-weight: 600; padding-left:40px;">
					{{this.sequence[this.questionTypes.indexOf('J')]}}判断题 共题{{ this.judgeTotal }}，每题{{ this.judgeScore }}分</span>
				
			</el-col>
			<el-col v-for="trueOrFalse in testPaper.trueOrFalses" :key="trueOrFalse.seq" :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb5">
				<el-col :xs="24" :sm="20" :md="20" :lg="20" :xl="20">
					<el-form-item :label="trueOrFalse.seq + '.  '" class="mb5"><span>{{ trueOrFalse.question }}</span></el-form-item>
                </el-col> 
				<el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" >
					<el-radio-group v-model="trueOrFalse.result">
						<el-radio class="paddingLeft" label="对">对</el-radio>
						<el-radio label="错">错</el-radio>
					</el-radio-group>
				</el-col>
				<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-if="testCount>0">
					<el-form-item label="答案" class="mb5 answerpaddingLeft">
						<el-input v-model="trueOrFalse.answer" class="answerWidth" readonly ></el-input>
					</el-form-item>
					<el-form-item label="分值" class="mb5 answerpaddingLeft">
						<el-input v-model="trueOrFalse.score" class="answerWidth" readonly ></el-input>
					</el-form-item>
					<el-form-item label="得分" class="mb5 answerpaddingLeft">
						<el-input v-model="trueOrFalse.gotScore" class="answerWidth" readonly ></el-input>
					</el-form-item>
			    </el-col> 
			</el-col>
			
		</el-row>
		<el-row :gutter="24" v-if="this.testPaper.essayQuestions && this.testPaper.essayQuestions.length>0">
			<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb10">
				<span class="tag-group__title" style="font-weight: 600; padding-left:40px;">
					{{this.sequence[this.questionTypes.indexOf('E')]}}问答题 共{{ this.essayQuestionTotal }}题，每题{{ this.essayQuestionScore }}分</span>
				
			</el-col>
			<el-col v-for="essayQuestion in testPaper.essayQuestions" :key="essayQuestion.seq" :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb5">
				 <el-col :xs="24" :sm="20" :md="20" :lg="20" :xl="20">
					<el-form-item :label="essayQuestion.seq + '.  '" class="mb5"><span>{{ essayQuestion.question }}</span></el-form-item>
                </el-col> 
				<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb5">
					<el-input class="paddingLeft" v-model="essayQuestion.result" clearable maxlength="1000" rows="5" type="textarea" ></el-input>
				</el-col>
				<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-if="testCount>0">
					<el-form-item label="答案" class="mb5 answerpaddingLeft">
						<el-input  v-model="essayQuestion.answer"  readonly maxlength="1000" rows="5" type="textarea" ></el-input>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-if="testCount>0">
					<el-form-item label="分值" class="mb5 answerpaddingLeft">
						<el-input  v-model="essayQuestion.score"  class="answerWidth"  readonly ></el-input>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-if="testCount>0">
					<el-form-item label="得分" class="mb5 answerpaddingLeft">
						<el-input  v-model="essayQuestion.gotScore"  class="answerWidth"  readonly ></el-input>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" v-if="testCount>0">
					<el-form-item label="总得分" class="mb5 answerpaddingLeft">
						<span style="color:red;"  class="answerWidth"  >{{testPaper.totalGetScore}}</span>
					</el-form-item>
				</el-col>  
			</el-col>
		</el-row>
		<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">
			<el-form-item label=" ">
				<el-button plain type="success" v-if="testCount==-1" @click="submit" :loading="submitLoading" :disabled="submitDisabled" >提交试卷</el-button>
				<el-button plain type="info" v-if="testCount>-1" @click="close">关闭</el-button>
			</el-form-item>
		</el-col>
	</el-form>
</template>

<script>
import { findTestPaper,findOneWithAnswer } from '@/api/training/trainingTestPaper';
import { addTestedPaperResults } from '@/api/training/trainingTestResults';
import { similar } from '@/utils/similarAlgorithm.js'
export default {
	name: 'TestPaper',

	data() {
		return {
			testPaper: {
				paperName: '',
				totalScore: 100,
				passScore: 60,
				goodScore: 70,
				excellentScore: 90,
				singleSelections: [],
				multipleSelections: [],
				trueOrFalses: [],
				essayQuestions:[]
			},
			singleSelectionCount: 0,
			singleTotal: 1,
			singleScore: 2,
			multipleSelectionCount: 0,
			multipleTotal: 1,
			multipleScore: 2,
			trueOrFalseCount: 0,
			judgeTotal: 1,
			judgeScore: 2,
			essayQuestionTotal:1,
			essayQuestionScore:10,
			essayQuestionCount:0,
			testPaperId: 0,
			submitLoading: false,
			submitDisabled:false,

			total: 0,
			loading: false,
			sequence:['一、','二、','三、','四、','五、'],
			questionTypes:[],
		};
	},
	props: {
		selectTestPaperId: Number,
		testCount:Number,
		courseId:Number,
		courseName:String,
		testerId:String,
	},
	mounted() {
		
	},
	created() {
		this.load();

	},
	
	watch: {
		testCount(newValue,oldValue) {
			if(newValue != oldValue){
				this.load();
			}
		},
	},
	
	methods: {
		load() {
			let that = this;
			if(this.testCount > 0){
				findOneWithAnswer(this.selectTestPaperId,this.testCount,this.courseId,this.testerId)
				.then((response) => {
					if (response.data) {
						that.testPaper.paperName = response.data.testPaperName;
						that.testPaper.companyName = response.data.companyName;
						that.testPaper.passScore = response.data.passScore;
						that.testPaper.goodScore = response.data.goodScore;
						that.testPaper.excellentScore = response.data.excellentScore;
						that.testPaper.totalScore = response.data.totalScore;
						that.testPaper.totalGetScore = response.data.totalGetScore;
						
						that.testPaper.id = response.data.id;
						that.testPaperId = response.data.id;
						if (response.data.testQuestions && response.data.testQuestions.length > 0) {
							that.testPaper.singleSelections = response.data.testQuestions.filter((item) => item.questionType == 'S');
							that.testPaper.multipleSelections = response.data.testQuestions.filter((item) => item.questionType == 'M');
							that.testPaper.trueOrFalses = response.data.testQuestions.filter((item) => item.questionType == 'J');
							that.testPaper.essayQuestions  = response.data.testQuestions.filter(item=>item.questionType=='E');
							if (that.testPaper.singleSelections && that.testPaper.singleSelections.length > 0) {
								that.singleSelectionCount = that.testPaper.singleSelections.length;
								that.singleTotal = that.testPaper.singleSelections.length;
								that.singleScore = that.testPaper.singleSelections[0].score;
								that.questionTypes.push('S');
							}
							if (that.testPaper.multipleSelections && that.testPaper.multipleSelections.length > 0) {
								that.multipleSelectionCount = that.testPaper.multipleSelections.length;
								that.multipleTotal = that.testPaper.multipleSelections.length;
								that.multipleScore = that.testPaper.multipleSelections[0].score;
								that.testPaper.multipleSelections.forEach(element => {
									element.result = element.result.split("");
								});
								
								that.questionTypes.push('M');
							}
							if (that.testPaper.trueOrFalses && that.testPaper.trueOrFalses.length > 0) {
								that.trueOrFalseCount = that.testPaper.trueOrFalses.length;
								that.judgeTotal = that.testPaper.trueOrFalses.length;
								that.judgeScore = that.testPaper.trueOrFalses[0].score;
								that.questionTypes.push('J');
							}
							if(that.testPaper.essayQuestions && that.testPaper.essayQuestions.length > 0){
								that.essayQuestionCount=that.testPaper.essayQuestions.length;
								that.essayQuestionTotal=that.testPaper.essayQuestions.length;
								that.essayQuestionScore=that.testPaper.essayQuestions[0].score;
								that.questionTypes.push('E');
							}
						}
					}
				})
				.catch((err) => {
					that.msgError('查询试卷失败，' + err);
				});
			}
			else
			{
 				findTestPaper(that.selectTestPaperId)
				.then((response) => {
					if (response.data) {
						that.testPaper.paperName = response.data.testPaperName;
						that.testPaper.companyName = response.data.companyName;
						that.testPaper.passScore = response.data.passScore;
						that.testPaper.goodScore = response.data.goodScore;
						that.testPaper.excellentScore = response.data.excellentScore;
						that.testPaper.totalScore = response.data.totalScore;
						that.testPaper.id = response.data.id;
						that.testPaperId = response.data.id;
						if (response.data.testQuestions && response.data.testQuestions.length > 0) {
							that.testPaper.singleSelections = response.data.testQuestions.filter((item) => item.questionType == 'S');
							that.testPaper.multipleSelections = response.data.testQuestions.filter((item) => item.questionType == 'M');
							that.testPaper.trueOrFalses = response.data.testQuestions.filter((item) => item.questionType == 'J');
							that.testPaper.essayQuestions  = response.data.testQuestions.filter(item=>item.questionType=='E');
							if (that.testPaper.singleSelections && that.testPaper.singleSelections.length > 0) {
								that.singleSelectionCount = that.testPaper.singleSelections.length;
								that.singleTotal = that.testPaper.singleSelections.length;
								that.singleScore = that.testPaper.singleSelections[0].score;
								that.questionTypes.push('S');
							}
							if (that.testPaper.multipleSelections && that.testPaper.multipleSelections.length > 0) {
								that.multipleSelectionCount = that.testPaper.multipleSelections.length;
								that.multipleTotal = that.testPaper.multipleSelections.length;
								that.multipleScore = that.testPaper.multipleSelections[0].score;
								for (let index = 0; index < that.testPaper.multipleSelections.length; index++) {
									let element = that.testPaper.multipleSelections[index];
									element.result=[];
								}
								that.questionTypes.push('M');
							}
							if (that.testPaper.trueOrFalses && that.testPaper.trueOrFalses.length > 0) {
								that.trueOrFalseCount = that.testPaper.trueOrFalses.length;
								that.judgeTotal = that.testPaper.trueOrFalses.length;
								that.judgeScore = that.testPaper.trueOrFalses[0].score;
								that.questionTypes.push('J');
							}
							if(that.testPaper.essayQuestions && that.testPaper.essayQuestions.length > 0){
								that.essayQuestionCount=that.testPaper.essayQuestions.length;
								that.essayQuestionTotal=that.testPaper.essayQuestions.length;
								that.essayQuestionScore=that.testPaper.essayQuestions[0].score;
								that.questionTypes.push('E');
							}
						}
					}
				})
				.catch((err) => {
					this.msgError('查询试卷失败，' + err);
				});
			}
			
		},

		submit() {
			let results = [];
			let totalScore = 0;
			let questions = this.testPaper.singleSelections.concat(this.testPaper.multipleSelections, this.testPaper.trueOrFalses,this.testPaper.essayQuestions);
			if (questions && questions.length > 0) {
				for (let index = 0; index < questions.length; index++) {
					const testResult = questions[index];
					let resultScore = 0.0;
					
					// 问题类型 M:多选题 E:问答题,S：单选题,J：判断题
					if(testResult.questionType=="M"){
						if(Array.isArray(testResult.result)==true){
							testResult.result.sort();
							let tempResult = testResult.result.join("");
							testResult.result = tempResult;
							if ((testResult.answer == testResult.result) == true) {
								resultScore = testResult.score;
								totalScore += parseFloat(resultScore);
							}
						}
					}
				    else if(testResult.questionType=="E"){
						let percentage = similar(testResult.answer,testResult.result,2);
						let gotScore = testResult.score*percentage/100;
						resultScore = Math.floor(gotScore) ;
						totalScore += parseFloat(resultScore);
					}
					else {
						if ((testResult.answer == testResult.result) == true) {
							resultScore = testResult.score;
							totalScore += parseFloat(resultScore);
						}
					}
					
					let result = {};
					result.id = this.testPaper.id;
					result.userId = this.$store.getters.userInfo.id;
					result.testPaperName = this.testPaper.paperName;
					result.tester = this.$store.getters.userInfo.username;
					result.courseId = this.courseId;
					result.courseName = this.courseName;
					result.result = testResult.result;
					result.score = resultScore;
					result.seq = testResult.seq;
					result.questionType = testResult.questionType;
					result.testPaperId = this.testPaper.id;
					results.push(result);
				}
			}

			let level = "";
			if(totalScore>=this.testPaper.excellentScore && totalScore <= this.testPaper.totalScore){
				level="优";
			}
			else if (totalScore>=this.testPaper.goodScore && totalScore < this.testPaper.excellentScore){
				level="良";
			}
			else if (totalScore>=this.testPaper.passScore && totalScore < this.testPaper.goodScore){
				level="合格";
			}
			else{
				level="不合格";
			}


			// add total score
			let result = {};
			result.id = this.testPaper.id;
			result.userId = this.$store.getters.userInfo.id;
			result.testPaperName = this.testPaper.paperName;
			result.courseId = this.courseId;
			result.courseName = this.courseName;
			result.tester = this.$store.getters.userInfo.username;
			result.result = '';
			result.score = totalScore;
			result.level=level;
			result.seq = 1000;
			result.questionType = 'T';
			result.testPaperId = this.testPaper.id;
			results.push(result);
			this.submitLoading = true;
			if(totalScore==0){
				this.msgError('请答题再提交！');
				return;
			}
			
			addTestedPaperResults(results)
				.then((response) => {
					this.submitLoading = false;
					this.submitDisabled = true;
					this.recoveryResultValue();
					
					this.msgSuccess('提交试卷答案成功。');
					console.log('提交试卷答案成功。');
				})
				.catch((err) => {
					this.recoveryResultValue();
					this.submitLoading = false;
					this.msgError('增提交试卷答案失败。' + err);
					console.log('增提交试卷答案失败。' + err);
				});
		},
		recoveryResultValue()
		{
			let questions = this.testPaper.multipleSelections;
			if (questions && questions.length > 0) {
				for (let index = 0; index < questions.length; index++) {
					const testResult = questions[index];
					if(testResult.result){
						testResult.result = Array.from(testResult.result);
					}
					
				}
			}
		},

		close() {
			this.$emit('close', { refresh: this.isNew, isSuccess: true });
		},
	},
};
</script>

<style lang="scss" scoped>
.button-bottom {
	vertical-align: bottom;
}
.title {
	display: inline-flex;
	margin-left: 15px;
}
.title-button {
	width: 80px;
	margin: 0 5px;
}
.paddingLeft{
	padding-left: 80px;
}
.answerpaddingLeft{
	padding-left: 40px;
}
.answerWidth
{
	width: 150px;
}
.optionDiv{
	white-space: pre-wrap;
	display: inline-flex;
	margin-bottom: 5px;
}

</style>
<style lang="scss">
@media screen and (max-width: 576px)
{
	:deep(.el-form-item__label){
		justify-content: flex-start;
		text-align: right !important;
		}
}
</style