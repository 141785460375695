<template>
<div>
  <div v-if="editable" class="app-container">
    <el-form :model="salary" ref="refForm" :inline="true" label-width="68px">
      <el-form-item label="其他扣补" prop="otherDeduct">
        <el-input v-model="salary.otherDeduct" placeholder="请输入其他扣补金额"/>
      </el-form-item>
      <el-form-item label="扣补说明" prop="otherMemo">
        <el-input v-model="salary.otherMemo" placeholder="请输入其他扣补说明"/>
      </el-form-item>
<!--      <el-form-item label="扣税" prop="tax">-->
<!--        <el-input v-model="salary.tax" placeholder="请输入合计扣税（纳税）金额"/>-->
<!--      </el-form-item>-->
    </el-form>
  </div>
  <div>
    <span v-if="salary.attendInfo">实发工资=应发工资-考勤总扣除-其他补扣={{salary.totalSalary}}-{{salary.attDeduct}}
                  -{{salary.otherDeduct}}={{salary.totalPay}}元</span><br>
    <span v-if="salary.attendInfo">考勤总扣除=固定值扣除+比例扣除={{salary.attendInfo.attDeductFixTotal}}+{{salary.attendInfo.attDeductPercentAmount}}
                  ={{salary.attendInfo.attDeductTotal}}元， 其中固定值扣除：{{salary.attendInfo.attDeductFixTotal}}元，
                  比例扣除：{{salary.attendInfo.deductPercentDays}}天/{{salary.attendInfo.days}}天={{salary.attendInfo.attDeductPercentTotal}}</span><br>
    <span v-if="salary.attendInfo">参与考勤工资合计：{{salary.attendInfo.attSalaryTotal}}元，比例扣除合计{{salary.attendInfo.attDeductPercentAmount}}元，明细如下：</span>
  </div>
  <el-tabs v-model="activeName">
    <el-tab-pane label="考勤合计" name="att">
      <el-table :data="salaryAttData" stripe border>
        <el-table-column label="考勤项目" align="center" prop="attName"/>
        <el-table-column label="扣款方式" align="center" prop="attType"/>
        <el-table-column label="扣款值" align="center" prop="attValue"/>
        <el-table-column label="缺勤(天/次)" align="center" prop="attNum"/>
        <el-table-column label="扣款金额(元)" align="center" prop="deduct"/>
      </el-table>
    </el-tab-pane>
    <el-tab-pane label="工资项" name="salary">
      <el-table :data="getSalaryItemInfo(salary.salaryItems)" show-summary :summary-method="getTotalSalarySummaries" stripe border>
        <el-table-column label="项目名" align="center" prop="itemName">
          <template slot-scope="scopeItem">
            {{scopeItem.row.itemName}}
          </template>
        </el-table-column>
        <el-table-column label="项目类型" align="center" prop="itemType">
          <template slot-scope="scopeItem">
            {{scopeItem.row.itemType}}
          </template>
        </el-table-column>
        <el-table-column label="增减项" align="center" prop="plusMinus">
          <template slot-scope="scopeItem">
            {{scopeItem.row.plusMinus}}
          </template>
        </el-table-column>
        <el-table-column label="考勤比例发放" align="center" prop="isAttend">
          <template slot-scope="scopeItem">
            {{ (mySalary&&!scopeItem.row.showToEmp)?"***":scopeItem.row.isAttend?"是":"否"}}
          </template>
        </el-table-column>
        <el-table-column label="是否扣税" align="center" prop="isTax">
          <template slot-scope="scopeItem">
            {{(mySalary&&!scopeItem.row.showToEmp)?"***":scopeItem.row.isTax?"是":"否"}}
          </template>
        </el-table-column>
        <el-table-column label="金额(元)" align="center" prop="amount" width="88px">
          <template slot-scope="scopeItem">
            {{scopeItem.row.amount}}
          </template>
        </el-table-column>
        <el-table-column label="考勤扣除(元)" align="center" prop="deduct" width="98px">
          <template slot-scope="scopeItem">
            {{scopeItem.row.deduct.toFixed(2)}}
          </template>
        </el-table-column>
        <el-table-column label="实发(元)" align="center" prop="pay" width="88px">
          <template slot-scope="scopeItem">
            {{scopeItem.row.pay.toFixed(2)}}
          </template>
        </el-table-column>
      </el-table>
    </el-tab-pane>
  </el-tabs>
  <div slot="footer" class="dialog-footer" style="text-align: center">
    <el-button v-if="editable" type="primary" @click="submitForm" v-permission="['/cb/cbSalary/edit']">保 存</el-button>
    <el-button @click="cancel">关闭</el-button>
  </div>
</div>
</template>

<script>
import permission from '@/directive/permission/index.js' // 权限判断指令
import {DICT_TYPE} from "@/utils/dict";
import { showLoading, hideLoading } from '@/utils/reqloading';
import {updateCbSalary } from "@/api/cb/cbSalary";

export default {
  name: "salaryPayDetail",
  props: {
    editable: Boolean,
    salaryIn: Object,
    companyIdIn: Number,
    mySalary: Boolean,
  },
  directives: { permission },
  watch: {
    "editable": function (n, o) {
    },
    "salaryIn": function (n, o) {
      this.salary = this.salaryIn;
      this.salaryAttData = this.getSalaryAttData();
      console.log(this.salary)
    },
    "salary.otherDeduct": function (n, o) {
      let totalPay = this.salary.totalSalary - this.salary.attDeduct - this.salary.otherDeduct;
      this.salary.totalPay = totalPay.toFixed(2);
    },
  },
  created() {
    this.salary = this.salaryIn;
    this.salaryAttData = this.getSalaryAttData();
    console.log(this.salary)
  },
  mounted() {
    // console.log("mounted")
    // console.log(this.salaryInfoIn);
  },
  data() {
    return {
      activeName: 'salary',
      salary: {},
      salaryAttData: [],
    }
  },
  methods: {
    getSalaryAttData(){
      let types = this.salary.attendInfo.deductType;
      let data = [];
      if(types){
        return types.map( type =>{
          return this.salary.attendInfo[type];
        })
      }
      return data;
    },
    getSalaryItemInfo(str){
      let data = null;
      if(typeof str == "object") data = str;
      if(typeof str == "string"){
        console.log("getSalaryItemInfo="+str);
        let json = JSON.parse(str);
        console.log(json);
        data = json;
      }else{
        data = str;
      }
      let rtData = [];
      if(this.mySalary){
        let delItem = [];
        let extVal = 0;
        let val = 0;
        let deduct = 0;
        let pay = 0;
        for(let i = 0; i < data.length; i++){
          let item = data[i];
          // console.log(item)
          if(!item.showToEmp){
            delItem.push(item);
            const value = Number(item.amount);
            if (!isNaN(value)) {
              if (item.plusMinus == '增项') {
                val += value;
              } else {
                val -= value;
              }
              if(DICT_TYPE.CB_TEMPLATE_SALARY_TYPE_EXTRA == item.itemType){
                if (item.plusMinus == '增项') {
                  extVal += value;
                } else {
                  extVal -= value;
                }
              }
            }
            const deductn = Number(item.deduct);
            const payn = Number(item.pay);
            if (item.plusMinus == '增项') {
              if(!isNaN(deductn)){
                deduct += deductn;
              }
              if(!isNaN(payn)){
                pay += payn;
              }
            } else {
              if(!isNaN(deductn)){
                deduct -= deductn;
              }
              if(!isNaN(payn)){
                pay -= payn;
              }
            }
          }else{
            rtData.push(item);
          }
        }
        rtData.push({itemName:'其他合并项',plusMinus:'增项',amount:val,deduct:deduct,pay:pay,showToEmp:false})
      }else{
        rtData = data;
      }
      return rtData;
    },
    getTotalSalarySummaries(param) {
      const {columns, data} = param;
      const sums = [];
      sums[0] = '合计';
      let extVal = 0;
      let val = 0;
      let deduct = 0;
      let pay = 0;
      data.map(item => {
        const value = Number(item.amount);
        if (!isNaN(value)) {
          if (item.plusMinus == '增项') {
            val += value;
          } else {
            val -= value;
          }
          if(DICT_TYPE.CB_TEMPLATE_SALARY_TYPE_EXTRA == item.itemType){
            if (item.plusMinus == '增项') {
              extVal += value;
            } else {
              extVal -= value;
            }
          }
        }
        const deductn = Number(item.deduct);
        const payn = Number(item.pay);
        if (item.plusMinus == '增项') {
          if(!isNaN(deductn)){
            deduct += deductn;
          }
          if(!isNaN(payn)){
            pay += payn;
          }
        } else {
          if(!isNaN(deductn)){
            deduct -= deductn;
          }
          if(!isNaN(payn)){
            pay -= payn;
          }
        }

      });
      sums[5] = ""+val.toFixed(2);//+"(其中包含"+extVal+DICT_TYPE.CB_TEMPLATE_SALARY_TYPE_EXTRA+")";
      sums[6] = deduct.toFixed(2);
      sums[7] = pay.toFixed(2); 
      // sums[3] = "包含"+extVal+DICT_TYPE.CB_TEMPLATE_SALARY_TYPE_EXTRA+"";
      // if(this.contractSalary){
      //   sums[2] = this.contractSalary+"(合同总工资)"
      // }
      return sums;
    },
    // 取消按钮
    cancel() {
      this.$emit("close", {refresh:false,isSuccess:true});
    },
    submitForm(){
      const row = this.salary;
      // const
      let form = {id:row.id,calculateType:'人工设置',totalPay:row.totalPay, otherDeduct:row.otherDeduct,otherMemo:row.otherMemo};
      showLoading();
      updateCbSalary(form).then(response => {
        this.msgSuccess("修改成功");
        hideLoading();
        this.$emit("close", {refresh:true,isSuccess:true});
      });
    },
  },

}
</script>

<style scoped>

</style>
