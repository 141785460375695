<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px">
      <el-form-item label="调动调岗用户id" prop="transferuserid">
        <el-input
            v-model="queryParams.transferuserid"
            placeholder="请输入调动调岗用户id"
            clearable
            size="small"
            @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="调出部门" prop="outdepartmentid">
        <el-input
            v-model="queryParams.outdepartmentid"
            placeholder="请输入调出部门"
            clearable
            size="small"
            @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="调出岗位" prop="outpositionid">
        <el-input
            v-model="queryParams.outpositionid"
            placeholder="请输入调出岗位"
            clearable
            size="small"
            @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="调动时间" prop="transferdate">
        <el-date-picker clearable size="small"
                        v-model="queryParams.transferdate"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="选择调动时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
            type="primary"
            plain
            icon="el-icon-plus"
            size="mini"
            @click="handleAdd"
            v-permission="['/bpm/transferhis/add']"
        >新增
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
            type="success"
            plain
            icon="el-icon-edit"
            size="mini"
            :disabled="single"
            @click="handleUpdate"
            v-permission="['/bpm/transferhis/edit']"
        >修改
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
            type="danger"
            plain
            icon="el-icon-delete"
            size="mini"
            :disabled="multiple"
            @click="handleDelete"
            v-permission="['/bpm/transferhis/remove']"
        >删除
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
            type="warning"
            plain
            icon="el-icon-download"
            size="mini"
            @click="handleExport"
            v-permission="['/bpm/transferhis/export']"
        >导出
        </el-button>
      </el-col>
      <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>
    </el-row>

    <el-table v-loading="loading" :data="transferhisList" @selection-change="handleSelectionChange" stripe>
      <el-table-column type="selection" width="55" align="center"/>
      <el-table-column label="自增id" align="center" prop="id"/>
      <el-table-column label="所属公司" align="center" prop="companyid"/>
      <el-table-column label="所属公司" align="center" prop="companycode"/>
      <el-table-column label="调动申请表单id" align="center" prop="transferformid"/>
      <el-table-column label="调动调岗用户id" align="center" prop="transferuserid"/>
      <el-table-column label="调出部门" align="center" prop="outdepartmentid"/>
      <el-table-column label="调出部门" align="center" prop="outdepartmentname"/>
      <el-table-column label="调出岗位" align="center" prop="outpositionid"/>
      <el-table-column label="调出岗位" align="center" prop="outpositionname"/>
      <el-table-column label="调动时间" align="center" prop="transferdate" width="180">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.transferdate, '{y}-{m}-{d}') }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="handleUpdate(scope.row)"
              v-permission="['/bpm/transferhis/edit']"
          >修改
          </el-button>
          <el-button
              size="mini"
              type="text"
              icon="el-icon-delete"
              @click="handleDelete(scope.row)"
              v-permission="['/bpm/transferhis/remove']"
          >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
        v-show="total>0"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
    />

    <!-- 添加或修改调动调岗历史对话框 -->
    <el-dialog :title="title" :visible.sync="open" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-row :gutter="35">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
            <el-form-item label="所属公司" prop="companyid">
              <el-input v-model="form.companyid" placeholder="请输入所属公司"/>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
            <el-form-item label="所属公司" prop="companycode">
              <el-input v-model="form.companycode" placeholder="请输入所属公司"/>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
            <el-form-item label="调动申请表单id" prop="transferformid">
              <el-input v-model="form.transferformid" placeholder="请输入调动申请表单id"/>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
            <el-form-item label="调动调岗用户id" prop="transferuserid">
              <el-input v-model="form.transferuserid" placeholder="请输入调动调岗用户id"/>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
            <el-form-item label="调出部门" prop="outdepartmentid">
              <el-input v-model="form.outdepartmentid" placeholder="请输入调出部门"/>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
            <el-form-item label="调出部门" prop="outdepartmentname">
              <el-input v-model="form.outdepartmentname" placeholder="请输入调出部门"/>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
            <el-form-item label="调出岗位" prop="outpositionid">
              <el-input v-model="form.outpositionid" placeholder="请输入调出岗位"/>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
            <el-form-item label="调出岗位" prop="outpositionname">
              <el-input v-model="form.outpositionname" placeholder="请输入调出岗位"/>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
            <el-form-item label="调动时间" prop="transferdate">
              <el-date-picker clearable size="small"
                              v-model="form.transferdate"
                              type="date"
                              value-format="yyyy-MM-dd"
                              placeholder="选择调动时间">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm" v-permission="['/bpm/transferhis/add', '/bpm/transferhis/edit']">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  listTransferhis,
  getTransferhis,
  delTransferhis,
  addTransferhis,
  updateTransferhis,
  exportTransferhis } from "@/api/bpm/transferHis";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import RightToolbar from "@/components/RightToolbar"
import permission from '@/directive/permission/index.js' // 权限判断指令
import Pagination from "@/components/Pagination";

// 表查询配置
const dbOrderBy = ''

export default {
  name: "Transferhis",
  components: {
    Treeselect,
    RightToolbar,
    Pagination,
  },
  directives: { permission },
  data() {
    return {
      // 遮罩层
      loading: true,
      // 查询参数
      where: {
        query: '',
        filters: []
      },


      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 调动调岗历史表格数据
      transferhisList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        orderby: dbOrderBy,
        transferuserid: null,
        outdepartmentid: null,
        outpositionid: null,
        transferdate: null,
      },
      // 表单参数
      form: {
      }
      ,
      // 表单校验
      rules: {
      }
    }
        ;
  },
  created() {
    this._filter = {};
    this.getList();
  },
  methods: {
    /** 查询调动调岗历史列表 */
    getQuery(){
      this._filter = {};
      if(this.queryParams.transferuserid) {
        this._filter['transferuserid'] = {
          type: 'search',
          value: this.queryParams.transferuserid,
        }
      }
      if(this.queryParams.outdepartmentid) {
        this._filter['outdepartmentid'] = {
          type: 'search',
          value: this.queryParams.outdepartmentid,
        }
      }
      if(this.queryParams.outpositionid) {
        this._filter['outpositionid'] = {
          type: 'search',
          value: this.queryParams.outpositionid,
        }
      }
      if(this.queryParams.transferdate) {
        this._filter['transferdate'] = {
          type: 'search',
          value: this.queryParams.transferdate,
        }
      }
      const filters = this.parseFilters(this._filter)
      if (filters && filters.length > 0) {
        this.where.filters = filters
      } else {
        // 如果过滤条件为空，设置空数组
        this.where.filters = []
      }
      const params = {
        pageNum: this.queryParams.pageNum,
        pageSize: this.queryParams.pageSize,
        where: this.where,
        order: this.orderby
      }

      return params;
    },
    getList() {
      this.loading = true;
      const params = this.getQuery();
      listTransferhis(params).then(response => {
        this.transferhisList = response.data.records;
        this.total = response.data.total;
        this.loading = false;
      })
      ;
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {};
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加调动调岗历史";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.id || this.ids
      getTransferhis(id).then(response => {
        this.form = response.data;
        this.open = true;
        this.title = "修改调动调岗历史";
      })
      ;
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate(valid => {
            if(valid) {
              if (this.form.id != null) {
                updateTransferhis(this.form).then(response => {
                  this.msgSuccess("修改成功");
                  this.open = false;
                  this.getList();
                })
                ;
              } else {
                addTransferhis(this.form).then(response => {
                  this.msgSuccess("新增成功");
                  this.open = false;
                  this.getList();
                })
                ;
              }
            }
          }
      )
      ;
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      let ids = [];
      if(row.id){
        ids = [row.id];
      }else{
        ids = this.ids;
      }

      this.$confirm('是否确认删除选中的调动调岗历史数据?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return delTransferhis(ids);
      }).then(() => {
        this.getList();
        this.msgSuccess("删除成功");
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      const params = this.getQuery();
      this.$confirm('是否确认导出所有调动调岗历史数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return exportTransferhis(params);
      }).then(response => {
        this.download(response.msg);
      })
    }
  }
};
</script>
<style scoped>

</style>
