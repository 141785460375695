<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px">
      <el-form-item label="公司名称" prop="companyId">
        <treeselect v-model="queryParams.companyId" style="width: 198px"
                    :options="companyOptions"
                    :normalizer="normalizerCompany" placeholder="请选择所属公司"/>
      </el-form-item>
      <el-form-item label="考核名称" prop="name">
        <el-input v-model="queryParams.name" placeholder="请输入考核名称" clearable  @keyup.enter.native="handleQuery" />
      </el-form-item>
      <el-form-item label="内容描述" prop="name">
        <el-input v-model="queryParams.content" placeholder="请输入包含内容" clearable  @keyup.enter.native="handleQuery" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
<!--      <el-col :span="1.5">-->
<!--        <el-button type="primary" plain icon="el-icon-plus" size="mini"-->
<!--                   @click="handleAdd" v-permission="['/kpi/employ/add']" >新增 </el-button>-->
<!--      </el-col>-->
      <el-col :span="1.5">
        <el-button type="success" plain icon="el-icon-edit" size="mini" :disabled="single"
                   @click="handleUpdate" v-permission="['/kpi/employ/edit']" >评分 </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button type="danger" plain icon="el-icon-delete" size="mini" :disabled="multiple"
                   @click="handleDelete" v-permission="['/kpi/employ/remove']">删除</el-button>
      </el-col>
      <!--      <el-col :span="1.5">-->
      <!--        <el-button-->
      <!--            type="warning"-->
      <!--            plain-->
      <!--            icon="el-icon-download"-->
      <!--            size="mini"-->
      <!--            @click="handleExport"-->
      <!--            v-permission="['/kpi/employ/export']"-->
      <!--        >导出-->
      <!--        </el-button>-->
      <!--      </el-col>-->
      <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>
    </el-row>

    <el-table v-loading="loading" :data="kpiEmploy" @selection-change="handleSelectionChange" stripe border>
      <el-table-column type="selection" width="55" align="center"/>
      <el-table-column label="姓名" align="center" prop="empName"/>
      <el-table-column label="考核表名" align="center" prop="name">
        <template slot-scope="scope" >
          <el-button size="mini" type="text"
                     @click="handleView(scope.row)">
            {{ scope.row.name }}
          </el-button>
        </template>
      </el-table-column>
      <el-table-column label="考核内容" align="center" width="98" :show-overflow-tooltip="true" prop="content"/>
      <el-table-column label="部门" align="center" prop="depId" show-overflow-tooltip width="108" sortable>
        <template slot-scope="scope">
          {{companyFormat(scope.row, null)}}
        </template>
      </el-table-column>
      <el-table-column label="考核类型" align="center" prop="type"/>
      <el-table-column label="考核开始日期" align="center" min-width="118" prop="startDate"/>
      <el-table-column label="考核结束日期" align="center" min-width="118" prop="endDate"/>
      <el-table-column label="考核奖金" align="center" prop="hasBonus">
        <template slot-scope="scope">{{scope.row.hasBonus?"是":"否"}}</template>
      </el-table-column>
      <el-table-column label="奖金类型" align="center" min-width="98" prop="bonusType">
        <template slot-scope="scope">{{scope.row.hasBonus?scope.row.bonusType:""}}</template>
      </el-table-column>
      <el-table-column label="奖金金额" align="center" min-width="98" prop="bonus">
        <template slot-scope="scope">{{ (scope.row.hasBonus&&scope.row.bonusType=='固定金额')?scope.row.bonus:""}}</template>
      </el-table-column>
      <el-table-column label="评分人及权重(%)" align="center" min-width="268" :show-overflow-tooltip="true" prop="levelScore">
        <template slot-scope="scope">
          <span v-for="tag in scope.row.levelScore.items" :key="tag.id">
            {{tag.empName}}({{tag.percentage}}%)
          </span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" min-width="118" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button size="mini" type="text" icon="el-icon-edit" @click="handleUpdate(scope.row)"
                     v-permission="['/kpi/employ/edit']">评分</el-button>
          <el-button size="mini" type="text" icon="el-icon-delete" @click="handleDelete(scope.row)"
                     v-permission="['/kpi/employ/remove']">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination v-show="total>0" :total="total" :page.sync="queryParams.pageNum"
                :limit.sync="queryParams.pageSize" @pagination="getList"/>
  </div>
</template>

<script>
import {listKpiEmploy,getKpiEmploy,delKpiEmploy,addKpiEmploy,updateKpiEmploy,exportKpiEmploy } from "@/api/kpi/employ";
import {listKpiTemplate} from "@/api/kpi/template";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import RightToolbar from "@/components/RightToolbar"
import permission from '@/directive/permission/index.js' // 权限判断指令
import Pagination from "@/components/Pagination";
import {listCompany} from "@/api/org/company";
import {listDepartment} from "@/api/org/department";
import { Local } from '@/utils/storage.js';
import {showLoading,hideLoading} from "@/utils/reqloading";

// 表查询配置
const dbOrderBy = ''

export default {
  name: "KpiEmploy",
  components: {
    Treeselect,RightToolbar,Pagination,
  },
  directives: { permission },
  data() {
    return {
      // 公司树选项
      companyList: [],
      companyOptions: [],
      row: null,
      // 遮罩层
      loading: true,
      // 查询参数
      where: {
        query: '',
        filters: []
      },
      clockRange: false,

      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 表格数据
      kpiEmploy: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        orderby: dbOrderBy,
        name: null,
        content: null,
      },
      // 表单参数
      form: {
        type: 'template',
        templateId: null,
      },
      editDialogSize:"60%",
      kpiTemplateList: [],
    };
  },
  created() {
    if(!this.$store.getters.userInfo.employ || !this.$store.getters.userInfo.employ.id){
      // this.$message.error('当前账号不是员工，不能使用考核评分功能！');
    }else{
      this.currentEmpId = this.$store.getters.userInfo.employ.id;
    }
    this.getTreeselectCompany();
    this._filter = {};
    this.getList();
    let uniapp =Local.get("isUniapp")?true:false;
    if(uniapp)
    {
      this.editDialogSize="90%";
    }
  },
  methods: {
    // getScoreEmpName(levelScore){
    //   if(levelScore && levelScore.items && levelScore.items.length > 0){
    //     for(let i = 0; i < levelScore.items.length, i++){
    //
    //     }
    //   }else{
    //     return ""
    //   }
    // },
    handleView(row){
      this.$router.push({name:"kpi_employ_view",query: {op:'view',id:row.id, readOnly:true}});
    },
    /** 查询公司下拉树结构 */
    getTreeselectCompany() {
      const params = {
        pageNum: 1,
        pageSize: 500,
        where: null,
        order: ''
      }
      listCompany(params).then(response => {
        this.companyList = response.data.records;
        this.companyOptions = this.handleTree(response.data.records, "id", "parentId");
      }) ;
      listDepartment(params).then(response => {
        this.departmentList = response.data.records;
        // this.companyOptions = this.handleTree(response.data.records, "id", "parentId");
      }) ;
    },
    /** 查询班次管理列表 */
    getQuery(){
      this._filter = {};
      if(this.queryParams.name) {
        this._filter['name'] = {
          type: 'search',
          value: this.queryParams.name,
        }
      }
      return this.getQueryParam(this._filter,this.queryParams.pageNum,this.queryParams.pageSize,this.orderby)
    },
    getList() {
      this.loading = true;
      const params = this.getQuery();
      listKpiEmploy(params).then(response => {
        this.kpiEmploy = response.data.records;
        this.total = response.data.total;
        this.loading = false;
      })
      ;
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {};
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },

    /** 修改按钮操作 */
    handleUpdate(row) {
      if(row.id){
        this.row = row;
      }else{
        // let kpiEmp = null;
        // for(let i = 0; i < this.kpiEmploy.length; i++){
        //   if(this.kpiEmploy[i].id == this.ids){
        //     kpiEmp = kpiEmploy[i]
        //     break;
        //   }
        // }
        // // 查找评分人是否是当前用户
        // let isScoreEmp = false;
        // if(kpiEmp.levelScore && kpiEmp.levelScore.maxOrder > 0){
        //   for(let i = 0; i < kpiEmp.levelScore.maxOrder; i++){
        //     // if(kpiEmp.levelScore["empId"+i] == )
        //   }
        // }else{
        //
        // }
        this.row = {};
        this.row.id = this.ids;
      }
      this.$router.push({name:"kpi_employ_view",query: {op:'edit',id:this.row.id}});
      // this.open = true;
      // this.title = "修改绩效模板";
    },

    close(arg){
      this.open = false;
      if(arg.refresh){
        this.getList();
      }
    },

    /** 转换公司数据结构 */
    normalizerCompany(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.id,
        label: node.companyName,
        children: node.children
      };
    },
    companyFormat(row, column) {
      // console.log(row);
      for(var i = 0; i < this.companyList.length; i++){
        const item =  this.companyList[i];
        if(item.id == row.companyId){
          return item.companyName;
        }
      }
      return "";
    },
    departmentFormat(row, column) {
      for(var i = 0; i < this.departmentList.length; i++){
        const item =  this.departmentList[i];
        // console.log("id:"+item.id+",depid:"+row.departmentId+", name :"+item.name+",ee="+(item.id == row.departmentId));
        if(item.id == row.depId){
          return item.name;
        }
      }
      return "";
    },

    getCompanyCode(companyId){
      // console.log(companyId)
      // console.log(this.companyList)
      for(var i = 0; i < this.companyList.length; i++){
        const item =  this.companyList[i];
        if(item.id == companyId){
          return item.code;
        }
      }
    },

    /** 删除按钮操作 */
    handleDelete(row) {
      let ids = [];
      if(row.id){
        ids = [row.id];
      }else{
        ids = this.ids;
      }

      this.$confirm('是否确认删除选中的员工绩效考核数据?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return delKpiEmploy(ids);
      }).then(() => {
        this.getList();
        this.msgSuccess("删除成功");
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      const params = this.getQuery();
      this.$confirm('是否确认导出所有绩效数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return exportKpiEmploy(params);
      }).then(response => {
        this.download(response.msg);
      })
    }
  }
};
</script>

<style scoped>
.el-form-item{
  margin-bottom: 10px;
}

</style>
