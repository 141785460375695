import request from '@/utils/request'

// 查询转正申请列表
export function listOfficial(query) {
    return request({
        url: '/bpm/official/findAll',
        method: 'post',
        data: query
    })
}

// 查询转正申请详细
export function initOfficialApply() {
    return request({
        url: '/bpm/official/initApply',
        method: 'get'
    })
}

// 查询转正申请详细
export function getOfficial(id) {
    return request({
        url: '/bpm/official/findOne?id=' + id,
        method: 'post'
    })
}

export function getByProcessInstanceId(processInstanceId) {
    return request({
        url: '/bpm/official/getByProcessInstanceId?processInstanceId=' + processInstanceId,
        method: 'post'
    })
}

// 新增转正申请
export function addOfficial(data) {
    return request({
        url: '/bpm/official/add',
        method: 'post',
        data: data
    })
}

// 修改转正申请
export function completeTask(data) {
    return request({
        url: '/bpm/official/completeTask',
        method: 'post',
        data: data
    })
}

// 删除转正申请
export function delOfficial(id) {
    return request({
        url: '/bpm/official/remove',
        method: 'post',
        data: id
    })
}

// 导出转正申请
export function exportOfficial(query) {
    return request({
        url: '/bpm/official/export',
        method: 'post',
        data: query
    })
}
