<template>
  	<el-collapse-item title="军人信息" :name="this.collapseStatus" :class="collapseSetting()">
		<el-form class="employ" label-width="150px">
		<el-row>
			<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
				<el-form-item label="是否现役军人">
					<el-checkbox v-model="soldierInfoModal.enlistedMan" :disabled="readonly" ></el-checkbox>
				</el-form-item>
			</el-col>
            	<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
				<el-form-item label="是否基干民兵">
					<el-checkbox v-model="soldierInfoModal.primarySoldier" :disabled="readonly" ></el-checkbox>
				</el-form-item>
			</el-col>
	
			<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
				<el-form-item label="是否军属家庭">
					<el-checkbox v-model="soldierInfoModal.soldierFamily" :disabled="readonly" ></el-checkbox>
				</el-form-item>
			</el-col>
            	<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
				<el-form-item label="是否复退军人">
					<el-checkbox v-model="soldierInfoModal.demobilizedSoldier" :disabled="readonly" ></el-checkbox>
				</el-form-item>
			</el-col>
	
			<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
				<el-form-item label="是否复退军属">
					<el-checkbox v-model="soldierInfoModal.demobilizedSoldierFamily" :disabled="readonly" ></el-checkbox>
				</el-form-item>
			</el-col>
            	<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
                <el-form-item label="参军时间">
                    <el-date-picker v-model="soldierInfoModal.joinArmDate"  value-format="yyyy-MM-dd"  :readonly="readonly" align="left" type="date" placeholder="选择日期" >
					</el-date-picker>
				</el-form-item>
			</el-col>
	
			<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
				<el-form-item label="参军地点">
					<el-input v-model="soldierInfoModal.joinArmPlace" :readonly="readonly" ref="armPlaceFocus"></el-input>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
				<el-form-item label="退役或转业时间">
                    <el-date-picker v-model="soldierInfoModal.demobilizedDate"  value-format="yyyy-MM-dd"  :readonly="readonly" align="left" type="date" placeholder="选择日期" >
					</el-date-picker>
				</el-form-item>
			</el-col>
		
			<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
				<el-form-item label="退役或转业地点">
                    <el-input v-model="soldierInfoModal.demobilizedPlace" :readonly="readonly" ></el-input>
				</el-form-item>
			</el-col>
				<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
				 <el-form-item >
				
				</el-form-item> 
			</el-col>
			<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
				<el-form-item label=" ">
					<el-button v-on:click="eidit" icon="el-icon-edit" plain  v-bind:disabled="readonly"  type="primary">编辑</el-button>
					<el-button v-on:click="save" v-bind:disabled="readonly" :loading="saving" icon="el-icon" plain  type="primary">保存</el-button>
				</el-form-item>
			</el-col>
		</el-row>
		</el-form>
	</el-collapse-item>
</template>

<script>
export default {

    
    data() {
        let readonlyInit = true;
		let soldierInfoInit = null;
        if (this.soldierInfo==null) {
            soldierInfoInit={
                enlistedMan:false,
                primarySoldier:false,
                soldierFamily:false,
                demobilizedSoldier:false,
                demobilizedSoldierFamily:false,
                joinArmDate:null,
                joinArmPlace:null,
                demobilizedDate:null,
                demobilizedPlace:null
            };
            readonlyInit = false;

         }
		 else{
			 	soldierInfoInit = this.soldierInfo;
		 }
		
		if(this.locked){
			readonlyInit=true;
		}
		else{
			readonlyInit = this.isReadOnly;
		}



        return {
            soldierInfoModal:soldierInfoInit,
            readonly: readonlyInit,
			saving:false,
            pickerOptions1: {
                    disabledDate(time) {
                        return time.getTime() > Date.now();
                    }
            }
        };
    },
      props: ['soldierInfo', 'id','isReadOnly','collapseStatus','locked'],
	  	watch: {
		isReadOnly(newValue,oldV) {
			this.readonly = newValue;
		},
		locked(newValue, oldV) {
			this.hasLocked = newValue;
			if (this.hasLocked) {
				this.readonly = true;
			}
			else
			{
				this.readonly = false;
			}
		},
	},
	computed:{
		collapseSetting(){
			return function(){
				if(this.soldierInfoModal.enlistedMan || this.soldierInfoModal.primarySoldier
					|| this.soldierInfoModal.soldierFamily ||  this.soldierInfoModal.demobilizedSoldier
					|| this.soldierInfoModal.demobilizedSoldierFamily )
				{
					return {callpse_header_fill:true};
				}
				else
				{
					return {callpse_header_unfill:true};
				}
				
			}
		},
	},
      methods: {
		save() {
			// save data to DB
			let savingSoldierInfo = {};
			savingSoldierInfo.id = this.id;
			savingSoldierInfo.soldierInfo = this.soldierInfoModal;
			this.saving=true;
			this.$emit('updatePartialEmployeeInfo', { data: savingSoldierInfo }, (response) => {
				this.saving = false;
				if (response.success) {
					this.msgSuccess('修改成功');
					this.readonly = true;
				} else {
					this.msgError('修改失败,' + response.msg);
				}
			});
		},
		eidit() {
			this.readonly = false;
			this.$refs.armPlaceFocus.focus();
		},
	}
}
</script>

<style>

</style>