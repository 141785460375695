<template>
	<div>
		<el-table v-loading="loading" :data="checkInList" style="width: 100%" max-height="100%" stripe border>
			<el-table-column label="编号ID" align="center" prop="id" v-if="false" />
			<el-table-column label="员工" align="center" prop="employeeName" sortable min-width="150" :show-overflow-tooltip="true" />
			<el-table-column label="培训名称" align="center" prop="trainingName" sortable min-width="100"> </el-table-column>
			<el-table-column label="签到开始时间" align="center" prop="createTime" min-width="150" sortable :show-overflow-tooltip="true" />
			<el-table-column label="签到结束时间" align="center" prop="updateTime" min-width="150" sortable :show-overflow-tooltip="true" />
			<el-table-column label="评价" align="center" prop="comments" sortable min-width="100" />
			<el-table-column label="星数" align="center" prop="stars" sortable min-width="100" />
			<el-table-column label="是否完成" align="center" prop="completed" sortable min-width="100">
				<template slot-scope="scope">
					<span>{{ completeStatus(scope.row) }}</span>
				</template>
			</el-table-column>
		</el-table>
        <el-row>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">
                	<el-button style="float:right;margin-top:10px;" plain type="info" @click="close">关闭</el-button>
			        <el-button style="float:right;margin-top:10px;margin-right:10px;" :loading="downloadCheckInLoading" plain type="info" @click="exportTrainingCheckInList">导出</el-button>
				
				
			</el-col>
        </el-row>
	</div>
</template> 

<script>
import { getCheckInList,exportTrainingCheckInList } from '@/api/training/myTraining';
export default {
	name: 'checkinList',

	data() {
		return {
			checkInList: [],
			loading: false,
			downloadCheckInLoading:false,
		};
	},
    props:{traningPlanId:String},
	created() {
		this.getCheckInList();
	},

	mounted() {},

	methods: {
		getCheckInList() {
			let training = {};
			training.courseId = this.traningPlanId;
            this.loading=true;
			getCheckInList(training)
				.then((response) => {
                    this.loading=false;
					if (response.code == 200) {
						this.checkInList = response.data;
					} else {
						this.checkInList = {};
					}

					console.log('获取记录培训成功。');
				})
				.catch((err) => {
					//this.msgError('获取记录培训失败，'+err);
					 this.loading=false;
					console.log('获取记录培训失败，' + err);
				});
		},
        close() {
			this.$emit('close', { refresh: false, isSuccess: true });
		},
        completeStatus(row){
			if(row.completed){
				return "是";
			}
			else{
				return "否";
			}
		},
		exportTrainingCheckInList() {
			let that = this;
			this.$confirm('是否确认导出本培训签到信息?', '警告', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(function () {
					that.downloadCheckInLoading = true;
					let queryParams = {};
					queryParams.trainingId=that.traningPlanId;
					return exportTrainingCheckInList(queryParams);
				})
				.then((result) => {
					that.downloadCheckInLoading=false;
					if (result) {
						let blob = new Blob([result.data], {
							type: result.data.type,
						});
						let filename = (result.headers.filename && decodeURI(result.headers.filename)) || '培训签到表.xlsx';
						if ('download' in document.createElement('a')) {
							const link = document.createElement('a');
							link.style.display = 'none';
							link.href = URL.createObjectURL(blob);
							link.setAttribute('download', filename);
							document.body.appendChild(link);
							link.click();
							document.body.removeChild(link);
						} else {
							navigator.msSaveBlob(blob, filename);
						}
					}
				})
				.catch((e) => {
					that.downloadCheckInLoading=false;
					that.msgError(`后端下载文件出错 & ${e}`);
				});
		},
		
	},
};
</script>

<style lang="scss" scoped>
</style>