<template>
<div>
<h3>加载中，请稍后...</h3>
  <span>{{status}} </span>
  <span>{{datetime}}</span>
<!--  <div><el-button  @click="gotoPage('/pages/attendance/attendanceGroup/index')">默认按钮</el-button></div>-->
<!--  <Home></Home>-->
<!--  <div class="app-container">-->
<!--    <el-tabs v-model="activeName">-->
<!--      <el-tab-pane label="消息代办" name="msg">-->
<!--        <Home></Home>-->
<!--      </el-tab-pane>-->
<!--      <el-tab-pane label="系统功能" name="sys">-->
<!--        <div>系统功能</div>-->
<!--      </el-tab-pane>-->
<!--    </el-tabs>-->
<!--  </div>-->

</div>
</template>
<script>
import { Local } from '@/utils/storage.js';
import { getToken, removeToken } from '@/utils/auth' // get token from cookie
import {PrevLoading} from '@/utils/loading.js';
import * as uni from '@/utils/uni.webview.1.5.2.js'
// import Home from '@/views/home/index';

export default {
  name: "uniapp",
  // components: {
  //   Home,
  // },
  data() {
    return {
      checkPath: "/appwork",
      activeName: "msg",
      token:null,
      pages:null,
      myUni:null,
      datetime: "",
      status: "",
    }
  },
  created() {
    console.log("uniapp created...")
    // console.log(" login page =  this.$route.query.token")
    // let token = this.$route.query.token;
    // console.log(token)
    // this.$store.dispatch('user/uLoginToken', token).then(() => {
    //   console.log("aaaaaaaaaaaaaaaaa*****************&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&")
    //   PrevLoading.start();
    // }).catch(() => {
    // })
    PrevLoading.start();
    this.status = " created ";
    this.doLoad();
  },
  mounted() {
  },
  // activated(){
  //   this.status = " activated ";
  //   this.doLoad();
  // },
  methods: {
    doLoad(){
      console.log(" login page =  this.$route.query.token")
      this.token = this.$route.query.token;
      console.log(this.$route.query.token)
      this.pages = this.$route.query.pages;
      this.datetime = this.$route.query.b;
      let that = this;
      document.addEventListener("UniAppJSBridgeReady", function() {
        that.status = " UniAppJSBridgeReady 执行中 ";
        console.log("UniAppJSBridgeReady ................................ uni=");
        console.log(uni);
        that.myUni = uni;
        console.log("UniAppJSBridgeReady ................................Vue.prototype.myUni.postMessage=");
        console.log(that.myUni);
        console.log(that.myUni.postMessage);
        let token = that.token;
        let pages = that.pages;
        let uniApp=Local.get("isUniapp")?true:false;
        let hasToken = getToken();
        console.log(" uniapp hasToken="+hasToken)
        if(uniApp && hasToken && token == hasToken){
          console.log("uniapp already login. direct route to page "+pages)
          console.log("uni.postMessageuni.postMessageuni.postMessageuni.postMessageuni.postMessageuni.postMessageuni.postMessage")
          that.status = " 准备跳转到页面 "+pages;
          if(pages) that.$router.push(pages);
          that.postMsg({ uniAppReady: true})
          // PrevLoading.done();
        }else if(token){
          that.status = " 验证身份 ";
          that.submitToken(token,pages);
        }else{
          console.log("!!!!!!!!!!!!! no token,")
          that.status = " 执行错误，没有找到身份信息 ";
          // PrevLoading.done();
        }
      });

      // 注册回退方法
      window.myHistory = () => {
        var path = this.$route.path
        console.log("do myHistory path=")
        console.log(path)
        if (this.checkPath.indexOf(path) >= 0) {
          // 到达主页面, 无法后退只能退出
          uni.postMessage({
            data: {
              message: '我要退出!',
              type: 'outApp'
            }
          })
        } else {
          this.$router.push("/appwork");
          // window.history.go(-1)
          // this.$router.go(-1)
        }
      }
    },
    submitToken(token,pages){
      console.log("submitToken")
      console.log(pages)
      let that = this;
      this.$store.dispatch('user/uLoginToken', token).then(() => {
        console.log("aaaaaaaaaaaaaaaaa*****************&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&")
        // PrevLoading.done();
        that.status = " 身份验证成功 ";
        if(pages) that.$router.push(pages);
        that.postMsg({ uniAppReady: true})
      }).catch(() => {
        that.status = " 身份验证失败 ";
        console.log("******************dispatch exception")
      })
    },
    postMsg(data){
      let that = this;
      setTimeout(function (){
        console.log("uni.postMessageuni.postMessageuni.postMessageuni.postMessageuni.postMessageuni.postMessageuni.postMessage")
        console.log(that.myUni)
        if(!that.myUni){
          that.postMsg(data);
        }else{
          console.log("post data="+data);
          console.log(data);
          that.myUni.postMessage({
            data: data
          })
        }
      },500)

    },
    gotoPage(url){
      this.$router.push(url);
    }
  },
}
</script>

<style scoped>

</style>
