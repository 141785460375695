
import store from '@/store'

export const DICT_TYPE = {
    // bpm
    BPM_MODEL_CATEGORY: 'bpm_model_category',
    BPM_FLOW_TYPE: 'bpm_flow_type',
    BPM_TRANSFER_BATCH_EXE: 'bpm_transfer_batch_exe',
    BPM_DEPART_TYPE: 'bpm_depart_type',
    BPM_DEPART_TYPE_SELF: 1, // 自我原因离职
    BPM_LEAVE_TYPE: 'bpm_leave_type',
    BPM_OVERTIME_TYPE: 'bpm_overtime_type',
    BPM_CORRECT_TIME_CLOCK_TYPE: 'bpm_correct_time_clock_type',
    BPM_BUSINESS_TRIP_TYPE: 'bpm_business_trip_type',
    // common
    COM_YES_NO: 'com_yes_no',
    COM_APPROVE_NO: 'com_approve_no',

    // att 考勤
    ATT_TYPE:'att_type',
    ATT_CLOCK_TIME_TYPE: 'att_clock_time_type',
    ATT_STATISTIC_TYPE: 'Att_statistic_type',
    ATT_CONFIRM_STATUS: 'att_confirm_status',

    // CB 薪酬
    CB_TEMPLATE_TYPE: 'cb_template_type',
    CB_TEMPLATE_SALARY_TYPE: 'cb_template_salary_type',
    CB_TEMPLATE_SALARY_TYPE_SALARY: '工资',
    CB_TEMPLATE_SALARY_TYPE_FLOAT: '浮动值',
    CB_TEMPLATE_SALARY_TYPE_EXTRA: '其他项',
    CB_TEMPLATE_SALARY_PLUSMINUS: 'cb_template_salary_plusminus',
    CB_TEMPLATE_SALARY_VALUE: 'cb_template_salary_value',
    CB_SALARY_STATUS: 'cb_salary_status',
    CB_SALARY_CALCULATE_TYPE: 'cb_salary_calculate_type',

    // KPI
    KPI_TYPE: 'kpi_type',
    KPI_SCORE_EMP_TYPE: 'kpi_score_emp_type',
    // system
    SYS_EHRTRACE_OP: 'sys_ehrtrace_op',

    // employ
    employeeStatuses: [
        { value: '试用', label: '试用' },
        { value: '正式', label: '正式' },
        // { value: '员工', label: '员工' },
        { value: '离退休', label: '离退休' },
        { value: '离职', label: '离职' },
        { value: '离岗', label: '离岗' },
        { value: '黑名单', label: '黑名单' },
    ],
    empOnWorkStatuses: [
        { value: '试用', label: '试用' },
        { value: '正式', label: '正式' },
    ],
    // 考勤类型
    AttendanceTypes: [
        { value: 1, label: '固定制' },
        { value: 2, label: '排班制' },
        // { value: 3, label: '自由制' },
    ],
    AttStatisticType: [
        { value: '未完成', label: '未完成' },
        { value: '休息日', label: '休息日' },
        { value: '正常出勤', label: '正常出勤' },
        { value: '打卡异常', label: '打卡异常' },
        { value: '旷工', label: '旷工' },
    ],
    AttConfirmStatus:[
        { value: '未确认', label: '未确认' },
        { value: '已确认', label: '已确认' },
        // { value: '已审批', label: '已审批' },
        // { value: '已发放', label: '已发放' },
    ],

    ATT_ITEMS: [{attName:"调休结算"},{attName:"迟到"},{attName:"早退"},{attName:"旷工"},{attName:"年假"}
        ,{attName:"往年结余年假"},{attName:"事假"},{attName:"病假"},{attName:"婚假"},{attName:"产假"},{attName:"陪产假"},{attName:"其他"}],

    sysEhrtraceOp:[
        { value: 'INSERT', label: '新增' },
        { value: 'UPDATE', label: '修改' },
        { value: 'DELETE', label: '删除' },
    ],
}

export function getDictLabelFromData(dictDatas, value) {
    // console.log(value);
    // 获取 dictType 对应的数据字典数组
    if (!dictDatas || dictDatas.length === 0) {
        return ''
    }
    // 获取 value 对应的展示名
    // value = value + '' // 强制转换成字符串，因为 DictData 小类数值，是字符串
    for (const dictData of dictDatas) {
        if (dictData.value === value) {
            return dictData.label
        }
    }
    return ''
}

/**
 * 获取 dictType 对应的数据字典数组
 *
 * @param dictType 数据类型
 * @returns {*|Array} 数据字典数组
 */
export function getDictDatas(dictType) {
    if(dictType == DICT_TYPE.BPM_MODEL_CATEGORY){
        return [{label:'ehr', value:'ehr'}];
    }else if(dictType == DICT_TYPE.COM_YES_NO){
        return [{label:'是', value: 1 },{label:'否', value: 0 }];
    }else if(dictType == DICT_TYPE.COM_APPROVE_NO){
        return [{label:'同意', value: 1 },{label:'不同意', value: 0 },{label:'不用审批', value: 2 }];
    }else if(dictType == DICT_TYPE.BPM_TRANSFER_BATCH_EXE){
        return [{label:'未执行', value: 0 },{label:'已调入', value: 1 },{label:'已调回', value: 2 }];
    }else if(dictType == DICT_TYPE.BPM_DEPART_TYPE){
        return [{label:'自我原因离职', value: DICT_TYPE.BPM_DEPART_TYPE_SELF },
            {label:'辞退', value: 2 },{label:'其它', value: 10 }];
    }else if(dictType == DICT_TYPE.BPM_LEAVE_TYPE){
        return [{label:'年假', value: '年假' }, {label:'往年结余年假', value: '往年结余年假' }, {label:'事假', value: '事假' },{label:'病假', value: '病假' },
            {label:'调休假', value: '调休假' }, {label:'婚假', value: '婚假' },{label:'产假', value: '产假' },
            {label:'陪产假', value: '陪产假' }, {label:'其他', value: '其他' }];
    }else if(dictType == DICT_TYPE.BPM_OVERTIME_TYPE){
        return [{label:'工作日', value: '工作日' }, {label:'休息日', value: '休息日' },{label:'法定节假日', value: '法定节假日' }];
    }else if(dictType == DICT_TYPE.BPM_CORRECT_TIME_CLOCK_TYPE){
        return [{label:'打卡故障', value: '打卡故障' }, {label:'外出', value: '外出' },{label:'其他', value: '其他' }];
    }else if(dictType == DICT_TYPE.BPM_BUSINESS_TRIP_TYPE){
        return [{label:'省内出差', value: '省内出差' }, {label:'省外出差', value: '省外出差' }, {label:'公务外出', value: '公务外出' },{label:'其他', value: '其他' }];
    }else if(dictType == DICT_TYPE.BPM_FLOW_TYPE){
        return [
            {label:'转正申请', value: 'Official' },
            {label:'离职申请', value: 'Depart' },
            {label:'离职结算申请', value: 'DepartEnd' },
            {label:'调动调岗', value: 'Transfer' },
            {label:'请假调休', value: 'Leave' },
            {label:'加班申请', value: 'Overtime' },
            {label:'出差申请', value: 'BusinessTrip' },
            {label:'补卡申请', value: 'CorrectTimeClock' },
            {label:'其他申请', value: 'Other' },
        ];
    }else if(dictType == DICT_TYPE.ATT_TYPE){
        return DICT_TYPE.AttendanceTypes;
    }else if(dictType == DICT_TYPE.ATT_STATISTIC_TYPE){
        return DICT_TYPE.AttStatisticType;
    }else if(dictType == DICT_TYPE.ATT_CONFIRM_STATUS){
        return DICT_TYPE.AttConfirmStatus;
    }else if(dictType == DICT_TYPE.ATT_CLOCK_TIME_TYPE){
        return [
            {label:'数据导入', value: '数据导入' },
            {label:'考勤机', value: '考勤机' },
            {label:'WIFI', value: 'WIFI' },
            {label:'手机打卡', value: '手机打卡' },
        ];
    }else if(dictType == DICT_TYPE.CB_TEMPLATE_TYPE){
        return [
            {label:'社保', value: '社保' },
            {label:'住房公积金', value: '住房公积金' },
        ];
    }else if(dictType == DICT_TYPE.CB_TEMPLATE_SALARY_TYPE){
        return [
            {label:DICT_TYPE.CB_TEMPLATE_SALARY_TYPE_SALARY, value: DICT_TYPE.CB_TEMPLATE_SALARY_TYPE_SALARY },
            {label:DICT_TYPE.CB_TEMPLATE_SALARY_TYPE_FLOAT, value: DICT_TYPE.CB_TEMPLATE_SALARY_TYPE_FLOAT },
            {label:DICT_TYPE.CB_TEMPLATE_SALARY_TYPE_EXTRA, value: DICT_TYPE.CB_TEMPLATE_SALARY_TYPE_EXTRA },
        ];
    }else if(dictType == DICT_TYPE.CB_TEMPLATE_SALARY_PLUSMINUS){
        return [
            {label:'增项', value: '增项' },
            {label:'减项', value: '减项' },
        ];
    }else if(dictType == DICT_TYPE.CB_TEMPLATE_SALARY_VALUE){
        return [
            {label:'设置值', value: '设置值' },
            {label:'系统计算值', value: '系统计算值' },
            // {label:'引用值', value: '引用值' },
        ];
    }else if(dictType == DICT_TYPE.CB_SALARY_STATUS){
        return [
            {label:'考勤确认中', value: '考勤确认中' },
            {label:'计算中', value: '计算中' },
            {label:'审核中', value: '审核中' },
            {label:'发放中', value: '发放中' },
            {label:'已发放', value: '已发放' },
        ];
    }else if(dictType == DICT_TYPE.CB_SALARY_CALCULATE_TYPE){
        return [
            {label:'系统计算', value: '系统计算' },
            {label:'人工设置', value: '人工设置' },
            // {label:'引用值', value: '引用值' },
        ];
    }else if(dictType == DICT_TYPE.KPI_TYPE){
        return [
            {label:'月度考核', value: '月度考核算' },
            {label:'季度考核', value: '季度考核' },
            {label:'年度考核', value: '年度考核' },
            {label:'其他考核', value: '其他考核' },
        ];
    }else if(dictType == DICT_TYPE.KPI_SCORE_EMP_TYPE){
        return [
            {label:'自评', value: '自评' },
            {label:'本部门领导', value: '本部门领导' },
            {label:'上级部门领导', value: '上级部门领导' },
            {label:'指定评分人', value: '指定评分人' },
        ];
    }else if(dictType == DICT_TYPE.SYS_EHRTRACE_OP){
        return DICT_TYPE.sysEhrtraceOp;
    }else{
        return [];
    }
    // return store.getters.dict_datas[dictType] || []
}

export function getDictDataLabel(dictType, value) {
    // console.log(value);
    // 获取 dictType 对应的数据字典数组
    const dictDatas = getDictDatas(dictType)
    if (!dictDatas || dictDatas.length === 0) {
        return ''
    }
    // 获取 value 对应的展示名
    // value = value + '' // 强制转换成字符串，因为 DictData 小类数值，是字符串
    for (const dictData of dictDatas) {
        if (dictData.value === value) {
            return dictData.label
        }
    }
    return ''
}
