<template>
  <div class="tableContent">
		<div id="printMe">
			<div class="title">员 工 离 职 申 请 及 结 算 表</div>
			<div>
				<table class="table">
					<tr>
						<!-- 6 col -->
						<td class="secondCol">姓&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;名</td>
						<td class="thirdCol">{{form.name}}</td>
						<td class="fourCol">公&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;司</td>
						<td class="fiveCol">{{companyName}}</td>
							<td class="fourCol">部&nbsp;门&nbsp;/&nbsp;岗&nbsp;位</td>
						<td class="fiveCol">{{form.departmentName+" / "+form.positionName}}</td>
					</tr>
          <tr>
            <!-- 6 col -->
            <td class="secondCol">证&nbsp;件&nbsp;类&nbsp;型&nbsp;</td>
            <td class="thirdCol">{{employ.idType}}</td>
            <td class="fourCol">证&nbsp;件&nbsp;类&nbsp;型&nbsp;号&nbsp;码</td>
            <td class="fiveCol">{{employ.idNum}}</td>
            <td class="fourCol">入&nbsp;职&nbsp;日&nbsp;期</td>
            <td class="fiveCol">{{form.joinCompanyDate}}</td>
          </tr>
          <tr>
            <!-- 6 col -->
            <td class="secondCol">离&nbsp;职&nbsp;类&nbsp;型&nbsp;</td>
            <td class="thirdCol">{{getDictDataLabel(DICT_TYPE.BPM_DEPART_TYPE,form.type)}}</td>
            <td class="fourCol">离&nbsp;职&nbsp;日&nbsp;期&nbsp;</td>
            <td class="fiveCol">{{form.realDate}}</td>
            <td class="fourCol">联&nbsp;系&nbsp;电&nbsp;话</td>
            <td class="fiveCol">{{user.mobile}}</td>
          </tr>
					<tr>
						<td >离职原因</td>
						<td colspan="5">{{form.departReason}}</td>
					</tr>
          <tr>
            <td >离职交接</td>
            <td colspan="5" style="padding:0px;margin:0px;">
              <table style="width: 100%;border-collapse:collapse;" frame="void">
                <tr>
                  <th>物品交接</th>
                  <th>折价说明</th>
                  <th>折价金额</th>
                  <th>接收人</th>
                </tr>
                <tr v-for="goods in form.returnGoods" :key="goods.title+goods.description">
                  <td>{{ goods.title }}</td>
                  <td>{{ goods.description }}</td>
                  <td>{{ goods.depreciate }}</td>
                  <td>{{ goods.receiverName }}</td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td >当月考勤</td>
            <td colspan="5">
              <div>该员工当月考勤截止日期：{{form.attendenceInfo.attEndDate}} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;，出勤：  {{form.attendenceInfo.attDays}} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;天，加班：  {{form.attendenceInfo.otDays}} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;天。</div>
              <div>其它情况说明： {{form.attendenceInfo.others?form.attendenceInfo.others:"无"}}&nbsp;&nbsp;&nbsp;&nbsp;</div>
            </td>
          </tr>
          <tr>
            <td >财务结算</td>
            <td colspan="2">
              <div>结算金额：人民币 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{(form.salary?'￥'+form.salary:'')}}元，结算方式：  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{form.salaryType?form.salaryType:""}}</div>
            </td>
            <td colspan="3">
              <div>&nbsp;&nbsp;本人对离职、薪酬结算没有异议，情况属实。</div>
              <div>&nbsp;</div>
              <div>签名（按手印）：&nbsp;&nbsp;&nbsp;</div>
            </td>
          </tr>

					<tr>
						<td colspan="6" >审批进度</td>
					</tr>
					<tr style="border-top:hidden;" v-for="(item,index) in handleTask.historyTask" :key="index">
            <td v-if="item.status" colspan="6">{{ stepDes(item, handleTask) }} </td>
						<td v-else colspan="6">{{item.stepName}} (进行中)</td>
					</tr>
          <tr>
            <td colspan="6" >
              <div style="text-align: center"><h2><b>终止合同事项告知</b></h2></div>
              <br>
              <div>
                <div>
                乙方（姓名： {{form.name}}  ；身份证号： {{employ.idNum}}  ）由于{{getDictDataLabel(DICT_TYPE.BPM_DEPART_TYPE,form.type)}}于{{form.applyDepartDate}}向甲方（{{ companyName }} ）提出正常离职申请，
                  甲方于 {{form.approveDepartDate}}批准乙方的离职申请，经双方确认，自 {{form.realDate}}起，甲乙双方签订的劳动/劳务合同以及相应的权利义务随之终止。现甲方就终止劳动/劳务合同关系告知乙方如下：
                </div>
                <ul>
                  <li>1、乙方薪酬结算至离职之日，需在批准离职之日起3个工作日内办结离职手续。乙方同意甲方在次月15日左右的发放结算薪酬日期以银行转账或现金支付给乙方离职薪酬；</li>
                  <li>2、甲方依据相关法律法规，与乙方已办结相关离职手续并终止所有劳动/劳务关系，双方均无任何异议；</li>
                  <li>3、乙方确认与甲方无劳动争议，乙方承诺不再以曾有的劳动/劳务为由向甲方要求任何经济补偿，且离职后个人行为与甲方无关；</li>
                  <li>4、 乙方对上述告知事项已知情并理解，对乙方在此表上的签字和按捺指纹均是本人的真实意愿表示。</li>
                </ul>
                <div></div>
                <br>
                <div>&nbsp;&nbsp;&nbsp;乙方（签字/手印）：</div>
                <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;年&nbsp;&nbsp;&nbsp;&nbsp;月&nbsp;&nbsp;&nbsp;&nbsp;日 </div>
              </div>
            </td>
          </tr>

<!--					<tr>-->
<!--						<td colspan="3" style="border-right:hidden;border-bottom:hidden" ></td>-->
<!--						<td colspan="3" style="border-bottom:hidden" >申请人：</td>-->

<!--					</tr>-->
<!--          		<tr>-->
<!--					<td colspan="3" style="border-right:hidden;"  ></td>-->
<!--					<td colspan="3" >日期：</td>-->
<!--					</tr>-->
				</table>
			</div>

		</div>
			<div style="text-align:center;" >
				<el-button type="success" plain icon="el-icon-printer" size="mini" v-print="printObj">打印</el-button>
        		<el-button style="margin-top: 10px" type="primary" size="mini"  plain @click="cancel">关 闭</el-button>
			</div>
	</div>
</template>

<script>
import permission from '@/directive/permission/index.js'; // 权限判断指令
import { getDepartEnd} from "@/api/bpm/departEnd.js";
import {getCompany} from "@/api/org/company.js"
import {getUser} from "@/api/sys/user.js"
import {getEmploy} from "@/api/employ/employ.js"
import {taskSteps} from "@/api/bpm/todoList.js";
import {DICT_TYPE,getDictLabelFromData, getDictDataLabel, getDictDatas} from "@/utils/dict";
import {getStepActive, getStepDes, getStepTitle} from "@/views/pages/bpm/bpmUtils";

export default {
  name: "departEndPrinting",
  props: {
    id: String,
    processInstanceId: String,
	rowData:Object
  },
  directives: { permission },
  computed:{
    stepDes() {
      return getStepDes;
    }
  },
  data(){

    return {
      // 表单参数
      form: this.rowData,
      companyName:"Not found",
      employ: {},
      user: {},
      handleTask:{
        historyTask: []
      },
      printObj: {
				id: 'printMe',
				popTitle: ' ',
				extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>',
			},
    };
  },
  created() {
    this.load();
  },
  methods: {
    load() {
          //this.form = this.row;
      getUser(this.form.userId).then(response => {
        this.user = response.data;
      });
      getEmploy(this.form.employId).then(response => {
        this.employ = response.data;
      });
      getCompany(this.form.companyId).then(response => {
        this.companyName = response.data.companyName;
      });
      taskSteps(this.form.processInstanceId).then(response => {
        this.handleTask = response.data;
      });
    },
    cancel() {
			this.$emit('close', { refresh: false, isSuccess: true });
		},

  },


}

</script>

<style scoped>
.table {
	width: 100%;
	border-style: solid;
	border-collapse: collapse;
	overflow-y: scroll;
	border: 1;

}
.tableContent {
	width: 900px;
	margin: auto;
	margin-top: 20px;
}

.firstCol{
	width: 2%;
}
.secondCol{
	width:15%;
}
.thirdCol{
	width:25%;
}
.fourCol{
	width:10%;
}
.fiveCol{
	width:25%;
}
.sexCol{
	width:23%;
}

.tableList table,
table tr th,
table tr td {
	border: 1px solid rgb(12, 12, 12);
  padding: 15px;
}

.title {
	font-size: 30px;
	width: 100%;
	text-align: center;
	margin-bottom: 10px;
}

</style>
