<template>
  <div class="app-container">
    <el-tabs v-model="activeName">
      <el-tab-pane label="短信设置" name="sms">
        <SmsSetting></SmsSetting>
      </el-tab-pane>
<!--      <el-tab-pane label="巡逻记录" name="records">-->
<!--        <AttPatrolClock></AttPatrolClock>-->
<!--      </el-tab-pane>-->
    </el-tabs>
  </div>
</template>

<script>
import SmsSetting from "./smsSetting";


export default {
  name: "index",
  components: {SmsSetting},
  data() {
    return {
      activeName: 'sms',
    }
  },
}
</script>

<style scoped>

</style>
