<template>
  <div class="app-container">
    <el-row>
      <el-col>
        <template v-if="isCompanyVisible">
          请先选择需要查询统计的公司部门：
            <treeselect v-model="queryParams.companyId" :options="treeData" :multiple="true" value-consists-of="ALL"
                        :normalizer="normalizerCompany" @select="selectCompany" @input="companyIdInput"
                        @deselect="deselectCompany" placeholder="请选择公司" :limit="5" :limitText="count => `及其它${count}部门`"
                        />
        </template>
      </el-col>
    </el-row>
<!--    <el-divider></el-divider>-->
    <el-tabs type="border-card">
      <el-tab-pane label="查询统计">
        <div v-if="queryType == 'normal'">
        <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="80px" label-position="left">
<!--          <el-form-item label="部门" prop="departmentId">-->
<!--            &lt;!&ndash;            <el-select v-model="queryParams.departmentId" multiple placeholder="请选择部门" clearable>&ndash;&gt;-->
<!--            &lt;!&ndash;              <el-option&ndash;&gt;-->
<!--            &lt;!&ndash;                  v-for="dict in departmentList"&ndash;&gt;-->
<!--            &lt;!&ndash;                  :key="dict.id"&ndash;&gt;-->
<!--            &lt;!&ndash;                  :label="dict.name+'('+dict.companyId+')'"&ndash;&gt;-->
<!--            &lt;!&ndash;                  :value="dict.id"&ndash;&gt;-->
<!--            &lt;!&ndash;              ></el-option>&ndash;&gt;-->
<!--            &lt;!&ndash;            </el-select>&ndash;&gt;-->
<!--            <treeselect style="width: 358px" v-model="queryParams.departmentId" :options="departmentOptions"-->
<!--                        :normalizer="normalizerDepartment" :multiple="true" value-consists-of="ALL" placeholder="请选择部门"/>-->
<!--          </el-form-item>-->
          <el-form-item label="岗位" prop="positionId">
            <el-select v-model="queryParams.positionId" multiple placeholder="请选择岗位" clearable>
              <el-option
                  v-for="dict in positionList"
                  :key="dict.id"
                  :label="dict.name+'('+dict.companyId+')'"
                  :value="dict.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="员工状态" prop="empStatus">
            <el-select v-model="queryParams.empStatus" multiple placeholder="请选择员工状态" clearable>
              <el-option v-for="dict in employeeStatuses" :key="dict.value" :label="dict.label" :value="dict.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="工作证号" prop="worknum">
            <el-input
                v-model="queryParams.workNum"
                placeholder="请输入工作证号"
                clearable
                size="small"
                @keyup.enter.native="handleQuery"
            />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
            <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
            <el-button v-if="queryType == 'normal'" type="primary" icon="el-icon-d-arrow-right" size="mini" @click="queryType='dynamic'">高级查询</el-button>
          </el-form-item>
        </el-form>
        </div>
        <div v-if="queryType == 'dynamic'">
          <DynamicQuery v-show="showSearch" :fieldsIn="dynamicFields" :orderFieldsIn="orderFieldsIn" @queryDynamic="handleQueryDynamic" @queryTypeToNormal="queryType='normal'"></DynamicQuery>
    <!--      <el-row></el-row>-->
        </div>
        <el-row :gutter="10" class="mb8">
          <el-col :span="1.5">
            <el-button
                type="warning"
                plain
                icon="el-icon-download"
                size="mini"
                @click="handleExport"
                v-permission="['/employ/employ/export']"
            >导出
            </el-button>
          </el-col>
          <right-toolbar v-if="queryType == 'normal'" :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>
          <right-toolbar v-if="queryType == 'dynamic'" :showSearch.sync="showSearch" @queryTable="queryDynamic"></right-toolbar>
        </el-row>

    <!--    <el-table v-loading="loading" :data="employList" @selection-change="handleSelectionChange" stripe border style="width:100%">-->
        <el-table v-loading="loading" :data="employList" stripe border style="width:100%">
          <el-table-column label="姓名" align="center" prop="name" sortable width="auto" min-width="150" :show-overflow-tooltip='true'/>
          <!--          <el-table-column label="档案编号" align="center" prop="num"/>-->
          <el-table-column label="工作证号" align="center" prop="workNum" sortable width="auto" min-width="150" :show-overflow-tooltip='true'/>
          <el-table-column label="部门" align="center" prop="departmentId" sortable width="auto" min-width="180"  :show-overflow-tooltip='true'>
            <template slot-scope="scope">
              <el-tag type="info" :disable-transitions="false">
                {{ departmentFormat(scope.row, null) }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column label="岗位" align="center" prop="positionId" sortable width="auto" min-width="150" :show-overflow-tooltip='true' >
            <template slot-scope="scope">
              <el-tag type="info" :disable-transitions="false">
                {{ positionFormat(scope.row, null) }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column label="员工状态" align="center" prop="empStatus" sortable width="auto" min-width="150"  :show-overflow-tooltip='true'/>
          <el-table-column label="员工类型" align="center" prop="empType" sortable width="auto" min-width="150"  :show-overflow-tooltip='true'/>
          <el-table-column label="用工形式" align="center" prop="empWorkType" sortable width="auto" min-width="150"  :show-overflow-tooltip='true'/>

          <el-table-column label="证件类型" align="center" prop="idType" sortable width="auto" min-width="100"  :show-overflow-tooltip='true'/>
          <el-table-column label="身份证号" align="center" prop="idNum" sortable width="auto" min-width="180"  :show-overflow-tooltip='true'/>
          <el-table-column label="性别" align="center" prop="sex" sortable width="auto" min-width="100"  :show-overflow-tooltip='true'/>
          <el-table-column label="民族" align="center" prop="nation" sortable width="auto" min-width="100" />
          <el-table-column label="曾用名" align="center" prop="formerName" sortable width="auto" min-width="120"  :show-overflow-tooltip='true'/>
          <!--          <el-table-column label="员工证相片" align="center" prop="photo"/>-->
          <el-table-column label="出生年月" align="center" prop="idNridthDay" sortable width="auto" min-width="180"  :show-overflow-tooltip='true'>
            <template slot-scope="scope">
              <span>{{ parseTime(scope.row.idNridthDay, '{y}-{m}-{d}') }}</span>
            </template>
          </el-table-column>
          <el-table-column label="籍贯" align="center" prop="nativePlace" sortable width="auto" min-width="100"  :show-overflow-tooltip='true'/>
          <el-table-column label="婚姻状况" align="center" prop="marritalStatus" sortable width="auto" min-width="100"  :show-overflow-tooltip='true'/>
          <el-table-column label="健康状况" align="center" prop="helth" sortable width="auto" min-width="100"  :show-overflow-tooltip='true'/>
          <el-table-column label="出生地" align="center" prop="birthAddr" sortable width="auto" min-width="180"  :show-overflow-tooltip='true'/>
          <!--          <el-table-column label="显示顺序" align="center" prop="seq"/>-->
          <el-table-column label="首聘时间" align="center" prop="curPositionStartDate" sortable width="auto" min-width="180"  :show-overflow-tooltip='true'>
            <template slot-scope="scope">
              <span>{{ parseTime(scope.row.curPositionStartDate, '{y}-{m}-{d}') }}</span>
            </template>
          </el-table-column>
          <el-table-column label="参加工作时间" align="center" prop="workStartDate" sortable width="auto" min-width="180"  :show-overflow-tooltip='true' >
            <template slot-scope="scope">
              <span>{{ parseTime(scope.row.workStartDate, '{y}-{m}-{d}') }}</span>
            </template>
          </el-table-column>
          <el-table-column label="入职日期" align="center" prop="joinCompanyDate" sortable width="auto" min-width="180"  :show-overflow-tooltip='true' >
            <template slot-scope="scope">
              <span>{{ parseTime(scope.row.joinCompanyDate, '{y}-{m}-{d}') }}</span>
            </template>
          </el-table-column>
          <el-table-column label="离职日期" align="center" prop="leaveDate" sortable width="auto" min-width="180"  :show-overflow-tooltip='true' >
            <template slot-scope="scope">
              <span>{{ parseTime(scope.row.leaveDate, '{y}-{m}-{d}') }}</span>
            </template>
          </el-table-column>
          <el-table-column label="休假工龄起始时间" align="center" prop="paidHolidayStartDate" sortable width="auto" min-width="180"  :show-overflow-tooltip='true'>
            <template slot-scope="scope">
              <span>{{ parseTime(scope.row.paidHolidayStartDate, '{y}-{m}-{d}') }}</span>
            </template>
          </el-table-column>
          <el-table-column label="办公电话" align="center" prop="workPhone" sortable width="auto" min-width="180"  :show-overflow-tooltip='true'/>
          <el-table-column label="住宅电话" align="center" prop="homePhone" sortable width="auto" min-width="180"  :show-overflow-tooltip='true'/>
          <el-table-column label="紧急联系人" align="center" prop="urgentContact" sortable width="auto" min-width="200"  :show-overflow-tooltip='true'>
            <template slot-scope="scope">
              <el-tooltip placement="top">
                <div slot="content">
                      <span v-if="scope.row.urgentContact">
                        电话号码：{{ scope.row.urgentContact.phone }}<br/>
                        手机号：{{ scope.row.urgentContact.mobile }}<br/>
                        地址：{{ scope.row.urgentContact.addr }}<br/>
                      </span>
                </div>
                <span v-if="scope.row.urgentContact"><el-tag>{{ scope.row.urgentContact.name }}</el-tag></span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="现住地址" align="center" prop="currentAddr" sortable width="auto" min-width="180"  :show-overflow-tooltip='true' />
          <el-table-column label="户口地" align="center" prop="householdAddr" sortable width="auto" min-width="100"  :show-overflow-tooltip='true'/>
          <el-table-column label="集体户口" align="center" prop="householdType" sortable width="auto" min-width="100"  :show-overflow-tooltip='true'/>
        </el-table>

        <pagination v-if="queryType == 'normal'"
            v-show="total>0"
            :total="total"
            :page.sync="queryParams.pageNum"
            :limit.sync="queryParams.pageSize"
            @pagination="getList"
        />
        <pagination v-if="queryType == 'dynamic'"
            v-show="total>0"
            :total="total"
            :page.sync="queryParams.pageNum"
            :limit.sync="queryParams.pageSize"
            @pagination="queryDynamic"
        />
      </el-tab-pane>
      <el-tab-pane label="入职">
        <EntryStatistic :companyIds="getClassIds(queryParams.companyId,'company')" :departmentList="departmentList"></EntryStatistic>
      </el-tab-pane>
      <el-tab-pane label="离职">
        <DepartStatistic :companyIds="getClassIds(queryParams.companyId,'company')" :departmentList="departmentList"></DepartStatistic>
      </el-tab-pane>
    </el-tabs>

  </div>
</template>

<script>
import {
  listEmploy,
  exportEmploy
} from "@/api/employ/employ";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import RightToolbar from "@/components/RightToolbar"
import permission from '@/directive/permission/index.js' // 权限判断指令
import Pagination from "@/components/Pagination";
import {getTreeselectCompany} from "@/api/org/company";
import {getTreeselectDepartment} from "@/api/org/department";
import EmployEdit from "./employEdit"
import EmployAdjust from "./employAdjust";
import {getTreeselectPosition} from '@/api/org/position';
import {showLoading, hideLoading} from "../../../../utils/reqloading";
import DynamicQuery from '@/views/fun/dynamicQuery/index';
import {FIELD_VALUE_TYPE, FUNC_SELECTS, getFuncSelect} from '@/views/fun/dynamicQuery/dynamicField';
import {queryDynamic} from "../../../../api/employ/employ";
import EntryStatistic from "./statistic/entryStatistic";
import DepartStatistic from "./statistic/departStatistic";
import {DICT_TYPE,getDictLabelFromData, getDictDataLabel, getDictDatas} from "@/utils/dict";
import {getOrgTree} from "../../org/components/orgtree";
import {sumTreeChild, getQueryParam} from "@/utils/tools";

// 表查询配置
const dbOrderBy = ''

export default {
  name: "Employ",
  components: {
    EntryStatistic,
    Treeselect,
    RightToolbar,
    Pagination,
    EmployEdit,
    DynamicQuery,
    DepartStatistic,
  },
  directives: {permission},
  data() {
    return {
      treeData: [],
      isCompanyVisible: true,
      queryType:'normal',
      row: null,
      opType: 'hr',
      // 是否显示弹出层
      open: false,
      openMove: false,
      // 弹出层标题
      title: "",
      // 遮罩层
      loading: true,
      // 查询参数
      where: {
        query: '',
        filters: []
      },
      dynamicWhere:"",
      companyId: 1,
      companyList: [],
      // 部门管理树选项
      departmentList: [],
      departmentOptions: [],
      positionList: [],
      positionOptions: [],
      // 公司树选项
      companyOptions: [],
      //
      postLevelOptions: [],
      defaultProps: {
        children: "children",
        label: "name"
      },
      departmentName: undefined,

      // 可以编辑的
      employReadOnly: false,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 员工信息表格数据
      employList: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        orderby: dbOrderBy,
        positionId: null,
        departmentId: null,
        empStatus: null,
        workNum: null,
        idNum: null,
        companyId: [],
      },
      queryEntry:{
        pageNum: 1,
        pageSize: 10,
        orderby: dbOrderBy,
        departmentId: null,
        companyId: [],
      },
      employAdjustChangeId: 1,
      employeeStatuses: DICT_TYPE.employeeStatuses,
      dynamicFields: [
        {label: "岗位", value: "positionId", funcList:getFuncSelect(FUNC_SELECTS.TYPE_SELECT), type: FIELD_VALUE_TYPE.SELECT, options: []},
        // {label: "部门", value: "departmentId", funcList:getFuncSelect(FUNC_SELECTS.TYPE_SELECT), type: FIELD_VALUE_TYPE.SELECT, options: []},
        {label: "工作证号", value: "workNum", funcList:getFuncSelect(FUNC_SELECTS.TYPE_INPUT_STRING), type: FIELD_VALUE_TYPE.INPUT},
        {label: "入职日期", value: "joinCompanyDate", funcList:getFuncSelect(FUNC_SELECTS.TYPE_INPUT_DATE), type: FIELD_VALUE_TYPE.DATE},
        {label: "离职日期", value: "leaveDate", funcList:getFuncSelect(FUNC_SELECTS.TYPE_INPUT_DATE), type: FIELD_VALUE_TYPE.DATE},
        {label: "学历", value: "JSON_EXTRACT(educations, '$[*].education')", funcList:getFuncSelect(FUNC_SELECTS.TYPE_INPUT_JSON_STRING), type: FIELD_VALUE_TYPE.INPUT},
        {label: "证书名称", value: "certificates->'$[*].name'", funcList:getFuncSelect(FUNC_SELECTS.TYPE_INPUT_JSON_STRING), type: FIELD_VALUE_TYPE.INPUT},
      ],
      orderFieldsIn:[
        {label: "岗位", value: "positionId"},
        // {label: "部门", value: "departmentId"},
        {label: "工作证号", value: "workNum"},
        {label: "入职日期", value: "joinCompanyDate"},
        {label: "离职日期", value: "leaveDate"},
      ]
    };
  },
  watch: {

  },

  created() {
    this._filter = {};
    this.companyId = this.$store.getters.userInfo.companyId;
    // this.queryParams.companyId = ["company"+this.companyId];
    // this.getDicts("org_position_level").then(response => {
    //   this.postLevelOptions = response.data;
    // });

    // getTreeselectCompany(null, (list, options) =>{
    //   this.companyList = list;
    //   if(this.companyList.length > 1){
    //     this.isCompanyVisible = true;
    //   }
    //   this.companyOptions = options;
    //   // this.getDepartment(this.companyId);
    // });
    getOrgTree((tree, companyList, departmentList, positionList )=>{
      console.log("return tree:"+tree);
      console.log(tree);
      this.companyList = companyList;
      // if(this.$store.getters.userInfo.companyId == PARENT_COMPANY_ID){
      sumTreeChild(tree,'id','pid');
      this.treeData = tree;

      this.allDepartmentOptions = [];
      const dataDepartment = { id:0,
        name:'顶级部门',
        children: []
      };
      dataDepartment.children = this.handleTree(departmentList, "id", "pid");
      this.allDepartmentOptions.push(dataDepartment);

      this.companyOptions = [];
      this.companyOptions = this.handleTree(companyList, "id", "pid");

      this.positionList = positionList;
      this.departmentList = departmentList;
      this.getList();
    });

    // this.getDepartment(this.companyId);
    this.getPosition(null);
    this.getList();
  },
  methods: {
    /** 查询员工信息列表 */
    getQuery() {
      this._filter = {};
      if (this.queryParams.departmentId) {
        this._filter['departmentId'] = {
          type: 'select',
          value: this.queryParams.departmentId,
        }
      }
      if (this.queryParams.positionId) {
        this._filter['positionId'] = {
          type: 'select',
          value: this.queryParams.positionId,
        }
      }
      if (this.queryParams.empStatus) {
        this._filter['empStatus'] = {
          type: 'select',
          value: this.queryParams.empStatus,
        }
      }
      if (this.queryParams.workNum) {
        this._filter['workNum'] = {
          type: 'search',
          value: this.queryParams.workNum,
        }
      }
      if (this.queryParams.idNum) {
        this._filter['idNum'] = {
          type: 'search',
          value: this.queryParams.idNum,
        }
      }
      // console.log(this.queryParams.companyId)
      // if (this.queryParams.companyId) {
      //   console.log("aaa")
      //   this._filter['companyId'] = {
      //     type: 'select',
      //     value: this.queryParams.companyId,
      //   }
      //   console.log(this._filter);
      // }
      const filters = this.parseFilters(this._filter)
      if (filters && filters.length > 0) {
        this.where.filters = filters
      } else {
        // 如果过滤条件为空，设置空数组
        this.where.filters = []
      }
      const params = {
        pageNum: this.queryParams.pageNum,
        pageSize: this.queryParams.pageSize,
        where: this.where,
        order: this.orderby
      }
      console.log("params")
      console.log(params)
      return params;
    },
    getList() {
      this.loading = true;
      const params = this.getQuery();
      listEmploy(params).then(response => {
        this.employList = response.data.records;
        this.total = response.data.total;
        this.loading = false;
      });
    },
    queryDynamic(){
      console.log("this.dynamicWhere");
      // console.log(this.queryParams.companyId);
      // if(this.queryParams.companyId && this.queryParams.companyId.length>0){
      //   this.dynamicWhere = " companyId in ( " + this.queryParams.companyId.join(",") + " ) and "+ this.dynamicWhere;
      // }
      console.log(this.queryParams.departmentId);
      if(this.queryParams.departmentId && this.queryParams.departmentId.length>0){
        this.dynamicWhere = " departmentId in ( " + this.queryParams.departmentId.join(",") + " ) and "+ this.dynamicWhere;
      }      console.log(this.dynamicWhere);
      this.loading = true;
      const params = {
        pageNum: this.queryParams.pageNum,
        pageSize: this.queryParams.pageSize,
        dynamic: this.dynamicWhere,
        order: this.orderby,
      }
      queryDynamic(params).then(response => {
        this.employList = response.data.records;
        this.total = response.data.total;
        this.loading = false;
      });
    },
    handleQueryDynamic(query){
      console.log(query);
      this.queryParams.pageNum = 1;
      this.dynamicWhere = query.dynamicWhere
      this.orderby = query.orderBy;
      this.queryDynamic(query);
    },
    companyIdInput(value, instanceId){
      console.log("companyIdInput");
      console.log(value);
      console.log(instanceId);
      console.log(this.queryParams.companyId);
      // const companyIds = this.getClassIds(value,"company");
      this.queryParams.positionId = null;
      this.queryParams.departmentId = this.getClassIds(value,"department");
      console.log(this.queryParams.departmentId);
      // this.queryParams.positionId = null;
      // this.$refs["selectPosTree"].clear();
      // this.getDepartment(value);
      // this.getPosition(value);
      console.log("companyIdInput");
    },
    getClassIds(value, className){
      if(value && value.length>0){
        let ids = [];
        value.forEach( id => {
          if(id.startsWith(className)){
            const idstr = id.substring(className.length);
            console.log(idstr)
            ids.push(idstr);
          }
        })
        console.log("getClassIds , ids=");
        console.log(ids);
        return ids;
      }else{
        return [];
      }
    },
    deselectCompany(node, instanceId) {
      console.log("deselectCompany");
      console.log(node.id);
      console.log(instanceId);
    },
    selectCompany(node, instanceId) {
    },
    /** 转换公司数据结构 */
    normalizerCompany(node) {
      // if (node['id'] && !node['id'].length) {
      //   delete node['children'];
      // }
      if(node.className=='company'){
        return {
          id: node['treeId'],
          label: node['id'] + " - " +node['companyName'],
          children: node['children']
        };
      }else if(node.className=='department'){
        return {
          id: node['treeId'],
          label: node['name'],
          children: node['children']
        };
      }
      // return this.normalizer(node,'id','companyName');
    },
    /** 转换部门数据结构 */
    normalizerDepartment(node) {
      return this.normalizer(node);
    },

    /** 查询公司下拉树结构 */
    getDepartment(companyId) {
      getTreeselectDepartment(companyId, false, (list, options) =>{
        this.departmentList = list;
        for(let i in this.dynamicFields) {
          if (this.dynamicFields[i].value == "departmentId") {
            this.dynamicFields[i].options=[];
            for(let j in this.departmentList){
              this.dynamicFields[i].options.push({value: this.departmentList[j].id, label: this.departmentList[j].name+"("+this.departmentList[j].companyId+")"})
            }
            break;
          }
        }
        let companyName = "";
        this.companyList.forEach(item => {
          if (item.id == companyId) {
            companyName = item.companyName;
          }
        })
        const departTree = [{
          id: 0,
          name: companyName,
          children: []
        }];
        departTree[0].children = options;
        this.departmentOptions = departTree;
      });
    },
    normalizerPosition(node) {
      return this.normalizer(node);
    },
    /** 查询岗位下拉树结构 */
    getPosition(companyId) {
      getTreeselectPosition(companyId, false, (list, options) =>{
        this.positionList = list;
        this.positionOptions = options;
        for(let i in this.dynamicFields) {
          if (this.dynamicFields[i].value == "positionId") {
            this.dynamicFields[i].options=[];
            for(let j in this.positionList){
              this.dynamicFields[i].options.push({value: this.positionList[j].id, label: this.positionList[j].name+"("+this.positionList[j].companyId+")"})
            }
            break;
          }
        }
      });
    },
    positionFormat(row, column) {
      console.log(row.positionId);
      for (var i = 0; i < this.positionList.length; i++) {
        const item = this.positionList[i];
        // console.log("id:"+item.id+",depid:"+row.departmentId+", name :"+item.name+",ee="+(item.id == row.departmentId));
        if (item.id == row.positionId) {
          return item.name;
        }
      }
      return "";
    },

    departmentFormat(row, column) {
      // console.log(row.departmentId);
      for (var i = 0; i < this.departmentList.length; i++) {
        const item = this.departmentList[i];
        // console.log("id:"+item.id+",depid:"+row.departmentId+", name :"+item.name+",ee="+(item.id == row.departmentId));
        if (item.id == row.departmentId) {
          return item.name;
        }
      }
      return "";
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    // handleSelectionChange(selection) {
    //   this.ids = selection.map(item => item.id)
    //   this.single = selection.length !== 1
    //   this.multiple = !selection.length
    // },

    /** 导出按钮操作 */
    handleExport() {
      const params = this.getQuery();
      this.$confirm('是否确认导出所有员工信息数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        showLoading();
        return exportEmploy(params);
      }).then(result=>{
        hideLoading();
        if(result){
          console.log("**********************************************");
          console.log(result);
          // this.loading = false;
          let blob = new Blob([result.data], {
            type: result.data.type
          });
          let filename = result.headers.filename && decodeURI(result.headers.filename) || '员工列表.xlsx';
          if ('download' in document.createElement('a')) {
            const link = document.createElement("a");
            link.style.display = "none";
            link.href = URL.createObjectURL(blob);
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else {
            navigator.msSaveBlob(blob, filename);
          }
        }
      }).catch(e=>{
        this.msgError(`后端下载文件出错 & ${e}`)
      });
    },


  }
};
</script>


<style scoped>

</style>
