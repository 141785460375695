<template>
<div>
  <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px" label-position="left">
    <el-form-item label="月份" prop="entryMonth" :rules="[{required: true ,message: '月份不能为空', trigger: 'blur' }]">
      <el-date-picker
          v-model="queryParams.entryMonth"
          type="month"
          value-format="yyyy-MM-dd"
          placeholder="选择月">
      </el-date-picker>
    </el-form-item>
<!--    <el-form-item label="部门" prop="departmentId">-->
<!--      <el-select v-model="queryParams.departmentId" multiple placeholder="请选择部门" clearable>-->
<!--        <el-option-->
<!--            v-for="dict in departmentList"-->
<!--            :key="dict.id"-->
<!--            :label="dict.name+'('+dict.companyId+')'"-->
<!--            :value="dict.id"-->
<!--        ></el-option>-->
<!--      </el-select>-->
<!--      &lt;!&ndash;        <treeselect v-model="queryParams.departmentId" :options="departmentOptions"&ndash;&gt;-->
<!--      &lt;!&ndash;                    :normalizer="normalizerCompany" :multiple="true" @select="selectCompany" placeholder="请选择部门"/>&ndash;&gt;-->
<!--    </el-form-item>-->
    <el-form-item>
      <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
      <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
    </el-form-item>
  </el-form>
  <el-row :gutter="10" class="mb8">
    <el-col :span="1.5">
      <el-button
          type="warning"
          plain
          icon="el-icon-download"
          size="mini"
          @click="showChart"
      >图表
      </el-button>
    </el-col>

<!--    <el-col :span="1.5">-->
<!--      <el-button-->
<!--          type="warning"-->
<!--          plain-->
<!--          icon="el-icon-download"-->
<!--          size="mini"-->
<!--          @click="handleExport"-->
<!--      >导出-->
<!--      </el-button>-->
<!--    </el-col>-->
    <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>
  </el-row>

  <!--    <el-table v-loading="loading" :data="employList" @selection-change="handleSelectionChange" stripe border style="width:100%">-->
  <el-table v-loading="loading" :data="employList" stripe border style="width:100%">
    <el-table-column label="公司" align="center" prop="companyName" sortable width="auto" min-width="150" :show-overflow-tooltip='true'/>
    <el-table-column label="部门" align="center" prop="departmentName" sortable width="auto" min-width="180"  :show-overflow-tooltip='true'/>
    <el-table-column label="人数" align="center" prop="ct" sortable width="auto" min-width="150"  :show-overflow-tooltip='true'/>
  </el-table>
<!--  <pagination v-if="queryType == 'normal'"-->
<!--              v-show="total>0"-->
<!--              :total="total"-->
<!--              :page.sync="queryParams.pageNum"-->
<!--              :limit.sync="queryParams.pageSize"-->
<!--              @pagination="getList"-->
<!--  />-->
  <el-dialog :title="title" :visible.sync="open" width="85%" append-to-body>
    <statisticChart title1="部门入职人员" title2="部门入职人员" :charData="employList" :companyIds="companyIds"></statisticChart>
  </el-dialog>

</div>
</template>

<script>
import {
  listEmploy,
  exportEmploy,
  statisticEntry,
} from "@/api/employ/employ";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import RightToolbar from "@/components/RightToolbar"
import Pagination from "@/components/Pagination";
import {getTreeselectCompany} from "@/api/org/company";
import {getTreeselectDepartment} from "@/api/org/department";
import {getTreeselectPosition} from '@/api/org/position';
import {showLoading, hideLoading} from "@/utils/reqloading";
import StatisticChart from "./statisticChart";
// 表查询配置
const dbOrderBy = ''

export default {
  name: "entryStatistic",
  components: {
    Treeselect,
    RightToolbar,
    Pagination,
    StatisticChart,
  },
  props: {
    companyIds: Array,
    departmentList: Array,
    departmentOptionsIn: Array,
    isReadOnly: Boolean,
    departmentId: Number,
  },

  data() {
    return {
      queryType:'normal',
      row: null,
      opType: 'hr',
      // 是否显示弹出层
      open: false,
      openMove: false,
      // 弹出层标题
      title: "",
      // 遮罩层
      loading: false,
      // 查询参数
      where: {
        query: '',
        filters: []
      },
      companyId: 1,
      // 部门管理树选项
      // departmentList: [],
      // departmentOptions: [],
      defaultProps: {
        children: "children",
        label: "name"
      },
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 员工信息表格数据
      employList: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        orderby: dbOrderBy,
        positionId: null,
        departmentId: null,
        entryMonth: null,
        empStatus: null,
        workNum: null,
        idNum: null,
        companyId: [],
      },
    };
  },
  watch: {

  },

  created() {
    this._filter = {};
    this.companyId = this.$store.getters.userInfo.companyId;
    this.queryParams.companyId = [this.companyId];
  },
  methods: {
    showChart(){
      if(this.employList.length <=0){
        // this.getList();
        this.msgError('请先查询数据');
        return;
      }
      this.open = true;
      this.title = "入职图表";

    },
    /** 查询员工信息列表 */
    getQuery() {
      this._filter = {};
      if (this.queryParams.departmentId) {
        this._filter['departmentId'] = {
          type: 'select',
          value: this.queryParams.departmentId,
        }
      }
      if (this.queryParams.positionId) {
        this._filter['positionId'] = {
          type: 'select',
          value: this.queryParams.positionId,
        }
      }
      if (this.queryParams.empStatus) {
        this._filter['empStatus'] = {
          type: 'select',
          value: this.queryParams.empStatus,
        }
      }
      if (this.queryParams.workNum) {
        this._filter['workNum'] = {
          type: 'search',
          value: this.queryParams.workNum,
        }
      }
      if (this.queryParams.idNum) {
        this._filter['idNum'] = {
          type: 'search',
          value: this.queryParams.idNum,
        }
      }
      console.log(this.queryParams.companyId)
      if (this.queryParams.companyId) {
        console.log("aaa")
        this._filter['companyId'] = {
          type: 'select',
          value: this.queryParams.companyId,
        }
        console.log(this._filter);
      }
      const filters = this.parseFilters(this._filter)
      if (filters && filters.length > 0) {
        this.where.filters = filters
      } else {
        // 如果过滤条件为空，设置空数组
        this.where.filters = []
      }
      const params = {
        pageNum: this.queryParams.pageNum,
        pageSize: this.queryParams.pageSize,
        where: this.where,
        order: this.orderby
      }
      console.log("params")
      console.log(params)
      return params;
    },
    getList() {
      console.log("statisticEntry :");
      console.log(this.queryParams.entryMonth);
      let date = this.queryParams.entryMonth;
      if(!date) {
        this.msgError('月份必须选择');
        return;
      }
      this.loading = true;
      statisticEntry(this.companyIds,date).then(response => {
        console.log("statisticEntry response:");
        console.log(response.data);
        this.employList = response.data;
        this.total = response.data.total;
        this.loading = false;
      });
      // const params = this.getQuery();
      // listEmploy(params).then(response => {
      //   this.employList = response.data.records;
      //   this.total = response.data.total;
      //   this.loading = false;
      // });
    },

    departmentFormat(row, column) {
      // console.log(row.departmentId);
      for (var i = 0; i < this.departmentList.length; i++) {
        const item = this.departmentList[i];
        // console.log("id:"+item.id+",depid:"+row.departmentId+", name :"+item.name+",ee="+(item.id == row.departmentId));
        if (item.id == row.departmentId) {
          return item.name;
        }
      }
      return "";
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },

    /** 导出按钮操作 */
    handleExport() {
      const params = this.getQuery();
      this.$confirm('是否确认导出所有员工信息数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        showLoading();
        return exportEmploy(params);
      }).then(result=>{
        hideLoading();
        if(result){
          console.log("**********************************************");
          console.log(result);
          // this.loading = false;
          let blob = new Blob([result.data], {
            type: result.data.type
          });
          let filename = result.headers.filename && decodeURI(result.headers.filename) || '员工列表.xlsx';
          if ('download' in document.createElement('a')) {
            const link = document.createElement("a");
            link.style.display = "none";
            link.href = URL.createObjectURL(blob);
            link.setAttribute("download", filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else {
            navigator.msSaveBlob(blob, filename);
          }
        }
      }).catch(e=>{
        this.msgError(`后端下载文件出错 & ${e}`)
      });
    },

  }
};

</script>

<style scoped>

</style>
