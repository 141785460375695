import request from '@/utils/request'

// 查询字典管理列表
export function listSysdict(query) {
    return request({
        url: '/system/sysdict/findAll',
        method: 'post',
        data: query
    })
}

// 查询字典管理详细
export function getSysdict(id) {
    return request({
        url: '/system/sysdict/findOne?id=' + id,
        method: 'post'
    })
}

// 根据字典类型查询字典数据信息
export function getDicts(dictType) {
    return request({
        url: '/system/sysdict/type/' + dictType,
        method: 'get'
    })
}

// 新增字典管理
export function addSysdict(data) {
    return request({
        url: '/system/sysdict/add',
        method: 'post',
        data: data
    })
}

// 修改字典管理
export function updateSysdict(data) {
    return request({
        url: '/system/sysdict/edit',
        method: 'post',
        data: data
    })
}

// 删除字典管理
export function delSysdict(id) {
    return request({
        url: '/system/sysdict/remove',
        method: 'post',
        data: id
    })
}

// 导出字典管理
export function exportSysdict(query) {
    return request({
        url: '/system/sysdict/export',
        method: 'get',
        data: query
    })
}
