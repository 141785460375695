import request from '@/utils/request'

// 查询班次管理列表
export function listCbTaxSetting(query) {
    return request({
        url: '/cb/cbTaxSetting/findAll',
        method: 'post',
        data: query
    })
}

// 查询班次管理详细
export function getCbTaxSetting(id) {
    return request({
        url: '/cb/cbTaxSetting/findOne?id=' + id,
        method: 'post'
    })
}

// 新增班次管理
export function addCbTaxSetting(data) {
    return request({
        url: '/cb/cbTaxSetting/add',
        method: 'post',
        data: data
    })
}

// 修改班次管理
export function updateCbTaxSetting(data) {
    return request({
        url: '/cb/cbTaxSetting/edit',
        method: 'post',
        data: data
    })
}

// 删除班次管理
export function delCbTaxSetting(id) {
    return request({
        url: '/cb/cbTaxSetting/remove',
        method: 'post',
        data: id
    })
}

// 导出班次管理
export function exportCbTaxSetting(query) {
    return request({
        url: '/cb/cbTaxSetting/export',
        method: 'post',
        data: query
    })
}
