<template>
  <div>
    <el-row class="mb8">
      <el-col>
        请先选择需要查询的公司部门：
        <treeselect v-model="queryParams.companyId" :options="treeData" :multiple="true" value-consists-of="ALL"
                    :limit="5" :limitText="count => `及其它${count}部门`" :flat="true"
                    :normalizer="normalizerCompany" @select="selectCompany" @input="companyIdInput"
                    @deselect="deselectCompany" placeholder="请选择公司" 
        />
      </el-col>
    </el-row>
    <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch"  class="mb8">
      <el-form-item label="排班日期" :label-width="uniApp? '120px':'75px'">
        <el-date-picker type="date" clearable size="small" v-model="queryParams.clockDateStart" value-format="yyyy-MM-dd" placeholder="选择开始日期"/>
        至
        <el-date-picker type="date"  clearable size="small" v-model="queryParams.clockDateEnd" value-format="yyyy-MM-dd" placeholder="选择结束日期"/>
      </el-form-item>
      <el-form-item label="员工姓名" label-width="75px">
        <el-input v-model="queryParams.empName" placeholder="请输入员工姓名" clearable size="small" @keyup.enter.native="handleQuery"/>
      </el-form-item>
<!--      <el-form-item label="考勤状态">-->
<!--        <el-select v-model="queryParams.attType" multiple placeholder="请选择考勤状态" clearable size="small">-->
<!--          <el-option v-for="item in getDictDatas(DICT_TYPE.ATT_STATISTIC_TYPE)" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled">-->
<!--          </el-option>-->
<!--        </el-select>-->
<!--        &lt;!&ndash;          <el-input v-model="queryParams.attType" placeholder="请输入考勤状态" clearable size="small" @keyup.enter.native="handleQuery"/>&ndash;&gt;-->
<!--      </el-form-item>-->
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">查询</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>
    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button type="primary" plain icon="el-icon-search" size="mini" @click="queryToday" >今日班次</el-button>
        <el-button type="primary" plain icon="el-icon-search" size="mini" @click="queryYestoday" >昨日班次</el-button>
        <el-button type="primary" plain icon="el-icon-search" size="mini" @click="queryPreMonth" class="mb8" >上月班次</el-button>
        <el-button type="primary" plain icon="el-icon-search" size="mini" @click="queryThisMonth"  class="marginLeft">本月班次</el-button>
        <!--      <el-button type="primary" plain icon="el-icon-search" size="mini" @click="statisticByEmp" >考勤日统计</el-button>-->
      </el-col>
      <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>
    </el-row>
    <el-table ref="staticEmp" v-loading="staticEmpLoading" :data="statisticEmployList" @selection-change="handleSelectionChange" width="100%" height="618" stripe border>
      <el-table-column type="selection" width="40" align="center"/>
      <el-table-column label="公司名称" align="center" >
        <template slot-scope="scope">{{ getCompanyName(scope.row.companyId)}}</template>
      </el-table-column>
      <el-table-column label="部门名称" align="center" prop="depName" >
        <template slot-scope="scope">{{ getDepartmentName(scope.row.depId)}}</template>
      </el-table-column>
      <el-table-column label="员工姓名" align="center" prop="empName" width="100px" fixed/>
      <!--            <el-table-column label="日期" align="center">-->
      <el-table-column align="center" v-for="day in startEndDays" :key="day" :label="day" width="130px" >
        <template slot-scope="scope">
          <template v-if="scope.row[day]">
            <span>{{scope.row[day]['attClassName']?scope.row[day]['attClassName']:"休息"}}</span>
          </template>
          <template v-else>
            <span></span>
          </template>
        </template>
      </el-table-column>
      <!--            </el-table-column>-->
    </el-table>

  </div>
</template>

<script>
import {
  listAttStatisticDay, count, clockOnOff, getAttStatisticDay, updateAttStatisticDay,
  exportAttStatisticDay, statisticByEmp } from "@/api/attendance/attStatisticDay";
import { showLoading, hideLoading } from '@/utils/reqloading';
import {formatDate} from "@/utils/formatTime";
import {getFirstLastDayOfMonth, getDayAll} from "@/utils/dateTimeUtils";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { LOAD_CHILDREN_OPTIONS } from '@riophae/vue-treeselect'
import RightToolbar from "@/components/RightToolbar"
import permission from '@/directive/permission/index.js' // 权限判断指令
import Pagination from "@/components/Pagination";
import {checkRole} from "@/utils/role";
import {getAllOrgTreeWithParent} from "../../org/components/orgtree";
import {DICT_TYPE, getDictDatas} from "@/utils/dict";
import {Local} from '@/utils/storage.js'

export default {
  name: "schQuery",
  // props:{
  //   depId: Array,
  //   companyList: Array,
  //   departmentList: Array,
  // },
  components: {
    Treeselect,
    RightToolbar,

  },
  directives: { permission },
  data() {
    return {
      uniApp:false,
      companyList: [],
      departmentList: [],
      treeData: [],
      statisticEmployList: [],
      startEndDays: [],
      staticEmpLoading: false,
      absentCount: 0,
      attStatisticDayList: null,
      clockDetailData: null,
      searchLoading: false,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      open: false,
      // 查询参数
      queryParams: {
        companyId: null,
        depId: null,
        empName: null,
        attType: null,
        clockDateStart: null,
        clockDateEnd: null,
      },
    }
  },
  created() {
    this.uniApp=Local.get("isUniapp")?true:false;
    const [start, end] = getFirstLastDayOfMonth(new Date());
    this.queryParams.clockDateStart = start;
    this.queryParams.clockDateEnd = end;
    getAllOrgTreeWithParent(false, (tree, companyList, departmentList)=>{
      console.log("tree");
      console.log(tree);
      this.companyList = companyList;
      this.treeData = tree;

      this.departmentList = departmentList;
      // this.getList();
    });
  },
  methods: {
    getList(){
      this.statisticByEmp();
    },
    statisticByEmp() {
      this.staticEmpLoading = true;
      // let params = this.getQuery();
      let params = {
        start: this.queryParams.clockDateStart, end: this.queryParams.clockDateEnd, depIdList: this.queryParams.depId,
        empName: this.queryParams.empName, attType: this.queryParams.attType
      }
      console.log(params);
      this.startEndDays = getDayAll(this.queryParams.clockDateStart, this.queryParams.clockDateEnd);
      statisticByEmp(params).then(response => {
        let records = response.data;
        console.log(records);
        this.staticEmpLoading = false;
        if(!records || records.length <= 0){
          this.msgInfo("没有考勤统计信息");
          this.statisticEmployList = [];
        }
        let keys = Object.keys(records);
        let list = [];
        if (!keys || keys.length <= 0){
          this.msgInfo("没有考勤统计信息");
          this.statisticEmployList = [];
          return;
        }
        keys.forEach(k => {
          let empList = records[k];
          console.log(empList);
          empList.forEach(e => {
            list.push(e);
          });
        });
        console.log(list);
        // this.total = response.data.total;
        this.statisticEmployList = list;
        //解决表格列乱排布问题
        let self = this
        this.$nextTick(function () {
          self.$refs.staticEmp.doLayout();
        })
        this.staticEmpLoading = false;
      });

    },
    queryToday(){
      const today = formatDate(new Date(),'YYYY-mm-dd');
      this.queryParams.clockDateStart = today;
      this.queryParams.clockDateEnd = today;
      this.getList();
    },
    queryYestoday(){
      let today = new Date();
      let yesterday = new Date(today.setDate(today.getDate() - 1));
      console.log(yesterday);
      // const yesterday = formatDate(date, 'yyyy-MM-dd');//昨天
      this.queryParams.clockDateStart = yesterday;
      this.queryParams.clockDateEnd = yesterday;
      this.getList();
    },
    queryPreMonth(){
      let date = new Date();
      let preMonth = new Date(date.setMonth(date.getMonth() - 1));
      const [start, end] = getFirstLastDayOfMonth(preMonth);
      this.queryParams.clockDateStart = start;
      this.queryParams.clockDateEnd = end;
      this.getList();
    },
    queryThisMonth(){
      const [start, end] = getFirstLastDayOfMonth(new Date());
      this.queryParams.clockDateStart = start;
      this.queryParams.clockDateEnd = end;
      this.getList();
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },
    normalizerDepartment(node) {
      return this.normalizer(node);
    },
    getDepartmentName(id){
      //console.log(id);
      for(let i = 0; i < this.departmentList.length; i++){
        if(id == this.departmentList[i].id) return this.departmentList[i].name;
      }
      return "";
    },
    companyIdInput(value, instanceId){
      console.log("companyIdInput");
      console.log(value);
      console.log(instanceId);
      console.log(this.queryParams.companyId);
      // const companyIds = this.getClassIds(value,"company");
      this.queryParams.positionId = null;
      this.queryParams.depId = this.getClassIds(value,"department");
      console.log(this.queryParams.depId);
      // this.queryParams.positionId = null;
      // this.$refs["selectPosTree"].clear();
      // this.getDepartment(value);
      // this.getPosition(value);
      console.log("companyIdInput");
    },
    getClassIds(value, className){
      if(value && value.length>0){
        let ids = [];
        value.forEach( id => {
          if(id.startsWith(className)){
            const idstr = id.substring(className.length);
            console.log(idstr)
            ids.push(idstr);
          }
        })
        console.log("getClassIds , ids=");
        console.log(ids);
        return ids;
      }else{
        return [];
      }
    },
    getCompanyName(id){
      for(let i = 0; i < this.companyList.length; i++){
        if(id == this.companyList[i].id) return this.companyList[i].name;
      }
      return "";
    },
    deselectCompany(node, instanceId) {
      console.log("deselectCompany");
      console.log(node.id);
      console.log(instanceId);
    },
    selectCompany(node, instanceId) {
    },
    /** 转换公司数据结构 */
    normalizerCompany(node) {
      // if (node['id'] && !node['id'].length) {
      //   delete node['children'];
      // }
      if(node.className=='company'){
        return {
          id: node['treeId'],
          label: node['id'] + " - " +node['companyName'],
          children: node['children']
        };
      }else if(node.className=='department'){
        return {
          id: node['treeId'],
          label: node['name'],
          children: node['children']
        };
      }
      // return this.normalizer(node,'id','companyName');
    },

  },

}
</script>

<style scoped>

</style>
