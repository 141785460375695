<template>
	<el-collapse-item title="档案信息" :name="this.collapseStatus" :class="collapseSetting()">
		<el-form :model="archiveInfoModel" ref="archiveInfoModel" :rules="rules" class="employ" label-width="150px">
			<el-row>
				<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
					<el-form-item label="档案编号">
						<el-input v-model="archiveInfoModel.number" :readonly="readonly" ref="archiveNo"></el-input>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
					<el-form-item label="档案类型">
						<el-input v-model="archiveInfoModel.type" :readonly="readonly"></el-input>
					</el-form-item>
				</el-col>
			
				<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
					<el-form-item label="档案是否转入">
						<el-checkbox v-model="archiveInfoModel.transferIn" :disabled="readonly"></el-checkbox>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
					<el-form-item label="转入公司时间">
						<el-date-picker
							v-model="archiveInfoModel.transferInDate"
							value-format="yyyy-MM-dd"
							:readonly="readonly"
							align="left"
							type="date"
							placeholder="选择日期"
						>
						</el-date-picker>
					</el-form-item>
				</el-col>
		
				<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
					<el-form-item label="档案来源">
						<el-input v-model="archiveInfoModel.source" :readonly="readonly"></el-input>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
					<el-form-item label="档案转出时间" prop="transferOutDate" :rules="[{ validator: (rule, value, callback)=>{ValidateEndDate(rule, value, callback,archiveInfoModel.transferInDate,archiveInfoModel.number)}, trigger: 'change'}]" 	>
						<el-date-picker
							v-model="archiveInfoModel.transferOutDate"
							value-format="yyyy-MM-dd"
							:readonly="readonly"
							align="left"
							type="date"
							placeholder="选择日期"
						>
						</el-date-picker>
					</el-form-item>
				</el-col>
			
				<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
					<el-form-item label="档案去向">
						<el-input v-model="archiveInfoModel.to" :readonly="readonly"></el-input>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
					<el-form-item label="档案状态">
						<el-input v-model="archiveInfoModel.status" :readonly="readonly"></el-input>
					</el-form-item>
				</el-col>
			
				<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
					<el-form-item label="档案保管地点">
						<el-input v-model="archiveInfoModel.keepPlace" :readonly="readonly"></el-input>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
					<el-form-item label="档案材料信息">
						<el-input v-model="archiveInfoModel.information" :readonly="readonly"></el-input>
					</el-form-item>
				</el-col>
			
				<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
					<el-form-item label="类号">
						<el-input v-model="archiveInfoModel.typeNo" :readonly="readonly"></el-input>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
					<el-form-item label="材料名称">
						<el-input v-model="archiveInfoModel.documentName" :readonly="readonly"></el-input>
					</el-form-item>
				</el-col>
			
				<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
					<el-form-item label="份数" prop="documentCopy">
						<el-input v-model="archiveInfoModel.documentCopy" :readonly="readonly" placeholder="一位整数或空"></el-input>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
					<el-form-item label="页数" prop="documentPages" >
						<el-input v-model="archiveInfoModel.documentPages" :readonly="readonly" placeholder="1到3位整数或空"></el-input>
					</el-form-item>
				</el-col>
		
				<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
					<el-form-item label="备注">
						<el-input v-model="archiveInfoModel.remarks" :readonly="readonly"></el-input>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
					<el-form-item label=" ">
						<el-button v-on:click="eidit" icon="el-icon-edit" plain v-bind:disabled="readonly" type="primary">编辑</el-button>
						<el-button v-on:click="save" v-bind:disabled="readonly" :loading="saving" icon="el-icon" plain type="primary">保存</el-button>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
	</el-collapse-item>
</template>


<script>
export default {
	data() {
		
		let readonlyInit = true;
		let archiveInfoInit = null;
		if (this.archiveInfo == null) {
			archiveInfoInit = {
				number: '',
				type: null,
				transferIn: null,
				transferInDate: null,
				source: null,
				transferOutDate: null,
				to: null,
				status: null,
				keepPlace: null,
				information: null,
				typeNo: null,
				documentName: null,
				documentCopy: null,
				documentPages: null,
				remarks: null,
			};
			readonlyInit = false;
		} else {
			archiveInfoInit = this.archiveInfo;
		}

		if(this.locked){
			readonlyInit=true;
		}
		else{
			readonlyInit = this.isReadOnly;
		}
		
		return {
			archiveInfoModel: archiveInfoInit,
			readonly: readonlyInit,
			saving:false,
			pickerOptions1: {
				disabledDate(time) {
					return time.getTime() > Date.now();
				},
			},
			rules:{transferOutDate:[{required:true,message:"abc",trigger: 'blur'}],
				   documentPages:[{ required: false, pattern: /^\d{1,3}$/, message: '请输入1-3位整数', trigger: 'blur' }],
				   documentCopy:[{ required: false, pattern: /^\d{1}$/, message: '请输入1位整数', trigger: 'blur' }]}
		};
	},
	props: ['archiveInfo', 'id', 'isReadOnly',"collapseStatus","locked"],
	watch: {
		isReadOnly(newValue,oldV) {
			this.readonly = newValue;
		},
		locked(newValue, oldV) {
			this.hasLocked = newValue;
			if (this.hasLocked) {
				this.readonly = true;
			}
			else
			{
				this.readonly = false;
			}
		},
	},
	computed:{
		collapseSetting(){
			return function(){
				if(this.archiveInfoModel.number != null && this.archiveInfoModel.number.length > 0 )
				{
					return {callpse_header_fill:true};
				}
				else
				{
					return {callpse_header_unfill:true};
				}
				
			}
		},
	},
	methods: {
		save() {
			// save data to DB
			// this.$emit("submitSpouseInfo",this.spouseInfoInit);
			this.$refs.archiveInfoModel.validate(valid => {
				let self = this;
				if (valid) {
					let savingArchiveInfo = {};
					savingArchiveInfo.id = this.id;
					savingArchiveInfo.archiveInfo = this.archiveInfoModel;
					this.saving=true;
					this.$emit('updatePartialEmployeeInfo', { data: savingArchiveInfo }, (response) => {
						this.saving=false;
						if (response.success) {
							this.msgSuccess('修改成功');
							this.readonly = true;
						} else {
							this.msgError('修改失败,' + response.msg);
						}
					});
				} 
				else {
						this.msgError('有非法输入或必填项未填写，请检查!');
				}
			});
		},
		eidit() {
			this.readonly = false;
			this.$refs.archiveNo.focus();
		},
		ValidateEndDate(rule,value,callback,transferInDate,archiveNumber){
			// 必需每个分支都要有返回，否则进不去保存的validate方法。
			if(archiveNumber==null || archiveNumber =="")
			{
				return callback();
			}
			else
			{
				if(transferInDate==null || transferInDate =="")
				{
					return callback(new Error("先选转入公司日期。"));
				}
				else
				{
					if(value==null || value=="")
					{
						return callback(new Error("终止日期不能为空。"));
					}
					else{
						if ( value < transferInDate) {
							return callback(new Error("终止日期不能小于转入公司日期。"));
						}
						else
						{
							return callback();
						}
					}
				}		
			}
            
        }
		
	}
};
</script>

<style>
</style>