import request from '@/utils/request'

// 员工自助查询信息详细
export function getSelf() {
    return request({
        url: '/employ/self/getSelf',
        method: 'post'
    })
}

// 员工自助修改信息
export function updateSelf(data) {
    return request({
        url: '/employ/self/update',
        method: 'post',
        data: data
    })
}

export function updateUserBase(data) {
    return request({
        url: '/employ/self/updateUserBase',
        method: 'post',
        data: data
    })
}
