<template>
	<div style="border: cyan; border-width: thin; border-style: solid; padding: 5px; margin: 5px">
		<div style="width: 100px; height: 100px; margin-left: auto; margin-right: auto" v-loading="pictureLoading">
			<img width="100%" height="100%" :src="teacherPhoto" alt="" style="border-radius: 50%" />
		</div>
		<div>
			<table width="100%" style="margin-top: 5px; word-break: break-word">
				<tr>
					<td colspan="2" style="text-align: center">
						{{ teacher.name }}
						<el-tag :type="genderBackgroundColor" style="border-radius: 50%; width: 28px; height: 28px; padding: 0px">{{ teacher.gender }} </el-tag>
					</td>
				</tr>
				<tr>
					<td style="width: 35%">手机：</td>
					<td>{{ teacher.telephone }}</td>
				</tr>
				<tr>
					<td>公司：</td>
					<td class="textFormat">{{ teacher.company }}</td>
				</tr>
				<tr>
					<td>部门：</td>
					<td class="textFormat">{{ teacher.department }}</td>
				</tr>
				<tr>
					<td>职位：</td>
					<td class="textFormat">{{ teacher.position }}</td>
				</tr>
			</table>
		</div>
		<div style="display: block; text-align: center">
			<el-tag round type="success" style="padding: 2px; margin: 2px">员工讲师</el-tag>
			<el-tag round type="success" style="padding: 2px; margin: 2px">在职</el-tag>
			<el-dropdown style="padding: 2px; margin: 2px">
				<el-button size="mini" style="padding: 0px; border: none; vertical-align: middle">
					<el-tag style="writing-mode: vertical-lr; margin: 0 auto; padding: 0; font-weight: bold">...</el-tag>
				</el-button>
				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item @click.native="viewTeacher()">查看</el-dropdown-item>
					<el-dropdown-item @click.native="editTeacher()">编辑</el-dropdown-item>
					<el-dropdown-item @click.native="deleteTeacher()">删除</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
		</div>
	</div>
</template>

<script>
import { downloadPersonalPhoto } from '@/api/employ/employ';

export default {
	name: 'teacherView',

	data() {
		let teacherInit = this.teacherProp;
		return {
			//teacher:{...this.teacherProp},
			teacher: this.teacherProp,
			teacherPhoto: '',
			genderBackgroundColor: '',
			pictureLoading: false,
			girlPicture: '/static/boy.jpeg',
			boyPicture: '/static/girl.jpeg',
		};
	},
	props: { teacherProp: Object },
	watch: {
		teacherProp(newValue, oldV) {
			this.teacher = newValue;
			if (newValue.photoUrl != oldV.photoUrl) {
				this.downloadTeacherPhoto();
			}
			if (this.teacher.gender == '男') {
				this.genderBackgroundColor = '';
			} else {
				this.genderBackgroundColor = 'danger';
			}
			this.$forceUpdate();
		},
	},
	created() {
		if (this.teacher.gender == '男') {
			this.genderBackgroundColor = '';
		} else {
			this.genderBackgroundColor = 'danger';
		}
		if (this.teacher.photoUrl != null && this.teacher.photoUrl.length > 0) {
			this.downloadTeacherPhoto();
		} else {
			if (this.teacher.gender == '男') {
				this.teacherPhoto = this.girlPicture;
			} else {
				this.teacherPhoto = this.boyPicture;
			}
		}
	},

	mounted() {},

	methods: {
		viewTeacher() {
			this.$emit('viewSelectTeacher', { data: this.teacherProp });
		},
		editTeacher() {
			this.$emit('updateSelectTeacher', { data: this.teacherProp });
		},
		deleteTeacher() {
			this.$emit('deleteSelectTeacher', { data: this.teacherProp });
		},
		downloadTeacherPhoto() {
			this.pictureLoading = true;
			downloadPersonalPhoto(this.teacher.photoUrl)
				.then((res) => {
					if (res) {
						let resData = res.data;
						var blob = new Blob([resData], { type: 'application/octet-stream' });
						this.teacherPhoto = URL.createObjectURL(blob);
					} else {
						this.msgError(`后端下载文件出错 & ${e}`);
					}
					this.pictureLoading = false;
				})
				.catch((e) => {
					//this.loading = false;
					this.pictureLoading = false;
					this.msgError(`后端下载文件出错 & ${e}`);
				});
		},
	},
};
</script>

<style lang="scss" scoped>
.textFormat {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	overflow-x: hidden;
	width: 90px;
	word-break: keep-all;
	display: block;
}
</style>
