<template>
  <div >
    <el-row>
      <h2 style="text-align: center">{{form.name}}</h2>
    </el-row>
  <div class="container" v-loading="loading">
    <el-row>
      <table class="table">
        <tr>
          <td style="width: 15%">被考核对象</td>
          <td style="width: 20%">{{form.empName}}({{form.idNum}})</td>
          <td style="width: 15%">部门</td>
          <td style="width: 20%">{{form.depName}}</td>
          <td style="width: 15%">岗位</td>
          <td style="width: 15%">{{form.posName}}</td>
        </tr>
        <tr>
          <td style="width: 15%">综合得分</td>
          <td style="width: 20%">{{form.finalScore}} 分
            <span style="text-align: right; margin-left: 16px" v-if="currentEmpId == form.special['empId']">
              <el-button type="primary" round size="small" @click="changeSpecial">权重修正</el-button>
              <span v-if="form.special['weight']">修正后：{{ (form.finalScore * form.special['weight']).toFixed(2)}}分</span>
            </span>
          </td>
          <td style="width: 15%">考评时间</td>
          <td style="width: 20%">{{form.startDate}}至{{form.endDate}}</td>
          <td style="width: 15%">评分截止日期</td>
          <td style="width: 15%">{{form.deadline}}</td>
        </tr>
        <tr>
          <td style="width: 15%">考评内容</td>
          <td colspan="5" style="width: 85%">{{form.content}}</td>
        </tr>
      </table>
    </el-row>
    <el-row>
      <table class="tableScore">
        <thead>
          <tr>
            <template v-for="level in maxLevel">
              <th rowspan="2" :key="level" :style="{'width': (35/maxLevel)+'%'}">{{level}}级指标</th>
            </template>
            <th rowspan="2" style="width: 8%">权重(分)</th>
            <th rowspan="2" style="width: 19%">考评内容</th>
            <th :colspan="scoreEmpNum+1" style="text-align: center; width: 33%">评分</th>
          </tr>
          <tr>
            <template v-for="item in form.levelScore.items">
              <th v-if="item.empId == currentEmpId" :key="item.empId+item.empName" style="width: 15%">
                {{item.empName}}({{item.percentage}}%)
              </th>
              <th v-else :key="item.empId+item.empName" :style="{'width': (18/(scoreEmpNum))+'%'}">
                {{item.empName}}({{item.percentage}}%)
              </th>
            </template>
<!--            <th :style="{'width': (18/(scoreEmpNum))+'%'}">综合</th>-->
          </tr>
        </thead>
        <tbody>
          <template v-for="item in tableScore" >
            <tr :key="item.content+item.score">
              <template v-for="level in maxLevel">
                <td v-if="item['name'+level]" :key="item['name'+level]+level" :rowspan="item['rowspan'+level]">
                  {{item['name'+level]}}<span v-if="item['score'+level]">({{item['score'+level]}}分)</span>
                </td>
              </template>
              <td>{{item.score}}</td>
              <td><pre>{{item.content}}</pre></td>
              <template v-for="scoreItem in form.levelScore.items">
                <td :key="scoreItem.empId+scoreItem.empName" style="padding: 0px;text-align: center">
                  <el-input v-if="canScore && scoreItem.empId == currentEmpId" v-model="form.scores['empId'+scoreItem.empId]['itemId'+item.id]"
                            type="number" :placeholder="'不能大于'+item.score+'分'"></el-input>
                  <span style="text-align: center" v-else>{{form.scores['empId'+scoreItem.empId]?form.scores['empId'+scoreItem.empId]['itemId'+item.id]:""}}</span>
                </td>
              </template>
<!--              <td>{{  }}</td>-->
            </tr>
          </template>
        </tbody>
        <tfoot>
          <tr>
            <td :colspan="maxLevel+2">合计(总分 {{totalScore}} 分)：</td>
            <template v-for="scoreItem in form.levelScore.items">
              <td :key="scoreItem.empId+scoreItem.empName" style="padding: 0px;text-align: center">
                {{getSumScore(scoreItem.empId,form.scores)}}
              </td>
            </template>
          </tr>
        </tfoot>
      </table>
    </el-row>
  </div>
    <div slot="footer" class="dialog-footer" style="text-align: center;margin-top:15px;">
      <el-button v-if=" canScore" type="primary" @click="submitForm"
                 :loading="saving">保 存</el-button>
      <el-button @click="cancel">关 闭</el-button>
    </div>
  </div>
<!--  <div class="tableContent">-->
<!--    <div id="printMe">-->
<!--      <div class="title">员 工 离 职 申 请 及 结 算 表</div>-->
<!--      <table class="table">-->
<!--        <tr>-->
<!--          <td>-->
<!--            afda-->
<!--          </td>-->
<!--          <td>-->
<!--            bbbb-->
<!--          </td>-->
<!--        </tr>-->
<!--      </table>-->
<!--    </div>-->
<!--  </div>-->
</template>

<script>
import {listKpiEmploy,getKpiEmploy,updateKpiEmploy,exportKpiEmploy, saveKpiEmpScore,updateSpecialWeight } from "@/api/kpi/employ";
import {getKpiTemplate} from "@/api/kpi/template";
import permission from '@/directive/permission/index.js'; // 权限判断指令
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import {DICT_TYPE,getDictLabelFromData, getDictDataLabel, getDictDatas} from "@/utils/dict";
import { getOrgEmpTree, normalizerEmployMultiSelectTree} from '@/views/pages/employ/components/empOrgTree';
import { showLoading, hideLoading } from '@/utils/reqloading';
import { Local } from '@/utils/storage.js';
import Teacher from "../../training/teacher/teacher";

export default {
  name: "kpiEmployEdit",
  directives: { permission },
  components: {
    Teacher,
    Treeselect,
  },
  data() {
    return {
      saving: false,
      uniApp:false,
      id: null,
      companyId: null,
      currentEmpId: null,
      readOnly: true,
      form:{
        levelScore:{items:[]},
        special:{},
      },
      maxLevel:1, // 指标层数
      scoreEmpNum: 1, // 多少个打分人
      totalCol: 1,
      levelWidth: 10,
      scoreWidth: 10,
      tableScore: null,
      totalScore: 0, // 总分
      scores:[],
      canScore: false,
      loading:false
    }
  },
  created() {
    this.uniApp=Local.get("isUniapp")?true:false;
  },
  mounted() {
    this.companyId = this.$store.getters.userInfo.companyId;
    if(!this.$store.getters.userInfo.employ || !this.$store.getters.userInfo.employ.id){
      this.$message.error('当前账号不是员工，不能使用考核评分功能！');
    }else{
      this.currentEmpId = this.$store.getters.userInfo.employ.id;
    }
    console.log(this.$route.query)
    const {id, readOnly} = this.$route.query;
    this.id = id;
    this.readOnly = readOnly;

    this.load();
  },
  methods: {
    load() {
      console.log("load...")
      this.handleUpdate(this.id);
    },
    /** 修改按钮操作 */
    handleUpdate(id) {
      this.loading=true;
      getKpiEmploy(id).then(response => {
        this.form = response.data;
        if(this.form.levelScore && this.form.levelScore.maxOrder){
          this.scoreEmpNum = this.form.levelScore.maxOrder;
        }
        const items = this.form.items;
        if(items && items.length>0){
          let totalScore = 0;
          for(let i = 0; i < items.length; i++) {
            this.caculateRowLevel(items[i]);
            totalScore = Number(totalScore) + Number(items[i].score);
          }
          this.totalScore = totalScore;
          let table = [];
          table.push({});
          for(let i = 0; i < items.length; i++) {
            this.caculateTableRow(items[i],table);
          }
          console.log(table);
          table.splice(table.length-1, 1);
          this.updateScore(table)
          this.tableScore = table;
          console.log(this.tableScore);
        }
        this.totalCol = Number(this.scoreEmpNum) + Number(this.maxLevel) + 3;

        // 检查当前登陆用户是否能保存，及是否为打分用户，并且没有打分
        this.checkIsSocre();
        this.loading=false;
      });
    },
    checkIsSocre(){
      if(this.form.scores['empId'+this.currentEmpId]){
        console.log(this.form.scores['empId'+this.currentEmpId])
        let finish = this.form.scores['empId'+this.currentEmpId]['sum'];
        if(finish && finish >= 0) this.canScore = false;
        else this.canScore = true;
      }else{
        this.canScore = false;
      }
    },
    caculateRowLevel(item){
      if(item && item.children && item.children.length>0){
        let sumRows = 0;
        let childs = item.children;
        for(let i =0; i<childs.length; i++){
          const rows = this.caculateRowLevel(childs[i])
          sumRows = Number(sumRows) + Number(rows);
        }
        item.rowspan = sumRows;
        return sumRows;
      }else{
        if(item.level > this.maxLevel){
          this.maxLevel = item.level;
        }
        item.rowspan = 1
        return item.rowspan;
      }
    },

    caculateTableRow(item, table){
      this.setItemValue(item, table);
      if(item && item.children && item.children.length>0){
        let childs = item.children;
        for(let i =0; i<childs.length; i++){
          this.caculateTableRow(childs[i],table)
        }
        return;
      }else{
        table.push({});
        return;
      }
    },
    setItemValue(item,table){
      table[table.length-1]["id"+item.level] = item.id;
      table[table.length-1]["name"+item.level] = item.name;
      table[table.length-1]["score"+item.level] = item.score;
      table[table.length-1]["content"+item.level] = item.content;
      table[table.length-1]["level"+item.level] = item.level;
      table[table.length-1]["rowspan"+item.level] = item.rowspan;
    },

    updateScore(table){
      for(let idx = 0; idx < table.length; idx++){
        let item = table[idx];
        let max = 0;
        // 将最后一个分数作为打分分数
        for(let i = 1; i <= this.maxLevel; i++){
          if(item["score"+i]){
            item.id = item["id"+i]
            item.name = item["name"+i]
            item.score = item["score"+i]
            item.content = item["content"+i]
            max = i;
          }
        }
        // 最后的name都设置字符串，方便table显示
        for(let i = max+1; i <= this.maxLevel; i++){
          item["name"+i] = " ";
        }
      }
    },
    getSumScore(empId, scores){
      let scoreMap = scores['empId'+empId];
      // console.log(scoreMap);
      let sum = 0;
      for(let i = 0; i <this.tableScore.length; i++) {
        let item = this.tableScore[i];
        if(scoreMap['itemId'+item.id]){
          sum = Number(sum) + Number(scoreMap['itemId'+item.id]);
        }else{
          return '';
        }
      }
      if(!sum){
        return '';
      }
      return sum;
    },
    submitForm() {
      let that = this;
      this.$confirm('是否确认完成对当前员工绩效考核评分？确定后将不能修改。', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return that.saveForm();
      })
    },
    saveForm(){
      console.log("saveForm ...")
      console.log(this.tableScore)
      console.log(this.form)
      let socreMap = this.form.scores['empId'+this.currentEmpId];
      let sum = 0;
      for(let i = 0; i <this.tableScore.length; i++){
        let item = this.tableScore[i];
        if(socreMap['itemId'+item.id]){
          sum = Number(sum) + Number(socreMap['itemId'+item.id]);
          if(socreMap['itemId'+item.id] > item.score){
            this.msgError("”"+item.name+"“评分不应该超过"+item.score+", 当前项评分为："+socreMap['itemId'+item.id]);
            return;
          }
        }else{
          this.msgError("”"+item.name+"“没有打分，请给该项打分");
          return;
        }
      }
      socreMap['sum'] = sum;
      // socreMap['finish'] = true;
      let finalScore = 0;
      let levelScore = this.form.levelScore;
      for(let i = 0; i < levelScore.maxOrder; i++){
        let empId = levelScore['empId'+i];
        if(empId == this.currentEmpId){
          console.log("this.levelScore['percentage'+i] = "+levelScore['percentage'+i])
          socreMap['sumPercentage'] = Number(sum) * Number(levelScore['percentage'+i])/100;
          finalScore = Number(finalScore)  + Number(socreMap['sumPercentage']);
        }else{
          finalScore = Number(finalScore) + Number(this.form.scores['empId'+empId]['sumPercentage'])
        }
      }
      if(finalScore)  this.form.finalScore = finalScore.toFixed(2);
      console.log(this.form)
      // let form = {id: this.form.id, scores: this.form.scores, finalScore: this.form.finalScore};
      // console.log(form)
      this.saving = true;
      saveKpiEmpScore(this.form).then(response => {
        this.msgSuccess("保存成功");
        this.form = response.data;
        this.saving = false;
      }).catch(()=>{
        this.saving = false;
      });
    },
    changeSpecial(){
      this.$prompt('请输入修正权重值，默认为”1“，最后得分=综合得分 * 修正权重', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^(\d+)(\.\d+)?$/,
        inputErrorMessage: '权重只能是数字'
      }).then(({ value }) => {
        this.$set(this.form.special,"weight", value);
        let form = {id:this.form.id, special: this.form.special}
        updateSpecialWeight(form).then(response => {
          this.msgSuccess("修改成功");
          this.form = response.data;
        }).catch(()=>{
        });
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '取消输入'
        // });
      });
    },
    // 取消按钮
    cancel() {
      // this.$emit("close", {refresh:false,isSuccess:true});
      this.bus.$emit('onCurrentContextmenuClick', {
        id: 1,
        path: this.$route.path,
      });
    },

  },
}
</script>

<style scoped>

.tableContent {
  width: 900px;
  margin: auto;
  margin-top: 20px;
  overflow: auto;
}

.table {
  width: 100%;
  border-style: solid;
  border-collapse: collapse;
  overflow-x: scroll;
  overflow-y: scroll;
  border: 1;
}
.tableScore {
  width: 100%;
  border-style: solid;
  border-collapse: collapse;
  overflow-x: scroll;
  overflow-y: scroll;
  border: 1;
  font-size: x-small;
}
.container{
   width: 100%;
   min-width: 200px;
   display: grid;
   overflow-x: scroll;
   overflow-y: scroll;
}
table tr th,
table tr td {
  border: 1px solid rgb(12, 12, 12);
  padding: 8px;
  white-space: nowrap;
}



</style>
