import request from '@/utils/request'

// 查询班次管理列表
export function listKpiTemplate(query) {
    return request({
        url: '/kpi/kpiTemplate/findAll',
        method: 'post',
        data: query
    })
}

// 查询班次管理详细
export function getKpiTemplate(id) {
    return request({
        url: '/kpi/kpiTemplate/findOne?id=' + id,
        method: 'post'
    })
}

// 新增班次管理
export function addKpiTemplate(data) {
    return request({
        url: '/kpi/kpiTemplate/add',
        method: 'post',
        data: data
    })
}

// 修改班次管理
export function updateKpiTemplate(data) {
    return request({
        url: '/kpi/kpiTemplate/edit',
        method: 'post',
        data: data
    })
}

// 删除班次管理
export function delKpiTemplate(id) {
    return request({
        url: '/kpi/kpiTemplate/remove',
        method: 'post',
        data: id
    })
}

// 导出班次管理
export function exportKpiTemplate(query) {
    return request({
        url: '/kpi/kpiTemplate/export',
        method: 'post',
        data: query
    })
}
