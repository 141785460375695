import {listCompany, findAllWithParent} from "@/api/org/company";
import {listDepartment } from "@/api/org/department";
import {listPosition} from "@/api/org/position";
import {handleTree} from "@/utils/tools";


function addToChild(parentTree, id, item,  parentId, children, className, parentClass){
    id = id || 'id';
    parentId = parentId || 'parentId';
    children = children || 'children';
    className = className || 'className';
    parentClass = parentClass || 'company';
    for(var i = 0; i < parentTree.length; i++){
        let parent = parentTree[i];
        if( (parent[id] === item[parentId]) && (parent[className] === parentClass) ){
            if(!parent[children]){
                parent[children] = []
            }
            parent[children].push(item);
            // console.log("add child-"+item.name +" to "+parent.name);
            return true;
        }else{
            if(parent[children]){
                if(addToChild(parent[children],id,item,parentId,children,className,parentClass)){
                    return true;
                }
            }
        }
    }
    // parentTree.forEach(parent =>{
    // });
    return false;
}

// 获取公司部门组织架构
export function getOrgTree(callback){
    return getAllOrgTreeWithParent(true,callback);
    // let companyList = [];
    // let companyTree = [];
    // let departmentList = [];
    // let departmentTree = [];
    // let positionList = [];
    //
    // const params = {
    //     pageNum: 1,
    //     pageSize: 10000,
    //     where: null,
    //     order: '',
    // }
    // const rtCompany = findAllWithParent(params).then(response => {
    //     if(response.data.records){
    //         companyList = response.data.records;
    //         companyList.forEach(item=>{
    //             item.name = item.companyName
    //             item.className = item.classType;
    //             item.treeId = item.classType+item.id;
    //             item.nodeContent = "法人："+(item.register || '')+"";
    //             item.selfRealCount = item.realCount;
    //         });
    //         // console.log("return company");
    //     }
    //     return response;
    // });
    // const rtDepartment = listDepartment(params).then(response => {
    //     if(response.data.records) {
    //         departmentList = response.data.records;
    //         departmentList.forEach(item=> {
    //             item.className = item.classType
    //             item.treeId = item.classType+item.id;
    //             item.nodeContent = "管理岗："+(item.managerPostName || '')+"";
    //             item.selfRealCount = item.realCount;
    //         });
    //         console.log("return rtDepartment");
    //     }
    //     return response;
    // });
    // const rtPostion = listPosition(params).then(response => {
    //     positionList = response.data.records;
    //     positionList.forEach(item=> {
    //         item.className = item.classType;
    //         item.treeId = item.classType+item.id;
    //         item.nodeContent = "共"+(item.realCount || '0')+"人";
    //         item.selfRealCount = item.realCount;
    //     });
    //     // console.log("return rtPostion");
    //     return response;
    // });
    // Promise.all([rtCompany,rtDepartment,rtPostion]).then(()=>{
    // // Promise.all([rtCompany,rtDepartment]).then(()=>{
    //     console.log("all return ");
    //     companyTree = handleTree(companyList, "id", "parentId");
    //     departmentTree = handleTree(departmentList, "id", "pid");
    //     // 添加岗位到部门
    //     // console.log("add position to department...")
    //     // positionList.forEach(pos=>{
    //     //     // console.log(pos)
    //     //     addToChild(departmentTree,"id",pos,"departmentId");
    //     // });
    //     // console.log("departmentTree list:");
    //     departmentTree.forEach(dep=>{
    //         // console.log(dep)
    //         addToChild(companyTree,"id",dep,"companyId");
    //     })
    //     // console.log("department added to company");
    //     companyTree.forEach(comp=>{
    //         // console.log(comp);
    //     });
    //     // return Promise.resolve(companyTree);
    //     callback(companyTree, companyList, departmentList, positionList);
    // });
}

export function getAllOrgTreeWithParent(hasPostion, callback){
    let companyList = [];
    let companyTree = [];
    let departmentList = [];
    let departmentTree = [];
    let positionList = [];

    const params = {
        pageNum: 1,
        pageSize: 10000,
        where: null,
        order: '',
    }
    const rtCompany = findAllWithParent(params).then(response => {
        if(response.data.records){
            companyList = response.data.records;
            companyList.forEach(item=>{
                item.name = item.companyName
                item.className = item.classType;
                item.treeId = item.classType+item.id;
                item.nodeContent = "法人："+(item.register || '')+"";
                item.selfRealCount = item.realCount;
            });
            // console.log("return company");
        }
        return response;
    });
    const rtDepartment = listDepartment(params).then(response => {
        if(response.data.records) {
            departmentList = response.data.records;
            departmentList.forEach(item=> {
                item.className = item.classType
                item.treeId = item.classType+item.id;
                item.nodeContent = "管理岗："+(item.managerPostName || '')+"";
                item.selfRealCount = item.realCount;
            });
            console.log("return rtDepartment");
        }
        return response;
    });
    if(hasPostion){
        const rtPostion = listPosition(params).then(response => {
            positionList = response.data.records;
            positionList.forEach(item=> {
                item.className = item.classType;
                item.treeId = item.classType+item.id;
                item.nodeContent = "共"+(item.realCount || '0')+"人";
                item.selfRealCount = item.realCount;
            });
            // console.log("return rtPostion");
            return response;
        });
        Promise.all([rtCompany,rtDepartment,rtPostion]).then(()=>{
            // Promise.all([rtCompany,rtDepartment]).then(()=>{
            console.log("all return ");
            companyTree = handleTree(companyList, "id", "parentId");
            departmentTree = handleTree(departmentList, "id", "pid");
            departmentTree.forEach(dep=>{
                // console.log(dep)
                addToChild(companyTree,"id",dep,"companyId");
            })
            callback(companyTree, companyList, departmentList, positionList);
        });
    }else{
        Promise.all([rtCompany,rtDepartment]).then(()=>{
            console.log("all return ");
            companyTree = handleTree(companyList, "id", "parentId");
            departmentTree = handleTree(departmentList, "id", "pid");
            departmentTree.forEach(dep=>{
                addToChild(companyTree,"id",dep,"companyId");
            })
            callback(companyTree, companyList, departmentList);
        });
    }
}
