<template>
  <div>
    <el-row>
      <el-col v-for="photo in photoUrl" :key="photo.url" :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
        <el-image style="width: 100px; height: 100px" :src="photo.url" :preview-src-list="srcList">
        </el-image>
        <div>审批状态：{{photo.approved?"通过":photo.approved==null?"审核中":"未通过"}}</div>
        <div>
          <el-button type="success" plain icon="el-icon-edit" size="mini" @click="approve(photo)">通过</el-button>
          <el-button type="danger" plain icon="el-icon-delete" size="mini" @click="disapprove(photo)">拒绝</el-button>
        </div>
      </el-col>
    </el-row>
    <div style="text-align: center">
      <el-button type="primary" @click="submitForm">保 存</el-button>
      <el-button @click="cancel">取 消</el-button>
    </div>
  </div>
</template>

<script>
import {getPreviewFileUrl} from "@/api/comapi/comapi";
import { showLoading, hideLoading } from '@/utils/reqloading';
import {updateApproved} from "@/api/attendance/attMySetting";

export default {
  name: "edit",
  props:{
    row: Object,
  },
  computed: {
    srcList(){
      let urlList = [];
      this.photoUrl.forEach(item=>{
        urlList.push(item.url);
      })
      console.log("srcList")
      console.log(urlList)
      return urlList;
    }
  },
  watch: {
    row(){
      console.log("row changed")
      this.load();
    },
  },
  data() {
    return {
      form:{},
      photoUrl: [],
    }
  },
  created() {
    this.load();
  },
  methods:{
    load(){
      this.form = this.row;
      this.getPhotoPreviewUrl();
    },
    // getPhotoPreviewUrl(){
    //   this.srcList = []
    //   if(this.form.photoAddr&&this.form.photoAddr.length>0){
    //     this.form.photoAddr.forEach(item=>{
    //       getPreviewFileUrl(item.url).then(resp=>{
    //         console.log("getPreviewFileUrl return ="+resp.data)
    //         this.$set(this.form.photoAddr,"viewUrl", resp.data);
    //         this.srcList.push(resp.data)
    //       });
    //     });
    //   }
    // },
    getPhotoPreviewUrl(){
      console.log("watch form.photoAddr before:")
      console.log(this.form.photoAddr)
      let photoUrl = [];
      let promises = [];
      if(this.form.photoAddr&&this.form.photoAddr.length>0){
        this.form.photoAddr.forEach(item=>{
          promises.push(getPreviewFileUrl(item.url).then(resp=>{
            photoUrl.push({name:item.name,url:resp.data, approved:item.approved,realUrl:item.url})
          }))
        })
      }
      // 等所有返回
      Promise.all(promises).then(()=>{
        this.photoUrl = photoUrl;
        console.log("watch form.photoAddr")
        console.log(this.photoUrl)
      })
    },
    approve(photo){
      console.log("approve")
      this.$set(photo,"approved",true)
      for(let i = 0; i < this.form.photoAddr.length;i++){
        if(this.form.photoAddr[i].url == photo.realUrl){
          this.form.photoAddr[i].approved = true
          break;
        }
      }
      console.log(this.form)
    },
    disapprove(photo){
      console.log("disapprove")
      this.$set(photo,"approved",false)
      for(let i = 0; i < this.form.photoAddr.length;i++){
        if(this.form.photoAddr[i].url == photo.realUrl){
          this.form.photoAddr[i].approved = false
          break;
        }
      }
      console.log(this.form)
    },
    submitForm(){
      console.log("submitform")
      showLoading();
      console.log(this.form)
      if (this.form.id == null) {
      } else {
        updateApproved(this.form).then(response =>{
          hideLoading();
          this.$emit("close", {refresh:true,isSuccess:true});
        })

      }
    },
    cancel(){
      this.$emit("close", {refresh:false,isSuccess:true});
    },
  }
}
</script>

<style scoped>

</style>
