<template>
<div>
  <tree-transfer  :from_data='fromData' :to_data='toData' node_key="treeId" pid="treePid" :defaultProps="defaultProps"
                 @addBtn='add' @removeBtn='remove' :mode='mode' height='540px' filter openAll>
  </tree-transfer>
  <el-row>

  </el-row>
  <div slot="footer" class="dialog-footer" style="text-align: center">
      <el-button type="primary" @click="selected">确 定</el-button>
      <el-button type="primary" @click="cancel">取 消</el-button>
  </div>
</div>
</template>

<script>
import treeTransfer from 'el-tree-transfer'
import {getOrgTree} from './empOrgTree';

export default {
  name: "EmpTreeSelect",
  components: {
    // OrgTree,
    treeTransfer,
  },
  data() {
    return {
      mode: "transfer",
      title:['选择人员','待选择','已选择'],
      fromData: [],
      toData: [],
      companyId: null,
      data: [],
      value: [],
      allEmploys:[],
      defaultProps: {
        children: "children",
        label: "name"
      },
    };
  },
  created() {
    this.companyId = this.$store.getters.userInfo.companyId;
    // this.selectedCompanyId = this.$store.getters.userInfo.companyId;
    getOrgTree(this.companyId, true,(tree, companyList, departmentList, employList )=>{
      this.fromData = tree;
      console.log(this.fromData);

      this.allDepartmentOptions = [];
      const dataDepartment = { id:0,
        name:'顶级部门',
        children: []
      };
      dataDepartment.children = this.handleTree(departmentList, "id", "pid");
      this.allDepartmentOptions.push(dataDepartment);

      this.companyOptions = [];
      this.companyOptions = this.handleTree(companyList, "id", "pid");

      this.employList = employList;

    });
  },
  watch:{
    // companyId(val){
    //   findSimpleByCompay(val).then(response=>{
    //     this.allEmploys = response.data;
    //   })
    // },
  },
  methods: {
    selected(){
      console.log(this.toData);

      this.$emit("selected", {data:this.toData});
    },
    cancel(){
      this.$emit("cancel");
    },

    // 切换模式 现有树形穿梭框模式transfer 和通讯录模式addressList
    changeMode() {
      if (this.mode == "transfer") {
        this.mode = "addressList";
      } else {
        this.mode = "transfer";
      }
    },
    // 监听穿梭框组件添加
    add(fromData,toData,obj){
      // 树形穿梭框模式transfer时，返回参数为左侧树移动后数据、右侧树移动后数据、移动的        {keys,nodes,halfKeys,halfNodes}对象
      // 通讯录模式addressList时，返回参数为右侧收件人列表、右侧抄送人列表、右侧密送人列表
      console.log("fromData:", fromData);
      console.log("toData:", toData);
      console.log("obj:", obj);
    },
    // 监听穿梭框组件移除
    remove(fromData,toData,obj){
      // 树形穿梭框模式transfer时，返回参数为左侧树移动后数据、右侧树移动后数据、移动的{keys,nodes,halfKeys,halfNodes}对象
      // 通讯录模式addressList时，返回参数为右侧收件人列表、右侧抄送人列表、右侧密送人列表
      console.log("fromData:", fromData);
      console.log("toData:", toData);
      console.log("obj:", obj);
    }
  }

}
</script>

<style scoped>

</style>
