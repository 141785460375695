<template>
  <div>
    <AttLeaveEdit type="apply" :isShowClose="true"></AttLeaveEdit>
  </div>
</template>

<script>
import AttLeaveEdit from "./attLeaveEdit";
export default {
  name: "apply",
  components:{
    AttLeaveEdit,
  }
}
</script>

<style scoped>

</style>
