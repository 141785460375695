import store from '@/store'

export function checkRole(checkRoles) {
    const value = checkRoles
    const roles = store.getters && store.getters.roles

    if (value && value instanceof Array) {
        if (value.length > 0) {
            const permissionRoles = value
            const hasRole = roles.some(role => {
                return permissionRoles.includes(role)
            })
            return hasRole;
        }
    } else {
        throw new Error(`need roles! Like v-role="['admin','employ']"`)
    }
}
