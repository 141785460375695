import request from '@/utils/request'

// 查询菜单管理列表
export function listMenu(query) {
    return request({
        url: '/system/menu/findAll',
        method: 'post',
        data: query
    })
}

// 查询菜单管理详细
export function getMenu(id) {
    return request({
        url: '/system/menu/findOne?id='+id,
        method: 'post'
    })
}

// 新增菜单管理
export function addMenu(data) {
    return request({
        url: '/system/menu/add',
        method: 'post',
        data: data
    })
}

// 修改菜单管理
export function updateMenu(data) {
    return request({
        url: '/system/menu/edit',
        method: 'post',
        data: data
    })
}

// 删除菜单管理
export function delMenu(ids) {
    return request({
        url: '/system/menu/remove',
        method: 'post',
        data: ids,
    })
}

// 导出菜单管理
export function exportMenu(query) {
    return request({
        url: '/system/menu/export',
        method: 'post',
        data: query
    })
}
