<template>
  <div>
    <ScoreList :listType="listType"></ScoreList>
  </div>
</template>

<script>
// import ScoreList from "@/views/pages/kpi/components/scoreList";
import ScoreList from "../components/socreList";

export default {
  name: "index",
  components: {
    ScoreList,
  },
  data() {
    return {
      listType: "scoring"
    };
  },
  created() {
  },
  methods:{

  },
}
</script>

<style scoped>

</style>
