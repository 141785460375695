import request from '@/utils/request'
// Find all test papers
export function findAllTestPapers(query) {
    return request({
        url: '/TrainingTestPaper/findAll',
        method: 'post',
        data: query
    })
}

// Find all test papers
export function findAllPaperNames(query) {
    return request({
        url: '/TrainingTestPaper/findAllPaperNames',
        method: 'post',
        data: query
    })
}


// Find  test paper
export function findTestPaper(id) {
    return request({
        url: '/TrainingTestPaper/findOne?id=' + id,
        method: 'post',
    })
}

// Find  test paper
export function findOneWithAnswer(id,testCount,courseId,userId) {
    return request({
        url: '/TrainingTestPaper/findOneWithAnswer?id=' + id+'&testCount='+testCount+'&courseId='+courseId+'&userId='+userId,
        method: 'post',
    })
}

// Find  test paper
export function findlastTest(id,courseId,userId) {
    return request({
        url: '/TrainingTestPaper/findlastTest?id=' + id+'&courseId='+courseId+'&userId='+userId,
        method: 'post',
    })
}


// Add new test paper
export function addTestPaper(data) {
    return request({
        url: '/TrainingTestPaper/add',
        method: 'post',
        data: data
    })
}

// update test paper
export function updateTestPaper(data) {
    return request({
        url: '/TrainingTestPaper/edit',
        method: 'post',
        data: data
    })
}

// delete test paper
export function deleteTestPaper(ids) {
    return request({
        url: '/TrainingTestPaper/remove',
        method: 'post',
        data: ids
    })
}

