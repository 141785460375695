<template>
	<div >
		<el-form class="employ" label-width="100px">
			<el-row gutter="24">
				<el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6" class="mb0">
					<el-form-item label="公司名称" >
						<el-select v-model="companySelect" clearable @clear="handleClearable" placeholder="请选择" collapse-tags ref="companySelected">
							<el-option :value="companyId" :label="companySelect" style="height: auto">
								<el-tree
									:data="companyOptions"
									:props="defaultProps"
									:expand-on-click-node="false"
									ref="tree"
									show-checkbox
									node-key="id"
									default-expand-all
									@node-click="handleNodeClick"
									@check="handleNodeClick"
									highlight-current
								/>
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6" class="mb0">
					<el-form-item label="起始时间">
						<el-date-picker v-model="queryParameters.startDate" value-format="yyyy-MM-dd 00:00:00" align="left" type="date" placeholder="选择日期">
						</el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6" class="mb0">
					<el-form-item label="终止时间">
						<el-date-picker v-model="queryParameters.endDate" value-format="yyyy-MM-dd 24:00:00" align="left" type="date" placeholder="选择日期">
						</el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6" class="mb0">
					<el-form-item label="">
					<el-button type="primary" :loading="loading" icon="el-icon-postcard" @click="statistics()">统计</el-button>
					</el-form-item>
				</el-col>
			</el-row>

		</el-form>
		<el-table v-loading="loading" :data="recruitResults" style="width: 100%" stripe border>
			<el-table-column label="招聘职位" align="center" prop="positionName" min-width="180" sortable show-overflow-tooltip="true"> </el-table-column>
			<el-table-column label="投递量" align="center" prop="appliedCount" min-width="100"  sortable> </el-table-column>
			<el-table-column label="面试量" align="center" prop="interviewCount" />
			<el-table-column label="录用量" align="center" prop="hireCount" min-width="100"  sortable />
			<el-table-column label="职位创建日期" align="center" prop="createTime" width="180px" sortable />
		</el-table>
		<pagination v-show="total > 0" :total="total" :page.sync="queryParameters.pageNum" :limit.sync="queryParameters.pageSize" @pagination="query" />
	</div>
</template>

<script>
import { getTreeselectCompany } from '@/api/org/company';
import { statisticsRecruit } from '@/api/recruit/positionManagement';
import Pagination from '@/components/Pagination';
export default {
	name: 'recruitAssessment',
	components: {
		Pagination,
	},
	data() {
		return {
			loading: false,
			recruitResults: [],
			queryParameters: {
				companyId: '',
				startDate: '',
				endDate: '',
				pageSize: 10,
				pageNum: 1,
			},
			where: {
				query: '',
				filters: [],
			},

			total: 0,
			companySelect: '',
			companyId: '',
			isCompnayVisible: false,
			// 公司树选项
			companyOptions: [],
			companyList: [],
			defaultProps: {
				children: 'children',
				label: 'companyName',
			},
		};
	},

	mounted() {
		getTreeselectCompany(null, (list, options) => {
			this.companyList = list;
			if (this.companyList.length > 1) {
				this.isCompnayVisible = true;
			}
			this.companyOptions = options;
		});
	},

	methods: {
		// 节点单击事件
		handleNodeClick(data) {
			this.companyId = data.id;
			this.queryParameters.companyId = data.id;
			this.companySelect = data.companyName;
			let selectArray = [];
			selectArray.push(this.companyId);
			this.$refs.tree.setCheckedKeys(selectArray);
			this.$refs.companySelected.blur();
		},

		handleClearable() {
			this.$refs.tree.setCheckedKeys([]);
			this.companyId = null;
			this.companySelect = '';
		},
		statistics() {
			let filter = {};
			if (this.queryParameters.companyId) {
				filter['companyId'] = {
					type: 'eq',
					value: this.queryParameters.companyId,
				};
			}

			if (this.queryParameters.startDate) {
				filter['startDate'] = {
					type: 'eq',
					value: this.queryParameters.startDate,
				};
			}

			if (this.queryParameters.endDate) {
				filter['endDate'] = {
					type: 'eq',
					value: this.queryParameters.endDate,
				};
			}

			const filters = this.parseFilters(filter);
			if (filters && filters.length > 0) {
				this.where.filters = filters;
			} else {
				// 如果过滤条件为空，设置空数组
				this.where.filters = [];
			}
			const params = {
				pageNum: this.queryParameters.pageNum,
				pageSize: this.queryParameters.pageSize,
				where: this.where,
				order: '',
			};
			this.loading = true;
			statisticsRecruit(params)
				.then((response) => {
					this.recruitResults = response.data.records;
					this.total = response.data.total;
					this.loading = false;
				})
				.catch((err) => {
					this.loading = false;
					this.msgError('搜索失败，' + err);
				});
		},
	},
};
</script>

<style lang="scss" scoped>
</style>