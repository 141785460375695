<template>
  <div>
    <el-tabs type="border-card">
      <el-tab-pane label="任务处理">

        <el-divider>申 请 表 单</el-divider>
        <AttLeaveEdit ref="attLeave" :type="taskStep" :id="businessKey" :isReadOnly="true" :isShowClose="false"></AttLeaveEdit>
        <el-divider>审 批 意 见</el-divider>
        <el-form ref="form" :model="form" :rules="rules" label-width="150px">
          <el-row :gutter="35">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
              <el-form-item label="是否同意" prop="approved" :rules="{required: true,message: '是否同意不能为空', trigger: 'blur' }">
                <el-radio-group :readonly="isReadOnly" v-model="form.approved">
                  <el-radio label="0">不同意</el-radio>
                  <el-radio label="1">同意</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">
              <el-form-item label="审批意见" prop="content">
                <el-input :readonly="isReadOnly" v-model="form.content" type="textarea" placeholder="请填写审批意见"/>
              </el-form-item>
            </el-col>
            <el-col :xs="24"
                    :sm="24" :md="24" :lg="24" :xl="24" class="mb0" STYLE="text-align: center">
              <el-form-item>
                <el-button v-if="isShowSubmit" type="primary" @click="submitForm"
                           v-permission="['/bpm/attLeave/apply', '/bpm/attLeave/approve']">提 交
                </el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="历史跟踪">
        <el-steps :active="stepActive" simple finish-status="success">
          <el-step :title="stepTitle(item)" icon="el-icon-edit" v-for="(item) in handleTask.historyTask"></el-step>
        </el-steps>
        <br/>
        <el-steps direction="vertical" :active="stepActive" space="65px">
          <el-step :title="stepTitle(item)" :description="stepDes(item, handleTask)"
                   v-for="(item) in handleTask.historyTask"></el-step>
        </el-steps>
      </el-tab-pane>
      <el-tab-pane label="流程图">
        <div v-html="highlightSvgUrl"></div>
      </el-tab-pane>
    </el-tabs>

  </div>

</template>

<script>
import permission from '@/directive/permission/index.js'; // 权限判断指令
import AttLeaveEdit from "./attLeaveEdit";
import {completeTask, getAttLeave} from "@/api/bpm/attLeave.js";
import {taskSteps, getHighlightImg} from "@/api/bpm/todoList.js";
import { showLoading, hideLoading } from '@/utils/reqloading';
import {getStepDes, getStepTitle} from "@/views/pages/bpm/bpmUtils";

export default {
  name: "approve",
  components: {
    AttLeaveEdit,
  },
  directives: {permission},
  computed: {
    stepActive: function () {
      let idx = 0;
      for (let i = 0; i < this.handleTask.historyTask.length; i++) {
        if (this.handleTask.historyTask[i].status === 1) {
          idx = idx + 1;
        } else {
          break;
        }
      }
      return idx;
    },
    stepTitle() {
      return getStepTitle;
    },
    stepDes() {
      return getStepDes;
    }
  },

  data() {
    return {
      handleTask: {
        historyTask: []
      },
      highlightSvgUrl: "",
      isReadOnly: false,
      isShowSubmit: true,
      row: null,
      taskId: null,
      businessKey: null,
      processInstanceId: null,
      taskStep: null,
      form: {},
      // 表单校验
      rules: {},
      selfAppraisal: {
        content: null,
      },

    }
  },
  created() {
    // user router
    console.log("this.$route.query=");
    console.log(this.$route.query);
    const {businessKey, id, processInstanceId, taskDefinitionKey} = this.$route.query;
    if(!processInstanceId){
      console.log("processInstanceId is empty...");
      return;
    }
    if(!businessKey){
      console.log("businessKey is empty...");
      return;
    }
    this.businessKey = businessKey;
    this.taskStep = taskDefinitionKey;
    this.taskId = id;
    this.processInstanceId = processInstanceId;
    this.getHighlightImg(this.processInstanceId);
    // getAttLeave(businessKey).then(response => {
    //   this.form = response.data;
    //   if (!this.form.selfAppraisal) {
    //     this.form.selfAppraisal = this.selfAppraisal;
    //   }
    // });

    taskSteps(this.processInstanceId).then(response => {
      this.handleTask = response.data;
    });
    this.isShowSubmit = true;

  },
  methods: {
    getHighlightImg(processInstanceId) {
      getHighlightImg(processInstanceId).then(response => {
        // console.log(response)
        this.highlightSvgUrl = response.data;
      })
    },
    close() {

    },
    // 取消按钮
    cancel() {
      // this.open = false;
      // this.reset();
      this.$emit("close", {refresh: false, isSuccess: true});
    },

    /** 提交按钮 */
    submitForm() {
      this.form.taskId = this.taskId;
      this.form.id = this.businessKey;
      if(!this.form.approved){
        this.msgError("是否同意必须选择！");
        return;
      }
      console.log(this.$refs['attLeave'].form)
      this.form.type = this.$refs['attLeave'].form.type;
      this.form.employId = this.$refs['attLeave'].form.employId;
      this.form.days = this.$refs['attLeave'].form.days;
      this.form.annualDays = this.$refs['attLeave'].form.annualDays;
      this.form.lastAnnual = this.$refs['attLeave'].form.lastAnnual;
      this.form.otLeave = this.$refs['attLeave'].form.otLeave;
      if(this.taskStep == 'hrApprove'){
        if(!this.form.type){
          this.msgError("请设置休假类型");
          return false;
        }
        if('年假' == this.form.type && this.form.days > this.form.annualDays){
          this.msgError("休假天数不能大于剩余可休天数");
          return false;
        } else if('往年结余年假' == this.form.type && this.form.days > this.form.lastAnnual){
          this.msgError("休假天数不能大于剩余可休天数");
          return false;
        } else if('调休假' == this.form.type && this.form.days > this.form.otLeave){
          this.msgError("休假天数不能大于剩余可休天数");
          return false;
        }
      }

      showLoading();
      completeTask(this.form).then(response => {
        this.form = response.data;
        hideLoading();
        this.msgSuccess("审批提交成功");
        this.isShowSubmit = false;
        this.bus.$emit('onCurrentContextmenuClick', {
          id: 1,
          path: this.$route.path,
        });

      });
    },

  },
}
</script>

<style scoped>

</style>
