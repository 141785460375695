<template>
  <div class="app-container">
    <el-tabs v-model="activeName">
      <el-tab-pane label="注册入职" name="regist">
    <el-row>
      <el-col>
        <el-steps :active="active" finish-status="success">
          <el-step title="选择用户" description=""></el-step>
          <el-step title="部门岗位" description=""></el-step>
          <el-step title="入职结果" description=""></el-step>
        </el-steps>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <template v-if="active==1">
          <user :whereFrom="userWhereFrom" @select="selectUser"></user>
        </template>
        <template v-if="active==2">
          <div style="text-align: center">
            <el-form ref="form" :model="form" :rules="rules" label-width="80px">
              <el-row :gutter="35">
                <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8" >
                  <el-form-item label="所属部门" prop="departmentId">
                    <treeselect v-model="form.departmentId" :options="departmentOptions"
                                :normalizer="normalizerDepartment" placeholder="请选择所属部门"
                                @select="selectDepartment"/>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8" >
                  <el-form-item label="所属岗位" prop="positionId">
<!--                    <treeselect-->
<!--                        v-model="form.positionId"-->
<!--                        :options="positionOptions"-->
<!--                        :normalizer="normalizerPosition"-->
<!--                        placeholder="请选择岗位"-->
<!--                    />-->
                    <el-select clearable v-model="form.positionId" placeholder="请选择岗位" style="width:100%">
                      <el-option
                          v-for="dict in positionList"
                          :key="dict.id"
                          :label="dict.name"
                          :value="dict.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8" >
                  <el-form-item label="角色" prop="roleIds">
                    <el-select v-model="form.roleIds" multiple placeholder="请选择角色" @change="chooseDTChange">
                      <el-option
                          v-for="item in roleOptions"
                          :key="item.id"
                          :label="item.zhName"
                          :value="item.id">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>

              </el-row>
                <el-row :gutter="35">
              </el-row>
            </el-form>
            <el-divider> 选择的用户</el-divider>
            <el-table :data="userList" :row-class-name="tableRowClassName" stripe border>
              <el-table-column label="用户名" align="center" prop="username" sortable/>
              <el-table-column label="姓名" align="center" prop="name" sortable/>
              <el-table-column label="手机号" align="center" prop="mobile" width="130"  sortable/>
              <el-table-column label="角色" align="center" width="210"  sortable>
                <template slot-scope="scope">
                  <el-tag
                      :key="tag.id"
                      v-for="tag in scope.row.roles"
                      :disable-transitions="false"
                  >
                    {{ tag.zhName }}
                  </el-tag>
                </template>
              </el-table-column>
            </el-table>
            <el-button type="primary" @click="submitForm" style="margin-top:10px;">保 存</el-button>
            <el-button @click="returnClick" style="margin-top:10px;">返 回</el-button>
          </div>
        </template>
        <template v-if="active==3">
          <div align="center" style="margin-top: 60px; font-size: xx-large"><H1><B>入职办理完成！</B></H1></div>
          <div align="center" style="margin-top: 60px; font-size: xx-large"><el-button @click="returnClick" style="margin-top:10px;">返 回</el-button></div>
          <div v-if="existEmploy.length > 0">
            <el-divider><h2>以下员工不能办理入职:</h2></el-divider>
            <el-table :data="existEmploy" :row-class-name="tableRowClassName" :cell-style="cellStyle" style="width:100%" stripe border >
              <el-table-column label="入职注册用户信息" align="center">
                <el-table-column label="注册账号" align="center" prop="user.username"/>
                <el-table-column label="注册姓名" align="center" prop="user.name"/>
                <el-table-column label="注册身份证号" align="center" prop="user.idNum" width="130"/>
              </el-table-column>
              <el-table-column label="证件号相同的员工信息" align="center">
                <el-table-column label="员工证件类型" align="center" prop="idType" width="130"/>
                <el-table-column label="员工身份证号" align="center" prop="idNum" width="130"/>
                <el-table-column label="员工姓名" align="center" prop="name"/>
                <el-table-column label="员工工号" align="center" prop="wrokNum"/>
                <el-table-column label="员工状态" align="center" prop="empStatus"/>
              </el-table-column>
<!--              <el-table-column label="员工状态" align="center" prop="empStatus"/>-->
<!--              <el-table-column label="员工状态" align="center" prop="empStatus"/>-->
            </el-table>
          </div>
        </template>
      </el-col>
    </el-row>
      </el-tab-pane>
      <el-tab-pane label="直接入职" name="hrEntry">
        <Employ opType="hrEntry"></Employ>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import {addEmploy} from "@/api/employ/employ";
// import {validUsername} from '@/utils/validate';
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import User from '@/views/pages/system/user/list';
import {getTreeselectDepartment} from "@/api/org/department";
import {getTreeselectPosition} from "@/api/org/position";
import { listEntryRole } from "@/api/sys/role";
import { showLoading, hideLoading } from '@/utils/reqloading';
import Employ from './list';

export default {
  name: "add",
  components: {
    Treeselect,
    User,
    Employ,
  },
  data() {
    return {
      activeName: 'regist',
      active: 1,
      userWhereFrom: 'employ',
      userList: [],
      // 表单参数
      form: {
        userIds: [],
      },
      companyId: null,
      departmentOptions: [],
      postionEmpty: '请先选择部门',
      // 岗位管理表格数据
      positionList: [],
      //
      postLevelOptions: [],
      // 角色选项
      roleOptions: [],

      //
      existEmploy: [],

      // 表单校验
      rules: {
        departmentId: [
          {
            required: true,
            message: "所属部门不能为空", trigger: "blur"
          }
        ],
        positionId: [
          {
            required: true,
            message: "岗位不能为空", trigger: "blur" }
        ],
        // postLevel: [
        //   {
        //     required: true,
        //     message: "岗级id不能为空", trigger: "blur" }
        // ],
      }
    }
  },
  created() {
    // user router
    const {users, companyId} = this.$route.params;
    if (users) this.active = 2;
    this.companyId = companyId;
    this.departmentOptions = [];
    this.userList = users;
    this.getDepartment(this.companyId);

  },
  methods: {
    chooseDTChange() {
      this.$forceUpdate();
    },
    tableRowClassName({row, rowIndex}) {
      if (row.userEnabled === 1) {
        return 'success-row';
      } else if (row.userEnabled === 0) {
        return 'warning-row';
      }
      return '';
    },
    registerClose() {
      this.active = 2;
    },
    // 新增员工选择
    selectUser(params) {
      const {users, companyId} = params;
      if (users) this.active = 2;
      this.companyId = companyId;
      this.departmentOptions = [];
      this.userList = users;
      this.getDepartment(this.companyId);
      this.getPositionList(this.companyId);
      this.getRoles();
      // this.active = 2;
    },
    /** 转换部门数据结构 */
    normalizerDepartment(node) {
      return this.normalizer(node);
    },
    normalizerPosition(node) {
      return this.normalizer(node);
    },
    /** 查询公司下拉树结构 */
    getDepartment(companyId) {
      getTreeselectDepartment(companyId, false, (list, options) =>{
        this.departmentList = list;
        this.departmentOptions = options;
      });
    },
    selectDepartment(node, instanceId) {
      // this.getPositionList(node.id);
    },
    getPositionList(companyId) {
      getTreeselectPosition(companyId, false, (list, options) =>{
        this.positionList = list;
        this.positionOptions = options;
      });
    },
    getRoles(){
      return listEntryRole().then(response => {
        console.log(response.data);
        this.roleOptions = response.data;
        return response;
      });

    },

    submitForm() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          let ids = this.userList.map(item => item.id);
          // console.log(ids);
          this.form.userIds = ids;
          this.form.companyId = this.companyId;
          this.form.empStatus = '试用';
          showLoading();
          this.existEmploy = [];
          addEmploy(this.form).then(response => {
            this.msgSuccess("添加员工成功");
            console.log("*************************")
            console.log(response.data)
            this.existEmploy = response.data;
            this.active = 3;
            hideLoading();
            // this.getList();
          });
        }
      });
    },
    returnClick() {
      this.active = 1;
    },

    cellStyle({ row, column, rowIndex, columnIndex }){
      if(columnIndex > 2){
        return 'color: #FF3B4B';
      }
    },
  },
}
</script>

<style scoped>

</style>
