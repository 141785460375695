<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px">
      <el-form-item label="调动人员" prop="transferName">
        <el-input
            v-model="queryParams.transferName"
            placeholder="请输入调动人员姓名"
            clearable
            size="small"
            @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="调出部门" prop="outDepartmentId">
        <treeselect v-model="queryParams.outDepartmentId" :options="departmentOptions"
                    :normalizer="normalizerDepartment" placeholder="请选择调出部门" style="width: 215px"/>
      </el-form-item>
      <el-form-item label="调出岗位" prop="outPositionId">
        <el-select clearable v-model="queryParams.outPositionId" placeholder="请选择岗位" style="width:100%">
          <el-option
              v-for="dict in positionList"
              :key="dict.id"
              :label="dict.name"
              :value="dict.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="调入部门" prop="inDepartmentId">
        <treeselect v-model="queryParams.inDepartmentId" :options="departmentOptions"
                    :normalizer="normalizerDepartment" placeholder="请选择调出部门" style="width: 215px"/>
      </el-form-item>
      <el-form-item label="调入岗位" prop="inPositionId">
        <el-select clearable v-model="queryParams.inPositionId" placeholder="请选择岗位" style="width:100%">
          <el-option
              v-for="dict in positionList"
              :key="dict.id"
              :label="dict.name"
              :value="dict.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="调入时间" prop="transferDate">
        <el-date-picker clearable size="small"
                        v-model="queryParams.transferDate"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="选择调入新岗位时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="临时借调" prop="isTemp">
        <el-select v-model="queryParams.isTemp" clearable placeholder="是否临时借调" size="small">
          <el-option v-for="item in dictYesNo" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="调回时间" prop="returnDate">
        <el-date-picker clearable size="small"
                        v-model="queryParams.returnDate"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="选择调回时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="已执行" prop="batchExe">
        <el-select v-model="queryParams.batchExe" clearable placeholder="是否已执行" size="small">
          <el-option v-for="item in getDictDatas(DICT_TYPE.BPM_TRANSFER_BATCH_EXE)" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col v-if="canApply" :span="1.5">
        <el-button
            type="primary"
            plain
            icon="el-icon-plus"
            size="mini"
            @click="handleAdd"
            v-permission="['/bpm/transfer/apply']"
        >申请调动
        </el-button>
      </el-col>
<!--      <el-col :span="1.5">-->
<!--        <el-button-->
<!--            type="success"-->
<!--            plain-->
<!--            icon="el-icon-edit"-->
<!--            size="mini"-->
<!--            :disabled="single"-->
<!--            @click="handleUpdate"-->
<!--            v-permission="['/bpm/transfer/approve']"-->
<!--        >修改-->
<!--        </el-button>-->
<!--      </el-col>-->
<!--      <el-col :span="1.5">-->
<!--        <el-button-->
<!--            type="danger"-->
<!--            plain-->
<!--            icon="el-icon-delete"-->
<!--            size="mini"-->
<!--            :disabled="multiple"-->
<!--            @click="handleDelete"-->
<!--            v-permission="['/bpm/transfer/remove']"-->
<!--        >删除-->
<!--        </el-button>-->
<!--      </el-col>-->
<!--      <el-col :span="1.5">-->
<!--        <el-button-->
<!--            type="warning"-->
<!--            plain-->
<!--            icon="el-icon-download"-->
<!--            size="mini"-->
<!--            @click="handleExport"-->
<!--            v-permission="['/bpm/transfer/export']"-->
<!--        >导出-->
<!--        </el-button>-->
<!--      </el-col>-->
      <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>
    </el-row>

    <el-table v-loading="loading" :data="transferList" @selection-change="handleSelectionChange" style="width:100%" stripe border>
      <el-table-column type="selection" width="55" align="center"/>
      <el-table-column label="发起人" align="center" prop="name" sortable width="auto" min-width="150"  :show-overflow-tooltip='true'/>
      <el-table-column label="调动人员" align="center" prop="transferUserIdNames" sortable width="auto" min-width="350" :show-overflow-tooltip='true'/>
      <el-table-column label="调出部门" align="center" prop="outDepartmentName" sortable width="auto" min-width="150"  :show-overflow-tooltip='true'/>
      <el-table-column label="调出岗位" align="center" prop="outPositionName" sortable width="auto" min-width="150" :show-overflow-tooltip='true'>
        <template slot-scope="scope">
          {{positionFormat(scope.row.outPositionId, null)}}
        </template>
      </el-table-column>
      <el-table-column label="调入部门" align="center" prop="inDepartmentName" sortable width="auto" min-width="150"  :show-overflow-tooltip='true'/>
      <el-table-column label="调入岗位" align="center" prop="inPositionName" sortable width="auto" min-width="150"  :show-overflow-tooltip='true'>
        <template slot-scope="scope">
          {{positionFormat(scope.row.inPositionId, null)}}
        </template>
      </el-table-column>
      <el-table-column label="调入时间" align="center" prop="transferDate" sortable width="auto" min-width="180"  :show-overflow-tooltip='true'/>
      <el-table-column label="临时借调" align="center" prop="isTemp" sortable width="auto" min-width="150"  :show-overflow-tooltip='true'>
        <template slot-scope="scope">
          {{scope.row.isTemp?getDictDataLabel(DICT_TYPE.COM_YES_NO,scope.row.isTemp):"未知"}}
        </template>
      </el-table-column>
      <el-table-column label="调回时间" align="center" prop="returnDate" sortable width="auto" min-width="180"  :show-overflow-tooltip='true'/>
      <el-table-column label="调动原因" align="center" prop="transferReason" sortable width="auto" min-width="230"  :show-overflow-tooltip='true'/>
      <el-table-column label="同意" align="center" prop="approved" sortable width="auto" min-width="80" :show-overflow-tooltip='true'>
        <template slot-scope="scope">
          {{scope.row.approved?getDictDataLabel(DICT_TYPE.COM_APPROVE_NO,scope.row.approved):"处理中"}}
        </template>
      </el-table-column>
<!--      <el-table-column label="流程id" align="center" prop="processInstanceId"/>-->
      <el-table-column label="执行情况" align="center" prop="batchExe" sortable width="auto" min-width="100"  :show-overflow-tooltip='true'>
        <template slot-scope="scope">
          {{getDictDataLabel(DICT_TYPE.BPM_TRANSFER_BATCH_EXE,scope.row.batchExe)}}
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width" width="88" fixed="right">
        <template slot-scope="scope">
          <template v-if="scope.row.processInstanceId">
            <el-dropdown trigger="click" @command="handleCommand">
            <span class="el-dropdown-link">
              菜单<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="{row: scope.row, cmd: 'process' } " icon="el-icon-guide"
                                  v-permission="['/bpm/transfer/approve','/bpm/transfer/apply']">审批进度</el-dropdown-item>
                <el-dropdown-item :command="{row:scope.row, cmd: 'view' }" icon="el-icon-view"
                                  v-permission="['/bpm/transfer/approve','/bpm/transfer/apply']">查看</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
<!--          <el-button v-if="scope.row.processInstanceId"-->
<!--              size="mini"-->
<!--              type="text"-->
<!--              icon="el-icon-edit"-->
<!--              @click="showFlowDetail(scope.row)"-->
<!--              v-permission="['/bpm/transfer/approve','/bpm/transfer/apply']"-->
<!--          >审批进度-->
<!--          </el-button>-->
          <el-button v-if="!scope.row.processInstanceId"
              size="mini"
              type="text"
              icon="el-icon-view"
              @click="showTask(scope.row)"
              v-permission="['/bpm/transfer/approve','/bpm/transfer/apply']"
          >查看
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
        v-show="total>0"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
    />

    <!-- 添加或修改调动调岗对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="80%" append-to-body>
      <TransferEdit :type="businessType" :row="businessKey" :isReadOnly="true" :isShowClose="true" @close="open = false"></TransferEdit>
    </el-dialog>
    <el-dialog :title="title" :visible.sync="openFlowDetail" width="80%" append-to-body>
      <FlowDetail :processInstanceIdIn="processInstanceId"></FlowDetail>
      <div style="text-align: center"><el-button @click="openFlowDetail=false">关 闭</el-button></div>
    </el-dialog>
  </div>
</template>

<script>
import {
  listTransfer,
  getTransfer,
  delTransfer,
  addTransfer,
  updateTransfer,
  exportTransfer } from "@/api/bpm/transfer";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import RightToolbar from "@/components/RightToolbar"
import permission from '@/directive/permission/index.js' // 权限判断指令
import Pagination from "@/components/Pagination";
import FlowDetail from "../todo/flowDetail";
import {checkRole} from "@/utils/role";
import {DICT_TYPE, getDictDataLabel, getDictDatas} from "@/utils/dict";
import {getTreeselectDepartment} from "@/api/org/department";
import { getTreeselectPosition } from '@/api/org/position';
import { getEmployByUserId} from '@/api/employ/employ';
import TransferEdit from './transferEdit';

// 表查询配置
const dbOrderBy = ''

export default {
  name: "Transfer",
  components: {
    Treeselect,
    RightToolbar,
    Pagination,
    FlowDetail,
    TransferEdit,
  },
  directives: { permission },
  data() {
    return {
      // 遮罩层
      companyId: null,
      loading: true,
      // 查询参数
      where: {
        query: '',
        filters: []
      },

      // 部门管理树选项
      departmentList: [],
      departmentOptions: [],
      positionList: [],
      positionOptions: [],

      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 调动调岗表格数据
      transferList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      openFlowDetail: false,
      open: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        orderby: dbOrderBy,
        name: null,
        outDepartmentId: null,
        outPositionId: null,
        inDepartmentId: null,
        inPositionId: null,
        transferDate: null,
        isTemp: null,
        returnDate: null,
        transferReason: null,
        batchExe: null,
        transferName: null,
      },
      businessKey: null,
      businessType: null,
      processInstanceId: null,
      canApply: true,
      dictYesNo: getDictDatas(DICT_TYPE.COM_YES_NO),
      dictApproveNo: getDictDatas(DICT_TYPE.COM_APPROVE_NO),
    };
  },
  created() {
    this._filter = {};
    this.companyId = this.$store.getters.userInfo.companyId;
    console.log("create")
    this.getEmploy();
    this.getDepartment(this.companyId);
    this.getPositison(this.companyId);
    this.getList();
  },
  methods: {
    getEmploy(){
      console.log(this.$store.getters.userInfo);
      let id = this.$store.getters.userInfo.id;
      // getEmployByUserId(id,null);
      getEmployByUserId(id, (e) => {
        if(!e){
          this.canApply = false;
        }
      });
    },
    /** 查询调动调岗列表 */
    getQuery(){
      this._filter = {};
      // 人事和经理可以查看所有人申请，员工只能看自己申请
      const role = ["Admin","GM","HRManager","CAdmin","ShiftJob"];
      if(!checkRole(role)){
        this._filter['userId'] = {
          type: 'eq',
          value: this.$store.getters.userInfo.id,
        }
      }
      if(this.queryParams.name) {
        this._filter['name'] = {
          type: 'search',
          value: this.queryParams.name,
        }
      }
      if(this.queryParams.transferName) {
        this._filter['transferUserIdNames'] = {
          type: 'search',
          value: this.queryParams.transferName,
        }
      }
      if(this.queryParams.outDepartmentId) {
        this._filter['outDepartmentId'] = {
          type: 'eq',
          value: this.queryParams.outDepartmentId,
        }
      }
      if(this.queryParams.outPositionId) {
        this._filter['outPositionId'] = {
          type: 'eq',
          value: this.queryParams.outPositionId,
        }
      }
      if(this.queryParams.inDepartmentId) {
        this._filter['inDepartmentId'] = {
          type: 'eq',
          value: this.queryParams.inDepartmentId,
        }
      }
      if(this.queryParams.inPositionId) {
        this._filter['inPositionId'] = {
          type: 'eq',
          value: this.queryParams.inPositionId,
        }
      }
      if(this.queryParams.transferDate) {
        this._filter['transferDate'] = {
          type: 'eq',
          value: this.queryParams.transferDate,
        }
      }
      if(this.queryParams.isTemp) {
        this._filter['isTemp'] = {
          type: 'eq',
          value: this.queryParams.isTemp,
        }
      }
      if(this.queryParams.returnDate) {
        this._filter['returnDate'] = {
          type: 'date',
          value: this.queryParams.returnDate,
        }
      }
      if(this.queryParams.transferReason) {
        this._filter['transferReason'] = {
          type: 'search',
          value: this.queryParams.transferReason,
        }
      }
      if(this.queryParams.batchExe) {
        this._filter['batchExe'] = {
          type: 'eq',
          value: this.queryParams.batchExe,
        }
      }
      const filters = this.parseFilters(this._filter)
      if (filters && filters.length > 0) {
        this.where.filters = filters
      } else {
        // 如果过滤条件为空，设置空数组
        this.where.filters = []
      }
      const params = {
        pageNum: this.queryParams.pageNum,
        pageSize: this.queryParams.pageSize,
        where: this.where,
        order: 'id desc'
      }

      return params;
    },
    getList() {
      this.loading = true;
      const params = this.getQuery();
      console.log(params)
      listTransfer(params).then(response => {
        this.transferList = response.data.records;
        this.total = response.data.total;
        this.loading = false;
      });
    },
    handleCommand(command){
      if(command.cmd == "process"){
        this.showFlowDetail(command.row);
      }else if(command.cmd === "view"){
        this.showTask(command.row);
      }else {

      }
    },
    // 显示任务
    showTask(row){
      this.businessType = "view";
      this.businessKey = row;
      this.open = true;
    },
    showFlowDetail(row){
      console.log(row.processInstanceId);
      this.processInstanceId = row.processInstanceId;
      this.openFlowDetail = true;
    },

    /** 转换部门数据结构 */
    normalizerDepartment(node) {
      return this.normalizer(node);
    },

    /** 查询公司下拉树结构 */
    getDepartment(companyId) {
      getTreeselectDepartment(companyId, false, (list, options) => {
        this.departmentList = list;
        this.departmentOptions = options;
      });
    },
    normalizerPosition(node) {
      return this.normalizer(node);
    },
    /** 查询岗位下拉树结构 */
    getPositison(companyId) {
      getTreeselectPosition(companyId, false, (list, options) => {
        this.positionList = list;
        this.positionOptions = options;
      });
    },
    positionFormat(id, column) {
      console.log(id);
      for(var i = 0; i < this.positionList.length; i++){
        const item =  this.positionList[i];
        // console.log("id:"+item.id+",depid:"+row.departmentId+", name :"+item.name+",ee="+(item.id == row.departmentId));
        if(item.id == id){
          return item.name;
        }
      }
      return "";
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.$router.push({name:"bpm_transfer_apply"});
    },

    /** 导出按钮操作 */
    handleExport() {
      const params = this.getQuery();
      this.$confirm('是否确认导出所有调动调岗数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return exportTransfer(params);
      }).then(response => {
        this.download(response.msg);
      })
    },

  }
};
</script>

<style scoped>

</style>
