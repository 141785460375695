import request from '@/utils/request'

export function getPreviewFileUrl(fileName){
    return request({
        url: '/file/getPreviewFileUrl?fileName='+fileName,
        method: 'get',
        // params: {formData},
    })


}

// 导出用户管理
export function registUser(data) {
    return request({
        url: '/user/updatePwd',
        method: 'post',
        data: data
    })
}

// 下载
export function download(fileName) {
    return request({
        url: '/file/downloadFile?fileName='+fileName,
        method: 'get',
        responseType: 'blob'
        // data: data
    })
}


export function uploadFile(formData){
    return request({
        url: '/file/uploadFileToTemp',
        method: 'post',
        data: formData,
    })
}

// export function uploadFileToTemp(formData){
//     return request({
//         url: '/file/uploadFileToTemp',
//         method: 'post',
//         data: formData,
//     })
// }
