<template>
	<div>
		<el-form :model="recruitPosition" label-width="120px" ref="form">
			<el-row :gutter="24">
				<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">
					<el-form-item label="公司" prop="companyName" :rules="[{ required: true, message: '公司名称必选。', trigger: 'blur' }]">
						<el-select
							v-model="recruitPosition.companyName"
							clearable
							@clear="handleClearable"
							placeholder="请选择"
							collapse-tags
							ref="companySelected"
						>
							<el-option :value="recruitPosition.companyId" :label="recruitPosition.companyName" style="height: auto">
								<el-tree
									:data="companyOptions"
									:props="defaultProps"
									:expand-on-click-node="false"
									ref="tree"
									show-checkbox
									node-key="id"
									default-expand-all
									@node-click="handleNodeClick"
									@check="handleNodeClick"
									highlight-current
								/>
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>

				<el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" class="mb0">
					<el-form-item label="职位名称" prop="positionName" :rules="[{ required: true, message: '职位名称必填。', trigger: 'blur' }]">
						<el-input v-model="recruitPosition.positionName" placeholder="请输入职位名称" />
					</el-form-item>
				</el-col>

				<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">
					<el-form-item label="岗位职责" prop="responsibility" :rules="[{ required: true, message: '岗位职责必填。', trigger: 'blur' }]">
						<el-input
							clearable
							:autosize="{ minRows: 3, maxRows: 10 }"
							maxlength="1000"
							show-word-limit
							type="textarea"
							v-model="recruitPosition.responsibility"
							placeholder="请输岗位职责"
						/>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">
					<el-form-item label="岗位要求" prop="requirement" :rules="[{ required: true, message: '岗位要求必填。', trigger: 'blur' }]">
						<el-input
							clearable
							:autosize="{ minRows: 3, maxRows: 10 }"
							maxlength="1000"
							show-word-limit
							type="textarea"
							v-model="recruitPosition.requirement"
							placeholder="请输岗位要求"
						/>
					</el-form-item>
				</el-col>
                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">
					<el-form-item label="福利津贴" >
						<el-input
							clearable
							:autosize="{ minRows: 2, maxRows: 5 }"
							maxlength="500"
							show-word-limit
							type="textarea"
							v-model="recruitPosition.welfare"
							placeholder="请输入福利津贴"
						/>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" class="mb0">
					<el-form-item label="上班地点" prop="workPlace" :rules="[{ required: true, message: '上班地点必填。', trigger: 'blur' }]">
						<el-input v-model="recruitPosition.workPlace" clearable placeholder="请输入上班地点" />
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" class="mb0">
					<el-form-item label="工作年限" prop="workYears">
						<el-select v-model="recruitPosition.workYears" clearable placeholder="请选择最低工作年限" style="width:100% !important;">
							<el-option label="一年以上" value="一年以上" />
							<el-option label="二年以上" value="二年以上" />
							<el-option label="三年以上" value="三年以上" />
							<el-option label="五年以上" value="五年以上" />
							<el-option label="六年以上" value="六年以上" />
							<el-option label="八年以上" value="八年以上" />
							<el-option label="十年以上" value="十年以上" />
							<el-option label="十五年以上" value="十五年以上" />
							<el-option label="不限" value="不限" />
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" class="mb0">
					<el-form-item label="最高学历" prop="education">
						<el-select v-model="recruitPosition.education" clearable placeholder="请选择最高学历" style="width:100% !important;">
							<el-option label="初中" value="初中" />
							<el-option label="高中" value="高中" />
							<el-option label="大专" value="大专" />
							<el-option label="本科" value="本科" />
							<el-option label="硕士" value="硕士" />
							<el-option label="博士" value="博士" />
							<el-option label="不限" value="不限" />
						</el-select>
					</el-form-item>
				</el-col>
		
				<el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" class="mb0">
					<el-form-item label="职位类型" prop="jobType" :rules="[{ required: true, message: '职位类型必选。', trigger: 'blur' }]">
						<el-select v-model="recruitPosition.jobType" placeholder="请选择职位类型" style="width:100% !important;">
							<el-option v-for="item in jobTypes" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue" />
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
					<el-form-item label="招聘类型" prop="jobClass" :rules="[{ required: true, message: '招聘类型必选。', trigger: 'blur' }]">
						<el-select v-model="recruitPosition.jobClass" placeholder="请选择招聘类型" style="width:100% !important;">
							<el-option v-for="item in jobClasses" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue" />
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
					<el-form-item label="薪资类型" prop="salaryType" :rules="[{ required: true, message: '薪资类型必选。', trigger: 'blur' }]">
						<el-select v-model="recruitPosition.salaryType" placeholder="请选择薪资类型" style="width:100% !important;">
							<el-option v-for="item in salaryTypes" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue" />
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
					<el-form-item  label="性别" prop="salaryType" :rules="[{ required: true, message: '性别必选。', trigger: 'blur' }]">
						<el-select v-model="recruitPosition.gender" placeholder="请选择招聘性别" style="width:100% !important;">
							<el-option label="男" value="男" />
							<el-option label="女" value="女" />
							<el-option label="男女均可" value="男女均可" />
						</el-select>
					</el-form-item>
				</el-col>

				<el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" class="mb0">
					<el-form-item label="薪资范围">
                        <div style="display:inline-flex">
                            <el-input style="width:49%" v-model="recruitPosition.minSalary" type="number" placeholder="0" /> 
                            <span style="maring-left:2px;margin-right:2px;">至</span>
						    <el-input style="width:49%"  v-model="recruitPosition.maxSalary" type="number" placeholder="0" />
                        </div>
						
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
					<el-form-item label="年龄">
                        <div style="display:inline-flex">
                            <el-input  style="width:49%" v-model="recruitPosition.minAge" type="number" placeholder="18" />
                            <span style="maring-left:2px;margin-right:2px;">至</span>
						    <el-input style="width:49%" v-model="recruitPosition.maxAge" type="number" placeholder="18" />
                        </div>
						
					</el-form-item>
				</el-col>

				<el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" class="mb0">
					<el-form-item label="语言">
						<el-input v-model="recruitPosition.language" placeholder="请输入语言" />
					</el-form-item>
				</el-col>

				<el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" class="mb0">
					<el-form-item label="语言待级">
						<el-input v-model="recruitPosition.languageLevel" placeholder="请输入语言待级" />
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" class="mb0">
					<el-form-item label="招聘人数" prop="headCount" :rules="[{ required: true, message: '性别必选。', trigger: 'blur' }]">
						<el-input v-model="recruitPosition.headCount" placeholder="请输入招聘人数" />
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
					<el-form-item label="面试流程" prop="interviewProcess" >
						<el-select v-model="recruitPosition.interviewProcess" placeholder="请选择面试流程" style="width:100% !important;">
							<el-option v-for="item in interviewProcesses" :key="item.id" :label="item.processName" :value="item.id" />
						</el-select>
					</el-form-item>
				</el-col>
				
				<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">
						<span style="color:red;margin-left:120px;">注意：不设招聘年龄表示没年龄限制，不设薪资范围表示面谈</span>
				</el-col>
				<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">
					<el-form-item label="">
						<el-button v-if="isNewRecruitPosition" plain type="success" :loading="addLoading" @click="add">创建</el-button>
						<el-button v-else plain type="success" @click="update" :loading="updateLoading">更新</el-button>
						<el-button plain type="info" @click="close">关闭</el-button>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
	</div>
</template>

<script>
import permission from '@/directive/permission/index.js'; // 权限判断指令
import {addRecruitPosition,updateRecruitPosition} from "@/api/recruit/positionManagement"
import {findAllInterviewProcess} from '@/api/recruit/interviewProcess';
export default {
	name: 'recruitPositionEdit',

	data() {
		let postionInit = {};

		if (this.currentRecruitPosition == null || this.currentRecruitPosition == undefined) {
			postionInit = {
				companyId: this.currentCompanyId,
				companyName: this.currentCompanyName,
				status: '',
			};
		} else {
			//JS中对像，数组是引用，共享一个内存空间，子组件绑定修改会影响到父组件。如果不需要，改成复制拷呗去新空间
			// courseInit = this.currentCourse;
			postionInit = { ...this.currentRecruitPosition };
		}
		return {
			recruitPosition: postionInit,
			jobTypes: [],
			jobClasses: ["",""],
			salaryTypes: [],

			trainner: [],
			trainingType: 0,
			defaultProps: {
				children: 'children',
				label: 'companyName',
			},
			companyName: '',
			companySelect: '',
			companyId: '',
			addLoading: false,
			updateLoading: false,
			previousrecruitPositions: [],
			allEmployees: true,
			employees: [],
			checkedEmployees: [],
			interviewProcesses:[],
			checkAll: false,
			isIndeterminate: true,
		};
	},
	props: {
		companyOptions: Array,
		isNewRecruitPosition: Boolean,
		currentRecruitPosition: Object,
		currentCompanyId: String,
		currentCompanyName: String,
	},

	mounted() {},
	created() {
		this.getDicts('job_type').then((response) => {
			this.jobTypes = response.data;
		});

		this.getDicts('job-class').then((response) => {
			this.jobClasses = response.data;
		});

		// this.getDicts('recruit-status').then((response) => {
		// 			this.status = response.data;
		// 		});

		this.getDicts('salary-type').then((response) => {
					this.salaryTypes = response.data;
				});
		this.getInterviewProcess();
		const params = {
			// pageNum: this.queryParameters.pageNum,
			// pageSize: this.queryParameters.pageSize,
			// where: this.where,
			// order: '',
		};
		
		
	},

	methods: {

		getInterviewProcess()
		{
			
			findAllInterviewProcess()
				.then((response) => {
					this.interviewProcesses = response.data;
				})
				.catch((err) => {
					this.msgError('获取职位失败，' + err);
				});
		},

		// 节点单击事件
		handleNodeClick(data) {
			this.recruitPosition.companyId = data.id;
			this.recruitPosition.companyName = data.companyName;
			let selectArray = [];
			selectArray.push(this.recruitPosition.companyId);
			this.$refs.tree.setCheckedKeys(selectArray);

			this.$refs.companySelected.blur();
			//this.getEmployees();
		},
		handleClearable() {
			this.$refs.tree.setCheckedKeys([]);
			this.recruitPosition.companyId = null;
			this.recruitPosition.companyName = '';
		},
		// getEmployees() {
		// 	getEmployees(this.recruitPosition.companyId)
		// 		.then((response) => {
		// 			this.employees = response.data;
		// 		})
		// 		.catch((err) => {
		// 			that.msgError('获取此公司或部门员工失败，' + err);
		// 		});
		// },
		add() {
			let that = this;
			this.$refs['form'].validate((valid) => {
				if (valid) {
					that.addLoading = true;
					 that.recruitPosition.status = '新建';
					addRecruitPosition(that.recruitPosition)
						.then((response) => {
							that.addLoading = false;
							that.msgSuccess('添加招聘申请成功。');
							that.$emit('close', { refresh: true, isSuccess: true });
						})
						.catch((err) => {
							that.addLoading = false;
							that.msgError('添加招聘申请失败，' + err);
						});
				} else {
					that.msgError('有非法输入或必填项未填写，请检查!');
				}
			});
		},
		update() {
			this.$refs['form'].validate((valid) => {
				if (valid) {
					this.updateLoading = true;
					updateRecruitPosition(this.recruitPosition)
						.then((response) => {
							this.updateLoading = false;
							this.msgSuccess('更新招聘申请成功。');
							this.$emit('close', { refresh: true, isSuccess: true });
						})
						.catch((err) => {
							this.updateLoading = false;
							this.msgError('更新招聘申请失败，' + err);
						});
				 }
			});
		},
		close() {
			this.$emit('close', { refresh: false, isSuccess: true });
		},
		// loadrecruitPosition(selectValue) {
		// 	getTraingPlan(selectValue)
		// 		.then((response) => {
		// 			this.recruitPosition = response.data;
		// 			this.recruitPosition.id = null;
		// 		})
		// 		.catch((err) => {
		// 			that.msgError('查询培训计划失败，' + err);
		// 		});
		// },

		
	},
};
</script>

<style lang="scss" scoped>
</style>