import request from '@/utils/request'

// 查询消息管理列表
export function listMsg(query) {
    return request({
        url: '/msgreceived/findAll',
        method: 'post',
        data: query
    })
}

// 导出消息管理
export function exportMsg(query) {
    return request({
        url: '/msgreceived/export',
        method: 'post',
        data: query
    })
}

//获取未读消息数
export function getUnreadCount(){
    return request({
        url: '/msgreceived/getUnreadCount',
        method: 'post',
        data: null
    })
}
