<template>
  <div class="app-container">
     <el-row style="margin-bottom:10px; display:flex;">
        <label style="width: 88px;text-align:right;" class="el-form-item__label"> 公司部门</label>
        <div class="el-form-item__content" style="width:88%;">
         <treeselect ref="treeRef" v-model="queryParams.companyId" :options="treeData" :multiple="true" value-consists-of="ALL"
                     :limit="5" :limitText="count => `及其它${count}部门`" :flat="true"
                    :normalizer="normalizerCompany" @input="companyIdInput" placeholder="请选择公司部门"/>
        </div>

    </el-row>

    <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="88px">
      <el-form-item label="工资月份" prop="salaryDate">
        <el-date-picker v-model="queryParams.salaryDate" style="width:100%" type="month" clearable value-format="yyyy-MM-dd" placeholder="选择月"/>
      </el-form-item>
      <el-form-item label="员工姓名" prop="empName">
        <el-input v-model="queryParams.empName" placeholder="请输入员工姓名" clearable
                   @keyup.enter.native="handleQuery" />
      </el-form-item>
      <el-form-item v-if="'query' == listType" label="状态" prop="status">
        <el-select v-model="queryParams.status" placeholder="请选择状态" multiple clearable>
          <el-option  v-for="dict in getDictDatas(DICT_TYPE.CB_SALARY_STATUS)" :key="dict.value" :label="dict.label" :value="dict.value"
          ></el-option>
        </el-select>
        <!--        <el-input v-model="queryParams.status" placeholder="请输入员工姓名" clearable-->
        <!--                  size="small" @keyup.enter.native="handleQuery" />-->
      </el-form-item>
      <el-form-item label="计算方式" prop="calculateType">
        <el-select v-model="queryParams.calculateType" placeholder="请选择计算方式" style="width:100%" clearable>
          <el-option  v-for="dict in getDictDatas(DICT_TYPE.CB_SALARY_CALCULATE_TYPE)" :key="dict.value" :label="dict.label" :value="dict.value"
          ></el-option>
        </el-select>
        <!--        <el-input v-model="queryParams.calculateType" placeholder="请输入员工姓名" clearable-->
        <!--                  size="small" @keyup.enter.native="handleQuery" />-->
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <template v-if="'calculate' == listType">
        <el-col :span="1.5">
          <el-button type="primary" plain icon="el-icon-plus" size="mini" :class="{uniAppMarginBottom:uniApp,webMarginBottom:!uniApp }"
                     @click="handleAdd" v-permission="['/cb/cbSalary/add']">生成工资</el-button>
        </el-col>
        <el-col :span="1.5">
          <el-button type="primary" plain icon="el-icon-refresh-left" size="mini" :disabled="multiple" :class="{uniAppMarginBottom:uniApp,webMarginBottom:!uniApp }"
                     @click="handleUpdateAttInfo" v-permission="['/cb/cbSalary/edit']">更新重算</el-button>
        </el-col>
        <el-col :span="1.5">
          <el-button type="warning" plain icon="el-icon-download" size="mini" :class="{uniAppMarginBottom:uniApp,webMarginBottom:!uniApp }"
                     @click="handleExportTax" v-permission="['/cb/cbSalary/edit']" >导出报税
          </el-button>
        </el-col>
        <el-col :span="1.5">
          <el-upload v-permission="['/cb/cbSalary/edit']"
                     action
                     :name="form.url"
                     class="upload-demo"
                     ref="upload"
                     :limit="1"
                     :http-request="uploadFile"
                     :on-preview="handlePreview"
                     :on-remove="handleRemove"
                     :file-list="fileList"
                     accept=".xls"
                     :auto-upload="true">
            <el-button slot="trigger" size="mini" type="warning" plain :class="{uniAppMarginBottom:uniApp,webMarginBottom:!uniApp }">导入报税</el-button>
          </el-upload>

<!--          <el-button type="warning" plain icon="el-icon-upload2" size="mini" :class="{uniAppMarginBottom:uniApp,webMarginBottom:!uniApp }"-->
<!--                     @click="handleImportTax" v-permission="['/cb/cbSalary/edit']" >导入报税-->
<!--          </el-button>-->
        </el-col>
        <!--      <el-col :span="1.5">-->
        <!--        <el-button type="success" plain icon="el-icon-edit" size="mini"-->
        <!--            :disabled="single" @click="handleUpdate" v-permission="['/cb/cbSalary/edit']" >修改-->
        <!--        </el-button>-->
        <!--      </el-col>-->
        <el-col :span="1.5">
          <el-button type="success" plain icon="el-icon-folder-checked" size="mini" :class="{uniAppMarginBottom:uniApp,webMarginBottom:!uniApp }"
                     :disabled="multiple" @click="handleApplyApprove" v-permission="['/cb/cbSalary/edit']" >提交审核
          </el-button>
        </el-col>
        <el-col :span="1.5">
          <el-button type="danger" plain icon="el-icon-delete" size="mini" :class="{uniAppMarginBottom:uniApp,webMarginBottom:!uniApp }"
                     :disabled="multiple" @click="handleDelete" v-permission="['/cb/cbSalary/remove']" >删除
          </el-button>
        </el-col>
      </template>
      <template v-if="'approve' == listType">
        <el-col :span="1.5">
          <el-button type="success" plain icon="el-icon-download" size="mini" :class="{uniAppMarginBottom:uniApp,webMarginBottom:!uniApp }"
                     @click="handleExportApprove" v-permission="['/cb/cbSalary/approve']" >导出审核表
          </el-button>
        </el-col>
        <el-col :span="1.5">
          <el-button type="success" plain icon="el-icon-finished" size="mini" :class="{uniAppMarginBottom:uniApp,webMarginBottom:!uniApp }"
                     :disabled="multiple" @click="handleApproved" v-permission="['/cb/cbSalary/approve']" >审核通过
          </el-button>
        </el-col>
        <el-col :span="1.5">
          <el-button type="success" plain icon="el-icon-circle-close" size="mini" :class="{uniAppMarginBottom:uniApp,webMarginBottom:!uniApp }"
                     :disabled="multiple" @click="handleDisapprove" v-permission="['/cb/cbSalary/disapprove']" >审核退回
          </el-button>
        </el-col>
      </template>
      <template v-if="'paid' == listType">
        <el-col :span="1.5">
          <el-button type="success" plain icon="el-icon-finished" size="mini" :class="{uniAppMarginBottom:uniApp,webMarginBottom:!uniApp }"
                     :disabled="multiple" @click="handlePaid" v-permission="['/cb/cbSalary/paid']" >分次发放
          </el-button>
        </el-col>
        <el-col :span="1.5">
          <el-button type="warning" plain icon="el-icon-finished" size="mini" :class="{uniAppMarginBottom:uniApp,webMarginBottom:!uniApp }"
                     :disabled="multiple" @click="handlePaidFinish" v-permission="['/cb/cbSalary/paid']" >发放完毕
          </el-button>
        </el-col>
        <el-col :span="1.5">
          <el-button type="success" plain icon="el-icon-circle-close" size="mini" :class="{uniAppMarginBottom:uniApp,webMarginBottom:!uniApp }"
                     :disabled="multiple" @click="handleReturnApprove" v-permission="['/cb/cbSalary/returnApprove']" >退回重审
          </el-button>
        </el-col>
        <el-col :span="1.5">
          <el-button type="success" plain icon="el-icon-download" size="mini" :class="{uniAppMarginBottom:uniApp,webMarginBottom:!uniApp }"
                     @click="exportBank" v-permission="['/cb/cbSalary/paid']" >导出银行
          </el-button>
        </el-col>
<!--        <el-col :span="1.5">-->
<!--          <el-button type="success" plain icon="el-icon-download" size="mini" :class="{uniAppMarginBottom:uniApp,webMarginBottom:!uniApp }"-->
<!--                     @click="exportInsurance" v-permission="['/cb/cbSalary/paid']" >导出社保-->
<!--          </el-button>-->
<!--        </el-col>-->
<!--        <el-col :span="1.5">-->
<!--          <el-button type="success" plain icon="el-icon-download" size="mini" :class="{uniAppMarginBottom:uniApp,webMarginBottom:!uniApp }"-->
<!--                     @click="exportFund" v-permission="['/cb/cbSalary/paid']" >导出公积金-->
<!--          </el-button>-->
<!--        </el-col>-->
      </template>
<!--      <template v-if="'query' == listType">-->
<!--        <el-col :span="1.5">-->
<!--          <el-button type="success" plain icon="el-icon-download" size="mini" :class="{uniAppMarginBottom:uniApp,webMarginBottom:!uniApp }"-->
<!--                     @click="genSalarySlip" v-permission="['/cb/cbSalary/paid']" >下载工资条-->
<!--          </el-button>-->
<!--        </el-col>-->
<!--      </template>-->
      <el-col :span="1.5">
        <el-button type="success" plain icon="el-icon-download" size="mini" :class="{uniAppMarginBottom:uniApp,webMarginBottom:!uniApp }"
                   @click="genSalarySlip" v-permission="['/cb/cbSalary/edit','/cb/cbSalary/paid']" >下载工资单
        </el-button>
      </el-col>
<!--      <el-col :span="1.5">-->
<!--        <el-button type="warning" plain icon="el-icon-download" size="mini"-->
<!--                   @click="handleExport" v-permission="['/cb/cbSalary/export']" >导出-->
<!--        </el-button>-->
<!--      </el-col>-->
      <el-col :span="1.5">
        <el-popover placement="top-start" title="薪酬计算" width="350" trigger="click">
          <ul>
            <li>生成工资：生成公司员工月薪酬信息。根据工资帐套及设置生成工资。如果已经生成工资后账套设置修改，并不会影响已经生成的工资。</li>
            <li>更新重算：如果生成的薪酬信息状态是“考勤确认中”，需要等考勤专员确认月考勤信息；然后可以更新考勤选中员工的考勤信息已经考勤扣除金额，如果考勤已经确认则状态会变成“计算中”</li>
            <li>导出报税：导出税前工资项，方便导入税务系统</li>
            <li>导入报税：将税务系统扣税信息导入系统，计算薪酬扣税的税后工资</li>
          </ul>
          <!--          <span>考勤确认</span>-->
          <el-button type="primary" plain icon="el-icon-question" size="mini" :class="{uniAppMarginBottom:uniApp,webMarginBottom:!uniApp }" slot="reference">帮助</el-button>
        </el-popover>
      </el-col>
      <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>
    </el-row>

    <el-table v-loading="loading" :data="cbSalaryList" @selection-change="handleSelectionChange" stripe border>
      <el-table-column type="selection" width="55" align="center"/>
      <el-table-column label="员工姓名" align="center" prop="empName" fixed/>
      <el-table-column label="所属公司" align="center" prop="companyId" :show-overflow-tooltip="true" width="168px" sortable>
        <template slot-scope="scope">
          {{companyFormat(scope.row, null)}}
        </template>
      </el-table-column>
      <el-table-column label="部门" align="center" prop="departmentId" sortable width="auto" min-width="150" :show-overflow-tooltip='true'>
        <template slot-scope="scope">
          {{departmentFormat(scope.row, null)}}
        </template>
      </el-table-column>
      <el-table-column label="月份" align="center" prop="salaryDate" width="98px">
        <template slot-scope="scope">
          <span>{{scope.row.salaryDate.substr(0,7)}}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态" align="center" prop="status" width="98px"/>
      <el-table-column label="计算方式" align="center" prop="calculateType" width="98px">
        <template slot-scope="scope">
          <span v-if="'人工设置'==scope.row.calculateType" style="color: #ba0427">{{scope.row.calculateType}}</span>
          <span v-else>{{scope.row.calculateType}}</span>
        </template>
      </el-table-column>
      <el-table-column label="工资(合同)" align="center" prop="contractSalary" width="98px"/>
      <el-table-column label="应发工资" align="center" prop="totalSalary">
        <template slot-scope="scope">
          <el-tooltip placement="top" effect="light">
            <div slot="content">
              <el-table :data="getSalaryItemInfo(scope.row.salaryItems)" show-summary :summary-method="getTotalSalarySummaries" stripe border>
                <el-table-column label="项目名" align="center" prop="itemName">
                  <template slot-scope="scopeItem">
                    {{scopeItem.row.itemName}}
                  </template>
                </el-table-column>
                <el-table-column label="项目类型" align="center" prop="itemType">
                  <template slot-scope="scopeItem">
                    {{scopeItem.row.itemType}}
                  </template>
                </el-table-column>
                <el-table-column label="增减项" align="center" prop="plusMinus">
                  <template slot-scope="scopeItem">
                    {{scopeItem.row.plusMinus}}
                  </template>
                </el-table-column>
                <el-table-column label="考勤比例发放" align="center" prop="isAttend">
                  <template slot-scope="scopeItem">
                    {{scopeItem.row.isAttend?"是":"否"}}
                  </template>
                </el-table-column>
                <el-table-column label="是否扣税" align="center" prop="isTax">
                  <template slot-scope="scopeItem">
                    {{scopeItem.row.isTax?"是":"否"}}
                  </template>
                </el-table-column>
                <el-table-column label="金额(元)" align="center" prop="amount" width="88px">
                  <template slot-scope="scopeItem">
                    {{scopeItem.row.amount}}
                  </template>
                </el-table-column>
              </el-table>
              <span>总工资={{getSalaryItemTotalText(scope.row.salaryItems)}}</span>
            </div>
            <span style="color: #4ba5f6">{{scope.row.totalSalary}}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="考勤扣除" align="center" prop="attDeduct"/>
      <el-table-column label="其他扣补" align="center" prop="otherDeduct" width="100px">
        <template slot-scope="scope" slot="header" sortable>
          <el-tooltip :aa="scope" class="item" effect="light" content="其他扣除补发款项，请用“正数”表示扣除，请使用“负数”表示补发" placement="top" >
            <span>其他扣补 ？</span>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <el-tooltip placement="top" effect="light">
            <div v-if="scope.row.otherMemo" slot="content">
              <span>{{scope.row.otherMemo}}</span>
            </div>
            <el-input v-if="scope.row.editable" v-model="scope.row.otherDeduct" placeholder="请输入其他扣补"></el-input>
            <span style="color: #4ba5f6" v-else>{{scope.row.otherDeduct}}</span>
          </el-tooltip>
        </template>
      </el-table-column>
<!--      <el-table-column label="其他扣补说明" align="center" prop="otherMemo" width="168px">-->
<!--        <template slot-scope="scope">-->
<!--          <el-input v-if="scope.row.editable" v-model="scope.row.otherMemo" placeholder="请输入其他扣补说明"></el-input>-->
<!--          <span v-else>{{scope.row.otherMemo}}</span>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column label="实发工资" align="center" prop="totalPay">
        <template slot-scope="scope">
          <el-button type="text" @click="showSalaryPayDetail(scope.row)">{{scope.row.totalPay}}</el-button>
        </template>
      </el-table-column>
      <el-table-column label="报税工资" align="center" prop="applyTaxPay">
<!--        <template slot-scope="scope">-->
<!--          <el-button type="text" @click="showSalaryPayDetail(scope.row)">{{scope.row.totalPay}}</el-button>-->
<!--        </template>-->
      </el-table-column>
      <el-table-column label="扣税" align="center" prop="tax">
        <template slot-scope="scope">
          <el-tooltip placement="top" effect="light">
            <div v-if="scope.row.taxInfo" slot="content">
              {{scope.row.taxInfo.text}}
            </div>
            <el-input v-if="scope.row.editable" v-model="scope.row.tax" placeholder="请输入扣税"></el-input>
            <span style="color: #4ba5f6" v-else>{{scope.row.tax}}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="税后工资" align="center" prop="salaryAfterTax" width="120px">
        <template slot-scope="scope" slot="header" sortable>
          <el-tooltip :aa="scope" class="item" effect="light" content="税后工资 = 实发工资 - 扣税" placement="top" >
            <span>税后工资 ？</span>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <span>{{scope.row.salaryAfterTax}}</span>
          <!--          <span v-else>{{scope.row.totalPay}}</span>-->
        </template>
      </el-table-column>
      <el-table-column label="发放次数" align="center" prop="otherInfo">
        <template slot-scope="scope">
          <el-tooltip placement="top" effect="light">
            <div slot="content">
              <el-form :inline="true" label-width="98px">
                <el-row>
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" >
                    <el-form-item label="发放次数" prop="times">
                      <el-input disabled v-model.number="scope.row.paidInfo.payTimes" type='number' placeholder="请输入发放次数"/>
                    </el-form-item>
                  </el-col>
                </el-row>
                <template>
                  <el-form-item v-for="t in scope.row.paidInfo.payTimes" :key="'t'+t" :label="'第'+t+'次比例(%)'">
                    <el-input disabled v-model="scope.row.paidInfo['t'+t]" type='number' placeholder="请输入发放百分比例"/>
                  </el-form-item>
                </template>
                <el-row>
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item label="备注说明" prop="msg">
                      <el-input disabled v-model="scope.row.paidInfo.msg" placeholder="请输入备注说明"/>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </div>
            <span style="color: #4ba5f6">
              {{scope.row.paidInfo?(scope.row.paidInfo?scope.row.paidInfo.payTimes:''):''}}
            </span>
          </el-tooltip>
        </template>
      </el-table-column>

      <el-table-column label="操作记录" align="center" prop="otherInfo">
        <template slot-scope="scope">
          <el-tooltip placement="top" effect="light">
            <div slot="content">
              <el-table :data="scope.row.otherInfo?scope.row.otherInfo.opRecords:[]" stripe border>
                <el-table-column label="操作人" align="center" prop="opName"/>
                <el-table-column label="操作时间" align="center" prop="opTime" sortable width="100px"/>
                <el-table-column label="操作类型" align="center" prop="operate"/>
                <el-table-column label="说明" align="center" prop="msg" width="180px"/>
              </el-table>
            </div>
            <span style="color: #4ba5f6">
              {{scope.row.otherInfo?(scope.row.otherInfo.opRecords?scope.row.otherInfo.opRecords[scope.row.otherInfo.opRecords.length-1].operate:''):''}}
            </span>
          </el-tooltip>
        </template>
      </el-table-column>

      <!--      <el-table-column label="开会银行" align="center" prop="bankName"/>-->
      <!--      <el-table-column label="社保卡号" align="center" prop="insuranceAcct"/>-->
      <!--      <el-table-column label="公积金号" align="center" prop="fundAcct"/>-->
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width" min-width="88px" fixed="right">
        <template slot-scope="scope">
          <template v-if="'calculate' == listType">
            <el-tooltip v-if="!scope.row.editable&&'计算中'==scope.row.status" :aa="scope" class="item" effect="dark" content="修改" placement="top" >
              <el-button v-if="!scope.row.editable" size="mini" type="text" icon="el-icon-edit" @click="handleUpdate(scope.row)"
                         v-permission="['/cb/cbSalary/edit']" ></el-button>
            </el-tooltip>
<!--            <el-tooltip v-if="scope.row.editable&&'计算中'==scope.row.status" :aa="scope" class="item" effect="dark" content="保存" placement="top" >-->
<!--              <el-button v-if="scope.row.editable" size="mini" type="text" icon="el-icon-finished" @click="saveUpdate(scope.row)"-->
<!--                         v-permission="['/cb/cbSalary/edit']" ></el-button>-->
<!--            </el-tooltip>-->
<!--            <el-tooltip v-if="scope.row.editable&&'计算中'==scope.row.status" :aa="scope" class="item" effect="dark" content="取消" placement="top" >-->
<!--              <el-button v-if="scope.row.editable" size="mini" type="text" icon="el-icon-back" @click="cancelUpdate(scope.row)"-->
<!--                         v-permission="['/cb/cbSalary/edit']" ></el-button>-->
<!--            </el-tooltip>-->
            <el-tooltip :aa="scope" class="item" effect="dark" content="删除" placement="top" >
              <el-button size="mini" type="text" icon="el-icon-delete" @click="handleDelete(scope.row)"
                         v-permission="['/cb/cbSalary/remove']" ></el-button>
            </el-tooltip>
          </template>
          <template v-else-if="'approve' == listType">
            <el-button size="mini" type="text" icon="el-icon-finished" @click="handleApproved(scope.row)"
                       v-permission="['/cb/cbSalary/edit']" >审核通过</el-button>
            <el-button size="mini" type="text" icon="el-icon-circle-close" @click="handleDisapprove(scope.row)"
                       v-permission="['/cb/cbSalary/edit']" >审核退回</el-button>
          </template>
          <template v-else-if="'paid' == listType">
            <el-button size="mini" type="text" icon="el-icon-finished" @click="handlePaid(scope.row)"
                       v-permission="['/cb/cbSalary/edit']">分次发放</el-button>
            <el-button size="mini" type="text" icon="el-icon-finished" @click="handlePaidFinish(scope.row)"
                       v-permission="['/cb/cbSalary/edit']">发放完毕</el-button>
            <el-button size="mini" type="text" icon="el-icon-circle-close" @click="handleReturnApprove(scope.row)"
                       v-permission="['/cb/cbSalary/edit']">退回重审</el-button>
          </template>
        </template>
      </el-table-column>
    </el-table>

    <pagination
        v-show="total>0"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
    />

    <!-- 添加或修改薪酬设置对话框 -->
    <el-dialog title="生成工资" :visible.sync="openGen" width="80%" append-to-body>
      <SalaryGen :companyList="companyList" :companyOptions="companyOptions" :departmentList="departmentList"
                 :departmentOptions="treeData" @close="closeGen" ></SalaryGen>
    </el-dialog>
    <el-dialog :title="title" :visible.sync="open" width="80%" append-to-body>
      <SalaryEdit :salaryId="salaryId" :companyList="companyList" :companyOptions="companyOptions" :departmentList="departmentList"
                  :departmentOptions="departmentOptions" @close="closeEdit" ></SalaryEdit>
    </el-dialog>
    <el-dialog title="工资明细" :visible.sync="openPaydetail" width="80%" append-to-body>
      <SalaryPayDetail :salaryIn="salaryPayDetailIn" :editable="salaryPayDetailEdit" @close="closePayDetail"></SalaryPayDetail>
    </el-dialog>
    <el-dialog title="工资发放" :visible.sync="openPayTimes" width="78%" append-to-body>
      <div style="text-align: center">
        <el-switch :disabled="!paidEditable" v-model="salaryPayTimes.isOneTime" active-text="一次" inactive-text="多次">
        </el-switch>
      </div>
      <el-form :model="salaryPayTimes" ref="payTimesForm" :inline="true" label-width="88px">
        <el-row>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" >
            <el-form-item v-if="!salaryPayTimes.isOneTime" label="发放次数" prop="times">
              <el-input :disabled="!paidEditable" v-model.number="salaryPayTimes.times" type='number' placeholder="请输入发放次数"/>
            </el-form-item>
          </el-col>
        </el-row>
        <template v-if="!salaryPayTimes.isOneTime">
          <el-form-item v-for="t in salaryPayTimes.times" :key="'t'+t" :label="'第'+t+'次比例(%)'">
            <el-input :disabled="!paidEditable" v-model="salaryPayTimes.payPerTime['t'+t]" type='number' placeholder="请输入发放百分比例"/>
          </el-form-item>
        </template>
        <el-row>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-form-item label="备注说明" prop="msg">
              <el-input :disabled="!paidEditable" v-model="salaryPayTimes.msg" placeholder="请输入备注说明"/>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button v-if="paidEditable" type="primary" @click="doPaySalary">保 存</el-button>
        <el-button @click="openPayTimes=false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {listCbSalary,updateAttInfo,getCbSalary,delCbSalary,updateCbSalary,applyApprove,approve,disapprove,paid,returnApprove,paidComplete,exportCbSalary,
  exportTax, exportSalarySlip, exportSalaryBank, exportSalaryInsurance, exportSalaryFund, exportSalaryApprove } from "@/api/cb/cbSalary";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import RightToolbar from "@/components/RightToolbar"
import permission from '@/directive/permission/index.js' // 权限判断指令
import Pagination from "@/components/Pagination";
import {getOrgTree} from "@/views/pages/org/components/orgtree";
import {getOrgEmpTree, normalizerEmployMultiSelectTree} from '@/views/pages/employ/components/empOrgTree';
import SalaryGen from "@/views/pages/cb/components/salaryGen";
import SalaryEdit from "@/views/pages/cb/components/salaryEdit";
import {getFirstLastDayOfMonth, getCurrentTime} from "@/utils/dateTimeUtils";
import {getUpMonthDay} from "../../../../utils/dateTimeUtils";
import { showLoading, hideLoading } from '@/utils/reqloading';
import {DICT_TYPE,getDictLabelFromData, getDictDataLabel, getDictDatas} from "@/utils/dict";
import SalaryPayDetail from "@/views/pages/cb/components/salaryPayDetail";
import {Local} from '@/utils/storage.js'
import {formatDate} from "../../../../utils/formatTime";
import {importTax} from "../../../../api/cb/cbSalary";

// 表查询配置
const dbOrderBy = ''

export default {
  name: "SalaryList",
  components: {
    Treeselect,RightToolbar,Pagination,SalaryGen,SalaryEdit,SalaryPayDetail,
  },
  directives: { permission },
  props: {
    listType: String,
  },
  data() {
    return {
      activeName: 'salary',
      selectedRows: [],
      openGen: false,
      openPaydetail: false,
      openPayTimes: false,  // 工资分次发放
      salaryPayDetailEdit: false,
      salaryPayDetailIn: {},
      treeData: [],
      companyId: 1,
      companyList: [],
      // 部门管理树选项
      departmentList: [],
      departmentOptions: [],
      positionList: [],
      positionOptions: [],
      employList: [],
      // 公司树选项
      companyOptions: [],
      // 遮罩层
      loading: true,
      // 查询参数
      where: {
        query: '',
        filters: []
      },


      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 薪酬设置表格数据
      cbSalaryList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        orderby: dbOrderBy,
        companyId: null,
        empName: null,
        depId: null,
        salaryDate: new Date(),
        status: [],
        calculateType: null,
      },
      // 表单参数
      form: {
      },
      salaryId:null,
      // 分次发放工资
      salaryPayTimes:{
        isOneTime: true,
        times: 1,
        payPerTime:{"t1":100},
        msg: "",
      },
      paidEditable: true,
      payIds: [],
      fileList: [],
      uniApp:false,
    };
  },
  created() {
    this.companyId = this.$store.getters.userInfo.companyId;
    this.uniApp=Local.get("isUniapp")?true:false;
    console.log(this.$store.getters.userInfo)
    this._filter = {};
    const [first,last] = getFirstLastDayOfMonth(new Date());
    let upMonth = getUpMonthDay(first);
    // console.log(first);
    this.queryParams.salaryDate = upMonth;
    if('approve'==this.listType){
      this.queryParams.status = ['审核中'];
    }else if('calculate'==this.listType) {
      this.queryParams.status = ['考勤确认中','计算中'];
    }else if('paid'==this.listType) {
      this.queryParams.status = ['发放中'];
    }
        // console.log(this.queryParams.salaryDate);
    showLoading();
    // getOrgEmpTree(this.companyId, true,(tree, companyList, departmentList, employList )=>{
    //   this.employTree = tree;
    //   console.log('this.employTree');
    //   console.log(this.employTree);
    //   this.departmentList = departmentList;
    //   this.departmentOptions = this.handleTree(departmentList, "id", "pid");
    //
    //   // this.companyOptions = [];
    //   this.companyOptions = this.handleTree(companyList, "id", "pid");
    //
    //   this.employList = employList;
    //   this.getList();
    //   setTimeout(()=>{
    //     this.inputChange(this.queryParams.companyId);
    //     hideLoading();
    //   },500);
    // });

    getOrgTree((tree, companyList, departmentList, positionList )=>{
      this.companyList = companyList;
      this.treeData = tree;
      // this.allDepartmentOptions = [];
      // const dataDepartment = { id:0,
      //   name:'顶级部门',
      //   children: []
      // };
      // dataDepartment.children = this.handleTree(departmentList, "id", "pid");
      // this.allDepartmentOptions.push(dataDepartment);
      this.companyOptions = [];
      this.companyOptions = this.handleTree(companyList, "id", "parentId");

      this.positionList = positionList;
      this.departmentList = departmentList;
      this.departmentOptions = this.handleTree(departmentList, "id", "pid");
      this.getList();
      setTimeout(()=>{
        this.inputChange(this.queryParams.companyId);
        hideLoading();
      },500);
    });
    // this.getList();
  },
  methods: {
    showSalaryPayDetail(row){
      this.salaryPayDetailIn = row;
      this.salaryPayDetailEdit = false;
      this.openPaydetail = true;
    },
    closePayDetail(arg){
      this.openPaydetail = false;
      if(arg.refresh){
        this.getList();
      }
    },
    getSalaryItemInfo(str){
      if(typeof str == "object") return str;
      if(typeof str == "string"){
        console.log("getSalaryItemInfo="+str);
        let json = JSON.parse(str);
        console.log(json);
        return json;
      }else{
        return str;
      }
    },
    getSalaryItemTotalText(str){
      let itemInfo = this.getSalaryItemInfo(str);
      console.log("getSalaryItemTotalText:");
      console.log(itemInfo);
      let text = '';
      let salary = '';
      let sum = 0;
      let floatItem = ''
      if(itemInfo) {
        // let itemInfo = this.salaryInfo.itemInfo;
        let salaryItemInfo = itemInfo;//.sort(sortBy('orderNum',true));
        console.log(salaryItemInfo);
        let isFirst = true;
        salaryItemInfo.forEach(item=>{
          if(isFirst){
            isFirst = false;
            if (item.plusMinus == '增项') {
              text = text + item.itemName;
              salary = salary + this.getItemAmountValue(item);
            } else {
              text = text + ' - ' + item.itemName;
              salary = salary + ' - ' + this.getItemAmountValue(item);
            }
          }else{
            if (item.plusMinus == '增项') {
              text = text + ' + ' + item.itemName;
              salary = salary + ' + ' + this.getItemAmountValue(item);
            } else {
              text = text + ' - ' + item.itemName;
              salary = salary + ' - ' + this.getItemAmountValue(item);
            }
          }
          // 求和
          const value = Number(item.amount);
          if (!isNaN(value)) {
            if (item.plusMinus == '增项') {
              sum += value;
            } else {
              sum -= value;
            }
          }else{
            floatItem += item.itemName + ",";
          }
        });
      }
      sum = sum.toFixed(2);
      if(floatItem&&floatItem.length>0){
        text = text + " = " + salary + ' = ' + sum + '元(不包括：'+floatItem.substring(0,floatItem.length-1)+')';
      }else{
        text = text + " = " + salary + ' = ' + sum + '元';
      }
      console.log(text);
      return text;
    },
    getItemAmountValue(item){
      const value = Number(item.amount);
      if (!isNaN(value)) {
        return value;
      }else{
        return item.itemName;
      }
    },
    getTotalSalarySummaries(param) {
      const {columns, data} = param;
      const sums = [];
      sums[0] = '合计';
      let extVal = 0;
      let val = 0;
      let deduct = 0;
      let pay = 0;
      data.map(item => {
        const value = Number(item.amount);
        if (!isNaN(value)) {
          if (item.plusMinus == '增项') {
            val += value;
          } else {
            val -= value;
          }
          if(DICT_TYPE.CB_TEMPLATE_SALARY_TYPE_EXTRA == item.itemType){
            if (item.plusMinus == '增项') {
              extVal += value;
            } else {
              extVal -= value;
            }
          }
        }
        const deductn = Number(item.deduct);
        const payn = Number(item.pay);
        if (item.plusMinus == '增项') {
          if(!isNaN(deductn)){
            deduct += deductn;
          }
          if(!isNaN(payn)){
            pay += payn;
          }
        } else {
          if(!isNaN(deductn)){
            deduct -= deductn;
          }
          if(!isNaN(payn)){
            pay -= payn;
          }
        }

      });
      sums[4] = ""+val.toFixed(2);//+"(其中包含"+extVal+DICT_TYPE.CB_TEMPLATE_SALARY_TYPE_EXTRA+")";
      sums[5] = deduct.toFixed(2);
      sums[6] = pay.toFixed(2);
      // sums[3] = "包含"+extVal+DICT_TYPE.CB_TEMPLATE_SALARY_TYPE_EXTRA+"";
      // if(this.contractSalary){
      //   sums[2] = this.contractSalary+"(合同总工资)"
      // }
      return sums;
    },

    getAttDeductItems(row){
      // console.log(row);
      if(!row.attendInfo || !row.attendInfo.deductType || row.attendInfo.deductType.length<=0) return [];
      let attDeductItems = [];
      row.attendInfo.deductType.forEach(item=>{
        attDeductItems.push(row.attendInfo[item]);
      });
      return attDeductItems;
    },
    getSummaries(param) {
      const {columns, data} = param;
      const sums = [];
      sums[0] = '考勤总扣除';
      let val = 0;
      data.map(item => {
        const value = Number(item.deduct);
        if (!isNaN(value)) {
          val += value;
        }
      });
      sums[4] = val.toFixed(2);
      return sums;
    },
    closeGen(arg){
      this.openGen=false;
      if(arg.refresh){
        this.getList();
      }
    },
    closeEdit(arg){
      this.open=false;
      if(arg.refresh){
        this.getList();
      }
    },
    companyIdInput(value, instanceId){
      console.log("companyIdInput");
      console.log(value);
      console.log(instanceId);
      console.log(this.queryParams.companyId);
      // const companyIds = this.getClassIds(value,"company");
      this.queryParams.positionId = null;
      this.queryParams.depId = this.getClassIds(value,"department");
      console.log(this.queryParams.depId);
      // this.queryParams.positionId = null;
      // this.$refs["selectPosTree"].clear();
      // this.getDepartment(value);
      // this.getPosition(value);
      console.log("companyIdInput");
      this.inputChange(value,instanceId);
    },
    getClassIds(value, className){
      if(value && value.length>0){
        let ids = [];
        value.forEach( id => {
          if(id.startsWith(className)){
            const idstr = id.substring(className.length);
            console.log(idstr)
            ids.push(idstr);
          }
        })
        console.log("getClassIds , ids=");
        console.log(ids);
        return ids;
      }else{
        return [];
      }
    },

    /** 查询薪酬设置列表 */
    getQuery(){
      this._filter = {};
      console.log("this.queryParams.companyId=")
      console.log(this.queryParams.companyId);
      if(this.queryParams.companyId) {
        this._filter['companyId'] = {
          type: 'eq',
          value: this.queryParams.companyId,
        }
      }
      if(this.queryParams.empName) {
        this._filter['empName'] = {
          type: 'search',
          value: this.queryParams.empName,
        }
      }
      console.log(this.queryParams.depId)
      if(this.queryParams.depId) {
        this._filter['depId'] = {
          type: 'in',
          value: this.queryParams.depId,
        }
      }
      if(this.queryParams.salaryDate) {
        console.log(this.queryParams.salaryDate)
        this._filter['salaryDate'] = {
          type: 'eq',
          value: this.queryParams.salaryDate,
        }
      }
      if(this.queryParams.status) {
        console.log(this.queryParams.status)
        this._filter['status'] = {
          type: 'in',
          value: this.queryParams.status,
        }
      }
      if(this.queryParams.calculateType) {
        console.log(this.queryParams.calculateType)
        this._filter['calculateType'] = {
          type: 'eq',
          value: this.queryParams.calculateType,
        }
      }


      const filters = this.parseFilters(this._filter)
      if (filters && filters.length > 0) {
        this.where.filters = filters
      } else {
        // 如果过滤条件为空，设置空数组
        this.where.filters = []
      }
      const params = {
        pageNum: this.queryParams.pageNum,
        pageSize: this.queryParams.pageSize,
        where: this.where,
        order: this.orderby
      }

      return params;
    },
    getList() {
      this.loading = true;
      const params = this.getQuery();
      listCbSalary(params).then(response => {
        this.cbSalaryList = response.data.records;
        this.total = response.data.total;
        this.loading = false;
      });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {};
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.selectedRows = selection;
      this.ids = selection.map(item => item.id)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.openGen = true;
      // this.title = "添加薪酬设置";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      // if(row.id){
      //   this.$set(row,"editable", true);
      //   this.setOtherInfoTempOldValue(row);
      // }else if(this.selectedRows&&this.selectedRows.length>0){
      //   this.selectedRows.forEach(item=>{
      //     this.$set(item,"editable", true);
      //     this.setOtherInfoTempOldValue(item);
      //   });
      // }
      this.salaryPayDetailIn = row;
      this.salaryPayDetailEdit = true;
      this.openPaydetail = true;
      // this.reset();
      // this.salaryId = row.id || this.ids;
      // this.open = true;
      // this.title = "修改薪酬设置";
    },
    // setOtherInfoTempOldValue(row){
    //   if(!row.otherInfo) row.otherInfo={};
    //   row.otherInfo.tempOldValue = {insurancePerson:row.insurancePerson,fundPerson:row.fundPerson,attDeduct:row.attDeduct,tax:row.tax,
    //     otherDeduct:row.otherDeduct,otherMemo:row.otherMemo,updateBy: this.$store.getters.userInfo.username,updateTime: getCurrentTime()};
    // },
    // recoveryOtherInfoTempOldValue(row){
    //   if(!row.otherInfo && !row.otherInfo.tempOldValue) return;
    //   const oldValue = row.otherInfo.tempOldValue;
    //   row.insurancePerson=oldValue.insurancePerson
    //   row.fundPerson=oldValue.fundPerson
    //   row.attDeduct=oldValue.attDeduct
    //   row.tax=oldValue.tax
    //   row.otherDeduct=oldValue.otherDeduct
    //   row.otherMemo=oldValue.otherMemo
    // },
    // cancelUpdate(row){
    //   if(row.id){
    //     this.$set(row,"editable", false);
    //     this.recoveryOtherInfoTempOldValue(row);
    //   }else if(this.selectedRows&&this.selectedRows.length>0){
    //     this.selectedRows.forEach(item=>{
    //       this.$set(item,"editable", false);
    //       this.recoveryOtherInfoTempOldValue(item);
    //     });
    //   }
    // },
    // saveUpdate(row){
    //   let form = {id:row.id,calculateType:'人工设置',insurancePerson:row.insurancePerson,fundPerson:row.fundPerson,attDeduct:row.attDeduct,tax:row.tax,
    //     otherDeduct:row.otherDeduct,otherMemo:row.otherMemo,otherInfo:row.otherInfo};
    //   if(!form.otherInfo.oldValue) form.otherInfo.oldValue=[];
    //   form.otherInfo.oldValue.push(row.otherInfo.tempOldValue);
    //   showLoading();
    //   updateCbSalary(form).then(response => {
    //     this.msgSuccess("修改成功");
    //     hideLoading();
    //     // this.open = false;
    //     this.getList();
    //   });
    // },
    //
    handleUpdateAttInfo(row){
      let ids = [];
      if(row.id){
        ids = [row.id];
      }else{
        ids = this.ids;
      }
      showLoading();
      updateAttInfo(ids).then(response=>{
        this.getList();
        hideLoading();
        this.msgSuccess("更新成功");
      });
    },
    handleApplyApprove(row){
      let ids = [];
      if(row.id){
        ids = [row.id];
      }else{
        ids = this.ids;
      }
      if(!ids || ids.length < 1){
        this.$alert('请选择要提交审批的员工', '提交审批');
        return;
      }
      this.$prompt('请输入审批说明', '提示', {confirmButtonText: '确定', cancelButtonText: '取消',
      }).then(({ value }) => {
        showLoading();
        applyApprove({ids:ids,msg:value,operation:'提交审批'}).then(response=>{
          this.getList();
          hideLoading();
          this.msgSuccess("提交成功");
        });
      }).catch(() => {
      });
    },
    // 导出工资审核表
    handleExportApprove(row){
      if(!this.queryParams.salaryDate){
        this.$alert("请选择“工资月份”");
        return;
      }
      // this.$set(this.queryParams,"status",['已发放']);
      const params = this.getQuery();
      this.$confirm('是否确认导出当前查询所有员工的工资审核表?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        showLoading();
        return exportSalaryApprove(params);
      }).then(result=>{
        hideLoading();
        let fileName = this.$store.getters.userInfo.companyName+"("+formatDate(new Date(this.queryParams.salaryDate),'YYYY-mm')+")"+'工资审核表.xlsx'
        this.downloadResult(result,fileName);
      }).catch(e=>{
        if(e == 'cancel'){
          this.msgInfo("下载已取消！")
        }else{
          this.msgError(`后端下载文件出错 & ${e}`)
        }
      });
    },

    handleApproved(row){
      let ids = [];
      if(row.id){
        ids = [row.id];
      }else{
        ids = this.ids;
      }
      this.$prompt('请输入审批意见', '提示', {confirmButtonText: '确定', cancelButtonText: '取消',
      }).then(({ value }) => {
        showLoading();
        approve({ids:ids,msg:value,operation:'审批通过'}).then(response=>{
          this.getList();
          hideLoading();
          this.msgSuccess("更新成功");
        });
      }).catch(() => {
      });
    },
    handleDisapprove(row){
      let ids = [];
      if(row.id){
        ids = [row.id];
      }else{
        ids = this.ids;
      }
      if(!ids || ids.length < 1){
        this.$alert('请选择要审核退回的员工', '审核退回');
        return;
      }
      this.$prompt('请输入审核退回说明', '提示', {confirmButtonText: '确定', cancelButtonText: '取消',
      }).then(({ value }) => {
        showLoading();
        disapprove({ids:ids,msg:value,operation:'审核退回'}).then(response=>{
          this.getList();
          hideLoading();
          this.msgSuccess("审核退回成功");
        });
      }).catch(() => {
      });
    },
    handlePaid(row){
      let ids = [];
      if(row.id){
        ids = [row.id];
      }else{
        ids = this.ids;
      }
      if(!ids || ids.length < 1){
        this.$alert('请选择要发放工资的员工', '发放工资');
        return;
      }
      this.payIds = ids;
      this.openPayTimes = true;
    },
    doPaySalary(){
      if(this.salaryPayTimes.isOneTime){
        this.salaryPayTimes.times = 1;
        this.salaryPayTimes.payPerTime = {"t1":100};
      }
      let total = 0;
      for(let i = 1; i <= this.salaryPayTimes.times; i++){
        console.log(this.salaryPayTimes.payPerTime['t'+i])
        total += Number(this.salaryPayTimes.payPerTime['t'+i]);
      }
      if(total != 100){
        this.$alert("分多次发放比例之和不是100%，当前发放比例和是"+total+"%");
        return;
      }
      showLoading();
      paid({ids:this.payIds, times: this.salaryPayTimes.times, payPerTime:this.salaryPayTimes.payPerTime
          ,operation:'发放薪资', msg:this.salaryPayTimes.msg}).then(response=>{
        this.openPayTimes = false;
        this.getList();
        hideLoading();
        this.msgSuccess("更新成功");
      });
    },

    handlePaidFinish(row){
      let ids = [];
      if(row.id){
        ids = [row.id];
      }else{
        ids = this.ids;
      }
      if(!ids || ids.length < 1){
        this.$alert('请选择薪资发放完成的员工', '薪资发放完成');
        return;
      }
      this.$prompt('请输入薪资发放完成说明', '提示', {confirmButtonText: '确定', cancelButtonText: '取消',
      }).then(({ value }) => {
        showLoading();
        paidComplete({ids:ids,msg:value,operation:'发放完成'}).then(response=>{
          this.getList();
          hideLoading();
          this.msgSuccess("更新成功");
        });
      }).catch(() => {
      });
    },
    handleReturnApprove(row){
      let ids = [];
      if(row.id){
        ids = [row.id];
      }else{
        ids = this.ids;
      }
      if(!ids || ids.length < 1){
        this.$alert('请选择要退回重审的员工', '退回重审');
        return;
      }
      this.$prompt('请输入退回重审说明', '提示', {confirmButtonText: '确定', cancelButtonText: '取消',
      }).then(({ value }) => {
        showLoading();
        returnApprove({ids:ids,msg:value,operation:'退回重审'}).then(response=>{
          this.getList();
          hideLoading();
          this.msgSuccess("更新成功");
        });
      }).catch(() => {
      });
    },
    exportBank(){
      if(!this.queryParams.salaryDate){
        this.$alert("请选择“工资月份”");
        return;
      }
      // this.$set(this.queryParams,"status",['发放中']);
      const params = this.getQuery();
      this.$confirm('是否确认导出当前查询所有员工的银行数据?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        showLoading();
        return exportSalaryBank(params);
      }).then(result=>{
        hideLoading();
        let fileName = this.$store.getters.userInfo.companyName+"("+formatDate(new Date(this.queryParams.salaryDate),'YYYY-mm')+")"+'银行发放数据.xlsx'
        this.downloadResult(result,fileName);
        // this.downloadResult(result,'银行发放数据.xlsx');
      }).catch(e=>{
        if(e == 'cancel'){
          this.msgInfo("下载已取消！")
        }else{
          this.msgError(`后端下载文件出错 & ${e}`)
        }
      });
    },
    exportInsurance(){
      if(!this.queryParams.salaryDate){
        this.$alert("请选择“工资月份”");
        return;
      }
      // this.$set(this.queryParams,"status",['发放中']);
      const params = this.getQuery();
      this.$confirm('是否确认导出当前查询所有员工的社保数据?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        showLoading();
        return exportSalaryInsurance(params);
      }).then(result=>{
        hideLoading();
        let fileName = this.$store.getters.userInfo.companyName+"("+formatDate(new Date(this.queryParams.salaryDate),'YYYY-mm')+")"+'社保记录.xlsx'
        this.downloadResult(result,fileName);
        // this.downloadResult(result,'社保记录.xlsx');
      }).catch(e=>{
        if(e == 'cancel'){
          this.msgInfo("下载已取消！")
        }else{
          this.msgError(`后端下载文件出错 & ${e}`)
        }
      });
    },
    exportFund(){
      if(!this.queryParams.salaryDate){
        this.$alert("请选择“工资月份”");
        return;
      }
      // this.$set(this.queryParams,"status",['发放中']);
      const params = this.getQuery();
      this.$confirm('是否确认导出当前查询所有员工的公积金?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        showLoading();
        return exportSalaryFund(params);
      }).then(result=>{
        hideLoading();
        let fileName = this.$store.getters.userInfo.companyName+"("+formatDate(new Date(this.queryParams.salaryDate),'YYYY-mm')+")"+'公积金记录.xlsx'
        this.downloadResult(result,fileName);
        // this.downloadResult(result,'公积金记录.xlsx');
      }).catch(e=>{
        if(e == 'cancel'){
          this.msgInfo("下载已取消！")
        }else{
          this.msgError(`后端下载文件出错 & ${e}`)
        }
      });
    },
    genSalarySlip(){
      if(!this.queryParams.salaryDate){
        this.$alert("请选择“工资月份”");
        return;
      }
      // this.$set(this.queryParams,"status",['已发放']);
      const params = this.getQuery();
      this.$confirm('是否确认导出当前查询所有员工的工资条?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        showLoading();
        return exportSalarySlip(params);
      }).then(result=>{
        hideLoading();
        let fileName = this.$store.getters.userInfo.companyName+"("+formatDate(new Date(this.queryParams.salaryDate),'YYYY-mm')+")"+'工资单.xlsx'
        this.downloadResult(result,fileName);
      }).catch(e=>{
        if(e == 'cancel'){
          this.msgInfo("下载已取消！")
        }else{
          this.msgError(`后端下载文件出错 & ${e}`)
        }
      });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      let ids = [];
      if(row.id){
        ids = [row.id];
      }else{
        ids = this.ids;
      }

      this.$confirm('是否确认删除选中的薪酬设置数据?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        showLoading();
        return delCbSalary(ids);
      }).then(() => {
        this.getList();
        hideLoading();
        this.msgSuccess("删除成功");
      })
    },
    handleExportTax(){
      if(!this.queryParams.salaryDate){
        this.$alert("请选择“工资月份”");
        return;
      }
      const params = this.getQuery();
      this.$confirm('是否确认导出当前查询所有员工的报税数据?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        showLoading();
        return exportTax(params);
      }).then(result=>{
        let fileName = this.$store.getters.userInfo.companyName+"("+this.queryParams.salaryDate+")"+'正常工资薪金所得.xlsx'
        this.downloadResult(result,fileName);
        // this.downloadResult(result,'正常工资薪金所得.xlsx');
      }).catch(e=>{
        if(e == 'cancel'){
          this.msgInfo("下载已取消！")
        }else{
          this.msgError(`后端下载文件出错 & ${e}`)
        }
      });
    },
    downloadResult(result, fileName){
      if(result){
        let blob = new Blob([result.data], {
          type: result.data.type
        });
        let filename = result.headers.filename && decodeURI(result.headers.filename) || fileName;
        if ('download' in document.createElement('a')) {
          console.log("downloadResult if ")
          const link = document.createElement("a");
          link.style.display = "none";
          link.href = URL.createObjectURL(blob);
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          console.log("downloadResult else")
          navigator.msSaveBlob(blob, filename);
        }
      }
    },
    // handleImportTax(){
    //   this.msgSuccess("功能未实现");
    // },

    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },

    async uploadFile(params) {
      const _file = params.file;
      const formData = new FormData();
      formData.append("companyId",this.queryParams.companyId);
      formData.append('file', _file);
      showLoading();
      importTax(formData).then(response=>{
        console.log(response.code);
        this.fileList = [];
        this.getList();
        hideLoading();
      }).reject( ()=>{
        this.fileList = [];
        hideLoading();
      });
      //
    },

    /** 导出按钮操作 */
    handleExport() {
      const params = this.getQuery();
      this.$confirm('是否确认导出所有薪酬设置数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return exportCbSalary(params);
      }).then(response => {
        this.download(response.msg);
      })
    },
    /** 转换公司数据结构 */
    normalizerCompany(node) {
      if(node.className=='company'){
        return {
          id: node['treeId'],
          label: node['companyName'], //node['id'] + " - " +node['companyName'],
          children: node['children']
        };
      }else if(node.className=='department'){
        return {
          id: node['treeId'],
          label: node['name'],
          children: node['children']
        };
      }
    },

    companyFormat(row, column) {
      // console.log(row);
      for(var i = 0; i < this.companyList.length; i++){
        const item =  this.companyList[i];
        if(item.id == row.companyId){
          return item.companyName;
        }
      }
      return "";
    },
    departmentFormat(row, column) {
      for(var i = 0; i < this.departmentList.length; i++){
        const item =  this.departmentList[i];
        // console.log("id:"+item.id+",depid:"+row.departmentId+", name :"+item.name+",ee="+(item.id == row.departmentId));
        if(item.id == row.depId){
          return item.name;
        }
      }
      return "";
    },
    getCompanyCode(companyId){
      console.log(companyId)
      console.log(this.companyList)
      for(var i = 0; i < this.companyList.length; i++){
        const item =  this.companyList[i];
        if(item.id == companyId){
          return item.code;
        }
      }
    },
    inputChange(val,instanceId){
      // console.log(val)
      if(!val) return;
      this.$emit("change",val);
      if(this.multiple){//只有多选模式下才考虑提示功能
        this.allLabel = val.map(item=>{
          let label = "";
          //getNode是我自己查找下拉树的内置方法，呕心沥血才找到的
          // console.log(this.$refs["treeRef"]);
          label = this.$refs["treeRef"].getNode(this.valueFormat == "object"?item.id:item).label;
          label = label.replace('(unknown)',"(外部)");
          return label;
        })
        let el = this.$el.querySelector(".vue-treeselect__multi-value");
        el.setAttribute("title",this.allLabel.join(", "));
      }else{
        this.removePlaceHolder();
      }
      this.addPlaceHolder(val);
    },
    //增加文字提示tooltip
    addPlaceHolder(value){
      // console.log("addPlaceHolder......")
      // console.log(value)
      let placeholder = this.$el.querySelector(".vue-treeselect__placeholder");
      let temp = value !== "_first"? value:this.value;
      if(placeholder && (!temp || !temp.length)){
        let content = placeholder.innerText;
        placeholder.parentNode.setAttribute("title",content);
      }
    },
    removePlaceHolder(){
      let placeholder = this.$el.querySelector(".vue-treeselect__placeholder");
      if(placeholder){
        placeholder.parentNode.removeAttribute("title");
      }
    },

  }
};
</script>

<style scoped>
.uniAppMarginBottom {
  margin-bottom: 10px;
}
.webMarginBottom {
  margin-bottom: 0px;
}
</style>
