import request from '@/utils/request'

// 修改薪酬模板
export function updateCbSalarySetting(data) {
    return request({
        url: '/cb/cbSalarySetting/edit',
        method: 'post',
        data: data
    })
}

export function importSocialSecurity(data) {
    return request({
        url: '/cb/cbSalarySetting/importSocialSecurity',
        method: 'post',
        data: data
    })
}

export function importFund(data) {
    return request({
        url: '/cb/cbSalarySetting/importFund',
        method: 'post',
        data: data
    })
}

export function importSalary(data) {
    return request({
        url: '/cb/cbSalarySetting/importSalary',
        method: 'post',
        data: data
    })
}
