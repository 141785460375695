import request from '@/utils/request'

// 查询系统设置列表
export function listSysSetting(query) {
    return request({
        url: '/system/sysSetting/findAll',
        method: 'post',
        data: query
    })
}

// 查询默认系统设置详细
export function getDefaultSysSetting() {
    return request({
        url: '/system/sysSetting/getDefault',
        method: 'get'
    })
}

// // 查询系统设置详细
// export function getSysSetting(id) {
//     return request({
//         url: '/system/sysSetting/findOne?id=' + id,
//         method: 'post'
//     })
// }
//
// // 新增系统设置
// export function addSysSetting(data) {
//     return request({
//         url: '/system/sysSetting/add',
//         method: 'post',
//         data: data
//     })
// }

// 修改系统设置
export function updateSysSetting(data) {
    return request({
        url: '/system/sysSetting/edit',
        method: 'post',
        data: data
    })
}

// // 删除系统设置
// export function delSysSetting(id) {
//     return request({
//         url: '/system/sysSetting/remove',
//         method: 'post',
//         data: id
//     })
// }
//
// // 导出系统设置
// export function exportSysSetting(query) {
//     return request({
//         url: '/system/sysSetting/export',
//         method: 'post',
//         data: query
//     })
// }


