<template>
<div>
  <el-form ref="form" :model="form" :rules="rules" label-width="118px">
    <el-row :gutter="35">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" >
        <el-form-item label="班次名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入班次名称，首字符显示在考勤月历"/>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"  align="left">
        <el-form-item label="每天上下班" prop="times" >
          <el-radio-group v-model="form.times"  style="display:flex;">
            <el-radio-button :label="1" >一次</el-radio-button>
            <el-radio-button :label="2" >两次</el-radio-button>
            <el-radio-button :label="3" >三次</el-radio-button>
          </el-radio-group>
        </el-form-item>
      </el-col>
      <!--          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12"  >-->
      <!--            <el-form-item label="考勤方式？" prop="type">-->
      <!--              <el-select v-model="form.type" placeholder="请选择考勤方式？">-->
      <!--                <el-option label="请选择字典生成" value=""/>-->
      <!--              </el-select>-->
      <!--            </el-form-item>-->
      <!--          </el-col>-->
      <template v-for="t in form.times">
      <el-col :key="'on'+t" :xs="24" :sm="12" :md="12" :lg="12" :xl="12"  >
        <el-form-item :key="'onItem'+t" :label="'上班时间'+(t>1?t:'')">
           <el-time-picker :key="'onTime'+t" value-format="HH:mm" format="HH:mm"
              v-model="form.clockSetting['clockOn'+t]"
              placeholder="选择上班时间" @change="updateTotalHours(t)"
              style="width:100%">
          </el-time-picker>
        </el-form-item>
      </el-col>
      <el-col :key="'off'+t" :xs="24" :sm="12" :md="12" :lg="12" :xl="12"  >
        <el-form-item :key="'offItem'+t" :label="'下班时间'+(t>1?t:'')">
           <el-time-picker :key="'offTime'+t" value-format="HH:mm" format="HH:mm" style="width:100% !important;"
              v-model="form.clockSetting['clockOff'+t]"
              placeholder="选择下班时间" @change="updateTotalHours(t)">
          </el-time-picker>
          <el-checkbox v-if="t == form.times" :key="'offOver'+t" v-model="form.clockSetting['clockOffOver'+t]" @change="updateTotalHours(t)">次日</el-checkbox>
        </el-form-item>
      </el-col>
<!--      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"  >-->
<!--        <el-form-item label="中间休息" prop="isRest">-->
<!--          <el-switch-->
<!--              v-model="form.isRest">-->
<!--          </el-switch>-->
<!--          <el-popover-->
<!--              placement="top"-->
<!--              title=""-->
<!--              width="200"-->
<!--              trigger="click"-->
<!--              content="开启中间休息时间，考勤时间小时数会减去休息时间这一段时间。不足一小时不计时。">-->
<!--            <el-button type="text" round slot="reference">？</el-button>-->
<!--          </el-popover>-->
<!--        </el-form-item>-->
<!--      </el-col>-->
<!--      <el-collapse-transition>-->
<!--        <div v-if="form.isRest">-->
<!--          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12"  >-->
<!--            <el-form-item label="休息开始时间" prop="restStart">-->
<!--               <el-time-picker value-format="HH:mm" format="HH:mm"-->
<!--                  v-model="form.restStart"-->
<!--                  placeholder="选择开始时间">-->
<!--              </el-time-picker>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12"  >-->
<!--            <el-form-item label="休息结束时间" prop="restEnd">-->
<!--               <el-time-picker value-format="HH:mm" format="HH:mm"-->
<!--                  v-model="form.restEnd"-->
<!--                  placeholder="选择结束时间">-->
<!--              </el-time-picker>-->
<!--              <el-checkbox v-model="form.isRestEndOver">次日</el-checkbox>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--        </div>-->
<!--      </el-collapse-transition>-->
      </template>

      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12"  >
        <el-form-item label="记作考勤天数" prop="days">
          <el-select v-model="form.days" placeholder="请选择记作考勤天数">
            <el-option :value="0.5" label="0.5天"></el-option>
            <el-option :value="1" label=" 1 天"></el-option>
            <el-option :value="1.5" label="1.5天"></el-option>
            <el-option :value="2" label=" 2 天"></el-option>
          </el-select>
<!--          <el-input v-model="form.days" placeholder="请输入记作考勤天数"><template slot="append">天</template></el-input>-->
        </el-form-item>
      </el-col>
      <el-col v-if="form.otherInfo" :xs="24" :sm="12" :md="12" :lg="12" :xl="12"  >
        <span v-if="form.otherInfo.totalRestHours">总时间：{{form.otherInfo.totalHours?form.otherInfo.totalHours:""}}小时， </span>
        <span>考勤：{{form.otherInfo.totalAttHours?form.otherInfo.totalAttHours:""}}小时</span>
        <span v-if="form.otherInfo.totalRestHours">，休息：{{form.otherInfo.totalRestHours?form.otherInfo.totalRestHours:""}}小时</span>
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"  >
        <el-form-item label="打卡时段设置" prop="clockRange">
          <el-switch
              v-model="form.clockRange">
          </el-switch>
          <el-popover
              placement="top"
              title="打卡时段设置："
              width="300"
              trigger="click">
            <p>在打卡时段外打卡不会被自动计算；</p>
            <p>例如：上班打卡时间是09:00，上班最早打卡设置是60，上班最晚打卡设置是30；</p>
            <p>则只有在08:00 至 09:30之间的打卡记录才为09:00上班的有效的打卡。下班类似。</p>
            <p>未设置则使用默认值</p>
            <el-button type="text" round slot="reference">？</el-button>
          </el-popover>
        </el-form-item>
      </el-col>
      <el-collapse-transition>
        <div v-if="form.clockRange">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12"  >
            <el-form-item label="上班最早打卡" prop="clockOnBegin">
              <el-input v-model="form.clockOnBegin" placeholder="上班最早可以提前多少分钟打卡"><template slot="append">分钟</template></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12"  >
            <el-form-item label="上班最晚打卡" prop="clockOnEnd">
              <el-input v-model="form.clockOnEnd" placeholder="上班最晚可以延后多少分钟打卡"><template slot="append">分钟</template></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12"  >
            <el-form-item label="下班最早打卡" prop="clockOffBegin">
              <el-input v-model="form.clockOffBegin" placeholder="下班最早可以提前多少分钟打卡"><template slot="append">分钟</template></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12"  >
            <el-form-item label="下班最晚打卡" prop="clockOffEnd">
              <el-input v-model="form.clockOffEnd" placeholder="下班最晚可以延后多少分钟打卡"><template slot="append">分钟</template></el-input>
            </el-form-item>
          </el-col>
        </div>
      </el-collapse-transition>
<!--      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"  >-->
<!--        <el-form-item label="下班不打卡" prop="isNoClockOff">-->
<!--          <el-switch-->
<!--              v-model="form.isNoClockOff">-->
<!--          </el-switch>-->
<!--          &lt;!&ndash;              <el-input v-model="form.isNoClockOff" placeholder="请输入下班不用打卡"/>&ndash;&gt;-->
<!--        </el-form-item>-->
<!--      </el-col>-->
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12"  >
        <el-form-item label="迟到" prop="late">
          <el-input v-model="form.late" placeholder="请输入超过几分钟记作迟到"><template slot="append">分钟</template></el-input>
        </el-form-item>
      </el-col>
<!--      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12"  >-->
<!--        <el-form-item label="严重迟到" prop="tooLate">-->
<!--          <el-input v-model="form.tooLate" placeholder="请输入超过几分钟记作严重迟到"/>-->
<!--        </el-form-item>-->
<!--      </el-col>-->
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12"  >
        <el-form-item label="旷工" prop="absent">
          <el-input v-model="form.absent" placeholder="请输入超过几分钟记作旷工"><template slot="append">分钟</template></el-input>
        </el-form-item>
      </el-col>
      <!----------- begin 请假时段设置 ------------->
<!--      <template v-if="form.days > 0.5">-->
<!--      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"  >-->
<!--        请假半天时段设置：-->
<!--        <el-popover-->
<!--            placement="top"-->
<!--            title="请假半天时段设置："-->
<!--            width="300"-->
<!--            trigger="click">-->
<!--          <p>因为请假最低0.5天，考勤时间大于0.5天的需要设置每0.5天的时间段。</p>-->
<!--          <p>例如：记作考勤一天的情况，8:00-17:00点上班并且上下班只打卡一次，可以设置0.5天时间段为 13:00。-->
<!--            这样8:00-13:00为上午请假时间，下午必须13:00前打卡，否则视为迟到或者旷工；下午请假时间为13:00-17:00，上午下班必须13:00后打卡，否则视为早退或旷工。</p>-->
<!--          <p>如果考勤一天，打卡两次情况，8:00-12:00和14:00-15:00，0.5天时间段可以设置12:00-14:00中的任意时间。比如设置13:00,上午请假0.5天填8:00-12:00，-->
<!--            此时因为请假时间已经覆盖上午上班时间段，下午有新的打卡时间段设置，下午上班只需按照正常14:00前打卡即可；下午请假类似</p>-->
<!--&lt;!&ndash;          <p>未设置则使用默认值，默认值为上下班时间的中间值，比如8:00-17:00中间值为12:30。</p>&ndash;&gt;-->
<!--          <el-button type="text" round slot="reference">？</el-button>-->
<!--        </el-popover>-->
<!--      </el-col>-->
<!--      <template v-for="l in (Math.ceil(form.days/0.5)-1)">-->
<!--        <el-col :key="'leave'+l" :xs="24" :sm="12" :md="12" :lg="12" :xl="12"  >-->
<!--          <el-form-item :label="'半天请假时间'+l" :prop="'clockSetting[leaveTime'+l+']'" :rules="{required: true,message: '半天请假时间不能为空', trigger: 'blur' }">-->
<!--            <el-time-picker value-format="HH:mm" format="HH:mm" style="width:100% !important;"-->
<!--                            v-model="form.clockSetting['leaveTime'+l]"-->
<!--                            placeholder="选择请假时间" @change="changeLeaveTime(l)">-->
<!--            </el-time-picker>-->
<!--            <el-checkbox v-model="form.clockSetting['leaveTimeOver'+l]" @change="changeLeaveTime(l)">次日</el-checkbox>-->
<!--          </el-form-item>-->
<!--        </el-col>-->
<!--      </template>-->
<!--      </template>-->
      <!----------- end 请假时段设置 ------------->

    </el-row>
  </el-form>
  <div slot="footer" class="dialog-footer" style="text-align: right">
    <el-button v-if="!isReadOnly" type="primary" @click="submitForm" v-permission="['/attendance/attendanceClass/add', '/attendance/attendanceClass/edit']">确 定</el-button>
    <el-button @click="cancel">取 消</el-button>
  </div>
</div>
</template>

<script>
import {
  getAttendanceClass,
  addAttendanceClass,
  updateAttendanceClass,
  } from "@/api/attendance/attendanceClass";

import permission from '@/directive/permission/index.js'; // 权限判断指令
import {getHourInterval, getEarly, getLater} from "@/utils/dateTimeUtils";

export default {
  name: "attendanceClassEdit",
  props: {
    row: Object,
    isReadOnly: Boolean,
  },

  directives: { permission },
  watch:{
    row(n,o){ //n为新值,o为旧值;
      //if(n === o) return;
      this.load();
    },
    "form.times"(val){
      this.changeTimes(val);
    },
  },

  data() {
    return {
      // 遮罩层
      loading: true,
      // 查询参数
      where: {
        query: '',
        filters: []
      },


      // 表单参数
      form: {
        isRest: false,
        clockRange: false,
        times: 1,
        clockSetting: {clockOn1: null,clockOff1: null, clockOffOver1:false, leaveTime1:null},
        otherInfo: {totalHours:0,totalAttHours:0,totalRestHours:0},
      }
      ,
      // 表单校验
      rules: {
        name: [
          {
            required: true,
            message: "班次名称，首字符显示在考勤月历不能为空", trigger: "blur" }
        ],
        times: [
          {
            required: true,
            message: "上下班次数, 1-3次不能为空", trigger: "blur" }
        ],
        // type: [
        //   {
        //     required: true,
        //     message: "考勤方式？不能为空", trigger: "change"
        //   }
        // ],
        // clockOn1: [
        //   {
        //     required: true,
        //     message: "上班时间不能为空", trigger: "blur" }
        // ],
        // clockOff1: [
        //   {
        //     required: true,
        //     message: "下班时间不能为空", trigger: "blur" }
        // ],
        days: [
          {
            // type: 'float',
            required: true,
            message: "考勤天数必须整数或小数", trigger: "change" },
          // {
          //   type: "float",
          //   message: "必须是数字", trigger: "blur" },
        ],
        // clockOnBegin: [
        //   {
        //     type: "integer",
        //     message: "必须是整数", trigger: "blur" }
        // ],
        // clockOnEnd: [
        //   {
        //     type: "integer",
        //     message: "必须是整数", trigger: "blur" }
        // ],
        // clockOffBegin: [
        //   {
        //     type: "integer",
        //     message: "必须是整数", trigger: "blur" }
        // ],
        // clockOffEnd: [
        //   {
        //     type: "integer",
        //     message: "必须是整数", trigger: "blur" }
        // ],
        // late: [
        //   {
        //     type: "integer",
        //     message: "必须是整数", trigger: "blur" }
        // ],
        // tooLate: [
        //   {
        //     type: "integer",
        //     message: "必须是整数", trigger: "blur" }
        // ],
        // absent: [
        //   {
        //     type: "integer",
        //     message: "必须是整数", trigger: "blur" }
        // ],
      }
    };
  },
  mounted() {
    this.load();
  },

  methods: {
    changeLeaveTime(val){
      console.log(val);
    },
    changeTimes(val){
      // console.log(val);
      for(let i = 1; i<= val; i++){
        if(!this.form.clockSetting['clockOn'+i]){
          this.$set(this.form.clockSetting,'clockOn'+i,"");
          this.$set(this.form.clockSetting,'clockOff'+i,"");
          this.$set(this.form.clockSetting,'clockOffOver'+i,false);
          // this.form.clockSetting['clockOn'+i] = "";
          // this.form.clockSetting['clockOff'+i] = "";
          // this.form.clockSetting['clockOffOver'+i] = false;
        }
      }
    },
    updateTotalHours(t){
      let times = t
      if(!this.form.otherInfo) this.form.otherInfo = {};
      console.log(this.form.clockSetting["clockOn"+times]);
      console.log(this.form.clockSetting["clockOff"+times]);
      // 次日 +24小时
      if(this.form.clockSetting["clockOffOver"+times]){
        this.form.otherInfo["onOffHours"+times] = getHourInterval(this.form.clockSetting["clockOn"+times],this.form.clockSetting["clockOff"+times])+24;
      }else{
        this.form.otherInfo["onOffHours"+times] = getHourInterval(this.form.clockSetting["clockOn"+times],this.form.clockSetting["clockOff"+times]);
      }
      // 休息时段
      if(this.form.clockSetting["isRest"+times]&&this.form.clockSetting["restStart"+times]&&this.form.clockSetting["restEnd"+times]){
        if(this.form.clockSetting["isRestEndOver"+times]){
          this.form.otherInfo["restHours"+times] = getHourInterval(this.form.clockSetting["restStart"+times],this.form.clockSetting["restEnd"+times])+24;
        }else{
          this.form.otherInfo["restHours"+times] = getHourInterval(this.form.clockSetting["restStart"+times],this.form.clockSetting["restEnd"+times]);
        }
      }else{
        this.form.otherInfo["restHours"+times] = 0;
      }
      console.log("restHours:"+this.form.otherInfo["restHours"+times]);
      this.form.otherInfo["attHours"+times] = this.form.otherInfo["onOffHours"+times] - this.form.otherInfo["restHours"+times];
      console.log("attHours:"+this.form.otherInfo["attHours"+times]);
      this.form.otherInfo.totalHours = 0;
      this.form.otherInfo.totalAttHours = 0;
      this.form.otherInfo.totalRestHours = 0;
      this.form.otherInfo.earlyOn = null;
      this.form.otherInfo.laterOff = null;
      for(let i = 1; i <= this.form.times; i++){
        this.form.otherInfo.totalHours += this.form.otherInfo["onOffHours"+i];
        this.form.otherInfo.totalAttHours += this.form.otherInfo["attHours"+i];
        this.form.otherInfo.totalRestHours += this.form.otherInfo["restHours"+i];
        //
        this.form.otherInfo.earlyOn = getEarly(this.form.otherInfo.earlyOn, this.form.clockSetting["clockOn"+i]);
        console.log("计算 later off：");
        console.log(this.form.otherInfo.laterOff);
        console.log(this.form.clockSetting["clockOff"+i]);
        this.form.otherInfo.laterOff = getLater(this.form.otherInfo.laterOff, this.form.clockSetting["clockOff"+i]);
        console.log("later off：");
        console.log(this.form.otherInfo.laterOff);
      }
      // 如果最后一个下班是次日，这最晚下班则是最后一个时间
      if(this.form.clockSetting["clockOffOver"+this.form.times]){
        console.log("次日："+this.form.clockSetting["clockOff"+this.form.times])
        this.form.otherInfo.laterOff = this.form.clockSetting["clockOff"+this.form.times];
        this.form.otherInfo.laterOffOver = true;
      }else{
        console.log("不是次日");
        this.form.otherInfo.laterOffOver = false;
      }
      console.log(this.form.otherInfo["onOffHours"+times]);
      console.log(this.form.otherInfo);
      // 计算默认0.5天请假时间
    },

    load() {
      this.reset();
      if(this.row && this.row.id){
        this.handleUpdate(this.row);
      }else{
        this.handleAdd();
      }
    },

    // 取消按钮
    cancel() {
      // this.open = false;
      // this.reset();
      this.$emit("close", {refresh:false,isSuccess:true});
    },
    // 表单重置
    reset() {
      this.form = {};
      this.resetForm("form");
    },

    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.form = {type: 1, late:0, absent: 60, clockOnBegin:60, clockOnEnd:30,
        clockOffBegin:30, clockOffEnd:120, times: 1,
        clockSetting: {clockOn1: null,clockOff1: null, clockOffOver1:false},
        otherInfo: {totalHours:0,totalAttHours:0,totalRestHours:0},}
      // this.form.type = 1;
      // this.open = true;
      // this.title = "添加班次管理";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.id || this.ids
      getAttendanceClass(id).then(response => {
        this.form = response.data;
        // this.open = true;
        // this.title = "修改班次管理";
      });
    },
    /** 提交按钮 */
    submitForm() {
      this.form.companyId = this.$store.getters.userInfo.companyId;
      this.$refs["form"].validate(valid => {
            if(valid) {

              if (this.form.id != null) {
                updateAttendanceClass(this.form).then(response => {
                  this.msgSuccess("修改成功");
                  this.$emit("close", {refresh:true,isSuccess:true});
                  this.reset();
                });
              } else {
                addAttendanceClass(this.form).then(response => {
                  this.msgSuccess("新增成功");
                  this.$emit("close", {refresh:true,isSuccess:true});
                  this.reset();
                });
              }
            }
          }
      )
      ;
    },
  }
};


</script>

<style  scoped >




</style>
