
// 获取 年月 ，第一天日期 ， str = yyyy-MM ,
// return yyyy-MM-dd
export function getFirstLastDayOfMonth(str){
    let curDate = null;
    if(typeof str === 'string') {
        curDate = new Date(str);
    }else if(typeof str === 'date' || str instanceof Date){
        curDate = str;
    }else{
        curDate = new Date(str);
    }
    let y = curDate.getFullYear();
    let m = curDate.getMonth() + 1;//+1等于计算下一个月
    if( m > 12 ){
        m = 1;
        y++
    }
    let monthLastDay = new Date(y,m,0).getDate();
    let firstDay = y + '-' + (m < 10 ? '0'+m : m) + '-' + '01';
    let lastDay = y + '-' + (m < 10 ? '0'+m : m) + '-' + (monthLastDay < 10 ? '0'+monthLastDay : monthLastDay);
    return [firstDay,lastDay];
}

// 获取 年月 ，第一天日期 ， str = yyyy-MM ,
// return yyyy-MM-dd
export function getFirstLastTimestampDayOfMonth(str){
    let curDate = new Date(str);
    let y = curDate.getFullYear();
    let m = curDate.getMonth() + 1;//+1等于计算下一个月
    if( m > 12 ){
        m = 1;
        y++
    }
    let monthLastDay = new Date(y,m,0).getDate();
    let firstDay = y + '-' + (m < 10 ? '0'+m : m) + '-' + '01';
    let lastDay = y + '-' + (m < 10 ? '0'+m : m) + '-' + (monthLastDay < 10 ? '0'+monthLastDay : monthLastDay);
    let fTimestamp = (new Date(firstDay)).valueOf();
    let lTimestamp = (new Date(lastDay)).valueOf();
    return [fTimestamp,lTimestamp];
}

// 获取日期上个月 t = yyyy-MM , return yyyy-MM
export function getUpMonth(t){
    var year =t.substring(0,4);            //获取当前日期的年
    var month = t.substring(5,7);              //获取当前日期的月

    var year2 = year;
    var month2 = parseInt(month)-1;
    if(month2==0) {
        year2 = parseInt(year2)-1;
        month2 = 12;
    }

    if(month2<10) {
        month2 = '0'+month2;
    }
    var m = year2.toString();
    var n= month2.toString();
    var t2 = m+"-"+n;
    return t2;
}

// 获取上个月日期， t = yyyy-MM-dd return yyyy-MM-dd
// 2022-01-31, return 2021-12-30
export function getUpMonthDay(t){
    var tarr = t.split('-');
    var year = tarr[0];                //获取当前日期的年
    var month = tarr[1];            //获取当前日期的月
    var day = tarr[2];                //获取当前日期的日
    var days = new Date(year,month,0);
    days = days.getDate();//获取当前日期中的月的天数
    var year2 = year;
    var month2 = parseInt(month)-1;
    if(month2==0) {
        year2 = parseInt(year2)-1;
        month2 = 12;
    }
    var day2 = day;
    var days2 = new Date(year2,month2,0);
    days2 = days2.getDate();
    if(day2>days2) {
        day2 = days2;
    }
    if(month2<10) {
        month2 = '0'+month2;
    }
    var t2 = year2+'-'+month2+'-'+day2;
    return t2;
}

// 获取年月 天数组,str = yyyy-MM
export function getDaysArrOfMonth(str){
    let curDate = null;
    if(str){
        curDate = new Date(str);
    }else{
        curDate = new Date();
    }
    console.log(curDate);
    let y = curDate.getFullYear();
    let m = curDate.getMonth() + 1;//+1等于计算下一个月
    if( m > 12 ){
        m = 1;
        y++
    }
    let monthLastDay = new Date(y,m,0).getDate();
    let days = [];
    let week = "日一二三四五六";
    for(let d = 1;  d <= parseInt(monthLastDay); d++){
        let day = y + '-' + (m < 10 ? '0'+m : m) + '-' + (d < 10 ? '0'+d : d);
        let dayOjb = {day: d, week: week.charAt((new Date(day)).getDay())}
        days.push(dayOjb);
    }
    console.log(days);
    return days;
}

/**
 * 得到开始和结束日期，得到中间所有天返回数组
 * @param {String} string 开始日期'2021-7-1'
 * @param {String} String 结束日期'2021-8-1'
 * @return {Array} ['2021-07-01', '2021-07-01'...., '2021-08-01']
 */
export function getDayAll(starDay, endDay) {
    var arr = [];
    var dates = [];
    // 设置两个日期UTC时间
    var db = new Date(starDay);
    var de = new Date(endDay);
    // 获取两个日期GTM时间
    var s = db.getTime() - 24 * 60 * 60 * 1000;
    var d = de.getTime() - 24 * 60 * 60 * 1000;
    // 获取到两个日期之间的每一天的毫秒数
    for (var i = s; i <= d;) {
        i = i + 24 * 60 * 60 * 1000;
        arr.push(parseInt(i))
    }
    // 获取每一天的时间  YY-MM-DD
    for( var j in arr ){
        var time = new Date(arr[j]);
        var year = time.getFullYear(time);
        var mouth = (time.getMonth() + 1)>=10?(time.getMonth() + 1):('0'+(time.getMonth() + 1));
        var day = time.getDate()>=10?time.getDate():('0'+time.getDate());
        var YYMMDD = year + '-' + mouth + '-' + day;
        dates.push(YYMMDD)
    }
    return dates
}

/**
 * 得到开始和结束日期，得到中间所有月返回数组
 * @param {String} string 开始日期'2021-1-1'
 * @param {String} String 结束日期'2021-12-1'
 * @return {Array} ['2021-01', '2021-02'...., '2021-12']
 */
export function getMonthAll(startDay, endDay) {
    let months = [];
    let start = parseInt(startDay.substring(0,4)+""+startDay.substring(5,7));
    let end = parseInt(endDay.substring(0,4)+""+endDay.substring(5,7));
    console.log(start);
    for(let month = start; month <= end; month++){
        let m = month % 100;
        if(m == 13){
            let y = Math.floor(month / 100) + 1;
            month = parseInt(y.toString() + "01");
        }
        months.push(month.toString().substring(0,4)+"-"+month.toString().substring(4,6));
    }
    return months
}



//获取某月第一天和最后一天日期
export function getDateByMonth (timeStamp) {
    let inDate = new Date(timeStamp)
    let year = inDate.getFullYear()
    let month = inDate.getMonth()
    let startTime = new Date(year, month, 1).getTime()
    let endTime =  new Date(year, month + 1, 0).getTime()
    return {
        startTime: startTime,
        endTime: endTime
    }
}

/** 根据时间戳 获取其月开始时间戳和结束时间戳 */
export function getTimeStamp (timeStamp) {
    let inDate = new Date(timeStamp)
    let year = inDate.getFullYear()
    let month = inDate.getMonth()
    let startTime = new Date(year, month, 1).getTime()
    let endTime =  new Date(year, month + 1, 1).getTime() - 1
    return {
        startTime: startTime,
        endTime: endTime
    }
}

//自己注意传递要么都带日期，要么都不带，否则计算出来的不对
//getHourInterval('2017-8-23 12:05:05', '2017-8-25 10:05:05')
//getHourInterval('12:05:05', '10:05:05')
export function getHourInterval(s1, s2) {
    let reDate = /\d{4}-\d{1,2}-\d{1,2} /;
    s1 = new Date((reDate.test(s1) ? s1 : '2022-1-1 ' + s1).replace(/-/g, '/'));
    s2 = new Date((reDate.test(s2) ? s2 : '2022-1-1 ' + s2).replace(/-/g, '/'));
    let ms = (s2.getTime() - s1.getTime())/1.0;
    // if (ms < 0) return 0;
    // return Math.floor(ms / 1000 / 60 / 60);  //小时
    let hours = Math.floor(ms / 1000 / 60 / 60 *10)/10;
    return hours;
}

// 两时间获取较早的一个 自己注意传递要么都带日期，要么都不带，否则计算出来的不对
export function getEarly(s1,s2){
    if(!s1) return s2;
    if(!s2) return s1;
    let reDate = /\d{4}-\d{1,2}-\d{1,2} /;
    let dt1 = new Date((reDate.test(s1) ? s1 : '2022-1-1 ' + s1).replace(/-/g, '/'));
    let dt2 = new Date((reDate.test(s2) ? s2 : '2022-1-1 ' + s2).replace(/-/g, '/'));
    if(dt1.getTime() < dt2.getTime()) return s1;
    else return s2;
}

// 两时间获取较晚的一个 自己注意传递要么都带日期，要么都不带，否则计算出来的不对
// o - 是否次日
export function getLater(s1, s2){
    if(!s1) return s2;
    if(!s2) return s1;
    let reDate = /\d{4}-\d{1,2}-\d{1,2} /;
    let dt1 = new Date((reDate.test(s1) ? s1 : '2022-1-1 ' + s1).replace(/-/g, '/'));
    let dt2 = new Date((reDate.test(s2) ? s2 : '2022-1-1 ' + s2).replace(/-/g, '/'));
    // if(o1){
    //     dt1 = dt1.setDate(dt1.getDate()+1);
    // }
    // if(o2){
    //     dt2 = dt2.setDate(dt2.getDate()+1);
    // }
    if(dt1.getTime() > dt2.getTime()) return s1;
    else return s2;
}


function repair(i){
    if (i >= 0 && i <= 9) {
        return "0" + i;
    } else {
        return i;
    }
}

export function getCurrentTime() {
    var date = new Date();//当前时间
    var year = date.getFullYear() //返回指定日期的年份
    var month = repair(date.getMonth() + 1);//月
    var day = repair(date.getDate());//日
    var hour = repair(date.getHours());//时
    var minute = repair(date.getMinutes());//分
    var second = repair(date.getSeconds());//秒

    //当前时间
    var curTime = year + "-" + month + "-" + day
        + " " + hour + ":" + minute + ":" + second;
    return curTime;
}

// return YYYY-MM-dd
export function getDateStr(date){
    if(typeof date === 'date' || date instanceof Date){
        return date.getFullYear()+"-"+repair(date.getMonth()+1)+"-"+repair(date.getDate());
    }else{
        return date;
    }

}

// yyyy-mm
export function getYearMonth(date){
    if(typeof date === 'date' || date instanceof Date){
        return date.getFullYear()+"-"+repair(date.getMonth()+1);
    }else{
        return null;
    }
}

export function getJavaLocalDateStr(localDate){
    let dateStr = localDate.year+'-'+repair(localDate.monthValue)+'-'+repair(localDate.dayOfMonth)+' '
    +repair(localDate.hour)+':'+repair(localDate.minute);
    return dateStr;
}
// export function getSpecialDate(){
//     var day = new Date();
//
//     var today = day.format('yyyy-MM-dd');//今天
//     var yesterday = new Date(new Date().setDate(new Date().getDate() - 1)).format('yyyy-MM-dd');//昨天
//
//     //本月第一天和本月最后一天
//     var thismonth1 = new Date(new Date().setDate(1)).format('yyyy-MM-dd');
//     var thismonth2 = new Date(new Date(new Date().setMonth(new Date().getMonth() + 1)).setDate(0)).format('yyyy-MM-dd');
//     //上一月第一天和最后一天
//     var lastmonth1 = new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)).format('yyyy-MM-dd');
//     var lastmonth2 = new Date(new Date().setDate(0)).format('yyyy-MM-dd');
//
//     //本周的第一天和最后一天
//     var weekday = new Date().getDay() || 7;
//     var thisweek1 = new Date(new Date().setDate(new Date().getDate() - weekday + 1)).format('yyyy-MM-dd');
//     var thisweek2 = new Date(new Date().setDate(new Date().getDate() - weekday + 7)).format('yyyy-MM-dd')
//
//     //上周的第一天和最后一天
//     var lastweek1 = new Date(new Date().setDate(new Date().getDate() - weekday - 6)).format('yyyy-MM-dd');
//     var lastweek2 = new Date(new Date().setDate(new Date().getDate() - weekday)).format('yyyy-MM-dd')
//
//     //本季度第一天和最后一天
//     var month = new Date().getMonth();
//     var newmonth
//     var newmonth1
//     if (month < 3) {
//         newmonth = new Date(new Date().setMonth(0))
//         newmonth1 = new Date(new Date().setMonth(3))
//     } else if (2 < month && month < 6) {
//         newmonth = new Date(new Date().setMonth(3))
//         newmonth1 = new Date(new Date().setMonth(6))
//     } else if (5 < month && month < 9) {
//         newmonth = new Date(new Date().setMonth(6))
//         newmonth1 = new Date(new Date().setMonth(9))
//     } else if (8 < month && month < 11) {
//         newmonth = new Date(new Date().setMonth(9))
//         newmonth1 = new Date(new Date().setMonth(12))
//     }
//     var thisseason1 = new Date(newmonth.setDate(1)).format('yyyy-MM-dd')
//     var thisseason2 = new Date(newmonth1.setDate(0)).format('yyyy-MM-dd')
//     //上半年第一天和最后一天
//     var firsthalf1 = new Date(new Date(new Date().setMonth(0)).setDate(1)).format('yyyy-MM-dd')
//     var firsthalf2 = new Date(new Date(new Date().setMonth(6)).setDate(0)).format('yyyy-MM-dd')
//
//     //下半年第一天和最后一天
//     var secondehalf1 = new Date(new Date(new Date().setMonth(6)).setDate(1)).format('yyyy-MM-dd')
//     var secondehalf2 = new Date(new Date(new Date().setMonth(12)).setDate(0)).format('yyyy-MM-dd')
// }
