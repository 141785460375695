<template>
<div class="app-container">
  <el-row>
    <el-col>
      请先选择需要查询统计的公司部门：
      <treeselect v-model="queryParams.companyId" :options="treeData" :multiple="true" value-consists-of="ALL"
                  :limit="5" :limitText="count => `及其它${count}部门`" :flat="true"
                  :normalizer="normalizerCompany" @select="selectCompany" @input="companyIdInput"
                  @deselect="deselectCompany" placeholder="请选择公司"
      />
    </el-col>
  </el-row>
  <el-tabs v-model="activeName" @tab-click="handleClick">
    <el-tab-pane label="考勤查询" name="query">
      <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="auto">
        <el-form-item label="考勤日期">
          <el-date-picker type="date" clearable size="small" v-model="queryParams.clockDateStart" value-format="yyyy-MM-dd" placeholder="选择开始日期"/>
          至
          <el-date-picker type="date" clearable size="small" v-model="queryParams.clockDateEnd" value-format="yyyy-MM-dd" placeholder="选择结束日期"/>
        </el-form-item>
        <el-form-item label="员工姓名">
          <el-input v-model="queryParams.empName" placeholder="请输入员工姓名" clearable size="small" @keyup.enter.native="handleQuery"/>
        </el-form-item>
        <el-form-item label="考勤状态">
          <el-select v-model="queryParams.attType" multiple placeholder="请选择考勤状态" clearable size="small">
            <el-option v-for="item in getDictDatas(DICT_TYPE.ATT_STATISTIC_TYPE)" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled">
            </el-option>
          </el-select>
<!--          <el-input v-model="queryParams.attType" placeholder="请输入考勤状态" clearable size="small" @keyup.enter.native="handleQuery"/>-->
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
          <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
        </el-form-item>
      </el-form>
      <el-row :gutter="10" class="mb8">
        <el-col :span="1.5">
          <el-button type="primary" plain icon="el-icon-search" size="mini" @click="queryToday" class="button-strap-gap" >今日考勤</el-button>
          <el-button type="primary" plain icon="el-icon-search" size="mini" @click="queryYestoday" class="button-strap-gap" >昨日考勤</el-button>
          <el-button type="primary" plain icon="el-icon-search" size="mini" @click="queryPreMonth"  class="mb10 button-strap-gap">上月考勤</el-button>
          <el-button type="primary" plain icon="el-icon-search" size="mini" @click="queryThisMonth" class="button-strap-gap" >本月考勤</el-button>
          <el-button type="primary" plain icon="el-icon-download" size="mini" @click="exportExcel" class="button-strap-gap" >导出</el-button>
        </el-col>
        <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>
      </el-row>
      <el-table id="table_excel" v-loading="loading" :data="attStatisticDayList" @selection-change="handleSelectionChange" stripe border>
        <el-table-column type="selection" width="55" align="center"/>
        <el-table-column label="公司名称" align="center" width="200px">
          <template slot-scope="scope">{{ getCompanyName(scope.row.companyId)}}</template>
        </el-table-column>
        <el-table-column label="部门名称" align="center" prop="depName" width="200px">
          <template slot-scope="scope">{{ getDepartmentName(scope.row.depId)}}</template>
        </el-table-column>
        <el-table-column label="员工姓名" align="center" prop="empName" width="100px"/>
        <el-table-column label="考勤日期" align="center" prop="clockDate" width="100px"/>
        <el-table-column label="考勤状态" align="center" prop="attType" width="100px"/>
        <el-table-column label="考勤天数" align="center" prop="days" width="100px"/>
        <el-table-column label="出勤天数" align="center" prop="clockDays" width="100px"/>
        <el-table-column label="迟到次数" align="center" prop="lateTimes" width="100px"/>
        <el-table-column label="早退次数" align="center" prop="earlyTimes" width="100px"/>
        <el-table-column label="是否旷工" align="center" width="100px">
          <template slot-scope="scope">{{ scope.row.absentTime > 0? "旷工":"" }}</template>
        </el-table-column>
        <el-table-column label="请假情况" align="center" width="100px">
          <template slot-scope="scope">{{ getLeave(scope.row.otherInfo)}}</template>
        </el-table-column>
        <el-table-column label="考勤组" align="center" prop="attGroupName" width="100px"/>
        <el-table-column label="班次" align="center" prop="attClassName" width="100px"/>
        <el-table-column label="上下班次数" align="center" prop="times" width="100px"/>
        <el-table-column label="操作" align="center" class-name="small-padding fixed-width" width="110px">
          <template slot-scope="scope">
            <el-button v-if="scope.row.attType!='休息日' && scope.row.attType!='未完成'" size="mini" type="text" icon="el-icon-view" @click="viewClock(scope.row)" >打卡详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <pagination
          v-show="total>0"
          :total="total"
          :page.sync="queryParams.pageNum"
          :limit.sync="queryParams.pageSize"
          @pagination="getList"
      />
    </el-tab-pane>
<!--    <el-tab-pane label="考勤日统计" name="day">-->
<!--    </el-tab-pane>-->
    <el-tab-pane label="考勤日统计" name="day">
      <StatisticDays :depId="queryParams.depId" :companyList="companyList" :departmentList="departmentList"></StatisticDays>
    </el-tab-pane>
    <el-tab-pane label="考勤月统计" name="month">
      <StatisticMonth :depId="queryParams.depId" :companyList="companyList" :departmentList="departmentList"></StatisticMonth>
    </el-tab-pane>
<!--    <el-tab-pane label="加班统计" name="ot">-->
<!--    </el-tab-pane>-->
    <el-tab-pane label="休假统计" name="leave">
      <StatisticLeave :depId="queryParams.depId" :companyList="companyList" :departmentList="departmentList"></StatisticLeave>
    </el-tab-pane>
  </el-tabs>
  <el-dialog title="打卡详情" :visible.sync="open" width="80%" append-to-body>
    <ClockDetail :clockDetailData="clockDetailData" :isReadOnly="true" :isShowClose="true" @close="open = false"></ClockDetail>
  </el-dialog>

</div>
</template>

<script>
import {
  listAttStatisticDay, count, clockOnOff, getAttStatisticDay, updateAttStatisticDay,
  exportAttStatisticDay, statisticByEmp } from "@/api/attendance/attStatisticDay";
import { showLoading, hideLoading } from '@/utils/reqloading';
import {formatDate} from "@/utils/formatTime";
import {getFirstLastDayOfMonth, getDayAll} from "@/utils/dateTimeUtils";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { LOAD_CHILDREN_OPTIONS } from '@riophae/vue-treeselect'
import RightToolbar from "@/components/RightToolbar"
import permission from '@/directive/permission/index.js' // 权限判断指令
import Pagination from "@/components/Pagination";
import {checkRole} from "@/utils/role";
import {getAllOrgTreeWithParent} from "../../org/components/orgtree";
import ClockDetail from "./clockDetail";
import {DICT_TYPE, getDictDatas} from "@/utils/dict";
import StatisticDays from "./statisticDays";
import StatisticMonth from "./statisticMonth";
import StatisticLeave from "./statisticLeave"
import FileSaver from "file-saver";
import * as XLSX from "xlsx";

export default {
  name: "index.vue",
  components: {
    Treeselect,
    RightToolbar,
    Pagination,
    ClockDetail,
    StatisticDays,
    StatisticMonth,
    StatisticLeave,
  },
  directives: { permission },
  data() {
    return {
      companyList: [],
      departmentList: [],
      treeData: [],
      absentCount: 0,
      activeName: 'query',
      attStatisticDayList: null,
      clockDetailData: null,
      searchLoading: false,
      // 遮罩层
      loading: false,
      // 查询参数
      where: {
        query: '',
        filters: []
      },
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      open: false,
      // 总条数
      total: 0,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        companyId: null,
        depId: null,
        empName: null,
        attType: null,
        clockDateStart: null,
        clockDateEnd: null,
        addr: null,
        wifi: null,
        machine: null,
        photo: null,
        photoAddr: null,
      },
    }
  },
  created() {
    const [start, end] = getFirstLastDayOfMonth(new Date());
    this.queryParams.clockDateStart = start;
    this.queryParams.clockDateEnd = end;
    getAllOrgTreeWithParent(false, (tree, companyList, departmentList)=>{
      console.log("tree");
      console.log(tree);
      this.companyList = companyList;
      this.treeData = tree;

      this.departmentList = departmentList;
      // this.getList();
    });
  },
  methods: {
    getLeave(otherInfo){
      let leave = [];
      if(!otherInfo) return "";
      // console.log(otherInfo);
      let leaveInfo = otherInfo.leaveInfo;
      if(!leaveInfo) return "";
      let keys = Object.keys(leaveInfo);
      if(!keys || keys.length <= 0) return "";
      keys.forEach(k =>{
        leave.push(leaveInfo[k]);
      });
      return leave.join(",");
    },
    queryToday(){
      const today = formatDate(new Date(),'YYYY-mm-dd');
      this.queryParams.clockDateStart = today;
      this.queryParams.clockDateEnd = today;
      this.getList();
    },
    queryYestoday(){
      let today = new Date();
      let yesterday = new Date(today.setDate(today.getDate() - 1));
      console.log(yesterday);
      // const yesterday = formatDate(date, 'yyyy-MM-dd');//昨天
      this.queryParams.clockDateStart = yesterday;
      this.queryParams.clockDateEnd = yesterday;
      this.getList();
    },
    queryPreMonth(){
      let date = new Date();
      let preMonth = new Date(date.setMonth(date.getMonth() - 1));
      const [start, end] = getFirstLastDayOfMonth(preMonth);
      this.queryParams.clockDateStart = start;
      this.queryParams.clockDateEnd = end;
      this.getList();
    },
    queryThisMonth(){
      const [start, end] = getFirstLastDayOfMonth(new Date());
      this.queryParams.clockDateStart = start;
      this.queryParams.clockDateEnd = end;
      this.getList();
    },
    queryAbsent(param){
      count(param).then(response => {
        console.log("queryAbsent:")
        console.log(response.data);
        this.absentCount = response.data;
        // this.total = response.data.total;
        // this.loading = false;
      });
    },
    companyIdInput(value, instanceId){
      console.log("companyIdInput");
      console.log(value);
      console.log(instanceId);
      console.log(this.queryParams.companyId);
      // const companyIds = this.getClassIds(value,"company");
      this.queryParams.positionId = null;
      this.queryParams.depId = this.getClassIds(value,"department");
      console.log(this.queryParams.depId);
      // this.queryParams.positionId = null;
      // this.$refs["selectPosTree"].clear();
      // this.getDepartment(value);
      // this.getPosition(value);
      console.log("companyIdInput");
    },
    getClassIds(value, className){
      if(value && value.length>0){
        let ids = [];
        value.forEach( id => {
          if(id.startsWith(className)){
            const idstr = id.substring(className.length);
            console.log(idstr)
            ids.push(idstr);
          }
        })
        console.log("getClassIds , ids=");
        console.log(ids);
        return ids;
      }else{
        return [];
      }
    },
    deselectCompany(node, instanceId) {
      console.log("deselectCompany");
      console.log(node.id);
      console.log(instanceId);
    },
    selectCompany(node, instanceId) {
    },
    /** 转换公司数据结构 */
    normalizerCompany(node) {
      // if (node['id'] && !node['id'].length) {
      //   delete node['children'];
      // }
      if(node.className=='company'){
        return {
          id: node['treeId'],
          label: node['id'] + " - " +node['companyName'],
          children: node['children']
        };
      }else if(node.className=='department'){
        return {
          id: node['treeId'],
          label: node['name'],
          children: node['children']
        };
      }
      // return this.normalizer(node,'id','companyName');
    },

    /** 查询打卡记录列表 */
    getQuery(attType){
      this._filter = {};
      // 人事和经理可以查看所有人，员工只能看自己
      const role = ["Admin","GM","HRManager","CAdmin","Attence"];
      if(!checkRole(role)){
        this._filter['userId'] = {
          type: 'eq',
          value: this.$store.getters.userInfo.id,
        }
      }
      if(this.queryParams.empName) {
        this._filter['empName'] = {
          type: 'search',
          value: this.queryParams.empName,
        }
      }
      if(attType){
        //this.$set(this.queryParams,'attType',null);
        this._filter['attType'] = {
          type: 'eq',
          value: attType,
        }
      }else if(this.queryParams.attType) {
        console.log(this.queryParams.attType);
        // const attype = this.queryParams.attType.split(",");
        this._filter['attType'] = {
          type: 'in',
          value: this.queryParams.attType,
        }
      }
      if(this.queryParams.clockDateStart && this.queryParams.clockDateEnd) {
        this._filter['clockDate'] = {
          type: 'date',
          value: [this.queryParams.clockDateStart,this.queryParams.clockDateEnd],
        }
      }else if(this.queryParams.clockDateStart) {
        this._filter['clockDate'] = {
          type: 'ge',
          value: this.queryParams.clockDateStart,
        }
      }else if(this.queryParams.clockDateEnd) {
        this._filter['clockDate'] = {
          type: 'le',
          value: this.queryParams.clockDateEnd,
        }
      }
      if(this.queryParams.depId) {
        this._filter['depId'] = {
          type: 'in',
          value: this.queryParams.depId,
        }
      }
      const filters = this.parseFilters(this._filter)
      if (filters && filters.length > 0) {
        this.where.filters = filters
      } else {
        // 如果过滤条件为空，设置空数组
        this.where.filters = []
      }
      const params = {
        pageNum: this.queryParams.pageNum,
        pageSize: this.queryParams.pageSize,
        where: this.where,
        order: ' clockDate desc '
      }

      return params;
    },
    getList() {
      this.loading = true;
      let params = this.getQuery();
      listAttStatisticDay(params).then(response => {
        this.attStatisticDayList = response.data.records;
        this.total = response.data.total;
        this.loading = false;
      });

      params = this.getQuery("旷工");
      this.queryAbsent(params);
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {};
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },
    normalizerDepartment(node) {
      return this.normalizer(node);
    },
    getDepartmentName(id){
      //console.log(id);
      for(let i = 0; i < this.departmentList.length; i++){
        if(id == this.departmentList[i].id) return this.departmentList[i].name;
      }
      return "";
    },
    getCompanyName(id){
      for(let i = 0; i < this.companyList.length; i++){
        if(id == this.companyList[i].id) return this.companyList[i].name;
      }
      return "";
    },
    viewClock(row){
      console.log("viewClock");
      this.open = true;
      this.clockDetailData = row;
    },
    handleCommand(command){
      console.log(command);
      if(command.cmd == "viewClock"){
        // this.showFlowDetail(command.row);
        this.viewClock(command.row);
      }else if(command.cmd === "view"){
        this.showTask(command.row);
      }else {

      }
    },

    exportExcel(){
      this.$confirm("确定将当前查询页导出到Excel？如果导出记录不够，可以将每页显示记录数设置更大数值，比如：200条/页","导出提示",{
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "info",
      }).then(()=>{
        setTimeout(()=>{
          var xlsxParam = { raw: true }  // 导出的内容只做解析，不进行格式转换
          let tables = document.getElementById('table_excel');   //这里是绑定元素，输入你要导出的table表格的id
          console.log(XLSX)
          let table_book = XLSX.utils.table_to_book(tables,xlsxParam);
          const table_write = XLSX.write(table_book, {
            bookType: "xlsx",
            bookSST: true,
            type: "array",
          });
          try {
            let title = "考勤查询";
            FileSaver.saveAs(
                new Blob([table_write], { type: "application/octet-stream" }),
                `${title}.xlsx`   //这里是文件名
            );
          } catch (e) {
            if (typeof console !== "undefined") console.log(e, table_write);
          }
          return table_write;
        },1000)
      });
    },
  },

}
</script>

<style scoped>

</style>
