<template>
    <el-form :model="testPaper" label-width="80px" :rules="rules" ref="form" >
		<el-row :gutter="24">
				<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb10">
                    <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12"><el-form-item label="试卷名称" class="mb5" prop="paperName"><el-input  v-model="testPaper.paperName" clearable></el-input></el-form-item></el-col>
					<el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
					<el-form-item label="公司" prop="companySelect">
						<el-select v-model="companySelect" clearable @clear="handleClearable" placeholder="请选择" 
							collapse-tags ref="companySelected">
							<el-option :value="this.companyId" :label="companySelect" style="height: auto">
								<el-tree :data="companyOptions" :props="defaultProps" :expand-on-click-node="false"
									ref="tree" show-checkbox node-key="id" default-expand-all
									@node-click="handleNodeClick" @check="handleNodeClick" highlight-current/>
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
					<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6"><el-form-item label="试卷总分" prop="totalScore" class="mb5"><el-input type="number"  v-model.number="testPaper.totalScore" clearable></el-input></el-form-item></el-col>
					<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6"><el-form-item label="合格分数" prop="passScore" class="mb5"><el-input type="number"  v-model.number="testPaper.passScore" clearable></el-input></el-form-item></el-col>
					<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6"><el-form-item label="良好分数" prop="goodScore" class="mb5"><el-input type="number"  v-model.number="testPaper.goodScore" clearable></el-input></el-form-item></el-col>
					<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6"><el-form-item label="优秀分数" prop="excellentScore" class="mb5"><el-input type="number"  v-model.number="testPaper.excellentScore" clearable></el-input></el-form-item></el-col>
                </el-col>
		</el-row>
			<el-row :gutter="24">
				<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb10">
                    <div :xs="5" :sm="3" :md="3" :lg="3" :xl="3" style="margin-bottom:10px;"> <span  class="tag-group__title" style="font-weight:600;">单项选择题</span></div> 
					<div :xs="8" :sm="4" :md="4" :lg="4" :xl="4" class="title">
						<span>总共</span>
						<el-select v-model="singleTotal" size="mini" clearable class="title-button" >
							<el-option v-for="item in questionCount" :key="item" :label="item" :value="item" />
						</el-select>
						<span>题，每题</span>
						<el-select v-model="singleScore"  size="mini" class="title-button" clearable @change="singleSelectionChanged"  >
							<el-option v-for="item in singleScores" :key="item" :label="item" :value="item" />
						</el-select>
						
						<span>分</span>
					</div>
					<div :xs="24" :sm="4" :md="4" :lg="4" :xl="4" style="display:inline;margin-left:35px;">
					  <el-button type="primary" class="button-bottom" style="margin-left:15px;margin-top:10px;" plain icon="el-icon-plus" size="mini" @click="addSingleSelection" ></el-button>
                      <el-button type="primary" class="button-bottom" plain icon="el-icon-minus" size="mini" @click="deleteSingleSelection" ></el-button>
					</div>
			    </el-col>
                <el-col v-for="singleSelection in testPaper.singleSelections" :key="singleSelection.seq" :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb5">
					 <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"><el-form-item :label="singleSelection.seq+'.  题干'" class="mb5"><el-input v-model="singleSelection.question" clearable></el-input></el-form-item></el-col>
                    <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12"><el-form-item label="A. " class="mb5"><el-input v-model="singleSelection.selectionA" clearable ></el-input></el-form-item></el-col>
                    <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12"><el-form-item label="B. " class="mb5"><el-input v-model="singleSelection.selectionB" clearable ></el-input></el-form-item></el-col>
                    <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12"><el-form-item label="C. " class="mb5"><el-input v-model="singleSelection.selectionC" clearable ></el-input></el-form-item></el-col>
                    <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12"><el-form-item label="D. " class="mb5"><el-input v-model="singleSelection.selectionD" clearable ></el-input></el-form-item></el-col>
                    <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12"><el-form-item label="E. " class="mb5"><el-input v-model="singleSelection.selectionE" clearable ></el-input></el-form-item></el-col>
                    <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12"><el-form-item label="分值" class="mb5 scoreWidth"><el-input v-model="singleSelection.score" clearable ></el-input></el-form-item></el-col>
                    <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
						<el-form-item label="标准答案" class="mb5"  >
							<el-select v-model="singleSelection.answer" >
								<el-option v-for="item in singleSelections2" :key="item.value" :label="item.label" :value="item.value" />
							</el-select>
						</el-form-item>
					</el-col>

				</el-col>
            </el-row>
            <el-row :gutter="24">
				<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb10">
                    <div :xs="5" :sm="3" :md="3" :lg="3" :xl="3" style="margin-bottom:10px;"><span class="tag-group__title" style="font-weight:600;">多项选择题</span></div>
					<div class="title" :sm="4" :md="4" :lg="4" :xl="4" >
						<span>总共</span>
						<el-select v-model="multipleTotal" size="mini" clearable class="title-button" >
							<el-option v-for="item in questionCount" :key="item" :label="item" :value="item" />
						</el-select>
						<span>题，每题</span>
						<el-select v-model="multipleScore"  size="mini" class="title-button" clearable @change="multipleSelectionChanged" >
							<el-option v-for="item in singleScores" :key="item" :label="item" :value="item" />
						</el-select>
						<span>分</span>
					</div>
					<div :xs="24" :sm="4" :md="4" :lg="4" :xl="4" style="display:inline;margin-left:35px;">
					<el-button class="button-bottom" plain icon="el-icon-plus" style="margin-left:15px;margin-top:10px;"  size="mini" @click="addMultipleSelection" ></el-button>
                    <el-button class="button-bottom" type="primary" plain icon="el-icon-minus" size="mini" @click="deleteMultipleSelection" ></el-button>
					</div>
                </el-col>
                  <el-col v-for="multipleSelection in testPaper.multipleSelections" :key="multipleSelection.seq" :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb5">
                    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                        <el-form-item :label="multipleSelection.seq+'.  题干'" class="mb5" ><el-input v-model="multipleSelection.question" clearable></el-input></el-form-item>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12"><el-form-item label=" A." class="mb5" ><el-input v-model="multipleSelection.selectionA" clearable ></el-input></el-form-item></el-col>
                    <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12"><el-form-item label=" B." class="mb5" ><el-input v-model="multipleSelection.selectionB" clearable ></el-input></el-form-item></el-col>
                    <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12"><el-form-item label=" C." class="mb5" ><el-input v-model="multipleSelection.selectionC" clearable ></el-input></el-form-item></el-col>
                    <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12"><el-form-item label=" D." class="mb5" ><el-input v-model="multipleSelection.selectionD" clearable ></el-input></el-form-item></el-col>
                    <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12"><el-form-item label=" E." class="mb5" ><el-input v-model="multipleSelection.selectionE" clearable ></el-input></el-form-item></el-col>
                    <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12"><el-form-item label="分值" class="mb5 scoreWidth" ><el-input v-model="multipleSelection.score" clearable ></el-input></el-form-item></el-col>
                    <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12"> 
						<el-form-item  label="标准答案" class="mb5" >
							<el-select v-model="multipleSelection.answer" >
								<el-option v-for="item in multipleSelections2" :key="item.value" :label="item.label" :value="item.value" />
							</el-select>
						</el-form-item>
					</el-col>
				</el-col>
            </el-row>
            <el-row :gutter="24">
				<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb10">
                   <div :xs="5" :sm="3" :md="3" :lg="3" :xl="3" style="margin-bottom:10px;"><span class="tag-group__title" style="font-weight:600;">判断题  </span></div>
				   <div class="title" :sm="4" :md="4" :lg="4" :xl="4" >
						<span>总共</span>
						<el-select v-model="judgeTotal" size="mini" clearable class="title-button" >
							<el-option v-for="item in questionCount" :key="item" :label="item" :value="item" />
						</el-select>
						<span>题，每题</span>
						<el-select v-model="judgeScore"  size="mini" class="title-button" @change="trueOrFalseSelectChanged" clearable >
							<el-option v-for="item in singleScores" :key="item" :label="item" :value="item" />
						</el-select>
						<span>分</span>
					</div>
					<div :xs="24" :sm="4" :md="4" :lg="4" :xl="4" style="display:inline;margin-left:35px;">
				   <el-button class="button-bottom" plain icon="el-icon-plus" style="margin-left:15px;margin-top:10px;"  size="mini" @click="addTrueOrFalses" ></el-button>
                   <el-button class="button-bottom" type="primary" plain icon="el-icon-minus" size="mini" @click="deleteTrueOrFalses" ></el-button>
					</div>
                </el-col>
                 <el-col v-for="trueOrFalse in testPaper.trueOrFalses" :key="trueOrFalse.seq" :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb5">
                    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">  
						<el-form-item :label="trueOrFalse.seq+'.  题干'" class="mb5">
						<el-input v-model="trueOrFalse.question" clearable ></el-input>
						</el-form-item>
					</el-col>
                    <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
						<el-form-item label="分值" class="mb5 scoreWidth">
						   <el-input v-model="trueOrFalse.score" clearable ></el-input>
						</el-form-item>
					</el-col>
                    <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
						<el-form-item label="标准答案" class="mb5"  > 
							<el-select v-model="trueOrFalse.answer" >
								<el-option v-for="item in trueOrFalseSelections" :key="item.value" :label="item.label" :value="item.value" />
							</el-select>
						</el-form-item>
					</el-col>
				</el-col>
            </el-row>
		     <el-row :gutter="24">
				<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb10">
                   <div :xs="5" :sm="3" :md="3" :lg="3" :xl="3" style="margin-bottom:10px;"><span class="tag-group__title" style="font-weight:600;">问答题  </span></div>
				   <div class="title" :sm="4" :md="4" :lg="4" :xl="4" >
						<span>总共</span>
						<el-select v-model="essayQuestionTotal" size="mini" clearable class="title-button" >
							<el-option v-for="item in questionCount" :key="item" :label="item" :value="item" />
						</el-select>
						<span>题，每题</span>
						<el-select v-model="essayQuestionScore"  size="mini" class="title-button" @change="essayQuestionSelectChanged" clearable >
							<el-option v-for="item in singleScores" :key="item" :label="item" :value="item" />
						</el-select>
						<span>分</span>
					</div>
					<div :xs="24" :sm="4" :md="4" :lg="4" :xl="4" style="display:inline;margin-left:35px;">
				   <el-button class="button-bottom" plain icon="el-icon-plus" style="margin-left:15px;margin-top:10px;"  size="mini" @click="addEssayQuestion" ></el-button>
                   <el-button class="button-bottom" type="primary" plain icon="el-icon-minus" size="mini" @click="deleteEssayQuestion" ></el-button>
					</div>
                </el-col>
                 <el-col v-for="essayQuestion in testPaper.essayQuestions" :key="essayQuestion.seq" :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb5">
                    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">  
						<el-form-item :label="essayQuestion.seq+'.  题干'" class="mb5">
							<el-input v-model="essayQuestion.question" clearable ></el-input>
						</el-form-item></el-col>
                    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
						<el-form-item label="分值" class="mb5 scoreWidth"> 
							<el-input v-model="essayQuestion.score" clearable ></el-input>
						</el-form-item>
					</el-col>
                    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
						<el-form-item label="标准答案" class="mb5"  > 
							<el-input v-model="essayQuestion.answer" clearable maxlength="1000" rows="5"  type="textarea" ></el-input>
						</el-form-item>
					</el-col>
				</el-col>
            </el-row>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">
					<el-form-item label="">
						<el-button v-if="isNew" plain type="success" :loading="addLoading" @click="add" :disabled="disableNewButton">创建</el-button>
						<el-button v-else plain type="success" @click="update" :loading="updateLoading">更新</el-button>
						<el-button plain type="info" @click="close">关闭</el-button>
					</el-form-item>
			</el-col>
    </el-form>
</template>

<script>
import { addTestPaper, updateTestPaper,findTestPaper } from "@/api/training/trainingTestPaper"
import { addTestedPaperResults  } from "@/api/training/trainingTestResults"
import { getTreeselectCompany } from "@/api/org/company";
export default {
	name: 'TestPaper',

	data() {
		
		return {
			testPaper: {
				paperName:'',
				totalScore:100,
				passScore:60,
				goodScore:70,
				excellentScore:90,
				singleSelections: [],
				multipleSelections: [],
				trueOrFalses: [],
				essayQuestions:[]
			},
			singleSelectionCount: 0,
			singleTotal:1,
			singleScore:2,
			multipleSelectionCount: 0,
			multipleTotal:1,
			multipleScore:2,
			trueOrFalseCount: 0,
			judgeTotal:1,
			judgeScore:2,
			essayQuestionTotal:1,
			essayQuestionScore:10,
			essayQuestionCount:0,

			testPaperId:0,
			addLoading: false,
			updateLoading: false,
			submitLoading:false,
			disableNewButton:false,

			companyList: [],
			total: 0,
			loading: false,
			companySelect: '',
			companyId:'',
			companyCode:'',
			// 公司树选项
			companyOptions: [],
			defaultProps: {
				children: 'children',
				label: 'companyName',
			},
			questionCount:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20],
			singleScores:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,30,40,50,60,70,80,90,100],
			singleSelections2:[{ value: 'A', label: 'A' },{ value: 'B', label: 'B' },{ value: 'C', label: 'C' },{ value: 'D', label: 'D' },{ value: 'E', label: 'E' }],
			multipleSelections2:[{ value: 'AB', label: 'AB' },{ value: 'ABC', label: 'ABC' },{ value: 'ABCD', label: 'ABCD' },{ value: 'ABCDE', label: 'ABCDE' },
								{ value: 'BC', label: 'BC' },{ value: 'BCD', label: 'BCD' },{ value: 'BCDE', label: 'BCDE' },{ value: 'CD', label: 'CD' },
								{ value: 'CDE', label: 'CDE' },{ value: 'DE', label: 'DE' }],
			trueOrFalseSelections:[{ value: '对', label: '对' },{ value: '错', label: '错' }],
			rules: {
				paperName: [{ required: true, message: '必需填写。', trigger: 'blur' }],
				companySelect:[{ required: true, message: '必需选择。', trigger: 'blur' }],
				totalScore: [{ required: false,type: "number", min:0,max:100, trigger: 'blur' }],
				passScore: [{ required: false, type: "number", min:0,max:100,  trigger: 'blur' }],
				goodScore: [{ required: false, type: "number", min:0,max:100,  trigger: 'blur' }],
				excellentScore: [{ required: false, type: "number", min:0,max:100,  trigger: 'blur' }],
			},

		};
	},
	props: {
		isNew: Boolean,
		//selectTestPaper: Object,
		selectTestPaperId: Number,
	},
	mounted() {
		if (this.isNew) {
			this.addSingleSelection();
			//this.addMultipleSelection();
			//this.addTrueOrFalses();
		}
	},
	created() {
		this.load();
	},
	methods: {

		load() {
			getTreeselectCompany(null, (list, options) => {
				this.companyList = list;
				if (this.companyList.length > 1) {
					this.isCompnayVisible = true;
				}
				this.companyOptions = options;
			});

			if(!this.isNew)
			{
				this.loading=true;
				findTestPaper(this.selectTestPaperId,-1,'').then((response) => {
					if(response.data){
						this.testPaper.paperName = response.data.testPaperName;
						this.testPaper.companyName = response.data.companyName;
						this.testPaper.passScore = response.data.passScore;
						this.testPaper.goodScore = response.data.goodScore;
						this.testPaper.excellentScore = response.data.excellentScore;
						this.testPaper.totalScore = response.data.totalScore;
						this.companySelect = response.data.companyName;
						this.companyId = response.data.companyId;
						this.companyCode=response.data.companyCode;
						this.testPaper.id = response.data.id;
						this.testPaperId = response.data.id;
						if(response.data.testQuestions && response.data.testQuestions.length > 0){
							this.testPaper.singleSelections=response.data.testQuestions.filter(item=>item.questionType=='S');
							this.testPaper.multipleSelections = response.data.testQuestions.filter(item=>item.questionType=='M');
							this.testPaper.trueOrFalses  = response.data.testQuestions.filter(item=>item.questionType=='J');
							this.testPaper.essayQuestions  = response.data.testQuestions.filter(item=>item.questionType=='E');
							if(this.testPaper.singleSelections&& this.testPaper.singleSelections.length > 0){
								this.singleSelectionCount= this.testPaper.singleSelections.length;
								this.singleTotal = this.testPaper.singleSelections.length;
								this.singleScore = this.testPaper.singleSelections[0];
							}
							if(this.testPaper.multipleSelections && this.testPaper.multipleSelections.length > 0){
								this.multipleSelectionCount=this.testPaper.multipleSelections.length;
								this.multipleTotal=this.testPaper.multipleSelections.length;
								this.multipleScore = this.testPaper.multipleSelections[0];
							}
							if(this.testPaper.trueOrFalses && this.testPaper.trueOrFalses.length > 0){
								this.trueOrFalseCount=this.testPaper.trueOrFalses.length;
								this.judgeTotal=this.testPaper.trueOrFalses.length;
								this.judgeScore=this.testPaper.trueOrFalses[0].score;
							}
							if(this.testPaper.essayQuestions && this.testPaper.essayQuestions.length > 0){
								this.essayQuestionCount=this.testPaper.essayQuestions.length;
								this.essayQuestionTotal=this.testPaper.essayQuestions.length;
								this.essayQuestionScore=this.testPaper.essayQuestions[0].score;
							}
						}
						
					}
					this.loading=false;
            }).catch((err) => {
                this.loading=false;
                this.msgError('查询试卷失败，' + err);
			});
			}
		},

        // 节点单击事件
		handleNodeClick(data) {
			this.companyId = data.id;
			this.companyCode = data.code;
			this.companySelect = data.companyName;
			let selectArray = [];
			selectArray.push(this.companyId);
			this.$refs.tree.setCheckedKeys(selectArray);

			this.$refs.companySelected.blur();
		},
		handleClearable() {
			this.$refs.tree.setCheckedKeys([]);
			this.companyId = null;
			this.companySelect = '';
			this.companyCode='';
		},
		singleSelectionChanged(value){
			if(this.testPaper.singleSelections&&this.testPaper.singleSelections.length>0){
				for (let index = 0; index < this.testPaper.singleSelections.length; index++) {
					const element = this.testPaper.singleSelections[index];
					element.score = value;
				}
			}
		},
		multipleSelectionChanged(value){
			if(this.testPaper.multipleSelections&&this.testPaper.multipleSelections.length>0){
				for (let index = 0; index < this.testPaper.multipleSelections.length; index++) {
					const element = this.testPaper.multipleSelections[index];
					element.score = value;
				}
			}
		},
		trueOrFalseSelectChanged(value){
			if(this.testPaper.trueOrFalses&&this.testPaper.trueOrFalses.length>0){
				for (let index = 0; index < this.testPaper.trueOrFalses.length; index++) {
					const element = this.testPaper.trueOrFalses[index];
					element.score = value;
				}
			}
		},
		essayQuestionSelectChanged(value){
			if(this.testPaper.essayQuestions&&this.testPaper.essayQuestions.length>0){
				for (let index = 0; index < this.testPaper.essayQuestions.length; index++) {
					const element = this.testPaper.essayQuestions[index];
					element.score = value;
				}
			}
		},

		addSingleSelection() {
			this.singleSelectionCount += 1;
			this.testPaper.singleSelections.push({
				seq: this.singleSelectionCount,
				testPaperId:this.testPaperId,
				questionType:'S',
				question: '',
				selectionA: '',
				selectionB: '',
				selectionC: '',
				selectionD: '',
				selectionE: '',
				answer: '',
				score: this.singleScore,
			});
		},
		deleteSingleSelection() {
			if(this.testPaper.singleSelections && this.testPaper.singleSelections.length >0){
				this.singleSelectionCount -= 1;
				this.testPaper.singleSelections.pop();
			}
		},

		addMultipleSelection() {
			this.multipleSelectionCount += 1;
			this.testPaper.multipleSelections.push({
				seq: this.multipleSelectionCount,
				testPaperId:this.testPaperId,
				questionType:'M',
				question: '',
				selectionA: '',
				selectionB: '',
				selectionC: '',
				selectionD: '',
				selectionE: '',
				answer: '',
				score: this.multipleScore,
			});
		},
		deleteMultipleSelection() {
			if(this.testPaper.multipleSelections && this.testPaper.multipleSelections.length > 0){
				this.multipleSelectionCount -= 1;
				this.testPaper.multipleSelections.pop();
			}
		},
		addTrueOrFalses() {
			this.trueOrFalseCount += 1;
			this.testPaper.trueOrFalses.push({ seq: this.trueOrFalseCount,testPaperId:this.testPaperId, questionType:'J',question: '', answer: '', score: this.judgeScore });
		},
		deleteTrueOrFalses() {
			if(this.testPaper.trueOrFalses && this.testPaper.trueOrFalses.length >0){
				this.trueOrFalseCount -= 1;
				this.testPaper.trueOrFalses.pop();
			}
		},
		addEssayQuestion() {
			this.essayQuestionCount += 1;
			this.testPaper.essayQuestions.push({ seq: this.essayQuestionCount,testPaperId:this.testPaperId, questionType:'E',question: '', answer: '', score: this.essayQuestionScore });
		},
		deleteEssayQuestion() {
			if(this.testPaper.essayQuestions && this.testPaper.essayQuestions.length >0){
				this.essayQuestionCount -= 1;
				this.testPaper.essayQuestions.pop();
			}
		},
		add() {
			
			if(!this.testPaper.paperName){
				this.msgError("试卷名不能为空。");
				return;
			}

			if(!this.companyId){
				this.msgError("公司部门没选，是必选项。");
				return;
			}

			let testPaper = {};
			testPaper.companyName=this.companySelect;
			testPaper.companyId=this.companyId;
			testPaper.companyCode=this.companyCode;
			testPaper.testPaperName=this.testPaper.paperName;
			testPaper.totalScore = this.testPaper.totalScore;
			testPaper.passScore=this.testPaper.passScore;
			testPaper.goodScore=this.testPaper.goodScore;
			testPaper.excellentScore=this.testPaper.excellentScore;
			let questions=this.testPaper.singleSelections.concat(this.testPaper.multipleSelections,this.testPaper.trueOrFalses,this.testPaper.essayQuestions);
			for (let index = 0; index < questions.length; index++) {
				let element = questions[index];
				element.result='';
			}
			testPaper.testQuestions=questions;
			let checkMsg = this.checksum(questions);
			if(checkMsg){
				this.msgError(checkMsg);
				return;
			}
			this.addLoading = true;
			addTestPaper(testPaper)
				.then((response) => {
					this.addLoading = false;
					this.disableNewButton = true;
					this.msgSuccess("增加新卷成功。");
					console.log('增加新卷成功。');
				})
				.catch((err) => {
					this.addLoading = false;
					this.msgError("增加新卷失败。"+ err);
					console.log('增加新卷失败。' + err);
				});
		},
		update() {

			let testPaper = {};
			testPaper.id= this.testPaper.id;
			testPaper.companyName=this.companySelect;
			testPaper.companyId=this.companyId;
			testPaper.companyCode=this.companyCode;
			testPaper.testPaperName=this.testPaper.paperName;
			testPaper.totalScore = this.testPaper.totalScore;
			testPaper.passScore=this.testPaper.passScore;
			testPaper.goodScore=this.testPaper.goodScore;
			testPaper.excellentScore=this.testPaper.excellentScore;
			testPaper.totalScore = this.testPaper.totalScore;
			let questions=this.testPaper.singleSelections.concat(this.testPaper.multipleSelections,this.testPaper.trueOrFalses,this.testPaper.essayQuestions);
			testPaper.testQuestions=questions;
			let checkMsg = this.checksum(questions);
			if(checkMsg){
				this.msgError(checkMsg);
				return;
			}
			this.updateLoading=true;
			updateTestPaper(testPaper)
				.then((response) => {
					this.updateLoading = false;
					this.msgSuccess("更新试卷成功。");
					console.log('更新试卷成功。');
				})
				.catch((err) => {
					this.updateLoading = false;
					this.msgError("更新试卷失败，"+ err);
					console.log('更新试卷失败，' + err);
				});
		},
		
		checksum(questions){
			let message="";
			if(questions && questions.length > 0){
				let computingTotalScore = 0;
				questions.forEach(element => {
					computingTotalScore += parseFloat(element.score)
				});
				
				if(computingTotalScore!=this.testPaper.totalScore)
				{
					message="试卷中所有题目分数之和为："+computingTotalScore+",而试卷要求的总分为:"+this.testPaper.totalScore+",两者不相等，请核实。";
				}
			}
			return message;
		},
		close() {
			this.$emit('close', { refresh: this.isNew, isSuccess: true });
		},
	},
};
</script>

<style lang="scss" scoped>
.button-bottom{
	vertical-align: bottom;
}
.title{
	display:inline-flex;margin-left:15px;
}
.title-button{
	width:80px;margin:0 5px;
}
.scoreWidth{
	width: 200px;
}
</style>