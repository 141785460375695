<template>
  <div>
    <template>
      <el-row :gutter="35">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb20">
          <el-form-item label="考勤班次" prop="scheduleIds" :rules="{required: true,message: '考勤班次不能为空', trigger: 'blur' }">
            <el-select v-model="scheduleIds" multiple @change="scheduleIdsChange" placeholder="请选择" style="width: 100%">
              <el-option v-for="item in attendanceClassList" :key="item.id" :label="getFixDefLabel(item)"
                         :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="35">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb20">
          <el-form-item label="排班周期" prop="schedule">
            <el-row :gutter="35">
              <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8" class="mb20">
                <el-form-item label="周期天数" :rules="{required: true,message: '周期天数不能为空', trigger: 'blur' }">
                  <el-input v-model="schedule.days"></el-input>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8" class="mb20">
                周期循环排班，最大周期天数31天
              </el-col>
            </el-row>
            <el-row :gutter="35">
              <template v-for="d in schedule.days?parseInt(schedule.days):3">
                <el-col :key="d" :xs="24" :sm="8" :md="8" :lg="8" :xl="8" class="mb20">
                  <el-form-item :label="'第'+d+'天'" :rules="{required: true,message: '第'+d+'天不能为空', trigger: 'blur' }">
                    <el-select v-model="schedule.attClass[d]" @change="onChangeUpdate" placeholder="请选择班次">
                      <el-option v-for="item in scheduleClassList" :key="item.id" :label="getFixDefLabel(item)"
                                 :value="item.id"></el-option>
                      <el-option label="休息" value=0></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </template>
            </el-row>
          </el-form-item>
        </el-col>
      </el-row>
    </template>
  </div>
</template>
<script>
import { getAttendanceGroup,updateAttendanceGroup } from "@/api/attendance/attendanceGroup";
import { listAttendanceClass } from "@/api/attendance/attendanceClass";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import permission from '@/directive/permission/index.js' // 权限判断指令
import { showLoading, hideLoading } from '@/utils/reqloading';
import {DICT_TYPE,getDictLabelFromData, getDictDataLabel, getDictDatas} from "@/utils/dict";
export default {
  name: "classCycleEdit",
}
</script>

<style scoped>

</style>