import request from '@/utils/request'


// 查询入职可选角色
export function listEntryRole() {
    return request({
        url: '/system/role/listEntryRole',
        method: 'get',
    })
}

// 查询角色管理列表
export function listRole(query) {
    return request({
        url: '/system/role/findAll',
        method: 'post',
        data: query
    })
}

// 查询角色管理详细
export function getRole(id) {
    return request({
        url: '/system/role/findOne?id=' + id,
        method: 'post'
    })
}

// 新增角色管理
export function addRole(data) {
    return request({
        url: '/system/role/add',
        method: 'post',
        data: data
    })
}

// 修改角色管理
export function updateRole(data) {
    return request({
        url: '/system/role/edit',
        method: 'post',
        data: data
    })
}

// 删除角色管理
export function delRole(id) {
    return request({
        url: '/system/role/remove',
        method: 'post',
        data: id
    })
}

// 导出角色管理
export function exportRole(query) {
    return request({
        url: '/system/role/export',
        method: 'post',
        data: query
    })
}
