// 定义内容
export default {
	card: {
		title1: '我的工作臺',
		title2: '消息通知',
		title3: '更多',
		title4: '行銷推薦',
		title5: '更多',
		title6: '庫存工作',
		title7: '履約情况',
		title8: '缺貨監控',
		title9: '履約超時預警',
	},
};
