<template>
	<el-collapse-item title="培训经历" :name="this.collapseStatus" :class="(collapseSetting)">
		<el-form ref="dataFrom" :model="dataFrom" :rules="trainingRules" class="employ" label-width="150px">
			<el-row v-for="(trainingModel, index) in dataFrom.trainingsModel" :key="index" :class="newAddedAfterLocked(trainingModel)">
				<el-row>
					<el-row>
						<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
							<el-form-item label="培训项目" :prop="'trainingsModel.'+index+'.trainingProject'" :rules="trainingRules.trainingProject" >
								<el-input v-model="trainingModel.trainingProject" :readonly="canEdit(trainingModel)"></el-input>
							</el-form-item>
						</el-col>
						<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
							<el-form-item label="培训名称" :prop="'trainingsModel.'+index+'.trainingName'" :rules="trainingRules.trainingName" >
								<el-input v-model="trainingModel.trainingName" :readonly="canEdit(trainingModel)"></el-input>
							</el-form-item>
						</el-col>
					
						<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
							<el-form-item label="培训内容">
								<el-input v-model="trainingModel.trainingContent" :readonly="canEdit(trainingModel)"></el-input>
							</el-form-item>
						</el-col>
						<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
							<el-form-item label="培训起始时间">
								<el-date-picker
									v-model="trainingModel.trainingStart"
									value-format="yyyy-MM-dd"
									:disabled="canEdit(trainingModel)"
									align="left"
									type="date"
									placeholder="选择日期"
							
								>
								</el-date-picker>
							</el-form-item>
						</el-col>
				
						<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
							<el-form-item
								label="培训结束时间"
								:prop="'trainingsModel.' + index + '.traingEnd'"
								:rules="[
									{
										validator: (rule, value, callback) => {
											endDateValidator(rule, value, callback, trainingModel.trainingStart);
										},
										trigger: 'change',
									}
								]"
							>
								<el-date-picker
									v-model="trainingModel.traingEnd"
									value-format="yyyy-MM-dd"
									:disabled="canEdit(trainingModel)"
									align="left"
									type="date"
									placeholder="选择日期"
							
								>
								</el-date-picker>
							</el-form-item>
						</el-col>
						<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
							<el-form-item label="培训课时">
								<el-input v-model="trainingModel.trainingPeriod" :readonly="canEdit(trainingModel)"></el-input>
							</el-form-item>
						</el-col>
				
						<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
							<el-form-item label="培训积分">
								<el-input v-model="trainingModel.trainingGrade" :readonly="canEdit(trainingModel)"></el-input>
							</el-form-item>
						</el-col>
						<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
							<el-form-item label="培训方式">
								<el-input v-model="trainingModel.trainingWay" :readonly="canEdit(trainingModel)"></el-input>
							</el-form-item>
						</el-col>
					
					
						<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
							<el-form-item label=" "> 
							<el-button
								plain
								class="employ-form-delete-button"
								:id="trainingModel.trainingProject"
								v-bind:disabled="canEdit(trainingModel)"
								v-on:click="deleteTrainingExperience($event, trainingModel.trainingProject)"
								type="danger"
								icon="el-icon-delete"
								>删除</el-button
							>
							</el-form-item>
						</el-col>
					</el-row>
					<div
						style="
							width: 80%;
							border-top: 1px solid;
							color: #ebeef5;
							height: 10px;
							margin-left: 10%;
							border-bottom-width: 1px;
							border-left-width: 1px;
							text-align: center;
						"
					></div>
				</el-row>
			</el-row>

			<el-row>
				<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
					<el-form-item label=" ">
						<el-button v-on:click="addTrainingExperience" type="primary" v-bind:disabled="enableAdd" plain icon="el-icon-plus">增加</el-button>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
					<el-form-item label=" ">
						<el-button v-on:click="edit" type="primary" plain v-bind:disabled="hasLocked?true:this.isReadOnly" icon="el-icon-edit">编辑</el-button>
						<el-button v-on:click="save" plain v-bind:disabled="this.isReadOnly" type="primary" :loading="saving" icon="el-icon">保存</el-button>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
	</el-collapse-item>
</template>

<script>
export default {
	data() {
		let readonlyInit = true;
		let trainingsInit = new Array();
		let initAddItem = false;
		if (this.trainingExperiences == null || (this.trainingExperiences!=null && this.trainingExperiences.length==0 )) {
			trainingsInit.push({
				trainingProject: '',
				trainingName: null,
				trainingContent: null,
				trainingStart: null,
				traingEnd: null,
				trainingPeriod: null,
				trainingGrade: null,
				trainingWay: null,
				newAdded:true
			});
			initAddItem=true;
		} else {
			trainingsInit = this.trainingExperiences;
		}

			//  isReadOnly=true for view, others for edit. Users can't eidt if locked, but they can add 
		if(this.isReadOnly || this.locked)
		{
			readonlyInit = true;
		}
		else{
			readonlyInit = false;
		}

		return {
			dataFrom: { trainingsModel: trainingsInit },
			readonly: readonlyInit,
			enableAdd:this.enableAddButton,
			saving:false,
			hasLocked:this.locked,
			initItem:initAddItem,
			pickerOptions1: {
				disabledDate(time) {
					return time.getTime() > Date.now();
				},
			},
			trainingRules:{
				trainingProject:[{required: true, message: '请输入培训项目名称。', trigger: 'blur,change' }],
				trainingName:[{required: true, message: '请输入培训名称。', trigger: 'blur,change' }]
			}
		};
	},
	props: { trainingExperiences: Array, id: Number, isReadOnly: Boolean,collapseStatus:String ,locked:Boolean ,enableAddButton:Boolean },
	watch: {
		isReadOnly(newValue,oldV) {
			this.readonly = newValue;
			//this.hasLocked = newValue;
		},
		locked(newValue,oldV) {
			this.hasLocked = newValue;
		},
	},
	computed: {
        newAddedAfterLocked() {
            return function(currentItem){
                return {employ_item_update:this.locked&&currentItem.newAdded && !this.initItem &&!this.isReadOnly};         
            }           
        },
		canEdit(){
			 return function(currentItem){
				 let returnValue = true;
				 if(this.hasLocked){
					 returnValue =currentItem.newAdded&&!this.isReadOnly?false:true;
				 }
				 else{
					returnValue = this.readonly;
				 }
                return returnValue;      
            }   
		} ,
		collapseSetting(){
			return function(){
				if(this.dataFrom.trainingsModel !=null && this.dataFrom.trainingsModel.length >0)
				{
					if(this.locked)
					{
						let newAddResults = this.dataFrom.trainingsModel.filter(item=>item.newAdded==true);
						if(newAddResults.length > 0)
						{
							if(this.initItem)
							{
								return {callpse_header_unfill:true};
							}
							else{
								return {callpse_header_newAdd:true};
							}
						}
						else
						{
							return {callpse_header_fill:true};
						}
						
					}
					else
					{
						if(this.initItem)
						{
							return {callpse_header_unfill:true};
						}
						else{
							return {callpse_header_fill:true};
						}
					}
				}
				else
				{
					return {callpse_header_unfill:true};
				}
				
			}
		},
    },
	methods: {
		save() {
			this.$refs.dataFrom.validate(valid => {
				let self = this;
				if (valid) {
						// save data to DB
						let savingTrainings = {};
						savingTrainings.id = this.id;
						savingTrainings.trainExps = this.dataFrom.trainingsModel;
						this.saving = true;
						this.$emit('updatePartialEmployeeInfo', { data: savingTrainings }, (response) => {
							this.saving=false;
							if (response.success) {
								this.msgSuccess('修改成功');
								this.readonly = true;
							} else {
								this.msgError('修改失败,' + response.msg);
							}
						});
			} 
				else {
						this.msgError('有非法输入或必填项未填写，请检查!');
				}
			});
		},
		edit() {
			this.readonly = false;
		},
		addTrainingExperience() {
			this.readonly = false;
			this.dataFrom.trainingsModel.push({
				trainingProject: '',
				trainingName: null,
				trainingContent: null,
				trainingStart: null,
				traingEnd: null,
				trainingPeriod: null,
				trainingGrade: null,
				trainingWay: null,
				newAdded:true
			});
		},
		deleteTrainingExperience(event, trainingProject) {
			let self = this;
			if (event.currentTarget.id == trainingProject) {
				this.$confirm('确定要删除吗?', '确认', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'error',
					// type: 'warning'
				})
					.then(() => {
						if (self.dataFrom.trainingsModel.length > 0) {
							let foundIndex = self.dataFrom.trainingsModel.findIndex((x) => x.trainingProject === trainingProject);
							if (foundIndex > -1) {
								self.dataFrom.trainingsModel.splice(foundIndex, 1);
							}
						}
					})
					.catch(() => {
						this.msgError('删除发生异常!');
					});
			}
		},
		endDateValidator(rule, value, callback, startDate) {
			// 必需每个分支都要有返回，否则进不去保存的validate方法。
            if(startDate==null || startDate =="")
			{
				return callback(new Error("先选起始日期。"));
			}
			else
			{
				if(value==null || value == ""){
					return callback(new Error("有起始日期，终止日期不能为空。"));
				}
				else{
					if (value < startDate) {
						return callback(new Error("终止日期不能小于起始日期。"));
					}
					else
					{
						return callback();
					}
				}
				
			}
            
        }
	},
};
</script>

<style>
</style>