<template>
<div>
  <el-form ref="form" :model="form" :rules="rules" label-width="80px">
    <el-row :gutter="35">
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
        <el-form-item label="用户名" prop="username">
          <el-input :disabled="form.id? true: false" v-model="form.username" placeholder="请输入登录用户名"/>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
        <el-form-item label="密码" prop="password"
                      :rules="[{required: form.id? false: true ,message: '登录密码不能为空', trigger: 'blur' }]">
          <el-input type='password' auto-complete="new-password" v-model="form.password" placeholder="请输入登录密码"/>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
        <el-form-item label="姓名" prop="name">
          <el-input v-model="form.name" placeholder="请输入用户姓名"/>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
        <el-form-item label="身份证号" prop="idNum">
          <el-input v-model="form.idNum" placeholder="请输入身份证号"/>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
        <el-form-item label="手机号" prop="mobile">
          <el-input v-model="form.mobile" placeholder="请输入手机号"/>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
        <el-form-item label="所属公司" prop="companyId">
          <treeselect v-if="form.userEnabled == 0 || !form.id" v-model="form.companyId" :options="companyOptions"
                      :normalizer="normalizerCompany" placeholder="请选择公司" @select="selectCompany"/>
          <el-input v-if="form.userEnabled != 0 && form.id" disabled :value="companyFormat(form, null)">{{}}</el-input>
        </el-form-item>
      </el-col>
<!--      <div v-if="!form.id">-->
<!--      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">-->
<!--        <el-form-item label="所属部门" prop="departmentId">-->
<!--          <treeselect ref="selectDepTree" v-model="form.departmentId" :options="departmentOptions"-->
<!--                      :normalizer="normalizerDepartment" placeholder="请选择所属部门"-->
<!--                      @select="selectDepartment"/>-->
<!--        </el-form-item>-->
<!--      </el-col>-->
<!--      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">-->
<!--        <el-form-item label="岗位" prop="positionId">-->
<!--          <treeselect ref="selectPosTree" v-model="form.positionId" :options="positionOptions"-->
<!--                      :normalizer="normalizerPosition" placeholder="请选择所属岗位"-->
<!--                      @select="selectDepartment"/>-->
<!--        </el-form-item>-->
<!--      </el-col>-->
<!--      </div>-->
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
        <el-form-item label="角色" prop="roleIds">
          <el-select style="width: 100%" v-model="form.roleIds" multiple placeholder="请选择角色" @change="chooseDTChange">
            <el-option
                v-for="item in roleOptions"
                :key="item.id"
                :label="item.zhName"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col  :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
        <el-form-item label="授权公司" prop="broCompanyIds">
          <el-select ref="selectBroCompanyIds" style="width: 100%" v-model="form.broCompanyIds" multiple placeholder="请选择可看兄弟公司" @change="chooseDTChange">
            <el-option
                v-for="item in broCompanyList"
                :key="item.id"
                :label="item.companyName"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
        <el-form-item label="启用" prop="userEnabled">
          <el-select style="width: 100%" v-model.number="form.userEnabled" >
            <el-option :key="0" label="注册账号" :value="0"/>
            <el-option :key="1" label="正常用户" :value="1"/>
            <el-option :key="2" label="离职员工" :value="2"/>
            <el-option :key="10" label="弃用账号" :value="10"/>
          </el-select>
          <!--              <el-switch-->
          <!--                  v-model.number="form.userEnabled"-->
          <!--                  active-color="#13ce66"-->
          <!--                  inactive-color="#ff4949"-->
          <!--                  :active-value=1-->
          <!--                  :inactive-value=0>-->
          <!--              </el-switch>-->
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
        <el-form-item label="描述" prop="introduction">
          <el-input v-model="form.introduction" placeholder="请输入描述"/>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
        <el-form-item label="图标" prop="avatar">
          <el-input v-model="form.avatar" placeholder="请输入图标"/>
        </el-form-item>
      </el-col>

    </el-row>






  </el-form>
  <div slot="footer" class="dialog-footer" style="text-align: center">
    <el-button type="primary" @click="submitForm" v-permission="['/user/add', '/user/edit']">确 定</el-button>
    <el-button @click="cancel">取 消</el-button>
  </div>

</div>
</template>

<script>
import {
  listUser,
  getUser,
  delUser,
  addUser,
  updateUser,
  exportUser } from "@/api/sys/user";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import permission from '@/directive/permission/index.js' // 权限判断指令
import {getTreeselectCompany, findBroCompany} from "@/api/org/company";
import {getTreeselectDepartment} from "@/api/org/department";
import {getTreeselectPosition} from "@/api/org/position";
import {validatorCompanyId, verifyIdCard, isPassword,validatePhone, verifyAccount, verifyFullName} from "@/utils/validate.js"
import { listRole } from "@/api/sys/role";
import { showLoading, hideLoading } from '@/utils/reqloading';
export default {
  name: "userEdit",
  components: {
    Treeselect,
  },
  props: {
    row: Object,
    companyOptionsIn: Array,
    companyListIn: Array,
    departmentOptionsIn: Array,
    isReadOnly: Boolean,
    companyId: Number,
    whereFrom: {
      type: String,
      default: 'self',
    }
  },
  watch:{
    row(n,o){ //n为新值,o为旧值;
      //if(n === o) return;
      this.load();
    },
    // companyId(){
    //   this.load();
    // }
  },
  directives: { permission },

  data() {
    return {
      // 遮罩层
      loading: true,

      // 公司树选项
      companyOptions: [],
      companyList: [],
      broCompanyList: [],
      // 部门管理树选项
      departmentOptions: [],
      // 岗位管理表格数据
      positionList: [],
      positionOptions: [],
      // 角色选项
      roleOptions: [],

      // 表单参数
      form: {
      }
      ,
      // 表单校验
      rules: {
        companyId: [
          {
            required: true,
            message: "所属公司不能为空,必须选择公司", trigger: "blur" },
          {validator: validatorCompanyId, trigger: "blur"}
        ],
        departmentId: [
          {
            required: true,
            message: "所属部门不能为空,必须选择部门", trigger: "blur" },
        ],
        positionId: [
          {
            required: true,
            message: "所属岗位不能为空,必须选择岗位", trigger: "blur" },
        ],
        username: [
          {
            required: true,
            message: "登录用户名不能为空", trigger: "blur" },
        ],
        name: [
          {
            required: true,
            message: "用户姓名不能为空", trigger: "blur" }
        ],
        idNum: [
          {
            required: true,
            message: "身份证号不能为空", trigger: "blur" },
          {validator: verifyIdCard,trigger: "blur" }
        ],
        mobile: [
          {
            required: true,
            message: "手机号不能为空", trigger: "blur" },
          {validator: validatePhone,trigger: "blur" }
        ],
        userEnabled: [
          {
            required: true,
            message: "是否启用不能为空", trigger: "blur" }
        ],
      }
    };
  },
  mounted() {
    this.load();
    this.getRoles();
  },
  methods: {
    load() {
      this.reset();

      if(this.companyOptionsIn){
        this.companyOptions = this.companyOptionsIn;
        this.companyList = this.companyListIn;
      }else{
        getTreeselectCompany(null, (list, options) =>{
          this.companyList = list;
          this.companyOptions = options;
        });
      }
      if(this.row && this.row.id){
        this.handleUpdate(this.row);
      }else{
        this.handleAdd();
      }

    },
    validatePwd(rule, value, callback) {
      // isPassword(rule,value,callback);
    },
    chooseDTChange(){
      this.$forceUpdate();
    },

    /** 转换公司数据结构 */
    normalizerCompany(node) {
      return this.normalizer(node,'id','companyName');
    },

    companyFormat(row, column) {
      // console.log(row.departmentId);
      for(var i = 0; i < this.companyList.length; i++){
        const item =  this.companyList[i];
        // console.log("id:"+item.id+",depid:"+row.departmentId+", name :"+item.name+",ee="+(item.id == row.departmentId));
        if(item.id == row.companyId){
          return item.companyName;
        }
      }
      return "";
    },

    /** 转换部门数据结构 */
    normalizerDepartment(node) {
      return this.normalizer(node);
    },
    normalizerPosition(node) {
      return this.normalizer(node);
    },

    /** 查询公司下拉树结构 */
    getDepartment(companyId) {
      getTreeselectDepartment(companyId, false, (list, options) =>{
        this.departmentList = list;
        this.departmentOptions = options;
      });
    },
    selectCompany(node, instanceId) {
      console.log(node);
      if(node.id){
        this.form.broCompanyIds = null;
        // this.$refs["selectBroCompanyIds"].clear();
        findBroCompany(node.id).then(response => {
          this.broCompanyList = response.data;
        });
      }

      // console.log(node);
      // console.log(this.$refs["selectDepTree"]);
      // this.$refs["selectDepTree"].clear();
      // this.$refs["selectPosTree"].clear();
      // this.getDepartment(node.id);
      // this.getPositionList(node.id);
    },
    selectDepartment(node, instanceId) {
      // this.getPositionList(node.id);
    },
    getPositionList(companyId) {
      getTreeselectPosition(companyId, false, (list, options) =>{
        this.positionList = list;
        this.positionOptions = options;
      });
    },

    getRoles(){
      const params = {
        pageNum: 1,
        pageSize: 1000,
        where: null,
        order: null,
      }
      return listRole(params).then(response => {
        this.roleOptions = response.data.records;
        return response;
      });

    },

    // 取消按钮
    cancel() {
      this.$emit("close", {refresh:false,isSuccess:true});
    },
    // 表单重置
    reset() {
      this.form = {};
      this.resetForm("form");
    },

    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.form.companyId  = this.companyId;
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.id || this.ids
      getUser(id).then(response => {
        this.form = response.data;
        findBroCompany(this.form.companyId).then(response => {
          this.broCompanyList = response.data;
        });
        if(this.form.broCompanyIds){
          let broCompanyIds = this.form.broCompanyIds.split(",")
          this.form.broCompanyIds = [];
          broCompanyIds.forEach(i=>{
            this.form.broCompanyIds.push(parseInt(i));
            // this.form.broCompanyIds.push(""+i);
          })
        }
        this.getDepartment(this.form.companyId);
        this.getPositionList(this.form.companyId);

        this.$nextTick(() => {
          // roles.then( ()=> {
          // console.log("roles return!")
          // console.log(res);
          let roleIds = []
          if(this.form.roles){
            this.form.roles.forEach(item=>{
              // this.$nextTick(()=> {
              // console.log("set roleid="+item.id)
              roleIds.push(item.id);
              // });
            });
          }
          this.form.roleIds = roleIds;
          this.open = true;
          // });
        });
        this.title = "修改用户";
      });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate(valid => {
            if(valid) {
              console.log(this.form);
              showLoading();
              if(this.form.broCompanyIds){
                this.form.broCompanyIds = this.form.broCompanyIds.join(",");
              }
              if (this.form.id != null) {
                updateUser(this.form).then(response => {
                  this.msgSuccess("修改成功");
                  hideLoading();
                  this.$emit("close", {refresh:true,isSuccess:true});
                  this.reset();
                });
              } else {
                addUser(this.form).then(response => {
                  this.msgSuccess("新增成功");
                  hideLoading();
                  this.$emit("close", {refresh:true,isSuccess:true});
                  this.reset();
                });
              }
            }
          }
      )
      ;
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      let ids = [];
      if(row.id){
        ids = [row.id];
      }else{
        ids = this.ids;
      }
      this.$confirm('是否确认删除用户数据?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return delUser(ids);
      }).then(() => {
        this.getList();
        this.msgSuccess("删除成功");
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      // const queryParams = this.queryParams;
      const params = this.getQuery();
      this.$confirm('是否确认导出所有用户管理数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return exportUser(params);
      }).then(response => {
        this.download(response.msg);
      })
    },
    handlesSetEmploy(row){
      let users = [];
      if(row.id){
        users = [row];
      }else{
        users = this.selectedRow;
      }
      if(users.length < 1){
        return;
      }


      const companyId = users[0].companyId;
      for(var i = 0; i < users.length; i++){
        const user = users[i];
        if(user.companyId !== companyId){
          this.$message.error('必须选择同一公司下的用户');
          return;
        }
      }
      console.log(companyId);
      console.log(users);
      // console.log(this.selectedRow);
      if(this.whereFrom === "self"){
        this.$router.push({name:"employ_employ_add_vue", params: { users: users , companyId: companyId}});
      }else if(this.whereFrom === "employ") {
        this.$emit("select", {users: users, companyId: companyId});
      }
    }
  }
};
</script>

<style scoped>

</style>
