export function parseFilters(filter) {
    let filters = []
    for (let field in filter) {
        let { type, value } = filter[field]
        switch (type) {
            case "eq":
                if ( (typeof value === 'string' && value.length) || typeof value === 'number' ) {
                    filters.push({name: field, type: type, values: [value]})
                }
                break;
            case "ne":
                if ( (typeof value === 'string' && value.length) || typeof value === 'number' ) {
                    filters.push({name: field, type: type, values: [value]})
                }
                break;
            case "llike":
                if ( (typeof value === 'string' && value.length) || typeof value === 'number' ) {
                    filters.push({name: field, type: type, values: [value]})
                }
                break;
            case "rlike":
                if ( (typeof value === 'string' && value.length) || typeof value === 'number' ) {
                    filters.push({name: field, type: type, values: [value]})
                }
                break;
            case "lt":
                if ( (typeof value === 'string' && value.length) || typeof value === 'number' ) {
                    filters.push({name: field, type: type, values: [value]})
                }
                break;
            case "le":
                if ( (typeof value === 'string' && value.length) || typeof value === 'number' ) {
                    filters.push({name: field, type: type, values: [value]})
                }
                break;
            case "gt":
                if ( (typeof value === 'string' && value.length) || typeof value === 'number' ) {
                    filters.push({name: field, type: type, values: [value]})
                }
                break;
            case "ge":
                if ( (typeof value === 'string' && value.length) || typeof value === 'number' ) {
                    filters.push({name: field, type: type, values: [value]})
                }
                break;
            case "search":
                if (typeof value === 'string' && value.length) {
                    filters.push({name: field, type: type, values: [value]})
                }
                break;
            case "select":
                if (value.length) {
                    let selectValue = []
                    for (let s of value) {
                        selectValue.push(s)
                    }
                    filters.push({name: field, type: type, values: selectValue})
                }
                break;
            case "in":
                if (value.length) {
                    let selectValue = []
                    for (let s of value) {
                        selectValue.push(s)
                    }
                    filters.push({name: field, type: type, values: selectValue})
                }
                break;
            case "notIn":
                if (value.length) {
                    let selectValue = []
                    for (let s of value) {
                        selectValue.push(s)
                    }
                    filters.push({name: field, type: type, values: selectValue})
                }
                break;
            case "range":
                if (value.length) {
                    let gt = value[0]
                    let lt = value[1]
                    filters.push({name: field, type: type, values: [gt, lt]})
                }
                break;
            case "date":
                if (value.length) {
                    let [s, e] = value
                    filters.push({name: field, type: type, values: [s, e]})
                }
                break;
            case "timestamp":
                if (value.length) {
                    // let [startDate, endDate] = value
                    // filters.push({name: field, type: type, values: [startDate, endDate]})
                    let [s, e] = value
                    let startDate = new Date(s)
                    let endDate = new Date(e)
                    filters.push({name: field, type: type, values: [startDate.valueOf(), endDate.valueOf()]})
                }
                break;
            default:
                filters.push({name: field, type: type, values: [value]})
                break;
        }
    }
    return filters
}

//
export function getWhereFilter(filter){
    const filters = parseFilters(filter);
    let where = {};
    if (filters && filters.length > 0) {
        where.filters = filters
    } else {
        // 如果过滤条件为空，设置空数组
        where.filters = []
    }
    return where;
}

//根据filter 等参数 生成查询参数
export function getQueryParam(filter, pageNum, pageSize, order){
    filter = filter || {};
    pageNum = pageNum || 1;
    pageSize = pageSize || 10;
    order = order || '';
    const where = getWhereFilter(filter);
    const params = {
        pageNum: pageNum,
        pageSize: pageSize,
        where: where,
        order: order
    }
    return params;
}

// 日期格式化
export function parseTime(time, pattern) {
    if (arguments.length === 0 || !time) {
        return null
    }
    const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
    let date
    if (typeof time === 'object') {
        date = time
    } else {
        if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
            time = parseInt(time)
        } else if (typeof time === 'string') {
            time = time.replace(new RegExp(/-/gm), '/');
        }
        if ((typeof time === 'number') && (time.toString().length === 10)) {
            time = time * 1000
        }
        date = new Date(time)
    }
    const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay()
    }
    const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
        let value = formatObj[key]
        // Note: getDay() returns 0 on Sunday
        if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
        if (result.length > 0 && value < 10) {
            value = '0' + value
        }
        return value || 0
    })
    return time_str
}

// 表单重置
export function resetForm(refName) {
    if (this.$refs[refName]) {
        this.$refs[refName].resetFields();
    }
}

// 添加日期范围
export function addDateRange(params, dateRange, propName) {
    var search = params;
    search.params = {};
    if (null != dateRange && '' != dateRange) {
        if (typeof(propName) === "undefined") {
            search.params["beginTime"] = dateRange[0];
            search.params["endTime"] = dateRange[1];
        } else {
            search.params["begin" + propName] = dateRange[0];
            search.params["end" + propName] = dateRange[1];
        }
    }
    return search;
}

// 回显数据字典
export function selectDictLabel(datas, value) {
    var actions = [];
    Object.keys(datas).some((key) => {
        if (datas[key].dictValue == ('' + value)) {
            actions.push(datas[key].dictLabel);
            return true;
        }
    })
    return actions.join('');
}

// 回显数据字典（字符串数组）
export function selectDictLabels(datas, value, separator) {
    var actions = [];
    var currentSeparator = undefined === separator ? "," : separator;
    var temp = value.split(currentSeparator);
    Object.keys(value.split(currentSeparator)).some((val) => {
        Object.keys(datas).some((key) => {
            if (datas[key].dictValue == ('' + temp[val])) {
                actions.push(datas[key].dictLabel + currentSeparator);
            }
        })
    })
    return actions.join('').substring(0, actions.join('').length - 1);
}

// 通用下载方法
export function download(fileName) {
    window.location.href = baseURL + "/common/download?fileName=" + encodeURI(fileName) + "&delete=" + true;
}

// 字符串格式化(%s )
export function sprintf(str) {
    var args = arguments, flag = true, i = 1;
    str = str.replace(/%s/g, function () {
        var arg = args[i++];
        if (typeof arg === 'undefined') {
            flag = false;
            return '';
        }
        return arg;
    });
    return flag ? str : '';
}

// 转换字符串，undefined,null等转化为""
export function praseStrEmpty(str) {
    if (!str || str == "undefined" || str == "null") {
        return "";
    }
    return str;
}

/**
 * 构造树型结构数据
 * @param {*} data 数据源
 * @param {*} id id字段 默认 'id'
 * @param {*} parentId 父节点字段 默认 'parentId'
 * @param {*} children 孩子节点字段 默认 'children'
 * @param {*} rootId 根Id 默认 0
 */
export function handleTree(data, id, parentId, children, rootId) {
    id = id || 'id'
    parentId = parentId || 'parentId'
    children = children || 'children'
    rootId = rootId || Math.min.apply(Math, data.map(item => { return item[parentId] })) || 0
    //对源数据深度克隆
    const cloneData = JSON.parse(JSON.stringify(data))
    //循环所有项
    const treeData = cloneData.filter(father => {
        let branchArr = cloneData.filter(child => {
            //返回每一项的子级数组
            return father[id] === child[parentId]
        });
        branchArr.length > 0 ? father.children = branchArr : '';
        // branchArr.length > 0 ? father.hasChildren = true : false;
        //返回第一层
        return father[parentId] === rootId;
    });
    return treeData != '' ? treeData : data;
}

/**
 * 根据tree 计算每一层某字段，所有子孙节点数据和该节点数据和
 * @param tree
 * @param id
 * @param parentId
 * @param children
 * @param sumField
 */
export function sumTreeChild(tree, id, parentId, children, sumField){
    id = id || 'id'
    parentId = parentId || 'parentId'
    children = children || 'children'
    sumField = sumField || 'realCount'
    // 树每一层，ids 二维列表
    let levelIdsList = [];
    let idMap = {};
    // push 顶层
    console.log(tree[id]);
    if(tree.className == 'department'){
        levelIdsList.push[tree[0][id]];
        idMap[tree[id]] = tree[0];
    }
    let childs = tree[0][children];
    tree[0].changed = false;
    console.log(childs.length);
    // 查找每层子节点
    while(childs && childs.length>0){
        let idsList = []
        let newChilds = [];
        childs.forEach(c =>{
            c.changed = false;
            if(c.className == 'department'){
                idsList.push(c[id]);
                idMap[c[id]] = c;
            }
            if(c[children] && c[children].length>0){
                newChilds = newChilds.concat(c[children]);
            }
        })
        childs = newChilds;
        levelIdsList.push(idsList);
    }
    console.log(levelIdsList);
    // 从最后一层计算和
    for(let i = levelIdsList.length-1; i >=0; i--){
        const idsList = levelIdsList[i];
        idsList.forEach( childId =>{
           const childItem = idMap[childId];
           if(childItem[sumField]&&childItem[parentId]>0){
               let sum = idMap[childItem[parentId]][sumField]? idMap[childItem[parentId]][sumField]:0;
               sum += childItem[sumField];
               idMap[childItem[parentId]][sumField] = sum;
           }
        });
    }
}

/** 转换数据结构 */
export function normalizer(node, id, name, children) {
    id = id || 'id';
    name = name || 'name';
    children = children || 'children'
    if (node[children] && !node[children].length) {
        delete node[children];
    }
    return {
        id: node[id],
        label: node[name],
        children: node[children]
    };
}

/**
 * 树型结构数据获取id 对应 pid
 * @param {*} treeData 数据源
 * @param {*} id id字段 默认 'id'
 * @param {*} parentId 父节点字段 默认 'parentId'
 * @param {*} children 孩子节点字段 默认 'children'
 * @param {*} rootId 根Id 默认 0
 */
export function getChangedIdMapPid(treeData, id, parentId, children, rootId) {
    id = id || 'id'
    parentId = parentId || 'parentId'
    children = children || 'children'
    rootId = rootId || 0
    let idMapPid = [];
    if(treeData[children] && treeData[children].length > 0){
        treeData[children].forEach(item=>{
            // 如果原来的pid 和现在的pid不同，则改变了
           if(item[parentId] != rootId){
               let data = {};
               data[id] = item.id;
               data[parentId] = rootId;
               idMapPid.push(data); // 设置新的pid
           }
           // 嵌套查找下面的节点
           getChangedIdMapPid(item,id,parentId,children,item.id).map(i=>idMapPid.push(i));
        });
    }
    return idMapPid;
}

export function strToIntArray(dataStr,splitChar){
    if(!dataStr || dataStr === '') return [];
    splitChar = splitChar || ','
    let dataStrArr=dataStr.split(splitChar);  //分割成字符串数组
    let dataIntArr=[];//保存转换后的整型字符串
    //方法一
    dataStrArr.forEach(item => {
        dataIntArr.push(+item);
    });
    // console.log(dataIntArr);
    // //方法二
    // dataIntArr=dataStrArr.map(item => {
    //     return +item;
    // });
    // console.log(dataIntArr);
    return dataIntArr;
}

export function getEmpNamesStrFromListByIds(employList, ids){
    // employList.
    console.log("employ ids:");
    console.log(ids);
    console.log("employList:"+employList.length);
    console.log(employList);
    if(!employList || employList.length <=0 ) return "";
    let userNames = "";
    let isFirst = true;
    if(ids && ids.length >0){
        for(let userId in ids){
            for(let e in employList){
                if(employList[e].userId == ids[userId]){
                    if(isFirst) {
                        userNames = employList[e].name;
                        isFirst = false;
                    } else {
                        userNames = userNames + "," +employList[e].name;
                    }
                    break;
                }
            }
        }
    }
    return userNames;
}

export function getRandomColor(i) {
    i = parseInt(i) || 1;
    if(i == -1) return '#808080';//'#000000';
    // return "#"+("00000"+((Math.random(i)*16777215+0.5)>>0).toString(16)).slice(-6);
    switch (i % 10) {
        case 1:
            return '#FFC0CB';
        case 2:
            return '#4169E1';
        case 3:
            return '#87CEEB';
        case 4:
            return '#008B8B';
        case 5:
            return '#228B22';
        case 6:
            return '#BDB76B';
        case 7:
            return '#DAA520';
        case 8:
            return '#E9967A';
        case 9:
            return '#CD5C5C';
        case 0:
            return '#9932CC';
    }
}

export function sortBy(property, asc) {
    //默认升序,不能写在闭包里面，写在闭包里面是无效的……asc没反应就
    if (asc==undefined) {
        asc = -1
    } else {
        asc=asc ? -1 : 1
    }
    return function (value1, value2) {
        let a = value1[property]
        let b = value2[property]
        return a < b ? asc : a > b ? asc*-1 : 0
    }
}
