<template>
  <div class="app-container">
    <employ-edit :row="row" opType="self" :company-id="$store.getters.userInfo.companyId" :isReadOnly="formReadonly" :canAdd="false" @close="close" @update="update" @updatePartialEmployeeInfo='updatePartialEmployeeInfo' ></employ-edit>
  </div>
</template>

<script>
import employEdit from '../employ/employEdit'
import {
  getSelf,
  updateSelf,
} from "@/api/employ/self";
import {updateEmploy} from "@/api/employ/employ";
import permission from '@/directive/permission/index.js' // 权限判断指令

export default {
  name: "index",
  components: {
    employEdit,
  },
  data() {
    return {
      row: null,
      employeeId:null,
      formReadonly:false
    }
  },
  created() {
    this._filter = {};
    this.getSelfData();
  },
  methods: {
    getSelfData(){
      getSelf().then(response => {
        this.row = response.data;
        if (response.data != null && response.data != undefined) {
          this.employeeId = response.data.id;
        }
        else{
           this.formReadonly = true;
           this.msgError("此用户还没入职，不能自助填写信息！");
          //this.formReadonly = false;
        }
        console.log(this.row);
      });
    },
    // 提交确认后关闭对话框
    close(arg){

    },

    update(data){
      console.log(data.data);
      updateEmploy(data.data).then(response => {
        this.msgSuccess("修改成功");
        // this.open = false;
        // this.getList();
      });
    },

    updatePartialEmployeeInfo(data,callback){
      console.log("update");
      console.log(data.data);
      updateEmploy(data.data).then(response => {
        callback(response);
      }).catch(
        (err)=>{
          let response = {};
          response.msg = err;
          callback(response);
        }
      );
    },
  },
}
</script>

<style scoped>

</style>
