<template>
    <div>
        <el-form :model="queryParameters" ref="queryParameters" class="employ" label-width="80px">
			<el-row :gutter="24">
				<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
					<el-form-item label="公司">
						<el-select v-model="companySelect" clearable @clear="handleClearable" placeholder="请选择" collapse-tags ref="companySelected">
							<el-option :value="queryParameters.companyId" :label="companySelect" style="height: auto">
								<el-tree
									:data="companyOptions"
									:props="defaultProps"
									:expand-on-click-node="false"
									ref="tree"
									show-checkbox
									node-key="id"
									default-expand-all
									@node-click="handleNodeClick"
									@check="handleNodeClick"
									highlight-current
								/>
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
                <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
					<el-form-item label="姓名">
						<el-input v-model="queryParameters.userName" placeholder="输入员工姓名">
						</el-input>
					</el-form-item>
				</el-col>
                <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
					<el-form-item label="试卷名称">
						<el-input v-model="queryParameters.testPaperName" placeholder="输入试卷名称">
						</el-input>
					</el-form-item>
				</el-col>
				 <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
					<el-form-item label="课程名称">
						<el-input v-model="queryParameters.courseName" placeholder="输入课程名称">
						</el-input>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
					<el-form-item label="起始时间">
						<el-date-picker v-model="queryParameters.startDate" value-format="yyyy-MM-dd" align="left" type="date" placeholder="选择日期">
						</el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
					<el-form-item label="终止时间">
						<el-date-picker v-model="queryParameters.endDate" value-format="yyyy-MM-dd" align="left" type="date" placeholder="选择日期">
						</el-date-picker>
					</el-form-item>
				</el-col>
                <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
							<el-form-item label=" ">
								<el-button v-on:click="query" size="mini" icon="el-icon-search" plain type="primary">查询</el-button>
								<el-button v-on:click="exportExcel" :loading="downloadLoading" size="mini" icon="el-icon-download" plain type="primary">导出</el-button>
							</el-form-item>
						</el-col>
			</el-row>
		</el-form>
        <el-table v-loading="loading" :data="trainingList" style="width: 100%" max-height="100%" stripe border>
            <el-table-column label="编号ID" align="center" prop="id" v-if="false" />
            <el-table-column label="公司" align="center" prop="companyName" sortable min-width="100" :show-overflow-tooltip="true"> </el-table-column>
            <el-table-column label="员工姓名" align="center" prop="tester" sortable min-width="150" :show-overflow-tooltip="true" />
			<el-table-column label="课程/培训名称" align="center" prop="courseName" sortable min-width="150" :show-overflow-tooltip="true" />
            <el-table-column label="试卷名称" align="center" prop="testPaperName" sortable min-width="100" :show-overflow-tooltip="true"> </el-table-column>
            <el-table-column label="考试时间" align="center" prop="createTime" min-width="150" sortable :show-overflow-tooltip="true" >
                <template slot-scope="scope">
							<span>{{ formatDate(scope.row.createTime) }}</span>
						</template>
            </el-table-column>
            <el-table-column label="考试成绩" align="center" prop="score" min-width="150" sortable :show-overflow-tooltip="true" />
            <el-table-column label="考试等级" align="center" prop="level" min-width="150" sortable :show-overflow-tooltip="true" />
            <el-table-column label="操作" align="center" min-width="150" fixed="right">
					<template slot-scope="scope">
						<el-button size="mini" class="btn baseManageBtn" title="查阅" type="text" icon="el-icon-reading"
							@click="readPaper(scope.row)" ></el-button>
					</template>
				</el-table-column>
		</el-table>
        <pagination
            v-show="total > 0"
            :total="total"
            :page.sync="queryParameters.pageNum"
            :limit.sync="queryParameters.pageSize"
            @pagination="query"
        />
		<el-dialog title="" :visible.sync='showTestingPaper' :width="uniApp?'80%':'70%'" :append-to-body='true' :close-on-click-modal="false" >
        	<testing-paper v-if="showTestingPaper" :selectTestPaperId="selectTestPaperId" :testCount="selectTestCount" courseName="" :courseId="selectCourseId" :testerId="selectTesterId" @close="closeTestingPaper" ></testing-paper>
    	</el-dialog>
    </div>
</template>

<script>
import { getTreeselectCompany } from '@/api/org/company';
import Pagination from '@/components/Pagination';
import { findAllTestPaperResults,exportTrainingTestPaperScores} from '@/api/training/trainingTestResults';
import testingPaper from "./testingPaper.vue"
import { Local } from '@/utils/storage.js'
export default {
    name: 'Ehrwebvue2TestQuery',
    components: {
		Pagination,
		testingPaper
	},
    data() {
        return {
            trainingList:[],
            companyList: [],
			total: 0,
			loading: false,
			companySelect: '',
			// 公司树选项
			companyOptions: [],
			defaultProps: {
				children: 'children',
				label: 'companyName',
			},
			queryParameters: {
				startDate: '',
				endDate: '',
				userName: '',
                testPaperName:'',
				courseName:'',
				companyId: '',
				pageSize: 10,
				pageNum: 1,
			},

			where: {
				query: '',
				filters: [],
			},
			companyId: '',
            downloadLoading:false,
			showTestingPaper:false,
			selectTestPaperId:-1,
			selectTestCount:-1,
			selectCourseId:-1,
			selectTesterId:'',
			uniApp: false,
        };
    },

    mounted() {
        
    },
    created() {
		this.uniApp = Local.get("isUniapp") ? true : false;
		this.load();
		//this.query();
	},
    methods: {
        load() {
			getTreeselectCompany(null, (list, options) => {
				this.companyList = list;
				if (this.companyList.length > 1) {
					this.isCompnayVisible = true;
				}
				this.companyOptions = options;
			});
		},
		// 节点单击事件
		handleNodeClick(data) {
			this.queryParameters.companyId = data.id;
			this.companyId = data.id;
			this.companySelect = data.companyName;
			let selectArray = [];
			selectArray.push(this.companyId);
			this.$refs.tree.setCheckedKeys(selectArray);

			this.$refs.companySelected.blur();
		},
		handleClearable() {
			this.$refs.tree.setCheckedKeys([]);
			this.companyId = null;
			this.companySelect = '';
		},
        generateQueryParams() {
			let filter = {};
			if (this.companyId) {
				filter['companyId'] = {
					type: 'eq',
					value: this.companyId,
				};
			}
			if (this.queryParameters.id) {
				filter['trainingId'] = {
					type: 'eq',
					value: this.queryParameters.id,
				};
			}
			if (this.queryParameters.tester) {
                filter['tester'] = {
                    type: 'search',
                    value: this.queryParameters.tester,
                };
			}
            if (this.queryParameters.testPaperName) {
                filter['testPaperName'] = {
                    type: 'search',
                    value: this.queryParameters.testPaperName,
                };
			}
			if (this.queryParameters.courseName) {
                filter['courseName'] = {
                    type: 'search',
                    value: this.queryParameters.courseName,
                };
			}
			
            filter['seq'] = {
						type: 'eq',
						value: 1000,
					};

			if (this.queryParameters.startDate && this.queryParameters.endDate) {
				let tempDate = this.queryParameters.startDate.replace(/-/g, '/'); //必须把日期'-'转为'/'
				var startDateTimeStamp = new Date(tempDate).getTime();
				tempDate = this.queryParameters.endDate.replace(/-/g, '/');
				var endDateTimeStamp = new Date(tempDate).getTime();
				filter['createTime'] = {
					type: 'timestamp',
					value: [startDateTimeStamp, endDateTimeStamp],
				};
			}

			const filters = this.parseFilters(filter);
			if (filters && filters.length > 0) {
				this.where.filters = filters;
			} else {
				// 如果过滤条件为空，设置空数组
				this.where.filters = [];
			}
			const params = {
				pageNum: this.queryParameters.pageNum,
				pageSize: this.queryParameters.pageSize,
				where: this.where,
				order: '',
			};
			return params;
		},
		query() {
			let params = this.generateQueryParams();
			this.loading = true;
			findAllTestPaperResults(params)
				.then((response) => {
					this.trainingList = response.data.records;
					this.total = response.data.total;
					this.loading = false;
				})
				.catch((err) => {
					this.msgError('查询失败，' + err);
				});
		},
        exportExcel(){
            const params = this.generateQueryParams();
			let that = this;
			this.$confirm('是否确认导出考试成绩?', '信息', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(function () {
					that.downloadStatisticLoading = true;
					return exportTrainingTestPaperScores(params);
				})
				.then((result) => {
					that.downloadStatisticLoading=false;
					if (result) {
						let blob = new Blob([result.data], {
							type: result.data.type,
						});
						let filename = (result.headers.filename && decodeURI(result.headers.filename)) || '培训考试成绩表.xlsx';
						if ('download' in document.createElement('a')) {
							const link = document.createElement('a');
							link.style.display = 'none';
							link.href = URL.createObjectURL(blob);
							link.setAttribute('download', filename);
							document.body.appendChild(link);
							link.click();
							document.body.removeChild(link);
						} else {
							navigator.msSaveBlob(blob, filename);
						}
					}
				})
				.catch((e) => {
					that.downloadStatisticLoading=false;
					that.msgError(`后端下载文件出错 & ${e}`);
				});
        },
        readPaper(row){
			this.showTestingPaper=true;
			this.selectTestPaperId = row.testPaperId;
			this.selectTestCount =row.testCount;
			this.selectCourseId = row.courseId;
			this.selectTesterId = row.userId;
        },
        formatDate(dateStr) {
			if (dateStr != '' && dateStr.length > 0) {
					dateStr = dateStr.replace('T', ' ');
                    return dateStr;
			} else {
				return dateStr;
			}
		},
		closeTestingPaper(params){
			this.showTestingPaper=false;
		},
    },
};
</script>

<style lang="scss" scoped>

</style>