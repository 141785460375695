<template>
  <div>
    <depart-edit type="apply" :isShowClose="true"></depart-edit>
  </div>
</template>

<script>
import DepartEdit from "./departEdit";
export default {
  name: "apply",
  components:{
    DepartEdit,
  }
}
</script>

<style scoped>

</style>
