import request from '@/utils/request'

// 查询巡逻人员列表
export function listAttPatrolEmp(query) {
    return request({
        url: '/attendance/attPatrolEmp/findAll',
        method: 'post',
        data: query
    })
}

// 查询巡逻人员详细
export function getAttPatrolEmp(id) {
    return request({
        url: '/attendance/attPatrolEmp/findOne?id=' + id,
        method: 'post'
    })
}

// 新增巡逻人员
export function addAttPatrolEmp(data) {
    return request({
        url: '/attendance/attPatrolEmp/add',
        method: 'post',
        data: data
    })
}

// 修改巡逻人员
export function updateAttPatrolEmp(data) {
    return request({
        url: '/attendance/attPatrolEmp/edit',
        method: 'post',
        data: data
    })
}

export function approvePhoto(data) {
    return request({
        url: '/attendance/attPatrolEmp/approvePhoto',
        method: 'post',
        data: data
    })
}
// 删除巡逻人员
export function delAttPatrolEmp(id) {
    return request({
        url: '/attendance/attPatrolEmp/remove',
        method: 'post',
        data: id
    })
}

// 导出巡逻人员
export function exportAttPatrolEmp(query) {
    return request({
        url: '/attendance/attPatrolEmp/export',
        method: 'post',
        data: query
    })
}
