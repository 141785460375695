<template>
  <div class="app-container" style="padding-left: 0px; padding-right: 0px;">
    <el-row :gutter="20">
      <el-col :span="6" :xs="24" >
        <div class="head-container">
          <!--公司选择-->
          <!--          <el-divider>公司</el-divider>-->
<!--          <template v-if="isCompnayVisible">-->
<!--            <div style="color: #f52828; font-size: large; background-color: cadetblue">请先选择公司</div>-->
<!--            <treeselect v-model="companyId" :options="companyOptions"-->
<!--                        :normalizer="normalizerCompany" @select="selectCompany" placeholder="请选择公司"/>-->
<!--&lt;!&ndash;            <el-divider></el-divider>&ndash;&gt;-->
<!--          </template>-->
<!--          <span>请选择部门</span>-->
          <el-input
              v-model="departmentName"
              placeholder="请输入查找字符"
              clearable
              size="small"
              prefix-icon="el-icon-search"
              style="margin-bottom: 20px"
          />
        </div>
        <div class="head-container">
          <el-tree
              class="flow-tree"
              :data="treeData"
              :props="defaultProps"
              :expand-on-click-node="false"
              :filter-node-method="filterNode"
              ref="tree"
              :default-expand-all="!uniApp"
              @node-click="handleNodeClick"
              highlight-current
          >
                    <span class="custom-tree-node" slot-scope="{ node, data }">
                      <span>{{ node.label }}</span>
                      <el-tooltip class="item" effect="dark" :content="'编制：'+data.planCount+'人,在编：'+showRealCount(data)+'人,缺编：'+(data.planCount-(data.realCount?data.realCount:0))+'人'" placement="top-end">
                        <template v-if="data.planCount">
                          <span v-if="(data.planCount-(data.realCount?data.realCount:0))>0" style="color: #c75151">{{showRealCount(data)+"+"+(data.planCount-(data.realCount?data.realCount:0))}}</span>
                          <span v-else style="color: green">{{showRealCount(data)+"+"+(data.planCount-(data.realCount?data.realCount:0))}}</span>
                        </template>
                      </el-tooltip>
                      <el-tooltip class="item" effect="dark" :content="'编制：-人,在编：'+showRealCount(data)+'人,缺编：-人'" placement="top-end">
                        <span v-if="!data.planCount">{{showRealCount(data)}}</span>
                      </el-tooltip>
                    </span>
          </el-tree>
        </div>
      </el-col>

      <el-col :span="18" :xs="24">
        <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px">
          <el-form-item label="">
            <el-checkbox v-model="queryParams.isShowDeleted" size="small" @change="handleQuery">只看删除人员</el-checkbox>
          </el-form-item>
          <el-form-item v-if="!queryParams.isShowDeleted" label="">
            <el-checkbox v-model="queryParams.isShowDepart" size="small" @change="handleQuery">显示离职人员</el-checkbox>
          </el-form-item>
          <el-form-item label="姓名" prop="name">
            <el-input
                v-model="queryParams.name"
                placeholder="姓名"
                clearable
                size="small"
                @keyup.enter.native="handleQuery"
            />
          </el-form-item>
          <el-form-item label="员工状态" prop="empStatus">
            <el-select v-model="queryParams.empStatus" placeholder="请选择员工状态" clearable>
              <el-option  v-for="dict in employeeStatuses" :key="dict.value" :label="dict.label" :value="dict.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="工作证号" prop="worknum">
            <el-input
                v-model="queryParams.workNum"
                placeholder="请输入工作证号"
                clearable
                size="small"
                @keyup.enter.native="handleQuery"
            />
          </el-form-item>
          <el-form-item label="身份证号" prop="idNum">
            <el-input
                v-model="queryParams.idNum"
                placeholder="请输入身份证号码"
                clearable
                size="small"
                @keyup.enter.native="handleQuery"
            />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
            <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
            <el-button v-if="opType!='hrEntry' && officalCount >= 0"
                type="primary"
                plain
                icon="el-icon-s-release"
                size="mini"
                :disabled="officalCount <= 0"
                @click="listOffical"
                v-permission="['/employ/employ/add','/employ/employ/edit','/employ/employ/remove']"
            >{{officalCount}}人待转正
            </el-button>
          </el-form-item>
        </el-form>

        <el-row :gutter="10" class="mb5">
          <el-col v-if="opType=='hrEntry'" :span="1.5">
            <el-button
                type="primary"
                plain
                icon="el-icon-plus"
                size="mini"
                @click="handleAdd"
                v-permission="['/employ/employ/add']"
                class="mt5"
            >直接入职
            </el-button>
          </el-col>
          <template v-if="!queryParams.isShowDeleted">
          <el-col :span="1.5">
            <el-button
                type="success"
                plain
                icon="el-icon-edit"
                size="mini"
                :disabled="single"
                @click="handleUpdate"
                v-permission="['/employ/employ/edit']"
                class="mt5"
            >修改
            </el-button>
          </el-col>
          <el-col :span="1.5">
            <el-button
                type="danger"
                plain
                icon="el-icon-delete"
                size="mini"
                :disabled="multiple"
                @click="handleDelete"
                v-permission="['/employ/employ/remove']"
                class="mt5"
            >删除
            </el-button>
          </el-col>
          <el-col :span="1.5">
            <el-button
                type="success"
                plain
                icon="el-icon-coin"
                size="mini"
                :disabled="single"
                @click="viewTransferHis"
                v-permission="['/employ/employ/edit']"
                class="mt5"
            >调动历史
            </el-button>
          </el-col>
          <el-col v-if="opType!='hrEntry'" :span="1.5">
            <el-button
                type="primary"
                plain
                icon="el-icon-tickets"
                size="mini"
                :disabled="single"
                @click="handleEmployResume"
                v-permission="['/employ/employ/edit']"
                class="mt5"
            >打印员工简历
            </el-button>
          </el-col>
          <el-col v-if="opType!='hrEntry'" :span="1.5">
            <el-button
                type="danger"
                plain
                icon="el-icon-d-caret"
                size="mini"
                @click="handleMove"
                v-permission="['/employ/employ/add','/employ/employ/edit','/employ/employ/remove']"
                class="mt5"
            >迁移
            </el-button>
          </el-col>
          <el-col v-if="opType!='hrEntry'" :span="1.5">
            <el-button
                type="danger"
                plain
                icon="el-icon-s-release"
                size="mini"
                :disabled="multiple"
                @click="handleBlackList"
                v-permission="['/employ/employ/add','/employ/employ/edit','/employ/employ/remove']"
                class="mt5"
            >加入黑名单
            </el-button>
          </el-col>
          <el-col v-if="opType!='hrEntry' && officalCount >= 0" :span="1.5">
            <el-button
                type="primary"
                plain
                icon="el-icon-s-release"
                size="mini"
                :disabled="multiple"
                @click="doOffical"
                v-permission="['/employ/employ/add','/employ/employ/edit','/employ/employ/remove']"
                class="mt5"
            >转正
            </el-button>
          </el-col>
          </template>
          <template v-if="queryParams.isShowDeleted">
          <el-col v-if="opType!='hrEntry'" :span="1.5">
            <el-button type="primary" plain icon="el-icon-s-fold" size="mini" :disabled="multiple"
                @click="recovery"
                v-permission="['/employ/employ/recovery']"
                class="mt5"
            >恢复删除
            </el-button>
          </el-col>
          <el-col v-if="opType!='hrEntry'" :span="1.5">
            <el-button type="danger" plain icon="el-icon-delete-solid" size="mini" :disabled="multiple"
                @click="hardDelete"
                v-permission="['/employ/employ/hardDelete']"
                class="mt5"
            >彻底删除
            </el-button>
          </el-col>
          </template>
<!--          <el-col :span="1.5">-->
<!--            <el-button-->
<!--                type="warning"-->
<!--                plain-->
<!--                icon="el-icon-download"-->
<!--                size="mini"-->
<!--                @click="handleExport"-->
<!--                v-permission="['/employ/employ/export']"-->
<!--            >导出-->
<!--            </el-button>-->
<!--          </el-col>-->
          <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>
        </el-row>

        <el-table v-loading="loading" :data="employList" @selection-change="handleSelectionChange" style="width:100%" stripe border>
          <el-table-column type="selection" width="40" align="center"/>
          <!-- <el-table-column label="id" align="center" prop="id" width="60"  sortable >
          </el-table-column> -->
          <el-table-column label="姓名" align="center" prop="name" sortable width="auto" min-width="80"  :show-overflow-tooltip='true'>
               <template slot-scope="scope" >
              <el-button size="mini" type="text"
                  @click="handleView(scope.row)">
                  {{ scope.row.name }}
              </el-button>
          </template>
          </el-table-column>
          <el-table-column label="用户名" align="center" prop="user.username" sortable width="auto" min-width="100"  :show-overflow-tooltip='true'/>
          <el-table-column label="工作证号" align="center" prop="workNum" sortable width="auto" min-width="150"  :show-overflow-tooltip='true'/>
          <el-table-column label="公司" align="center" prop="companyId" sortable width="auto" min-width="150" :show-overflow-tooltip='true'>
            <template slot-scope="scope">
              {{companyFormat(scope.row, null)}}
            </template>
          </el-table-column>
          <el-table-column label="部门" align="center" prop="departmentId" sortable width="auto" min-width="150" :show-overflow-tooltip='true'>
            <template slot-scope="scope">
                {{departmentFormat(scope.row, null)}}
            </template>
          </el-table-column>
          <el-table-column label="岗位" align="center" prop="positionId" sortable width="auto" min-width="120" :show-overflow-tooltip='true'>
            <template slot-scope="scope">
                {{positionFormat(scope.row, null)}}
            </template>
          </el-table-column>
          <el-table-column label="员工状态" align="center" prop="empStatus" sortable width="auto" min-width="100"  :show-overflow-tooltip='true'/>
          <el-table-column label="员工类型" align="center" prop="empType" sortable width="auto" min-width="100"  :show-overflow-tooltip='true'/>
          <el-table-column label="用工形式" align="center" prop="empWorkType" sortable width="auto" min-width="100" :show-overflow-tooltip='true'/>

          <el-table-column label="证件类型" align="center" prop="idType" sortable width="auto" min-width="100" :show-overflow-tooltip='true'/>
          <el-table-column label="身份证号" align="center" prop="idNum"  sortable width="auto" min-width="180" :show-overflow-tooltip='true'/>
          <el-table-column label="性别" align="center" prop="sex" sortable width="auto" min-width="100" :show-overflow-tooltip='true' />
          <el-table-column label="民族" align="center" prop="nation" sortable width="auto" min-width="100" :show-overflow-tooltip='true' />
          <el-table-column label="曾用名" align="center" prop="formerName" sortable width="auto" min-width="100" :show-overflow-tooltip='true'/>
          <el-table-column label="出生年月" align="center" prop="idBridthDay" sortable width="auto" min-width="150" :show-overflow-tooltip='true'>
            <template slot-scope="scope">
              <span>{{ parseTime(scope.row.idBridthDay, '{y}-{m}-{d}') }}</span>
            </template>
          </el-table-column>
          <el-table-column label="籍贯" align="center" prop="nativePlace" sortable />
          <el-table-column label="婚姻状况" align="center" prop="marritalStatus" sortable width="auto" min-width="100"  :show-overflow-tooltip='true'/>
          <el-table-column label="健康状况" align="center" prop="helth" sortable width="auto" min-width="100"/>
          <el-table-column label="出生地" align="center" prop="birthAddr" sortable width="auto" min-width="100" :show-overflow-tooltip='true'/>
          <el-table-column label="首聘时间" align="center" prop="curPositionStartDate" sortable width="auto" min-width="150"  :show-overflow-tooltip='true'>
            <template slot-scope="scope">
              <span>{{ parseTime(scope.row.curPositionStartDate, '{y}-{m}-{d}') }}</span>
            </template>
          </el-table-column>
          <el-table-column label="参加工作时间" align="center" prop="workStartDate" sortable width="auto" min-width="150" :show-overflow-tooltip='true'>
            <template slot-scope="scope">
              <span>{{ parseTime(scope.row.workStartDate, '{y}-{m}-{d}') }}</span>
            </template>
          </el-table-column>
          <el-table-column label="入本单位时间" align="center" prop="joinCompanyDate" sortable width="auto" min-width="150" :show-overflow-tooltip='true'>
            <template slot-scope="scope">
              <span>{{ parseTime(scope.row.joinCompanyDate, '{y}-{m}-{d}') }}</span>
            </template>
          </el-table-column>
          <el-table-column label="休假工龄起始时间" align="center" prop="paidHolidayStartDate" sortable width="auto" min-width="150" :show-overflow-tooltip='true'>
            <template slot-scope="scope">
              <span>{{ parseTime(scope.row.paidHolidayStartDate, '{y}-{m}-{d}') }}</span>
            </template>
          </el-table-column>
          <el-table-column label="办公电话" align="center" prop="workPhone" sortable width="auto" min-width="100" :show-overflow-tooltip='true'/>
          <el-table-column label="住宅电话" align="center" prop="homePhone" sortable width="auto" min-width="100" :show-overflow-tooltip='true'/>
          <el-table-column label="紧急联系人" align="center" prop="urgentContact" sortable width="auto" min-width="200" :show-overflow-tooltip='true'>
            <template slot-scope="scope">
              <el-tooltip placement="top">
                <div slot="content">
                  <span v-if="scope.row.urgentContact">
                    电话号码：{{ scope.row.urgentContact.phone }}<br/>
                    手机号：{{ scope.row.urgentContact.mobile }}<br/>
                    地址：{{ scope.row.urgentContact.addr }}<br/>
                  </span>
                </div>
                  <span v-if="scope.row.urgentContact">{{ scope.row.urgentContact.name }}</span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="现住地址" align="center" prop="currentAddr" sortable width="auto" min-width="180" :show-overflow-tooltip='true'/>
          <el-table-column label="户口地" align="center" prop="householdAddr" sortable width="auto" min-width="150" :show-overflow-tooltip='true'/>
          <el-table-column label="集体户口" align="center" prop="householdType" sortable width="auto" min-width="100"/>
          <el-table-column label="操作" align="center" class-name="small-padding fixed-width" min-width="100" fixed="right">
            <template slot-scope="scope">
              <el-button v-if="!queryParams.isShowDeleted"
                  size="mini"
                  type="text"
                  icon="el-icon-edit"
                  @click="handleUpdate(scope.row)"
                  v-permission="['/employ/employ/edit']"
              >
              </el-button>
              <el-button v-if="!queryParams.isShowDeleted"
                  size="mini"
                  type="text"
                  icon="el-icon-delete"
                  @click="handleDelete(scope.row)"
                  v-permission="['/employ/employ/remove']"
              >
              </el-button>
              <el-button v-if="!queryParams.isShowDeleted"
                  size="mini"
                  type="text"
                  icon="el-icon-coin"
                  @click="viewTransferHis(scope.row)"
                  v-permission="['/bpm/transferhis/findAll']"
              >
              </el-button>
            </template>
          </el-table-column>
        </el-table>

        <pagination
            v-show="total>0"
            :total="total"
            :page.sync="queryParams.pageNum"
            :limit.sync="queryParams.pageSize"
            @pagination="getList"
        />
      </el-col>
    </el-row>
    <!-- 添加或修改岗位管理对话框 -->
    <el-dialog  :title="title" :visible.sync="open" width="85%" append-to-body>
<!--      <Edit @close="close"></Edit>-->
      <employ-edit :row="row" opType="hrEntry" :company-id="companyId" v-if="open" :isReadOnly="employReadOnly" :canAdd="employeeInfoAdd" @close="close" @update="update" @updatePartialEmployeeInfo='updatePartialEmployeeInfo'></employ-edit>
    </el-dialog>
    <el-dialog  title="员工迁移" :visible.sync="openMove" width="80%" append-to-body>
      <employ-adjust :changeId="employAdjustChangeId" @cancel="cancelAdjust" @submitAdjust="submitAdjust"></employ-adjust>
    </el-dialog>
    <el-dialog  title="调动轨迹" :visible.sync="openTransferTrace" width="60%" append-to-body>
      <TransferHisTrace :userId="transferUserId" @close="openTransferTrace = false"></TransferHisTrace>
    </el-dialog>
    <el-dialog title="打印员工简历" :visible.sync='showEmployResume' width="80%" append-to-body >
        <employ-resume @close="closeEmployResume" :passEmployee="row"  v-if="showEmployResume" ></employ-resume>
    </el-dialog>
  </div>
</template>

<script>
import { listEmploy,listEmployIncludeDelete, getEmploy, delEmploy, addBlackList,
  addEmploy, updateEmploy, exportEmploy, countOfficalEmploy, trunToOffical, recovery, hardDelete } from "@/api/employ/employ";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import RightToolbar from "@/components/RightToolbar"
import permission from '@/directive/permission/index.js' // 权限判断指令
import Pagination from "@/components/Pagination";
import {getTreeselectCompany} from "@/api/org/company";
import {getTreeselectDepartment} from "@/api/org/department";
import EmployEdit from "./employEdit"
import EmployAdjust from "./employAdjust";
import { getTreeselectPosition } from '@/api/org/position';
import TransferHisTrace from '@/views/pages/bpm/transferHis/transferHisTrace';
import EmployResume from './employResume.vue';
import {getOrgTree} from "../../org/components/orgtree";
import {DICT_TYPE,getDictLabelFromData, getDictDataLabel, getDictDatas} from "@/utils/dict";
import {sumTreeChild, getQueryParam} from "@/utils/tools";
import {formatDate} from "../../../../utils/formatTime";
import {checkRole} from "@/utils/role";

// 表查询配置
const dbOrderBy = ''

export default {
  name: "Employ",
  components: {
    EmployAdjust,
    Treeselect,
    RightToolbar,
    Pagination,
    EmployEdit,
    TransferHisTrace,
    EmployResume,
  },
  props: {
    //hrEntry 人事入职
    opType: {
      type: String,
      default: '',
    },
  },
  directives: {permission},
  data() {
    return {
      treeData: [],
      openTransferTrace: false,
      transferUserId: null,
      isCompnayVisible: false,
      row: null,
      // opType: 'hr',
      // 是否显示弹出层
      open: false,
      openMove: false,
      // 弹出层标题
      title: "",
      // 遮罩层
      loading: true,
      // 查询参数
      where: {
        query: '',
        filters: []
      },

      companyId: 1,
      companyCode: 1,
      companyList: [],
      // 部门管理树选项
      departmentList: [],
      departmentOptions: [],
      positionList: [],
      positionOptions: [],
      // 公司树选项
      companyOptions: [],
      //
      postLevelOptions: [],
      defaultProps: {
        children: "children",
        label: "name"
      },
      departmentName: undefined,

      // 可以编辑的
      employReadOnly:false,
      employeeInfoAdd:false,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 员工信息表格数据
      employList: [],
      // 今天到期待转正员工数
      officalCount: 0,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        orderby: dbOrderBy,
        name: null,
        empStatus: null,
        workNum: null,
        idNum: null,
        companyId: null,
        companyCode: null,
        isShowDepart: false,
        isShowDeleted: false,
      },
      employAdjustChangeId: 1,
      employeeStatuses: DICT_TYPE.empOnWorkStatuses,//DICT_TYPE.employeeStatuses,
      showEmployResume:false,
      uniApp:false,
    };
  },
  watch: {
    // 根据名称筛选部门树
    departmentName(val) {
      this.$refs.tree.filter(val);
    },
    // companyId(val){
    //   this.getDepartment(val);
    //   this.getPositison(val);
    // },
  },

  created() {
    // if(this.$store.getters.userInfo.constParentCompanyId == this.$store.getters.userInfo.companyId){
    //   this.isCompnayVisible = true;
    // }
    console.log("this.$store.getters.userInfo="+this.$store.getters.userInfo);
    console.log(this.$store.getters.userInfo);
    this._filter = {};
    this.companyId = this.$store.getters.userInfo.companyId;
    this.companyCode = this.$store.getters.userInfo.companyCode;
    console.log("this compnayCode="+this.companyCode);
    this.queryParams.companyId = this.companyId;
    this.queryParams.companyCode = this.companyCode;
    getOrgTree((tree, companyList, departmentList, positionList )=>{
      console.log("return tree:"+tree);
      console.log(tree);
      this.companyList = companyList;
      // if(this.$store.getters.userInfo.companyId == PARENT_COMPANY_ID){
      sumTreeChild(tree,'id','pid');
      this.treeData = tree;

      this.allDepartmentOptions = [];
      const dataDepartment = { id:0,
        name:'顶级部门',
        children: []
      };
      dataDepartment.children = this.handleTree(departmentList, "id", "pid");
      this.allDepartmentOptions.push(dataDepartment);

      this.companyOptions = [];
      this.companyOptions = this.handleTree(companyList, "id", "pid");

      this.positionList = positionList;
      this.departmentList = departmentList;
      this.getList();
    });
    this.queryOfficalEmployCount(this.companyCode);

    this.uniApp=Local.get("isUniapp")?true:false;
   
  },
  
  methods: {
    checkRole(role){
      return checkRole(role);
    },
    showRealCount(data){
      if(data.realCount){
        if(data.realCount == data.selfRealCount){
          return data.realCount;
        }else if(data.selfRealCount){
          return data.realCount + "(" + data.selfRealCount +")";
        }else{
          return data.realCount;
        }
      }else{
        return "0";
      }
    },
    /** 查询员工信息列表 */
    getQuery() {
      this._filter = {};
      if(this.queryParams.departmentId) {
        this._filter['departmentId'] = {
          type: 'eq',
          value: this.queryParams.departmentId,
        }
      }
      if(this.queryParams.name) {
        this._filter['name'] = {
          type: 'search',
          value: this.queryParams.name,
        }
      }
      if (this.queryParams.workNum) {
        this._filter['workNum'] = {
          type: 'search',
          value: this.queryParams.workNum,
        }
      }
      if (this.queryParams.idNum) {
        this._filter['idNum'] = {
          type: 'search',
          value: this.queryParams.idNum,
        }
      }
      if (this.queryParams.companyCode) {
        this._filter['companyCode'] = {
          type: 'rlike',
          value: this.queryParams.companyCode,
        }
      }
      if (this.queryParams.empStatus) {
        this._filter['empStatus'] = {
          type: 'eq',
          value: this.queryParams.empStatus,
        }
      }
      const filters = this.parseFilters(this._filter)
      if(this.queryParams.isShowDeleted){
        this.employeeStatuses = DICT_TYPE.employeeStatuses;
        filters.push({name: 'deleted', type: 'eq', values: [1]});
      }else{
        if(!this.queryParams.isShowDepart){
          filters.push({name: 'empStatus', type: 'notIn', values: ['离职','离退休','离岗','黑名单']})
          this.employeeStatuses = DICT_TYPE.empOnWorkStatuses;
        }else{
          this.employeeStatuses = DICT_TYPE.employeeStatuses;
        }
      }

      if (filters && filters.length > 0) {
        this.where.filters = filters
      } else {
        // 如果过滤条件为空，设置空数组
        this.where.filters = []
      }
      const params = {
        pageNum: this.queryParams.pageNum,
        pageSize: this.queryParams.pageSize,
        where: this.where,
        order: this.orderby
      }

      return params;
    },
    getList() {
      this.loading = true;
      const params = this.getQuery();
      if(this.queryParams.isShowDeleted){
        console.log("only deleted")
        listEmployIncludeDelete(params).then(response => {
          this.employList = response.data.records;
          this.total = response.data.total;
          this.loading = false;
        });
      }else{
        listEmploy(params).then(response => {
          this.employList = response.data.records;
          this.total = response.data.total;
          this.loading = false;
        });
      }
    },
    // 查询公司待转正员工数
    queryOfficalEmployCount(companyCode){
      const date = new Date();
      countOfficalEmploy(companyCode, formatDate(date,'YYYY-mm-dd')).then(response => {
        this.officalCount = response.data;
      });
    },
    /** 转换公司数据结构 */
    normalizerCompany(node) {
      return this.normalizer(node,'id','companyName');
    },

    /** 查询公司下拉树结构 */
    getDepartment(companyId) {
      getTreeselectDepartment(companyId, false, (list, options) =>{
        this.departmentList = list;
        let companyName = "";
        this.companyList.forEach(item =>{
          if(item.id == companyId){
            companyName = item.companyName;
          }
        })
        const departTree = [{ id:0,
          name: companyName,
          children: []
        }];
        departTree[0].children = options;
        this.departmentOptions = departTree;
      });
    },
    normalizerPosition(node){
      return this.normalizer(node);
    },
    /** 查询岗位下拉树结构 */
    getPositison(companyId) {
      getTreeselectPosition(companyId, false, (list, options) =>{
        this.positionList = list;
        this.positionOptions = options;
      });
    },
    positionFormat(row, column) {
      console.log(row.positionId);
      for(var i = 0; i < this.positionList.length; i++){
        const item =  this.positionList[i];
        // console.log("id:"+item.id+",depid:"+row.departmentId+", name :"+item.name+",ee="+(item.id == row.departmentId));
        if(item.id == row.positionId){
          return item.name;
        }
      }
      return "";
    },

    // 筛选节点
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    selectCompany(val){
      console.log(val);
      console.log(val.id);
      this.queryParams.companyId = val.id;
    },
    // 节点单击事件
    handleNodeClick(data) {
      if(data.classType === 'company'){
        console.log("select company");
        console.log(data);
        if(data.id == 0){
          this.queryParams.companyCode = this.companyCode;
        }else{
          this.queryParams.companyCode = data.code;
          if(data.code.length < this.$store.getters.userInfo.companyCode.length){
            console.log("选了父公司,companyid 不赋值父公司");
          }else{
            this.companyId = data.id;
            // 查询公司待转正人员
            this.queryOfficalEmployCount(data.code);
          }
        }
        console.log("queryParams");
        console.log(this.queryParams);
        this.queryParams.departmentId = null;
      }else if(data.classType === 'department') {
        console.log("select department");
        console.log(data);
        this.queryParams.companyCode = data.companyCode;
        this.companyId = data.companyId;
        this.queryParams.departmentId = data.id;
      }
      this.getList();
    },
    companyFormat(row, column) {
      // console.log(row.departmentId);
      for(var i = 0; i < this.companyList.length; i++){
        const item =  this.companyList[i];
        // console.log("id:"+item.id+",depid:"+row.departmentId+", name :"+item.name+",ee="+(item.id == row.departmentId));
        if(item.id == row.companyId){
          return item.name;
        }
      }
      return "";
    },
    departmentFormat(row, column) {
      // console.log(row.departmentId);
      for(var i = 0; i < this.departmentList.length; i++){
        const item =  this.departmentList[i];
        // console.log("id:"+item.id+",depid:"+row.departmentId+", name :"+item.name+",ee="+(item.id == row.departmentId));
        if(item.id == row.departmentId){
          return item.name;
        }
      }
      return "";
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      // this.$router.replace("/");
      // this.$router.push({name:"employ_employ_add_vue", params: {companyId: this.companyId}});
      this.row = {companyId: this.companyId ,time: Date.parse(new Date())};
      this.open = true;
      this.title = "新增员工";
    },
    handleEmployResume(row){
        const id = row.id || this.ids
        getEmploy(id).then(response => {
            this.row = response.data;
            this.showEmployResume=true;
        });
    },
     /** 修改按钮操作 */
    handleView(row) {
      this.employReadOnly = true;
      this.employeeInfoAdd = true; // diable button
      const id = row.id || this.ids
      getEmploy(id).then(response => {
        // this.$router.push({name:"employ_employ_edit_vue", params: {row:response.data, companyId: this.companyId}});
        this.row = response.data;
        this.open = true;
        this.title = "查阅员工信息";
      });
    },

    /** 修改按钮操作 */
    handleUpdate(row) {
      this.employReadOnly = false;
      this.employeeInfoAdd = false;
      const id = row.id || this.ids
      getEmploy(id).then(response => {
        // this.$router.push({name:"employ_employ_edit_vue", params: {row:response.data, companyId: this.companyId}});
        this.row = response.data;
        console.log("handleUpdate re");
        console.log(this.row.companyId);
        this.companyId = this.row.companyId;
        this.open = true;
        this.title = "修改员工信息";
      });
    },

    handleMove(){
      this.employAdjustChangeId = this.employAdjustChangeId + 1;
      this.openMove = true;
    },

    viewTransferHis(row){
      console.log("viewTransferHis row.userId:");
      console.log(row.userId);
      this.transferUserId = row.userId;
      this.openTransferTrace = true;
      // getTransferhisByUserId(row.userId, (list)=>{
      //   console.log("viewTransferHis return:");
      //   console.log(list);
      // });
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      let ids = [];
      if (row.id) {
        ids = [row.id];
      } else {
        ids = this.ids;
      }

      this.$confirm('是否确认删除选中的员工信息数据?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return delEmploy(ids);
      }).then(() => {
        this.getList();
        this.msgSuccess("删除成功");
      })
    },
    listOffical(){
      this.loading = true;
      let filter = {};
      filter['companyCode'] = {
        type: 'rlike',
        value: this.queryParams.companyCode,
      }
      filter['empStatus'] = {
        type: 'eq',
        value: '试用',
      }
      filter['officalDate'] = {
        type: 'le',
        value: formatDate(new Date(),'YYYY-mm-dd'),
      }
      const params = getQueryParam(filter,this.queryParams.pageNum, this.queryParams.pageSize,'');
      listEmploy(params).then(response => {
        this.employList = response.data.records;
        this.total = response.data.total;
        this.loading = false;
      });
    },
    doOffical(row){
      let ids = [];
      if (row.id) {
        ids = [row.id];
      } else {
        ids = this.ids;
      }
      for(let i = 0; i < this.employList.length; i++){
        for(let j = 0; j < ids.length; j++){
          if(this.employList[i].id == ids[j] && this.employList[i].empStatus!='试用'){
            this.msgError("员工"+this.employList[i].name+"是"+this.employList[i].empStatus+"员工。 不是试用期，不能转正！")
            return;
          }
        }
      }
      this.$confirm('是否确认将选中的员工转正?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return trunToOffical(ids);
      }).then(() => {
        this.listOffical();
        this.queryOfficalEmployCount(this.queryParams.companyCode);
        this.msgSuccess("转正完成");
      })
    },
    recovery(row){
      let ids = [];
      if (row.id) {
        ids = [row.id];
      } else {
        ids = this.ids;
      }
      this.$confirm('是否确认将选中的员工恢复?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return recovery(ids);
      }).then(() => {
        this.getList();
        // this.queryOfficalEmployCount(this.queryParams.companyCode);
        this.msgSuccess("恢复完成");
      })
    },
    hardDelete(row){
      let ids = [];
      if (row.id) {
        ids = [row.id];
      } else {
        ids = this.ids;
      }
      this.$confirm('危险动作，是否确认将选中的员工彻底删除，彻底删除后将不可恢复，请谨慎操作！！！', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return hardDelete(ids);
      }).then(() => {
        this.getList();
        // this.queryOfficalEmployCount(this.queryParams.companyCode);
        this.msgSuccess("彻底删除完成");
      })
    },
    handleBlackList(row){
      let ids = [];
      if (row.id) {
        ids = [row.id];
      } else {
        ids = this.ids;
      }

      this.$confirm('是否确认将选中的员工加入黑名单?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return addBlackList(ids);
      }).then(() => {
        this.getList();
        this.msgSuccess("加入黑名单成功");
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      const params = this.getQuery();
      this.$confirm('是否确认导出所有员工信息数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return exportEmploy(params);
      }).then(response => {
        this.download(response.msg);
      })
    },

    // 提交确认后关闭对话框
    close(arg){
      this.open = false;
      if(arg.refresh){
        this.getList();
      }
    },

    update(data){

      console.log("update");
      console.log(data.data);
      updateEmploy(data.data).then(response => {
        this.msgSuccess("修改成功");
        this.open = false;
        this.getList();
      });
    },
    updatePartialEmployeeInfo(data,callback){

      console.log("update");
      console.log(data.data);
      updateEmploy(data.data).then(response => {
        callback(response);
      }).catch(
        (err)=>{
          let response = {};
          response.msg = err;
          callback(response);
        }
      );
    },
    submitAdjust(){
      this.getList();
      // 让调整对话框下次打开刷新
      // this.employAdjustChangeId = this.employAdjustChangeId + 1;
      this.openMove = false;
    },
    cancelAdjust(){
      // this.employAdjustChangeId = this.employAdjustChangeId + 1;
      this.openMove = false;
    },
    closeEmployResume(){
      this.showEmployResume=false;
    }
  }
};
</script>
