<template>
	<el-collapse-item title="工作经历" :name="this.collapseStatus" :class="collapseSetting()">
		<el-form ref="dataFrom" :model="dataFrom"  :rules="workExpRules" class="employ" label-width="150px">
		<el-row v-for="(workExperienceModel,index) in dataFrom.workExperiencesModel" :key="index" :class="newAddedAfterLocked(workExperienceModel)">
			<el-row>
				<el-row>
					<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
						<el-form-item label="开始时间" :prop="'workExperiencesModel.'+index+'.startDate'" :rules="workExpRules.startDate" >
							<el-date-picker  v-model="workExperienceModel.startDate" value-format="yyyy-MM-dd" :disabled="canEdit(workExperienceModel)" align="left" type="date" placeholder="选择日期" >
							</el-date-picker>
						</el-form-item>
					</el-col>
					<!-- @change="changeEndDate($event,workExperienceModel.startDate)" -->
					<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
						<el-form-item label="结束时间" :prop="'workExperiencesModel.'+index+'.endDate'" :rules="[{ validator: (rule, value, callback)=>{endDateValidator(rule, value, callback,workExperienceModel.startDate)}, trigger: 'change'},{required: true, message: '请输入终止日期', trigger: 'blur'}]" >
								<el-date-picker v-model="workExperienceModel.endDate" value-format="yyyy-MM-dd" 
								:disabled="canEdit(workExperienceModel)" align="left" type="date" placeholder="选择日期" 
								>
							</el-date-picker>
						</el-form-item>
					</el-col>

					<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
						<el-form-item label="工作单位" :prop="'workExperiencesModel.'+index+'.workUnit'" :rules="workExpRules.workUnit" >
							<el-input v-model="workExperienceModel.workUnit" :readonly="canEdit(workExperienceModel)"></el-input>
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
						<el-form-item label="部门" >
							<el-input v-model="workExperienceModel.department" :readonly="canEdit(workExperienceModel)"></el-input>
						</el-form-item>
					</el-col>

					<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
						<el-form-item label="岗位" >
							<el-input v-model="workExperienceModel.workPosition" :readonly="canEdit(workExperienceModel)"></el-input>
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
						<el-form-item label="证明人" >
							<el-input v-model="workExperienceModel.witness" :readonly="canEdit(workExperienceModel)"></el-input>
						</el-form-item>
					</el-col>

					<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
						<el-form-item label="备注" >
							<el-input v-model="workExperienceModel.remarks" :readonly="canEdit(workExperienceModel)"></el-input>
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
						<el-form-item label=" ">
							<el-button plain class="employ-form-delete-button" :id="workExperienceModel.workUnit" v-bind:disabled="canEdit(workExperienceModel)"   v-on:click="deleteWorkExperience($event,workExperienceModel.workUnit)" type="danger" icon="el-icon-delete">删除</el-button>						
						</el-form-item>
					</el-col>

				</el-row>
				<div style="width: 80%;border-top: 1px solid;color:#ebeef5;height:10px;margin-left:10%; border-bottom-width: 1px;border-left-width: 1px;text-align: center;">
				</div>
			
			</el-row>
		</el-row>
		<el-row>
				<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
				<el-form-item label=" ">
					<el-button v-on:click="addWorkExperience" type="primary" v-bind:disabled="enableAdd"  plain icon="el-icon-plus">增加</el-button>
				</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
					<el-form-item label=" ">
						<el-button v-on:click="edit" type="primary" plain v-bind:disabled="hasLocked?true:this.isReadOnly" icon="el-icon-edit">编辑</el-button>
						<el-button v-on:click="save" plain v-bind:disabled="this.isReadOnly" type="primary" :loading="saving" icon="el-icon">保存</el-button>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
	</el-collapse-item>
</template>


<script>
import { init } from 'echarts';
export default {
	data() {
		let readonlyInit = true;
	

		let workExperiencesInit = new Array();
		let initAdd = false;
		if (this.workExperiences == null || (this.workExperiences != null && this.workExperiences.length==0) ){
			workExperiencesInit.push({
				startDate: null,
				endDate: null,
				workUnit: '',
				department:null,
				workPosition: null,
				witness: null,
				remarks: null,
				newAdded:this.locked?true:false
			});
			initAdd = true;
		} else {
			workExperiencesInit = this.workExperiences;
		}
		
		//  isReadOnly=true for view, others for edit. Users can't eidt if locked, but they can add 
		if(this.isReadOnly || this.locked)
		{
			readonlyInit = true;
		}
		else{
			readonlyInit = false;
		}
		

		return {
			dataFrom:{workExperiencesModel: workExperiencesInit} ,
			readonly: readonlyInit,
			enableAdd:this.enableAddButton,
			hasLocked:this.locked,
			saving:false,
			initAddItem:initAdd,
			pickerOptions1: {
                    disabledDate(time) {
                        return time.getTime() > Date.now();
                    }
            },
			workExpRules:{
				workUnit:[{required: true, message: '请输入工作单位', trigger: 'blur' },
						  { min: 1, max: 200, message: '长度在 1 到 200 个字符', trigger: 'blur' }],
				startDate:[{required: true, message: '请输入起始日期', trigger: 'blur' }]
				// endDate:[{required: true, message: '请输入终止日期', trigger: 'blur' },
				// 		 {validator:ValidateEndDate,trigger:"blur"}]
			}
		};
	},
	props: { workExperiences: Array, id: Number ,isReadOnly:Boolean,collapseStatus:String,locked:Boolean,enableAddButton:Boolean },
	watch: {
		isReadOnly(newValue,oldV) {
			this.readonly = newValue;
			//this.hasLocked = newValue;
		},
		locked(newValue,oldV) {
			this.hasLocked = newValue;
		},
	},
	computed: {
        newAddedAfterLocked() {
            return function(currentItem){
                return {employ_item_update:this.locked&&currentItem.newAdded&&!this.initAddItem&&!this.isReadOnly};         
            }           
        } ,
		canEdit(){
			 return function(currentItem){
				 let returnValue = true;
				 if(this.hasLocked){
					 returnValue =currentItem.newAdded&&!this.isReadOnly?false:true;
				 }
				 else{
					returnValue = this.readonly;
				 }
                return returnValue;      
            }   
		} ,
		collapseSetting(){
			return function(){
				if(this.dataFrom.workExperiencesModel !=null && this.dataFrom.workExperiencesModel.length >0)
				{
					if(this.locked)
					{
						let newAddResults = this.dataFrom.workExperiencesModel.filter(item=>item.newAdded==true);
						if(newAddResults.length > 0)
						{
							if(this.initAddItem){
								return {callpse_header_unfill:true};
							}
							else{
								return {callpse_header_newAdd:true};
							}
						}
						else
						{
							return {callpse_header_fill:true};
						}
						
					}
					else
					{
						if(this.initAddItem)
						{
							return {callpse_header_unfill:true};
						}
						else{
							return {callpse_header_fill:true};
						}
					}
					
				}
				else
				{
					return {callpse_header_unfill:true};
				}
				
			}
		},
    },
	methods: {
		save() {
			this.$refs.dataFrom.validate(valid => {
				let self = this;
				if (valid) {
						// save data to DB
						let savingWorkExperiences = {};
						savingWorkExperiences.id = this.id;
						savingWorkExperiences.workExps = self.dataFrom.workExperiencesModel;
						this.saving = true;
						this.$emit('updatePartialEmployeeInfo', { data: savingWorkExperiences }, (response) => {
							this.saving = false;
							if (response.success) {
								this.msgSuccess('修改成功');
								this.readonly = true;
							} else {
								this.msgError('修改失败,' + response.msg);
							}
						});
				} 
				else {
						this.msgError('有非法输入或必填项未填写，请检查!');
				}
			});
		},
		edit() {
			this.readonly = false;
		},
		addWorkExperience() {
			this.readonly = false;
			this.dataFrom.workExperiencesModel.push({
				startDate: null,
				endDate: null,
				workUnit: '',
				department:null,
				workPosition: null,
				witness: null,
				remarks: null,
				newAdded:true
			});
		},
		deleteWorkExperience(event, workUnit) {
			let self = this;
			if (event.currentTarget.id == workUnit) {
				this.$confirm('确定要删除吗?', '确认', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'error',
					// type: 'warning'
				})
				.then(() => {
					if (self.dataFrom.workExperiencesModel.length > 0) {
						let foundIndex = self.dataFrom.workExperiencesModel.findIndex((x) => x.workUnit === workUnit);
						if (foundIndex > -1) {
							self.dataFrom.workExperiencesModel.splice(foundIndex, 1);
						}
					}
				})
				.catch(() => {
					this.msgError('删除发生异常!');
				});
			}
		},
		// changeEndDate(endDate,startDate)
		// {
		// 	if (endDate < startDate) {
		// 		this.msgError("终止日期不能小于起始日期。");
		// 	}
		// }
		endDateValidator(rule, value, callback, startDate) {
			// 必需每个分支都要有返回，否则进不去保存的validate方法。
            if(startDate==null || startDate =="")
			{
				return callback(new Error("先选起始日期。"));
			}
			else
			{
				if (value < startDate) {
					return callback(new Error("终止日期不能小于起始日期。"));
				}
				else
				{
					return callback();
				}
			}
            
        },
	},
};
</script>

<style>
</style>