// 定义内容
export default {
	card: {
		title1: '我的工作台',
		title2: '消息通知',
		title3: '更多',
		title4: '营销推荐',
		title5: '更多',
		title6: '库存作业',
		title7: '履约情况',
		title8: '缺货监控',
		title9: '履约超时预警',
	},
};
