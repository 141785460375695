<template>
  <div class="app-container">
    <MsgEdit :row="row" :msg-level-options-in="msgLevelOptions" :msg-type-options-in="msgTypeOptions"
             :is-read-only="readOnly" @close="close">
    </MsgEdit>
  </div>
</template>

<script>
import MsgEdit from './msgEdit';
export default {
  name: "edit",
  components: {
    MsgEdit,
  },
  data() {
    return {
      row: null,
      msgLevelOptions: null,
      msgTypeOptions: null,
      readOnly: null,
      opType: 'hr',
    }
  },
  created() {
    // user router
    const {row, msgLevelOptions, msgTypeOptions} = this.$route.params;
    if (row) this.row = row;
    this.msgLevelOptions = msgLevelOptions;
    this.msgTypeOptions = msgTypeOptions;

  },
  methods: {
    close(){
      this.bus.$emit('onCurrentContextmenuClick', {
        id: 1,
        path: this.$route.path,
      });
    },
    update(data){
      console.log("update");
      console.log(data.data);
    }
  },
}
</script>

<style scoped>

</style>
