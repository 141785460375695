
import request from '@/utils/request'

// Find current company all positions
export function findAllPositons(query) {
    return request({
        url: '/positionrequest/findAllReleasedPositions',
        method: 'post',
        data: query
    })
}

export function GetPositionApplicants(positionId) {
    return request({
        url: '/positionrequest/getPositionApplicants?positionId='+positionId,
        method: 'post',
    })
}



// 新增招聘请求
export function addRecruitPosition(data) {
    return request({
        url: '/positionrequest/add',
        method: 'post',
        data: data
    })
}

// 更新招聘请求
export function updateRecruitPosition(data) {
    return request({
        url: '/positionrequest/edit',
        method: 'post',
        data: data
    })
}

// 删除招聘请求
export function deleteRecruitPosition(ids) {
    return request({
        url: '/positionrequest/remove',
        method: 'post',
        data: ids
    })
}

export function getRecruitPosition(id) {
    return request({
        url: '/positionrequest/findOne?id=' + id,
        method: 'post'
    })
}

export function getPositionInterviewProcess(id) {
    return request({
        url: '/positionrequest/getPositionInterviewProcess?id=' + id,
        method: 'post'
    })
}


//发布招聘计划
export function releaseRecruitPosition(id) {
    return request({
        url: '/positionrequest/releasePosition?id=' + id,
        method: 'post'
    })
}

//关闭招聘计划
export function closeRecruitPosition(id) {
    return request({
        url: '/positionrequest/closePosition?id=' + id,
        method: 'post'
    })
}

//审批招聘计划
export function approveRecruitPosition(id,status, reason) {
    return request({
        url: '/positionrequest/approvePosition?id=' + id+"&status="+status+"&reason="+reason,
        method: 'post'
    })
}



//查出公司及子公司所有已经发布的职位
export function findAllPositionNames(id) {
    return request({
        url: '/positionrequest/findAllPositionNames?companyId=' + id,
        method: 'post'
    })
}



//申请招聘
export function applyPosition(data) {
    return request({
        url: '/positionresume/add',
        method: 'post',
        data: data
    })
}

// 通知申请人
export function updateInterview(data) {
    return request({
        url: '/positionresume/edit',
        method: 'post',
        data: data
    })
}

// 转成员工

export function convertToEmployee(data) {
    return request({
        url: '/positionresume/convertToEmployee',
        method: 'post',
        data: data
    })
}

// Find current company all positions
export function statisticsRecruit(query) {
    return request({
        url: '/positionresume/statisticsRecruit',
        method: 'post',
        data: query
    })
}
