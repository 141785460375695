const tagsViewRoutesModule = {
	namespaced: true,
	state: {
		tagsViewRoutes: [],
	},
	mutations: {
		// 设置 TagsView 路由
		SET_TAGS_VIEW_ROUTES(state, data) {
			state.tagsViewRoutes = data;
		},
		DEL_VISITED_VIEW: (state, view) => {
			for (const [i, v] of state.tagsViewRoutes.entries()) {
				if (v.path === view.path) {
					state.tagsViewRoutes.splice(i, 1)
					break
				}
			}
		},

	},
	actions: {
		// 设置 TagsView 路由
		async setTagsViewRoutes({ commit }, data) {
			commit('SET_TAGS_VIEW_ROUTES', data);
		},
		delView({ commit, state }, view) {
			return new Promise(resolve => {
				commit('DEL_VISITED_VIEW', view)
				resolve([...state.tagsViewRoutes])
			})
		},
	},
};

export default tagsViewRoutesModule;
