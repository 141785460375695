import {convertUTCTimeToLocalTime} from "@/utils/formatTime";

export function getStepDes(item, handleTask) {
    let desc = "";
    if (item.status === 1) {
        let assignee = item.assignee;
        if(handleTask && handleTask.userNameMap && handleTask.userNameMap[assignee]){
            assignee = handleTask.userNameMap[assignee].name;
        }
        desc += "审批人：[" + assignee + "]    审批意见: [" + item.comment + "]   审批时间: " + this.convertUTCTimeToLocalTime(item.endTime);
        // desc += "审批人：[" + item.assignee + "]    审批意见: [" + item.comment + "]   审批时间: " + convertUTCTimeToLocalTime(item.endTime);
    }
    return desc;
}

export function getStepTitle(item) {
    let name = item.stepName;
    if (item.status === 1) {
        name += '(已完成)'
    }
    if (item.status === 0) {
        name += '(进行中)'
    }
    return name;
}

export function getStepActive(handleTask) {
    let idx = 0;
    for (let i = 0; i < handleTask.historyTask.length; i++) {
        if (handleTask.historyTask[i].status === 1) {
            idx = idx + 1;
        } else {
            break;
        }
    }
    return idx;
}
