import request from '@/utils/request'

// 查询离职申请列表
export function listAttLeave(query) {
    return request({
        url: '/bpm/attLeave/findAll',
        method: 'post',
        data: query
    })
}
// 查询转正申请详细
export function initApply() {
    return request({
        url: '/bpm/attLeave/initApply',
        method: 'get'
    })
}

// 修改转正申请
export function completeTask(data) {
    return request({
        url: '/bpm/attLeave/completeTask',
        method: 'post',
        data: data
    })
}
// 查询离职申请详细
export function getAttLeave(id) {
    return request({
        url: '/bpm/attLeave/findOne?id=' + id,
        method: 'post'
    })
}

export function getByProcessInstanceId(processInstanceId) {
    return request({
        url: '/bpm/attLeave/getByProcessInstanceId?processInstanceId=' + processInstanceId,
        method: 'post'
    })
}

// 新增离职申请
export function addAttLeave(data) {
    return request({
        url: '/bpm/attLeave/add',
        method: 'post',
        data: data
    })
}

// // 修改离职申请
// export function updateAttLeave(data) {
//     return request({
//         url: '/bpm/attLeave/edit',
//         method: 'post',
//         data: data
//     })
// }

// 删除离职申请
export function delAttLeave(id) {
    return request({
        url: '/bpm/attLeave/remove',
        method: 'post',
        data: id
    })
}

// 导出离职申请
export function exportAttLeave(query) {
    return request({
        url: '/bpm/attLeave/export',
        method: 'post',
        data: query
    })
}
