<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px">
      <el-form-item label="字典类型" prop="dictType">
        <el-input
            v-model="queryParams.dictType"
            placeholder="请输入字典类型"
            clearable
            size="small"
            @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="字典标签" prop="dictLabel">
        <el-input
            v-model="queryParams.dictLabel"
            placeholder="请输入字典标签"
            clearable
            size="small"
            @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="字典键值" prop="dictValue">
        <el-input
            v-model="queryParams.dictValue"
            placeholder="请输入字典键值"
            clearable
            size="small"
            @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
            type="primary"
            plain
            icon="el-icon-plus"
            size="mini"
            @click="handleAdd"
            v-permission="['/system/sysdict/add']"
        >新增
        </el-button>
      </el-col>
      <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>
    </el-row>

    <el-table
        v-loading="loading"
        :data="sysdictList"
        row-key="id"
        default-expand-all
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        style="width:100%" stripe border
    >
      <el-table-column label="字典类型" align="center" prop="dictType" width="180" sortable/>
      <el-table-column label="字典标签" align="center" prop="dictLabel" width="120" sortable/>
      <el-table-column label="字典键值" align="center" prop="dictValue" width="180" sortable/>
      <el-table-column label="字典排序" align="center" prop="dictSort" width="120" sortable/>
      <el-table-column label="备注" align="center" prop="remark"/>
      <el-table-column label="操作" align="center" width="120" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="handleUpdate(scope.row)"
              v-permission="['/system/sysdict/edit']">修改
          </el-button>
          <el-button
              size="mini"
              type="text"
              icon="el-icon-delete"
              @click="handleDelete(scope.row)"
              v-permission="['/system/sysdict/remove']">删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 添加或修改字典管理对话框 -->
    <el-dialog :title="title" :visible.sync="open" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-row :gutter="35">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
            <el-form-item label="字典类型" prop="dictType">
              <el-input v-model="form.dictType" placeholder="请输入字典类型"/>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
            <el-form-item label="字典标签" prop="dictLabel">
              <el-input v-model="form.dictLabel" placeholder="请输入字典标签"/>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
            <el-form-item label="字典键值" prop="dictValue">
              <el-input v-model="form.dictValue" placeholder="请输入字典键值"/>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
            <el-form-item label="字典排序" prop="dictSort">
              <el-input v-model="form.dictSort" placeholder="请输入字典排序"/>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
            <el-form-item label="样式属性" prop="cssClass">
              <el-input v-model="form.cssClass" placeholder="请输入样式属性"/>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
            <el-form-item label="表格样式" prop="listClass">
              <el-input v-model="form.listClass" placeholder="请输入表格回显样式"/>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
            <el-form-item label="上级字典" prop="pid">
              <treeselect v-model="form.pid" :options="sysdictOptions"
                          :normalizer="normalizer" placeholder="请选择上级字典"/>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
            <el-form-item label="是否默认" prop="isDefault">
              <el-radio v-model="form.isDefault" label="Y">是</el-radio>
              <el-radio v-model="form.isDefault" label="N">否</el-radio>
<!--              <el-input v-model="form.isDefault" placeholder="请输入是否默认"/>-->
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
            <el-form-item label="状态" prop="status">
              <el-radio v-model="form.status" label="0">正常</el-radio>
              <el-radio v-model="form.status" label="1">停用</el-radio>
<!--              <el-input v-model="form.status" placeholder="请输入状态"/>-->
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
            <el-form-item label="备注" prop="remark">
              <el-input v-model="form.remark" type="textarea" placeholder="请输入内容"/>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  listSysdict,
  getSysdict,
  delSysdict,
  addSysdict,
  updateSysdict,
  exportSysdict } from "@/api/sys/sysdict";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import RightToolbar from "@/components/RightToolbar"
import permission from '@/directive/permission/index.js' // 权限判断指令
// 表查询配置
const dbOrderBy = 'dictType, dictSort  asc'
// 分页配置
const pageSize = 50
const pageCurrent = 1
export default {
  name: "Sysdict",
  components: {
    Treeselect,
    RightToolbar,
  },

  directives: { permission },

  data() {
    return {
      // 遮罩层
      loading: true,
      // 显示搜索条件
      showSearch: true,
      // 字典管理表格数据
      sysdictList: [],
      // 字典管理树选项
      sysdictOptions: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 查询参数
      where: {
        query: '',
        filters: []
      },
      orderby: dbOrderBy,
      options: {
        pageSize,
        pageCurrent
      },
      // 查询参数
      queryParams: {
        dictLabel: null,
        dictValue: null,
        dictType: null,
      },
      // 表单参数
      form: {
      }
      ,
      // 表单校验
      rules: {
      }
    }
        ;
  },
  created() {
    this._filter = {};
    this.getList();
  },
  methods: {
    /** 查询字典管理列表 */
    getList() {
      this.loading = true;
      this._filter = {};
      if(this.queryParams.dictLabel !== null) {
        this._filter['dictLabel'] = {
          type: 'search',
          value: this.queryParams.dictLabel,
        }
      }
      if(this.queryParams.dictValue) {
        this._filter['dictValue'] = {
          type: 'search',
          value: this.queryParams.dictValue,
        }
      }
      if(this.queryParams.dictType) {
        this._filter['dictType'] = {
          type: 'search',
          value: this.queryParams.dictType,
        }
      }
      const filters = this.parseFilters(this._filter)
      if (filters && filters.length > 0) {
        this.where.filters = filters
      } else {
        // 如果过滤条件为空，设置空数组
        this.where.filters = []
      }
      const params = {
        pageNum: this.options.pageCurrent,
        pageSize: this.options.pageSize,
        where: this.where,
        order: this.orderby
      }

      listSysdict(params).then(response => {
        this.sysdictList = this.handleTree(response.data.records, "id", "pid");
        console.log(this.sysdictList)
        this.loading = false;
      });
    },
    /** 转换字典管理数据结构 */
    normalizer(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.id,
        label: node.dictLabel,
        children: node.children
      };
    },
    /** 查询部门下拉树结构 */
    getTreeselect() {
      const params = {
        pageNum: 1,
        pageSize: 10000,
        where: null,
        order: this.orderby
      }
      listSysdict(params).then(response => {
        this.sysdictOptions = [];
        const data = { id:0,
          dictLabel:'顶级节点',
          children: []
        };
        data.children = this.handleTree(response.data.records, "id", "pid");
        this.sysdictOptions.push(data);
      }) ;
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {};
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.getTreeselect();
      this.open = true;
      this.title = "添加字典管理";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      this.getTreeselect();

      getSysdict(row.id).then(response => {
        this.form = response.data;
        this.open = true;
        this.title = "修改字典管理";
      })
      ;
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate(valid => {
            if(valid) {
              if (this.form.id != null) {
                updateSysdict(this.form).then(response => {
                  this.msgSuccess("修改成功");
                  this.open = false;
                  this.getList();
                });
              } else {
                addSysdict(this.form).then(response => {
                  this.msgSuccess("新增成功");
                  this.open = false;
                  this.getList();
                });
              }
            }
          }
      )
      ;
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm('是否确认删除字典"' + row.dictLabel + '"的数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return delSysdict([row.id]);
      }).then(() => {
        this.getList();
        this.msgSuccess("删除成功");
      })
    }
  }
};
</script>
