<template>
<div>
  <el-form ref="form" :model="form" :rules="rules" label-width="80px">
    <el-row :gutter="35">
      <el-col :xs="24" :sm="16" :md="8" :lg="8" :xl="8" class="mb20">
        <el-form-item label="员工姓名" prop="empName">
          <el-input :disabled="true" v-model="form.empName" placeholder="请输入员工姓名"/>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="16" :md="8" :lg="8" :xl="8" class="mb20">
        <el-form-item label="公司" prop="companyId">
          <span>{{companyFormat(form, null)}}</span>
<!--          <treeselect :disabled="true" v-model="form.companyId" :options="companyOptions"-->
<!--                      :normalizer="normalizerCompany" placeholder="请选择公司"/>-->
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="16" :md="8" :lg="8" :xl="8" class="mb20">
        <el-form-item label="部门" prop="depId">
          <span>{{departmentFormat(form, null)}}</span>
<!--          <treeselect :disabled="true" v-model="form.depId" :options="departmentOptions"-->
<!--                      :normalizer="normalizerCompany" placeholder="请选择部门"/>-->
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="16" :md="8" :lg="8" :xl="8" class="mb20">
        <el-form-item label="银行卡号" prop="bankAcct">
          <el-input v-model="form.bankAcct" placeholder="请输入银行卡号"/>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="16" :md="8" :lg="8" :xl="8" class="mb20">
        <el-form-item label="开会银行" prop="bankName">
          <el-input v-model="form.bankName" placeholder="请输入开会银行"/>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="16" :md="8" :lg="8" :xl="8" class="mb20">
        <el-form-item label="社保卡号" prop="insuranceAcct">
          <el-input v-model="form.insuranceAcct" placeholder="请输入社保卡号"/>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="16" :md="8" :lg="8" :xl="8" class="mb20">
        <el-form-item label="公积金号" prop="fundAcct">
          <el-input v-model="form.fundAcct" placeholder="请输入公积金号"/>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="16" :md="8" :lg="8" :xl="8" class="mb20">
        <el-form-item label="总工资" prop="contractSalary">
          <el-input v-model="form.contractSalary" placeholder="请输入合同总工资，自动计算浮动奖金"/>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
  <div slot="footer" class="dialog-footer">
    <el-button type="primary" @click="submitForm" v-permission="['/cb/cbSalary/add', '/cb/cbSalary/edit']">确 定</el-button>
    <el-button @click="cancel">取 消</el-button>
  </div>

</div>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import permission from '@/directive/permission/index.js' // 权限判断指令
import {getCbSalary,updateCbSalary } from "@/api/cb/cbSalary";

export default {
  name: "salaryEdit",
  components: {
    Treeselect,
  },
  directives: { permission },
  props:{
    // 公司树选项
    companyList: Array,
    companyOptions: Array,
    // 部门管理树选项
    departmentList: Array,
    departmentOptions: Array,
    salaryId: Number,
  },
  watch: {
    "salaryId": function (n, o) {
      this.load();
    },
  },
  data() {
    return {
      // 表单参数
      form: {
      },
      // 表单校验
      rules: {

      },

    }
  },
  created() {

  },
  methods:{
    load(){
      if(this.salaryId){
        getCbSalary(this.salaryId).then(response => {
          this.form = response.data;
        });
      }
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate(valid => {
        if(valid) {
          if (this.form.id != null) {
            updateCbSalary(this.form).then(response => {
              this.msgSuccess("修改成功");
              this.open = false;
              this.getList();
            });
          } else {
            // addCbSalary(this.form).then(response => {
            //   this.msgSuccess("新增成功");
            //   this.open = false;
            //   this.getList();
            // });
          }
        }
      });
    },
    /** 转换公司数据结构 */
    normalizerCompany(node) {
      if(node.className=='company'){
        return {
          id: node['treeId'],
          label: node['companyName'], //node['id'] + " - " +node['companyName'],
          children: node['children']
        };
      }else if(node.className=='department'){
        return {
          id: node['treeId'],
          label: node['name'],
          children: node['children']
        };
      }
    },

    companyFormat(row, column) {
      console.log(row);
      for(var i = 0; i < this.companyList.length; i++){
        const item =  this.companyList[i];
        if(item.id == row.companyId){
          return item.companyName;
        }
      }
      return "";
    },
    departmentFormat(row, column) {
      for(var i = 0; i < this.departmentList.length; i++){
        const item =  this.departmentList[i];
        // console.log("id:"+item.id+",depid:"+row.departmentId+", name :"+item.name+",ee="+(item.id == row.departmentId));
        if(item.id == row.depId){
          return item.name;
        }
      }
      return "";
    },
    getCompanyCode(companyId){
      console.log(companyId)
      console.log(this.companyList)
      for(var i = 0; i < this.companyList.length; i++){
        const item =  this.companyList[i];
        if(item.id == companyId){
          return item.code;
        }
      }
    },
    // 取消按钮
    cancel() {
      this.$emit("close", {refresh:false,isSuccess:true});
    },

  },
}
</script>

<style scoped>

</style>
