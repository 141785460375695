<template>
    <div style="background-color:white;margin-bottom:5px;padding-left:20px;">
        <el-form ref="form" :model="position" >
            <el-row style="margin-bottom:5px;">
                <span style="color:#409eff;font-size:22px;"> {{position.positionName}}</span>
                <span style="font-size:18px"> {{position.releasedDate.substr(0,10)}}发布</span>
            </el-row>
            <el-row style="margin-bottom:5px;">
                <span style="color:orange;font-size:22px;margin-right:15px;"> {{this.salary}}</span>
                <span style="font-size:18px; margin-right:5px;"> {{position.gender}} &nbsp;|</span>
                <span style="font-size:18px;margin-right:5px;"> {{this.age}} &nbsp;|</span>
                <span style="font-size:18px;margin-right:5px;"> {{this.workExp}} &nbsp;|</span>
                <span style="font-size:18px"> {{this.education}}</span>
            </el-row>
            <el-row style="margin-bottom:5x;">
                <span style="margin-right:10px;">福利津贴:</span><span>{{position.welfare}}</span>
            </el-row>
			<el-row >
               <span> <el-link type="primary" @click="openDetail" >详情</el-link>
                   <el-button style="border:none;padding:0px;margin-right:15px;"  @click="openDetail" :icon='unfoldIcon' ></el-button>
                </span>
				<el-button style="margin-bottom:5px;" v-on:click="applyCurrentPosition" :loading="addLoading" plain size="mini" type="primary">应聘</el-button>
			</el-row>
            <el-row :style="{'display':unfold? 'block':'none'}" style="background-color:#f2f6fc;">
                    <h3>职位信息</h3>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;{{position.responsibility}}</div>
                    <h3>任职要求</h3>
                    <div>&nbsp;&nbsp;&nbsp;&nbsp;{{position.requirement}}</div>
                    <h3>工作地址</h3>
                    <div>上班地址：{{position.workPlace}}</div>
            </el-row>
		</el-form>
    </div>
</template>

<script>
import {applyPosition} from '@/api/recruit/positionManagement';
export default {
    name: 'onePosition',

    data() {
        return {
            position:this.currentPosition,
            unfold:false,
            unfoldIcon:'el-icon-caret-bottom',
            salary:"",
            age:"",
            workExp :"",
            education:"",
            releasedDate:"",
            addLoading:false,
        };
    },

    props: {
		currentPosition: Object,
        currentApplicant: Object
	},
    created(){
        if(this.currentPosition.minAge == undefined && this.currentPosition.maxAge == undefined ){
            this.age="无年龄限制";
        }
        else if((this.currentPosition.minAge == null || this.currentPosition.minAge == '' || this.currentPosition.minAge == undefined )
                &&(this.currentPosition.minAge != null || this.currentPosition.minAge != '') ){
            this.age="不大于"+this.currentPosition.maxAge + "岁";
        }
        else if((this.currentPosition.minAge != null || this.currentPosition.minAge != '') 
        && (this.currentPosition.maxAge == null || this.currentPosition.maxAge == '' || this.currentPosition.maxAge == undefined )){
            this.age="不小于"+this.currentPosition.minAge+"岁";
        }
        else
        {
            this.age= this.currentPosition.minAge+'-'+this.currentPosition.maxAge+"岁";
        }

        if(this.currentPosition.minSalary == undefined && this.currentPosition.maxSalary == undefined){
            this.salary="面谈";
        }
        else if((this.currentPosition.minSalary == null || this.currentPosition.minSalary == '' || this.currentPosition.minSalary == undefined)
            &&(this.currentPosition.maxSalary != null || this.currentPosition.maxSalary != '') ){
            this.salary="不高于"+this.currentPosition.maxSalary;
        }
        else if((this.currentPosition.minSalary != null || this.currentPosition.minSalary != '')&& 
        (this.currentPosition.maxSalary == null || this.currentPosition.maxSalary == '' || this.currentPosition.maxSalary == undefined)){
            this.salary="不低于"+this.currentPosition.minSalary;
        }
        else
        {
            this.salary= this.currentPosition.minSalary+'-'+this.currentPosition.maxSalary;
        }

        if(this.salary != "面谈"){
             if(this.currentPosition.salaryType=="年薪" ){
                this.salary += "/年"
            }
            else{
                this.salary += "/月"
            }
        }
       
        if(this.currentPosition.workExp == null || this.currentPosition.workExp == '' )
        {
            this.workExp = "无工作经验也可";
        }
        else{
            this.workExp =this.currentPosition.workExp +"经验";
        }

        if(this.currentPosition.education == null || this.currentPosition.education == '' ){
            this.education = "无学历要求";
        }
        else{
            this.education = this.currentPosition.education;
        }
      
    },
    mounted() {
        
    },

    methods: {
        openDetail(){
            this.unfold = !this.unfold;
            if(this.unfold)
            {
                this.unfoldIcon = 'el-icon-caret-top'
            }
            else
            {
                this.unfoldIcon='el-icon-caret-bottom';
            }
        },
         applyCurrentPosition(){
            if(this.currentApplicant == null || this.currentApplicant.name==undefined  || this.currentApplicant.name =='' || this.currentApplicant.name==null){
                this.msgError('请先登录，如没注册，先注册再登录！');
                return;
            }
            let appledPerson = {};
            appledPerson.positionId = this.currentPosition.id;
            appledPerson.positionName = this.currentPosition.positionName;
            appledPerson.resumeId = this.currentApplicant.id;
            appledPerson.resumeName = this.currentApplicant.name;
            appledPerson.status="申请";
            let that = this;
            this.addLoading = true;
            applyPosition(appledPerson)
                .then((response) => {
                    that.addLoading = false;
                    that.msgSuccess('申请成功。');
                })
                .catch((err) => {
                    that.addLoading = false;
                  //  that.msgError('申请失败，' + err);
                });
         },
    },
};
</script>

<style lang="scss" scoped>

</style>