<template>
	<div>
		<el-form :model="queryParameters" ref="queryParameters" class="employ" label-width="auto">
			<el-row :gutter="24">
				<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
					<el-form-item label="公司名称">
						<el-select v-model="companySelect" clearable @clear="handleClearable" placeholder="请选择" collapse-tags ref="companySelected">
							<el-option :value="companyId" :label="companySelect" style="height: auto">
								<el-tree
									:data="companyOptions"
									:props="defaultProps"
									:expand-on-click-node="false"
									ref="tree"
									show-checkbox
									node-key="id"
									default-expand-all
									@node-click="handleNodeClick"
									@check="handleNodeClick"
									highlight-current
								/>
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
                <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
					<el-form-item label="职位名称">
						<el-input v-model="queryParameters.jobName" clearable placeholder="请输入职位名称"></el-input>
					</el-form-item>
				</el-col>
                <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
					<el-form-item label="职位类型">
						<el-select v-model="queryParameters.jobType" clearable placeholder="请选择职位类型" style="width:100% !important;">
							<el-option v-for="item in jobTypes" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue" />
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
					<el-form-item label="职位状态">
						<el-select v-model="queryParameters.jobStatus" clearable placeholder="请选择职位状态" style="width:100% !important;">
							<el-option v-for="item in jobStatuses" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue" />
						</el-select>
					</el-form-item>
				</el-col>
				
			</el-row>
			<el-row>
				<el-col :span="12" class="mb8">
					<el-button  icon="el-icon-search" size="mini" plain type="primary" :loading='loading'  @click="query">搜索</el-button>
				</el-col>
			</el-row>
		</el-form>
		
		<el-table v-loading="loading" :data="positions" style="width: 100%" @selection-change="handleSelectionChange" stripe border>
			<!-- <el-table-column type="selection" width="55" align="center" /> -->
			<el-table-column label="公司名称" align="center" prop="companyName" sortable width="150px" show-overflow-tooltip="true"> </el-table-column>
			<el-table-column label="职位名称" align="center" prop="positionName" width="150px"  sortable :show-overflow-tooltip="true" >
                 <template slot-scope="scope">
                     <el-link type="primary" @click="fetchApplicants(scope.row)" :underline="false">{{scope.row.positionName}}</el-link>
                </template>
            </el-table-column>
            <el-table-column label="简历总数" align="center" prop="applicantCount" min-width="60" />
			<el-table-column label="工作地点" align="center" prop="workPlace" width="150px"  sortable :show-overflow-tooltip="true" />
			<el-table-column label="发布日期" align="center" prop="releasedDate" sortable :show-overflow-tooltip="true">
                <template slot-scope="scope">
                     <span>{{ formatDate(scope.row.releasedDate) }}</span>
                </template>
             </el-table-column>
			<el-table-column label="结束日期" align="center" prop="closedDate" sortable>
                <template slot-scope="scope">
                     <span>{{ formatDate(scope.row.closedDate) }}</span>
                </template>
             </el-table-column>
			<el-table-column label="职位类型" align="center" prop="jobType" />
			<el-table-column label="招聘类型" align="center" prop="jobClass" />
			<el-table-column label="状态" align="center" prop="status" />
		</el-table>
		<pagination v-show="total > 0" :total="total" :page.sync="queryParameters.pageNum" :limit.sync="queryParameters.pageSize" @pagination="query" />
		
       
	</div>
</template>

<script>
import Pagination from '@/components/Pagination';
import permission from '@/directive/permission/index.js'; // 权限判断指令
import { getTreeselectCompany } from '@/api/org/company';
import {findAllPositons,deleteRecruitPosition,GetPositionApplicants} from "@/api/recruit/positionManagement"
import positionEdit from "./positionEdit.vue"
export default {
	name: 'positionApplicants',
	components: {
		Pagination,
		positionEdit,
	},
	data() {
		return {
			queryParameters: {
                jobName:'',
				jobStatus: '',
				jobType: '',
				companyId: '',
				pageSize: 10,
				pageNum: 1,
			},
			where: {
				query: '',
				filters: [],
			},
			ids: [],
			single: false,
			multiple: false,
			positions: [],
			companySelect: '',
			companyId: '',
			isCompnayVisible: false,
			// 公司树选项
			companyOptions: [],
			companyList: [],
			defaultProps: {
				children: 'children',
				label: 'companyName',
			},
            loading:false,
			newRecruitPosition:false,

			selectRecruitPosition:{},
			total: 0,
			queryType:"all",
			title:"",
            dateRange:'',
			releasedLoading:false,
			unreleasedLoading:false,
			jobTypes:[],
            jobStatuses:[],

		};
	},

	mounted() {
		this.query();
	},
	created() {
		getTreeselectCompany(null, (list, options) => {
			this.companyList = list;
			if (this.companyList.length > 1) {
				this.isCompnayVisible = true;
			}
			this.companyOptions = options;
		});
        this.getDicts('job_type').then((response) => {
			this.jobTypes = response.data;
		});

		this.getDicts('recruit-status').then((response) => {
			this.jobStatuses = response.data;
		});
	},
	methods: {
         formatDate(currentDate){
            if(currentDate==null || currentDate=='')
            {
                return "";
            }
            else{
                return currentDate.substr(0,10);
            }
            
         },
		 handleSelectionChange(selection) {
				this.ids = selection.map(item => item.id)
				this.single = selection.length == 1
				this.multiple = selection.length>=1;
				let findPositions= this.positions.filter(item=>item.id==this.ids[0]);
				this.selectRecruitPosition = findPositions[0];
				// this.enableCheckInButton = this.single && !this.disableButton(this.selectTrainingPlan);
				// this.enableReleaseButton = this.single && !this.disableButton(this.selectTrainingPlan)&&findTrainingPlans[0].status=='未发布';
			},
        // 节点单击事件
		handleNodeClick(data) {

			this.queryParameters.companyId = data.id;
			this.companyId = data.id;
			this.companySelect = data.companyName;
			let selectArray = [];
			selectArray.push(this.companyId);
			this.$refs.tree.setCheckedKeys(selectArray);
			this.$refs.companySelected.blur();
		},
		
		handleClearable()
		{
				this.$refs.tree.setCheckedKeys([]);
				this.companyId=null;
				this.companySelect='';
		},
      
       
        query() {
			let filter = {};
			if (this.companyId) {
				filter['companyId'] = {
					type: 'eq',
					value: this.companyId,
				};
			}
			
			if (this.queryParameters.jobStatus) {
				filter['status'] = {
					type: 'eq',
					value: this.queryParameters.jobStatus,
				};
			}

            if (this.queryParameters.jobType) {
				filter['jobType'] = {
					type: 'eq',
					value: this.queryParameters.jobType,
				};
			}

            if (this.queryParameters.jobName) {
				filter['positionName'] = {
					type: 'search',
					value: this.queryParameters.jobName,
				};
			}

			
			const filters = this.parseFilters(filter);
			if (filters && filters.length > 0) {
				this.where.filters = filters;
			} else {
				// 如果过滤条件为空，设置空数组
				this.where.filters = [];
			}
			const params = {
				pageNum: this.queryParameters.pageNum,
				pageSize: this.queryParameters.pageSize,
				where: this.where,
				order: '',
			};
			this.loading = true;
			findAllPositons(params).then((response) => {
				this.positions = response.data.records;
				this.total = response.data.total;
	
				this.loading = false;
				
			});

		},
	
		fetchApplicants(row){
			this.$router.push({name:"recruit_position_applicant", params: {  positionId: row.id}});
		},
		
		

    },
};
</script>

<style  scoped>

</style>