<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px">
      <el-form-item label="姓名" prop="name">
        <el-input
            v-model="queryParams.name"
            placeholder="请输入姓名"
            clearable
            size="small"
            @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="转正情况" prop="status">
        <el-select v-model="queryParams.status" clearable placeholder="转正情况"
                   size="small">
          <el-option label="提前转正" value="1"/>
          <el-option label="正常转正" value="2"/>
          <el-option label="延迟转正" value="3"/>
          <el-option label="不聘用" value="4"/>
        </el-select>
      </el-form-item>
      <el-form-item label="同意" prop="approved">
        <el-select v-model="queryParams.approved" clearable placeholder="是否同意"
                   size="small">
          <el-option label="不同意" value="0"/>
          <el-option label="同意" value="1"/>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col v-if="canApply" :span="1.5">
        <el-button
            type="primary"
            plain
            icon="el-icon-plus"
            size="mini"
            @click="handleAdd"
            v-permission="['/bpm/official/apply']"
        >转正申请
        </el-button>
      </el-col>
<!--      <el-col :span="1.5">-->
<!--        <el-button-->
<!--            type="warning"-->
<!--            plain-->
<!--            icon="el-icon-download"-->
<!--            size="mini"-->
<!--            @click="handleExport"-->
<!--            v-permission="['/bpm/official/export']"-->
<!--        >导出-->
<!--        </el-button>-->
<!--      </el-col>-->
      <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>
    </el-row>

    <el-table v-loading="loading" :data="OfficialList" @selection-change="handleSelectionChange" style="width:100%" stripe border>
      <el-table-column type="selection" width="55" align="center"/>
      <el-table-column label="姓名" align="center" prop="name" sortable width="100"/>
      <el-table-column label="部门" align="center" prop="departmentName" sortable width="100"/>
      <el-table-column label="岗位" align="center" prop="positionName" sortable width="100"/>
      <el-table-column label="入职时间" align="center" prop="joinCompanyDate" width="150" sortable>
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.joinCompanyDate, '{y}-{m}-{d}') }}</span>
        </template>
      </el-table-column>
      <el-table-column label="计划转正时间" align="center" prop="planDate" width="150" sortable>
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.planDate, '{y}-{m}-{d}') }}</span>
        </template>
      </el-table-column>
<!--      <el-table-column label="自我评价" align="center" prop="selfAppraisal"/>-->
<!--      <el-table-column label="部门领导评价" align="center" prop="dmAppraisal"/>-->
      <el-table-column label="试用工资" align="center" prop="probationSalary" width="120"/>
<!--      <el-table-column label="转正工资" align="center" prop="OfficialSalary" width="120"/>-->
<!--      <el-table-column label="实际转正时间" align="center" prop="realDate" width="180" sortable>-->
<!--        <template slot-scope="scope">-->
<!--          <span>{{ parseTime(scope.row.realDate, '{y}-{m}-{d}') }}</span>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column label="申请转正" align="center" prop="status" sortable width="100">
        <template slot-scope="scope">
          <span v-if="scope.row.status == 1">提前转正</span>
          <span v-if="scope.row.status == 2">正常转正</span>
          <span v-if="scope.row.status == 3">延迟转正</span>
          <span v-if="scope.row.status == 4">不聘用</span>
        </template>
      </el-table-column>
      <el-table-column label="同意" align="center" prop="approved">
        <template slot-scope="scope">
          <span v-if="scope.row.approved == 1">同意</span>
          <span v-if="scope.row.approved == 0">不同意</span>
          <span v-if="scope.row.approved == null">处理中</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width"  width="200" fixed="right">
        <template slot-scope="scope">
          <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="showFlowDetail(scope.row)"
              v-permission="['/bpm/official/approve','/bpm/official/apply']"
          >审批进度
          </el-button>
          <el-button
              size="mini"
              type="text"
              icon="el-icon-view"
              @click="showTask(scope.row)"
              v-permission="['/bpm/official/approve','/bpm/official/apply']"
          >查看
          </el-button>
          <el-button
              size="mini"
              type="text"
              icon="el-icon-printer"
              @click="showTaskPrinting(scope.row)"
              v-permission="['/bpm/official/approve','/bpm/official/apply']"
          >打印
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
        v-show="total>0"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
    />

    <!-- 添加或修改转正申请对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="60%" append-to-body>
      <official-edit :type="businessType" :id="businessKey" :isReadOnly="true" :isShowClose="true" @close="open = false"></official-edit>
    </el-dialog>
    <el-dialog :title="title" :visible.sync="openFlowDetail" width="60%" append-to-body>
      <FlowDetail :processInstanceIdIn="processInstanceId"></FlowDetail>
      <div style="text-align: center"><el-button @click="openFlowDetail=false">关 闭</el-button></div>
    </el-dialog>
    <el-dialog title="打印" v-if="openPrinting" :visible.sync="openPrinting" width="80%" append-to-body>
      <official-printing :rowData='currentRow' :id="businessKey"  @close="openPrinting = false"></official-printing>
    </el-dialog>
  </div>
</template>

<script>
import {
  listOfficial,
  exportOfficial } from "@/api/bpm/official.js";
import RightToolbar from "@/components/RightToolbar"
import permission from '@/directive/permission/index.js' // 权限判断指令
import Pagination from "@/components/Pagination";
import OfficialEdit from "../official/officialEdit";
import officialPrinting from "../official/officialPrinting";
import FlowDetail from "../todo/flowDetail";
import {checkRole} from "@/utils/role";

// 表查询配置
const dbOrderBy = ''

export default {
  name: "Official",
  components: {
    RightToolbar,
    Pagination,
    OfficialEdit,
    FlowDetail,
    officialPrinting
  },
  directives: { permission },
  data() {
    return {
      // 遮罩层
      loading: true,
      // 查询参数
      where: {
        query: '',
        filters: []
      },


      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 转正申请表格数据
      OfficialList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      openPrinting:false,
      currentRow:{},
      openFlowDetail: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        orderby: dbOrderBy,
        name: null,
        status: null,
        approved: null,
      },
      businessKey: null,
      businessType: null,
      processInstanceId: null,
      canApply: true,
    };
  },
  created() {
    this._filter = {};
    this.getList();
  },
  methods: {
    /** 查询转正申请列表 */
    getQuery(){
      this._filter = {};
      // 人事和经理可以查看所有人申请，员工只能看自己申请
      const role = ["Admin","GM","HRManager","CAdmin","Official"];
      if(!checkRole(role)){
        this._filter['userId'] = {
          type: 'eq',
          value: this.$store.getters.userInfo.id,
        }
      }
      if(this.queryParams.name) {
        this._filter['name'] = {
          type: 'search',
          value: this.queryParams.name,
        }
      }
      if(this.queryParams.status) {
        this._filter['status'] = {
          type: 'search',
          value: this.queryParams.status,
        }
      }
      if(this.queryParams.approved) {
        this._filter['approved'] = {
          type: 'search',
          value: this.queryParams.approved,
        }
      }
      const filters = this.parseFilters(this._filter)
      if (filters && filters.length > 0) {
        this.where.filters = filters
      } else {
        // 如果过滤条件为空，设置空数组
        this.where.filters = []
      }
      const params = {
        pageNum: this.queryParams.pageNum,
        pageSize: this.queryParams.pageSize,
        where: this.where,
        order: 'id desc'
      }

      return params;
    },
    getList() {
      this.loading = true;
      const params = this.getQuery();
      listOfficial(params).then(response => {
        this.OfficialList = response.data.records;
        this.total = response.data.total;
        this.loading = false;
      });
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.$router.push({name:"bpm_offical_apply"});
    },

    // 显示任务
    showTask(row){
      this.businessType = "view";
      this.businessKey = ""+row.id;
      this.open = true;
    },
    showTaskPrinting(row){
      this.currentRow=row;
       this.businessKey = ""+row.id;
      this.openPrinting=true;
    },
    showFlowDetail(row){
      console.log(row.processInstanceId);
      this.processInstanceId = row.processInstanceId;
      this.openFlowDetail = true;
    },
    // 取消按钮
    cancel() {
      this.open = false;
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },

    /** 导出按钮操作 */
    handleExport() {
      const params = this.getQuery();
      this.$confirm('是否确认导出所有转正申请数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return exportOfficial(params);
      }).then(response => {
        this.download(response.msg);
      })
    }
  }
};
</script>
