<template>
	<el-collapse-item title="党团信息" :name="this.collapseStatus" :class="collapseSetting()">
		<el-form class="employ" label-width="150px">
		<el-row >
			<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
				<el-form-item label="政治面貌">
					<el-input v-model="partyInfoModel.politicalStatus" :readonly="readonly" ref="polical"></el-input>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
				<el-form-item label="入党时间">
					<el-date-picker v-model="partyInfoModel.joinPartyDate" value-format="yyyy-MM-dd" :readonly="readonly" align="left" type="date" placeholder="选择日期" >
					</el-date-picker>
				</el-form-item>
			</el-col>

			<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
				<el-form-item label="转正时间">
                    <el-date-picker v-model="partyInfoModel.formalPartyDate"  value-format="yyyy-MM-dd"  :readonly="readonly" align="left" type="date" placeholder="选择日期" >
					</el-date-picker>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
				<el-form-item label="入党积极分子时间" style="padding:0;">
					<el-date-picker v-model="partyInfoModel.becomePartyActivistDate"  value-format="yyyy-MM-dd"  :readonly="readonly" align="left" type="date" placeholder="选择日期" >
					</el-date-picker>
				</el-form-item>
			</el-col>
	
			<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
				<el-form-item label="考察对像时间">
					<el-date-picker v-model="partyInfoModel.becomeInvestigationDate"  value-format="yyyy-MM-dd"  :readonly="readonly" align="left" type="date" placeholder="选择日期" >
					</el-date-picker>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
				<el-form-item label="入团时间">
					<el-date-picker v-model="partyInfoModel.joinLeagueDate"  value-format="yyyy-MM-dd"  :readonly="readonly" align="left" type="date" placeholder="选择日期" >
					</el-date-picker>
				</el-form-item>
			</el-col>
	
			<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
				 <el-form-item >
					<!-- <el-input v-model="spouseInfoModel.remarks" :readonly="readonly"></el-input> -->
				</el-form-item> 
			</el-col>
			<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
				<el-form-item label=" ">
					<el-button v-on:click="eidit" plain  type="primary" v-bind:disabled="readonly"  icon="el-icon-edit">编辑</el-button>
					<el-button v-on:click="save" v-bind:disabled="readonly" plain :loading="saving" type="primary" icon="el-icon">保存</el-button>
				</el-form-item>
			</el-col>
		</el-row>
	</el-form>
	</el-collapse-item>

</template>


<script>
export default {
	data() {
		let readonlyInit = true;
		let partyInfoInit = null;
		if (this.partyInfo == null) {
			partyInfoInit = {
				politicalStatus: null,
				joinPartyDate: null,
				formalPartyDate: null,
				becomePartyActivistDate: null,
				becomeInvestigationDate: null,
				joinLeagueDate: null,
			};
			readonlyInit = false;
		}
		else{
			partyInfoInit=this.partyInfo;
		}
		

		if(this.locked){
			readonlyInit=true;
		}
		else{
			readonlyInit = this.isReadOnly;
		}


		return {
			partyInfoModel: partyInfoInit,
			readonly: readonlyInit,
			saving:false,
            pickerOptions1: {
                    disabledDate(time) {
                        return time.getTime() > Date.now();
                    }
            }
		};
	},
	props: ['partyInfo', 'id','isReadOnly','collapseStatus','locked'],
	watch: {
		isReadOnly(newValue,oldV) {
			this.readonly = newValue;
		},
		locked(newValue, oldV) {
			this.hasLocked = newValue;
			if (this.hasLocked) {
				this.readonly = true;
			}
			else
			{
				this.readonly = false;
			}
		},
	},
	computed:{
			collapseSetting(){
			return function(){
				if(this.partyInfoModel.politicalStatus != null && this.partyInfoModel.politicalStatus.length > 0 )
				{
					return {callpse_header_fill:true};
				}
				else
				{
					return {callpse_header_unfill:true};
				}
				
			}
		}
	},
	methods: {
		save() {
			// save data to DB
			// this.$emit("submitSpouseInfo",this.spouseInfoInit);
			let savingPartyInfo = {};
			savingPartyInfo.id = this.id;
			savingPartyInfo.partyInfo = this.partyInfoModel;
			this.saving = true;
			this.$emit('updatePartialEmployeeInfo', { data: savingPartyInfo }, (response) => {
				this.saving = false;
				if (response.success) {
					this.msgSuccess('修改成功');
					this.readonly = true;
				} else {
					this.msgError('修改失败,' + response.msg);
				}
			});
		},
		eidit() {
			this.readonly = false;
			this.$refs.political.focus();
		},
	},
};
</script>

<style>
</style>