<template>
    <div>
        <el-form :model="queryParameters" ref="queryParameters" class="employ" label-width="100px">
            <el-row :gutter="24">
                <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
                    <el-form-item label="公司">
                        <el-select v-model="companySelect" clearable @clear="handleClearable" placeholder="请选择"
                            collapse-tags ref="companySelected">
                            <el-option :value="queryParameters.companyId" :label="companySelect" style="height: auto">
                                <el-tree :data="companyOptions" :props="defaultProps" :expand-on-click-node="false"
                                    ref="tree" show-checkbox node-key="id" default-expand-all
                                    @node-click="handleNodeClick" @check="handleNodeClick" highlight-current/>
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
                    <el-form-item label="起始时间">
                        <el-date-picker v-model="queryParameters.startDate" value-format="yyyy-MM-dd 00:00:00"
                            align="left" type="date" placeholder="选择日期">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
                    <el-form-item label="终止时间">
                        <el-date-picker v-model="queryParameters.endDate" value-format="yyyy-MM-dd 24:00:00"
                            align="left" type="date" placeholder="选择日期">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
                    <el-form-item label="需求名称">
                        <el-input v-model="queryParameters.name" placeholder="培训需求名称" clearable />
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
                    <el-form-item label=" " style="margin-bottom:0px !important;">
                        <el-button v-on:click="query" size="mini" icon="el-icon-search" plain type="primary">查询
                        </el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <el-row :gutter="10" class="mb8">
            <el-col :span="1.5" style="margin-top:15px;">
                <el-button type="primary" plain icon="el-icon-plus" size="mini" @click="handleAdd"
                    v-permission="['/TrainingPlan/add']">新增 </el-button>
            </el-col>
            <el-col :span="1.5" style="margin-top:15px;">
                <el-button type="success" plain icon="el-icon-edit" size="mini" :disabled="!single"
                    @click="handleUpdate2" v-permission="['/TrainingPlan/edit']">修改
                </el-button>
            </el-col>
            <el-col :span="1.5" style="margin-top:15px;">
                <el-button type="danger" plain icon="el-icon-delete" size="mini" :disabled="!multiple"
                    @click="handleDelete" :loading="deleteLoading" v-permission="['/TrainingPlan/delete']">删除
                </el-button>
            </el-col>
        </el-row>
        <el-row :gutter="24">
            <el-table v-loading="loading" :data="trainingRequirements" style="width: 100%;" max-height="100%"
                @selection-change="handleSelectionChange" stripe border>
                <el-table-column type="selection" width="55" align="center" />
                <el-table-column label="编号ID" align="center" prop="id" v-if="false" />
                <!-- <el-table-column label="培训名称" align="center" prop="name" sortable min-width="150"
                    :show-overflow-tooltip="true" /> -->
                <el-table-column label="培训内容" align="center" prop="trainingContent" sortable min-width="150"
                    :show-overflow-tooltip="true" />
                <el-table-column label="培训方式" align="center" prop="traningWay" sortable min-width="150"
                    :show-overflow-tooltip="true" />
                <el-table-column label="培训时长" align="center" prop="trainingHours" sortable min-width="100">
                </el-table-column>
                <el-table-column label="创建日期" align="center" prop="createTime" sortable min-width="100">
                    <template slot-scope="scope">
                        <span>{{ parseTime(scope.row.createTime) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="起始时间" align="center" prop="startDate" sortable min-width="160" />
                <el-table-column label="终止时间" align="center" prop="endDate" sortable min-width="160" />
                <el-table-column label="操作" align="center" min-width="150" fixed="right">
                    <template slot-scope="scope">
                        <el-button size="mini" class="btn baseManageBtn" title="编辑" type="text" icon="el-icon-edit"
                            @click="handleUpdate(scope.row)" v-permission="['/TrainingPlan/edit']"></el-button>
                        <el-button size="mini" class="btn baseManageBtn" title="删除" 
                            type="text" icon="el-icon-s-check" @click="checkInOnline(scope.row)"></el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-row>
        <pagination v-show="total > 0" :total="total" :page.sync="queryParameters.pageNum"
            :limit.sync="queryParameters.pageSize" @pagination="query" />

        <el-dialog :title="title" :visible.sync='showRequirementEdit' width="uniApp?'95%':'50%'" appendhandleUpdate-to-body>
            <div>
                <el-form :model="requirement" label-width="140px" ref="form">
                    <el-row :gutter="24">
                        <!-- <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" class="mb0">
                            <el-form-item label="需求名称" prop="name"
                                :rules="[{ required: true, message: '培训需求名称必填。', trigger: 'blur' }]">
                                <el-input v-model="requirement.name" clearable placeholder="请输入培训需求名称必填。" />
                            </el-form-item>
                        </el-col> -->
                        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">
                            <el-form-item label="需求内容" prop="trainingContent" :rules="[{ required: true, message: '培训内容必填。', trigger: 'blur' }]">
                                <el-input clearable :autosize="{ minRows: 5, maxRows: 10 }" maxlength="1000"
                                    show-word-limit type="textarea" v-model="requirement.trainingContent"
                                    placeholder="请输培训需求内容" />
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" class="mb0">
                            <el-form-item label="培训方式" prop="traningWay"
                                :rules="[{ required: true, message: '培训类型必选。', trigger: 'blur' }]">
                                <el-select v-model="requirement.traningWay"  placeholder="培训类型必选。">
										<el-option v-for="item in trainingWays" :key="item.value" :label="item.label" :value="item.value" />
									</el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" class="mb0">
                            <el-form-item label="培训时长" prop="trainingHours"
                                :rules="[{ required: true, message: '培训类型必选。', trigger: 'blur' }]">
                                <el-input v-model="requirement.trainingHours" clearable type="number" placeholder="请输入培训时长" />
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" class="mb0">
                            <el-form-item label="起始时间" prop="startDate"
                                :rules="[{ required: true, message: '培训起始时间必选。', trigger: 'blur' }]">
                                <el-date-picker v-model="requirement.startDate" value-format="yyyy-MM-dd HH:mm:ss" align="left"
                                    type="datetime" placeholder="选择日期时间" clearable style="width:100%;">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" class="mb0">
                            <el-form-item label="结束时间" prop="endDate"
                                :rules="[{ required: true, message: '培训结束时间必选。', trigger: 'blur' }]">
                                <el-date-picker v-model="requirement.endDate" value-format="yyyy-MM-dd HH:mm:ss" align="left"
                                    type="datetime" placeholder="选择日期时间" clearable style="width:100%;">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">
                            <el-form-item label="">
                                <el-button v-if="addRequirement" plain type="success" :loading="addLoading"
                                    @click="add">创建</el-button>
                                <el-button v-else plain type="success" @click="update" :loading="updateLoading">更新
                                </el-button>
                                <el-button plain type="info" @click="close">关闭</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>

                </el-form>
            </div>
        </el-dialog>

    </div>
</template>

<script>
import permission from '@/directive/permission/index.js'; // 权限判断指令
import { getTreeselectCompany } from "@/api/org/company";
import Pagination from "@/components/Pagination";
import { Local } from '@/utils/storage.js'
import { addRequirement,updateRequirement,findTrainingRequirement,findAllTrainingRequirements,deleteRequirement } from '@/api/training/requirement';

export default {
    name: 'trainingRequirement',
    components: {
        Pagination
    },
    directives: { permission },
    data() {
        return {
            trainingRequirements: [],
            isCompnayVisible: false,
            companyList: [],
            total: 0,
            loading: false,
            companySelect: '',
            // 公司树选项
            companyOptions: [],
            defaultProps: {
                children: 'children',
                label: 'companyName',
            },
            queryParameters: {
                startDate: '',
                endDate: '',
                name: '',
                companyId: '',
                pageSize: 10,
                pageNum: 1,
            },
            where: {
                query: '',
                filters: [],
            },
            trainingWays: [
				{ value: '在线视频', label: '在线视频' },
				{ value: '在线文档', label: '在线文档' },
				{ value: '线下讲座', label: '线下讲座' },
				{ value: '其它', label: '其它' },
			],
            title: "",
            showRequirementEdit: false,
            selectTrainingRequirement: null,
            uniApp: false,
            ids: [],
            single: false,
            multiple: false,
            companyId:'',
            requirement:{},
            trainingTypes:[],
            addRequirement:false,
            addLoading:false,
            updateLoading:false,
            refreshList:false,
            deleteLoading:false,
        };
    },

    mounted() {

    },
    created() {
        this.uniApp = Local.get("isUniapp") ? true : false;
        this.load();
        this.query();

        this.getDicts('trainingPlan_type').then((response) => {
			this.trainingTypes = response.data;
		});
    },

    methods: {
        load() {
            getTreeselectCompany(null, (list, options) => {
                this.companyList = list;
                if (this.companyList.length > 1) {
                    this.isCompnayVisible = true;
                }
                this.companyOptions = options;
            });
        },
        // 节点单击事件
        handleNodeClick(data) {
            this.queryParameters.companyId = data.id;
            this.companyId = data.id;
            this.companySelect = data.companyName;
            let selectArray = [];
            selectArray.push(this.companyId);
            this.$refs.tree.setCheckedKeys(selectArray);

            this.$refs.companySelected.blur();
        },
        handleClearable() {
            this.$refs.tree.setCheckedKeys([]);
            this.companyId = null;
            this.companySelect = '';
        },
        query() {
            let filter = {};
            if (this.companyId) {
                filter['companyId'] = {
                    type: 'eq',
                    value: this.companyId,
                };
            }
            if (this.queryParameters.name) {
                filter['name'] = {
                    type: 'search',
                    value: this.queryParameters.name,
                };
            }

            if (this.queryParameters.startDate && this.queryParameters.endDate) {
                let tempDate = this.queryParameters.startDate.replace(/-/g, '/'); //必须把日期'-'转为'/'
                var startDateTimeStamp = new Date(tempDate).getTime();
                tempDate = this.queryParameters.endDate.replace(/-/g, '/');
                var endDateTimeStamp = new Date(tempDate).getTime();
                filter['createTime'] = {
                    type: 'timestamp',
                    value: [startDateTimeStamp, endDateTimeStamp],
                };
            }

            const filters = this.parseFilters(filter);
            if (filters && filters.length > 0) {
                this.where.filters = filters;
            } else {
                // 如果过滤条件为空，设置空数组
                this.where.filters = [];
            }
            const params = {
                pageNum: this.queryParameters.pageNum,
                pageSize: this.queryParameters.pageSize,
                where: this.where,
                order: '',
            };
            this.loading = true;
            findAllTrainingRequirements(params).then((response) => {
                this.trainingRequirements = response.data.records;
                this.total = response.data.total;
                this.loading = false;
            });
        },

        handleAdd() {
            this.addRequirement = true;
            this.showRequirementEdit = true;
            this.selectTrainingRequirement = null;
            this.title = "增加培训需求";
            this.requirement = {};
            this.refreshList = false;
        },

        handleUpdate(row) {
            this.requirement = row;
            this.showRequirementEdit = true;
            this.addRequirement = false;
            this.title = "更新培训需求";
            this.refreshList = false;
        },
        handleUpdate2() {
            let findRequirement = this.trainingRequirements.filter(item => item.id == this.ids[0]);
            this.requirement = findRequirement[0];
            this.addRequirement = false;
            this.showRequirementEdit = true;
            this.courseEditTitle = "更新培训需求";
            this.refreshList = false;
        },
        handleDelete() {
            let that = this;
            this.$confirm('确定删除选中的培训计划?', "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(function () {
                that.deleteLoading = true;
                deleteRequirement(that.ids).then(
                    (response)=>{
                        that.deleteLoading = false;
                         that.msgSuccess("删除成功");
                         that.query();
                    }
                ).catch((err) => {
                    that.deleteLoading = false;
                    that.msgError("删除失败，");
                });
            })
            // .then(() => {
            //     that.query();
            //     that.msgSuccess("删除成功");
            // })
        },
        
        handleSelectionChange(selection) {
            this.ids = selection.map(item => item.id)
            this.single = selection.length == 1
            this.multiple = selection.length >= 1;
        
        },

        close() {
            this.showRequirementEdit = false;
           if (this.refreshList) {
              this.query();
            }
            
            

        },
        parseTime(rowDateTime) {
            if (rowDateTime != null && rowDateTime.indexOf('T') > 0) {
                return rowDateTime.substr(0, 10);
            }
            else {
                return "";
            }
        },
      
        
        add(row) {
            //this.requirement.trainingHours = parseFloat(this.requirement.trainingHours);
            this.addLoading = true;
            addRequirement(this.requirement)
                .then((response) => {
                    this.addLoading = false;
                    this.refreshList  =true;
                    this.msgSuccess("增加成功。");
                    console.log('增加成功。');
                })
                .catch((err) => {
                    this.addLoading = false;
                    this.msgError("增加失败，");
                    console.log('增加失败，' + err);
                });
        },

        update(){
            this.updateLoading = true;
            updateRequirement(this.requirement)
            .then((response) => {
                this.updateLoading = false;
                this.refreshList = true;
                this.msgSuccess("更新成功。");
            })
            .catch((err) => {
                this.updateLoading = false;
                this.msgError("更新失败，");
            });
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
