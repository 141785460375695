import request from '@/utils/request'

// 查询离职申请列表
export function listCorrectTimeClock(query) {
    return request({
        url: '/bpm/correctTimeClock/findAll',
        method: 'post',
        data: query
    })
}
// 查询转正申请详细
export function initApply() {
    return request({
        url: '/bpm/correctTimeClock/initApply',
        method: 'get'
    })
}

// 修改转正申请
export function completeTask(data) {
    return request({
        url: '/bpm/correctTimeClock/completeTask',
        method: 'post',
        data: data
    })
}
// 查询离职申请详细
export function getCorrectTimeClock(id) {
    return request({
        url: '/bpm/correctTimeClock/findOne?id=' + id,
        method: 'post'
    })
}

export function getByProcessInstanceId(processInstanceId) {
    return request({
        url: '/bpm/correctTimeClock/getByProcessInstanceId?processInstanceId=' + processInstanceId,
        method: 'post'
    })
}

// 新增离职申请
export function addCorrectTimeClock(data) {
    return request({
        url: '/bpm/correctTimeClock/add',
        method: 'post',
        data: data
    })
}

// // 修改离职申请
// export function updateCorrectTimeClock(data) {
//     return request({
//         url: '/bpm/correctTimeClock/edit',
//         method: 'post',
//         data: data
//     })
// }

// 删除离职申请
export function delCorrectTimeClock(id) {
    return request({
        url: '/bpm/correctTimeClock/remove',
        method: 'post',
        data: id
    })
}

// 导出离职申请
export function exportCorrectTimeClock(query) {
    return request({
        url: '/bpm/correctTimeClock/export',
        method: 'post',
        data: query
    })
}
