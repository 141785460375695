<template>
	<el-collapse-item title="学历教育信息及鉴定" :name="this.collapseStatus" :class="collapseSetting()">
		<el-form ref="dataFrom" :model="dataFrom"  class="employ" label-width="150px">
			<el-row v-for="(eduExperienceModel, index) in dataFrom.eduExperiencesModel" :key="index" :class="newAddedAfterLocked(eduExperienceModel)">
				<el-row>
					<el-row>
						<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
							<el-form-item label="开始时间">
								<el-date-picker
									v-model="eduExperienceModel.startDate"
									value-format="yyyy-MM-dd"
									:disabled="canEdit(eduExperienceModel)"
									align="left"
									type="date"
									placeholder="选择日期"
								>
								</el-date-picker>
							</el-form-item>
						</el-col>
						<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
							<el-form-item label="结束时间"
								:prop="'eduExperiencesModel.' + index + '.endDate'"
									:rules="[
										{
											validator: (rule, value, callback) => {
												endDateValidator(rule, value, callback, eduExperienceModel.startDate);
											},
											trigger: 'change',
										}
									]"
							>
								<el-date-picker
									v-model="eduExperienceModel.endDate"
									value-format="yyyy-MM-dd"
									:disabled="canEdit(eduExperienceModel)"
									align="left"
									type="date"
									placeholder="选择日期"
								>
								</el-date-picker>
							</el-form-item>
						</el-col>
					
						<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
							<el-form-item label="院校">
								<el-input v-model="eduExperienceModel.school" :readonly="canEdit(eduExperienceModel)"></el-input>
							</el-form-item>
						</el-col>
						<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
							<el-form-item label="专业">
								<el-input v-model="eduExperienceModel.major" :readonly="canEdit(eduExperienceModel)"></el-input>
							</el-form-item>
						</el-col>
					
						<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
							<el-form-item label="学习结果">
								<el-input v-model="eduExperienceModel.studyResults" :readonly="canEdit(eduExperienceModel)"></el-input>
							</el-form-item>
						</el-col>
						<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
							<el-form-item label="证明人">
								<el-input v-model="eduExperienceModel.witness" :readonly="canEdit(eduExperienceModel)"></el-input>
							</el-form-item>
						</el-col>
					
						<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
							<el-form-item label="学历">
								<el-input v-model="eduExperienceModel.education" :readonly="canEdit(eduExperienceModel)"></el-input>
							</el-form-item>
						</el-col>
						<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
							<el-form-item label="学历证书编号">
								<el-input v-model="eduExperienceModel.edcationNo" :readonly="canEdit(eduExperienceModel)"></el-input>
							</el-form-item>
						</el-col>
					
						<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
							<el-form-item label="学位">
								<el-input v-model="eduExperienceModel.academicDegree" :readonly="canEdit(eduExperienceModel)"></el-input>
							</el-form-item>
						</el-col>
						<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
							<el-form-item label="学位证书编号">
								<el-input v-model="eduExperienceModel.academicDegreeNo" :readonly="canEdit(eduExperienceModel)"></el-input>
							</el-form-item>
						</el-col>
					
						<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
							<el-form-item label="获取时间">
								<el-date-picker
									v-model="eduExperienceModel.getDate"
									value-format="yyyy-MM-dd"
									:disabled="canEdit(eduExperienceModel)"
									align="left"
									type="date"
									placeholder="选择日期"
								>
								</el-date-picker>
							</el-form-item>
						</el-col>
						<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
							<el-form-item label="鉴定时间">
								<el-date-picker
									v-model="eduExperienceModel.confirmDate"
									value-format="yyyy-MM-dd"
									:disabled="canEdit(eduExperienceModel)"
									align="left"
									type="date"
									placeholder="选择日期"
								>
								</el-date-picker>
							</el-form-item>
						</el-col>
					
						<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
							<el-form-item label="是否通过鉴定">
								<el-checkbox v-model="eduExperienceModel.confirmed" :disabled="canEdit(eduExperienceModel)"></el-checkbox>
							</el-form-item>
						</el-col>
						<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
							<el-form-item label="备注">
								<el-input v-model="eduExperienceModel.remarks" :readonly="canEdit(eduExperienceModel)"></el-input>
							</el-form-item>
						</el-col>
						<!-- <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0" >
							<el-form-item label="">
								<div style="display:flex;">
									<div style="width:40%"> <el-button plain icon="el-icon-upload" type="info" >上传证书</el-button></div>
									<label  style="width:60%;border:1px;border-color:#DCDFE6;border-bottom-style:solid"  ></label>
								</div>
							</el-form-item>
							
						</el-col> -->
						<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
							<el-form-item label=" ">
							<el-button
								plain
								class="employ-form-delete-button"
								:id="eduExperienceModel.school"
								v-bind:disabled="canEdit(eduExperienceModel)"
								v-on:click="deleteEduExperience($event, eduExperienceModel.school)"
								type="danger"
								icon="el-icon-delete"
								>删除</el-button
							>
							</el-form-item>
						</el-col>
						<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
							<div style="margin-left:150px;margin-bottom:10px;">
								<el-upload
									:disabled="canEdit(eduExperienceModel)"
									class="avatar-uploader"
									action="uploadCertifcatePicture()"
									:show-file-list="false"
									:on-success="handleAvatarSuccess"
									:http-request="(res)=>{return uploadCertifcatePicture(res,eduExperienceModel)}"
									:auto-upload="true"
									accept=".jpg,.jpeg,.bmp,.png,application/pdf"
									:before-upload="beforeAvatarUpload">
								
									<div v-if="eduExperienceModel.certificateUrl">
										<pdf v-if="eduExperienceModel.certificateOriginalFileName!=null&&eduExperienceModel.certificateOriginalFileName.endsWith('.pdf')" :src="eduExperienceModel.certificateUrl" :page="1" class="avatar"></pdf>
										<img v-else class="avatar" :src="eduExperienceModel.certificateUrl" alt="" />
									</div>
									<i v-else class="el-icon-plus avatar-uploader-icon"></i> 
								</el-upload>
									<el-dialog :visible.sync="dialogVisible" @close="closeDialog">
										<div v-if="eduExperienceModel.certificateOriginalFileName!=null&&eduExperienceModel.certificateOriginalFileName.endsWith('.pdf')">
											<pdf :src="dialogImageUrl" :page="1"></pdf>
										</div>
										<div v-else>
											<img width="100%" :src="dialogImageUrl" alt="" />
										</div>
									</el-dialog>
								<p class="scaleFont"> 支持jpg、png、bpm、pdf格式，大小不超过50M。</p>
								<el-button plain type="primary" v-if="eduExperienceModel.certificateUrl"  size="mini" icon="el-icon-zoom-in" @click="handlePictureCardPreview(eduExperienceModel)">预览</el-button>
	
							</div>
						</el-col>
					</el-row>
					<div
						style="
							width: 80%;
							border-top: 1px solid;
							color: #ebeef5;
							height: 10px;
							margin-left: 10%;
							border-bottom-width: 1px;
							border-left-width: 1px;
							text-align: center;
						"
					></div>
				</el-row>
			</el-row>

			<el-row>
				<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
					<el-form-item label=" ">
						<el-button v-on:click="addEduExperience" type="primary" v-bind:disabled="enableAdd" plain icon="el-icon-plus">增加</el-button>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
					<el-form-item label=" ">
						<el-button v-on:click="edit" type="primary" v-bind:disabled="hasLocked?true:this.isReadOnly" plain icon="el-icon-edit">编辑</el-button>
						<el-button v-on:click="save" plain v-bind:disabled="this.isReadOnly" type="primary" :loading="saving" icon="el-icon">保存</el-button>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
	</el-collapse-item>
</template>

<script>
import { uploadCertifcatePictureApi,downloadCertificatePictureApi} from "@/api/employ/employ";
import Pdf from 'vue-pdf';
export default {
	components: {
		Pdf,
	},
	data() {
		let readonlyInit = true;
		let eduExperiencesInit = new Array();
		let initAddItem = false;
		if (this.eduExperiences == null || (this.eduExperiences != null && this.eduExperiences.length==0)) {
			eduExperiencesInit.push({
				startDate: null,
				endDate: null,
				school: '',
				major: null,
				studyResults: null,
				witness: null,
				education: null,
				edcationNo: null,
				academicDegree: null,
				academicDegreeNo: null,
				getDate: null,
				confirmDate: null,
				confirmed: null,
				certificateUrl:null,
				certificateOriginalFileName:null,
				certificateTempUrl:null,
				newAdded:this.locked?true:false,
				remarks: null,
			});

			initAddItem=true;
		} else {
			eduExperiencesInit = this.eduExperiences;
		}
		//  isReadOnly=true for view, others for edit. Users can't eidt if locked, but they can add 
		if(this.isReadOnly || this.locked)
		{
			readonlyInit = true;
		}
		else{
			readonlyInit = false;
		}
		return {
			dataFrom:{eduExperiencesModel: eduExperiencesInit},
			readonly: readonlyInit,
			enableAdd:this.enableAddButton,
			saving:false,
			hasLocked:this.locked,
			initItem:initAddItem,
			dialogImageUrl: '',
			dialogPdfUrl:'',
			dialogPdfName:'',
			dialogVisible:false,
			pickerOptions1: {
				disabledDate(time) {
					return time.getTime() > Date.now();
				},
			},
		};
	},
	props: { eduExperiences: Array, id: Number, isReadOnly: Boolean,collapseStatus:String,locked:Boolean,enableAddButton:Boolean  },
	watch: {
		isReadOnly(newValue,oldV) {
			this.readonly = newValue;
			//this.hasLocked = newValue;
		},
		locked(newValue,oldV) {
			this.hasLocked = newValue;
		},
		
	},
	created(){ 
		if (this.eduExperiences !=null && this.eduExperiences.length >0) {
				this.eduExperiences.forEach(item=>{
					if(item.certificateUrl != null && item.certificateUrl.length > 0)
					{
						this.downloadCertificatePictures(item);
					}
				});

				this.$forceUpdate()
		}
	},
	computed: {
        newAddedAfterLocked() {
            return function(currentItem){
                return {employ_item_update:this.locked&&currentItem.newAdded && !this.initItem &&!this.isReadOnly};         
            }           
        },
		collapseSetting(){
			return function(){
				if(this.dataFrom.eduExperiencesModel !=null && this.dataFrom.eduExperiencesModel.length >0)
				{
					if(this.locked)
					{
						let newAddResults = this.dataFrom.eduExperiencesModel.filter(item=>item.newAdded==true);
						if(newAddResults.length > 0 )
						{
							if(this.initItem)
							{
								return {callpse_header_unfill:true};
							}
							else{
								return {callpse_header_newAdd:true};
							}
							
						}
						else
						{
							return {callpse_header_fill:true};
						}
						
					}
					else
					{
						if(this.initItem)
						{
							return {callpse_header_unfill:true};
						}
						else{
							return {callpse_header_fill:true};
						}
					}
					
				}
				else
				{
					return {callpse_header_unfill:true};
				}
				
			}
		},
		canEdit(){
			 return function(currentItem){
				 let returnValue = true;
				 if(this.hasLocked){
 					returnValue =currentItem.newAdded&&!this.isReadOnly?false:true;
				 }
				 else{
					returnValue = this.readonly;
				 }
                return returnValue;      
            }   
		}  
    },
	methods: {
		
		save() {
			this.$refs.dataFrom.validate(valid => {
			let self = this;
			if (valid) {
				// save data to DB
				let savingEduExperiences = {};
				savingEduExperiences.id = this.id;
				savingEduExperiences.educations = new Array();
				self.dataFrom.eduExperiencesModel.forEach(item=>{savingEduExperiences.educations.push({...item})});
				savingEduExperiences.educations.forEach(item=>{item.certificateUrl=item.certificateTempUrl});
				this.saving=true;
				this.$emit('updatePartialEmployeeInfo', { data: savingEduExperiences }, (response) => {
					this.saving=false;
					if (response.success) {
						this.msgSuccess('修改成功');
						this.readonly = true;
					} else {
						this.msgError('修改失败,' + response.msg);
					}
				});
			} 
			else{
					this.msgError('有非法输入或必填项未填写，请检查!');
				}
			});
		},
		fetchCertificateName(fileUrl,originFileName)
		{
			// 取得证书上传后文件服务器中的文件名字(GUID.JPEF)
			let array= fileUrl.split('/');
			let fileName = array[array.length-1];
			let extensionName = originFileName.split('.')[1];
			return fileName+"."+extensionName;
		},
		edit() {
			this.readonly = false;
		},
		addEduExperience() {
			this.readonly = false;
			this.dataFrom.eduExperiencesModel.push({
				startDate: null,
				endDate: null,
				school: '',
				major: null,
				studyResults: null,
				witness: null,
				education: null,
				edcationNo: null,
				academicDegree: null,
				academicDegreeNo: null,
				getDate: null,
				confirmDate: null,
				confirmed: null,
				certificateUrl:null,
				certificateOriginalFileName:null,
				certificateTempUrl:null,
				newAdded:true,
				remarks: null,
			});
		},
		deleteEduExperience(event, school) {
			let self = this;
			if (event.currentTarget.id == school) {
				this.$confirm('确定要删除吗?', '确认', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'error',
					// type: 'warning'
				})
					.then(() => {
						if (self.dataFrom.eduExperiencesModel.length > 0) {
							let foundIndex = self.dataFrom.eduExperiencesModel.findIndex((x) => x.school === school);
							if (foundIndex > -1) {
								self.dataFrom.eduExperiencesModel.splice(foundIndex, 1);
							}
						}
					})
					.catch(() => {
						this.msgError('删除发生异常!');
					});
			}
		},
		endDateValidator(rule, value, callback, startDate) {
			// 必需每个分支都要有返回，否则进不去保存的validate方法。
            if(startDate==null || startDate =="")
			{
				return callback(new Error("先选起始日期。"));
			}
			else
			{
				if(value==null || value == ""){
					return callback(new Error("有起始日期，终止日期不能为空。"));
				}
				else{
					if (value < startDate) {
						return callback(new Error("终止日期不能小于起始日期。"));
					}
					else
					{
						return callback();
					}
				}
			}
        },
		

		uploadCertifcatePicture(params,eduExperienceModel)
		{
			if(params != undefined)
			{
				this.formData = new FormData();
				this.formData.append('file', params.file);
				eduExperienceModel.certificateOriginalFileName = params.file.name;
				let self = this;
				uploadCertifcatePictureApi(this.formData)
					.then((response) => {
						if (response.status == 200) {
							let resData = response.data;
							eduExperienceModel.certificateTempUrl= response.headers["content-disposition"].split('=')[1];
							var blob = new Blob([resData], { type: 'application/octet-stream' });
							eduExperienceModel.certificateUrl =URL.createObjectURL(blob);
							this.$forceUpdate()
							this.$message.success('上传成功!');
						}
						else{
							this.$message.error('上传失败：');
						}
					})
					.catch((err) => {
						this.$message.error('上传失败：'+err);

					});
			}
		},

		handleAvatarSuccess(res, file) {
			//this.form.photo = URL.createObjectURL(file.name);
		},
		beforeAvatarUpload(file) {
			const isJPG = file.type === 'image/jpeg' || file.type === 'image/jpg';
			const isPng = file.type === 'image/png';
			const isBmp = file.type === 'image/bmp';
			const ispdf = file.type === 'application/pdf';
			const isLt50M = file.size / 1024 / 1024 < 50;
			if (!isJPG && !isPng && !isBmp && !ispdf) {
				this.$message.error('上传头像图片只能是 JPG,jpeg,png,bmp,pdf 格式!');
			}
			if (!isLt50M) {
					this.$message.error('上传图片大小不能超过 50MB!');
			}

			return (isJPG || isPng || isBmp || ispdf) && isLt50M;
		},
		downloadCertificatePictures(item)
		{
			if(item.certificateUrl!=null && item.certificateUrl.length > 0)
			{
				let that = this;
				// download 
				downloadCertificatePictureApi(item.certificateUrl)
					.then((res) => {
						if (res) {
							let resData = res.data;
							var blob = new Blob([resData], { type: 'application/octet-stream' });
							item.certificateTempUrl =item.certificateUrl;
							item.certificateUrl =  URL.createObjectURL(blob);
							that.$forceUpdate();
						}
						else
						{
							that.msgError(`后端下载文件出错 & ${e}`);
						}
					})
					.catch((e) => {
						that.loading = false;
						that.msgError(`后端下载文件出错 & ${e}`);
					});
			}
		},
			handlePictureCardPreview(dataModel) {
				// if(dataModel.certificateTempUrl.endsWith('.pdf'))
				// {
				// 	this.dialogPdfUrl = dataModel.certificateUrl;
				// 	this.dialogPdfName = dataModel.certificateTempUrl;
				// }
				// else
				// {
					this.dialogImageUrl = dataModel.certificateUrl;
				// }
			
				this.dialogVisible = true;
		},
		closeDialog() {
			this.dialogImageUrl = '';
			this.dialogVisible = false;
		},
	},
};
</script>

<style>
</style>