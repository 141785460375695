import request from '@/utils/request'

// Find all teachers
export function findTeachers(query) {
    return request({
        url: '/TrainingTeacher/findAll',
        method: 'post',
        data: query
    })
}

// 新增讲师信息
export function addTrainingTeacher(data) {
    return request({
        url: '/TrainingTeacher/add',
        method: 'post',
        data: data
    })
}

// 新增讲师信息
export function updateTrainingTeacher(data) {
    return request({
        url: '/TrainingTeacher/edit',
        method: 'post',
        data: data
    })
}

// 删除讲师信息
export function deleteTeachers(ids) {
    return request({
        url: '/TrainingTeacher/remove',
        method: 'post',
        data: ids
    })
}

// 上传讲师相片
export function UploadTeacherPicture(data) {
    return request({
        url: '/TrainingTeacher/UploadTeacherPicture',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}