<template>
<!--  总工资 - 基础扣除项 + 基本工资 = 浮动奖金 -->
<!--  工资分为 总工资 和 额外工资(包含临时工资, 节日补贴)-->
<!--  设置总工资, 基本工资(最低工资), 餐补,交通补贴,通讯补贴 ,  浮动奖金.  临时工资(不计入总工资浮动奖金计算)  -->
<!--  工资类型： 工资(计入)，浮动值（工资总计算减去项目后的奖金），额外工资(不计入, 事假), -->
<!--  工资增减项: 增项, 减项-->
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px">
      <el-form-item label="公司名称" prop="companyId">
        <treeselect v-model="queryParams.companyId" style="width: 100%"
                    :options="companyOptions"
                    :normalizer="normalizerCompany" placeholder="请选择所属公司"/>
      </el-form-item>
      <el-form-item label="账套名称" prop="name">
        <el-input
            v-model="queryParams.name"
            placeholder="请输入账套名称"
            clearable
            size="large"
            @keyup.enter.native="handleQuery"
        />
      </el-form-item>
<!--      <el-form-item label="类型" prop="type">-->
<!--        <el-select v-model="queryParams.type" placeholder="请选择模板类型： 社保，公积金" clearable-->
<!--                   size="small">-->
<!--          <el-option v-for="item in getDictDatas(DICT_TYPE.CB_TEMPLATE_TYPE)" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled"/>-->
<!--        </el-select>-->
<!--      </el-form-item>-->
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button type="primary" plain icon="el-icon-plus" size="mini"
                   @click="handleAdd" v-permission="['/cb/cbTemplate/add']" >新增
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button type="success" plain icon="el-icon-edit" size="mini"
            :disabled="single" @click="handleUpdate" v-permission="['/cb/cbTemplate/edit']"
        >修改
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button type="danger" plain icon="el-icon-delete" size="mini"
            :disabled="multiple" @click="handleDelete" v-permission="['/cb/cbTemplate/remove']"
        >删除
        </el-button>
      </el-col>
      <el-col :span="1.5">
<!--        <el-button type="danger" plain icon="el-icon-question" size="mini"-->
<!--                   :disabled="multiple" @click="handleDelete" v-permission="['/cb/cbTemplate/remove']"-->
<!--        >帮助-->
<!--        </el-button>-->
        <el-popover placement="top-start"
                    title="工资计算规则及说明"
                    width="350" trigger="click">
          <p>支付总工资=总工资(合同工资)+其他项(社保公积金，过节费，水电代扣等工资项)<br>
            总工资(合同工资)=工资(项目和)+浮动值(奖金等,只能有一个浮动值)<br>
            所以也可以是：支付总工资=工资(项目和)+浮动值(奖金等,只能有一个浮动值)+其他项(社保公积金，过节费，水电代扣等工资项)</p><br>
          <p>工资项目类型: <br>&nbsp;&nbsp;&nbsp;&nbsp;
            "工资"是指一般工资项目, 比如基本工资, 社保个人所交部分等这些固定值和系统计算的考勤扣除金额等. 考勤扣除项目不需要添加，系统自动计算 <br>&nbsp;&nbsp;&nbsp;&nbsp;
            "浮动值"是指奖金项目, 它有且只能有一项, 它的值是由每位员工设置了总工资后, 由总工资减去所有工资项目和所得.<br>&nbsp;&nbsp;&nbsp;&nbsp;
            "其他项"是不参与总工资计算浮动值的部分. 比如社保公积金，过节费，水电代扣等工资项</p><br>
          <p>增减项: 是指此工资项目的运算是加还是减</p><br>
          <p>值类型: "设置值"设置固定工资金额; "系统计算值"由系统计算得到,比如浮动工资奖金; "引用值"则是来源其他地方的设置,比如社保公积金等.</p><br>
          <el-button type="primary" plain icon="el-icon-question" size="mini"
                     slot="reference"
          >帮助
          </el-button>
        </el-popover>
      </el-col>
      <!--      <el-col :span="1.5">-->
      <!--        <el-button-->
      <!--            type="warning"-->
      <!--            plain-->
      <!--            icon="el-icon-download"-->
      <!--            size="mini"-->
      <!--            @click="handleExport"-->
      <!--            v-permission="['/cb/cbTemplate/export']"-->
      <!--        >导出-->
      <!--        </el-button>-->
      <!--      </el-col>-->
      <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>
    </el-row>

    <el-table v-loading="loading" :data="cbSalaryTemplateList" @selection-change="handleSelectionChange" stripe border>
      <el-table-column type="selection" width="55" align="center"/>
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-table :data="props.row.itemInfo" show-summary :summary-method="getSummaries" stripe border>
            <el-table-column label="项目名" align="center" prop="itemName"/>
            <el-table-column label="类型" align="center" prop="itemType"/>
            <el-table-column label="增减项" align="center" prop="plusMinus"/>
            <el-table-column label="值类型" align="center" prop="valueType"/>
            <el-table-column label="考勤比例发放" align="center" prop="isAttend">
              <template slot-scope="scope">
                {{scope.row.isAttend?"是":"否"}}
              </template>
            </el-table-column>
            <el-table-column label="金额" align="center" prop="amount"/>
          </el-table>
        </template>
      </el-table-column>
      <el-table-column label="所属公司" align="center" prop="companyId" show-overflow-tooltip width="210" sortable>
        <template slot-scope="scope">
          {{companyFormat(scope.row, null)}}
        </template>
      </el-table-column>
      <el-table-column label="账套名称" align="center" prop="name"/>
<!--      <el-table-column label="类型" align="center" prop="type"/>-->
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button size="mini" type="text" icon="el-icon-document-copy"
                     @click="handleCopy(scope.row)" v-permission="['/cb/cbTemplate/add']"
          >复制
          </el-button>
          <el-button size="mini" type="text" icon="el-icon-edit"
              @click="handleUpdate(scope.row)" v-permission="['/cb/cbTemplate/edit']"
          >修改
          </el-button>
          <el-button size="mini" type="text" icon="el-icon-delete"
              @click="handleDelete(scope.row)" v-permission="['/cb/cbTemplate/remove']"
          >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
        v-show="total>0"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
    />

    <!-- 添加或修改薪酬模板对话框 -->
    <el-dialog :title="title" width="95%" :visible.sync="open" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-row :gutter="35">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="6">
            <el-form-item label="账套名称" prop="name">
              <el-input v-model="form.name" placeholder="请输入账套名称" clearable/>
            </el-form-item>
          </el-col>
<!--          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">-->
<!--            <el-form-item label="模板类型" prop="type">-->
<!--              <el-select v-model="form.type" placeholder="请选择模板类型">-->
<!--                <el-option v-for="item in getDictDatas(DICT_TYPE.CB_TEMPLATE_TYPE)" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled"/>-->
<!--              </el-select>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="6" >
            <el-form-item label="公司" prop="companyId">
              <treeselect :disabled="form.id>0" v-model="form.companyId" :options="companyOptions"
                          :normalizer="normalizerCompany" placeholder="请选择公司"/>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" >
            <el-form-item id="employIds" label="适用人员">
<!--              <el-button size="mini" type="primary" @click="selectReceivers">选择接收人</el-button>-->
<!--              <el-tag-->
<!--                  v-for="tag in form.empIds"-->
<!--                  :key="tag.name"-->
<!--                  closable-->
<!--                  size="mini">-->
<!--                {{tag.name}}-->
<!--              </el-tag>-->
              <treeselect v-model="form.empIds" :options="employTree"
                          :multiple="true" :flat="true" :disable-branch-nodes="true" noChildrenText="没有员工"
                          :normalizer="normalizeNode" placeholder="请选择考勤管理员"/>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" >
            <el-form-item label="描述" prop="description">
              <el-input v-model="form.description" type="textarea" :autosize="{ minRows: 3, maxRows: 10 }" maxlength="500"
							show-word-limit placeholder="请输入描述"/>
            </el-form-item>
          </el-col>
        </el-row>
        <SalaryEdit :salaryInfoIn="form" :editable="true" :companyIdIn="form.companyId"></SalaryEdit>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button type="primary" @click="submitForm" v-permission="['/cb/cbTemplate/add', '/cb/cbTemplate/edit']">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>

</template>

<script>
import {
  listCbSalaryTemplate,
  getCbSalaryTemplate,
  delCbSalaryTemplate,
  addCbSalaryTemplate,
  updateCbSalaryTemplate,
  exportCbSalaryTemplate } from "@/api/cb/cbSalaryTemplate";
import {listCbTemplate } from "@/api/cb/cbTemplate";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import RightToolbar from "@/components/RightToolbar"
import permission from '@/directive/permission/index.js' // 权限判断指令
import Pagination from "@/components/Pagination";
import { showLoading, hideLoading } from '@/utils/reqloading';
import {DICT_TYPE} from "@/utils/dict";
import SalaryEdit from "@/views/pages/cb/components/salaryEdit";
import { getOrgEmpTree, normalizerEmployMultiSelectTree} from '@/views/pages/employ/components/empOrgTree';
import {sortBy} from "@/utils/tools";
import {Local} from '@/utils/storage.js'

// 表查询配置
const dbOrderBy = ''
export default {
  name: "SalaryTemplete",
  components: {
    Treeselect,
    RightToolbar,
    Pagination,
    SalaryEdit,
  },
  directives: { permission },
  props:{
    // 公司树选项
    companyList: Array,
    companyOptions: Array,
  },
  watch:{
    "form.companyId":{
      immediate: true,
      handler: function (n, o) {
        console.log("companyId change to n=" + n + ",o=" + o);
        if (n) {
          showLoading();
          console.log(n)
          getOrgEmpTree(n, true, (tree, companyList, departmentList, employList) => {
            this.employTree = tree;
            console.log('this.employTree');
            console.log(this.employTree);
            this.departmentList = departmentList;
            this.departmentOptions = this.handleTree(departmentList, "id", "pid");

            this.employList = employList;
            hideLoading();
          });
        }
      }
    },
  },
  data() {
    return {
      // 遮罩层
      loading: true,
      uniApp:false,
      // 查询参数
      where: {
        query: '',
        filters: []
      },

      // activeNames: ['attInfo','salaryInfo'],
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 薪酬模板表格数据
      cbSalaryTemplateList: [],
      cbTemplateList: [],
      insuranceList: [],
      fundList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        orderby: dbOrderBy,
        companyId: null,
        name: null,
        type: null,
      },
      // 表单参数
      form: {
        companyId: null,
        insuranceId: null,
        fundId: null,
        itemInfo:[],
        attInfo:{attItems:[]},
        empIds: null,
      },
      employTree: [],

      attItems: DICT_TYPE.ATT_ITEMS,
      // 表单校验
      rules: {
        companyId: [
          {
            required: true,
            message: "公司不能为空", trigger: "blur" }
        ],
        name: [
          {
            required: true,
            message: "账套名称不能为空", trigger: "blur" }
        ],
        type: [
          {
            required: true,
            message: "模板类型不能为空", trigger: "change"
          }
        ],
      }
    };
  },
  created() {
    this._filter = {};
    this.uniApp=Local.get("isUniapp")?true:false;

    // this.getTreeselectCompany();
    this.getList();
  },
  methods: {
    getSummaries(param) {
      const { columns, data } = param;
      // console.log("columns=")
      // console.log(columns)
      // console.log("data=")
      // console.log(data)
      const sums = [];
      sums[0] = '总工资（不包括浮动项）';
      let extVal = 0;
      let val = 0;
      data.map(item=>{
        console.log(item);
        const value = Number(item.amount);
        if (!isNaN(value)) {
          if(item.plusMinus == '增项'){
            val += value;
          }else {
            val -= value;
          }
          if(DICT_TYPE.CB_TEMPLATE_SALARY_TYPE_EXTRA == item.itemType){
            if (item.plusMinus == '增项') {
              extVal += value;
            } else {
              extVal -= value;
            }
          }
        }
      });
      sums[5] = "合计："+val;//+"(其中包含"+extVal+DICT_TYPE.CB_TEMPLATE_SALARY_TYPE_EXTRA+")";
      sums[4] = "包含"+extVal+DICT_TYPE.CB_TEMPLATE_SALARY_TYPE_EXTRA+"";
      // sums[4] = extVal+"("+DICT_TYPE.CB_TEMPLATE_SALARY_TYPE_EXTRA+")";
      return sums;
    },
    /** 查询薪酬模板列表 */
    getQuery(){
      this._filter = {};
      if(this.queryParams.companyId) {
        this._filter['companyId'] = {
          type: 'eq',
          value: this.queryParams.companyId,
        }
      }
      if(this.queryParams.name) {
        this._filter['name'] = {
          type: 'search',
          value: this.queryParams.name,
        }
      }
      if(this.queryParams.type) {
        this._filter['type'] = {
          type: 'eq',
          value: this.queryParams.type,
        }
      }
      if(this.queryParams.description) {
        this._filter['description'] = {
          type: 'search',
          value: this.queryParams.description,
        }
      }
      const filters = this.parseFilters(this._filter)
      if (filters && filters.length > 0) {
        this.where.filters = filters
      } else {
        // 如果过滤条件为空，设置空数组
        this.where.filters = []
      }
      const params = {
        pageNum: this.queryParams.pageNum,
        pageSize: this.queryParams.pageSize,
        where: this.where,
        order: this.orderby
      }

      return params;
    },
    getList() {
      this.loading = true;
      const params = this.getQuery();
      listCbSalaryTemplate(params).then(response => {
        this.cbSalaryTemplateList = response.data.records;
        this.total = response.data.total;
        this.loading = false;
      });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {};
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加薪酬模板";
      this.$set(this.form, "attInfo", {attItems: this.attItems});
      this.$set(this.form,"companyId", this.$store.getters.userInfo.companyId);
      // this.form.id = null;
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.id || this.ids
      getCbSalaryTemplate(id).then(response => {
        this.form = response.data;
        if(!this.form.attInfo){
          this.form.attInfo = {attItems: this.attItems};
        }
        this.open = true;
        this.title = "修改薪酬模板";
      })
      ;
    },
    handleCopy(row) {
      this.reset();
      const id = row.id || this.ids
      getCbSalaryTemplate(id).then(response => {
        this.form = response.data;
        this.form.id = null;
        this.form.name = this.form.name+"-复制"
        this.form.empIds = null;
        if(!this.form.attInfo){
          this.form.attInfo = {attItems: this.attItems};
        }
        this.open = true;
        this.title = "复制薪酬模板";
      });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate(valid => {
            if(valid) {
              let itemInfo = [];
              let floatNum = 0;
              if(!this.form.itemInfo || this.form.itemInfo.length <= 0){
                this.msgError("薪资项目未设置，请在薪资构成中设置项目。");
                return;
              }
              this.form.itemInfo.forEach(item=>{
                if(item.itemType == DICT_TYPE.CB_TEMPLATE_SALARY_TYPE_FLOAT){
                  floatNum++;
                }
                console.log("item.orderNum="+item.orderNum);
                itemInfo.push({itemName:item.itemName,itemType:item.itemType,plusMinus:item.plusMinus
                  ,valueType:item.valueType,isAttend:item.isAttend,isTax:item.isTax,refId:item.refId
                  ,amount:item.amount,orderNum:item.orderNum,showToEmp:item.showToEmp, refType:item.refType});
              });
              if(floatNum > 1){
                this.$alert('只能添加一个“浮动值”项。现在有'+floatNum+'项“浮动值”', '错误', {
                  confirmButtonText: '确定',
                });
                return;
              }
              console.log(this.form);
              this.form.itemInfo = itemInfo.sort(sortBy('orderNum',true));
              this.form.companyCode = this.getCompanyCode(this.form.companyId);
              console.log(this.form);
              showLoading();
              if (this.form.id != null) {
                updateCbSalaryTemplate(this.form).then(response => {
                  this.msgSuccess("修改成功");
                  hideLoading();
                  this.open = false;
                  this.getList();
                });
              } else {
                addCbSalaryTemplate(this.form).then(response => {
                  this.msgSuccess("新增成功");
                  hideLoading();
                  this.open = false;
                  this.getList();
                });
              }
            }
          }
      );
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      let ids = [];
      if(row.id){
        ids = [row.id];
      }else{
        ids = this.ids;
      }

      this.$confirm('是否确认删除选中的薪酬模板数据?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        showLoading();
        return delCbSalaryTemplate(ids);
      }).then(() => {
        hideLoading();
        this.getList();
        this.msgSuccess("删除成功");
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      const params = this.getQuery();
      this.$confirm('是否确认导出所有薪酬模板数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return exportCbSalaryTemplate(params);
      }).then(response => {
        this.download(response.msg);
      })
    },
    /** 转换公司数据结构 */
    normalizerCompany(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.id,
        label: node.companyName,
        children: node.children
      };
    },
    companyFormat(row, column) {
      // console.log(row);
      for(var i = 0; i < this.companyList.length; i++){
        const item =  this.companyList[i];
        if(item.id == row.companyId){
          return item.companyName;
        }
      }
      return "";
    },
    getCompanyCode(companyId){
      // console.log(companyId)
      // console.log(this.companyList)
      for(var i = 0; i < this.companyList.length; i++){
        const item =  this.companyList[i];
        if(item.id == companyId){
          return item.code;
        }
      }
    },
    normalizeNode(node){
      return normalizerEmployMultiSelectTree(node,"id");
    },
    salarySetting(){
      return function(){
        if(this.form.id)
        {
          return {callpse_header_fill:true};
        }
        else{
          return {callpse_header_unfill:true};
        }
      }
    },


  }
};
</script>

<style>
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 88px;
  text-align: right;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  /*width: 30%;*/
}
</style>
<style lang="less" scoped>
 #employIds{
   display: block  !important;
 }
</style>
