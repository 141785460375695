<template>
	<div>
		<div style="font-size:large;">批量增加员工</div>
		<br />
		<div style="color:red;"><span style="font-weight: 600;">上传模板文件注意事项:</span><br/>
		1） 登录用户名系统自动取，只要填充一个任一值都可，如自动取名。
		2）密码可取一样的。
		3）员工角色，部门，岗位名称要都是当前用户所在公司已经存在的。<br/>
		4）已婚，健康状况，员工状态，员工类型列只能取下拉框中的值。
		5）身份证号，手机号码都是唯一的，不可重复。
		6）此为模板，不可删除列和首行。
		</div>
		<br/>
		<br/>
		<br/>
		<div>
			<!-- "image/png,image/jpg,image/jpeg,image/gif" -->
			<el-upload
				class="upload-demo"
				ref="upload"
				action=" "
				:on-preview="handlePreview"
				:on-remove="handleRemove"
				:file-list="fileList"
				:before-upload="beforeUpload"
				:http-request="uploadFile"
				:auto-upload="false"
				accept=".xls,.xlsx"
				:limit="1"
				:on-change="handleChange"
				:multiple="true"
				:show-file-list="true"
				:on-exceed="handleExceed"
			>
				<el-button slot="trigger" size="small" type="primary">选取文件</el-button>
				<el-button style="margin-left: 10px" size="small" :loading="loading" type="success" @click="submitUpload">上传</el-button>
				<el-button type="primary" size="small"  @click="employeeTemplateDownload">下载模板</el-button>
				<div slot="tip" class="el-upload__tip">只能上传Excel文件，且不超过5Mb</div>

			</el-upload>
			<el-divider content-position="left">错误信息</el-divider>
			
			<div style="color:red;border-width:thin;border-style:ridge;width:100%;height:400px;">
				<span v-html="message"></span>
			</div>
		</div>
	</div>
</template>	
<script>
import { uploadBatchEmployees } from '@/api/employ/employ';
export default {
	data() {
		return {
			fileList: [],
			formData: null,
			message:"",
			loading:false
		};
	},
	methods: {
		uploadFile(params) {
			console.debug(params);
			this.formData.append('file', params.file);
		},
		cancel() {
			this.$emit('closedialog', { refresh: false, isSuccess: true });
		},
		handleExceed(files, fileList) {
			this.msgError(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件.`);
		},
		handleRemove() {},
		handleChange(file, fileList) {
			//this.currentContract.contractPictures = fileList;
			// 非自动上传，before-upload 不起作用，改到这里验证。
			let isValidFile = this.beforeUpload(file);
			if (!isValidFile) {
				this.$refs.upload.handleRemove(file);
			}
		},
		beforeUpload(file) {
			var fileType = file.name.substring(file.name.lastIndexOf('.') + 1);
			const excel = fileType === 'xls' || fileType === 'xlsx';
			const isLt2M = file.size / 1024 / 1024 < 5;
			const isEmpty = file.size === 0;
			if (!excel) {
				this.msgError('上传文件只能是png,jpeg格式!');
			}
			if (!isLt2M) {
				this.msgError('上传文件大小不能超过 5MB!');
			}
			if (isEmpty) {
				this.msgError('上传文件大小不能为空文件!');
			}
			return excel && isLt2M && !isEmpty;
		
		},
		handlePreview() {},
		submitUpload() {
			let self = this;
			self.formData = new FormData();
			self.formData.append('createdUserName', self.$store.state.user.userInfo.username);
			self.formData.append('companyId', self.$store.state.user.userInfo.companyId);

			//uploadFile会逐个加入到formData
			self.$refs.upload.submit();

			if (self.formData.getAll("file").length === 1) {
				// 后台接口

				self.loading = true;
				uploadBatchEmployees(self.formData)
					.then((response) => {
						self.loading = false;
						if (response.success) {
							this.fileList=[];
							self.message=response.msg+response.data;
							self.msgSuccess('批量上传员工成功');
						} else {
							self.message=response.msg;
							this.msgError("上传失败！");
						}
					})
					.catch((err) => {
						self.loading = false;
						self.message=err;
						self.msgError(err);
					});
			} else {
				self.msgError('请每次添加1个上传的文件！');
			}
		},
		employeeTemplateDownload() {
			var a = document.createElement("a"); //创建一个<a></a>标签
			a.href = "/static/employeeTemplate.xlsx"; // 给a标签的href属性值加上地址，注意，这里是绝对路径，不用加 点.
			a.download = "员工上传模板.xlsx"; //设置下载文件文件名，这里加上.xlsx指定文件类型，pdf文件就指定.fpd即可
			a.style.display = "none"; // 障眼法藏起来a标签
			document.body.appendChild(a); // 将a标签追加到文档对象中
			a.click(); // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
			a.remove(); // 一次性的，用完就删除a标签
		}
	},
};
</script>

<style>

</style>