<template>
	<div>
		<el-form :model="queryParameters" ref="queryParameters" class="employ" label-width="100px">
			<el-row :gutter="24">
				<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
					<el-form-item label="公司"> 
						<el-select v-model="companySelect" clearable @clear="handleClearable" placeholder="请选择" collapse-tags ref="companySelected" >
							<el-option :value="companyId" :label="companySelect" style="height: auto">
								<el-tree
									:data="companyOptions"
									:props="defaultProps"
									:expand-on-click-node="false"
									ref="tree"
									show-checkbox
									node-key="id"
									default-expand-all
									@node-click="handleNodeClick"
									@check="handleNodeClick"
									highlight-current
								/>
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
					<el-form-item label="起始时间">
						<el-date-picker
							v-model="queryParameters.startDate"
							value-format="yyyy-MM-dd"
							align="left"
							type="date"
							placeholder="选择日期"
							style="width:100%"
						>
						</el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
					<el-form-item label="终止时间">
						<el-date-picker
							v-model="queryParameters.endDate"
							value-format="yyyy-MM-dd"
							align="left"
							type="date"
							placeholder="选择日期"
							style="width:100%"
						>
						</el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" >
					<el-form-item label="课程名称">
						<el-input v-model="queryParameters.courseName" placeholder="请输入课程名称" clearable />
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
					<el-form-item label="分类">
						<el-select v-model="queryParameters.courseType" placeholder="请选择课程分类" clearable style="width:100%">
							<el-option v-for="item in courseTypes" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue" />
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
					<el-form-item label=" " style="margin-bottom:10px !important;">
						<el-button v-on:click="query" icon="el-icon" plain type="primary">查询</el-button>
					</el-form-item>
				</el-col>
			</el-row>
			
		</el-form>
		<el-row :gutter="10" class="mb8">
			<el-col :span="1.5">
				<el-button type="primary" plain icon="el-icon-plus" size="mini" @click="handleAdd" class="mt5">新增 </el-button>
			</el-col>
			<el-col :span="1.5">
				<el-button
					type="success"
					plain
					icon="el-icon-edit"
					size="mini"
					:disabled="!single"
					@click="handleUpdate"
					class="mt5"
					>修改
				</el-button>
			</el-col>
			<el-col :span="1.5">
				<el-button
					type="danger"
					plain
					icon="el-icon-delete"
					size="mini"
					:disabled="!multiple"
					@click="handleDelete"
					class="mt5"
					>删除
				</el-button>
			</el-col>
			<el-col :span="1.5">
				<el-button type="success" plain icon="el-icon-edit" size="mini" @click="handleTrainingCourseType" class="mt5"
					>课程分类维护
				</el-button>
			</el-col>
		</el-row>
		<el-table v-loading="loading" :data="courses"  style="width:100%" @selection-change="handleSelectionChange" stripe border>
			<el-table-column type="selection" width="55" align="center" />
			<el-table-column label="编号ID" align="center" prop="id" v-if="false" />
			<el-table-column label="公司" align="center" prop="companyName" sortable :show-overflow-tooltip='true' />
			<el-table-column label="课程名称" align="center" prop="courseName" sortable :show-overflow-tooltip='true'></el-table-column>
			<el-table-column label="测试名称" align="center" prop="testPaperName" sortable :show-overflow-tooltip='true'>
			</el-table-column>
			<el-table-column label="课程分类" align="center" prop="courseType" sortable >
				<template slot-scope="scope">
              	{{courseTypeFormat(scope.row, null)}}
            	</template>
			</el-table-column>
			<el-table-column label="文件类型" align="center" prop="fileType" sortable>
				<template slot-scope="scope">
              	{{courseFileTypeFormat(scope.row, null)}}
           		</template>
			</el-table-column>
			<el-table-column label="时长(时)" align="center" prop="studyHours" min-width="60" />
			<el-table-column label="大小(MB)" align="center" prop="fileSize" />
			<el-table-column label="访问类型" align="center" prop="visitType" sortable >
				<template slot-scope="scope">
              	{{courseVisitTypeFormat(scope.row, null)}}
           		</template>
			</el-table-column>
			<el-table-column label="描述" align="center" prop="description" :show-overflow-tooltip='true'/>
			<el-table-column label="创建者" align="center" prop="creator" :show-overflow-tooltip='true' />
			<el-table-column label="创建日期" align="center" prop="createTime">
				<template slot-scope="scope">
					<span>{{ parseTime(scope.row.createTime) }}</span>
				</template>
			</el-table-column>
			<!-- <el-table-column label="操作" align="center" prop="remark">
				<template slot-scope="scope">
					<el-button size="mini" type="text" icon="el-icon-edit" @click="handleUpdate(scope.row)" v-permission="['/employ/employ/edit']"> </el-button>
					<el-button size="mini" type="text" icon="el-icon-delete" @click="handleDelete(scope.row)" v-permission="['/employ/employ/remove']">
					</el-button>
				</template>
			</el-table-column> -->
		</el-table>
		<pagination v-show="total > 0" :total="total" :page.sync="queryParameters.pageNum" :limit.sync="queryParameters.pageSize" @pagination="query" />
		 <el-dialog :title="courseEditTitle" :visible.sync='showCourseEdit' width="uniApp?'95%':'65%'" appendhandleUpdate-to-body >
        	<course-edit @close="closeCourseEdit" :currentCourse="selectCourse" :currentCompanyId="companyId" 
			:currentCompanyName="companySelect" :isNewCourse='newCourse' :dictCourseTypes="courseTypes"   :companyList="companyList"
			:dictCourseFileTypes="courseFileTypes" :courseVisitTypes="courseAccessTypes" :courseEditcompanyOptions="companyOptions" :testPapers="testPapers"
			v-if="showCourseEdit" ></course-edit>
    	</el-dialog>
		 <el-dialog title="课程分类维护" :visible.sync='showCourseType' width="uniApp?'80%':30%" appendhandleUpdate-to-body >
        	<course-type v-if="showCourseType"  @close="closeCourseType" ></course-type>
    	</el-dialog>
		
	</div>
</template>

<script>
import Pagination from "@/components/Pagination";
import { findAllCourses,deleteCourses } from '@/api/training/trainingCourse';
import permission from '@/directive/permission/index.js' // 权限判断指令
import {getTreeselectCompany} from "@/api/org/company";
import CourseEdit from './courseEdit.vue';
import CourseType from './courseType.vue';
import {Local} from '@/utils/storage.js'
import { findAllPaperNames } from "@/api/training/trainingTestPaper"

export default {
	components: {
		Pagination,
		CourseEdit,
		CourseType
	},
	directives: { permission },
	data() {
		return {
			queryParameters: {
				startDate: '',
				endDate: '',
				courseName: '',
				courseType: '',
				companyId:'',
				pageSize: 10,
				pageNum: 1,
			},
			ids: [],
			single: false,
			multiple: false,
			courses: [],
			courseTypes: [],
			courseFileTypes:[],
			courseAccessTypes:[],
			companySelect: '',
			companyId: '',
			isCompnayVisible: false,
			// 公司树选项
			companyOptions: [],
			companyList:[],
			defaultProps: {
				children: 'children',
				label: 'companyName',
			},
			total:0,
			courseEditTitle:"",
			showCourseEdit:false,
			selectCourse:null,
			newCourse:false,
			showCourseType:false,
			pickerOptions1: {
				disabledDate(time) {
					return time.getTime() > Date.now();
				},
			},
			where: {
				query: '',
				filters: [],
			},
			uniApp:false,

			testPapers:[],
		};
	},
	created() {
		if (this.$store.getters.userInfo.constParentCompanyId == this.$store.getters.userInfo.companyId) {	
					this.isCompnayVisible = true;
		}
		getTreeselectCompany(null, (list, options) => {
			this.companyList = list;
			if (this.companyList.length > 1) {
				this.isCompnayVisible = true;
			}
			this.companyOptions = options;
		});
		this.load();
		 this.uniApp=Local.get("isUniapp")?true:false;
	},
	methods: {
		    // handleSelectionChange(selection) {
			// 	this.ids = selection.map(item => item.id)
			// 	this.single = selection.length !== 1
			// 	this.multiple = !selection.length
			// },
		handleUpdate(row) {
			this.selectCourse = row;
			this.showCourseEdit = true;
			this.courseEditTitle="更新课程";
			this.newCourse=false;
		},
		handleAdd(){
			this.showCourseEdit = true;
			this.selectCourse = null;
			this.courseEditTitle="增加课程";
			this.newCourse = true;
		},
		handleDelete() {
			let that = this;
			this.$confirm('确定删除选中的课程?', "警告", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			}).then(function () {
				return deleteCourses(that.ids);
			}).then(() => {
				that.query();
				that.msgSuccess("删除成功");
			})
		},
		handleTrainingCourseType() {
			this.showCourseType=true;
		},
		closeCourseType(){
			this.showCourseType=false;
		},
		// 节点单击事件
		handleNodeClick(data) {

			this.queryParameters.companyId = data.id;
			this.companyId = data.id;
			this.companySelect = data.companyName;
			let selectArray = [];
			selectArray.push(this.companyId);
			this.$refs.tree.setCheckedKeys(selectArray);
			this.$refs.companySelected.blur();
		},
		
		handleClearable()
		{
				this.$refs.tree.setCheckedKeys([]);
				this.companyId=null;
				this.companySelect='';
		},
		load() {
			let companyId = this.$store.getters.userInfo.companyId;
			//this.getDepartments(companyId);
			//this.getCompanys();

			this.getDicts('trainingCourse_type').then((response) => {
				this.courseTypes = response.data;
			});

			this.getDicts('trainingCourseFile_type').then((response) => {
				this.courseFileTypes = response.data;
			});

			this.getDicts('trainingCourseAccess_type').then((response) => {
				this.courseAccessTypes = response.data;
			});

			const params = {
				// pageNum: this.queryParameters.pageNum,
				// pageSize: this.queryParameters.pageSize,
				// where: this.where,
				// order: '',
			};

			findAllPaperNames(params)
			.then((response) => {
				this.testPapers = response.data;
			})
			.catch((err) => {
				this.msgError('查询考试试卷失败，' + err);
			});
			
		
			this.query();
		},
		query() {
			let filter = {};
			if (this.companyId) {
				filter['companyId'] = {
					type: 'eq',
					value: this.companyId,
				};
			}
			if (this.queryParameters.courseName) {
				filter['courseName'] = {
					type: 'search',
					value: this.queryParameters.courseName,
				};
			}
			if (this.queryParameters.courseType) {
				filter['courseType'] = {
					type: 'search',
					value: this.queryParameters.courseType,
				};
			}

			if (this.queryParameters.startDate && this.queryParameters.endDate) {
				let tempDate = this.queryParameters.startDate.replace(/-/g, '/'); //必须把日期'-'转为'/'
				var startDateTimeStamp = new Date(tempDate).getTime();
				tempDate = this.queryParameters.endDate.replace(/-/g, '/');
				var endDateTimeStamp = new Date(tempDate).getTime();
				filter['createdDate'] = {
					type: 'timestamp',
					value: [startDateTimeStamp, endDateTimeStamp],
				};
			}

			const filters = this.parseFilters(filter);
			if (filters && filters.length > 0) {
				this.where.filters = filters;
			} else {
				// 如果过滤条件为空，设置空数组
				this.where.filters = [];
			}
			const params = {
				pageNum: this.queryParameters.pageNum,
				pageSize: this.queryParameters.pageSize,
				where: this.where,
				order: '',
			};
			this.loading = true;
			findAllCourses(params).then((response) => {
				this.courses = response.data.records;
				this.total = response.data.total;
				this.loading = false;
			});
		},
		handleUpdate() {
			let findCourse = this.courses.filter(item=>item.id==this.ids[0]);
			this.selectCourse = findCourse[0];
			// this.selectCourse.courseType= this.courseTypeFormat(this.selectCourse,null);
			// this.selectCourse.fileType= this.courseFileTypeFormat(this.selectCourse,null);
			// this.selectCourse.visitType= this.courseVisitTypeFormat(this.selectCourse,null);
			this.showCourseEdit = true;
			this.courseEditTitle="更新课程";
			this.newCourse=false;
		},

		// 多选框选中数据
		handleSelectionChange(selection) {
			this.ids = selection.map((item) => item.id);
			this.single = selection.length == 1;
			this.multiple = selection.length>=1;
		},
		closeCourseEdit(arg)
		{
			this.showCourseEdit = false;
			if(arg.refresh){
				this.query();
			}
		},
		 courseTypeFormat(row, column) {
			for(var i = 0; i < this.courseTypes.length; i++){
				const item =  this.courseTypes[i];
				if(item.dictValue == row.courseType){
				return item.dictLabel;
				}
			}
			return "";
    	},
		courseFileTypeFormat(row, column) {
			for(var i = 0; i < this.courseFileTypes.length; i++){
				const item =  this.courseFileTypes[i];
				if(item.dictValue == row.fileType){
				return item.dictLabel;
				}
			}
			return "";
    	},
		courseVisitTypeFormat(row, column) {
			for(var i = 0; i < this.courseAccessTypes.length; i++){
				const item =  this.courseAccessTypes[i];
				if(item.dictValue == row.visitType){
				return item.dictLabel;
				}
			}
			return "";
    	},
		parseTime(rowDateTime)
		{
			if(rowDateTime != null && rowDateTime.indexOf('T')> 0)
			{
				return rowDateTime.substr(0,10);
			}
			else{
				return "";
			}
		}
	},
};
</script>

<style>
	.el-form-item{
		margin-bottom: 10px !important;
	}
</style>