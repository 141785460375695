<template>
  <div class="app-container">
    <employ-edit :row="row" :company-id="companyId" :opType="opType" @close="close" @update="update"></employ-edit>
  </div>
</template>

<script>
import employEdit from './employEdit';
import {updateEmploy} from "@/api/employ/employ";
export default {
  name: "edit",
  components: {
    employEdit,
  },
  data() {
    return {
      row: null,
      companyId: null,
      opType: 'hr',
    }
  },
  created() {
    // user router
    const {row, companyId} = this.$route.params;
    if (row) this.row = row;
    this.companyId = companyId;

  },
  methods: {
    close(){

    },
    update(data){
      console.log("update");
      console.log(data.data);
    }
  },
}
</script>

<style scoped>

</style>
