import { showLoading, hideLoading } from './reqloading';
import {Message} from "element-ui";

export function fileDownload(row, download) {
    console.log(row);
    showLoading();
    download(row.url).then(res=>{
        hideLoading();
        if(res){
            let resData = res.data;
            var blob = new Blob([resData],{type:'application/octet-stream'});
            const fileName = `${row.name}`;
            if ("download" in document.createElement("a")) {
                // 非IE下载
                const elink = document.createElement("a");
                elink.download = fileName;
                elink.style.display = "none";
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href); // 释放URL 对象
                document.body.removeChild(elink);
            } else {
                // IE10+下载
                navigator.msSaveBlob(blob, fileName);
            }
        }
    }).catch(e=>{
        Message.error(`后端下载文件出错 & ${e}`)
    });
}
