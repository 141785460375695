import request from '@/utils/request'

// 查询流程抄送列表
export function listActCopyTask(query) {
    return request({
        url: '/bpm/actCopyTask/findAll',
        method: 'post',
        data: query
    })
}

// 查询流程抄送详细
export function getActCopyTask(id) {
    return request({
        url: '/bpm/actCopyTask/findOne?id=' + id,
        method: 'post'
    })
}

// 新增流程抄送
export function addActCopyTask(data) {
    return request({
        url: '/bpm/actCopyTask/add',
        method: 'post',
        data: data
    })
}

// 修改流程抄送
export function updateActCopyTask(data) {
    return request({
        url: '/bpm/actCopyTask/edit',
        method: 'post',
        data: data
    })
}

// 删除流程抄送
export function delActCopyTask(id) {
    return request({
        url: '/bpm/actCopyTask/remove',
        method: 'post',
        data: id
    })
}

// 导出流程抄送
export function exportActCopyTask(query) {
    return request({
        url: '/bpm/actCopyTask/export',
        method: 'post',
        data: query
    })
}
