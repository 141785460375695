<template>
	<div>
		<el-form ref="contractEncodingForm" :model="contractEncodingForm" :rules="rules" label-width="150px">
			<el-row>
				<el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
					<el-form-item label="合同编号名称" prop="dictLabel">
						<el-input v-model="contractEncodingForm.dictLabel"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
					<el-form-item label="公司代码">
						<div>
							<el-checkbox v-model="companyChecked" @change="handleChecked"></el-checkbox>
						</div>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
					<el-form-item label="部门代码">
						<div>
							<el-checkbox v-model="departmentChecked" @change="handleChecked"></el-checkbox>
						</div>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
					<el-form-item label="合同类型">
						<div>
							<el-checkbox v-model="contractTypeChecked" @change="handleChecked"></el-checkbox>
						</div>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
					<el-form-item label="员工工作编号">
						<div>
							<el-checkbox v-model="workNoChecked" @change="handleChecked"></el-checkbox>
						</div>
					</el-form-item>
				</el-col>
			</el-row>

			<el-row>
				<el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
					<el-form-item label="自定义值">
						<div v-bind:style="selectStyle">
							<el-checkbox v-model="selfChecked" @change="handleChecked"></el-checkbox>
							<el-input :style="inputMargin" v-model="selfDefinedValue" @keyup.native="changeSelfDefinition"></el-input>
						</div>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
					<el-form-item label="说明">
						<div >
							<el-input  v-model="contractEncodingForm.remark" ></el-input>
						</div>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row>
				<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">
					<el-form-item label="生成的编码模式" prop="dictValue">
						<el-input v-model="contractEncodingForm.dictValue" id="contractCoding"> </el-input>
					</el-form-item>
				</el-col>
			</el-row>

			<div style="margin-top: 10px; text-align: center">
				<el-button type="primary" plain v-if="isAddWay" :loading="loading" @click="submitForm">增 加</el-button>
				<el-button type="primary" plain v-if="isUpdateWay" :loading="loading" @click="updateContractNoEncoding">修 改</el-button>
				<el-button @click="cancel">取 消</el-button>
			</div>
		</el-form>
	</div>
</template>

<script>
import { addSysdict ,updateSysdict} from '@/api/sys/sysdict';
import permission from '@/directive/permission/index.js'; // 权限判断指令
import { formatDate } from '@/utils/formatTime';
export default {
	data() {
		let contractEncodingWay={ dictLabel: '', dictValue: '',listClass:'' ,remark:''};
		if(this.isUpdate)
		{
			contractEncodingWay = this.selectContractEncoding;
		}
		return {
			contractEncodingForm: contractEncodingWay,
			selectStyle: 'display:inline-flex',
			inputMargin: 'margin-left:15px',
			isAddWay: this.isAdd,
			isUpdateWay: this.isUpdate,
			loading: false,

			companyChecked: false,
			departmentChecked: false,
			contractTypeChecked: false,
			workNoChecked: false,
			selfChecked: false,
			selfDefinedValue: '',

			rules: {
				dictLabel: [
					{
						required: true,
						message: '名称必需填写',
						trigger: 'blur',
					},
				],
				dictValue: [
					{
						required: true,
						message: '必需有合同编码方式生成。',
						trigger: 'blur',
					},
				],
			},
		};
	},
	props: { isAdd: Boolean, isUpdate: Boolean, selectContractEncoding: Object },
	directives: { permission },
	mounted() {},
	computed: {},

	methods: {
		handleChecked() {
			 this.encoding();
		},
		changeSelfDefinition() {
			this.encoding();
		},
		encoding() {
			let newEncodingWay = '';
			let newEncodingWayDisplay ="";

			if (this.companyChecked) {
				newEncodingWay += newEncodingWay == '' ? 'companyNum' : '-companyNum';
				newEncodingWayDisplay += newEncodingWayDisplay == '' ? '员工公司代码' : '-员工公司代码';
				
			}
			if (this.departmentChecked) {
				newEncodingWay += newEncodingWay == '' ? 'departmentNum' : '-departmentNum';
				newEncodingWayDisplay += newEncodingWayDisplay == '' ? '员工部门代码' : '-员工部门代码';
			}
			if (this.contractTypeChecked) {
				newEncodingWay += newEncodingWay == '' ? 'contractType' : '-contractType';
				newEncodingWayDisplay += newEncodingWayDisplay == '' ? '合同类型' : '-合同类型';
			}
			if (this.workNoChecked) {
				newEncodingWay += newEncodingWay == '' ? 'workNumber' : '-workNumber';
				newEncodingWayDisplay += newEncodingWayDisplay == '' ? '员工工作号' : '-员工工作号';
			}
			if (this.selfChecked) {
				newEncodingWay += newEncodingWay == '' ? this.selfDefinedValue : '-' + this.selfDefinedValue;
				newEncodingWayDisplay += newEncodingWayDisplay == '' ?  this.selfDefinedValue : '-' + this.selfDefinedValue;
			}

			newEncodingWay += newEncodingWay == '' ? '' : '-flowCode';
			newEncodingWayDisplay += newEncodingWayDisplay == '' ? '' : '-4位流水码';
			this.contractEncodingForm.dictValue = newEncodingWayDisplay;
			this.contractEncodingForm.listClass= newEncodingWay;
			return newEncodingWay;
		},
		submitForm() {
			if (this.selfChecked) {
				if (this.selfDefinedValue == undefined || this.selfDefinedValue == '') {
					this.msgError('已经选择自定义字段，其值不能为空。');
					return;
				}
			}
			this.$refs['contractEncodingForm'].validate((valid) => {
				let self = this;
				if (valid) {
					var formData = {};
					formData.pid = 0;
					formData.dictSort = 2;
					formData.dictLabel = self.contractEncodingForm.dictLabel;
					formData.dictValue = self.contractEncodingForm.dictValue;
					formData.dictType = "contract_id_encoding";
					formData.cssClass = "";
					formData.listClass = self.contractEncodingForm.listClass;
					formData.isDefault = "N";
					formData.status = "0";
					formData.createBy = self.$store.state.user.userInfo.username;
					formData.updateBy = "";
					formData.remark =  self.contractEncodingForm.remark;
					self.loading = true;
					addSysdict(formData)
						.then((response) => {
							self.loading = false;
							if (response.code == 200) {
								self.msgSuccess('增加成功');
								self.$emit('closedialog', { refresh: true, isSuccess: true });
							} else {
								self.msgError(response.message);
							}
						})
						.catch((err) => {
							self.loading = false;
							self.msgError(err);
						});
				} else {
					this.msgError('有非法输入或必填项未填写，请检查!');
				}
			});
		},
		updateContractNoEncoding(){
			if (this.selfChecked) {
				if (this.selfDefinedValue == undefined || this.selfDefinedValue == '') {
					this.msgError('已经选择自定义字段，其值不能为空。');
					return;
				}
			}
			this.$refs['contractEncodingForm'].validate((valid) => {
				let self = this;
				if (valid) {
					var formData = self.selectContractEncoding;
					formData.dictLabel = self.contractEncodingForm.dictLabel;
					formData.dictValue = self.contractEncodingForm.dictValue;
					formData.listClass = self.contractEncodingForm.listClass;
					formData.updateBy = self.$store.state.user.userInfo.username;
					formData.remark =  self.contractEncodingForm.remark;
					self.loading = true;
					updateSysdict(formData)
						.then((response) => {
							self.loading = false;
							if (response.code == 200) {
								self.msgSuccess('更改成功');
								self.$emit('closedialog', { refresh: true, isSuccess: true });
							} else {
								self.msgError(response.message);
							}
						})
						.catch((err) => {
							self.loading = false;
							self.msgError(err);
						});
				} else {
					this.msgError('有非法输入或必填项未填写，请检查!');
				}
			});
		},
		cancel() {
			this.$emit('closedialog', { refresh: false, isSuccess: true });
		},
	},
};
</script>

<style lang="scss" scoped>
@import url('../../../../theme/other.scss');
</style>