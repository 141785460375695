<template>
	<div class="tableContent">
		<div id="printMe" ref="printMe">
			<div class="title">员工简历</div>
			<div>
				<table class="table">
					<tr>
						<!-- 6 col -->
						<td rowspan="6" class="firstCol">个人资料</td>
						<td class="secondCol">姓&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;名</td>
						<td class="thirdCol">{{employee.name}}</td>
						<td class="fourCol">性&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;别</td>
						<td class="fiveCol">{{employee.sex}}</td>
						<td class="sicCol" rowspan="4">
							<img v-if="employee.photo" :src="employee.photo" class="imgsrc" />
							<i v-else class="el-icon-plus avatar-uploader-icon"></i>
						</td>
					</tr>
					<tr>
						<td>民&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;族</td>
						<td>{{employee.nation}}</td>
						<td>出生年月</td>
						<td>{{employee.idBridthDay}}</td>
					</tr>
					<tr>
						<td>籍&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;贯</td>
						<td>{{employee.nativePlace}}</td>
						<td>政治面貌</td>
						<td>{{this.politcalStatus}}</td>
					</tr>
					<tr>
						<td>婚姻状况</td>
						<td>{{employee.marritalStatus}}</td>
						<td>联系电话</td>
						<td>{{employee.user.mobile}}</td>
					</tr>
					<tr>
						<td>通讯地址</td>
						<td colspan="4">{{employee.currentAddr}}</td>
					</tr>
					<tr>
						<td>身份证号码</td>
						<td colspan="4">{{employee.idNum}}</td>
					</tr>
				
					<tr>
						<td rowspan="3" class="firstCol">教育背景</td>
						<td>最高学历</td>
						<td>{{highestEdu.edu}}</td>
						<td>专&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;业</td>
						<td>{{highestEdu.major}}</td>
						<td>{{highestEdu.period}}</td>
					</tr>
					<tr>
						<td>毕业院校</td>
						<td colspan="4">{{highestEdu.school}}</td>
					</tr>
					<tr>
						<td>学历证书编号</td>
						<td >{{highestEdu.graduationNumber}}</td>
						<td class="secondCol">学位证书编号</td>
						<td colspan="2">{{highestEdu.degreeNumber}}</td>
					</tr>
					<tr>
						<td :rowspan="workExpRow" class="firstCol">工作经历</td>
						<td colspan="2" style="text-align:center;">起止时间</td>
						<td colspan="3" style="text-align:center;">工作单位及职务</td>
						
					</tr>
					<tr v-for="(workExp,index) in employee.workExps" :key="index" >
						<td colspan="2">{{workExp.startDate}}—{{workExp.endDate}}</td>
						<td colspan="3">{{workExp.workUnit}}&nbsp;&nbsp;&nbsp;&nbsp;{{ workExp.workPosition}}</td>
					</tr>
					<tr>
						
						<td colspan="5"></td>
					</tr>
					
					<tr>
						<td :rowspan="skillsRow" class="firstCol">技能及评价</td>
						<td>技能名称</td>
						<td >证书号码</td>
						<td >技能等级</td>
						<td >获取时间</td>
						<td >技能时长</td>
					</tr>
					<tr v-for="(skill,index) in employee.skills" :key="index">
						<td>{{skill.name}}</td>
						<td >{{skill.number}}</td>
						<td >{{skill.level}}</td>
						<td >{{skill.getDate}}</td>
						<td >{{skill.employPeriod}}</td>
					</tr>
					
					<tr>
						<td></td>
						<td ></td>
						<td ></td>
						<td ></td>
						<td ></td>
					</tr>
				</table>
			</div>
			<!-- <div class="content-table-img">
          <div class="table-name-one">XXXX</div>
          <div class="table-info-img">
            <div class="imgsrc">
              <img
                v-if="list.img"
                :src="list.img"
              />
            </div>
          </div>
        </div> -->
			
		</div>
			<div style="text-align:center;" >
				<el-button type="success" plain icon="el-icon-printer" size="mini" @click="printHandle">打印</el-button>
        		<el-button style="margin-top: 10px" type="primary" size="mini"  plain @click="cancel">关 闭</el-button>
			</div>
	</div>
</template>

<script>
import {
	downloadPersonalPhoto
} from '@/api/employ/employ';
import html2canvas from 'html2canvas'
import printJS from 'print-js'
export default {
	name: 'printInfo',
	data() {
		let politicalStatusInit="";
		let skillsRowInit =2;
		let workExpsRowInit =2 
		if(this.passEmployee.partyInfo != null && this.passEmployee.partyInfo.politicalStatus != undefined)
		{
			politicalStatusInit=this.passEmployee.partyInfo.politicalStatus;
		}
		let highestEduInit={edu:"",major:"",period:"",school:"",graduationNumber:"",degreeNumber:""};
		if(this.passEmployee.educations != null && this.passEmployee.educations.length > 0)
		{
			let foundHighestEdu = null ;
			let filterResults = this.passEmployee.educations.filter(item=> (item.education != undefined  && item.education != null && item.education.indexOf("博士")>-1)
																		|| ( item.academicDegree != undefined && item.academicDegree != null && item.academicDegree.indexOf("博士")>-1 ));
			if (filterResults != null && filterResults.length > 0) {
				foundHighestEdu = filterResults[0];
			}
			else{
				filterResults = this.passEmployee.educations.filter(item=>(item.education != undefined  && item.education != null && item.education.indexOf("硕士")>-1)
											 || ( item.academicDegree != undefined && item.academicDegree != null && item.academicDegree.indexOf("硕士")>-1));
				if (filterResults != null && filterResults.length > 0) {
					foundHighestEdu = filterResults[0];
				}
				else{

					filterResults = this.passEmployee.educations.filter(item=>(item.education != undefined  && item.education != null && item.education.indexOf("本科")>-1 )
								|| ( item.academicDegree != undefined && item.academicDegree != null &&item.academicDegree.indexOf("本科")>-1 ));
					if (filterResults != null && filterResults.length > 0) {
						foundHighestEdu = filterResults[0];
					}
					else{
						filterResults = this.passEmployee.educations.filter(item=>(item.education != undefined  && item.education != null && item.education.indexOf("高中")>-1 )
								|| ( item.academicDegree != undefined && item.academicDegree != null &&item.academicDegree.indexOf("高中")>-1 ));
						if (filterResults != null && filterResults.length > 0) {
							foundHighestEdu = filterResults[0];
						}
						else{
							filterResults = this.passEmployee.educations.filter(item=>(item.education != undefined  && item.education != null && item.education.indexOf("初中")>-1)
							 	|| ( item.academicDegree != undefined && item.academicDegree != null && item.academicDegree.indexOf("初中")>-1 ));
							if (filterResults != null && filterResults.length > 0) {
								foundHighestEdu = filterResults[0];
							}
						}
					}
				}
			}
			


			if (foundHighestEdu == null) {
				foundHighestEdu = this.passEmployee.educations[0];
			}
			
			highestEduInit.edu = foundHighestEdu.education;
				highestEduInit.major = foundHighestEdu.major;
				highestEduInit.school = foundHighestEdu.school;
				highestEduInit.period = foundHighestEdu.startDate+"—"+ foundHighestEdu.endDate;
				highestEduInit.graduationNumber = foundHighestEdu.edcationNo;
				highestEduInit.degreeNumber = foundHighestEdu.academicDegreeNo;


			if(this.passEmployee.skills != null && this.passEmployee.skills.length > 0)
			{
				skillsRowInit += this.passEmployee.skills.length;
			}

			if(this.passEmployee.workExps != null && this.passEmployee.workExps.length > 0)
			{
				workExpsRowInit += this.passEmployee.workExps.length;
			}
		}
		return {
			employee: this.passEmployee,
			politcalStatus:politicalStatusInit,
			highestEdu:highestEduInit,
			skillsRow:skillsRowInit,
			workExpRow:workExpsRowInit,
			printObj: {
				id: 'printMe',
				popTitle: ' ',
				extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>',
	
			},
		};
	},
	props:{passEmployee:Object},
	methods: {
		downloadPersonalPhoto() {
			if (this.employee != null && this.employee.photo.length > 0) {
				// download

				downloadPersonalPhoto(this.employee.photo)
					.then((res) => {
						if (res) {
							let resData = res.data;
							var blob = new Blob([resData], { type: 'application/octet-stream' });
							this.employee.photo =  URL.createObjectURL(blob);
						} else {
							this.msgError(`后端下载文件出错 & ${e}`);
						}
					})
					.catch((e) => {
						this.loading = false;
						this.msgError(`后端下载文件出错 & ${e}`);
					});
			}
		},
		cancel() {
			this.$emit('close', { refresh: false, isSuccess: true });
		},
		printHandle() {
			html2canvas(this.$refs.printMe, {
					backgroundColor: null,
					useCORS: true,
					windowHeight: document.body.scrollHeight
			}).then((canvas) => {
					const url = canvas.toDataURL()
					this.img = url
					printJS({
						printable: url,
						type: 'image',
						documentTitle: ''
					})
			})
		},
	},
	mounted:function(){
		this.downloadPersonalPhoto();
	},
	computed: {},
	created() {},
	
};
</script>

<style  scoped>
.table {
	width: 100%;
	border-style: solid;
	border-collapse: collapse;
	overflow-y: scroll;
	border: 1;
	
}
.tableContent {
	width: 900px;
	margin: auto;
	margin-top: 20px;
}

.firstCol{
	width: 2%;
}
.secondCol{
	width:15%;
}
.thirdCol{
	width:25%;
}
.fourCol{
	width:10%;
}
.fiveCol{
	width:25%;
}
.sexCol{
	width:23%;
}

.tableList table,
table tr th,
table tr td {
	border: 1px solid rgb(12, 12, 12);
  padding: 15px;
}

.title {
	font-size: 30px;
	width: 100%;
	text-align: center;
	margin-bottom: 10px;
}

.table-info-img {
	border: 0.55px solid;
	width: 75%;
}


.content-table-img {
	display: flex;
	height: 100px;
	width: 100%;
	line-height: 100px;
	text-align: center;
}

img {
	max-width: 100%;
	height: 100%;
	background-size: 100%;
	background-repeat: no-repeat;
}
.imgsrc {
	width: 99%;

	height: 99%;

}
body{
    margin: 10mm 15mm 10mm 15mm; /* margin you want for the content */
  }
</style>
