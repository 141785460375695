<template>
  <div>
    <departEndEdit type="apply" :isShowClose="true"></departEndEdit>
  </div>
</template>

<script>
import DepartEndEdit from "./departEndEdit";
export default {
  name: "apply",
  components:{
    DepartEndEdit,
  }
}
</script>

<style scoped>

</style>
