<template>
	<div class="home">
		<!-- 用户信息 -->
		<el-row :gutter="15">
			<el-col :md="24" :lg="16" :xl="16" class="mb15">
				<el-card shadow="hover">
					<div slot="header">
						<span>{{ $t('message.card.title1') }}</span>
					</div>
					<div class="user-item">
						<div class="user-item-left">
							<img :src="getUserInfos.avatar" />
						</div>
						<div class="user-item-right overflow">
							<el-row>
								<el-col :span="24" class="right-title mb15 one-text-overflow"
									>{{ currentTime }}，{{ $store.getters.userInfo.name === '' ? $store.getters.userInfo.username : $store.getters.userInfo.name }}，
                   欢迎来到{{$store.getters.userInfo.companyName}}
<!--                  ,{{ dailyMessage }}-->
								</el-col>
								<el-col :span="24">
									<el-col :xs="12" :sm="12" :md="8" class="right-l-v">
										<div class="right-label">姓名：</div>
										<div class="right-value">{{ $store.getters.userInfo.name === '' ? $store.getters.userInfo.username : $store.getters.userInfo.name }}</div>
									</el-col>
									<el-col :xs="12" :sm="12" :md="16" class="right-l-v">
                    <div class="right-label">部门：</div>
                    <div class="right-value one-text-overflow">{{$store.getters.userInfo.departmentName}}</div>
									</el-col>
								</el-col>
								<el-col :span="24" class="mt5">
									<el-col :xs="12" :sm="12" :md="8" class="right-l-v">
										<div class="right-label one-text-overflow">工号：</div>
										<div v-if="$store.getters.userInfo.employ" class="right-value one-text-overflow">{{$store.getters.userInfo.employ.workNum}}</div>
                    <div v-else class="right-label one-text-overflow">非员工账号</div>
									</el-col>
									<el-col :xs="12" :sm="12" :md="16" class="right-l-v">
										<div class="right-label one-text-overflow">岗位：</div>
										<div class="right-value one-text-overflow">{{$store.getters.userInfo.positionName}}</div>
									</el-col>
								</el-col>
								<el-col :span="24" class="mt15">
									<el-button size="small" icon="el-icon-edit-outline" @click="modifyUserInfo">修改信息 </el-button>
<!--									<el-button size="small" icon="el-icon-position" type="primary">发布活动</el-button>-->
								</el-col>
							</el-row>
						</div>
					</div>
				</el-card>
			</el-col>
			<el-col :md="24" :lg="8" :xl="8" class="mb15">
				<el-card shadow="hover">
					<div slot="header">
						<span>{{ $t('message.card.title2') }}</span>
						<el-button class="home-card-more" type="text" @click="routerMoreMsg">{{ $t('message.card.title3') }}</el-button>
					</div>
					<div class="info">
						<Scroll :data="msgList" class="info-scroll" :class-option="optionSingleHeight">
							<ul class="info-ul">
								<li v-for="(v, k) in msgList" :key="k" class="info-item" @click="handleView(v)">
<!--                  <div class="info-item-left" v-text="selectDictLabel(msgTypeOptions, v.msgType)"></div>-->
<!--                  <div class="info-item-left" >{{v.readTimes > 0 ? "[已读]": "[未读]"}}<B>{{ shwoMsgType(v) }}</B> - {{v.title}}</div>-->
                      <div class="info-item-left" ><B>{{ shwoMsgType(v) }}</B> - {{v.title}}</div>
									<div class="info-item-right" v-text="v.startTime"></div>
								</li>
							</ul>
						</Scroll>
					</div>
				</el-card>
			</el-col>
		</el-row>
    <!-- 添加或修改消息管理对话框 -->
    <el-dialog :title="previewTitle" :visible.sync="previewOpen" width="90%" append-to-body>
      <MsgPreview :msg="previewMsg" @close="previewClose"></MsgPreview>
    </el-dialog>


		<el-card shadow="hover">
			<div slot="header">
				<span>代 办 事 项</span>
<!--				<el-button class="home-card-more" type="text" @click="onOpenGitee">更 多</el-button>-->
			</div>
			<el-row :gutter="15" class="home-recommend-row">
        <el-col :xs="8" :sm="8" :md="6" :lg="3" :xl="3" v-role="['Admin','CAdmin','HRManager','Entry']">
          <div @click="goEntry" class="home-recommend" :style="{ 'background-color': '#48D18D','margin-top': '10px'}">
            <i class="el-icon-s-custom" :style="{ 'color': '#64d89d' }"></i>
            <div class="home-recommend-auto">
              <div style="text-align: center">待 入 职</div>
              <div class="home-recommend-msg" style="text-align: center">{{ nedTodo.entryCount }} 人</div>
            </div>
          </div>
        </el-col>
        <div v-permission="['/pages/bpm/todo/index']">
          <el-col :xs="8" :sm="8" :md="6" :lg="3" :xl="3">
            <div @click="goTodo"  class="home-recommend" :style="{ 'background-color': '#48D18D','margin-top': '10px'}">
              <i class="el-icon-s-custom" :style="{ 'color': '#64d89d' }"></i>
              <div class="home-recommend-auto">
                <div style="text-align: center">待 转 正</div>
                <div class="home-recommend-msg" style="text-align: center">{{ nedTodo.officialCount }} 人</div>
              </div>
            </div>
          </el-col>
          <el-col :xs="8" :sm="8" :md="6" :lg="3" :xl="3">
            <div @click="goTodo" class="home-recommend" :style="{ 'background-color': '#48D18D','margin-top': '10px'}">
              <i class="el-icon-s-custom" :style="{ 'color': '#64d89d' }"></i>
              <div class="home-recommend-auto">
                <div style="text-align: center">待 离 职</div>
                <div class="home-recommend-msg" style="text-align: center">{{ nedTodo.departCount }} 人</div>
              </div>
            </div>
          </el-col>
        </div>
<!--				<el-col :xs="8" :sm="8" :md="6" :lg="3" :xl="3"  v-for="(v, k) in recommendList" :key="k">-->
<!--					<div class="home-recommend" :style="{ 'background-color': v.bg ,'margin-top': '10px'}">-->
<!--						<i :class="v.icon" :style="{ color: v.iconColor }"></i>-->
<!--						<div class="home-recommend-auto">-->
<!--							<div>{{ v.title }}</div>-->
<!--							<div class="home-recommend-msg">{{ v.msg }}</div>-->
<!--						</div>-->
<!--					</div>-->
<!--				</el-col>-->
			</el-row>
		</el-card>

<!--		&lt;!&ndash; charts &ndash;&gt;-->
<!--		<el-row :gutter="15" class="mt15">-->
<!--			<el-col :md="24" :lg="8" :xl="8" class="mb15">-->
<!--				<el-card shadow="hover">-->
<!--					<div slot="header">-->
<!--						<span>{{ $t('message.card.title6') }}</span>-->
<!--					</div>-->
<!--					<div class="charts">-->
<!--						<div class="charts-right">-->
<!--							<div ref="homeStockRef" class="h100"></div>-->
<!--						</div>-->
<!--					</div>-->
<!--				</el-card>-->
<!--			</el-col>-->
<!--			<el-col :md="24" :lg="16" :xl="16" class="mb15">-->
<!--				<el-card shadow="hover">-->
<!--					<div slot="header">-->
<!--						<span>{{ $t('message.card.title7') }}</span>-->
<!--					</div>-->
<!--					<div class="charts">-->
<!--						<div class="charts-left mr7">-->
<!--							<div ref="homeLaboratoryRef" class="h100"></div>-->
<!--						</div>-->
<!--					</div>-->
<!--				</el-card>-->
<!--			</el-col>-->
<!--		</el-row>-->

<!--		&lt;!&ndash; v-charts &ndash;&gt;-->
<!--		<el-row :gutter="15">-->
<!--			<el-col :md="24" :lg="16" :xl="16" class="home-lg">-->
<!--				<el-card shadow="hover">-->
<!--					<div slot="header">-->
<!--						<span>{{ $t('message.card.title8') }}</span>-->
<!--					</div>-->
<!--					<div class="charts">-->
<!--						<div class="charts-left mr7">-->
<!--							<div ref="homeOvertimeRef" class="h100"></div>-->
<!--						</div>-->
<!--					</div>-->
<!--				</el-card>-->
<!--			</el-col>-->
<!--			<el-col :md="24" :lg="8" :xl="8">-->
<!--				<el-card shadow="hover">-->
<!--					<div slot="header">-->
<!--						<span>{{ $t('message.card.title9') }}</span>-->
<!--					</div>-->
<!--					<div class="home-charts">-->
<!--						<div class="home-charts-item" v-for="(v, k) in chartsRightList" :key="k">-->
<!--							<div class="home-charts-item-left">-->
<!--								<div class="home-charts-item-title">{{ v.title }}</div>-->
<!--								<div class="home-charts-item-num" :style="{ color: v.color }" :id="`titleNum${k + 1}`"></div>-->
<!--							</div>-->
<!--							<div class="home-charts-item-right">-->
<!--								<i :class="v.icon" :style="{ 'background-color': v.iconBg, color: v.color }"></i>-->
<!--							</div>-->
<!--						</div>-->
<!--					</div>-->
<!--				</el-card>-->
<!--			</el-col>-->
<!--		</el-row>-->
	</div>
</template>

<script>
import * as echarts from 'echarts';
import Scroll from 'vue-seamless-scroll';
import { CountUp } from 'countup.js';
import { Session } from '@/utils/storage';
import { formatAxis, formatDate } from '@/utils/formatTime';
import MsgPreview from "@/views/pages/msg/msg/msgPreview";
import { listMsg } from "@/api/msg/msgreceived";
import role from '@/directive/role/index.js' // 权限判断指令
import permission from '@/directive/permission/index.js' // 权限判断指令

import { recommendList, chartsRightList, dailyMessage } from './mock';
import {needTodo} from "../../api/home";
import {getMsgreceiver} from "../../api/msg/msgreceiver";
export default {
	name: 'home',
  directives: { role , permission},
	components: { Scroll , MsgPreview},
	data() {
		return {
			recommendList,
			chartsRightList,
			msgList: [],
      previewTitle: "",
      previewOpen: false,
      previewMsg: null,
			userInfo: {},
      nedTodo:{},
			dailyMessage: {},
      msgTypeOptions: [],
		};
	},
	created() {
		this.initUserInfo();
		this.initMsgList();
    this.getDicts("msg_type").then(response => {
      this.msgTypeOptions = response.data;
    });
    needTodo().then(response => {
      this.nedTodo = response.data;
    });
		// this.initDailyMessage();
	},
	computed: {
		currentTime() {
			return formatAxis(new Date());
		},
		optionSingleHeight() {
			return {
				singleHeight: 28,
				limitMoveNum: 8,
				waitTime: 2000,
			};
		},
		getUserInfos() {
			return this.$store.getters.userInfo;
		},
	},
	mounted() {
		// this.initHomeStock();
		// this.initHomeLaboratory();
		// this.initHomeOvertime();
		// this.initNumCountUp();
	},
	methods: {
    modifyUserInfo(){
      this.$router.push({name:"personal"});
    },
    handleView(row){
      // 5- 待办事项
      console.log(row);
      getMsgreceiver(row.id);
      if(5 ==row.msgType){
        console.log("go 待办事项");
        if("Entry" == row.businessType){
          this.$router.push({name:"employ_employ_add_vue", params: {companyId: this.$store.getters.userInfo.companyId}});
        }else{
          console.log("go bpm_todo_list");
          this.$router.push({name: "bpm_todo_list"});
        }
      }else{
        this.previewMsg = row;
        // this.previewTitle = '通告';
        this.previewOpen = true;
      }
      row.readTimes = row.readTimes+1;
      // this.msgList.pop(row);
    },
    previewClose(){
      this.previewOpen = false;
    },
    initMsgList(){
      this._filter = {};
      this._filter['readTimes'] = {
        type: 'le',
        value: 0,
      }
      const filters = this.parseFilters(this._filter)
      this.where = {};
      if (filters && filters.length > 0) {
        this.where.filters = filters
      } else {
        // 如果过滤条件为空，设置空数组
        this.where.filters = []
      }

      const params = {
        pageNum: 1,
        pageSize: 10,
        where: this.where,
        order: 'id desc',
      }
      listMsg(params).then(response => {
        this.msgList = response.data.records;
        // this.loading = false;
      });
    },
    shwoMsgType(v){
      if(v.msgType == 1){
        return "公告";
      } else if(v.msgType == 2){
        return "通知";
      } else if(v.msgType == 3){
        return "提醒";
      } else if(v.msgType == 5){
        return "待办";
      }else{
        return "其他";
      }
    },
    routerMoreMsg(){
      this.$router.push({name:"msg_my"});
    },
    goEntry(){
      this.$router.push({name:"employ_employ_add_vue", params: {companyId: this.$store.getters.userInfo.companyId}});
    },
    goTodo(){
      this.$router.push({name: "bpm_todo_list"});
    },
		// 初始化数字滚动
		initNumCountUp() {
			this.$nextTick(() => {
				// new CountUp('titleNum1', Math.random() * 100000).start();
				// new CountUp('titleNum2', Math.random() * 100000).start();
				// new CountUp('titleNum3', Math.random() * 100000).start();
			});
		},
		// 库存作业
		initHomeStock() {
			const myChart = echarts.init(this.$refs.homeStockRef);
			const option = {
				grid: {
					top: 50,
					right: 20,
					bottom: 30,
					left: 30,
				},
				tooltip: {
					trigger: 'item',
				},
				legend: {
					left: 'center',
				},
				series: [
					{
						name: '邮件营销',
						type: 'pie',
						radius: ['40%', '70%'],
						avoidLabelOverlap: false,
						itemStyle: {
							borderRadius: 10,
							borderColor: '#fff',
							borderWidth: 2,
						},
						data: [
							{ value: 580, name: '邮件营销' },
							{ value: 300, name: '视频广告' },
							{ value: 230, name: '联盟广告' },
						],
						top: 30,
					},
				],
			};
			myChart.setOption(option);
			window.addEventListener('resize', () => {
				myChart.resize();
			});
		},
		// 履约情况
		initHomeLaboratory() {
			const myChart = echarts.init(this.$refs.homeLaboratoryRef);
			const option = {
				grid: {
					top: 50,
					right: 20,
					bottom: 30,
					left: 30,
				},
				tooltip: {
					trigger: 'axis',
				},
				legend: {
					data: ['预购队列', '最新成交价'],
					right: 13,
				},
				xAxis: {
					data: ['衬衫', '羊毛衫', '雪纺衫', '裤子', '高跟鞋', '袜子'],
				},
				yAxis: [
					{
						type: 'value',
						name: '价格',
					},
				],
				series: [
					{
						name: '预购队列',
						type: 'bar',
						data: [5, 20, 36, 10, 10, 20],
					},
					{
						name: '最新成交价',
						type: 'line',
						data: [15, 20, 16, 20, 30, 8],
					},
				],
			};
			myChart.setOption(option);
			window.addEventListener('resize', () => {
				myChart.resize();
			});
		},
		// 缺货监控
		initHomeOvertime() {
			const myChart = echarts.init(this.$refs.homeOvertimeRef);
			const option = {
				grid: {
					top: 50,
					right: 20,
					bottom: 30,
					left: 30,
				},
				tooltip: {
					trigger: 'axis',
				},
				legend: {
					data: ['订单数量', '超时数量', '在线数量', '预警数量'],
					right: 13,
				},
				xAxis: {
					data: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
				},
				yAxis: [
					{
						type: 'value',
						name: '数量',
					},
				],
				series: [
					{
						name: '订单数量',
						type: 'bar',
						data: [5, 20, 36, 10, 10, 20, 11, 13, 10, 9, 17, 19],
					},
					{
						name: '超时数量',
						type: 'bar',
						data: [15, 12, 26, 15, 11, 16, 31, 13, 5, 16, 13, 15],
					},
					{
						name: '在线数量',
						type: 'line',
						data: [15, 20, 16, 20, 30, 8, 16, 19, 12, 18, 19, 14],
					},
					{
						name: '预警数量',
						type: 'line',
						data: [10, 10, 13, 12, 15, 18, 19, 10, 12, 15, 11, 17],
					},
				],
			};
			myChart.setOption(option);
			window.addEventListener('resize', () => {
				myChart.resize();
			});
		},
		// 随机语录
		initDailyMessage() {
			this.dailyMessage = dailyMessage[Math.floor(Math.random() * dailyMessage.length)];
		},
		// 初始化登录信息
		initUserInfo() {
			if (!Session.get('userInfo')) return false;
			this.userInfo = Session.get('userInfo');
			this.userInfo.time = formatDate(new Date(this.userInfo.time), 'YYYY-mm-dd HH:MM:SS');
		},
		// 消息通知当前项点击
		onMsgListClick(v) {
			// window.open(v.link);
		},
		// 跳转到 gitee
		onOpenGitee() {
			// window.open('www.baidu.com');

		},
	},
};
</script>

<style scoped lang="scss">
@import './index.scss';
</style>
