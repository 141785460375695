import request from '@/utils/request'

// 查询合同模板列表
export function listContract(query) {
    return request({
        url: '/template/contract/findAll',
        method: 'post',
        data: query
    })
}

// 查询合同模板详细
export function getContract(id) {
    return request({
        url: '/template/contract/findOne?id=' + id,
        method: 'post'
    })
}

// 新增合同模板
export function addContract(data) {
    return request({
        url: '/template/contract/add',
        method: 'post',
        data: data
    })
}

// 修改合同模板
export function updateContract(data) {
    return request({
        url: '/template/contract/edit',
        method: 'post',
        data: data
    })
}

// 提交没有附件的修改
export function updateContractNoFile(data){
    console.log("updateContractNoFile")
    console.log(data);
    return request({
        url: '/template/contract/updateContractNoFile',
        method: 'post',
        data: data
    })
}
// 删除合同模板
export function delContract(id) {
    return request({
        url: '/template/contract/remove',
        method: 'post',
        data: id
    })
}

// 导出合同模板
export function exportContract(query) {
    return request({
        url: '/template/contract/export',
        method: 'post',
        data: query
    })
}

// 修改合同模板
export function download(fileName, num) {
    return request({
        url: '/template/contract/download?fileName='+fileName+'&num='+num,
        method: 'get',
        responseType: 'blob'
        // data: data
    })
}
