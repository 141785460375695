<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px">
      <el-form-item label="公司名称" prop="companyId">
        <treeselect v-model="queryParams.companyId" style="width: 198px"
                    :options="companyOptions"
                    :normalizer="normalizerCompany" placeholder="请选择所属公司"/>
      </el-form-item>
      <el-form-item label="名称说明" prop="name">
        <el-input v-model="queryParams.name" placeholder="请输入名称说明" clearable  @keyup.enter.native="handleQuery" />
      </el-form-item>
      <el-form-item label="巡逻员" prop="name">
        <el-input v-model="queryParams.empName" placeholder="请输入巡逻员姓名" clearable  @keyup.enter.native="handleQuery" />
      </el-form-item>
      <el-form-item label="是否拍照" prop="photo">
        <el-select v-model="queryParams.photo" placeholder="请选择是否拍照" clearable
                   size="small">
          <el-option label="是" :value="1"/>
          <el-option label="否" :value="0"/>
        </el-select>
      </el-form-item>
      <el-form-item label="照片审核" prop="photoApproved">
        <el-select v-model="queryParams.photoApproved" placeholder="请选择照片审核状态" clearable
                   size="small">
          <el-option label="未上传照片" :value="0"/>
          <el-option label="已审核" :value="1"/>
          <el-option label="待审核" :value="2"/>
          <el-option label="审核不通过" :value="3"/>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button type="primary" plain icon="el-icon-plus" size="mini"
                   @click="handleAdd" v-permission="['/attendance/attPatrolEmp/add']" >新增 </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button type="success" plain icon="el-icon-edit" size="mini" :disabled="single"
                   @click="handleUpdate" v-permission="['/attendance/attPatrolEmp/edit']" >修改 </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
            type="danger"
            plain
            icon="el-icon-delete"
            size="mini"
            :disabled="multiple"
            @click="handleDelete"
            v-permission="['/attendance/attPatrolEmp/remove']"
        >删除
        </el-button>
      </el-col>
      <!--      <el-col :span="1.5">-->
      <!--        <el-button-->
      <!--            type="warning"-->
      <!--            plain-->
      <!--            icon="el-icon-download"-->
      <!--            size="mini"-->
      <!--            @click="handleExport"-->
      <!--            v-permission="['/attendance/attPatrolEmp/export']"-->
      <!--        >导出-->
      <!--        </el-button>-->
      <!--      </el-col>-->
      <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>
    </el-row>

    <el-table v-loading="loading" :data="attPatrolEmpList" @selection-change="handleSelectionChange" stripe border>
      <el-table-column type="selection" width="55" align="center"/>
      <el-table-column label="巡逻人员" align="center" prop="empName"/>
      <el-table-column label="名称说明" align="center" prop="name"/>
      <el-table-column label="所属公司" align="center" prop="companyId" show-overflow-tooltip width="210" sortable>
        <template slot-scope="scope">
          {{companyFormat(scope.row, null)}}
        </template>
      </el-table-column>
      <el-table-column label="是否拍照" align="center" prop="isPhoto">
        <template slot-scope="scope">
          {{scope.row.photo?"是":"否"}}
        </template>
      </el-table-column>
      <el-table-column label="拍照是否已审核" align="center" prop="isPhoto">
        <template slot-scope="scope">
          {{getPhotoStatus(scope.row)}}
        </template>
      </el-table-column>
<!--      <el-table-column label="打卡次数" align="center" prop="times"/>-->
<!--      <el-table-column label="巡逻点" align="center" >-->
<!--        {{}}-->
<!--      </el-table-column>-->
<!--      <el-table-column label="上班时间" align="center" prop="otherInfo.earlyOn"/>-->
<!--      <el-table-column label="下班时间" align="center">-->
<!--        <template slot-scope="scope">-->
<!--          {{scope.row.otherInfo.laterOffOver?"次日":""}}{{scope.row.otherInfo.laterOff}}-->
<!--        </template>-->
<!--      </el-table-column>-->
<!--      <el-table-column label="记作考勤天数" align="center" prop="days"/>-->
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="handleCopy(scope.row)"
              v-permission="['/attendance/attPatrolEmp/add']"
          >复制
          </el-button>
          <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="handleUpdate(scope.row)"
              v-permission="['/attendance/attPatrolEmp/edit']"
          >修改
          </el-button>
          <el-button
              size="mini"
              type="text"
              icon="el-icon-delete"
              @click="handleDelete(scope.row)"
              v-permission="['/attendance/attPatrolEmp/remove']"
          >删除
          </el-button>
<!--                        v-if="scope.row.photo && (scope.row.photoApproved==2 || scope.row.photoApproved==3 || scope.row.photoApproved==0)"
-->
          <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="handleApprovePhoto(scope.row)"
              v-permission="['/attendance/attPatrolEmp/edit']"
          >审核
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
        v-show="total>0"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
    />

    <!-- 添加或修改巡逻人员管理对话框 -->
    <el-dialog :title="title" :visible.sync="open" append-to-body :width="editDialogSize">
      <AttPatrolEmpEdit :row="row" @close="close"></AttPatrolEmpEdit>
    </el-dialog>

    <el-dialog :title="title" :visible.sync="openApprovePhoto" append-to-body :width="editDialogSize">
      <ApprovePhoto :row="row" @close="closeApprovePhoto"></ApprovePhoto>
    </el-dialog>
  </div>
</template>

<script>
import {
  listAttPatrolEmp,
  getAttPatrolEmp,
  delAttPatrolEmp,
  addAttPatrolEmp,
  updateAttPatrolEmp,
  exportAttPatrolEmp } from "@/api/attendance/attPatrolEmp";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import RightToolbar from "@/components/RightToolbar"
import permission from '@/directive/permission/index.js' // 权限判断指令
import Pagination from "@/components/Pagination";
import AttPatrolEmpEdit from "./attPatrolEmpEdit";
import ApprovePhoto from "./approvePhoto";
import {listCompany} from "@/api/org/company";
import { Local } from '@/utils/storage.js';

// 表查询配置
const dbOrderBy = ''

export default {
  name: "AttPatrolEmp",
  components: {
    Treeselect,RightToolbar,Pagination,AttPatrolEmpEdit,ApprovePhoto,
  },
  directives: { permission },
  data() {
    return {
      // 公司树选项
      companyList: [],
      companyOptions: [],
      row: null,
      // 遮罩层
      loading: true,
      // 查询参数
      where: {
        query: '',
        filters: []
      },
      clockRange: false,

      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 巡逻人员管理表格数据
      attPatrolEmpList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      openApprovePhoto: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        orderby: dbOrderBy,
        name: null,
        empName: null,
        photoApproved: null,
        photo: null,
      },
      // 表单参数
      form: {
        isRest: false,
      },
      editDialogSize:"80%",
    };
  },
  created() {
    this.getTreeselectCompany();
    this._filter = {};
    this.getList();
    let uniapp =Local.get("isUniapp")?true:false;
    if(uniapp)
    {
      this.editDialogSize="90%";
    }
  },
  methods: {
    /** 查询公司下拉树结构 */
    getTreeselectCompany() {
      const params = {
        pageNum: 1,
        pageSize: 500,
        where: null,
        order: ''
      }
      listCompany(params).then(response => {
        this.companyList = response.data.records;
        this.companyOptions = this.handleTree(response.data.records, "id", "parentId");
      }) ;
    },
    /** 查询巡逻人员管理列表 */
    getQuery(){
      this._filter = {};
      if(this.queryParams.companyId) {
        this._filter['companyId'] = {
          type: 'eq',
          value: this.queryParams.companyId,
        }
      }
      if(this.queryParams.name) {
        this._filter['name'] = {
          type: 'search',
          value: this.queryParams.name,
        }
      }
      if(this.queryParams.empName) {
        this._filter['empName'] = {
          type: 'search',
          value: this.queryParams.empName,
        }
      }
      if(this.queryParams.photoApproved) {
        this._filter['photoApproved'] = {
          type: 'eq',
          value: this.queryParams.photoApproved,
        }
      }
      console.log(this.queryParams.photo != null)
      if(this.queryParams.photo != null) {
        console.log("photo="+this.queryParams.photo)
        this._filter['photo'] = {
          type: 'eq',
          value: this.queryParams.photo,
        }
        console.log(this._filter)
      }
      return this.getQueryParam(this._filter,this.queryParams.pageNum,this.queryParams.pageSize,this.orderby)
    },
    getList() {
      this.loading = true;
      const params = this.getQuery();
      console.log(params)
      listAttPatrolEmp(params).then(response => {
        this.attPatrolEmpList = response.data.records;
        this.total = response.data.total;
        this.loading = false;
      })
      ;
    },
    getPhotoStatus(row){
      // console.log("getPhotoStatus=")
      // console.log(row)
      if(row.photo){
        // console.log(row.photoApproved)
        if(row.photoApproved == 0 || row.photoApproved==null){
          return "未上传照片"
        }else if(row.photoApproved == 1){
          return "已审核"
        }else if(row.photoApproved == 2){
          return "待审核"
        }else if(row.photoApproved == 3){
          return "审核不通过"
        }else{
          return "未知"
        }
      }else {
        return "不用拍照"
      }
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {};
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.row = {time: Date.parse(new Date())};
      this.open = true;
      this.title = "增加巡逻人员";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      if(row.id){
        this.row = row;
      }else{
        this.row = {};
        this.row.id = this.ids;
      }
      this.open = true;
      this.title = "修改巡逻人员";
    },
    handleCopy(row){
      console.log("do copy")
      this.row = JSON.parse(JSON.stringify(row));
      console.log(this.row);
      this.row.name = "复制 - "+this.row.name;
      this.row.id = null;
      this.row.companyId = null;
      this.row.companyCode = null;
      this.row.userId = null;
      this.row.empId = null;
      this.row.empName = null;
      this.row.depId = null;
      this.open = true;
      this.title = "复制巡逻设置，请选择新的巡逻员";
    },
    handleApprovePhoto(row) {
      if(row.id){
        this.row = row;
      }
      this.openApprovePhoto = true;
      this.title = "巡逻人员巡逻点照片审批";
    },


    close(arg){
      this.open = false;
      if(arg.refresh){
        this.getList();
      }
    },
    closeApprovePhoto(arg){
      this.openApprovePhoto = false;
      if(arg.refresh){
        this.getList();
      }
    },
    /** 转换公司数据结构 */
    normalizerCompany(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.id,
        label: node.companyName,
        children: node.children
      };
    },
    companyFormat(row, column) {
      // console.log(row);
      for(var i = 0; i < this.companyList.length; i++){
        const item =  this.companyList[i];
        if(item.id == row.companyId){
          return item.companyName;
        }
      }
      return "";
    },
    getCompanyCode(companyId){
      // console.log(companyId)
      // console.log(this.companyList)
      for(var i = 0; i < this.companyList.length; i++){
        const item =  this.companyList[i];
        if(item.id == companyId){
          return item.code;
        }
      }
    },

    /** 删除按钮操作 */
    handleDelete(row) {
      let ids = [];
      if(row.id){
        ids = [row.id];
      }else{
        ids = this.ids;
      }

      this.$confirm('是否确认删除选中的巡逻人员管理数据?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return delAttPatrolEmp(ids);
      }).then(() => {
        this.getList();
        this.msgSuccess("删除成功");
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      const params = this.getQuery();
      this.$confirm('是否确认导出所有巡逻人员管理数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return exportAttPatrolEmp(params);
      }).then(response => {
        this.download(response.msg);
      })
    }
  }
};
</script>

<style scoped>
.el-form-item{
  margin-bottom: 10px;
}

</style>
