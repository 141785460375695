<template>
<div>
  <h2 align="center">考 勤 组 管 理</h2>
  <el-form ref="form" :model="form" :rules="rules" label-width="118px">
    <el-row :gutter="35">
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" >
        <el-form-item label="组名称" prop="name" style="margin-bottom:20px;">
          <el-input v-model="form.name" placeholder="请输入组名称"/>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" >
        <el-form-item label="生效日期" prop="effectiveDate">
          <el-date-picker
              v-model="form.effectiveDate"
              type="date"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              placeholder="选择生效日期">
          </el-date-picker>
        </el-form-item>
      </el-col>
    </el-row>
    <el-divider>考勤组人员</el-divider>
    <el-row :gutter="24">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" >
        <el-form-item label="参与考勤人员" prop="attGrpEmpIds" style="display:block !important" :rules="{required: true,message: '参与考勤人员不能为空', trigger: 'blur' }">
          <treeselect ref="treeRef" :disabled="isReadOnly" v-model="attGrpEmpIds" :options="employTree"
                      :limit="5" :limitText="count => `及其它${count}人`" @input="inputChange"
                      :multiple="true" value-consists-of="LEAF_PRIORITY"  noChildrenText="没有员工"
                      :normalizer="normalizeNodeDisableEmp" placeholder="请选择考勤人员"/>
        </el-form-item>
      </el-col>
<!--      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb20">-->
<!--        <el-form-item label="其他部门人员" prop="otherEmpIds">-->
<!--          <treeselect :disabled="isReadOnly" v-model="otherEmpIds" :options="employTree"-->
<!--                      :multiple="true" :flat="true" :disable-branch-nodes="true" noChildrenText="没有员工"-->
<!--                      :normalizer="normalizeNode" placeholder="请选择其他部门参与考勤人员"/>-->
<!--        </el-form-item>-->
<!--      </el-col>-->
<!--      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" >-->
<!--        <el-form-item label="无需考勤人员" prop="noAttendEmpIds" style="display:block !important">-->
<!--          <treeselect :disabled="isReadOnly" v-model="noAttendEmpIds" :options="employTree"-->
<!--                      :multiple="true" :flat="true" :disable-branch-nodes="true" noChildrenText="没有员工"-->
<!--                      :normalizer="normalizeNode" placeholder="请选择无需考勤人员"/>-->
<!--        </el-form-item>-->
<!--      </el-col>-->
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" >
        <el-form-item label="考勤管理员" prop="managerEmp" style="display:block !important">
          <treeselect :disabled="isReadOnly" v-model="managerEmp" :options="employTree"
                      :multiple="multiple" :flat="true" :disable-branch-nodes="true" noChildrenText="没有员工"
                      :normalizer="normalizeNode" placeholder="请选择考勤管理员"/>
        </el-form-item>
      </el-col>
    </el-row>
    <el-divider>考勤时间</el-divider>
    <el-row :gutter="35">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24"  align="left">
        <el-form-item label="考勤类型" prop="type">
          <el-radio-group v-model="attType">
            <el-radio-button v-for="item in AttendanceTypes" :key="item.value" :label="item.value">{{item.label}}</el-radio-button>
          </el-radio-group>
        </el-form-item>
      </el-col>
<!--      <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8" class="mb20">-->
<!--        <el-form-item label="打卡方式" prop="publicHoliday">-->
<!--          <el-input v-model="form.publicHoliday" placeholder="请输入固定班制，法定节假日是否自动排休"/>-->
<!--        </el-form-item>-->
<!--      </el-col>-->
    </el-row>
    <!--  ---------------begin    固定班           -------------      -->
    <template  v-if="attType == 1">
    <el-row :gutter="35">
      <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8" >
        <el-form-item label="快捷设置" prop="fixDef">
          <el-select v-model="form.fixDef" placeholder="请选择默认固定制班次" @change="fixDefChange">
            <el-option v-for="item in attendanceClassList" :key="item.id" :label="getFixDefLabel(item)" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8" >
        <el-form-item label="特殊日自动排" prop="publicHoliday">
          <el-radio-group v-model="form.publicHoliday">
            <el-radio-button label=true>自动排</el-radio-button>
            <el-radio-button label=false>不自动排</el-radio-button>
<!--            <el-radio-button label="3">一天三次</el-radio-button>-->
          </el-radio-group>

<!--          <el-switch-->
<!--              style="display: block"-->
<!--              v-model="form.publicHoliday"-->
<!--              active-text="自动排休"-->
<!--              inactive-text="不自动排休">-->
<!--          </el-switch>-->
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="35">
      <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8" >
        <el-form-item label="每周一" prop="week1" :rules="{required: true,message: '每周一不能为空', trigger: 'blur' }">
          <el-select v-model="form.week1" @change="onChangeUpdate" placeholder="请选择星期一班次">
            <el-option v-for="item in attendanceClassList" :key="item.id" :label="getFixDefLabel(item)" :value="item.id"></el-option>
            <el-option label="休息" :value="0"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8" >
        <el-form-item label="每周二" prop="week2" :rules="{required: true,message: '每周二不能为空', trigger: 'blur' }">
          <el-select v-model="form.week2" @change="onChangeUpdate" placeholder="请选择星期二班次">
            <el-option v-for="item in attendanceClassList" :key="item.id" :label="getFixDefLabel(item)" :value="item.id"></el-option>
            <el-option label="休息" :value="0"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8" >
        <el-form-item label="每周三" prop="week3" :rules="{required: true,message: '每周三不能为空', trigger: 'blur' }">
          <el-select v-model="form.week3" @change="onChangeUpdate" placeholder="请选择星期三班次">
            <el-option v-for="item in attendanceClassList" :key="item.id" :label="getFixDefLabel(item)" :value="item.id"></el-option>
            <el-option label="休息" :value="0"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8" >
        <el-form-item label="每周四" prop="week4" :rules="{required: true,message: '每周四不能为空', trigger: 'blur' }">
          <el-select v-model="form.week4" @change="onChangeUpdate" placeholder="请选择星期四班次">
            <el-option v-for="item in attendanceClassList" :key="item.id" :label="getFixDefLabel(item)" :value="item.id"></el-option>
            <el-option label="休息" :value="0"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8" >
        <el-form-item label="每周五" prop="week5" :rules="{required: true,message: '每周五不能为空', trigger: 'blur' }">
          <el-select v-model="form.week5" @change="onChangeUpdate" placeholder="请选择星期五班次">
            <el-option v-for="item in attendanceClassList" :key="item.id" :label="getFixDefLabel(item)" :value="item.id"></el-option>
            <el-option label="休息" :value="0"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8" >
        <el-form-item label="每周六" prop="week6" :rules="{required: true,message: '每周六不能为空', trigger: 'blur' }">
          <el-select v-model="form.week6" @change="onChangeUpdate" placeholder="请选择星期六班次">
            <el-option v-for="item in attendanceClassList" :key="item.id" :label="getFixDefLabel(item)" :value="item.id"></el-option>
            <el-option label="休息" :value="0"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8" >
        <el-form-item label="每周日" prop="week7" :rules="{required: true,message: '每周日不能为空', trigger: 'blur' }">
          <el-select v-model="form.week7" @change="onChangeUpdate" placeholder="请选择星期日班次">
            <el-option v-for="item in attendanceClassList" :key="item.id" :label="getFixDefLabel(item)" :value="item.id"></el-option>
            <el-option label="休息" :value="0"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    </template>
    <!--  ---------------end    固定班           -------------      -->
    <!--  ---------------begin    排班制           -------------      -->
    <template v-if="attType == 2">
      <el-row :gutter="35">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" >
          <el-form-item label="考勤班次" prop="scheduleIds" :rules="{required: true,message: '考勤班次不能为空', trigger: 'blur' }">
            <el-select v-model="scheduleIds" multiple @change="scheduleIdsChange" placeholder="请选择" style="width: 100%">
              <el-option v-for="item in attendanceClassList" :key="item.id" :label="getFixDefLabel(item)" :value="''+item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24" class="attendance-Class-Edit">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" >
          <el-form-item label="排班周期" prop="schedule">
            <el-row :gutter="24" class="attendance-Group-days" >
              <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8" class="mb20">
                <el-form-item label="周期天数" :rules="{required: true,message: '周期天数不能为空', trigger: 'blur' }">
                  <el-input v-model="schedule.days" style="width:120px;"> <template slot="append">天</template></el-input>
                  <el-tag>周期循环排班,最大天数31天</el-tag>
                </el-form-item>
              </el-col>
              <!-- <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8" >
                周期循环排班,最大天数31天
              </el-col> -->
            </el-row>
            <el-row :gutter="24" class="attendance-Group-days" >
              <template v-for="d in schedule.days?parseInt(schedule.days):3">
              <el-col :key="d" :xs="24" :sm="8" :md="8" :lg="8" :xl="8" class="mb20">
                <el-form-item :label="'第'+d+'天'"  :rules="{required: true,message: '第'+d+'天不能为空', trigger: 'blur' }">
                  <el-select v-model="schedule.attClass[d-1]" @change="onChangeUpdate" placeholder="请选择班次" >
                    <el-option v-for="item in scheduleClassList" :key="item.id" :label="getFixDefLabel(item)" :value="item.id"></el-option>
                    <el-option label="休息" :value='0'></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              </template>
            </el-row>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="form.id" :gutter="24">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" >
          <el-form-item label="排班" >
            <el-button type="text" @click="doAttGrpClsSch" v-permission="['/attendance/attendanceGroup/add', '/attendance/attendanceGroup/edit']">点击进行排班</el-button>
          </el-form-item>
        </el-col>
      </el-row>

    </template>
    <!--  ---------------end    排班制         -------------      -->
    <!-- -----------------begin    弹性制 自由班           -------------      -->
    <el-row :gutter="35">
      <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8"  align="left">
        <el-form-item label="自由班" prop="type">
          <el-radio-group v-model="form.freeSelectCls">
            <el-radio-button :label=true>支持</el-radio-button>
            <el-radio-button :label=false>不支持</el-radio-button>
          </el-radio-group>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8"  align="left">
        <el-form-item v-if="form.freeSelectCls" label="自由班班次" :rules="{required: true,message: '自由班班次不能为空', trigger: 'blur' }">
          <el-select v-model="freeClass" @change="onChangeUpdate" placeholder="请选择自由班班次">
            <el-option v-for="item in attendanceClassList" :key="item.id" :label="getFixDefLabel(item)" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8"  align="left">
        <el-form-item v-if="form.freeSelectCls" label="全勤天数" :rules="{required: true,message: '全勤天数不能为空', trigger: 'blur' }">
          <el-input v-model="form.fullDays" style="width:120px;"> <template slot="append">天</template></el-input>
        </el-form-item>
      </el-col>
    </el-row>

    <!-- -----------------end    弹性制  自由班       -------------      -->
    <el-row :gutter="35">
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" >
        <el-form-item label="打卡方式">
          <el-checkbox v-model="form.importClock">导入考勤数据打卡</el-checkbox>
          <el-checkbox v-model="form.cardClock">刷卡打卡</el-checkbox>
          <el-checkbox v-model="form.mobileClock">手机打卡</el-checkbox>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row v-if="form.mobileClock">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" >
        <el-form-item label="打卡地点" >
          <AmapPage :maker-position.sync="makerPosition" :addr.sync="addr" :dialogVisible.sync="positionShow" />
          <el-button type="text" @click="showAmapPage()">添加打卡地点</el-button>
        </el-form-item>
      </el-col>
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" >
        <el-tag v-for="(item,i) in form.addr" :key="item.addr" closable @close="handleCloseAddr(item)">
          打卡坐标: {{ item.position[0]?item.position[0]:'' }}, {{ item.position[1]?item.position[1]:'' }} 地址: {{ item.addr?item.addr:'' }}
          有效范围(米):<el-input class="addr-range-input" v-model="item.range"></el-input>
        </el-tag>
      </el-col>
    </el-row>
    <el-row>
      <el-col v-if="form.mobileClock" :xs="24" :sm="8" :md="8" :lg="8" :xl="8" class="mb20">
        <el-form-item label="打卡人脸认证" prop="photo">
          <el-checkbox v-model="form.photo">是否人脸认证</el-checkbox>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col v-if="form.mobileClock && form.photo" :xs="24" :sm="8" :md="8" :lg="8" :xl="8" class="mb20">
        <el-form-item label="人脸认证频率" prop="photo">
          <el-radio v-model="faceFreq" :label="1">每次打卡</el-radio>
          <el-radio v-model="faceFreq" :label="2">每月随机</el-radio>
        </el-form-item>
      </el-col>
      <el-col v-if="form.mobileClock && form.photo && faceFreq == 2" :xs="24" :sm="8" :md="8" :lg="8" :xl="8" class="mb20">
        <el-form-item label="每月随机次数" prop="photo">
          <el-input-number v-model="form.photoTimes" :min="0" :max="10" placeholder="必须小于10的正整数"></el-input-number>
        </el-form-item>
      </el-col>
<!--      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">-->
<!--        <el-form-item label="可选班次" prop="freeSelectCls">-->
<!--          <el-input v-model="form.freeSelectCls" placeholder="请输入未排班，可选择班次打卡"/>-->
<!--        </el-form-item>-->
<!--      </el-col>-->
<!--      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">-->
<!--        <el-form-item label="自由制，次时间之前算昨日打卡,默认5点" prop="freeTypeTime">-->
<!--          <el-input v-model="form.freeTypeTime" placeholder="请输入自由制，次时间之前算昨日打卡,默认5点"/>-->
<!--        </el-form-item>-->
<!--      </el-col>-->
<!--      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">-->
<!--        <el-form-item label="是否允许外勤打卡" prop="allowOut">-->
<!--          <el-input v-model="form.allowOut" placeholder="请输入是否允许外勤打卡"/>-->
<!--        </el-form-item>-->
<!--      </el-col>-->
<!--      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">-->
<!--        <el-form-item label="是否需要外勤打卡填备注 " prop="outMemo">-->
<!--          <el-input v-model="form.outMemo" placeholder="请输入是否需要外勤打卡填备注 "/>-->
<!--        </el-form-item>-->
<!--      </el-col>-->
<!--      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">-->
<!--        <el-form-item label="是否需要外勤打卡拍照" prop="outPhoto">-->
<!--          <el-input v-model="form.outPhoto" placeholder="请输入是否需要外勤打卡拍照"/>-->
<!--        </el-form-item>-->
<!--      </el-col>-->
<!--      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">-->
<!--        <el-form-item label="是否下班不用打卡" prop="noClockOff">-->
<!--          <el-input v-model="form.noClockOff" placeholder="请输入是否下班不用打卡"/>-->
<!--        </el-form-item>-->
<!--      </el-col>-->
<!--      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">-->
<!--        <el-form-item label="生效日期" prop="effectiveDate">-->
<!--          <el-date-picker clearable size="small"-->
<!--                          v-model="form.effectiveDate"-->
<!--                          type="date"-->
<!--                          value-format="yyyy-MM-dd"-->
<!--                          placeholder="选择生效日期">-->
<!--          </el-date-picker>-->
<!--        </el-form-item>-->
<!--      </el-col>-->
    </el-row>
  </el-form>
  <div slot="footer" class="dialog-footer" style="text-align: center">
    <el-button v-if="!isReadOnly" type="primary" @click="submitForm" v-permission="['/attendance/attendanceGroup/add', '/attendance/attendanceGroup/edit']">保 存</el-button>
    <el-button @click="cancel">关 闭</el-button>
  </div>
  <!-- 更新考勤统计 -->
  <el-dialog title="更新排班和考勤" :visible.sync="openStatistic" append-to-body width="70%">
    <el-form ref="formStatistic" :model="formStatistic" :rules="rulesStatistic" label-width="90px">
      <!--        <el-row :gutter="35">-->
      <!--          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb20">-->
      <!--            <el-form-item label="" prop="type">-->
      <!--              更新考勤统计时间段-->
      <!--              <el-radio-group v-model="formStatistic.range">-->
      <!--                <el-radio :label="1">本月</el-radio>-->
      <!--                <el-radio :label="2">本月</el-radio>-->
      <!--                <el-radio :label="3">下月</el-radio>-->
      <!--                <el-radio :label="4">其他</el-radio>-->
      <!--              </el-radio-group>-->
      <!--            </el-form-item>-->
      <!--          </el-col>-->
      <!--        </el-row>-->
      <el-row :gutter="35">
        <div style="padding-left: 30px;padding-top: 10px">* 如果对考勤组进行了修改，可以使用此更新，重新更新设置某时段的排班情况。<br/>
          * 如果员工调动部门，只需对某一员工排班更新，请选择员工更新，日期则选择该员工调动日期到月底。
        </div>
      </el-row>
      <el-row :gutter="35">
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
          <el-form-item :label="formStatistic.name" prop="name">
            <el-radio v-model="formStatistic.type" label="group">整组</el-radio>
            <el-radio v-model="formStatistic.type" label="employ">员工</el-radio>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
          <el-form-item v-if="formStatistic.type=='employ'" label="选择员工" prop="empId" :rules="{ required: true, message: '必须选择员工', trigger: ['blur', 'change'] }">
            <el-select style="width: 100%" v-model="formStatistic.empId" filterable remote reserve-keyword placeholder="请输入员工姓名" :remote-method="searchUpdatedEmploy"
                       :loading="loading" >
              <el-option
                  v-for="item in updatedEmploy"
                  :key="item.id"
                  :label="item.name+'('+item.idNum+')'"
                  :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <!--          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">-->
        <!--          </el-col>-->
      </el-row>
      <el-row :gutter="35">
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
          <el-form-item label="开始时间" prop="startDate">
            <el-date-picker clearable size="small"
                            v-model="formStatistic.startDate"
                            type="date"
                            value-format="yyyy-MM-dd"
                            placeholder="选择考勤统计开始时间">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
          <el-form-item label="结束时间" prop="endDate">
            <el-date-picker clearable size="small"
                            v-model="formStatistic.endDate"
                            type="date"
                            value-format="yyyy-MM-dd"
                            placeholder="选择考勤统计结束时间">
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="35">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb20">
          <el-form-item label="" prop="type">
            是否重新计算考勤打卡请假（如果是更新下月可以不计算）
            <el-radio-group v-model="formStatistic.reCaculate">
              <el-radio :label="true">计算</el-radio>
              <el-radio :label="false">不计算</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitStatistic" v-permission="['/attendance/attClockTime/add']">确 定</el-button>
      <el-button @click="cancelStatistic">取 消</el-button>
    </div>
  </el-dialog>

</div>
</template>

<script>
import {
  listAttendanceGroup,
  getAttendanceGroup,
  delAttendanceGroup,
  addAttendanceGroup,
  updateAttendanceGroup,
  exportAttendanceGroup } from "@/api/attendance/attendanceGroup";
import { listAttendanceClass } from "@/api/attendance/attendanceClass";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import permission from '@/directive/permission/index.js' // 权限判断指令
import { showLoading, hideLoading } from '@/utils/reqloading';
import { getOrgEmpTree, normalizerEmployMultiSelectTree, checkTreeIdsExist} from '@/views/pages/employ/components/empOrgTree';
import {DICT_TYPE,getDictLabelFromData, getDictDataLabel, getDictDatas} from "@/utils/dict";
import MapSelectDialog from "../components/MapSelectDialog";
import AmapPage from "../components/AmapPage";
import {reGenStatisticDay} from "@/api/attendance/attStatisticDay";
import {listSimpleEmploy} from '@/api/employ/employ';
import {getQueryParam} from "../../../../utils/tools";

export default {
  name: "attendanceGroupEdit",
  components: {
    Treeselect,
    MapSelectDialog,
    AmapPage,
  },
  props: {
    row: Object,
    isReadOnly: Boolean,
    departmentOptionsIn: Array,
  },

  directives: { permission },
  watch:{
    // row(n,o){ //n为新值,o为旧值;
    //   //if(n === o) return;
    //   this.load();
    // },
    faceFreq(){
      if(this.faceFreq == 1){
        this.form.photoTimes = 0;
      }else if(this.faceFreq == 2){
        if(this.form.photoTimes == null || this.form.photoTimes==0){
          this.form.photoTimes = 3;
        }
      }
    },
    attType(){
      this.form.type=this.attType;
    },
    positionShow(){
      if(!this.positionShow && this.addr && this.addr.length>0){
        this.form.addr.push({
          addr: this.addr,
          position: this.makerPosition,
          range: 0,
        });
      }
    },
    freeClass(){
      this.form.freeClass=this.freeClass;
    },
  },
  computed:{
  },

  data() {
    return {
      faceFreq: null,
      attGrpEmpIds:[],
      noAttendEmpIds:[],
      otherEmpIds:[],
      managerEmp:[],
      addrList: [], // 打卡地址列表
      positionShow: false,
      makerPosition: [114.027868, 22.60775],
      addr: '',
      // positionRange: 100,
      attType: 1,
      // 排班 班次id
      scheduleIds: [],
      schedule:{
        days: 1,
        attClass:[],
      },
      scheduleClassList:[],
      attendanceClassList: [],
      // 遮罩层
      loading: true,
      // 查询参数
      where: {
        query: '',
        filters: []
      },

      // 部门管理树选项
      departmentOptions: [],
      employTree: [],

      // 表单参数
      form: {
        addr:[{
          addr: '',
          position: [],
          range: 100,
          positionShow: false,
        }],
      }
      ,
      // 表单校验
      rules: {
        name: [
          {
            required: true,
            message: "组名称不能为空", trigger: "blur" }
        ],
        type: [
          {
            required: true,
            message: "考勤类型不能为空", trigger: "change"
          }
        ],
        effectiveDate: [
          {
            required: true,
            message: "生效日期不能为空", trigger: "blur" }
        ],
      },
      AttendanceTypes: DICT_TYPE.AttendanceTypes,
      openStatistic: false,
      formStatistic: {
        range: 2,
        reCaculate: true,
        type: 'group',
      },
      rulesStatistic: {
        startDate: [
          {
            required: true,
            message: "开始时间不能为空", trigger: "blur" }
        ],
        endDate: [
          {
            required: true,
            message: "结束时间不能为空", trigger: "blur" }
        ],

      },
      updatedEmploy:[],
      selectedEmpIds:[],
      multiple: true,
      // employDisableEmpTree: [],
      freeClass: '',
    };
  },
  mounted() {
    this.companyId = this.$store.getters.userInfo.companyId;
    console.log('mounted');
    console.log(this.$route.query);
    if(this.$route.query){
      this.form = this.$route.query;
      if(this.form.companyId){
        this.companyId = this.form.companyId;
      }
    }
    this.load();
    if(this.departmentOptionsIn){
      this.departmentOptions = this.departmentOptionsIn;
    }else{
    }
    showLoading();
    this.quereySelectedEmpIds(this.form);
    // 查询班次
    const classParams = this.getQueryParam({},1,1000,'');
    listAttendanceClass(classParams).then(response => {
      // const attClass = this.schedule.attClass;
      // const days = this.schedule.days;
      // this.schedule = null;
      this.attendanceClassList = response.data.records;
      this.scheduleIdsChange(this.scheduleIds);
      this.$forceUpdate();
      // this.schedule.days = days;
      // this.schedule.attClass = attClass;
    });


  },

  methods: {
    showAmapPage(){
      this.addr = "";
      this.makerPosition = [114.027868, 22.60775];
      this.positionShow = true;
    },
    handleCloseAddr(item){
      console.log(item);
      this.form.addr.splice(this.form.addr.indexOf(item),1);
    },
    load() {
      console.log('load');
      if(this.row && this.row.id){
        this.handleUpdate(this.row);
      }else if(this.form && this.form.id){
        this.copyFormToData(this.form);
        // this.handleUpdate(this.form);
      }else {
        this.reset();
        this.handleAdd();
      }
    },
    quereySelectedEmpIds(form){
      let filter = {};
      if(form && form.companyId) {
        filter['companyId'] = {
          type: 'eq',
          value: form.companyId,
        }
      }
      if(form && form.id){
        filter['id'] = {
          type: 'ne',
          value: form.id,
        }
      }
      const params =  this.getQueryParam(filter,1,1000,'');
      params.fields = 'attGrpEmpIds';
      let that = this;
      listAttendanceGroup(params).then(response => {
        const data = response.data;
        console.log("listAttendanceGroup response=")
        console.log(data)
        if(data && data.records && data.records.length>0){
          let ids = [];
          data.records.forEach(item=>{
            if(item.attGrpEmpIds){
              ids = ids.concat(item.attGrpEmpIds.split(","));
            }
          });
          console.log("ids=");
          console.log(ids);
          ids.forEach((item,index)=>{
            ids[index] = parseInt(item);
          });
          that.selectedEmpIds = ids;
        }else{
          that.selectedEmpIds = [];
        }
        console.log("that.selectedEmpIds=");
        console.log(that.selectedEmpIds);
        getOrgEmpTree(this.companyId, true,(tree, companyList, departmentList, employList )=>{
          this.employTree = tree;
          console.log('this.employTree');
          console.log(this.employTree);
          this.departmentList = departmentList;
          this.departmentOptions = this.handleTree(departmentList, "id", "pid");

          this.companyOptions = [];
          this.companyOptions = this.handleTree(companyList, "id", "pid");

          this.employList = employList;
          // 移除unkown 员工，例如已经离职的员工
          console.log('this.attGrpEmpIds');
          console.log(this.attGrpEmpIds);
          let checkedIds = [];
          checkTreeIdsExist(this.employTree, this.attGrpEmpIds,checkedIds);
          console.log("checkedIds=");
          console.log(checkedIds);
          this.attGrpEmpIds = checkedIds;
          hideLoading();
        });
      });
    },
    copyFormToData(data){
      // 初始化人脸认证次数
      if(this.form.photoTimes == 0){
        this.faceFreq = 1;
      }else if(this.form.photoTimes > 0){
        this.faceFreq = 2;
      }else{
        this.faceFreq = null;
      }
      console.log("copyFormToData");
      let ids = data.attGrpEmpIds.split(",");
      if(ids && ids.length>0 && ids[0] != ""){
        this.attGrpEmpIds = [];
        ids.forEach(id=>{
          this.attGrpEmpIds.push(parseInt(id));
        });
      }else{
        this.attGrpEmpIds = [];
      }
      this.noAttendEmpIds = data.noAttendEmpIds?data.noAttendEmpIds.split(","):null;
      this.otherEmpIds = data.otherEmpIds?data.otherEmpIds.split(","):null;
      let idsManager = data.managerEmp?data.managerEmp.split(","):null;
      if(idsManager && idsManager.length>0 && idsManager[0] != "") {
        this.managerEmp = [];
        idsManager.forEach(id => {
          this.managerEmp.push(parseInt(id));
        })
      }else{
        this.managerEmp = [];
      }

      this.attType = data.type;
      if (data.type == 2) {
        this.scheduleIds = data.scheduleIds?data.scheduleIds.split(","):[];
        this.scheduleIdsChange(this.scheduleIds);
        this.schedule = data.schedule;
      }
      if(data.addr) {
        this.addrList = data.addr;
        this.addrList.forEach((item, index) => {
          this.positionShow = false;
        });
      }
      if(data.freeClass){
        console.log("data.freeClass=")
        this.freeClass = Number.parseInt(data.freeClass);
        console.log(this.freeClass);
      }
    },
    normalizerDepartment(node) {
      return this.normalizer(node);
    },

    normalizeNode(node){
      return normalizerEmployMultiSelectTree(node,"id");
    },
    normalizeNodeDisableEmp(node){
      let data = normalizerEmployMultiSelectTree(node,"id");
      if("employ" == node.className ){
        if(this.selectedEmpIds && this.selectedEmpIds.indexOf(data.id) >= 0){
          // console.log(this.selectedEmpIds);
          // console.log("normalizeNodeDisableEmp data=");
          // console.log(data);
          data.isDisabled = true;
        }
      }
      return data;
    },
    getFixDefLabel(item){
      let str = item.name+"("+item.otherInfo.earlyOn;
      if(item.otherInfo.laterOffOver){
        str += " - 次日"+item.otherInfo.laterOff;
      }else{
        str += " - "+item.otherInfo.laterOff;
      }
      return str + ")" +item.days +"天";
    },

    fixDefChange(val){
      // this.$nextTick(()=>{
        this.form.week1 = val;
        this.form.week2 = val;
        this.form.week3 = val;
        this.form.week4 = val;
        this.form.week5 = val;
        this.form.week6 = '0';
        this.form.week7 = '0';
      // })
    },
    scheduleIdsChange(val){
      console.log(val);
      console.log(this.attendanceClassList);
      let scheduleClassList = [];
      for(let v in val){
        for(let cls in this.attendanceClassList){
          if(this.attendanceClassList[cls].id == val[v]){
            scheduleClassList.push(this.attendanceClassList[cls]);
          }
        }
      }
      console.log(scheduleClassList);
      this.scheduleClassList = scheduleClassList;
    },
    onChangeUpdate(){
      this.$forceUpdate();
    },
    // 取消按钮
    cancel() {
      this.bus.$emit('onCurrentContextmenuClick', {
        id: 1,
        path: this.$route.path,
      });
    },
    // 表单重置
    reset() {
      this.form = {};
      this.resetForm("form");
    },

    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.form.type = 1;
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      const id = row.id || this.ids
      if(row.name){
        console.log("direct use row")
        this.form = row;
        this.copyFormToData(this.form);
      }else{
        this.reset();
        console.log("query by id")
        getAttendanceGroup(id).then(response => {
          this.form = response.data;
          this.copyFormToData(this.form);
        });
      }
    },
    /** 提交按钮 */
    submitForm() {
      if(this.form.photo){
        if(this.faceFreq ==  null){
          this.msgError("选择人脸认证必须设置人脸认证频率，请选择”每次认证“还是”每月随机“");
          return;
        }else if(this.form.photoTimes > 15){
          this.msgError("每月随机次数不能大于15次");
          return;
        }
      }
      this.form.attGrpEmpIds = this.attGrpEmpIds.join(",");
      this.form.noAttendEmpIds = this.noAttendEmpIds?this.noAttendEmpIds.join(","):null;
      this.form.otherEmpIds = this.otherEmpIds?this.otherEmpIds.join(","):null;
      this.form.managerEmp = this.managerEmp?this.managerEmp.join(","):null;
      console.log("submitForm....");
      console.log(this.attGrpEmpIds);
      console.log(this.form);
      if(this.form.type == 2){
        this.form.scheduleIds = this.scheduleIds.join(",");
        this.form.schedule = this.schedule;
      }
      if(this.form.importClock || this.form.cardClock || this.form.mobileClock){
        if(this.form.mobileClock){
          this.form.addr=this.addrList;
        }
        if(this.form.freeSelectCls==true){
          if(this.form.fullDays == null || !this.form.fullDays || this.form.fullDays==undefined){
            this.msgError("支持自由班次时，全勤天数不能为空");
            return;
          }
          if(this.form.freeClass == null || !this.form.freeClass || this.form.freeClass==undefined){
            this.msgError("支持自由班次时，必须选择一个自由班次");
            return;
          }
        }
        this.$refs["form"].validate(valid => {
          if(valid) {
              if (this.form.id != null) {
                updateAttendanceGroup(this.form).then(response => {
                  this.msgSuccess("修改成功");
                  this.showUpdateSchedule();
                  // this.bus.$emit('onCurrentContextmenuClick', {
                  //   id: 1,
                  //   path: this.$route.path,
                  // });
                });
              } else {
                addAttendanceGroup(this.form).then(response => {
                  this.msgSuccess("新增成功");
                  console.log(response.data.id);
                  this.form.id = response.data.id;
                  this.showUpdateSchedule();
                  // this.bus.$emit('onCurrentContextmenuClick', {
                  //   id: 1,
                  //   path: this.$route.path,
                  // });
                });
              }
          }
        });
      }else{
        this.msgError("必须选择一种打卡方式");
      }
    },
    showUpdateSchedule(){
      let that = this;
      // 如果是排班
      if(this.attType == 2){
        this.$confirm('保存成功，现在进行排班', '提示', {
          confirmButtonText: '现在排班',
          cancelButtonText: '暂不排班',
          type: 'info'
        }).then(() => {
          that.doAttGrpClsSch();
          // this.reGenStatisticDay(that.form);
        }).catch(() => {
          that.doreGenStatisticDay();
        });
      }else{
        that.doreGenStatisticDay();
      }
    },
    doreGenStatisticDay(){
      let that = this;
      this.$confirm('保存成功，考勤组更新是否要更新排班和考勤', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'info'
      }).then(() => {
        that.reGenStatisticDay(that.form);
      }).catch(() => {
      });
    },
    reGenStatisticDay(row){
      // reGenStatisticDay();
      console.log(row);
      this.openStatistic = true;
      this.formStatistic.groupId = row.id;
      this.formStatistic.name = row.name;
      this.formStatistic.attGrpEmpIds = row.attGrpEmpIds;
    },
    submitStatistic(){
      this.$refs["formStatistic"].validate(valid => {
        if (valid) {
          let empId = ''
          console.log(this.formStatistic.empId)
          if(this.formStatistic.type == 'group') {
            empId = '';
          }else if(this.formStatistic.empId){
            empId = this.formStatistic.empId;
          }else{
            this.msgError("请选择员工");
            return;
          }
          showLoading();
          reGenStatisticDay(this.formStatistic.groupId,
              this.formStatistic.startDate, this.formStatistic.endDate,
              this.formStatistic.reCaculate, empId)
              .then(response => {
                this.openStatistic = false;
                hideLoading();
                this.msgSuccess("统计计算完成");
              });
        }
      });
    },
    cancelStatistic(){
      this.openStatistic = false;
    },
    searchUpdatedEmploy(query){
      if (query !== '') {
        this.loading = true;
        let filter = {};
        filter['name'] = {
          type: 'search',
          value: query,
        }
        const params = this.getQueryParam(filter, 1, 20, '');
        listSimpleEmploy(params).then(response=>{
          console.log(response.data.records);
          this.updatedEmploy = response.data.records;
          this.loading = false;
        });
      } else {
        this.updatedEmploy = [];
      }
    },
    selectEmploy(val){
      this.formStatistic.empId = val;
      // console.log(val)
      // let employ = null;
      // for(let i = 0; i < this.updatedEmploy.length; i++){
      //   if(val == this.updatedEmploy[i].userId){
      //     employ = this.updatedEmploy[i];
      //     break;
      //   }
      // }
      // if(employ) this.formStatistic.empId = employ.id;
    },

    doAttGrpClsSch(){
      this.$router.push({name:"attendance_attGrpClsSch_edit", query: {attGrpId: this.form.id}});
    },

    inputChange(val,instanceId){
      // console.log(val)
      if(!val) return;
      this.$emit("change",val);
      if(this.multiple){//只有多选模式下才考虑提示功能
        this.allLabel = val.map(item=>{
          let label = "";
          //getNode是我自己查找下拉树的内置方法，呕心沥血才找到的
          // console.log(this.$refs["treeRef"]);
          label = this.$refs["treeRef"].getNode(this.valueFormat == "object"?item.id:item).label;
          label = label.replace('(unknown)',"(外部)");
          return label;
        })
        let el = this.$el.querySelector(".vue-treeselect__multi-value");
        el.setAttribute("title",this.allLabel.join(", "));
      }else{
        this.removePlaceHolder();
      }
      this.addPlaceHolder(val);
    },
    //增加文字提示tooltip
    addPlaceHolder(value){
      // console.log("addPlaceHolder......")
      // console.log(value)
      let placeholder = this.$el.querySelector(".vue-treeselect__placeholder");
      let temp = value !== "_first"? value:this.value;
      if(placeholder && (!temp || !temp.length)){
        let content = placeholder.innerText;
        placeholder.parentNode.setAttribute("title",content);
      }
    },
    removePlaceHolder(){
      let placeholder = this.$el.querySelector(".vue-treeselect__placeholder");
      if(placeholder){
        placeholder.parentNode.removeAttribute("title");
      }
    },

  }
};



</script>

<style scoped>
.el-tag{
  line-height: 36px;
  white-space: normal;
  height:auto;
}
.addr-range-input{
  width: 78px;
  height: 28px;
}
</style>
