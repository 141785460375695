<template>
  <div>
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <el-row :gutter="35">
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
          <el-form-item label="消息类型" prop="msgType">
            <el-select v-model="form.msgType" placeholder="请选择消息类型">
              <el-option
                  v-for="dict in msgTypeOptions"
                  :key="dict.dictValue"
                  :label="dict.dictLabel"
                  :value="dict.dictValue"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
          <el-form-item label="消息级别" prop="msgLevel">
            <el-select v-model="form.msgLevel" placeholder="请选择消息级别" clearable
                       size="small">
              <el-option
                  v-for="dict in msgLevelOptions"
                  :key="dict.dictValue"
                  :label="dict.dictLabel"
                  :value="dict.dictValue"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">
          <el-form-item label="消息标题" prop="title">
            <el-input v-model="form.title" type="textarea" placeholder="请输入内容"/>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">
          <el-form-item label="消息内容">
            <tinymce ref="editor" :key="tinymceFlag" v-model="form.content" :height="300" />
<!--            <editor v-model="form.content" :min-height="192"/>-->
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
          <el-form-item label="短信通知" prop="sms">
            <el-select v-model="form.sms" placeholder="请选择是否短信通知">
              <el-option label="是" :value="true"></el-option>
              <el-option label="否" :value="false"></el-option>
            </el-select>
          </el-form-item>
        </el-col>

<!--        <el-col :span="22" :offset="3">-->
<!--          <el-radio-group v-model="pubType">-->
<!--            <el-radio label="1" @change="chooseDTChange">立即发布</el-radio>-->
<!--            <el-radio label="2" @change="chooseDTChange">定时发布</el-radio>-->
<!--          </el-radio-group>-->
<!--        </el-col>-->
<!--        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">-->
<!--          <el-form-item label="发送时间" prop="startTime">-->
<!--            <el-date-picker clearable size="small"-->
<!--                            v-model="form.startTime"-->
<!--                            type="datetime"-->
<!--                            value-format="yyyy-MM-dd hh:mm"-->
<!--                            placeholder="选择生效开始时间">-->
<!--            </el-date-picker>-->
<!--          </el-form-item>-->
<!--        </el-col>-->
<!--        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">-->
<!--          <el-form-item label="失效时间" prop="endTime">-->
<!--            <el-date-picker clearable size="small"-->
<!--                            v-model="form.endTime"-->
<!--                            type="datetime"-->
<!--                            value-format="yyyy-MM-dd hh:mm"-->
<!--                            placeholder="选择生效结束时间">-->
<!--            </el-date-picker>-->
<!--          </el-form-item>-->
<!--        </el-col>-->
<!--        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">-->
<!--          <el-form-item label="发送人" prop="senderId">-->
<!--            <el-input v-model="form.senderId" placeholder="请输入发送人"/>-->
<!--          </el-form-item>-->
<!--        </el-col>-->
<!--        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">-->
<!--          <el-form-item label="撤回" prop="recall">-->
<!--            <el-input v-model="form.recall" placeholder="是否撤回"/>-->
<!--          </el-form-item>-->
<!--        </el-col>-->
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">
          <el-form-item label="接收人" prop="receivers">
            <el-button size="mini" type="primary" @click="selectReceivers">选择接收人</el-button>
            <el-tag
                v-for="tag in form.receivers"
                :key="tag.name"
                closable
                size="mini">
              {{tag.name}}
            </el-tag>
<!--            <el-input v-model="form.receivers" type="textarea" placeholder="请输入内容"/>-->
          </el-form-item>
        </el-col>

      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer" style="text-align: right">
      <el-button type="primary" @click="preview">预 览</el-button>
      <el-button type="primary" @click="submitForm2" v-permission="['/msg/msg/add', '/msg/msg/edit']">存 稿</el-button>
      <el-button type="primary" @click="submitForm" v-permission="['/msg/msg/add', '/msg/msg/edit']">发 布</el-button>
      <el-button @click="cancel">重 置</el-button>
    </div>
    <el-dialog :title="title" :visible.sync="open" append-to-body>
      <EmpTreeSelect @cancel="selectReceiversCancel" @selected="selected"></EmpTreeSelect>
    </el-dialog>
    <el-dialog :title="previewTitle" :visible.sync="previewOpen" width="90%" append-to-body>
      <MsgPreview :msg="form" @close="previewClose"></MsgPreview>
    </el-dialog>
  </div>
</template>

<script>
import {
  getMsg,
  addMsg,
  updateMsg } from "@/api/msg/msg";
import Tinymce from '@/components/Tinymce'
// import Editor from '@/components/Editor'
import permission from '@/directive/permission/index.js' // 权限判断指令
import { formatDate } from '@/utils/formatTime';
import EmpTreeSelect from '../../employ/components/EmpTreeSelect'
import {getLeafList} from "../../employ/components/empOrgTree";
import MsgPreview from "./msgPreview";

export default {
  name: "MsgEdit",
  components: {
    MsgPreview,
    Tinymce,
    EmpTreeSelect,
    // Editor,
  },
  props: {
    row: Object,
    isReadOnly: Boolean,
    msgTypeOptionsIn: Array,
    msgLevelOptionsIn: Array,
  },
  directives: { permission },
  watch:{
    row(n,o){ //n为新值,o为旧值;
      //if(n === o) return;
      this.load();
    },
  },
  activated() {
    // this.tinymceFlag++;
  },
  data(){
    return {

      tinymceFlag: 1,
      msgTypeOptions: [],
      msgLevelOptions: [],
      open: false,
      title: '发布通知公告',
      previewTitle: '通知公告预览',
      previewOpen: false,
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        // companyId: [
        //   {
        //     required: true,
        //     message: "所属公司不能为空", trigger: "blur"
        //   }
        // ],
        msgType: [
          {
            required: true,
            message: "消息类型，不能为空", trigger: "change"
          }
        ],
        msgLevel: [
          {
            required: true,
            message: "消息级别，不能为空", trigger: "blur"
          }
        ],
        title: [
          {
            required: true,
            message: "消息标题不能为空", trigger: "blur"
          }
        ],
        // senderId: [
        //   {
        //     required: true,
        //     message: "发送人不能为空", trigger: "blur"
        //   }
        // ],
      }
    }
  },
  created() {
    this.load();
  },
  methods:{
    preview(){
      this.previewOpen = true;
    },
    previewClose(){
      this.previewOpen = false;
    },
    selectReceivers(){
      this.title = "请选择接收人";
      this.open = true;
    },
    selected(data){
      console.log("selected:")
      console.log(data.data)
      let receivers = [];
      getLeafList(receivers, data.data);
      this.form.receivers = receivers;
      this.open = false;
    },

    selectReceiversCancel(){
      this.open = false;
    },
    chooseDTChange(){
      this.$forceUpdate();
      console.log(this.pubType);
      if(this.pubType==1){
        this.form.startTime = formatDate(new Date(), 'YYYY-mm-dd HH:MM:SS');
      }
    },
    load() {
      this.reset();
      let _this = this;
      if(this.msgLevelOptionsIn){
        this.msgLevelOptions=this.msgLevelOptionsIn;
      }else{
        this.getDicts("msg_level").then(response => {
          _this.msgLevelOptions = response.data;
        });
      }
      if(this.msgTypeOptionsIn){
        this.msgTypeOptions = this.msgTypeOptionsIn;
      }else{
        this.getDicts("msg_type").then(response => {
          _this.msgTypeOptions = response.data;
          console.log(response);
        });
      }

      if(this.row){
        this.handleUpdate(this.row);
      }else{
        this.handleAdd();
      }
    },
    // 取消按钮
    cancel() {
      // this.open = false;
      this.reset();
      //this.$emit("close", {refresh:false,isSuccess:true});
    },
    // 表单重置
    reset() {
      this.form = {};
      // this.$refs["editor"]
    },

    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.form.senderId = this.$store.getters.userInfo.id;
      this.form.senderName = this.$store.getters.name;
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.id || this.ids
      getMsg(id).then(response => {
        this.form = response.data;
      });
    },

    submit(){
      this.$refs["form"].validate(valid => {
            if(valid) {
              console.log(this.form);
              if(this.form.startTime && this.form.startTime.length == 16){
                this.form.startTime = this.form.startTime+":00";
              }
              if(this.form.endTime && this.form.endTime.length == 16){
                this.form.endTime = this.form.endTime+":00";
              }
              // this.form.startTime = formatDate(this.form.startTime, 'YYYY-mm-dd HH:MM:SS');
              // this.form.endTime = formatDate(this.form.endTime, 'YYYY-mm-dd HH:MM:SS');
              if (this.form.id) {
                let status = this.form.status;
                updateMsg(this.form).then(response => {
                  if(status == 2){
                    this.msgSuccess("发布成功");
                  }else{
                    this.msgSuccess("存稿成功");
                  }
                  this.$emit("close", {refresh:true,isSuccess:true});
                });
              } else {
                addMsg(this.form).then(response => {
                  if(status == 2){
                    this.msgSuccess("发布成功");
                  }else{
                    this.msgSuccess("存稿成功");
                  }
                  this.$emit("close", {refresh:true,isSuccess:true});
                });
              }
            }
          }
      );
    },
    /** 提交按钮 */
    submitForm() {
      this.form.status = 2;
      this.submit();
    },
    submitForm2() {
      this.form.status = 1;
      this.submit();
    }
  }
}
</script>

<style scoped>

</style>
