<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px">
      <el-form-item label="公司名称" prop="companyname">
        <el-input
            v-model="queryParams.companyname"
            placeholder="请输入公司名称"
            clearable
            size="small"
            @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
            type="primary"
            plain
            icon="el-icon-plus"
            size="mini"
            @click="handleAdd"
            v-permission="['/org/company/add']"
        >新增
        </el-button>
        <el-button
            type="primary"
            plain
            icon="el-icon-s-home"
            size="mini"
            @click="handleChart"
            v-permission="['/org/company/findAll','/org/company/add','/org/company/edit']"
        >组织架构
        </el-button>
      </el-col>
      <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>
    </el-row>

    <el-table
        v-loading="loading"
        :data="companyList"
        row-key="id"
        default-expand-all
        :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
        style="width:100%" stripe border
    >
      <el-table-column label="公司名称" align="center" prop="companyName" show-overflow-tooltip width="218"/>
      <el-table-column label="编号" align="center" prop="num"/>
      <el-table-column label="编制人数" align="center" prop="planCount"/>
      <el-table-column label="在编人数" align="center" prop="realCount"/>
      <el-table-column label="缺编人数" align="center">
        <template slot-scope="scope"><span v-if="scope.row.planCount&&(scope.row.planCount-scope.row.realCount)>0" style="color: #c75151">
          {{scope.row.planCount - scope.row.realCount}}</span></template>
      </el-table-column>
      <el-table-column label="法人" align="center" prop="register"/>
      <el-table-column label="公司类型" align="center" prop="companyType"
                       :formatter="companytypeFormat"/>
      <el-table-column label="公司规模" align="center" prop="companySize"
                       :formatter="companysizeFormat"/>
      <el-table-column label="区域" align="center" prop="regionId"
                       :formatter="regionidFormat"/>
      <el-table-column label="启用" align="center" prop="enabled">
        <template slot-scope="scope">
          <el-switch disabled
                     v-model="scope.row.enabled"
                     active-color="#13ce66"
                     inactive-color="#ff4949">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="handleUpdate(scope.row)"
              v-permission="['/org/company/edit']"
          >修改
          </el-button>
          <el-button v-if="scope.row.id != 1"
              size="mini"
              type="text"
              icon="el-icon-delete"
              @click="handleDelete(scope.row)"
              v-permission="['/org/company/remove']"
          >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 添加或修改公司对话框 -->
    <el-dialog :title="title" :visible.sync="open" append-to-body>
      <CompanyEdit :row="row" :companytypeOptionsIn="companytypeOptions" :companyListIn="companyList" :companysizeOptionsIn="companysizeOptions"
                   :regionidOptionsIn="regionidOptions" :ts="ts" @close="close"></CompanyEdit>
    </el-dialog>
  </div>
</template>

<script>
import {
  listCompany,
  getCompany,
  delCompany,
  addCompany,
  updateCompany,
  exportCompany } from "@/api/org/company";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import RightToolbar from "@/components/RightToolbar";
import permission from '@/directive/permission/index.js'; // 权限判断指令
import CompanyEdit from './companyEdit.vue';
// 表查询配置
const dbOrderBy = ' seq asc '
// 分页配置
const pageSize = 200
const pageCurrent = 1
export default {
  name: "CompanyList",
  components: {
    Treeselect,
    CompanyEdit,
    RightToolbar,
  },

  directives: { permission },

  data() {
    return {
      ts: null,
      row: null,
      // 遮罩层
      loading: true,
      // 显示搜索条件
      showSearch: true,
      // 公司表格数据
      companyList: [],
      // 公司树选项
      companyOptions: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 查询参数
      where: {
        query: '',
        filters: []
      },
      orderby: dbOrderBy,
      options: {
        pageSize,
        pageCurrent
      },
      // 公司类型，字典代码字典
      companytypeOptions: [],
      // 公司规模，字典代码 大中小字典
      companysizeOptions: [],
      // 区域id字典
      regionidOptions: [],
      // 查询参数
      queryParams: {
        companyName: null,
      },
      // 表单参数
      form: {
      }
      ,
      // 表单校验
      rules: {
        companyName: [
          {
            required: true,
            message: "公司名称不能为空", trigger: "blur" }
        ],
        parentId: [
          {
            required: true,
            message: "父公司不能为空", trigger: "blur" }
        ],
        num: [
          {
            required: true,
            message: "公司编号不能为空", trigger: "blur" }
        ],
        enabled: [
          {
            required: true,
            message: "是否启用： 1启用，0不启用不能为空", trigger: "blur" }
        ],
      }
    }
        ;
  },
  created() {
    this._filter = {};
    this.getList();
    this.getDicts("sys_company_type").then(response => {
      this.companytypeOptions = response.data;
    });
    this.getDicts("sys_company_size").then(response => {
      this.companysizeOptions = response.data;
    })
    ;
    this.getDicts("sys_location_region").then(response => {
      this.regionidOptions = response.data;
    })
    ;
  },
  methods: {
    /** 查询公司列表 */
    getList() {
      this.loading = true;
      this._filter = {};
      if(this.queryParams.companyName) {
        this._filter['companyName'] = {
          type: 'search',
          value: this.queryParams.companyName,
        }
      }
      const filters = this.parseFilters(this._filter)
      if (filters && filters.length > 0) {
        this.where.filters = filters
      } else {
        // 如果过滤条件为空，设置空数组
        this.where.filters = []
      }
      const params = {
        pageNum: this.options.pageCurrent,
        pageSize: this.options.pageSize,
        where: this.where,
        order: this.orderby
      }

      listCompany(params).then(response => {
        this.companyList = this.handleTree(response.data.records, "id", "parentId");
        this.loading = false;
      });
    },

    // 公司类型，字典代码字典翻译
    companytypeFormat(row, column) {
      return this.selectDictLabel(this.companytypeOptions, row.companyType);
    },
    // 公司规模，字典代码 大中小字典翻译
    companysizeFormat(row, column) {
      return this.selectDictLabel(this.companysizeOptions, row.companySize);
    },
    // 区域id字典翻译
    regionidFormat(row, column) {
      return this.selectDictLabel(this.regionidOptions, row.regionId);
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.row = null;
      this.open = true;
      this.title = "添加公司";
      this.ts = Date.parse(new Date());
      console.log(this.ts);
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      if(row.id){
        this.row = row;
      }else{
        this.row = {};
        this.row.id = this.ids;
      }
      this.open = true;
      this.title = "修改公司";
    },
    close(arg){
      this.open = false;
      if(arg.refresh){
        this.getList();
      }
    },

    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm('是否确认删除公司"' + row.companyName + '"的数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return delCompany([row.id]);
      }).then(() => {
        this.getList();
        this.msgSuccess("删除成功");
      })
    },
    handleChart(){
      this.$router.push("/pages/org/chart/index")
    },
  }
};
</script>
