<template>
<div>
  <el-form ref="form" :model="form" label-width="86px" class="attendance-Class-records">
    <el-tabs v-model="activeName" type="border-card">
      <el-tab-pane label="考勤记录" name="attRecordInfo">
        <el-row style ="border-width: 1px;border-style: solid;border-bottom: none;border-color: antiquewhite;">
          <div class="att-record-h">
            <div>
              <span style="color: #439924; font-size: large">{{days?days:0}}天</span>
              <div>应出勤</div>
            </div>
            <div>
              <span style="color: #0f1d99; font-size: large">{{clockDays?clockDays:0}}天</span>
              <div>实际出勤</div>
            </div>
          </div>
        </el-row>
        <el-row style ="border-width: 1px;border-style: solid;border-top: none;border-color: antiquewhite;">
          <div class="att-record-h">
            <div>
              <span style="color: #ffd500; font-size: large">{{lateTimes?lateTimes:0}}次</span>
              <div>迟到</div>
            </div>
            <div>
              <span style="color: #b615bb; font-size: large">{{earlyTimes?earlyTimes:0}}次</span>
              <div>早退</div>
            </div>
            <div>
              <span style="color: #ff0000; font-size: large">{{absentDay?absentDay:0}}天</span>
              <div>旷工</div>
            </div>
          </div>
        </el-row>
<!--        <el-divider></el-divider>-->
        <el-row style="padding: 10px" v-if="dateClocktime">
          <div class="att-record-h">
            <div>
              <span>{{clockDate}}</span>
            </div>
            <div>
              <span>{{dateClocktime.attClassName}}</span>
            </div>
            <div>
              <span v-if="dateClocktime.attType=='正常出勤' || dateClocktime.attType=='休息日'" style="color: #0734d5">
<!--                {{dateClocktime.attType}}{{dateClocktime.absentDay>0?(dateClocktime.absentDay+'天'):''}}-->
              </span>
              <span v-else-if="dateClocktime.attType=='旷工'" style="color:#f51f1f;">{{dateClocktime.attType}} {{dateClocktime.absentDay>0?(dateClocktime.absentDay+'天;'):''}}</span>
              <span v-else-if="dateClocktime.attType=='打卡异常'" style="color:#f51f1f;">{{dateClocktime.lateTimes>0?('迟到'+dateClocktime.lateTimes+'次'):''}}{{dateClocktime.earlyTimes>0?('早退'+dateClocktime.earlyTimes+'次'):''}}</span>
              <span v-if="dateClocktime.clockDays">{{"打卡："+dateClocktime.clockDays+"天;"}}</span>
<!--              <span>{{dateClocktime.otherInfo.attStatus1?'上午:'+dateClocktime.otherInfo.attStatus1+';':""}}{{dateClocktime.otherInfo.attStatus2?'下午:'+dateClocktime.otherInfo.attStatus2:""}}</span>-->
            </div>
            <template v-if="dateClocktime.otherInfo && dateClocktime.otherInfo.leaveInfo">
            <span v-for="type in dateClocktime.otherInfo.leaveInfo.leaveTypes" :key="type">
              {{type+":"+dateClocktime.otherInfo.leaveInfo[type]["leaveDays"]+"天"}}
            </span>
            </template>
         </div>
        </el-row>
        <template v-if="dateClocktime" >
          <el-row v-for="(t)  in dateClocktime.times" :key="'clockSetting'+t">
            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
<!--            <div class="att-record-h" style="justify-content: flex-start">-->
              <span style="justify-content: flex-start;padding-left: 18px;">上班：{{dateClocktime.clockSetting["clockOn"+(t)]}}</span>
              <span style="justify-content: flex-start;padding-left: 18px;" v-if="dateClocktime.clockTime['clockTimeOn'+(t)]">打卡：{{dateClocktime.clockTime["clockTimeOn"+(t)]}}</span>
              <span style="justify-content: flex-start;padding-left: 18px; color: #ffd500" v-else>打卡：{{dateClocktime.clockTime["clockTimeOnNear"+(t)]?dateClocktime.clockTime["clockTimeOnNear"+(t)]+"(无效)":""}}</span>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
<!--            </div>-->
<!--            <div class="att-record-h" style="justify-content: flex-start; ">-->
              <span style="justify-content: flex-start;padding-left: 18px;">下班：{{dateClocktime.clockSetting["clockOff"+(t)]}}</span>
              <span style="justify-content: flex-start;padding-left: 18px;" v-if="dateClocktime.clockTime['clockTimeOff'+(t)]">打卡：{{dateClocktime.clockTime["clockTimeOff"+(t)]}}</span>
              <span style="justify-content: flex-start;padding-left: 18px; color: #ffd500" v-else>打卡：{{dateClocktime.clockTime["clockTimeOffNear"+(t)]?dateClocktime.clockTime["clockTimeOffNear"+(t)]+"(无效)":""}}</span>
            </el-col>
<!--            </div>-->
          </el-row>
        </template>
        <el-row>
          <div>
            <el-calendar v-model="attCalValue" style="padding-left: 0px !important;padding-right: 0px !important;" >
              <template slot="dateCell" slot-scope="{date, data}">
                <p :class="data.isSelected ? 'is-selected' : ''" style="text-align: center">
                  {{ data.day.split('-')[2] }} {{ data.isSelected ? '✔️' : ''}}
                </p>
<!--                <p v-if="statisticEmploy[data.day] && statisticEmploy[data.day].attClassName" :style="{'text-align': 'center','font-size': isUniapp?'xx-small':'medium'}">-->
<!--                  {{statisticEmploy[data.day].attClassName}}-->
<!--                </p>-->
                <p v-if="statisticEmploy[data.day] && '未完成'==statisticEmploy[data.day].attType" :style="{'text-align': 'center','font-size': isUniapp?'xx-small':'medium'}"></p>
                <p v-else-if="statisticEmploy[data.day] && statisticEmploy[data.day].attType=='休息日'" :style="{'text-align': 'center','font-size': isUniapp?'xx-small':'medium', 'color': '#0ea586'}">休息</p>
                <p v-else-if="statisticEmploy[data.day] && statisticEmploy[data.day].attType=='正常出勤'" :style="{'text-align': 'center','font-size': isUniapp?'xx-small':'medium', 'color': '#0734d5'}">正常</p>
                <p v-else-if="statisticEmploy[data.day] && statisticEmploy[data.day].attType" :style="{'text-align': 'center','font-size': isUniapp?'xx-small':'medium', 'color': '#de1533'}">
                  {{ statisticEmploy[data.day].attType }}
<!--                  {{statisticEmploy[data.day].absentTime>0?Math.round(statisticEmploy[data.day].absentTime/60):""}}-->
                </p>
              </template>
            </el-calendar>
          </div>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="请假记录" name="baseSetting">
        <el-form :model="leaveQuery" ref="queryForm" :inline="true" label-width="68px">
          <el-form-item label="请假类型">
            <el-select v-model="leaveQuery.type" multiple clearable @change="typeChange" placeholder="请假类型" size="small">
              <el-option v-for="item in getDictDatas(DICT_TYPE.BPM_LEAVE_TYPE)" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="请假月份">
            <el-date-picker type="month" clearable size="small" v-model="leaveQuery.leaveMonth" value-format="yyyy-MM-dd" placeholder="休假月份"/>
          </el-form-item>
<!--          <el-form-item label="状态">-->
<!--            <el-select v-model="leaveQuery.status" multiple placeholder="状态" clearable size="small">-->
<!--              <el-option label="审核中" value="" />-->
<!--              <el-option label="不同意" :value="0" />-->
<!--              <el-option label="同意" :value="1" />-->
<!--            </el-select>-->
<!--          </el-form-item>-->
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
            <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
          </el-form-item>
        </el-form>
        <el-table v-loading="loading" :data="leaveList" stripe border>
<!--          <el-table-column type="expand">-->
<!--            <template slot-scope="props">-->
<!--              <el-table :data="props.row.itemInfo" show-summary :summary-method="getSummaries" stripe border>-->
<!--                <el-table-column label="项目名" align="center" prop="itemName"/>-->
<!--                <el-table-column label="类型" align="center" prop="itemType"/>-->
<!--                <el-table-column label="增减项" align="center" prop="plusMinus"/>-->
<!--                <el-table-column label="值类型" align="center" prop="valueType"/>-->
<!--                <el-table-column label="考勤比例发放" align="center" prop="isAttend">-->
<!--                  <template slot-scope="scope">-->
<!--                    {{scope.row.isAttend?"是":"否"}}-->
<!--                  </template>-->
<!--                </el-table-column>-->
<!--                <el-table-column label="金额" align="center" prop="amount"/>-->
<!--              </el-table>-->
<!--            </template>-->
<!--          </el-table-column>-->
          <el-table-column label="类型" align="center" prop="type" width="80" show-overflow-tooltip sortable/>
          <el-table-column label="状态" align="center" prop="approved" width="80" show-overflow-tooltip sortable>
            <template slot-scope="scope">
              <el-tag v-if="scope.row.approved == 1" type="success">同意</el-tag>
              <el-tag v-else-if="scope.row.approved == 0" type="danger">不同意</el-tag>
              <el-tag v-else type="warning">审核中</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="开始时间" align="center" prop="beginTime" width="138" show-overflow-tooltip sortable/>
          <el-table-column label="结束时间" align="center" prop="endTime" width="138" show-overflow-tooltip sortable/>
<!--          <el-table-column label="所属公司" align="center" prop="companyId" show-overflow-tooltip width="210" sortable>-->
<!--            <template slot-scope="scope">-->
<!--              {{companyFormat(scope.row, null)}}-->
<!--            </template>-->
<!--          </el-table-column>-->
        </el-table>

        <pagination v-show="total>0" :total="total" :page.sync="leaveQuery.pageNum" :limit.sync="leaveQuery.pageSize" @pagination="getLeave"/>
<!--        <el-row>-->
<!--          <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">-->

<!--          </el-col>-->
<!--        </el-row>-->
      </el-tab-pane>
      <el-tab-pane label="考勤设置" name="attSetting">
        <Setting></Setting>
      </el-tab-pane>
    </el-tabs>
  </el-form>

</div>
</template>

<script>
import { statisticByEmp, listAttStatisticDay } from "@/api/attendance/attStatisticDay";
import {getFirstLastDayOfMonth, getDayAll, getYearMonth,getDateStr} from "@/utils/dateTimeUtils";
import {formatDate} from "@/utils/formatTime";
import {listAttLeave} from "@/api/bpm/attLeave";
import Pagination from "@/components/Pagination";
import { Local } from '@/utils/storage.js';
import Setting from './setting';

export default {
  name: "records",
  components: {
    Pagination,
    Setting,
  },
  props: {
    employ: Object,
  },
  watch: {
    attCalValue(){
      console.log("attCalValue")
      console.log(this.attCalValue);
      this.selectedDay = getDateStr(this.attCalValue)
      console.log(this.statisticEmploy[this.selectedDay])
      this.getDateClocktime(this.attCalValue)
      let ym = getYearMonth(this.attCalValue);
      console.log(ym)
      if(ym != this.curMonth){
        this.getMonthData(this.attCalValue)
      }
    },
    employ:{
      handler:function(newValue,oldValue){
        this.getMonthData(new Date());
        this.getLeave();
      },
      // immediate:true,
      deep:true,
    }
  },
  computed: {
    isUniapp(){
      return Local.get("isUniapp")? true: false
    },
  },

  data() {
    return {
      loading: true,
      activeName: 'attRecordInfo',
      // 表单参数
      form: {},
      attCalValue: null,
      selectedDay: null,
      statisticEmploy: {},
      staticEmpLoading: true,
      curMonth: null,
      queryParams: {
        empName: null,
        attType: null,
        clockDateStart: null,
        clockDateEnd: null,
      },
      lateTimes: 0,
      earlyTimes: 0,
      absentTime: 0,
      absentDay: 0, // 旷工天
      days: 0,
      clockDays: 0,
      dateClocktime:{},
      // 总条数
      total: 0,
      leaveQuery:{
        pageNum: 1,
        pageSize: 10,
        type: null,
        leaveMonth: null,
        status: null,
      },
      leaveList:[],
    }
  },
  created() {
    this.getMonthData(new Date());
    this.getLeave();
  },
  mounted() {

  },

  methods: {
    getMonthData(date){
      this.getDateClocktime(date)
      console.log("getMonthData")
      // console.log(date)
      this.curMonth = getYearMonth(date);
      // console.log(this.$store.getters.userInfo.employ)
      let empId = null;
      console.log(this.employ);
      if(this.employ && this.employ.employId){
        empId = this.employ.employId;
      }
      else if(!this.$store.getters.userInfo.employ || !this.$store.getters.userInfo.employ.id){
        this.$message.error('当前账号不是员工，不能查看本人考勤信息！');
        return
      }else{
        empId = this.$store.getters.userInfo.employ.id;
      }

      let queryMonth = date || new Date()
      // console.log(queryMonth)
      const [start, end] = getFirstLastDayOfMonth(queryMonth);
      this.queryParams.clockDateStart = start;
      this.queryParams.clockDateEnd = end;
      let params = {
        start: this.queryParams.clockDateStart, end: this.queryParams.clockDateEnd, empId: empId
      }
      statisticByEmp(params).then(response => {
        let records = response.data;
        // console.log(records);
        this.staticEmpLoading = false;
        if(!records || records.length <= 0){
          this.msgInfo("没有考勤统计信息");
          this.statisticEmployList = [];
        }
        let keys = Object.keys(records);
        let list = [];
        if (!keys || keys.length <= 0){
          this.msgInfo("没有考勤统计信息");
          this.statisticEmployList = [];
          return;
        }
        keys.forEach(k => {
          let empList = records[k];
          console.log(empList);
          empList.forEach(e => {
            list.push(e);
          });
        });
        console.log(list);
        // this.total = response.data.total;
        this.statisticEmploy = list[0];
        let startEndDays = getDayAll(this.queryParams.clockDateStart, this.queryParams.clockDateEnd);
        let lateTimes = 0;
        let earlyTimes = 0;
        let absentTime = 0;
        let absentDay = 0; // 矿工天数
        let days = 0;
        let clockDays = 0;
        let oneDayTotalHours = 0;
        // 月初到月末数据
        let isFreeClass = false; // 是否是自由班
        let fullDays = 0; // 全勤天数
        startEndDays.forEach(day=>{
          const item = this.statisticEmploy[day];
          if(item){
            // if(item.otherInfo && item.otherInfo.totalAttHours){
            //   oneDayTotalHours = item.otherInfo.totalAttHours?item.otherInfo.totalAttHours:8;
            //   absentDay += Number((item.absentTime/60) /Number(oneDayTotalHours));
            // }
            lateTimes += item.lateTimes
            earlyTimes += item.earlyTimes
            absentTime += item.absentTime
            absentDay += item.absentDay
            days += item.days?item.days:0;
            clockDays += item.clockDays?item.clockDays:0;
            isFreeClass = item.freeSelectCls;
            fullDays = item.fullDays;
            // console.log("days = "+item.days+",clockDays = "+item.clockDays)
          }
          // if(item.absentTime>0){
          //   console.log("day:"+day+",item.absentTime="+item.absentTime)
          // }
        })
        this.lateTimes = lateTimes;
        this.earlyTimes = earlyTimes;
        this.absentTime = ((absentTime / 60) /oneDayTotalHours).toFixed(2)
        this.absentDay = absentDay.toFixed(2);
        this.days = days;
        if(isFreeClass) this.days = fullDays; // 自由班，应出勤天数为全勤天数
        this.clockDays = clockDays;
        // console.log("days = "+days+",clockDays = "+clockDays)
        //解决表格列乱排布问题
        // let self = this
        // this.$nextTick(function () {
        //   self.$refs.staticEmp.doLayout();
        // })
        this.staticEmpLoading = false;
      });

    },

    getDateClocktime(data){
      console.log(data+",getDateClocktime"); //选中某天
      let selectedDate = new Date(data);
      this.clockDate = formatDate(selectedDate, 'YYYY-mm-dd');
      this.dateClocktime = this.statisticEmploy[this.clockDate];
    },
    getLeave(){
      let filter = {};
      if(this.leaveQuery.leaveMonth){
        console.log(this.leaveQuery.leaveMonth)
        const [start, end] = getFirstLastDayOfMonth(this.leaveQuery.leaveMonth);
        filter['beginTime'] = {
          type: 'ge',
          value: start+" 00:00:00",
        }
        filter['endTime'] = {
          type: 'le',
          value: end+" 23:59:59",
        }

      }
      if(this.leaveQuery.type){
        console.log(this.leaveQuery.type)
        filter['type'] = {
          type: 'in',
          value: this.leaveQuery.type,
        }
      }
      if(this.leaveQuery.status){
        console.log(this.leaveQuery.status)
        filter['approved'] = {
          type: 'in',
          value: this.leaveQuery.status,
        }
      }
      if(this.employ && this.employ.id){
        filter['userId'] = {
          type: 'eq',
          value: this.employ.userId,
        }
      }else{
        filter['userId'] = {
          type: 'eq',
          value: this.$store.getters.userInfo.id,
        }
      }
      // filter['userId'] = {
      //   type: 'eq',
      //   value: this.$store.getters.userInfo.id,
      // }
      const params = this.getQueryParam(filter, 1, 10, ' beginTime desc');
      listAttLeave(params).then(response => {
        console.log(response)
        this.leaveList = response.data.records;
        this.total = response.data.total;
        this.loading = false;
        if(response.data.records.length == 1){
        }else{
        }
      });
    },
    typeChange(){

    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.leaveQuery.pageNum = 1;
      this.getLeave();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },

  },
}
</script>

<style scoped>

.att-record-h{
  display: flex;
  align-items: center;
  align-content: center;
  text-align: center;
  justify-content: space-around;
}

.el-calendar-table .el-calendar-day {
  padding: 0px !important;
}
</style>
