<template>
	<div>
		<el-form :model="course" label-width="90px" ref="form" >
			<el-row :gutter="24">
				<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb10">
					<!-- 当前公司或子公司列表 -->
					<el-form-item label="公司" :rules="[{ required: true, message: '公司不为空。', trigger: 'blur' }]" >
						<!-- <el-input v-model="course.companyName" readonly="true"></el-input> -->
						<el-select v-model="course.companyName" clearable  placeholder="请选择" collapse-tags ref="courseEditcompanySelected" >
							<el-option :value="course.companyId" :label="course.companyName" style="height: auto">
								<el-tree
									:data="courseEditcompanyOptions"
									:props="defaultProps"
									:expand-on-click-node="false"
									ref="tree"
									show-checkbox
									node-key="id"
									default-expand-all
									@node-click="handleNodeClick"
									@check="handleNodeClick"
									highlight-current
								/>
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb10">
					<el-form-item
						label="课程名称"
						prop="courseName"
						:rules="[{ required: true, message: '课程名称不能为空', trigger: 'blur' }]"
					>
						<el-input v-model="course.courseName" clearable></el-input>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">
					<el-form-item label="课程分类"  prop="courseType" :rules="[{ required: true, message: '课程分类必选', trigger: 'blur' }]">
						<el-select v-model="course.courseType" style="width:100%;" placeholder="请选择课程类型">
							<el-option v-for="item in dictCourseTypes" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue" />
						</el-select>
					</el-form-item>
				</el-col>               
				<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">
					<el-form-item label="文件分类"  prop="fileType" :rules="[{ required: true, message: '课程文件类型必选。', trigger: 'blur' }]"  >
						<el-radio-group v-model="course.fileType" @change="changeFileType">
							<el-radio v-for="item in dictCourseFileTypes" :key="item.dictValue" :label="item.dictValue" :value="item.dictValue">{{
								item.dictLabel
							}}</el-radio>
						</el-radio-group>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">
					<el-form-item label="访问类型" prop="visitType" :rules="[{ required: true, message: '课程访问类型必选。', trigger: 'blur' }]">
						<el-radio-group v-model="course.visitType">
							<el-radio v-for="item in courseVisitTypes" :key="item.dictValue" :label="item.dictValue" :value="item.dictValue">{{
								item.dictLabel
							}}</el-radio>
						</el-radio-group>
						
						<div style="display: block; float: right; padding-left: 15px;">
							<el-tooltip class="item" effect="dark" content="公开的课程表示子公司都可以看见，公司私有表示只有本公司才可看见。" placement="right">
								<i class="el-icon-question icon-color"></i>
							</el-tooltip>
						</div>
					</el-form-item>
				</el-col>

				
				<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">
					<el-form-item label="描述" >
						<el-input
							clearable=""
							:autosize="{ minRows: 3, maxRows: 10 }"
							maxlength="1000"
							show-word-limit
							type="textarea"
							v-model="course.description"
							placeholder="请输入课程简介"
						/>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6" class="mb0">
					<el-form-item
						label="学习时长"
						prop="studyHours"
						:rules="[
									{
										validator: (rule, value, callback) => {
											validateCourseHours(rule, value, callback);
										},
										trigger: 'blur',
										required:true
									},
								]"
						>
						<el-input v-model="course.studyHours"  placeholder="请输入课程学习时长"> <template slot="append">小时</template></el-input>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6" class="mb0">
					<el-form-item label="考试试卷">
						<el-select v-model="course.testPaperId" clearable placeholder="请选择课程类型">
							<el-option v-for="item in testPapers" :key="item.key" :label="item.value" :value="item.key" />
						</el-select>
					</el-form-item>
				</el-col>
				<el-col>
					<el-form-item
						:label="linkTitle"
						prop="courseUrl"
						:rules="[{ required: true, message: '链接不能为空', trigger: 'blur' },{
										validator: (rule, value, callback) => {
											validateURL(rule, value, callback);
										},
										trigger: 'blur',
										required:true
									},]"
					
					>
					<el-input v-model="course.courseUrl" v-if="course.fileType==4" clearable placeholder="http://www.baidu.com"></el-input>
					<el-upload
						
						class="upload-demo"
						ref="upload"
						action="uploadFile()"
						v-loading.fullscreen.lock="uploadLoading"
						element-loading-text="拼命上传中。。。。。。"
						element-loading-spinner='el-icon-loading'
						element-loading-background='rgba(0,0,0,0.5)'
						:file-list="fileList"
						:before-upload="beforeUpload"
						:http-request="uploadFile"
						:auto-upload="true"
						:accept="uploadFileExd"
						:on-change="handleChange"
						:limit="1"
						:disabled="disableUpload"
						:multiple="false"
						:show-file-list="true"
						:on-exceed="handleExceed"
						v-else
					>
						<el-button slot="trigger" size="small" type="primary">选取文件</el-button>
							<div v-if="uniApp" style="width:150px;" slot="tip" class="el-upload__tip1" >上传视频avi,mp4,wmv,Word(docx),PDF,JPJ,PNG,JIF等格式的课程文件,PPT,DOC需先转成PDF。</div>
							<div v-else slot="tip" class="el-upload__tip" >上传视频avi,mp4,wmv,Word(docx),PDF,JPJ,PNG,JIF等格式的课程文件,PPT,DOC需先转成PDF</div>
						</el-upload>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">
					<el-form-item label=" ">
						<el-button v-if="isNewCourse" v-on:click="addCourse" :loading="addLoading" plain type="primary">增加</el-button>
						<el-button v-else v-on:click="updateCourse" :loading="updateLoading" plain type="primary">更新</el-button>
						<el-button style="margin-top: 10px" type="primary" plain @click="cancel">关 闭</el-button>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
	</div>
</template>

<script>
import { uploadTrainingCourseFile, addTrainingCourse, updateTrainingCourse} from '@/api/training/trainingCourse';
import {Local} from '@/utils/storage.js'

export default {
	name: 'courseEdit',

	data() {
		let courseInit = {};
		let fileListInit = [];
		if (this.currentCourse == null || this.currentCourse == undefined) {
			courseInit = {
				courseName: '',
				courseType: '',
				fileType: '',
				studyHours: 0.0,
				description: '',
				visitType: '',
				courseUrl: '',
				companyId:this.currentCompanyId,
				companyName:this.currentCompanyName,
				companyCode:'',
				testPaperId:''
			};
		} else {
			//JS中对像，数组是引用，共享一个内存空间，子组件绑定修改会影响到父组件。如果不需要，改成复制拷呗去新空间
			// courseInit = this.currentCourse;
			courseInit = {...this.currentCourse}
			fileListInit.push({name:courseInit.ogriginalFileName,url:courseInit.courseUrl});
		}

		return {
			fileList: fileListInit,
			course: courseInit,
			companyid:"",
			addLoading: false,
			updateLoading: false,
			uploadLoading:false,
			uploadFileExd:"",
			disableUpload:false,
			linkTitle:"上传文件",
			defaultProps: {
				children: 'children',
				label: 'companyName',
			},
			uniApp:false,
		
		};
	},
	props: {
		currentCourse: Object,
		isNewCourse: Boolean,
		currentCompanyName: String,
		currentCompanyId: String,
		companyList:Array,
		dictCourseTypes: Array,
		dictCourseFileTypes: Array,
		courseVisitTypes: Array,
		courseEditcompanyOptions:Array,
		testPapers:Array,
	},
	created(){
		 this.uniApp=Local.get("isUniapp")?true:false;
	},
	mounted() {},

	methods: {
		cancel() {
			this.$emit('close', { refresh: false, isSuccess: true });
		},
		handleExceed(files, fileList) {
			this.msgError(`当前限制选择 20 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件.`);
		},
		handleRemove() {},
		uploadFile(params) {
			let formData = new FormData();
			formData.append('file', params.file);
			this.course.fileSize = (params.file.size / (1024 * 1024)).toFixed(2);
			this.course.ogriginalFileName = params.file.name;
			let self = this;
			this.uploadLoading = true;
			uploadTrainingCourseFile(formData)
				.then((response) => {
					this.uploadLoading = false;
					if (response.success) {
						let filePath = response.data;
						this.course.courseUrl = filePath;
						self.$message.success('上传成功!');
					} else {
						self.$message.error('上传失败：');
					}
				})
				.catch((err) => {
					this.uploadLoading = false;
					self.$message.error('上传失败：' + err);
				});
		},
		handleChange(file, fileList) {
			//this.currentContract.contractPictures = fileList;
			// 非自动上传，before-upload 不起作用，改到这里验证。
			let isValidFile = this.beforeUpload(file);
			if (!isValidFile) {
				this.$refs.upload.handleRemove(file);
			}
		},
		beforeUpload(file) {
		
			if(this.course.fileType=='1')
			{
				return this.checkDocument(file);
			}
			else if(this.course.fileType=='2')
			{
				return this.checkPicture(file);
			}
			else if (this.course.fileType=='3'){
				return this.checkVideo(file);
			}
			else if (this.course.fileType=='4'){
				this.disableUpload = true;
			}
			else
			{
				this.uploadFileExd='*.*';
			}
		},
		checkPicture(file){
			var fileType = file.name.substring(file.name.lastIndexOf('.') + 1);
			const jpg = fileType === 'jpg' || fileType === 'JPG';
			const jpeg = fileType === 'jpeg' || fileType === 'JPEG';
			const gif = fileType === 'gif';
			const png = fileType === 'png'
			const isLt5M = file.size / 1024 / 1024 < 50;
			const isEmpty = file.size === 0;
			if (!jpg && !jpeg && !gif && !png) {
				this.msgError('上传文件只能是png,jpeg,gif,png格式!');
			}
			if (!isLt5M) {
				this.msgError('上传文件大小不能超过 50MB!');
			}
			if (isEmpty) {
				this.msgError('上传文件大小不能为空文件!');
			}
			return (jpeg || jpg || gif || png) && isLt5M && !isEmpty;
		},
		checkVideo(file){
			var fileType = file.name.substring(file.name.lastIndexOf('.') + 1);
			const avi = fileType === 'avi';
			const mpeg = fileType === 'mpg' ;
			const wav = fileType === 'wav';
			const mp4 = fileType == 'mp4'
			const isLt200M = (file.size / 1024 / 1024).toFixed(2) < 200;
			const isEmpty = file.size === 0;
			if (!avi && !mpeg && !wav && !mp4) {
				this.msgError('上传文件只能是avi,mpg,mpeg,wav格式!');
			}
			if (!isLt200M) {
				this.msgError('上传文件大小不能超过 200MB!');
			}
			if (isEmpty) {
				this.msgError('上传文件大小不能为空文件!');
			}
			return (avi || mpeg || wav || mp4) && isLt200M && !isEmpty;
		},
		checkDocument(file){
			var fileType = file.name.substring(file.name.lastIndexOf('.') + 1);
			const doc = fileType === 'docx';
			// const ppt = fileType === 'ppt';
			const txt = fileType === 'txt';
			const pdf = fileType === 'pdf';
			const isLt5M = file.size / 1024 / 1024 < 50;
			const isEmpty = file.size === 0;
			if (!doc && !txt && !pdf) {
				this.msgError('上传文件只能是docx,txt,pdf格式!');
			}
			if (!isLt5M) {
				this.msgError('上传文件大小不能超过 50MB!');
			}
			if (isEmpty) {
				this.msgError('上传文件大小不能为空文件!');
			}
			return (doc  || txt || pdf) && isLt5M && !isEmpty;
		},
		addCourse() {
			if (this.course.courseUrl == null || this.course.courseUrl == '') {
				this.msgError('请选择课程文件或输入外部链接。');
				return;
			}
			let that = this;
			this.$refs['form'].validate((valid) => {
				if (valid) {
					// that.course.companyName = that.currentCompanyName;
					// that.course.companyId = that.currentCompanyId;
					that.course.status = 1;
					that.addLoading = true;
					that.course.companyCode= that.getCompanyCode(that.course.companyId);
					addTrainingCourse(that.course)
						.then((response) => {
							that.addLoading = false;
							that.msgSuccess('添加课程成功。');
							that.$emit('close', { refresh: true, isSuccess: true });
						})
						.catch((err) => {
							that.addLoading = false;
							that.msgError('添加课程失败，' + err);
						});
				} else {
					that.msgError('有非法输入或必填项未填写，请检查!');
				}
			});
		},
		getCompanyCode(companyId){
			for(var i = 0; i < this.companyList.length; i++){
				const item =  this.companyList[i];
				if(item.id == companyId){
				return item.code;
				}
			}
    	},
		updateCourse() {
			if (this.course.courseUrl == null || this.course.courseUrl == '') {
				this.msgError('请选择课程文件或输入外部链接。');
				return;
			}
			if(!this.course.testPaperId){
				this.course.testPaperId=0;
			}
			this.$refs['form'].validate((valid) => {
				if (valid) {
					this.updateLoading = true;
					updateTrainingCourse(this.course)
						.then((response) => {
							this.updateLoading = false;
							this.msgSuccess('更新课程成功。');
							this.$emit('close', { refresh: true, isSuccess: true });
						})
						.catch((err) => {
							this.updateLoading = false;
							this.msgError('更新课程失败，' + err);
						});
				}
			});
		},
		// 节点单击事件
		handleNodeClick(data) {

			//this.queryParameters.companyId = data.id;
			 this.course.companyId = data.id;
			 this.course.companyName = data.companyName;
			let selectArray = [];
			selectArray.push(data.id);
			this.$refs.tree.setCheckedKeys(selectArray);

			this.$refs.courseEditcompanySelected.blur()
			
		},
		
		changeFileType(){
			this.disableUpload = false;
			this.linkTitle = "上传文件";
			if(this.course.fileType=='1')
			{
				this.uploadFileExd = 'text/plain,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document'; //,application/vnd.ms-powerpoint
			}
			else if(this.course.fileType=='2')
			{
				this.uploadFileExd='image/jpeg,image/png,image/gif';
			}
			else if (this.course.fileType=='3'){
				this.uploadFileExd='video/x-msvideo,video/mpeg,audio/x-wav,video/mp4';
			}
			else if (this.course.fileType=='4'){
				this.disableUpload = true;
				this.linkTitle = "链接URL";
			}
			else
			{
				this.uploadFileExd='*.*';
			}
		},
		validateCourseHours(rule,value,callback) {
			let stringValue = value.toString();
			if(!stringValue){
				callback(new Error('时长不能为空。'))
			}
			else if(isNaN(stringValue)){
				callback(new Error('你输入的不是数字。'))
			}
			else if(stringValue.indexOf(".") != -1 && stringValue.split('.').length > 2){
				callback(new Error('请输入正确格式的时间，不能多个小数点，0个或1个小数点。')) //防止输入多个小数点
			}else if(stringValue.indexOf(".") != -1 && stringValue.split('.')[1].length > 1){
				callback(new Error('请确保输入只保留1位小数')) //小数点后两位
			}else{
				callback();
			}
		},
		validateURL(rule,value,callback) {
			const urlregex = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
			if(!urlregex.test(value) && this.course.fileType=='4')
			{
				callback(new Error('你输入的URL不合法。'))
			}
			else
			{
				callback();
			}

		},
		
	},
	
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 576px) {
	.el-form-item {
		display:-webkit-inline-box !important;
	}
}
</style>