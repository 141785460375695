<template>
  <div>
    <official-edit type="apply" :isShowClose="true"></official-edit>
  </div>
</template>

<script>
import OfficialEdit from "./officialEdit";
export default {
  name: "apply",
  components:{
    OfficialEdit,
  }
}
</script>

<style scoped>

</style>
