<template>
  <div>
    <el-form ref="form" :model="form" :rules="rules" label-width="90px">
      <el-row :gutter="35">
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
          <el-form-item label="公司名称" prop="companyName">
            <el-input :disabled="isReadOnly" v-model="form.companyName" placeholder="请输入公司名称"/>
          </el-form-item>
        </el-col>
        <el-col v-if="form.id != $store.getters.userInfo.constParentCompanyId"
                :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
          <el-form-item  label="上级公司" prop="parentId">
            <treeselect v-if="!form.id" v-model="form.parentId" :options="companyOptions" style="width: 100%;min-width: 230px;"
                        :disabled="isReadOnly" :normalizer="normalizerCompany" placeholder="请选择上级公司"/>
            <el-input v-if="form.id" disabled :value="companyFormat(form.parentId, null)"></el-input>
          </el-form-item>
<!--          <span v-if="form.id"> </span>-->
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
          <el-form-item label="编号" prop="num">
            <el-input :disabled="isReadOnly" v-model="form.num" placeholder="请输入编号"/>
          </el-form-item>
        </el-col>
<!--        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">-->
<!--          <el-form-item label="排序" prop="seq">-->
<!--            <el-input :disabled="isReadOnly" v-model="form.seq" placeholder="请输入排序顺序"/>-->
<!--          </el-form-item>-->
<!--        </el-col>-->
<!--        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">-->
<!--          <el-form-item label="实际人数" prop="realCount">-->
<!--            <el-input :disabled="isReadOnly" v-model="form.realCount" placeholder="请输入实际人数"/>-->
<!--          </el-form-item>-->
<!--        </el-col>-->
<!--        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">-->
<!--          <el-form-item label="编制人数" prop="planCount">-->
<!--            <el-input :disabled="isReadOnly" v-model="form.planCount" placeholder="为空则不限制"/>-->
<!--          </el-form-item>-->
<!--        </el-col>-->
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
          <el-form-item label="法人" prop="register">
            <el-input :disabled="isReadOnly" v-model="form.register" placeholder="请输入法人"/>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
          <el-form-item label="公司类型" prop="companyType">
            <el-select style="width: 100%" :disabled="isReadOnly" v-model="form.companyType" placeholder="请选择公司类型">
              <el-option
                  v-for="dict in companytypeOptions"
                  :key="dict.dictValue"
                  :label="dict.dictLabel"
                  :value="dict.dictValue"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
          <el-form-item label="成立时间" prop="regDate">
            <el-date-picker style="width: 100%" :disabled="isReadOnly" clearable size="small"
                            v-model="form.regDate"
                            type="date"
                            value-format="yyyy-MM-dd"
                            placeholder="选择成立时间">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
          <el-form-item label="规模" prop="companySize">
            <el-select style="width: 100%" :disabled="isReadOnly" v-model="form.companySize" placeholder="请选择规模">
              <el-option
                  v-for="dict in companysizeOptions"
                  :key="dict.dictValue"
                  :label="dict.dictLabel"
                  :value="dict.dictValue"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
          <el-form-item label="经营范围" prop="businessScope">
            <el-input :disabled="isReadOnly" v-model="form.businessScope" placeholder="请输入公司经验范围"/>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
          <el-form-item label="注册地" prop="regAddr">
            <el-input :disabled="isReadOnly" v-model="form.regAddr" placeholder="请输入注册地"/>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
          <el-form-item label="公司web" prop="website">
            <el-input :disabled="isReadOnly" v-model="form.website" placeholder="请输入公司web"/>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
          <el-form-item label="区域" prop="regionId">
            <el-select style="width: 100%" :disabled="isReadOnly" v-model="form.regionId" placeholder="请选择区域">
              <el-option
                  v-for="dict in regionidOptions"
                  :key="dict.dictValue"
                  :label="dict.dictLabel"
                  :value="parseInt(dict.dictValue)"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
          <el-form-item label="手机号" prop="phoneNumber">
            <el-input :disabled="isReadOnly" v-model="form.phoneNumber" placeholder="请输入手机号"/>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
          <el-form-item label="描述" prop="introduction">
            <el-input :disabled="isReadOnly" v-model="form.introduction" placeholder="请输入描述"/>
          </el-form-item>
        </el-col>
<!--        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">-->
<!--          <el-form-item label="图标" prop="avatar">-->
<!--            <el-input :disabled="isReadOnly" v-model="form.avatar" placeholder="请输入图标"/>-->
<!--          </el-form-item>-->
<!--        </el-col>-->
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
          <el-form-item label="启用" prop="enabled">
            <el-switch :disabled="isReadOnly"
                       v-model="form.enabled"
                active-color="#13ce66"
                inactive-color="#ff4949">
            </el-switch>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="box-center">
      <div class="avatar_current">
        <img :src="currentImg">
        <el-row>
          <el-col :span="24" align="center">
            <input type="file" ref="uploads" id="uploads" accept="image/png, image/jpeg, image/gif, image/jpg" hidden
                   @change="setImage($event)">
            <el-button type="primary" @click="selectAvatar" size="mini" round>更换公司logo</el-button>
          </el-col>
        </el-row>
      </div>
      <el-dialog :visible.sync="open" append-to-body>
        <div class="cropper_box">
          <div class="avatar_cropper">
            <vue-cropper
                ref="cropper"
                :img="option.img"
                :outputSize="option.outputSize"
                :outputType="option.outputType"
                :info="option.info"
                :canScale="option.canScale"
                :autoCrop="option.autoCrop"
                :autoCropWidth="option.autoCropWidth"
                :autoCropHeight="option.autoCropHeight"
                :fixed="option.fixed"
                :fixedNumber="option.fixedNumber"
                :full="option.full"
                :fixedBox="option.fixedBox"
                :canMove="option.canMove"
                :canMoveBox="option.canMoveBox"
                :original="option.original"
                :centerBox="option.centerBox"
                :height="option.height"
                :infoTrue="option.infoTrue"
                :maxImgSize="option.maxImgSize"
                :enlarge="option.enlarge"
                :mode="option.mode"
                @realTime="realTime"
                @imgLoad="imgLoad">
            </vue-cropper>
          </div>

          <!--            <div class="show_preview" :style="{'width': previews.w + 'px', 'height': previews.h + 'px',  'overflow': 'hidden',-->
          <!--          'margin': '5px'}">-->
          <!--              <div :style="previews.div">-->
          <!--                <img :src="option.img" :style="previews.img">-->
          <!--              </div>-->
          <!--            </div>-->
        </div>

        <el-row>
          <el-col :span="24" align="center">
            <div class="show_preview" :style="{'width': previews.w + 'px', 'height': previews.h + 'px',  'overflow': 'hidden',
          'margin': '5px'}">
              <div :style="previews.div">
                <img :src="option.img" :style="previews.img">
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24" align="center">
            <el-button type="success" style="" @click="uploadImg('blob')">上传图片</el-button>
          </el-col>
        </el-row>
      </el-dialog>
    </div>

    <div slot="footer" class="dialog-footer" style="text-align: right">
      <el-button v-if="!isReadOnly" type="primary" @click="submitForm" v-permission="['/org/company/add','/org/company/edit']">保 存</el-button>
      <el-button @click="cancel">取 消</el-button>
    </div>

  </div>
</template>

<script>
import {
  findAllWithParent,
  getCompany,
  addCompany,
  updateCompany,updateAvatar
   } from "@/api/org/company";
import {VueCropper} from 'vue-cropper'
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import permission from '@/directive/permission/index.js' // 权限判断指令
import {getPreviewFileUrl} from '@/api/comapi/comapi';

export default {
  name: "CompanyEdit",
  components: {
    Treeselect, VueCropper,
  },
  props: {
    row: Object,
    companyListIn: Array,
    companyOptionsIn: Array,
    companytypeOptionsIn: Array,
    companysizeOptionsIn: Array,
    regionidOptionsIn: Array,
    isReadOnly: Boolean,
    ts: Number,
  },
  directives: { permission },
  watch:{
    row(n,o){ //n为新值,o为旧值;
      //if(n === o) return;
      this.load();
    },
    ts(){
      this.load();
    },
  },
  data() {
    return {
      // 公司树选项
      companyList: [],
      companyOptions: [],
      // 公司类型，字典代码字典
      companytypeOptions: [],
      // 公司规模，字典代码 大中小字典
      companysizeOptions: [],
      // 区域id字典
      regionidOptions: [],
      // 表单参数
      form: {
        id:null,
        parentId:null,
      },
      // 公司头像
      currentImg: null,
      previews: {},
      option: {
        img: '',                //裁剪图片的地址,
        outputSize: 1,          //裁剪生成的图片质量可选(0,1,-1)
        outputType: 'jpeg',     //裁剪生成图片的格式
        info: true,             //图片大小信息
        canScale: true,         //是否允许滚轮缩放
        autoCrop: true,         //是否默认生成截图框
        autoCropWidth: 100,
        autoCropHeight: 100,    //默认生成截图框大小
        fixed: true,            //是否开启截图框宽高固定比例
        fixedNumber: [1, 1],     //截图框的宽高比,
        full: true,            //按原比例裁剪图片，不失真
        fixedBox: false,         //固定截图框大小，不允许改变
        canMove: true,         //上传图片是否可以移动,
        canMoveBox: true,       //截图框是否可以拖动
        original: true,        //上传图片按照原始比例渲染
        centerBox: false,       //截图框是否被限制在图片里面
        height: true,           //是否按照设备的dpr，输出等比例图片
        infoTrue: false,        //true为展示真实输出图片宽高，false展示看到的截图框宽高，
        maxImgSize: 3000,       //限制图片最大宽度和高度
        enlarge: 1,             //图片根据截图框输出比例倍数
        mode: '400px 300px'     //图片渲染方式
      },
      // 表单校验
      rules: {
        companyName: [
          {
            required: true,
            message: "公司名称不能为空", trigger: "blur" }
        ],
        parentId: [
          {
            required: true,
            message: "父公司不能为空", trigger: "blur" }
        ],
        num: [
          {
            required: true,
            message: "公司编号不能为空", trigger: "blur" }
        ],
        enabled: [
          {
            required: true,
            message: "是否启用： 1启用，0不启用不能为空", trigger: "blur" }
        ],
      }
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load(){
      if(this.companytypeOptionsIn){
        this.companytypeOptions = this.companytypeOptionsIn;
      }else{
        this.getDicts("sys_company_type").then(response => {
          this.companytypeOptions = response.data;
        });
      }
      if(this.companysizeOptionsIn){
        this.companysizeOptions = this.companysizeOptionsIn;
      }else{
        this.getDicts("sys_company_size").then(response => {
          this.companysizeOptions = response.data;
        });
      }
      if(this.regionidOptionsIn){
        this.regionidOptions = this.regionidOptionsIn;
      }else{
        this.getDicts("sys_location_region").then(response => {
          this.regionidOptions = response.data;
        });
      }

      this.reset();
      if(this.companyOptionsIn){
        this.companyOptions = this.companyOptionsIn;
      }else{
        this.getTreeselect();
      }

      if(this.row){
        this.handleUpdate(this.row);
      }else{
        this.handleAdd();
      }

    },
    /** 转换公司数据结构 */
    normalizerCompany(node) {
      return this.normalizer(node,'id','companyName');
    },

    companyFormat(companyId, column) {
      for(var i = 0; i < this.companyList.length; i++){
        const item =  this.companyList[i];
        if(item.id == companyId){
          return item.companyName;
        }
      }
      return "";
    },

    /** 查询公司下拉树结构 */
    getTreeselect() {
      const params = {
        pageNum: 1,
        pageSize: 10000,
        where: null,
        order: this.orderby
      }
      findAllWithParent(params).then(response => {
        this.companyOptions = [];
        this.companyList = response.data.records;
        // const data = { id:0,
        //   companyName:'顶级节点',
        //   children: []
        // };
        // data.children = this.handleTree(response.data.records, "id", "parentId");
        // this.companyOptions.push(data);
        this.companyOptions = this.handleTree(response.data.records, "id", "parentId");
      });
    },

    // 取消按钮
    cancel() {
      // this.reset();
      this.$emit("close", {refresh:false,isSuccess:true});
    },
    // 表单重置
    reset() {
      this.form = {
        enabled: true,
      };
      this.resetForm("form");
    },

    /** 新增按钮操作 */
    handleAdd() {
      // this.form.parentId = 1;
      // this.title = "添加公司";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      getCompany(row.id).then(response => {
        this.form = response.data;
        if(this.form.avatar) {
          getPreviewFileUrl(this.form.avatar).then(resp => {
            console.log("*******  getPreviewFileUrl resp=")
            console.log(resp.data)
            this.currentImg = resp.data;
          });
        }
      });
    },
    // 选择图片调用方法
    selectAvatar() {
      // this.option.img = '';
      this.$refs.uploads.click();
      this.open = true;
      //
    },
    // 真正的选择图片方法，姑且先这么命名吧
    setImage(e) {
      let file = e.target.files[0];
      if (!/\.(jpg|jpeg|png|JPG|PNG)$/.test(e.target.value)) {
        // this.$message.info("图片类型不正确");
        console.log("图片类型不正确");
        return false;
      }
      //转化为blob,使用blob是为了在页面中展示上传的那张图片
      let reader = new FileReader();
      // 文件读取成功后触发onload方法
      reader.onload = (e) => {
        let data;
        // 要在页面中展示，转化为url形式
        if (typeof e.target.result === 'object') {
          data = window.URL.createObjectURL(new Blob([e.target.result]))
        } else {
          data = e.target.result
        }
        this.option.img = data
        //转化为base64
        this.open = true;
      }
      reader.readAsDataURL(file)
    },

    realTime(data) {
      this.previews = data;
    },
    //初始化函数
    imgLoad(msg) {
      console.log("工具初始化函数=====" + msg);
    },

    // 头像上传调用方法
    uploadImg(type) {
      let _this = this;
      if(!this.form.id){
        this.msgError("请先保存公司信息，再上传公司logo！");
        return;
      }
      if(!this.form.num){
        this.msgError("请先填写公司编码！");
        return;
      }
      if (type === 'blob') {
        //获取截图的blob数据类型
        this.$refs.cropper.getCropBlob(async (data) => {
          let formData = new FormData();
          // 发数据传递到后端,注意这里请根据自己的后端逻辑进行处理，我是将用户名保存在Vuex中，可以直接进行命名
          formData.append("id", this.form.id);
          formData.append('file', data, this.form.num + ".jpg");
          updateAvatar(formData).then(function (response) {
            // console.log(response);
            if (response.code == 200) {
              // console.log(response);
              _this.currentImg = response.data;
              // _this.$store.dispatch('user/getInfo');
              // _this.$router.go(0);                                   //刷新网页
              _this.open = false;
            }
          })
        })
      }
    },

    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate(valid => {
            if(valid) {
              console.log(this.form);
              if (this.form.id != null) {
                updateCompany(this.form).then(response => {
                  this.msgSuccess("修改成功");
                  this.$emit("close", {refresh:true,isSuccess:true});
                });
              } else {
                addCompany(this.form).then(response => {
                  this.msgSuccess("新增成功");
                  this.$emit("close", {refresh:true,isSuccess:true});
                });
              }
            }
          }
      )
      ;
    },

  }
};
</script>
<style scoped>
.box-center {
  margin: 0 auto;
  display: table;
}

.tab-create {
  position: absolute;
  right: 80px;
  top: 115px;
  margin-top: 5px;
  z-index: 999;
}

.avatar_header {
  width: 100%;
  height: 50px;
  font-size: 14px;
  line-height: 50px;
  font-weight: 550;
  padding-left: 20px;
  text-align: left;
}

.avatar_current {
  width: 100%;
  height: 200px;
  text-align: left;
}

.avatar_current img {
  width: 180px;
  height: 180px;
  margin-left: 20px;

}

.avatar_select {
  text-align: left;
}

.cropper_box {
  text-align: center;
//position: relative;
}

.avatar_cropper {
  margin-top: 5px;
  height: 300px;
  width: 300px;
  display: inline-block;
}

.show_preview {
  display: inline-block;
//position: absolute;
//top: 10px;
//left: 10px;
  button: 20px;
}

</style>
