<template>
	<div>
		<div>
			<el-row :gutter="10" class="mb8">
				<el-col :span="1.5">
					<el-button type="primary" plain icon="el-icon-plus" size="mini" @click="handleAdd">新增 </el-button>
				</el-col>
				<el-col :span="1.5">
					<el-button
						type="success"
						plain
						icon="el-icon-edit"
						size="mini"
						:disabled="!single"
						@click="handleUpdate"
						v-permission="['/employ/employ/edit']"
						>修改
					</el-button>
				</el-col>
				<el-col :span="1.5">
					<el-button
						type="danger"
						plain
						icon="el-icon-delete"
						size="mini"
						:disabled="!multiple"
						@click="handleDelete"
						v-permission="['/employ/employ/remove']"
						>删除
					</el-button>
				</el-col>
			</el-row>
			<el-table v-loading="loading" :data="contractCodeList" @selection-change="handleSelectionChange" stripe border>
				<el-table-column type="selection" width="55" align="center"  />
                <el-table-column label="编号ID" align="center" prop="id"  v-if="false" />
				<el-table-column label="合同编号名称" align="center" width="150" prop="dictLabel" sortable />
				<el-table-column label="合同编号格式" align="center" width="180" prop="dictValue" />
				<el-table-column label="合同编号说明" align="center" min-width="150" prop="remark" />
			</el-table>
		</div>
		<el-dialog :title="dialogTitle" :visible.sync="dialogVisible" v-if="dialogVisible" width="uniApp?95%:60%" :close-on-click-modal="false">
			<contract-code-pattern
				:id="id"
				:selectContractEncoding="selectContract"
				:isAdd="addCoding" 
				:isUpdate="updateCoding" 
				@closedialog="close"
			>
			</contract-code-pattern>
		</el-dialog>
	</div>
</template>


<script>
import contractCodePattern from './contractCodePattern.vue';
import { delSysdict ,getSysdict} from '@/api/sys/sysdict';
import {Local} from '@/utils/storage.js'
export default {
  components: { contractCodePattern },
    name: '',

    data() {
        return {
            contractCodeList:[],
            dialogVisible:false,
            id: '',
			ids:[],
			addCoding:false,
			updateCoding:false,
			// 非单个禁用
			single: false,
			// 非多个禁用
			multiple: false,
			loading:false,
			uniApp:false,
        };
    },

    mounted() {
       this.load();
	   this.uniApp=Local.get("isUniapp")?true:false;
       
    },

    methods: {
        load(){
				this.loading=true;
                this.getDicts('contract_id_encoding').then((response) => {
				    this.contractCodeList = response.data;
					this.loading=false;
			});
        },
        /** 新增按钮操作 */
		handleAdd() {
			this.dialogTitle = '增加合同编号方式';
			this.dialogVisible = true;
			this.addCoding = true;
			this.updateCoding = false;
			this.id = '';
			this.selectContract=null;
		},

		/** 修改按钮操作 */
		handleUpdate(row) {
			let self = this;
			this.id=this.ids;
			getSysdict(this.id)
				.then((response) => {
					if(response.success)
					{
						self.selectContract = response.data;
						this.dialogTitle = '修改合同编号方式';
						this.dialogVisible = true;
						this.addCoding = false;
						this.updateCoding = true;
					}
					else{
						this.msgError('获取数据失败。');
					}
					
				})
				.catch((error) => {
					this.msgError('异常:' + error);
				});
		},

		/** 删除按钮操作 */
		handleDelete() {
			this.$confirm('是否确认删除选中的员工合同编号方式?', '警告', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
						
					let formData = {};
					formData.ids= this.ids;
					delSysdict(formData.ids).then((response) => {
						if (response.success) {
							this.load();
							this.msgSuccess('删除成功');
						} else {
							this.msgError('删除失败,' + response.msg);
						}
					});

				
				})
				
		},
        // 多选框选中数据
		handleSelectionChange(selection) {
			this.ids = selection.map((item) => item.id);
			this.single = selection.length == 1;
			this.multiple = selection.length >= 1;
		
		},
		// 提交确认后关闭对话框
		close(arg) {
			this.dialogVisible = false;
			if (arg.refresh) {
				
				this.load();
			}
		},
    },
};
</script>

<style lang="scss" scoped>

</style>