<template>
	<div>
		<div>
			<el-form :model="queryParameters" ref="queryParameters" class="employ" label-width="110px">
				<el-row :gutter="24">
					<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
						<el-form-item label="合同起始时间">
							<el-date-picker
								v-model="queryParameters.startDate"
								value-format="yyyy-MM-dd"
								align="left"
								type="date"
								placeholder="选择日期"
							>
							</el-date-picker>
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
						<el-form-item label="合同终止时间">
							<el-date-picker
								v-model="queryParameters.endDate"
								value-format="yyyy-MM-dd"
								align="left"
								type="date"
								placeholder="选择日期"
							>
							</el-date-picker>
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
						<el-form-item label="合同类型">
							<el-select v-model="queryParameters.contractType" style="width:100%;" placeholder="请选择合同状态" clearable>
								<el-option v-for="item in contractTypes" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue" />
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
						<el-form-item label="合同状态">
							<el-select v-model="queryParameters.contractStatus" style="width:100%;"  placeholder="请选择合同状态" clearable>
								<el-option v-for="item in constactStatuses" :key="item.dictValue" :label="item.dictLabel" :value="item.dictValue" />
							</el-select>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row :gutter="24">
					<!-- <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
						<el-form-item label="员工编号" prop="workNum">
							<el-input v-model="queryParameters.workNum" clearable placeholder="请输入员工证编号" />
						</el-form-item>
					</el-col> -->
					<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
						<el-form-item label="员工状态" prop="empStatus">
							 <el-select v-model="queryParameters.empStatus" placeholder="请选择员工状态" clearable>
								<el-option  v-for="dict in employeeStatuses" :key="dict.value" :label="dict.label" :value="dict.value"
								></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
						<el-form-item label="身份证号" prop="idNum">
							<el-input v-model="queryParameters.idNum" clearable placeholder="请输入身份证证号" />
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
						<el-form-item label="姓名" prop="name">
							<el-input v-model="queryParameters.name" clearable placeholder="请输入姓名" />
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
						<el-form-item label=" ">
							<el-button v-on:click="query" icon="el-icon" plain type="primary">查询</el-button>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
		</div>
		<div>
			<el-row :gutter="10" class="mb8">
				<el-col :span="1.5">
					<el-button type="success" plain icon="el-icon-edit" size="mini" :disabled="single" @click="updateConstract">编辑 </el-button>
				</el-col>
				<el-col :span="1.5">
					<el-button type="danger" plain icon="el-icon-delete" size="mini" :disabled="multiple" @click="deleteConstracts">批量删除 </el-button>
				</el-col>
				<el-col :span="1.5">
					<el-button type="danger" plain icon="el-icon-import" size="mini" @click="contractInportClick">批量导入 </el-button>
				</el-col>
			</el-row>
			<el-table v-loading="loading" :data="queryContractList" @selection-change="handleSelectionChange" stripe border>
				<el-table-column type="selection" width="55" align="center" />
				<el-table-column label="员工编号" align="center" prop="workNum" sortable />
				<el-table-column label="姓名" align="center" prop="name" sortable />
				<el-table-column label="员工状态" align="center" prop="empStatus" sortable />
				<el-table-column label="性别" align="center" prop="sex" sortable />
				<el-table-column label="公司" align="center" prop="company" sortable>
					<template slot-scope="scope">
						<el-tag type="info" :disable-transitions="false">
							{{ companyFormat(scope.row, null) }}
						</el-tag>
					</template>
				</el-table-column>
				<el-table-column label="部门" align="center" prop="department" sortable>
					<template slot-scope="scope">
						<el-tag type="info" :disable-transitions="false">
							{{ departmentFormat(scope.row, null) }}
						</el-tag>
					</template>
				</el-table-column>
				<el-table-column label="是否签合同" align="center"  sortable >
					    <template slot-scope="scope" >
							<el-button size="mini" type="text"
								@click="updateConstract(scope.row)">
								{{ scope.row.signed }}
							</el-button>
						</template>
				</el-table-column>
			</el-table>
			<pagination v-show="total > 0" :total="total" :page.sync="queryParameters.pageNum" :limit.sync="queryParameters.pageSize" @pagination="query" />
		</div>
		<el-dialog title="合同批量导入" :visible.sync="contractInportOpen" width="60%" append-to-body>
			<contract-import @close="closeDialog"></contract-import>
			<!-- <employ-edit :row="row" opType="hrEntry" :company-id="companyId" v-if="open" :isReadOnly="employReadOnly" :canAdd="employeeInfoAdd" @close="close" @update="update" @updatePartialEmployeeInfo='updatePartialEmployeeInfo'></employ-edit> -->
		</el-dialog>
	</div>
</template>

<script>
import { listEmploy, getEmploy, updateEmploy, removeContracts,findAllEmplyees } from '@/api/employ/employ';
import Pagination from '@/components/Pagination';
import { listCompany } from '@/api/org/company';
import { listDepartment } from '@/api/org/department';
import ContractImport from './contractImport.vue';
import {DICT_TYPE} from "@/utils/dict";
export default {
	components: {
		Pagination,
		ContractImport,
	},
	data() {
		return {
			queryParameters: {
				startDate: '',
				endDate: '',
				empStatus: '正式',
				contractStatus: '',
				contractType:'',
				idNum: '',
				pageSize: 10,
				pageNum: 1,
				name:''
			},
			queryContractList: [],
			contractTypes:[],
			constactStatuses: [],
			employeeStatuses: DICT_TYPE.employeeStatuses,//DICT_TYPE.employeeStatuses,
			where: {
				query: '',
				filters: [],
			},
			pickerOptions1: {
				disabledDate(time) {
					return time.getTime() > Date.now();
				},
			},
			// 非单个禁用
			single: false,
			// 非多个禁用
			multiple: true,
			// 遮罩层
			loading: false,
			// 总条数
			total: 0,
			// 员工信息表格数据
			employList: [],
			ids: [],
			companyList: [],
			departmentList: [],
			contractInportOpen: false,
			dynamicWhere:''
		};
	},

	created() {
		this.load();
	},

	methods: {
		contractInportClick() {
			this.contractInportOpen = true;
		},

		load() {
			let companyId = this.$store.getters.userInfo.companyId;
			this.getDepartments(companyId);
			this.getCompanys();

			this.getDicts('contract_type').then((response) => {
				this.contractTypes = response.data;
			});
			this.getDicts('contract_status').then((response) => {
				this.constactStatuses = response.data;
			});
			this.query();
		},
		// 多选框选中数据
		handleSelectionChange(selection) {
			this.ids = selection.map((item) => item.id);
			this.single = selection.length !== 1;
			this.multiple = !selection.length;
		},
		query() {
			
			let filter = {};
			this.dynamicWhere="";
			if (this.queryParameters.empStatus) {
				filter['empStatus'] = {
					type: 'eq',
					value: this.queryParameters.empStatus,
				};
			}
			if (this.queryParameters.idNum) {
				filter['idNum'] = {
					type: 'search',
					value: this.queryParameters.idNum,
				};
			}
			if (this.queryParameters.name) {
				filter['name'] = {
					type: 'search',
					value: this.queryParameters.name,
				};
			}
			if (this.queryParameters.contractStatus && this.queryParameters.contractType) {
				 this.dynamicWhere = " JSON_CONTAINS(contracts,JSON_OBJECT('contractType', '"+this.queryParameters.contractType+"','contractStatus', '"+this.queryParameters.contractStatus+"'))  ";
			}

			if (this.queryParameters.contractStatus=='' && this.queryParameters.contractType) {
				 this.dynamicWhere = " JSON_CONTAINS(contracts,JSON_OBJECT('contractType', '"+this.queryParameters.contractType+"')) ";
			}
			if (this.queryParameters.contractStatus && this.queryParameters.contractType=='') {
				 this.dynamicWhere = " JSON_CONTAINS(contracts,JSON_OBJECT('contractStatus', '"+this.queryParameters.contractStatus+"')) ";
			}

			if (this.queryParameters.startDate && this.queryParameters.endDate) {
				let tempDate = this.queryParameters.startDate.replace(/-/g, '/'); //必须把日期'-'转为'/'
				var startDateTimeStamp = new Date(tempDate).getTime();
				tempDate = this.queryParameters.endDate.replace(/-/g, '/');
				var endDateTimeStamp = new Date(tempDate).getTime();
				filter['createTime'] = {
					type: 'timestamp',
					value: [startDateTimeStamp, endDateTimeStamp],
				};
			}

			const filters = this.parseFilters(filter);
			if (filters && filters.length > 0) {
				this.where.filters = filters;
			} else {
				// 如果过滤条件为空，设置空数组
				this.where.filters = [];
			}
			const params = {
				pageNum: this.queryParameters.pageNum,
				pageSize: this.queryParameters.pageSize,
				where: this.where,
				dynamic: this.dynamicWhere,
				order: this.orderby,
			};
			this.loading = true;
			findAllEmplyees(params).then((response) => {
				this.employList = [];
				this.queryContractList = [];
				this.employList = response.data.records;
				this.total = response.data.total;
				this.loading = false;
				if (this.employList != null && this.employList.length > 0) {
					this.employList.forEach((element) => {
						let temp = {};
						temp.id = element.id;
						temp.workNum = element.workNum;
						temp.empStatus = element.empStatus;
						temp.name = element.name;
						temp.sex = element.sex;
						temp.company = element.companyId;
						temp.department = element.departmentId;
						temp.signed = element.contracts != null && element.contracts != undefined && element.contracts.length > 0 ? '已经签' : '未签';

						this.queryContractList.push(temp);
					});
				}
			});
		},
		updateConstract(row) {
			const id =  row.id||this.ids;
			let employ = this.employList.find((a) => {
				return a.id == id;
			});
			if (employ != null || employ != undefined) {
				this.$router.push({ name: 'contract_management_list', params: { selectEmployeeId: employ.id } });
			} else {
				this.msgError('列表中没找到选择的员工。');
			}
		},

		/** 删除按钮操作 */
		deleteConstracts() {
			let ids = this.ids;
			this.$confirm('是否确认删除选中的员工信息数据?', '警告', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			}).then(() => {
				removeContracts(ids).then((response) => {
					if (response.success) {
						this.query();
						this.msgSuccess('删除成功');
					} else {
						this.msgError('删除失败,' + response.msg);
					}
				});
			});
		},

		getCompanys() {
			const params = {
				pageNum: 1,
				pageSize: 500,
				where: null,
				order: '',
			};
			listCompany(params).then((response) => {
				this.companyList = response.data.records;
			});
		},
		companyFormat(row, column) {
			for (var i = 0; i < this.companyList.length; i++) {
				const item = this.companyList[i];
				if (item.id == row.company) {
					return item.companyName;
				}
			}
			return '';
		},
		getDepartments(companyId) {
			let queryWhere = { query: '', filters: [] };
			let filter = {};
			if (companyId) {
				filter['companyId'] = {
					type: 'eq',
					value: companyId,
				};
			}
			const filters = this.parseFilters(filter);
			if (filters && filters.length > 0) {
				queryWhere.filters = filters;
			} else {
				// 如果过滤条件为空，设置空数组
				queryWhere.filters = [];
			}
			const params = {
				pageNum: 1,
				pageSize: 500,
				where: queryWhere,
				order: '',
			};
			listDepartment(params).then((response) => {
				this.departmentList = response.data.records;
			});
		},
		departmentFormat(row, column) {
			// console.log(row.departmentId);
			for (var i = 0; i < this.departmentList.length; i++) {
				const item = this.departmentList[i];
				// console.log("id:"+item.id+",depid:"+row.departmentId+", name :"+item.name+",ee="+(item.id == row.departmentId));
				if (item.id == row.department) {
					return item.name;
				}
			}
			return '';
		},

		// 提交确认后关闭对话框
		closeDialog(arg) {
			this.contractInportOpen = false;
			if (arg.refresh) {
				this.query();
			}
		},
	},
};
</script>

<style>
</style>