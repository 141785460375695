<template>
  <el-card style="margin-bottom:20px;">
    <div slot="header" class="clearfix">
      <span>个 人 信 息</span>
      <el-button v-show="!isEdit" type="text" style="float: right; padding: 3px 0" size="mini" @click="modify"> 修 改 </el-button>
      <el-button v-show="isEdit" type="text" size="mini" style="float: right; padding: 3px 0" @click="cancel"> 取 消 </el-button>
      <el-button v-show="isEdit" type="text" style="float: right; padding: 3px 0" size="mini" @click="submitForm"> 保 存 </el-button>
    </div>

    <div class="user-profile">
      <div class="box-center">
        <div class="avatar_current">
          <img :src="currentImg">
          <el-row>
            <el-col :span="24" align="center">
              <input type="file" ref="uploads" id="uploads" accept="image/png, image/jpeg, image/gif, image/jpg" hidden
                     @change="setImage($event)">
              <el-button type="primary" @click="selectAvatar" size="mini" round>更换头像</el-button>
            </el-col>
          </el-row>
        </div>
        <el-dialog :visible.sync="open" append-to-body>
          <div class="cropper_box">
            <div class="avatar_cropper">
              <vue-cropper
                  ref="cropper"
                  :img="option.img"
                  :outputSize="option.outputSize"
                  :outputType="option.outputType"
                  :info="option.info"
                  :canScale="option.canScale"
                  :autoCrop="option.autoCrop"
                  :autoCropWidth="option.autoCropWidth"
                  :autoCropHeight="option.autoCropHeight"
                  :fixed="option.fixed"
                  :fixedNumber="option.fixedNumber"
                  :full="option.full"
                  :fixedBox="option.fixedBox"
                  :canMove="option.canMove"
                  :canMoveBox="option.canMoveBox"
                  :original="option.original"
                  :centerBox="option.centerBox"
                  :height="option.height"
                  :infoTrue="option.infoTrue"
                  :maxImgSize="option.maxImgSize"
                  :enlarge="option.enlarge"
                  :mode="option.mode"
                  @realTime="realTime"
                  @imgLoad="imgLoad">
              </vue-cropper>
            </div>

<!--            <div class="show_preview" :style="{'width': previews.w + 'px', 'height': previews.h + 'px',  'overflow': 'hidden',-->
<!--          'margin': '5px'}">-->
<!--              <div :style="previews.div">-->
<!--                <img :src="option.img" :style="previews.img">-->
<!--              </div>-->
<!--            </div>-->
          </div>

          <el-row>
            <el-col :span="24" align="center">
              <div class="show_preview" :style="{'width': previews.w + 'px', 'height': previews.h + 'px',  'overflow': 'hidden',
          'margin': '5px'}">
                <div :style="previews.div">
                  <img :src="option.img" :style="previews.img">
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24" align="center">
              <el-button type="success" style="" @click="uploadImg('blob')">上传图片</el-button>
            </el-col>
          </el-row>
        </el-dialog>
      </div>
      <!--      <div class="box-center">-->
      <!--        <pan-thumb :image="user.avatar" :height="'100px'" :width="'100px'" :hoverable="false">-->
      <!--          <div>Hello</div>-->
      <!--          {{ user.role }}-->
      <!--        </pan-thumb>-->
      <!--      </div>-->

      <div class="box-center">
        <el-row>
          <el-col :span="24" align="center">
            <div class="user-name text-center">{{ user.username }}</div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24" align="center">
            <div class="user-role text-center text-muted">
              <div v-if="form.employ">
                部门：{{form.departmentName}}， 岗位：{{form.positionName}}
              </div>
              <div v-else>非员工账号</div>
<!--              <el-tag-->
<!--                  :key="tag.id"-->
<!--                  v-for="tag in user.roles"-->
<!--                  :disable-transitions="false"-->
<!--              >-->
<!--                {{ tag.zhName }}-->
<!--              </el-tag>-->
            </div>
          </el-col>
        </el-row>
        <el-form ref="form" :model="form" :rules="rules" label-width="80px">

          <el-row>
            <el-col :span="24" align="center">
              <el-form-item label="姓名" size="mini" prop="name">
                <el-input v-model="form.name" :disabled="!isEdit" size="mini" placeholder="请输入用户姓名"/>
              </el-form-item>
            </el-col>
            <el-col :span="24" align="center">
              <el-form-item label="手机号" size="mini" prop="mobile">
                <el-input v-model="form.mobile" :disabled="!isEdit" size="mini" placeholder="请输入手机号"/>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <!--    <div class="user-bio">-->
      <!--      <div class="user-education user-bio-section">-->
      <!--        <div class="user-bio-section-header">-->
      <!--          <svg-icon icon-class="education"/>-->
      <!--          <span>Education</span></div>-->
      <!--        <div class="user-bio-section-body">-->
      <!--          <div class="text-muted">-->
      <!--            JS in Computer Science from the University of Technology-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->

      <!--      <div class="user-skills user-bio-section">-->
      <!--        <div class="user-bio-section-header">-->
      <!--          <svg-icon icon-class="skill"/>-->
      <!--          <span>Skills</span></div>-->
      <!--        <div class="user-bio-section-body">-->
      <!--          <div class="progress-item">-->
      <!--            <span>Vue</span>-->
      <!--            <el-progress :percentage="70"/>-->
      <!--          </div>-->
      <!--          <div class="progress-item">-->
      <!--            <span>JavaScript</span>-->
      <!--            <el-progress :percentage="18"/>-->
      <!--          </div>-->
      <!--          <div class="progress-item">-->
      <!--            <span>Css</span>-->
      <!--            <el-progress :percentage="12"/>-->
      <!--          </div>-->
      <!--          <div class="progress-item">-->
      <!--            <span>ESLint</span>-->
      <!--            <el-progress :percentage="100" status="success"/>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
  </el-card>
</template>

<script>
// import PanThumb from '@/components/PanThumb'
import {VueCropper} from 'vue-cropper'
import {updateAvatar} from "../../../api/sys/user";
// import {getPreviewFileUrl} from "../../../api/comapi/comapi"
import {updateUserBase} from "../../../api/employ/self";
import {listSimpleEmploy} from "@/api/employ/employ";

export default {
  components: {VueCropper},
  props: {
    user: {
      type: Object,
      default: () => {
        return {
          name: 'wangjie',
          email: '',
          avatar: '',
          role: ''
        }
      }
    }
  },
  data() {
    return {
      form: {name: '', mobile: ''},
      employ: null,
      open: false,
      isEdit: false,
      currentImg: this.$store.getters.avatar,    //这里我是将用户信息保存在Vuex进行管理
      // viewImg: '',
      previews: {},
      option: {
        img: '',                //裁剪图片的地址,
        outputSize: 1,          //裁剪生成的图片质量可选(0,1,-1)
        outputType: 'jpeg',     //裁剪生成图片的格式
        info: true,             //图片大小信息
        canScale: true,         //是否允许滚轮缩放
        autoCrop: true,         //是否默认生成截图框
        autoCropWidth: 100,
        autoCropHeight: 100,    //默认生成截图框大小
        fixed: true,            //是否开启截图框宽高固定比例
        fixedNumber: [1, 1],     //截图框的宽高比,
        full: true,            //按原比例裁剪图片，不失真
        fixedBox: false,         //固定截图框大小，不允许改变
        canMove: true,         //上传图片是否可以移动,
        canMoveBox: true,       //截图框是否可以拖动
        original: true,        //上传图片按照原始比例渲染
        centerBox: false,       //截图框是否被限制在图片里面
        height: true,           //是否按照设备的dpr，输出等比例图片
        infoTrue: false,        //true为展示真实输出图片宽高，false展示看到的截图框宽高，
        maxImgSize: 3000,       //限制图片最大宽度和高度
        enlarge: 1,             //图片根据截图框输出比例倍数
        mode: '400px 300px'     //图片渲染方式
      },
      // 表单校验
      rules: {
        name: [
          {
            required: true,
            message: "用户姓名不能为空", trigger: "blur"
          }
        ],
        mobile: [
          {
            required: true,
            message: "手机号不能为空", trigger: "blur"
          }
        ],
      },
    }
  },
  computed: {
    // // eslint-disable-next-line vue/no-async-in-computed-properties
    // viewImg: async function () {
    //   console.log(this.currentImg);
    //   // eslint-disable-next-line vue/no-async-in-computed-properties
    //   let img = await getPreviewFileUrl(this.currentImg);
    //   console.log(img.data);
    //   return img.data;
    // },
  },
  created() {
    console.log(this.$store.getters.userInfo);
    console.log("user card");
    console.log(this.user);
    this.form = this.user;
    // let filter = {};
    // filter['userId'] = {
    //   type: 'eq',
    //   value: this.form.id,
    // }
    // const empParams = this.getQueryParam(filter, 1, 1, null);
    // listSimpleEmploy(empParams).then(response => {
    //   if(response.data.records && response.data.records.length > 0){
    //     this.employ = response.data.records[0];
    //     console.log(this.employ);
    //   }
    // });

  },
  methods: {
    // 标签页切换调用方法，不重要！删掉了一些不必要的代码
    handleClick() {
    },

    // 选择图片调用方法
    selectAvatar() {
      // this.option.img = '';
      this.$refs.uploads.click();
      this.open = true;
      //
    },
    // 真正的选择图片方法，姑且先这么命名吧
    setImage(e) {
      let file = e.target.files[0];
      if (!/\.(jpg|jpeg|png|JPG|PNG)$/.test(e.target.value)) {
        // this.$message.info("图片类型不正确");
        console.log("图片类型不正确");
        return false;
      }
      //转化为blob,使用blob是为了在页面中展示上传的那张图片
      let reader = new FileReader();
      // 文件读取成功后触发onload方法
      reader.onload = (e) => {
        let data;
        // 要在页面中展示，转化为url形式
        if (typeof e.target.result === 'object') {
          data = window.URL.createObjectURL(new Blob([e.target.result]))
        } else {
          data = e.target.result
        }
        this.option.img = data
        //转化为base64
        this.open = true;
      }
      reader.readAsDataURL(file)
    },

    realTime(data) {
      this.previews = data;
    },
    //初始化函数
    imgLoad(msg) {
      console.log("工具初始化函数=====" + msg);
    },

    // 头像上传调用方法
    uploadImg(type) {
      let _this = this;
      if (type === 'blob') {
        //获取截图的blob数据类型
        this.$refs.cropper.getCropBlob(async (data) => {
          let formData = new FormData();
          // 发数据传递到后端,注意这里请根据自己的后端逻辑进行处理，我是将用户名保存在Vuex中，可以直接进行命名
          formData.append("username", this.$store.getters.name);
          formData.append('file', data, this.$store.getters.name + ".jpg");
          updateAvatar(formData).then(function (response) {
            // console.log(response);
            if (response.code == 200) {
              // console.log(response);
              _this.currentImg = response.data;
              _this.$store.dispatch('user/getInfo');
              // _this.$router.go(0);                                   //刷新网页
              _this.open = false;
            }
          })
        })
      }
    },

    submitForm() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          // console.log(this.form);
          // eslint-disable-next-line no-unused-vars
          updateUserBase(this.form).then(response => {
            this.msgSuccess("修改成功");
            this.isEdit = false;
            // this.open = false;
            // this.getList();
          });
        }
      });
    },
    modify() {
      console.log(this.$store.getters.userInfo);
      console.log("user cardaa");
      this.isEdit = true;
    },
    cancel() {
      this.isEdit = false;
    }
  },
}
</script>

<style lang="scss" scoped>
.box-center {
  margin: 0 auto;
  display: table;
}

.text-muted {
  color: #777;
}

.user-profile {
  .user-name {
    font-weight: bold;
  }

  .box-center {
    padding-top: 10px;
  }

  .user-role {
    padding-top: 10px;
    font-weight: 400;
    font-size: 14px;
  }

  .box-social {
    padding-top: 30px;

    .el-table {
      border-top: 1px solid #dfe6ec;
    }
  }

  .user-follow {
    padding-top: 20px;
  }
}

.user-bio {
  margin-top: 20px;
  color: #606266;

  span {
    padding-left: 4px;
  }

  .user-bio-section {
    font-size: 14px;
    padding: 15px 0;

    .user-bio-section-header {
      border-bottom: 1px solid #dfe6ec;
      padding-bottom: 10px;
      margin-bottom: 10px;
      font-weight: bold;
    }
  }
}


.tab-create {
  position: absolute;
  right: 80px;
  top: 115px;
  margin-top: 5px;
  z-index: 999;
}

.avatar_header {
  width: 100%;
  height: 50px;
  font-size: 14px;
  line-height: 50px;
  font-weight: 550;
  padding-left: 20px;
  text-align: left;
}

.avatar_current {
  width: 100%;
  height: 200px;
  text-align: left;
}

.avatar_current img {
  width: 180px;
  height: 180px;
  margin-left: 20px;

}

.avatar_select {
  text-align: left;
}

.cropper_box {
  text-align: center;
  //position: relative;
}

.avatar_cropper {
  margin-top: 5px;
  height: 300px;
  width: 300px;
  display: inline-block;
}

.show_preview {
  display: inline-block;
  //position: absolute;
  //top: 10px;
  //left: 10px;
  button: 20px;
}
</style>
