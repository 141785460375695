<template>
<div class="app-container">
  <el-form label-width="88px">
    <el-row>
    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
      <el-form-item label="公司部门：">
      <treeselect v-model="queryParams.companyId" :options="treeData" :multiple="true" value-consists-of="ALL"
                  :limit="5" :limitText="count => `及其它${count}部门`" :flat="true"
                  :normalizer="normalizerCompany" @input="companyIdInput" placeholder="请选择公司" style="min-width:220px;" />
      </el-form-item>
    </el-col>
    </el-row>
  </el-form>
  <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="88px">
    <el-row>
    <el-form-item label="考勤月份">
      <el-date-picker type="month" clearable size="small" v-model="queryParams.clockDate" value-format="yyyy-MM-dd" placeholder="选择开始日期"/>
    </el-form-item>
    <el-form-item label="姓名">
      <el-input v-model="queryParams.empName" placeholder="请输入员工姓名" clearable size="small" @keyup.enter.native="handleQuery"/>
    </el-form-item>
    <el-form-item label="状态">
      <el-select v-model="queryParams.confirmStatus" placeholder="请选择状态" clearable size="small">
        <el-option v-for="item in getDictDatas(DICT_TYPE.ATT_CONFIRM_STATUS)" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled">
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="请假审批">
      <el-select v-model="queryParams.leaveApproved" placeholder="请选择请假审批状态" clearable size="small">
        <el-option key="0" label="未审批完" value="0" ></el-option>
        <el-option key="1" label="已完成" value="1" ></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="考勤计算">
      <el-select v-model="queryParams.statisticCompleted" placeholder="请选择考勤计算结果" clearable size="small">
        <el-option key="0" label="未完成" value="0" ></el-option>
        <el-option key="1" label="已完成" value="1" ></el-option>
      </el-select>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
      <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
    </el-form-item>
  </el-row>
  </el-form>

  <el-row :gutter="10" class="mb8 buttonStripe">
    <el-col :span="1.5">
      <el-button type="primary"  plain icon="el-icon-search" size="mini" @click="queryPreMonth" >上月考勤</el-button>
    </el-col>
    <el-col :span="1.5">
      <el-button type="danger" plain icon="el-icon-camera" size="mini" @click="manualCalAttStatic">生成确认</el-button>
    </el-col>
    <el-col :span="1.5">
      <el-button :disabled="multiple" type="danger" plain icon="el-icon-refresh" size="mini" @click="calAttStatic">计算考勤</el-button>
    </el-col>
    <el-col :span="1.5">
      <el-button :disabled="confirmButtonDisable" type="primary" plain icon="el-icon-set-up" size="mini" @click="setFullAtt" >设置全勤</el-button>
    </el-col>
    <el-col :span="1.5">
      <el-button :disabled="confirmButtonDisable" type="primary" plain icon="el-icon-back" size="mini" @click="unSetFullAtt" >撤销全勤</el-button>
    </el-col>
    <el-col :span="1.5">
      <el-button type="primary" plain icon="el-icon-download" size="mini" @click="exportConfirmAtt" >导出确认表</el-button>
    </el-col>
    <el-col :span="1.5">
      <el-button :disabled="confirmButtonDisable" type="primary" plain icon="el-icon-s-check" size="mini" @click="confirmAttMonth" >确认</el-button>
    </el-col>
    <el-col :span="1.5">
      <el-upload action :name="form.url" class="upload-demo" ref="upload" :limit="1" :http-request="importAttConfirm" :on-preview="handlePreview"
                 :on-remove="handleRemove" :file-list="fileList" accept=".xls,.xlsx" :auto-upload="true">
        <el-button slot="trigger" size="mini" type="warning" plain :class="{uniAppMarginBottom:uniApp,webMarginBottom:!uniApp }">导入确认</el-button>
      </el-upload>
    </el-col>
    <el-col :span="1.5">
      <el-button type="danger" plain icon="el-icon-delete" size="mini" :disabled="multiple" @click="handleDelete">删除</el-button>
    </el-col>
    <el-col :span="1.5">
      <el-popover placement="top-start" title="考勤确认" width="350" trigger="click">
        <ul>
          <li>计算考勤: 如果考勤打卡数据有更新，系统未自动计算，可以选择员工和日期范围更新计算考勤信息。系统会在每天晚上1点，根据打卡信息，计算考勤状态等信息。</li>
          <li>确认：本月考勤核对无误后可以确认考勤，确认考勤后，薪资核算人员便可以开始计算本月薪资。</li>
          <li>全勤设置：如果将员工本月考勤设置为全勤，薪资计算时不会扣除迟到、早退、旷工等缺勤薪资。</li>
          <li>导入确认：可以将导出的确认表中”考勤确认“表格进行修改，并将”是否修正“列改成”是“；然后通过导入确认导入表格，修改设置了”是否修正“的考勤确认记录。</li>
        </ul>
        <el-button type="primary" plain icon="el-icon-question" size="mini" slot="reference" >帮助</el-button>
      </el-popover>
    </el-col>
    <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>
  </el-row>
  <el-table v-loading="loading" :data="attConfirmList" @selection-change="handleSelectionChange" stripe border>
    <el-table-column type="selection" width="55" align="center"/>
    <el-table-column label="姓名" align="center" prop="empName" width="58px" :fixed="isUniapp?false:true">
      <template slot-scope="scope">
        <el-button type="text" @click="showAttRecords(scope.row.empId,scope.row.empName)">{{ scope.row.empName}}</el-button>
      </template>
    </el-table-column>
    <el-table-column label="公司名称" align="center" width="200px">
      <template slot-scope="scope">{{ getCompanyName(scope.row.companyId)}}</template>
    </el-table-column>
    <el-table-column label="部门名称" align="center" prop="depName" width="200px">
      <template slot-scope="scope">{{ getDepartmentName(scope.row.depId)}}</template>
    </el-table-column>
    <el-table-column label="状态" align="center" prop="confirmStatus" width="80px"/>
    <el-table-column label="是否全勤" align="center" prop="isFullAtt" width="80px">
      <template slot-scope="scope">
        <span v-if="scope.row.isFullAtt" style="color: green">全勤</span>
      </template>
    </el-table-column>
    <el-table-column label="是否修正" align="center" prop="isModify" width="80px">
      <template slot-scope="scope">
        <span v-if="scope.row.isModify" style="color: orangered">修正</span>
      </template>
    </el-table-column>
    <el-table-column label="请假申请审批" align="center" prop="leaveApproved" width="120px">
      <template slot-scope="scope">
        <el-popover v-if="!scope.row.leaveApproved" placement="top-start" title="未审批完成的请假申请"
                    width="380" trigger="click">
          <el-table :data="scope.row.confirmMap.approvedNullLeaveList" stripe border>
            <el-table-column label="请假类型" align="center" prop="type"/>
            <el-table-column label="天数" align="center" prop="days" width="50px"/>
            <el-table-column label="开始时间" align="center" width="139px">
              <template slot-scope="scopebt">
                {{getLocalDateStr(scopebt.row.beginTime)}}
              </template>
            </el-table-column>
            <el-table-column label="结束时间" align="center" width="139px">
              <template slot-scope="scopeet">
                {{getLocalDateStr(scopeet.row.endTime)}}
              </template>
            </el-table-column>
          </el-table>
          <el-button type="text" plain slot="reference" >未审批完</el-button>
        </el-popover>
        <span v-if="scope.row.leaveApproved">已完成</span>
      </template>
    </el-table-column>
    <el-table-column label="调休结算（天）" align="center" prop="confirmMap.lieuDays" />
    <el-table-column label="应出勤（天）" align="center" prop="days" />
    <el-table-column label="实际出勤（天）" align="center" prop="clockDays">
      <template slot-scope="scope">
        {{scope.row.isFullAtt?((scope.row.clockDays + (scope.row.absentDay)).toFixed(2)):scope.row.clockDays}}
      </template>
    </el-table-column>
    <el-table-column label="迟到（次）" align="center" width="60px">
      <template slot-scope="scope">
        <template v-if="scope.row.isFullAtt || !(scope.row.lateTimes > 0)">
          <span style="color: green">0</span>
        </template>
        <template v-else>
          <span style="color: #c18318">{{scope.row.lateTimes}}</span>
        </template>
      </template>
    </el-table-column>
    <el-table-column label="早退（次）" align="center" width="60px">
      <template slot-scope="scope">
        <template v-if="scope.row.isFullAtt || !(scope.row.earlyTimes > 0)">
          <span style="color: green">0</span>
        </template>
        <template v-else>
          <span style="color: #c9c616">{{scope.row.earlyTimes}}</span>
        </template>
      </template>
    </el-table-column>
    <el-table-column label="旷工（天）" align="center" width="60px">
      <template slot-scope="scope">
        <template v-if="scope.row.isFullAtt || !(scope.row.absentDay > 0)">
          <span style="color: green">0</span>
        </template>
        <template v-else>
          <span style="color: #990000">{{(scope.row.absentDay).toFixed(2)}}</span>
        </template>
      </template>
    </el-table-column>
<!--    <el-table-column label="迟到，早退，旷工汇总" align="center" width="160px">-->
<!--      <template slot-scope="scope">-->
<!--        <template v-if="scope.row.isFullAtt || (scope.row.lateTimes == 0 && scope.row.earlyTimes == 0 && scope.row.absentTime == 0)">-->
<!--          <span style="color: green">全勤</span>-->
<!--        </template>-->
<!--        <template v-else>-->
<!--          <span v-if="scope.row.lateTimes > 0" style="color: #c18318">迟到{{scope.row.lateTimes}}次,</span>-->
<!--          <span v-if="scope.row.earlyTimes > 0" style="color: #c9c616">早退{{scope.row.earlyTimes}}次,</span>-->
<!--          <span v-if="scope.row.absentTime > 0" style="color: #990000">旷工{{ (scope.row.absentTime/60/8).toFixed(2)}}天</span>-->
<!--        </template>-->
<!--      </template>-->
<!--    </el-table-column>-->

    <el-table-column align="center" v-for="type in leaveTypes" :key="type" :label="type+'（天）'" width="68px" >
      <template slot-scope="scope">
        <template v-if="scope.row.confirmMap[type]">
          <el-tooltip placement="top">
            <div slot="content">
              <el-table :data="scope.row.confirmMap[type].leaveList" stripe border>
                <el-table-column label="时长" align="center" prop="minutes">
                  <template slot-scope="scopeItem">
                    {{ scopeItem.row.leaveDays.toFixed(2)}}天
                  </template>
                </el-table-column>
                <el-table-column label="开始时间" align="center" prop="startTime" width="100px">
                  <template slot-scope="scopeItem">
                    {{ scopeItem.row.startTime}}
                  </template>
                </el-table-column>
                <el-table-column label="结束时间" align="center" prop="endTime" width="100px">
                  <template slot-scope="scopeItem">
                    {{ scopeItem.row.endTime}}
                  </template>
                </el-table-column>
                <el-table-column label="申请时长" align="center" prop="attDays">
                  <template slot-scope="scopeItem">
                    {{ scopeItem.row.attDays}}天
                  </template>
                </el-table-column>
                <el-table-column label="申请开始时间" align="center" prop="startTime" width="100px">
                  <template slot-scope="scopeItem">
                    {{ scopeItem.row.attBeginTime}}
                  </template>
                </el-table-column>
                <el-table-column label="申请结束时间" align="center" prop="endTime" width="100px">
                  <template slot-scope="scopeItem">
                    {{ scopeItem.row.attEndTime}}
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <span>{{scope.row.confirmMap[type].leaveDays.toFixed(2)}}</span>
          </el-tooltip>

<!--          <el-popover placement="top-start" title="休假情况" width="350" trigger="click">-->
<!--            <span v-for="leave in scope.row.confirmMap[type].leaveList" :key="leave.startTime+leave.endTime">-->
<!--              {{}}-->
<!--            </span>-->
<!--            <el-button type="primary" plain icon="el-icon-question" size="mini" slot="reference" >{{ (scope.row.confirmMap[type].leaveTime/60/8).toFixed(2)}}天</el-button>-->
<!--          </el-popover>-->
        </template>
        <template v-else>
          <span></span>
        </template>
      </template>
    </el-table-column>

    <el-table-column align="center" v-for="day in startEndDays" :key="day" :label="day" width="130px" >
      <template slot-scope="scope">
        <template v-if="scope.row.confirmMap[day]">
          <el-tooltip placement="top" title="详情">
            <div slot="content">
              <ul>
                <li>应出勤{{scope.row.confirmMap[day]['days']?scope.row.confirmMap[day]['days']:"0"}}天，
                  实际出勤{{scope.row.confirmMap[day]['clockDays']?scope.row.confirmMap[day]['clockDays']:"0"}}天
                </li>
                <li v-if="scope.row.confirmMap[day]['earlyTimes']>0||scope.row.confirmMap[day]['lateTimes']>0">
                  迟到{{scope.row.confirmMap[day]['lateTimes']?scope.row.confirmMap[day]['lateTimes']:"0"}}次，
                  早退{{scope.row.confirmMap[day]['earlyTimes']?scope.row.confirmMap[day]['earlyTimes']:"0"}}次
                </li>
                <li v-if="scope.row.confirmMap[day]['leaveInfo'] && scope.row.confirmMap[day]['leaveInfo']['leaveTypes']">
                  休假情况：
                  <ul>
                    <li v-for="leaveType in scope.row.confirmMap[day]['leaveInfo']['leaveTypes']" :key="leaveType">
                      {{leaveType}}：
                      <ul>
                        <li v-for="leave in scope.row.confirmMap[day]['leaveInfo'][leaveType]['leaveList']" :key="leave.startTime">
                          {{leave.leaveDays.toFixed(2)}}天，{{leave.startTime}}至{{leave.endTime}}
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <!--          <span>【</span>-->
          <span v-if="'未完成'==scope.row.confirmMap[day]['attType']" style="color: #e0c162">{{scope.row.confirmMap[day]['attType']}}</span>
          <span v-else-if="'正常出勤'==scope.row.confirmMap[day]['attType']" style="color: #009933">
            {{scope.row.confirmMap[day]['attType']}}{{scope.row.confirmMap[day]['clockDays']?scope.row.confirmMap[day]['clockDays']:"0"}}天
          </span>
          <span v-else-if="'休息日'==scope.row.confirmMap[day]['attType']" style="color: #009933">{{scope.row.confirmMap[day]['attType']}}</span>
          <span v-else>
            <span v-if="scope.row.isFullAtt" style="color: green">全勤</span>
            <span v-else style="color: #990000">
              {{scope.row.confirmMap[day]['attType']}}
            </span>
          </span>
<!--          <span>】</span>-->
          <!--                    <span v-if="''!=scope.row.confirmMap[day]["attType"]">状态:【{{scope.row.confirmMap[day]["attType"]}}】</span>-->
<!--          <span>{{scope.row.confirmMap[day]["lateTimes"]? "迟到"+scope.row.confirmMap[day]["lateTimes"]+"次,":""}}</span>-->
<!--          <span>{{scope.row.confirmMap[day]["earlyTimes"]? "早退"+scope.row.confirmMap[day]["earlyTimes"]+"次,":""}}</span>-->
<!--          <span>{{scope.row.confirmMap[day]["leaveTimes"]? "请假"+scope.row.confirmMap[day]["leaveTimes"]+"次,":""}}</span>-->
<!--          <span>{{scope.row.confirmMap[day]["absentTime"]? "旷工"+scope.row[day]["absentTime"]/60+"小时":""}}</span>-->
          </el-tooltip>
        </template>
        <template v-else>
          <span></span>
        </template>
      </template>
    </el-table-column>
    <el-table-column label="操作" align="center" class-name="small-padding fixed-width" :fixed="isUniapp?false:'right'" width="80px">
      <template slot-scope="scope">
        <el-button v-if="scope.row.leaveApproved && scope.row.statisticCompleted && '未确认' == scope.row.confirmStatus" size="mini" type="text"
                   icon="el-icon-s-check" @click="confirmAttMonth(scope.row)" >确认</el-button>
      </template>
    </el-table-column>
    <!--            </el-table-column>-->
  </el-table>
  <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
  />

  <el-dialog title="计算考勤统计时间范围" :visible.sync="openStatistic" append-to-body>
    <el-form ref="formStatistic" :model="formStatistic" :rules="rulesStatistic" label-width="80px">
      <el-row :gutter="35">
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
          <el-form-item label="开始时间" prop="startDate">
            <el-date-picker clearable size="small"
                            v-model="formStatistic.startDate"
                            type="date"
                            value-format="yyyy-MM-dd"
                            placeholder="选择考勤计算开始时间">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
          <el-form-item label="结束时间" prop="endDate">
            <el-date-picker clearable size="small"
                            v-model="formStatistic.endDate"
                            type="date"
                            value-format="yyyy-MM-dd"
                            placeholder="选择考勤计算结束时间">
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitStatistic" v-permission="['/attendance/attClockTime/add']">确 定</el-button>
      <el-button @click="cancelStatistic">取 消</el-button>
    </div>
  </el-dialog>
  <el-dialog title="生成考勤确认月份" :visible.sync="openGenConfirmMoth" append-to-body>
    <el-form ref="formStatistic" :model="formGenConfirmMoth" label-width="80px">
      <h3>选择需要重新生成考勤确认的员工和月份。</h3>
      <el-row>
        <el-col>
          <el-form-item label="选择员工" style="display:block !important" :rules="{required: true,message: '参与考勤人员不能为空', trigger: 'blur' }">
            <treeselect ref="treeRef" v-model="genConfirmEmpIds" :options="employTree"
                        :limit="5" :limitText="count => `及其它${count}人`" @input="inputChange"
                        :multiple="true" value-consists-of="LEAF_PRIORITY"  noChildrenText="没有员工"
                        :normalizer="normalizeNodeDisableEmp" placeholder="请选择员工"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row><el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb20">
        选择生成考勤确认月份
      </el-col></el-row>
      <el-row :gutter="35">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb20">
          <el-form-item label="月份">
            <el-radio v-model="genConfirmCurrentMonth" :label="false">上月</el-radio>
            <el-radio v-model="genConfirmCurrentMonth" :label="true">本月</el-radio>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="submitGenConfirm">确 定</el-button>
      <el-button @click="cancelGenConfirm">取 消</el-button>
    </div>
  </el-dialog>
  <el-dialog :title="recordsTitle" :visible.sync="openAttRecords" append-to-body width="70%">
    <Records :employ="empRecord"></Records>
  </el-dialog>

</div>
</template>

<script>
import {formatDate} from "@/utils/formatTime";
import {getFirstLastDayOfMonth, getDayAll,getJavaLocalDateStr} from "@/utils/dateTimeUtils";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { LOAD_CHILDREN_OPTIONS } from '@riophae/vue-treeselect'
import RightToolbar from "@/components/RightToolbar"
import permission from '@/directive/permission/index.js' // 权限判断指令
import Pagination from "@/components/Pagination";
import {getAllOrgTreeWithParent} from "../../org/components/orgtree";
import { getOrgEmpTree, normalizerEmployMultiSelectTree, checkTreeIdsExist} from '@/views/pages/employ/components/empOrgTree';
import {listAttConfirms, calculateStatisticAndGenConfirm, updateConfirmStatus, updateFullAtt, delAttConfirm, exportConfirm, importAttConfirm} from "@/api/attendance/attConfirm";
import { showLoading, hideLoading } from '@/utils/reqloading';
import { Local } from '@/utils/storage.js';
import Records from "@/views/pages/attendance/my/records";

export default {
  name: "index",
  components: {
    Treeselect,
    RightToolbar,
    Pagination,Records,
  },
  directives: { permission },
  data() {
    return {
      openStatistic: false,
      formStatistic: {},
      rulesStatistic: {
        startDate: [
          {
            required: true,
            message: "开始时间不能为空", trigger: "blur" }
        ],
        endDate: [
          {
            required: true,
            message: "结束时间不能为空", trigger: "blur" }
        ],

      },
      leaveTypes:[],
      confirmButtonDisable: true,
      startEndDays: [],
      companyList: [],
      departmentList: [],
      treeData: [],
      attConfirmList: [],
      absentCount: 0,
      activeName: 'query',
      searchLoading: false,
      // 遮罩层
      loading: false,
      // 查询参数
      where: {
        query: '',
        filters: []
      },
      // 选中数组
      ids: [],
      empIds:[],
      selection: null,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      open: false,
      // 总条数
      total: 0,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        companyId: null,
        depId: null,
        empName: null,
        confirmStatus: null,
        leaveApproved: null,
        statisticCompleted: null,
        attType: null,
        clockDate: null,
      },
      openGenConfirmMoth: false, // 手动生成考勤确认月份对话框
      genConfirmCurrentMonth: false, // 手动生成本月考勤确认月份
      formGenConfirmMoth:{}, // 手动生成考勤确认月份表单
      employTree: [],
      genConfirmEmpIds:[],
      companyId: '',
      openAttRecords: false,
      recordsTitle: "考勤记录",
      empRecord:{employId:null},

      form:{},
      fileList: [],
      uniApp:false,

    }
  },
  computed: {
    isUniapp(){
      return Local.get("isUniapp")? true: false
    },
  },
  created() {
    const [start, end] = getFirstLastDayOfMonth(new Date());
    this.queryParams.clockDateStart = start;
    this.queryParams.clockDateEnd = end;
    getAllOrgTreeWithParent(false, (tree, companyList, departmentList)=>{
      console.log("tree");
      console.log(tree);
      this.companyList = companyList;
      this.treeData = tree;

      this.departmentList = departmentList;
      // this.getList();
    });
    this.companyId = this.$store.getters.userInfo.companyId;
    getOrgEmpTree(this.companyId, true,(tree, companyList, departmentList, employList )=> {
      this.employTree = tree;
      console.log('this.employTree');
      console.log(this.employTree);
    });
  },

  methods:{
    getLocalDateStr(date){
      return getJavaLocalDateStr(date);
    },
    calAttStatic(){
      this.openStatistic = true;
    },
    // 手动生成考勤确认
    manualCalAttStatic(){
      let that = this;
      this.openGenConfirmMoth = true;
    },
    submitGenConfirm(){
      let that = this;
      console.log("生成考勤确认信息")
      let date = new Date();
      if(this.genConfirmCurrentMonth) {
        console.log("本月");
      } else {
        console.log("上月");
        date = new Date(date.setMonth(date.getMonth() - 1));
      }
      // let preMonth = new Date(date.setMonth(date.getMonth() - 1));
      console.log(date);
      const [start, end] = getFirstLastDayOfMonth(date);
      console.log(start);
      console.log(end);
      showLoading();
      let empIds = this.genConfirmEmpIds.join(",");
      calculateStatisticAndGenConfirm(start, end, empIds)
          .then(response => {
            hideLoading();
            that.openGenConfirmMoth = false;
            // this.getList();
            that.msgSuccess("生成考勤确认完成");
          });
    },
    cancelGenConfirm(){
      this.openGenConfirmMoth = false;
    },
    submitStatistic(){
      let that = this;
      this.$refs["formStatistic"].validate(valid => {
        if (valid) {
          // if(!this.queryParams.clockDate) {
          //   this.$alert("考勤月份不能为空");
          //   return;
          // }
          if(!this.empIds || this.empIds.length <= 0){
            this.$alert("选择要计算的考勤的员工");
            return;
          }
          const empIds = this.empIds.join(",");
          showLoading();
          calculateStatisticAndGenConfirm(this.formStatistic.startDate, this.formStatistic.endDate, empIds)
              .then(response => {
                hideLoading();
                that.openStatistic = false;
                that.getList();
                that.msgSuccess("统计计算完成");
              });

        }
      });
    },
    cancelStatistic(){
      this.openStatistic = false;
    },

    confirmAttMonth(row){
      let ids = [];
      if(row.id){
        ids = [row.id];
      }else{
        ids = this.ids;
      }
      ids = ids.join(",");
      console.log(ids);
      this.$confirm('考勤数据已经正确无误？', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return updateConfirmStatus(ids, "已确认");
      }).then(() => {
        this.getList();
        this.msgSuccess("考勤确认成功");
      })
    },

    setFullAtt(row){
      let ids = [];
      if(row.id){
        ids = [row.id];
      }else{
        ids = this.ids;
      }
      ids = ids.join(",");
      console.log(ids);
      this.$confirm('确认将这些员工考勤设置成全勤？', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return updateFullAtt(ids, true);
      }).then(() => {
        this.getList();
        this.msgSuccess("全勤设置成功");
      })
    },

    unSetFullAtt(row){
      let ids = [];
      if(row.id){
        ids = [row.id];
      }else{
        ids = this.ids;
      }
      ids = ids.join(",");
      console.log(ids);
      this.$confirm('确认取消这些员工全勤设置，恢复系统考勤？', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return updateFullAtt(ids, false);
      }).then(() => {
        this.getList();
        this.msgSuccess("取消全勤设置成功");
      })
    },

    queryPreMonth(){
      let date = new Date();
      let preMonth = new Date(date.setMonth(date.getMonth() - 1));
      console.log(preMonth);
      this.queryParams.clockDate = preMonth;
      this.getList();
    },

    /** 查询打卡记录列表 */
    getQuery(){
      this._filter = {};
      if(this.queryParams.clockDate) {
        // console.log(this.queryParams.clockDate.substr(0,7));
        const [start, end] = getFirstLastDayOfMonth(this.queryParams.clockDate);
        this.queryParams.clockDateStart = start;
        this.queryParams.clockDateEnd = end;
        this._filter['clockDate'] = {
          type: 'date',
          value: [this.queryParams.clockDateStart,this.queryParams.clockDateEnd],
        }
      }
      if(this.queryParams.empName) {
        this._filter['empName'] = {
          type: 'search',
          value: this.queryParams.empName,
        }
      }
      if(this.queryParams.confirmStatus) {
        this._filter['confirmStatus'] = {
          type: 'eq',
          value: this.queryParams.confirmStatus,
        }
      }
      if(this.queryParams.leaveApproved) {
        this._filter['leaveApproved'] = {
          type: 'eq',
          value: this.queryParams.leaveApproved,
        }
      }
      if(this.queryParams.statisticCompleted) {
        this._filter['statisticCompleted'] = {
          type: 'eq',
          value: this.queryParams.statisticCompleted,
        }
      }

      if(this.queryParams.depId) {
        this._filter['depId'] = {
          type: 'in',
          value: this.queryParams.depId,
        }
      }
      const filters = this.parseFilters(this._filter)
      if (filters && filters.length > 0) {
        this.where.filters = filters
      } else {
        // 如果过滤条件为空，设置空数组
        this.where.filters = []
      }
      const params = {
        pageNum: this.queryParams.pageNum,
        pageSize: this.queryParams.pageSize,
        where: this.where,
        order: ' depId asc, empId asc'
      }

      return params;
    },
    getList() {
      if(!this.queryParams.clockDate) {
        this.$alert("考勤月份不能为空");
        return;
      }
      this.loading = true;
      let params = this.getQuery();
      listAttConfirms(params).then(response => {
        this.attConfirmList = response.data.records;
        let leaveTypes = [];
        let typeMap = {};
        this.attConfirmList.forEach(item=>{
          if(item.confirmMap && item.confirmMap.leaveTypes){
            item.confirmMap.leaveTypes.forEach(t=>{
              if(!typeMap[t]){
                typeMap[t] = t;
                leaveTypes.push(t);
              }
            });
          }
        });
        this.leaveTypes = leaveTypes;
        this.total = response.data.total;
        this.loading = false;
      });
      this.startEndDays = getDayAll(this.queryParams.clockDateStart, this.queryParams.clockDateEnd);
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.empIds = selection.map(item => item.empId);
      this.confirmButtonDisable = false;
      selection.forEach(item=>{
        if(!item.leaveApproved || !item.statisticCompleted){
          this.confirmButtonDisable = true;
        }
      })
      this.selection = selection;
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },

    companyIdInput(value, instanceId){
      console.log("companyIdInput");
      console.log(value);
      console.log(instanceId);
      console.log(this.queryParams.companyId);
      // const companyIds = this.getClassIds(value,"company");
      this.queryParams.positionId = null;
      this.queryParams.depId = this.getClassIds(value,"department");
      console.log(this.queryParams.depId);
      // this.queryParams.positionId = null;
      // this.$refs["selectPosTree"].clear();
      // this.getDepartment(value);
      // this.getPosition(value);
      console.log("companyIdInput");
    },
    getClassIds(value, className){
      if(value && value.length>0){
        let ids = [];
        value.forEach( id => {
          if(id.startsWith(className)){
            const idstr = id.substring(className.length);
            console.log(idstr)
            ids.push(idstr);
          }
        })
        console.log("getClassIds , ids=");
        console.log(ids);
        return ids;
      }else{
        return [];
      }
    },
    /** 转换公司数据结构 */
    normalizerCompany(node) {
      // if (node['id'] && !node['id'].length) {
      //   delete node['children'];
      // }
      if(node.className=='company'){
        return {
          id: node['treeId'],
          label: node['id'] + " - " +node['companyName'],
          children: node['children']
        };
      }else if(node.className=='department'){
        return {
          id: node['treeId'],
          label: node['name'],
          children: node['children']
        };
      }
      // return this.normalizer(node,'id','companyName');
    },
    getDepartmentName(id){
      //console.log(id);
      for(let i = 0; i < this.departmentList.length; i++){
        if(id == this.departmentList[i].id) return this.departmentList[i].name;
      }
      return "";
    },
    getCompanyName(id){
      for(let i = 0; i < this.companyList.length; i++){
        if(id == this.companyList[i].id) return this.companyList[i].name;
      }
      return "";
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      let ids = [];
      if(row.id){
        ids = [row.id];
      }else{
        ids = this.ids;
      }

      this.$confirm('是否确认删除选中的考勤确认记录?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return delAttConfirm(ids);
      }).then(() => {
        this.getList();
        this.msgSuccess("删除成功");
      })
    },

    exportConfirmAtt(){
      if(!this.queryParams.clockDate) {
        this.$alert("考勤月份不能为空");
        return;
      }
      const params = this.getQuery();
      showLoading();
      const that = this;
      exportConfirm(params).then(result => {
        let fileName = this.$store.getters.userInfo.companyName+"("+formatDate(new Date(this.queryParams.clockDate),'YYYY-mm')+")"+'考勤确认表.xlsx'
        that.downloadResult(result,fileName);
      })
    },
    downloadResult(result, fileName){
      if(result){
        let blob = new Blob([result.data], {
          type: result.data.type
        });
        let filename = result.headers.filename && decodeURI(result.headers.filename) || fileName;
        if ('download' in document.createElement('a')) {
          console.log("downloadResult if ")
          const link = document.createElement("a");
          link.style.display = "none";
          link.href = URL.createObjectURL(blob);
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          console.log("downloadResult else")
          navigator.msSaveBlob(blob, filename);
        }
      }
    },
    normalizeNodeDisableEmp(node){
      let data = normalizerEmployMultiSelectTree(node,"id");
      if("employ" == node.className ){
        if(this.selectedEmpIds && this.selectedEmpIds.indexOf(data.id) >= 0){
          // console.log(this.selectedEmpIds);
          // console.log("normalizeNodeDisableEmp data=");
          // console.log(data);
          data.isDisabled = true;
        }
      }
      return data;
    },

    inputChange(val,instanceId){
      // console.log(val)
      if(!val) return;
      this.$emit("change",val);
      if(this.multiple){//只有多选模式下才考虑提示功能
        this.allLabel = val.map(item=>{
          let label = "";
          //getNode是我自己查找下拉树的内置方法，呕心沥血才找到的
          // console.log(this.$refs["treeRef"]);
          label = this.$refs["treeRef"].getNode(this.valueFormat == "object"?item.id:item).label;
          label = label.replace('(unknown)',"(外部)");
          return label;
        })
        let el = this.$el.querySelector(".vue-treeselect__multi-value");
        el.setAttribute("title",this.allLabel.join(", "));
      }else{
        this.removePlaceHolder();
      }
      this.addPlaceHolder(val);
    },
    //增加文字提示tooltip
    addPlaceHolder(value){
      // console.log("addPlaceHolder......")
      // console.log(value)
      let placeholder = this.$el.querySelector(".vue-treeselect__placeholder");
      let temp = value !== "_first"? value:this.value;
      if(placeholder && (!temp || !temp.length)){
        let content = placeholder.innerText;
        placeholder.parentNode.setAttribute("title",content);
      }
    },
    removePlaceHolder(){
      let placeholder = this.$el.querySelector(".vue-treeselect__placeholder");
      if(placeholder){
        placeholder.parentNode.removeAttribute("title");
      }
    },

    showAttRecords(empId, name){
      this.$set(this.empRecord,"employId", empId);
      this.openAttRecords = true;
      console.log("showAttRecords");
      console.log(this.form)
      this.recordsTitle = name+"考勤记录";
    },
    async importAttConfirm(params){
      const _file = params.file;
      const formData = new FormData();
      formData.append("companyId",this.$store.getters.userInfo.companyId);
      formData.append('file', _file);
      showLoading();
      importAttConfirm(formData).then(response=>{
        console.log(response.code);
        this.$message({type: 'success',message: response.msg});
        this.fileList = [];
        console.log("success, do query list");
        this.getList();
        hideLoading();
      }).reject( ()=>{
        this.fileList = [];
        hideLoading();
      });
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },

  },


}
</script>

<style scoped>

</style>
