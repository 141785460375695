<template>
<div>
  <template v-if="'Official' == dType">
    <official-edit :type="businessType" :processInstanceId="processInstanceId" :isReadOnly="true" :isShowClose="true" @close="close"></official-edit>
  </template>
  <template v-if="'Depart' == dType">
    <depart-edit :type="businessType" :processInstanceId="processInstanceId" :isReadOnly="true" :isShowClose="true" @close="close"></depart-edit>
  </template>
  <template v-if="'DepartEnd' == dType">
    <DepartEndEdit :type="businessType" :processInstanceId="processInstanceId" :isReadOnly="true" :isShowClose="true" @close="close"></DepartEndEdit>
  </template>
  <template v-if="'Transfer' == dType">
    <TransferEdit :type="businessType" :processInstanceId="processInstanceId" :isReadOnly="true" :isShowClose="true" @close="close"></TransferEdit>
  </template>
  <template v-if="'Leave' == dType">
    <AttLeaveEdit :type="businessType" :processInstanceId="processInstanceId" :isReadOnly="true" :isShowClose="true" @close="close"></AttLeaveEdit>
  </template>
  <template v-if="'Overtime' == dType">
    <OvertimeEdit :type="businessType" :processInstanceId="processInstanceId" :isReadOnly="true" :isShowClose="true" @close="close"></OvertimeEdit>
  </template>
  <template v-if="'BusinessTrip' == dType">
    <BusinessTripEdit :type="businessType" :processInstanceId="processInstanceId" :isReadOnly="true" :isShowClose="true" @close="close"></BusinessTripEdit>
  </template>
  <template v-if="'CorrectTimeClock' == dType">
    <CorrectTimeClockEdit :type="businessType" :processInstanceId="processInstanceId" :isReadOnly="true" :isShowClose="true" @close="close"></CorrectTimeClockEdit>
  </template>
  <template v-if="'selfFlow' == dType">
    <SelfFlowEdit :type="businessType" :processInstanceId="processInstanceId" :isReadOnly="true" :isShowClose="true" @close="close"></SelfFlowEdit>
  </template>
</div>
</template>

<script>
import OfficialEdit from "../official/officialEdit";
import DepartEdit from "../depart/departEdit";
import DepartEndEdit from "../departEnd/departEndEdit";
import TransferEdit from "../transfer/transferEdit";
import AttLeaveEdit from "../attLeave/attLeaveEdit";
import OvertimeEdit from "../overtime/overtimeEdit";
import BusinessTripEdit from "../businessTrip/businessTripEdit";
import CorrectTimeClockEdit from "../correctTimeClock/correctTimeClockEdit";
import SelfFlowEdit from "../selfFlow/selfFlowEdit";

export default {
  name: "formDetail",
  props: {
    dialogType: String,
    businessType: String,
    processInstanceId: String,
  },
  components: {
    DepartEdit,
    DepartEndEdit,
    OfficialEdit,
    TransferEdit,
    AttLeaveEdit,
    OvertimeEdit,
    BusinessTripEdit,
    CorrectTimeClockEdit,
    SelfFlowEdit,
  },
  watch: {
    processInstanceId(n, o) { //n为新值,o为旧值;
      //if(n === o) return;
      this.load();
    },
  },
  created() {
    this.load();
  },
  data() {
    return {
      dType: null,
      bType: null,
      key: null,
    }
  },
  methods: {
    load() {
      this.dType = this.dialogType;
      console.log("formDetail load this.dType="+this.dType);
    },
    close() {
      this.$emit("close", {refresh:false,isSuccess:true});
    },
  },
}
</script>

<style scoped>

</style>
