export const quotationsList = [
	{
		name: '颜渊',
		comeFrom: '论语',
		content:
			'自己不喜欢的，就不要强加给别人。饥寒是自己不喜欢的，不要把它强加给别人；耻辱是自己不喜欢的，也不要把它强加给别人。将心比心，推己及人，从自己的利与害想到对别人的利与害，多替别人着想，这是终生应该奉行的原则。',
	},
	{
		name: '荀子',
		comeFrom: '劝学',
		content:
			'木料经过木工用墨线（木工用具）划直线加工以后，就变直了；金属物品在磨刀石上磨砺后，就能锋利。人经过学习磨练，自我反省，就会变得聪慧明智，不犯错误，也越来越坚强。',
	},
	{
		name: '里仁',
		comeFrom: '论语',
		content:
			'见到贤人，就应该想着向他学习；看见不贤的人，便应该自己反省，对不如自己的人喜欢讥笑、轻视，因而沾沾自喜；对比自己强的人喜欢贬低，甚至嫉妒、畏惧退缩，害怕与他们交往：这都是不正确的态度。',
	},
	{
		name: '述而',
		comeFrom: '论语',
		content:
			'君子心地平坦宽广，小人却经常局促忧愁。君子襟怀坦白，安贫乐业，与人为善，知足常乐，所以能坦荡荡。小人欲念太多，患得患失，忧心忡忡，怨天尤人，局促不安，所以常心怀戚戚。',
	},
	{
		name: '老子',
		comeFrom: '第六十四章',
		content:
			'千里遥远的路程是从脚下第一步开始的。任何事情的成功都是从头开始，从小到大逐渐积累的。万事开头难，没有个开头就不会有结果。任何事情都要从一点一滴的小事开始做起。',
	},
	{
		name: '朱熹',
		comeFrom: '训学斋规',
		content:
			'读书有三到，谓心到，眼到，口到。心不在此，则眼看不仔细，心眼既不专一，却只漫浪诵读，决不能记，久也不能久也。三到之中，心到最急，心既到矣，眼口岂不到乎？',
	},
	{
		name: '德鲁克',
		comeFrom: '',
		content:
			'创新是企业家的具体工具，也就是他们借以利用变化作为开创一种新的实业和一项新的服务的机会的手段。……企业家们需要有意识地去寻找创新的源泉，去寻找表明存在进行成功创新机会的情况变化扩其征兆。他们还需要懂得进行成功的创新的原则并加以运用。',
	},
	{
		name: '法拉第',
		comeFrom: '',
		content:
			'希望你们年轻的一代，也能像蜡烛为人照明那样，有一分热，发一分光，忠诚而为人类伟大的事业贡献自己的力量。',
	},
	{
		name: '高尔基',
		comeFrom: '',
		content:
			'如果你在任何时候，任何地方，你一生中留给人们的都是些美好的东西——鲜花、思想以及对你的非常美好的回忆，那你的生活将会轻松而愉快。那时你就会感到所有的人都需要你，这种感觉使你成为一个心灵丰富的人。你要知道，给永远比拿愉快。',
	},
	{
		name: '莫泊桑',
		comeFrom: '',
		content:
			'生活不可能像你想像得那麼美好，但也不會像你想像得那麼糟。 我覺得人的脆弱和堅強都超乎自己的想像。 有時，我們可能脆弱的一句話就淚流滿面， 有時，也發現自己不知不覺咬著牙走了很長的路。',
	},
	{
		name:"Peter Su",
		comeFrom:'',
		content:'一個人最大的魅力不在於有多少人喜歡你，而是在認識你之後， 也許幾個月、也許數年，卻仍然想要和你相處在一起， 依然欣賞你，甚至想跟你說聲： 「能認識你真好。」'
	},
	{
		name:'張愛玲',
		comeFrom:'',
		content:'於千萬人之中遇見你所遇見的人 於千萬年之中，時間的無涯的荒野裡 沒有早一步，也沒有晚一步，剛巧趕上了，也沒有別的話可說 惟有輕輕的問一聲：『噢，你也在這裡嗎？』'
	},
	{
		name:'勞倫斯．卜洛克/劇本',
		comeFrom:'',
		content:'One is always on a strange road, watching strange scenery and listening to strange music. Then one day, you will find that the things you try hard to forget are already gone. 一個人總要走陌生的路，看陌生的風景，聽陌生的歌， 然後在某個不經意的瞬間，你會發現， 原本是費盡心機想要忘記的事情真的就那麼忘記了'
	},
	{
		name:'伊坂幸太郎',
		comeFrom:'',
		content:'人生就像在搭電扶梯，即使自己佇足不動，不知不覺還是會前進。 一搭上電扶梯就不斷向前，目的地早已決定，身體不由自主地朝終點邁進。 不過，大家都沒注意到這一點，以為只有自己不在電扶梯上。'
	},
	{
		name:'岸見一郎',
		comeFrom:'',
		content:'重要的不是你有些什麼，而是如何運用你所擁有的東西。 你不需要變得特別，只要接受現在的自己； 你不必被過去羈絆，因為人生永遠都有選擇的可能性。'
	},
	{
		name:'《青囊经》',
		comeFrom:'',
		content:'理寓于气，气囿于形。日月星宿，刚气上腾。山川草木，柔气下凝。资阳以昌，用阴以成。阳德有象，阴德有位。地有四势，气从八方。外气行形，内气止生。乘风则散，界水则止。'
	},
	{
		name:'毛泽东',
		comeFrom:'',
		content:'独立寒秋，湘江北去，橘子洲头。看万山红遍，层林尽染；漫江碧透，百舸争流。鹰击长空，鱼翔浅底，万类霜天竞自由。怅寥廓，问苍茫大地，谁主沉浮？携来百侣曾游，忆往昔峥嵘岁月稠。恰同学少年，风华正茂；书生意气，挥斥方遒。指点江山，激扬文字，粪土当年万户侯。曾记否，到中流击水，浪遏飞舟？'
	},
	
];
