<template>
  <div>
    <el-row>
      <el-col>
        <div style="height: 50px; text-align: center; font-size: large; " >修改密码</div>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
    <div class="uni-container">
      <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="旧密码" prop="oldPassword" >
          <el-input type="password" v-model="ruleForm.oldPassword" autocomplete="off"/>
        </el-form-item>

        <el-form-item label="密码" prop="newPassword">
          <el-input type="password" v-model="ruleForm.newPassword" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="passwordConfirmation">
          <el-input type="password" v-model="ruleForm.passwordConfirmation" autocomplete="off"></el-input>
        </el-form-item>
        <div style="height: 50px; text-align: center; font-size: large; ">
            <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
            <el-button @click="resetForm('ruleForm')">重置</el-button>
        </div>
      </el-form>
    </div>
      </el-col>
    </el-row>
  </div>

</template>

<script>

import {registUser} from "../../api/comapi/comapi";

export default {
  name: "ChgPwd",
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.ruleForm.passwordConfirmation !== '') {
          this.$refs.ruleForm.validateField('passwordConfirmation');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm.newPassword) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        newPassword: '',
        passwordConfirmation: '',
        oldPassword: ''
      },
      rules: {
        newPassword: [
          { validator: validatePass, trigger: 'blur' }
        ],
        passwordConfirmation: [
          { validator: validatePass2, trigger: 'blur' }
        ],
        // oldPassword: [
        //   { validator: checkAge, trigger: 'blur' }
        // ]
      }
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          registUser(this.ruleForm).then(response => {
            this.msgSuccess("密码修改成功");
            // this.$emit("close", {refresh:true,isSuccess:true});
          });
          // alert('submit!');
        } else {
          // console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  }
}
</script>

<style scoped>

</style>
