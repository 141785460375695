<template>
  <div>
    <h2 align="center">请 假 申 请</h2>
    <el-form ref="form" :model="form" :rules="rules" label-width="150px">
      <el-row :gutter="35">
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
          <el-form-item label="姓名" prop="name">
            <el-input disabled v-model="form.name" placeholder="请输入姓名"/>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
          <el-form-item label="公司" prop="companyName">
            <el-input disabled v-model="form.companyName" placeholder="请输入所属公司"/>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
          <el-form-item label="部门" prop="departmentName">
            <el-input disabled v-model="form.departmentName" placeholder="请输入部门"/>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
          <el-form-item label="岗位" prop="positionName">
            <el-input disabled v-model="form.positionName" placeholder="请输入岗位"/>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
          <el-form-item label="入职时间" prop="joinCompanyDate">
            <el-input disabled v-model="form.joinCompanyDate" placeholder="请输入职时间"/>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
          <el-form-item label="员工状态" prop="empStatus">
            <el-input disabled v-model="form.empStatus" placeholder="员工状态"/>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
          <el-form-item label="开始时间" prop="beginTime" :rules="{required: form.id?false:true,message: '开始时间不能为空', trigger: 'blur' }">
            <el-date-picker :disabled="isReadOnly" clearable size="small"
                            v-model="form.beginTime" @change="datetimeChange"
                            type="datetime"
                            value-format="yyyy-MM-dd HH:mm"
                            placeholder="开始时间">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
          <el-form-item label="结束时间" prop="endTime" :rules="{required: form.id?false:true,message: '结束时间不能为空', trigger: 'blur' }">
            <el-date-picker :disabled="isReadOnly" clearable size="small"
                            v-model="form.endTime" @change="datetimeChange"
                            type="datetime"
                            value-format="yyyy-MM-dd HH:mm"
                            placeholder="结束时间">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
          <el-form-item label="请假天数" prop="days" :rules="{required: form.id?false:true,message: '天数不能为空', trigger: 'blur' }">
<!--            <el-input :disabled="isReadOnly" v-model="form.days" placeholder="请假天数"/>-->
            <el-select :disabled="isReadOnly" v-model="form.days" placeholder="请选择请假天数">
              <el-option v-for="item in 60" :key="item" :label="(item*0.5)+'天'" :value="(item*0.5)"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
          <el-form-item v-if="taskStep == 'hrApprove'" label="请假类型" prop="type" :rules="{required: true,message: '请假类型不能为空', trigger: 'blur' }">
            <el-select v-if="taskStep == 'hrApprove'" v-model="form.type" clearable @change="typeChange" placeholder="请假类型" size="small">
              <el-option v-for="item in getDictDatas(DICT_TYPE.BPM_LEAVE_TYPE)" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled">
              </el-option>
            </el-select>
            <span v-if="taskStep == 'hrApprove' && form.type=='年假'" >剩{{form.annualDays}}天</span>
            <span v-if="taskStep == 'hrApprove' && form.type=='往年结余年假'" >剩{{form.lastAnnual}}天</span>
            <span v-if="taskStep == 'hrApprove' && form.type=='调休假'" >剩{{form.otLeave}}天</span>
          </el-form-item>
          <el-form-item v-if="taskStep != 'hrApprove' && taskStep != 'apply'" label="请假类型" prop="type">
            <el-select v-if="taskStep != 'hrApprove'" :disabled="isReadOnly || this.taskStep != 'hrApprove'"
                       v-model="form.type" clearable placeholder="请假类型" size="small">
              <el-option v-for="item in getDictDatas(DICT_TYPE.BPM_LEAVE_TYPE)" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">
          <el-form-item label="请假事由" prop="reason">
            <el-input :disabled="isReadOnly" v-model="form.reason" type="textarea" placeholder="请假事由"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="35">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb20">
          <el-form-item label="附件" prop="attachedFiles">
            <template v-if="form.attachedFiles">
              <el-tag v-for="item in form.attachedFiles"
                      :key="item.name"
                      @click="download(item)">
                <el-link type="primary" target="_blank">
                  {{item.name}}
                </el-link>
              </el-tag>
            </template>
            <template v-if="!form.id">
              <el-upload
                  action
                  class="upload-demo"
                  ref="upload"
                  multiple
                  :limit="20"
                  :on-exceed="handleExceed"
                  :http-request="uploadFile"
                  :on-preview="handlePreview"
                  :on-remove="handleRemove"
                  :before-upload="beforeUpload"
                  :on-change="handleChange"
                  :file-list="fileList"
                  :auto-upload="false">
                <el-button slot="trigger" size="small" type="primary">上传文件</el-button>
                <!--                <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">上传到服务器</el-button>-->
                <!--                <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>-->
              </el-upload>
              <!--              <el-input disabled v-model="form.url" placeholder="请选择文件"/>-->
            </template>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="35" v-if="!form.id">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb20">
          <el-form-item label="抄送给" prop="cc" style="display:block !important;">
            <template v-if="!form.id"> 
              <treeselect :disabled="isReadOnly" v-model="cc" :options="employTree"
                        :multiple="true" :flat="true" :disable-branch-nodes="true" noChildrenText="没有员工"
                        :normalizer="normalizerNode" placeholder="请选择抄送人员"/>
            </template>
            <template v-if="form.id">
              <span>{{form.otherInfo? form.otherInfo.ccName:"无"}}</span>
            </template>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="35">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb20">
          <el-form-item label="相关人员">
          <el-popover
              placement="right"
              width="300"
              trigger="click">
            <Approves :approvesOrder="approvesOrder" :otherInfo="form.otherInfo"></Approves>
            <el-button type="text" slot="reference">相关人员</el-button>
          </el-popover>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer" style="text-align: center">
      <template v-if="showSubmit">
      <el-button v-if="taskStep == 'apply'" type="primary" @click="submitForm" v-permission="['/bpm/attLeave/apply', '/bpm/attLeave/approve']">提 交
      </el-button>
      </template>
      <template v-if="isShowClose">
        <el-button v-if="isReadOnly" @click="cancel">关 闭</el-button>
<!--        <el-button v-if="!isReadOnly" @click="cancel">取 消</el-button>-->
      </template>
    </div>

  </div>
</template>

<script>
import permission from '@/directive/permission/index.js'; // 权限判断指令
import {
  initApply,
  addAttLeave,
  getAttLeave,
  getByProcessInstanceId,
} from "@/api/bpm/attLeave.js";
import { showLoading, hideLoading } from '@/utils/reqloading';
import {fileDownload} from "@/utils/fileUtils";
import {download} from "@/api/comapi/comapi.js";
import {DICT_TYPE,getDictLabelFromData, getDictDataLabel, getDictDatas} from "@/utils/dict";
import Approves from "../components/approves"
import { getOrgEmpTree, normalizerEmployMultiSelectTree} from '@/views/pages/employ/components/empOrgTree';
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import {strToIntArray, getEmpNamesStrFromListByIds} from "@/utils/tools";


export default {
  name: "attLeaveEdit",
  components: {
    Treeselect,
    Approves,
  },
  props: {
    type: String,
    // row: Object,
    id: String,
    processInstanceId: String,
    isReadOnly: Boolean,
    isShowClose: Boolean,
  },
  directives: { permission },
  watch:{
    // "row.id": function (){
    //   this.load();
    // },
    id(){
      this.load();
    },
    processInstanceId(){
      this.load();
    },
  },
  computed: {

  },
  activated() {
    // this.tinymceFlag++;
  },
  data(){
    return {
      employTree: [],
      fileList:[],
      formData: null,
      taskStep: 'apply',
      // 表单参数
      cc: null,
      form: {
      },
      // 表单校验
      rules: {
      },
      showSubmit: true,

      approvesOrder: {"dm":"部门领导", "hr":"人事专员", "HRManager":"人事经理", "gm":"总经理"},
    };
  },
  created() {
    this.load();
    if(this.type){
      this.taskStep = this.type;
    }
    console.log("this.taskStep ="+this.taskStep );
  },
  methods: {
    datetimeChange(){
      if(this.form.beginTime && this.form.endTime){
        let beginTime = new Date(this.form.beginTime);
        let endTime = new Date(this.form.endTime);
        if(beginTime.getTime() >= endTime.getTime()){
          this.msgError("结束时间不能小于开始时间！");
          return;
        }

      }
    },
    validateAttLeaveDate(){
      let beginTime = new Date(this.form.beginTime);
      let endTime = new Date(this.form.endTime);
      if(beginTime.getTime() >= endTime.getTime()){
        this.msgError("结束时间不能小于开始时间！");
        return false;
      }
      if(this.form.days <= 0 ){
        this.msgError("休假天数不能小于等于0");
        return false;
      }
      return true;
    },
    typeChange(val){
      this.$nextTick(() => {

      });
    },

    load() {
      this.reset();
      showLoading();
      console.log("load...")
      if(this.type == 'apply'){
        console.log("apply..");
        if(!this.employTree || this.employTree.length <= 0){
          getOrgEmpTree(this.$store.getters.userInfo.companyId, true,(tree, companyList, departmentList, employList )=>{
            this.employTree = tree;
            console.log('this.employTree');
            console.log(this.employTree);
            this.departmentList = departmentList;
            this.departmentOptions = this.handleTree(departmentList, "id", "pid");

            this.companyOptions = [];
            this.companyOptions = this.handleTree(companyList, "id", "pid");

            this.employList = employList;
            hideLoading();
          });
        }
        initApply().then(response => {
          this.form = response.data;
          this.initForm();
          hideLoading();
        });
      }else {
        if(this.id) {
          console.log("id=");
          console.log(this.id);
          getAttLeave(this.id).then(response => {
            this.form = response.data;
            this.initForm();
            hideLoading();
          });
        // }else if(this.row){
        //   console.log("row=");
        //   console.log(this.row);
        //   this.form = this.row;
        //   this.initForm();
        //   hideLoading();
        }else if(this.processInstanceId){
          console.log("processInstanceId="+this.processInstanceId);
          getByProcessInstanceId(this.processInstanceId).then(response => {
            this.form = response.data;
            // if(response.data.otherInfo && response.data.otherInfo["cc"]){
            //   const ids = response.data.otherInfo.cc
            //   this.cc = strToIntArray(ids);
            // }
            // this.form.companyName = this.$store.getters.userInfo.companyName;
            this.initForm();
            hideLoading();
          });
        }else{
          hideLoading();
          console.log("no arg");
        }
      }
    },
    // 重新加载数据后，初识不能从后台返回的数据
    initForm(){
      this.form.companyName = this.$store.getters.userInfo.companyMap[this.form.companyId];
      if(!this.form.companyName){
        this.form.companyName = this.$store.getters.userInfo.companyName;
      }
      // console.log(this.$store.getters.userInfo.companyName)
      this.showSubmit = true;
    },
    // 取消按钮
    cancel() {
      this.$emit("close", {refresh:false,isSuccess:true});
    },
    // 表单重置
    reset() {
      this.form = {};
    },
    normalizerNode(node) {
      return normalizerEmployMultiSelectTree(node);
    },
    /** 提交按钮 */
    submitForm() {
      if(this.type == 'apply'){
        console.log("submitform")
        let self = this;
        this.$refs["form"].validate(valid => {
          if (valid) {
            if(!this.validateAttLeaveDate()){
              return;
            }
            if (this.form.id == null) {
              if(this.cc){
                this.form.cc = this.cc.join(",");
                this.form.ccName = getEmpNamesStrFromListByIds(this.employList, this.cc);
              }
              showLoading();
              self.formData = new FormData();
              //uploadFile会逐个加入到formData
              self.$refs.upload.submit();

              // formData.append('files', _file);
              delete this.form.companyName;
              console.log("days="+this.form.days);
              self.formData.append('attLeaveStr', JSON.stringify(this.form)); // isOverride自定义的其他参数
              addAttLeave(self.formData).then(response => {
                this.form = response.data;
                this.msgSuccess("申请成功");
                this.showSubmit = false;
                hideLoading();
                this.bus.$emit('onCurrentContextmenuClick', {
                  id: 1,
                  path: this.$route.path,
                });

              });
            }
          }
        });
      }else{

      }
    },

    handleExceed(files, fileList) {
      this.msgError(`当前限制选择 20 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件.`);
    },

    handleRemove(file, fileList) {
      console.log("handleRemove");
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log("handlePreview");
      console.log(file);
    },

    handleChange(file, fileList) {
      console.log("handleChange");
      console.log(file)
      console.log(fileList)
      //this.currentContract.contractPictures = fileList;
      // 非自动上传，before-upload 不起作用，改到这里验证。
      let isValidFile = this.beforeUpload(file);
      if (!isValidFile) {
        this.$refs.upload.handleRemove(file);
      }
    },
    beforeUpload(file) {
      console.log("beforeUpload");
      var fileType = file.name.substring(file.name.lastIndexOf('.') + 1);
      const jpg = fileType === 'jpg' || fileType === 'JPG';
      const jpeg = fileType === 'jpeg' || fileType === 'JPEG';
      const isLt2M = file.size / 1024 / 1024 < 5;
      const isEmpty = file.size === 0;
      // if (!jpg && !jpeg) {
      //   this.msgError('上传文件只能是png,jpeg格式!');
      // }
      if (!isLt2M) {
        this.msgError('上传文件大小不能超过 5MB!');
      }
      // if (isEmpty) {
      //   this.msgError('上传文件大小不能为空文件!');
      // }
      // return (jpeg || jpg) && isLt2M && !isEmpty;
      return isLt2M;
    },

    async uploadFile(params) {
      console.log("uploadFile");
      console.debug(params);
      this.formData.append('files', params.file);

    },

    download(row) {
      fileDownload(row,download);
    },

  },
}
</script>

<style scoped>

</style>
