import request from '@/utils/request'
import {parseFilters, handleTree} from "@/utils/tools";

// 查询员工信息列表
export function listEmploy(query) {
    return request({
        url: '/employ/employ/findAll',
        method: 'post',
        data: query
    })
}
export function listEmployIncludeDelete(query) {
    return request({
        url: '/employ/employ/findAllIncludeDelete',
        method: 'post',
        data: query
    })
}

//恢复删除的员工，根据ids
export function recovery(id){
    return request({
        url: '/employ/employ/recovery',
        method: 'post',
        data: id
    })
}
//彻底删除员工，根据ids
export function hardDelete(id){
    return request({
        url: '/employ/employ/hardDelete',
        method: 'post',
        data: id
    })
}

// 查询员工合同信息列表
export function findAllEmplyees(query) {
    return request({
        url: '/employ/employ/findAllEmplyees',
        method: 'post',
        data: query
    })
}

export function queryDynamic(query) {
    return request({
        url: '/employ/employ/findDynamic',
        method: 'post',
        data: query
    })
}

// 查询员工信息详细
export function getEmploy(id) {
    return request({
        url: '/employ/employ/findOne?id=' + id,
        method: 'post'
    })
}

// 查询公司员工
export function findSimpleByCompay(companyId) {
    return request({
        url: '/employ/employ/findSimpleByCompay?companyId=' + companyId,
        method: 'get'
    })
}

// 查询部门员工
export function findSimpleByDepartment(departmentId) {
    return request({
        url: '/employ/employ/findSimpleByDepartment?departmentId=' + departmentId,
        method: 'get'
    })
}

// 查询员工信息列表
export function listSimpleEmploy(query) {
    return request({
        url: '/employ/employ/findAllSimple',
        method: 'post',
        data: query
    })
}

// 新增员工信息
export function addEmploy(data) {
    return request({
        url: '/employ/employ/add',
        method: 'post',
        data: data
    })
}

// hr修改员工信息
export function updateEmploy(data) {
    return request({
        url: '/employ/employ/edit',
        method: 'post',
        data: data
    })
}

// 批量更新
export function updateBatchEmploys(data) {
    return request({
        url: '/employ/employ/updateEmploys',
        method: 'post',
        data: data
    })
}

//调动员工，记录调动轨迹
export function transferBatchEmploys(data){
    return request({
        url: '/employ/employ/transferBatchEmploys',
        method: 'post',
        data: data
    })
}

// hr修改员工信息
export function removeContracts(data) {
    return request({
        url: '/employ/employ/removeContracts',
        method: 'post',
        data: data
    })
}

// 删除员工信息
export function delEmploy(id) {
    return request({
        url: '/employ/employ/remove',
        method: 'post',
        data: id
    })
}

//加入黑名单
export function addBlackList(id) {
    return request({
        url: '/employ/employ/addBlackList',
        method: 'post',
        data: id
    })
}

//转正，根据ids
export function trunToOffical(id){
    return request({
        url: '/employ/employ/trunToOffical',
        method: 'post',
        data: id
    })
}

// 导出员工信息
export function exportEmploy(query) {
    return request({
        url: '/employ/employ/export',
        method: 'post',
        data: query,
        responseType: 'blob',
    })
}

// 导出员工信息
export function uploadBatchEmployees(data) {
    return request({
        url: '/employ/employ/uploadBatchEmployees',
        method: 'post',
        data: data
    })
}

// 下载个人相片
export function downloadPersonalPhoto(filePath) {
    return request({
        url: '/employ/employ/downloadPersonalPhoto?filePath='+filePath,
        method: 'get',
        responseType: 'blob'
    })
}

// 上传个人相片
export function uploadPersonalPhoto(data) {
    return request({
        url: '/employ/employ/UploadPersonalPhoto',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}

// 上传个人相片
export function UploadPersonalPicture(data) {
    return request({
        url: '/employ/employ/UploadPersonalPicture',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}


// 上传学历证书
export function uploadCertifcatePictureApi(data) {
    return request({
        url: '/employ/employ/uploadCertifcatePicture',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}

// 下载学历证书
export function downloadCertificatePictureApi(filePath) {
    return request({
        url: '/employ/employ/downloadCertificatePicture?filePath='+filePath,
        method: 'get',
        responseType: 'blob'
    })
}

// 上传身份证
export function uploadIdCardPicture(data) {
    return request({
        url: '/employ/employ/UploadIDCardPicture',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}

// 上传员工头像
export function UploadPersonalHeadPhoto(data) {
    return request({
        url: '/employ/employ/UploadPersonalHeadPhoto',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}

// 上传个人体检表
export function uploadHealthCheckPicture(data) {
    return request({
        url: '/employ/employ/uploadHealthCheckPicture',
        method: 'post',
        data: data
    })
}

// 删除个人体检表
export function DeleteHealthCheckPicture(data) {
    return request({
        url: '/employ/employ/deleteHealthCheckPicture',
        method: 'post',
        data: data
    })
}

// 删除图片
export function deletePicture(filePath) {
    return request({
        url: '/employ/employ/deletePicture?filePath='+filePath,
        method: 'post',
    })
}

// 上传个人体检表
export function uploadleavingCertificatePicture(data) {
    return request({
        url: '/employ/employ/uploadLeavingCertificatePicture',
        method: 'post',
        data: data
    })
}

// 更新体检结果
export function updateHealthCheck(id, result,remarks){
    return request({
        url: '/employ/employ/updateHealthCheck?employeeId=' + id+'&result='+result+'&remarks='+remarks,
        method: 'get'
    })
}


export function downloadHealthPictures(data) {
    return request({
        url: '/employ/employ/downloadHealthPictures',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}

// 上传个人离职证明
export function updateLeavingDocuments(data) {
    return request({
        url: '/employ/employ/updateLeavingDocuments',
        method: 'post',
        data: data
    })
}

// 删除个人离职证明
export function deleteLeavingCertificatePicture(data) {
    return request({
        url: '/employ/employ/deleteLeavingCertificatePicture',
        method: 'post',
        data: data
    })
}


export function statisticEntry(companyId, date){
    return request({
        url: '/employ/employ/statisticEntry?companyIds=' + companyId+'&date='+date,
        method: 'get'
    })
}

export function statisticDepart(companyId, date){
    return request({
        url: '/employ/employ/statisticDepart?companyIds=' + companyId+'&date='+date,
        method: 'get'
    })
}

export function countOfficalEmploy(companyCode, date){
    return request({
        url: '/employ/employ/countOfficalEmploy?companyCode=' + companyCode+'&date='+date,
        method: 'get'
    })
}

// unlock employee by id
export function unlockEmployee(id) {
    return request({
        url: '/employ/employ/unlockEmployee?employeeId='+id,
        method: 'post'
    })
}

// lock employee by id
export function lockEmployee(id) {
    return request({
        url: '/employ/employ/lockEmployee?employeeId='+id,
        method: 'post'
    })
}

// 保存年假设置
export function updateEmployList(list){
    return request({
        url: '/employ/employ/updateEmployList',
        method: 'post',
        data: list
    })
}


/** 根据用户ID 查员工 */
export function getEmployByUserId(userId, callback) {
    console.log("aabb")
    let _filter = {};
    if(userId) {
        _filter['userId'] = {
            type: 'eq',
            value: userId,
        }
    }else{
        callback(null);
    }
    let where = {};
    const filters = parseFilters(_filter)
    if (filters && filters.length > 0) {
        where.filters = filters
    } else {
        // 如果过滤条件为空，设置空数组
        where.filters = []
    }
    const params = {
        pageNum: 1,
        pageSize: 1000,
        where: where,
        order: ''
    }
    console.log(params)
    listSimpleEmploy(params).then(response => {
        const list = response.data.records;
        if(list && list.length>0){
            callback(list[0]);
        }else{
            callback(null);
        }
    });
}

/** 根据用户ID 查员工 */
export function getEmploysByDepPos(departmentId, positionId, callback) {
    let _filter = {};
    if(departmentId) {
        _filter['departmentId'] = {
            type: 'eq',
            value: departmentId,
        }
    }else{
        callback(null);
    }
    if(positionId) {
        _filter['positionId'] = {
            type: 'eq',
            value: positionId,
        }
    }else{
        callback(null);
    }
    let where = {};
    const filters = parseFilters(_filter)
    if (filters && filters.length > 0) {
        where.filters = filters
    } else {
        // 如果过滤条件为空，设置空数组
        where.filters = []
    }
    const params = {
        pageNum: 1,
        pageSize: 5000,
        where: where,
        order: ''
    }
    listSimpleEmploy(params).then(response => {
        const list = response.data.records;
        callback(list);

    });

}

// 查询部门员工
export function findRecommenderList(recommander,companyId) {
    return request({
        url: '/employ/employ/findRecommenderList?recommander=' + recommander+'&companyId='+companyId,
        method: 'get'
    })
}

export function updateMultiSalary(data){
    return request({
        url: '/employ/employ/updateMultiSalary',
        method: 'post',
        data: data
    })
}
