import request from '@/utils/request'

// 查询用户人脸列表
export function listUserFace(query) {
    return request({
        url: '/sys/userFace/findAll',
        method: 'post',
        data: query
    })
}

// 查询用户人脸详细
export function getUserFace(id) {
    return request({
        url: '/sys/userFace/findOne?id=' + id,
        method: 'post'
    })
}

// 查询用户人脸详细
export function getByUserId(userId) {
    return request({
        url: '/sys/userFace/getByUserId?userId=' + userId,
        method: 'post'
    })
}

// 新增用户人脸
export function addUserFace(data) {
    return request({
        url: '/sys/userFace/add',
        method: 'post',
        data: data
    })
}

// 修改用户人脸
export function updateUserFace(data) {
    return request({
        url: '/sys/userFace/edit',
        method: 'post',
        data: data
    })
}

// 删除用户人脸
export function delUserFace(id) {
    return request({
        url: '/sys/userFace/remove',
        method: 'post',
        data: id
    })
}

export function approvedById(id) {
    return request({
        url: '/sys/userFace/approvedById',
        method: 'post',
        data: id
    })
}

export function disapprovedById(id) {
    return request({
        url: '/sys/userFace/disapprovedById',
        method: 'post',
        data: id
    })
}

// 导出用户人脸
export function exportUserFace(query) {
    return request({
        url: '/sys/userFace/export',
        method: 'post',
        data: query
    })
}
