import request from '@/utils/request'

//新增面试流程
export function addInterViewProcess(data) {
 return request({
    url: '/recruitprocess/add',
     method: 'post',
    data: data
 })
}

//更新面试流程
export function editInterViewProcess(data) {
    return request({
       url: '/recruitprocess/edit',
        method: 'post',
       data: data
    })
   }

export function findAllProcesses(query) {
    return request({
        url: '/recruitprocess/findAll',
        method: 'post',
        data: query
    })
}

export function findAllInterviewProcess() {
    return request({
        url: '/recruitprocess/findAllInterviewProcess',
        method: 'post'
    })
}



export function findOne(id) {
    return request({
        url: '/recruitprocess/findOne?id=' + id,
        method: 'post'
    })
}

export function deleteInterviewProcess(ids) {
    return request({
        url: '/recruitprocess/remove',
        method: 'post',
        data: ids
    })
}
