import request from '@/utils/request'

// 查询打卡记录列表
export function listAttConfirms(query) {
    return request({
        url: '/attendance/attConfirm/findAll',
        method: 'post',
        data: query
    })
}

//触发考勤统计，根据考勤打卡时间计算考勤统计，并重新生成月考勤确认记录
export function calculateStatisticAndGenConfirm(start, end, empIds){
    return request({
        url: '/attendance/attConfirm/calculateStatisticAndGenConfirm?start='+start+"&end="+end+"&empIds="+empIds,
        method: 'get'
    })
}

export function updateConfirmStatus(ids, confirmStatus){
    return request({
        url: '/attendance/attConfirm/updateConfirmStatus?ids='+ids+"&confirmStatus="+confirmStatus,
        method: 'post'
    })

}

// 更新满勤
export function updateFullAtt(ids, isFullAtt){
    return request({
        url: '/attendance/attConfirm/updateFullAtt?ids='+ids+"&isFullAtt="+isFullAtt,
        method: 'post'
    })

}

// 删除打卡记录
export function delAttConfirm(id) {
    return request({
        url: '/attendance/attConfirm/remove',
        method: 'post',
        data: id
    })
}

//
export function exportConfirm(query){
    return request({
        url: '/attendance/attConfirm/exportConfirm',
        method: 'post',
        data: query,
        responseType: 'blob',
    })
}

export function importAttConfirm(data) {
    return request({
        url: '/attendance/attConfirm/importAttConfirm',
        method: 'post',
        data: data
    })
}
