<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px">
      <el-form-item label="公司id" prop="companyid">
        <el-input
            v-model="queryParams.companyid"
            placeholder="请输入公司id"
            clearable
            size="small"
            @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="公司code" prop="companycode">
        <el-input
            v-model="queryParams.companycode"
            placeholder="请输入公司code"
            clearable
            size="small"
            @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="用户id" prop="userid">
        <el-input
            v-model="queryParams.userid"
            placeholder="请输入用户id"
            clearable
            size="small"
            @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="员工id" prop="empid">
        <el-input
            v-model="queryParams.empid"
            placeholder="请输入员工id"
            clearable
            size="small"
            @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="员工姓名" prop="empname">
        <el-input
            v-model="queryParams.empname"
            placeholder="请输入员工姓名"
            clearable
            size="small"
            @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="结算天数" prop="days">
        <el-input
            v-model="queryParams.days"
            placeholder="请输入结算天数"
            clearable
            size="small"
            @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="发放工资id" prop="salaryid">
        <el-input
            v-model="queryParams.salaryid"
            placeholder="请输入发放工资id"
            clearable
            size="small"
            @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="调休结算到几月工资发放" prop="salarydate">
        <el-date-picker clearable size="small"
                        v-model="queryParams.salarydate"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="选择调休结算到几月工资发放">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="状态，计算中；审核中；发放中；已发放；" prop="status">
        <el-select v-model="queryParams.status" placeholder="请选择状态，计算中；审核中；发放中；已发放；" clearable
                   size="small">
          <el-option label="请选择字典生成" value=""/>
        </el-select>
      </el-form-item>
      <el-form-item label="结算金额" prop="amount">
        <el-input
            v-model="queryParams.amount"
            placeholder="请输入结算金额"
            clearable
            size="small"
            @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="其他信息" prop="deleted">
        <el-input
            v-model="queryParams.deleted"
            placeholder="请输入其他信息"
            clearable
            size="small"
            @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="创建者" prop="createby">
        <el-input
            v-model="queryParams.createby"
            placeholder="请输入创建者"
            clearable
            size="small"
            @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="创建时间" prop="createtime">
        <el-date-picker clearable size="small"
                        v-model="queryParams.createtime"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="选择创建时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="创建者" prop="updateby">
        <el-input
            v-model="queryParams.updateby"
            placeholder="请输入创建者"
            clearable
            size="small"
            @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="更新时间" prop="updatetime">
        <el-date-picker clearable size="small"
                        v-model="queryParams.updatetime"
                        type="date"
                        value-format="yyyy-MM-dd"
                        placeholder="选择更新时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
            type="primary"
            plain
            icon="el-icon-plus"
            size="mini"
            @click="handleAdd"
            v-permission="['/cb/cbSettleLieu/add']"
        >新增
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
            type="success"
            plain
            icon="el-icon-edit"
            size="mini"
            :disabled="single"
            @click="handleUpdate"
            v-permission="['/cb/cbSettleLieu/edit']"
        >修改
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
            type="danger"
            plain
            icon="el-icon-delete"
            size="mini"
            :disabled="multiple"
            @click="handleDelete"
            v-permission="['/cb/cbSettleLieu/remove']"
        >删除
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
            type="warning"
            plain
            icon="el-icon-download"
            size="mini"
            @click="handleExport"
            v-permission="['/cb/cbSettleLieu/export']"
        >导出
        </el-button>
      </el-col>
      <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>
    </el-row>

    <el-table v-loading="loading" :data="cbSettleLieuList" @selection-change="handleSelectionChange" stripe>
      <el-table-column type="selection" width="55" align="center"/>
      <el-table-column label="自增id" align="center" prop="id"/>
      <el-table-column label="公司id" align="center" prop="companyid"/>
      <el-table-column label="公司code" align="center" prop="companycode"/>
      <el-table-column label="用户id" align="center" prop="userid"/>
      <el-table-column label="员工id" align="center" prop="empid"/>
      <el-table-column label="员工姓名" align="center" prop="empname"/>
      <el-table-column label="结算天数" align="center" prop="days"/>
      <el-table-column label="发放工资id" align="center" prop="salaryid"/>
      <el-table-column label="调休结算到几月工资发放" align="center" prop="salarydate" width="180">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.salarydate, '{y}-{m}-{d}') }}</span>
        </template>
      </el-table-column>
      <el-table-column label="状态，计算中；审核中；发放中；已发放；" align="center" prop="status"/>
      <el-table-column label="结算金额" align="center" prop="amount"/>
      <el-table-column label="其他信息" align="center" prop="otherinfo"/>
      <el-table-column label="其他信息" align="center" prop="deleted"/>
      <el-table-column label="创建者" align="center" prop="createby"/>
      <el-table-column label="创建时间" align="center" prop="createtime" width="180">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.createtime, '{y}-{m}-{d}') }}</span>
        </template>
      </el-table-column>
      <el-table-column label="创建者" align="center" prop="updateby"/>
      <el-table-column label="更新时间" align="center" prop="updatetime" width="180">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.updatetime, '{y}-{m}-{d}') }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="handleUpdate(scope.row)"
              v-permission="['/cb/cbSettleLieu/edit']"
          >修改
          </el-button>
          <el-button
              size="mini"
              type="text"
              icon="el-icon-delete"
              @click="handleDelete(scope.row)"
              v-permission="['/cb/cbSettleLieu/remove']"
          >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
        v-show="total>0"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
    />

    <!-- 添加或修改调休假结算对话框 -->
    <el-dialog :title="title" :visible.sync="open" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-row :gutter="35">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
            <el-form-item label="公司id" prop="companyid">
              <el-input v-model="form.companyid" placeholder="请输入公司id"/>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
            <el-form-item label="公司code" prop="companycode">
              <el-input v-model="form.companycode" placeholder="请输入公司code"/>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
            <el-form-item label="用户id" prop="userid">
              <el-input v-model="form.userid" placeholder="请输入用户id"/>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
            <el-form-item label="员工id" prop="empid">
              <el-input v-model="form.empid" placeholder="请输入员工id"/>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
            <el-form-item label="员工姓名" prop="empname">
              <el-input v-model="form.empname" placeholder="请输入员工姓名"/>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
            <el-form-item label="结算天数" prop="days">
              <el-input v-model="form.days" placeholder="请输入结算天数"/>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
            <el-form-item label="发放工资id" prop="salaryid">
              <el-input v-model="form.salaryid" placeholder="请输入发放工资id"/>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
            <el-form-item label="调休结算到几月工资发放" prop="salarydate">
              <el-date-picker clearable size="small"
                              v-model="form.salarydate"
                              type="date"
                              value-format="yyyy-MM-dd"
                              placeholder="选择调休结算到几月工资发放">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
            <el-form-item label="状态，计算中；审核中；发放中；已发放；">
              <el-radio-group v-model="form.status">
                <el-radio label="1">请选择字典生成</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
            <el-form-item label="结算金额" prop="amount">
              <el-input v-model="form.amount" placeholder="请输入结算金额"/>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
            <el-form-item label="其他信息" prop="deleted">
              <el-input v-model="form.deleted" placeholder="请输入其他信息"/>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm" v-permission="['/cb/cbSettleLieu/add', '/cb/cbSettleLieu/edit']">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  listCbSettleLieu,
  getCbSettleLieu,
  delCbSettleLieu,
  addCbSettleLieu,
  updateCbSettleLieu,
  exportCbSettleLieu } from "@/api/cb/cbSettleLieu";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import RightToolbar from "@/components/RightToolbar"
import permission from '@/directive/permission/index.js' // 权限判断指令
import Pagination from "@/components/Pagination";

// 表查询配置
const dbOrderBy = ''

export default {
  name: "CbSettleLieu",
  components: {
    Treeselect,
    RightToolbar,
    Pagination,
  },
  directives: { permission },
  data() {
    return {
      // 遮罩层
      loading: true,
      // 查询参数
      where: {
        query: '',
        filters: []
      },


      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 调休假结算表格数据
      cbSettleLieuList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        orderby: dbOrderBy,
        companyid: null,
        companycode: null,
        userid: null,
        empid: null,
        empname: null,
        days: null,
        salaryid: null,
        salarydate: null,
        status: null,
        amount: null,
        otherinfo: null,
        deleted: null,
        createby: null,
        createtime: null,
        updateby: null,
        updatetime: null
      },
      // 表单参数
      form: {
      }
      ,
      // 表单校验
      rules: {
        companyid: [
          {
            required: true,
            message: "公司id不能为空", trigger: "blur" }
        ],
        companycode: [
          {
            required: true,
            message: "公司code不能为空", trigger: "blur" }
        ],
        userid: [
          {
            required: true,
            message: "用户id不能为空", trigger: "blur" }
        ],
        empid: [
          {
            required: true,
            message: "员工id不能为空", trigger: "blur" }
        ],
        empname: [
          {
            required: true,
            message: "员工姓名不能为空", trigger: "blur" }
        ],
        salarydate: [
          {
            required: true,
            message: "调休结算到几月工资发放不能为空", trigger: "blur" }
        ],
        deleted: [
          {
            required: true,
            message: "调休结算到几月工资发放不能为空", trigger: "blur" }
        ],
      }
    }
        ;
  },
  created() {
    this._filter = {};
    this.getList();
  },
  methods: {
    /** 查询调休假结算列表 */
    getQuery(){
      this._filter = {};
      if(this.queryParams.companyid) {
        this._filter['companyid'] = {
          type: 'search',
          value: this.queryParams.companyid,
        }
      }
      if(this.queryParams.companycode) {
        this._filter['companycode'] = {
          type: 'search',
          value: this.queryParams.companycode,
        }
      }
      if(this.queryParams.userid) {
        this._filter['userid'] = {
          type: 'search',
          value: this.queryParams.userid,
        }
      }
      if(this.queryParams.empid) {
        this._filter['empid'] = {
          type: 'search',
          value: this.queryParams.empid,
        }
      }
      if(this.queryParams.empname) {
        this._filter['empname'] = {
          type: 'search',
          value: this.queryParams.empname,
        }
      }
      if(this.queryParams.days) {
        this._filter['days'] = {
          type: 'search',
          value: this.queryParams.days,
        }
      }
      if(this.queryParams.salaryid) {
        this._filter['salaryid'] = {
          type: 'search',
          value: this.queryParams.salaryid,
        }
      }
      if(this.queryParams.salarydate) {
        this._filter['salarydate'] = {
          type: 'search',
          value: this.queryParams.salarydate,
        }
      }
      if(this.queryParams.status) {
        this._filter['status'] = {
          type: 'search',
          value: this.queryParams.status,
        }
      }
      if(this.queryParams.amount) {
        this._filter['amount'] = {
          type: 'search',
          value: this.queryParams.amount,
        }
      }
      if(this.queryParams.otherinfo) {
        this._filter['otherinfo'] = {
          type: 'search',
          value: this.queryParams.otherinfo,
        }
      }
      if(this.queryParams.deleted) {
        this._filter['deleted'] = {
          type: 'search',
          value: this.queryParams.deleted,
        }
      }
      if(this.queryParams.createby) {
        this._filter['createby'] = {
          type: 'search',
          value: this.queryParams.createby,
        }
      }
      if(this.queryParams.createtime) {
        this._filter['createtime'] = {
          type: 'search',
          value: this.queryParams.createtime,
        }
      }
      if(this.queryParams.updateby) {
        this._filter['updateby'] = {
          type: 'search',
          value: this.queryParams.updateby,
        }
      }
      if(this.queryParams.updatetime) {
        this._filter['updatetime'] = {
          type: 'search',
          value: this.queryParams.updatetime,
        }
      }
      const filters = this.parseFilters(this._filter)
      if (filters && filters.length > 0) {
        this.where.filters = filters
      } else {
        // 如果过滤条件为空，设置空数组
        this.where.filters = []
      }
      const params = {
        pageNum: this.queryParams.pageNum,
        pageSize: this.queryParams.pageSize,
        where: this.where,
        order: this.orderby
      }

      return params;
    },
    getList() {
      this.loading = true;
      const params = this.getQuery();
      listCbSettleLieu(params).then(response => {
        this.cbSettleLieuList = response.data.records;
        this.total = response.data.total;
        this.loading = false;
      })
      ;
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {};
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加调休假结算";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.id || this.ids
      getCbSettleLieu(id).then(response => {
        this.form = response.data;
        this.open = true;
        this.title = "修改调休假结算";
      })
      ;
    },
    /** 提交按钮 */
    submitForm() {
      this.refs["form"].validate(valid => {
            if(valid) {
              if (this.form.id != null) {
                updateCbSettleLieu(this.form).then(response => {
                  this.msgSuccess("修改成功");
                  this.open = false;
                  this.getList();
                })
                ;
              } else {
                addCbSettleLieu(this.form).then(response => {
                  this.msgSuccess("新增成功");
                  this.open = false;
                  this.getList();
                })
                ;
              }
            }
          }
      )
      ;
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      let ids = [];
      if(row.id){
        ids = [row.id];
      }else{
        ids = this.ids;
      }

      this.$confirm('是否确认删除选中的调休假结算数据?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return delCbSettleLieu(ids);
      }).then(() => {
        this.getList();
        this.msgSuccess("删除成功");
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      const params = this.getQuery();
      this.$confirm('是否确认导出所有调休假结算数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return exportCbSettleLieu(params);
      }).then(response => {
        this.download(response.msg);
      })
    }
  }
};
</script>

<style scoped>

</style>
