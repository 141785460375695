<template>
	<div>
		<el-form model="myPlan" label-width="130px" v-loading="dataLoading">
			<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">
				<el-form-item label="首次签到时间">
					<el-tag size="medium" style="width: 200px">{{ myPlan.createTime }}</el-tag>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">
				<el-form-item label="最后一次签到时间">
					<el-tag size="medium" style="width: 200px">{{ myPlan.updateTime }}</el-tag>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">
				<el-form-item label="">
					<el-button plain type="info" @click="close">关闭</el-button>
				</el-form-item>
			</el-col>
		</el-form>
	</div>
</template>

<script>
import { findTrainingRecord } from '@/api/training/myTraining';
export default {
	name: 'checkInRecords',

	data() {
		return {
			myPlan: {},
			dataLoading:false,
		};
	},
	props: { traningPlanId: String },
	created() {
		let myTraining = {};
		myTraining.userId = this.$store.getters.userInfo.id;
		myTraining.courseId = this.traningPlanId;
		this.dataLoading = true;
		findTrainingRecord(myTraining)
			.then((response) => {
				if (response.code == 200) {
					this.myPlan = response.data;
				} else {
					this.myPlan = {};
				}
				thi.dataLoading=false;
				console.log('获取记录培训成功。');
			})
			.catch((err) => {
				//this.msgError('获取记录培训失败，'+err);
				this.dataLoading = false;
				console.log('获取记录培训失败，'+err);
			});
	},

	mounted() {},

	methods: {
		close() {
			this.$emit('close', { refresh: false, isSuccess: true });
		},
	},
};
</script>

<style lang="scss" scoped>
</style>