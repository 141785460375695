<template>
	<el-collapse-item title="历任岗位位信息" :name="this.collapseStatus" 	:class="collapseSetting()">
		<el-form class="employ" label-width="150px">
		<el-row v-for="(previousPositionModel, index) in previousPositionsModel" :key="index" :class="newAddedAfterLocked(previousPositionModel)">
			<el-row>
				<el-row>
					<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
						<el-form-item label="公司">
							<el-input v-model="previousPositionModel.company" :readonly="canEdit(previousPositionModel)"></el-input>
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
						<el-form-item label="部门">
							<el-input v-model="previousPositionModel.department" :readonly="canEdit(previousPositionModel)"></el-input>
						</el-form-item>
					</el-col>

					<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
						<el-form-item label="岗位">
							<el-input v-model="previousPositionModel.position" :readonly="canEdit(previousPositionModel)"></el-input>
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
						<el-form-item label="岗级">
							<el-input v-model="previousPositionModel.positionLevel" :readonly="canEdit(previousPositionModel)"></el-input>
						</el-form-item>
					</el-col>

					<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
						<el-form-item label="变动日期">
							<el-date-picker
								v-model="previousPositionModel.changedDate"
								value-format="yyyy-MM-dd"
								:readonly="canEdit(previousPositionModel)"
								align="left"
								type="date"
								placeholder="选择日期"
							>
							</el-date-picker>
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
						<el-form-item label="变动类型">
							<el-input v-model="previousPositionModel.changedType" :readonly="canEdit(previousPositionModel)"></el-input>
						</el-form-item>
					</el-col>

					<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
						<el-form-item label="是否主要职位">
							<el-checkbox v-model="previousPositionModel.majorPosition" :readonly="canEdit(previousPositionModel)"></el-checkbox>
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
						<el-form-item label="是否试岗">
							<el-checkbox v-model="previousPositionModel.probation" :readonly="canEdit(previousPositionModel)"></el-checkbox>
						</el-form-item>
					</el-col>

					<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
						<el-form-item label="试岗时间">
							<el-date-picker
								v-model="previousPositionModel.probationDate"
								value-format="yyyy-MM-dd"
								:readonly="canEdit(previousPositionModel)"
								align="left"
								type="date"
								placeholder="选择日期"
							>
							</el-date-picker>
							
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
						<el-form-item label="原来公司">
							<el-input v-model="previousPositionModel.formerCompany" :readonly="canEdit(previousPositionModel)"></el-input>
						</el-form-item>
					</el-col>

					<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
						<el-form-item label="原来部门">
							<el-input v-model="previousPositionModel.formerDepartment" :readonly="canEdit(previousPositionModel)"></el-input>
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
						<el-form-item label="原来岗位">
							<el-input v-model="previousPositionModel.formerPosition" :readonly="canEdit(previousPositionModel)"></el-input>
						</el-form-item>
					</el-col>

					<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
						<el-form-item label="原来岗级">
							<el-input v-model="previousPositionModel.formerPositionLevel" :disabled="canEdit(previousPositionModel)"></el-input>
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
						<el-form-item label=" ">
						<el-button
							plain
							class="employ-form-delete-button"
							:id="previousPositionModel.company"
							v-bind:disabled="canEdit(previousPositionModel)"
							v-on:click="deletePreviousPostion($event, previousPositionModel.company)"
							type="danger"
							icon="el-icon-delete"
							>删除</el-button
						>
						</el-form-item>
					</el-col>
				</el-row>
				<div
					style="
						width: 80%;
						border-top: 1px solid;
						color: #ebeef5;
						height: 10px;
						margin-left: 10%;
						border-bottom-width: 1px;
						border-left-width: 1px;
						text-align: center;
					"
				></div>
			</el-row>
		</el-row>

		<el-row>
			<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
				<el-form-item label=" ">
					<el-button v-on:click="addPreviousPosition" type="primary" v-bind:disabled="enableAdd" plain icon="el-icon-plus">增加</el-button>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
				<el-form-item label=" ">
					<el-button v-on:click="edit" type="primary" v-bind:disabled="hasLocked?true:this.isReadOnly" plain icon="el-icon-edit">编辑</el-button>
					<el-button v-on:click="save" plain v-bind:disabled="this.isReadOnly" type="primary" :loading="saving" icon="el-icon">保存</el-button>
				</el-form-item>
			</el-col>
		</el-row>
		</el-form>
	</el-collapse-item>
</template>


<script>
export default {
	data() {
		let readonlyInit = true;
		let previousPositionsInit = new Array();
		let initAddItem = false;
		if (this.previousPositions == null || (this.previousPositions != null && this.previousPositions.length==0)) {
			previousPositionsInit.push({
				company:'',
				department: null,
				position: null,
				positionLevel:null,
				changedDate: null,
				changedType: null,
				majorPosition:null,
				probation: null,
				probationDate: null,
				formerCompany: null,
				formerDepartment: null,
				formerPosition: null,
				formerPositionLevel: null,
				newAdded:this.locked?true:false
			});

			initAddItem=true;
		} else {
			previousPositionsInit = this.previousPositions;
		}
		 
		//  isReadOnly=true for view, others for edit. Users can't eidt if locked, but they can add 
		if(this.isReadOnly || this.locked)
		{
			readonlyInit = true;
		}
		else{
			readonlyInit = false;
		}


		return {
			previousPositionsModel: previousPositionsInit,
			readonly: readonlyInit,
			enableAdd:this.enableAddButton,
			hasLocked:this.locked,
			saving:false,
			initItem:initAddItem,
			pickerOptions1: {
				disabledDate(time) {
					return time.getTime() > Date.now();
				},
			},
		};
	},
	props: { previousPositions: Array, id: Number ,isReadOnly:Boolean,collapseStatus:String,locked:Boolean,enableAddButton:Boolean  },
	watch: {
		isReadOnly(newValue,oldV) {
			this.readonly = newValue;
			//this.hasLocked = newValue;
		},
		locked(newValue,oldV) {
			this.hasLocked = newValue;
		},
	},
	computed: {
       newAddedAfterLocked() {
            return function(currentItem){
                return {employ_item_update:this.locked&&currentItem.newAdded && !this.initItem &&!this.isReadOnly};         
            }           
        },
		canEdit(){
			 return function(currentItem){
				 let returnValue = true;
				 if(this.hasLocked){
					 returnValue =currentItem.newAdded&&!this.isReadOnly?false:true;
				 }
				 else{
					returnValue = this.readonly;
				 }
                return returnValue;      
            }   
		} ,
		collapseSetting(){
			return function(){
				if(this.previousPositionsModel !=null && this.previousPositionsModel.length >0)
				{
					if(this.locked)
					{
						let newAddResults = this.previousPositionsModel.filter(item=>item.newAdded==true);
						if(newAddResults.length > 0)
						{
							if(this.initItem)
							{
								return {callpse_header_unfill:true};
							}
							else{
								return {callpse_header_newAdd:true};
							}
						}
						else
						{
							return {callpse_header_fill:true};
						}
						
					}
					else
					{
						if(this.initItem)
						{
							return {callpse_header_unfill:true};
						}
						else{
							return {callpse_header_fill:true};
						}
					}
					
				}
				else
				{
					return {callpse_header_unfill:true};
				}
				
			}
		},
    },
	methods: {
		save() {
			// save data to DB
			let savingPreviousPositions = {};
			savingPreviousPositions.id = this.id;
			savingPreviousPositions.previousPositions = this.previousPositionsModel;
			this.saving =true;
			this.$emit('updatePartialEmployeeInfo', { data: savingPreviousPositions }, (response) => {
				this.saving=false;
				if (response.success) {
					this.msgSuccess('修改成功');
					this.readonly = true;
				} else {
					this.msgError('修改失败,' + response.msg);
				}
			});
		},
		edit() {
			this.readonly = false;
		},
		addPreviousPosition() {
			this.readonly = false;
			this.previousPositionsModel.push({
				company:'',
				department: null,
				position: null,
				positionLevel:null,
				changedDate: null,
				changedType: null,
				majorPosition:null,
				probation: null,
				probationDate: null,
				formerCompany: null,
				formerDepartment: null,
				formerPosition: null,
				formerPositionLevel: null,
				newAdded:true
			});
		},
		deletePreviousPostion(event, company) {
			let self = this;
			if (event.currentTarget.id == company) {
				this.$confirm('确定要删除吗?', '确认', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'error',
					// type: 'warning'
				})
					.then(() => {
						if (self.previousPositionsModel.length > 0) {
							let foundIndex = self.previousPositionsModel.findIndex((x) => x.company === company);
							if (foundIndex > -1) {
								self.previousPositionsModel.splice(foundIndex, 1);
							}
						}
					})
					.catch(() => {
						this.msgError('删除发生异常!');
					});
			}
		},
	},
};
</script>

<style>
</style>