<template>
	<div>
		<el-form :model="queryParameters" ref="queryParameters" label-width="80px" >
			<el-row :gutter="24">
				<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" >
					<el-form-item label="公司名称" >
						<el-select v-model="companySelect" style="width:100%;"  clearable @clear="handleClearable" placeholder="请选择" collapse-tags ref="companySelected">
							<el-option :value="companyId" :label="companySelect" style="height: auto">
								<el-tree
									:data="companyOptions"
									:props="defaultProps"
									:expand-on-click-node="false"
									ref="tree"
									show-checkbox
									node-key="id"
									default-expand-all
									@node-click="handleNodeClick"
									@check="handleNodeClick"
									highlight-current
								/>
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<!-- <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" >
					<el-form-item label="创建日期" >
						 <el-date-picker
							:xs="10" :sm="20" :md="20" :lg="20" :xl="20"
							v-model="dateRange"
							type="daterange"
							align="right"
							unlink-panels
							range-separator="-"
							start-placeholder="开始日期"
							end-placeholder="结束日期"
							:picker-options="pickerOptions"
							value-format="yyyy-MM-dd" 
						>
						</el-date-picker> 
					</el-form-item>
				</el-col> -->
				<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
					<el-form-item label="起始时间">
						<el-date-picker v-model="queryParameters.startDate" style="width:100%;"  clearable value-format="yyyy-MM-dd 00:00:00" align="left" type="date" placeholder="选择起始创建日期">
						</el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
					<el-form-item label="终止时间">
						<el-date-picker v-model="queryParameters.endDate" style="width:100%;"  clearable value-format="yyyy-MM-dd 24:00:00" align="left" type="date" placeholder="选择终止创建日期">
						</el-date-picker>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row :gutter="10">
				<el-col class="mb8" :span="1.5">
					<el-button  icon="el-icon-search" size="mini" plain type="primary" :loading='unreleasedLoading' @click="queryUnreleased">未发布</el-button>
				</el-col>
				<el-col :span="1.5">
					<el-button  icon="el-icon-search" size="mini" plain type="primary" :loading='releasedLoading'  @click="queryReleased">已发布</el-button>
				</el-col>
				<el-col :span="1.5">
					<el-button  icon="el-icon-search" size="mini" plain type="primary" :loading='loading'  @click="queryAll">全部</el-button>	
				</el-col>
			</el-row>
		</el-form>
		<el-row :gutter="10" class="mb8 buttonStripe">
			<el-col :span="1.5">
				<el-button type="primary" plain icon="el-icon-plus" size="mini" @click="addRecruitPosition" v-permission="['/positionManagement/new']">新增 </el-button>
			</el-col>
			<el-col :span="1.5">
				<el-button type="success" plain icon="el-icon-edit" size="mini" :disabled="!applied"  @click="updateRecruitPosition" v-permission="['/positionManagement/edit']">修改 </el-button>
			</el-col>
			<el-col :span="1.5">
				<el-button type="success" plain icon="el-icon-s-check" size="mini" :disabled="!applied" @click="showApprovePosition" v-permission="['/positionManagement/verify']">审批 </el-button>
			</el-col>
			<el-col :span="1.5">
				<el-button type="success" plain icon="el-icon-finished" size="mini" :disabled="!approved" :loading="releaseLoading" @click="releasePosition" v-permission="['/positionManagement/release']">发布 </el-button>
			</el-col>
			<el-col :span="1.5">
				<el-button type="success" plain icon="el-icon-document-copy" size="mini" :disabled="!single" :loading='addLoading' @click="copyPosition" v-permission="['/positionManagement/new']">复制职位 </el-button>
			</el-col>
			<el-col :span="1.5">
				<el-button type="success" plain icon="el-icon-document-copy" size="mini" :disabled="!single" :loading='closeLoading' @click="closePosition" v-permission="['/positionManagement/close']">关闭 </el-button>
			</el-col>
			<el-col :span="1.5">
				<el-button type="danger" plain icon="el-icon-delete" size="mini" :disabled="!multiple" @click="deletePosition " v-permission="['/positionManagement/delete']">删除 </el-button>
			</el-col>

		</el-row>
		<el-table v-loading="loading" :data="positions" style="width: 100%" @selection-change="handleSelectionChange" stripe border>
			<el-table-column type="selection" width="55" align="center" />
			<el-table-column label="公司名称" align="center" prop="companyName" sortable width="150px" :show-overflow-tooltip="true"> </el-table-column>
			<el-table-column label="招聘职位名称" align="center" prop="positionName" sortable width="150px" :show-overflow-tooltip="true" />
			<el-table-column label="工作地点" align="center" prop="workPlace" sortable width="180px" :show-overflow-tooltip="true" />
			<el-table-column label="创建日期" align="center" prop="createTime" sortable width="100px"  :show-overflow-tooltip="true"> 
				<template slot-scope="scope">
                     <span>{{ formatDate(scope.row.createTime) }}</span>
                </template>
			</el-table-column>
			<el-table-column label="发布日期" align="center" prop="releasedDate" sortable width="100px" :show-overflow-tooltip="true"> 
				<template slot-scope="scope">
                     <span>{{ formatDate(scope.row.releasedDate) }}</span>
                </template>
			</el-table-column>
			<el-table-column label="结束日期" align="center" prop="closedDate" width="100px" sortable>
				<template slot-scope="scope">
                     <span>{{ formatDate(scope.row.closedDate) }}</span>
                </template>
			 </el-table-column>
			<el-table-column label="人数" align="center" prop="headCount" min-width="60" />
			<el-table-column label="职位类型" align="center" prop="jobType" />
			<el-table-column label="招聘类型" align="center" prop="jobClass" />
			<el-table-column label="状态" align="center" prop="status" />
		</el-table>
		<pagination v-show="total > 0" :total="total" :page.sync="queryParameters.pageNum" :limit.sync="queryParameters.pageSize" @pagination="paginationQuery()" />
		
		 <el-dialog :title="title" :visible.sync='showRecruitPositionEdit' :width="uniapp?'95%':'60%'" append-to-body >
        	<position-edit v-if="showRecruitPositionEdit" :companyOptions="companyOptions"
			 :currentRecruitPosition="selectRecruitPosition" :isNewRecruitPosition="newRecruitPosition" @close="closePositionEdit" >
			 </position-edit>
    	</el-dialog>

		<el-dialog title="职位审批" :visible.sync='showPositionApproved' :width="uniapp?'95%':'50%'" append-to-body >
			<el-form>
				<el-form-item label="审批意见" >
				<el-input v-model="approvedReason" type="textarea"  :rows="3" placeholder="请输入审批意见"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="showPositionApproved = false">取 消</el-button>
				<el-button type="primary" :loading="approvedLoading" @click="approvePosition">同意</el-button>
				<el-button type="danger" :loading="disapprovedLoading" @click="disapprovePosition">不同意</el-button>
			</span>
    	</el-dialog>
	</div>
</template>

<script>
import Pagination from '@/components/Pagination';
import permission from '@/directive/permission/index.js'; // 权限判断指令
import { getTreeselectCompany } from '@/api/org/company';
import {findAllPositons,deleteRecruitPosition,addRecruitPosition,closeRecruitPosition,releaseRecruitPosition,approveRecruitPosition} from "@/api/recruit/positionManagement"
import positionEdit from "./positionEdit.vue"
import { Local } from '@/utils/storage.js';
export default {
	name: 'positionManagement',
	directives: { permission },
	components: {
		Pagination,
		positionEdit,
	},
	data() {
		return {
			queryParameters: {
				startDate: '',
				endDate: '',
				positionType: '',
				companyId: '',
				pageSize: 10,
				pageNum: 1,
			},
			where: {
				query: '',
				filters: [],
			},
			ids: [],
			single: false,
			applied:false,
			approved:false,
			multiple: false,
			addLoading: false,
			releaseLoading:false,
			closeLoading:false,
			positions: [],
			companySelect: '',
			companyId: '',
			isCompnayVisible: false,
			showPositionApproved:false,
			approvedReason:'',
			// 公司树选项
			companyOptions: [],
			companyList: [],
			defaultProps: {
				children: 'children',
				label: 'companyName',
			},
            loading:false,
			newRecruitPosition:false,
			showRecruitPositionEdit:false,
			selectRecruitPosition:{},
			total: 0,
			queryType:"all",
			title:"",
            dateRange:'',
			uniapp:false,
			releasedLoading:false,
			unreleasedLoading:false,
			approvedLoading:false,
			disapprovedLoading:false,
			pickerOptions: {
				shortcuts: [
					{
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						},
					},
					{
						text: '最近一个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						},
					},
					{
						text: '最近三个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							picker.$emit('pick', [start, end]);
						},
					},
				],
			},
		};
	},

	mounted() {
		this.queryAll();
	},
	created() {
		getTreeselectCompany(null, (list, options) => {
			this.companyList = list;
			if (this.companyList.length > 1) {
				this.isCompnayVisible = true;
			}
			this.companyOptions = options;
		});

		this.uniapp =Local.get("isUniapp")?true:false;
	},
	methods: {
		 formatDate(currentDate){
            if(currentDate==null || currentDate=='')
            {
                return "";
            }
            else{
                return currentDate.substr(0,10);
            }
            
         },
		 handleSelectionChange(selection) {
				this.ids = selection.map(item => item.id)
				this.single = selection.length == 1
				this.multiple = selection.length>=1;
				let findPositions= this.positions.filter(item=>item.id==this.ids[0]);
				this.selectRecruitPosition = findPositions[0];
				if(this.single){
					if(selection[0].status=='新建'){
						this.applied=true;
					}
					else{
						this.applied = false;
					}

					if(selection[0].status=='审批同意'){
						this.approved=true;
					}
					else{
						this.approved = false;
					}
				}
				else
				{
					this.applied = false;
					this.approved = false;
				}
				// this.enableCheckInButton = this.single && !this.disableButton(this.selectTrainingPlan);
				// this.enableReleaseButton = this.single && !this.disableButton(this.selectTrainingPlan)&&findTrainingPlans[0].status=='未发布';
			},
        // 节点单击事件
		handleNodeClick(data) {

			this.queryParameters.companyId = data.id;
			this.companyId = data.id;
			this.companySelect = data.companyName;
			let selectArray = [];
			selectArray.push(this.companyId);
			this.$refs.tree.setCheckedKeys(selectArray);
			this.$refs.companySelected.blur();
		},
		
		handleClearable()
		{
				this.$refs.tree.setCheckedKeys([]);
				this.companyId=null;
				this.companySelect='';
		},
        queryReleased(){
			this.releasedLoading = true;
            this.query('Released');
			this.queryType='Released';
        },
        queryUnreleased(){
			this.unreleasedLoading = true;
			this.query('Unreleased');
			this.queryType='Unreleased';
        },
        queryAll(){
			this.loading = true;
			this.query('All');
			this.queryType='All';
        },
		paginationQuery(){
			this.query(this.queryType);
		},
        query(positionType) {
			let filter = {};
			if (this.companyId) {
				filter['companyId'] = {
					type: 'eq',
					value: this.companyId,
				};
			}
			
			if (positionType == "Released") {
				filter['status'] = {
					type: 'search',
					value: "发布",
				};
			}

			if (positionType == "Unreleased") {
				filter['status'] = {
					type: 'in',
					value: ["新建","审批同意","审批不同意","审批中"],
				};
			}

			// if (this.dateRange ) {
			// 	let tempDate = this.dateRange[0].replace(/-/g, '/'); //必须把日期'-'转为'/'
			// 	var startDateTimeStamp = new Date(tempDate).getTime();
			// 	tempDate = this.dateRange[1].replace(/-/g, '/');
			// 	var endDateTimeStamp = new Date(tempDate).getTime();
			// 	filter['createTime'] = {
			// 		type: 'timestamp',
			// 		value: [startDateTimeStamp, endDateTimeStamp],
			// 	};
			// }

			if (this.queryParameters.startDate && this.queryParameters.endDate) {
				let tempDate = this.queryParameters.startDate.replace(/-/g, '/'); //必须把日期'-'转为'/'
				var startDateTimeStamp = new Date(tempDate).getTime();
				tempDate = this.queryParameters.endDate.replace(/-/g, '/');
				var endDateTimeStamp = new Date(tempDate).getTime();
				filter['createTime'] = {
					type: 'timestamp',
					value: [startDateTimeStamp, endDateTimeStamp],
				};
			}
			else if (this.queryParameters.startDate )
			{
				filter['createTime'] = {
					type: 'ge',
					value: this.queryParameters.startDate,
				};
			}
			else if(this.queryParameters.endDate){
				filter['createTime'] = {
					type: 'le',
					value: this.queryParameters.endDate,
				};
			}




			const filters = this.parseFilters(filter);
			if (filters && filters.length > 0) {
				this.where.filters = filters;
			} else {
				// 如果过滤条件为空，设置空数组
				this.where.filters = [];
			}
			const params = {
				pageNum: this.queryParameters.pageNum,
				pageSize: this.queryParameters.pageSize,
				where: this.where,
				order: '',
			};
			
			findAllPositons(params).then((response) => {
				this.positions = response.data.records;
				this.total = response.data.total;
				if(positionType == "Released")
				{
					this.releasedLoading=false;
				}
				else if(positionType == "Unreleased"){
					this.unreleasedLoading=false;
				}
				else{
					this.loading = false;
				}
			});

		},
		addRecruitPosition(){
			this.showRecruitPositionEdit=true;
			this.newRecruitPosition = true;
			this.selectRecruitPosition = null;
			this.title="增加招聘计划";

		},
		updateRecruitPosition(){
			this.showRecruitPositionEdit=true;
			this.newRecruitPosition = false;
			this.selectRecruitPosition = this.positions.filter(item=>item.id==this.ids[0])[0];;
			this.title="修改招聘计划";
		},
		closePositionEdit(params){
			this.showRecruitPositionEdit=false;
			if(params.refresh){
				this.query(this.queryType);
			}
		},

		releasePosition(){
			if(this.selectRecruitPosition != null)
			{
				this.releaseLoading=true;
				let that = this;
				releaseRecruitPosition(this.selectRecruitPosition.id)
				.then((response) => {
					that.releaseLoading = false;
					that.query(that.queryType);
					that.msgSuccess('发布成功。');
				})
				.catch((err) => {
					that.releaseLoading = false;
					that.msgError('发布失败，' + err);
				});
			}
			else
			{
				this.msgSuccess("没有选择职位，先选择一个职位再发布。");
			}
		},
		closePosition(){
			if(this.selectRecruitPosition != null)
			{
				this.closeLoading=true;
				let that = this;
				closeRecruitPosition(this.selectRecruitPosition.id)
				.then((response) => {
					that.closeLoading = false;
					that.query(that.queryType);
					that.msgSuccess('关闭成功。');
				})
				.catch((err) => {
					that.closeLoading = false;
					that.msgError('关闭失败，' + err);
				});
			}
			else
			{
				this.msgSuccess("没有选择职位，先选择一个职位再发布。");
			}
		},
		copyPosition(){
			if(this.selectRecruitPosition != null)
			{
				this.selectRecruitPosition.status="新建";
				this.selectRecruitPosition.id=null;
				this.selectRecruitPosition.createTime='';
				this.selectRecruitPosition.createBy='';
				this.selectRecruitPosition.udateTime='';
				this.selectRecruitPosition.updateBy='';
				this.selectRecruitPosition.releasedDate='';
				this.selectRecruitPosition.closedDate='';
				this.addLoading=true;
				let that = this;
				addRecruitPosition(this.selectRecruitPosition)
				.then((response) => {
					that.addLoading = false;
					that.query(that.queryType);
					that.msgSuccess('复制招聘申请成功。');
				})
				.catch((err) => {
					that.addLoading = false;
					that.msgError('复制招聘申请失败，' + err);
				});
			}
			else
			{
				this.msgSuccess("没有选择职位，先选择一个职位再复制。");
			}
		},

		showApprovePosition(){
			this.showPositionApproved = true;
			this.approvedReason='';
		},

		approvePosition(){
			if(this.selectRecruitPosition != null)
			{
				this.approvedLoading=true;
				let that = this;
				approveRecruitPosition(this.selectRecruitPosition.id,'审批同意',this.approvedReason)
				.then((response) => {
					that.approvedLoading = false;
					that.query(that.queryType);
					that.msgSuccess('审批成功。');
				})
				.catch((err) => {
					that.approvedLoading = false;
					that.msgError('审批失败，' + err);
				});
			}
			else
			{
				this.msgSuccess("没有选择职位，先选择一个职位再审批。");
			}
		},
		disapprovePosition(){
			if(this.selectRecruitPosition != null)
			{
				let that = this;
				if(this.approvedReason==""){
					this.msgError('请输入不同意理由。');
					return;
				}
				this.disapprovedLoading=true;
				approveRecruitPosition(this.selectRecruitPosition.id,'审批不同意',this.approvedReason)
				.then((response) => {
					that.disapprovedLoading = false;
					that.query(that.queryType);
					that.msgSuccess('审批成功。');
				})
				.catch((err) => {
					that.disapprovedLoading = false;
					that.msgError('审批失败，' + err);
				});
			}
			else
			{
				this.msgSuccess("没有选择职位，先选择一个职位再审批。");
			}
		},

		deletePosition(){
			let that = this;
			this.$confirm('确定删除选中的招聘计划?', "警告", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			}).then(function () {
				return deleteRecruitPosition(that.ids);
			}).then(() => {
				that.query();
				that.msgSuccess("删除成功");
			})
		},
		

    },
};
</script>

<style  scoped>


</style>