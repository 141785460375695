import request from '@/utils/request'

// 查询班次管理列表
export function listKpi(query) {
    return request({
        url: '/kpi/kpi/findAll',
        method: 'post',
        data: query
    })
}

// 查询班次管理详细
export function getKpi(id) {
    return request({
        url: '/kpi/kpi/findOne?id=' + id,
        method: 'post'
    })
}

// 新增班次管理
export function addKpi(data) {
    return request({
        url: '/kpi/kpi/add',
        method: 'post',
        data: data
    })
}

// 修改班次管理
export function updateKpi(data) {
    return request({
        url: '/kpi/kpi/edit',
        method: 'post',
        data: data
    })
}

// 删除班次管理
export function delKpi(id) {
    return request({
        url: '/kpi/kpi/remove',
        method: 'post',
        data: id
    })
}

export function genEmpKpi(id) {
    return request({
        url: '/kpi/kpi/genEmpKpi',
        method: 'post',
        data: id
    })
}
// 导出班次管理
export function exportKpi(query) {
    return request({
        url: '/kpi/kpi/export',
        method: 'post',
        data: query
    })
}
