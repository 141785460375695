<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px">
      <el-form-item label="标题" prop="title">
        <el-input v-model="queryParams.title" placeholder="请输入标题类容" clearable  @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="消息类型" prop="msgType">
        <el-select v-model="queryParams.msgType" placeholder="请选择消息类型" clearable>
          <el-option
              v-for="dict in msgTypeOptions"
              :key="dict.dictValue"
              :label="dict.dictLabel"
              :value="dict.dictValue"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="消息级别" prop="msgLevel">
        <el-select v-model="queryParams.msgLevel" placeholder="请选择消息级别" clearable>
          <el-option
              v-for="dict in msgLevelOptions"
              :key="dict.dictValue"
              :label="dict.dictLabel"
              :value="dict.dictValue"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="消息状态" prop="msgLevel">
        <el-select v-model="queryParams.status" placeholder="请选择消息状态" clearable>
          <el-option
              v-for="dict in msgStatusOptions"
              :key="dict.dictValue"
              :label="dict.dictLabel"
              :value="dict.dictValue"
          ></el-option>
        </el-select>
      </el-form-item>
<!--      <el-form-item label="发送人" prop="senderId">-->
<!--        <el-input-->
<!--            v-model="queryParams.senderId"-->
<!--            placeholder="请输入发送人"-->
<!--            clearable-->
<!--            size="small"-->
<!--            @keyup.enter.native="handleQuery"-->
<!--        />-->
<!--      </el-form-item>-->
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
            type="primary"
            plain
            icon="el-icon-plus"
            size="mini"
            @click="handleAdd"
            v-permission="['/msg/msg/add']"
        >新增
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
            type="success"
            plain
            icon="el-icon-edit"
            size="mini"
            :disabled="single"
            @click="handleUpdate"
            v-permission="['/msg/msg/edit']"
        >修改
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
            type="danger"
            plain
            icon="el-icon-delete"
            size="mini"
            :disabled="multiple"
            @click="handleDelete"
            v-permission="['/msg/msg/remove']"
        >删除
        </el-button>
      </el-col>
<!--      <el-col :span="1.5">-->
<!--        <el-button-->
<!--            type="warning"-->
<!--            plain-->
<!--            icon="el-icon-download"-->
<!--            size="mini"-->
<!--            @click="handleExport"-->
<!--            v-permission="['/msg/msg/export']"-->
<!--        >导出-->
<!--        </el-button>-->
<!--      </el-col>-->
      <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>
    </el-row>

    <el-table v-loading="loading" :data="msgList" @selection-change="handleSelectionChange" style="width:100%" stripe border>
      <el-table-column type="selection" width="55" align="center"/>
<!--      <el-table-column label="自增id" align="center" prop="id"/>-->
<!--      <el-table-column label="所属公司" align="center" prop="companyId"/>-->
      <el-table-column label="消息类型" align="center" prop="msgType" :formatter="msgTypeFormat"/>
      <el-table-column label="消息级别" align="center" prop="msgLevel" :formatter="msgLevelFormat"/>
      <el-table-column label="消息标题" align="center" prop="title" width="auto" min-width="150" :show-overflow-tooltip='true'>
      </el-table-column>
<!--      <el-table-column label="消息内容" align="center" prop="content" :show-overflow-tooltip="true"/>-->
      <el-table-column label="状态" align="center" prop="status" :formatter="statusFormat"/>
      <el-table-column label="发送人" align="center" prop="senderName"/>
<!--      <el-table-column label="撤回" align="center" prop="recall">-->
<!--        <template slot-scope="scope">-->
<!--          <span v-if="scope.row.recall==1">已撤回</span>-->
<!--          <span v-if="scope.row.recall==0">未撤回</span>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column label="接收人" align="center" prop="receivers" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <el-tag
              v-for="tag in scope.row.receivers"
              :key="tag.name"
              size="mini">
            {{tag.name}},
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="短信通知" align="center" prop="sms">
        <template slot-scope="scope">
          <span v-if="scope.row.sms==true">是</span>
          <span v-if="scope.row.sms==false">否</span>
        </template>
      </el-table-column>
      <el-table-column label="短信发送结果" width="128" align="center" prop="smsResult" :show-overflow-tooltip="true">
        <template slot-scope="scope">
          <span v-if="scope.row.smsResult=='OK'">发送成功</span>
          <span v-else>{{scope.row.smsResult}}</span>
        </template>
      </el-table-column>
      <el-table-column label="发布时间" align="center" prop="startTime" width="180">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.startTime, '{y}-{m}-{d} {h}:{i}') }}</span>
        </template>
      </el-table-column>
<!--      <el-table-column label="生效开始时间" align="center" prop="startTime" width="180">-->
<!--        <template slot-scope="scope">-->
<!--          <span>{{ parseTime(scope.row.startTime, '{y}-{m}-{d} {h}:{i}') }}</span>-->
<!--        </template>-->
<!--      </el-table-column>-->
<!--      <el-table-column label="生效结束时间" align="center" prop="endTime" width="180">-->
<!--        <template slot-scope="scope">-->
<!--          <span>{{ parseTime(scope.row.endTime, '{y}-{m}-{d} {h}:{i}') }}</span>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width" width="180px">
        <template slot-scope="scope">
          <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="handleView(scope.row)"
          >查看
          </el-button>
          <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="handleUpdate(scope.row)"
              v-permission="['/msg/msg/edit']"
          >修改
          </el-button>
          <el-button
              size="mini"
              type="text"
              icon="el-icon-delete"
              @click="handleDelete(scope.row)"
              v-permission="['/msg/msg/remove']"
          >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
        v-show="total>0"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
    />

    <!-- 添加或修改消息管理对话框 -->
    <el-dialog v-el-drag-dialog :title="title" :visible.sync="open" append-to-body>
      <MsgEdit :row="row" :msg-level-options-in="msgLevelOptions" :msg-type-options-in="msgTypeOptions"
            :is-read-only="readOnly" @close="close">
      </MsgEdit>
    </el-dialog>
    <el-dialog :title="previewTitle" :visible.sync="previewOpen" width="90%" append-to-body>
      <MsgPreview :msg="row" @close="previewClose"></MsgPreview>
    </el-dialog>
  </div>
</template>

<script>
import {
  listMsg,
  getMsg,
  delMsg,
  addMsg,
  updateMsg,
  exportMsg } from "@/api/msg/msg";
import RightToolbar from "@/components/RightToolbar"
import permission from '@/directive/permission/index.js' // 权限判断指令
import Pagination from "@/components/Pagination";
import MsgEdit from "./msgEdit"
import elDragDialog from '@/directive/el-drag-dialog' // base on element-ui
import MsgPreview from "./msgPreview";

// 表查询配置
const dbOrderBy = ''

export default {
  name: "Msg",
  components: {
    MsgEdit,
    RightToolbar,
    Pagination,
    MsgPreview,
  },
  directives: { permission, elDragDialog },
  data() {
    return {
      readOnly: false,
      // 遮罩层
      loading: true,
      // 查询参数
      where: {
        query: '',
        filters: []
      },

      row: null,

      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 消息管理表格数据
      msgList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      previewTitle: "",
      previewOpen: false,
      previewMsg: null,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        orderby: dbOrderBy,
        msgType: null,
        msgLevel: null,
        title: null,
        senderId: null,
        status: null,
      },
      msgTypeOptions: null,
      msgLevelOptions: null,
      msgStatusOptions: null,
    };
  },
  created() {
    this._filter = {};
    this.companyId = this.$store.getters.userInfo.companyId;
    let rtType = this.getDicts("msg_type").then(response => {
      this.msgTypeOptions = response.data;
      console.log(response);
      return response;
    });
    let rtLevel = this.getDicts("msg_level").then(response => {
      this.msgLevelOptions = response.data;
      return response;
    });
    let rtStatus = this.getDicts("msg_status").then(response => {
      this.msgStatusOptions = response.data;
      return response;
    });
    Promise.all([rtType,rtLevel,rtStatus]).then(()=> {
      this.getList();
    });
  },
  methods: {
    handleView(row){
      this.row = row;
      // this.previewTitle = '通告';
      this.previewOpen = true;
    },
    previewClose(){
      this.previewOpen = false;
    },
    /** 查询消息管理列表 */
    getQuery(){
      this._filter = {};
      if(this.queryParams.msgType) {
        this._filter['msgType'] = {
          type: 'search',
          value: this.queryParams.msgType,
        }
      }
      if(this.queryParams.msgLevel) {
        this._filter['msgLevel'] = {
          type: 'search',
          value: this.queryParams.msgLevel,
        }
      }
      if(this.queryParams.status) {
        this._filter['status'] = {
          type: 'search',
          value: this.queryParams.status,
        }
      }
      if(this.queryParams.title) {
        this._filter['title'] = {
          type: 'search',
          value: this.queryParams.title,
        }
      }
      if(this.queryParams.senderId) {
        this._filter['senderId'] = {
          type: 'search',
          value: this.queryParams.senderId,
        }
      }
      const filters = this.parseFilters(this._filter)
      if (filters && filters.length > 0) {
        this.where.filters = filters
      } else {
        // 如果过滤条件为空，设置空数组
        this.where.filters = []
      }
      const params = {
        pageNum: this.queryParams.pageNum,
        pageSize: this.queryParams.pageSize,
        where: this.where,
        order: 'id desc'
      }

      return params;
    },
    getList() {
      this.loading = true;
      const params = this.getQuery();
      listMsg(params).then(response => {
        this.msgList = response.data.records;
        this.total = response.data.total;
        this.loading = false;
      });
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.row = null;
      this.$router.push({name:"msg_msg_edit_vue",
        params: {row: this.row,msgLevelOptions:this.msgLevelOptions,msgTypeOptions:this.msgTypeOptions}});
      // this.open = true;
      // this.title = "添加消息";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      if(row.id){
        this.row = row;
      }else{
        this.row = {};
        this.row.id = this.ids;
      }
      this.$router.push({name:"msg_msg_edit_vue",
        params: {row: this.row,msgLevelOptions:this.msgLevelOptions,msgTypeOptions:this.msgTypeOptions}});
      // this.title = "修改消息";
    },
    // 提交确认后关闭对话框
    close(arg){
      this.open = false;
      if(arg.refresh){
        this.getList();
      }
    },

    /** 删除按钮操作 */
    handleDelete(row) {
      let ids = [];
      if(row.id){
        ids = [row.id];
      }else{
        ids = this.ids;
      }

      this.$confirm('是否确认删除选中的消息管理数据?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return delMsg(ids);
      }).then(() => {
        this.getList();
        this.msgSuccess("删除成功");
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      const params = this.getQuery();
      this.$confirm('是否确认导出所有消息管理数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return exportMsg(params);
      }).then(response => {
        this.download(response.msg);
      })
    },

    handleDrag() {
      this.$refs.select.blur()
    },

    msgLevelFormat(row, column) {
      return this.selectDictLabel(this.msgLevelOptions, row.msgLevel);
    },
    msgTypeFormat(row, column) {
      return this.selectDictLabel(this.msgTypeOptions, row.msgType);
    },
    statusFormat(row, column) {
      return this.selectDictLabel(this.msgStatusOptions, row.status);
    },

  }
};
</script>
