<template>
  <div>
    <TransferEdit type="apply"></TransferEdit>
  </div>
</template>

<script>
import TransferEdit from './transferEdit';

export default {
  name: "apply",
  components:{
    TransferEdit,
  }

}
</script>

<style scoped>

</style>
