import { render, staticRenderFns } from "./transferHisTrace.vue?vue&type=template&id=1e6e2bda&scoped=true"
import script from "./transferHisTrace.vue?vue&type=script&lang=js"
export * from "./transferHisTrace.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1e6e2bda",
  null
  
)

export default component.exports