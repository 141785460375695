<template>
  <div>
    <el-form ref="form" :model="form" :rules="rules" class="employ" label-width="150px">
      <el-collapse v-model="activeNames" >
        <el-collapse-item title=" 基 本 信 息" name="accountInfo" :class="accountorSetting()" >
          <el-row>
            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
              <el-form-item label="姓名" prop="name">
                <el-input :disabled="disabledControl" v-model="form.name" placeholder="请输入姓名" />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
              <el-form-item label="证件类型" prop="idType">
                <el-select v-model="form.idType" :disabled="disabledControl" placeholder="请选择证件类型">
                  <el-option v-for="item in iDTypes" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
              <el-form-item label="身份证号码" prop="idNum">
                <el-input :disabled="disabledControl" v-model="form.idNum" placeholder="请输入身份证号码" />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
              <el-form-item label="身份证地址" prop="birthAddr">
                <el-input :disabled="disabledControl" v-model="form.birthAddr" placeholder="请输入出生地" />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
              <el-form-item label="户口所在地" prop="householdAddr">
                <el-input :disabled="disabledControl" v-model="form.householdAddr" placeholder="请输入户口所在地" />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
              <el-form-item label="参加工作时间" prop="workStartDate">
                <el-date-picker v-model="form.workStartDate" :disabled="disabledControl" type="date" value-format="yyyy-MM-dd" placeholder="选择参加工作时间">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
              <el-form-item label="集体户口" prop="householdType">
                <el-checkbox v-model="form.householdType" :disabled="disabledControl"></el-checkbox>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
<!--              <el-divider></el-divider>-->
            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
              <el-form-item label="公司" prop="companyId">
                <el-input :disabled="disabledControl" :value="companyFormat(form, null)" />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
              <el-form-item label="岗位" prop="positionId">
                <el-select :disabled="disabledControl" clearable v-model="form.positionId" placeholder="请选择岗位" style="width:100%">
                  <el-option v-for="dict in positionList" :key="dict.id" :label="dict.name" :value="dict.id" ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
              <el-form-item label="部门" prop="departmentId">
                <treeselect :disabled="disabledControl" v-model="form.departmentId" :options="departmentOptions"
                    :normalizer="normalizerDepartment" placeholder="请选择所属部门" />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
              <el-form-item label="员工编号" prop="workNum">
                <el-input :disabled="disabledControl" v-model="form.workNum" />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
              <el-form-item label="现岗位首聘" prop="curPositionStartDate">
                <el-date-picker :disabled="disabledControl" v-model="form.curPositionStartDate"
                    type="date" value-format="yyyy-MM-dd" placeholder="选择现任岗位的首聘时间" >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
              <el-form-item label="入本单位时间" prop="joinCompanyDate">
                <el-date-picker :disabled="disabledControl" v-model="form.joinCompanyDate" type="date"
                    value-format="yyyy-MM-dd" placeholder="选择入本单位时间" >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
              <el-form-item label="休假工龄起始" prop="paidHolidayStartDate">
                <el-date-picker :disabled="disabledControl" v-model="form.paidHolidayStartDate" type="date"
                    value-format="yyyy-MM-dd" placeholder="选择休假工龄的起始时间" >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
              <el-form-item label="员工状况" prop="empStatus">
                <el-select v-model="form.empStatus" placeholder="请选择" :disabled="disabledControl">
                  <el-option v-for="item in employeeStatuses" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
              <el-form-item label="员工类型" prop="empType">
                <el-select v-model="form.empType" placeholder="请选择" :disabled="disabledControl">
                  <el-option v-for="item in employeeTypes" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
              <el-form-item label="用工形式" prop="empWorkType">
                <el-select v-model="form.empWorkType" :disabled="disabledControl" placeholder="请选择用工形式">
                  <el-option v-for="item in employmentTypes" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
              <el-form-item label="转正时间" prop="officalDate">
                <el-date-picker :disabled="disabledControl" v-model="form.officalDate" type="date"
                    value-format="yyyy-MM-dd" placeholder="选择转正时间" >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </el-collapse-item>
        <el-collapse-item title=" 薪 酬 设 置" name="salaryInfo" :class="accountorSetting()" >
          <el-row>
            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
              <el-form-item label="银行名称" prop="bankName" :rules="[{ required: true, message: '银行名称不能为空', trigger: 'blur' }]">
                <el-input :disabled="salaryReadonly" v-model="form.bankName" placeholder="请输入银行名称" />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
              <el-form-item label="银行账号" prop="bankAcct" :rules="[{ required: true, message: '银行账号不能为空', trigger: 'blur' }]">
                <el-input :disabled="salaryReadonly" v-model="form.bankAcct" placeholder="请输入银行账号" />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
              <el-form-item label="社保账号" prop="insuranceAcct">
                <el-input :disabled="salaryReadonly" v-model="form.insuranceAcct" placeholder="请输入社保账号" />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
              <el-form-item label="公积金号" prop="fundAcct">
                <el-input :disabled="salaryReadonly" v-model="form.fundAcct" placeholder="请输入公积金号" />
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
              <el-form-item label="总工资" prop="contractSalary" :rules="[{ required: true, message: '总工资不能为空', trigger: 'blur' }]">
                <el-input :disabled="salaryReadonly" v-model.number="form.contractSalary" placeholder="请输入总工资" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
              <el-form-item label="" prop="salarySelfDef">
                <el-switch v-model="form.salarySelfDef" active-text="自定义工资" inactive-text="工资账套"> </el-switch>
              </el-form-item>
            </el-col>
            <el-col v-if="!form.salarySelfDef" :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
              <el-form-item label="工资账套" prop="salaryTempId" :rules="[{ required: true, message: '必须选择工资账套', trigger: 'blur' }]">
                <el-select v-model.number="form.salaryTempId" clearable :disabled="salaryReadonly" @change="salaryTempChange" placeholder="请选择工资账套">
                  <el-option v-for="item in salaryTempList" :key="item.id" :label="item.name" :value="item.id" />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <SalaryEdit :salaryInfoIn="salaryInfo" :editable="form.salarySelfDef" :salaryTempId="form.salaryTempId" :contractSalary="form.contractSalary"
            :companyIdIn="form.companyId"></SalaryEdit>
          <div style="margin-bottom: 10px; text-align: center">
            <el-button @click="saveSalaryInfo" plain :disabled="salaryReadonly" :loading="saving" type="primary" icon="el-icon">保 存</el-button>
            <el-button @click="close" plain :loading="saving" icon="el-icon">取 消</el-button>
          </div>

        </el-collapse-item>
      </el-collapse>
    </el-form>
  </div>
</template>

<script>
import {
  getEmploy,
  updateEmploy,
  findRecommenderList
} from '@/api/employ/employ';
import {listCbSalaryTemplate} from '@/api/cb/cbSalaryTemplate';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import permission from '@/directive/permission/index.js'; // 权限判断指令
import { getTreeselectCompany } from '@/api/org/company';
import { getTreeselectDepartment } from '@/api/org/department';
import { getTreeselectPosition } from '@/api/org/position';
import { listEntryRole } from "@/api/sys/role";
import { showLoading, hideLoading } from '@/utils/reqloading';
import { uploadCertifcatePictureApi} from "@/api/employ/employ";
import {DICT_TYPE,getDictLabelFromData, getDictDataLabel, getDictDatas} from "@/utils/dict";
import {updateCbSalarySetting} from "@/api/cb/cbSalarySetting";
import {listCbTemplate } from "@/api/cb/cbTemplate";
import SalaryEdit from "@/views/pages/cb/components/salaryEdit";

export default {
  name: "SalarySettingEdit",
  components: {
    Treeselect,
    SalaryEdit,
  },
  props: {
    row: Object,
    salaryTempListIn: Array,
    departmentOptionsIn: Array,
    positionOptionsIn: Array,
    isReadOnly: Boolean,
    companyId: Number,
    departmentId: Number,
  },
  directives: { permission },
  watch: {
    "form.salaryTempId": function (n,o){
      if(this.form.salaryTempId > 0){
        this.salaryInfo = this.getSalaryInfo(this.form.salaryTempId);
        // this.$set(this.salaryInfo,"itemInfo",salaryItemInfo);
        // this.salaryInfo.itemInfo
        // 更新考勤信息
        // this.salaryInfo.attItems = this.getAttItemInfo(this.form.salaryTempId);
      }
    },
    "form.contractSalary": function (n,o){
      console.log(this.form.contractSalary)
      if(this.form.contractSalary > 0 && this.salaryInfo && this.salaryInfo.itemInfo){
        this.calculateFloatItem(this.salaryInfo.itemInfo);
      }
    },
    "form.salaryInfo.itemInfo": function (n,o){
      console.log(this.form.contractSalary)
      if(this.form.contractSalary > 0 && this.salaryInfo && this.salaryInfo.itemInfo){
        this.calculateFloatItem(this.salaryInfo.itemInfo);
      }
    },
    "form.salarySelfDef": function (n,o){
      console.log(this.form.salarySelfDef);
      if(this.form.salarySelfDef){
        this.$set(this.form, "salaryTempId", null);
      }else{

      }
    },

    row(n, o) {
      //n为新值,o为旧值;
      //if(n === o) return;
      console.log('row change');
      console.log(n);
      console.log(o);
      console.log('change n - o');
      this.load();
    },
    companyId() {
      if (this.departmentOptionsIn) {
        this.departmentOptions = this.departmentOptionsIn;
      } else {
        this.departmentOptions = [];
        this.getDepartment(this.companyId);
      }
      if (this.positionOptionsIn) {
        this.positionOptions = this.positionOptionsIn;
      } else {
        this.positionOptions = [];
        this.getPositison(this.companyId);
      }
      this.getSalaryTempList(this.companyId);
    },
    departmentId() {
      // this.load();
    },
  },

  data() {
    return {
      tabName:"attInfo",
      insuranceList: [],
      fundList: [],
      salaryTempList: [],
      activeNames: ['accountInfo','salaryInfo'],
      companyOptions: [],
      companyList: [],
      departmentOptions: [],
      positionList:[],
      positionOptions: [],
      //
      postLevelOptions: [],
      // 角色选项
      roleOptions: [],
      //推荐人列表
      recommenderList:[],

      // 表单参数
      form: {},
      salaryInfo:{attInfo:{attItems:[]},itemInfo: []},
      attItems: DICT_TYPE.ATT_ITEMS,
      saving: false,
      formReadOnly: false,
      reloading: false,
      disabledControl:true,
      salaryReadonly: false,
      employeeStatuses: DICT_TYPE.employeeStatuses,

      employeeTypes: [
        { value: '管理人员', label: '管理人员' },
        { value: '文职', label: '文职' },
        { value: '员工', label: '员工' },
        { value: '临聘工', label: '临聘工' },
        { value: '其它', label: '其它' },
      ],
      employmentTypes: [
        { value: '聘用制', label: '聘用制' },
        { value: '临时工', label: '临时工' },
        { value: '劳务工', label: '劳务工' },
        { value: '小时工', label: '小时工' },
        { value: '定量外包', label: '定量外包' },
        { value: '其它', label: '其它' },
      ],
      iDTypes: [
        { value: '身份证', label: '身份证' },
        { value: '军官证', label: '军官证' },
        { value: '学生证', label: '学生证' },
        { value: '护照', label: '护照' },
        { value: '其它', label: '其它' },
      ],
      collapseStatus: 'open',

      // 表单校验
      rules: {
      },
    };
  },
  created() {
    console.log('create view');
    this.load();
  },
  mounted() {},
  computed:{
    collapseSetting(){
      return function(){
        let filledCompleted = true;
        for (let key of Object.keys(this.form)) {
          let propValue = this.form[key];
          if(propValue == undefined ||  propValue == null || propValue == "" )
          {
            filledCompleted = false;
            break;
          }
        }

        if(filledCompleted)
        {
          return {callpse_header_fill:true};
        }
        else
        {
          return {callpse_header_unfill:true};
        }

      }
    },
    accountorSetting(){
      return function(){
        if(this.form.id)
        {
          return {callpse_header_fill:true};
        }
        else{
          return {callpse_header_unfill:true};
        }
      }
    },
  },
  methods: {
    close(){
      this.$emit("close", {refresh:false,isSuccess:true});
    },
    getSalaryInfo(id){
      console.log("getSalaryInfo id="+id);
      // let salaryItemInfo = [];
      let salaryInfo = null;
      this.salaryTempList.forEach(item=>{
        if(item.id == id){
          // salaryItemInfo = item.itemInfo;
          salaryInfo = item;
          return;
        }
      });
      if(salaryInfo){
        this.calculateFloatItem(salaryInfo.itemInfo);
      }
      // return salaryItemInfo;
      return salaryInfo;
    },

    calculateFloatItem(salaryItemInfo){
      let floatItem = null;
      let val = 0;
      salaryItemInfo.forEach(item=>{
        if(item.itemType == DICT_TYPE.CB_TEMPLATE_SALARY_TYPE_FLOAT){
          floatItem = item;
        }else{
          const value = Number(item.amount);
          if (!isNaN(value)) {
            if (item.plusMinus == '增项') {
              val += value;
            } else {
              val -= value;
            }
          }
        }
      });
      if(floatItem){
        const amount = this.form.contractSalary - val;
        this.$set(floatItem, "amount", amount);
      }
    },
    salaryTempChange(){

    },
    getSummaries(param) {
      const {columns, data} = param;
      const sums = [];
      sums[0] = '总工资';
      let val = 0;
      data.map(item => {
        const value = Number(item.amount);
        if (!isNaN(value)) {
          if (item.plusMinus == '增项') {
            val += value;
          } else {
            val -= value;
          }
        }
      });
      sums[4] = val;
      return sums;
    },
    itemTypeChange(row) {
      if (DICT_TYPE.CB_TEMPLATE_SALARY_TYPE_FLOAT == row.itemType) {
        row.plusMinus = "增项";
        row.valueType = "系统计算值";
      } else if (DICT_TYPE.CB_TEMPLATE_SALARY_TYPE_SALARY == row.itemType || DICT_TYPE.CB_TEMPLATE_SALARY_TYPE_EXTRA == row.itemType) {
        // row.plusMinus = "增项";
        row.valueType = "设置值";
      }
    },
   handleChange(val) {
      console.log(val);
    },
    load() {
      console.log('load ...');
      this.getDicts('org_position_level').then((response) => {
        this.postLevelOptions = response.data;
      });
      getTreeselectCompany(null, (list, options) => {
        this.companyList = list;
        this.companyOptions = options;
      });
      if (this.departmentOptionsIn) {
        this.departmentOptions = this.departmentOptionsIn;
      } else {
        this.departmentOptions = [];
        this.getDepartment(this.companyId);
      }
      if (this.positionOptionsIn) {
        this.positionOptions = this.positionOptionsIn;
      } else {
        this.positionOptions = [];
        this.getPositison(this.companyId);
      }
      if(this.salaryTempListIn){
        this.salaryTempList = this.salaryTempListIn;
      }else{
        this.salaryTempList = [];
        this.getSalaryTempList(this.companyId);
      }
      if (this.row && this.row.id) {
        this.handleUpdate(this.row);
        this.disableLockButton = false;
      } else {
        // this.handleAdd(this.row);
        // this.disableLockButton = true;
      }
      // json 字段没用值必须初识化对象
      if (!this.form.urgentContact) {
        this.form.urgentContact = this.urgentContact;
      }
      if(!this.form.user)
      {
        this.form.user = {};
      }
      this.formReadOnly = this.isReadOnly;
      console.log('set is read only =' + this.formReadOnly);
      console.log(this.form);
    },
    handleAddItem(){
      if(!this.form.itemInfo){
        this.$set(this.form,"itemInfo", []);
      }
      this.form.itemInfo.push({itemName:"",itemType:"",plusMinus:"",valueType:"",refId:null,amount:null});
    },
    handleDeleteItem(row){
      var index = this.form.itemInfo.indexOf(row);
      if (index > -1) {
        this.form.itemInfo.splice(index, 1);
      }
    },

    getCbTemplateList(companyId){
      let filter = {};
      filter['companyId'] = {
        type: 'eq',
        value: companyId,
      };
      const params = this.getQueryParam(filter, 1, 1000, '');
      listCbTemplate(params).then(response => {
        this.cbTemplateList = response.data.records;
        let insuranceList = [];
        let fundList = [];
        this.cbTemplateList.forEach( item=>{
          if("社保" == item.type){
            insuranceList.push(item);
          }else if("住房公积金" == item.type){
            fundList.push(item);
          }
        });
        this.insuranceList = insuranceList;
        this.fundList = fundList;
        // 检查form 是否设置社保公积金,设置这添加item项目
        this.removeRefItem();
        if(this.form.insuranceId){
          let insurance = this.getItemByRefId(this.form.insuranceId);
          this.addRefItem(insurance);
        }
        if(this.form.fundId){
          let fund = this.getItemByRefId(this.form.fundId);
          this.addRefItem(fund);
        }
      });
    },

    companyFormat(row, column) {
      // console.log(row.departmentId);
      for (var i = 0; i < this.companyList.length; i++) {
        const item = this.companyList[i];
        // console.log("id:"+item.id+",depid:"+row.departmentId+", name :"+item.name+",ee="+(item.id == row.departmentId));
        if (item.id == row.companyId) {
          return item.companyName;
        }
      }
      return '';
    },
    /** 转换部门数据结构 */
    normalizerDepartment(node) {
      return this.normalizer(node);
    },
    getSalaryTempList(companyId){
      let filter = {};
      filter['companyId'] = {
        type: 'eq',
        value: companyId,
      };
      const params = this.getQueryParam(filter, 1, 1000, '');
      listCbSalaryTemplate(params).then(response => {
        this.salaryTempList = response.data.records;
        console.log("this.form.salaryTempId")
        console.log(this.form.salaryTempId)
        if(this.form.salaryTempId > 0){
          this.salaryInfo = this.getSalaryInfo(this.form.salaryTempId);
        }

      });
    },
    /** 查询公司下拉树结构 */
    getDepartment(companyId) {
      getTreeselectDepartment(companyId, false, (list, options) => {
        this.departmentList = list;
        this.departmentOptions = options;
      });
    },
    normalizerPosition(node) {
      return this.normalizer(node);
    },
    /** 查询岗位下拉树结构 */
    getPositison(companyId) {
      getTreeselectPosition(companyId, false, (list, options) => {
        this.positionList = list;
        this.positionOptions = options;
      });
    },

    // 取消按钮
    cancel() {
      this.$emit('close', { refresh: false, isSuccess: true });
    },
    // 表单重置
    reset() {
      this.form = {};
      this.resetForm('form');
    },

    chooseDTChange(){
      this.$forceUpdate();
    },
    getRoles(){
      return listEntryRole().then(response => {
        console.log(response.data);
        this.roleOptions = response.data;
        return response;
      });
    },

    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      let that = this;
      getEmploy(row.id).then((response) => {
        that.form = response.data;
        that.locked = that.form.locked;
        that.idCardBirthday = response.data.idBridthDay;
        that.idCardSex = response.data.sex;
        // json 字段没用值必须初识化对象
        if (!that.form.urgentContact) {
          that.form.urgentContact = that.urgentContact;
        }
        if(that.form.salaryTempId > 0) {
          that.salaryInfo = that.getSalaryInfo(that.form.salaryTempId);
        }
        console.log(that.salaryInfo);
        if(that.form.salaryInfo){
          console.log(that.form)
          that.salaryInfo = that.form.salaryInfo;
        }else if(!that.salaryInfo){
          that.salaryInfo = {attInfo:{attItems:[]},itemInfo: []};
        }
        if(!that.salaryInfo.attInfo){
          that.salaryInfo.attInfo = {attItems:[]};
        }
        if(!that.salaryInfo.attInfo.attItems || that.salaryInfo.attInfo.attItems.length<=0){
          that.salaryInfo.attInfo.attItems = that.attItems;
        }
        that.reloading = true;
        console.debug(that.form);
      });
    },
    openAllCollapses() {
      this.activeNames = []; // 注意：由于每点开一个的单独面板 activeName都会发生变化，所以点击全部展开的时候要将activeName置空
      this.activeNames = this.ActiveNameList;
    },
    closeAllCollapses() {
      this.activeNames = [];
    },

    /** 提交按钮 */
    submitForm() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if (this.form.id != null) {
            this.$emit('update', { data: this.form });
          } else {
            // addPosition(this.form).then(response => {
            //   this.msgSuccess("新增成功");
            //   this.$emit("close", {refresh: true, isSuccess: true});
            // });
          }
        }
      });
    },
    saveSalaryInfo() {
      //   this.submitForm();
      console.log('saveSalaryInfo');
      let self = this;
      this.$refs['form'].validate((valid) => {
        if (valid) {
          let salaryInfo = {};
          salaryInfo.id = self.form.id;
          if(self.form.salaryTempId){
            salaryInfo.salaryTempId = self.form.salaryTempId;
          }else{
            salaryInfo.salaryTempId = 0;
          }
          salaryInfo.contractSalary = self.form.contractSalary;
          salaryInfo.bankAcct = self.form.bankAcct;
          salaryInfo.bankName = self.form.bankName;
          salaryInfo.insuranceAcct = self.form.insuranceAcct;
          salaryInfo.fundAcct = self.form.fundAcct;
          salaryInfo.salarySelfDef = self.form.salarySelfDef;
          if(self.form.salarySelfDef){
            salaryInfo.salaryInfo = self.salaryInfo;
          }else{
            salaryInfo.salaryInfo = null;
          }
          this.saving = true;
          updateCbSalarySetting(salaryInfo).then( resp =>{
            this.saving = false;
            this.$emit('close', { refresh: true, isSuccess: true });
          });
        } else {
          this.msgError('有非法输入或必填项未填写，请检查!');
        }
      });
    },

    findRecommander(recommender)
    {
      let that = this;
      if (recommender!=null && recommender.length > 0 ) {
        that.nameListLoading = true;
        findRecommenderList(recommender,this.form.companyId).then(
            (response)=>{
              if(response.code==200){
                that.nameListLoading = false;
                let recommenders = response.data;
                if (recommenders != null && recommenders != undefined && recommenders.length > 0) {
                  recommenders.forEach(element => {
                    that.recommenderList.push({value:element.id,label:element.name+'('+element.idNum+')'});
                  });
                }
              }
            }
        ).catch(err=>
            {
              that.nameListLoading = false;
              this.msgError(`后端下载文件出错 & ${err}`);
            }
        );
      }
      else
      {
        that.recommenderList=[];
      }
    },

  },
};
</script>


<style scoped>
a:hover {
  color: white;
}

/* a:visited{
  color:#409EFF;
} */
</style>
