import request from '@/utils/request'

// 查询打卡记录列表
export function listAttClockTime(query) {
    return request({
        url: '/attendance/attClockTime/findAll',
        method: 'post',
        data: query
    })
}

// 查询打卡记录详细
export function getAttClockTime(id) {
    return request({
        url: '/attendance/attClockTime/findOne?id=' + id,
        method: 'post'
    })
}

// 新增打卡记录
export function addAttClockTime(data) {
    return request({
        url: '/attendance/attClockTime/add',
        method: 'post',
        data: data
    })
}

export function updateAttClockTime(data) {
    return request({
        url: '/attendance/attClockTime/edit',
        method: 'post',
        data: data
    })
}
// 导入打卡记录
export function importAttClockTime(data) {
    return request({
        url: '/attendance/attClockTime/importClockTime',
        method: 'post',
        data: data
    })
}

// 删除打卡记录
export function delAttClockTime(id) {
    return request({
        url: '/attendance/attClockTime/remove',
        method: 'post',
        data: id
    })
}

// 导出打卡记录
export function exportAttClockTime(query) {
    return request({
        url: '/attendance/attClockTime/export',
        method: 'post',
        data: query
    })
}

