<template>
<div>
  <input type="file" accept="image/*;capture=camera">
  <video id="video">浏览器不支持 Video</video>

  <canvas id="canvas">
    <img id="photo" alt="拍照后的照片">
  </canvas>
  <div class="actions">
    <button id="takePhotoButton" type="button" class="btn btn-primary">拍照</button>
    <button id="downloadButton" type="button" class="btn btn-success">下载</button>
    <button id="clearButton" type="button" class="btn btn-danger">清空</button>
  </div>

</div>

</template>

<script>
export default {
  name: "records",
  data() {
    return {
      fileSrc: null,
      width : 320, // 默认比例
      height : 0, // 视频的高度，需要按照上面等比例放大

      streaming : false,

      video : null,
      canvas : null,
      photo : null,
      takePhotoButton : null,
      downloadButton : null,
      clearButton : null,
    }
  },
  created() {
    this.load()
  },

  methods: {
    load(){
      this.start();
    },
    clearPhoto() {
      let context = this.canvas.getContext('2d')
      // 生成空白图片
      context.fillStyle = "#AAA";
      context.fillRect(0, 0, this.canvas.width, this.canvas.height);
      let data = this.canvas.toDataURL('image/png');
      this.photo.setAttribute('src', data);
    },

    takePhoto() {
      let context = this.canvas.getContext('2d')
      if (this.width && this.height) {
        // 将 video 元素的 width 和 height 拿过来
        this.canvas.width = this.width;
        this.canvas.height = this.height;

        context.drawImage(this.video, 0, 0, this.width, this.height);

        // 生成图片
        let data = this.canvas.toDataURL('image/png');
        this.photo.setAttribute('src', data);
      } else {
        this.clearPhoto()
      }
    },

    downloadPhoto() {
      let link = document.createElement('a');
      link.download = '你的帅照.png';
      link.href = this.canvas.toDataURL();
      link.click();
    },

    async start() {
      this.video = document.getElementById('video');
      this.canvas = document.getElementById('canvas');
      this.photo = document.getElementById('photo');
      this.takePhotoButton = document.getElementById('takePhotoButton');
      this.downloadButton = document.getElementById('downloadButton');
      this.clearButton = document.getElementById('clearButton');

      // 获取摄像头的视频流
      try {
        this.video.srcObject = await navigator.mediaDevices.getUserMedia({video: true, audio: false})
        this.video.play()
      } catch (e) {
        console.error(e)
      }

      this.video.addEventListener('canplay', (event) => {
        if (!this.streaming) {
          // 按比例放大 videoHeight
          this.height = this.video.videoHeight / (this.video.videoWidth / this.width);

          // 设置 video 的宽高
          this.video.setAttribute('width', this.width);
          this.video.setAttribute('height', this.height);
          // 设置 canvas 的宽高
          this.canvas.setAttribute('width', this.width);
          this.canvas.setAttribute('height', this.height);
          this.streaming = true;
        }
      }, false)

      this.takePhotoButton.addEventListener('click', (event) => {
        // 拍照
        this.takePhoto()
      }, false)

      this.downloadButton.addEventListener('click', (event) => {
        // 下载
        this.downloadPhoto()
      })

      this.clearButton.addEventListener('click', (event) => {
        this.clearPhoto();
      })

      // 生成默认空白图片
      this.clearPhoto();
    }

    // start().then()

  },
}
</script>

<style scoped>

</style>
