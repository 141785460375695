import request from '@/utils/request'

// 查询薪酬设置列表
export function listCbSalary(query) {
    return request({
        url: '/cb/cbSalary/findAll',
        method: 'post',
        data: query
    })
}
export function mySalary(query) {
    return request({
        url: '/cb/cbSalary/mySalary',
        method: 'post',
        data: query
    })
}

// 查询薪酬设置详细
export function getCbSalary(id) {
    return request({
        url: '/cb/cbSalary/findOne?id=' + id,
        method: 'post'
    })
}

// 新增薪酬设置
export function genSalary(data) {
    return request({
        url: '/cb/cbSalary/genSalary',
        method: 'post',
        data: data
    })
}

// 修改薪酬设置
export function updateCbSalary(data) {
    return request({
        url: '/cb/cbSalary/edit',
        method: 'post',
        data: data
    })
}

// 删除薪酬设置
export function delCbSalary(id) {
    return request({
        url: '/cb/cbSalary/remove',
        method: 'post',
        data: id
    })
}

// 导出薪酬设置
export function exportCbSalary(query) {
    return request({
        url: '/cb/cbSalary/export',
        method: 'post',
        data: query
    })
}

// 更新考勤状态并计算考勤扣除
export function updateAttInfo(data) {
    return request({
        url: '/cb/cbSalary/updateAttInfo',
        method: 'post',
        data: data
    })
}

export function applyApprove(data){
    return request({
        url: '/cb/cbSalary/applyApprove',
        method: 'post',
        data: data
    })
}
export function approve(data){
    return request({
        url: '/cb/cbSalary/approve',
        method: 'post',
        data: data
    })
}
export function disapprove(data){
    return request({
        url: '/cb/cbSalary/disapprove',
        method: 'post',
        data: data
    })
}
export function paid(data){
    return request({
        url: '/cb/cbSalary/paid',
        method: 'post',
        data: data
    })
}
export function paidComplete(data){
    return request({
        url: '/cb/cbSalary/paidComplete',
        method: 'post',
        data: data
    })
}
export function returnApprove(data){
    return request({
        url: '/cb/cbSalary/returnApprove',
        method: 'post',
        data: data
    })
}

export function exportTax(query) {
    return request({
        url: '/cb/cbSalary/exportTax',
        method: 'post',
        data: query,
        responseType: 'blob',
    })
}

export function importTax(data) {
    return request({
        url: '/cb/cbSalary/importTax',
        method: 'post',
        data: data
    })
}

export function exportSalarySlip(query) {
    return request({
        url: '/cb/cbSalary/exportSalarySlip',
        method: 'post',
        data: query,
        responseType: 'blob',
    })
}
// 导出工资审核表
export function exportSalaryApprove(query) {
    return request({
        url: '/cb/cbSalary/exportSalaryApprove',
        method: 'post',
        data: query,
        responseType: 'blob',
    })
}

export function exportSalaryBank(query) {
    return request({
        url: '/cb/cbSalary/exportSalaryBank',
        method: 'post',
        data: query,
        responseType: 'blob',
    })
}

export function exportSalaryInsurance(query) {
    return request({
        url: '/cb/cbSalary/exportSalaryInsurance',
        method: 'post',
        data: query,
        responseType: 'blob',
    })
}

export function exportSalaryFund(query) {
    return request({
        url: '/cb/cbSalary/exportSalaryFund',
        method: 'post',
        data: query,
        responseType: 'blob',
    })
}
