<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px">
      <el-form-item label="组名称" prop="name">
        <el-input
            v-model="queryParams.name"
            placeholder="请输入组名称"
            clearable
            size="small"
            @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="考勤类型" prop="type">
        <el-select v-model="queryParams.type" placeholder="请选择考勤类型" clearable
                   size="small">
          <el-option v-for="item in AttendanceTypes" :key="item.value" :label="item.label" :value="item.value"/>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
<!--      <el-col :span="1.5">-->
<!--        <el-button type="primary" plain icon="el-icon-plus" size="mini"-->
<!--                   @click="handleAdd" v-permission="['/attendance/attendanceGroup/add']" >新增 </el-button>-->
<!--      </el-col>-->
<!--      <el-col :span="1.5">-->
<!--        <el-button type="success" plain icon="el-icon-edit" size="mini" :disabled="single"-->
<!--                   @click="handleUpdate" v-permission="['/attendance/attendanceGroup/edit']" >修改 </el-button>-->
<!--      </el-col>-->
<!--      <el-col :span="1.5">-->
<!--        <el-button type="danger" plain icon="el-icon-delete" size="mini" :disabled="multiple"-->
<!--                   @click="handleDelete" v-permission="['/attendance/attendanceGroup/remove']" >删除 </el-button>-->
<!--      </el-col>-->
      <!--      <el-col :span="1.5">-->
      <!--        <el-button-->
      <!--            type="warning"-->
      <!--            plain-->
      <!--            icon="el-icon-download"-->
      <!--            size="mini"-->
      <!--            @click="handleExport"-->
      <!--            v-permission="['/attendance/attendanceGroup/export']"-->
      <!--        >导出-->
      <!--        </el-button>-->
      <!--      </el-col>-->
      <el-col :span="1.5">
        <el-popover placement="top-start" title="考勤组" width="350" trigger="click">
          <ul>
            <li>！！！注意：如果考勤组班次等打卡相关信息修改并需要更新本月考勤打卡设置的，请执行“更新排班”，更新考勤组的打卡信息，否则当月考勤打卡信息不会被更新。</li>
            <li>系统会在每月27号晚上11点执行生成下月考勤组所有员工每日上下班打卡设置信息，如果27号后修改考勤组信息，则需执行“更新排班”功能，跟新考勤组所有员工考勤打卡设置</li>
            <li>更新排班：此功能用于根据当前考勤组设置重新更新打卡设置，比如考勤班次修改，特殊日期添加修改等，都需要重新更新打卡设置</li>
          </ul>
          <el-button type="primary" plain icon="el-icon-question" size="mini" slot="reference">帮助</el-button>
        </el-popover>
      </el-col>
      <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>
    </el-row>

    <el-table v-loading="loading" :data="attendanceGroupList" @selection-change="handleSelectionChange" stripe border>
      <el-table-column type="selection" width="55" align="center"/>
      <el-table-column label="考勤组名称" align="center" prop="name"/>
      <el-table-column label="考勤类型" align="center" prop="type">
        <template slot-scope="scope">
          {{getDictDataLabel(DICT_TYPE.ATT_TYPE, scope.row.type)}}
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
<!--          <el-button size="mini" type="text" icon="el-icon-edit"-->
<!--                     @click="handleUpdate(scope.row)"-->
<!--                     v-permission="['/attendance/attendanceGroup/edit']"-->
<!--          >修改-->
<!--          </el-button>-->
          <el-button v-if="scope.row.type == 2" size="mini" type="text" icon="el-icon-edit"
                     @click="doAttGrpClsSch(scope.row.id)">排班
          </el-button>
<!--          <el-button size="mini" type="text" icon="el-icon-delete"-->
<!--                     @click="handleDelete(scope.row)"-->
<!--                     v-permission="['/attendance/attendanceGroup/remove']"-->
<!--          >删除-->
<!--          </el-button>-->
          <el-button size="mini" type="text" icon="el-icon-edit"
                     @click="reGenStatisticDay(scope.row)">更新排班
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
        v-show="total>0"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
    />

    <!-- 更新考勤统计 -->
    <el-dialog :title="title" :visible.sync="openStatistic" append-to-body width="70%">
      <el-form ref="formStatistic" :model="formStatistic" :rules="rulesStatistic" label-width="90px">
        <!--        <el-row :gutter="35">-->
        <!--          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb20">-->
        <!--            <el-form-item label="" prop="type">-->
        <!--              更新考勤统计时间段-->
        <!--              <el-radio-group v-model="formStatistic.range">-->
        <!--                <el-radio :label="1">本月</el-radio>-->
        <!--                <el-radio :label="2">本月</el-radio>-->
        <!--                <el-radio :label="3">下月</el-radio>-->
        <!--                <el-radio :label="4">其他</el-radio>-->
        <!--              </el-radio-group>-->
        <!--            </el-form-item>-->
        <!--          </el-col>-->
        <!--        </el-row>-->
        <el-row :gutter="35">
          <div style="padding-left: 30px;padding-top: 10px">* 如果对考勤组进行了修改，可以使用此更新，重新更新设置某时段的排班情况。<br/>
            * 如果员工调动部门，只需对某一员工排班更新，请选择员工更新，日期则选择该员工调动日期到月底。
          </div>
        </el-row>
        <el-row :gutter="35">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
            <el-form-item :label="formStatistic.name" prop="name">
              <el-radio v-model="formStatistic.type" label="group">整组</el-radio>
              <el-radio v-model="formStatistic.type" label="employ">员工</el-radio>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
            <el-form-item v-if="formStatistic.type=='employ'" label="选择员工" prop="empId" :rules="{ required: true, message: '必须选择员工', trigger: ['blur', 'change'] }">
              <el-select style="width: 100%" v-model="formStatistic.empId" filterable remote reserve-keyword placeholder="请输入员工姓名" :remote-method="searchUpdatedEmploy"
                         :loading="loading" >
                <el-option
                    v-for="item in updatedEmploy"
                    :key="item.id"
                    :label="item.name+'('+item.idNum+')'"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!--          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">-->
          <!--          </el-col>-->
        </el-row>
        <el-row :gutter="35">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
            <el-form-item label="开始时间" prop="startDate">
              <el-date-picker clearable size="small"
                              v-model="formStatistic.startDate"
                              type="date"
                              value-format="yyyy-MM-dd"
                              placeholder="选择考勤统计开始时间">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
            <el-form-item label="结束时间" prop="endDate">
              <el-date-picker clearable size="small"
                              v-model="formStatistic.endDate"
                              type="date"
                              value-format="yyyy-MM-dd"
                              placeholder="选择考勤统计结束时间">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="35">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb20">
            <el-form-item label="" prop="type">
              是否重新计算考勤打卡请假（如果是更新下月可以不计算）
              <el-radio-group v-model="formStatistic.reCaculate">
                <el-radio :label="true">计算</el-radio>
                <el-radio :label="false">不计算</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitStatistic">确 定</el-button>
        <el-button @click="cancelStatistic">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  listAttGroupWithMe,
  getAttendanceGroup,
  delAttendanceGroup,
  addAttendanceGroup,
  updateAttendanceGroup,
  exportAttendanceGroup } from "@/api/attendance/attendanceGroup";
import RightToolbar from "@/components/RightToolbar"
import permission from '@/directive/permission/index.js' // 权限判断指令
import Pagination from "@/components/Pagination";
import {DICT_TYPE,getDictLabelFromData, getDictDataLabel, getDictDatas} from "@/utils/dict";
import {reGenStatisticDay} from "@/api/attendance/attStatisticDay";
import {hideLoading, showLoading} from "../../../../utils/reqloading";
import {getEmployByUserId,getEmploysByDepPos, listSimpleEmploy} from '@/api/employ/employ';

// 表查询配置
const dbOrderBy = ''

export default {
  name: "SchModify",
  components: {
    RightToolbar,
    Pagination,
  },
  directives: { permission },
  watch: {
    // "formStatistic.range"() {
    //   if(1 == this.formStatistic.range){
    //
    //   }
    // },
  },
  data() {
    return {
      row: null,
      isReadOnly: false,
      // 遮罩层
      loading: true,
      // 查询参数
      where: {
        query: '',
        filters: []
      },
      // 选中数组
      ids: [],
      selectRows: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 考勤组管理表格数据
      attendanceGroupList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      openStatistic: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        orderby: dbOrderBy,
        name: null,
        type: null,
      },
      // 表单参数
      form: {
      },
      formStatistic: {
        range: 2,
        reCaculate: true,
        type: 'group',
      },
      updatedEmploy:[],
      empIds:[],
      rulesStatistic: {
        startDate: [
          {
            required: true,
            message: "开始时间不能为空", trigger: "blur" }
        ],
        endDate: [
          {
            required: true,
            message: "结束时间不能为空", trigger: "blur" }
        ],

      },

      AttendanceTypes: DICT_TYPE.AttendanceTypes,
    };
  },
  created() {
    this._filter = {};
    this.getList();
  },
  methods: {
    reGenStatisticDay(row){
      // reGenStatisticDay();
      console.log(row);
      this.openStatistic = true;
      this.formStatistic.groupId = row.id;
      this.formStatistic.name = row.name;
      this.formStatistic.attGrpDepIds = row.attGrpDepIds;
    },
    submitStatistic(){
      this.$refs["formStatistic"].validate(valid => {
        if (valid) {
          let empId = ''
          console.log(this.formStatistic.empId)
          if(this.formStatistic.type == 'group') {
            empId = '';
          }else if(this.formStatistic.empId){
            empId = this.formStatistic.empId;
          }else{
            this.msgError("请选择员工");
            return;
          }
          showLoading();
          reGenStatisticDay(this.formStatistic.groupId,
              this.formStatistic.startDate, this.formStatistic.endDate,
              this.formStatistic.reCaculate, empId)
              .then(response => {
                this.openStatistic = false;
                hideLoading();
                this.msgSuccess("统计计算完成");
              });
        }
      });
    },
    cancelStatistic(){
      this.openStatistic = false;
    },
    /** 查询考勤组管理列表 */
    getQuery(){
      this._filter = {};
      if(this.queryParams.name) {
        this._filter['name'] = {
          type: 'search',
          value: this.queryParams.name,
        }
      }
      if(this.queryParams.type) {
        this._filter['type'] = {
          type: 'search',
          value: this.queryParams.type,
        }
      }
      const filters = this.parseFilters(this._filter)
      if (filters && filters.length > 0) {
        this.where.filters = filters
      } else {
        // 如果过滤条件为空，设置空数组
        this.where.filters = []
      }
      const params = {
        pageNum: this.queryParams.pageNum,
        pageSize: this.queryParams.pageSize,
        where: this.where,
        order: this.orderby
      }

      return params;
    },
    getList() {
      this.loading = true;
      const params = this.getQuery();
      listAttGroupWithMe(params).then(response => {
        this.attendanceGroupList = response.data.records;
        this.total = response.data.total;
        this.loading = false;
      })
      ;
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {};
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      console.log(selection);
      this.selectRows = selection;
      this.ids = selection.map(item => item.id)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.$router.push({name:"attendance_attendanceGroup_add"});
      // this.row = {time: Date.parse(new Date())};
      // this.open = true;
      // this.title = "添加考勤组";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      if(row.id){
        this.row = row;
      }else{
        if(this.selectRows.length === 1){
          this.row = this.selectRows[0];
        }else {
          this.row = {};
        }
        // this.row = {};
        // this.row.id = this.ids[0];
      }
      console.log(this.row);
      this.$router.push({name:"attendance_attendanceGroup_add", query: this.row});
      // this.open = true;
      // this.title = "修改考勤组";
    },

    doAttGrpClsSch(attGrpId){
      this.$router.push({name:"attendance_attGrpClsSch_edit", query: {attGrpId: attGrpId}});
    },

    close(arg){
      this.open = false;
      if(arg.refresh){
        this.getList();
      }
    },

    /** 删除按钮操作 */
    handleDelete(row) {
      let ids = [];
      if(row.id){
        ids = [row.id];
      }else{
        ids = this.ids;
      }

      this.$confirm('是否确认删除选中的考勤组管理数据?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return delAttendanceGroup(ids);
      }).then(() => {
        this.getList();
        this.msgSuccess("删除成功");
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      const params = this.getQuery();
      this.$confirm('是否确认导出所有考勤组管理数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return exportAttendanceGroup(params);
      }).then(response => {
        this.download(response.msg);
      })
    },
    searchUpdatedEmploy(query){
      if (query !== '') {
        this.loading = true;
        let filter = {};
        filter['name'] = {
          type: 'search',
          value: query,
        }
        const params = this.getQueryParam(filter, 1, 20, '');
        listSimpleEmploy(params).then(response=>{
          console.log(response.data.records);
          this.updatedEmploy = response.data.records;
          this.loading = false;
        });
      } else {
        this.updatedEmploy = [];
      }
    },
    selectEmploy(val){
      this.formStatistic.empId = val;
      // console.log(val)
      // let employ = null;
      // for(let i = 0; i < this.updatedEmploy.length; i++){
      //   if(val == this.updatedEmploy[i].userId){
      //     employ = this.updatedEmploy[i];
      //     break;
      //   }
      // }
      // if(employ) this.formStatistic.empId = employ.id;
    },

  }
};
</script>

<style scoped>

</style>
