<template>
  <div>
    <h2 align="center">离 职 结 算 申 请</h2>
    <el-form ref="form" :model="form" :rules="rules" label-width="150px">
      <el-collapse v-model="activeNames">
        <el-collapse-item title="离 职 申 请 信 息" name="departInfo">
          <el-row :gutter="35">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
              <el-form-item label="姓名" prop="name">
                <el-input disabled v-model="form.name" placeholder="请输入姓名"/>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
              <el-form-item label="公司" prop="companyName">
                <el-input disabled v-model="form.companyName" placeholder="请输入所属公司"/>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
              <el-form-item label="部门" prop="departmentName">
                <el-input disabled v-model="form.departmentName" placeholder="请输入部门"/>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
              <el-form-item label="岗位" prop="positionName">
                <el-input disabled v-model="form.positionName" placeholder="请输入岗位"/>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
              <el-form-item label="入职时间" prop="joinCompanyDate">
                <el-input disabled v-model="form.joinCompanyDate" placeholder="请输入职时间"/>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
              <el-form-item label="员工状态" prop="empStatus">
                <el-input disabled v-model="form.empStatus" placeholder="员工状态"/>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
              <el-form-item label="离职类型" prop="type" :rules="{required: true,message: '离职类型不能为空', trigger: 'blur' }">
                <el-select v-if="!form.id" v-model="form.type" clearable @change="typeChange" placeholder="离职类型" size="small">
                  <el-option v-for="item in getDictDatas(DICT_TYPE.BPM_DEPART_TYPE)" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled">
                  </el-option>
                </el-select>
                <el-select v-if="form.id" :disabled="isReadOnly" v-model="form.type" clearable placeholder="离职类型" size="small">
                  <el-option v-for="item in getDictDatas(DICT_TYPE.BPM_DEPART_TYPE)" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
              <el-form-item label="实际离职时间" prop="realDate" :rules="{required: form.id?false:true,message: '离职时间不能为空', trigger: 'blur' }">
                <el-date-picker :disabled="isReadOnly" clearable size="small"
                                v-model="form.realDate"
                                type="date"
                                value-format="yyyy-MM-dd"
                                placeholder="实际离职时间">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">
              <el-form-item label="离职原因" prop="departReason">
                <el-input disabled v-model="form.departReason" type="textarea" placeholder="离职原因"/>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">
              <el-form-item label="离职交接事项" prop="departReturn">
                <el-input disabled v-model="form.departReturn" type="textarea" placeholder="离职交接事项"/>
              </el-form-item>
            </el-col>
          </el-row>
        </el-collapse-item>
        <el-collapse-item title="离 职 结 算 信 息" name="returnGoodsInfo">
          <el-divider>交接物品</el-divider>
          <el-row :gutter="10" class="mb8">
            <el-col v-if="!isReadOnly" :span="1.5">
              <el-button
                  type="primary"
                  plain
                  icon="el-icon-plus"
                  size="mini"
                  @click="handleAddGoods"
              >新增
              </el-button>
            </el-col>
          </el-row>
          <el-table v-loading="loading" :data="form.returnGoods" style="width:100%" stripe border>
            <el-table-column label="交接物品" align="center" prop="title">
              <template slot-scope="scope">
                <el-input :disabled="isReadOnly" v-model="scope.row.title"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="折旧说明" align="center" prop="description">
              <template slot-scope="scope">
                <el-input :disabled="isReadOnly" v-model="scope.row.description"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="折旧金额" align="center" prop="depreciate">
              <template slot-scope="scope">
                <el-input :disabled="isReadOnly" v-model="scope.row.depreciate"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="接收人" align="center" prop="receiverName">
              <template slot-scope="scope">
<!--                <el-select style="width: 100%" v-model="scope.row.receiverName" filterable remote reserve-keyword placeholder="请输入接收人姓名"-->
<!--                           :remote-method="searchEmploy" @change="selectEmploy" :loading="loading">-->
<!--                  <el-option-->
<!--                      v-for="item in transferEmploy"-->
<!--                      :key="item.userId"-->
<!--                      :label="item.name+'('+item.idNum+')'"-->
<!--                      :value="item.userId">-->
<!--                  </el-option>-->
<!--                </el-select>-->
                <el-autocomplete :disabled="isReadOnly" clearable v-model="scope.row.receiverName" :fetch-suggestions="searchEmploy" placeholder="请输入接收人姓名"
                                 :trigger-on-focus="false" @select="selectEmploy"></el-autocomplete>
<!--                <el-input v-model="scope.row.receiverName"></el-input>-->
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center" class-name=" fixed-width" width="80" fixed="right">
              <template slot-scope="scope">
                <el-button v-if="!isReadOnly" size="mini" type="text" icon="el-icon-delete" @click="handleDeleteGoods(scope.row)">
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-divider>当 月 考 勤</el-divider>
          <el-row :gutter="35">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="6" class="mb0">
              <el-form-item label="考勤截止日期">
                <el-date-picker :disabled="isReadOnly" clearable
                                v-model="form.attendenceInfo.attEndDate"
                                type="date" value-format="yyyy-MM-dd" placeholder="考勤截止日期">
                </el-date-picker>
<!--                <el-popover-->
<!--                    placement="right"-->
<!--                    width="300"-->
<!--                    height="300"-->
<!--                    trigger="click">-->
<!--                  <Records :eomploy="form" ></Records>-->
<!--                  <el-button type="text" slot="reference">查看考勤</el-button>-->
<!--                </el-popover>-->
                <el-button type="text" plain icon="el-icon-search" size="mini" @click="showAttRecords">查看考勤</el-button>
<!--                <el-input :disabled="isReadOnly" v-model="form.attendenceInfo.attEndDate" placeholder="出勤天数"/>-->
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="6" class="mb0">
              <el-form-item label="出勤天数">
                <el-input :disabled="isReadOnly" v-model="form.attendenceInfo.attDays" placeholder="出勤天数"/>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="6" class="mb0">
              <el-form-item label="加班天数">
                <el-input :disabled="isReadOnly" v-model="form.attendenceInfo.otDays" placeholder="加班天数"/>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="6" class="mb0">
              <el-form-item label="其它情况说明">
                <el-input :disabled="isReadOnly" v-model="form.attendenceInfo.others" placeholder="其它情况说明"/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-divider>财 务 结 算</el-divider>
          <el-row :gutter="35">
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
              <el-form-item label="最后结算工资">
                <el-input :disabled="isReadOnly || !(taskStep == 'financeApprove' || taskStep == 'hrApprove')" v-model="form.salary" placeholder="最后结算工资金额"/>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
              <el-form-item label="支付方式">
                <el-select :disabled="isReadOnly || !(taskStep == 'financeApprove' || taskStep == 'hrApprove')" v-model="form.salaryType" placeholder="请选择支付方式">
                  <el-option label="现金支付" value="现金支付"/>
                  <el-option label="工资卡支付" value="工资卡支付"/>
                </el-select>
<!--                <el-input :disabled="isReadOnly" v-model="form.salaryType" placeholder="支付方式"/>-->
              </el-form-item>
            </el-col>
          </el-row>
          <el-divider>其 他</el-divider>
          <el-row :gutter="35">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb20">
              <el-form-item label="附件" prop="attachedFiles">
                <template v-if="form.attachedFiles">
                  <el-tag v-for="item in form.attachedFiles"
                          :key="item.name"
                          @click="download(item)">
                    <el-link type="primary" target="_blank">
                      {{item.name}}
                    </el-link>
                  </el-tag>
                </template>
                <template v-if="!form.id">
                  <el-upload
                      action
                      class="upload-demo"
                      ref="upload"
                      multiple
                      :limit="20"
                      :on-exceed="handleExceed"
                      :http-request="uploadFile"
                      :on-preview="handlePreview"
                      :on-remove="handleRemove"
                      :before-upload="beforeUpload"
                      :on-change="handleChange"
                      :file-list="fileList"
                      :auto-upload="false">
                    <el-button slot="trigger" size="small" type="primary">上传文件</el-button>
                    <!--                <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">上传到服务器</el-button>-->
                    <!--                <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>-->
                  </el-upload>
                  <!--              <el-input disabled v-model="form.url" placeholder="请选择文件"/>-->
                </template>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="35" v-if="!form.id">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb20">
              <el-form-item label="抄送给" prop="cc"  style="display:block !important;">
                <template v-if="!form.id">
                  <treeselect :disabled="isReadOnly" v-model="cc" :options="employTree"
                              :multiple="true" :flat="true" :disable-branch-nodes="true" noChildrenText="没有员工"
                              :normalizer="normalizerNode" placeholder="请选择抄送人员"/>
                </template>
                <template v-if="form.id">
                  <span>{{form.otherInfo? form.otherInfo.ccName:"无"}}</span>
                </template>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="35">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb20">
              <el-form-item label="相关人员">
                <el-popover
                    placement="right"
                    width="300"
                    trigger="click">
                  <Approves :approvesOrder="approvesOrder" :otherInfo="form.otherInfo"></Approves>
                  <el-button type="text" slot="reference">相关人员</el-button>
                </el-popover>
              </el-form-item>
            </el-col>
          </el-row>
        </el-collapse-item>
      </el-collapse>
    </el-form>
    <div slot="footer" class="dialog-footer" style="text-align: center">
      <template v-if="showSubmit">
      <el-button v-if="taskStep == 'apply'" type="primary" @click="submitForm" v-permission="['/bpm/departEnd/apply', '/bpm/departEnd/approve']">提 交
      </el-button>
      </template>
      <template v-if="isShowClose">
        <el-button v-if="isReadOnly" @click="cancel">关 闭</el-button>
<!--        <el-button v-if="!isReadOnly" @click="cancel">取 消</el-button>-->
      </template>
    </div>
    <el-dialog :title="recordsTitle" :visible.sync="openAttRecords" append-to-body width="70%">
      <Records :employ="form"></Records>
    </el-dialog>
  </div>
</template>

<script>
import permission from '@/directive/permission/index.js'; // 权限判断指令
import {
  initApply,
  addDepartEnd,
  getDepartEnd,
  getByProcessInstanceId,
} from "@/api/bpm/departEnd.js";
import { showLoading, hideLoading } from '@/utils/reqloading';
import {fileDownload} from "@/utils/fileUtils";
import {download} from "@/api/comapi/comapi.js";
import { getOrgEmpTree, normalizerEmployMultiSelectTree} from '@/views/pages/employ/components/empOrgTree';
import {DICT_TYPE,getDictLabelFromData, getDictDataLabel, getDictDatas} from "@/utils/dict";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import {strToIntArray, getEmpNamesStrFromListByIds} from "@/utils/tools";
import Approves from "../components/approves"
import {getEmployByUserId,getEmploysByDepPos, listSimpleEmploy} from '@/api/employ/employ';
import Records from "@/views/pages/attendance/my/records";

export default {
  name: "departEndEdit",
  components: {
    Treeselect,
    Approves,
    Records,
  },
  props: {
    type: String,
    row: Object,
    id: String,
    processInstanceId: String,
    isReadOnly: Boolean,
    isShowClose: Boolean,
  },
  directives: { permission },
  watch:{
    row(n,o){ //n为新值,o为旧值;
      //if(n === o) return;
      this.load();
    },
    id(){
      this.load();
    },
    processInstanceId(){
      this.load();
    },
  },
  activated() {
    // this.tinymceFlag++;
  },
  data(){
    return {
      // 遮罩层
      loading: false,
      employs: [],
      activeNames: ['departInfo',"returnGoodsInfo","attendenceInfo","salaryInfo","otherInfo"],
      employTree: [],
      fileList:[],
      formData: null,
      taskStep: 'apply',
      cc: null,
      // 表单参数
      form: {
        returnGoods: [],
        attendenceInfo: {},
        salaryInfo: null,
      },
      // 表单校验
      rules: {
      },
      showSubmit: true,
      pickerOptionsDepartEnd: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
      },
      approvesOrder: {"dm":"部门领导", "hr":"人事专员", "HRManager":"人事经理", "finance":"财务专员", "gm":"总经理"},
      openAttRecords: false,
      recordsTitle: "考勤记录",
    };
  },
  created() {
    this.load();
    console.log("this.type="+this.type)
    if(this.type){
      this.taskStep = this.type;
    }
  },
  methods: {
    handleAddGoods(){
      this.form.returnGoods.push({});
    },
    handleDeleteGoods(row){
      console.log(this.form.returnGoods.indexOf(row));
      const idx = this.form.returnGoods.indexOf(row)
      this.form.returnGoods.splice(idx, 1);
    },
    searchEmploy(query, cb){
      // console.log(row);
      if (query !== '' && query.length > 0) {
        // this.loading = true;
        let filter = {};
        filter['name'] = {
          type: 'search',
          value: query,
        };
        filter['companyId'] = {
          type: 'eq',
          value: this.form.companyId,
        }

        const params = this.getQueryParam(filter, 1, 20, '');
        listSimpleEmploy(params).then(response=>{
          let employs = [];
          response.data.records.forEach(item =>{
            let idNum = item.idNum.substr(item.idNum.length-6, item.idNum.length);
            employs.push({id: item.id, value: item.name+"("+item.idNum+")", name:item.name});
          })
          cb(employs);
          // this.loading = false;
        });
      } else {
        cb([]);
      }
    },
    selectEmploy(val) {
      console.log(val)
      // console.log(this);
      // console.log(row)
      let employ = null;
      // for(let i = 0; i < this.transferEmploy.length; i++){
      //   if(val == this.transferEmploy[i].userId){
      //     employ = this.transferEmploy[i];
      //     break;
      //   }
      // }
      // if(null != employ){
      //   this.form.outDepartmentId = employ.departmentId;
      //   this.form.outPositionId = employ.positionId;
      // }
      // console.log(instanceId)
    },
    validateDepartEndDate(){
      if (this.form.type == DICT_TYPE.BPM_DEPART_TYPE_SELF) {
        console.log("validateDepartEndDate");
        let departEndDate = Date.now();
        if (this.form.empStatus == '试用') {
          departEndDate = new Date(new Date().setDate(new Date().getDate() + 3));
          let planDate = new Date(this.form.planDate);
          if(planDate.getTime() < departEndDate.getTime()){
            this.msgError("试用期，离职时间不能小于3天");
            return false;
          }
        } else if (this.form.empStatus == '正式') {
          departEndDate = new Date(new Date().setMonth(new Date().getMonth() + 1));
          let planDate = new Date(this.form.planDate);
          if(planDate.getTime() < departEndDate.getTime()){
            this.msgError("正式员工，离职时间不能小于一个月");
            return false;
          }
        } else {
          let planDate = new Date(this.form.planDate);
          if(planDate.getTime() < departEndDate.getTime()){
            this.msgError("离职时间不能小于今天");
            return false;
          }
        }
      }
      return true;
    },
    typeChange(val){
      this.$nextTick(() => {
        //this.form.planDate = null;
        if (val == DICT_TYPE.BPM_DEPART_TYPE_SELF) {
          let departEndDate = Date.now();
          if (this.form.empStatus == '试用') {
            departEndDate = new Date(new Date().setDate(new Date().getDate() + 3));
          } else if (this.form.empStatus == '正式') {
            departEndDate = new Date(new Date().setMonth(new Date().getMonth() + 1));
          } else {
            departEndDate = new Date(new Date().setMonth(new Date().getMonth() + 1));
          }
          this.pickerOptionsDepartEnd = {
            disabledDate(time) {
              return time.getTime() < departEndDate;
            },
          }
        } else {
          this.pickerOptionsDepartEnd = {
            disabledDate(time) {
              return time.getTime() < Date.now();
            },
          }
        }
      });
    },

    load() {
      this.reset();
      console.log("load...")
      if(this.type == 'apply'){
        console.log("apply..");
        console.log(this.$route.query)
        if(this.$route.query.departId){
          initApply(this.$route.query.departId).then(response => {
            this.form = response.data;
            this.initForm();
            this.loading = false;
          });
        }else{
          this.msgError("请先提交离职申请并审批同意才能办理离职结算")
        }
        if(!this.employTree || this.employTree.length <= 0){
          getOrgEmpTree(this.$store.getters.userInfo.companyId, true,(tree, companyList, departEndmentList, employList )=>{
            this.employTree = tree;
            this.employList = employList;
            hideLoading();
          });
        }
      }else {
        if(this.id) {
          console.log("id=");
          console.log(this.id);
          getDepartEnd(this.id).then(response => {
            this.form = response.data;
            this.initForm();
          });
        }else if(this.row){
          console.log("row=");
          console.log(this.row);
          this.form = this.row;
          this.form.companyName = this.$store.getters.userInfo.companyMap[this.form.companyId];
          if(!this.form.attendenceInfo){
            this.form.attendenceInfo = {};
          }
        }else if(this.processInstanceId){
          console.log("processInstanceId="+this.processInstanceId);
          getByProcessInstanceId(this.processInstanceId).then(response => {
            this.form = response.data;
            this.form.companyName = this.$store.getters.userInfo.companyMap[this.form.companyId];
            // this.form.companyName = this.$store.getters.userInfo.companyName;
          });
        }else{
          console.log("no arg");
        }
      }
    },
    // 重新加载数据后，初识不能从后台返回的数据
    initForm(){
      this.form.companyName = this.$store.getters.userInfo.companyMap[this.form.companyId];
      if(!this.form.companyName){
        this.form.companyName = this.$store.getters.userInfo.companyName;
      }
      // console.log(this.$store.getters.userInfo.companyMap[this.form.companyId])
      // console.log(this.$store.getters.userInfo.companyName)
      this.showSubmit = true;
    },
    // 取消按钮
    cancel() {
      this.$emit("close", {refresh:false,isSuccess:true});
    },
    // 表单重置
    reset() {
      this.form = {
        returnGoods: [],
        attendenceInfo: {},
        salaryInfo: null,
      };
    },
    normalizerNode(node) {
      return normalizerEmployMultiSelectTree(node);
    },
    /** 提交按钮 */
    submitForm() {
      if(this.type == 'apply'){
        let self = this;
        this.$refs["form"].validate(valid => {
          if (valid) {
            if(!this.validateDepartEndDate()){
              return;
            }
            if (this.form.id == null) {
              if(this.cc){
                this.form.cc = this.cc.join(",");
                this.form.ccName = getEmpNamesStrFromListByIds(this.employList, this.cc);
              }
              showLoading();
              self.formData = new FormData();
              //uploadFile会逐个加入到formData
              self.$refs.upload.submit();

              // formData.append('files', _file);
              delete this.form.companyName;
              self.formData.append('departEnd', JSON.stringify(this.form)); // isOverride自定义的其他参数
              addDepartEnd(self.formData).then(response => {
                this.form = response.data;
                this.msgSuccess("申请成功");
                this.showSubmit = false;
                hideLoading();
                this.bus.$emit('onCurrentContextmenuClick', {
                  id: 1,
                  path: this.$route.path,
                });

              });
            }
          }
        });
      }else{

      }
    },

    handleExceed(files, fileList) {
      this.msgError(`当前限制选择 20 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件.`);
    },

    handleRemove(file, fileList) {
      console.log("handleRemove");
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log("handlePreview");
      console.log(file);
    },

    handleChange(file, fileList) {
      console.log("handleChange");
      //this.currentContract.contractPictures = fileList;
      // 非自动上传，before-upload 不起作用，改到这里验证。
      let isValidFile = this.beforeUpload(file);
      if (!isValidFile) {
        this.$refs.upload.handleRemove(file);
      }
    },
    beforeUpload(file) {
      console.log("beforeUpload");
      var fileType = file.name.substring(file.name.lastIndexOf('.') + 1);
      const jpg = fileType === 'jpg' || fileType === 'JPG';
      const jpeg = fileType === 'jpeg' || fileType === 'JPEG';
      const isLt2M = file.size / 1024 / 1024 < 5;
      const isEmpty = file.size === 0;
      // if (!jpg && !jpeg) {
      //   this.msgError('上传文件只能是png,jpeg格式!');
      // }
      if (!isLt2M) {
        this.msgError('上传文件大小不能超过 5MB!');
      }
      // if (isEmpty) {
      //   this.msgError('上传文件大小不能为空文件!');
      // }
      // return (jpeg || jpg) && isLt2M && !isEmpty;
      return isLt2M;
    },

    async uploadFile(params) {
      console.log("uploadFile");
      console.debug(params);
      this.formData.append('files', params.file);

    },

    download(row) {
      fileDownload(row,download);
    },

    showAttRecords(){
      this.openAttRecords = true;
      console.log("showAttRecords");
      console.log(this.form)
      this.recordsTitle = this.form.name+"考勤记录";
    },
  },
}
</script>

<style scoped>

</style>
