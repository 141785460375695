import { render, staticRenderFns } from "./courseEvaluation.vue?vue&type=template&id=17b108c0&scoped=true"
import script from "./courseEvaluation.vue?vue&type=script&lang=js"
export * from "./courseEvaluation.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17b108c0",
  null
  
)

export default component.exports