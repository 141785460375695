<template>
	<el-collapse-item title="体检报告" :name="this.collapseStatus" :class="collapseSetting()">
		<el-form class="employ" ref="dataFrom" :model="dataFrom" label-width="150px">
			<el-row>
				<el-row>
					<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
						<el-form-item label="体检结果" prop="result" :rules="[{ required: true, message: '体检结果不能为空', trigger: 'blur' }]">
							<el-select v-model="dataFrom.result" placeholder="请选择" :disabled="readonly">
								<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
						<el-form-item label="体检备注">
							<el-input v-model="dataFrom.remarks" :readonly="readonly"></el-input>
						</el-form-item>
					</el-col>

					<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
						<el-form-item label=" ">
							<!-- <el-input v-model="remarks" :readonly="readonly"></el-input> -->
						</el-form-item>
					</el-col>

					<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">
						<div style="margin-left: 150px; margin-bottom: 10px">
							<el-upload
								ref="elUpload"
								:disabled="readonly"
								multiple=""
								class="avatar-uploader"
								action="onChange()"
								:limit="pictureLimit"
								:show-file-list="true"
								:file-list="fileList"
								list-type="picture-card"
								:on-success="handleAvatarSuccess"
								:http-request="onChange"
								:on-preview="handlePictureCardPreview"
								:auto-upload="true"
								accept=".jpeg,.jpg,.png,.bmp,application/pdf"
								:before-upload="beforeAvatarUpload"
								:on-exceed="handleExceed"
								:on-remove="handleRemove"
							>
								<i class="el-icon-plus avatar-uploader-icon"></i>
								<div slot="file" slot-scope="{ file }" v-loading="file.status == 'uploading'" style="height: 100%">
									<pdf v-if="file.name.endsWith('.pdf')" :src="file.url" :page="1"></pdf>
									<img v-else class="el-upload-list__item-thumbnail" style="object-fit: cover" :src="file.url" alt="" />
									<span class="el-upload-list__item-actions">
										<span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
											<i class="el-icon-zoom-in"></i>
										</span>

										<span  class="el-upload-list__item-delete" @click="handleRemove(file)">
											<i class="el-icon-delete"></i>
										</span>
									</span>
								</div>
							</el-upload>
							<el-dialog :visible.sync="dialogVisible" @close="closeDialog">
								<div v-if="dialogPdfName.endsWith('.pdf')">
									<pdf :src="pdfSrc" :page="1"></pdf>
								</div>
								<div v-else>
									<img width="100%" :src="dialogImageUrl" alt="" />
								</div>
							</el-dialog>
							<p style="transform:scale(0.6);margin-left:-230px;">上传体检结果, 支持jpg、jpeg、bmp、png、pdf格式，单个文件不超过50M。</p>
						</div>
					</el-col>
				</el-row>
			</el-row>
			<el-row>
				<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
					<el-form-item label=" ">
						<!-- <el-button v-on:click="edit" type="primary" plain v-bind:disabled="hasLocked ? true : this.isReadOnly" icon="el-icon-edit"
							>编辑</el-button
						> -->
						<el-button v-on:click="submitUpload" plain v-bind:disabled="readonly" type="primary" :loading="saving" icon="el-icon">保存</el-button>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
	</el-collapse-item>
</template>


<script>
import {
	uploadHealthCheckPicture,
	DeleteHealthCheckPicture,
	updateHealthCheck,
	downloadHealthPictures,
	downloadCertificatePictureApi,
} from '@/api/employ/employ';
import Pdf from 'vue-pdf';
export default {
	components: {
		Pdf,
	},
	data() {
		let readonlyInit = true;
		let healthReportInit = null;
		if (this.healthReport == null) {
			healthReportInit = {
				result: '',
				remarks: '',
				pictures: [],
			};
			readonlyInit = false;
		} else {
			healthReportInit = this.healthReport;
		}

		if (this.locked) {
			readonlyInit = true;
		} else {
			readonlyInit = this.isReadOnly;
		}

		return {
			dataFrom: healthReportInit,
			readonly: readonlyInit,
			enableAdd: this.enableAddButton,
			hasLocked: this.locked,
			saving: false,
			dialogImageUrl: '',
			dialogPdfName: '',
			pdfSrc: '',
			dialogVisible: false,
			pictureLimit: 6,
			fileList: [],
			tempFileList: new Map(),
			formData: new FormData(),
			options: [
				{ value: '合格', label: '合格' },
				{ value: '不合格', label: '不合格' },
				{ value: '其它', label: '其它' },
			],
		};
	},
	props: { healthReport: Object, id: Number, isReadOnly: Boolean, collapseStatus: String, locked: Boolean },
	watch: {
		isReadOnly(newValue, oldV) {
			this.readonly = newValue;
		},
		locked(newValue, oldV) {
			this.hasLocked = newValue;
			if (this.hasLocked) {
				this.readonly = true;
			} else {
				this.readonly = false;
			}
		},
	},
	created() {
		if (this.dataFrom != null && this.dataFrom.pictures.length > 0) {
			// this.languageLevels.forEach((item) => {
			// 	if (item.certificateUrl != null && item.certificateUrl.length > 0) {
			// 		this.downloadHealthPictures(item, item.certificateUrl);
			// 	}
			// });
			this.downloadAllHealthPictures();
			this.$forceUpdate();
		}
	},
	computed: {
		collapseSetting() {
			return function () {
				if (this.dataFrom.result && this.dataFrom.pictures != null && this.dataFrom.pictures != undefined && this.dataFrom.pictures.length > 0) {
					return { callpse_header_fill: true };
				} else {
					return { callpse_header_unfill: true };
				}
			};
		},
	},
	methods: {
		submitUpload() {
			let self = this;
			this.$refs.dataFrom.validate((valid) => {
				if (valid) {
					updateHealthCheck(self.id, self.dataFrom.result, self.dataFrom.remarks)
						.then((response) => {
							if (response.code == 200) {
								self.msgSuccess('保存成功');
							} else {
								self.msgError(response.message);
							}
						})
						.catch((err) => {
							self.msgError(err);
						});
				} else {
					this.msgError('有非法输入或必填项未填写，请检查!');
				}
			});
		},

		onChange(params) {
			//this.dataFrom.pictures.push(file);
			let formData = new FormData();
			formData.append('file', params.file);
			formData.append('employeeId', this.id);
			let self = this;
			uploadHealthCheckPicture(formData)
				.then((response) => {
					if (response.success) {
						let filePath = response.data;
						self.tempFileList.set(params.file.name.replace('.', ''), filePath);
						self.$message.success('上传成功!');
					} else {
						self.$message.error('上传失败：');
					}
				})
				.catch((err) => {
					self.$message.error('上传失败：' + err);
				});
		},

		handleAvatarSuccess(res, file) {
			//this.form.photo = URL.createObjectURL(file.name);
		},
		beforeAvatarUpload(file) {
			const isJPG = file.type === 'image/jpeg' || file.type === 'image/jpg';
			const isPng = file.type === 'image/png';
			const isBmp = file.type === 'application/bmp';
			const ispdf = file.type === 'application/pdf';
			const isLt50M = file.size / 1024 / 1024 < 50;
			if (!isJPG && !isPng && !ispdf && !isBmp) {
				this.$message.error('上传图片只能是 JPG,JPEG,PNG,BMP,PDF 格式!');
				file.status='fail';
			}
			if (!isLt50M) {
				this.$message.error('上传图片大小不能超过 50MB!');
				file.status='fail';
			}
			return (isJPG || isPng || ispdf || isBmp) && isLt50M;
		},
		handleExceed(files, fileList) {
			this.msgError(
				`当前限制选择 ${this.pictureLimit} 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件.`
			);
		},
		handleRemove(file, fileList) {
			if(file && file.raw && file.raw.status=="fail")
			{
				return;
			}
			
			let that = this;
			this.$confirm('确定移除吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					let filePath = '';
					if (file != null && file.name != null && file.name != undefined && file.name.indexOf('/') > 0) {
						filePath = file.name;
					} else {
						let fileName = file.name.replace('.', '');
						if (that.tempFileList.has(fileName)) {
							filePath = that.tempFileList.get(fileName);
							that.tempFileList.delete(fileName);
						} else {
							that.$message.error('前端没有找到要删除的体检报告。');
							return;
						}
					}

					let uploadFiles = this.$refs.elUpload.uploadFiles;
					let index = uploadFiles.findIndex((item) => {
						return item == file;
					});
					if (index > -1) {
						uploadFiles.splice(index, 1);
					}

					let formData = new FormData();
					formData.append('employeeId', that.id);
					formData.append('filePath', filePath);
					DeleteHealthCheckPicture(formData)
						.then((response) => {
							if (response.success) {
								that.$message.success('删除成功!');
							} else {
								that.$message.error('删除失败：' + response.message);
							}
						})
						.catch((err) => {
							that.$message.error('删除失败：' + err);
						});
				})
				.catch(() => {
					that.msgError('删除失败!');
				});
			
		},

		downloadAllHealthPictures() {
			this.dataFrom.pictures.forEach((item) => {
				// formData.files.push(encodeURIComponent(item));
				downloadCertificatePictureApi(item)
					.then((res) => {
						if (res) {
							let resData = res.data;
							var blob = new Blob([resData], { type: 'application/octet-stream' });
							let tempUrl = URL.createObjectURL(blob);
							this.fileList.push({ name: item, url: tempUrl });
							this.tempFileList.set(item, item);
						} else {
							this.msgError('后端下载文件出');
						}
					})
					.catch((e) => {
						this.loading = false;
						this.msgError(`后端下载文件出错 & ${e}`);
					});
			});
		},
		handlePictureCardPreview(file) {
			if (file.name.endsWith('.pdf')) {
				this.pdfSrc = file.url;
				this.dialogPdfName = file.name;
				this.dialogImageUrl='';
			} else {
				this.dialogImageUrl = file.url;
				this.pdfSrc ='';
			}

			this.dialogVisible = true;
		},
		closeDialog() {
			this.dialogImageUrl = '';
			this.dialogVisible = false;
		},
	},
};
</script>

<style>
</style>