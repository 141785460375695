import 'tracking/build/tracking-min.js';
import 'tracking/build/data/face-min.js';

export function loadImageToCanvas(url, callback) {
    var canvas = document.createElement('canvas');
    let ctx = canvas.getContext('2d');
    let img = new Image();
    //img.crossOrigin = 'anonymous';
    img.onload = function() {
        // 图片原始尺寸
        var originWidth = this.width;
        var originHeight = this.height;
        // alert("img onload="+originWidth+",h="+originHeight)
        // 最大尺寸限制
        var maxWidth = 368, maxHeight = 368;
        // 目标尺寸
        var targetWidth = originWidth, targetHeight = originHeight;
        // 图片尺寸超过400x400的限制
        if (originWidth > maxWidth || originHeight > maxHeight) {
            if (originWidth / originHeight > maxWidth / maxHeight) {
                // 更宽，按照宽度限定尺寸
                targetWidth = maxWidth;
                targetHeight = Math.round(maxWidth * (originHeight / originWidth));
            } else {
                targetHeight = maxHeight;
                targetWidth = Math.round(maxHeight * (originWidth / originHeight));
            }
        }
        // canvas对图片进行缩放
        canvas.width = targetWidth;
        canvas.height = targetHeight;
        // 清除画布
        ctx.clearRect(0, 0, targetWidth, targetHeight);
        // 图片压缩
        ctx.drawImage(img, 0, 0, targetWidth, targetHeight);
        console.log('img.height : ' + img.height + ' img.width : ' + img.width);
        let origImgData = ctx.getImageData(0,0,targetWidth,targetHeight)
        callback(img, canvas, url, origImgData);
    };
    img.src = url;
}

export function setFaceImg(imgPath, callback) {
    // alert("setfaceimg...")
    console.log("setFaceImg"+imgPath);
    setTimeout(function() {
        loadImageToCanvas(imgPath,(img, canvas,path, origImgData)=>{
            trackFace(img,canvas,path, origImgData, callback);
        })
    },10);
}

export function trackFace(img,canvas, path, origImgData,callback){
    var type = 'image/jpeg';
    //将canvas元素中的图像转变为DataURL
    var dataurl = canvas.toDataURL(type);
    var sampleImg = document.getElementById("sample");
    sampleImg.onload = function() {
        var tracker = new tracking.ObjectTracker(['face']);
        tracker.setStepSize(1.7)
        tracking.track('#sample', tracker);
        tracker.on('track', function(event) {
            console.log("track");
            console.log(event);
            cutFaces(sampleImg,canvas,event.data,(faceDataUrl, faceImgData)=>{
                console.log(faceImgData.length)
                // let faceCanvas = document.getElementById('imageResult');
                // let ctx = faceCanvas.getContext('2d');
                for(var i = 0; i < faceImgData.length;i++){
                    let canvasData = faceImgData[i];
                    // ctx.putImageData(canvasData,0,0)
                }
                callback({
                    type:'face',
                    faceDataUrl: faceDataUrl,
                    // faceImgData: faceImgData,
                    canvas: canvas,
                    code: event.data.length,
                    faceRects: event.data,
                    path: path,
                    // origImgData: origImgData,
                });

            })
        })
    }
    sampleImg.src=dataurl;
}

// img 检测到头像的图片，
function cutFaces(img, canvas, rects, callback){
    let faceDataUrl = []
    let faceImgData = []
    console.log("rects.length="+rects.length)
    for(var i = 0; i < rects.length;i++){
        let faceCanvas = document.createElement('canvas');
        let ctx = faceCanvas.getContext('2d');
        let rect = rects[i];
        faceCanvas.width = rect.width;
        faceCanvas.height = rect.height;
        ctx.clearRect(0, 0, rect.width, rect.height);
        ctx.drawImage(img, rect.x, rect.y, rect.width, rect.height, 0, 0, rect.width, rect.height);
        console.log(ctx.getImageData(0, 0,rect.width, rect.height))
        var type = 'image/jpeg';
        //将canvas元素中的图像转变为DataURL
        var dataUrl = faceCanvas.toDataURL(type);
        faceDataUrl.push(dataUrl)
        faceImgData.push(ctx.getImageData(0,0,rect.width,rect.height))
        // faceImgData.push(ctx.getImageData(0, 0,rect.width, rect.height));
    }
    callback(faceDataUrl, faceImgData)
}


