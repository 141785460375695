<template>
  <div class="tableContent">
		<div id="printMe">
			<div class="title">请 假 申 请 单</div>
			<div>
				<table class="table">
					<tr>
						<!-- 6 col -->
						<td class="secondCol">姓&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;名</td>
						<td class="thirdCol">{{form.name}}</td>
						<td class="fourCol">公&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;司</td>
						<td class="fiveCol">{{companyName}}</td>
						<td class="fourCol">部&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;门</td>
						<td class="fiveCol">{{form.departmentName}}</td>
					</tr>
					<tr>
						<td>请假起止</td>
						<td>{{form.beginTime}}-{{form.endTime}}</td>
						<td>请假时长</td>
						<td>{{form.days}}    天</td>
						<td>请假类型</td>
						<td>{{form.type}}</td>
					</tr>
					<tr>
						<td >请假事由</td>
						<td colspan="5">{{form.reason}}</td>
					</tr>

					<tr>
						<td colspan="6" >审批进度</td>
					</tr>

					<tr style="border-top:hidden;" v-for="(item,index) in handleTask.historyTask" :key="index">
            <td v-if="item.status" colspan="6">{{ stepDes(item, handleTask) }} </td>
						<td v-else colspan="6">{{item.stepName}} (进行中)</td>
					</tr>

					<tr>
						<td colspan="3" style="border-right:hidden;border-bottom:hidden" ></td>
						<td colspan="3" style="border-bottom:hidden" >申请人：</td>

					</tr>
          		<tr>
					<td colspan="3" style="border-right:hidden;"  ></td>
					<td colspan="3" >日期：</td>
					</tr>
				</table>
			</div>

		</div>
			<div style="text-align:center;" >
				<el-button type="success" plain icon="el-icon-printer" size="mini" v-print="printObj">打印</el-button>
        		<el-button style="margin-top: 10px" type="primary" size="mini"  plain @click="cancel">关 闭</el-button>
			</div>
	</div>
</template>

<script>
import permission from '@/directive/permission/index.js'; // 权限判断指令
import { getDepart} from "@/api/bpm/depart.js";
import {getCompany} from "@/api/org/company.js"
import {taskSteps} from "@/api/bpm/todoList.js";
import {getStepActive, getStepDes, getStepTitle} from "@/views/pages/bpm/bpmUtils";

export default {
  name: "leavePrinting",
  props: {
    id: String,
    processInstanceId: String,
	rowData:Object
  },
  directives: { permission },
  computed:{
    stepDes() {
      return getStepDes;
    }
  },

  data(){
	  let abc = this.rowData;
    return {
      // 表单参数
      form: this.rowData,
	  companyName:"Not found",
	  handleTask:{
        historyTask: []
      },
      printObj: {
				id: 'printMe',
				popTitle: ' ',
				extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>',
			},
    };
  },
  created() {
    this.load();
  },
  methods: {
    load() {
          //this.form = this.row;
           getCompany(this.form.companyId).then(response => {
            this.companyName = response.data.companyName;
          });
		  taskSteps(this.form.processInstanceId).then(response => {
			this.handleTask = response.data;
      	 });
      },
	   cancel() {
			this.$emit('close', { refresh: false, isSuccess: true });
		},
    },

}

</script>

<style scoped>
.table {
	width: 100%;
	border-style: solid;
	border-collapse: collapse;
	overflow-y: scroll;
	border: 1;

}
.tableContent {
	width: 900px;
	margin: auto;
	margin-top: 20px;
}

.firstCol{
	width: 2%;
}
.secondCol{
	width:15%;
}
.thirdCol{
	width:25%;
}
.fourCol{
	width:10%;
}
.fiveCol{
	width:25%;
}
.sexCol{
	width:23%;
}

.tableList table,
table tr th,
table tr td {
	border: 1px solid rgb(12, 12, 12);
  padding: 15px;
}

.title {
	font-size: 30px;
	width: 100%;
	text-align: center;
	margin-bottom: 10px;
}

</style>
