<template>
<div>
  <el-form ref="form" :model="form" label-width="118px">
  <el-row :gutter="35">
    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" >
      <el-form-item label="名称说明" prop="name">
        <el-input :disabled="true" v-model="form.name" placeholder="请输入名称说明"/>
      </el-form-item>
    </el-col>
  </el-row>
  <el-row :gutter="35">
    <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" >
      <el-form-item label="巡逻员">
        <el-input :disabled="true" v-model="form.empName" placeholder="巡逻员"/>
      </el-form-item>
    </el-col>
  </el-row>
    <el-row :gutter="35">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" >
        <el-form-item label="巡逻日期">
          <el-input :disabled="true" v-model="form.patrolDate" placeholder="巡逻日期"/>
        </el-form-item>
      </el-col>
    </el-row>
  <el-card class="box-card">
    <div v-for="item in form.patrolClockItems" :key="form.patrolClockItems.indexOf(item)" class="text item">
      <el-divider>巡逻点{{form.patrolClockItems.indexOf(item)+1}}</el-divider>
      <div>
        <div v-if="item.fixAdd" style="display: inline-flex;">
          <el-form-item label="指定地点：" prop="fixAdd">
            {{item.addr.addr}}，有效范围{{item.addr.range}}（米）
          </el-form-item>
        </div>
        <div v-if="item.fixTime" style="display: inline-flex">
          <el-form-item label="指定时间范围：" prop="fixTime">
            <span>{{item.startTime}}-{{item.endTime}}</span>
          </el-form-item>
        </div>
        <div style="display: inline-flex">
          <el-form-item label="打卡时间：" prop="fixTime">
            <span v-if="item.clockTime">{{item.clockTime}}</span>
            <span v-else style="padding: 3px; text-align: center; font-weight: bolder;color: red">未打卡</span>
          </el-form-item>
        </div>
        <div v-if="item.clockAddr && item.clockAddr.addr" style="display: inline-flex">
          <el-form-item label="打卡地点：" prop="fixTime">
            {{item.clockAddr.addr}}，距离指定地点 {{item.clockAddr.range}}（米）
          </el-form-item>
        </div>
        <div v-if="item.clockPhotoUrl">
          <Button class="uni-button" v-if="item.showPhoto" @click="hiddenPhoto(item)">隐藏照片</Button>
          <Button class="uni-button" v-if="!item.showPhoto" @click="showPhoto(item)">查看照片</Button>
          <el-image v-if="item.showPhoto" fit="scale-down" :src="item.showPhotoUrl" style="height:360px;"></el-image>
        </div>
      </div>
    </div>
  </el-card>
  </el-form>
</div>
</template>

<script>
import {getPreviewFileUrl} from '@/api/comapi/comapi';
export default {
  name: "detail",
  props: {
    row: Object,
  },
  watch:{
    row:{
      handler(n,o){ //n为新值,o为旧值;
        this.form = this.row;
        console.log("patrol detail...")
        console.log(this.form)
      },
      immediate: true,
    },
  },
  data() {
    return {
      form:{},
    }
  },
  methods:{
    showPhoto(item){
      this.$set(item,"showPhoto",true);
      if(item.showPhotoUrl){
        console.log(item.showPhotoUrl)
      }else{
        getPreviewFileUrl(item.clockPhotoUrl).then(resp=> {
          console.log("*******  getPreviewFileUrl resp=")
          console.log(resp.data)
          this.$set(item,"showPhotoUrl",resp.data);
        });
      }
    },
    hiddenPhoto(item){
      this.$set(item,"showPhoto",false);
    },

  }
}
</script>

<style scoped>

</style>