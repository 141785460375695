<template>
	<el-form :model="candidate" label-width="130px" ref="form" :disabled="readOnly" >
		<el-row :gutter="24">
			<el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8" class="mb0">
				<el-form-item label="">
					<div style="margin-bottom: 10px; margin-top: 10px; text-align: center">
						<el-upload
							class="avatar-uploader"
							action="uploadPersonalPhoto()"
							v-loading="photoLoading"
							:show-file-list="false"
							:on-success="handleAvatarSuccess"
							:http-request="uploadPersonalPhoto"
							:auto-upload="true"
							:disabled="readOnly"
							accept=".jpg,.jpeg,.png,.bpm,application/pdf"
							:before-upload="beforeAvatarUpload"
						>
							<div v-if="candidate.photo != undefined && candidate.photo != null && candidate.photo">
								<pdf
									v-if="tempPersonalPhotoUrl != null && tempPersonalPhotoUrl.endsWith('.pdf')"
									:src="candidate.photo"
									:page="1"
									class="avatar"
								></pdf>
								<img v-else :src="candidate.photo" class="avatar" />
							</div>

							<i v-else class="el-icon-plus avatar-uploader-icon"></i>
						</el-upload>
						<label style="font-size: large; font-weight: 520px">简历相片</label>
					</div>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
				<el-form-item label="用户名" prop="userName" :rules="[{ required: true, message: '用户名不能为空', trigger: 'blur' }]">
					<el-input v-model="candidate.userName" clearable placeholder="请输入用户名"></el-input>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
				<el-form-item label="密码" prop="password" :rules="[{ required: true, message: '密码不能为空', trigger: 'blur' }]">
					<el-input v-model="candidate.password" type="passowrd" clearable placeholder="请输入密码"></el-input>
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
				<el-form-item label="姓名" prop="name" :rules="[{ required: true, message: '姓名不能为空', trigger: 'blur' }]">
					<el-input v-model="candidate.name" clearable placeholder="请输入姓名"></el-input>
				</el-form-item>
			</el-col>

			<el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
				<el-form-item label="性别" prop="gender" :rules="[{ required: true, message: '性别不为空。', trigger: 'blur' }]">
					<el-select v-model="candidate.gender" placeholder="请选择性别" class="select-datePicker-width">
						<el-option label="男" value="男" />
						<el-option label="女" value="女" />
						<el-option label="未知" value="未知" />
					</el-select>
				</el-form-item>
			</el-col>

			<el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
				<el-form-item
					label="手机号"
					prop="phoneNumber"
					:rules="[
						{ required: true, message: '手机号不能为空', trigger: 'blur' },
						{
							required: false,
							validator: (rule, value, callback) => {
								validatorMobilePhone(rule, value, callback);
							},
							trigger: 'blur',
						},
					]"
				>
					<el-input v-model="candidate.phoneNumber" clearable placeholder="请输入手机号" />
				</el-form-item>
			</el-col>

			<!-- <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8" class="mb0">
					<el-form-item label="出生年月" >
						 <el-date-picker clearable v-model="candidate.birthday" type="date" value-format="yyyy-MM-dd" placeholder="选择身份证记载出生年月">
						</el-date-picker> 
							<el-date-picker clearable v-model="candidate.startWorkDate" type="date" value-format="yyyy-MM-dd" placeholder="请输入开始工作年份">
						</el-date-picker>
					</el-form-item>
			</el-col> -->
			<el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
				<el-form-item label="出生年月" prop="birthday" :rules="[{ required: true, message: '出生年月不为空', trigger: 'blur' }]">
					<el-date-picker
						clearable
						v-model="candidate.birthday"
						class="select-datePicker-width"
						type="date"
						value-format="yyyy-MM-dd"
						placeholder="选择身份证记载出生年月"
					>
					</el-date-picker>
				</el-form-item>
			</el-col>

			<el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
				<el-form-item
					label="身份证号"
					prop="idNumber"
					:rules="[
						{ required: true, message: '身份证号码不能为空', trigger: 'blur' },
						{
							required: true,
							validator: (rule, value, callback) => {
								validateID(rule, value, callback);
							},
							trigger: 'blur',
						},
					]"
				>
					<el-input v-model="candidate.idNumber" clearable placeholder="请输入身份证号" />
				</el-form-item>
			</el-col>

			<el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
				<el-form-item label="婚姻状态" prop="marriageStatus" :rules="[{ required: true, message: '婚姻状态不为空。', trigger: 'blur' }]">
					<el-select v-model="candidate.marriageStatus" placeholder="请选择" class="select-datePicker-width">
						<el-option label="未婚" value="未婚" />
						<el-option label="已婚" value="已婚" />
						<el-option label="离异" value="离异" />
					</el-select>
				</el-form-item>
			</el-col>

			<el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
				<el-form-item label="最后学历" prop="highestDegree" :rules="[{ required: true, message: '最后学历不为空。', trigger: 'blur' }]">
					<el-select v-model="candidate.highestDegree" placeholder="请选择" class="select-datePicker-width">
						<el-option label="博士" value="博士" />
						<el-option label="硕士" value="硕士" />
						<el-option label="本科" value="本科" />
						<el-option label="大专" value="大专" />
						<el-option label="高中" value="高中" />
						<el-option label="中专" value="中专" />
						<el-option label="初中" value="初中" />
						<el-option label="小学" value="小学" />
					</el-select>
				</el-form-item>
			</el-col>

			<el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
				<el-form-item label="政治面貌" prop="politicalStatus" :rules="[{ required: true, message: '政治面貌不为空。', trigger: 'blur' }]">
					<el-input v-model="candidate.politicalStatus" clearable placeholder="请输入政治面貌" />
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
				<el-form-item label="工作年限" prop="workYears" :rules="[{ required: true, message: '工作年限不为空', trigger: 'blur' }]">
					<el-input v-model="candidate.workYears" clearable placeholder="请输入工作年限" />
				</el-form-item>
			</el-col>

			<el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
				<el-form-item label="现居地" prop="livingAddr" :rules="[{ required: true, message: '现居地不为空', trigger: 'blur' }]">
					<el-input v-model="candidate.livingAddr" clearable placeholder="请输入现居地" />
				</el-form-item>
			</el-col>

			<el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
				<el-form-item label="技能特长" prop="skills" :rules="[{ required: true, message: '技能特长不为空', trigger: 'blur' }]">
					<el-input v-model="candidate.skills" clearable placeholder="请输入技能特长" />
				</el-form-item>
			</el-col>

			<el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
				<el-form-item label="兴趣爱好" prop="hobbies" :rules="[{ required: true, message: '兴趣爱好不为空', trigger: 'blur' }]">
					<el-input v-model="candidate.hobbies" clearable placeholder="兴趣爱好" />
				</el-form-item>
			</el-col>
			<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
				<el-form-item label="">
					<el-upload
						class="upload-demo"
						action="uploadAppliantResume()"
						:http-request="uploadAppliantResume"
						:on-preview="handlePreview"
						:on-remove="handleRemove"
						multiple
						:limit="1"
						:on-exceed="handleExceed"
						:before-upload="beforeUpload"
						accept=".docx,.doc,application/pdf"
						:auto-upload="true"
						:file-list="fileList"
					>
						<el-button size="small" type="primary">上传个人简历</el-button>
						<div slot="tip" class="el-upload__tip">支持word、pdf格式,大小不超过20M。</div>
					</el-upload>
				</el-form-item>
			</el-col>
		</el-row>

		<!--工作经验部分-->
		<el-collapse v-model="activeName" aaccordion>
			<el-collapse-item title="工作经历" name="1" class="callpse_header_unfill">
				<div style="height: 15px"></div>
				<el-row v-for="(workExperienceModel, index) in candidate.workExp" :key="index">
					<el-row>
						<el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
							<el-form-item label="工作开始时间">
								<!-- <el-input v-model="workExperienceModel.workStart" placeholder="工作开始时间" /> -->
								<el-date-picker
									clearable
									v-model="workExperienceModel.startDate"
									class="select-datePicker-width"
									type="date"
									value-format="yyyy-MM-dd"
									placeholder="工作开始时间"
								>
								</el-date-picker>
							</el-form-item>
						</el-col>
						<el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
							<el-form-item label="工作结束时间" prop="workExp.workEnd">
								<!-- <el-input v-model="workExperienceModel.workEnd" placeholder="工作结束时间" /> -->
								<el-date-picker
									clearable
									v-model="workExperienceModel.endDate"
									class="select-datePicker-width"
									type="date"
									value-format="yyyy-MM-dd"
									placeholder="工作结束时间"
								>
								</el-date-picker>
							</el-form-item>
						</el-col>
						<el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
							<el-form-item label="工作单位/公司">
								<el-input v-model="workExperienceModel.workUnit" clearable placeholder="工作单位/公司" />
							</el-form-item>
						</el-col>
						<el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
							<el-form-item label="部门">
								<el-input v-model="workExperienceModel.department" clearable placeholder="部门" />
							</el-form-item>
						</el-col>

						<el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
							<el-form-item label="岗位">
								<el-input v-model="workExperienceModel.workPosition" clearable placeholder="岗位" />
							</el-form-item>
						</el-col>
						<el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
							<el-form-item label="证明人">
								<el-input v-model="workExperienceModel.witness" clearable placeholder="证明人" />
							</el-form-item>
						</el-col>

						<el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
							<el-form-item label="工作内容">
								<el-input
									maxlength="500"
									style="width: 100% !important"
									show-word-limit
									rows="4"
									clearable
									type="textarea"
									v-model="workExperienceModel.workContent"
									placeholder="工作内容"
								/>
							</el-form-item>
						</el-col>
					</el-row>
					<div
						style="
							width: 90%;
							border-top: 1px solid;
							color: #ebeef5;
							height: 10px;
							margin-left: 10%;
							border-bottom-width: 1px;
							border-left-width: 1px;
							text-align: center;
						"
					></div>
				</el-row>

				<el-row>
					<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">
						<el-form-item label=" ">
							<el-button v-on:click="addWorkExperience" style="margin-bottom:15px;margin-right:15px;" type="primary" plain icon="el-icon-plus">增加</el-button>
							<el-button v-on:click="deleteWorkExperience" type="primary" style="margin:0px;" plain icon="el-icon-delete">删除</el-button>
						</el-form-item>
					</el-col>
				</el-row>
			</el-collapse-item>
		</el-collapse>
		<!-- 教育 -->
		<el-collapse v-model="activeName" accordion>
			<el-collapse-item title="教育经历" name="1" class="callpse_header_unfill">
				<div style="height: 15px"></div>
				<el-row v-for="(educationExpItem, index) in candidate.educationExp" :key="index">
					<el-row>
						<el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
							<el-form-item label="教育开始时间">
								<!-- <el-input v-model="educationExpItem.eduStart" placeholder="教育开始时间" /> -->
								<el-date-picker
									clearable
									v-model="educationExpItem.startDate"
									type="date"
									class="select-datePicker-width"
									value-format="yyyy-MM-dd"
									placeholder="教育开始时间"
								>
								</el-date-picker>
							</el-form-item>
						</el-col>
						<el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
							<el-form-item label="教育结束时间">
								<!-- <el-input v-model="educationExpItem.eduEnd" placeholder="教育结束时间" /> -->
								<el-date-picker
									clearable
									v-model="educationExpItem.endDate"
									class="select-datePicker-width"
									type="date"
									value-format="yyyy-MM-dd"
									placeholder="教育结束时间"
								>
								</el-date-picker>
							</el-form-item>
						</el-col>
						<el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
							<el-form-item label="毕业学校">
								<el-input v-model="educationExpItem.school" clearable placeholder="毕业学校" />
							</el-form-item>
						</el-col>
						<el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
							<el-form-item label="专业">
								<el-input v-model="educationExpItem.major" clearable placeholder="专业" />
							</el-form-item>
						</el-col>
						<el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
							<el-form-item label="学历">
								<el-input v-model="educationExpItem.academic" clearable placeholder="学历" />
							</el-form-item>
						</el-col>
						<el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
							<el-form-item label="学位">
								<el-input v-model="educationExpItem.degree" clearable placeholder="学位" />
							</el-form-item>
						</el-col>
					</el-row>
					<div
						style="
							width: 90%;
							border-top: 1px solid;
							color: #ebeef5;
							height: 10px;
							margin-left: 10%;
							border-bottom-width: 1px;
							border-left-width: 1px;
							text-align: center;
						"
					></div>
				</el-row>
				<el-row>
					<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">
						<el-form-item label=" ">
							<el-button v-on:click="addEduExperience" type="primary" style="margin-bottom:15px;margin-right:15px;"  plain icon="el-icon-plus">增加</el-button>
							<el-button v-on:click="deleteEduExperience" type="primary" style="margin:0px;"  plain icon="el-icon-delete">删除</el-button>
						</el-form-item>
					</el-col>
				</el-row>
			</el-collapse-item>
		</el-collapse>
		<!--
			<el-row>
				<el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
					<el-form-item label="面试时间" prop="reviewTime" :rules="[{ required: true, message: '面试时间不为空', trigger: 'blur' }]">
						<el-input v-model="reviewTime"  placeholder="面试时间" />
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
					<el-form-item label="面试地点" prop="reviewPlace" :rules="[{ required: true, message: '面试地点不为空', trigger: 'blur' }]">
						<el-input v-model="reviewPlace"  placeholder="面试地点" />
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
					<el-form-item label="面试人" prop="reviewPerson" :rules="[{ required: true, message: '面试人不为空', trigger: 'blur' }]">
						<el-input v-model="reviewPerson"  placeholder="面试人" />
					</el-form-item>
				</el-col>
			</el-row>


			<el-row>
				<el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
					<el-form-item label="结果" prop="reviewResult" :rules="[{ required: true, message: '结果不为空', trigger: 'blur' }]">
						<el-input v-model="reviewResult"  placeholder="结果" />
					</el-form-item>
				</el-col>
			</el-row>


			<el-row>
				<el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
					<el-form-item label="背调时间" prop="degree" :rules="[{ required: true, message: '背调时间不为空', trigger: 'blur' }]">
						<el-input v-model="degree"  placeholder="背调时间" />
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
					<el-form-item label="背调结果" prop="time" :rules="[{ required: true, message: '背调结果不为空', trigger: 'blur' }]">
						<el-input v-model="time"  placeholder="背调结果" />
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
					<el-form-item label="背调员" prop="bgcPerson" :rules="[{ required: true, message: '背调员不为空', trigger: 'blur' }]">
						<el-input v-model="bgcPerson"  placeholder="背调员" />
					</el-form-item>
				</el-col>
			</el-row>
			-->

		<!-- <el-row style="margin-top: 15px">
			<el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
				<el-form-item label="面试结果" prop="status">
					<el-select v-model="candidate.status" placeholder="请选择结果">
						<el-option label="录用" value="录用" />
						<el-option label="未录用" value="未录用" />
						<el-option label="其他" value="其他" />
					</el-select>
				</el-form-item>
			</el-col>
		</el-row> -->

		<el-row>
			<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0 mt8">
				<el-form-item label=" ">
					<el-button v-if="!Register" v-on:click="submitResume" :loading="addLoading" plain type="primary"
						>提交</el-button
					>
					<el-button v-else v-on:click="updateResume" :loading="updateLoading" plain type="primary"
						>更新</el-button
					>
					<el-button plain type="primary" @click="close">关闭</el-button>
				</el-form-item>
			</el-col>
		</el-row>
	</el-form>
</template>

<script>
import { addResume, findOne, editResume, uploadPersonalPhoto, uploadResume } from '@/api/recruit/register';
import { UploadPersonalPicture, downloadPersonalPhoto } from '@/api/employ/employ';
import Pdf from 'vue-pdf';
export default {
	components: {
		Pdf,
	},
	name: 'EhrWebVue2ResumeRegister',

	data() {
		let resumeInit = null;
		if (this.RowData == null || this.RowData == undefined) {
			resumeInit = {
				name: '',
				gender: '',
				telephoneNumber: '',
				idNumber: '',
				marriageStatus: '',
				politicStatus: '',
				birthday: '',
				startWorkDate: '',
				livingAddr: '',
				skills: '',
				hobbies: '',
				resumeURL: '',
				workExp: [
					{
						workStart: '',
						workEnd: '',
						workUnit: '',
						department: '',
						workPosition: '',
						witness: '',
						workContent: '',
					},
				],
				educationExp: [
					{
						eduStart: '',
						eduEnd: '',
						school: '',
						major: '',
						academic: '',
						degree: '',
					},
				],

				status: '',
				photo: '',
			};
		} else {
			resumeInit = this.RowData;
		}
		return {
			candidate: resumeInit,
			addLoading: false,
			updateLoading: false,
			activeName: '1',
			readOnly: this.ReadOnly,
			photoLoading: false,
			loading: false,
			formData: null,
			tempPersonalPhotoUrl: '',
			fileList: [],
		};
	},
	props: { ReadOnly: Boolean, RowData: Object,Register:Boolean },
	created() {
		if (this.RowData != null) {
			findOne(this.RowData.id)
				.then((response) => {
					this.candidate = response.data;
					this.downloadPersonalPhoto();
					if (this.candidate.resumeURL) {
						this.fileList.push({ name: this.candidate.resumeOrginalName, url: this.candidate.resumeURL });
					}
				})
				.catch((err) => {
					this.msgError('获取详细简历失败，' + err);
				});
		}
	},
	mounted() {},

	methods: {
		updateResume() {
			let that = this;
			this.updateLoading = true;
			this.$refs['form'].validate((valid) => {
				if (valid) {
					let tempPhoto = that.candidate.photo; // save blob file
					that.candidate.photo = that.tempPersonalPhotoUrl;
					editResume(that.candidate)
						.then((response) => {
							that.updateLoading = false;
							that.tempPersonalPhotoUrl = that.candidate.photo;
							that.candidate.photo = tempPhoto;
							that.msgSuccess('更新成功。');
						})
						.catch((err) => {
							that.updateLoading = false;
							that.tempPersonalPhotoUrl = that.candidate.photo;
							that.candidate.photo = tempPhoto;
							that.msgError('更新失败，' + err);
						});
				} else {
					that.updateLoading = false;
					that.msgError('有非法输入或必填项未填写，请检查!');
				}
			});
		},
		submitResume() {
			let that = this;
			
			this.$refs['form'].validate((valid) => {
				if (valid) {
					let tempPhoto = that.candidate.photo; // save blob file
					that.candidate.photo = that.tempPersonalPhotoUrl;
					that.addLoading = true;
					addResume(that.candidate)
						.then((response) => {
							that.addLoading = false;
							that.tempPersonalPhotoUrl = that.candidate.photo;
							that.candidate.photo = tempPhoto;
							that.msgSuccess('注册成功。');
						})
						.catch((err) => {
							that.addLoading = false;
							that.tempPersonalPhotoUrl = that.candidate.photo;
							that.candidate.photo = tempPhoto;
							that.msgError('注册失败，' + err);
						});
				} else {
					that.addLoading = false;
					that.msgError('有非法输入或必填项未填写，请检查!');
				}
			});
		},
		addWorkExperience() {
			this.candidate.workExp.push({
				workStart: '',
				workEnd: '',
				workUnit: '',
				department: '',
				workPosition: '',
				witness: '',
				workContent: '',
			});
		},
		deleteWorkExperience() {
			if (this.candidate.workExp.length > 1) {
				this.candidate.workExp.pop();
			} else {
				this.msgError('只有一个了，不能再删除了哟！');
			}
		},
		addEduExperience() {
			this.candidate.educationExp.push({
				eduStart: '',
				eduEnd: '',
				school: '',
				major: '',
				academic: '',
				degree: '',
			});
		},
		deleteEduExperience() {
			if (this.candidate.educationExp.length > 1) {
				this.candidate.educationExp.pop();
			} else {
				this.msgError('只有一个了，不能再删除了哟！');
			}
		},

		uploadPersonalPhoto(params) {
			this.formData = new FormData();
			if (this.candidate.id == undefined || this.candidate.id == null || this.candidate.id == '') {
				this.formData = new FormData();
				this.formData.append('file', params.file);
				let self = this;
				self.photoLoading=true;
				UploadPersonalPicture(this.formData)
					.then((response) => {
						self.photoLoading=false;
						if (response.status == 200) {
							let resData = response.data;
							self.tempPersonalPhotoUrl = response.headers['content-disposition'].split('=')[1];
							var blob = new Blob([resData], { type: 'application/octet-stream' });
							self.candidate.photo = URL.createObjectURL(blob);
							this.$forceUpdate();
							this.$message.success('上传成功!');
						} else {
							this.$message.error('上传失败：');
						}
					})
					.catch((err) => {
						self.photoLoading=false;
						this.$message.error('上传失败：' + err);
					});
			} else {
				this.formData.append('id', this.candidate.id);
				this.formData.append('file', params.file);

				this.loading = true;
				let self = this;
				self.photoLoading=true;
				uploadPersonalPhoto(this.formData)
					.then((response) => {
						self.photoLoading = false;
						if (response.status == 200) {
							let resData = response.data;
							self.tempPersonalPhotoUrl = response.headers['content-disposition'].split('=')[1];
							var blob = new Blob([resData], { type: 'application/octet-stream' });
							self.candidate.photo = URL.createObjectURL(blob);
							this.$forceUpdate();
							this.msgSuccess('上传成功!');
						} else {
							this.$message.error('上传失败：');
						}
					})
					.catch((err) => {
						self.photoLoading = false;
						this.$message.error('上传失败：' + err);
					});
			}
		},

		handleAvatarSuccess(res, file) {
			//this.form.photo = URL.createObjectURL(file.name);
		},
		beforeAvatarUpload(file) {
			const isJPG = file.type === 'image/jpeg' || file.type === 'image/jpg';
			const isPng = file.type === 'image/png';
			const isBmp = file.type === 'application/bmp';
			const ispdf = file.type === 'application/pdf';
			const isLt50M = file.size / 1024 / 1024 < 50;
			if (!isJPG && !isPng && !ispdf && !isBmp) {
				this.$message.error('上传图片只能是 JPG,JPEG,PNG,BMP,PDF 格式!');
			}
			if (!isLt50M) {
				this.$message.error('上传头像图片大小不能超过 50MB!');
			}
			return (isJPG || isPng || ispdf || isBmp) && isLt50M;
		},
		downloadPersonalPhoto() {
			if (this.candidate.photo != null && this.candidate.photo.length > 0) {
				// download
				this.tempPersonalPhotoUrl = this.candidate.photo;
				this.photoLoading = true;
				downloadPersonalPhoto(this.candidate.photo)
					.then((res) => {
						this.photoLoading = false;
						if (res) {
							let resData = res.data;
							var blob = new Blob([resData], { type: 'application/octet-stream' });
							this.candidate.photo = URL.createObjectURL(blob);
						} else {
							this.msgError(`后端下载文件出错 & ${e}`);
						}
					})
					.catch((e) => {
						this.photoLoading = false;
						this.msgError(`后端下载文件出错 & ${e}`);
					});
			} else {
				this.candidate.photo = null;
			}
		},

		close() {
			this.$emit('close');
		},

		handleExceed(files, fileList) {
			this.msgError(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件.`);
		},
		handleRemove() {},
		handleChange(file, fileList) {
			//this.currentContract.contractPictures = fileList;
			// 非自动上传，before-upload 不起作用，改到这里验证。
			let isValidFile = this.beforeUpload(file);
			if (!isValidFile) {
				this.$refs.upload.handleRemove(file);
			}
		},
		beforeUpload(file) {
			var fileType = file.name.substring(file.name.lastIndexOf('.') + 1);
			const doc = fileType === 'doc' || fileType === 'DOC' || fileType == 'docx' || fileType === 'DOCX';
			const pdf = fileType === 'pdf' || fileType === 'PDF';
			const isLt5M = file.size / 1024 / 1024 < 5;
			const isEmpty = file.size === 0;
			if (!doc && !pdf) {
				this.msgError('上传文件只能是doc,docx,pdf格式!');
			}
			if (!isLt5M) {
				this.msgError('上传文件大小不能超过 5MB!');
			}
			if (isEmpty) {
				this.msgError('上传文件大小不能为空文件!');
			}
			return (doc || pdf) && isLt5M && !isEmpty;
		},
		handlePreview() {},
		uploadAppliantResume(params) {
			if (params != undefined) {
				this.formData = new FormData();
				this.formData.append('file', params.file);
				let self = this;
				uploadResume(this.formData)
					.then((response) => {
						if (response.status == 200) {
							let resData = response.data;
							this.candidate.resumeURL = response.headers['content-disposition'].split('=')[1];
							this.candidate.resumeOrginalName = params.file.name;
							this.$forceUpdate();
							this.$message.success('上传成功!');
						} else {
							this.$message.error('上传失败：');
						}
					})
					.catch((err) => {
						this.$message.error('上传失败：' + err);
					});
			}
		},

		validateID(rule, value, callback) {
			// 身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X
			let reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
			if (value === '' || value == null) {
				callback(new Error('证件号码不能为空'));
			} else {
				if (!reg.test(value)) {
					callback(new Error('身份证号码不正确'));
				} else {
					//this.analysisID(value);
					callback();
				}
			}
		},
		validatorMobilePhone(rule, value, callback) {
			if (value === '' || value == null) {
				callback(new Error('手机号不能为空'));
				//callback();
			} else if (!/^((13[0-9])|(14[5|7])|(15([0-3]|[5-9]))|(18[0,5-9]))\d{8}$/.test(value)) {
				callback(new Error('手机号格式错误，11位数字。'));
			} else {
				callback();
			}
		},
	},
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 768px) {
	.select-datePicker-width {
		width:100% !important;
	}
}
</style>

<style lang="less" scoped>
 @media screen and (max-width: 576px) {
	/deep/.el-date-editor.el-input, .el-date-editor.el-input__inner{
		width:100%
	}
}

</style>