<template>
  <div>
    <el-form ref="form" :model="form" class="employ" label-width="100px">
      <el-row>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">
          <el-form-item label="工资月份" prop="salaryDate"
            :rules="{ required: true, message: '工资月份不能为空', trigger: 'blur' }">
            <el-date-picker v-model="form.salaryDate" style="width:100%;" type="month" value-format="yyyy-MM-dd" placeholder="选择月" />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">
          <el-form-item label="">
            <el-radio-group v-model="form.empRange">
              <el-radio label="company">公司</el-radio>
              <el-radio label="department">部门</el-radio>
              <el-radio label="employ">员工</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">
          <el-form-item v-show="'company' == form.empRange" label="公司" prop="companyId">
            <treeselect v-model="form.companyId"  :options="companyOptions" :multiple="true" :flat="true"
              value-consists-of="ALL" :normalizer="normalizerCompany" placeholder="请选择公司" />
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">
          <el-form-item v-show="'department' == form.empRange" label="部门" prop="departmentId">
            <treeselect v-model="form.departmentId"  :options="departmentOptions" :multiple="true"
              :flat="true" value-consists-of="ALL" :normalizer="normalizerCompanyDepartment" placeholder="请选择部门" />
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">
          <el-form-item v-show="'employ' == form.empRange" label="员工" prop="employ">
            <treeselect ref="treeRef"  v-model="form.employ" :options="employTree" :limit="5"
              :limitText="count => `及其它${count}人`" @input="inputChange" :multiple="true" noChildrenText="没有员工"
              value-consists-of="LEAF_PRIORITY" :normalizer="normalizeNode" placeholder="请选择人员" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-row></el-row>
    <div slot="footer" class="dialog-footer" style="text-align: center;margin-top: 68px">
      <el-button type="primary" @click="submitForm" v-permission="['/cb/cbSalary/add']">确 定</el-button>
      <el-button @click="cancel">取 消</el-button>
    </div>
  </div>
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import permission from '@/directive/permission/index.js' // 权限判断指令
import { genSalary } from "@/api/cb/cbSalary";
import { getOrgEmpTree, normalizerEmployMultiSelectTree } from '@/views/pages/employ/components/empOrgTree';
import { showLoading, hideLoading } from "@/utils/reqloading";

export default {
  name: "SalaryGen",
  components: {
    Treeselect,
  },
  props: {
    // 公司树选项
    companyList: Array,
    companyOptions: Array,
    departmentList: Array,
    departmentOptions: Array,
  },
  directives: { permission },
  data() {
    return {
      treeData: [],
      employTree: [],
      employList: [],
      companyId: 1,
      // companyList: [],
      // // 公司树选项
      // companyOptions: [],
      // 部门管理树选项
      // departmentList: [],
      // departmentOptions: [],
      // positionList: [],
      // positionOptions: [],
      form: {
        empRange: 'company',
        employ: null,
        companyId: null,
        departmentId: null,
      },
    }
  },
  created() {
    console.log(this.companyOptions);
    this.companyId = this.$store.getters.userInfo.companyId;
    showLoading();
    getOrgEmpTree(this.companyId, true, (tree, companyList, departmentList, employList) => {
      this.employTree = tree;
      console.log('this.employTree');
      console.log(this.employTree);
      // this.departmentList = departmentList;
      // this.departmentOptions = this.handleTree(departmentList, "id", "pid");
      //
      // this.companyOptions = [];
      // this.companyOptions = this.handleTree(companyList, "id", "pid");

      this.employList = employList;
      setTimeout(() => {
        this.inputChange(this.form.employ);
        hideLoading();
      }, 500);
    });

  },
  methods: {
    /** 转换公司数据结构 */
    normalizerCompany(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.id,
        label: node.companyName,
        children: node.children
      };
    },
    /** 提交按钮 */
    submitForm() {
      console.log("this.form");
      console.log(this.form);
      this.$refs["form"].validate(valid => {
        if (valid) {
          let data = {};
          if ('company' == this.form.empRange) {
            data = { salaryDate: this.form.salaryDate, range: this.form.empRange, genCompanyIds: this.form.companyId }
          } else if ('department' == this.form.empRange) {
            let departmentIds = [];
            if (this.form.departmentId && this.form.departmentId.length > 0) {
              this.form.departmentId.forEach(item => {
                departmentIds.push(item.substring("department".length, item.length))
              })
            } else {
              this.msgError("没有选择部门")
              return;
            }
            data = { salaryDate: this.form.salaryDate, range: this.form.empRange, genDepartmentId: departmentIds }
          } else if ('employ' == this.form.empRange) {
            let employIds = [];
            if (this.form.employ && this.form.employ.length > 0) {
              // this.form.departmentId.forEach(item=>{
              //   employIds.push(item.substring("department".length,item.length))
              // })
            } else {
              this.msgError("没有选择员工")
              return;
            }
            data = { salaryDate: this.form.salaryDate, range: this.form.empRange, genEmployId: this.form.employ }
          }


          // let data = {salaryDate: this.form.salaryDate, range:this.form.empRange, genCompanyIds: this.form.companyId,
          //   genDepartmentId: this.form.departmentId, genEmployId: this.form.employ}
          console.log(data);
          showLoading();
          genSalary(data).then(response => {
            this.msgSuccess("生成成功");
            hideLoading()
            this.$emit("close", { refresh: true, isSuccess: true });
          });
          // if (this.form.id != null) {
          //   updateCbSalary(this.form).then(response => {
          //     this.msgSuccess("修改成功");
          //     this.open = false;
          //     this.getList();
          //   });
          // } else {
          //   addCbSalary(this.form).then(response => {
          //     this.msgSuccess("新增成功");
          //     this.open = false;
          //     this.getList();
          //   });
          // }
        }
      });
    },
    // 取消按钮
    cancel() {
      this.$emit("close", { refresh: false, isSuccess: true });
    },
    /** 转换公司数据结构 */
    normalizerCompanyDepartment(node) {
      if (node.className == 'company') {
        return {
          id: node['treeId'],
          label: node['companyName'], //node['id'] + " - " +node['companyName'],
          children: node['children'],
          isDisabled: true,
        };
      } else if (node.className == 'department') {
        return {
          id: node['treeId'],
          label: node['name'],
          children: node['children']
        };
      }
    },
    normalizeNode(node) {
      return normalizerEmployMultiSelectTree(node, "id");
    },

    inputChange(val, instanceId) {
      console.log(val)
      if (!val) return;
      this.$emit("change", val);
      if (this.multiple) {//只有多选模式下才考虑提示功能
        this.allLabel = val.map(item => {
          let label = "";
          //getNode是我自己查找下拉树的内置方法，呕心沥血才找到的
          // console.log(this.$refs["treeRef"]);
          label = this.$refs["treeRef"].getNode(this.valueFormat == "object" ? item.id : item).label;
          label = label.replace('(unknown)', "(外部)");
          return label;
        })
        let el = this.$el.querySelector(".vue-treeselect__multi-value");
        el.setAttribute("title", this.allLabel.join(", "));
      } else {
        this.removePlaceHolder();
      }
      this.addPlaceHolder(val);
    },
    //增加文字提示tooltip
    addPlaceHolder(value) {
      console.log("addPlaceHolder......")
      console.log(value)
      let placeholder = this.$el.querySelector(".vue-treeselect__placeholder");
      let temp = value !== "_first" ? value : this.value;
      if (placeholder && (!temp || !temp.length)) {
        let content = placeholder.innerText;
        placeholder.parentNode.setAttribute("title", content);
      }
    },
    removePlaceHolder() {
      let placeholder = this.$el.querySelector(".vue-treeselect__placeholder");
      if (placeholder) {
        placeholder.parentNode.removeAttribute("title");
      }
    },

  },
}
</script>

<style lang="less" scoped>
.enlong {
  display: block !important;
}
</style>
