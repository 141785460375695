<template>
<div>
  <h3 style="text-align: center">{{clockDetailData.empName}}， {{clockDetailData.clockDate}}打卡详情</h3>
  <div style="text-align: center">{{clockDetailData.attClassName}}，
    <span v-if="clockDetailData.attType=='旷工'||clockDetailData.attType=='打卡异常'" style="color: #ba0427">{{clockDetailData.attType}}</span>
    <span v-else>{{clockDetailData.attType}}</span>
    ，考勤{{clockDetailData.days}}天，出勤{{clockDetailData.clockDays}}天</div>
  <div style="text-align: center">&nbsp;</div>
  <h3 style="text-align: center">考勤打卡时间</h3>
  <el-row :key="'row'+t" v-for="t in clockDetailData.times">
    <el-col :xs="24" :sm="8" :md="4" :lg="6" :xl="6">上班时间{{t>1?t:''}}：{{clockDetailData.clockSetting["clockOn"+t]}}</el-col>
    <el-col :xs="24" :sm="16" :md="8" :lg="6" :xl="6">上班打卡{{t>1?t:''}}：{{clockDetailData.clockTime["clockTimeOn"+t]}} </el-col>
    <el-col :xs="24" :sm="8" :md="4" :lg="6" :xl="6">
      下班时间{{t>1?t:''}}：{{clockDetailData.clockSetting["clockOffOver"+t]?"次日":""}}{{clockDetailData.clockSetting["clockOff"+t]}}</el-col>
    <el-col :xs="24" :sm="16" :md="8" :lg="6" :xl="6">下班打卡{{t>1?t:''}}：{{clockDetailData.clockTime["clockTimeOff"+t]}} </el-col>
  </el-row>
  <h3 style="text-align: center">打卡照片</h3>
  <el-row>
    <el-col v-for="photo in photoUrl" :key="photo.url" :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
      <el-image style="width: 100px; height: 100px" :src="photo.url" :preview-src-list="srcList">
      </el-image>
      <div>打卡时间：{{photo.clockTime}}</div>
    </el-col>
  </el-row>
  <div style="text-align: center">&nbsp;</div>
  <h3 style="text-align: center">当天打卡时间段内记录（超过时间段记录忽略）</h3>
  <ul>
    <li :key="item.id" v-for="item in clockRecords">{{item.clockTime}}</li>
  </ul>
</div>
</template>

<script>
import { listAttClockTime} from "@/api/attendance/attClockTime";
import {formatDate} from "../../../../utils/formatTime";
import {getPreviewFileUrl} from "@/api/comapi/comapi";

export default {
  name: "clockDetail",
  props: {
    clockDetailData: Object,
  },
  computed: {
    srcList(){
      let urlList = [];
      this.photoUrl.forEach(item=>{
        urlList.push(item.url);
      })
      console.log("srcList")
      console.log(urlList)
      return urlList;
    }
  },
  watch: {
    clockDetailData(){
      console.log("clockDetailData changed.");
      this.getClockRecords();
    }
  },
  data() {
    return {
      clockRecords: [],
      photoUrl: [],
    }
  },
  mounted() {
    console.log("props :")
    console.log(this.clockDetailData);
    this.photoUrl = [];
    this.clockRecords = [],
    this.getClockRecords();
    this.getPhotoPreviewUrl();
  },
  methods: {
    getClockRecords(){
      let filter = {};
      let startTimeStr = this.clockDetailData.clockDate + " " + this.clockDetailData.clockSetting["clockOn1"]+":00";
      let endTimeStr = this.clockDetailData.clockDate + " " + this.clockDetailData.clockSetting["clockOff"+this.clockDetailData.times]+":00";
      let startTime = new Date(startTimeStr);
      let endTime = new Date(endTimeStr);
      console.log(startTime)
      console.log(endTime)
      console.log(this.clockDetailData.clockOnBegin)
      let l = Number(startTime.getTime()) - Number(this.clockDetailData.clockOnBegin)*1000*60;
      console.log(l)
      startTime = new Date(l);
      l = Number(endTime.getTime()) + Number(this.clockDetailData.clockOffEnd)*1000*60
      endTime = new Date(l)
      console.log(startTime)
      console.log(endTime)
      startTimeStr = formatDate(startTime,"YYYY-mm-dd HH:MM:SS");
      endTimeStr = formatDate(endTime,"YYYY-mm-dd HH:MM:SS");
      filter['clockTime'] = {
        type: 'timestamp',
        value: [startTimeStr,endTimeStr],
      }
      filter['empId'] = {
        type: 'eq',
        value: this.clockDetailData.empId,
      }
      const params = this.getQueryParam(filter, 1, 30, ' clockTime ');
      listAttClockTime(params).then(response => {
        this.clockRecords = response.data.records;
        this.total = response.data.total;
      });

    },
    getPhotoPreviewUrl(){
      console.log("watch photoAddr before:")
      let setting = this.clockDetailData.clockSetting;
      console.log(setting)
      let photoUrl = [];
      let promises = [];
      if(this.clockDetailData.times&&this.clockDetailData.times>0){
        for(let i = 0; i < this.clockDetailData.times; i++){
          let t = i+1;
          let urlOn = this.clockDetailData.clockTime["clockTimeOn"+t+"photoAddr"];
          if(urlOn){
            promises.push(getPreviewFileUrl(urlOn).then(resp=>{
              let clockTimeOn = this.clockDetailData.clockTime["clockTimeOn"+t];
              photoUrl.push({clockTime:clockTimeOn,type:"On", t:t,url:resp.data,realUrl:urlOn})
            }))
          }
          let urlOff = this.clockDetailData.clockTime["clockTimeOff"+t+"photoAddr"];
          if(urlOff){
            promises.push(getPreviewFileUrl(urlOff).then(resp=>{
              let clockTimeOff = this.clockDetailData.clockTime["clockTimeOff"+t];
              photoUrl.push({clockTime:clockTimeOff,type:"Off", t:t,url:resp.data,realUrl:urlOff})
            }))
          }
        }
        // this.form.photoAddr.forEach(item=>{
        //   promises.push(getPreviewFileUrl(item.url).then(resp=>{
        //     photoUrl.push({name:item.name,url:resp.data, approved:item.approved,realUrl:item.url})
        //   }))
        // })
      }
      // 等所有返回
      Promise.all(promises).then(()=>{
        this.photoUrl = photoUrl;
        console.log("watch form.photoAddr")
        console.log(this.photoUrl)
      })
    },

  },
}
</script>

<style scoped>

</style>
