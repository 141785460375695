import {listCompany, getCompany} from "@/api/org/company";
import {listDepartment } from "@/api/org/department";
import {listSimpleEmploy} from "@/api/employ/employ";
import {handleTree, parseFilters} from "@/utils/tools";
import {PARENT_COMPANY_ID} from "@/settings"
import { showLoading, hideLoading } from '@/utils/reqloading';

function addToChild(parentTree, id, item,  parentId, children, className, parentClass){
    id = id || 'id';
    parentId = parentId || 'parentId';
    children = children || 'children'
    className = className || 'className';
    parentClass = parentClass || 'company';
    // if(item.name=='米色么日莫'){
    //     console.log("米色么日莫");
    // }
    for(var i = 0; i < parentTree.length; i++){
        let parent = parentTree[i];
        if( (parent[id] === item[parentId]) && (parent[className] === parentClass) ){
            if(!parent[children]){
                parent[children] = []
            }
            parent[children].push(item);
            // console.log("add child-"+item.name +" to "+parent.name);
            return true;
        }else{
            if(parent[children]){
                if(addToChild(parent[children],id,item,parentId,children,className,parentClass)){
                    return true;
                }
            }
        }
    }
    // parentTree.forEach(parent =>{
    // });
    return false;
}

function addTreePid(node, children){
    children = children || 'children'
    if(node[children]){
        node[children].forEach(child =>{
            if(node.className=="employ"){
                // console.log(node);
            }
            child.treePid = node.treeId;
            addTreePid(child);
        })
    }
}

//移除id 已经在tree中不存在的unkown员工，例如已经离职的员工
export function checkTreeIdsExist(tree, ids, checkedIds){
    if(tree && tree.length>0){
        tree.forEach(item=>{
            if( "employ" == item.className){
                if(ids && ids.length>0 && ids.indexOf(item.id)>=0){
                    // console.log(item)
                    checkedIds.push(item.id);
                }
            }
            checkTreeIdsExist(item.children, ids, checkedIds);
        });
    }
}

export function disableChildNodeByClassAndIds(node, children, nodeClass, ids){
    children = children || 'children'
    if(node[children]){
        node[children].forEach(child =>{
            if(nodeClass == child.className && ids.indexOf(child.id) >= 0){
                child.disabled = true;
            }
            disableChildNodeByClassAndIds(child);
        })
    }
}

export function getLeafList(list, nodes, children){
    children = children || 'children'
    if(nodes) {
        nodes.forEach(item => {
            if(item.className == 'employ'){
                let receive = {id:item.userId, name:item.name};
                list.push(receive);
            }else if(item[children]){
                getLeafList(list, item[children],children);
            }else{
                return;
            }
        })
    }
}
export function getOrgTree(companyId, hasEmploy, callback){
    let companyList = [];
    let companyTree = [];
    let departmentList = [];
    let departmentTree = [];
    let employList = [];
    showLoading();
    const params = {
        pageNum: 1,
        pageSize: 10000,
        where: {},
        order: '',
    }
    console.log("this.PARENT_COMPANY_ID="+PARENT_COMPANY_ID);
    let rtCompany = null;
    // if(companyId == PARENT_COMPANY_ID){
    //     rtCompany = listCompany(params).then(response => {
    //         if(response.data.records){
    //             companyList = response.data.records;
    //             companyList.forEach(item=>{
    //                 item.name = item.companyName
    //                 item.className = item.classType;
    //                 item.treeId = item.classType+item.id;
    //                 item.nodeContent = "共"+(item.realCount || '0')+"人";
    //             });
    //             // console.log("return company");
    //         }
    //         return response;
    //     });
    // }else{
    rtCompany = getCompany(companyId).then(response => {
        if(response.data){
            const comp = response.data;
            comp.name = comp.companyName
            comp.className = comp.classType;
            comp.treeId = comp.classType+comp.id;
            comp.nodeContent = "法人："+(comp.register || '')+"";
            companyList.push(comp);
        }
        return response;
    });
    let filter = {};
    filter['companyId'] = {
        type: 'eq',
        value: companyId,
    }
	
    const filters = parseFilters(filter);
    params.where = {};
    if (filters && filters.length > 0) {
        params.where.filters = filters
    } else {
        // 如果过滤条件为空，设置空数组
        params.where.filters = []
    }
    // }
    const rtDepartment = listDepartment(params).then(response => {
        if(response.data.records) {
            departmentList = response.data.records;
            departmentList.forEach(item=> {
                item.className = item.classType
                item.treeId = item.classType+item.id;
                item.nodeContent = "管理岗："+(item.managerPostName || '')+"";
            });
            // console.log("return rtDepartment");
        }
        return response;
    });
    if(hasEmploy){
		params.where.filters.push({name: 'empStatus', type: 'notIn', values: ['离职','离退休','离岗','黑名单']})
        const rtEmploy = listSimpleEmploy(params).then(response => {
            employList = response.data.records;
            employList.forEach(item=> {
                item.className = 'employ';
                item.treeId = item.className+item.id;
                item.nodeContent = "共"+(item.realCount || '0')+"人";
            });
            // console.log("return rtPostion");
            return response;
        });
        Promise.all([rtCompany,rtDepartment,rtEmploy]).then(()=>{
            console.log("all return ");
            companyTree = handleTree(companyList, "id", "parentId");
            departmentTree = handleTree(departmentList, "id", "pid");
            // 添加岗位到部门
            // console.log("add position to department...")
            employList.forEach(emp=>{
                // console.log(pos)
                addToChild(departmentTree,"id",emp,"departmentId",'children','className','department');
            });
            // console.log("departmentTree list:");
            departmentTree.forEach(dep=>{
                // console.log(dep)
                addToChild(companyTree,"id",dep,"companyId", 'children','className','company');
            })
            // console.log("department added to company");
            companyTree.forEach(comp=>{
                addTreePid(comp);
            });
            // return Promise.resolve(companyTree);
            callback(companyTree, companyList, departmentList, employList);
            hideLoading();
        });
    }else{
        Promise.all([rtCompany,rtDepartment]).then(()=>{
            console.log("all return ");
            companyTree = handleTree(companyList, "id", "parentId");
            departmentTree = handleTree(departmentList, "id", "pid");
            // 添加岗位到部门
            // console.log("add position to department...")
            // console.log("departmentTree list:");
            departmentTree.forEach(dep=>{
                // console.log(dep)
                addToChild(companyTree,"id",dep,"companyId", 'children','className','company');
            })
            // console.log("department added to company");
            companyTree.forEach(comp=>{
                addTreePid(comp);
            });
            // return Promise.resolve(companyTree);
            callback(companyTree, companyList, departmentList, employList);
            hideLoading();
        });
    }

}


export function getOrgTreeWithData(companyList, departmentList, employList){
    let companyTree = handleTree(companyList, "id", "parentId");
    let departmentTree = handleTree(departmentList, "id", "pid");
    // 添加岗位到部门
    // console.log("add position to department...")
    if(employList){
        employList.forEach(emp=>{
            // console.log(pos)
            addToChild(departmentTree,"id",emp,"departmentId",'children','className','department');
        });
    }
    // console.log("departmentTree list:");
    departmentTree.forEach(dep=>{
        // console.log(dep)
        addToChild(companyTree,"id",dep,"companyId",'children','className','company');
    })
    // console.log("department added to company");
    companyTree.forEach(comp=>{
        addTreePid(comp);
    });
    return companyTree;
}

export function getOrgEmpTree(companyId, isOnlyCurCompany, callback){
    getOrgTree(companyId,true, callback);

}

export function normalizerEmployMultiSelectTree(node, employIdUserId){
    let userId = employIdUserId || 'userId'
    if("company" == node.className){
        return {
            id: node.treeId,
            label: node.companyName,
            children: node.children
        };
    }else if("department" == node.className ){
        return {
            id: node.treeId,
            label: node.name,
            children: node.children?node.children:[],
            isDisabled: (node.children && node.children.length > 0)? false: true,
        };
    }else if("employ" == node.className ){
        return {
            id: node[userId],
            label: node.name,
        };
    }
}
