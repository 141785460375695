<template>
  <div>
    <el-form ref="form" :model="form" :rules="rules" label-width="118px">
      <el-row :gutter="35">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" >
          <el-form-item label="名称说明" prop="name">
            <el-input v-model="form.name" placeholder="请输入名称说明"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="35">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" >
            <el-form-item label="选择巡逻员" prop="empId" :rules="{ required: true, message: '必须选择员工', trigger: ['blur', 'change'] }">
              <el-select style="width: 100%" v-model="form.empId" filterable remote reserve-keyword placeholder="请输入员工姓名" :remote-method="searchUpdatedEmploy"
                         :loading="loading" >
                <el-option
                    v-for="item in updatedEmploy"
                    :key="item.id"
                    :label="item.name+'('+item.idNum+')'"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="35">
        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8" class="mb20">
          <el-form-item label="打卡人脸认证" prop="photo">
            <el-checkbox v-model="form.face">是否人脸认证</el-checkbox>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="35">
        <el-col v-if="form.face" :xs="24" :sm="8" :md="8" :lg="8" :xl="8" class="mb20">
          <el-form-item label="人脸认证频率" prop="photo">
            <el-radio v-model="faceFreq" :label="1">每次打卡</el-radio>
            <el-radio v-model="faceFreq" :label="2">每月随机</el-radio>
          </el-form-item>
        </el-col>
        <el-col v-if="form.face && faceFreq == 2" :xs="24" :sm="8" :md="8" :lg="8" :xl="8" class="mb20">
          <el-form-item label="每月随机次数" prop="photo">
            <el-input-number v-model="form.faceTimes" :min="0" :max="10" placeholder="必须小于10的正整数"></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="35">
        <el-col :xs="12" :sm="12" :md="12" :lg="24" :xl="24" >
          <el-form-item label="是否拍照" prop="photo">
            <el-radio v-model="form.photo" :label="true">是</el-radio>
            <el-radio v-model="form.photo" :label="false">否</el-radio>
          </el-form-item>
        </el-col>
      </el-row>
      <template v-if="form.photo">
<!--        <el-row :gutter="35">-->
<!--          <el-col :xs="12" :sm="12" :md="12" :lg="24" :xl="24" >-->
<!--            <el-form-item label="是否对比人脸" prop="photo">-->
<!--              <el-radio v-model="form.matchFace" :label="true">是</el-radio>-->
<!--              <el-radio v-model="form.matchFace" :label="false">否</el-radio>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--          <template v-if="form.matchFace">-->
<!--          <el-col :xs="12" :sm="12" :md="12" :lg="24" :xl="24" >-->
<!--            <el-form-item label="匹配百分比" prop="facePercent">-->
<!--              <el-input v-model="form.facePercent" type="number" placeholder="请输入人脸匹配百分比">-->
<!--                <template slot="append">%</template>-->
<!--              </el-input>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--          </template>-->
<!--        </el-row>-->
<!--        <el-row :gutter="35">-->
<!--          <el-col :xs="12" :sm="12" :md="12" :lg="24" :xl="24" >-->
<!--            <el-form-item label="是否对比照片" prop="matchPhoto">-->
<!--              <el-radio v-model="form.matchPhoto" :label="true">是</el-radio>-->
<!--              <el-radio v-model="form.matchPhoto" :label="false">否</el-radio>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--          <template v-if="form.matchPhoto">-->
<!--          <el-col :xs="12" :sm="12" :md="12" :lg="24" :xl="24" >-->
<!--            <el-form-item label="匹配百分比" prop="photoPercent">-->
<!--              <el-input v-model="form.photoPercent" type="number" placeholder="请输入照片匹配百分比">-->
<!--                <template slot="append">%</template>-->
<!--              </el-input>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--          </template>-->
<!--        </el-row>-->
      </template>
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>巡逻点设置</span>
          <el-button style="float: right; padding: 3px 0" type="text" @click="addPatrolItem">添加</el-button>
        </div>
<!--        <el-table v-loading="loading" :data="form.patrolItems" stripe border>-->
<!--          <el-table-column label="是否指定地点" align="center" prop="fixAdd" min-width="100">-->
<!--            <template slot-scope="scope">-->
<!--              <el-select :disabled="!editable" v-model="scope.row.fixAdd" placeholder="请选择是否指定地点" clearable-->
<!--                         size="small">-->
<!--                <el-option :key="true" label="是" :value="true"/>-->
<!--                <el-option :key="false" label="否" :value="false"/>-->
<!--              </el-select>-->
<!--            </template>-->
<!--          </el-table-column>-->
<!--        </el-table>-->
        <div v-for="item in form.patrolItems" :key="form.patrolItems.indexOf(item)" class="text item">
          <div style="padding: 3px">
<!--            <span style="padding-left: 5px; font-weight: bolder; ">巡逻点{{form.patrolItems.indexOf(item)+1}}</span>-->
            <el-divider>巡逻点{{form.patrolItems.indexOf(item)+1}}</el-divider>
            <el-button style="float: right; padding: 5px 0" type="text" @click="delPatrolItem(item)">删除</el-button>
          </div>
          <div>
            <div style="display: inline-flex;">
              <el-form-item label="指定地点" prop="fixAdd">
                <el-radio v-model="item.fixAdd" :label="true">是</el-radio>
                <el-radio v-model="item.fixAdd" :label="false">否</el-radio>
                <template v-if="item.fixAdd">
                  <el-popover placement="top-start" title="地址详情" width="200" trigger="hover"
                              :content="getAddrDetail(item)">
                    <el-link slot="reference" type="primary" @click="showAmapPage(item)">{{getAddr(item)}}
                    </el-link>
                  </el-popover>
                  有效范围（米）<el-input v-model="item.addr.range" style="width: 88px" type="number" placeholder="请输入打卡地点多少米内">
                  </el-input>
                </template>
              </el-form-item>
            </div>
            <div style="display: inline-flex">
              <el-form-item label="指定时间范围" prop="fixTime">
                <el-radio v-model="item.fixTime" :label="true">是</el-radio>
                <el-radio v-model="item.fixTime" :label="false">否</el-radio>
                <template v-if="item.fixTime">
                  <span>时间范围：
                  <el-time-picker value-format="HH:mm" format="HH:mm" style="width: 100px"
                                  v-model="item.startTime"placeholder="选择开始时间" >
                  </el-time-picker>至
                  <el-time-picker value-format="HH:mm" format="HH:mm" style="width: 100px"
                                  v-model="item.endTime"placeholder="选择结束时间">
                  </el-time-picker>
                  </span>
                </template>
              </el-form-item>
            </div>
<!--            <div style="display: inline-flex" v-if="form.matchFace || form.matchPhoto">-->
<!--              <el-form-item label="对比照片" prop="photoUrl">-->
<!--                <el-link type="primary" v-if="item.photoUrl">查看照片</el-link>-->
<!--                <span>暂未上传照片</span>-->
<!--              </el-form-item>-->
<!--            </div>-->
          </div>
        </div>
      </el-card>
    </el-form>
    <div slot="footer" class="dialog-footer" style="text-align: right">
      <el-button v-if="!isReadOnly" type="primary" @click="submitForm" v-permission="['/attendance/attPatrolEmp/add', '/attendance/attPatrolEmp/edit']">确 定</el-button>
      <el-button @click="cancel">取 消</el-button>
    </div>
    <AmapPage :maker-position.sync="makerPosition" :addr.sync="addr" :dialogVisible.sync="positionShow" />

  </div>
</template>

<script>
import permission from '@/directive/permission/index.js'; // 权限判断指令
import {listSimpleEmploy} from '@/api/employ/employ';
import {
  listAttPatrolEmp,
  getAttPatrolEmp,
  delAttPatrolEmp,
  addAttPatrolEmp,
  updateAttPatrolEmp,
  exportAttPatrolEmp } from "@/api/attendance/attPatrolEmp";
import AmapPage from "../components/AmapPage";
export default {
  name: "attPatrolEmpEdit",
  components: {AmapPage,},
  props: {
    row: Object,
    isReadOnly: Boolean,
  },
  directives: { permission },
  watch:{
    faceFreq(){
      if(this.faceFreq == 1){
        this.form.faceTimes = 0;
      }else if(this.faceFreq == 2){
        if(this.form.faceTimes == null || this.form.faceTimes==0){
          this.form.faceTimes = 3;
        }
      }
    },
    row(n,o){ //n为新值,o为旧值;
      //if(n === o) return;
      this.load();
    },
    "form.empId"(val){
      console.log("form.empId="+val)
      this.changeEmpId(val);
    },
    "form.times"(val){
      console.log("form.times="+val)
      this.changeTimes(val);
    },
    positionShow(){
      if(!this.positionShow && this.addr && this.addr.length>0 && this.currentPatrol){
        this.currentPatrol.addr.addr = this.addr;
        this.currentPatrol.addr.position = this.makerPosition;
        // this.form.addr.push({
        //   addr: this.addr,
        //   position: this.makerPosition,
        //   range: 0,
        // });
      }
    },

  },
  data() {
    return {
      faceFreq: null,
      // 遮罩层
      loading: true,

      // 表单参数
      form: {
        empId: null,
        patrolItems: [],
        times: 0,
      },
      updatedEmploy: [],
      positionShow: false,
      makerPosition: [114.027868, 22.60775],
      addr: '',
      currentPatrol: null,

      // 表单校验
      rules: {
        name: [
          {
            required: true,
            message: "名称说明不能为空", trigger: "blur" }
        ],
        times: [
          {
            required: true,
            message: "巡逻打卡次数不能为空", trigger: "blur" }
        ],
      }
    };
  },
  mounted() {
    this.load();
  },
  methods:{
    showAmapPage(item){
      this.addr = "";
      this.makerPosition = [114.027868, 22.60775];
      this.positionShow = true;
      this.currentPatrol = item;
    },
    changeEmpId(val) {
      if(this.updatedEmploy){
        for(let i in this.updatedEmploy){
          let item = this.updatedEmploy[i];
          if(item.id == val){
            console.log("selected emp")
            console.log(item)
            this.form.empName = item.name;
            this.form.userId = item.userId;
            this.form.depId = item.departmentId;
          }
        }
      }
    },
    changeTimes(val) {
      if(this.form.times > 0){
        if(this.form.times > this.form.patrolItems.length){
          for(let i = this.form.patrolItems.length; i < this.form.times; i++){
            this.form.patrolItems.push(this.createAttPatrolItemObj());
          }
        }else{
          for(let i = this.form.times; i < this.form.patrolItems.length; i++){
            this.form.patrolItems.pop();
          }
        }
      }else{
        this.form.patrolItems = [];
      }
    },
    addPatrolItem(){
      this.form.patrolItems.push(this.createAttPatrolItemObj());
    },
    delPatrolItem(item){
      console.log(item)
      let idx = this.form.patrolItems.indexOf(item);
      console.log("idx="+idx)
      this.form.patrolItems.splice(idx,1);
    },
    createAttPatrolItemObj(){
      let obj = {fixAdd: true, addr:{}, fixTime: true};
      return obj;
    },
    getAddr(item){
      let addr = item.addr.position?item.addr.addr:"选择地点";
      if(addr.length > 16){
        addr = "..."+addr.substr(addr.length-16,16);
      }
      return addr;
    },
    getAddrDetail(item){
      let addr = "暂未设置地点";
      if(item.addr.position && item.addr.position.length == 2){
        addr = item.addr.addr+'。坐标：['+item.addr.position[0]+','+item.addr.position[1]+']'
      }
      return addr;
    },
    searchUpdatedEmploy(query){
      if (query !== '') {
        this.loading = true;
        let filter = {};
        filter['name'] = {
          type: 'search',
          value: query,
        }
        const params = this.getQueryParam(filter, 1, 20, '');
        listSimpleEmploy(params).then(response=>{
          console.log(response.data.records);
          this.updatedEmploy = response.data.records;
          this.loading = false;
        });
      } else {
        this.updatedEmploy = [];
      }
    },

    load() {
      this.reset();
      if(this.row && this.row.id){
        this.handleUpdate(this.row);
      }else{
        if(this.row && this.row.name && this.row.name.startsWith("复制")){
          this.form = this.row;
          this.refreasFaceFreq()
        }else{
          this.handleAdd();
        }
      }
    },

    // 取消按钮
    cancel() {
      // this.open = false;
      // this.reset();
      this.$emit("close", {refresh:false,isSuccess:true});
    },
    // 表单重置
    reset() {
      this.form = {times:0,patrolItems:[]};
      this.resetForm("form");
    },

    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.form = {times:0,patrolItems:[]}

    },
    refreasFaceFreq(){
      if(this.form.faceTimes == 0){
        this.faceFreq = 1;
      }else if(this.form.faceTimes > 0){
        this.faceFreq = 2;
      }else{
        this.faceFreq = null;
      }
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.id || this.ids
      getAttPatrolEmp(id).then(response => {
        this.form = response.data;
        this.updatedEmploy = [];
        this.updatedEmploy.push({id: this.form.empId,name: this.form.empName, userId:this.form.userId,
          departmentId:this.form.depId, idNum:'...'})
        console.log(this.form)
        this.refreasFaceFreq()
      });
    },
    /** 提交按钮 */
    submitForm() {
      this.form.companyId = this.$store.getters.userInfo.companyId;
      console.log(this.form)
      if(this.form.face){
        if(this.faceFreq ==  null){
          this.msgError("选择人脸认证必须设置人脸认证频率，请选择”每次认证“还是”每月随机“");
          return;
        }
      }
      this.$refs["form"].validate(valid => {
            if(valid) {
              if (this.form.id != null) {
                updateAttPatrolEmp(this.form).then(response => {
                  this.msgSuccess("修改成功");
                  this.$emit("close", {refresh:true,isSuccess:true});
                  this.reset();
                });
              } else {
                addAttPatrolEmp(this.form).then(response => {
                  this.msgSuccess("新增成功");
                  this.$emit("close", {refresh:true,isSuccess:true});
                  this.reset();
                });
              }
            }
          }
      );
    },

  },
}
</script>

<style scoped>
.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both
}


</style>
