<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="auto">
      <el-form-item label="公司名称" prop="companyId">
        <treeselect v-model="queryParams.companyId" style="width: 198px"
                    :options="companyOptions"
                    :normalizer="normalizerCompany" placeholder="请选择所属公司"/>
      </el-form-item>
      <div v-if="uniApp">
         <el-form-item  label="日期范围" prop="schDate">
        <el-date-picker type="date" clearable size="small" v-model="queryParams.schDateStart" value-format="yyyy-MM-dd" placeholder="选择开始日期"/>
         </el-form-item>
         <el-form-item label="至">
        <el-date-picker type="date" clearable size="small" v-model="queryParams.schDateEnd" value-format="yyyy-MM-dd" placeholder="选择结束日期"/>
      </el-form-item>
      </div>
      <el-form-item  v-else label="日期范围" prop="schDate">
        <el-date-picker type="date" clearable size="small" v-model="queryParams.schDateStart" value-format="yyyy-MM-dd" placeholder="选择开始日期"/>
        至
        <el-date-picker type="date" clearable size="small" v-model="queryParams.schDateEnd" value-format="yyyy-MM-dd" placeholder="选择结束日期"/>
      </el-form-item>
    
      <el-form-item label="日期名称" prop="name">
        <el-input v-model="queryParams.name" placeholder="请输入特殊日期名称" clearable size="small" @keyup.enter.native="handleQuery" />
      </el-form-item>
      <el-form-item label="类型" prop="type">
        <el-select v-model="queryParams.type" placeholder="请选择日期类型" clearable size="small">
          <el-option :key="1" label="上班" :value="1"/>
          <el-option :key="2" label="放假" :value="2"/>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button type="primary" plain icon="el-icon-plus" size="mini"
            @click="handleAdd" v-permission="['/attendance/attSpecialDay/add']" >新增 </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button type="success" plain icon="el-icon-edit" size="mini" :disabled="single"
            @click="handleUpdate" v-permission="['/attendance/attSpecialDay/edit']" >修改 </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button type="danger" plain icon="el-icon-delete" size="mini" :disabled="multiple"
            @click="handleDelete" v-permission="['/attendance/attSpecialDay/remove']" >删除 </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button type="warning" plain icon="el-icon-download" size="mini" class="mb8"
            @click="handleExport" v-permission="['/attendance/attSpecialDay/export']" >导出 </el-button>
      </el-col>
      <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>
      <el-col :span="1.5">
        <el-popover placement="top-start" title="特殊日期" width="350" trigger="click">
          <ul>
            <li>特殊日期，用于设置固定制考勤组指定日期放假或指定某个日期上班等。需要结合考勤组固定制中“特殊日自动排”设置一起使用。</li>
            <li>比如： 国庆法定三天放假，可以设定10.1，10.2，10.3 三日为放假，可以新增三个特殊日期为放假日。或者公司某个周末日需要特别指定需要上班的也可以在此设置为上班日</li>
            <li>如果设置当月的特殊日期或者当前日期在27号之后（27号会生成下月考勤打卡信息），则需要对相应考勤组执行“更新考勤”</li>
          </ul>
          <el-button type="primary" plain icon="el-icon-question" size="mini" slot="reference" class="marginLeft">帮助</el-button>
        </el-popover>
      </el-col>
    </el-row>

    <el-table v-loading="loading" :data="attSpecialDayList" @selection-change="handleSelectionChange" stripe border>
      <el-table-column type="selection" width="55" align="center"/>
      <el-table-column label="日期" align="center" prop="schDate" width="180" sortable>
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.schDate, '{y}-{m}-{d}') }}</span>
        </template>
      </el-table-column>
      <el-table-column label="日期名称" align="center" prop="name" sortable/>
      <el-table-column label="所属公司" align="center" prop="companyId" show-overflow-tooltip width="210" sortable>
        <template slot-scope="scope">
          {{companyFormat(scope.row, null)}}
        </template>
      </el-table-column>
      <el-table-column label="类型" align="center" prop="type" sortable>
        <template slot-scope="scope">
          <span v-if="1 == scope.row.type">上班</span>
          <span v-else-if="2 == scope.row.type">放假</span>
        </template>
      </el-table-column>
      <el-table-column label="参与考勤组" align="center" prop="allGrp" min-width="120px">
        <template slot-scope="scope">
          <span v-if="scope.row.allGrp">所有考勤组</span>
          <el-select disabled v-else v-model="scope.row.attGrpIds.ids" multiple placeholder="请选择考勤组">
            <el-option v-for="item in attGrpList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="打卡班次" align="center" prop="attClassId" min-width="120px" sortable>
        <template slot-scope="scope">
          <span v-if="scope.row.type==1">{{ getClassLabel(scope.row.attClassId) }}</span>
<!--          <span v-else></span>-->
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button size="mini" type="text" icon="el-icon-edit" @click="handleUpdate(scope.row)"
              v-permission="['/attendance/attSpecialDay/edit']" >修改 </el-button>
          <el-button size="mini" type="text" icon="el-icon-delete" @click="handleDelete(scope.row)"
              v-permission="['/attendance/attSpecialDay/remove']" >删除 </el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
        v-show="total>0"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
    />

    <!-- 添加或修改特殊日期对话框 -->
    <el-dialog :title="title" :visible.sync="open" append-to-body width="uniApp?'100%':'50%'">
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-row :gutter="35">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" >
            <el-form-item label="日期名称" prop="name">
              <el-input v-model="form.name" clearable placeholder="请输入特殊日期名称"/>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" >
            <el-form-item label="日期" prop="schDate">
              <el-date-picker clearable size="large" v-model="form.schDate" type="date"  style="width:100%;height:100%"
                              value-format="yyyy-MM-dd" placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" >
            <el-form-item label="日期类型" prop="type">
              <el-select v-model="form.type" :change="typeChange" placeholder="请选择类型" style="width:100%">
                <el-option :key="1" label="上班" :value="1"/>
                <el-option :key="2" label="放假" :value="2"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" >
            <el-form-item label="公司" prop="companyId">
              <treeselect v-if="!form.id" v-model="form.companyId" :options="companyOptions"
                          :normalizer="normalizerCompany" placeholder="请选择公司"/>
              <span v-if="form.id" class="el-input--medium el-input__inner" style="border:0px solid #DCDFE6" >{{companyFormat(form,null)}}</span>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" >
            <el-form-item label="" prop="allGrp">
              <el-switch v-model="form.allGrp" style="display: block" active-text="适用所有固定制考勤组" inactive-text="不适用所有固定制考勤组"
                         active-color="#13ce66" inactive-color="#ff4949"></el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" >
            <el-form-item v-if="!form.allGrp" label="考勤组" prop="attGrpIds">
              <el-select v-model="form.attGrpIds.ids" multiple placeholder="请选择考勤组" style="width:100%">
                <el-option v-for="item in companyGrpList" :key="item.id" :label="item.name" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col v-if="1 == form.type" :xs="24" :sm="12" :md="12" :lg="12" :xl="12" >
            <el-form-item label="上班班次" prop="attClassId">
              <el-select v-model="form.attClassId" placeholder="请选择上班班次">
                <el-option v-for="item in companyClassList" :key="item.id" :label="getFixDefLabel(item)" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm" v-permission="['/attendance/attSpecialDay/add', '/attendance/attSpecialDay/edit']">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  listAttSpecialDay,
  getAttSpecialDay,
  delAttSpecialDay,
  addAttSpecialDay,
  updateAttSpecialDay,
  exportAttSpecialDay } from "@/api/attendance/attSpecialDay";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import RightToolbar from "@/components/RightToolbar"
import permission from '@/directive/permission/index.js' // 权限判断指令
import Pagination from "@/components/Pagination";
import {listCompany} from "@/api/org/company";
import {DICT_TYPE,getDictLabelFromData, getDictDataLabel, getDictDatas} from "@/utils/dict";
import { listAttendanceClass } from "@/api/attendance/attendanceClass";
import {listAttendanceGroup,} from "@/api/attendance/attendanceGroup";
import {Local} from '@/utils/storage.js'

// 表查询配置
const dbOrderBy = ''

export default {
  name: "AttSpecialDay",
  components: {
    Treeselect, RightToolbar, Pagination,
  },
  directives: { permission },
  watch:{
    "form.companyId": function (n, o) {
      console.log(this.attendanceClassList);
      let companyClassList = [];
      if(this.attendanceClassList){
        companyClassList = this.attendanceClassList.filter(item=> {
          if(this.form.companyId == item.companyId) return item;
        });
      }
      console.log(this.companyClassList)
      this.companyClassList = companyClassList;
      // 公司考勤组
      let companyGrpList = [];
      if(this.attGrpList){
        companyGrpList = this.attGrpList.filter(item=> {
          if(this.form.companyId == item.companyId) return item;
        });
      }
      console.log(this.companyGrpList)
      this.companyGrpList = companyGrpList;
    },
  },
  data() {
    return {
      // 公司树选项
      companyList: [],
      companyOptions: [],
      attendanceClassList: [],
      companyClassList: [],
      attGrpList: [],
      companyGrpList: [],
      // 遮罩层
      loading: true,
      // 查询参数
      where: {
        query: '',
        filters: []
      },
      uniApp:false,

      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 特殊日期表格数据
      attSpecialDayList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        orderby: dbOrderBy,
        name: null,
        companyId: null,
        schDateStart: null,
        schDateEnd: null,
        type: null,
        allGrp: null,
        attgrpids: null,
      },
      // 表单参数
      form: {
        attGrpIds:{ids:[]},
      }
      ,
      // 表单校验
      rules: {
        name: [
          {
            required: true,
            message: "特殊日期名称不能为空", trigger: "blur" }
        ],
        companyId: [
          {
            required: true,
            message: "公司不能为空", trigger: "blur" }
        ],
        schDate: [
          {
            required: true,
            message: "日期不能为空", trigger: "blur" }
        ],
        type: [
          {
            required: true,
            message: "类型不能为空", trigger: "change"
          }
        ],
        allGrp: [
          {
            required: true,
            message: "是否所以考勤组不能为空", trigger: "blur" }
        ],
      }
    };
  },
  created() {
    this.getTreeselectCompany();
    this.loadClass();
    this.loadGrp();
    this._filter = {};
    this.getList();
    this.uniApp=Local.get("isUniapp")?true:false;
  },
  methods: {
    typeChange(){
      if(this.form.type == 1){
      }
    },
    loadClass(){
      // 查询班次
      const classParams = this.getQueryParam({},1,1000,'');
      listAttendanceClass(classParams).then(response => {
        this.attendanceClassList = response.data.records;
      });
    },
    loadGrp(){
      // 查询班次
      const grpParams = this.getQueryParam({},1,1000,'');
      listAttendanceGroup(grpParams).then(response => {
        this.attGrpList = response.data.records;
      });
    },
    getClassLabel(classId){
      // console.log("classid = "+classId);
      let label = "";
      this.attendanceClassList.forEach(item=>{
        if(item.id == classId){
          // console.log(item);
          label = this.getFixDefLabel(item);
        }
      });
      return label;
    },
    getFixDefLabel(item){
      let str = item.name+"("+item.otherInfo.earlyOn;
      if(item.otherInfo.laterOffOver){
        str += " - 次日"+item.otherInfo.laterOff;
      }else{
        str += " - "+item.otherInfo.laterOff;
      }
      return str + ")" +item.days +"天";
    },
    /** 查询公司下拉树结构 */
    getTreeselectCompany() {
      const params = {
        pageNum: 1,
        pageSize: 500,
        where: null,
        order: ''
      }
      listCompany(params).then(response => {
        this.companyList = response.data.records;
        this.companyOptions = this.handleTree(response.data.records, "id", "parentId");
      }) ;
    },

    /** 查询特殊日期列表 */
    getQuery(){
      this._filter = {};
      if(this.queryParams.name) {
        this._filter['name'] = {
          type: 'search',
          value: this.queryParams.name,
        }
      }
      if(this.queryParams.companyId) {
        this._filter['companyId'] = {
          type: 'search',
          value: this.queryParams.companyId,
        }
      }
      if(this.queryParams.schDateStart && this.queryParams.schDateEnd) {
        this._filter['schDate'] = {
          type: 'timestamp',
          value: [this.queryParams.schDateStart,this.queryParams.schDateEnd],
        }
      }else if(this.queryParams.schDateStart) {
        this._filter['schDate'] = {
          type: 'ge',
          value: this.queryParams.schDateStart,
        }
      }else if(this.queryParams.schDateEnd) {
        this._filter['schDate'] = {
          type: 'le',
          value: this.queryParams.schDateEnd,
        }
      }
      if(this.queryParams.type) {
        this._filter['type'] = {
          type: 'eq',
          value: this.queryParams.type,
        }
      }
      if(this.queryParams.allGrp) {
        this._filter['allGrp'] = {
          type: 'search',
          value: this.queryParams.allGrp,
        }
      }
      const filters = this.parseFilters(this._filter)
      if (filters && filters.length > 0) {
        this.where.filters = filters
      } else {
        // 如果过滤条件为空，设置空数组
        this.where.filters = []
      }
      const params = {
        pageNum: this.queryParams.pageNum,
        pageSize: this.queryParams.pageSize,
        where: this.where,
        order: this.orderby
      }

      return params;
    },
    getList() {
      this.loading = true;
      const params = this.getQuery();
      listAttSpecialDay(params).then(response => {
        this.attSpecialDayList = response.data.records;
        this.total = response.data.total;
        this.loading = false;
      })
      ;
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {allGrp:false};
      this.resetForm("form");
      this.$set(this.form,"attGrpIds",{ids: []});
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.$set(this.queryParams,"schDateStart", null);
      this.$set(this.queryParams,"schDateEnd", null);
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加特殊日期";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.id || this.ids
      getAttSpecialDay(id).then(response => {
        this.form = response.data;
        if(!this.form.attGrpIds || !this.form.attGrpIds.ids){
          this.$set(this.form,"attGrpIds",{ids: []});
        }
        this.open = true;
        this.title = "修改特殊日期";
      })
      ;
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate(valid => {
            if(valid) {
              if(this.form.attGrpIds && this.form.attGrpIds.ids){
                let idMap = {ids:this.form.attGrpIds.ids};
                this.form.attGrpIds.ids.forEach(id=>{
                  idMap['i'+id] = id;
                });
                this.form.attGrpIds = idMap;
              }
              if (this.form.id != null) {
                updateAttSpecialDay(this.form).then(response => {
                  this.msgSuccess("修改成功");
                  this.open = false;
                  this.getList();
                });
              } else {
                addAttSpecialDay(this.form).then(response => {
                  this.msgSuccess("新增成功");
                  this.open = false;
                  this.getList();
                });
              }
            }
          }
      );
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      let ids = [];
      if(row.id){
        ids = [row.id];
      }else{
        ids = this.ids;
      }

      this.$confirm('是否确认删除选中的特殊日期数据?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return delAttSpecialDay(ids);
      }).then(() => {
        this.getList();
        this.msgSuccess("删除成功");
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      const params = this.getQuery();
      this.$confirm('是否确认导出所有特殊日期数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return exportAttSpecialDay(params);
      }).then(response => {
        this.download(response.msg);
      })
    },
    /** 转换公司数据结构 */
    normalizerCompany(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.id,
        label: node.companyName,
        children: node.children
      };
    },
    companyFormat(row, column) {
      // console.log(row);
      for(var i = 0; i < this.companyList.length; i++){
        const item =  this.companyList[i];
        if(item.id == row.companyId){
          return item.companyName;
        }
      }
      return "";
    },
    getCompanyCode(companyId){
      // console.log(companyId)
      // console.log(this.companyList)
      for(var i = 0; i < this.companyList.length; i++){
        const item =  this.companyList[i];
        if(item.id == companyId){
          return item.code;
        }
      }
    },

  }
};
</script>
<style scoped>
/* small */
.vueTreeSelectSmall {
  height: 36px;
  display: flex;
  align-items: center;
}
.vueTreeSelectSmall .vue-treeselect__control {
  height: 30px !important;
}
.vueTreeSelectSmall .vue-treeselect__control .vue-treeselect__value-container {
  height: 30px !important;
  display: block !important;
}
.vueTreeSelectSmall
  .vue-treeselect__control
  .vue-treeselect__value-container
  .vue-treeselect__placeholder {
  line-height: 30px !important;
  font-size: 14px !important;
}
.vueTreeSelectSmall
  .vue-treeselect__control
  .vue-treeselect__value-container
  .vue-treeselect__single-value {
  line-height: 30px !important;
  font-size: 14px !important;
}
</style>
