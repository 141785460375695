import { Local } from '@/utils/storage.js';
import { listAllCompany } from '@/api/org/company';
import { parseFilters } from "@/utils/tools";
/**
 * 2020.05.28 by lyt 优化
 * 修改一下配置时，需要每次都清理 `window.localStorage` 浏览器永久缓存，配置才会生效
 */
const themeConfigModule = {
	namespaced: true,
	state: {
		themeConfig: {
			// 是否开启布局配置抽屉
			isDrawer: false,

			/**
			 * 界面设置
			 */
			// 是否开启菜单水平折叠效果
			isCollapse: false,
			// 是否开启菜单手风琴效果
			isUniqueOpened: false,
			// 是否开启固定 Header
			isFixedHeader: false,

			/**
			 * 界面显示
			 */
			// 是否开启侧边栏 Logo
			isShowLogo: true,
			// 是否开启 Breadcrumb
			isBreadcrumb: true,
			// 是否开启 Breadcrumb 图标
			isBreadcrumbIcon: false,
			// 是否开启 Tagsview
			isTagsview: true,
			// 是否开启 Tagsview 图标
			isTagsviewIcon: false,
			// 是否开启 TagsView 缓存
			isCacheTagsView: true,
			// 是否开启 Footer 底部版权信息
			isFooter: false,
			// 是否开启灰色模式
			isGrayscale: false,
			// 是否开启色弱模式
			isInvert: false,

			/**
			 * 其它设置
			 */
			// 默认 Tagsview 风格，可选 1、 tags-style-one，自行扩展：
			// 1、需修改 @/views/layout/navBars/breadcrumb/setings.vue `getThemeConfig.tagsStyle` el-option
			// 2、需修改 @/views/layout/navBars/tagsView/tagsView.vue 代码最底部注释部分 css 样式
			tagsStyle: 'tags-style-one',
			// 主页面切换动画：可选值"<slide-right|slide-left|opacitys>"，默认 slide-right
			animation: 'slide-right',
			// 分栏高亮风格：可选值"<columns-round|columns-card>"，默认 columns-round
			columnsAsideStyle: 'columns-round',
			// 分栏布局风格：可选值"<columns-horizontal|columns-vertical>"，默认 columns-horizontal
			columnsAsideLayout: 'columns-vertical',

			/**
			 * 布局切换
			 * 注意：为了演示，切换布局时，颜色会被还原成默认，代码位置：/@/views/layout/navBars/breadcrumb/setings.vue
			 * 中的 `initSetLayoutChange(设置布局切换，重置主题样式)` 方法
			 */
			// 布局切换：可选值"<defaults|classic|transverse|columns>"，默认 defaults
			layout: 'defaults',

			/**
			 * 全局网站标题 / 副标题
			 */
			// 网站副标题（登录页顶部文字）
			globalViceTitle: '欢迎使用',
			// 网站描述（登录页顶部文字）
			globalViceDes: '集团信息化管理系统',
			// 默认初始语言，可选值"<zh-cn|en|zh-tw>"，默认 zh-cn
			globalI18n: 'zh-cn',
			// 默认全局组件大小，可选值"<|medium|small|mini>"，默认 ''
			globalComponentSize: 'medium',
		},
		// 网站主标题（菜单导航、浏览器当前网页标题）
		globalTitle: '',
		domainUrl: 'www.jtehr.com',

	},
	mutations: {
		// 设置布局配置
		getThemeConfig(state, data) {
			// let isUniapp = Local.get("isUniapp");
			// if(!isUniapp){
			// 	state.themeConfig = data;
			// }else {
				state.themeConfig = data;
			// }
		},
		updateDomain(state, domainUrl){
			state.domainUrl = domainUrl;
		},
		updateGlobalTitle(state, globalTitle){
			state.globalTitle = globalTitle;
		},
	},
	actions: {
		// 设置布局配置
		setThemeConfig({ commit }, data) {
			commit('getThemeConfig', data);
		},
		changeDomain({ commit }, domainUrl){
			let _filter = {};
			_filter['website'] = {
				type: 'rlike',
				value: domainUrl,
			}
			const filters = parseFilters(_filter);
			let where = {filters:[]};
			if (filters && filters.length > 0) {
				where.filters = filters
			} else {
				// 如果过滤条件为空，设置空数组
				where.filters = []
			}
			const params = {
				pageNum: 1,
				pageSize: 1000,
				where: where,
				order: this.orderby,
			};
			return new Promise((resolve, reject) => {
				listAllCompany(params).then((response) => {
					console.log("*********getLayoutThemeConfig listAllCompany******************");
					let title = "";
					if (response.data.records && response.data.records.length > 0) {
						let company = response.data.records[0];
						title = company.companyName + "信息化管理系统";
					} else {
						title = '集团信息化管理系统';
					}
					commit("updateDomain",domainUrl);
					commit("updateGlobalTitle",title);
					Local.set('domainUrl', domainUrl);
					Local.set('globalTitle', title);
					document.title = title;
					resolve()
				}).catch(error => {
					reject(error)
				});
			});
		},
	},
};

export default themeConfigModule;
