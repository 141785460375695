<template>
<div class="app-container">
  <el-row :gutter="15" class="home-recommend-row">
    <el-col v-for="(item,index) in list" :key="item.id" :xs="8" :sm="8" :md="6" :lg="3" :xl="3">
      <div @click="selfFlow(item)" class="home-recommend" :style="{'background-color': getRandomColor(index),'margin-top': '10px','height':'80px','cursor':'pointer','border-radius':'4px'}">
        <i class="el-icon-s-custom" :style="{ 'color': '#64d89d' }"></i>
        <div class="home-recommend-auto">
          <div style="text-align: center;margin-top: 16px;"><b>{{ item.model.name }}</b></div>
<!--          <div class="home-recommend-msg" style="text-align: center">{{ item.id }} 人</div>-->
        </div>
      </div>
    </el-col>
  </el-row>
</div>
</template>

<script>
import {findModels} from "@/api/bpm/model";
import { showLoading, hideLoading } from '@/utils/reqloading';

export default {
  name: "index",
  data() {
    return {
      // 表格数据
      list: [],
      // 查询参数
      queryParams: {
        pageNo: 1,
        pageSize: 100,
      },
    }
  },
  created() {
    this.getList();
  },
  methods: {
    /** 查询流程模型列表 */
    getList() {
      // this.loading = true;
      showLoading();
      findModels(this.queryParams).then(response => {
          console.log("findModels result:");
          console.log(response.data.list)
          this.list = response.data.list.reverse();
          this.total = response.data.total;
          // this.loading = false;
          hideLoading();
        }
      );
    },
    selfFlow(item){
      console.log(item);
      if(item.model.category == 'ehr'){
        let routeName = 'bpm_'+item.model.key.slice(0,1).toLowerCase()+item.model.key.slice(1);
        console.log(routeName);
        this.$router.push({name: routeName,params: {}});
      }else if(item.model.category == 'self'){
        let name = item.model.name;
        if(name.endsWith("流程")){
          const len = "流程".length;
          name = name.slice(0,name.length - len);
        }
        this.$router.push({name: "bpm_selfFlow",query: {type:item.model.key, name: name}});
      } else{

      }
    },

    getRandomColor(i) {
      i = parseInt(i) || 0;
      if(i == -1) return '#808080';//'#000000';
      // return "#"+("00000"+((Math.random(i)*16777215+0.5)>>0).toString(16)).slice(-6);
      switch (i % 10) {
        case 1:
          return 'rgba(255,192,203,0.5)';
        case 2:
          return 'rgba(65,105,225,0.5)';
        case 3:
          return 'rgba(135,206,235,0.5)';
        case 4:
          return 'rgba(0,139,139,0.5)';
        case 5:
          return 'rgba(34,139,34,0.5)';
        case 6:
          return 'rgba(189,183,107,0.5)';
        case 7:
          return 'rgba(218,165,32,0.5)';
        case 8:
          return 'rgba(233,150,122,0.5)';
        case 9:
          return 'rgba(205,92,92,0.5)';
        case 0:
          return 'rgba(153,50,204,0.5)';
      }
    }
  }
}
</script>

<style scoped>

</style>
