<template>
	<div>
		<el-form ref="queryParameters" label-width="80px">
			<el-row >
				<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
					<el-form-item label="起始时间">
						<el-date-picker style="width:100% !important" v-model="queryParameters.startDate" value-format="yyyy-MM-dd" align="left" type="date" placeholder="选择日期">
						</el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
					<el-form-item label="终止时间">
						<el-date-picker style="width:100% !important" v-model="queryParameters.endDate" value-format="yyyy-MM-dd" align="left" type="date" placeholder="选择日期">
						</el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
					<el-form-item label="课程名称">
						<el-input style="width:100% !important" v-model="queryParameters.courseName" clearable placeholder="请输入课程名称" />
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
					<el-button v-on:click="query" icon="el-icon" plain type="primary">查询</el-button>
				</el-col>
			</el-row>
			<el-tabs v-model="activeName" @tab-click="handleClick">
				<el-tab-pane label="视频" name="first">
					<el-table v-loading="loading" :data="vedioCourses" style="width: 100%" stripe border v-if="activeName === 'first'">
						<el-table-column label="编号ID" align="center" prop="id" v-if="false" />
						<el-table-column label="公司" align="center" prop="companyName" sortable :show-overflow-tooltip="true" />
						<el-table-column label="课程名称" align="center" prop="courseName" width="150px" sortable :show-overflow-tooltip="true"> </el-table-column>
						<el-table-column label="课程分类" align="center" prop="courseType" width="150px" sortable :show-overflow-tooltip="true">
							<template slot-scope="scope">
								{{ courseTypeFormat(scope.row, null) }}
							</template>
						</el-table-column>
						<el-table-column label="时长(时)" align="center" prop="studyHours" width="80px" min-width="60" />
						<el-table-column label="大小(MB)" align="center" prop="fileSize" />
						<el-table-column label="描述" align="center" prop="description" :show-overflow-tooltip="true" />
						<el-table-column label="创建日期" align="center" prop="createTime">
							<template slot-scope="scope">
								<span>{{ convertUTCTimeToLocalDate(scope.row.createTime) }}</span>
							</template>
						</el-table-column>
						<el-table-column label="观看" fixed="right">
							<template slot-scope="scope">
								<!-- <span>{{ scope.row.courseUrl }}</span> -->
								<el-button size="mini" @click="showVideo(scope.row)">进入</el-button>
							</template>
						</el-table-column>
					</el-table>
					<pagination
						v-show="totalVedioCourse > 0"
						:total="totalVedioCourse"
						:page.sync="queryParameters.pageNum"
						:limit.sync="queryParameters.pageSize"
						@pagination="queryVedio"
					/>
				</el-tab-pane>
				<el-tab-pane label="文本" name="second">
					<el-table v-loading="loading" :data="textCourses" style="width: 100%" stripe border v-if="activeName === 'second'">
						<el-table-column label="编号ID" align="center" prop="id" v-if="false" />
						<el-table-column label="公司" align="center" prop="companyName" sortable :show-overflow-tooltip="true" />
						<el-table-column label="课程名称" align="center" prop="courseName" width="150px" sortable :show-overflow-tooltip="true"> </el-table-column>
						<el-table-column label="课程分类" align="center" prop="courseType" width="150px" sortable :show-overflow-tooltip="true">
							<template slot-scope="scope">
								{{ courseTypeFormat(scope.row, null) }}
							</template>
						</el-table-column>
						<el-table-column label="时长(时)" align="center" prop="studyHours" min-width="80" />
						<el-table-column label="大小(MB)" align="center" prop="fileSize" />
							<el-table-column label="文件类型" align="center" prop="fileType" width="100px" sortable>
							<template slot-scope="scope">
							{{courseFileTypeFormat(scope.row, null)}}
							</template>
						</el-table-column>
						<el-table-column label="描述" align="center" prop="description" :show-overflow-tooltip="true" />
						<el-table-column label="创建日期" align="center" prop="createTime">
							<template slot-scope="scope">
								<span>{{ convertUTCTimeToLocalDate(scope.row.createTime) }}</span>
							</template>
						</el-table-column>
						<el-table-column label="阅读" fixed="right">
							<template slot-scope="scope">
								<el-button size="mini" @click="showDocument(scope.row)">进入</el-button>
							</template>
						</el-table-column>
					</el-table>
					<pagination
						v-show="totalVedioCourse > 0"
						:total="totalVedioCourse"
						:page.sync="queryParameters.pageNum"
						:limit.sync="queryParameters.pageSize"
						@pagination="queryText"
					/>
				</el-tab-pane>
				<el-tab-pane label="图片" name="third">
					<el-table v-loading="loading" :data="pictureCourses" style="width: 100%" stripe border v-if="activeName === 'third'">
						<el-table-column label="编号ID" align="center" prop="id" v-if="false" />
						<el-table-column label="公司" align="center" prop="companyName" sortable :show-overflow-tooltip="true" />
						<el-table-column label="课程名称" align="center" prop="courseName" sortable width="150px" show-overflow-tooltip="true"> </el-table-column>
						<el-table-column label="课程分类" align="center" prop="courseType" sortable width="150px" show-overflow-tooltip="true">
							<template slot-scope="scope">
								{{ courseTypeFormat(scope.row, null) }}
							</template>
						</el-table-column>
						<el-table-column label="时长(时)" align="center" prop="studyHours" width="100px" min-width="60" />
						<el-table-column label="大小(MB)" align="center" prop="fileSize" />
						<el-table-column label="描述" align="center" prop="description" :show-overflow-tooltip="true" />
						<el-table-column label="创建日期" align="center" prop="createTime">
							<template slot-scope="scope">
								<span>{{ convertUTCTimeToLocalDate(scope.row.createTime) }}</span>
							</template>
						</el-table-column>
						<el-table-column label="查阅" fixed="right">
							<template slot-scope="scope">
								<el-button size="mini" @click="showDocument(scope.row)">进入</el-button>
							</template>
						</el-table-column>
					</el-table>
					<pagination
						v-show="totalVedioCourse > 0"
						:total="totalVedioCourse"
						:page.sync="queryParameters.pageNum"
						:limit.sync="queryParameters.pageSize"
						@pagination="queryPicture"
					/>
				</el-tab-pane>
				<el-tab-pane label="链接" name="fourth">
					<el-table v-loading="loading" :data="linkCourses" style="width: 100%" stripe border v-if="activeName === 'fourth'">
						<el-table-column label="编号ID" align="center" prop="id" v-if="false" />
						<el-table-column label="公司" align="center" prop="companyName" sortable width="150px"  :show-overflow-tooltip="true" />
						<el-table-column label="课程名称" align="center" prop="courseName" sortable width="150px" :show-overflow-tooltip="true"> </el-table-column>
						<el-table-column label="课程分类" align="center" prop="courseType" width="100px" sortable>
							<template slot-scope="scope">
								{{ courseTypeFormat(scope.row, null) }}
							</template>
						</el-table-column>
						<el-table-column label="时长(时)" align="center" prop="studyHours" width="100px"  min-width="60" />
						<el-table-column label="大小(MB)" align="center" prop="fileSize" />
						<el-table-column label="描述" align="center" prop="description" :show-overflow-tooltip="true" />
						<el-table-column label="创建日期" align="center" prop="createTime">
							<template slot-scope="scope">
								<span>{{ convertUTCTimeToLocalDate(scope.row.createTime) }}</span>
							</template>
						</el-table-column>
						<el-table-column label="链接地址" fixed="right">
							<template slot-scope="scope">
								 <!-- <el-link type='primary' :href='scope.row.courseUrl' target='_blank'>{{ scope.row.courseUrl }}</el-link> -->
								 <router-link :to="{path:scope.row.courseUrl}" target="_blank">{{ scope.row.courseUrl }}</router-link>
								
							</template>
						</el-table-column>
					</el-table>
					<pagination
						v-show="totalVedioCourse > 0"
						:total="totalVedioCourse"
						:page.sync="queryParameters.pageNum"
						:limit.sync="queryParameters.pageSize"
						@pagination="queryLink"
					/>
				</el-tab-pane>
			</el-tabs>
		</el-form>
	</div>
</template>

<script>
import Pagination from '@/components/Pagination';
import { findAllCourses } from '@/api/training/trainingCourse';
import permission from '@/directive/permission/index.js'; // 权限判断指令
import RouterLink from './RouterLink.vue'
import { listEmployIncludeDelete } from '../../../../api/employ/employ';


export default {
	name: 'EhrWebVue2OnlineStudy',
	components: {
		Pagination,
		RouterLink

	},
	data() {
		return {
			queryParameters: {
				startDate: '',
				endDate: '',
				courseName: '',
				courseType: 3,
				pageSize: 10,
				pageNum: 1,
			},
			vedioCourses: [],
			pictureCourses: [],
			textCourses: [],
			linkCourses: [],
			trainingRecords: [],
			courseTypes: [],
			totalVedioCourse: 0,
			totalTextCourse: 0,
			totalPictureCourse: 0,
			totalLinkCourse: 0,
			loading: false,
			where: {
				query: '',
				filters: [],
			},
			activeName: 'first',
		};
	},
	created() {
		this.queryVedio();
		this.load();
	},
	mounted() {},

	methods: {
		load() {
			this.getDicts('trainingCourse_type').then((response) => {
				this.courseTypes = response.data;
			});
				this.getDicts('trainingCourseFile_type').then((response) => {
				this.courseFileTypes = response.data;
			});
		},
        query(){
            if (this.activeName === 'first') {
				this.queryVedio();
			} else if (this.activeName === 'second') {
				this.queryText();
			} else if (this.activeName === 'third') {
				this.queryPicture();
			} else if (this.activeName === 'fourth') {
				this.queryLink();

			} else {
				this.queryVedio();
			}
        },
		handleClick(tab, event) {
			if (tab.name === 'first') {
				if(this.vedioCourses == null || this.vedioCourses.length == 0 )
				{
					this.queryVedio();
				}
				
				this.activeName = 'first';
			} else if (tab.name === 'second') {
				if(this.textCourses == null || this.textCourses.length == 0 )
				{
					this.queryText();
					this.activeName = 'second';
				}
				
			} else if (tab.name === 'third') {
				if(this.pictureCourses == null || this.pictureCourses.length == 0 )
				{
					this.queryPicture();
				}
				this.activeName = 'third';
			} else if (tab.name === 'fourth') {
				if(this.linkCourses == null || this.linkCourses.length == 0 )
				{
					this.queryLink();
				}
				this.activeName = 'fourth';
			} else {
				this.queryVedio();
				this.activeName = 'first';
			}
		},
		courseTypeFormat(row, column) {
			for (var i = 0; i < this.courseTypes.length; i++) {
				const item = this.courseTypes[i];
				if (item.dictValue == row.courseType) {
					return item.dictLabel;
				}
			}
			return '';
		},
		queryParam() {
			let filter = {};

			if (this.queryParameters.courseName) {
				filter['courseName'] = {
					type: 'search',
					value: this.queryParameters.courseName,
				};
			}
			if (this.queryParameters.courseType) {
				filter['fileType'] = {
					type: 'eq',
					value: this.queryParameters.courseType,
				};
			}

			if (this.queryParameters.startDate && this.queryParameters.endDate) {
				let tempDate = this.queryParameters.startDate.replace(/-/g, '/'); //必须把日期'-'转为'/'
				var startDateTimeStamp = new Date(tempDate).getTime();
				tempDate = this.queryParameters.endDate.replace(/-/g, '/');
				var endDateTimeStamp = new Date(tempDate).getTime();
				filter['createdDate'] = {
					type: 'timestamp',
					value: [startDateTimeStamp, endDateTimeStamp],
				};
			}

			const filters = this.parseFilters(filter);
			if (filters && filters.length > 0) {
				this.where.filters = filters;
			} else {
				// 如果过滤条件为空，设置空数组
				this.where.filters = [];
			}
			const params = {
				pageNum: this.queryParameters.pageNum,
				pageSize: this.queryParameters.pageSize,
				where: this.where,
				order: '',
			};
			return params;
		},
		queryVedio() {
			this.queryParameters.courseType = 3;
			let params = this.queryParam();
			this.loading = true;
			findAllCourses(params).then((response) => {
				this.vedioCourses = response.data.records;
				this.totalVedioCourse = this.vedioCourses ==null?0: this.vedioCourses.length;
				this.loading = false;
			});
		},
		queryText() {
			this.queryParameters.courseType = 1;
			let params = this.queryParam();
			this.loading = true;
			findAllCourses(params).then((response) => {
				this.textCourses = response.data.records;
				this.totalTextCourse = this.textCourses==null?0: this.textCourses.length;
				this.loading = false;
			});
		},
		queryPicture() {
			this.queryParameters.courseType = 2;
			let params = this.queryParam();
			this.loading = true;
			findAllCourses(params).then((response) => {
				this.pictureCourses = response.data.records;
				this.totalPictureCourse = this.pictureCourses==null?0: this.pictureCourses.length;
				this.loading = false;
			});
		},
		queryLink() {
			this.queryParameters.courseType = 4;
			let params = this.queryParam();
			this.loading = true;
			findAllCourses(params).then((response) => {
				this.linkCourses = response.data.records;
				this.totalLinkCourse = this.linkCourses==null?0: this.linkCourses.length;
				this.loading = false;
			});
		},
		showVideo(data){
		
			 	this.$router.push({ name: 'onlineStudy_videoPlayer', params: { courseData: data} });
		},
		showDocument(data){
			
			 	this.$router.push({ name: 'onlineStudy_documentPlayer', params: { courseData: data} });
		},
		courseFileTypeFormat(row, column) {
			let retVal="未知"
			if(row.courseUrl != null && row.courseUrl.trim() != '')
			{
				let tempArray= 	row.courseUrl.split('.');
				if(tempArray.length==2)
				{
					retVal=tempArray[1];
				}
			}
	
			return retVal;
		},
	},
};
</script>

<style lang="scss" scoped>
</style>