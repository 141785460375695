import { render, staticRenderFns } from "./trainingPlanEdit.vue?vue&type=template&id=fb3818c8&scoped=true"
import script from "./trainingPlanEdit.vue?vue&type=script&lang=js"
export * from "./trainingPlanEdit.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fb3818c8",
  null
  
)

export default component.exports