<template>
	<div>
		<el-form :model="trainingPlan" label-width="140px" ref="form">
			<el-row :gutter="24">
				<el-col v-if="isNewTrainingPlan" :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">
					<el-form-item label="以前的培训计划" prop="name">
						<el-select v-model="trainingPlan.name" placeholder="请选择以前的培训计划" @change="loadTrainingPlan">
							<el-option v-for="item in previousTrainingPlans" :key="item.id" :label="item.createTimeName" :value="item.id" />
						</el-select>
						<el-tooltip class="item" effect="light" content="选择以前的培训计划作为基础进行创建新的培训计划。" placement="right-start">
						<i class="el-icon-question" />
					</el-tooltip>
					</el-form-item>
					
				</el-col>
				<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">
					<el-form-item label="公司">
						<el-select v-model="trainingPlan.companyName" clearable @clear="handleClearable" placeholder="请选择" collapse-tags ref="companySelected">
							<el-option :value="trainingPlan.companyId" :label="trainingPlan.companyName" style="height: auto">
								<el-tree
									:data="companyOptions"
									:props="defaultProps"
									:expand-on-click-node="false"
									ref="tree"
									clearable
									show-checkbox
									node-key="id"
									default-expand-all
									@node-click="handleNodeClick"
									@check="handleNodeClick"
									highlight-current
								/>
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
				
				<el-col  :xs="24" :sm="24" :md="12" :lg="12" :xl="12" class="mb0">
					<el-form-item label="培训计划名称" prop="name" :rules="[{ required: true, message: '培训计划名称必填。', trigger: 'blur' }]">
						<el-input v-model="trainingPlan.name" clearable placeholder="请输入培训计划名称" />
					</el-form-item>
				</el-col>
				
				<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">
					<el-form-item label="培训简介" prop="description" >
						<el-input clearable
							:autosize="{ minRows: 3, maxRows: 10 }"
							maxlength="1000"
							show-word-limit
							type="textarea" v-model="trainingPlan.description" placeholder="请输培训简介" />
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">
					<el-form-item label="培训类型" prop="trainingType" :rules="[{ required: true, message: '培训类型必选。', trigger: 'blur' }]">
						<el-radio-group v-model="trainingPlan.trainingType">
							<el-radio v-for="item in trainingTypes" :key="item.dictValue" :label="item.dictValue" :value="item.dictValue"></el-radio>
						</el-radio-group>
					</el-form-item>
				</el-col>
				<!-- <el-col v-if="trainingType==0" :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">
					<el-form-item label="选择在线课程" prop="trainingCourse" :rules="[{ required: true, message: '在线课程必选', trigger: 'blur' }]">
						<el-select v-model="trainingCourses" placeholder="请选择在线课程" >
							<el-option v-for="item in trainingCourses" :key="item" :label="item" :value="item" />
						</el-select>
					</el-form-item>
				</el-col> -->
				<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">
					<el-form-item label="培训机构" prop="trainingAgency">
						<el-select v-model="trainingPlan.trainingAgency" clearable placeholder="请选择培训机构">
							<el-option v-for="item in trainingAgencies" :key="item.id" :label="item.name" :value="item.id" />
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">
					<el-form-item label="培训讲师" prop="trainingTeacher">
						<el-select v-model="trainingPlan.trainingTeacher" clearable placeholder="请选择培训讲师">
							<el-option v-for="item in trainingTeachers" :key="item.id" :label="item.name" :value="item.id" />
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" class="mb0">
					<el-form-item label="起始时间" prop="trainingStart" :rules="[{ required: true, message: '培训起始时间必选。', trigger: 'blur' }]">
						<el-date-picker
							v-model="trainingPlan.trainingStart"
							value-format="yyyy-MM-dd HH:mm:ss"
							align="left"
							type="datetime"
							placeholder="选择日期时间"
							clearable
							style="width:100%;"
						>
						</el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" class="mb0">
					<el-form-item label="结束时间" prop="trainingEnd" :rules="[{ required: true, message: '培训结束时间必选。', trigger: 'blur' }]">
						<el-date-picker
							v-model="trainingPlan.trainingEnd"
							value-format="yyyy-MM-dd HH:mm:ss"
							align="left"
							type="datetime"
							placeholder="选择日期时间"
							clearable
							style="width:100%;"
						>
						</el-date-picker>
					</el-form-item>
				</el-col>
				<el-col  :xs="24" :sm="24" :md="12" :lg="12" :xl="12" class="mb0">
					<el-form-item label="培训地点" prop="trainingPlace">
						<el-input v-model="trainingPlan.trainingPlace" clearable placeholder="请输入培训计划地点" />
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" class="mb0">
					<el-form-item label="培训时长" prop="trainingTime" :rules="[{ required: true, message: '培训时长必填。', trigger: 'blur' }]">
						<el-input v-model="trainingPlan.trainingTime" clearable type="number" placeholder="请输入培训时长" />
					</el-form-item>
				</el-col>
			
			<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0" >
				<el-form-item label="受训人员" prop="trainingEmpIds">
				<div>
					<el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
					<el-input placeholder="请输入关键字" v-model="keyword" @change="searchemployees"  @keyup="searchemployees" clearable></el-input>
					<div style="margin: 15px 0;"></div>
						<el-checkbox-group v-model="checkedEmployees" @change="handleCheckedEmployeesChange">
							<el-checkbox style="width:80px;" v-for="employee in filterEmployees" :label="employee.id" :key="employee.id">{{employee.name}}</el-checkbox>
						</el-checkbox-group>
				</div>
				</el-form-item>
			</el-col>
		
			<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">
					<el-form-item label="">
						<el-button v-if="isNewTrainingPlan" plain type="success" :loading="addLoading" @click="add">创建</el-button>
						<el-button v-else plain type="success" @click="update" :loading="updateLoading">更新</el-button>
						<el-button plain type="info" @click="close">关闭</el-button>
					</el-form-item>
			</el-col>
			</el-row>
		</el-form>
	</div>
</template>

<script>
import permission from '@/directive/permission/index.js'; // 权限判断指令
import { addTrainingPlan, updateTrainingPlan,findAllTrainingPlanNames,getTraingPlan,getEmployees } from '@/api/training/trainingPlan';
import { findAgencies } from '@/api/training/trainingAgency';
import { findTeachers } from '@/api/training/trainingTeacher';
export default {
	name: 'trainingPlanEdit',

	data() {
		let planInit = {};
		if (this.currentTrainingPlan == null || this.currentTrainingPlan == undefined) {
			planInit = {
				name: '',
				trainingType: '',
				trainingStart: '',
				trainingEnd: '',
				trainingTime: 0.0,
				trainingAgency:'',
				trainingAgencyName:'',
				trainingTeacher:'',
				trainingTeacherName:'',
				description:'',
				trainingPlace:'',
				//trainingPeople:"",
				companyId: this.currentCompanyId,
				companyName: this.currentCompanyName,
				status:'',

			};
		} else {
			//JS中对像，数组是引用，共享一个内存空间，子组件绑定修改会影响到父组件。如果不需要，改成复制拷呗去新空间
			// courseInit = this.currentCourse;
			planInit = { ...this.currentTrainingPlan };
		}
		return {
			trainingPlan: planInit,
			trainingCourses: [],
			trainingAgencies: [],
			trainingTeachers: [],
			trainingTypes: [],
			trainner: [],
			trainingType: 0,
			defaultProps: {
				children: 'children',
				label: 'companyName',
			},
			keyword:'',
			companyName: '',
			companySelect: '',
			companyId: '',
			addLoading: false,
			updateLoading: false,
			previousTrainingPlans:[],
			allEmployees:true,
			employees:[],
			filterEmployees:[],
			checkedEmployees:[],
			checkAll: false,
        	isIndeterminate: true
		};
	},
	props: {
		companyOptions: Array,
		isNewTrainingPlan: Boolean,
		currentTrainingPlan: Object,
		currentCompanyId: String,
		currentCompanyName: String,
	},

	mounted() {
		 this.getEmployees();
	},
	created() {
		this.getDicts('trainingPlan_type').then((response) => {
			this.trainingTypes = response.data;
		});

		const params = {
			// pageNum: this.queryParameters.pageNum,
			// pageSize: this.queryParameters.pageSize,
			// where: this.where,
			// order: '',
		};
		findTeachers(params)
			.then((response) => {
				this.trainingTeachers = response.data.records;
			})
			.catch((err) => {
				this.msgError('查询讲师失败，' + err);
			});

		findAgencies(params)
			.then((response) => {
				this.trainingAgencies = response.data.records;
			})
			.catch((err) => {
				this.msgError('查询机构失败，' + err);
			});
		if(this.isNewTrainingPlan)
		{
			
			findAllTrainingPlanNames()
			.then((response) => {
				this.previousTrainingPlans = response.data;
			})
			.catch((err) => {
				this.msgError('查询以前培训计划失败，' + err);
			});
		}
	},

	methods: {
		changeTraingType() {},

		// 节点单击事件
		handleNodeClick(data) {
			this.trainingPlan.companyId = data.id;
			this.trainingPlan.companyName = data.companyName;
			let selectArray = [];
			selectArray.push(this.trainingPlan.companyId);
			this.$refs.tree.setCheckedKeys(selectArray);

			this.$refs.companySelected.blur();
			this.getEmployees();
		},
		handleClearable() {
			this.$refs.tree.setCheckedKeys([]);
			this.trainingPlan.companyId = null;
			this.trainingPlan.companyName = '';
		},
		getEmployees(){
			if(this.trainingPlan.companyId){
				let that = this;
				getEmployees(this.trainingPlan.companyId)
				.then((response) => {
					this.employees = response.data;
					this.filterEmployees = this.employees;
					if(this.isNewTrainingPlan==false){
						let temp = that.trainingPlan.trainingEmployeeIds.split(',');
						temp.forEach(element => {
							that.checkedEmployees.push(parseInt(element));
						});
					}
					
				})
				.catch((err) => {
					this.msgError('获取此公司或部门员工失败，' + err);
				});
			}
			// else{
			// 	this.msgError('培训计划无指定公司');	
			// }
			
		},
		add() {
			let that = this;
			this.$refs['form'].validate((valid) => {
				if (valid) {
					that.addLoading = true;
					that.trainingPlan.status = "未发布";
					let agenceName = that.trainingPlan.trainingAgencyName==''?'':that.trainingAgencies.find(agency=>agency.id==that.trainingPlan.trainingAgency).name;
					let teacherName = that.trainingPlan.trainingTeacherName==''?"":that.trainingTeachers.find(teacher=>teacher.id==that.trainingPlan.trainingTeacher).name;
					that.trainingPlan.trainingAgencyName=agenceName;
					that.trainingPlan.trainingTeacherName=teacherName;
					that.trainingPlan.trainingEmployeeIds = this.checkedEmployees.join(",");
					addTrainingPlan(that.trainingPlan)
						.then((response) => {
							that.addLoading = false;
							that.msgSuccess('添加培训计划成功。');
							that.$emit('close', { refresh: true, isSuccess: true });
						})
						.catch((err) => {
							that.addLoading = false;
							that.msgError('添加培训计划失败，' + err);
						});
				} else {
					that.msgError('有非法输入或必填项未填写，请检查!');
				}
			});
		},
		update() {
			let that=this;
			this.$refs['form'].validate((valid) => {
				if (valid) {
					that.updateLoading = true;
					let agenceName = that.trainingPlan.trainingAgencyName==''?'':that.trainingAgencies.find(agency=>agency.id==that.trainingPlan.trainingAgency).name;
					let teacherName = that.trainingPlan.trainingTeacherName==''?"":that.trainingTeachers.find(teacher=>teacher.id==that.trainingPlan.trainingTeacher).name;
					that.trainingPlan.trainingAgencyName=agenceName;
					that.trainingPlan.trainingTeacherName=teacherName;
					that.trainingPlan.trainingEmployeeIds = that.checkedEmployees.join(",");
					updateTrainingPlan(that.trainingPlan)
						.then((response) => {
							that.updateLoading = false;
							that.msgSuccess('更新培训计划成功。');
							that.$emit('close', { refresh: true, isSuccess: true });
						})
						.catch((err) => {
							that.updateLoading = false;
							that.msgError('更新培训计划失败，' + err);
						});
				}
			});
		},
		close() {
			this.$emit('close', { refresh: false, isSuccess: true });
		},
		loadTrainingPlan(selectValue){
				
			getTraingPlan(selectValue)
			.then((response) => {
				this.trainingPlan = response.data;
				this.trainingPlan.id=null;
			})
			.catch((err) => {
				that.msgError('查询培训计划失败，' + err);
			});
		},

		handleCheckAllChange(val) {
			if(val){
				let employeeIds = [];
				this.employees.forEach(employee=>employeeIds.push(employee.id));
				this.checkedEmployees =employeeIds;
			}
			else{
				this.checkedEmployees = [];
			}
			this.isIndeterminate = false;
        },
        handleCheckedEmployeesChange(value) {
			let checkedCount = value.length;
			this.checkAll = checkedCount === this.employees.length;
			this.isIndeterminate = checkedCount > 0 && checkedCount < this.employees.length;
       },
	   searchemployees(){
			if(this.keyword){
				this.filterEmployees = this.employees.filter(item=> item.name.indexOf(this.keyword)>-1?true:false );
			}
			else{
				this.filterEmployees = this.employees;
			}
			
	   }
	},
};
</script>

<style lang="scss" scoped>
</style>