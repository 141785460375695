import request from '@/utils/request'

// 查询班次管理列表
export function listAttendanceClass(query) {
    return request({
        url: '/attendance/attendanceClass/findAll',
        method: 'post',
        data: query
    })
}

// 查询班次管理详细
export function getAttendanceClass(id) {
    return request({
        url: '/attendance/attendanceClass/findOne?id=' + id,
        method: 'post'
    })
}

// 新增班次管理
export function addAttendanceClass(data) {
    return request({
        url: '/attendance/attendanceClass/add',
        method: 'post',
        data: data
    })
}

// 修改班次管理
export function updateAttendanceClass(data) {
    return request({
        url: '/attendance/attendanceClass/edit',
        method: 'post',
        data: data
    })
}

// 删除班次管理
export function delAttendanceClass(id) {
    return request({
        url: '/attendance/attendanceClass/remove',
        method: 'post',
        data: id
    })
}

// 导出班次管理
export function exportAttendanceClass(query) {
    return request({
        url: '/attendance/attendanceClass/export',
        method: 'post',
        data: query
    })
}
