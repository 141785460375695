import request from '@/utils/request'

// 查询提醒设置列表
export function listRemindSetting(query) {
    return request({
        url: '/schedule/remindSetting/findAll',
        method: 'post',
        data: query
    })
}

// 查询提醒设置详细
export function getRemindSetting(id) {
    return request({
        url: '/schedule/remindSetting/findOne?id=' + id,
        method: 'post'
    })
}

// 新增提醒设置
export function addRemindSetting(data) {
    return request({
        url: '/schedule/remindSetting/add',
        method: 'post',
        data: data
    })
}

// 修改提醒设置
export function updateRemindSetting(data) {
    return request({
        url: '/schedule/remindSetting/edit',
        method: 'post',
        data: data
    })
}

// 删除提醒设置
export function delRemindSetting(id) {
    return request({
        url: '/schedule/remindSetting/remove',
        method: 'post',
        data: id
    })
}

// 导出提醒设置
export function exportRemindSetting(query) {
    return request({
        url: '/schedule/remindSetting/export',
        method: 'post',
        data: query
    })
}
