<template>
  <div>
    <el-form ref="form" :model="form" label-width="86px">
      <el-tabs v-model="activeName" type="border-card">
        <el-tab-pane label="基本设置" name="baseSetting">
          <el-row>
            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
              <el-form-item label="所在考勤组" prop="">
                <span style="color: #20B2AA">{{attGroup.name}}</span>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
              <el-form-item label="班制类型" prop="">
                <span style="color: #20B2AA">{{getDictDataLabel(DICT_TYPE.ATT_TYPE,attGroup.type)}}</span>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
              <el-form-item label="考勤管理员" prop="">
                <span style="color: #20B2AA">{{attManager}}</span>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
              <el-form-item label="打卡方式">
                <span style="color: #adc750">{{attGroup.importClock?"导入考勤数据打卡":""}}  </span>
                <span style="color: #2645e2">{{attGroup.cardClock?"刷卡打卡":""}}  </span>
                <span style="color: #b80871">{{attGroup.mobileClock?"手机打卡":""}}  </span>
              </el-form-item>
            </el-col>
            <el-col v-if="attGroup.mobileClock" :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
              <el-form-item label="是否拍照" prop="photo">
                <span style="color: #20B2AA">{{attGroup.photo?"拍照打卡":"不用拍照打卡"}}</span>
              </el-form-item>
            </el-col>
          <el-row>
          </el-row>
            <el-col v-if="attGroup.mobileClock" :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">
              <el-form-item label="打卡地点" >
                <el-tag v-for="(addr,idx) in attGroup.addr" :key="idx">{{addr.addr+'('+addr.range+'米内)'}}</el-tag>
              </el-form-item>
            </el-col>
<!--            <el-col v-if="attGroup.mobileClock" :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">-->
<!--              <el-form-item label="有效范围">-->
<!--                <span style="color: #20B2AA">{{attGroup.addr.range?attGroup.addr.range+"米内打卡":"未设置"}}</span>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
          </el-row>
        </el-tab-pane>
        <el-tab-pane v-if="attGroup.photo" label="照片设置" name="clockPhoto">
          <template v-if="photoUrl" >
            <el-row>
              <el-col>
                <h3>打卡对比照片(请上传两张正脸打卡地照片):</h3>
              </el-col>
            </el-row>
            <el-row>
              <el-col v-for="photo in photoUrl" :key="photo.url" :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
                <el-image :ref="photo.url" :id="photo.url"
                    style="width: 100px; height: 100px"
                    :src="photo.url"
                    :preview-src-list="srcList">
                </el-image>
                <div>
                  <el-button @click="deletePhoto(photo)">删除</el-button>
                  <span>审批状态：{{photo.approved?"通过":photo.approved==null?"审核中":"未通过"}}</span>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col>
                <canvas ref="firstCanvas" style="width: 300px; height: 200px;" id="firstCanvas"></canvas>
                <canvas id="canvas" style="position: fixed;left: -999999px; top: -999999px;"></canvas>
              </el-col>
            </el-row>
          </template>
          <template v-else>
          </template>
          <el-row>
            <el-col>
              <h3>添加新的打卡照片:</h3>
            </el-col>
          </el-row>
          <el-row>
            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
    <!--          <el-form-item label="打卡照片" prop="">-->
                <div style="margin-bottom: 10px ;margin-top: 10px">
                  <el-upload
                      ref="upload"
                      action="uploadFile"
                      :http-request="uploadFile"
                      :auto-upload="true"
                      :list-type="listType"
                      :file-list="fileList"
                      accept=".jpg,.jpeg,.png"
                      :before-upload="beforeUpload"
                      :on-change="onFileChange"
                      :on-preview="handlePictureCardPreview"
                      :on-remove="handleRemove">
    <!--                <img v-if="form.photoAddr" :src="form.photoAddr" class="avatar" />-->
        <!--            <i v-else class="el-icon-plus avatar-uploader-icon"></i>-->
                    <i class="el-icon-plus"></i>
                  </el-upload>
                </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <div v-show="sampleSrc" id="background" class="o_image">识别到的人脸：
                <img id="sample" :src="sampleSrc" alt="facedetection" />
              </div>
            </el-col>
          </el-row>
          <div style="text-align: center">
            <el-button type="primary" @click="submitForm">保 存</el-button>
<!--            <el-button @click="cancel">取 消</el-button>-->
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-form>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>

<script>
import {getPreviewFileUrl} from "@/api/comapi/comapi";
import {listAttMySetting,getAttMySetting,delAttMySetting,addAttMySetting,updateAttMySetting,exportAttMySetting } from "@/api/attendance/attMySetting";
import {getGroupByEmpId} from "@/api/attendance/attendanceGroup";
import { showLoading, hideLoading } from '@/utils/reqloading';
import {DICT_TYPE,getDictLabelFromData, getDictDataLabel, getDictDatas} from "@/utils/dict";
import {getEmploy,listSimpleEmploy} from "@/api/employ/employ";
import {getQueryParam} from "../../../../utils/tools";
import 'tracking/build/tracking-min.js';
import 'tracking/build/data/face-min.js';
import "./face";
import {setFaceImg} from "./face";


export default {
  name: "setting",
  components: {

  },
  computed: {
    srcList(){
      let urlList = [];
      this.photoUrl.forEach(item=>{
        urlList.push(item.url);
      })
      console.log("srcList")
      console.log(urlList)
      return urlList;
    }
  },
  watch: {
    "form.photoAddr"(){

    },
    imageDatas(){
      console.log("imageData changed len = "+this.imageDatas.length);
      if(this.imageDatas.length > 1){

      }
    }
  },
  data() {
    return {
      activeName: 'baseSetting',
      // 表单参数
      form: {},
      attGroup: {addr:{}},
      attManager: '',
      formData: new FormData(),
      photoUrl: [],
      formReadOnly: false,
      fileList: [],
      listType: 'picture-card',
      dialogImageUrl: '',
      dialogVisible: false,
      imageDatas: [],
      cornerses:[],
      descriptorses:[],
      sampleSrc: null,
    }
  },
  created() {
    this.load()
  },
  methods:{
    load(){
      console.log("load ,tracking")
      console.log(tracking)
      console.log(this.$store.getters.userInfo.employ)
      if(!this.$store.getters.userInfo.employ || !this.$store.getters.userInfo.employ.id){
        this.$message.error('当前账号不是员工，不能设置本人考勤信息！');
        return
      }
      let empId = this.$store.getters.userInfo.employ.id;
      console.log(empId);
      showLoading();
      const rtGroup = getGroupByEmpId(empId).then(response => {
        this.attGroup =  response.data;
        if(this.attGroup.managerEmp){
          let empIds = this.attGroup.managerEmp.split(",");
          let filter = {};
          filter['id'] = {
            type: 'in',
            value: empIds,
          }
          let param = this.getQueryParam(filter,1,2);
          console.log(param)
          listSimpleEmploy(param).then(resp=>{
            let manager = ""
            if(resp.data.records.length>0){
              for(let i = 0; i < resp.data.records.length;i++){
                manager += resp.data.records[i].name+"，"
              }
            }
            console.log(manager)
            this.attManager = manager.substring(0, manager.length-1)
          });
        }
        hideLoading();
      });
      let filter = {};
      filter['empId'] = {
        type: 'eq',
        value: empId,
      }
      let param = this.getQueryParam(filter,1,2);
      console.log(param)
      listAttMySetting(param).then(response =>{
        // 等等组信息返回
        Promise.all([rtGroup]).then(()=>{
          if(response.data.records && response.data.records.length>0){
            this.form = response.data.records[0];
            this.form.groupId = this.attGroup.id;
            this.form.groupName = this.attGroup.name;
            this.form.groupManager = this.attGroup.managerEmp;
            this.getPhotoPreviewUrl();
          }else {
            this.form={companyId:this.$store.getters.userInfo.companyId,userId:this.$store.getters.userInfo.id,empId,
              empName:this.$store.getters.userInfo.employ.name,groupId:this.attGroup.id, groupName:this.attGroup.name,
              groupManager:this.attGroup.managerEmp};
          }
        })
      })
    },
    getPhotoPreviewUrl(){
      console.log("watch form.photoAddr before:")
      console.log(this.photoUrl)
      let photoUrl = [];
      let promises = [];
      if(this.form.photoAddr&&this.form.photoAddr.length>0){
        this.form.photoAddr.forEach(item=>{
          promises.push(getPreviewFileUrl(item.url).then(resp=>{
            photoUrl.push({name:item.name,url:resp.data, approved:item.approved,realUrl:item.url})
          }))
        })
      }
      // 等所有返回
      Promise.all(promises).then(()=>{
        this.photoUrl = photoUrl;
        console.log("watch form.photoAddr")
        console.log(this.photoUrl)
        var width = 100;
        var height = 295;
        console.log("window")
        // console.log(window)
        // let descriptorLength = 256;
        // let matchesShown = 30;
        let blurRadius = 3;

        console.log("createCanvasContext")

        // let canvas = this.$refs.firstCanvas;
        let canvas =document.getElementById("firstCanvas");
        // let canvas = this.$refs.firstCanvas;
        let context = canvas.getContext('2d');
        console.log(context)
        let that = this;
        setTimeout(function(){
          console.log("setTimeout")
          console.log(that.photoUrl)
          let cornerses = [];
          let descriptorses = [];
          let grays = [];
          let imgdt = []
          if(that.photoUrl && that.photoUrl.length>0){
            that.photoUrl.forEach(item=>{
              // let img = that.$refs[item.url]
              // let img =document.getElementById(item.url);
              let image = new Image();
              image.crossOrigin = 'anonymous'
              image.src = item.url;
              image.onload = function(){
                console.log("image.onload")
                const canvas = document.getElementById("canvas");
                const context = canvas.getContext('2d')
                const scale = this.width / this.height
                let width = this.width;
                let height = this.height
                context.drawImage(image, 0, 0,this.width,this.height);
                let imgData = context.getImageData(0,0,this.width,this.height);
                console.log("imgData")
                console.log(imgData)
                that.imageDatas.push(imgData);
                let gray = tracking.Image.grayscale(tracking.Image.blur(imgData.data, this.width, this.height, 3), this.width, height);
                console.log("gray")
                console.log(gray)
                // grays.push(gray);
                let corners = tracking.Fast.findCorners(gray, this.width, this.height);
                console.log("corners")
                console.log(corners)
                // cornerses.push(corners)
                that.cornerses.push(corners)
                let descriptors = tracking.Brief.getDescriptors(gray, this.width, this.height);
                // descriptorses.push(descriptors)
                console.log("descriptors")
                console.log(descriptors)
                that.descriptorses.push(descriptors)
                if(that.cornerses.length>1){
                  // context.putImageData(imgdt[0],0,0)
                  // context.putImageData(imgdt[1],0,height)
                  // console.log("do matches cornerses.len="+cornerses.length+",descriptorses.len="+descriptorses.length)
                  console.log(that.cornerses)
                  console.log("do matches")
                  let matches = tracking.Brief.reciprocalMatch(that.cornerses[0], that.descriptorses[0], that.cornerses[1], that.descriptorses[1]);
                  console.log(matches)
                  matches.sort(function(a, b) {
                    return b.confidence - a.confidence;
                  });
                  console.log("matches sort:"+matches.length)
                  console.log(matches)
                }
              }
              // img.crossOrigin = 'anonymous'
              // console.log(img)
              // img = document.getElementById(item.url);
              // console.log(img)
              // context.drawImage(img,0,0,width,height)
              // let imgData = context.getImageData(0,0,width,height)
              // console.log("imgData")
              // console.log(imgData)
              // imgdt.push(imgData)
            })
            // if(cornerses.length>1){
            //   context.putImageData(imgdt[0],0,0)
            //   context.putImageData(imgdt[1],0,height)
            //   console.log("do matches cornerses.len="+cornerses.length+",descriptorses.len="+descriptorses.length)
            //   console.log(cornerses)
            //   console.log("do matches")
            //   let matches = tracking.Brief.reciprocalMatch(cornerses[0], descriptorses[0], cornerses[1], descriptorses[1]);
            //   console.log(matches)
            //   matches.sort(function(a, b) {
            //     return b.confidence - a.confidence;
            //   });
            //   console.log("matches sort:"+matches.length)
            //   console.log(matches)
            // }
          }
        },2000)
      })
    },
    readImage(url){
      let image = new Image();
      image.src = url;
      image.onload = function(){
        // const canvas = document.createElement('canvas')
        const canvas = document.getElementById("canvas");
        const context = canvas.getContext('2d')
        const scale = this.width / this.height
        context.drawImage(image, 0, 0,this.width,this.height);
        let imgData = context.getImageData(0,0,this.width,this.height);
        console.log("imgData")
        console.log(imgData)
        this.imageDatas.push(imgData);
      }
    },
    deletePhoto(photo){
      console.log(photo)
      this.photoUrl.splice(this.photoUrl.indexOf(photo),1)
      for(let i = 0; i < this.form.photoAddr.length;i++){
        if(this.form.photoAddr[i].url == photo.realUrl){
          console.log("i="+i)
          this.form.photoAddr.splice(i,1)
          console.log(this.form.photoAddr)
          break;
        }
      }
    },
    uploadFile(params) {
      console.log("uploadFile");
      console.log(params);
      this.formData.append('files', params.file);
    },
    handleAvatarSuccess(res, file) {
      //this.form.photo = URL.createObjectURL(file.name);
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    onFileChange(file, fileList){
      console.log(file)
      console.log(fileList)
      // this.$set(this.form,"photoAddr", file.url)
      // this.listType = 'text'
      // this.form.photoAddr = file.url;
    },
    beforeUpload(file) {
      console.log("beforeUpload");
      console.log(file);
      const isJPG = file.type === 'image/jpeg';
      const isPNG = file.type === 'image/png';
      const fileSize = file.size / 1024 / 1024;
      const isLt5M = fileSize < 1;
      if (!isJPG && !isPNG) {
        this.$message.error('上传图片只能是 JPG/PNG 格式!');
        return false;
      }
      if (!isLt5M) {
        this.$message.error('上传图片大小不能超过 1MB!当前文件大小是：'+fileSize+"MB, 可以将相机照片质量设置为低拍照");
        return false;
      }
      let url = URL.createObjectURL(file);
      console.log(url);
      let p = new Promise((resolve,reject)=>{
        setFaceImg(url, (data)=>{
          console.log(data)
          resolve(data)
        })
      })
      p.then(data=>{
        if(data.faceRects.length>0){
          this.sampleSrc = data.faceDataUrl[0];
        }else{
          this.$message.error('没有检测到人脸，请重新上传包括人脸的照片');

        }
      });
      if((isJPG || isPNG)&& isLt5M){
        return true;
      }else{
        return false;
      }
    },
    submitForm(){
      console.log("submitform")
      let self = this;
      this.$refs["form"].validate(valid => {
        if (valid) {
          showLoading();
          self.formData = new FormData();
          //uploadFile会逐个加入到formData
          console.log("upload")
          self.$refs.upload.submit();
          console.log(self.formData)
          console.log(this.form)
          if(!this.form.groupId){
            this.form.groupId = this.attGroup.id
            this.form.groupName = this.attGroup.name;
            this.form.groupManager = this.attGroup.managerEmp;
          }
          self.formData.append('attMySettingStr', JSON.stringify(this.form)); // isOverride自定义的其他参数
          console.log(self.formData)
          self.sampleSrc = null;
          if (this.form.id == null) {
            addAttMySetting(self.formData).then(response =>{
              this.form = response.data;
              this.getPhotoPreviewUrl();
              this.msgSuccess("保存成功");
              this.showSubmit = false;
              this.fileList = []
              hideLoading();
            })
          } else {
            updateAttMySetting(self.formData).then(response =>{
              this.form = response.data;
              this.getPhotoPreviewUrl();
              this.msgSuccess("保存成功");
              this.showSubmit = false;
              this.fileList = []
              hideLoading();
            })

          }
        }
      });
    },


  },
}
</script>

<style scoped>

</style>
