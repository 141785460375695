<template>
    <div>
        <el-form :model="queryParameters" ref="queryParameters" class="employ" label-width="100px">
			<el-row :gutter="24">
				<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
					<el-form-item label="起始时间">
						<el-date-picker v-model="queryParameters.startDate" value-format="yyyy-MM-dd 00:00:00" align="left" type="date" placeholder="选择日期">
						</el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
					<el-form-item label="终止时间">
						<el-date-picker v-model="queryParameters.endDate" value-format="yyyy-MM-dd 24:00:00" align="left" type="date" placeholder="选择日期">
						</el-date-picker>
					</el-form-item>
				</el-col>
                <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0" >
					<el-form-item label=" " style="margin-bottom:0px !important;">
						<el-button v-on:click="query" size="mini" icon="el-icon-search" plain type="primary">查询</el-button>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>

		<el-row :gutter="24">
		<el-table v-loading="loading" :data="myTrainingList" style="width: 100%;" max-height="100%" stripe border>
			<el-table-column label="培训名称" align="center" prop="trainingName" sortable  min-width="150" :show-overflow-tooltip="true" />
			<el-table-column label="培训类型" align="center" prop="trainingType" sortable  min-width="100" />
			<el-table-column label="培训日期" align="center" prop="createTime" sortable  min-width="180" >
			</el-table-column>
			<el-table-column label="是否完成" align="center" prop="completed"  min-width="100"  sortable>
				<template slot-scope="scope">
              	{{completedFormat(scope.row, null)}}
           		</template>
			</el-table-column>
			<el-table-column label="分数" align="center" prop="score"  min-width="100" sortable :show-overflow-tooltip="true" />
			<el-table-column label="等级" align="center" prop="level"  min-width="80" sortable :show-overflow-tooltip="true" />
			<el-table-column label="五星" align="center" prop="stars"  min-width="150" sortable :show-overflow-tooltip="true" />
			<el-table-column label="评价" align="center" prop="comments" sortable min-width="100" />
		</el-table>
		</el-row>
		<pagination v-show="total > 0" :total="total" :page.sync="queryParameters.pageNum" :limit.sync="queryParameters.pageSize" @pagination="query" />

    </div>
</template>

<script>
import {findAllTrainings} from '@/api/training/myTraining';
import Pagination from "@/components/Pagination";
export default {
    name: 'MyTraining',
	components: {
		Pagination
	},
    data() {
        return {
            queryParameters: {
				startDate: '',
				endDate: '',
				name: '',
				companyId:'',
				pageSize: 10,
				pageNum: 1,
			},
			where: {
				query: '',
				filters: [],
			},
            myTrainingList:[],
            loading:false,
			total:0,
        };
    },

    mounted() {
        this.query();
    },

    methods: {
        query() {
			let filter = {};
            
            filter['userId'] = {
					type: 'eq',
					value: this.$store.getters.userInfo.id,
			};

			if (this.queryParameters.startDate && this.queryParameters.endDate) {
				let tempDate = this.queryParameters.startDate.replace(/-/g, '/'); //必须把日期'-'转为'/'
				var startDateTimeStamp = new Date(tempDate).getTime();
				tempDate = this.queryParameters.endDate.replace(/-/g, '/');
				var endDateTimeStamp = new Date(tempDate).getTime();
				filter['createdDate'] = {
					type: 'timestamp',
					value: [startDateTimeStamp, endDateTimeStamp],
				};
			}

			const filters = this.parseFilters(filter);
			if (filters && filters.length > 0) {
				this.where.filters = filters;
			} else {
				// 如果过滤条件为空，设置空数组
				this.where.filters = [];
			}
			const params = {
				pageNum: this.queryParameters.pageNum,
				pageSize: this.queryParameters.pageSize,
				where: this.where,
				order: '',
			};
			this.loading = true;
			let that = this;
			findAllTrainings(params).then((response) => {
				that.myTrainingList = response.data.records;
				that.total = response.data.total;
				that.loading = false;

			}).catch((err) => {
                that.loading = false;
				that.msgError('获取培训记录失败，' + err);
			});
		},
		completedFormat(row) {
			if(row.completed){
				return "是";
			}
			else{
				return "否";
			}
    	},
    },
};
</script>

<style lang="scss" scoped>

</style>