import request from '@/utils/request'

// Find all 机构
export function findAgencies(query) {
    return request({
        url: '/TrainingAgency/findAll',
        method: 'post',
        data: query
    })
}

// 新增机构信息
export function addTrainingAgency(data) {
    return request({
        url: '/TrainingAgency/add',
        method: 'post',
        data: data
    })
}

// 更新机构信息
export function updateTrainingAgency(data) {
    return request({
        url: '/TrainingAgency/edit',
        method: 'post',
        data: data
    })
}

// 删除机构信息
export function deleteAgencies(ids) {
    return request({
        url: '/TrainingAgency/remove',
        method: 'post',
        data: ids
    })
}

// 上传机构相片
export function UploadAgancyPicture(data) {
    return request({
        url: '/TrainingAgency/UploadAgancyPicture',
        method: 'post',
        data: data,
        responseType: 'blob'
    })
}