import request from '@/utils/request'

// 查询调休假结算列表
export function listCbSettleLieu(query) {
    return request({
        url: '/cb/cbSettleLieu/findAll',
        method: 'post',
        data: query
    })
}

// 查询调休假结算详细
export function getCbSettleLieu(id) {
    return request({
        url: '/cb/cbSettleLieu/findOne?id=' + id,
        method: 'post'
    })
}

// 新增调休假结算
export function addCbSettleLieu(data) {
    return request({
        url: '/cb/cbSettleLieu/add',
        method: 'post',
        data: data
    })
}

// 修改调休假结算
export function updateCbSettleLieu(data) {
    return request({
        url: '/cb/cbSettleLieu/edit',
        method: 'post',
        data: data
    })
}

// 删除调休假结算
export function delCbSettleLieu(id) {
    return request({
        url: '/cb/cbSettleLieu/remove',
        method: 'post',
        data: id
    })
}

// 导出调休假结算
export function exportCbSettleLieu(query) {
    return request({
        url: '/cb/cbSettleLieu/export',
        method: 'post',
        data: query
    })
}

// 修改调休假结算
export function settle(data) {
    return request({
        url: '/cb/cbSettleLieu/settle',
        method: 'post',
        data: data
    })
}
