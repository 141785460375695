// 定义内容
export default {
	login: {
		placeholder1: 'Please enter the username',
		placeholder2: 'Please enter the password',
		placeholder3: 'Please enter the verification code',
		btnText: 'Sign in',
		link: {
			one1: 'Third party login',
			one2: 'Links',
		},
		signInText: 'welcome back!',
		copyright: {
			one5: 'Copyright: Shenzhen haoya *** Technology Co., Ltd',
			two6: 'Copyright: Shenzhen haoya *** technology Guangdong ICP preparation no.*****',
		},
	},
};
