<template>
	<div style="margin-top: 20px">
		<div v-if="this.currentTraining.testPaperId && !this.currentTrainingcompleted">
        		<testing-paper :selectTestPaperId="this.currentTraining.testPaperId" :testCount="-1"  ></testing-paper>
    		</div>
		<div>
			<div style="margin: 10px 0px"><span>评价</span></div>
			<el-input type="textarea" v-model="comments" :rows="3" placeholder="" style="width: 90%"></el-input>
			<div style="margin: 10px 0px">
				<el-rate v-model="stars" :colors="colors"> </el-rate>
			</div>
		</div>
		<div>
			<el-button plain :loading="updateLoading" type="success" @click="submit">提交评价</el-button>
            <el-button plain type="success" @click="close">关闭</el-button>
		</div>

		<div style="margin-top: 20px"></div>
	</div>
</template>

<script>
import { updateMyTraining,findTrainingRecord } from '@/api/training/myTraining';
export default {
	name: 'finishTraining',

	data() {
       
		return {
			colors: ['#99A9BF', '#F7BA2A', '#FF9900'],
			stars:0,
			comments: '',
			updateLoading: false,
		};
	},
	props: { currentTraining: Object },
	mounted() {},
    created(){
        this.GetCourseRecord();
    },
	methods: {
		submit: function () {
			let myTraining = {};
			myTraining.userId = this.$store.getters.userInfo.id;
			myTraining.employeeName = this.$store.getters.userInfo.username;
			myTraining.trainingName = this.currentTraining.name;
			myTraining.trainingType = "培训计划";
			myTraining.trainingId = this.currentTraining.id;
			myTraining.testPaperId = this.currentTraining.testPaperId;
			myTraining.comments = this.comments;
			myTraining.stars = this.stars;
			myTraining.completed = true;
			this.updateLoading = true;
			updateMyTraining(myTraining)
				.then((response) => {
					this.updateLoading = false;
					this.msgSuccess('评价成功。');
				})
				.catch((err) => {
					this.updateLoading = false;
					this.msgError('评价失败，' + err);
				});
		},
        GetCourseRecord()
		{
			let myTraining={};
			myTraining.userId =this.$store.getters.userInfo.id;
			myTraining.courseId= this.currentTraining.id;
			findTrainingRecord(myTraining)
			.then((response) => {
				if(response.data)
				{
					this.comments=response.data.comments;
                    this.stars = response.data.stars;
                    console.log('获取培训记录成功。');
				}
			})
			.catch((err) => {
				console.log('获取培训记录失败，');
			});
		},
        close(){
            this.$emit('close', { refresh: false, isSuccess: true });
        }
	},
};
</script>

<style lang="scss" scoped>
</style>