<template>
  <div>
    <el-form ref="form" :model="form" :rules="rules" label-width="90px">
      <el-row :gutter="35" align="center">
      </el-row>
      <el-row :gutter="35">
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0" align="center">
          <el-form-item label="所属公司" prop="companyId">
            <treeselect disabled v-model="form.companyId"
                        :options="companyOptions"
                        :normalizer="normalizerCompany" placeholder="请选择所属公司"/>
            <!--              <el-input v-model="form.companyId" placeholder="请输入所属部门"/>-->
          </el-form-item>
        </el-col>
<!--        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">-->
<!--          <el-form-item label="上级岗位" prop="pid">-->
<!--            <treeselect :disabled="isReadOnly" v-model="form.pid"-->
<!--                        :options="positionOptions"-->
<!--                        :normalizer="normalizerPosition" placeholder="请选择上级岗位"/>-->
<!--          </el-form-item>-->
<!--        </el-col>-->
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
          <el-form-item label="岗位编号" prop="num">
            <el-input :disabled="isReadOnly" v-model="form.num" placeholder="不输入则自动编号"/>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
          <el-form-item label="岗位名称" prop="name">
            <el-input :disabled="isReadOnly" v-model="form.name" placeholder="请输入岗位名称"/>
          </el-form-item>
        </el-col>
<!--        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">-->
<!--          <el-form-item label="编制人数" prop="planCount">-->
<!--            <el-input :disabled="isReadOnly" v-model="form.planCount" placeholder="为空则不限制"/>-->
<!--          </el-form-item>-->
<!--        </el-col>-->
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
          <el-form-item label="岗级" prop="postLevel">
            <el-select style="width: 100%" :disabled="isReadOnly" v-model="form.postLevel" placeholder="请选择岗级">
              <el-option
                  v-for="dict in postLevelOptions"
                  :key="dict.dictValue"
                  :label="dict.dictLabel"
                  :value="parseInt(dict.dictValue)"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
<!--        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">-->
<!--          <el-form-item label="排序顺序" prop="seq">-->
<!--            <el-input :disabled="isReadOnly" v-model="form.seq" placeholder="请输入排序顺序"/>-->
<!--          </el-form-item>-->
<!--        </el-col>-->

<!--        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">-->
<!--          <el-form-item label="实际人数" prop="realCount">-->
<!--            <el-input :disabled="isReadOnly" v-model="form.realCount" placeholder="请输入实际人数"/>-->
<!--          </el-form-item>-->
<!--        </el-col>-->
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
          <el-form-item label="工资等级" prop="salaryLevel">
            <el-input :disabled="isReadOnly" v-model="form.salaryLevel" placeholder="请输入工资等级"/>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
          <el-form-item label="描述" prop="introduction">
            <el-input :disabled="isReadOnly" v-model="form.introduction" placeholder="请输入描述"/>
          </el-form-item>
        </el-col>
<!--        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">-->
<!--          <el-form-item label="备注" prop="note">-->
<!--            <el-input :disabled="isReadOnly" v-model="form.note" placeholder="请输入备注"/>-->
<!--          </el-form-item>-->
<!--        </el-col>-->
        <!--          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">-->
        <!--            <el-form-item label="图标" prop="avatar">-->
        <!--              <el-input :disabled="isReadOnly" v-model="form.avatar" placeholder="请输入图标"/>-->
        <!--            </el-form-item>-->
        <!--          </el-col>-->
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer" style="text-align: right">
      <el-button v-if="!isReadOnly"  type="primary" @click="submitForm" v-permission="['/org/position/add', '/org/position/edit']">确 定</el-button>
      <el-button @click="cancel">取 消</el-button>
      <el-popover
          placement="top"
          title=""
          width="200"
          trigger="click"
          content="如果公司设置了编制人数，则各岗位编制人数总和不能超过公司编制人数限制。">
        <el-button type="text" round slot="reference">？</el-button>
      </el-popover>
    </div>

  </div>
</template>

<script>
import {
  listPosition,
  getTreeselectPosition,
  getPosition,
  addPosition,
  updatePosition,
 } from "@/api/org/position";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import permission from '@/directive/permission/index.js'; // 权限判断指令
import {getTreeselectCompany} from "@/api/org/company";

export default {
  name: "positionEdit",
  components: {
    Treeselect,

  },
  props: {
    row: Object,
    companyOptionsIn: Array,
    positionOptionsIn: Array,
    isReadOnly: Boolean,
    companyId: Number,
  },
  directives: { permission },
  watch:{
    row(n,o){ //n为新值,o为旧值;
      //if(n === o) return;
      this.load();
    },
    companyId(n,o){
      // this.load();
      if(this.companyOptionsIn){
        this.companyOptions = this.companyOptionsIn;
      }else{
        this.companyOptions = [];
        this.getCompany();
      }
      this.form.companyId = this.companyId;
    },
    positionOptionsIn(){
      if(this.positionOptionsIn){
        this.positionOptions = [];
        const data = { id:0,
          name:'顶级岗位',
          children: []
        };
        data.children = this.positionOptionsIn;
        this.positionOptions.push(data);
      }else{
        this.positionOptions = [];
        this.getPositison(this.companyId);
      }
    },
  },
  data() {
    return {
      companyOptions: [],
      //
      postLevelOptions: [],
      positionOptions: [],
      // 表单参数
      form: {
      }
      ,
      // 表单校验
      rules: {
        // num: [
        //   {
        //     required: true,
        //     message: "岗位编号不能为空", trigger: "blur" }
        // ],
        name: [
          {
            required: true,
            message: "岗位名称不能为空", trigger: "blur" }
        ],
        pid: [
          {
            required: true,
            message: "父岗位不能为空", trigger: "blur" }
        ],
        companyId: [
          {
            required: true,
            message: "所属公司不能为空", trigger: "blur" }
        ],
        // postLevel: [
        //   {
        //     required: true,
        //     message: "岗级id不能为空", trigger: "blur" }
        // ],
        // seq: [
        //   {
        //     required: true,
        //     message: "排序顺序不能为空", trigger: "blur" }
        // ],
      }
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.reset();
      this.getDicts("org_position_level").then(response => {
        this.postLevelOptions = response.data;
      });

      if(this.companyOptionsIn){
        this.companyOptions = this.companyOptionsIn;
      }else{
        this.companyOptions = [];
        this.getCompany();
      }
      if(this.positionOptionsIn){
        this.positionOptions = [];
        const data = { id:0,
          name:'顶级岗位',
          children: []
        };
        data.children = this.positionOptionsIn;
        this.positionOptions.push(data);
      }else{
        this.positionOptions = [];
        this.getPositison(this.companyId);
      }
      console.log(this.positionOptions)
      if(this.row && this.row.id){
        this.handleUpdate(this.row);
      }else{
        this.handleAdd();
      }
   },
    /** 转换公司数据结构 */
    normalizerCompany(node) {
      return this.normalizer(node,'id','companyName');
    },
    /** 查询公司下拉树结构 */
    getCompany() {
      getTreeselectCompany(null, (list, options) =>{
        this.companyList = list;
        if(this.companyList.length > 1){
          this.isCompnayVisible = true;
        }
        this.companyOptions = options;
      });
    },
    normalizerPosition(node){
      return this.normalizer(node);
    },
    /** 查询岗位下拉树结构 */
    getPositison(companyId) {
      getTreeselectPosition(companyId, true, (list, options) =>{
        this.positionList = list;
        this.positionOptions = options;
      });
    },
    // 取消按钮
    cancel() {
      // this.reset();
      this.$emit("close", {refresh:false,isSuccess:true});
    },
    // 表单重置
    reset() {
      this.form = {};
      this.form.companyId = this.companyId;
      // this.postLevelList = [];
      this.resetForm("form");
    },

    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.form.companyId = this.companyId;
      this.title = "添加岗位管理";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.id || this.ids
      getPosition(id).then(response => {
        this.form = response.data;
        // this.postLevelList = response.data.postLevelList;
      });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate(valid => {
        if(valid) {
          console.log(this.form);
          // this.form.postLevelList = this.postLevelList;
          if (this.form.id != null) {
            updatePosition(this.form).then(response => {
              this.msgSuccess("修改成功");
              this.$emit("close", {refresh:true,isSuccess:true});
              this.reset();
            });
          } else {
            addPosition(this.form).then(response => {
              this.msgSuccess("新增成功");
              this.$emit("close", {refresh:true,isSuccess:true});
              this.reset();
            });
          }
        }
      });
    },
  },



}
</script>

<style scoped>

</style>
