<template>
<div>
  <el-tabs v-model="activeName">
    <el-tab-pane label="巡逻人员" name="emp">
      <AttPatrolEmp></AttPatrolEmp>
    </el-tab-pane>
    <el-tab-pane label="巡逻记录" name="records">
      <AttPatrolClock></AttPatrolClock>
    </el-tab-pane>
  </el-tabs>
</div>
</template>

<script>
import AttPatrolEmp from "../attPatrolEmp";
import AttPatrolClock from "../attPatrolClock"
export default {
  name: "index",
  components: {AttPatrolEmp,AttPatrolClock},
  data() {
    return {
      activeName: 'emp',
    }
  },
}
</script>

<style scoped>

</style>