<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px">
      <el-form-item label="公司名称" prop="companyId">
        <treeselect v-model="queryParams.companyId" style="width: 100%"
                    :options="companyOptions"
                    :normalizer="normalizerCompany" placeholder="请选择所属公司"/>
      </el-form-item>
      <el-form-item label="模板名" prop="name">
        <el-input
            v-model="queryParams.name"
            placeholder="请输入模板名"
            clearable
            size="large"
            @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="类型" prop="type">
        <el-select v-model="queryParams.type" placeholder="请选择模板类型： 社保，公积金" clearable
                   size="large">
          <el-option v-for="item in getDictDatas(DICT_TYPE.CB_TEMPLATE_TYPE)" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled"/>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
            type="primary"
            plain
            icon="el-icon-plus"
            size="mini"
            @click="handleAdd"
            v-permission="['/cb/cbTemplate/add']"
        >新增
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
            type="success"
            plain
            icon="el-icon-edit"
            size="mini"
            :disabled="single"
            @click="handleUpdate"
            v-permission="['/cb/cbTemplate/edit']"
        >修改
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
            type="danger"
            plain
            icon="el-icon-delete"
            size="mini"
            :disabled="multiple"
            @click="handleDelete"
            v-permission="['/cb/cbTemplate/remove']"
        >删除
        </el-button>
      </el-col>
<!--      <el-col :span="1.5">-->
<!--        <el-button-->
<!--            type="warning"-->
<!--            plain-->
<!--            icon="el-icon-download"-->
<!--            size="mini"-->
<!--            @click="handleExport"-->
<!--            v-permission="['/cb/cbTemplate/export']"-->
<!--        >导出-->
<!--        </el-button>-->
<!--      </el-col>-->
      <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>
    </el-row>

    <el-table v-loading="loading" :data="cbTemplateList" @selection-change="handleSelectionChange" stripe border>
      <el-table-column type="selection" width="55" align="center"/>
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-form label-position="right" inline class="demo-table-expand">
            <el-row :key="item.itemName" v-for="item in props.row.itemInfo">
              <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
                <el-form-item :label="item.itemName">
                  <span>{{ item.baseAmount }}</span>
                </el-form-item>
              </el-col>
              <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
                <el-form-item label="公司比例">
                  <span>{{ item.companyPercent }}% -> {{ (item.baseAmount*item.companyPercent/100)}}元</span>
                </el-form-item>
              </el-col>
              <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
                <el-form-item label="个人比例">
                  <span>{{ item.empPercent }}% -> {{ (item.baseAmount*item.empPercent/100)}}元</span>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </template>
      </el-table-column>
      <el-table-column label="所属公司" align="center" prop="companyId" show-overflow-tooltip width="210" sortable>
        <template slot-scope="scope">
          {{companyFormat(scope.row, null)}}
        </template>
      </el-table-column>
      <el-table-column label="模板名" align="center" prop="name"/>
      <el-table-column label="类型" align="center" prop="type"/>
<!--      <el-table-column label="项目名" align="center" prop="itemInfo.itemName"/>-->
<!--      <el-table-column label="公司比例" align="center" prop="itemInfo.companyPercent"/>-->
<!--      <el-table-column label="个人比例" align="center" prop="itemInfo.empPercent"/>-->
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
              size="mini"
              type="text"
              icon="el-icon-document-copy"
              @click="handleCopy(scope.row)"
              v-permission="['/cb/cbTemplate/add']"
          >复制
          </el-button>
          <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="handleUpdate(scope.row)"
              v-permission="['/cb/cbTemplate/edit']"
          >修改
          </el-button>
          <el-button
              size="mini"
              type="text"
              icon="el-icon-delete"
              @click="handleDelete(scope.row)"
              v-permission="['/cb/cbTemplate/remove']"
          >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
        v-show="total>0"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
    />

    <!-- 添加或修改薪酬模板对话框 -->
    <el-dialog :title="title" width="uniApp?'95%':'80%'" :visible.sync="open" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="80px">
        <el-row :gutter="35">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
            <el-form-item label="模板名" prop="name">
              <el-input v-model="form.name" placeholder="请输入模板名"/>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
            <el-form-item label="模板类型" prop="type">
              <el-select v-model="form.type" placeholder="请选择模板类型">
                <el-option v-for="item in getDictDatas(DICT_TYPE.CB_TEMPLATE_TYPE)" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
            <el-form-item label="公司" prop="companyId">
              <treeselect :disabled="form.id>0" v-model="form.companyId" :options="companyOptions"
                          :normalizer="normalizerCompany" placeholder="请选择公司"/>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
            <el-form-item label="描述" prop="description">
              <el-input v-model="form.description" placeholder="请输入描述"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10" class="mb8">
          <el-col :span="1.5">
            <el-button
                type="primary"
                plain
                icon="el-icon-plus"
                size="mini"
                @click="handleAddItem"
                v-permission="['/cb/cbTemplate/add','/cb/cbTemplate/edit']"
            >新增项目
            </el-button>
          </el-col>
        </el-row>
        <el-table :data="form.itemInfo" stripe border>
          <el-table-column label="项目名" align="center" prop="itemName">
            <template slot-scope="scope">
              <el-input v-model="scope.row.itemName" placeholder="缴纳项目名"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="缴纳基数" align="center" prop="baseAmount">
            <template slot-scope="scope">
              <el-input v-model="scope.row.baseAmount" placeholder="缴纳基数"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="公司比例%" align="center" prop="companyPercent">
            <template slot-scope="scope">
              <el-input v-model="scope.row.companyPercent" type="number" placeholder="公司缴纳比例"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="个人比例%" align="center" prop="empPercent">
            <template slot-scope="scope">
              <el-input v-model="scope.row.empPercent" type="number" placeholder="个人缴纳比例"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
            <template slot-scope="scope">
              <el-button
                  size="mini"
                  type="text"
                  icon="el-icon-delete"
                  @click="handleDeleteItem(scope.row)"
                  v-permission="['/cb/cbTemplate/add','/cb/cbTemplate/edit']"
              >删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button type="primary" @click="submitForm" v-permission="['/cb/cbTemplate/add', '/cb/cbTemplate/edit']">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  listCbTemplate,
  getCbTemplate,
  delCbTemplate,
  addCbTemplate,
  updateCbTemplate,
  exportCbTemplate } from "@/api/cb/cbTemplate";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import RightToolbar from "@/components/RightToolbar"
import permission from '@/directive/permission/index.js' // 权限判断指令
import Pagination from "@/components/Pagination";
import { showLoading, hideLoading } from '@/utils/reqloading';
import {Local} from '@/utils/storage.js'


// 表查询配置
const dbOrderBy = ''

export default {
  name: "InsuranceFund",
  components: {
    Treeselect,
    RightToolbar,
    Pagination,
  },
  directives: { permission },
  props:{
    // 公司树选项
    companyList: Array,
    companyOptions: Array,
  },
  data() {
    return {
      // 遮罩层
      loading: true,
      uniApp:false,
      // 查询参数
      where: {
        query: '',
        filters: []
      },


      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 薪酬模板表格数据
      cbTemplateList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        orderby: dbOrderBy,
        companyId: null,
        name: null,
        type: null,
      },
      // 表单参数
      form: {
        itemInfo:[],
      }
      ,
      // 表单校验
      rules: {
        companyId: [
          {
            required: true,
            message: "公司不能为空", trigger: "blur" }
        ],
        name: [
          {
            required: true,
            message: "模板名不能为空", trigger: "blur" }
        ],
        type: [
          {
            required: true,
            message: "模板类型不能为空", trigger: "change"
          }
        ],
      }
    }
        ;
  },
  created() {
    this._filter = {};
    this.uniApp=Local.get("isUniapp")?true:false;
    // this.getTreeselectCompany();
    this.getList();
  },
  methods: {
    handleAddItem(){
      if(!this.form.itemInfo){
        this.$set(this.form,"itemInfo", []);
      }
      this.form.itemInfo.push({itemName:"",baseAmount:0,companyPercent:0.0,empPercent:0.0});
    },
    handleDeleteItem(row){
      var index = this.form.itemInfo.indexOf(row);
      if (index > -1) {
        this.form.itemInfo.splice(index, 1);
      }
    },
    /** 查询薪酬模板列表 */
    getQuery(){
      this._filter = {};
      if(this.queryParams.companyId) {
        this._filter['companyId'] = {
          type: 'eq',
          value: this.queryParams.companyId,
        }
      }
      if(this.queryParams.name) {
        this._filter['name'] = {
          type: 'search',
          value: this.queryParams.name,
        }
      }
      if(this.queryParams.type) {
        this._filter['type'] = {
          type: 'eq',
          value: this.queryParams.type,
        }
      }
      if(this.queryParams.description) {
        this._filter['description'] = {
          type: 'search',
          value: this.queryParams.description,
        }
      }
      const filters = this.parseFilters(this._filter)
      if (filters && filters.length > 0) {
        this.where.filters = filters
      } else {
        // 如果过滤条件为空，设置空数组
        this.where.filters = []
      }
      const params = {
        pageNum: this.queryParams.pageNum,
        pageSize: this.queryParams.pageSize,
        where: this.where,
        order: this.orderby
      }

      return params;
    },
    getList() {
      this.loading = true;
      const params = this.getQuery();
      listCbTemplate(params).then(response => {
        this.cbTemplateList = response.data.records;
        this.total = response.data.total;
        this.loading = false;
      })
      ;
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {};
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.open = true;
      this.title = "添加薪酬模板";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.id || this.ids
      getCbTemplate(id).then(response => {
        this.form = response.data;
        this.open = true;
        this.title = "修改薪酬模板";
      });
    },
    handleCopy(row){
      this.reset();
      const id = row.id || this.ids
      getCbTemplate(id).then(response => {
        this.form = response.data;
        this.form.id = null;
        this.form.name = this.form.name+"-复制";
        this.open = true;
        this.title = "复制薪酬模板";
      });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate(valid => {
            if(valid) {
              let itemInfo = [];
              this.form.itemInfo.forEach(item=>{
                itemInfo.push({itemName:item.itemName, baseAmount:item.baseAmount,
                  companyPercent:item.companyPercent,empPercent:item.empPercent});
              });
              this.form.itemInfo = itemInfo;
              this.form.companyCode = this.getCompanyCode(this.form.companyId);
              console.log(this.form);
              showLoading();
              if (this.form.id != null) {
                updateCbTemplate(this.form).then(response => {
                  this.msgSuccess("修改成功");
                  hideLoading();
                  this.open = false;
                  this.getList();
                });
              } else {
                addCbTemplate(this.form).then(response => {
                  this.msgSuccess("新增成功");
                  hideLoading();
                  this.open = false;
                  this.getList();
                });
              }
            }
          }
      )
      ;
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      let ids = [];
      if(row.id){
        ids = [row.id];
      }else{
        ids = this.ids;
      }

      this.$confirm('是否确认删除选中的薪酬模板数据?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return delCbTemplate(ids);
      }).then(() => {
        this.getList();
        this.msgSuccess("删除成功");
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      const params = this.getQuery();
      this.$confirm('是否确认导出所有薪酬模板数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return exportCbTemplate(params);
      }).then(response => {
        this.download(response.msg);
      })
    },
    /** 转换公司数据结构 */
    normalizerCompany(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.id,
        label: node.companyName,
        children: node.children
      };
    },
    companyFormat(row, column) {
      // console.log(row);
      for(var i = 0; i < this.companyList.length; i++){
        const item =  this.companyList[i];
        if(item.id == row.companyId){
          return item.companyName;
        }
      }
      return "";
    },
    getCompanyCode(companyId){
      // console.log(companyId)
      // console.log(this.companyList)
      for(var i = 0; i < this.companyList.length; i++){
        const item =  this.companyList[i];
        if(item.id == companyId){
          return item.code;
        }
      }
    },


  }
};
</script>

<style>
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 88px;
  text-align: right;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  /*width: 30%;*/
}
</style>
