<template>
  <div>
    <BusinessTripEdit type="apply" :isShowClose="true"></BusinessTripEdit>
  </div>
</template>

<script>
import BusinessTripEdit from "./businessTripEdit";
export default {
  name: "apply",
  components:{
    BusinessTripEdit,
  }
}
</script>

<style scoped>

</style>
