<template>
  <div class="app-container">
    <div v-if="user">
      <el-row :gutter="20">

        <el-col :span="16" :xs="24">
          <Records></Records>
        </el-col>
        <el-col :span="8" :xs="24">
          <user-card :user="user" />
        </el-col>

<!--        <el-col :span="18" :xs="24">-->
<!--          <el-card>-->
<!--            <el-tabs v-model="activeTab">-->
<!--              <el-tab-pane label="Activity" name="activity">-->
<!--&lt;!&ndash;                <activity />&ndash;&gt;-->
<!--              </el-tab-pane>-->
<!--              <el-tab-pane label="Timeline" name="timeline">-->
<!--&lt;!&ndash;                <timeline />&ndash;&gt;-->
<!--              </el-tab-pane>-->
<!--              <el-tab-pane label="Account" name="account">-->
<!--&lt;!&ndash;                <account :user="user" />&ndash;&gt;-->
<!--              </el-tab-pane>-->
<!--            </el-tabs>-->
<!--          </el-card>-->
<!--        </el-col>-->

      </el-row>
      <el-row>
        <el-col :span="8" :xs="24" style="text-align: center"><el-button style="text-align: center" @click="returnClick">返回</el-button></el-col>
      </el-row>

    </div>
  </div>
</template>

<script>
import UserCard from './components/UserCard'
import Records from '../pages/attendance/my/records'
export default {
	name: 'personal',
  components: { UserCard, Records },
	data() {
		return {
      user: {name:'wangjie'},
      activeTab: 'activity',

    };
	},
	computed: {
		// 设置主盒子高度
		setMainHeight() {
			let { isTagsview } = this.$store.state.themeConfig.themeConfig;
			if (isTagsview) return `10px`;
			else return `80px`;
		},
	},
  created() {
    this.user = this.$store.getters.userInfo;
  },
  methods: {
    returnClick(){
      this.$router.push({name:"home"});
      // this.bus.$emit('onCurrentContextmenuClick', {
      //   id: 1,
      //   path: this.$route.path,
      // });
    },
  },
};
</script>

<style scoped lang="scss">
.personal  {}
</style>
