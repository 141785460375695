import { login, logout, getInfo } from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { resetRouter } from '@/router'
import { Local } from '@/utils/storage.js';

const state = {
  token: getToken(),
  name: '',
  avatar: '',
  introduction: '',
  roles: [],
  menus: [],
  menuUrls: [],
  userInfo: {},
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_INTRODUCTION: (state, introduction) => {
    state.introduction = introduction
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR: (state, avatar) => {
    state.avatar = avatar
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_MENUS: (state, menus) => {
    state.menus = menus
  },
  SET_MENUURLS: (state, menuUrls) => {
    state.menuUrls = menuUrls
  },
  SET_USERINFO: (state, userInfo) => {
    state.userInfo = userInfo
  }
}

const actions = {
  uLoginToken({ commit }, token) {
    return new Promise((resolve, reject) => {
      console.log('token:' + token)
      commit('SET_TOKEN', token)
      setToken(token)
      Local.set("isUniapp", true)
      resolve()
    })
  },
  // user login
  login({ commit }, userInfo) {
    const { loginType, userName, password, code } = userInfo
    return new Promise((resolve, reject) => {
      login({ loginType:loginType, username: userName.trim(), password: password, code:code }).then(response => {
        const { data } = response
        console.log('token:' + data.authentication)
        commit('SET_TOKEN', data.authentication)
        setToken(data.authentication)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo(state.token).then(response => {
        console.log("set isUniapp &***************####################################################")
        // window.localStorage.setItem("isUniapp", JSON.stringify(true));
        const { data } = response

        if (!data) {
          reject('Verification failed, please Login again.')
        }

        const { roles, username, avatar, introduction, menus, menuUrls } = data

        // roles must be a non-empty array
        if (!roles || roles.length <= 0) {
          reject('getInfo: roles must be a non-null array!')
        }
        var rnames = []
        roles.forEach(({ roleName }, idx) => {
          rnames[idx] = roleName
        })
        // 生成 menu tree
        let list = [
            {
              parentId: 0,
              id: -1,
              "path": "/home",
              "name": "home",
              "component": "home/index",
              "meta": {
                "title": "message.router.home",
                "isLink": "",
                "isHide": false,
                "isKeepAlive": true,
                "isAffix": true,
                "isIframe": false,
                "isUniApp": false,
                "icon": "iconfont icon-shouye",
                "uniIcon":"iconfont icon-shouye",
              }
            },];
        for (var idx = 0; idx < menus.length; idx++) {
          let m = menus[idx];
          const module = {
            parentId: m.parentId,
            id: m.id,
            hidden: m.hidden,
            path: m.menuPath,
            component: m.component,
            name: m.menuName,
            redirect: m.redirect,
            meta: { id: m.id, title: m.title, icon: m.icon, uniIcon: m.uniIcon, isHide: m.hidden, isAffix: m.affix, isKeepAlive: true, isUniApp: m.isUniApp },
            children: []
          }
          list.push(module);
        }
        const list2tree = (list, parentMenuId) => {
          let menuObj = {}
          list.forEach(item => {
            item.children = []
            menuObj[item.id] = item
          })
          return list.filter(item => {
            if (item.parentId !== parentMenuId) {
              menuObj[item.parentId].children.push(item)
              return false
            }
            return true
          })
        }
        let treeMenus = list2tree(list, 0)
        // let treeMenus = [];
        // list.forEach(item => {
        //   if(item.id === 0){
        //     treeMenus.push(item);
        //   }
        // })
        console.log(treeMenus)
        commit('SET_ROLES', rnames)
        commit('SET_NAME', username)
        commit('SET_AVATAR', avatar)
        commit('SET_INTRODUCTION', introduction)
        commit('SET_MENUS', treeMenus)
        commit('SET_MENUURLS', menuUrls),
        commit('SET_USERINFO', data),
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // user logout
  logout({ commit, state, dispatch }) {
    return new Promise((resolve, reject) => {
      logout(state.token).then(() => {
        commit('SET_TOKEN', '')
        commit('SET_ROLES', [])
        removeToken()
        resetRouter()

        // reset visited views and cached views
        // to fixed https://github.com/PanJiaChen/vue-element-admin/issues/2485
        dispatch('tagsView/delAllViews', null, { root: true })

        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  updateToken({ commit }, token) {
    return new Promise(resolve => {
      commit('SET_TOKEN', token)
      resolve()
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken()
      resolve()
    })
  },

  // // dynamically modify permissions
  // async changeRoles({ commit, dispatch }, role) {
  //   const token = role + '-token'
  //
  //   commit('SET_TOKEN', token)
  //   setToken(token)
  //
  //   const { roles } = await dispatch('getInfo')
  //
  //   resetRouter()
  //
  //   // generate accessible routes map based on roles
  //   const accessRoutes = await dispatch('permission/generateRoutes', roles, { root: true })
  //   // dynamically add accessible routes
  //   router.addRoutes(accessRoutes)
  //
  //   // reset visited views and cached views
  //   dispatch('tagsView/delAllViews', null, { root: true })
  // }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
