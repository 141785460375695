import request from '@/utils/request'
import {parseFilters, handleTree} from "@/utils/tools";

// 查询岗位管理列表
export function listPosition(query) {
    return request({
        url: '/org/position/findAll',
        method: 'post',
        data: query
    })
}

// 查询岗位管理详细
export function getPosition(id) {
    return request({
        url: '/org/position/findOne?id=' + id,
        method: 'post'
    })
}

// 新增岗位管理
export function addPosition(data) {
    return request({
        url: '/org/position/add',
        method: 'post',
        data: data
    })
}

// 修改岗位管理
export function updatePosition(data) {
    return request({
        url: '/org/position/edit',
        method: 'post',
        data: data
    })
}

// 删除岗位管理
export function delPosition(id) {
    return request({
        url: '/org/position/remove',
        method: 'post',
        data: id
    })
}

// 导出岗位管理
export function exportPosition(query) {
    return request({
        url: '/org/position/export',
        method: 'post',
        data: query
    })
}


export function getTreeselectPosition(companyId, hasTop, callback){
    console.log(companyId)
    let _filter = {};
    if (companyId) {
        if(Array.isArray(companyId)){
            _filter['companyId'] = {
                type: 'select',
                value: companyId,
            }
        }else{
            _filter['companyId'] = {
                type: 'select',
                value: [companyId],
            }
        }
    }
    let where = {};
    const filters = parseFilters(_filter)
    if (filters && filters.length > 0) {
        where.filters = filters
    } else {
        // 如果过滤条件为空，设置空数组
        where.filters = []
    }
    const params = {
        pageNum: 1,
        pageSize: 500,
        where: where,
        order: ''
    }
    console.log("pos par")
    console.log(params)
    console.log("pos par")
    listPosition(params).then(response => {
        // this.positionList = response.data.records;
        const list = response.data.records;
        let options = [];
        const data = { id:0,
            name:'顶级岗位',
            children: []
        };
        data.children = handleTree(response.data.records, "id", "pid");
        if(hasTop){
            options.push(data);
        }else{
            options = data.children;
        }
        callback(list, options);
    });
}
