<template>
    <div>
		<el-form :model="queryParameters" ref="queryParameters" class="employ" label-width="100px">
			<el-row :gutter="24">
				
				<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
					<el-form-item label="起始时间">
						<el-date-picker v-model="queryParameters.startDate" value-format="yyyy-MM-dd" align="left" type="date" placeholder="选择日期">
						</el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
					<el-form-item label="终止时间">
						<el-date-picker v-model="queryParameters.endDate" value-format="yyyy-MM-dd" align="left" type="date" placeholder="选择日期">
						</el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
					<el-form-item label="流程名称">
						<el-input v-model="queryParameters.name" placeholder="面试流程名称" clearable />
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0" >
					<el-form-item label=" " style="margin-bottom:0px !important;">
						<el-button v-on:click="query" size="mini" icon="el-icon-search" plain type="primary">查询</el-button>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<el-row :gutter="10" class="mb8">
			<el-col :span="1.5">
				<el-button type="primary" plain icon="el-icon-plus" size="mini" @click="handleAdd" v-permission="['/TrainingPlan/add']">新增 </el-button>
			</el-col>
			<el-col :span="1.5" >
				<el-button
					type="success"
					plain
					icon="el-icon-edit"
					size="mini"
					:disabled="!single"
					@click="handleUpdate2"
					>修改
				</el-button>
			</el-col>
			<el-col :span="1.5" >
				<el-button
					type="danger"
					plain
					icon="el-icon-delete"
					size="mini"
					:disabled="!multiple"
					@click="handleDelete2"
					>删除
				</el-button>
			</el-col>
		</el-row>
		<el-row :gutter="24">
		<el-table v-loading="loading" :data="processList" style="width: 100%;" max-height="100%" @selection-change="handleSelectionChange" stripe border>
			<el-table-column type="selection" width="55" align="center" />
			<el-table-column label="编号ID" align="center" prop="id" v-if="false" />
			<el-table-column label="流程名称" align="center" prop="processName" sortable  min-width="150" :show-overflow-tooltip="true" />
			
			<el-table-column label="创建者" align="center" prop="creator" sortable min-width="100" />
			<el-table-column label="创建日期" align="center" prop="createTime" sortable  min-width="100">
			</el-table-column>
            <el-table-column label="更新者" align="center" prop="updatedBy" sortable min-width="100" />
			<el-table-column label="更新日期" align="center" prop="updatedTime" sortable  min-width="100">
			</el-table-column>
			<el-table-column label="操作" align="center" min-width="150" fixed="right">
				<template slot-scope="scope">
					<el-button size="mini" type="text" icon="el-icon-edit" @click="handleUpdate(scope.row)" >编辑</el-button>
					<el-button size="mini"  type="text" icon="el-icon-s-check" @click="handleDelete(scope.row)" >删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		</el-row>
		<pagination v-show="total > 0" :total="total" :page.sync="queryParameters.pageNum" :limit.sync="queryParameters.pageSize" @pagination="query" />

		 <el-dialog :title="title" :visible.sync='showProcessEdit' width="uniApp?'95%':'50%'" append-to-body >
        	<interview-process-edit  v-if="showProcessEdit"  :currentProcess="selectedProcess" :newProcess="newProcess" @close="closeDialog" >
			 </interview-process-edit>
    	</el-dialog>
    </div>
</template>

<script>
import Pagination from '@/components/Pagination';
import interviewProcessEdit from './interviewProcessEdit.vue';
import { findAllProcesses,deleteInterviewProcess } from '@/api/recruit/interviewProcess';
import {Local} from '@/utils/storage.js'
export default {
    name: 'InterviewProcess',
    components: {
		Pagination,
		interviewProcessEdit,
	},
    data() {
        return {
            queryParameters: {
				startDate: '',
				endDate: '',
				name: '',
				pageSize: 10,
				pageNum: 1,
			},
            where: {
				query: '',
				filters: [],
			},
            processList:[],
            ids:[],
            loading:false,
            newProcess:false,
            showProcessEdit:false,
            title:"",
            single:false,
            multiple:false,
            total:0,
            selectedProcess:{},
			uniApp:false,
        };
    },
    created() {
		this.query();
		this.uniApp = Local.get('isUniapp') ? true : false;
	},
    mounted() {
        
    },

    methods: {
        query(){
            let filter = {};
			if (this.queryParameters.name) {
				filter['name'] = {
					type: 'search',
					value: this.queryParameters.name,
				};
			}

			if (this.queryParameters.startDate && this.queryParameters.endDate) {
				let tempDate = this.queryParameters.startDate.replace(/-/g, '/'); //必须把日期'-'转为'/'
				var startDateTimeStamp = new Date(tempDate).getTime();
				tempDate = this.queryParameters.endDate.replace(/-/g, '/');
				var endDateTimeStamp = new Date(tempDate).getTime();
				filter['createTime'] = {
					type: 'timestamp',
					value: [startDateTimeStamp, endDateTimeStamp],
				};
			}

			const filters = this.parseFilters(filter);
			if (filters && filters.length > 0) {
				this.where.filters = filters;
			} else {
				// 如果过滤条件为空，设置空数组
				this.where.filters = [];
			}
			const params = {
				pageNum: this.queryParameters.pageNum,
				pageSize: this.queryParameters.pageSize,
				where: this.where,
				order: '',
			};
			this.loading = true;
			findAllProcesses(params).then((response) => {
				this.processList = response.data.records;
				this.total = response.data.total;
				this.loading = false;

			});
        },
        
		handleAdd() {
			this.showProcessEdit = true;
			this.title="增加面试流程";
			this.newProcess = true;
			this.selectedProcess = null;
		},

		handleUpdate(row) {
            this.showProcessEdit = true;
			this.title="更新面试流程";
			this.newProcess = false;
			this.selectedProcess = row;
		},
		handleUpdate2() {
			let findProcesses= this.processList.filter(item=>item.id==this.ids[0]);
			this.selectedProcess = findProcesses[0];
			 this.showProcessEdit = true;
			this.title="更新面试流程";
			this.newProcess = false;
		},
		handleDelete2() {
			let that = this;
			this.$confirm('确定删除选中的面试流程?', "警告", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			}).then(function () {
				return deleteInterviewProcess(that.ids);
			}).then(() => {
				that.query();
				that.msgSuccess("删除成功");
			})
		},
		handleDelete(row) {
			let ids = [];
			if (row.id) {
				ids = [row.id];
			} else {
				ids = this.ids;
			}
			let that = this;
			this.$confirm('确定删除选中的面试流程?', "警告", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			}).then(function () {
				return deleteInterviewProcess(ids);
			}).then(() => {
				that.query();
				that.msgSuccess("删除成功");
			})
		},
        handleSelectionChange(selection) {
				this.ids = selection.map(item => item.id)
				this.single = selection.length == 1
				this.multiple = selection.length>=1;
				// let findTrainingPlans= this.trainingPlanList.filter(item=>item.id==this.ids[0]);
				// this.selectTrainingPlan = findTrainingPlans[0];
				// this.enableCheckInButton = this.single && !this.disableButton(this.selectTrainingPlan);
				// this.enableReleaseButton = this.single && !this.disableButton(this.selectTrainingPlan)&&findTrainingPlans[0].status=='未发布';
			},

		closeProcessEdit(params){
			this.showProcessEdit = false;
			if(params.refresh){
				this.query();
			}

		},
		parseTime(rowDateTime)
		{
			if(rowDateTime != null && rowDateTime.indexOf('T')> 0)
			{
				return rowDateTime.substr(0,10);
			}
			else{
				return "";
			}
		},

		closeDialog(arg)
		{
			this.showProcessEdit = false;
			if(arg.refresh){
				this.query();
			}
		},
    },
};
</script>

<style lang="scss" scoped>

</style>