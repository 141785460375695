import request from '@/utils/request'

// 查询薪酬模板列表
export function listCbTemplate(query) {
    return request({
        url: '/cb/cbTemplate/findAll',
        method: 'post',
        data: query
    })
}

// 查询薪酬模板详细
export function getCbTemplate(id) {
    return request({
        url: '/cb/cbTemplate/findOne?id=' + id,
        method: 'post'
    })
}

// 新增薪酬模板
export function addCbTemplate(data) {
    return request({
        url: '/cb/cbTemplate/add',
        method: 'post',
        data: data
    })
}

// 修改薪酬模板
export function updateCbTemplate(data) {
    return request({
        url: '/cb/cbTemplate/edit',
        method: 'post',
        data: data
    })
}

// 删除薪酬模板
export function delCbTemplate(id) {
    return request({
        url: '/cb/cbTemplate/remove',
        method: 'post',
        data: id
    })
}

// 导出薪酬模板
export function exportCbTemplate(query) {
    return request({
        url: '/cb/cbTemplate/export',
        method: 'post',
        data: query
    })
}
