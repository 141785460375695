<template>
  <el-collapse-item title="家庭及社会关系" :name="this.collapseStatus" :class="collapseSetting()">
    <el-form class="employ" label-width="150px">
      <el-row v-for="(familySocialRelationModel, index) in familySocialRelationsModel" :key="index"
              :class="newAddedAfterLocked(familySocialRelationModel)">
        <el-row>
          <el-row>
            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
              <el-form-item label="关系">
                <el-input v-model="familySocialRelationModel.relationShip"
                          :readonly="canEdit(familySocialRelationModel)"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
              <el-form-item label="姓名">
                <el-input v-model="familySocialRelationModel.name"
                          :readonly="canEdit(familySocialRelationModel)"></el-input>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
              <el-form-item label="政治面貌">
                <el-input v-model="familySocialRelationModel.policitalStatus"
                          :readonly="canEdit(familySocialRelationModel)"></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
              <el-form-item label="出生日期">
                <el-date-picker
                    v-model="familySocialRelationModel.birthDay"
                    value-format="yyyy-MM-dd"
                    :readonly="canEdit(familySocialRelationModel)"
                    align="left"
                    type="date"
                    placeholder="选择日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>


            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
              <el-form-item label="工作单位及职务">
                <el-input v-model="familySocialRelationModel.workUnitPosition"
                          :readonly="canEdit(familySocialRelationModel)"></el-input>
              </el-form-item>
            </el-col>

            <!-- <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
              <el-form-item label="紧急联系人">
                <el-checkbox v-model="familySocialRelationModel.emergencyContact" :disabled="readonly"></el-checkbox>
              </el-form-item>
            </el-col> -->
            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
              <el-form-item label="紧急联系人">
                <el-select :disabled="canEdit(familySocialRelationModel)"
                           v-model="familySocialRelationModel.emergencyContact" placeholder="请选择是否紧急联系人">
                  <el-option label="是" :value="true" key="true"/>
                  <el-option label="否" :value="false" key="false"/>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
              <el-form-item label=" ">
                <el-button
                    plain
                    class="employ-form-delete-button"
                    :id="familySocialRelationModel.name"
                    v-bind:disabled="canEdit(familySocialRelationModel)"
                    v-on:click="deleteFamilySocialRelation($event, familySocialRelationModel.name)"
                    type="danger"
                    icon="el-icon-delete"
                >删除
                </el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
          <div
              style="
							width: 80%;
							border-top: 1px solid;
							color: #ebeef5;
							height: 10px;
							margin-left: 10%;
							border-bottom-width: 1px;
							border-left-width: 1px;
							text-align: center;
						"
          ></div>
        </el-row>
      </el-row>

      <el-row>
        <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
          <el-form-item label=" ">
            <el-button v-on:click="addFamilySocialRelation" type="primary" v-bind:disabled="enableAdd" plain
                       icon="el-icon-plus"
            >增加
            </el-button
            >
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
          <el-form-item label=" ">
            <el-button v-on:click="edit" type="primary" plain v-bind:disabled="hasLocked?true:this.isReadOnly"
                       icon="el-icon-edit">编辑
            </el-button>
            <el-button v-on:click="save" plain v-bind:disabled="readonly" type="primary" :loading="saving"
                       icon="el-icon">保存
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </el-collapse-item>
</template>


<script>
export default {
	data() {
		let readonlyInit = true;
		let familySocialRelationsInit = new Array();
		let initAddItem = false;
		if (this.familySocialRelations == null || (this.familySocialRelations != null&&this.familySocialRelations.length==0)) {
			familySocialRelationsInit.push({
				relationShip: null,
				name: '',
				birthDay: null,
				policitalStatus: null,
				workUnitPosition: null,
				emergencyContact: null,
				newAdded:this.locked?true:false
			});

			initAddItem=true;
		} else {
			familySocialRelationsInit = this.familySocialRelations;
		}

		//  isReadOnly=true for view, others for edit. Users can't eidt if locked, but they can add
		if(this.isReadOnly || this.locked)
		{
			readonlyInit = true;
		}
		else{
			readonlyInit = false;
		}

    return {
      familySocialRelationsModel: familySocialRelationsInit,
      readonly: readonlyInit,
      enableAdd: this.enableAddButton,
      hasLocked: this.locked,
      saving: false,
      initItem: initAddItem,
      pickerOptions1: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
    };
  },
  props: {
    familySocialRelations: Array,
    id: Number,
    isReadOnly: Boolean,
    collapseStatus: String,
    locked: Boolean,
    enableAddButton: Boolean
  },
  watch: {
    isReadOnly(newValue, oldV) {
      this.readonly = newValue;
      //this.hasLocked = newValue;
    },
    locked(newValue, oldV) {
      this.hasLocked = newValue;
    },
  },
  computed: {
    newAddedAfterLocked() {
      return function (currentItem) {
        return {employ_item_update: this.locked && currentItem.newAdded && !this.initItem && !this.isReadOnly};
      }
    },

    canEdit() {
      return function (currentItem) {
        let returnValue = true;
        if (this.hasLocked) {
          returnValue = currentItem.newAdded && !this.isReadOnly ? false : true;
        } else {
          returnValue = this.readonly;
        }
        return returnValue;
      }
    },
    collapseSetting() {
      return function () {
        if (this.familySocialRelationsModel != null && this.familySocialRelationsModel.length > 0) {
          if (this.locked) {
            let newAddResults = this.familySocialRelationsModel.filter(item => item.newAdded == true);
            if (newAddResults.length > 0) {
              if (this.initItem) {
                return {callpse_header_unfill: true};
              } else {
                return {callpse_header_newAdd: true};
              }
            } else {
              return {callpse_header_fill: true};
            }

          } else {
            if (this.initItem) {
              return {callpse_header_unfill: true};
            } else {
              return {callpse_header_fill: true};
            }
          }

        } else {
          return {callpse_header_unfill: true};
        }

      }
    },

  },
  methods: {
    save() {
      // save data to DB
      let savingFamilySocialRelations = {};
      savingFamilySocialRelations.id = this.id;
      savingFamilySocialRelations.familySocialRelations = this.familySocialRelationsModel;
      this.saving = true;
      this.$emit('updatePartialEmployeeInfo', {data: savingFamilySocialRelations}, (response) => {
        this.saving = false;
        if (response.success) {
          this.msgSuccess('修改成功');
          this.readonly = true;
        } else {
          this.msgError('修改失败,' + response.msg);
        }
      });
    },
    edit() {
      this.readonly = false;
    },
    addFamilySocialRelation() {
      this.readonly = false;
      this.familySocialRelationsModel.push({
        relationShip: null,
        name: '',
        birthDay: null,
        policitalStatus: null,
        workUnitPosition: null,
        emergencyContact: null,
        newAdded: true
      });
    },
    deleteFamilySocialRelation(event, name) {
      let self = this;
      if (event.currentTarget.id == name) {
        this.$confirm('确定要删除吗?', '确认', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'error',
          // type: 'warning'
        })
            .then(() => {
              if (self.familySocialRelationsModel.length > 0) {
                let foundIndex = self.familySocialRelationsModel.findIndex((x) => x.name === name);
                if (foundIndex > -1) {
                  self.familySocialRelationsModel.splice(foundIndex, 1);
                }
              }
            })
            .catch(() => {
              this.msgError('删除发生异常!');
            });
      }
    },
  },
};
</script>

<style>
</style>
