import request from '@/utils/request'

// Find current company all cousres
export function findAllCourses(query) {
    return request({
        url: '/TrainingCourse/findAll',
        method: 'post',
        data: query
    })
}

// 上传培训课程文件
export function uploadTrainingCourseFile(data) {
    return request({
        url: '/TrainingCourse/uploadCourseFile',
        method: 'post',
        data: data
    })
}

// 新增课程信息
export function addTrainingCourse(data) {
    return request({
        url: '/TrainingCourse/add',
        method: 'post',
        data: data
    })
}

// 新增课程信息
export function updateTrainingCourse(data) {
    return request({
        url: '/TrainingCourse/edit',
        method: 'post',
        data: data
    })
}

// 删除员工信息
export function deleteCourses(ids) {
    return request({
        url: '/TrainingCourse/remove',
        method: 'post',
        data: ids
    })
}

// 取得资源的URL
export function getResourceUrl(fileName) {
    return request({
        url: '/TrainingCourse/getResourceUrl?fileName=' + fileName,
        method: 'post',
        data: fileName
    })
}
