import store from '@/store';
import router, { resetRouter } from '@/router/index';
import axios from 'axios';
import { Message, MessageBox } from 'element-ui';
import { Session } from '@/utils/storage';
import { getToken } from '@/utils/auth';
import { showLoading, hideLoading } from './reqloading';
import { Local } from '@/utils/storage.js';

// 创建 axios 实例
const service = axios.create({
	baseURL: process.env.VUE_APP_BASE_API,
	// baseURL: 'http://106.14.68.213:8080',
	timeout: 300000,
	headers: { 'Content-Type': 'application/json;charset=utf-8' },
});

// 添加请求拦截器
service.interceptors.request.use(
	(config) => {
		// showLoading();
		// 在发送请求之前做些什么 token
		// if (Session.get('token')) {
		// 	config.headers.common['Authorization'] = `${Session.get('token')}`;
		// }
		if (store.getters.token) {
			// let each request carry token
			// ['X-Token'] is a custom headers key
			// please modify it according to the actual situation
			config.headers['authentication'] = getToken()
		}
		return config;
	},
	(error) => {
		// 对请求错误做些什么
		// hideLoading();
		console.log(error) // for debug
		return Promise.reject(error);
	}
);

// 添加响应拦截器
service.interceptors.response.use(
	(response) => {
		// 对响应数据做点什么
		console.log(response)
		// if(response.header.authentication){
		// 	// console.log("new token:")
		// 	// console.log(response.header.authentication)
		// 	//vm.$u.toast(response.header.authentication);
		// 	this.$store.dispatch('user/updateToken', response.header.authentication)
		// 		.then(()=>{
		// 			console.log("user/updateToken")
		// 		})
		//
		// }
		hideLoading();
		const res = response.data;
		if (res.code && res.code !== 200) {
			// `token` 过期或者账号已在别处登录
			if (res.code === 401 || res.code === 2011) {
				// 清除浏览器全部临时缓存
				Session.clear();
				let uniApp = Local.get("isUniapp") ? true : false;
				if(uniApp){
					Local.clear();
					console.log("current is uniapp, post logout msg..................................")
					uni.postMessage({data: { logOut: true}})
				}
				router.push('/login');
				store.commit('setMenuData', {});
				resetRouter(); // 重置路由
				Message.warning('你已被登出，请重新登录');
				// MessageBox.alert('你已被登出，请重新登录', '提示', {})
				// 	.then(() => {})
				// 	.catch(() => {});
			}else if (res.code === 2021) {
				MessageBox.alert(response.data.msg+"，"+response.data.data, '提示', {})
					.then(() => {})
					.catch(() => {});
			}else{
				if(response.data.msg.indexOf('java.sql.SQLSyntaxErrorException:') != -1) {
					MessageBox.alert("数据库查询语句语法错误！", '提示', {})
						.then(() => {
						})
						.catch(() => {
						});
				}else if(response.data.msg.indexOf('java.sql.SQLException') != -1
					|| response.data.msg.indexOf('java.sql.') != -1){
						MessageBox.alert("数据库操作错误！", '提示', {})
							.then(() => {})
							.catch(() => {});
				}else if(response.data.msg.indexOf('Deadlock found when trying to get lock') != -1
					){
					MessageBox.alert("数据被锁，有人与你同时操作相同数据，请稍后再试！", '提示', {})
						.then(() => {})
						.catch(() => {});
				}else{
					MessageBox.alert(response.data.msg, '提示', {})
						.then(() => {})
						.catch(() => {});
				}
			}
			return Promise.reject(response.data.msg);
		} else {
			if(res.code){
				return response.data;
			}else{
				return response;
			}
		}
	},
	(error) => {
		hideLoading();
		console.log(error);
		// 对响应错误做点什么
		if (error.message.indexOf('timeout') != -1) {
			Message.error('网络超时');
		}else if (error.message.indexOf('Request failed with status code 504') != -1) {
				Message.error('网络超时，可能计算太大导致超时。可以尝试每次操作少一些记录');
		} else if (error.message == 'Network Error') {
			Message.error('网络连接错误');
		} else if (error.message.indexOf('404') != -1) {
			Message.error('请求参数错误');
		} else if (error.message.indexOf('500') != -1) {
			Message.error('服务器错误不能访问');
		} else if (error.message.indexOf('400') != -1) {
			Message.error('请求错误，请求参数类型不正确');
		} else {
			Message.error(error.message);
		}
		return Promise.reject(error);
	}
);

export function getBaseHeader() {
	return {
		'authentication': getToken(),
		// 'tenant-id': Cookies.get('tenantId'),
	}
}

// 导出 axios 实例
export default service;
