<template>
  <div>
    <el-form ref="form" :model="form" :rules="rules" label-width="90px">
      <el-row :gutter="35">
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
          <el-form-item label="所属公司" prop="companyId">
            <treeselect disabled v-model="form.companyId" :options="companyOptions"
                        :normalizer="normalizerCompany" placeholder="请选择公司"/>
            <!--              <el-input v-model="form.companyId" placeholder="请输入所属公司"/>-->
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
          <el-form-item label="上级部门" prop="pid">
            <treeselect :disabled="isReadOnly" v-model="form.pid" :options="departmentOptions"
                        :normalizer="normalizerDepartment" placeholder="请选择上级部门"/>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
          <el-form-item label="编号" prop="num">
            <el-input :disabled="isReadOnly" v-model="form.num" placeholder="不输入则自动编号"/>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
          <el-form-item label="名称" prop="name">
            <el-input :disabled="isReadOnly" v-model="form.name" placeholder="请输入部门名称"/>
          </el-form-item>
        </el-col>
<!--        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">-->
<!--          <el-form-item label="编制人数" prop="planCount">-->
<!--            <el-input :disabled="isReadOnly" v-model="form.planCount" placeholder="为空则不限制"/>-->
<!--          </el-form-item>-->
<!--        </el-col>-->
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
          <el-form-item label="区域" prop="regionId">
            <el-select style="width: 100%" :disabled="isReadOnly" v-model="form.regionId" placeholder="请选择区域">
              <el-option
                  v-for="dict in regionidOptions"
                  :key="dict.dictValue"
                  :label="dict.dictLabel"
                  :value="parseInt(dict.dictValue)"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
          <el-form-item label="管理岗位" prop="managerPostId">
<!--            <treeselect-->
<!--                :readonly="isReadOnly"-->
<!--                v-model="form.managerPostId"-->
<!--                :options="positionOptions"-->
<!--                :normalizer="normalizerPosition"-->
<!--                placeholder="请选择管理岗位"-->
<!--            />-->
            <el-select clearable :disabled="isReadOnly" v-model="form.managerPostId" placeholder="请选择岗位" style="width:100%">
              <el-option
                  v-for="dict in positionList"
                  :key="dict.id"
                  :label="dict.name"
                  :value="dict.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">
          <el-form-item label="代理负责人" prop="agentManagerIds" style="display:block !important">
            <treeselect ref="treeRef" :disabled="isReadOnly" v-model="agentManagerIds" :options="employTree"
                        :limit="5" :limitText="count => `及其它${count}人`" @input="inputChange"
                        :multiple="true" value-consists-of="LEAF_PRIORITY"  noChildrenText="没有员工"
                        :normalizer="normalizeNodeDisableEmp" placeholder="请选择代理负责人"/>
          </el-form-item>
        </el-col>
<!--        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">-->
<!--          <el-form-item label="领导岗位" prop="leaderPostId">-->
<!--            <el-input :disabled="isReadOnly" v-model="form.leaderPostId" placeholder="请输入领导岗位id"/>-->
<!--          </el-form-item>-->
<!--        </el-col>-->
<!--        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">-->
<!--          <el-form-item label="部门人数" prop="realCount">-->
<!--            <el-input :disabled="isReadOnly" v-model="form.realCount" placeholder="请输入部门人数"/>-->
<!--          </el-form-item>-->
<!--        </el-col>-->
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">
          <el-form-item label="描述" prop="introduction">
            <el-input :disabled="isReadOnly" v-model="form.introduction" placeholder="请输入描述"/>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">
          <el-form-item label="备注" prop="note">
            <el-input :disabled="isReadOnly" v-model="form.note" placeholder="请输入备注"/>
          </el-form-item>
        </el-col>
<!--        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">-->
<!--          <el-form-item label="图标" prop="avatar">-->
<!--            <el-input :disabled="isReadOnly" v-model="form.avatar" placeholder="请输入图标"/>-->
<!--          </el-form-item>-->
<!--        </el-col>-->
<!--        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">-->
<!--          <el-form-item label="排序顺序" prop="seq">-->
<!--            <el-input :disabled="isReadOnly" v-model="form.seq" placeholder="请输入排序顺序"/>-->
<!--          </el-form-item>-->
<!--        </el-col>-->
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer" style="text-align: right">
      <el-button v-if="!isReadOnly" type="primary" @click="submitForm" v-permission="['/org/department/add', '/org/department/edit']">确 定</el-button>
      <el-button @click="cancel">取 消</el-button>
<!--      <el-popover-->
<!--          placement="top"-->
<!--          title=""-->
<!--          width="200"-->
<!--          trigger="click"-->
<!--          content="如果公司设置了编制人数，则各部门编制人数总和不能超过公司编制人数限制。">-->
<!--        <el-button type="text" round slot="reference">？</el-button>-->
<!--      </el-popover>-->
    </div>
  </div>
</template>

<script>
import {
  listDepartment,
  getTreeselectDepartment,
  getDepartment,
  delDepartment,
  addDepartment,
  updateDepartment,
  exportDepartment } from "@/api/org/department";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import permission from '@/directive/permission/index.js' // 权限判断指令
import {getTreeselectCompany} from "@/api/org/company";
import {getTreeselectPosition} from "@/api/org/position";
import {validatorCompanyId} from "@/utils/validate.js"
import {hideLoading, showLoading} from "../../../../utils/reqloading";
import { getOrgEmpTree, normalizerEmployMultiSelectTree, checkTreeIdsExist} from '@/views/pages/employ/components/empOrgTree';

export default {
  name: "departmentEdit",
  components: {
    Treeselect,

  },
  props: {
    row: Object,
    companyOptionsIn: Array,
    departmentOptionsIn: Array,
    regionidOptionsIn: Array,
    isReadOnly: Boolean,
    companyId: Number,
  },
  directives: { permission },
  watch:{
    row(n,o){ //n为新值,o为旧值;
      //if(n === o) return;
      this.load();
    },
    companyId(){
      this.load();
    }
  },
  data() {
    return {
      // 部门管理树选项
      departmentOptions: [],
      // 公司树选项
      companyOptions: [],
      // 岗位管理表格数据
      positionList: [],
      positionOptions: [],
      // 区域id字典
      regionidOptions: [],
      defaultProps: {
        children: "children",
        label: "companyName"
      },
      // 表单参数
      form: {
      },
      employTree: [],
      agentManagerIds: [],
      // 表单校验
      rules: {
        companyId: [
          {
            required: true,
            validator: validatorCompanyId,
            message: "所属公司不能为空或顶级节点,必须选择公司", trigger: "blur" }
        ],
        // num: [
        //   {
        //     required: true,
        //     message: "部门编号不能为空", trigger: "blur" }
        // ],
        name: [
          {
            required: true,
            message: "部门名称不能为空", trigger: "blur" }
        ],
        pid: [
          {
            required: true,
            message: "父部门不能为空", trigger: "blur" }
        ],
        // regionId: [
        //   {
        //     required: true,
        //     message: "区域id不能为空", trigger: "blur" }
        // ],
        managerPostId: [
          {
            required: true,
            message: "管理岗位不能为空", trigger: "blur" }
        ],
      },
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.reset();
      showLoading();
      this.agentManagerIds = [];
      if(this.companyOptionsIn){
        this.companyOptions = this.companyOptionsIn;
      }else{
        getTreeselectCompany(null, (list, options) =>{
          this.companyOptions = options;
        });
      }
      if(this.departmentOptionsIn){
        this.departmentOptions = this.departmentOptionsIn;
      }else{
        this.departmentOptions = [];
        getTreeselectDepartment(this.companyId, true, (list, options) =>{
          this.departmentList = list;
          this.departmentOptions = options;
        });
      }
      if(this.regionidOptionsIn){
        this.regionidOptions = this.regionidOptionsIn;
      }else{
        this.getDicts("sys_location_region").then(response => {
          this.regionidOptions = response.data;
        });
      }
      getTreeselectPosition(this.companyId, false, (list, options) =>{
        this.positionList = list;
        this.positionOptions = options;
      });
      getOrgEmpTree(this.companyId, true,(tree, companyList, departmentList, employList )=>{
        this.employTree = tree;
        console.log('this.employTree');
        console.log(this.employTree);

        this.employList = employList;
        // 移除unkown 员工，例如已经离职的员工
        console.log('this.agentManagerIds');
        console.log(this.agentManagerIds);
        let checkedIds = [];
        checkTreeIdsExist(this.employTree, this.agentManagerIds,checkedIds);
        console.log("checkedIds=");
        console.log(checkedIds);
        this.agentManagerIds = checkedIds;
        hideLoading();
      });

      if(this.row && this.row.id){
        this.handleUpdate(this.row);
      }else{
        this.handleAdd();
      }
    },

    /** 转换公司数据结构 */
    normalizerCompany(node) {
      return this.normalizer(node,'id','companyName');
    },

    normalizerDepartment(node) {
      return this.normalizer(node);
    },
    normalizerPosition(node) {
      return this.normalizer(node);
    },
    // 取消按钮
    cancel() {
      // this.reset();
      this.$emit("close", {refresh:false,isSuccess:true});
    },
    // 表单重置
    reset() {
      this.form = {};
      this.resetForm("form");
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.form.companyId  = this.companyId;
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      getDepartment(row.id).then(response => {
        this.form = response.data;
        if(this.form.agentManagerIds){
          let ids = this.form.agentManagerIds.split(",");
          if(ids && ids.length>0 && ids[0] != ""){
            this.agentManagerIds = [];
            ids.forEach(id=>{
              this.agentManagerIds.push(parseInt(id));
            });
          }else{
            this.agentManagerIds = [];
          }
        }
      })
      ;
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate(valid => {
            if(valid) {
              this.form.agentManagerIds = this.agentManagerIds.join(",");
              const postId = this.form.managerPostId;
              if(postId && typeof postId ==='string'){
                this.form.managerPostName = postId;
                this.form.managerPostId = null;
              }
              if (this.form.id != null) {
                if(this.form.id == this.form.pid){
                  this.msgError("上级部门不能选自己");
                  return;
                }
                showLoading();
                updateDepartment(this.form).then(response => {
                  this.msgSuccess("修改成功");
                  this.$emit("close", {refresh:true,isSuccess:true});
                  this.reset();
                  hideLoading();
                });
              } else {
                showLoading();
                addDepartment(this.form).then(response => {
                  this.msgSuccess("新增成功");
                  this.$emit("close", {refresh:true,isSuccess:true});
                  this.reset();
                  hideLoading();
                });
              }
            }
      });
    },
    normalizeNodeDisableEmp(node){
      let data = normalizerEmployMultiSelectTree(node,"id");
      if("employ" == node.className ){
        if(this.selectedEmpIds && this.selectedEmpIds.indexOf(data.id) >= 0){
          // console.log(this.selectedEmpIds);
          // console.log("normalizeNodeDisableEmp data=");
          // console.log(data);
          data.isDisabled = true;
        }
      }
      return data;
    },
    inputChange(val,instanceId){
      // console.log(val)
      if(!val) return;
      this.$emit("change",val);
      if(this.multiple){//只有多选模式下才考虑提示功能
        this.allLabel = val.map(item=>{
          let label = "";
          //getNode是我自己查找下拉树的内置方法，呕心沥血才找到的
          // console.log(this.$refs["treeRef"]);
          label = this.$refs["treeRef"].getNode(this.valueFormat == "object"?item.id:item).label;
          label = label.replace('(unknown)',"(外部)");
          return label;
        })
        let el = this.$el.querySelector(".vue-treeselect__multi-value");
        el.setAttribute("title",this.allLabel.join(", "));
      }else{
        this.removePlaceHolder();
      }
      this.addPlaceHolder(val);
    },
    //增加文字提示tooltip
    addPlaceHolder(value){
      // console.log("addPlaceHolder......")
      // console.log(value)
      let placeholder = this.$el.querySelector(".vue-treeselect__placeholder");
      let temp = value !== "_first"? value:this.value;
      if(placeholder && (!temp || !temp.length)){
        let content = placeholder.innerText;
        placeholder.parentNode.setAttribute("title",content);
      }
    },
    removePlaceHolder(){
      let placeholder = this.$el.querySelector(".vue-treeselect__placeholder");
      if(placeholder){
        placeholder.parentNode.removeAttribute("title");
      }
    },

  },
}
</script>

<style scoped>

</style>
