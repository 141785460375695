<template>
  <div class="app-container">
    <el-form ref="form" :model="form" :rules="rules" label-width="118px" >
      <el-row :gutter="35">
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" >
          <el-form-item label="名称" prop="name">
            <el-input v-model="form.name" :readonly="isReadOnly" placeholder="请输入名称"/>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" >
          <el-form-item label="考核类型" prop="type" :rules="{required: true,message: '考核类型不能为空', trigger: 'blur' }">
            <el-select :disabled="isReadOnly" v-model="form.type" clearable placeholder="考核类型" size="small">
              <el-option v-for="item in getDictDatas(DICT_TYPE.KPI_TYPE)" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="35">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" >
          <el-form-item label="内容" prop="content">
            <el-input type="textarea" rows="3" :readonly="isReadOnly" v-model="form.content" placeholder="请输入考核内容描述"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="35">
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
          <el-form-item label="考核开始日期" prop="startDate">
            <el-date-picker :disabled="isReadOnly" clearable size="small"
                            v-model="form.startDate" @change="dateChange"
                            type="date"
                            value-format="yyyy-MM-dd"
                            placeholder="开始日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
          <el-form-item label="考核结束日期" prop="endDate">
            <el-date-picker :disabled="isReadOnly" clearable size="small"
                            v-model="form.endDate" @change="dateChange"
                            type="date"
                            value-format="yyyy-MM-dd"
                            placeholder="结束日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="35">
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
          <el-form-item label="评分截止日期" prop="deadline" :rules="{required: true,message: '评分截止日期不能为空', trigger: 'blur' }">
            <el-date-picker :disabled="isReadOnly" clearable size="small"
                            v-model="form.deadline" @change="dateChange"
                            type="date"
                            value-format="yyyy-MM-dd"
                            placeholder="评分截止日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
          <el-form-item label="是否考核奖金" prop="hasBonus">
            <el-radio-group :disabled="isReadOnly" v-model="form.hasBonus">
              <el-radio :label="true">是&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-radio>
              <el-radio :label="false">否&nbsp;&nbsp;&nbsp;&nbsp;</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row v-if="form.hasBonus" :gutter="35">
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
          <el-form-item label="奖金类型" prop="bonusType">
            <el-radio-group :disabled="isReadOnly" v-model="form.bonusType">
              <el-radio label="固定金额">固定金额</el-radio>
              <el-radio label="月工资比例">月工资比例 </el-radio>
            </el-radio-group>
            <el-popover placement="top-start" title="奖金类型" width="350" trigger="click">
              <ul>
                <li>固定金额计算方式，奖金 = 奖金金额 * 评分结果 * 特殊权重。</li>
                <li>月工资比例计算方式， 奖金 = 月工资 *  比例值 * 评分结果 * 特殊权重。</li>
                <li>评价结果 = 最后得分/总分。</li>
                <li>特殊权重为特殊评分人设置的值。</li>
              </ul>
              <el-button type="primary" plain icon="el-icon-question" size="mini" :class="{uniAppMarginBottom:uniApp,webMarginBottom:!uniApp }" slot="reference">帮助</el-button>
            </el-popover>
          </el-form-item>
        </el-col>
        <template v-if="form.bonusType=='固定金额'" >
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
            <el-form-item label="奖金金额" prop="bonus">
              <el-input :disabled="isReadOnly" v-model="form.bonus" type="number" :readonly="isReadOnly" placeholder="请输入固定金额"/>
            </el-form-item>
          </el-col>
        </template>
        <template v-if="form.bonusType=='月工资比例'" >
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb0">
            <el-form-item label="比例" prop="bonus">
              <el-input :disabled="isReadOnly" v-model="form.bonus" type="number" :readonly="isReadOnly" placeholder="请输入比例值"/>
            </el-form-item>
          </el-col>
        </template>
      </el-row>
      <el-row :gutter="35">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">
          <el-form-item label="参与考核人员" prop="employ">
            <treeselect ref="treeRef" :disabled="isReadOnly" v-model="form.employ" :options="getOptions"
                        :limit="5" :limitText="count => `及其它${count}人`" @input="inputChange"
                        :multiple="multiple" noChildrenText="没有员工" value-consists-of="LEAF_PRIORITY"
                        :normalizer="normalizeNode" placeholder="请选择参与考核人员"/>
          </el-form-item>
        </el-col>
      </el-row>
<!--      <el-divider>各级评分人</el-divider>-->
      <el-row v-if="!isReadOnly" :gutter="10" class="mb8">
        <el-col :span="1.5">
          <el-form-item label="添加自评">
            <el-checkbox v-model="isSelfScore"></el-checkbox>
          </el-form-item>
        </el-col>
        <el-col v-if="!isSelfScore" :span="1.5">
          <el-form-item label="评分人">
            <treeselect v-if="!isSelfScore" ref="scoreEmpTree" v-model="empId" :options="employTree"
                        noChildrenText="没有员工" value-consists-of="LEAF_PRIORITY" :disable-branch-nodes="true"
                        :normalizer="normalizeNode" placeholder="请选择评分人员"/>
          </el-form-item>
        </el-col>
        <el-col :span="1.5">
          <el-form-item label="权重(%)">
            <el-input v-model="percentage" type="number" placeholder="请输入权重百分数，输入3表示3%"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="1.5">
          <el-button type="primary" plain icon="el-icon-plus" size="mini"
                     @click="handleAddLevelScoreItem" v-permission="['/kpi/generate/add','/kpi/generate/edit']"
          >添加评分人
          </el-button>
          <el-popover placement="top-start" title="评分人设置" width="350" trigger="click">
            <ul>
              <li>添加自评：如果需要考核人员自评，可以选择“添加自评”，设置权重，点击“添加评分人”。</li>
              <li>添加评分人：按顺序添加评分人后，考核表按照此顺序依次被评分人打分</li>
            </ul>
            <el-button type="primary" plain icon="el-icon-question" size="mini" :class="{uniAppMarginBottom:uniApp,webMarginBottom:!uniApp }" slot="reference">帮助</el-button>
          </el-popover>
        </el-col>
      </el-row>
      <el-row v-if="!isReadOnly" >
        <el-col :span="1.5">
          <el-tag v-for="tag in form.levelScore.items" :key="tag.id" :closable="!isReadOnly"
                  @close="handleDeleteLevelScoreItem(tag)">
            {{tag.empName}}({{tag.percentage}}%)
          </el-tag>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="1.5">
          <el-steps align-center>
            <el-step v-for="step in form.levelScore.items" :key="step.empId+step.empName" :title="step.empName" :description="'评分权重占比'+step.percentage+'%'"></el-step>
          </el-steps>
        </el-col>
        <el-col :span="1.5">
          <el-form-item label="特殊评分人">
            <treeselect ref="specialEmp" :disabled="isReadOnly" v-model="form.special.empId" :options="employTree"
                        noChildrenText="没有员工" value-consists-of="LEAF_PRIORITY" :disable-branch-nodes="true"
                        :normalizer="normalizeNode" placeholder="请选择特殊评分人员"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
      </el-row>
      <!--
      <el-table :data="form.levelScore.items" stripe border>
        <el-table-column label="评分人类型" align="center">
          <template slot-scope="scope">
            <el-select :disabled="isReadOnly" v-model="scope.row.type" clearable placeholder="请选择评分人类型" size="small">
              <el-option v-for="item in getDictDatas(DICT_TYPE.KPI_SCORE_EMP_TYPE)" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="评分人" align="center">
          <template slot-scope="scope">
            <treeselect v-if="scope.row.type=='指定评分人'" :disabled="true" v-model="empId" :options="employTree"
                        noChildrenText="没有员工" value-consists-of="LEAF_PRIORITY"
                        :normalizer="normalizeNode" placeholder="请选择参与考核人员"/>
            <span v-else>自动设置</span>
          </template>
        </el-table-column>
        <el-table-column label="评分权重(%)" align="center">
          <template slot-scope="scope">
            <el-input v-model="scope.row.percentage" type="number" placeholder="请输入权重百分数，输入3表示3%"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="顺序" align="center">
          <template slot-scope="scope">
            <el-input v-model="scope.row.order" type="number" placeholder="请输入评分先后顺序"></el-input>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
          <template slot-scope="scope">
            <el-button
                size="mini"
                type="text"
                icon="el-icon-delete"
                @click="handleDeleteLevelScoreItem(scope.row)"
                v-permission="['/cb/cbTaxSetting/add','/cb/cbTaxSetting/edit']"
            >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      -->
      <el-row :gutter="35">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">

        </el-col>
      </el-row>
      <el-divider>考核项目</el-divider>
      <el-row v-if="!isReadOnly" :gutter="10" class="mb8">
        <el-col :span="1.5">
          <el-button type="primary" plain icon="el-icon-plus" size="mini"
                     @click="handleAddItem" v-permission="['/kpi/generate/add','/kpi/generate/edit']"
          >新增项目
          </el-button>
        </el-col>
      </el-row>
      <el-table :data="form.items" style="width: 100%;margin-bottom: 20px;" row-key="id" :indent='indents'
                border default-expand-all :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
        <el-table-column prop="level" label="层级" width="168">
          <template slot-scope="scope">
            <span>{{scope.row.level}}级</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="考核项目(指标)" width="168">
          <template slot-scope="scope">
            <el-input v-if="!isReadOnly" v-model="scope.row.name" placeholder="请输入考核项目（指标）"></el-input>
            <span v-else>{{scope.row.name}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="score" label="权重(分)" width="88">
          <template slot-scope="scope">
            <el-input v-if="!isReadOnly" v-model="scope.row.score" type="number" placeholder="请输入权重(分)"></el-input>
            <span v-else>{{scope.row.score}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="content" label="考核内容及定义" min-width="198">
          <template slot-scope="scope">
            <el-input v-if="!isReadOnly" type="textarea" rows="2" v-model="scope.row.content" placeholder="请输入考核内容及定义"></el-input>
            <span v-else>{{scope.row.content}}</span>
          </template>
        </el-table-column>
        <el-table-column v-if="!isReadOnly" label="操作" align="center" class-name="small-padding fixed-width" width="136">
          <template slot-scope="scope">
            <el-button size="mini" type="text" icon="el-icon-plus"
                       @click="handleAddSubItem(scope.row)" v-permission="['/kpi/generate/add','/kpi/generate/edit']"
            >子项</el-button>
            <el-button size="mini" type="text" icon="el-icon-delete"
                       @click="handleDeleteItem(scope.row)" v-permission="['/kpi/generate/add','/kpi/generate/edit']"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <div slot="footer" class="dialog-footer" style="text-align: center">
      <el-button v-if="!isReadOnly" type="primary" @click="submitForm" :loading="saving"
                 v-permission="['/kpi/generate/add', '/kpi/generate/edit']">保 存</el-button>
      <el-button @click="cancel">关 闭</el-button>
    </div>
  </div>
</template>

<script>
import {listKpi,getKpi,delKpi,addKpi,updateKpi,exportKpi } from "@/api/kpi/kpi";
import {getKpiTemplate} from "@/api/kpi/template";
import permission from '@/directive/permission/index.js'; // 权限判断指令
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import {DICT_TYPE,getDictLabelFromData, getDictDataLabel, getDictDatas} from "@/utils/dict";
import { getOrgEmpTree, normalizerEmployMultiSelectTree} from '@/views/pages/employ/components/empOrgTree';
import { showLoading, hideLoading } from '@/utils/reqloading';
import {Local} from '@/utils/storage.js'

export default {
  name: "kpiEdit",
  directives: { permission },
  components: {
    Treeselect,
  },
  data() {
    return {
      empId:null,
      percentage:0,
      isSelfScore: false,
      levelScoreType: '自评', // 评分人类型
      multiple: true,
      companyId: null,
      indents: 18,
      // 遮罩层
      saving: false,
      loading: true,
      // 查询参数
      where: {
        query: '',
        filters: []
      },
      isReadOnly: true,
      // 表单参数
      form: {
        levelScore:{
          items:[],
        }
      },
      defaultProps: {
        children: "children",
        label: "name"
      },
      // 表单校验
      rules: {
        name: [
          {
            required: true,
            message: "名称不能为空", trigger: "blur" }
        ],
        content: [
          {
            required: true,
            message: "内容描述不能为空", trigger: "blur" }
        ],
      },
      op: null,
      type: null,
      templateId: null,
      kpiTemplate: null,
      employTree: [],
      uniApp:false,
    };
  },
  created() {
    this.uniApp=Local.get("isUniapp")?true:false;
  },
  mounted() {
    this.companyId = this.$store.getters.userInfo.companyId;
    console.log(this.$route.query)
    if (this.$route.query) {
      const {op, id, readOnly, type, templateId, copyKpi} = this.$route.query;
      this.op = op;
      this.form = {};
      this.form.id = id;
      this.type = type;
      this.templateId = templateId;
      if(copyKpi) this.form = copyKpi;
      if(readOnly) this.isReadOnly = readOnly;
      else this.isReadOnly = false;
    }
    showLoading();
    getOrgEmpTree(this.companyId, true,(tree, companyList, departmentList, employList )=>{
      this.employTree = tree;
      console.log('this.employTree');
      console.log(this.employTree);
      this.departmentList = departmentList;
      this.departmentOptions = this.handleTree(departmentList, "id", "pid");

      this.companyOptions = [];
      this.companyOptions = this.handleTree(companyList, "id", "pid");

      this.employList = employList;
      setTimeout(()=>{
        this.inputChange(this.form.employ);
        hideLoading();
      },500);
    });

    this.load();
    console.log("do addPlaceHolder")
    this.addPlaceHolder("_first");//首次进来

  },
  computed: {
    getOptions() {
      return this.employTree;
    },
  },

  methods: {
    genId(){
      if(this.form.maxItemId){
        this.form.maxItemId++;
      }else{
        this.form.maxItemId = 1;
      }
      return this.form.maxItemId;
    },
    handleAddItem(){
      if(!this.form.items){
        this.$set(this.form,"items", []);
      }
      this.form.items.push({id:this.genId(),level:1,name:"",score:0,content:"",children:[]});
    },
    handleAddSubItem(row){
      if(!row.children){
        this.$set(row,"children", []);
      }
      let defautScore = row.score;
      let child = row.children
      if(child.length>0){
        for(let i = 0; i < child.length; i++){
          defautScore -= child[i].score;
        }
      }
      row.children.push({id:this.genId(),level:row.level+1,name:"",score:defautScore,content:"",children:[]});
    },
    handleDeleteItem(row){
      this.deleteSubItem(this.form.items,row);
    },
    deleteSubItem(items, row) {
      console.log(items);
      var index = items.indexOf(row);
      if (index > -1) {
        items.splice(index, 1);
        return true;
      }else{
        for(let i =0; i<items.length; i++) {
          if(this.deleteSubItem(items[i].children, row)){
            return true;
          }
        }
        return false;
      }
    },
    setDefaultLevelScore(data){
      if(!data.levelScore){
        this.$set(data,"levelScore", {items:[]});
      }else if(!data.levelScore.items){
        this.$set(data.levelScore,"items", []);
      }
      if(!data.levelScore.maxOrder)data.levelScore.maxOrder = 1;
      if(!data.levelScore.isSelfScore)data.levelScore.isSelfScore = false;
      if(!data.special) data.special = {empId:null, empName:null}
    },
    handleAddLevelScoreItem(){
      this.setDefaultLevelScore(this.form);
      if(this.isSelfScore){
        this.form.levelScore.items.push({type:'自评',empId:null,empName:'自评',percentage:this.percentage,order:this.form.levelScore.maxOrder++});
      }else{
        if(!this.empId){
          this.msgError("请选择评分人");
          return;
        }
        let empName =  this.$refs["treeRef"].getNode(this.empId).label;
        this.form.levelScore.items.push({type:'指定评分人',empId:this.empId,empName:empName,percentage:this.percentage,order:this.form.levelScore.maxOrder++});
      }
    },
    handleDeleteLevelScoreItem(row){
      let items = this.form.levelScore.items;
      var index = items.indexOf(row);
      if (index > -1) {
        items.splice(index, 1);
      }
    },
    load() {
      console.log("load...")
      if(this.op == 'add'){
        let formEmpty = {};
        this.setDefaultLevelScore(formEmpty);
        this.form = formEmpty;
        if(this.type == 'template' && this.templateId){
          getKpiTemplate(this.templateId).then(response => {
            this.kpiTemplate = response.data;
            let form = {templateId: this.kpiTemplate.id,items: this.kpiTemplate.items,name: this.kpiTemplate.name,
              content: this.kpiTemplate.content,maxItemId: this.kpiTemplate.maxItemId}
            this.setDefaultLevelScore(form);
            this.form = form;
          });
        }
      }else if(this.op == 'copy'){

      }else if(this.op == 'edit'){
        this.handleUpdate(this.form.id);
      }else if(this.op == 'view'){
        this.handleUpdate(this.form.id);
      }
    },
    dateChange(){

    },
    // 取消按钮
    cancel() {
      // this.$emit("close", {refresh:false,isSuccess:true});
      this.bus.$emit('onCurrentContextmenuClick', {
        id: 1,
        path: this.$route.path,
      });
    },
    normalizeNode(node){
      return normalizerEmployMultiSelectTree(node,"id");
    },

    /** 修改按钮操作 */
    handleUpdate(id) {
      getKpi(id).then(response => {
        this.setDefaultLevelScore(response.data);
        this.form = response.data;
      });
    },
    // copyItems(items){
    //   let id = 1;
    //   if(items && items.length > 0){
    //     let rt = [];
    //     for(let i = 0; i < items.length; i++){
    //       let item = {id:id++,level:items[i].level,name:items[i].name,score:items[i].score,content:items[i].content};
    //       item.children = this.copyItems(items[i].children);
    //       rt.push(item);
    //     }
    //     return rt;
    //   }else{
    //     return [];
    //   }
    // },
    validateScore(item){
      if(item && item.children && item.children.length>0){
        let sumScore = 0;
        let childs = item.children;
        for(let i =0; i<childs.length; i++){
          const subValid = this.validateScore(childs[i])
          if(subValid){
            return subValid;
          }
          sumScore = Number(sumScore) + Number(childs[i].score);
        }
        if(sumScore != item.score){
          return "考核项【"+item.name+"】的权重分为"+item.score+"，不等于子项的权重分之和"+sumScore+"，请修正。"
        }else{
          return null;
        }
      }else{
        return null;
      }
    },
    /** 提交按钮 */
    submitForm() {
      this.form.companyId = this.$store.getters.userInfo.companyId;
      this.$refs["form"].validate(valid => {
            if(valid) {
              console.log(this.form);
              const items = this.form.items;
              if(items && items.length>0){
                for(let i = 0; i < items.length; i++){
                  const v = this.validateScore(items[i]);
                  if(v) {
                    this.msgError(v);
                    return;
                  }
                }
              }
              // 保存评分人结构信息
              if(this.form.levelScore&&this.form.levelScore.items){
                this.form.levelScore.maxOrder = this.form.levelScore.items.length;
                for(let i = 0; i < this.form.levelScore.items.length; i++){
                  let scoreItem = this.form.levelScore.items[i];
                  const idx = i;
                  this.form.levelScore["type"+idx] = scoreItem.type;
                  this.form.levelScore["empId"+idx] = scoreItem.empId;
                  this.form.levelScore["empName"+idx] = scoreItem.empName;
                  this.form.levelScore["percentage"+idx] = scoreItem.percentage;
                  this.form.levelScore["order"+idx] = i;
                }
                for(let i = this.form.levelScore.items.length; i < 50; i++){
                  if(this.form.levelScore["empName"+i]){
                    const idx = i;
                    delete this.form.levelScore["type"+idx];
                    delete this.form.levelScore["empId"+idx];
                    delete this.form.levelScore["empName"+idx];
                    delete this.form.levelScore["percentage"+idx];
                    delete this.form.levelScore["order"+idx];
                  }else{
                    break;
                  }
                }
              }
              this.saving = true;
              if (this.form.id != null) {
                updateKpi(this.form).then(response => {
                  this.msgSuccess("修改成功");
                  this.form = response.data;
                  this.saving = false;
                }).catch(err=>{ this.saving = false;}
                );
              } else {
                addKpi(this.form).then(response => {
                  this.msgSuccess("新增成功");
                  this.form = response.data;
                  this.saving = false;
                }).catch(err=>{ this.saving = false;}
                );
              }
            }
          }
      );
    },

    inputChange(val,instanceId){
      console.log(val)
      if(!val) return;
      this.$emit("change",val);
      if(this.multiple){//只有多选模式下才考虑提示功能
        this.allLabel = val.map(item=>{
          let label = "";
          //getNode是我自己查找下拉树的内置方法，呕心沥血才找到的
          // console.log(this.$refs["treeRef"]);
          label = this.$refs["treeRef"].getNode(this.valueFormat == "object"?item.id:item).label;
          label = label.replace('(unknown)',"(外部)");
          return label;
        })
        let el = this.$el.querySelector(".vue-treeselect__multi-value");
        el.setAttribute("title",this.allLabel.join(", "));
      }else{
        this.removePlaceHolder();
      }
      this.addPlaceHolder(val);
    },
    //增加文字提示tooltip
    addPlaceHolder(value){
      console.log("addPlaceHolder......")
      console.log(value)
      let placeholder = this.$el.querySelector(".vue-treeselect__placeholder");
      let temp = value !== "_first"? value:this.value;
      if(placeholder && (!temp || !temp.length)){
        let content = placeholder.innerText;
        placeholder.parentNode.setAttribute("title",content);
      }
    },
    removePlaceHolder(){
      let placeholder = this.$el.querySelector(".vue-treeselect__placeholder");
      if(placeholder){
        placeholder.parentNode.removeAttribute("title");
      }
    },

  },
}
</script>

<style scoped>

</style>
