<template>
  <div class="app-container">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="公司部门编制设置" name="first">
        <h3 align="center">公 司 部 门 编 制 设 置
        </h3>
        <el-row :gutter="24">
          <el-col :xs="24" :sm="24" :md="18" :lg="16" :xl="12" class="mb20">
            <div class="head-container" align="right">
              <el-button size="mini" type="primary" @click="saveCompanyDepartmentPlanCount" style="margin-bottom:10px;margin-top:10px;">保存</el-button>
              <el-button size="mini" type="primary" @click="cancelCompanyDepartmentChange">恢复</el-button>
            </div>
            <div class="head-container">
              <el-input
                  v-model="nameFilter"
                  placeholder="请输入名称"
                  clearable
                  size="small"
                  prefix-icon="el-icon-search"
                  style="margin-bottom: 20px"
              />
            </div>
            <div class="head-container">
  
              <el-tree
                  :data="treeData"
                  :props="defaultProps"
                  :expand-on-click-node="false"
                  :filter-node-method="filterNode"
                  ref="tree"
                  default-expand-all
                  class="video-tree"
                  highlight-current
              >
                    <span class="custom-tree-node" slot-scope="{ node, data }">
                      <span>{{ node.label }}</span>
                      <span>
                        编制：<el-input @change="changeCompanyDepartmentPlanCount(data)" v-model="data.planCount"
                                :class="data.err?'errInput':data.changed?'annualInputCg':'annualInput'" style="width: 78px"/>
                        在编：<el-input disabled v-model="data.realCount" class="annualInput" style="width: 78px"/>
                      </span>
                    </span>
              </el-tree>

            </div>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="岗位编制设置" name="second">
        <h3 align="center">岗 位 编 制 设 置
        </h3>
        <el-row>
          <el-col :xs="24" :sm="24" :md="18" :lg="16" :xl="12" >
           <div class="head-container" align="right">
              <el-button size="mini" type="primary" @click="savePositions">保存</el-button>
              <el-button size="mini" type="primary" @click="cancelPositons">恢复</el-button>
            </div>
          </el-col>
        </el-row>
        <el-row v-if="companyList.length > 1" :gutter="35">
          <el-col :xs="24" :sm="24" :md="18" :lg="16" :xl="12">
            选择公司:<treeselect v-model="companyId" :options="companyOptions"
                             :normalizer="normalizerCompany" placeholder="请选择公司" @select="selectCompany"/>
          </el-col>
        </el-row>
        <el-row :gutter="35">
          <el-col :xs="24" :sm="24" :md="18" :lg="16" :xl="12" class="mb20">
            <!-- <div class="head-container" align="right">
              <el-button size="mini" type="primary" @click="savePositions">保存</el-button>
              <el-button size="mini" type="primary" @click="cancelPositons">恢复</el-button>
            </div> -->
            <el-table v-loading="loading" :data="positionList" >
              <el-table-column label="岗位编号" align="center" prop="num" width="100" sortable/>
              <el-table-column label="岗位名称" align="center" prop="name" width="180" sortable/>
              <el-table-column label="编制人数" align="center" prop="planCount" >
                <template slot-scope="scope">
                  <el-input v-model="scope.row.planCount" @change="changePositionPlanCount(scope.row)"
                            :class="scope.row.err?'errInput':scope.row.changed?'annualInputCg':'annualInput'">
                  </el-input>
                </template>
              </el-table-column>
              <el-table-column label="在编人数" align="center" prop="realCount"/>
              <el-table-column label="缺编人数" align="center">
                <template slot-scope="scope">
                  <span v-if="scope.row.planCount&&(scope.row.planCount-scope.row.realCount)>0">
                  {{scope.row.planCount - scope.row.realCount}}</span>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-row>
      </el-tab-pane>
    </el-tabs>

    <el-row :gutter="35">
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb20">
      </el-col>
    </el-row>
    <!--    <div slot="footer" class="dialog-footer">-->
    <!--      <el-button type="primary" @click="submitForm" >确 定</el-button>-->
    <!--      <el-button @click="cancel">关 闭</el-button>-->
    <!--    </div>-->
  </div>

</template>

<script>
import {listPosition} from "@/api/org/position";
import {listSysSetting,updateSysSetting} from "@/api/sys/sysSetting";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import {getOrgTree} from '../components/orgtree';
import { showLoading, hideLoading } from '@/utils/reqloading';
import {sumTreeChild,getQueryParam} from "@/utils/tools";
import {updateCompanyDepartment, updatePositions} from "@/api/org/planCount";

export default {
  name: "planCount",
  components: {
    Treeselect,
  },
  watch:{
    nameFilter(val) {
      this.$refs.tree.filter(val);
    }

  },
  data() {
    return {
      activeName:'first',
      treeExpandAll: true,
      treeExpandAllOt: true,
      nameFilter: undefined,
      treeSearchNameOt: null,
      companyId: null,
      curCompany: null,
      treeData: [],
      defaultProps: {
        children: "children",
        label: "name"
      },
      changedMap:{},
      companyList:[],
      companyOptions:[],
      departmentList:[],
      positionList:[],
      positionChanged:[],
      // 表单参数
      form: {
      },
      loading: false,
      // 表单校验
      rules: {
        //   annualDays: [
        //     {
        //       required: true,
        //       message: "年假天数不能为空", trigger: "blur" }
        //   ],
      }
    };
  },
  created() {
    this.companyId = this.$store.getters.userInfo.companyId;
    this.load();
  },
  methods: {
    load(){
      showLoading();
      this.changedMap = [];
      this.positionChanged = [],
      getOrgTree((tree, companyList, departmentList, positionList )=>{
        console.log("return tree:"+tree);
        console.log(tree);
        sumTreeChild(tree,'id','pid');
        this.treeData = tree;

        hideLoading();
        this.departmentList = departmentList;
        this.companyList = companyList;
        // this.positionList = positionList;
        this.companyOptions = [];
        this.companyOptions = this.handleTree(companyList, "id", "parentId");
      });
      this.getPosition();
    },
    getPosition(){
      this.loading = true;
      let filter = {};
      filter['companyId'] = {
        type: 'eq',
        value: this.companyId,
      }
      listPosition(getQueryParam(filter,1, 1000,'')).then(response => {
        this.positionList = response.data.records;
        this.positionOptions = this.handleTree(response.data.records, "id", "pid");
        // this.total = response.data.total;
        this.loading = false;
      });
    },
    isInteger(obj) {
      const r = /^\+?[0-9][0-9]*$/;
      return r.test(obj);
      // return typeof obj === 'number' && obj%1 === 0
    },
    changeCompanyDepartmentPlanCount(data){
      data.changed = true;
      const count = parseInt(data.planCount);
      if(!this.isInteger(data.planCount) || count<0){
        this.msgError("编制人数必须是正整数");
        this.$set(data,'err',true);
        return;
      }
      this.changedMap[data.treeId] = data;
      console.log(this.changedMap);
      if(data.planCount<data.realCount){
        this.msgError("编制人数不能小于在编人数");
        this.$set(data,'err',true);
        return;
      }else{
        this.$set(data,'err',false);
      }
      if(data.className == 'company'){
        // 所有部门编制人数不能大于公司编制人数
        const sum = this.checkCompanyPlanCount(data);
        if(sum != -1){
          this.msgError("部门编制人数不能大于公司编制人数, 部门目前编制："+sum+"人");
          this.$set(data,'err',true);
          return;
        }
        else{
          this.$set(data,'err',false);
        }
      }else if(data.className == 'department'){
        // 所有子部门编制人数不能大于父部门编制人数
        const sum = this.checkDepartmentPlanCount(data);
        if(sum != -1){
          this.msgError("该部门编制人数必须大于子部门编制人数之和，子部门目前编制人数之和共："+sum+"人");
          this.$set(data,'err',true);
          return;
        } else{
          // 验证修改的部门编制人数和兄弟编制之和是否大于父部门编制
          if(data.pid > 0){
            // 有上级部门
            const parent = this.getDataFromTreeById(this.treeData,'department'+data.pid)
            console.log(parent)
            if(parent && parent.planCount){
              const sum = this.checkDepartmentPlanCount(parent);
              console.log(sum);
              console.log(data.planCount);
              console.log(parent.planCount);
              if(sum != -1) {
                let rs = parseInt(parent.planCount) - (sum - parseInt(data.planCount));
                this.msgError("该部门编制人数和兄弟部门编制人数之和必须小于上级部门编制人数，该部门目前编制最多只能：" + rs + "人");
                this.$set(data, 'err', true);
                return;
              }
            }else{
              this.msgInfo("该部门上级部门编制未设置");
            }
          }else if(data.pid == 0){
            // 已经是顶级部门，验证公司
            const parent = this.getDataFromTreeById(this.treeData,'company'+data.pid);
            console.log(parent)
            if(parent && parent.planCount){
              const sum = this.checkCompanyPlanCount(parent);
              if(sum != -1){
                let rs = parseInt(parent.planCount) - (sum - parseInt(data.planCount));
                this.msgError("该部门编制人数和兄弟部门编制人数之和不能大于公司编制人数，该部门目前编制最多只能：" + rs + "人");
                this.$set(data,'err',true);
                return;
              }
            }
          }
          this.$set(data,'err',false);
        }

      }
    },
    checkCompanyPlanCount(data){
      let sum = 0;
      if(data.children && data.children.length>0){
        data.children.forEach( item =>{
          if(item.className == 'department'){
            sum += item.planCount?parseInt(item.planCount):0;
            console.log(item.planCount);
          }
        });
      }
      if(sum > data.planCount){
        return sum;
      }
      return -1;
    },
    // return -1 没有错误，否则有错误
    checkDepartmentPlanCount(data){
      let sum = 0;
      if(data.children && data.children.length>0){
        data.children.forEach( item =>{
          sum += item.planCount?parseInt(item.planCount):0;
          console.log(item.planCount);
          console.log(sum);
        });
      }
      console.log(sum);
      if(sum > data.planCount){
        return sum;
      }
      return -1;
    },
    //
    getDataFromTreeById(tree, treeId){
      if(tree&&tree.length>0){
        for(let i = 0; i < tree.length; i++){
          if(tree[i].treeId == treeId) return tree[i];
          else if(tree[i].children&&tree[i].children.length>0){
            const data = this.getDataFromTreeById(tree[i].children, treeId);
            if(data) return data;
          }
        }
      }else{
        return null;
      }

    },
    expandAll() {
      this.treeExpandAll = !this.treeExpandAll;
      var nodes = this.$refs.tree.store.nodesMap;
      for (var i in nodes) {
        nodes[i].expanded = this.treeExpandAll;
      }
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },

    saveCompanyDepartmentPlanCount(){
      console.log(this.changedMap);
      let changeList = [];
      for(let key in this.changedMap){
        const v = this.changedMap[key];
        if(v.err){
          this.$alert('编制人数不正确，请修正后再提交', '错误');
          return;
        }
        changeList.push({id:v.id, className:v.className,planCount:v.planCount});
      }
      console.log("changed list=");
      console.log(changeList);
      showLoading();
      updateCompanyDepartment(changeList).then(response => {
        this.load();
        // console.log(response.data);
        // if(response.data){
        //   this.employList = response.data;
        //   this.copyEmployListToMap();
        // hideLoading();
        this.msgSuccess("保存成功")
        // }
      });
    },
    cancelCompanyDepartmentChange(){
      this.load();
    },
    changePositionPlanCount(data){
      console.log(data);
      this.$set(data,'changed',true);
      const count = parseInt(""+data.planCount);
      if(!this.isInteger(data.planCount) || count<0){
        this.msgError("编制人数必须是正整数");
        this.$set(data,'err',true);
        return;
      }
      if(!this.curCompany){
        this.curCompany =  this.getDataFromTreeById(this.treeData,'company'+this.companyId);
      }
      if(this.curCompany && this.curCompany.planCount && this.curCompany.planCount > 0){
        let sum = 0;
        this.positionList.forEach(position =>{
          if(position.planCount&&position.planCount > 0){
            sum += parseInt(''+position.planCount);
          }
        });
        if(sum > this.curCompany.planCount){
          this.msgError("所有编制人数之和必须小于公司编制人数");
          this.$set(data,'err',true);
          return;
        }
      }
      this.$set(data,'err',false);
    },
    savePositions(){
      console.log(this.positionList);
      let changeList = [];
      for(let i = 0; i < this.positionList.length; i++){
        const v = this.positionList[i];
        if(v.err){
          this.$alert('编制人数不正确，请修正后再提交', '错误');
          return;
        }
        if(v.changed){
          changeList.push({id:v.id, className:v.className,planCount:v.planCount});
        }
      }
      console.log("changed list=");
      console.log(changeList);
      showLoading();
      updatePositions(changeList).then(response => {
        this.load();
        hideLoading();
        this.msgSuccess("保存成功")
      });
    },
    cancelPositons(){
      this.getPosition();
    },
    /** 转换公司数据结构 */
    normalizerCompany(node) {
      return this.normalizer(node,'id','companyName');
    },
    selectCompany(node, instanceId) {
      this.companyId = node.id;
      this.curCompany = node;
      this.getPosition();

    },
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },

    cancel(){
      this.bus.$emit('onCurrentContextmenuClick', {
        id: 1,
        path: this.$route.path,
      });
    },
  }
}

</script>

<style scoped >
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

.annualInput >>> input{
  /*width: 50px;*/
  height: 26px;
}
.annualInputCg >>> input{
  /*width: 50px;*/
  height: 26px;
  color: #c75151;
  background-color: antiquewhite;
}

.errInput >>> input{
  /*width: 50px;*/
  height: 26px;
  color: #51c7ab;
  background-color: #d40d0d;

}

</style>

