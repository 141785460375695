<template>
	<div>
		<el-form :model="form" ref="form" label-width="80px">
			<el-row :gutter="24">
				<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
					<el-form-item label="公司名称" prop="companySelect"
						:rules="[{ required: true, message: '公司必选', trigger: 'blur' }]">
						<el-select v-model="form.companySelect" style="width:100%;" clearable @clear="handleClearable"
							placeholder="请选择" collapse-tags ref="companySelected">
							<el-option :value="companyId" :label="form.companySelect" style="height: auto">
								<el-tree :data="companyOptions" :props="defaultProps" :expand-on-click-node="false"
									ref="tree" show-checkbox node-key="id" default-expand-all
									@node-click="handleNodeClick" @check="handleNodeClick" highlight-current/>
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
					<el-form-item label="部门" prop="departmentId"
						:rules="[{ required: true, message: '部门必选。', trigger: 'blur' }]">
						<treeselect v-model="form.departmentId" :options="departmentOptions"
							:normalizer="normalizerDepartment" placeholder="请选择所属部门" />
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
					<el-form-item label="角色" prop="roleId"
						:rules="[{ required: true, message: '角色必选。', trigger: 'blur' }]">
						<el-select style="width: 100%" v-model="form.roleId" placeholder="请选择角色" @change="chooseDTChange">
							<el-option v-for="item in roleList" :key="item.id" :label="item.zhName" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align:right;">
					<el-button type="primary" :loading="submitLoading" @click="convertResumeToEmployee">提交</el-button>
					<el-button type="primary" @click="closeHiringDialog()">关闭</el-button>
				</el-col>
			</el-row>
		</el-form>
	</div>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import permission from '@/directive/permission/index.js'; // 权限判断指令
import { getTreeselectCompany } from '@/api/org/company';
import { getTreeselectDepartment } from '@/api/org/department';
import { listEntryRole } from "@/api/sys/role";
import { convertToEmployee, } from "@/api/recruit/positionManagement"
export default {
	name: 'employeeConversion',
	components: {
		Treeselect,
	},
	data() {
		return {
			form:{},
			companySelect: '',
			companyId: '',
			isCompnayVisible: false,
			// 公司树选项
			companyOptions: [],
			companyList: [],
			departmentId: null,
			departmentOptions: [],
			departmentList: [],
			roleList: [],
			roleIds: [],
			defaultProps: {
				children: 'children',
				label: 'companyName',
			},
			submitLoading: false,
		};
	},
	props: {
		resumeId: String,
		positionId: String
	},
	created() {
		getTreeselectCompany(null, (list, options) => {
			this.companyList = list;
			if (this.companyList.length > 1) {
				this.isCompnayVisible = true;
			}
			this.companyOptions = options;

		});

		this.getRoles();
	},
	mounted() {

	},

	methods: {
		// 节点单击事件
		handleNodeClick(data) {
			this.companyId = data.id;
			this.form.companySelect = data.companyName;
			let selectArray = [];
			selectArray.push(this.companyId);
			this.$refs.tree.setCheckedKeys(selectArray);
			this.$refs.companySelected.blur();
			this.getDepartment(this.companyId);
		},

		getDepartment(companyId) {
			getTreeselectDepartment(companyId, false, (list, options) => {
				this.departmentList = list;
				this.departmentOptions = options;
			});
		},

		getRoles() {
			return listEntryRole().then(response => {
				this.roleList = response.data;
				return response;
			});
		},
		/** 转换部门数据结构 */
		normalizerDepartment(node) {
			return this.normalizer(node);
		},
		chooseDTChange() {
			this.$forceUpdate();
		},

		handleClearable() {
			this.$refs.tree.setCheckedKeys([]);
			this.companyId = null;
			this.companySelect = '';
		},
		closeHiringDialog() {
			this.$emit('close');
		},
		convertResumeToEmployee() {
			let that = this;
			this.$refs['form'].validate((valid) => {
				if (valid) {
					let data = {};
					data.companyId = that.companyId;
					data.departmentId = that.form.departmentId;
					data.positionId = that.positionId;
					data.roleId = that.form.roleId;
					data.resumeId = that.resumeId;
					that.submitLoading = true;
					
					convertToEmployee(data)
						.then((response) => {
							that.submitLoading = false;
							if (response.data.success) {
								that.msgSuccess('成功转换成员工。');
							}
							else {
								that.msgError('转换失败。' + response.data.msg);
							}

						})
						.catch((err) => {
							that.submitLoading = false;
							that.msgError('转换失败，' + err);
						});
				}
			});
		}
	},
};
</script>

<style lang="scss" scoped>

</style>