<template>
<div>
  <div>
    <el-form ref="form" :model="form" label-width="90px">
      <el-row>
        <el-col :xs="24" :sm="5" :md="5" :lg="5" :xl="5" class="mb0">
          <el-checkbox v-model="form.msgNotify">通知公告短信</el-checkbox>
        </el-col>
        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8" class="mb0">
          <el-form-item label="模板代码" prop="msgNotifyTemplateCode">
            <el-input v-model="form.msgNotifyTemplateCode" placeholder="请输入短信模板代码"/>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8" class="mb0">
          <el-form-item label="签名名称" prop="msgNotifySignName">
            <el-input v-model="form.msgNotifySignName" placeholder="请输入短信签名名称"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :xs="24" :sm="5" :md="5" :lg="5" :xl="5" class="mb0">
          <el-checkbox v-model="form.msgRemid">系统提醒短信</el-checkbox>
        </el-col>
        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8" class="mb0">
          <el-form-item label="模板代码" prop="msgRemidTemplateCode">
            <el-input v-model="form.msgRemidTemplateCode" placeholder="请输入短信模板代码"/>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8" class="mb0">
          <el-form-item label="签名名称" prop="msgRemidSignName">
            <el-input v-model="form.msgRemidSignName" placeholder="请输入短信签名名称"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :xs="24" :sm="5" :md="5" :lg="5" :xl="5" class="mb0">
          <el-checkbox v-model="form.msgOa">OA办公流程短信</el-checkbox>
        </el-col>
        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8" class="mb0">
          <el-form-item label="模板代码" prop="msgOaTemplateCode">
            <el-input v-model="form.msgOaTemplateCode" placeholder="请输入短信模板代码"/>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8" class="mb0">
          <el-form-item label="签名名称" prop="msgOaSignName">
            <el-input v-model="form.msgOaSignName" placeholder="请输入短信签名名称"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :xs="24" :sm="5" :md="5" :lg="5" :xl="5" class="mb0">
          <el-checkbox v-model="form.msgEntry">入职办理短信</el-checkbox>
        </el-col>
        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8" class="mb0">
          <el-form-item label="模板代码" prop="msgEntryTemplateCode">
            <el-input v-model="form.msgEntryTemplateCode" placeholder="请输入短信模板代码"/>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8" class="mb0">
          <el-form-item label="签名名称" prop="msgEntrySignName">
            <el-input v-model="form.msgEntrySignName" placeholder="请输入短信签名名称"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :xs="24" :sm="5" :md="5" :lg="5" :xl="5" class="mb0">
          <el-checkbox v-model="form.training">培训短信</el-checkbox>
        </el-col>
        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8" class="mb0">
          <el-form-item label="模板代码" prop="trainingTemplateCode">
            <el-input v-model="form.trainingTemplateCode" placeholder="请输入短信模板代码"/>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8" class="mb0">
          <el-form-item label="签名名称" prop="trainingSignName">
            <el-input v-model="form.trainingSignName" placeholder="请输入短信签名名称"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :xs="24" :sm="5" :md="5" :lg="5" :xl="5" class="mb0">
          <el-checkbox v-model="form.kpi">绩效考核短信</el-checkbox>
        </el-col>
        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8" class="mb0">
          <el-form-item label="模板代码" prop="kpiTemplateCode">
            <el-input v-model="form.kpiTemplateCode" placeholder="请输入短信模板代码"/>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8" class="mb0">
          <el-form-item label="签名名称" prop="kpiSignName">
            <el-input v-model="form.kpiSignName" placeholder="请输入短信签名名称"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :xs="24" :sm="5" :md="5" :lg="5" :xl="5" class="mb0">
          <el-checkbox v-model="form.contract">合同短信</el-checkbox>
        </el-col>
        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8" class="mb0">
          <el-form-item label="模板代码" prop="contractTemplateCode">
            <el-input v-model="form.contractTemplateCode" placeholder="请输入短信模板代码"/>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8" class="mb0">
          <el-form-item label="签名名称" prop="contractSignName">
            <el-input v-model="form.contractSignName" placeholder="请输入短信签名名称"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :xs="24" :sm="5" :md="5" :lg="5" :xl="5" class="mb0">
          <el-checkbox v-model="form.personnelFile">个人档案短信</el-checkbox>
        </el-col>
        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8" class="mb0">
          <el-form-item label="模板代码" prop="personnelFileTemplateCode">
            <el-input v-model="form.personnelFileTemplateCode" placeholder="请输入短信模板代码"/>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8" class="mb0">
          <el-form-item label="签名名称" prop="personnelFileSignName">
            <el-input v-model="form.personnelFileSignName" placeholder="请输入短信签名名称"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8" class="mb0">
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer" style="text-align: center">
      <el-button type="primary" @click="submitSms">保 存</el-button>
<!--      <el-button @click="cancel">取 消</el-button>-->
    </div>

  </div>
</div>
</template>

<script>
import {getCompany,updateCompany} from "@/api/org/company";

export default {
  name: "smsSetting",
  data() {
    return {
      form:{msgNotify: false, msgNotifyTemplateCode:"", msgNotifySignName:"",
        msgRemid: false, msgRemidTemplateCode:"", msgRemidSignName:"",
        msgOa: false, msgOaTemplateCode:"", msgOaSignName:"",
        msgEntry: false, msgEntryTemplateCode:"", msgEntrySignName:"",
        training: false, trainingTemplateCode:"", trainingSignName:"",
        kpi: false, kpiTemplateCode:"", kpiSignName:"",
        contract: false, contractTemplateCode:"", contractSignName:"",
        personnelFile: false, personnelFileTemplateCode:"", personnelFileSignName:"",},
      company:{id:null,smsSetting:{}},
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load(){
      let companyId = this.$store.getters.userInfo.companyId;
      console.log(companyId)
      getCompany(companyId).then(response => {
        this.company = response.data;
        console.log(this.company)
        if(this.company.smsSetting){
          this.form = this.company.smsSetting;
        }
      });
    },
    submitSms(){
      let company = {id: this.company.id, num:this.company.num,smsSetting: this.form};
      updateCompany(company).then(response => {
        this.msgSuccess("保存成功");
        // this.$emit("close", {refresh:true,isSuccess:true});
      });
    },
    cancel(){

    },
  },
}
</script>

<style scoped>

</style>