import request from '@/utils/request'

// 查询打卡记录列表
export function listAttStatisticDay(query) {
    return request({
        url: '/attendance/attStatisticDay/findAll',
        method: 'post',
        data: query
    })
}
export function count(query) {
    return request({
        url: '/attendance/attStatisticDay/count',
        method: 'post',
        data: query
    })
}
// 查询打卡记录详细
export function getAttStatisticDay(id) {
    return request({
        url: '/attendance/attStatisticDay/findOne?id=' + id,
        method: 'post'
    })
}
//触发考勤统计，根据考勤打卡时间计算考勤统计
export function updateStatisticClockTime(start,end, empIds){
    return request({
        url: '/attendance/attStatisticDay/updateStatisticClockTime?start='+start+"&end="+end+"&empIds="+empIds,
        method: 'get'
    })
}

//触发考勤统计，根据考勤打卡时间计算考勤统计
export function reGenStatisticDay(groupId,start,end,reCaculate,empId){
    return request({
        url: '/attendance/attStatisticDay/reGenStatisticDay?groupId='+groupId+'&start='+start+"&end="+end+"&reCaculate="+reCaculate+"&empId="+empId,
        method: 'get'
    })
}

// // 新增打卡记录
// export function addAttStatisticDay(data) {
//     return request({
//         url: '/attendance/attStatisticDay/add',
//         method: 'post',
//         data: data
//     })
// }

export function updateAttStatisticDay(data) {
    return request({
        url: '/attendance/attStatisticDay/edit',
        method: 'post',
        data: data
    })
}

export function clockOnOff(data) {
    return request({
        url: '/attendance/attStatisticDay/clockOnOff',
        method: 'post',
        data: data
    })
}

// 导出打卡记录
export function exportAttStatisticDay(query) {
    return request({
        url: '/attendance/attStatisticDay/export',
        method: 'post',
        data: query
    })
}

export function statisticByEmp(data) {
    return request({
        url: '/attendance/attStatisticDay/statisticByEmp',
        method: 'post',
        data: data
    })
}

export function statisticByEmpMonth(data) {
    return request({
        url: '/attendance/attStatisticDay/statisticByEmpMonth',
        method: 'post',
        data: data
    })
}

export function statisticLeaveByEmp(data) {
    return request({
        url: '/attendance/attStatisticDay/statisticLeaveByEmp',
        method: 'post',
        data: data
    })
}

