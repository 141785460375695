import request from '@/utils/request'

// 查询排班列表
export function listAttMySetting(query) {
    return request({
        url: '/attendance/attMySetting/findAll',
        method: 'post',
        data: query
    })
}

// 查询排班详细
export function getAttMySetting(id) {
    return request({
        url: '/attendance/attMySetting/findOne?id=' + id,
        method: 'post'
    })
}

// 新增排班
export function addAttMySetting(data) {
    return request({
        url: '/attendance/attMySetting/add',
        method: 'post',
        data: data
    })
}

// 修改排班
export function updateAttMySetting(data) {
    return request({
        url: '/attendance/attMySetting/edit',
        method: 'post',
        data: data
    })
}

export function updateApproved(data) {
    return request({
        url: '/attendance/attMySetting/updateApproved',
        method: 'post',
        data: data
    })
}

export function approvedById(id) {
    return request({
        url: '/attendance/attMySetting/approvedById',
        method: 'post',
        data: id
    })
}

export function disapprovedById(id) {
    return request({
        url: '/attendance/attMySetting/disapprovedById',
        method: 'post',
        data: id
    })
}

// 删除排班
export function delAttMySetting(id) {
    return request({
        url: '/attendance/attMySetting/remove',
        method: 'post',
        data: id
    })
}

// 导出排班
export function exportAttMySetting(query) {
    return request({
        url: '/attendance/attMySetting/export',
        method: 'post',
        data: query
    })
}
