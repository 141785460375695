
export const FIELD_VALUE_TYPE={
    INPUT: "input",
    SELECT: "select",
    DATE: "date",
}

export const FUNC_SELECTS = {
    TYPE_SELECT:"TYPE_SELECT",
    TYPE_INPUT_STRING: "TYPE_INPUT_STRING",
    TYPE_INPUT_NUMBER: "TYPE_INPUT_NUMBER",
    TYPE_INPUT_DATE: "TYPE_INPUT_DATE",
    TYPE_INPUT_DATETIME: "TYPE_INPUT_DATETIME",
    TYPE_INPUT_JSON_STRING: "TYPE_INPUT_JSON_STRING",
}

let FUNC_SELECT_MAP = new Map();
const TYPE_INPUT_STRING =  [{label: "等于", value: "="},{label: "不等于", value: "!="},{label: "包含", value: "like"},{label: "不包含", value: "not like"}];
const TYPE_INPUT_NUMBER =  [{label: "等于", value: "="},{label: "不等于", value: "!="},{label: "大于", value: ">"},
                            {label: "大于等于", value: ">="}, {label: "小于", value: "<"}, {label: "小于等于", value: "<="},];
const TYPE_INPUT_JSON_STRING =  [{label: "包含", value: "like"}];

// FUNC_SELECT_MAP.set(FUNC_SELECTS.TYPE_SELECT, [{label: "在内", value: "in"},{label: "不在内", value: "not in"}]);
FUNC_SELECT_MAP.set(FUNC_SELECTS.TYPE_SELECT, [{label: "等于", value: "="},{label: "不等于", value: "!="}]);
FUNC_SELECT_MAP.set(FUNC_SELECTS.TYPE_INPUT_STRING, TYPE_INPUT_STRING);
FUNC_SELECT_MAP.set(FUNC_SELECTS.TYPE_INPUT_NUMBER, TYPE_INPUT_NUMBER);
FUNC_SELECT_MAP.set(FUNC_SELECTS.TYPE_INPUT_DATE, TYPE_INPUT_NUMBER);
FUNC_SELECT_MAP.set(FUNC_SELECTS.TYPE_INPUT_DATETIME, TYPE_INPUT_NUMBER);
FUNC_SELECT_MAP.set(FUNC_SELECTS.TYPE_INPUT_JSON_STRING, TYPE_INPUT_JSON_STRING);

export { FUNC_SELECT_MAP };
// export const FUNC_SELECTS = {
//     TYPE_SELECT: [{label: "在内", value: "in"},{label: "不在内", value: "not in"}],
//     TYPE_INPUT_STRING: [{label: "等于", value: "="},{label: "不等于", value: "!="},{label: "包含", value: "like"},{label: "不包含", value: "not like"}],
//     TYPE_INPUT_NUMBER: [{label: "等于", value: "="},{label: "不等于", value: "!="},{label: "大于", value: ">"},
//         {label: "大于等于", value: ">="}, {label: "小于", value: "<"}, {label: "小于等于", value: "<="},],
//     TYPE_INPUT_DATE: [{label: "等于", value: "="},{label: "不等于", value: "!="},{label: "大于", value: ">"},
//         {label: "大于等于", value: ">="}, {label: "小于", value: "<"}, {label: "小于等于", value: "<="},],
//
//
// }

export function getFuncSelect(key){
    return FUNC_SELECT_MAP.get(key);
}
//
// let fieldList = [];
//
// function addField(fields, field){
//
// }
//
//
// let fieldName = ""; // 查询字段名
// let fieldText = ""; // 查询字段显示中文名
// let fieldValue = "";// 查询字段值
// let funcName = "";
// let funcText = "";
