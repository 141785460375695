<template>
  <div class="app-container">
<!--    <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px">-->
<!--      <el-form-item label="流程类型" prop="status">-->
<!--        <el-select v-model="queryParams.status" clearable placeholder="流程类型"-->
<!--                   size="small">-->
<!--          <el-option label="转正申请" value="Official"/>-->
<!--          <el-option label="离职申请" value="Leave"/>-->
<!--        </el-select>-->
<!--      </el-form-item>-->
<!--      <el-form-item>-->
<!--        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>-->
<!--        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>-->
<!--      </el-form-item>-->
<!--    </el-form>-->

    <el-row :gutter="10" class="mb8">
<!--      <el-col :span="1.5">-->
<!--        <el-button-->
<!--            type="warning"-->
<!--            plain-->
<!--            icon="el-icon-download"-->
<!--            size="mini"-->
<!--            @click="handleExport"-->
<!--            v-permission="['/bpm/official/export']"-->
<!--        >导出-->
<!--        </el-button>-->
<!--      </el-col>-->
      <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>
    </el-row>

    <el-table v-loading="loading" :data="todoList" @selection-change="handleSelectionChange" stripe>
<!--      <el-table-column type="selection" width="55" align="center"/>-->
      <el-table-column label="待办事项" align="center" prop="task.processDefinitionId" :formatter="processDef" width="100" sortable/>
      <el-table-column label="申请人" align="center" prop="applyUser" width="100" sortable/>
      <el-table-column label="任务步骤" align="center" prop="task.name" width="180"/>
<!--      <el-table-column label="申请时间" align="center" prop="createdDate" width="180">-->
<!--        <template slot-scope="scope">-->
<!--          <span>{{ parseTime(scope.row.createdDate, '{y}-{m}-{d}') }}</span>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column label="待办人" align="center" prop="task.assignee" width="100"/>
      <el-table-column label="任务状态" align="center" prop="task.status" width="80">
        <template slot-scope="scope">
          <span v-if="scope.row.task.status == 'CREATED'">待签收</span>
          <span v-if="scope.row.task.status == 'ASSIGNED'">已签收</span>
        </template>
      </el-table-column>
      <el-table-column label="申请时间" align="center" prop="applyDate" width="200" sortable/>
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width" width="230">
        <template slot-scope="scope">
          <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="showFlowDetail(scope.row.task)"
          >审批进度
          </el-button>
          <el-button
                     size="mini"
                     type="text"
                     icon="el-icon-view"
                     @click="showTask(scope.row.task)"
          >查看
          </el-button>
          <el-button v-if="!scope.row.task.assignee"
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="handleClaim(scope.row.task)"
          >签收
          </el-button>
          <el-button v-if="scope.row.task.assignee && 'bpm_selfFlow_approve' != scope.row.task.formKey"
                     size="mini"
                     type="text"
                     icon="el-icon-edit"
                     @click="handleUnclaim(scope.row.task)"
          >归还
          </el-button>
          <el-button v-if="scope.row.task.assignee == username"
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="getTaskFormKey(scope.row.task)"
          >办理
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
        v-show="total>0"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
    />

    <!-- 添加或修改转正申请对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="80%" append-to-body>
      <FormDetail :dialogType="dialogType" :businessType="businessType" :processInstanceId="processInstanceId" @close="close"></FormDetail>
<!--      <template v-if="'Official' == dialogType">-->
<!--        <OfficialEdit :type="businessType" :id="businessKey" :isReadOnly="true" :isShowClose="true" @close="close"></OfficialEdit>-->
<!--      </template>-->
<!--      <template v-if="'Depart' == dialogType">-->
<!--        <DepartEdit :type="businessType" :id="businessKey" :isReadOnly="true" :isShowClose="true" @close="close"></DepartEdit>-->
<!--      </template>-->
<!--      <template v-if="'Transfer' == dialogType">-->
<!--        <TransferEdit :type="businessType" :id="businessKey" :isReadOnly="true" :isShowClose="true" @close="close"></TransferEdit>-->
<!--      </template>-->
    </el-dialog>
    <el-dialog :title="title" :visible.sync="openFlowDetail" width="80%" append-to-body>
      <FlowDetail :processInstanceIdIn="processInstanceId"></FlowDetail>
      <div style="text-align: center"><el-button @click="openFlowDetail=false">关 闭</el-button></div>
    </el-dialog>
  </div>

</template>

<script>
import {myTasks, claimTask, unclaimTask} from "@/api/bpm/todoList.js";
import RightToolbar from "@/components/RightToolbar"
import permission from '@/directive/permission/index.js' // 权限判断指令
import Pagination from "@/components/Pagination";
import FlowDetail from "./flowDetail"
import FormDetail from "../components/formDetail";
import { showLoading, hideLoading } from '@/utils/reqloading';
import {DICT_TYPE, getDictDataLabel, getDictDatas} from "@/utils/dict";

// 表查询配置
const dbOrderBy = ''

export default {
  name: "index",
  components: {
    RightToolbar,
    Pagination,
    FlowDetail,
    FormDetail,
  },
  directives: { permission },
  data() {
    return {
      // 遮罩层
      loading: true,
      // 查询参数
      where: {
        query: '',
        filters: []
      },
      username: null,

      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 待办事项列表
      todoList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      openFlowDetail: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        orderby: dbOrderBy,
        name: null,
        status: null,
        approved: null,
      },
      dialogType: 'Official',
      businessKey: null,
      businessType: null,
      processInstanceId: null,
    };
  },
  created() {
    // console.log("create...")
    // console.log(this.$store.getters.name)
    // console.log(this.$store.getters.userInfo)
    // console.log("create...")
    this.username = this.$store.getters.name;
    this._filter = {};
    this.getList();
  },
  methods: {
    /** 查询转正申请列表 */
    getQuery(){
      this._filter = {};
      if(this.queryParams.name) {
        this._filter['name'] = {
          type: 'search',
          value: this.queryParams.name,
        }
      }
      if(this.queryParams.status) {
        this._filter['status'] = {
          type: 'search',
          value: this.queryParams.status,
        }
      }
      if(this.queryParams.approved) {
        this._filter['approved'] = {
          type: 'search',
          value: this.queryParams.approved,
        }
      }
      const filters = this.parseFilters(this._filter)
      if (filters && filters.length > 0) {
        this.where.filters = filters
      } else {
        // 如果过滤条件为空，设置空数组
        this.where.filters = []
      }
      const params = {
        pageNum: this.queryParams.pageNum,
        pageSize: this.queryParams.pageSize,
        where: this.where,
        order: this.orderby
      }

      return params;
    },
    getList() {
      this.loading = true;
      const params = this.getQuery();
      myTasks(params).then(response => {
        console.log(response);
        this.todoList = response.data.content;
        this.total = response.data.totalItems;
        this.loading = false;
      });
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {};
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },
    processDef(row, column){
      console.log(row)
      let def = row.task.processDefinitionId.split(":");
      let str = getDictDataLabel(DICT_TYPE.BPM_FLOW_TYPE,def[0]);
      str = str? str: '其他申请';
      return str;
    },
    // 显示任务
    showTask(row){
      console.log(row);
      if("bpm_selfFlow_approve" == row.formKey){
        this.dialogType = "selfFlow";
      }else{
        let def = row.processDefinitionId.split(":");
        this.dialogType = def[0];
      }
      this.businessType = row.taskDefinitionKey;
      // this.businessKey = row.businessKey;
      this.processInstanceId = row.processInstanceId;
      this.open = true;
    },
    showFlowDetail(row){
      this.processInstanceId = row.processInstanceId;
      this.openFlowDetail = true;
    },
    close(){
      this.open = false;
    },
    /** 任务签收操作 */
    handleClaim(row) {
      const id = row.id;
      showLoading();
      claimTask(id).then(() => {
        this.getList();
        this.msgSuccess("签收成功");
        hideLoading();
      });
    },
    /** 任务归还操作 */
    handleUnclaim(row) {
      const id = row.id;
      showLoading();
      unclaimTask(id).then(() => {
        this.getList();
        this.msgSuccess("签收成功");
        hideLoading();
      });
    },
    /** 任务办理操作 */
    getTaskFormKey(row) {
      if(!row) {
        console.log("记录为空！！");
        return;
      }
      console.log(row);
      this.$router.push({
        name: row.formKey,
        query: {businessKey: row.businessKey, id: row.id,
          processInstanceId: row.processInstanceId, taskDefinitionKey: row.taskDefinitionKey},
      });
      // const taskId = row.id;
      // const data = {
      //   taskId : taskId
      // }
      //
      // getTaskFormKey(data).then(response => {
      //   const resp = response.data;
      //   const path = resp.formKey;
      //   const taskId = resp.id;
      //   const businessKey =  resp.businessKey;
      //   const route = {
      //     path: path,
      //     query: {
      //       businessKey: businessKey,
      //       taskId:taskId,
      //       processInstanceId : resp.processInstanceId
      //     }
      //   }
      //   this.$router.replace(route);
      // });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate(valid => {
            if(valid) {
              // if (this.form.id != null) {
              //   updateOfficial(this.form).then(response => {
              //     this.msgSuccess("修改成功");
              //     this.open = false;
              //     this.getList();
              //   });
              // } else {
              //   addOfficial(this.form).then(response => {
              //     this.msgSuccess("新增成功");
              //     this.open = false;
              //     this.getList();
              //   });
              // }
            }
          }
      );
    },

    /** 导出按钮操作 */
    handleExport() {
      const params = this.getQuery();
      this.$confirm('是否确认导出所有转正申请数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        // return exportOfficial(params);
      }).then(response => {
        this.download(response.msg);
      })
    }
  }
};
</script>

}
</script>

<style scoped>

</style>
