import request from '@/utils/request'

export function getProcDefListByKey(query) {
    return request({
        url: '/bpm/procDef/listByKey',
        method: 'get',
        params: query
    })
}

export function getProcDefBpmnXML(id) {
    return request({
        url: '/bpm/procDef/bpmnXml?id=' + id,
        method: 'get'
    })
}
