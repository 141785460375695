<template>
<div class="app-container">
  <el-tabs v-model="activeName">
    <el-tab-pane label="保险公积金" name="isuranceFund">
      <InsuranceFund :companyList="companyList" :company-options="companyOptions"></InsuranceFund>
    </el-tab-pane>
    <el-tab-pane label="工资账套" name="salary">
      <SalaryTemplete :companyList="companyList" :company-options="companyOptions"></SalaryTemplete>
    </el-tab-pane>
  </el-tabs>
</div>
</template>

<script>
import InsuranceFund from "./insuranceFund";
import SalaryTemplete from "./salaryTemplete";
import {listCompany} from "@/api/org/company";

export default {
  name: "index",
  components: {
    InsuranceFund,
    SalaryTemplete,
  },
  data() {
    return {
      activeName: "isuranceFund",
      // 公司树选项
      companyList: [],
      companyOptions: [],
    }
  },
  created() {
    this.getTreeselectCompany();
  },
  methods:{
    /** 查询公司下拉树结构 */
    getTreeselectCompany() {
      const params = {
        pageNum: 1,
        pageSize: 500,
        where: null,
        order: ''
      }
      listCompany(params).then(response => {
        this.companyList = response.data.records;
        this.companyOptions = this.handleTree(response.data.records, "id", "parentId");
      }) ;
    },

  }
}
</script>

<style scoped>

</style>
