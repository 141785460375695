<template>
	<div class="app-container">
		<div style="text-align: center; width: 100%;">
			<el-form ref="form" :model="form" :rules="rules" label-width="280px">
				<!--      <el-row :gutter="35">-->
				<el-row :gutter="35"
					><el-col :span="19" :offset="2" class="mb20 registerCol">
						<el-form-item label="用户名" prop="username">
							<el-input v-model="form.username" placeholder="请输入登录用户名" />
						</el-form-item> </el-col
				></el-row>
				<el-row :gutter="35"
					><el-col :span="19" :offset="2" class="mb20 registerCol">
						<el-form-item label="密码" prop="password">
							<el-input type="password" v-model="form.password" placeholder="请输入登录密码" />
						</el-form-item> </el-col
				></el-row>
				<el-row :gutter="35"
					><el-col :span="19" :offset="2" class="mb20 registerCol">
						<el-form-item label="确认密码" prop="passwordConfirm">
							<el-input type="password" v-model="form.passwordConfirm" placeholder="请输入确认密码" />
						</el-form-item> </el-col
				></el-row>
				<el-row :gutter="35"
					><el-col :span="19" :offset="2" class="mb20 registerCol">
						<el-form-item label="姓名" prop="name">
							<el-input v-model="form.name" placeholder="请输入用户姓名" />
						</el-form-item> </el-col
				></el-row>
				<el-row :gutter="35"
					><el-col :span="19" :offset="2" class="mb20 registerCol">
						<el-form-item label="身份证号" prop="idNum">
							<el-input v-model="form.idNum" placeholder="请输入身份证号" />
						</el-form-item> </el-col
				></el-row>
				<el-row :gutter="35"
					><el-col :span="19" :offset="2" class="mb20 registerCol">
						<el-form-item label="手机号" prop="mobile">
							<el-input v-model="form.mobile" placeholder="请输入手机号" />
						</el-form-item> </el-col
				></el-row>
				<el-row :gutter="35"
					><el-col :span="19" :offset="2" class="mb20 registerCol">
						<el-form-item label="公司编号" prop="companyCode">
							<el-input v-model="form.companyCode" placeholder="请输入公司编号" />
							<!--            <treeselect v-model="form.companyId" :options="companyOptions"-->
							<!--                        :normalizer="normalizerCompany" placeholder="请选择公司"/>-->
						</el-form-item>
					</el-col></el-row
				>

				<!--      </el-row>-->
			</el-form>
		</div>
		<div slot="footer" class="dialog-footer" style="text-align: center">
			<el-button type="primary" @click="submitForm">注 册</el-button>
			<el-button v-if="isWeb" @click="cancel">返 回</el-button>
		</div>
	</div>
</template>

<script>
import { registUser } from '@/api/sys/user';
import { listAllCompany } from '@/api/org/company';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { validatorCompanyId, verifyIdCard, isPassword, validatePhone, verifyAccount, verifyFullName } from '@/utils/validate.js';
// import {registUser} from "../../../../api/sys/user";

export default {
	name: 'register',
	components: {
		Treeselect,
	},
  props: {
    isWeb: {
      type: Boolean,
      default: false,
    }
  },
	data() {
		return {
			// 遮罩层
			loading: true,
			// 公司树选项
			companyOptions: [],
			// 表单参数
			form: {},
			// 表单校验
			rules: {
				companyId: [
					{
						required: true,
						message: '所属公司不能为空或顶级节点,必须选择公司',
						trigger: 'blur',
					},
					{ validator: validatorCompanyId, trigger: 'blur' },
				],
				username: [
					{
						required: true,
						message: '登录用户名不能为空',
						trigger: 'blur',
					},
					{ validator: verifyAccount, trigger: 'blur' },
				],
				password: [
					{
						required: true,
						message: '登录密码不能为空',
						trigger: 'blur',
					},
					{ validator: isPassword, trigger: 'blur' },
				],
				name: [
					{
						required: true,
						message: '用户姓名不能为空',
						trigger: 'blur',
					},
					// {validator: verifyFullName,trigger: "blur" }
				],
				idNum: [
					{
						required: true,
						message: '身份证号不能为空',
						trigger: 'blur',
					},
					{ validator: verifyIdCard, trigger: 'blur' },
				],
				mobile: [
					{
						required: true,
						message: '手机号不能为空',
						trigger: 'blur',
					},
					{ validator: validatePhone, trigger: 'blur' },
				],
				companyCode: [
					{
						required: true,
						message: '公司编码不能为空',
						trigger: 'blur',
					},
					// {validator: validatePhone,trigger: "blur" }
				],
			},
		};
	},
	created() {
		this._filter = {};
		this.getTreeselectCompany();
	},
	methods: {
		/** 转换公司数据结构 */
		normalizerCompany(node) {
			if (node.children && !node.children.length) {
				delete node.children;
			}
			return {
				id: node.id,
				label: node.companyName,
				children: node.children,
			};
		},
		/** 查询公司下拉树结构 */
		getTreeselectCompany() {
			const params = {
				pageNum: 1,
				pageSize: 1000,
				where: null,
				order: this.orderby,
			};
			listAllCompany(params).then((response) => {
				this.companyOptions = [];
				// const data = { id:0,
				//   companyName:'顶级节点',
				//   children: []
				// };
				// data.children = this.handleTree(response.data.records, "id", "parentId");
				// this.companyOptions.push(data);
				this.companyOptions = this.handleTree(response.data.records, 'id', 'parentId');
			});
		},

		// 取消按钮
		cancel() {
			this.reset();
			this.$emit('close', { refresh: false, isSuccess: true });
		},
		// 表单重置
		reset() {
			this.form = {};
		},
		/** 提交按钮 */
		submitForm() {
			this.$refs['form'].validate((valid) => {
				if (valid) {
					console.log(this.form);
					registUser(this.form).then((response) => {
						this.msgSuccess('注册成功');
						this.$emit('close', { refresh: true, isSuccess: true, data: response.data });
					});
				}
			});
		},
	},
};
</script>

<style scoped>
.registerCol {
	margin-top: auto;
	margin-bottom: 30px;
	margin-left: 30px;
	margin-right: 30px;
	height: 90%;
}
</style>
