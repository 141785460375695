<template>
  <div class="container">
    <div class="detail">
      <h3 class="title">{{ this.msg.title }}</h3>
      <div class="author">
        <div class="text">
          <p class="name">作者：{{ this.msg.senderName}}</p>
          <p class="time">{{ this.msg.startTime}}</p>
        </div>
      </div>
    </div>
    <el-button v-if="msg.msgType == 3 && msg.businessType"
        size="mini"
        type="text"
        icon="el-icon-edit"
        @click="aToRoute()"
    >{{ msg.businessType }}
    </el-button>

    <div class="content" ref="htmljs" v-html="msg.content">

    </div>
    <div style="margin-top: 20px; text-align: center">
      <el-button @click="close">关 闭</el-button>
    </div>
  </div>
</template>

<script>

import {getMsgreceiver} from "../../../../api/msg/msgreceiver";

export default {
  name: "msgPreview",
  props: {
    msg: Object,
  },
  watch:{
    msg(n,o){ //n为新值,o为旧值;
      console.log(n);
      // getMsgreceiver(n.id);
      this.load();
    },
  },
  created() {
    console.log("created");
    console.log(this.msg);
    this.load();
    // if(this.msg && this.msg.id){
    //   getMsgreceiver(this.msg.id);
    // }
  },
  methods:{
    load(){
      this.$nextTick(()=>{
        // if(this.msg.businessKey){
        //   let ele = document.createElement("script");
        //   ele.innerHTML = this.msg.businessKey;
        //   console.log("this.$refs.htmljs")
        //   console.log(this.$refs.htmljs)
        //   this.$refs.htmljs.append(ele);
        // }
      });
    },
    //
    aToRoute(){
      console.log(this.msg.businessKey);
      this.$router.push(JSON.parse(this.msg.businessKey));
      console.log("to");
      this.$emit("close");
    },
    close(){
      this.$emit("close");
    },
  },
}
</script>

<style scoped lang="scss">
.container {
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;
}

.detail {
  padding: 46px 10px 44px;

  .title {
    font-size: 18px;
    line-height: 2;
    text-align: center;
  }

  .zan {
    text-align: center;
    padding: 10px 0;

    .active {
      border-color: red;
      color: red;
    }

  }

  .author {
    padding: 10px 0;
    display: flex;

    .text {
      flex: 1;
      padding-left: 10px;
      line-height: 1.5;

      .name {
        font-size: 14px;
        margin: 0;
        text-align: center;

      }

      .time {
        margin: 0;
        font-size: 12px;
        color: #999;
        text-align: right;

      }

    }
  }

  .content {
    padding: 20px 0;
    overflow: hidden;
    white-space: pre-wrap;
    word-break: break-all;

    ///deep/ img {
    //  max-width: 100%;
    //  background: #f9f9f9;
    //}
    //
    //
    ///deep/ code {
    //  white-space: pre-wrap;
    //}

  }
}
</style>
