<template>
  <div class="app-container">
    <el-table v-loading="loading" :data="cbSalaryList" stripe border>
      <el-table-column type="selection" width="55" align="center"/>
      <el-table-column label="员工姓名" align="center" prop="empName" fixed/>
      <el-table-column label="月份" align="center" prop="salaryDate" width="98px">
        <template slot-scope="scope">
          <span>{{scope.row.salaryDate.substr(0,7)}}</span>
        </template>
      </el-table-column>
<!--      <el-table-column label="工资(合同)" align="center" prop="contractSalary" width="98px"/>-->
      <el-table-column label="应发工资" align="center" prop="totalSalary">
        <template slot-scope="scope">
          <el-tooltip placement="top">
            <div slot="content">
              <el-table :data="getSalaryItemInfo(scope.row.salaryItems)" show-summary :summary-method="getTotalSalarySummaries" stripe border>
                <el-table-column label="项目名" align="center" prop="itemName">
                  <template slot-scope="scopeItem">
                    {{scopeItem.row.itemName}}
                  </template>
                </el-table-column>
                <el-table-column label="项目类型" align="center" prop="itemType">
                  <template slot-scope="scopeItem">
                    {{scopeItem.row.itemType}}
                  </template>
                </el-table-column>
                <el-table-column label="增减项" align="center" prop="plusMinus">
                  <template slot-scope="scopeItem">
                    {{scopeItem.row.plusMinus}}
                  </template>
                </el-table-column>
                <el-table-column label="考勤比例发放" align="center" prop="isAttend">
                  <template slot-scope="scopeItem">
                    {{!scopeItem.row.showToEmp?"***":(scopeItem.row.isAttend?"是":"否")}}
                  </template>
                </el-table-column>
                <el-table-column label="金额(元)" align="center" prop="amount" width="88px">
                  <template slot-scope="scopeItem">
                    {{scopeItem.row.amount}}
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <span style="color: #4ba5f6">{{scope.row.totalSalary}}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="考勤扣除" align="center" prop="attDeduct"/>
      <el-table-column label="其他扣补" align="center" prop="otherDeduct" width="100px">
        <template slot-scope="scope" slot="header" sortable>
          <el-tooltip :aa="scope" class="item" effect="dark" content="其他扣除补发款项，请用“正数”表示扣除，请使用“负数”表示补发" placement="top" >
            <span>其他扣补 ？</span>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <el-tooltip placement="top">
            <div v-if="scope.row.otherMemo" slot="content">
              <span>{{scope.row.otherMemo}}</span>
            </div>
            <el-input v-if="scope.row.editable" v-model="scope.row.otherDeduct" placeholder="请输入其他扣补"></el-input>
            <span style="color: #4ba5f6" v-else>{{scope.row.otherDeduct}}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <!--      <el-table-column label="其他扣补说明" align="center" prop="otherMemo" width="168px">-->
      <!--        <template slot-scope="scope">-->
      <!--          <el-input v-if="scope.row.editable" v-model="scope.row.otherMemo" placeholder="请输入其他扣补说明"></el-input>-->
      <!--          <span v-else>{{scope.row.otherMemo}}</span>-->
      <!--        </template>-->
      <!--      </el-table-column>-->
      <el-table-column label="实发工资" align="center" prop="totalPay">
        <template slot-scope="scope">
          <el-button type="text" @click="showSalaryPayDetail(scope.row)">{{scope.row.totalPay}}</el-button>
        </template>
      </el-table-column>
      <el-table-column label="扣税" align="center" prop="tax">
        <template slot-scope="scope">
          <el-tooltip placement="top">
            <div v-if="scope.row.taxInfo" slot="content">
              <!--              <span v-if="scope.row.attendInfo && scope.row.attendInfo.days">-->
              <!--                总工资：{{scope.row.contractSalary}}，考勤天数：{{scope.row.attendInfo.days}}，天工资：{{ (scope.row.contractSalary/scope.row.attendInfo.days).toFixed(2)}}-->
              <!--              </span>-->
              <!--              <el-table :data="getAttDeductItems(scope.row)" show-summary :summary-method="getSummaries" stripe border>-->
              <!--                <el-table-column label="项目名" align="center" width="98px" prop="attName"/>-->
              <!--                <el-table-column label="扣除类型" align="center" prop="attType"/>-->
              <!--                <el-table-column label="扣除权重" align="center" prop="attValue"/>-->
              <!--                <el-table-column label="缺勤（次或天）" align="center" prop="attNum"/>-->
              <!--                <el-table-column label="扣款金额" align="center" prop="deduct"/>-->
              <!--              </el-table>-->
            </div>
            <el-input v-if="scope.row.editable" v-model="scope.row.tax" placeholder="请输入扣税"></el-input>
            <span style="color: #4ba5f6" v-else>{{scope.row.tax}}</span>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="税后工资" align="center" prop="salaryAfterTax" width="120px">
        <template slot-scope="scope" slot="header" sortable>
          <el-tooltip :aa="scope" class="item" effect="dark" content="税后工资 = 实发工资 - 扣税" placement="top" >
            <span>税后工资 ？</span>
          </el-tooltip>
        </template>
        <template slot-scope="scope">
          <span>{{scope.row.salaryAfterTax}}</span>
          <!--          <span v-else>{{scope.row.totalPay}}</span>-->
        </template>
      </el-table-column>
      <el-table-column label="开户银行" align="center" prop="bankName"/>
      <el-table-column label="银行账号" align="center" prop="bankAcct"/>
      <el-table-column label="社保卡号" align="center" prop="insuranceAcct"/>
      <el-table-column label="公积金号" align="center" prop="fundAcct"/>
    </el-table>

<!--    <pagination-->
<!--        v-show="total>0"-->
<!--        :total="total"-->
<!--        :page.sync="pageNum"-->
<!--        :limit.sync="pageSize"-->
<!--        @pagination="getList"-->
<!--    />-->
    <el-dialog title="工资明细" :visible.sync="openPaydetail" width="80%" append-to-body>
      <SalaryPayDetail :salaryIn="salaryPayDetailIn" :editable="salaryPayDetailEdit" :mySalary="true" @close="closePayDetail"></SalaryPayDetail>
    </el-dialog>
  </div>
</template>

<script>
import {mySalary } from "@/api/cb/cbSalary";
import Pagination from "@/components/Pagination";
import {DICT_TYPE} from "@/utils/dict";
import SalaryPayDetail from "@/views/pages/cb/components/salaryPayDetail";

export default {
  name: "CbSalaryMy",
  components: {
    Pagination,SalaryPayDetail,
  },
  data() {
    return {
      openPaydetail: false,
      salaryPayDetailEdit: false,
      salaryPayDetailIn: {},
      // 遮罩层
      loading: true,
      total: 0,
      pageNum:1,
      pageSize:10,
      // 查询参数
      where: {
        query: '',
        filters: []
      },
      // 薪酬设置表格数据
      cbSalaryList: [],
    };
  },
  created() {
    this.getList();
  },
  methods:{
    getSalaryItemInfo(str){
      let data = null;
      if(typeof str == "object") data = str;
      if(typeof str == "string"){
        console.log("getSalaryItemInfo="+str);
        let json = JSON.parse(str);
        console.log(json);
        data = json;
      }else{
        data = str;
      }
      let rtData = [];
      let delItem = [];
      let extVal = 0;
      let val = 0;
      let deduct = 0;
      let pay = 0;
      for(let i = 0; i < data.length; i++){
        let item = data[i];
        // console.log(item)
        if(!item.showToEmp){
          delItem.push(item);
          const value = Number(item.amount);
          if (!isNaN(value)) {
            if (item.plusMinus == '增项') {
              val += value;
            } else {
              val -= value;
            }
            if(DICT_TYPE.CB_TEMPLATE_SALARY_TYPE_EXTRA == item.itemType){
              if (item.plusMinus == '增项') {
                extVal += value;
              } else {
                extVal -= value;
              }
            }
          }
          const deductn = Number(item.deduct);
          const payn = Number(item.pay);
          if (item.plusMinus == '增项') {
            if(!isNaN(deductn)){
              deduct += deductn;
            }
            if(!isNaN(payn)){
              pay += payn;
            }
          } else {
            if(!isNaN(deductn)){
              deduct -= deductn;
            }
            if(!isNaN(payn)){
              pay -= payn;
            }
          }
        }else{
          rtData.push(item);
        }
      }
      rtData.push({itemName:'其他合并项',plusMinus:'增项',amount:val,deduct:deduct,pay:pay,showToEmp:false})
      return rtData;
    },
    showSalaryPayDetail(row){
      this.salaryPayDetailIn = row;
      this.salaryPayDetailEdit = false;
      this.openPaydetail = true;
    },
    closePayDetail(arg){
      this.openPaydetail = false;
      if(arg.refresh){
        this.getList();
      }
    },
    getTotalSalarySummaries(param) {
      const {columns, data} = param;
      const sums = [];
      sums[0] = '合计';
      let extVal = 0;
      let val = 0;
      let deduct = 0;
      let pay = 0;
      data.map(item => {
        const value = Number(item.amount);
        if (!isNaN(value)) {
          if (item.plusMinus == '增项') {
            val += value;
          } else {
            val -= value;
          }
          if(DICT_TYPE.CB_TEMPLATE_SALARY_TYPE_EXTRA == item.itemType){
            if (item.plusMinus == '增项') {
              extVal += value;
            } else {
              extVal -= value;
            }
          }
        }
        const deductn = Number(item.deduct);
        const payn = Number(item.pay);
        if (item.plusMinus == '增项') {
          if(!isNaN(deductn)){
            deduct += deductn;
          }
          if(!isNaN(payn)){
            pay += payn;
          }
        } else {
          if(!isNaN(deductn)){
            deduct -= deductn;
          }
          if(!isNaN(payn)){
            pay -= payn;
          }
        }

      });
      sums[4] = ""+val.toFixed(2);//+"(其中包含"+extVal+DICT_TYPE.CB_TEMPLATE_SALARY_TYPE_EXTRA+")";
      sums[5] = deduct.toFixed(2);
      sums[6] = pay.toFixed(2);
      // sums[3] = "包含"+extVal+DICT_TYPE.CB_TEMPLATE_SALARY_TYPE_EXTRA+"";
      // if(this.contractSalary){
      //   sums[2] = this.contractSalary+"(合同总工资)"
      // }
      return sums;
    },
    getAttDeductItems(row){
      // console.log(row);
      if(!row.attendInfo || !row.attendInfo.deductType || row.attendInfo.deductType.length<=0) return [];
      let attDeductItems = [];
      row.attendInfo.deductType.forEach(item=>{
        attDeductItems.push(row.attendInfo[item]);
      });
      return attDeductItems;
    },
    getSummaries(param) {
      const {columns, data} = param;
      const sums = [];
      sums[0] = '考勤总扣除';
      let val = 0;
      data.map(item => {
        const value = Number(item.deduct);
        if (!isNaN(value)) {
          val += value;
        }
      });
      sums[4] = val.toFixed(2);
      return sums;
    },
    /** 查询薪酬设置列表 */
    getQuery(){
      const params = {
        // pageNum: this.pageNum,
        // pageSize: this.pageSize,
        pageNum: 1,
        pageSize: 3,
        where: this.where,
        order: ' salaryDate desc '
      }
      return params;
    },
    getList() {
      this.loading = true;
      const params = this.getQuery();
      mySalary(params).then(response => {
        this.cbSalaryList = response.data.records;
        this.total = response.data.total;
        this.loading = false;
      })
      ;
    },

  },
};
</script>

<style scoped>

</style>
