<template>
	<div>
		<div v-if="this.$store.getters.userInfo.username == null" style="text-align: right">
			<div style="margin-right: 25px; color: red; display: contents">
				<i class="el-icon-user-solid"></i>
				<span style="margin-right: 15px;vertical-align:middle;">
					{{ loginUserName(this.resume) }}
				</span>
			</div>
			<el-link style="margin-right:10px;" v-if="hasLogin" @click="showRegisterDialog" >修改</el-link>
			<el-button v-if="!hasLogin" @click="showLoginDialog">登录</el-button>
			<el-button v-if="!hasLogin" @click="showRegisterDialog" >注册</el-button>
			<el-button v-if="isWeb" @click="exitRecruit">退出</el-button>
		</div>
		<div style="text-align: left; color: red; font-size: 25px; margin-bottom: 10px">集团正在招聘职位</div>

		<el-row v-if="positions.length>0" :style="{height: windowHeight+'px'}" class="container-scroll" v-loading="queryLoading" >
				<el-form  >
					<el-row :gutter="24" v-for="position in positions" :key="position.id">
						<onePosition :currentPosition="position" :currentApplicant="resume"></onePosition>
					</el-row>
				</el-form>
				<pagination
					style="margin-top: 0px !important"
					v-show="total > 0"
					:total="total"
					:page.sync="queryParameters.pageNum"
					:limit.sync="queryParameters.pageSize"
					@pagination="query"
				/>
		</el-row>

		<div>
			{{ this.resultMsg }}
		</div>

		<el-dialog title="应聘登录" :visible.sync="openLoginDialog" :width="isWeb ? '25%' : '90%'"  :append-to-body="true">
			<div>
				<el-form :model="loginForm" ref="form" label-width="110px">
					<el-row>
						<el-col>
							<el-form-item label="用户名" prop="userName" :rules="[{ required: true, message: '用户名或手机号码称必填。', trigger: 'blur' }]">
								<el-input v-model="loginForm.userName" placeholder="请输入用户名或手机号码" />
							</el-form-item>
							<el-form-item label="密码" prop="password" :rules="[{ required: true, message: '用户密码必填。', trigger: 'blur' }]">
								<el-input v-model="loginForm.password" :show-password="true" type="password" placeholder="请输入用户密码" />
							</el-form-item>
							<el-form-item label="">
								<el-button :loading="loginLoading" plain type="primary" @click="login">提交</el-button>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
			</div>
		</el-dialog>
		<el-dialog title="应聘注册" :visible.sync="openRegisterDialog" :width="isWeb ? '80%' : '90%'" :append-to-body="true">
			<resume-register v-if="openRegisterDialog" @close="closeResumeRegister" :ReadOnly="false"  :RowData = 'resume' :Register="hasLogin" ></resume-register>
		</el-dialog>
	</div>
</template>

<script>
import { findAllPositons } from '@/api/recruit/positionManagement';
import onePosition from './onePosition.vue';
import resumeRegister from './resumeRegister.vue';
import Pagination from '@/components/Pagination';
import { login } from '@/api/recruit/register';

export default {
	name: 'recruitList',
	components: {
		onePosition,
		Pagination,
		resumeRegister,
	},
  props: {
    isWeb: {
      type: Boolean,
      default: false,
    }
  },
	data() {
		return {
			positions: [],
			where: {
				query: '',
				filters: [],
			},
			resultMsg: '',
			total: 0,
			queryParameters: {
				startDate: '',
				endDate: '',
				name: '',
				companyId: '',
				pageSize: 10,
				pageNum: 1,
			},

			//应聘者的用户名和密码
			loginForm: {
				userName: '',
				password: '',
			},
			loginLoading: false,
			openRegisterDialog: false,
			openLoginDialog: false,
			resume: null,
			windowHeight:600,
			queryLoading:false,
			hasLogin:false,
		};
	},

	mounted() {
		this.windowHeight=document.body.clientHeight-100;
	},
	created() {
		this.query();
	},
	computed: {
		loginUserName(currentItem) {
			return function () {
				return currentItem.resume == null ? '未登录' : currentItem.resume.userName + '  ' + currentItem.resume.name;
			};
		},
	},
	methods: {
		query() {
			let filter = {};
			filter['status'] = {
				type: 'search',
				value: '已发布',
			};

			const filters = this.parseFilters(filter);
			if (filters && filters.length > 0) {
				this.where.filters = filters;
			} else {
				// 如果过滤条件为空，设置空数组
				this.where.filters = [];
			}
			const params = {
				pageNum: this.queryParameters.pageNum,
				pageSize: this.queryParameters.pageSize,
				where: this.where,
				order: '',
			};
			this.queryLoading=true;
			findAllPositons(params).then((response) => {
				this.queryLoading=false;
				this.positions = response.data.records;
				this.total = response.data.total;
				if (this.positions.length == 0) {
					this.resultMsg = '非常抱歉，目前没有正在招聘的职位。';
				}
			});
		},
		login() {
			let that = this;

			this.$refs['form'].validate((valid) => {
				if (valid) {
					that.loginLoading = true;
					login(that.loginForm)
						.then((response) => {
							that.loginLoading = false;
							that.resume = response.data;

							//that.loginUserName =this.resume.userName +"  "+ this.resume.name;
							that.hasLogin = true;
							this.openLoginDialog = false;
							that.msgSuccess('登录成功。');
						})
						.catch((err) => {
							that.loginLoading = false;
							that.msgError('登录失败，' + err);
						});
				} else {
					that.msgError('有非法输入或必填项未填写，请检查!');
				}
			});
		},
		showRegisterDialog() {
			this.openRegisterDialog = true;
		},
		showLoginDialog() {
			this.openLoginDialog = true;
		},
		closeResumeRegister(params) {
			this.openRegisterDialog = false;
		},
		exitRecruit(){
			this.$emit("close");
		},
	},
};
</script>

<style lang="scss" scoped>
	.container-scroll{
		overflow-y: scroll;
		overflow-x: hidden;
	}
</style>
