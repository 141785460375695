<template>
    <div>
        <div>
            <el-button @click="addNewAgency">新增</el-button>
        </div>
        <div v-loading="loading" style="min-height:100px;">
             <el-row :gutter="24">
                	<el-col v-for="agency in agencyList" :key="agency.id" :xs="24" :sm="8" :md="6" :lg="6" :xl="6" class="mb0">
							<agency-view :agencyProp="agency" @viewSelectAgency="viewSelectAgency" @updateSelectAgency="updateSelectAgency" @deleteSelectAgency="deleteSelectAgency"></agency-view>
					</el-col>
                    <div v-if="total == 0" style="color:red;font-size: large;  text-align: center;">
                        本公司及子公司无培训机构。
                    </div>
             </el-row>
            <pagination v-show="total > 0" :total="total" :page.sync="queryParameters.pageNum" :limit.sync="queryParameters.pageSize" @pagination="GetAllAgencies" />
        </div>
        <el-dialog :title="dialogTitle" :visible.sync='showDialog' :width="editDialogWidth"  appendhandleUpdate-to-body >
        	<agency v-if="showDialog" :initReadonly="viewAgency" :initAgency="selectedAgency" :newAgency="addAgency" :updateAgency="updateAgency"  @close="closeAgencyDialog" ></agency>
    	</el-dialog>
    </div>
</template>

<script>
import { findAgencies,deleteAgencies } from '@/api/training/trainingAgency';
import Pagination from "@/components/Pagination";
import agencyView from './agencyView.vue';
import agency from './agency.vue';
import {Local} from '@/utils/storage.js'

export default {
    name: 'agencyList',
    components: {
		Pagination,
        agencyView,
        agency
	},
    data() {
        let abc ="abc";
        return {
            agencyList:[],
            queryParameters: {
				pageSize: 10,
				pageNum: 1,
			},
            loading:false,
            total:-1,
            where: {
				query: '',
				filters: [],
			},
            dialogTitle:"",
            showDialog:false,
            selectedAgency:null,
            viewAgency:false,
            addAgency:false,
            updateAgency:false,
            uniApp:false,
            editDialogWidth:"60%",
        };
    },
    created(){
        this.GetAllAgencies();
        this.uniApp=Local.get("isUniapp")?true:false;
         if(this.uniApp)
        {
            this.editDialogWidth = "95%";
        }
    },
   
    mounted() {
        
    },

    methods: {
        addNewAgency(){
            this.dialogTitle="新增机构";
            this.showDialog = true;
            this.addAgency=true;
            this.viewAgency = false;
            this.updateAgency = false;
             this.selectedAgency = null;
        },
        updateSelectAgency(arg){
			 this.dialogTitle="编辑机构";
            this.showDialog = true;
            this.addAgency=false;
            this.viewAgency = false;
            this.updateAgency = true;
            this.selectedAgency = arg.data;
		},
		viewSelectAgency(arg){
			 this.dialogTitle="查阅机构";
            this.showDialog = true;
            this.addAgency=false;
            this.viewAgency = true;
            this.updateAgency = false;
             this.selectedAgency = arg.data;
		},
		deleteSelectAgency(arg){
			
            let that = this;
			this.$confirm('确定删除选中的机构?', "警告", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			}).then(function () {
                let selectIds = [];
                selectIds.push(arg.data.id);
				 deleteAgencies(selectIds).then((response)=>{
                     if (response.success) {
                        that.GetAllAgencies();
						that.$message.success('删除成功!');
					} else {
						that.$message.error('删除失败：');      
					}
                 });
			});
		},
        closeAgencyDialog(arg){
            this.showDialog=false;
            if(arg.refresh){
				this.GetAllAgencies();
			}
        },
        GetAllAgencies()
        {
            let filter = {};
            const filters = this.parseFilters(filter);
            if (filters && filters.length > 0) {
                this.where.filters = filters;
            } else {
                // 如果过滤条件为空，设置空数组
                this.where.filters = [];
            }
            const params = {
                pageNum: this.queryParameters.pageNum,
                pageSize: this.queryParameters.pageSize,
                where: this.where,
                order: '',
            };
            this.loading = true;
            findAgencies(params).then((response) => {
                this.agencyList = response.data.records;
                this.total = response.data.total;
                this.loading = false;
               
            }).catch((err) => {
                that.loading = false;
                that.msgError('查询机构失败，' + err);
			});
        },
    },
};
</script>

<style lang="scss" scoped>

</style>