<template>
	<el-collapse-item title="离职资料" :name="this.collapseStatus" :class="collapseSetting()">
		<el-form class="employ" label-width="150px" :model="leavingDocuments">
			<el-row>
				<el-row>
					<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
						<el-form-item label="上一家公司离职备注">
							<el-input v-model="leavingDocuments.previousLeavingRemarks" :readonly="readonly"></el-input>
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">
						<div style="margin-left: 150px; margin-bottom: 10px">
							<el-upload
								:disabled="readonly"
								ref="elUploadPreviousLeaving"
								multiple=""
								class="avatar-uploader"
								v-loading="previousPictureLoading"
								action="onUploadPreviousLeavingDoc()"
								:limit="pictureLimit"
								:show-file-list="true"
								:file-list="previousLeavingDocList"
								list-type="picture-card"
								:on-success="handleAvatarSuccess"
								:http-request="onUploadPreviousLeavingDoc"
								:on-preview="handlePictureCardPreview"
								:auto-upload="true"
								accept=".jpg,.jpeg,.bmp,.png,application/pdf"
								:before-upload="beforeAvatarUpload"
								:on-exceed="handleExceedPreviousLeavingDoc"
								:on-remove="handleRemovePreviousLeavingDoc"
							>
								<i class="el-icon-plus avatar-uploader-icon"></i>
								<div slot="file" slot-scope="{ file }" v-loading="file.status == 'uploading'" style="height: 100%">
									<pdf v-if="file.name.endsWith('.pdf')" :src="file.url" :page="1"></pdf>
									<img v-else class="el-upload-list__item-thumbnail" style="object-fit: cover" :src="file.url" alt="" />
									<span class="el-upload-list__item-actions">
										<span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
											<i class="el-icon-zoom-in"></i>
										</span>

										<span class="el-upload-list__item-delete" @click="handleRemovePreviousLeavingDoc(file)">
											<i class="el-icon-delete"></i>
										</span>
									</span>
								</div>
							</el-upload>
							<el-dialog :visible.sync="dialogVisible" @close="closeDialog">
								<div v-if="dialogPdfName.endsWith('.pdf')">
									<pdf :src="pdfUrl" :page="1"></pdf>
								</div>
								<div v-else>
									<img width="100%" :src="dialogImageUrl" alt="" />
								</div>
							</el-dialog>
							<p style="transform: scale(0.6); margin-left: -230px">上传上一家公司离职资料, 支持jpg、png、bpm、pdf格式，单个文件不超过50M。</p>
						</div>
					</el-col>
				</el-row>
				<el-row>
					<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
						<el-form-item label="当前公司离职备注">
							<el-input v-model="leavingDocuments.currentLeavingRemarks" :readonly="readonly"></el-input>
						</el-form-item>
					</el-col>
					<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0">
						<div style="margin-left: 150px; margin-bottom: 10px">
							<el-upload
								:disabled="readonly"
								ref="elUploadCurrentLeaving"
								multiple=""
								class="avatar-uploader"
								v-loading="currentPictureLoading"
								action="onUploadCurrentLeavingDoc()"
								:limit="pictureLimit"
								:show-file-list="true"
								:file-list="currentLeavingDocList"
								list-type="picture-card"
								:on-success="handleAvatarSuccess"
								:http-request="onUploadCurrentLeavingDoc"
								:on-preview="handlePictureCardPreview"
								:auto-upload="true"
								accept=".jpg,.jpeg,.bmp,.png,application/pdf"
								:before-upload="beforeAvatarUpload"
								:on-exceed="handleExceedCurrentLeavingDoc"
								:on-remove="handleRemoveCurrentLeavingDoc"
							>
								<i class="el-icon-plus avatar-uploader-icon"></i>
								<div slot="file" slot-scope="{ file }" v-loading="file.status == 'uploading'" style="height: 100%">
									<pdf v-if="file.name.endsWith('.pdf')" :src="file.url" :page="1"></pdf>
									<img v-else class="el-upload-list__item-thumbnail" style="object-fit: cover" :src="file.url" alt="" />
									<span class="el-upload-list__item-actions">
										<span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
											<i class="el-icon-zoom-in"></i>
										</span>

										<span class="el-upload-list__item-delete" @click="handleRemoveCurrentLeavingDoc(file)">
											<i class="el-icon-delete"></i>
										</span>
									</span>
								</div>
							</el-upload>
							<el-dialog :visible.sync="dialogVisible" @close="closeDialog">
								<div v-if="dialogPdfName.endsWith('.pdf')">
									<pdf :src="pdfUrl" :page="1"></pdf>
								</div>
								<div v-else>
									<img width="100%" :src="dialogImageUrl" alt="" />
								</div>
							</el-dialog>
							<p style="transform: scale(0.6); margin-left: -230px">上传当前公司离职资料, 支持jpg、png、bpm、pdf格式，单个文件不超过50M。</p>
						</div>
					</el-col>
				</el-row>
			</el-row>
			<el-row>
				<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
					<el-form-item label=" ">
						<!-- <el-button v-on:click="edit" type="primary" plain v-bind:disabled="hasLocked ? true : this.isReadOnly" icon="el-icon-edit"
							>编辑</el-button
						> -->
						<el-button v-on:click="submitUpload" plain v-bind:disabled="readonly" type="primary" :loading="saving" icon="el-icon">保存</el-button>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
	</el-collapse-item>
</template>


<script>
import { updateLeavingDocuments, uploadleavingCertificatePicture,deleteLeavingCertificatePicture, downloadCertificatePictureApi } from '@/api/employ/employ';
import Pdf from 'vue-pdf';
export default {
	components: {
		Pdf,
	},
	data() {
		let readonlyInit = true;
		let leavingDocumentInit = {};
		if (!this.LeavingDocuments) {
			leavingDocumentInit = {
				previousLeavingRemarks: '',
				previousLeavingDocuments: [],
				currentLeavingRemarks: '',
				currentLeavingDocuments: [],
			};
			readonlyInit = false;
		} else {
			leavingDocumentInit = this.LeavingDocuments;
		}

		if (this.locked) {
			readonlyInit = true;
		} else {
			readonlyInit = this.isReadOnly;
		}

		return {
			leavingDocuments: leavingDocumentInit,
			readonly: readonlyInit,
			hasLocked: this.locked,
			saving: false,
			dialogImageUrl: '',
			dialogVisible: false,
			pictureLimit: 10,
			previousLeavingDocList: [],
			tempPreviousLeavingDocList: new Map(),
			currentLeavingDocList: [],
			tempCurrentLeavingDocList: new Map(),
			formData: new FormData(),
			pdfUrl: '',
			dialogPdfName: '',
			previousPictureLoading:false,
			currentPictureLoading:false,
		};
	},
	props: { LeavingDocuments: Object, id: Number, isReadOnly: Boolean, collapseStatus: String, locked: Boolean },
	watch: {
		isReadOnly(newValue, oldV) {
			this.readonly = newValue;
		},
		locked(newValue, oldV) {
			this.hasLocked = newValue;
			if (this.hasLocked) {
				this.readonly = true;
			} else {
				this.readonly = false;
			}
		},
	},
	created() {
		if (this.leavingDocuments&&(this.leavingDocuments.previousLeavingDocuments || this.leavingDocuments.currentLeavingDocuments)) {
			this.downloadLeavingCertificatePictures();
			this.$forceUpdate();
		}
	},
	computed: {
		collapseSetting() {
			return function () {
				if (this.leavingDocuments && ((this.leavingDocuments.previousLeavingDocuments  && this.leavingDocuments.previousLeavingDocuments.length>0) ||
												 (this.leavingDocuments.currentLeavingDocuments && this.leavingDocuments.currentLeavingDocuments.length>0) )) {
					return { callpse_header_fill: true };
				} else {
					return { callpse_header_unfill: true };
				}
			};
		},
	},
	methods: {
		submitUpload() {
			let self = this;
			let formData = new FormData();
			if(this.tempPreviousLeavingDocList && this.tempPreviousLeavingDocList.size>0){
				this.tempPreviousLeavingDocList.forEach((value,key) => {
                if(!this.leavingDocuments.previousLeavingDocuments){
					this.leavingDocuments.previousLeavingDocuments = [];
				}
				let foundIndex = this.leavingDocuments.previousLeavingDocuments.findIndex(item=>item.fileName==key);
				if(foundIndex < 0){
					this.leavingDocuments.previousLeavingDocuments.push({fileName:key,url:value});
				}
			});
			}
			
			if(this.tempCurrentLeavingDocList && this.tempCurrentLeavingDocList.size>0){
				this.tempCurrentLeavingDocList.forEach((value,key) => {
				if(!this.leavingDocuments.currentLeavingDocuments){
					this.leavingDocuments.currentLeavingDocuments = [];
				}

				let foundIndex = this.leavingDocuments.currentLeavingDocuments.findIndex(item=>item.fileName==key);
				if(foundIndex < 0){
					this.leavingDocuments.currentLeavingDocuments.push({fileName:key,url:value});
				}
			});
			}
			
			formData.append('leavingDocuments', JSON.stringify(self.leavingDocuments));
			formData.append('employId', self.id);
			this.saving = true;
			updateLeavingDocuments(formData)
				.then((response) => {
					self.saving = false;
					if (response.code == 200) {
						self.msgSuccess('保存成功');
					} else {
						self.msgError(response.message);
					}
				})
				.catch((err) => {
					self.msgError(err);
					self.saving=false;
				});
		},

		onUploadPreviousLeavingDoc(params) {
			//this.dataFrom.pictures.push(file);
			let formData = new FormData();
			formData.append('file', params.file);
			let self = this;
			this.previousPictureLoading=true,
			uploadleavingCertificatePicture(formData)
				.then((response) => {
					self.previousPictureLoading = false;
					if (response.success) {
						let filePath = response.data;
						self.tempPreviousLeavingDocList.set(params.file.name, filePath);
						self.$message.success('上传成功!');
					} else {
						self.$message.error('上传失败：');
					}
				})
				.catch((err) => {
					self.previousPictureLoading = false;
					self.$message.error('上传失败：' + err);
				});
		},

		onUploadCurrentLeavingDoc(params) {
			//this.dataFrom.pictures.push(file);
			let formData = new FormData();
			formData.append('file', params.file);
			let self = this;
			this.currentPictureLoading = true,
			uploadleavingCertificatePicture(formData)
				.then((response) => {
					self.currentPictureLoading=false;
					if (response.success) {
						let filePath = response.data;
						self.tempCurrentLeavingDocList.set(params.file.name, filePath);
						self.$message.success('上传成功!');
					} else {
						self.$message.error('上传失败：');
					}
				})
				.catch((err) => {
					self.currentPictureLoading=false;
					self.$message.error('上传失败：' + err);
				});
		},

		handleAvatarSuccess(res, file) {
			//this.form.photo = URL.createObjectURL(file.name);
		},
		beforeAvatarUpload(file) {
			const isJPG = file.type === 'image/jpeg' || file.type === 'image/jpg';
			const isPng = file.type === 'image/png';
			const isBmp = file.type === 'image/bmp';
			const ispdf = file.type === 'application/pdf';
			const isLt50M = file.size / 1024 / 1024 < 50;
			if (!isJPG && !isPng && !isBmp && !ispdf) {
				this.$message.error('上传图片只能是 JPG,jpeg,png,bmp,pdf 格式!');
				file.status = 'fail';
			}
			if (!isLt50M) {
				this.$message.error('上传图片大小不能超过 50MB!');
				file.status = 'fail';
			}
			return (isJPG || isPng || isBmp || ispdf) && isLt50M;
		},
		handleExceedPreviousLeavingDoc(files, fileList) {
			this.msgError(
				`当前限制选择 ${this.pictureLimit} 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件.`
			);
		},
		handleExceedCurrentLeavingDoc(files, fileList) {
			this.msgError(
				`当前限制选择 ${this.pictureLimit} 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件.`
			);
		},
		handleRemovePreviousLeavingDoc(file) {
			if (file && file.raw && file.raw.status == 'fail') {
				return;
			}
			let that = this;
			this.$confirm('确定移除吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					let filePath = '';
					if (file.name.indexOf('/') > 0) {
						filePath = file.name;
					} else {
						let fileName = file.name;
						if (that.tempPreviousLeavingDocList.has(fileName)) {
							filePath = that.tempPreviousLeavingDocList.get(fileName);
							that.tempPreviousLeavingDocList.delete(fileName);
						} else {
							that.$message.error('前端没有找到要删除的体检报告。');
							return;
						}
					}

					let foundIndex = this.leavingDocuments.previousLeavingDocuments.findIndex(item=>item.fileName==file.name);
					if(foundIndex >= 0){
						this.leavingDocuments.previousLeavingDocuments.splice(foundIndex,1);
					}

					let uploadFiles = this.$refs.elUploadPreviousLeaving.uploadFiles;
					let index = uploadFiles.findIndex((item) => {
						return item == file;
					});
					if (index > -1) {
						uploadFiles.splice(index, 1);
					}

					let formData = new FormData();
					formData.append('filePath', filePath);
					deleteLeavingCertificatePicture(formData)
						.then((response) => {
							if (response.success) {
								that.$message.success('删除成功!');
							} else {
								that.$message.error('删除失败：' + response.message);
							}
						})
						.catch((err) => {
							that.$message.error('删除失败：' + err);
						});
				})
				.catch(() => {
					that.msgError('删除失败!');
				});
		},

		handleRemoveCurrentLeavingDoc(file) {
			if (file && file.raw && file.raw.status == 'fail') {
				return;
			}
			let that = this;
			this.$confirm('确定移除吗？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					let filePath = '';
					if (file.name.indexOf('/') > 0) {
						filePath = file.name;
					} else {
						let fileName = file.name;
						if (that.tempCurrentLeavingDocList.has(fileName)) {
							filePath = that.tempCurrentLeavingDocList.get(fileName);
							that.tempCurrentLeavingDocList.delete(fileName);
						} else {
							that.$message.error('前端没有找到要删除的体检报告。');
							return;
						}
					}

					let foundIndex = this.leavingDocuments.currentLeavingDocuments.findIndex(item=>item.fileName==file.name);
					if(foundIndex >= 0){
						this.leavingDocuments.currentLeavingDocuments.splice(foundIndex,1);
					}

					let uploadFiles = this.$refs.elUploadCurrentLeaving.uploadFiles;
					let index = uploadFiles.findIndex((item) => {
						return item == file;
					});
					if (index > -1) {
						uploadFiles.splice(index, 1);
					}

					let formData = new FormData();
					formData.append('filePath', filePath);
					deleteLeavingCertificatePicture(formData)
						.then((response) => {
							if (response.success) {
								that.$message.success('删除成功!');
							} else {
								that.$message.error('删除失败：' + response.message);
							}
						})
						.catch((err) => {
							that.$message.error('删除失败：' + err);
						});
				})
				.catch(() => {
					that.msgError('删除失败!');
				});
		},

		downloadLeavingCertificatePictures() {
			let that = this;
			let previousLeavingDocuments = this.leavingDocuments.previousLeavingDocuments;
			let currentLeavingDocuments = this.leavingDocuments.currentLeavingDocuments;
			if (previousLeavingDocuments&&previousLeavingDocuments.length>0) {
				previousLeavingDocuments.forEach((item) => {
					downloadCertificatePictureApi(item.url)
						.then((res) => {
							if (res) {
								let resData = res.data;
								var blob = new Blob([resData], { type: 'application/octet-stream' });
								let tempUrl = URL.createObjectURL(blob);
								that.previousLeavingDocList.push({ name: item.fileName, url: tempUrl });
								that.tempPreviousLeavingDocList.set(item.fileName, item.url);
							} else {
								this.msgError('后端下载文件出错');
							}
						})
						.catch((e) => {
							this.loading = false;
							this.msgError(`后端下载文件出错 & ${e}`);
						});
				});
			}
			if (currentLeavingDocuments&&currentLeavingDocuments.length >0) {
				currentLeavingDocuments.forEach((item) => {
					downloadCertificatePictureApi(item.url)
						.then((res) => {
							if (res) {
								let resData = res.data;
								var blob = new Blob([resData], { type: 'application/octet-stream' });
								let tempUrl = URL.createObjectURL(blob);
								this.currentLeavingDocList.push({ name: item.fileName, url: tempUrl });
								this.tempCurrentLeavingDocList.set(item.fileName, item.url);
							} else {
								this.msgError('后端下载文件出错');
							}
						})
						.catch((e) => {
							this.loading = false;
							this.msgError(`后端下载文件出错 & ${e}`);
						});
				});
			}
		},
		handlePictureCardPreview(file) {
			if (file.name.endsWith('.pdf')) {
				this.pdfUrl = file.url;
				this.dialogPdfName = file.name;
			} else {
				this.dialogImageUrl = file.url;
			}

			this.dialogVisible = true;
		},
		closeDialog() {
			this.dialogImageUrl = '';
			this.dialogVisible = false;
		},
	},
};
</script>

<style></style>