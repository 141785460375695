import { render, staticRenderFns } from "./officialEdit.vue?vue&type=template&id=7cd63e39&scoped=true"
import script from "./officialEdit.vue?vue&type=script&lang=js"
export * from "./officialEdit.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7cd63e39",
  null
  
)

export default component.exports