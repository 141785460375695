<template>
	<div style="margin-top: 20px;margin-left:80px;">
		<div style="margin: 10px 0px"><span>评价</span></div>
		<el-input type="textarea" v-model="comments" :rows="3" placeholder="" style="width: 90%" :disabled="this.courseCompleted"></el-input>
		<div style="margin: 10px 0px">
			<el-rate v-model="stars" :colors="colors" :disabled="this.courseCompleted"> </el-rate>
		</div>
		<div>
			<el-button plain :loading="updateLoading" type="success" @click="submit" :disabled="this.courseCompleted">提交评价</el-button>
		</div>

		<div style="margin-top: 20px"></div>
	</div>
</template>

<script>
import { updateMyTraining,findTrainingRecord } from '@/api/training/myTraining';
export default {
	name: 'CourseEvaluation',

	data() {
       
		return {
			colors: ['#99A9BF', '#F7BA2A', '#FF9900'],
			stars:0,
			comments: '',
			updateLoading: false,
			completed:this.courseCompleted
		};
	},
	props: { currentCourse: Object,courseCompleted:Boolean },
	mounted() {},
    created(){
        this.GetCourseRecord();
    },
	methods: {
		submit: function () {
			let myTraining = {};
			myTraining.userId = this.$store.getters.userInfo.id;
			myTraining.employeeName = this.$store.getters.userInfo.username;
			myTraining.trainingName = this.currentCourse.courseName;
			myTraining.trainingType = "在线学习";
			myTraining.trainingId = this.currentCourse.id;
			myTraining.testPaperId = this.currentCourse.testPaperId;
			myTraining.comments = this.comments;
			myTraining.stars = this.stars;
			myTraining.completed = true;
			this.updateLoading = true;
			updateMyTraining(myTraining)
				.then((response) => {
					this.updateLoading = false;
					this.courseCompleted=true;
					this.$emit('completeCourse');
					this.msgSuccess('评价成功。');
				})
				.catch((err) => {
					this.updateLoading = false;
					this.msgError('评价失败，' + err);
				});
		},
        GetCourseRecord()
		{
			let myTraining={};
			myTraining.userId =this.$store.getters.userInfo.id;
			myTraining.courseId= this.currentCourse.id;
			findTrainingRecord(myTraining)
			.then((response) => {
				if(response.data)
				{
					this.comments=response.data.comments;
                    this.stars = response.data.stars;
                    console.log('获取培训记录成功。');
				}
			})
			.catch((err) => {
				console.log('获取培训记录失败，');
			});
		}
	},
};
</script>

<style lang="scss" scoped>
</style>