<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px">
      <el-form-item label="发送人" prop="fromName">
        <el-input v-model="queryParams.fromName" placeholder="请输入发送人姓名" clearable size="small" @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="是否已读" prop="isRead">
        <el-select v-model="queryParams.isRead" clearable placeholder="是否已读" size="small">
          <el-option v-for="item in dictYesNo" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="类型" prop="processDefinitionKey">
        <el-select v-model="queryParams.processDefinitionKey" clearable placeholder="是否已读" size="small">
          <el-option v-for="item in bpmFlowType" :key="item.value" :label="item.label" :value="item.value" >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
            type="danger"
            plain
            icon="el-icon-delete"
            size="mini"
            :disabled="multiple"
            @click="handleDelete"
            v-permission="['/bpm/actCopyTask/remove']"
        >删除
        </el-button>
      </el-col>
<!--      <el-col :span="1.5">-->
<!--        <el-button-->
<!--            type="warning"-->
<!--            plain-->
<!--            icon="el-icon-download"-->
<!--            size="mini"-->
<!--            @click="handleExport"-->
<!--            v-permission="['/bpm/actCopyTask/export']"-->
<!--        >导出-->
<!--        </el-button>-->
<!--      </el-col>-->
      <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>
    </el-row>

    <el-table v-loading="loading" :data="actCopyTaskList" @selection-change="handleSelectionChange" stripe>
      <el-table-column type="selection" width="55" align="center"/>
<!--      <el-table-column label="id" align="center" prop="id"/>-->
      <el-table-column label="发送人" align="center" prop="fromName"/>
      <el-table-column label="抄送时间" align="center" prop="createTime" width="180"/>
<!--      <el-table-column label="执行流程" align="center" prop="executionId"/>-->
<!--      <el-table-column label="流程Id" align="center" prop="processInstanceId"/>-->
      <el-table-column label="类型" align="center" prop="processDefinitionKey">
        <template slot-scope="scope">
          {{getDictDataLabel(DICT_TYPE.BPM_FLOW_TYPE, scope.row.processDefinitionId?
            scope.row.processDefinitionId.substring(0,scope.row.processDefinitionId.indexOf(":")) : '')}}
        </template>
      </el-table-column>
      <el-table-column label="是否已读" align="center" prop="isRead">
        <template slot-scope="scope">
          {{getDictDataLabel(DICT_TYPE.COM_YES_NO, scope.row.isRead)}}
        </template>
      </el-table-column>
      <el-table-column label="已读时间" align="center" prop="readTime" width="180"/>
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width">
        <template slot-scope="scope">
          <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="showFlowDetail(scope.row)"
          >审批进度
          </el-button>
          <el-button
              size="mini"
              type="text"
              icon="el-icon-view"
              @click="showTask(scope.row)"
          >查看
          </el-button>
          <el-button
              size="mini"
              type="text"
              icon="el-icon-delete"
              @click="handleDelete(scope.row)"
              v-permission="['/bpm/actCopyTask/remove']"
          >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
        v-show="total>0"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
    />

    <!-- 添加或修改流程抄送对话框 -->
    <el-dialog :title="title" :visible.sync="open" width="80%" append-to-body>
      <FormDetail :dialogType="dialogType" :businessType="businessType" :processInstanceId="processInstanceId" @close="close"></FormDetail>
<!--      <template v-if="'Official' == dialogType">-->
<!--        <official-edit :type="businessType" :id="businessKey" :isReadOnly="true" :isShowClose="true" @close="close"></official-edit>-->
<!--      </template>-->
<!--      <template v-if="'Depart' == dialogType">-->
<!--        <depart-edit :type="businessType" :id="businessKey" :isReadOnly="true" :isShowClose="true" @close="close"></depart-edit>-->
<!--      </template>-->
<!--      <template v-if="'Transfer' == dialogType">-->
<!--        <TransferEdit :type="businessType" :id="businessKey" :isReadOnly="true" :isShowClose="true" @close="close"></TransferEdit>-->
<!--      </template>-->
    </el-dialog>
    <el-dialog :title="title" :visible.sync="openFlowDetail" width="80%" append-to-body>
      <FlowDetail :process-instance-id-in="processInstanceId"></FlowDetail>
      <div style="text-align: center"><el-button @click="openFlowDetail=false">关 闭</el-button></div>
    </el-dialog>

  </div>
</template>

<script>
import {
  listActCopyTask,
  getActCopyTask,
  delActCopyTask,
  addActCopyTask,
  updateActCopyTask,
  exportActCopyTask } from "@/api/bpm/actCopyTask";

import RightToolbar from "@/components/RightToolbar"
import permission from '@/directive/permission/index.js' // 权限判断指令
import Pagination from "@/components/Pagination";
import FlowDetail from "../todo/flowDetail";
import FormDetail from "../components/formDetail";
import { showLoading, hideLoading } from '@/utils/reqloading';
import {DICT_TYPE, getDictDataLabel, getDictDatas} from "@/utils/dict";

// 表查询配置
const dbOrderBy = ''

export default {
  name: "ActCopyTask",
  components: {
    RightToolbar,
    Pagination,
    FlowDetail,
    FormDetail,
  },
  directives: { permission },
  data() {
    return {
      // 遮罩层
      loading: true,
      // 查询参数
      where: {
        query: '',
        filters: []
      },


      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 流程抄送表格数据
      actCopyTaskList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      openFlowDetail: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        orderby: dbOrderBy,
        readTime: null,
        isRead: null,
        fromName: null,
        processDefinitionKey: null,
      },
      // 表单参数
      form: {
      },
      // 表单校验
      rules: {
      },
      dialogType: 'Official',
      businessKey: null,
      businessType: null,
      processInstanceId: null,
      dictYesNo: getDictDatas(DICT_TYPE.COM_YES_NO),
      bpmFlowType: getDictDatas(DICT_TYPE.BPM_FLOW_TYPE),
    };
  },
  created() {
    this._filter = {};
    this.getList();
  },
  methods: {
    /** 查询流程抄送列表 */
    getQuery(){
      this._filter = {};
      if(this.queryParams.fromName) {
        this._filter['fromName'] = {
          type: 'search',
          value: this.queryParams.fromName,
        }
      }
      if(this.queryParams.isRead) {
        this._filter['isRead'] = {
          type: 'eq',
          value: this.queryParams.isRead,
        }
      }
      if(this.queryParams.processDefinitionKey) {
        this._filter['processDefinitionKey'] = {
          type: 'eq',
          value: this.queryParams.processDefinitionKey,
        }
      }
      const filters = this.parseFilters(this._filter)
      if (filters && filters.length > 0) {
        this.where.filters = filters
      } else {
        // 如果过滤条件为空，设置空数组
        this.where.filters = []
      }
      const params = {
        pageNum: this.queryParams.pageNum,
        pageSize: this.queryParams.pageSize,
        where: this.where,
        order: this.orderby
      }

      return params;
    },
    getList() {
      this.loading = true;
      const params = this.getQuery();
      listActCopyTask(params).then(response => {
        this.actCopyTaskList = response.data.records;
        this.total = response.data.total;
        this.loading = false;
      })
      ;
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {};
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },
    // 显示任务
    showTask(row){
      console.log(row.processDefinitionId)
      // const def = row.processDefinitionKey;
      const def = row.processDefinitionId.substring(0,row.processDefinitionId.indexOf(":"));
      this.dialogType = def;
      this.businessType = "view";
      // this.businessKey = row.businessKey;
      this.processInstanceId = row.processInstanceId;
      this.open = true;
    },
    showFlowDetail(row){
      console.log(row.processInstanceId);
      this.processInstanceId = row.processInstanceId;
      this.openFlowDetail = true;
    },
    close(){
      this.open = false;
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      let ids = [];
      if(row.id){
        ids = [row.id];
      }else{
        ids = this.ids;
      }

      this.$confirm('是否确认删除选中的流程抄送数据?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return delActCopyTask(ids);
      }).then(() => {
        this.getList();
        this.msgSuccess("删除成功");
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      const params = this.getQuery();
      this.$confirm('是否确认导出所有流程抄送数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return exportActCopyTask(params);
      }).then(response => {
        this.download(response.msg);
      })
    }
  }
};
</script>

<style scoped>

</style>
