<template>
	<div>
		<div style="margin-bottom: 10px; text-align: center">
			<el-button style="margin-top: 10px" plain type="danger" :loading="saving" :disabled="disableLockButton" v-show="!locked && !this.isReadOnly && this.opType == 'hrEntry'" @click="lockEmployee">锁定</el-button>
			<el-button style="margin-top: 10px" plain type="primary" :loading="saving" :disabled="disableLockButton" v-show="locked && !this.isReadOnly && this.opType == 'hrEntry'" @click="unlockEmployee">解锁</el-button>
			<el-button style="margin-top: 10px" plain type="primary" @click="openAllCollapses">全打开</el-button>
			<el-button style="margin-top: 10px" plain type="primary" @click="closeAllCollapses">全关闭</el-button>
			<el-link
				href="#footer"
				id="header"
				:underline="false"
				icon="el-icon-bottom"
				style="text-decoration: none; float: right; margin-top: 10px"
				class="el-button el-button--primary is-plain is-round"
				>页尾</el-link
			>
			<!-- <el-button plain round  type="primary" style="float:right;margin-top:10px;" icon="el-icon-bottom">  <a id="header" href="#footer" style="text-decoration:none; display:block text-align:center;color:#409EFF;">页尾</a> </el-button> -->
		</div>
		<el-form ref="form" :model="form" :rules="rules" class="employ" label-width="150px">
			<el-collapse v-model="activeNames" >
				<template v-if="!form.id || form.username">
					<el-collapse-item title=" 账 号 信 息" name="accountInfo" :class="accountorSetting()" >
						<el-row style="margin-top:10px;">
							<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
								<el-form-item label="用户名" prop="username" :rules="[{ required: true, message: '用户名不能为空', trigger: 'blur' }]">
									<el-input :readonly="formReadOnly" v-model="form.username" placeholder="请输入用户名" />
								</el-form-item>
							</el-col>
							<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
								<el-form-item label="密码" prop="password" :rules="[{ required: true, message: '登录密码不能为空', trigger: 'blur' }]">
									<el-input type="password" auto-complete="new-password" v-model="form.password" placeholder="请输入登录密码" />
								</el-form-item>
							</el-col>
							<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb20">
								<el-form-item label="手机号" prop="mobile" :rules="[{ required: true, message: '手机号不能为空', trigger: 'blur' }]">
									<el-input v-model="form.mobile" placeholder="请输入手机号" />
								</el-form-item>
							</el-col>
							<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
								<el-form-item label="角色" prop="roleIds" :rules="[{ required: true, message: '角色不能为空', trigger: 'blur' }]">
									<el-select style="width: 100%" v-model="form.roleIds" multiple placeholder="请选择角色" @change="chooseDTChange">
										<el-option v-for="item in roleOptions" :key="item.id" :label="item.zhName" :value="item.id"> </el-option>
									</el-select>
								</el-form-item>
							</el-col>
						</el-row>
					</el-collapse-item>
				</template>
				<el-collapse-item title=" 基 本 信 息" name="baseInfo" :class="collapseSetting()">
					<!-- <div style="margin-left: 150px; margin-bottom: 10px ;margin-top: 10px">
						<el-upload
							class="avatar-uploader"
							action="uploadPersonalPhoto()"
							:show-file-list="false"
							:on-success="handleAvatarSuccess"
							:http-request="uploadPersonalPhoto"
							:auto-upload="true"
							:disabled="locked?true:formReadOnly"
							accept="image/jpeg,image/png"
							:before-upload="beforeAvatarUpload"
						>
							<img v-if="form.photo" :src="form.photo" class="avatar" />
							<i v-else class="el-icon-plus avatar-uploader-icon"></i>
						</el-upload>
						<label style="font-size: large; font-weight: 520px">员工证相片</label>
					</div> -->
					<template>
						<el-row>
							<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
								<el-form-item label="" prop="">
									<div style="margin-bottom: 10px ;margin-top: 10px;text-align:center;">
										<el-upload
											class="avatar-uploader"
											action="uploadPersonalPhoto()"
											v-loading="photoLoading"
											:show-file-list="false"
											:on-success="handleAvatarSuccess"
											:http-request="uploadPersonalPhoto"
											:auto-upload="true"
											:disabled="locked?true:formReadOnly"
											accept=".jpg,.jpeg,.png,.bpm,application/pdf"
											:before-upload="beforeAvatarUpload"
										>
										<div v-if="form.photo" >
											<pdf v-if="tempPersonalPhotoUrl!=null&&tempPersonalPhotoUrl.endsWith('.pdf')" :src="form.photo" :page="1" class="avatar"></pdf>
											<img v-else :src="form.photo" class="avatar" />
										</div>
											
										<i v-else class="el-icon-plus avatar-uploader-icon"></i>
										</el-upload>
										<label style="font-size: large; font-weight: 520px">员工证相片</label>
									</div>
								</el-form-item>
							</el-col>
							<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
								<el-form-item label="" prop="">
									<div style="margin-bottom: 10px ;margin-top: 10px;text-align:center;">
										<el-upload
											class="avatar-uploader"
											action="uploadIdCardFrontPicture()"
											:show-file-list="false"
											v-loading="idCardFrontLoading"
											:on-success="handleAvatarSuccess"
											:http-request="uploadIdCardFrontPicture"
											:auto-upload="true"
											:disabled="locked?true:formReadOnly"
											accept=".jpg,.jpeg,.png,.bpm,application/pdf"
											:before-upload="beforeAvatarUpload"
										>
											<div v-if="form.idCardFrontPicture">
												<pdf v-if="tempIdCardFrontPicture!=null&&tempIdCardFrontPicture.endsWith('.pdf')" :src="form.idCardFrontPicture" :page="1" class="avatar"></pdf>
												<img v-else  :src="form.idCardFrontPicture" class="avatar" />
											</div>
											
											<i v-else class="el-icon-plus avatar-uploader-icon"></i>
										</el-upload>

										<div>
											<label style="font-size: large; font-weight: 520px;margin-right:15px;">身份证正面</label>
											<el-button  plain type="primary" @click="OpenIdCardPrintingDialog" class="el-icon-printer">打印</el-button>
										</div>
									</div>
								</el-form-item>
							</el-col>
							<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
								<el-form-item label="" prop="">
									<div style="margin-bottom: 10px ;margin-top: 10px;text-align:center;">
										<el-upload
											class="avatar-uploader"
											action="uploadIdCardBackPicture()"
											:show-file-list="false"
											v-loading="idCardBackLoading"
											:on-success="handleAvatarSuccess"
											:http-request="uploadIdCardBackPicture"
											:auto-upload="true"
											:disabled="locked?true:formReadOnly"
											accept=".jpg,.jpeg,.png,.bpm,application/pdf"
											:before-upload="beforeAvatarUpload"
										>
											<div v-if="form.idCardBackPicture">
												<pdf v-if="tempIdCardBackPicture!=null&&tempIdCardBackPicture.endsWith('.pdf')" :src="form.idCardBackPicture" :page="1" class="avatar"></pdf>
												<img v-else :src="form.idCardBackPicture" class="avatar" />
											</div>
											
											<i v-else class="el-icon-plus avatar-uploader-icon"></i>
										</el-upload>
										<label style="font-size: large; font-weight: 520px">身份证反面</label>
									</div>
								</el-form-item>
							</el-col>
						</el-row>
						<!-- <el-form ref="basicInfoform" :model="form" :rules="rules"> -->
						<el-row>
							<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0" v-if="form.id || form.username">
								<el-form-item label="用户名" >
									<el-input :disabled="disabledControl" v-model="form.user.username" autofocus/>
								</el-form-item>
							</el-col>
							<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0" v-if="form.id || form.username">
								<el-form-item label="密码">
									<el-input  :readonly="locked?true:formReadOnly" v-model="storePassword" placeholder="请输入新密码" />
								</el-form-item>
							</el-col>
							<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0" v-if="form.id || form.username">
								<el-form-item label="手机号码" prop="">
									<el-input :readonly="locked?true:formReadOnly" v-model="form.user.mobile"  />
								</el-form-item>
							</el-col>
							<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
								<el-form-item label="姓名" prop="name">
									<el-input :readonly="locked?true: formReadOnly" v-model="form.name" placeholder="请输入姓名" />
								</el-form-item>
							</el-col>

							<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
								<el-form-item label="曾用名" prop="formerName">
									<el-input :readonly="locked?true:formReadOnly" v-model="form.formerName" placeholder="请输入曾用名" />
								</el-form-item>
							</el-col>
						
							<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
								<el-form-item label="证件类型" prop="idType">
									<el-select v-model="form.idType" :disabled="locked?true:formReadOnly" placeholder="请选择证件类型">
										<el-option v-for="item in iDTypes" :key="item.value" :label="item.label" :value="item.value" />
									</el-select>
								</el-form-item>
							</el-col>
							<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
								<el-form-item
									label="身份证号码"
									prop="idNum"
									:rules="[
										{
											required: true,
											validator: (rule, value, callback) => {
												validateID(rule, value, callback, form.idType);
											},
											trigger: 'blur',
										},
									]"
								>
									<el-input :readonly="locked?true:formReadOnly" v-model="form.idNum" placeholder="请输入身份证号码" />
								</el-form-item>
							</el-col>
							<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
								<el-form-item label="身份证地址" prop="birthAddr">
									<el-input :readonly="locked?true:formReadOnly" v-model="form.birthAddr" placeholder="请输入出生地" />
								</el-form-item>
							</el-col>
							<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
								<el-form-item label="出生年月" prop="idBridthDay">
									<el-date-picker clearable v-model="idCardBirthday" :readonly="locked?true:formReadOnly" type="date" value-format="yyyy-MM-dd" placeholder="选择身份证记载出生年月">
									</el-date-picker>
								</el-form-item>
							</el-col>
							
						
							
							<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
								<el-form-item label="性别" prop="sex">
									<el-select :disabled="locked?true:formReadOnly" v-model="idCardSex" placeholder="请选择性别">
										<el-option label="男" value="男" />
										<el-option label="女" value="女" />
										<el-option label="未知" value="未知" />
									</el-select>
								</el-form-item>
							</el-col>
								<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
								<el-form-item label="民族" prop="nation">
									<el-input :readonly="locked?true:formReadOnly" v-model="form.nation" placeholder="请输入民族" />
								</el-form-item>
							</el-col>
							<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
								<el-form-item label="婚姻状况">
									<el-select v-model="form.marritalStatus" :disabled="locked?true:formReadOnly" placeholder="请选择婚姻状况">
										<el-option v-for="item in marriageStatuses" :key="item.value" :label="item.label" :value="item.value" />
									</el-select>
								</el-form-item>
							</el-col>
							<!-- <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
								<el-form-item label="健康状况">
									<el-select v-model="form.helth" :disabled="locked?true:formReadOnly" placeholder="请选择健康状况">
										<el-option v-for="item in employeeHealthes" :key="item.value" :label="item.label" :value="item.value" />
									</el-select>
								</el-form-item>
							</el-col> -->
							<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
								<el-form-item label="籍贯" prop="nativePlace">
									<el-input :readonly="locked?true:formReadOnly" v-model="form.nativePlace" placeholder="请输入籍贯" />
								</el-form-item>
							</el-col>
							<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
								<el-form-item label="户口所在地" prop="householdAddr">
									<el-input :readonly="locked?true:formReadOnly" v-model="form.householdAddr" placeholder="请输入户口所在地" />
								</el-form-item>
							</el-col>
							<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
								<el-form-item label="参加工作时间" prop="workStartDate">
									<el-date-picker clearable v-model="form.workStartDate" :readonly="locked?true:formReadOnly" type="date" value-format="yyyy-MM-dd" placeholder="选择参加工作时间">
									</el-date-picker>
								</el-form-item>
							</el-col>
							
							<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
								<el-form-item label="集体户口" prop="householdType">
									<!-- 1是，0不是" -->
									<el-checkbox v-model="form.householdType" :disabled="locked?true:formReadOnly"></el-checkbox>
								</el-form-item>
							</el-col>
						</el-row>
						<!-- </el-form> -->
						<!-- </el-collapse-item> -->
					</template>
					<template>
						<!-- <el-collapse-item title=" 人 事 信 息" name="hrInfo"> -->
						<!-- <el-form ref="hrInfoform" :model="form" :rules="rules"> -->
						<el-row>
							<el-divider></el-divider>

							<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
								<el-form-item label="公司" prop="companyId">
									<el-input :disabled="disabledControl" :value="companyFormat(form, null)" />
								</el-form-item>
							</el-col>
              <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
                <el-form-item label="岗位" prop="positionId">
                  <el-select :disabled="locked?true:this.opType=='self'?true: formReadOnly" clearable v-model="form.positionId" placeholder="请选择岗位" style="width:100%">
                    <el-option
                        v-for="dict in positionList"
                        :key="dict.id"
                        :label="dict.name"
                        :value="dict.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
							<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
								<el-form-item label="部门" prop="departmentId">
									<treeselect
										:disabled="locked?true:this.opType=='self'?true: formReadOnly"
										v-model="form.departmentId"
										:options="departmentOptions"
										:normalizer="normalizerDepartment"
										placeholder="请选择所属部门"
									/>
								</el-form-item>
							</el-col>
							<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
								<el-form-item label="员工编号" prop="workNum">
									<el-input :disabled="disabledControl" v-model="form.workNum" />
								</el-form-item>
							</el-col>
							<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
								<el-form-item label="现岗位首聘" prop="curPositionStartDate">
									<el-date-picker
										:readonly="locked?true:this.opType=='self'?true: formReadOnly"
										clearable
										v-model="form.curPositionStartDate"
										type="date"
										value-format="yyyy-MM-dd"
										placeholder="选择现任岗位的首聘时间"
									>
									</el-date-picker>
								</el-form-item>
							</el-col>


							<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
								<el-form-item label="入本单位时间" prop="joinCompanyDate" :rules="[{ required: true, message: '加入本单位时间不能为空', trigger: 'blur' }]">
									<el-date-picker
										:readonly="locked?true:this.opType=='self'?true: formReadOnly"
										clearable
										v-model="form.joinCompanyDate"
										type="date"
										value-format="yyyy-MM-dd"
										placeholder="选择入本单位时间"
									>
									</el-date-picker>
								</el-form-item>
							</el-col>
							<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
								<el-form-item label="休假工龄起始" prop="paidHolidayStartDate">
									<el-date-picker
										:readonly="locked?true:this.opType=='self'?true: formReadOnly"
										clearable
										v-model="form.paidHolidayStartDate"
										type="date"
										value-format="yyyy-MM-dd"
										placeholder="选择休假工龄的起始时间"
									>
									</el-date-picker>
								</el-form-item>
							</el-col>
							<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
								<el-form-item label="员工状况" prop="empStatus" :rules="[{ required: true, message: '员工状态不能为空', trigger: 'blur' }]">
									<el-select v-model="form.empStatus" placeholder="请选择" :disabled="locked?true:this.opType=='self'?true: formReadOnly">
										<el-option v-for="item in employeeStatuses" :key="item.value" :label="item.label" :value="item.value"></el-option>
									</el-select>
								</el-form-item>
							</el-col>
							<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
								<el-form-item label="员工类型" prop="empType">
									<el-select v-model="form.empType" placeholder="请选择" :disabled="locked?true:this.opType=='self'?true: formReadOnly">
										<el-option v-for="item in employeeTypes" :key="item.value" :label="item.label" :value="item.value"></el-option>
									</el-select>
								</el-form-item>
							</el-col>
							<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
								<el-form-item label="用工形式" prop="empWorkType">
									<el-select v-model="form.empWorkType" :disabled="locked?true:this.opType=='self'?true: formReadOnly" placeholder="请选择用工形式">
										<el-option v-for="item in employmentTypes" :key="item.value" :label="item.label" :value="item.value" />
									</el-select>
								</el-form-item>
							</el-col>
							<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
								<el-form-item label="退休类型" prop="retiredType">
									<el-select v-model="form.retiredType" placeholder="请选择" :disabled="locked?true:this.opType=='self'?true: formReadOnly">
										<el-option v-for="item in retiredTypes" :key="item.value" :label="item.label" :value="item.value"></el-option>
									</el-select>
								</el-form-item>
							</el-col>
							<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
								<el-form-item label="退休时间" prop="retiredDate">
									<el-date-picker
										:readonly="locked?true:this.opType=='self'?true: formReadOnly"
										clearable
										v-model="form.retiredDate"
										type="date"
										value-format="yyyy-MM-dd"
										placeholder="选择退休时间"
									>
									</el-date-picker>
								</el-form-item>
							</el-col>
							<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
								<el-form-item label="离职时间" prop="leaveDate">
									<el-date-picker
										:readonly="locked?true:this.opType=='self'?true: formReadOnly"
										clearable
										v-model="form.leaveDate"
										type="date"
										value-format="yyyy-MM-dd"
										placeholder="选择离职时间"
									>
									</el-date-picker>
								</el-form-item>
							</el-col>
							<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
								<el-form-item label="离职原因" prop="leaveReason">
									<el-input v-model="form.leaveReason" :disabled="locked?true:this.opType=='self'?true: formReadOnly"> </el-input>
								</el-form-item>
							</el-col>
							<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
								<el-form-item label="转正时间" prop="officalDate" :rules="[{ required: true, message: '转正时间不能为空', trigger: 'blur' }]">
									<el-date-picker
										:readonly="locked?true:this.opType=='self'?true: formReadOnly"
										clearable
										v-model="form.officalDate"
										type="date"
										value-format="yyyy-MM-dd"
										placeholder="选择转正时间"
									>
									</el-date-picker>
								</el-form-item>
							</el-col>
							<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0"> </el-col>
						</el-row>
						<!-- </el-row> -->

						<!-- </el-form> -->
						<!-- </el-collapse-item> -->
					</template>
					<template>
						<el-divider></el-divider>
						<el-row>
							<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
								<el-form-item label="银行名称" prop="bankName">
									<el-input :readonly="locked?true:formReadOnly" rule v-model="form.bankName" placeholder="请输入银行名称" />
								</el-form-item>
							</el-col>
							<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
								<el-form-item label="银行帐号" prop="bankAcct">
									<el-input :readonly="locked?true:formReadOnly" rule v-model="form.bankAcct" placeholder="请输入银帐号" />
								</el-form-item>
							</el-col>
							<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
								<el-form-item label="是否跨行" prop="isCrossBank">
									<el-switch v-model="form.isCrossBank" active-text="跨行" inactive-text="不跨行" :readonly="locked?true:formReadOnly"></el-switch>
								</el-form-item>
							</el-col>
							<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
								<el-form-item label="跨行银行名称" prop="crossBankName">
									<el-input :readonly="!form.isCrossBank" rule v-model="form.crossBankName" placeholder="请输入跨行银行名称" />
								</el-form-item>
							</el-col>
							<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
								<el-form-item label="社保帐号" prop="insuranceAcct">
									<el-input :readonly="locked?true:formReadOnly" rule v-model="form.insuranceAcct" placeholder="请输入社保帐号" />
								</el-form-item>
							</el-col>
							<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
								<el-form-item label="公积金帐号" prop="fundAcct">
									<el-input :readonly="locked?true:formReadOnly" rule v-model="form.fundAcct" placeholder="请输入公积金帐号" />
								</el-form-item>
							</el-col>
							<!-- <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
								<el-form-item label="是否模板扣款" prop="insuranceFundMode">
									<el-switch v-model="form.insuranceFundMode"  active-text="模板扣款" inactive-text="非模板扣款" :readonly="locked?true:formReadOnly"></el-switch>
								</el-form-item>
							</el-col>
							<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
								<el-form-item label="社保扣款" prop="insuranceAmt">
									<el-input :readonly="form.insuranceFundMode" rule v-model="form.insuranceAmt" placeholder="请输入社保扣款" />
								</el-form-item>
							</el-col>
							<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
								<el-form-item label="公积金扣款" prop="fundAmt">
									<el-input :readonly="form.insuranceFundMode" rule v-model="form.fundAmt" placeholder="请输入公积金扣款" />
								</el-form-item>
							</el-col> -->
							
						</el-row>
					</template>
					<template>
						<!-- <el-collapse-item title=" 联 系 方 式" name="contactInfo"> -->
						<!-- <el-form ref="contactform" :model="form" :rules="rules" > -->
						<el-divider></el-divider>
						<el-row>
							<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
								<el-form-item label="办公电话" prop="workPhone">
									<el-input :readonly="locked?true:formReadOnly" rule v-model="form.workPhone" placeholder="区号-号码" />
								</el-form-item>
							</el-col>
							<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
								<el-form-item label="住宅电话" prop="homePhone">
									<el-input :readonly="locked?true:formReadOnly" v-model="form.homePhone" placeholder="区号-号码" />
								</el-form-item>
							</el-col>
							<!-- <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
								<el-form-item label="手机号码">
									<el-input :disabled="true" :value="form.user.mobile" />
								</el-form-item>
							</el-col> -->
							<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
								<el-form-item label="现住地址" prop="currentAddr">
									<el-input :readonly="locked?true:formReadOnly" v-model="form.currentAddr" placeholder="请输入现住地址" />
								</el-form-item>
							</el-col>
							<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
								<el-form-item label="紧急联系人" prop="name">
									<el-input :readonly="locked?true:formReadOnly" v-model="form.urgentContact.name" placeholder="请输入紧急联系人" />
								</el-form-item>
							</el-col>
							<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
								<el-form-item label="联系人手机" prop="urgentContact.mobile">
									<el-input :readonly="locked?true:formReadOnly" v-model="form.urgentContact.mobile" placeholder="11位数字" />
								</el-form-item>
							</el-col>
							<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
								<el-form-item label="联系人电话" prop="urgentContact.phone">
									<el-input :readonly="locked?true:formReadOnly" v-model="form.urgentContact.phone" placeholder="区号-号码" />
								</el-form-item>
							</el-col>
							<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
								<el-form-item label="联系人地址">
									<el-input :readonly="locked?true:formReadOnly" v-model="form.urgentContact.addr" />
								</el-form-item>
							</el-col>
							<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
								<el-form-item label="推荐人">
										<el-select v-model="form.recommender" :remote-method="findRecommander" filterable remote reserve-keyword placeholder="请输入名字关键字" :loading="nameListLoading" :disabled="locked?true:formReadOnly">
											<el-option v-for="item in recommenderList" :key="item.value" :label="item.label" :value="item.label">
											</el-option>
										</el-select>
								</el-form-item>
							</el-col>
							<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
								<el-form-item label="服务时长">
									<el-input :readonly="locked?true:formReadOnly" v-model="form.lengthOfService" />
								</el-form-item>
							</el-col>
							<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
								<el-form-item label="推荐奖金">
									<el-input :readonly="locked?true:formReadOnly" v-model="form.recommenderBonus" />
								</el-form-item>
							</el-col>
						</el-row>
						<div style="margin-bottom: 10px; text-align: center">
							<el-button v-on:click="saveBasicInfo" plain v-bind:disabled="locked?true:formReadOnly" :loading="saving" type="primary" icon="el-icon"
							>保存</el-button
							>
						</div>
						<!--              <el-row>-->
						<!--								<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">-->
						<!--									<el-form-item label=" ">-->
						<!--										&lt;!&ndash; <el-button v-on:click="addLanguage" type="primary" v-bind:disabled="formReadOnly"  plain icon="el-icon-plus">增加</el-button> &ndash;&gt;-->
						<!--									</el-form-item>-->
						<!--								</el-col>-->
						<!--								<el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb0" style="text-align: center">-->
						<!--									<el-form-item label=" ">-->
						<!--										<el-button v-on:click="saveBasicInfo" plain v-bind:disabled="formReadOnly" :loading="saving" type="primary" icon="el-icon"-->
						<!--											>保存</el-button-->
						<!--										>-->
						<!--									</el-form-item>-->
						<!--								</el-col>-->
						<!--							</el-row>-->
						<!-- </el-form> -->
					</template>
				</el-collapse-item>
				<template>
					<health-report
						v-bind:healthReport="form.healthreport"
						collapseStatus="healthReport"
						:isReadOnly="formReadOnly"
						v-bind:id="form.id"
						:locked="form.locked"
						v-on="$listeners"
						v-if="reloading"
					>
					</health-report>
				</template>
				<!--  上一家公司离职证明 -->
				<template>
					<leaving-certificate
						v-bind:LeavingDocuments="form.leavingDocuments"
						collapseStatus="leavingCertificate"
						:isReadOnly="formReadOnly"
						v-bind:id="form.id"
						:locked="form.locked"
						v-on="$listeners"
						v-if="reloading"
					>
					</leaving-certificate>
				</template>

				<!-- // 学历，学位合并到一块 -->
				<template>
					<EduExperiences
						v-bind:eduExperiences="form.educations"
						collapseStatus="eduInfo"
						:isReadOnly="formReadOnly"
						:locked="form.locked"
						:enableAddButton="enableAdd"
						v-bind:id="form.id"
						v-on="$listeners"
						v-if="reloading"
					></EduExperiences>
				</template>
				<template>
					<LanguageLevels
						v-bind:languageLevels="form.languageLevels"
						collapseStatus="languageLevel"
						:isReadOnly="formReadOnly"
						:locked="form.locked"
						:enableAddButton="enableAdd"
						v-bind:id="form.id"
						v-on="$listeners"
						v-if="reloading"
					></LanguageLevels>
				</template>
				<template>
					<SpouseInfo
						v-bind:spouseInfo="form.spouseInfo"
						collapseStatus="spouseInfo"
						:isReadOnly="formReadOnly"
						v-bind:id="form.id"
						:locked="form.locked"
						v-on="$listeners"
						v-if="reloading"
					></SpouseInfo>
				</template>
				<template>
					<SoldierInfo
						v-bind:soldierInfo="form.soldierInfo"
						collapseStatus="soldierInfo"
						:isReadOnly="formReadOnly"
						:locked="form.locked"
						v-bind:id="form.id"
						v-on="$listeners"
						v-if="reloading"
					></SoldierInfo>
				</template>
				<template>
					<PartyInfo
						v-bind:partyInfo="form.partyInfo"
						collapseStatus="partyInfo"
						:isReadOnly="formReadOnly"
						:locked="form.locked"
						v-bind:id="form.id"
						v-on="$listeners"
						v-if="reloading"
					></PartyInfo>
				</template>
				<template>
					<FamilySocialRelations
						v-bind:familySocialRelations="form.familySocialRelations"
						collapseStatus="familySocialRelations"
						:isReadOnly="formReadOnly"
						:locked="form.locked"
						v-bind:id="form.id"
						:enableAddButton="enableAdd"
						v-on="$listeners"
						v-if="reloading"
					></FamilySocialRelations>
				</template>
				<template>
					<Certificates
						v-bind:certificates="form.certificates"
						collapseStatus="certificates"
						:isReadOnly="formReadOnly"
						:locked="form.locked"
						:enableAddButton="enableAdd"
						v-bind:id="form.id"
						v-on="$listeners"
						v-if="reloading"
					></Certificates>
				</template>
				<template>
					<PreviousPositions
						v-bind:previousPositions="form.previousPositions"
						collapseStatus="previousPositions"
						:isReadOnly="formReadOnly"
						:locked="form.locked"
						:enableAddButton="enableAdd"
						v-bind:id="form.id"
						v-on="$listeners"
						v-if="reloading"
					></PreviousPositions>
				</template>
				<template>
					<ProfessionalTechniques
						v-bind:professionalTechques="form.skills"
						collapseStatus="skills"
						:isReadOnly="formReadOnly"
						:locked="form.locked"
						:enableAddButton="enableAdd"
						v-bind:id="form.id"
						v-on="$listeners"
						v-if="reloading"
					></ProfessionalTechniques>
				</template>
				<template>
					<TrainingExperiences
						v-bind:trainingExperiences="form.trainExps"
						collapseStatus="trainExps"
						:isReadOnly="formReadOnly"
						:locked="form.locked"
						:enableAddButton="enableAdd"
						v-bind:id="form.id"
						v-on="$listeners"
						v-if="reloading"
					></TrainingExperiences>
				</template>
				<template>
					<WorkExperiences
						v-bind:workExperiences="form.workExps"
						collapseStatus="workExps"
						:isReadOnly="formReadOnly"
						:locked="form.locked"
						:enableAddButton="enableAdd"
						v-bind:id="form.id"
						v-on="$listeners"
						v-if="reloading"
					></WorkExperiences>
				</template>

				<template>
					<ArchiveInfo
						v-bind:archiveInfo="form.archiveInfo"
						collapseStatus="archiveInfo"
						:isReadOnly="formReadOnly"
						:locked="form.locked"
						v-bind:id="form.id"
						v-on="$listeners"
						v-if="reloading"
					></ArchiveInfo>
				</template>
				<template>
					<SelfInfo
						v-bind:selfInfo="form.selfInfo"
						collapseStatus="selfInfo"
						:isReadOnly="formReadOnly"
						:locked="form.locked"
						:enableAddButton="enableAdd"
						v-bind:id="form.id"
						v-on="$listeners"
						v-if="reloading"
					></SelfInfo>
				</template>
				<template>
					<el-collapse-item title=" 其 他 信 息" name="otherInfo" :class="otheInfoCollapseSetting()">
						<!-- <el-form ref="otherInfoForm" :model="form" :rules="rules" class="employ"> -->
						<el-row :gutter="35">
							<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
								<el-form-item label="其他信息" prop="otherInfo">
									<el-input :readonly="formReadOnly" v-model="form.otherInfo" placeholder="请输入其他信息" />
								</el-form-item>
							</el-col>
							<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
								<el-form-item label=" ">
									<el-button v-on:click="saveOtherInfo" plain v-bind:disabled="formReadOnly" :loading="saving" type="primary" icon="el-icon"
										>保存</el-button
									>
								</el-form-item>
							</el-col>
						</el-row>
						<!-- </el-form> -->
					</el-collapse-item>
				</template>
			</el-collapse>
			<el-dialog title="打印身份证" :visible.sync='showIdPrinting' width="80%" append-to-body >
        		<id-card-printing @close="ClosseIdCardPrintingDialog" :idCardBackUrl="form.idCardBackPicture" :idCardFrontFileName="tempIdCardFrontPicture" :idCardFrontUrl="form.idCardFrontPicture" :idCardBackFileName="tempIdCardBackPicture"  v-if="showIdPrinting" ></id-card-printing>
    		</el-dialog>
		</el-form>
		<div slot="footer" class="dialog-footer" style="text-align: center">
			<el-button style="margin-top: 10px" type="primary" plain @click="openAllCollapses">全打开</el-button>
			<el-button style="margin-top: 10px" type="primary" plain @click="closeAllCollapses">全关闭</el-button>
			<!-- <el-button style="margin-top:10px;margin-left:25px;" v-if="!formReadOnly" type="primary" plain @click="submitForm" v-permission="['/employ/employ/add', '/employ/employ/edit']">保存所有
      </el-button>  -->
			<el-button style="margin-top: 10px" type="primary" plain @click="cancel">关 闭</el-button>
			<el-link
				href="#header"
				icon="el-icon-top"
				id="footer"
				:underline="false"
				style="text-decoration: none; float: right; margin-top: 10px"
				class="el-button el-button--primary is-plain is-round"
				>页首</el-link
			>
			<!-- <el-button plain round  class="btn-1" type="primary" icon="el-icon-top" style="float:right;margin-top:10px;"><a id="footer" href="#header" style="text-decoration:none; display:block text-align:center;"><span>页首</span></a></el-button>  -->
			<!-- <el-button plain round  class="btn-1" type="primary" icon="el-icon-top" style="float:right;margin-top:10px;"><el-link href="#header" >页首</el-link></el-button>  -->
		</div>

	</div>
</template>

<script>
import {
	downloadPersonalPhoto,
	uploadPersonalPhoto,
	uploadIdCardPicture,
	UploadPersonalHeadPhoto,
	listEmploy,
	getEmploy,
	delEmploy,
	addEmploy,
	updateEmploy,
	exportEmploy,
	unlockEmployee,
	lockEmployee,
	findRecommenderList
} from '@/api/employ/employ';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import permission from '@/directive/permission/index.js'; // 权限判断指令
import { getTreeselectCompany } from '@/api/org/company';
import { getTreeselectDepartment } from '@/api/org/department';
import { getTreeselectPosition } from '@/api/org/position';
import SpouseInfo from './spouseInfo.vue';
import SoldierInfo from './soldierInfo.vue';
import PartyInfo from './partyInfo.vue';
import ArchiveInfo from './archiveInfo.vue';
import LanguageLevels from './languageLevel.vue';
import EduExperiences from './educationExperience.vue';
import FamilySocialRelations from './familySocialRelation.vue';
import Certificates from './certificate.vue';
import PreviousPositions from './previousPositionInfo.vue';
import ProfessionalTechniques from './professionalTechnique.vue';
import TrainingExperiences from './trainingExperience.vue';
import WorkExperiences from './workExperience.vue';
import LeavingCertificate from './leavingCertificate.vue';
import IdCardPrinting from './idCardPrinting.vue';
import SelfInfo from './selfInfo.vue';
import healthReport from './healthReport.vue';
import { listEntryRole } from "@/api/sys/role";
import { showLoading, hideLoading } from '@/utils/reqloading';
import { UploadPersonalPicture} from "@/api/employ/employ";
import {DICT_TYPE,getDictLabelFromData, getDictDataLabel, getDictDatas} from "@/utils/dict";
import Pdf from 'vue-pdf';
export default {
	name: 'employEdit',
	components: {
		Treeselect,
		SpouseInfo,
		SoldierInfo,
		PartyInfo,
		ArchiveInfo,
		LanguageLevels,
		EduExperiences,
		FamilySocialRelations,
		Certificates,
		PreviousPositions,
		ProfessionalTechniques,
		TrainingExperiences,
		WorkExperiences,
		SelfInfo,
		healthReport,
		LeavingCertificate,
		IdCardPrinting,
		Pdf,
	},
	props: {
		// optype  self 员工自助， hr 人事
		opType: {
			type: String,
			default: 'self',
		},
		row: Object,
		departmentOptionsIn: Array,
		positionOptionsIn: Array,
		isReadOnly: Boolean,
		canAdd:Boolean,
		companyId: Number,
		departmentId: Number,
	},
	directives: { permission },
	watch: {
		row(n, o) {
			//n为新值,o为旧值;
			//if(n === o) return;
			console.log('row change');
			console.log(n);
			console.log(o);
			console.log('change n - o');
			this.load();
		},
		companyId() {
			if (this.departmentOptionsIn) {
				this.departmentOptions = this.departmentOptionsIn;
			} else {
				this.departmentOptions = [];
				this.getDepartment(this.companyId);
			}
			if (this.positionOptionsIn) {
				this.positionOptions = this.positionOptionsIn;
			} else {
				this.positionOptions = [];
				this.getPositison(this.companyId);
			}
		},
		departmentId() {
			// this.load();
		},
	},

	data() {
		var validatorMobilePhone = function (rule, value, callback) {
			if (value === '' || value == null) {
				//callback(new Error('手机号不能为空'));
				callback();
			//} else if (!/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(value)) {
			} else if (!/^\d{11}$/.test(value)) {
				callback(new Error('手机号格式错误，11位数字。'));
			} else {
				callback();
			}
		};
		var validatorFamilyPhone = function (rule, value, callback) {
			if (value === '' || value == null) {
				//callback(new Error('电话号码不能为空'))
				callback();
			} else if (!/^(0\d{2}-\d{8}(-\d{1,4})?)|(0\d{3}-\d{7,8}(-\d{1,4})?)$/.test(value)) {
				callback(new Error('电话号码格式错误，区号-号码。'));
			} else {
				callback();
			}
		};

		return {
			ActiveNameList: [
				'accountInfo',
				'hrInfo',
				'baseInfo',
				'contactInfo',
				'healthReport',
				'leavingCertificate',
				'eduInfo',
				'languageLevel',
				'spouseInfo',
				'soldierInfo',
				'partyInfo',
				'familySocialRelations',
				'certificates',
				'previousPositions',
				'skills',
				'trainExps',
				'archiveInfo',
				'selfInfo',
				'otherInfo',
				'workExps',
			],
			storePassword:"",
			activeNames: ['accountInfo','baseInfo'],
			companyOptions: [],
			companyList: [],
			departmentOptions: [],
      		positionList:[],
			positionOptions: [],
			//
			postLevelOptions: [],
			// 角色选项
     		 roleOptions: [],
			//推荐人列表
			recommenderList:[],

			// 表单参数
			form: {},
			idCardBirthday: '',
			idCardSex: '',
			saving: false,
			formReadOnly: false,
			reloading: false,
			locked: false,
			showIdPrinting:false,
			disableLockButton:false,
			enableAdd:this.canAdd,
			nameListLoading:false,
			disabledControl:true,
			imageUrl: '',
			employeeHealthes: [
				{ value: '一般', label: '一般' },
				{ value: '良好', label: '良好' },
				{ value: '较差', label: '较差' },
			],
			employeeStatuses: DICT_TYPE.employeeStatuses,

			employeeTypes: [
				{ value: '管理人员', label: '管理人员' },
				{ value: '文职', label: '文职' },
				{ value: '员工', label: '员工' },
				{ value: '临聘工', label: '临聘工' },
				{ value: '其它', label: '其它' },
			],
			employmentTypes: [
				{ value: '聘用制', label: '聘用制' },
				{ value: '临时工', label: '临时工' },
				{ value: '劳务工', label: '劳务工' },
				{ value: '小时工', label: '小时工' },
				{ value: '定量外包', label: '定量外包' },
				{ value: '其它', label: '其它' },
			],
			iDTypes: [
				{ value: '身份证', label: '身份证' },
				{ value: '军官证', label: '军官证' },
				{ value: '学生证', label: '学生证' },
				{ value: '护照', label: '护照' },
				{ value: '其它', label: '其它' },
			],
			marriageStatuses: [
				{ value: '未婚', label: '未婚' },
				{ value: '已婚', label: '已婚' },
				{ value: '离异', label: '离异' },
				{ value: '其它', label: '其它' },
			],
			retiredTypes: [
				{ value: '正常退休', label: '正常退休' },
				{ value: '特殊工种退休', label: '特殊工种退休' },
				{ value: '病退休', label: '病退休' },
			],
			urgentContact: {
				name: null,
				phone: null,
				mobile: null,
				addr: null,
			},
			collapseStatus: 'open',
			tempPersonalPhotoUrl:"",
			tempIdCardFrontPicture:"",
			tempIdCardBackPicture:"",
			photoLoading:false,
			idCardFrontLoading:false,
			idCardBackLoading:false,

			// 表单校验
			rules: {
        departmentId: [
          {
            required: true,
            message: "所属部门不能为空,必须选择部门", trigger: "blur" },
        ],
        positionId: [
          {
            required: true,
            message: "所属岗位不能为空,必须选择岗位", trigger: "blur" },
        ],
				num: [
					{
						required: true,
						message: '岗位编号不能为空',
						trigger: 'blur',
					},
				],
				homePhone: [{ required: false, validator: validatorFamilyPhone, trigger: 'blur' }],
				workPhone: [{ required: false, validator: validatorFamilyPhone, trigger: 'blur' }],
				'urgentContact.mobile': [{ required: false, validator: validatorMobilePhone, trigger: 'blur' }],
				'urgentContact.phone': [{ required: false, validator: validatorFamilyPhone, trigger: 'blur', type: 'String' }],
				name: [{ required: true, message: '必需填写。', trigger: 'blur' }],
				nation: [{ required: true, message: '必需填写。', trigger: 'blur' }],
				idType: [{ required: true, message: '必需填写。', trigger: 'blur' }],
				// idNum:[{required:true, validator:(rule,value,callback)=>{
				//                 this.validateID(rule,value,callback,this.form);
				//             }, trigger:'blur'}]
			},
		};
	},
	created() {
		console.log('create view');
		this.load();
	},
	mounted() {},
	computed:{
		collapseSetting(){
			return function(){
				let filledCompleted = true;
				for (let key of Object.keys(this.form)) {
					let propValue = this.form[key];
					if(propValue == undefined ||  propValue == null || propValue == "" )
					{
						filledCompleted = false;
						break;
					}
				}

				if(filledCompleted)
				{
					return {callpse_header_fill:true};
				}
				else
				{
					return {callpse_header_unfill:true};
				}

			}
		},
		accountorSetting(){
			return function(){
				if(this.form.id)
				{
					return {callpse_header_fill:true};
				}
				else{
					return {callpse_header_unfill:true};
				}
			}
		},
		otheInfoCollapseSetting(){
			return function(){
				if(this.form.otherInfo!= null && this.form.otherInfo.length > 0 )
				{
					return {callpse_header_fill:true};
				}
				else
				{
					return {callpse_header_unfill:true};
				}

			}
		}
	},
	methods: {
		unlockEmployee()
		{
			this.locked=false;
		},
		handleChange(val) {
			console.log(val);
		},
		load() {
			// if(this.opType == 'self'){
			//   this.activeNames = ['baseInfo','contactInfo','eduInfo','workSkillInfo','archInfo','selfInfo','otherInfo'];
			// }else if(this.opType == 'hr'){
			//   this.activeNames = ['hrInfo'];
			// }
			// this.activeNames = [];
			console.log('load ...');
			this.getDicts('org_position_level').then((response) => {
				this.postLevelOptions = response.data;
			});
			getTreeselectCompany(null, (list, options) => {
				this.companyList = list;
				this.companyOptions = options;
			});
			if (this.departmentOptionsIn) {
				this.departmentOptions = this.departmentOptionsIn;
			} else {
				this.departmentOptions = [];
				this.getDepartment(this.companyId);
			}
			if (this.positionOptionsIn) {
				this.positionOptions = this.positionOptionsIn;
			} else {
				this.positionOptions = [];
				this.getPositison(this.companyId);
			}

			if (this.row && this.row.id) {
				this.handleUpdate(this.row);
				this.disableLockButton = false;
			} else {
				this.handleAdd(this.row);
				this.disableLockButton = true;
			}
			// json 字段没用值必须初识化对象
			if (!this.form.urgentContact) {
				this.form.urgentContact = this.urgentContact;
			}
			if(!this.form.user)
			{
				this.form.user = {};
			}
			this.formReadOnly = this.isReadOnly;
			console.log('set is read only =' + this.formReadOnly);
			console.log(this.form);
		},

		companyFormat(row, column) {
			// console.log(row.departmentId);
			for (var i = 0; i < this.companyList.length; i++) {
				const item = this.companyList[i];
				// console.log("id:"+item.id+",depid:"+row.departmentId+", name :"+item.name+",ee="+(item.id == row.departmentId));
				if (item.id == row.companyId) {
					return item.companyName;
				}
			}
			return '';
		},
		/** 转换部门数据结构 */
		normalizerDepartment(node) {
			return this.normalizer(node);
		},

		/** 查询公司下拉树结构 */
		getDepartment(companyId) {
			getTreeselectDepartment(companyId, false, (list, options) => {
				this.departmentList = list;
				this.departmentOptions = options;
			});
		},
		normalizerPosition(node) {
			return this.normalizer(node);
		},
		/** 查询岗位下拉树结构 */
		getPositison(companyId) {
			getTreeselectPosition(companyId, false, (list, options) => {
				this.positionList = list;
				this.positionOptions = options;
			});
		},

		// 取消按钮
		cancel() {
			this.$emit('close', { refresh: false, isSuccess: true });
		},
		// 表单重置
		reset() {
			this.form = {};
			this.resetForm('form');
		},

    chooseDTChange(){
			this.$forceUpdate();
		},
		getRoles(){
			return listEntryRole().then(response => {
				console.log(response.data);
				this.roleOptions = response.data;
				return response;
			});
		},

		/** 新增按钮操作 */
		handleAdd(row) {
			this.reset();
			this.form.companyId = row.companyId;
			this.reloading = true;
      		this.getRoles();
		},
		/** 修改按钮操作 */
		handleUpdate(row) {
			this.reset();
			getEmploy(row.id).then((response) => {
				this.form = response.data;
				this.locked = this.form.locked;
				this.idCardBirthday = response.data.idBridthDay;
				this.idCardSex = response.data.sex;
				// json 字段没用值必须初识化对象
				if (!this.form.urgentContact) {
					this.form.urgentContact = this.urgentContact;
				}
				this.reloading = true;
				this.downloadPersonalPhoto();
				this.downloadIdCardFrontPicture();
				this.downloadIdCardBackPicture();
				console.debug(this.form);
			});
		},
		openAllCollapses() {
			this.activeNames = []; // 注意：由于每点开一个的单独面板 activeName都会发生变化，所以点击全部展开的时候要将activeName置空
			this.activeNames = this.ActiveNameList;
		},
		closeAllCollapses() {
			this.activeNames = [];
		},

		validateID(rule, value, callback, idType) {
			// 身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X
			let reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
			if (value === '' || value == null) {
				callback(new Error('证件号码不能为空'));
			} else {
				if (idType == '身份证') {
					if (!reg.test(value)) {
						callback(new Error('身份证号码不正确'));
					} else {
						this.analysisID(value);
						callback();
					}
				} else {
					callback();
				}
			}
		},
		analysisID(val) {
			// 实现自动生成生日，性别，年龄
			let iden = val;
			let sex = null;
			let birth = null;
			let myDate = new Date();
			let month = myDate.getMonth() + 1;
			let day = myDate.getDate();
			let age = 0;
			if (this.form.idType == '身份证') {
				if (val.length === 18) {
					age = myDate.getFullYear() - iden.substring(6, 10) - 1;
					sex = iden.substring(16, 17);
					birth = iden.substring(6, 10) + '-' + iden.substring(10, 12) + '-' + iden.substring(12, 14);
					if (iden.substring(10, 12) < month || (iden.substring(10, 12) == month && iden.substring(12, 14) <= day)) age++;
				}
				if (val.length == 15) {
					age = myDate.getFullYear() - iden.substring(6, 8) - 1901;
					sex = iden.substring(13, 14);
					birth = '19' + iden.substring(6, 8) + '-' + iden.substring(8, 10) + '-' + iden.substring(10, 12);
					if (iden.substring(8, 10) < month || (iden.substring(8, 10) == month && iden.substring(10, 12) <= day)) age++;
				}
				if (birth != null) {
					if (sex % 2 === 0) sex = '女';
					else sex = '男';
					//this.form.sex = sex;
					//this.data.form.age = age;
					//this.form.idBridthDay = birth;
					this.idCardBirthday = birth;
					this.idCardSex = sex;
				}
			}
		},
		/** 提交按钮 */
		submitForm() {
			this.$refs['form'].validate((valid) => {
				if (valid) {
					if (this.form.id != null) {
						this.$emit('update', { data: this.form });
					} else {
						// addPosition(this.form).then(response => {
						//   this.msgSuccess("新增成功");
						//   this.$emit("close", {refresh: true, isSuccess: true});
						// });
					}
				}
			});
		},
		saveBasicInfo() {
			//   this.submitForm();
			console.log('saveBasicInfo');
			let self = this;
			this.$refs['form'].validate((valid) => {
				if (valid) {
					let basicInfo = {};
					basicInfo.id = self.form.id;
					basicInfo.photo = self.tempPersonalPhotoUrl;
					basicInfo.idCardFrontPicture = self.tempIdCardFrontPicture;
					basicInfo.idCardBackPicture = self.tempIdCardBackPicture;


					// basic
					basicInfo.name = self.form.name;
					basicInfo.formerName = self.form.formerName;
					basicInfo.nation = self.form.nation;

					basicInfo.idType = self.form.idType;
					basicInfo.idNum = self.form.idNum;
					basicInfo.sex = self.idCardSex;
					basicInfo.idBridthDay = self.idCardBirthday;
					basicInfo.nativePlace = self.form.nativePlace;
					basicInfo.marritalStatus = self.form.marritalStatus;
					//basicInfo.helth = self.form.helth;
					basicInfo.birthAddr = self.form.birthAddr;
					basicInfo.workStartDate = self.form.workStartDate;
					basicInfo.householdAddr = self.form.householdAddr;
					basicInfo.householdType = self.form.householdType;

					// hr
					basicInfo.companyId = self.form.companyId;
					basicInfo.departmentId = self.form.departmentId;
					basicInfo.positionId = self.form.positionId;
					basicInfo.curPositionStartDate = self.form.curPositionStartDate;
					basicInfo.paidHolidayStartDate = self.form.paidHolidayStartDate;
					basicInfo.workNum = self.form.workNum;
					basicInfo.joinGroupDate = self.form.joinGroupDate;
					basicInfo.joinCompanyDate = self.form.joinCompanyDate;
					basicInfo.paidHolidayStar = self.form.paidHolidayStartDate;
					basicInfo.empStatus = self.form.empStatus;
					basicInfo.empType = self.form.empType;
					basicInfo.empWorkType = self.form.empWorkType;
					basicInfo.retiredType = self.form.retiredType;
					basicInfo.retiredDate = self.form.retiredDate;
					basicInfo.leaveReason = self.form.leaveReason;
					basicInfo.leaveDate = self.form.leaveDate;
					basicInfo.officalDate = self.form.officalDate;
					basicInfo.password = self.storePassword;
					basicInfo.username = self.form.user.username;
					basicInfo.mobile = self.form.user.mobile;

					// contact information
					basicInfo.workPhone = self.form.workPhone;
					basicInfo.homePhone = self.form.homePhone;
					basicInfo.currentAddr = self.form.currentAddr;
					basicInfo.urgentContact = {};
					basicInfo.urgentContact.name = self.form.urgentContact.name;
					basicInfo.urgentContact.mobile = self.form.urgentContact.mobile;
					basicInfo.urgentContact.phone = self.form.urgentContact.phone;
					basicInfo.urgentContact.addr = self.form.urgentContact.addr;

   
					basicInfo.bankName = self.form.bankName;
					basicInfo.bankAcct = self.form.bankAcct;
					basicInfo.isCrossBank = self.form.isCrossBank;
					basicInfo.crossBankName = self.form.crossBankName;
					basicInfo.insuranceAcct = self.form.insuranceAcct;
					basicInfo.fundAcct = self.form.fundAcct;

					// recommander
					basicInfo.recommender =self.form.recommender;
					let selectedRecommender = self.recommenderList.filter(item=>item.label==self.form.recommender);
					basicInfo.recommenderID =   selectedRecommender.value;
					basicInfo.lengthOfService = self.form.lengthOfService;
					basicInfo.recommenderBonus = self.form.recommenderBonus;

          this.saving = true;
          if(self.form.id){
			  basicInfo.photo = self.tempPersonalPhotoUrl;
            this.$emit('updatePartialEmployeeInfo', { data: basicInfo }, (response) => {
              this.saving = false;
              if (response.success) {
                this.msgSuccess('修改成功');
                this.readonly = true;
              } else {
                this.msgError('修改失败,' + response.msg);
              }
            });
          }else{
            // showLoading();
			let oldPhoto = this.form.photo;
			let oldIdCardFrontPicture = this.form.idCardFrontPicture ;
			let oldIdCardBackPicture = this.form.idCardBackPicture ;
			this.form.photo = self.tempPersonalPhotoUrl;
			this.form.idCardFrontPicture = self.tempIdCardFrontPicture;
			this.form.idCardBackPicture = self.tempIdCardBackPicture;
            this.form.addUserAndEmploy = true;
            addEmploy(this.form).then(response => {
			  this.form.photo = oldPhoto;
			  this.form.idCardFrontPicture = oldIdCardFrontPicture;
			  this.form.idCardBackPicture =oldIdCardBackPicture;
              this.saving = false;
              this.msgSuccess("添加员工成功");
              this.form.id = response.data.id;
			  this.form.user.username = response.data.username;
			  this.disableLockButton = false;
              // hideLoading();
            }).catch( err => {
              this.saving = false;
              hideLoading();
            });
          }
				} else {
					this.msgError('有非法输入或必填项未填写，请检查!');
				}
			});
		},

		saveOtherInfo() {
			let savingOtherInfo = {};
			savingOtherInfo.id = this.form.id;
			savingOtherInfo.otherInfo = this.form.otherInfo;
			this.saving = true;
			this.$emit('updatePartialEmployeeInfo', { data: savingOtherInfo }, (response) => {
				this.saving = false;
				if (response.success) {
					this.msgSuccess('修改成功');
					this.readonly = true;
				} else {
					this.msgError('修改失败,' + response.msg);
				}
			});
		},

		uploadIdCardFrontPicture(params) {
				this.formData = new FormData();
				this.formData.append('file', params.file);
				let self = this;
				uploadIdCardPicture(this.formData)
					.then((response) => {
						if (response.status == 200) {
							let resData = response.data;
							self.tempIdCardFrontPicture= response.headers["content-disposition"].split('=')[1];
							var blob = new Blob([resData], { type: 'application/octet-stream' });
							self.form.idCardFrontPicture =URL.createObjectURL(blob);
							this.$forceUpdate()
							this.$message.success('上传成功!');
						}
						else{
							this.$message.error('上传失败：');
						}
					})
					.catch((err) => {
						this.$message.error('上传失败：'+err);

					});
		},
		uploadIdCardBackPicture(params) {
				this.formData = new FormData();
				this.formData.append('file', params.file);
				let self = this;
				uploadIdCardPicture(this.formData)
					.then((response) => {
						if (response.status == 200) {
							let resData = response.data;
							self.tempIdCardBackPicture= response.headers["content-disposition"].split('=')[1];
							var blob = new Blob([resData], { type: 'application/octet-stream' });
							self.form.idCardBackPicture =URL.createObjectURL(blob);
							this.$forceUpdate()
							this.$message.success('上传成功!');
						}
						else{
							this.$message.error('上传失败：');
						}
					})
					.catch((err) => {
						this.$message.error('上传失败：'+err);

					});
		},
		uploadPersonalPhoto(params) {
			this.formData = new FormData();
			if(this.form.id == undefined || this.form.id==null || this.form.id=="")
			{
				this.formData = new FormData();
				this.formData.append('file', params.file);
				let self = this;
				UploadPersonalPicture(this.formData)
					.then((response) => {
						if (response.status == 200) {
							let resData = response.data;
							self.tempPersonalPhotoUrl= response.headers["content-disposition"].split('=')[1];
							var blob = new Blob([resData], { type: 'application/octet-stream' });
							self.form.photo =URL.createObjectURL(blob);
							this.$forceUpdate()
							this.$message.success('上传成功!');
						}
						else{
							this.$message.error('上传失败：');
						}
					})
					.catch((err) => {
						this.$message.error('上传失败：'+err);

					});
			}
			else
			{
				this.formData.append('employId', this.form.id);
				this.formData.append('file', params.file);

				this.loading = true;
				let self = this;
				uploadPersonalPhoto(this.formData)
					.then((response) => {
						self.loading = false;
						if (response.status == 200) {
							let resData = response.data;
							self.tempPersonalPhotoUrl= response.headers["content-disposition"].split('=')[1];
							var blob = new Blob([resData], { type: 'application/octet-stream' });
							self.form.photo = URL.createObjectURL(blob);
							this.$forceUpdate()
							this.msgSuccess('上传成功!');
						} else {
							this.$message.error('上传失败：');
						}
					})
					.catch((err) => {
						self.loading = false;
						this.$message.error('上传失败：' + err);
					});
			}

		},

		handleAvatarSuccess(res, file) {
			//this.form.photo = URL.createObjectURL(file.name);
		},
		beforeAvatarUpload(file) {
			const isJPG = file.type === 'image/jpeg' || file.type === 'image/jpg';
			const isPng = file.type === 'image/png';
			const isBmp = file.type === 'application/bmp';
			const ispdf = file.type === 'application/pdf';
			const isLt50M = file.size / 1024 / 1024 < 50;
			if (!isJPG && !isPng && !ispdf && !isBmp) {
				this.$message.error('上传图片只能是 JPG,JPEG,PNG,BMP,PDF 格式!');
			}
			if (!isLt50M) {
				this.$message.error('上传图片大小不能超过 50MB!');
			}
			return (isJPG || isPng || ispdf || isBmp) && isLt50M;
		},
		downloadPersonalPhoto() {
			if (this.form.photo != null && this.form.photo.length > 0) {
				// download
				this.tempPersonalPhotoUrl = this.form.photo;
				this.photoLoading = true;
				downloadPersonalPhoto(this.form.photo)
					.then((res) => {
						this.photoLoading = false;
						if (res) {
							let resData = res.data;
							var blob = new Blob([resData], { type: 'application/octet-stream' });
							this.form.photo = URL.createObjectURL(blob);
							
						} else {
							this.msgError(`后端下载文件出错 & ${e}`);
						}
					})
					.catch((e) => {
						this.photoLoading = false;
						this.msgError(`后端下载文件出错 & ${e}`);
					});
			}
		},
		downloadIdCardFrontPicture() {
			if (this.form.idCardFrontPicture != null && this.form.idCardFrontPicture.length > 0) {
				// download
				this.tempIdCardFrontPicture = this.form.idCardFrontPicture;
				this.idCardFrontLoading = true;
				downloadPersonalPhoto(this.form.idCardFrontPicture)
					.then((res) => {
						this.idCardFrontLoading = false;
						if (res) {
							let resData = res.data;
							var blob = new Blob([resData], { type: 'application/octet-stream' });
							this.form.idCardFrontPicture = URL.createObjectURL(blob);
						} else {
							this.msgError(`后端下载文件出错 & ${e}`);
						}
						
					})
					.catch((e) => {
						this.idCardFrontLoading = false;
						this.msgError(`后端下载文件出错 & ${e}`);

					});
			}
		},
		downloadIdCardBackPicture() {
			if (this.form.idCardBackPicture != null && this.form.idCardBackPicture.length > 0) {
				// download
				this.tempIdCardBackPicture = this.form.idCardBackPicture;
				this.idCardBackLoading=true;
				downloadPersonalPhoto(this.form.idCardBackPicture)
					.then((res) => {
						this.idCardBackLoading=false;
						if (res) {
							let resData = res.data;
							var blob = new Blob([resData], { type: 'application/octet-stream' });
							this.form.idCardBackPicture = URL.createObjectURL(blob);
						} else {
							this.msgError(`后端下载文件出错 & ${e}`);
						}
						
					})
					.catch((e) => {
						this.idCardBackLoading=false;
						this.msgError(`后端下载文件出错 & ${e}`);
					});
			}
		},
		unlockEmployee(){

			if(this.form.id != undefined || this.form.id != null || this.form.id!="")
			{
				let self = this;
				this.saving=true;
				unlockEmployee(this.form.id).then((response) => {
					this.saving = false;
				if(response.code==200)
				{
					self.formReadOnly = false;
					self.locked=false;
					this.form.locked = false;
					self.msgSuccess('解锁成功!');
				}
				else
				{
					self.msgError(response.msg);
				}
			}).catch(err=>
				{
					this.saving=false;
					this.msgError(err);
					this.msgError(`后端下载文件出错 & ${e}`);
				}


			);
			}
			else{
				this.msgError("当前的员工ID为空");
			}

		},
		lockEmployee(){

			if(this.form.id != undefined || this.form.id != null || this.form.id!="")
			{
				let self = this;
				this.saving=true;
				lockEmployee(this.form.id).then((response) => {
				this.saving=false;
				if(response.code==200)
				{
					//self.formReadOnly = true;
					self.locked = true;
					this.form.locked = true;
					self.$message.success('锁定成功!');
				}
				else
				{
					self.msgError(response.msg);
				}
			}).catch(err=>
				{
					this.saving = false;
					this.msgError(`后端下载文件出错 & ${err}`);
				}


			);
			}
			else{
				this.msgError("当前的员工ID为空");
			}
		},
		findRecommander(recommender)
		{
			let that = this;
			if (recommender!=null && recommender.length > 0 ) {
				that.nameListLoading = true;
				findRecommenderList(recommender,this.form.companyId).then(
					(response)=>{
						if(response.code==200){
							that.nameListLoading = false;
							let recommenders = response.data;
							if (recommenders != null && recommenders != undefined && recommenders.length > 0) {
								recommenders.forEach(element => {
									that.recommenderList.push({value:element.id,label:element.name+'('+element.idNum+')'});
								});
							}
						}
					}
				).catch(err=>
					{
						that.nameListLoading = false;
						this.msgError(`后端下载文件出错 & ${err}`);
					}
				);
			}
			else
			{
				that.recommenderList=[];
			}
		},
		OpenIdCardPrintingDialog(){
			this.showIdPrinting = true;
		},
		ClosseIdCardPrintingDialog(arg){
			this.showIdPrinting = false;
		},
		changeFundMode()
		{
			if(this.locked){
				return true;
			}
			else
			{
				if(this.form.isf)
				{
					return true;
				}
				else{
					return false;
				}
			}
		},
		changeCrossBank()
		{
			if(this.locked){
				return true;
			}
			else
			{
				if(this.form.isCrossBank)
				{
					return true;
				}
				else{
					return false;
				}
			}

			
		}

	},
};
</script>


<style scoped>
a:hover {
	color: white;
}

.el-loading-spinner{
  text-align: center;
}
/* a:visited{
  color:#409EFF;
} */
</style>
