<template>
	<div>
		<div style="font-size: large; margin-bottom: 5px">面试流程定义</div>
		<div style="height: 300px">
			<el-form label-width="100px" :model="interviewProcess" ref="form">
				<el-row>
					<el-form-item label="流程名称" prop="processName">
						<el-input v-model="interviewProcess.processName" clearable style="width: 150px"
							placeholder="请输入流程名称" />
					</el-form-item>
					<el-steps direction="vertical">
						<el-step v-for="(item, index) in interviewProcess.process" :key="index"
							:title="item.processName">
							<template slot="title">
								<el-input v-model="item.stepName" clearable style="width: 150px"
									placeholder="请输入面试步骤名称" />
								<el-button type="text" plain icon="el-icon-delete" style="margin-left: 15px" size="mini"
									@click="handleDelete(item.stepName)">删除
								</el-button>
							</template>
							<template slot="description">
								<el-input v-model="item.stepContent" :autosize="{ minRows: 4, maxRows: 10 }"
									maxlength="1000" show-word-limit type="textarea" clearable placeholder="请输入面试内容。" />
							</template>
						</el-step>
					</el-steps>
					<el-form-item label="" label-width="40px">
						<el-button type="primary" plain icon="el-icon-plus" size="mini" @click="handleAdd">增加
						</el-button>
					</el-form-item>
				</el-row>
				<el-row>
					<el-form-item label="" style="text-align: center">
						<el-button v-if="this.newProcess" :loading="submitLoading" class="mb10" plain type="primary"
							@click="submitProcess">提交</el-button>
						<el-button v-else :loading="submitLoading" class="mb10" plain type="primary"
							@click="editProcess">更新</el-button>
						<el-button plain type="primary" @click="cancel">关闭</el-button>
					</el-form-item>
				</el-row>
			</el-form>
		</div>
	</div>
</template>


<script>
import { addInterViewProcess, editInterViewProcess } from '@/api/recruit/interviewProcess';
export default {
	name: 'InterviewProcessEdit',

	data() {
		let initData = {};


		if (this.currentProcess) {
			initData = this.currentProcess;
		}
		else {
			initData.processName = "";
			initData.process = new Array();
			initData.process.push({
				stepName: '自定义名称',
				stepContent: '',
			});
		}
		return {
			interviewProcess: initData,
			submitLoading: false,
		};
	},
	props: {
		currentProcess: Object,
		newProcess: Boolean,
	},
	mounted() { },

	methods: {
		handleAdd() {
			this.interviewProcess.process.push({ stepName: '自定义名称', stepContent: '' });
		},
		handleDelete(stepName) {
			if (stepName) {
				let foundIndex = this.interviewProcess.process.findIndex((x) => x.stepName === stepName);
				if (foundIndex > -1) {
					this.interviewProcess.process.splice(foundIndex, 1);
				}
			} else {
				this.msgError('流程名称为空，不能删除掉，重新刷新。');
			}
		},
		submitProcess() {
			let that = this;
			this.submitLoading = true;
			this.$refs['form'].validate((valid) => {
				if (valid) {
					addInterViewProcess(that.interviewProcess)
						.then((response) => {
							that.submitLoading = false;
							that.msgSuccess('增加面试流程成功。');
						})
						.catch((err) => {
							that.submitLoading = false;
							that.msgError('提交失败，' + err);
						});
				} else {
					that.submitLoading = false;
					that.msgError('有非法输入或必填项未填写，请检查!');
				}
			});
		},
		editProcess() {
			let that = this;
			this.submitLoading = true;
			this.$refs['form'].validate((valid) => {
				if (valid) {
					editInterViewProcess(that.interviewProcess)
						.then((response) => {
							that.submitLoading = false;
							that.msgSuccess('更新面试流程成功。');
						})
						.catch((err) => {
							that.submitLoading = false;
							that.msgError('更新失败，' + err);
						});
				} else {
					that.submitLoading = false;
					that.msgError('有非法输入或必填项未填写，请检查!');
				}
			});
		},
		cancel() {
			this.$emit('close', { refresh: this.newProcess, isSuccess: true });
		},
	},
};
</script>

<style lang="less" scoped>
/deep/ .el-step__description {
	padding-right: 0px !important;
	margin-bottom: 15px;
}
</style>