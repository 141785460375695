import request from '@/utils/request'

// 查询班次管理列表
export function listKpiEmploy(query) {
    return request({
        url: '/kpi/kpiEmploy/findAll',
        method: 'post',
        data: query
    })
}

// 查询班次管理详细
export function getKpiEmploy(id) {
    return request({
        url: '/kpi/kpiEmploy/findOne?id=' + id,
        method: 'post'
    })
}

// 新增班次管理
export function addKpiEmploy(data) {
    return request({
        url: '/kpi/kpiEmploy/add',
        method: 'post',
        data: data
    })
}

// 修改班次管理
export function updateKpiEmploy(data) {
    return request({
        url: '/kpi/kpiEmploy/edit',
        method: 'post',
        data: data
    })
}

export function saveKpiEmpScore(data) {
    return request({
        url: '/kpi/kpiEmploy/saveKpiEmpScore',
        method: 'post',
        data: data
    })
}

export function updateSpecialWeight(data) {
    return request({
        url: '/kpi/kpiEmploy/updateSpecialWeight',
        method: 'post',
        data: data
    })
}
// 删除班次管理
export function delKpiEmploy(id) {
    return request({
        url: '/kpi/kpiEmploy/remove',
        method: 'post',
        data: id
    })
}

// 导出班次管理
export function exportKpiEmploy(query) {
    return request({
        url: '/kpi/kpiEmploy/export',
        method: 'post',
        data: query
    })
}
