<template>
  <div>
    <div class="camera" @click="camera">+ 拍摄现场照片</div>
    <div class="button">
      <el-button type="primary" class="submit" @click="addForm">提交巡检数据</el-button>
    </div>
    <div>
      <img v-if="fileSrc" id="image1" :src="fileSrc"  class="avatar"/>
      <template v-for="file in scanFileArr">
        <img :src="file.src"  :key="file.src" class="avatar"/>
      </template>
      <canvas id="canvas" width="320" height="295"></canvas>
    </div>
  </div>
</template>

<script>
export default {
  name: "records.vue",
  data() {
    return {
      fileSrc: null,
      scanFileArr:[],
    }
  },
  methods:{
// 调用摄像头
    camera() {
      let that = this;
      // that.$alert("PAIZ")
      var cmr = plus.camera.getCamera(); //获取摄像头管理对象
      var res = cmr.supportedImageResolutions[0]; //字符串数组，摄像头支持的拍照分辨率
      var fmt = cmr.supportedImageFormats[0]; //字符串数组，摄像头支持的拍照文件格式
      console.log("拍照分辨率: " + res + ", 拍照文件格式: " + fmt);
      cmr.captureImage(function(path) {
        console.log("captureImage path=")
        console.log(path)
        //进行拍照操作
        // 通过URL参数获取目录对象或文件对象
        plus.io.resolveLocalFileSystemURL(path, function(entry) {
          console.log("resolveLocalFileSystemURL entry=")
          console.log(entry)

          var tmpPath = entry.toLocalURL(); //获取目录路径转换为本地路径URL地址
          that.fileSrc = tmpPath
          that.$alert(tmpPath)
          // that.$alert(entry)
          // that.setImg(that.fileSrc);
        });
      })
    },

// 对图片进行适当处理
    setImg(fileSrc) {
      let that = this;
      var name = JSON.stringify(fileSrc).split('/')
      var file_name = name[name.length - 1].split('.')[0]
      plus.zip.compressImage(
          {
            src: fileSrc,
            dst: "_doc/" + file_name + ".jpg",    //定义图片地址
            overwrite: true,  //是否覆盖原来文件
            //width: '270px',  //图片宽度 设置则表示缩放
            //height:'270px',  //图片高度 设置则表示缩放
            format: 'jpg',  //图片压缩后的格式
            quality: 50   //图片压缩的质量 最高100 默认50
          },
          function(e) {
            var image = new Image();
            image.src = e.target;
            image.width = e.width;
            image.height = e.height;
            image.size = e.size;
            image.index = index;
            that.scanFileArr.push(image);
          },
          function(err) {
            plus.nativeUI.alert('未知错误！', function() { });
          }
      )
    },
    // 提交巡检数据
    addForm() {
      let that = this
      if(that.fileSrc == ''){
        this.$message('上传附件不能为空!');
        return
      }
      // 请求上传管理创建新的上传任务，创建成功则返回Upload对象，用于管理上传任务。
      var task = plus.uploader.createUpload(that.api+ "接口地址",{ method:"POST"},
          function ( t, status ) {
            //  解析上传成功返回的数据
            that.uploaderBackData = JSON.parse( t.responseText)
            that.uploadFileArr = []
            that.uploadFileArr.push(that.uploaderBackData.file_guid)
            // 上传完成
            if ( that.uploaderBackData.status == 200 ) {
              // 图片上传完成，可进行下一步的操作（可以进行添加一类的操作）
              if(that.note == ''){
                MessageBox.alert('巡检描述不能为空!').then(action => {});
                return
              }
            } else {
              MessageBox.alert('图片上传失败!').then(action => {});
            }
          });
      // 向上传任务中添加文件，必须在任务开始上传前调用。
      // 第一个参数是文件上传的路径，支持本地的文件，可以将刚才拍照得到的路径放在这里
      // 第二个参数是要添加上传文件的参数
      task.addFile( that.fileSrc, {key:"files"} );
      // 向上传任务中添加数据，必须在任务开始上传前调用
      task.addData("current_project_id", that.project_id);
      // 开始上传任务
      task.start();
    },

  }
}
</script>

<style scoped>

</style>
