<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" >
      <el-form-item label="巡逻日期" :label-width="uniApp? '120px':'75px'" >
        <el-date-picker type="date"  clearable size="small" v-model="queryParams.clockTimeStart" value-format="yyyy-MM-dd" placeholder="选择开始时间"/>
        至
        <el-date-picker type="date"   clearable size="small" v-model="queryParams.clockTimeEnd" value-format="yyyy-MM-dd" placeholder="选择结束时间"/>
      </el-form-item>
      <el-form-item label="名称说明" prop="name">
        <el-input v-model="queryParams.name" placeholder="请输入名称说明" clearable  @keyup.enter.native="handleQuery" />
      </el-form-item>
      <el-form-item label="员工姓名" label-width="75px">
        <el-input v-model="queryParams.empName" placeholder="请输入员工姓名" clearable size="small" @keyup.enter.native="handleQuery"/>
      </el-form-item>
      <el-form-item label="部门" label-width="75px">
        <treeselect v-model="queryParams.depId" :options="departmentOptions" :multiple="true" :flat="true"
                    :normalizer="normalizerDepartment" placeholder="请选择部门" style="min-width:205px;" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>
    </el-row>

    <el-table v-loading="loading" :data="attClockTimeList" @selection-change="handleSelectionChange" stripe border>
      <el-table-column type="selection" width="55" align="center"/>
      <!--      <el-table-column label="公司名称" align="center" prop="companyId"/>-->
      <el-table-column label="部门名称" align="center" prop="depName" width="200px">
        <template slot-scope="scope">{{ getDepartmentName(scope.row.depId)}}</template>
      </el-table-column>
      <el-table-column label="名称说明" align="center" prop="name"/>
      <el-table-column label="员工姓名" align="center" prop="empName" width="100px"/>
      <el-table-column label="巡逻日期" align="center" prop="patrolDate" width="180px"/>
      <el-table-column label="状态" align="center" prop="status" width="80px"/>
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width" width="110px">
        <template slot-scope="scope">
          <el-button size="mini" type="text"
                     icon="el-icon-delete" @click="handleView(scope.row)">详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
        v-show="total>0"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
    />

    <el-dialog :title="title" :visible.sync="open" append-to-body :width="editDialogSize">
      <Detail :row="row" @close="close"></Detail>
    </el-dialog>

  </div>
</template>

<script>
import {listAttPatrolClock} from "@/api/attendance/attPatrolClock";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import RightToolbar from "@/components/RightToolbar"
import permission from '@/directive/permission/index.js' // 权限判断指令
import Pagination from "@/components/Pagination";
import {checkRole} from "@/utils/role";
import {getTreeselectDepartment} from "../../../../api/org/department";
import {fileDownload} from "@/utils/fileUtils";
import {download} from "@/api/comapi/comapi.js";
import { showLoading, hideLoading } from '@/utils/reqloading';
import {listEmploy} from "@/api/employ/employ";
import {Local} from '@/utils/storage.js'
import Detail from './detail';

export default {
  name: "index",
  components: {
    Treeselect,
    RightToolbar,
    Pagination,
    Detail,
  },
  directives: { permission },
  data() {
    return {
      row: null,
      fileList:[],
      employs:[],
      searchLoading: false,
      // 遮罩层
      loading: true,
      // 查询参数
      where: {
        query: '',
        filters: []
      },

      uniApp:false,

      // 部门管理树选项
      departmentList: [],
      departmentOptions: [],

      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 打卡记录表格数据
      attClockTimeList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      openStatistic: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        companyId: null,
        depId: null,
        empName: null,
        name: null,
        clockTimeStart: null,
        clockTimeEnd: null,
        addr: null,
        wifi: null,
        machine: null,
        photo: null,
        photoAddr: null,
      },
      // 表单参数
      form: {} ,
      // 表单校验
      rules: {
        clockTime: [
          {
            required: true,
            message: "打卡时间不能为空", trigger: "blur" }
        ],
        empName: [
          {
            required: true,
            message: "姓名不能为空", trigger: "blur" }
        ],
      },
      editDialogSize:"80%",
    };
  },
  created() {
    this.queryParams.companyId = this.$store.getters.userInfo.companyId;
    getTreeselectDepartment(null,false, (list,options)=>{
      this.departmentList = list;
      this.departmentOptions = options;
    })
    this._filter = {};
    this.getList();
    this.uniApp=Local.get("isUniapp")?true:false;
    let uniapp =Local.get("isUniapp")?true:false;
    if(uniapp)
    {
      this.editDialogSize="90%";
    }
  },
  methods: {
    handleView(row){
      this.row = row;
      this.open = true;
      this.title = "巡逻记录";
    },
    close(arg){
      this.open = false;
      if(arg.refresh){
        //this.getList();
      }
    },
    /** 查询打卡记录列表 */
    getQuery(){
      this._filter = {};
      // 人事和经理可以查看所有人申请，员工只能看自己申请
      const role = ["Admin","GM","HRManager","CAdmin","Attence"];
      if(!checkRole(role)){
        this._filter['userId'] = {
          type: 'eq',
          value: this.$store.getters.userInfo.id,
        }
      }
      // if(this.queryParams.companyId) {
      //   this._filter['companyId'] = {
      //     type: 'eq',
      //     value: this.queryParams.companyId,
      //   }
      // }
      if(this.queryParams.name) {
        this._filter['name'] = {
          type: 'search',
          value: this.queryParams.name,
        }
      }
      if(this.queryParams.empName) {
        this._filter['empName'] = {
          type: 'search',
          value: this.queryParams.empName,
        }
      }
      if(this.queryParams.clockTimeStart && this.queryParams.clockTimeEnd) {
        this._filter['patrolDate'] = {
          type: 'date',
          value: [this.queryParams.clockTimeStart,this.queryParams.clockTimeEnd],
        }
      }else if(this.queryParams.clockTimeStart) {
        this._filter['patrolDate'] = {
          type: 'ge',
          value: this.queryParams.clockTimeStart,
        }
      }else if(this.queryParams.clockTimeEnd) {
        this._filter['patrolDate'] = {
          type: 'le',
          value: this.queryParams.clockTimeEnd,
        }
      }
      if(this.queryParams.depId) {
        this._filter['depId'] = {
          type: 'in',
          value: this.queryParams.depId,
        }
      }
      if(this.queryParams.photo) {
        this._filter['photo'] = {
          type: 'search',
          value: this.queryParams.photo,
        }
      }

      const filters = this.parseFilters(this._filter)
      if (filters && filters.length > 0) {
        this.where.filters = filters
      } else {
        // 如果过滤条件为空，设置空数组
        this.where.filters = []
      }
      const params = {
        pageNum: this.queryParams.pageNum,
        pageSize: this.queryParams.pageSize,
        where: this.where,
        order: ' id desc '
      }

      return params;
    },
    getList() {
      this.loading = true;
      const params = this.getQuery();
      listAttPatrolClock(params).then(response => {
        this.attClockTimeList = response.data.records;
        this.total = response.data.total;
        this.loading = false;
      });
    },
    normalizerDepartment(node) {
      return this.normalizer(node);
    },
    getDepartmentName(id){
      //console.log(id);
      for(let i = 0; i < this.departmentList.length; i++){
        if(id == this.departmentList[i].id) return this.departmentList[i].name;
      }
      return "";
    },

    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {};
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },
  }
};
</script>

<style scoped>

</style>
