import request from '@/utils/request'
import {handleTree} from "@/utils/tools";

// 查询公司列表, 没有父公司
export function listCompany(query) {
    return request({
        url: '/org/company/findAll',
        method: 'post',
        data: query
    })
}


// 查询公司列表，父公司和自己子公司
export function findAllWithParent(query) {
    return request({
        url: '/org/company/findAllWithParent',
        method: 'post',
        data: query
    })
}

// 查询公司列表,不控制当前账号
export function listAllCompany(query) {
    return request({
        url: '/org/company/findAllForShow',
        method: 'post',
        data: query
    })
}

// 查询公司列表，父公司和自己子公司
export function findBroCompany(id) {
    return request({
        url: '/org/company/findBroCompany?id=' + id,
        method: 'post'
    })
}

// 查询公司详细
export function getCompany(id) {
    return request({
        url: '/org/company/findOne?id=' + id,
        method: 'post'
    })
}

// 新增公司
export function addCompany(data) {
    return request({
        url: '/org/company/add',
        method: 'post',
        data: data
    })
}

// 修改公司
export function updateCompany(data) {
    return request({
        url: '/org/company/edit',
        method: 'post',
        data: data
    })
}

// 删除公司
export function delCompany(id) {
    return request({
        url: '/org/company/remove',
        method: 'post',
        data: id
    })
}

// 导出公司
export function exportCompany(query) {
    return request({
        url: '/org/company/export',
        method: 'post',
        data: query
    })
}

export function updateAvatar(formData){
    return request({
        url: '/org/company/updateAvatar',
        method: 'post',
        data: formData,
    })
}

/** 查询公司下拉树结构 */
export function getTreeselectCompany(params, callback) {
    params = params || {
        pageNum: 1,
        pageSize: 500,
        where: null,
        order: ' seq asc '
    };

    listCompany(params).then(response => {
        let companyList = response.data.records;
        let companyOptions = handleTree(response.data.records, "id", "parentId");
        callback(companyList, companyOptions);
    });
}

