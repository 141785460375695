<template>
  <div class="app-container">
    <el-tabs type="border-card">
      <el-tab-pane label="架构详情">
        <el-row :gutter="20">
          <el-col :span="8" :xs="24">
<!--            <div class="head-container">-->
<!--              <el-button type="primary" size="mini" @click="exportCount">导出缺编</el-button>-->
<!--            </div>-->
            <div class="head-container">
              <el-input
                  v-model="nameFilter"
                  placeholder="请输入名称"
                  clearable
                  size="small"
                  prefix-icon="el-icon-search"
                  style="margin-bottom: 20px"
              />
            </div>
            <div class="head-container">
              <el-tree
                  :data="treeData"
                  :props="defaultProps"
                  :expand-on-click-node="false"
                  :filter-node-method="filterNode"
                  ref="tree"
                  default-expand-all
                  @node-click="handleNodeClick"
                  highlight-current
              >
                    <span class="custom-tree-node" slot-scope="{ node, data }">
                      <span>{{ node.label }}</span>
                      <el-tooltip class="item" effect="dark" :content="'编制：'+data.planCount+'人,在编：'+data.realCount+'人,缺编：'+(data.planCount-data.realCount)+'人'" placement="top-end">
                        <span v-if="data.planCount" style="color: #c75151">{{data.realCount}}
                        </span>
                      </el-tooltip>
                      <el-tooltip class="item" effect="dark" :content="'编制：-人,在编：'+(data.realCount?data.realCount:0)+'人,缺编：-人'" placement="top-end">
                        <span v-if="!data.planCount">{{data.realCount?data.realCount:0}}</span>
                      </el-tooltip>
                    </span>
              </el-tree>
            </div>
          </el-col>
          <el-col v-if="uniApp" :span="16" :xs="24">
            <el-dialog title="详情" :visible.sync="detailVisible" width="90%" :close-on-click-modal="true">
              <div v-if="companyDetailShow">
                <CompanyEdit :row="companyRow" :isReadOnly="isReadOnly" @close="close"></CompanyEdit>
              </div>
              <div v-if="departmentDetailShow">
                <DepartmentEdit :row="departmentRow" :isReadOnly="isReadOnly" :companyId="selectedCompanyId"
                                :companyOptionsIn="companyOptions" :departmentOptionsIn="departmentOptions"
                                @close="close"></DepartmentEdit>
              </div>
              <div v-if="positionDetailShow">
                <PositionEdit :row="positionRow" :isReadOnly="isReadOnly" :departmentId="selectedDepartmentId" :companyId="selectedCompanyId"
                              :departmentOptionsIn="departmentOptions"  @close="close"></PositionEdit>
              </div>
            </el-dialog>
          </el-col>
          <el-col v-else :span="16" :xs="24">
            <el-divider>{{classTypeTitle}}详情</el-divider>
            <div v-if="companyDetailShow">
              <CompanyEdit :row="companyRow" :isReadOnly="isReadOnly" @close="close"></CompanyEdit>
            </div>
            <div v-if="departmentDetailShow">
              <DepartmentEdit :row="departmentRow" :isReadOnly="isReadOnly" :companyId="selectedCompanyId"
                              :companyOptionsIn="companyOptions" :departmentOptionsIn="departmentOptions"
                              @close="close"></DepartmentEdit>
            </div>
            <div v-if="positionDetailShow">
              <PositionEdit :row="positionRow" :isReadOnly="isReadOnly" :departmentId="selectedDepartmentId" :companyId="selectedCompanyId"
                            :departmentOptionsIn="departmentOptions"  @close="close"></PositionEdit>
            </div>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="组织架构图">
        <el-row :gutter="20">
          <el-col >
            <vo-basic style="overflow: auto" :data="chartData" :nodeContent="nodeContent" :chartClass="className"></vo-basic>
<!--            <vo-basic style="overflow: auto" :data="chartData" :chartClass="className"></vo-basic>-->
          </el-col>
        </el-row>
      </el-tab-pane>


    </el-tabs>
  </div>
</template>

<script>
import { VoBasic } from 'vue-orgchart';
import {getOrgTree} from '../components/orgtree';
import CompanyEdit from '@/views/pages/org/company/companyEdit';
import DepartmentEdit from "@/views/pages/org/department/departmentEdit";
import PositionEdit from "@/views/pages/org/position/positionEdit";
import {PARENT_COMPANY_ID} from "@/settings";
import {sumTreeChild} from "@/utils/tools";
import {Local} from '@/utils/storage.js'

export default {
  name: "OrgChart",
  components: {
    VoBasic,
    CompanyEdit,
    DepartmentEdit,
    PositionEdit,
  },
  data() {
    return {
      chartData : {},
      treeData: [],
      selectedCompanyId: 1,
      selectedDepartmentId: null,
      // 公司树选项
      companyOptions: [],
      // 部门管理树选项
      allDepartmentOptions: [],
      departmentOptions: [],
      positionList: [],
      className: 'className',
      nodeContent: 'nodeContent',
      isReadOnly: true,
      defaultProps: {
        children: "children",
        label: "name"
      },
      nameFilter: undefined,
      companyDetailShow: true,
      companyRow: null,
      departmentDetailShow: false,
      departmentRow: null,
      positionDetailShow: false,
      positionRow: null,
      // 详情标题
      classTypeTitle: '公司',
      uniApp:false,
      detailVisible:false,
    }
  },
  watch: {
    // 根据名称筛选部门树
    nameFilter(val) {
      this.$refs.tree.filter(val);
    }
  },
  created() {
    this.selectedCompanyId = this.$store.getters.userInfo.companyId;
    //this.chartData=
    getOrgTree((tree, companyList, departmentList, positionList )=>{
      console.log("return tree:"+tree);
      console.log(tree);
      // if(this.$store.getters.userInfo.companyId == PARENT_COMPANY_ID){
      sumTreeChild(tree,'id','pid');
      this.treeData = tree;
      this.chartData = tree[0];
      // }else{
      //   if(tree[0].children){
      //     for(let i = 0; i < tree[0].children.length; i++){
      //       const c = tree[0].children[i];
      //       if(c.id == this.$store.getters.userInfo.companyId){
      //         this.treeData = [c];
      //         this.chartData = c;
      //         break;
      //       }
      //     }
      //   }
      // }

      this.allDepartmentOptions = [];
      const dataDepartment = { id:0,
        name:'顶级部门',
        children: []
      };
      dataDepartment.children = this.handleTree(departmentList, "id", "pid");
      this.allDepartmentOptions.push(dataDepartment);

      this.companyOptions = [];
      this.companyOptions = this.handleTree(companyList, "id", "pid");

      this.positionList = positionList;
      this.uniApp=Local.get("isUniapp")?true:false;
    });
  },
  methods: {
    // 筛选节点
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    // 节点单击事件
    handleNodeClick(data) {
      if(data.classType === 'company'){
        this.companyRow = data;
        this.companyDetailShow = true;
        this.departmentDetailShow = false;
        this.positionDetailShow = false;
        this.selectedCompanyId = data.id;
        this.classTypeTitle = "公司";
      }else if(data.classType === 'department'){
        this.departmentRow = data;
        this.companyDetailShow = false;
        this.departmentDetailShow = true;
        this.positionDetailShow = false;
        this.selectedCompanyId = data.companyId;
        this.classTypeTitle = "部门";
        this.departmentOptions = [];
        this.departmentOptions.push({ id:0,
          name:'顶级部门',
          children: []
        });
        this.allDepartmentOptions[0].children.forEach(item=>{
          if(item.companyId===this.selectedCompanyId){
            this.departmentOptions[0].children.push(item);
          }
        });
      // }else if(data.classType === 'position'){
      //   this.positionRow = data;
      //   this.companyDetailShow = false;
      //   this.departmentDetailShow = false;
      //   this.positionDetailShow = true;
      //   this.selectedDepartmentId = data.departmentId;
      //   this.classTypeTitle = "岗位";
      //   this.departmentOptions = this.allDepartmentOptions;
      
      }
      // this.queryParams.companyId = data.id;
      // this.getList();

      if(this.uniApp)
        this.detailVisible = true;
    },
    close(){

    },
    exportCount(){

    }
  }
}
</script>

<style >

.orgchart .company .title { background-color: #006699; }
.orgchart .company .content { border-color: #006699; }
.orgchart .department .title { background-color: #009933; }
.orgchart .department .content { border-color: #009933; }
.orgchart .position .title { background-color: #993366; }
.orgchart .position .content { border-color: #993366; }

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

</style>
