<template>
<div class="app-container">
  <el-row>
    <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
      <Calendar style="height: 345px;  margin-bottom:10px; background-color:rgb(77, 86, 104)" choseDay="clickDay" @changeMonth="changeDate" @isToday="clickToday"
                :markDateMore="markDate"  ></Calendar>
    </el-col>
    <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
      <div style="height: 345px; margin-bottom:10px;margin-right:10px;border-width:1px;border-style:solid;border-color:#10d070;">
        <div class="block">
          <h3 style="text-align: center"><b>上 下 班 打 卡</b></h3>
        </div>
          <div v-if="dateClocktime">
            <div class="block">
              <el-timeline>
                <template v-for="t in dateClocktime.times">
                <el-timeline-item :key="'on'+t" :timestamp="(dateClocktime.clockSetting['clockOn'+t])+'上班'" placement="top">
                  <el-button v-if="!dateClocktime.clockTime['clockTimeOn'+t] && !dateClocktime.otherInfo['clockOnStatus'+t]" @click="clockOn(t)" size="mini">上 班 打 卡</el-button>
                  <div v-if="dateClocktime.clockTime['clockTimeOn'+t]">
                    打卡时间: {{ dateClocktime.clockTime['clockTimeOn'+t] }} </div>
                  <div v-if="dateClocktime.otherInfo['clockOnStatus'+t]">
                    {{dateClocktime.otherInfo['clockOnStatus'+t]}}
                    {{dateClocktime.otherInfo['clockChangedOn'+t]?("，(有休假)应打卡时间："+dateClocktime.otherInfo['clockChangedOn'+t]):""}}
                  </div>
                  <div v-else-if="!dateClocktime.otherInfo['clockOnStatus'+t] && isAbsentOn(t)">
                    <span style="color: #990000">旷工 </span><el-button size="mini" @click="applyCorrectTimeClock(
                        getDateTime(dateClocktime.clockSetting['clockOn'+t],false))">补卡申请</el-button>
                  </div>
                  <div v-else-if="new Date(dateClocktime.clockTime['clockTimeOn'+t]) > getDateTime(dateClocktime.clockSetting['clockOn'+t],false)">
                    <span style="color: #990000">迟到 </span><el-button size="mini" @click="applyCorrectTimeClock(
                        getDateTime(dateClocktime.clockSetting['clockOn'+t],false))">补卡申请</el-button>
                  </div>
                </el-timeline-item>
                <el-timeline-item :key="'off'+t" :timestamp=
                    "(dateClocktime.clockSetting['clockOffOver'+t]?'次日':'')+dateClocktime.clockSetting['clockOff'+t]+'下班'" placement="top">
                  <el-button v-if="!dateClocktime.clockTime['clockTimeOff'+t] && !dateClocktime.otherInfo['clockOffStatus'+t]" @click="clockOff(t)" size="mini">下 班 打 卡</el-button>
                  <div v-if="dateClocktime.clockTime['clockTimeOff'+t]">
                    打卡时间: {{ dateClocktime.clockTime['clockTimeOff'+t] }}</div>
                  <div v-if="dateClocktime.otherInfo['clockOffStatus'+t]">
                    {{dateClocktime.otherInfo['clockOffStatus'+t]}}
                    {{dateClocktime.otherInfo['clockChangedOff'+t]?("，(有休假)应打卡时间： "+dateClocktime.otherInfo['clockChangedOff'+t]):""}}
                  </div>
                  <div v-else-if="!dateClocktime.otherInfo['clockOffStatus'+t] && isAbsentOff(t)">
                    <span style="color: #990000">旷工 </span><el-button size="mini" @click="applyCorrectTimeClock(
                        getDateTime(dateClocktime.clockSetting['clockOff'+t],false))">补卡申请</el-button>
                  </div>
                  <div v-else-if="new Date(dateClocktime.clockTime['clockTimeOff'+t]) <
                    getDateTime(dateClocktime.clockSetting['clockOff'+t],dateClocktime.clockSetting['clockOffOver'+t])">
                    <span style="color: #990000">早退 </span><el-button size="mini" @click="applyCorrectTimeClock(
                        getDateTime(dateClocktime.clockSetting['clockOff'+t],dateClocktime.clockSetting['clockOffOver'+t]))">补卡申请</el-button>
                  </div>
                </el-timeline-item>
                </template>
                <template v-if="'休息日'==dateClocktime.attType">
                  <el-timeline-item timestamp="休息" placement="top">
                    <el-button v-if="!dateClocktime.clockTime['clockTimeOn1']" @click="clockOn(1)">上 班 打 卡</el-button>
                    <div v-if="dateClocktime.clockTime['clockTimeOn1']">
                      打卡时间: {{ dateClocktime.clockTime['clockTimeOn1'] }}</div>
                  </el-timeline-item>
                  <el-timeline-item timestamp="休息" placement="top">
                    <el-button v-if="!dateClocktime.clockTime['clockTimeOff1']" @click="clockOff(1)">下 班 打 卡</el-button>
                    <div v-if="dateClocktime.clockTime['clockTimeOff1']">
                      打卡时间: {{ dateClocktime.clockTime['clockTimeOff1'] }}</div>
                  </el-timeline-item>
                </template>
              </el-timeline>
            </div>
            <div class="block">
              <h4 style="text-align: center">今天上{{ dateClocktime.attClassName }}，
                考勤{{ dateClocktime.days }}天，打卡{{ dateClocktime.clockDays }}天 (状态：<b>
               <span v-if="'未完成'==dateClocktime.attType" style="color: #e0c162">{{dateClocktime.attType}}</span>
               <span v-else-if="'正常出勤'==dateClocktime.attType" style="color: #009933">{{dateClocktime.attType}}</span>
               <span v-else-if="'休息日'==dateClocktime.attType" style="color: #009933">{{dateClocktime.attType}}</span>
               <span v-else style="color: #990000">{{dateClocktime.attType}}</span>
                </b>)
                <el-button type="success" v-if="dateClocktime.attType.indexOf('旷工')>=0" size="mini" @click="applyLeave(dateClocktime)">请假申请</el-button>
              </h4>
            </div>
            <div  class="block">
            </div>
          </div>
          <div v-if="!dateClocktime">
            <h2 style="text-align: center"><b>无 考 勤 信 息</b></h2>
          </div>
        </div>
    </el-col>
    <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
      <el-card shadow="hover" style="margin-bottom:10px;">
        <div slot="header">
          <span>上下班统计：{{statisticTimes}}天</span>
        </div>
        <div class="charts">
          <div ref="homeStockRef" class="h100" style="height:100%;width:100%;"></div>
        </div>
      </el-card>
    </el-col>
    <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">
      <el-amap vid="amapDemo2" :center="curMaker.position" :zoom="zoom" class="amap-demo" :events="events" :plugin="plugin">
<!--        <el-amap-marker v-for="(marker,index) in markers" :key="index" :position="marker.position" :events="marker.events"-->
<!--                        :title="marker.text=='当前位置'?('当前位置离打卡地点还有'+distance+'米'):marker.text"/>-->
        <el-amap-marker :position="curMaker.position" :events="curMaker.events" :title="'当前位置离打卡地点还有'+distance+'米'"/>
        <el-amap-marker :position="clockMaker.position" :events="clockMaker.events" :title="clockMaker.title"/>
        <!--圆-->
        <el-amap-circle vid= "circle" :center="clockMaker.position" :radius="radius" fill-opacity= "0.2" strokeColor= "#38f"
                        strokeOpacity= "0.8" strokeWeight= "1" fillColor= "#38f">
        </el-amap-circle>
        <el-amap-info-window v-if="poiInfoWindow" isCustom="true" :position="poiInfoWindow.position"
            :visible="poiInfoWindow.visible" :offset="poiInfoWindow.offset">
          <div class="slot-info-window scale" @click="closePoiwindow">
            <div class="info-title">
              {{ poiInfoWindow.title}}
            </div>
            <div class="info-content scale">
              <el-button size="mini">刷新当前位置</el-button>
<!--              <p>11111：<span>{{ poiInfoWindow.value }}</span></p>-->
<!--              <p>22222：<span>{{poiInfoWindow.ratio}}</span></p>-->
            </div>
          </div>
        </el-amap-info-window>
      </el-amap>
      <div style="text-align: center"> 当前位置：[{{lng}},{{lat}}]，距离打卡点{{distance}}米</div>
      <div style="text-align: center"> 打卡位置：[{{clockMaker.position[0]}},{{clockMaker.position[1]}}]，地点：{{attGrp?(attGrp.addr?attGrp.addr.addr:''):''}}，
        范围：{{attGrp?(attGrp.addr?attGrp.addr.range:''):''}}米</div>
    </el-col>
    
  </el-row>
</div>
</template>

<script>
import {
  listAttStatisticDay, clockOnOff, getAttStatisticDay, updateAttStatisticDay,
  exportAttStatisticDay } from "@/api/attendance/attStatisticDay";
import { getAttendanceGroup } from "@/api/attendance/attendanceGroup";
import Calendar from 'vue-calendar-component';
import * as echarts from 'echarts';
import { showLoading, hideLoading } from '@/utils/reqloading';
import {formatDate} from "@/utils/formatTime";
import {getFirstLastDayOfMonth} from "@/utils/dateTimeUtils";
import { AMapManager } from "vue-amap";


export default {
  name: "index",
  components: {
    Calendar,
  },
  data() {
    const self = this;
    return {
      clockDate: null,
      dateClocktime: null,
      fixClassSch: null,  // 如果是固定班次，只需查一次
      statisticTimes: 0,
      statisticMonth:[],
      curMonth: null,
      pipData:[],
      markDate: [{date:'2022/5/1',className:"mark1"}, {date:'2022/5/13',className:"mark2"}],
      attGrp: null,  // 当前考勤组信息
      zoom: 12,
      curMaker:{id:'cur',position:[0,0],title:'当前位置',events:null},
      clockMaker:{id:'clock',position:[0,0],title:'指定打卡位置',events:null},
      // mapCenter: [121.59996, 31.197646],
      // mapClockCenter: [0,0],
      address: '',
      loaded: false,

      //圆的半径, 表示打卡范围
      radius: 100,
      distance: null, // 打卡地点到定位点距离。
      plugin: [
        {
          pName: 'ToolBar',
          events: {
            init(instance) {
            }
          }
        },
        {
          enableHighAccuracy: true, //是否使用高精度定位，默认:true
          timeout: 100, //超过10秒后停止定位，默认：无穷大
          maximumAge: 0, //定位结果缓存0毫秒，默认：0
          convert: true, //自动偏移坐标，偏移后的坐标为高德坐标，默认：true
          showButton: true, //显示定位按钮，默认：true
          buttonPosition: "LB", //定位按钮停靠位置，默认：'LB'，左下角
          showMarker: true, //定位成功后在定位到的位置显示点标记，默认：true
          showCircle: true, //定位成功后用圆圈表示定位精度范围，默认：true
          panToLocation: true, //定位成功后将定位到的位置作为地图中心点，默认：true
          zoomToAccuracy: true, //定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：f
          extensions: "all",
          pName: 'Geolocation',
          events: {
            click: (e) => {
              console.log("Geolocation click"+e);
              self.$message("Geolocation click"+e);
            },
            init(instance) {
              // instance 是高德地图定位插件实例
              instance.getCurrentPosition(self.processCurrentPosition);
            }
          }
        },
      ],
      events: {
        click(e) {
          self.$message("click map");
          // console.log("events click..");
          // const { lng, lat } = e.lnglat
          // self.lng = lng
          // self.lat = lat
          // self.markers[0].position = [lng, lat]
          //
          // // 这里通过高德 SDK 完成。
          // let geocoder;
          // AMap.plugin('AMap.Geocoder',function(){
          //   geocoder = new AMap.Geocoder({
          //     radius: 1000,
          //     extensions: 'all'
          //   })
          //   geocoder.getAddress([lng, lat], function(status, result) {
          //     console.log("geocoder.getAddress result");
          //     console.log(result);
          //     if (status === 'complete' && result.info === 'OK') {
          //       if (result && result.regeocode) {
          //         self.address = result.regeocode.formattedAddress
          //         self.$nextTick()
          //       }
          //     }
          //   })
          // })

        }
      },
      lng: 0,
      lat: 0,
      poiInfoWindow: {
        content: null,
        visible: false,
        offset: [10, 10],
        title: '当前位置',
        datas: [{ ratio: 2, value: 10 }],
        position: [1, 1],
      },
      mapData:[],
    }
  },
  created() {
    // this._filter = {};
    // this.getList();
    this.curMaker.events= {click: e=>{this.getCurPostion(e);this.openPoiwindow(e,this.curMaker.id);},};
    this.clockMaker.events= {click: e=>{this.getCurPostion(e);this.openPoiwindow(e,this.clockMaker.id);},};
    this.mapData = [this.curMaker,this.clockMaker];
  },
  mounted() {
    // this.initPie();

  },
  methods: {
    closePoiwindow(){
      this.poiInfoWindow.visible = false;
    },
    openPoiwindow(e, n) {
      this.mapData.forEach((v, idx) => {
        this.mapData[idx].content = `<i title="${v.title}" class="">neirong</i>`;//这里的content并无实际意义
        if (v.id === n) {
          this.mapData[idx].content = `<i> 内容f</i>`;//同上
          this.poiInfoWindow.visible = true;
          this.poiInfoWindow.title = v.title;
          this.poiInfoWindow.position = v.position;//控制窗体显示在所点击的坐标上
          // this.poiInfoWindow.value = v.value;
          // this.poiInfoWindow.ratio = (v.ratio * 100).toFixed(2);
        }
      });
    },
    processCurrentPosition(status,result){
      if(status=='complete'){
        console.log("getCurrentPosition");
        this.$message("getCurrentPosition");
        console.log(result);
        if (result && result.position) {
          this.$message(result.position);
          this.lng = result.position.lng;
          this.lat = result.position.lat;
          this.curMaker.position = [this.lng, this.lat];
          this.loaded = true;
          this.$nextTick();
        }else if(result && result.message){
          this.$message(result.message);
        }
        this.caculateDistance();
      }else{
        this.$message(result.message);
        // onError(result)
      }
    },
    // 点击当前位置重新定位。
    getCurPostion(e){
      let that = this;
      AMap.plugin('AMap.Geolocation', function() {
        var geolocation = new AMap.Geolocation({
          enableHighAccuracy: true, //是否使用高精度定位，默认:true
          timeout: 10000, //超过10秒后停止定位，默认：无穷大
          maximumAge: 0, //定位结果缓存0毫秒，默认：0
          convert: true, //自动偏移坐标，偏移后的坐标为高德坐标，默认：true
          showButton: true, //显示定位按钮，默认：true
          buttonPosition: "LB", //定位按钮停靠位置，默认：'LB'，左下角
          showMarker: true, //定位成功后在定位到的位置显示点标记，默认：true
          showCircle: true, //定位成功后用圆圈表示定位精度范围，默认：true
          panToLocation: true, //定位成功后将定位到的位置作为地图中心点，默认：true
          zoomToAccuracy: true, //定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：f
          extensions: "all",
          buttonOffset: new AMap.Pixel(10, 20),//定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
        });
        // map.addControl(geolocation);
        geolocation.getCurrentPosition(that.processCurrentPosition);
      });
    },
    initPie() {
      console.log("initPie");
      const myChart = echarts.init(this.$refs.homeStockRef);
      const option = {
        grid: {
          top: 50,
          right: 20,
          bottom: 30,
          left: 30,
        },
        tooltip: {
          trigger: 'item',
        },
        legend: {
          left: 'center',
        },
        series: [
          {
            name: '出勤统计',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2,
            },
            data: this.pipData,
            top: 30,
          },
        ],
      };
      myChart.setOption(option);
      window.addEventListener('resize', () => {
        myChart.resize();
      });
    },

    load() {

    },
    getStatisticMonth(date){
      if(this.curMonth == date) return;
      this.curMonth = date;
      console.log("getStatisticMonth="+date); //选中某天
      let selectedDate = new Date(date);
      const [firstDay,lastDay] = getFirstLastDayOfMonth(formatDate(selectedDate, 'YYYY-mm'));
      let filter = {};
      filter['clockDate'] = {
        type: 'date',
        value: [firstDay,lastDay],
      }
      filter['userId'] = {
        type: 'eq',
        value: this.$store.getters.userInfo.id,
      }
      filter['attType'] = {
        type: 'ne',
        value: "未完成",
      }
      const params = this.getQueryParam(filter, 1, 50, ' clockDate asc ');
      listAttStatisticDay(params).then(response => {
        this.statisticMonth = response.data.records
        this.statisticTimes = response.data.total;
        console.log(this.statisticMonth);
        let attTypeNum = {};
        let pipData = [];
        let exceptDate = [];
        this.statisticMonth.forEach( item=>{
          let attType = item.attType.split(",");
          if(attType&&attType.length>0){
            for(let i = 0; i < attType.length; i++){
              if(attTypeNum[attType[i]]){
                attTypeNum[attType[i]].value = attTypeNum[attType[i]].value+1;
              }else{
                attTypeNum[attType[i]] = { value: 1, name: attType[i] };
                pipData.push(attTypeNum[attType[i]]);
              }
            }
          }
          if('正常出勤'!=item.attType && '休息日'!=item.attType && '未完成'!=item.attType){
            exceptDate.push({date:item.clockDate, className:"mark1"});
          // }else if('未完成'==item.attType){
          //   exceptDate.push({date:item.clockDate, className:"mark2"});
          }
        });
        console.log(pipData);
        this.pipData = pipData;
        this.markDate = exceptDate;
        this.initPie();
      });

    },
    // 获取某天打卡信息
    getDateClocktime(data){
      console.log(data+",getDateClocktime"); //选中某天
      let d = new Date();
      let currentDate = new Date(d.getFullYear(),d.getMonth(),d.getDate());
      let selectedDate = new Date(data);
      this.clockDate = formatDate(selectedDate, 'YYYY-mm-dd');
      // if(selectedDate > currentDate){
      //   console.log("将来")
      // }else if(selectedDate <= currentDate){
        console.log("今天和以前,");
        console.log(this.$store.getters.userInfo.id);
        let filter = {};
        filter['clockDate'] = {
          type: 'eq',
          value: this.clockDate,
        }
        filter['userId'] = {
          type: 'eq',
          value: this.$store.getters.userInfo.id,
        }
        const params = this.getQueryParam(filter, 1, 10, '');
        listAttStatisticDay(params).then(response => {
          if(response.data.records.length == 1){
            this.dateClocktime = response.data.records[0];
            if(!this.attGrp || this.attGrp.id != this.dateClocktime.attGroupId){
              getAttendanceGroup(this.dateClocktime.attGroupId).then(resGrp => {
                console.log("attgrp addr")
                console.log(resGrp.data)
                this.attGrp = resGrp.data;
                if(this.attGrp.addr && this.attGrp.addr.position){
                  this.clockMaker.position = this.attGrp.addr.position;
                  this.radius = this.attGrp.addr.range;
                  console.log("updated clockpos:"+this.clockMaker);
                  this.caculateDistance();
                }
              });
            }
          }else{
            this.dateClocktime = null;
          }
          console.log(this.dateClocktime);
        });
      // }
    },
    caculateDistance(){
      console.log("caculateDistance")
      this.$message("caculateDistance");
      let p1 = new AMap.LngLat(this.curMaker.position[0], this.curMaker.position[1]);
      let p2 = new AMap.LngLat(this.clockMaker.position[0], this.clockMaker.position[1]);
      let distance = Math.round(p1.distance(p2));
      console.log("距离是："+distance);
      this.distance = distance;
      if(this.distance <= this.radius){
        console.log("")
      }
    },
    getLocation(){
      AMap.plugin('AMap.Geolocation', function() {
        var geolocation = new AMap.Geolocation({
          // 是否使用高精度定位，默认：true
          enableHighAccuracy: true,
          // 设置定位超时时间，默认：无穷大
          timeout: 10000,
          // 定位按钮的停靠位置的偏移量
          offset: [10, 20],
          //  定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
          zoomToAccuracy: true,
          //  定位按钮的排放位置,  RB表示右下
          position: 'RB'
        })
        geolocation.getCurrentPosition(function(status,result){
          if(status=='complete'){
            onComplete(result)
          }else{
            onError(result)
          }
        });
        function onComplete (data) {
          // data是具体的定位信息
        }
        function onError (data) {
          // 定位出错
        }
      })
    },
    clockOn(t){
      if(this.distance > this.radius){
        this.msgError("当前定位不在指定打卡地点范围内，请到打卡地点范围内再打卡");
        return;
      }
      console.log("clockOn"+t)
      if(t > this.dateClocktime.times){
        this.msgError("考勤次数不正确")
        return;
      }
      if(this.dateClocktime.clockTime['clockTimeOn'+t]){
        this.msgInfo("已经打过上班卡，不需要重复打卡");
        return;
      }

      let data = {id: this.dateClocktime.id, clockTimes: t, clockOnOff: 'on', clockType: '网页打卡'};
      clockOnOff(data).then(response => {
        this.dateClocktime = response.data;
        console.log(this.dateClocktime);
      });
    },
    clockOff(t){
      console.log("clockOff"+t)
      if(this.distance > this.radius){
        this.msgError("当前定位不在指定打卡地点范围内，请到打卡地点范围内再打卡");
        return;
      }
      if(t > this.dateClocktime.times){
        this.msgError("考勤次数不正确")
        return;
      }
      if(this.dateClocktime.clockTime['clockTimeOff'+t]){
        this.msgInfo("已经打过下班卡，不需要重复打卡");
        return;
      }
      let clockOffDt = this.getDateTime(this.dateClocktime.clockSetting['clockOff'+t], this.dateClocktime.clockSetting['clockOffOver'+t])
      const that = this;
      if( new Date() < clockOffDt ){
        this.$confirm('还未到下班时间，确定要打卡?', "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function () {
          let data = {id: that.dateClocktime.id, clockTimes: t, clockOnOff: 'off', clockType: '网页打卡'};
          clockOnOff(data).then(response => {
            that.dateClocktime = response.data;
            console.log(that.dateClocktime);
          });
        });
      }else{
        let data = {id: this.dateClocktime.id, clockTimes: t, clockOnOff: 'off', clockType: '网页打卡'};
        clockOnOff(data).then(response => {
          this.dateClocktime = response.data;
          console.log(this.dateClocktime);
        });
      }
    },
    // 是否旷工
    isAbsentOn(t){
      let clockTimeOn = new Date(this.dateClocktime.clockTime['clockTimeOn'+t]);
      let clockOn = this.getDateTime(this.dateClocktime.clockSetting['clockOn'+t],false);
      if(this.dateClocktime.absent){
        clockOn.setMinutes(clockOn.getMinutes()+this.dateClocktime.absent)
      }
      if(clockTimeOn > clockOn){
        return true;
      }else{
        return false;
      }
    },
    isAbsentOff(t){
      let clockTimeOff = new Date(this.dateClocktime.clockTime['clockTimeOff'+t]);
      let clockOff = this.getDateTime(this.dateClocktime.clockSetting['clockOff'+t],this.dateClocktime.clockSetting['clockOffOver'+t]);
      if(this.dateClocktime.absent){
        clockOff.setMinutes(clockOff.getMinutes()-this.dateClocktime.absent)
      }
      if(clockTimeOff < clockOff){
        return true;
      }else{
        return false;
      }
    },
    //
    getDateTime(time, isNextDay){
        let datetime = this.dateClocktime.clockDate + " "+time;
        let dt = new Date(datetime);
        if(isNextDay){
          dt.setDate(dt.getDate()+1);
        }
        return dt;
    },
    applyCorrectTimeClock(ct){
      this.$router.push({name:"bpm_correctTimeClock_apply", query:{correctTime: formatDate(ct, 'YYYY-mm-dd HH:MM')}});
    },
    applyLeave(data){
      console.log(data);
      this.$router.push({name:"bpm_leave_apply", query:{start: null}});
    },
    clickDay(data) {
      this.getDateClocktime(data);
    },
    changeDate(data) {
      console.log(data); //左右点击切换月份
      this.getStatisticMonth(data);
      let d = new Date();
      let currentDate = new Date(d.getFullYear(),d.getMonth(),d.getDate());
      let selectedDate = new Date(data);
      if(selectedDate > currentDate){
        console.log("将来")
      }else if(selectedDate < currentDate){
        console.log("以前")
      }else{
        console.log("今天")
      }
    },
    clickToday(data) {
      console.log("跳到了本月"+data); //跳到了本月
      this.getStatisticMonth(data);
    }
  }
}
</script>

<style scoped>
.amap-demo {
  height: 428px;
  border: 1px solid #409eff;
}

.charts {
  width: 100%;
  height: 268px;
  display: flex;
  /*padding: 5px 15px;*/

}


.wh_container >>> .mark1 {
  background-color: #ba0427;
}

.wh_container >>> .mark2 {
  background-color: #6464c6;
}
.wh_content_item > .wh_isMark {
  background: orange;
}

.wh_content_item .wh_isToday[data-v-2ebcbc83] {
  background: #00d985  !important;
  color: #fff  !important;
}

.wh_container >>> .wh_content_all {
   background-color: rgb(77, 86, 104);

}

/*.wh_content_item,*/
/*.wh_content_item_tag{*/
/*  width: 14.285% !important;*/
/*  color: rgb(50, 50, 51) !important;*/
/*}*/
/*.wh_content_item .wh_chose_day{*/
/*  background: #ee0a24 !important;*/
/*  color: #fff !important;*/
/*}*/
/* .el-card__body, .el-main{
  padding: 0px !important;
} */
 .app-container .wh_container .wh_content_all{
  height: 340px !important;
}
</style>
