<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px">
      <el-form-item label="公司" prop="companyId">
        <treeselect v-model="queryParams.companyId" style="width: 198px"
                    :options="companyOptions"
                    :normalizer="normalizerCompany" placeholder="请选择所属公司"/>
      </el-form-item>
      <el-form-item label="合约名字" prop="name">
        <el-input
            v-model="queryParams.name"
            placeholder="请输入合约名字"
            clearable
            size="small"
            @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="合约类型" prop="type">
        <el-input
            v-model="queryParams.type"
            placeholder="请输入合约类型"
            clearable
            size="small"
            @keyup.enter.native="handleQuery"
        />
<!--        <el-select v-model="queryParams.type" placeholder="请选择合约类型" clearable-->
<!--                   size="small">-->
<!--          <el-option label="请选择字典生成" value=""/>-->
<!--        </el-select>-->
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button
            type="primary"
            plain
            icon="el-icon-plus"
            size="mini"
            @click="handleAdd"
            v-permission="['/template/contract/add']"
        >新增
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
            type="success"
            plain
            icon="el-icon-edit"
            size="mini"
            :disabled="single"
            @click="handleUpdate"
            v-permission="['/template/contract/edit']"
        >修改
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
            type="danger"
            plain
            icon="el-icon-delete"
            size="mini"
            :disabled="multiple"
            @click="handleDelete"
            v-permission="['/template/contract/remove']"
        >删除
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button
            type="warning"
            plain
            icon="el-icon-download"
            size="mini"
            @click="handleExport"
            v-permission="['/template/contract/export']"
        >导出
        </el-button>
      </el-col>
      <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>
    </el-row>

    <el-table v-loading="loading" :data="contractList" @selection-change="handleSelectionChange" style="width:100%" stripe border>
      <el-table-column type="selection" width="55" align="center"/>
      <el-table-column label="所属公司" align="center" prop="companyId" show-overflow-tooltip width="210" sortable>
        <template slot-scope="scope">
          {{companyFormat(scope.row, null)}}
        </template>
      </el-table-column>
      <el-table-column label="合约名字" width="180" align="center" prop="name" sortable/>
      <el-table-column label="是否有效" width="110" align="center" prop="valid" sortable/>
      <el-table-column label="合约类型" width="110" align="center" prop="type" sortable/>
      <el-table-column label="水印字符" width="180" align="center" prop="waterName" sortable/>
      <el-table-column label="合同文件" width="210" align="center" prop="url" sortable>
        <template slot-scope="scope">
<!--          <el-link type="primary" target="_blank" @click="download(scope.row)" v-permission="['/template/contract/download']">-->
            {{scope.row.name}}{{scope.row.url.substr(scope.row.url.lastIndexOf("."))}}
<!--          </el-link>-->
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width" width="190px">
        <template slot-scope="scope">
<!--          <el-link target="_blank" :href="'/template/contract/download?fileName='+scope.row.url" :underline="false" style="margin-left:15px">-->
          <el-button size="mini" type="text" icon="el-icon-download" @click="download(scope.row)"
              v-permission="['/template/contract/download']"
          >下载

          </el-button>
<!--          </el-link>-->
          <el-button
              size="mini"
              type="text"
              icon="el-icon-edit"
              @click="handleUpdate(scope.row)"
              v-permission="['/template/contract/edit']"
          >修改
          </el-button>
          <el-button
              size="mini"
              type="text"
              icon="el-icon-delete"
              @click="handleDelete(scope.row)"
              v-permission="['/template/contract/remove']"
          >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
        v-show="total>0"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
    />

    <!-- 添加或修改合同模板对话框 -->
    <el-dialog :title="title" :visible.sync="open" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="100px">
        <el-row :gutter="35">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
            <el-form-item label="所属公司" prop="companyID">
              <treeselect v-model="form.companyID" :options="companyOptions"
                          :normalizer="normalizerCompany" placeholder="请选择公司"/>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
            <el-form-item label="合约名字" prop="name">
              <el-input v-model="form.name" placeholder="请输入合约名字"/>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
            <el-form-item label="是否有效" prop="valid">
              <el-select v-model="form.valid" placeholder="请选择">
                <el-option key="true" label="有效" value="true"></el-option>
                <el-option key="false" label="无效" value="false"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
            <el-form-item label="合约类型" prop="type">
              <el-input v-model="form.type" placeholder="请输入合约类型" autocomplete="on"/>
<!--              <el-select v-model="form.type" placeholder="请选择合约类型">-->
<!--                <el-option key="true" label="有效" value="true"></el-option>-->
<!--              </el-select>-->
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
            <el-form-item label="水印字符" prop="waterName">
              <el-input v-model="form.waterName" @change="changeWater" placeholder="请输入水印字符"/>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb20">
            <el-form-item label="合同文件" prop="url">
              <el-tag v-if="form.url" v-permission="['/template/contract/download']"
                      closable
                      @close="handleClose()"
                      @click="download(form)">
                <el-link type="primary" target="_blank">
                {{form.name}}{{form.url.substr(form.url.lastIndexOf("."))}}
                </el-link>
              </el-tag>
              <el-upload
                  action
                  :name="form.url"
                  class="upload-demo"
                  ref="upload"
                  :limit="1"
                  :http-request="uploadFile"
                  :on-preview="handlePreview"
                  :on-remove="handleRemove"
                  :file-list="fileList"
                  accept=".pdf,.docx"
                  :auto-upload="false">
                <el-button slot="trigger" size="small" type="primary">上传文件</el-button>
<!--                <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">上传到服务器</el-button>-->
<!--                <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>-->
              </el-upload>
<!--              <el-input disabled v-model="form.url" placeholder="请选择文件"/>-->
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm"
                   v-permission="['/template/contract/add', '/template/contract/edit']">确 定
        </el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  listContract,
  getContract,
  delContract,
  addContract,
  updateContract,
  updateContractNoFile,
  exportContract
} from "@/api/template/contract";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import RightToolbar from "@/components/RightToolbar"
import permission from '@/directive/permission/index.js' // 权限判断指令
import Pagination from "@/components/Pagination";
import {listCompany} from "@/api/org/company";
import {download} from "../../../../api/template/contract";
import { showLoading, hideLoading } from '@/utils/reqloading';
import JSZip from "jszip";
import FileSaver from "file-saver";

// 表查询配置
const dbOrderBy = ''

export default {
  name: "Contract",
  components: {
    Treeselect,
    RightToolbar,
    Pagination,
  },
  directives: {permission},
  data() {
    return {
      waterChanged: false,
      fileList:[],
      // 公司树选项
      companyList: [],
      companyOptions: [],
      // 遮罩层
      loading: true,
      // 查询参数
      where: {
        query: '',
        filters: []
      },


      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 合同模板表格数据
      contractList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        orderby: dbOrderBy,
        name: null,
        type: null,
      },
      // 表单参数
      form: {
        waterName: null,
      }
      ,
      // 表单校验
      rules: {
        companyid: [
          {
            required: true,
            message: "分公司ID不能为空", trigger: "blur"
          }
        ],
        waterName: [
          {
            required: true,
            message: "水印字符不能为空", trigger: "blur"
          }
        ],
        deleted: [
          {
            required: true,
            message: "是否删除： 1删除，0不删除不能为空", trigger: "blur"
          }
        ]
      }
    }
        ;
  },
  created() {
    this._filter = {};
    this.getTreeselectCompany();
    this.getList();
  },
  methods: {
    changeWater(){
      // this.fileList = [];
      this.waterChanged = true;
    },
    handleClose(){
      this.form.url = null;
    },
    submitUpload() {
      this.$refs.upload.submit();
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },

    async uploadFile(params) {
      this.$refs["form"].validate(valid => {
        if (valid) {
          const _file = params.file;
          const formData = new FormData();
          formData.append('file', _file);
          formData.append('contract', JSON.stringify(this.form)); // isOverride自定义的其他参数
          showLoading();
          if (this.form.id != null) {
            // eslint-disable-next-line no-unused-vars
            updateContract(formData).then(response => {
              this.msgSuccess("修改成功");
              this.open = false;
              this.getList();
              hideLoading();
            });
          } else {
            // eslint-disable-next-line no-unused-vars
            addContract(formData).then(response => {
              this.msgSuccess("新增成功");
              this.open = false;
              this.getList();
              hideLoading();
            });
          }
        }
      });
    },

    inputDialog(){
      const h = this.$createElement;
      this.$msgbox({
        title: '消息',
        message: h('p', null, [
          h('span', null, '内容可以是 '),
          h('i', { style: 'color: teal' }, 'VNode')
        ]),
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = '执行中...';
            setTimeout(() => {
              done();
              setTimeout(() => {
                instance.confirmButtonLoading = false;
              }, 300);
            }, 3000);
          } else {
            done();
          }
        }
      }).then(action => {
        this.$message({
          type: 'info',
          message: 'action: ' + action
        });
      });
    },
    download(row) {
      // this.inputDialog();
      this.$confirm('请选择是打印一份还是多份。如果要自定义合同编号请选择打印一份。打印多份不能自定义合同编号，只能使用系统默认编号。', '提示', {
        confirmButtonText: '打印一份',
        cancelButtonText: '打印多份',
        type: 'warning'
      }).then(() => {
        // 打印一份
        this.$prompt('请输入合同编号（不输入则使用系统默认编号）', '编号定义', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(({ value }) => {
          if(!value) value='';
          this.downloadContract(row,value);
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消下载'
          });
        });
      }).catch(() => {
        // 打印多份
        this.$prompt('请输打印份数（打印多份不能自定义合同编号，需要定义合同编号请选择打印一份）', '打印数', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputPattern: /^[+]{0,1}(\d+)$/,
          inputErrorMessage: '只能输入正整数'
        }).then(({ value }) => {
          console.log(row);
          showLoading();
          if(value) {
            const times = parseInt(value);
            // this.downloadContract(row,"", times);
            const downloadFilename = row.name+".zip";
            const suf = row.url.substr(row.url.lastIndexOf("."))
            let zip = new JSZip();
            let promises = [];
            let cache = {};

            for(let i = 0; i < times; i++){
              let fname = row.name+(i+1)+suf;
              const promise = download(row.url, "").then((res) => {
                let data = res.data;
                // 下载文件, 并存成ArrayBuffer对象(blob)
                zip.file(fname, data, { binary: true }); // 逐个添加文件
                cache[fname] = data;
              });
              promises.push(promise);
            }
            Promise.all(promises).then(() => {
              zip.generateAsync({ type: "blob" }).then((content) => {
                // 生成二进制流
                FileSaver.saveAs(content, downloadFilename); // 利用file-saver保存文件  blogTitle:自定义文件名
              });
            }).catch((res) => {
              alert("文件压缩失败");
            });
          }
        }).catch(() => {
        });

      });

    },

    downloadPromise(row, num){
      // return new Promise((resolve, reject) => {
      return  download(row.url, num);
      // });
    },
    downloadContract(row, num){
      download(row.url, num).then(res=>{
        hideLoading();
        if(res){
          let resData = res.data;
          var blob = new Blob([resData],{type:'application/octet-stream'});
          const fileName = `${row.name}${row.url.substr(row.url.lastIndexOf("."))}`;
          if ("download" in document.createElement("a")) {
            // 非IE下载
            const elink = document.createElement("a");
            elink.download = fileName;
            elink.style.display = "none";
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href); // 释放URL 对象
            document.body.removeChild(elink);
          } else {
            // IE10+下载
            navigator.msSaveBlob(blob, fileName);
          }
        }
      }).catch(e=>{
        this.msgError(`后端下载文件出错 & ${e}`)
      });
    },

    downloadFile(url, fileName) {
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },

    /** 查询合同模板列表 */
    getQuery() {
      this._filter = {};
      if(this.queryParams.companyId) {
        this._filter['companyId'] = {
          type: 'eq',
          value: this.queryParams.companyId,
        }
      }
      if (this.queryParams.name) {
        this._filter['name'] = {
          type: 'search',
          value: this.queryParams.name,
        }
      }
      if (this.queryParams.type) {
        this._filter['type'] = {
          type: 'search',
          value: this.queryParams.type,
        }
      }
      const filters = this.parseFilters(this._filter)
      if (filters && filters.length > 0) {
        this.where.filters = filters
      } else {
        // 如果过滤条件为空，设置空数组
        this.where.filters = []
      }
      const params = {
        pageNum: this.queryParams.pageNum,
        pageSize: this.queryParams.pageSize,
        where: this.where,
        order: 'id desc'
      }

      return params;
    },
    getList() {
      this.loading = true;
      const params = this.getQuery();
      listContract(params).then(response => {
        this.contractList = response.data.records;
        this.total = response.data.total;
        this.loading = false;
      })
      ;
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {};
      this.resetForm("form");
      this.fileList = [];
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.form.companyID = this.$store.getters.userInfo.companyId;
      this.$set(this.form,'waterName',this.$store.getters.userInfo.companyName);
      // this.form.waterName = this.$store.getters.userInfo.companyName;
      this.waterChanged = false;
      this.open = true;
      this.title = "添加合同模板";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.id || this.ids
      getContract(id).then(response => {
        this.form = response.data;
        this.waterChanged = false;
        this.open = true;
        this.title = "修改合同模板";
      })
      ;
    },
    /** 提交按钮 */
    submitForm() {
      console.log(this.$refs.upload);
      console.log(this.form);
      console.log(this.fileList);
      console.log(this.$refs.upload.$data.uploadFiles.length);
      if(this.$refs.upload.$data.uploadFiles.length > 0){
        this.$refs.upload.submit();
      }else{
        if(this.waterChanged){
          this.msgError("你修改了水印字符，请重新上传合同文件");
          return;
        }else{
          // updateContractNoFile(this.form).then(response => {
          updateContractNoFile(this.form).then(response => {
            this.msgSuccess("修改成功");
            this.open = false;
            this.getList();
            hideLoading();
          });
        }
      }
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      let ids = [];
      if (row.id) {
        ids = [row.id];
      } else {
        ids = this.ids;
      }

      this.$confirm('是否确认删除选中的合同模板数据?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return delContract(ids);
      }).then(() => {
        this.getList();
        this.msgSuccess("删除成功");
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      const params = this.getQuery();
      this.$confirm('是否确认导出所有合同模板数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return exportContract(params);
      }).then(response => {
        this.download(response.msg);
      })
    },

    /** 转换公司数据结构 */
    normalizerCompany(node) {
      if (node.children && !node.children.length) {
        delete node.children;
      }
      return {
        id: node.id,
        label: node.companyName,
        children: node.children
      };
    },
    companyFormat(row, column) {
      console.log(row);
      for(var i = 0; i < this.companyList.length; i++){
        const item =  this.companyList[i];
        if(item.id == row.companyID){
          return item.companyName;
        }
      }
      return "";
    },

    /** 查询公司下拉树结构 */
    getTreeselectCompany() {
      const params = {
        pageNum: 1,
        pageSize: 500,
        where: null,
        order: ''
      }
      listCompany(params).then(response => {
        this.companyList = response.data.records;
        this.companyOptions = this.handleTree(response.data.records, "id", "parentId");
      }) ;
    },

  }
};
</script>
