<template>
  <div>
    <h2>排 班 管 理</h2>
    <el-form ref="form" :model="form" :rules="rules" label-width="118px">
      <el-collapse v-model="activeNames">
        <el-collapse-item title="班次周期设置" name="1">
          <el-row :gutter="35">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb20">
              <el-form-item label="考勤班次" prop="scheduleIds"
                            :rules="{required: true,message: '考勤班次不能为空', trigger: 'blur' }">
                <el-select ref="refSchIds" v-model="scheduleIds" multiple @change="scheduleIdsChange" placeholder="请选择"
                           style="width: 100%">
                  <el-option v-for="item in attendanceClassList" :key="item.id" :label="getFixDefLabel(item)"
                             :value="''+item.id" v-html="'<span style=color:'+item.color+'>'+getFixDefLabel(item)+'</span>'">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="35">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb20">
              <el-form-item label="排班周期" prop="schedule">
                <el-row :gutter="35">
                  <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8" class="mb20">
                    <el-form-item label="周期天数" :rules="{required: true,message: '周期天数不能为空', trigger: 'blur' }">
                      <el-input v-model="schedule.days"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8" class="mb20">
                    周期循环排班，最大周期天数31天
                  </el-col>
                </el-row>
                <el-row :gutter="35">
                  <template v-for="d in schedule.days?parseInt(schedule.days):3">
                    <el-col :key="d" :xs="24" :sm="8" :md="8" :lg="8" :xl="8" class="mb20">
                      <el-form-item :label="'第'+d+'天'" :rules="{required: true,message: '第'+d+'天不能为空', trigger: 'blur' }">
                        <el-select :ref="'cycleSchedule'+d" v-model="schedule.attClass[d-1]"
                                   @change="onChangeCycleSchedule($event, 'cycleSchedule'+d)" placeholder="请选择班次">
                          <el-option v-for="item in scheduleClassList" :key="item.id" :label="getFixDefLabel(item)" :value="item.id" >
                          </el-option>
                          <el-option label="休息" :value='0'></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </template>
                </el-row>
              </el-form-item>
            </el-col>
          </el-row>
        </el-collapse-item>
      </el-collapse>

      <!--     begin 对员工排班  -->
      <el-row>
        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8" class="mb20">
          <el-form-item label="排班月份" prop="schedule">
            <el-date-picker v-model="scheduleMonth" type="month" placeholder="选择排班月份" @change="schMonthChange">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="16" :md="16" :lg="16" :xl="16" align="right" class="mb20">
          <el-button type="primary" @click="saveChange">保 存</el-button>
          <el-button @click="resetChange">恢 复</el-button>
        </el-col>
<!--        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb20">-->
<!--          <el-button>取 消</el-button>-->
<!--        </el-col>-->
      </el-row>
      <el-row>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="mb20">
          <el-table v-loading="loading" :data="employList" border stripe height="600"
                    @cell-click="cellClick">
            <el-table-column label=" 员工姓名 " fixed="left" align="center" prop="name"/>
            <el-table-column v-for="item in schColDays" :key="item.day" :render-header="renderHeader"
                             :label="''+item.day+'-'+item.week" align="center" :prop="''+item.day">
<!--              <template slot="header" slot-scope="scope">-->
<!--                <el-button type="text">{{scope.day}}<br/>{{scope.week}}</el-button>-->
<!--              </template>-->

<!--              <template slot-scope="scope">-->
<!--                  <span class="el-dropdown-link" :style="'color: '+-->
<!--                  getColor(scope.row.daySch? scope.row.daySch[parseInt(scope.column.property)]?scope.row.daySch[parseInt(scope.column.property)].attClassId:-1:-1)">-->
<!--&lt;!&ndash;                    {{scope.row.daySch? scope.row.daySch[parseInt(scope.column.property)]?scope.row.daySch[parseInt(scope.column.property)].attClassId:-1:-1 }}&ndash;&gt;-->
<!--                    {{scope.row.daySch? scope.row.daySch[parseInt(scope.column.property)]?scope.row.daySch[parseInt(scope.column.property)].text:'':''}}-->
<!--                  </span>-->
<!--              </template>-->

              <template slot-scope="scope">
                <el-dropdown :ref="'dropdown-'+scope.row.id+'-'+scope.column.property" @command="handleCommand($event, scope)" trigger="click" :hide-on-click="true">
                  <span class="el-dropdown-link" :style="'color: '+
                  getColor(scope.row.daySch? scope.row.daySch[parseInt(scope.column.property)]?scope.row.daySch[parseInt(scope.column.property)].attClassId:-1:-1)">
<!--                    {{scope.row.daySch? scope.row.daySch[parseInt(scope.column.property)]?scope.row.daySch[parseInt(scope.column.property)].attClassId:-1:-1 }}-->
                    {{scope.row.daySch? scope.row.daySch[parseInt(scope.column.property)]?scope.row.daySch[parseInt(scope.column.property)].text:'':''}}
                  </span>
<!--                  <el-dropdown-menu v-if="isLessThanToday(item)" slot="dropdown">-->
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="cycle">周期排班</el-dropdown-item>
                    <el-dropdown-item v-for="(item,i) in schedule.attClass" :key="i" :command="i">{{ getClassText(item) }}</el-dropdown-item>
<!--                    <el-dropdown-item :command="scheduleClassList">休息</el-dropdown-item>-->
                  </el-dropdown-menu>
<!--                  <el-dropdown-menu v-if="!isLessThanToday(item)" slot="dropdown">-->
<!--                    <el-dropdown-item command="">小于当前日期不能排班</el-dropdown-item>-->
<!--                  </el-dropdown-menu>-->
                </el-dropdown>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <!--     end  对员工排班  -->
    </el-form>


  </div>
</template>

<script>
import {
  listAttGrpClsSch,
  getAttGrpClsSch,
  delAttGrpClsSch,
  addAttGrpClsSch,
  updateAttGrpClsSch,
  exportAttGrpClsSch
} from "@/api/attendance/attGrpClsSch";
import {getAttendanceGroup, attArrange} from "@/api/attendance/attendanceGroup";
import {listAttendanceClass} from "@/api/attendance/attendanceClass";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import permission from '@/directive/permission/index.js' // 权限判断指令
import {getOrgEmpTree, normalizerEmployMultiSelectTree} from '@/views/pages/employ/components/empOrgTree';
import {showLoading, hideLoading} from '@/utils/reqloading';
import {DICT_TYPE, getDictLabelFromData, getDictDataLabel, getDictDatas} from "@/utils/dict";
import {listSimpleEmploy} from "@/api/employ/employ";
import {getFirstLastDayOfMonth, getFirstLastTimestampDayOfMonth,getDaysArrOfMonth} from '@/utils/dateTimeUtils';
import {getRandomColor} from "../../../../utils/tools";
import {formatDate} from "../../../../utils/formatTime";

export default {
  name: "index",
  components: {},
  props: {
    row: Object,
    isReadOnly: Boolean,
  },

  directives: {permission},
  watch: {
    // row(n,o){ //n为新值,o为旧值;
    //   //if(n === o) return;
    //   this.load();
    // },
    "schedule.days"(){
      console.log("schedule changed");
      this.attGrpChanged = true;
    },
  },

  data() {
    return {
      attGrpChanged: false,
      activeNames: ['1'],
      scheduleMonth: new Date(),
      attGrpId: null,
      attGrpEmpIds: [],
      noAttendEmpIds: [],
      otherEmpIds: [],
      managerEmp: [],
      positionShow: false,
      makerPosition: [114.027868, 22.60775],
      addr: '',
      positionRange: 100,
      attType: 1,
      // 排班 班次id
      scheduleIds: [],
      schedule: {
        days: 1,
        attClass: [],
      },
      scheduleClassList: [],
      attendanceClassList: [],
      // 遮罩层
      loading: true,
      // 排班列日期星期
      schColDays: [],
      // 查询参数
      where: {
        query: '',
        filters: []
      },

      // 部门管理树选项
      departmentOptions: [],
      employList: [],
      AttGrpClsSch: [],

      // 表单参数
      form: {}
      ,
      // 表单校验
      rules: {},
      AttendanceTypes: DICT_TYPE.AttendanceTypes,
    };
  },
  mounted() {
    console.log('mounted');
    console.log(this.$route.query);
    if (this.$route.query) {
      const {attGrpId} = this.$route.query;
      this.attGrpId = attGrpId;
    }
    this.schColDays = getDaysArrOfMonth();
    console.log("this.schColDays=");
    console.log(this.schColDays);
    this.load();
    this.companyId = this.$store.getters.userInfo.companyId;
    // showLoading();
    // 查询班次
    const classParams = this.getQueryParam({}, 1, 1000, '');
    listAttendanceClass(classParams).then(response => {
      this.attendanceClassList = response.data.records;
      this.attendanceClassList.forEach(item=>{
        item.color = getRandomColor(item.id);
      });
      this.scheduleIdsChange(this.scheduleIds);
      // this.$forceUpdate();
      // hideLoading();
    });

  },

  methods: {
    load() {
      showLoading();
      // 查询考勤组信息
      getAttendanceGroup(this.attGrpId).then(response => {
        this.form = response.data;
        this.copyFormToData(this.form);
        console.log("this.form");
        console.log(this.form);
        // this.attGrpEmpIds = this.form.attGrpEmpIds;
        console.log("this.attGrpEmpIds");
        console.log(this.attGrpEmpIds);
        // 查询部门考勤人员
        let filter = {};
        filter['id'] = {
          type: 'in',
          value: this.attGrpEmpIds,
        }
        // filter['id'] = {
        //   type: 'notIn',
        //   value: this.noAttendEmpIds,
        // }
        console.log("filter");
        console.log(filter);
        const empParams = this.getQueryParam(filter, 1, 1000, 'departmentId asc');
        let rtEmploy = listSimpleEmploy(empParams).then(response => {
          this.employList = response.data.records;
        });
        // 查询某月考勤组排班
        let rtsch = this.queryAttGrpClassSch(this.attGrpId,this.scheduleMonth);
        Promise.all([rtsch,rtEmploy]).then(()=>{
          this.updateEmpSchedule();
          hideLoading();
          this.loading=false;
          console.log("set attGrpChanged to false")
          this.attGrpChanged = false;
        });

      });

    },
    // 查询某月考勤组排班
    queryAttGrpClassSch(attGrpId,month){
      let filter = {};
      const [start, end] = getFirstLastDayOfMonth(month);
      filter['schDate'] = {
        type: 'date',
        value: [start, end],
      }
      filter['attGrpId'] = {
        type: 'eq',
        value: attGrpId,
      }
      const schParams = this.getQueryParam(filter, 1, 10000, '');
      console.log(" begin queryAttGrpClassSch")
      let rtsch = listAttGrpClsSch(schParams).then(response => {
        console.log(" queryAttGrpClassSch response...")
        console.log(response.data.records)
        this.attGrpClsSch = response.data.records;
      });
      return rtsch;
    },
    // 根据排班组月份查到的排班信息，跟新到每一行员工的daySch 数组中。
    // 如果reset 可以call 此函数, 几号就放第几个下标
    updateEmpSchedule(){
      console.log('updateEmpSchedule');
      for(let i = 0; i < this.employList.length; i++){
        const emp = this.employList[i];
        if(!emp.daySch){
          this.$set(this.employList[i],'daySch',[]);
        }
        for(let j = 0; j < this.attGrpClsSch.length; j++){
          const sch = this.attGrpClsSch[j];
          if(emp.id == sch.empId){
            // 不从0 开始，几号就放第几个下标
            const date = new Date(sch.schDate).getDate();
            sch.text = this.getClassText(sch.attClassId);
            emp.daySch[date] = sch;
            // console.log(emp.daySch)
          }
        }
        for(let k = 1; k <= this.schColDays[this.schColDays.length-1].day; k++){
          if(!emp.daySch[k]){
            let day = k;
            let date = new Date(this.scheduleMonth);
            date.setDate(day);
            date = formatDate(date, 'YYYY-mm-dd');
            let daySch = {attGrpId:this.attGrpId, empId:emp.id, attClassId: null,
              schDate: date};
            daySch.text = '';
            this.$set(emp.daySch,day,daySch);
          }
        }
      }
      console.log(this.employList)
    },
    getClassText(id){
      if(id == 0) return '休息';
      for(let i = 0; i < this.attendanceClassList.length; i++){
        if(id == this.attendanceClassList[i].id){
          return this.attendanceClassList[i].name;
        }
      }
    },
    isLessThanToday(item){
      // console.log("isLessThanToday")
      // console.log(item.day);
      let date = new Date(this.scheduleMonth);
      let currentDate = new Date();
      date.setDate(item.day);
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      // console.log(date);
      // console.log(currentDate);
      // date = formatDate(date, 'YYYY-mm-dd');
      if(date > currentDate){
        return true;
      }else{
        return false;
      }
    },
    // 复制考勤组信息
    copyFormToData(data) {
      console.log("copyFormToData=");
      console.log(data)
      this.attGrpEmpIds = data.attGrpEmpIds.split(",");
      this.noAttendEmpIds = data.noAttendEmpIds ? data.noAttendEmpIds.split(",") : null;
      this.otherEmpIds = data.otherEmpIds ? data.otherEmpIds.split(",") : null;
      this.managerEmp = data.managerEmp ? data.managerEmp.split(",") : null;
      this.attType = data.type;
      if (data.type == 2) {
        this.scheduleIds = data.scheduleIds ? data.scheduleIds.split(",") : [];
        this.scheduleIdsChange(this.scheduleIds);
        this.schedule = data.schedule;
        this.scheduleCycleChange(this.schedule);
      }
      if (data.addr) {
        this.addr = data.addr.addr;
        this.makerPosition = data.addr.position;
        this.positionRange = data.addr.range;
      }
    },
    renderHeader(h, { column, $index }){
      const c = column.label.split('-');
      if(c.length > 1){
        return h('span',{}, [
          h('span',{}, c[0]),
          h('br'),
          h('span',{}, c[1]),
        ])
      }else {
        return h('span',{}, [
          h('span',{}, c[0]),
        ])
      }
    },
    normalizerDepartment(node) {
      return this.normalizer(node);
    },

    getFixDefLabel(item) {
      let str = item.name+"("+item.otherInfo.earlyOn;
      if(item.otherInfo.laterOffOver){
        str += " - 次日"+item.otherInfo.laterOff;
      }else{
        str += " - "+item.otherInfo.laterOff;
      }
      return str + " " + item.days + "天";
    },

    scheduleIdsChange(val) {
      console.log("update attGrpChanged to true.")
      this.attGrpChanged = true;
      this.$nextTick(()=>{
        console.log(val);
        console.log(this.attendanceClassList);
        let scheduleClassList = [];
        let i = 0;
        for (let v in val) {
          for (let cls in this.attendanceClassList) {
            if (this.attendanceClassList[cls].id == val[v]) {
              scheduleClassList.push(this.attendanceClassList[cls]);
              // 改变下拉框颜色值
              console.log("color "+i);
              console.log(this.$refs['refSchIds'].$el.children[0].children[0].children[i]);
              this.$refs['refSchIds'].$el.children[0].children[0].children[i].style.color = '' + this.attendanceClassList[cls].color + ''
              i++;
            }
          }
        }
        console.log(scheduleClassList);
        this.scheduleClassList = scheduleClassList;
      });
    },
    // 变更周期数
    scheduleCycleChange(schedule){
      // this.$nextTick(()=>{
      //   for(let i = 0; i < schedule.days; i++){
      //     this.onChangeCycleSchedule(schedule.attClass[i],"cycleSchedule"+(i+1));
      //   }
      // })
    },
    onChangeCycleSchedule($event, selectedRef) {
      console.log("selectedRef");
      console.log($event);
      console.log(selectedRef);
      // console.log(this.$refs[selectedRef][0].$children[0].$el);
      // for(let i = 0; i < this.attendanceClassList.length; i++){
      //   if(this.attendanceClassList[i].id == $event){
      //     this.$refs[selectedRef][0].$children[0].$el.children[0].style.color = '' + this.attendanceClassList[i].color + ''
      //     console.log(this.$refs[selectedRef][0].$children[0].$el.children[0])
      //   }
      // }
      console.log("update attGrpChanged to true.")
      this.attGrpChanged = true;
      this.$forceUpdate();
    },
    schMonthChange(val){
      console.log("click schMonthChange");
      console.log(val);
      this.$nextTick(()=>{
        console.log("schMonthChange showLoading");
        showLoading();
        for(let i = 0; i < this.employList.length; i++){
          this.employList[i].daySch = null;
        }
        this.schColDays = getDaysArrOfMonth(val);
        console.log("schMonthChange begain queryAttGrpClassSch");
        // this.$forceUpdate();
        // 月份改变后，需要重新查询排班数据
        this.queryAttGrpClassSch(this.attGrpId,this.scheduleMonth).then(()=>{
          console.log("schMonthChange queryAttGrpClassSch response");
          this.updateEmpSchedule();
          hideLoading();
          this.loading=false;
        });
      });
    },
    cellClick(row, column, cell, event){
      console.log('row='+row+',col='+column);
      console.log(row);
      console.log(column);
      console.log('cell='+cell);
      console.log(cell);
      console.log('event='+event);
      console.log(event);
      console.log("dropdown-")
      let refId = "dropdown-"+row.id+"-"+column.property;
      this.$nextTick(()=>{
        if('name' == column.property){
          console.log("click name");
        }else if(parseInt(column.property)>0){
          console.log(refId);
          console.log(this.$refs[refId]);
          this.$refs[refId][0].show();
        }
      });
    },
    handleCommand(command, scope) {
      // this.$nextTick(()=>{
      let cmdStr = ""+command;
      console.log('click on item ' + cmdStr);
      if("" == cmdStr){
        console.log('no command');
        return;
      }
      console.log(scope);
      console.log(scope.row.name);
      console.log(scope.column.property);
      let col = parseInt(scope.column.property);
      // 如果是周期排班
      if("cycle"==command){
        let attClassIdx = 0;
        let cycleDays = this.schedule.days;
        for(let i = col; i <= this.schColDays[this.schColDays.length-1].day; i++){
          const classIdx = attClassIdx%cycleDays;
          this.updateRowColSchedule(scope, i, classIdx);
          attClassIdx++;
        }
        console.log(scope.row.daySch)
      }else{
        this.updateRowColSchedule(scope, col, command);
      }
      // });
    },
    updateRowColSchedule(scope, col, classIdx){
      const day = this.schColDays[col-1].day;
      if(!scope.row.daySch){
        this.$set(scope.row,'daySch',[]);
        // scope.row.daySch = [];
      }
      let daySch = scope.row.daySch[day];
      if(daySch){
        daySch.attClassId = this.schedule.attClass[classIdx];
      }else{
        let date = new Date(this.scheduleMonth);
        date.setDate(day);
        date = formatDate(date, 'YYYY-mm-dd');
        daySch = {attGrpId:this.attGrpId, empId:scope.row.id, attClassId: this.schedule.attClass[classIdx],
          schDate: date};
      }
      if(daySch.attClassId > 0){
        daySch.text = this.getClassText(daySch.attClassId);
      }else{
        daySch.text = '';
      }
      if(!daySch.changeType) daySch.changeType = 'update';
      this.$set(scope.row.daySch,day,daySch);
    },
    saveChange(){
      // console.log()
      // 保存时可以考虑未修改的记录。 考勤组信息是否修改
      console.log("this.attGrpChanged="+this.attGrpChanged);
      this.submitForm();
    },
    resetChange(){
      for(let i = 0; i < this.employList.length; i++){
        this.employList[i].daySch = null;
      }
      this.updateEmpSchedule();
    },
    getColor(id){
      // console.log(id)
      return getRandomColor(id);
    },
    // 取消按钮
    cancel() {
      this.bus.$emit('onCurrentContextmenuClick', {
        id: 1,
        path: this.$route.path,
      });
    },
    // 表单重置
    reset() {
      this.form = {};
      this.resetForm("form");
    },

    /** 提交按钮 */
    submitForm() {
      let updateForm = {};
      updateForm.companyId = this.$store.getters.userInfo.companyId;
      updateForm.id = this.form.id
      if (this.attGrpChanged) {
        updateForm.scheduleIds = this.scheduleIds.join(",");
        updateForm.schedule = this.schedule;
      }
      // 提前修改的排班信息
      let updatedGrpClsSch = [];
      for(let i = 0; i < this.employList.length; i++) {
        const emp = this.employList[i];
        if (emp.daySch) {
          // 从1号开始找，修改记录
          for(let i = 1; i <= this.schColDays[this.schColDays.length-1].day; i++){
            const sch = emp.daySch[i];
            if(sch && sch.changeType=='update'){
              updatedGrpClsSch.push(sch);
            }
          }
        }
      }
      console.log(updatedGrpClsSch);
      updateForm.grpClsSchs = updatedGrpClsSch;
      if (updateForm.id != null) {
        showLoading();
        attArrange(updateForm).then(response => {
          hideLoading();
          const respForm = response.data;
          console.log("respForm=====");
          console.log(respForm);
          this.attGrpClsSch = respForm.grpClsSchs;
          this.updateEmpSchedule();
          this.msgSuccess("修改成功");
        });
      }
    },

  }
};

</script>

<style scoped>

</style>
