import request from '@/utils/request'

// 新增培训需求
export function addRequirement(data) {
    return request({
        url: '/TrainingRequirement/add',
        method: 'post',
        data: data
    })
}

// 更新培训需求
export function updateRequirement(data) {
    return request({
        url: '/TrainingRequirement/edit',
        method: 'post',
        data: data
    })
}

// 查找培训需求
export function findTrainingRequirement(id) {
    return request({
        url: '/TrainingRequirement/findOne?id='+id,
        method: 'post',
        data: data
    })
}


// 查找所有的培训需求
export function findAllTrainingRequirements(query) {
    return request({
        url: '/TrainingRequirement/findAll',
        method: 'post',
        data: query
    })
}

// 删除培训需求
export function deleteRequirement(ids) {
    return request({
        url: '/TrainingRequirement/remove',
        method: 'post',
        data: ids
    })
}