import request from '@/utils/request'

// 查询特殊日期列表
export function listAttSpecialDay(query) {
    return request({
        url: '/attendance/attSpecialDay/findAll',
        method: 'post',
        data: query
    })
}

// 查询特殊日期详细
export function getAttSpecialDay(id) {
    return request({
        url: '/attendance/attSpecialDay/findOne?id=' + id,
        method: 'post'
    })
}

// 新增特殊日期
export function addAttSpecialDay(data) {
    return request({
        url: '/attendance/attSpecialDay/add',
        method: 'post',
        data: data
    })
}

// 修改特殊日期
export function updateAttSpecialDay(data) {
    return request({
        url: '/attendance/attSpecialDay/edit',
        method: 'post',
        data: data
    })
}

// 删除特殊日期
export function delAttSpecialDay(id) {
    return request({
        url: '/attendance/attSpecialDay/remove',
        method: 'post',
        data: id
    })
}

// 导出特殊日期
export function exportAttSpecialDay(query) {
    return request({
        url: '/attendance/attSpecialDay/export',
        method: 'post',
        data: query
    })
}
