import request from '@/utils/request'

// 查询巡逻打卡列表
export function listAttPatrolClock(query) {
    return request({
        url: '/attendance/attPatrolClock/findAll',
        method: 'post',
        data: query
    })
}

export function getAttPatrolDate(query) {
    return request({
        url: '/attendance/attPatrolClock/getAttPatrolDate',
        method: 'post',
        data: query
    })
}

// 查询巡逻打卡详细
export function getAttPatrolClock(id) {
    return request({
        url: '/attendance/attPatrolClock/findOne?id=' + id,
        method: 'post'
    })
}

// 新增巡逻打卡
export function addAttPatrolClock(data) {
    return request({
        url: '/attendance/attPatrolClock/add',
        method: 'post',
        data: data
    })
}

// 修改巡逻打卡
export function updateAttPatrolClock(data) {
    return request({
        url: '/attendance/attPatrolClock/edit',
        method: 'post',
        data: data
    })
}

// 删除巡逻打卡
export function delAttPatrolClock(id) {
    return request({
        url: '/attendance/attPatrolClock/remove',
        method: 'post',
        data: id
    })
}

// 导出巡逻打卡
export function exportAttPatrolClock(query) {
    return request({
        url: '/attendance/attPatrolClock/export',
        method: 'post',
        data: query
    })
}
