import request from '@/utils/request'

// 查询离职申请列表
export function listOvertime(query) {
    return request({
        url: '/bpm/overtime/findAll',
        method: 'post',
        data: query
    })
}
// 查询转正申请详细
export function initApply() {
    return request({
        url: '/bpm/overtime/initApply',
        method: 'get'
    })
}

// 修改转正申请
export function completeTask(data) {
    return request({
        url: '/bpm/overtime/completeTask',
        method: 'post',
        data: data
    })
}
// 查询离职申请详细
export function getOvertime(id) {
    return request({
        url: '/bpm/overtime/findOne?id=' + id,
        method: 'post'
    })
}

export function getByProcessInstanceId(processInstanceId) {
    return request({
        url: '/bpm/overtime/getByProcessInstanceId?processInstanceId=' + processInstanceId,
        method: 'post'
    })
}

// 新增离职申请
export function addOvertime(data) {
    return request({
        url: '/bpm/overtime/add',
        method: 'post',
        data: data
    })
}

// // 修改离职申请
// export function updateOvertime(data) {
//     return request({
//         url: '/bpm/overtime/edit',
//         method: 'post',
//         data: data
//     })
// }

// 删除离职申请
export function delOvertime(id) {
    return request({
        url: '/bpm/overtime/remove',
        method: 'post',
        data: id
    })
}

// 导出离职申请
export function exportOvertime(query) {
    return request({
        url: '/bpm/overtime/export',
        method: 'post',
        data: query
    })
}
