import request from '@/utils/request'

// 查询消息管理详细
export function getMsgreceiver(id) {
    return request({
        url: '/msgreceiver/findOne?id=' + id,
        method: 'post'
    })
}

// 标记已读
export function markRead(ids){
    return request({
        url: '/msgreceiver/markRead',
        method: 'post',
        data: ids
    })
}
