<template>
	<el-collapse-item title="专业技术职称" :name="this.collapseStatus" 	:class="collapseSetting()">
		<el-form ref="dataFrom" :model="dataFrom" class="employ" label-width="150px">
			<el-row v-for="(professionalTechModel, index) in dataFrom.professionalTechesModel" :key="index" :class="newAddedAfterLocked(professionalTechModel)">
				<el-row>
					<el-row>
						<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
							<el-form-item label="专业技术职称">
								<el-input v-model="professionalTechModel.name" :readonly="canEdit(professionalTechModel)"></el-input>
							</el-form-item>
						</el-col>
						<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
							<el-form-item label="证书号码">
								<el-input v-model="professionalTechModel.number" :readonly="canEdit(professionalTechModel)"></el-input>
							</el-form-item>
						</el-col>
			
						<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
							<el-form-item label="职称等级">
								<el-input v-model="professionalTechModel.level" :readonly="canEdit(professionalTechModel)"></el-input>
							</el-form-item>
						</el-col>
						<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
							<el-form-item label="获取时间">
								<el-date-picker
									v-model="professionalTechModel.getDate"
									value-format="yyyy-MM-dd"
									:disabled="canEdit(professionalTechModel)"
									align="left"
									type="date"
									placeholder="选择日期"
								>
								</el-date-picker>
							</el-form-item>
						</el-col>
				
						<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
							<el-form-item label="聘用时间">
								<el-input v-model="professionalTechModel.employPeriod" :readonly="canEdit(professionalTechModel)"></el-input>
							</el-form-item>
						</el-col>
						<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
							<el-form-item label="聘用起始时间">
								<el-date-picker
									v-model="professionalTechModel.employStart"
									value-format="yyyy-MM-dd"
									:disabled="canEdit(professionalTechModel)"
									align="left"
									type="date"
									placeholder="选择日期"
								>
								</el-date-picker>
							</el-form-item>
						</el-col>
				
						<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
							<el-form-item label="聘用结束时间"
								:prop="'professionalTechesModel.' + index + '.employEnd'"
								:rules="[
									{
										validator: (rule, value, callback) => {
											endDateValidator(rule, value, callback, professionalTechModel.employStart);
										},
										trigger: 'change',
									}
								]"
							>
								<el-date-picker
									v-model="professionalTechModel.employEnd"
									value-format="yyyy-MM-dd"
									:disabled="canEdit(professionalTechModel)"
									align="left"
									type="date"
									placeholder="选择日期"
								>
								</el-date-picker>
							</el-form-item>
						</el-col>
						<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
							<el-form-item label="备注">
								<el-input v-model="professionalTechModel.remarks" :readonly="canEdit(professionalTechModel)"></el-input>
							</el-form-item>
						</el-col>
			
					
						<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
							<el-form-item label=" "> 
							<el-button
								plain
								class="employ-form-delete-button"
								:id="professionalTechModel.name"
								v-bind:disabled="canEdit(professionalTechModel)"
								v-on:click="deleteProfessionalTech($event, professionalTechModel.name)"
								type="danger"
								icon="el-icon-delete"
								>删除</el-button
							>
							</el-form-item>
						</el-col>
						<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
							<div style="margin-left:150px;margin-bottom:10px;">
								<el-upload
									:disabled="canEdit(professionalTechModel)"
									class="avatar-uploader"
									action="uploadCertifcatePicture()"
									:show-file-list="false"
									:on-success="handleAvatarSuccess"
									:http-request="(res)=>{return uploadCertifcatePicture(res,professionalTechModel)}"
									:auto-upload="true"
									accept=".jpg,.jpeg,.png,.bmp,application/pdf"
									:before-upload="beforeAvatarUpload">
									<div v-if="professionalTechModel.certificateUrl">
										<pdf v-if="professionalTechModel.certificateOriginalFileName!=null&&professionalTechModel.certificateOriginalFileName.endsWith('.pdf')" :src="professionalTechModel.certificateUrl" :page="1" class="avatar"></pdf>
										<img v-else :src="professionalTechModel.certificateUrl" class="avatar">
									</div>
									
									<i v-else class="el-icon-plus avatar-uploader-icon"></i>
								</el-upload>
								<el-dialog :visible.sync="dialogVisible" @close="closeDialog">
									<pdf v-if="professionalTechModel.certificateOriginalFileName!=null&&professionalTechModel.certificateOriginalFileName.endsWith('.pdf')" :src="professionalTechModel.certificateUrl" :page="1"></pdf>
									<img v-else width="100%" :src="dialogImageUrl" alt="" />
								</el-dialog>
								<p class="scaleFont">支持jpg、jpeg、bmp、png、pdf格式，大小不超过50M。</p>
								<el-button plain type="primary" v-if="professionalTechModel.certificateUrl"  size="mini" icon="el-icon-zoom-in" @click="handlePictureCardPreview(professionalTechModel.certificateUrl)">预览</el-button>
							</div>
						</el-col>
					</el-row>
					<div
						style="
							width: 80%;
							border-top: 1px solid;
							color: #ebeef5;
							height: 10px;
							margin-left: 10%;
							border-bottom-width: 1px;
							border-left-width: 1px;
							text-align: center;
						"
					></div>
				</el-row>
			</el-row>

			<el-row>
				<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
					<el-form-item label=" ">
						<el-button v-on:click="addProfessionalTech" type="primary" v-bind:disabled="enableAdd" plain icon="el-icon-plus">增加</el-button>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8" class="mb0">
					<el-form-item label=" ">
						<el-button v-on:click="edit" type="primary" v-bind:disabled="hasLocked?true:this.isReadOnly" plain icon="el-icon-edit">编辑</el-button>
						<el-button v-on:click="save" plain v-bind:disabled="this.isReadOnly" :loading="saving" type="primary" icon="el-icon">保存</el-button>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
	</el-collapse-item>
</template>


<script>
import { uploadCertifcatePictureApi,downloadCertificatePictureApi} from "@/api/employ/employ";
import Pdf from 'vue-pdf';
export default {
	components: {
		Pdf,
	},
	data() {
		let readonlyInit = true;
		let professionalTechesInit = new Array();
		let initAddItem = false;
		if (this.professionalTechques == null || (this.professionalTechques != null && this.professionalTechques.length==0)) {
			professionalTechesInit.push({
				name: '',
				number: null,
				level: null,
				getDate: null,
				employPeriod: null,
				employStart: null,
				employEnd: null,
				certificateUrl:null,
				certificateOriginalFileName:null,
				certificateTempUrl:null,
				newAdded:this.locked?true:false,
				remarks: null,
			});

			initAddItem=true;
		} else {
			professionalTechesInit = this.professionalTechques;
		}

		//  isReadOnly=true for view, others for edit. Users can't eidt if locked, but they can add 
		if(this.isReadOnly || this.locked)
		{
			readonlyInit = true;
		}
		else{
			readonlyInit = false;
		}

		return {
			dataFrom:{professionalTechesModel: professionalTechesInit},
			readonly: readonlyInit,
			enableAdd:this.enableAddButton,
			hasLocked:this.locked,
			saving:false,
			initItem:initAddItem,
			dialogImageUrl: '',
			dialogVisible:false,
			pickerOptions1: {
				disabledDate(time) {
					return time.getTime() > Date.now();
				},
			},
		};
	},
	props: { professionalTechques: Array, id: Number, isReadOnly: Boolean,collapseStatus:String,locked:Boolean,enableAddButton:Boolean   },
	watch: {
		isReadOnly(newValue,oldV) {
			this.readonly = newValue;
			//this.hasLocked = newValue;
		},
		locked(newValue,oldV) {
			this.hasLocked = newValue;
			if (this.hasLocked) {
				this.readonly = true;
			}
			else
			{
				this.readonly = false;
			}
		},
	},
	created(){ 
		if (this.professionalTechques !=null && this.professionalTechques.length >0) {
				this.professionalTechques.forEach(item=>{
					if(item.certificateUrl != null && item.certificateUrl.length > 0)
					{
						this.downloadCertificatePictures(item,item.certificateUrl);
					}
				});

				this.$forceUpdate()
		}
	},
	computed: {
        newAddedAfterLocked() {
            return function(currentItem){
                return {employ_item_update:this.locked&&currentItem.newAdded && !this.initItem &&!this.isReadOnly};         
            }           
        },
		canEdit(){
			 return function(currentItem){
				 let returnValue = true;
				 if(this.hasLocked){
					 returnValue =currentItem.newAdded&&!this.isReadOnly?false:true;
				 }
				 else{
					returnValue = this.readonly;
				 }
                return returnValue;      
            }   
		} ,
		collapseSetting(){
			return function(){
				if(this.dataFrom.professionalTechesModel !=null && this.dataFrom.professionalTechesModel.length >0)
				{
					if(this.locked)
					{
						let newAddResults = this.dataFrom.professionalTechesModel.filter(item=>item.newAdded==true);
						if(newAddResults.length > 0)
						{
							if(this.initItem)
							{
								return {callpse_header_unfill:true};
							}
							else{
								return {callpse_header_newAdd:true};
							}
						}
						else
						{
							return {callpse_header_fill:true};
						}
						
					}
					else
					{
						if(this.initItem)
						{
							return {callpse_header_unfill:true};
						}
						else{
							return {callpse_header_fill:true};
						}
					}
					
				}
				else
				{
					return {callpse_header_unfill:true};
				}
				
			}
		},
    },
	methods: {
		save() {
			this.$refs.dataFrom.validate(valid => {
			let self = this;
			if (valid) {
				// save data to DB
				let savingProfessionalTeches = {};
				savingProfessionalTeches.id = this.id;
				//savingProfessionalTeches.skills = this.dataFrom.professionalTechesModel;
				savingProfessionalTeches.skills= new Array();
				self.dataFrom.professionalTechesModel.forEach(item=>{savingProfessionalTeches.skills.push({...item})});
				savingProfessionalTeches.skills.forEach(item=>{item.certificateUrl=item.certificateTempUrl});
				this.saving = true;
				this.$emit('updatePartialEmployeeInfo', { data: savingProfessionalTeches }, (response) => {
					this.saving=false;
					if (response.success) {
						this.msgSuccess('修改成功');
						this.readonly = true;
					} else {
						this.msgError('修改失败,' + response.msg);
					}
				});
			} 
			else {
						this.msgError('有非法输入或必填项未填写，请检查!');
				}
			});
		},
		edit() {
			this.readonly = false;
		},
		addProfessionalTech() {
			this.readonly = false;
			this.dataFrom.professionalTechesModel.push({
				name: '',
				number: null,
				level: null,
				getDate: null,
				employPeriod: null,
				employStart: null,
				employEnd: null,
				certificateUrl:null,
				certificateOriginalFileName:null,
				certificateTempUrl:null,
				newAdded:true,
				remarks: null,
			});
		},
		deleteProfessionalTech(event, name) {
			let self = this;
			if (event.currentTarget.id == name) {
				this.$confirm('确定要删除吗?', '确认', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'error',
					// type: 'warning'
				})
					.then(() => {
						if (self.dataFrom.professionalTechesModel.length > 0) {
							let foundIndex = self.dataFrom.professionalTechesModel.findIndex((x) => x.name === name);
							if (foundIndex > -1) {
								self.dataFrom.professionalTechesModel.splice(foundIndex, 1);
							}
						}
					})
					.catch(() => {
						this.msgError('删除发生异常!');
					});
			}
		},
		endDateValidator(rule, value, callback, startDate) {
			// 必需每个分支都要有返回，否则进不去保存的validate方法。
            if(startDate==null || startDate =="")
			{
				return callback(new Error("先选起始日期。"));
			}
			else
			{
				if(value==null || value == ""){
					return callback(new Error("有起始日期，终止日期不能为空。"));
				}
				else{
					if (value < startDate) {
						return callback(new Error("终止日期不能小于起始日期。"));
					}
					else
					{
						return callback();
					}
				}
				
			}
            
        },
		uploadCertifcatePicture(params, currentDataModel) {
			if (params != undefined) {
				this.formData = new FormData();
				this.formData.append('file', params.file);
				currentDataModel.certificateOriginalFileName = params.file.name;
				let self = this;
				uploadCertifcatePictureApi(this.formData)
					.then((response) => {
						if (response.status == 200) {
							let resData = response.data;
							currentDataModel.certificateTempUrl = response.headers['content-disposition'].split('=')[1];
							var blob = new Blob([resData], { type: 'application/octet-stream' });
							currentDataModel.certificateUrl = URL.createObjectURL(blob);
							this.$forceUpdate();
							this.$message.success('上传成功!');
						} else {
							this.$message.error('上传失败：');
						}
					})
					.catch((err) => {
						this.$message.error('上传失败：' + err);
					});
			}
		},

		handleAvatarSuccess(res, file) {
			//this.form.photo = URL.createObjectURL(file.name);
		},
		beforeAvatarUpload(file) {
			const isJPG = file.type === 'image/jpeg' || file.type === 'image/jpg';
			const isPng = file.type === 'image/png';
			const isBmp = file.type === 'application/bmp';
			const ispdf = file.type === 'application/pdf';
			const isLt50M = file.size / 1024 / 1024 < 50;
			if (!isJPG && !isPng && !ispdf && !isBmp) {
				this.$message.error('上传图片只能是 JPG,JPEG,PNG,BMP,PDF 格式!');
			}
			if (!isLt50M) {
					this.$message.error('上传图片大小不能超过 50MB!');
			}
			return (isJPG || isPng || ispdf || isBmp) && isLt50M;
		},
		downloadCertificatePictures(currentComponent,filePath)
		{
			if(filePath!=null && filePath.length > 0)
			{
				// download 
				downloadCertificatePictureApi(filePath)
					.then((res) => {
						if (res) {
							let resData = res.data;
							var blob = new Blob([resData], { type: 'application/octet-stream' });
							currentComponent.certificateUrl =URL.createObjectURL(blob);
							
						}
						else
						{
							this.msgError(`后端下载文件出错 & ${e}`);
						}
					})
					.catch((e) => {
						this.loading = false;
						this.msgError(`后端下载文件出错 & ${e}`);
					});
			}
		},
		handlePictureCardPreview(file) {
			this.dialogImageUrl = file;
			this.dialogVisible = true;
		},
		closeDialog() {
			this.dialogImageUrl = '';
			this.dialogVisible = false;
		},
	},
	
};
</script>

<style>
</style>