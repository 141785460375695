<template>
    <div style="border: cyan; border-width: thin;border-style: solid;padding: 5px; margin: 5px;">
    
            <div style="width:150px;height:150px;margin-left:auto;margin-right:auto;" v-loading="pictureLoading">
                <img width="100%" height="100%" :src="agencyPhoto" alt=""  style="border-radius: 50%" />
            </div>
            <div>
                <table width="100%" style="margin-top:5px;word-break:break-word;" >
                    <tr>
                        <td colspan="2" style="text-align:center;font-weight:600;">{{agency.name}} </td>
                    </tr>
                    <tr>
                        <td style="width:35%;">公司电话：</td>
                        <td>{{agency.officeTelephone}}</td>
                    </tr>
                    <tr>
                        <td>公司地址：</td>
                        <td class="textFormat">{{agency.address}}</td>
                    </tr>
                     <tr>
                        <td>联系人：</td>
                        <td class="textFormat">{{agency.contactor}}</td>
                    </tr>
                     <tr>
                        <td>联系人电话：</td>
                        <td class="textFormat">{{agency.contactPhone}}</td>
                    </tr>
                </table>
            </div>
            <div style="display:block;text-align:center;">
                <el-button plain type="primary" @click="viewAgency()">查看</el-button>
                <el-button plain type="primary" @click="editAgency()">编辑</el-button>
                <el-button plain type="primary" @click="deleteAgency()">删除</el-button>     
               
               
            </div>
   
    </div>
</template>

<script>
import {downloadPersonalPhoto} from '@/api/employ/employ';
export default {
    name: 'agencyView',

    data() {
        let agencyInit = this.agencyProp;
        return {
            //agency:{...this.agencyProp},
            agency:this.agencyProp,
            agencyPhoto:'',
            defaultPhoto:'/static/agency.jpeg',
            pictureLoading:false,
        };
    },
    props:{agencyProp:Object},
    watch: {
		agencyProp(newValue,oldV) {
			this.agency = newValue;
            if(newValue.photoUrl != oldV.photoUrl){
                this.downloadAgencyPhoto();
            }
            
            this.$forceUpdate();
		},
	},
    created(){
        
        if(this.agency.photoUrl != null && this.agency.photoUrl.length>0)
        {
			this.downloadAgencyPhoto();
        }
        else
        {
            this.agencyPhoto=this.defaultPhoto;
        }
        
    },
    
    mounted() {
        
    },

    methods: {
        viewAgency(){
             this.$emit('viewSelectAgency', { data:this.agencyProp});  
        },
        editAgency(){
             this.$emit('updateSelectAgency', { data:this.agencyProp});  
        },
        deleteAgency(){
             this.$emit('deleteSelectAgency', { data:this.agencyProp});  
        },
        downloadAgencyPhoto(){
            this.pictureLoading = true;
            downloadPersonalPhoto(this.agency.photoUrl)
                .then((res) => {
                    if (res) {
                        let resData = res.data;
                        var blob = new Blob([resData], { type: 'application/octet-stream' });
                        this.agencyPhoto= URL.createObjectURL(blob);
                    } else {
                        this.msgError(`后端下载文件出错 & ${e}`);
                    }
                    this.pictureLoading=false;
                })
                .catch((e) => {
                    //this.loading = false;
                    this.pictureLoading = false;
                    this.msgError(`后端下载文件出错 & ${e}`);
                });
        },
    },
};
</script>

<style lang="scss" scoped>
    .textFormat{
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            overflow-x: hidden;
            width: 150px;
            word-break: keep-all;
            display: block;
    }
</style>