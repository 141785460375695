<template>
<div>
  <div class="head-container">
    <el-input
        v-model="nameFilter"
        placeholder="请输入名称"
        clearable
        size="small"
        prefix-icon="el-icon-search"
        style="margin-bottom: 20px"
    />
  </div>
  <div class="head-container">
    <el-tree
        :data="treeData"
        :props="defaultProps"
        :expand-on-click-node="false"
        :filter-node-method="filterNode"
        ref="tree"
        default-expand-all
        @node-click="handleNodeClick"
        highlight-current
    />
  </div>
</div>
</template>

<script>
import {getOrgTree} from './orgtree';

export default {
  name: "OrgTree",
  data() {
    return {
      treeData: [],
      selectedCompanyId: 1,
      selectedDepartmentId: null,
      // 公司树选项
      companyOptions: [],
      // 部门管理树选项
      allDepartmentOptions: [],
      departmentOptions: [],
      positionList: [],
      defaultProps: {
        children: "children",
        label: "name"
      },
      nameFilter: undefined,
    }
  },
  watch: {
    // 根据名称筛选部门树
    nameFilter(val) {
      this.$refs.tree.filter(val);
    }
  },
  created() {
    this.selectedCompanyId = this.$store.getters.userInfo.companyId;
    getOrgTree((tree, companyList, departmentList, positionList )=>{
      this.treeData = tree;

      this.allDepartmentOptions = [];
      const dataDepartment = { id:0,
        name:'顶级部门',
        children: []
      };
      dataDepartment.children = this.handleTree(departmentList, "id", "pid");
      this.allDepartmentOptions.push(dataDepartment);

      this.companyOptions = [];
      this.companyOptions = this.handleTree(companyList, "id", "pid");

      this.positionList = positionList;

    });
  },
  methods: {
    // 筛选节点
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    // 节点单击事件
    handleNodeClick(data) {
      this.$emit("handleNodeClick", {data:data});
    },
    close() {

    }
  }
}
</script>

<style scoped>

</style>
