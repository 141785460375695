<template>
<div class="home">
  <el-row :gutter="15" class="mt15">
    <el-col :md="24" :lg="8" :xl="8" class="mb15">
      <el-card shadow="hover">
        <div slot="header">
          <span>{{ title1 }}</span>
        </div>
        <div class="charts">
          <div class="charts-right">
            <div ref="homeStockRef" class="h100" style="height:100%;width:100%;"></div>
          </div>
        </div>
      </el-card>
    </el-col>
    <el-col :md="24" :lg="16" :xl="16" class="mb15">
      <el-card shadow="hover">
        <div slot="header">
          <span>{{ title2 }}</span>
        </div>
        <div class="charts">
          <div class="charts-left mr7">
            <div ref="homeLaboratoryRef" class="h100" style="height:100%;width:100%;"></div>
          </div>
        </div>
      </el-card>
    </el-col>
  </el-row>
</div>
</template>

<script>
import * as echarts from 'echarts';


export default {
  name: "statisticChart",
  props: {
    title1: String,
    title2: String,
    charData: Array,
    companyIds: Array,
    departmentOptionsIn: Array,
    isReadOnly: Boolean,
    departmentId: Number,
  },
  watch: {
    charData() {
      this.initPie();
      this.initBar();
    },
  },
  data() {
    return {
      chartPie: null
    }
  },
  mounted() {
    this.initPie();
    this.initBar();
  },
  methods:{
    // 库存作业
    initPie() {
      console.log("initPie");
      let data = [];
      this.charData.forEach(item=>{
        if(Array.isArray(this.companyIds) && this.companyIds.length>1){
          data.push({value:item.ct, name:item.departmentName+"("+item.companyName+")"})
        }else{
          data.push({value:item.ct, name:item.departmentName})
        }
      });
      const myChart = echarts.init(this.$refs.homeStockRef);
      const option = {
        grid: {
          top: 50,
          right: 20,
          bottom: 30,
          left: 30,
        },
        tooltip: {
          trigger: 'item',
        },
        legend: {
          left: 'center',
        },
        series: [
          {
            name: this.title1,
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2,
            },
            data: data,
            top: 30,
          },
        ],
      };
      myChart.setOption(option);
      window.addEventListener('resize', () => {
        myChart.resize();
      });
    },
    // 履约情况
    initBar() {
      let data = [];
      let xData = [];
      this.charData.forEach(item=>{
        if(Array.isArray(this.companyIds) && this.companyIds.length>1){
          xData.push(item.departmentName+"("+item.companyName+")");
          data.push(item.ct);
        }else{
          xData.push(item.departmentName);
          data.push(item.ct);
        }
      });


      const myChart = echarts.init(this.$refs.homeLaboratoryRef);
      const option = {
        grid: {
          top: 50,
          right: 20,
          bottom: 30,
          left: 30,
        },
        tooltip: {
          trigger: 'axis',
        },
        legend: {
          data: ['人数'],
          right: 13,
        },
        xAxis: {
          data: xData,
        },
        yAxis: [
          {
            type: 'value',
            name: '人数',
          },
        ],
        series: [
          {
            name: '部门人数',
            type: 'bar',
            data: data,
          },
          // {
          //   name: '最新成交价',
          //   type: 'line',
          //   data: [15, 20, 16, 20, 30, 8],
          // },
        ],
      };
      myChart.setOption(option);
      window.addEventListener('resize', () => {
        myChart.resize();
      });
    },

  },

}
</script>

<style scoped lang="scss">
@import './index.scss';
//.home {
//  width: 100%;
//  overflow: hidden;
//  .charts {
//    width: 100%;
//    height: 282.6px;
//    display: flex;
//    padding: 12px 15px;
//    .charts-left {
//      flex: 1;
//      height: 100%;
//    }
//    .charts-right {
//      flex: 1;
//      height: 100%;
//    }
//  }
//}
</style>
