import request from '@/utils/request'

// 查询消息管理列表
export function listMsg(query) {
    return request({
        url: '/msg/findAll',
        method: 'post',
        data: query
    })
}

// 查询消息管理详细
export function getMsg(id) {
    return request({
        url: '/msg/findOne?id=' + id,
        method: 'post'
    })
}

// 新增消息管理
export function addMsg(data) {
    return request({
        url: '/msg/add',
        method: 'post',
        data: data
    })
}

// 修改消息管理
export function updateMsg(data) {
    return request({
        url: '/msg/edit',
        method: 'post',
        data: data
    })
}

// 删除消息管理
export function delMsg(id) {
    return request({
        url: '/msg/remove',
        method: 'post',
        data: id
    })
}

// 导出消息管理
export function exportMsg(query) {
    return request({
        url: '/msg/export',
        method: 'post',
        data: query
    })
}
