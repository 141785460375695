<template>
    <div>
        <div>
            <el-button @click="addNewTeacher">新增</el-button>
        </div>
        <div v-loading='loading' style="min-height:100px;">
             <el-row :gutter="24">
                	<el-col v-for="teacher in teacherList" :key="teacher.id" :xs="12" :sm="8" :md="6" :lg="6" :xl="6" class="mb0">
							<teacher-view :teacherProp="teacher" @viewSelectTeacher="viewSelectTeacher" @updateSelectTeacher="updateSelectTeacher" @deleteSelectTeacher="deleteSelectTeacher"></teacher-view>
					</el-col>
                    <div v-show="total == 0" style="color:red;font-size: large;  text-align: center;">
                        本公司及子公司无培训讲师。
                    </div>
             </el-row>
            <pagination v-show="total > 0" :total="total" :page.sync="queryParameters.pageNum" :limit.sync="queryParameters.pageSize" @pagination="GetAllTeachers" />
        </div>
        <el-dialog :title="dialogTitle" :visible.sync='showDialog' :width="editDialogWidth"  appendhandleUpdate-to-body >
        	<teacher v-if="showDialog" :initReadonly="viewTeacher" :initTeacher="selectedTeacher" :newTeacher="addTeacher" :updateTeacher="updateTeacher"  @close="closeTeacherDialog" ></teacher>
    	</el-dialog>
    </div>
</template>

<script>
import { findTeachers,deleteTeachers } from '@/api/training/trainingTeacher';
import Pagination from "@/components/Pagination";
import teacherView from './teacherView.vue';
import teacher from './teacher.vue';
import {Local} from '@/utils/storage.js'

export default {
    name: 'teacherList',
    components: {
		Pagination,
        teacherView,
        teacher
	},
    data() {
        return {
            teacherList:[],
            queryParameters: {
				startDate: '',
				endDate: '',
				courseName: '',
				courseType: '',
				companyId:'',
				pageSize: 10,
				pageNum: 1,
			},
            loading:false,
            total:-1,
            where: {
				query: '',
				filters: [],
			},
            dialogTitle:"",
            showDialog:false,
            selectedTeacher:null,
            viewTeacher:false,
            addTeacher:false,
            updateTeacher:false,
            uniApp:false,
            editDialogWidth:"60%",
            
        };
    },
    created(){
        this.GetAllTeachers();
        this.uniApp=Local.get("isUniapp")?true:false;
         if(this.uniApp)
        {
            this.editDialogWidth = "90%";
        }
    },

    methods: {
        addNewTeacher(){
            this.dialogTitle="新增讲师";
            this.showDialog = true;
            this.addTeacher=true;
            this.viewTeacher = false;
            this.updateTeacher = false;
             this.selectedTeacher = null;
        },
        updateSelectTeacher(arg){
			 this.dialogTitle="编辑讲师";
            this.showDialog = true;
            this.addTeacher=false;
            this.viewTeacher = false;
            this.updateTeacher = true;
            this.selectedTeacher = arg.data;
		},
		viewSelectTeacher(arg){
			 this.dialogTitle="查阅讲师";
            this.showDialog = true;
            this.addTeacher=false;
            this.viewTeacher = true;
            this.updateTeacher = false;
             this.selectedTeacher = arg.data;
		},
		deleteSelectTeacher(arg){
			
            let that = this;
			this.$confirm('确定删除选中的讲师?', "警告", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			}).then(function () {
                let selectIds = [];
                selectIds.push(arg.data.id);
				 deleteTeachers(selectIds).then((response)=>{
                     if (response.success) {
                        that.GetAllTeachers();
						that.$message.success('删除成功!');
					} else {
						that.$message.error('删除失败：');      
					}
                 });
			});
		},
        closeTeacherDialog(arg){
            this.showDialog=false;
            if(arg.refresh){
				this.GetAllTeachers();
			}
        },
        GetAllTeachers()
        {
            let filter = {};
            const filters = this.parseFilters(filter);
            if (filters && filters.length > 0) {
                this.where.filters = filters;
            } else {
                // 如果过滤条件为空，设置空数组
                this.where.filters = [];
            }
            const params = {
                pageNum: this.queryParameters.pageNum,
                pageSize: this.queryParameters.pageSize,
                where: this.where,
                order: '',
            };
            this.loading = true;
            findTeachers(params).then((response) => {
                this.teacherList = response.data.records;
                this.total = response.data.total;;
                this.loading=false;
            }).catch((err) => {
                this.loading=false;
                that.msgError('查询讲师失败，' + err);
			});;
        },
    },
};
</script>

<style lang="scss" scoped>
   
</style>