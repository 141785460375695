import request from '@/utils/request'

// 查询操作日志列表
export function listEhrtrace(query) {
    return request({
        url: '/system/ehrtrace/findAll',
        method: 'post',
        data: query
    })
}

// 查询操作日志详细
export function getEhrtrace(id) {
    return request({
        url: '/system/ehrtrace/findOne?id=' + id,
        method: 'post'
    })
}


// 导出操作日志
export function exportEhrtrace(query) {
    return request({
        url: '/system/ehrtrace/export',
        method: 'post',
        data: query
    })
}
