<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px">
      <template v-if="whereFrom == 'self' && isCompnayVisible" >
      <el-form-item label="公司" prop="companyId">
        <treeselect v-model="queryParams.companyId" style="width: 198px"
                    :options="companyOptions"
                    :normalizer="normalizerCompany" placeholder="请选择所属公司"/>
      </el-form-item>
      </template>
      <el-form-item label="用户名" prop="username">
        <el-input
            v-model="queryParams.username"
            placeholder="请输入用户名"
            clearable
            size="small"
            @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="姓名" prop="name">
        <el-input
            v-model="queryParams.name"
            placeholder="请输入姓名"
            clearable
            size="small"
            @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item label="手机号" prop="mobile">
        <el-input
            v-model="queryParams.mobile"
            placeholder="请输入手机号"
            clearable
            size="small"
            @keyup.enter.native="handleQuery"
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <template v-if="whereFrom == 'self'">
        <el-col :span="1.5">
          <el-button
              type="primary"
              plain
              icon="el-icon-plus"
              size="mini"
              @click="handleAdd"
              v-permission="['/user/add']"
          >新增
          </el-button>
        </el-col>
        <el-col :span="1.5">
          <el-button
              type="success"
              plain
              icon="el-icon-edit"
              size="mini"
              :disabled="single"
              @click="handleUpdate"
              v-permission="['/user/edit']"
          >修改
          </el-button>
        </el-col>
        <el-col :span="1.5">
          <el-button
              type="danger"
              plain
              icon="el-icon-delete"
              size="mini"
              :disabled="multiple"
              @click="handleDelete"
              v-permission="['/user/remove']"
          >删除
          </el-button>
        </el-col>
        <el-col :span="1.5">
          <el-button
              type="warning"
              plain
              icon="el-icon-download"
              size="mini"
              @click="handleExport"
              v-permission="['/user/export']"
          >导出
          </el-button>
        </el-col>
      </template>
      <el-col :span="1.5" v-if="whereFrom == 'employ'">
        <el-button
            type="primary"
            plain
            icon="el-icon-plus"
            size="mini"
            :disabled="multiple"
            @click="handlesSetEmploy"
            v-permission="['/employ/employ/add']"
        >注册入职
        </el-button>
      </el-col>
<!--      <el-col :span="1.5" v-if="whereFrom == 'employ'">-->
<!--        <el-button-->
<!--            type="primary"-->
<!--            plain-->
<!--            icon="el-icon-plus"-->
<!--            size="mini"-->
<!--            @click="handEntryEmploy"-->
<!--            v-permission="['/employ/employ/add']"-->
<!--        >人事入职-->
<!--        </el-button>-->
<!--      </el-col>-->
      <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>
    </el-row>

    <el-table v-loading="loading" :data="userList" @selection-change="handleSelectionChange" :row-class-name="tableRowClassName" style="width:100%" stripe border>
      <el-table-column type="selection" width="55" align="center"/>
      <el-table-column label="用户名" align="center" prop="username" width="120" sortable show-overflow-tooltip />
      <el-table-column label="姓名" align="center" prop="name" width="120" sortable show-overflow-tooltip />
      <el-table-column label="所属公司" align="center" prop="companyId" show-overflow-tooltip width="210" sortable>
        <template slot-scope="scope">
            {{companyFormat(scope.row, null)}}
        </template>
      </el-table-column>
      <el-table-column label="手机号" align="center" prop="mobile" width="130" sortable/>
      <el-table-column label="角色" align="center" width="210" sortable show-overflow-tooltip>
        <template slot-scope="scope">
          <el-tag
              :key="tag.id"
              v-for="tag in scope.row.roles"
              :disable-transitions="false"
          >
            {{tag.zhName}}
          </el-tag>
        </template>
      </el-table-column>
      <!--      <el-table-column label="公司" align="center" prop="companyName" width="130"/>-->
      <el-table-column label="图标" align="center" prop="avatar" :show-overflow-tooltip="true"/>
      <el-table-column label="员工" align="center" prop="userEnabled">
        <template slot-scope="scope">
          <el-tooltip placement="top">
            <div slot="content">
              注册账号，办理入职后才能登录
            </div>
            <span v-if="scope.row.userEnabled == 0">注册账号</span>
            <span v-if="scope.row.userEnabled == 1">正常用户</span>
            <span v-if="scope.row.userEnabled == 2">离职账号</span>
            <span v-if="scope.row.userEnabled == 10">弃用账号</span>
            <!--          <el-switch disabled-->
            <!--                     v-model.number="scope.row.userEnabled"-->
            <!--                     active-color="#13ce66"-->
            <!--                     inactive-color="#ff4949"-->
            <!--                     :active-value=1-->
            <!--                     :inactive-value=0 >-->
            <!--          </el-switch>-->
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column label="注册时间" align="center" prop="registerDate" width="180" sortable>
      </el-table-column>
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width"  width="230">
        <template slot-scope="scope">
          <template v-if="whereFrom == 'self'">
            <el-button
                size="mini"
                type="text"
                icon="el-icon-edit"
                @click="handleUpdate(scope.row)"
                v-permission="['/user/edit']"
            >修改
            </el-button>
            <el-button
                size="mini"
                type="text"
                icon="el-icon-delete"
                @click="handleDelete(scope.row)"
                v-permission="['/user/remove']"
            >删除
            </el-button>
          </template>
          <el-button v-if="scope.row.userEnabled == 0 && whereFrom == 'employ'"
                     size="mini"
                     type="text"
                     icon="el-icon-plus"
                     @click="handlesSetEmploy(scope.row)"
                     v-permission="['/employ/employ/add']"
          >入职
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
        v-show="total>0"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
    />

    <!-- 添加或修改用户管理对话框 -->
    <el-dialog :title="title" :visible.sync="open" append-to-body>
      <UserEdit :row="row" :companyOptionsIn="companyOptions" :companyListIn="companyList" @close="close"></UserEdit>
    </el-dialog>
    <el-dialog title="员工入职" :visible.sync="openEmp" append-to-body>
      <employ-edit :row="row" :company-id="companyId" @close="close" ></employ-edit>
    </el-dialog>
  </div>
</template>

<script>
import {
  listUser,
  getUser,
  delUser,
  addUser,
  updateUser,
  exportUser } from "@/api/sys/user";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import RightToolbar from "@/components/RightToolbar"
import permission from '@/directive/permission/index.js' // 权限判断指令
import Pagination from "@/components/Pagination";
import {getTreeselectCompany} from "@/api/org/company";
import {validatorCompanyId, verifyIdCard, isPassword,validatePhone, verifyAccount, verifyFullName} from "@/utils/validate.js"
import { listRole } from "@/api/sys/role";
import { showLoading, hideLoading } from '@/utils/reqloading';
import UserEdit from './userEdit';
import EmployEdit from '@/views/pages/employ/employ/employEdit'
// 表查询配置
const dbOrderBy = ''

export default {
  name: "User",
  components: {
    Treeselect,
    RightToolbar,
    Pagination,
    UserEdit,
    EmployEdit,
  },
  props: {
    whereFrom: {
      type: String,
      default: 'self',
    }
  },
  directives: { permission },

  data() {
    return {
      row: null,
      companyId: null,
      isAddOpen: true,
      // 遮罩层
      loading: true,
      // 查询参数
      where: {
        query: '',
        filters: []
      },

      // 选中数组
      ids: [],
      selectedRow: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 用户管理表格数据
      userList: [],
      // 公司树选项
      isCompnayVisible: true,
      companyOptions: [],
      companyList: [],
      // 角色选项
      roleOptions: [],

      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      openEmp: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        orderby: dbOrderBy,
        companyId: null,
        username: null,
        name: null,
        mobile: null,
        userEnabled: null,
      },
      // 表单参数
      form: {
      }
      ,
      // 表单校验
      rules: {
        companyId: [
          {
            required: true,
            message: "所属公司不能为空或顶级节点,必须选择公司", trigger: "blur" },
          {validator: validatorCompanyId, trigger: "blur"}
        ],
        username: [
          {
            required: true,
            message: "登录用户名不能为空", trigger: "blur" },
          {validator: verifyAccount,trigger: "blur"}
        ],
        name: [
          {
            required: true,
            message: "用户姓名不能为空", trigger: "blur" },
          {validator: verifyFullName,trigger: "blur" }
        ],
        idNum: [
          {
            required: true,
            message: "身份证号不能为空", trigger: "blur" },
          {validator: verifyIdCard,trigger: "blur" }
        ],
        mobile: [
          {
            required: true,
            message: "手机号不能为空", trigger: "blur" },
          {validator: validatePhone,trigger: "blur" }
        ],
        userEnabled: [
          {
            required: true,
            message: "是否启用不能为空", trigger: "blur" }
        ],
      }
    };
  },
  created() {
    this.companyId = this.$store.getters.userInfo.companyId;
    this._filter = {};
    this.getCompany();
    this.getRoles();
    this.getList();
  },
  methods: {
    validatePwd(rule, value, callback) {
      // isPassword(rule,value,callback);
    },
    chooseDTChange(){
      this.$forceUpdate();
    },
    tableRowClassName({row, rowIndex}) {
      if (row.userEnabled === 1) {
        return 'success-row';
      } else if (row.userEnabled === 0) {
        return 'warning-row';
      }
      return '';
    },
    /** 查询用户管理列表 */
    getQuery(){
      this._filter = {};
      if(this.queryParams.companyId) {
        this._filter['companyId'] = {
          type: 'eq',
          value: this.queryParams.companyId,
        }
      }
      if(this.queryParams.username) {
        this._filter['username'] = {
          type: 'search',
          value: this.queryParams.username,
        }
      }
      if(this.queryParams.name) {
        this._filter['name'] = {
          type: 'search',
          value: this.queryParams.name,
        }
      }
      if(this.queryParams.mobile) {
        this._filter['mobile'] = {
          type: 'search',
          value: this.queryParams.mobile,
        }
      }
      if(this.queryParams.avatar) {
        this._filter['avatar'] = {
          type: 'search',
          value: this.queryParams.avatar,
        }
      }
      if(this.queryParams.registerDate) {
        this._filter['registerDate'] = {
          type: 'search',
          value: this.queryParams.registerDate,
        }
      }
      if(this.whereFrom === "employ") {
        this._filter['userEnabled'] = {
          type: 'eq',
          value: 0,
        }
        // this._filter['companyId'] = {
        //   type: 'eq',
        //   value: this.$store.getters.userInfo.companyId,
        // }
      }

      const filters = this.parseFilters(this._filter)
      if (filters && filters.length > 0) {
        this.where.filters = filters
      } else {
        // 如果过滤条件为空，设置空数组
        this.where.filters = []
      }
      const params = {
        pageNum: this.queryParams.pageNum,
        pageSize: this.queryParams.pageSize,
        where: this.where,
        order: this.orderby
      }
      return params;
    },
    getList() {
      this.loading = true;
      const params = this.getQuery();
      listUser(params).then(response => {
        this.userList = response.data.records;
        this.total = response.data.total;
        this.loading = false;
      });
    },

    /** 转换公司数据结构 */
    normalizerCompany(node) {
      return this.normalizer(node,'id','companyName');
    },
    /** 查询公司下拉树结构 */
    getCompany() {
      getTreeselectCompany(null, (list, options) =>{
        this.companyList = list;
        if(this.companyList.length > 1){
          this.isCompnayVisible = true;
        }
        this.companyOptions = options;
      });
    },

    companyFormat(row, column) {
      // console.log(row.departmentId);
      for(var i = 0; i < this.companyList.length; i++){
        const item =  this.companyList[i];
        // console.log("id:"+item.id+",depid:"+row.departmentId+", name :"+item.name+",ee="+(item.id == row.departmentId));
        if(item.id == row.companyId){
          return item.companyName;
        }
      }
      return "";
    },

    getRoles(){
      const params = {
        pageNum: 1,
        pageSize: 1000,
        where: null,
        order: null,
      }
      return listRole(params).then(response => {
        this.roleOptions = response.data.records;
        return response;
      });

    },

    close(arg){
      this.open = false;
      if(arg.refresh){
        this.getList();
      }
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.selectedRow = selection;
      this.ids = selection.map(item => item.id)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.row = {};
      this.open = true;
      this.title = "添加用户";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      if(row.id){
        this.row = row;
      }else{
        this.row = {};
        this.row.id = this.ids;
      }
      this.open = true;
      this.title = "修改用户";
    },

    /** 删除按钮操作 */
    handleDelete(row) {
      let ids = [];
      if(row.id){
        ids = [row.id];
      }else{
        ids = this.ids;
      }
      this.$confirm('是否确认删除用户数据?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return delUser(ids);
      }).then(() => {
        this.getList();
        this.msgSuccess("删除成功");
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      // const queryParams = this.queryParams;
      const params = this.getQuery();
      this.$confirm('是否确认导出所有用户管理数据项?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return exportUser(params);
      }).then(response => {
        this.download(response.msg);
      })
    },
    handlesSetEmploy(row){
      let users = [];
      if(row.id){
        users = [row];
      }else{
        users = this.selectedRow;
      }
      if(users.length < 1){
        return;
      }
      const companyId = users[0].companyId;
      for(var i = 0; i < users.length; i++){
        const user = users[i];
        if(user.companyId !== companyId){
          this.$message.error('必须选择同一公司下的用户');
          return;
        }
      }
      console.log(companyId);
      console.log(users);
      // console.log(this.selectedRow);
      if(this.whereFrom === "self"){
        this.$router.push({name:"employ_employ_add_vue", params: { users: users , companyId: companyId}});
      }else if(this.whereFrom === "employ") {
        this.$emit("select", {users: users, companyId: companyId});
      }
    },
    handEntryEmploy(){
      this.row = {companyId: this.companyId ,time: Date.parse(new Date())};
      this.openEmp = true;
    }
  }
};
</script>
<style scoped>
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>
