<template>
  <div class="app-container">
    <el-row :gutter="20">
      <!--公司选择-->
      <el-col v-if="isCompnayVisible" :span="4" :xs="24">
        <div class="head-container">
          <el-input
              v-model="companyName"
              placeholder="请输入公司名称"
              clearable
              size="small"
              prefix-icon="el-icon-search"
              style="margin-bottom: 20px"
          />
        </div>
        <div class="head-container">
          <el-tree
              :data="companyOptions"
              :props="defaultProps"
              :expand-on-click-node="false"
              :filter-node-method="filterNode"
              ref="tree"
              default-expand-all
              @node-click="handleNodeClick"
              highlight-current
          />
        </div>
      </el-col>
      <!--部门信息-->
      <el-col :span="20" :xs="24">
        <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" label-width="68px">
          <el-form-item label="部门编号" prop="num">
            <el-input
                v-model="queryParams.num"
                placeholder="请输入部门编号"
                clearable
                size="small"
                @keyup.enter.native="handleQuery"
            />
          </el-form-item>
          <el-form-item label="部门名称" prop="name">
            <el-input
                v-model="queryParams.name"
                placeholder="请输入部门名称"
                clearable
                size="small"
                @keyup.enter.native="handleQuery"
            />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
            <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
          </el-form-item>
        </el-form>

        <el-row :gutter="10" class="mb8">
          <el-col :span="1.5">
            <el-button
                type="primary"
                plain
                icon="el-icon-plus"
                size="mini"
                @click="handleAdd"
                v-permission="['/org/department/add']"
            >新增
            </el-button>
          </el-col>
          <el-col :span="1.5">
            <el-button
                type="primary"
                plain
                icon="el-icon-d-caret"
                size="mini"
                @click="handleMove"
                v-permission="['/org/department/add','/org/department/edit']"
            >调整
            </el-button>
          </el-col>
          <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>
        </el-row>

        <el-table
            v-loading="loading"
            :data="departmentList"
            row-key="id"
            default-expand-all
            :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
            style="width:100%" stripe border
        >
          <el-table-column label="部门编号" align="center" prop="num" width="180"/>
          <el-table-column label="部门名称" align="center" prop="name" show-overflow-tooltip=""/>
          <el-table-column label="编制人数" align="center" prop="planCount"/>
          <el-table-column label="在编人数" align="center" prop="realCount"/>
          <el-table-column label="缺编人数" align="center">
            <template slot-scope="scope"><span v-if="scope.row.planCount&&(scope.row.planCount-scope.row.realCount)>0" style="color: #c75151">
              {{scope.row.planCount - scope.row.realCount}}
            </span></template>
          </el-table-column>
          <el-table-column label="区域" align="center" prop="regionId"
                           :formatter="regionidFormat" width="120"/>
          <el-table-column label="操作" align="center" class-name="small-padding fixed-width" width="150">
            <template slot-scope="scope">
              <el-button
                  size="mini"
                  type="text"
                  icon="el-icon-edit"
                  @click="handleUpdate(scope.row)"
                  v-permission="['/org/department/edit']"
              >修改
              </el-button>
              <el-button
                  size="mini"
                  type="text"
                  icon="el-icon-delete"
                  @click="handleDelete(scope.row)"
                  v-permission="['/org/department/remove']"
              >撤销
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>

    <!-- 添加或修改部门管理对话框 -->
    <el-dialog :title="title" :visible.sync="open" append-to-body>
      <DepartmentEdit :row="row" :companyId="queryParams.companyId"
                      :companyOptionsIn="companyOptions" :regionidOptionsIn="regionidOptions"  @close="close"></DepartmentEdit>
    </el-dialog>
    <el-dialog title="部门调整" :visible.sync="openDepartmentAdjust" :center="true" append-to-body>
      <el-tree
          :data="departmentAdjustTree"
          :props="departmentProps"
          :expand-on-click-node="false"
          ref="tree"
          default-expand-all
          node-key="id"
          @node-drag-start="handleDragStart"
          @node-drag-enter="handleDragEnter"
          @node-drag-leave="handleDragLeave"
          @node-drag-over="handleDragOver"
          @node-drag-end="handleDragEnd"
          @node-drop="handleDrop"
          draggable
          :allow-drop="allowDrop"
          :allow-drag="allowDrag"
      />
      <div slot="footer" class="dialog-footer" style="text-align: center">
        <el-button type="primary" @click="submitDepartmentAdjust" v-permission="['/org/department/add', '/org/department/edit']">提 交</el-button>
        <el-button @click="closeDepartmentAdjust">取 消</el-button>
        <el-popover
            placement="top"
            title=""
            width="200"
            trigger="click"
            content="请直接拖到想要调整的部门到目标部门下面，则可以调整部门结构。点提交保存调整后的结果。">
          <el-button type="text" round slot="reference">？</el-button>
        </el-popover>
      </div>

    </el-dialog>
  </div>
</template>

<script>
import {
  listDepartment,
  getDepartment,
  delDepartment,
  addDepartment,
  updateDepartment,
  exportDepartment } from "@/api/org/department";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import RightToolbar from "@/components/RightToolbar"
import permission from '@/directive/permission/index.js' // 权限判断指令
import { getTreeselectCompany } from "@/api/org/company";
import {validatorCompanyId} from "@/utils/validate.js";
import DepartmentEdit from "./departmentEdit";
import {getChangedIdMapPid} from "../../../../utils/tools";
import {updateDepartments} from "../../../../api/org/department";
import { showLoading, hideLoading } from '@/utils/reqloading';
// 表查询配置
const dbOrderBy = 'seq asc'
// 分页配置
const pageSize = 1000
const pageCurrent = 1
export default {
  name: "Department",
  components: {
    Treeselect,
    RightToolbar,
    DepartmentEdit,
  },

  directives: { permission },

  data() {
    return {
      isCompnayVisible: false,
      row: null,
      // 遮罩层
      loading: true,
      // 显示搜索条件
      showSearch: true,
      // 部门管理表格数据
      departmentList: [],
      departmentAdjustTree: null,
      // 公司树选项
      companyOptions: [],
      companyList: [],
      // 区域id字典
      regionidOptions: [],
      defaultProps: {
        children: "children",
        label: "companyName"
      },
      departmentProps: {
        children: "children",
        label: "name"
      },
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      openDepartmentAdjust: false,
      // 查询公司名称
      companyName: undefined,
      selectedCompanyName: undefined,
      // 查询参数
      where: {
        query: '',
        filters: []
      },
      orderby: dbOrderBy,
      options: {
        pageSize,
        pageCurrent
      },
      // 查询参数
      queryParams: {
        companyId: null,
        num: null,
        name: null,
      },
      adjustDepartment: [],
    };
  },
  watch: {
    // 根据名称筛选部门树
    companyName(val) {
      this.$refs.tree.filter(val);
    }
  },
  created() {
    if(this.$store.getters.userInfo.constParentCompanyId == this.$store.getters.userInfo.companyId){
      this.isCompnayVisible = true;
    }
    this._filter = {};
    this.queryParams.companyId = this.$store.getters.userInfo.companyId;
    this.selectedCompanyName = this.$store.getters.userInfo.companyName;
    getTreeselectCompany(null, (list, options) =>{
      this.companyList = list;
      if(this.companyList.length > 1){
        this.isCompnayVisible = true;
      }
      this.companyOptions = options;
    });
    this.getDicts("sys_location_region").then(response => {
      this.regionidOptions = response.data;
    });
    this.getList();
  },
  methods: {
    /** 查询部门管理列表 */
    getList() {
      this.loading = true;
      this._filter = {};
      if(this.queryParams.companyId) {
        this._filter['companyId'] = {
          type: 'select',
          value: [this.queryParams.companyId],
        }
      }
      if(this.queryParams.num) {
        this._filter['num'] = {
          type: 'search',
          value: this.queryParams.num,
        }
      }
      if(this.queryParams.name) {
        this._filter['name'] = {
          type: 'search',
          value: this.queryParams.name,
        }
      }
      const filters = this.parseFilters(this._filter)
      if (filters && filters.length > 0) {
        this.where.filters = filters
      } else {
        // 如果过滤条件为空，设置空数组
        this.where.filters = []
      }
      const params = {
        pageNum: this.options.pageCurrent,
        pageSize: this.options.pageSize,
        where: this.where,
        order: this.orderby
      }

      listDepartment(params).then(response => {
        this.departmentList = this.handleTree(response.data.records, "id", "pid");
        this.loading = false;
      });
    },

    // 筛选节点
    filterNode(value, data) {
      if (!value) return true;
      return data.companyName.indexOf(value) !== -1;
    },
    // 节点单击事件
    handleNodeClick(data) {
      this.queryParams.companyId = data.id;
      this.selectedCompanyName = data.companyName;
      this.getList();
    },
    // 区域id字典翻译
    regionidFormat(row, column) {
      return this.selectDictLabel(this.regionidOptions, row.regionId);
    },

    /** 搜索按钮操作 */
    handleQuery() {
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.row = {};
      this.open = true;
      this.title = "添加部门";
    },
    /** 修改按钮操作 */
    handleUpdate(row) {
      if(row.id){
        this.row = row;
      }else{
        this.row = {};
        this.row.id = this.ids;
      }
      this.open = true;
      this.title = "修改部门";
    },
    handleMove(){
      this.departmentAdjustTree = [];
      const data = { id:0,
        name: this.selectedCompanyName,
        children: []
      };
      data.children = JSON.parse(JSON.stringify(this.departmentList));
      this.departmentAdjustTree.push(data);
      this.adjustDepartment = [];
      this.openDepartmentAdjust = true;
    },
    submitDepartmentAdjust(){
      console.log(this.departmentAdjustTree);
      const idMapPid = getChangedIdMapPid(this.departmentAdjustTree[0],"id","pid","children",this.departmentAdjustTree[0].id);
      console.log(idMapPid);
      const that = this;
      this.$confirm('是否确认更新部门的结构?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        showLoading();
        updateDepartments(idMapPid).then(response => {
          that.msgSuccess("更新成功");
          that.getList();
          hideLoading();
          that.openDepartmentAdjust = false;
        });
      }).catch(() => {});
      // this.adjustDepartment.forEach(item=> {
      //   console.log(item);
      // });
    },
    closeDepartmentAdjust(){
      this.openDepartmentAdjust = false;
    },
    handleDragStart(node, ev) {
      // console.log('drag start', node);
    },
    handleDragEnter(draggingNode, dropNode, ev) {
      // console.log('tree drag enter: ', dropNode.label);
    },
    handleDragLeave(draggingNode, dropNode, ev) {
      // console.log('tree drag leave: ', dropNode.label);
    },
    handleDragOver(draggingNode, dropNode, ev) {
      // console.log('tree drag over: ', dropNode.label);
    },
    handleDragEnd(draggingNode, dropNode, dropType, ev) {
      console.log(draggingNode.label, ',tree drag end: ', dropNode && dropNode.label, dropType);
      // console.log(draggingNode);
      // console.log(dropNode);
      // console.log(this.adjustDepartment[draggingNode.data.id]);
      // console.log(dropNode.data.id);
      // this.adjustDepartment[draggingNode.data.id] = dropNode.data.id;
      // console.log(this.adjustDepartment[draggingNode.data.id]);
    },
    handleDrop(draggingNode, dropNode, dropType, ev) {
      // console.log('tree drop: ', dropNode.label, dropType);
    },
    allowDrop(draggingNode, dropNode, type) {
      if (dropNode.data.id == 0) {
        return type === 'inner';
      } else if(type === 'inner'){
        return true;
      }else{
        return false;
      }
    },
    allowDrag(draggingNode) {
      console.log("draggingNode");
      console.log(draggingNode);
      console.log("draggingNode");
      return draggingNode.data.id != 0;
    },
    close(arg){
      this.open = false;
      if(arg.refresh){
        this.getList();
      }
    },

    /** 删除按钮操作 */
    handleDelete(row) {
      this.$confirm('是否确认撤销部门"' + row.name + '"?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return delDepartment([row.id]);
      }).then(() => {
        this.getList();
        this.msgSuccess("删除成功");
      })
    }
  }
};
</script>
