<template>
  <div>
    <CorrectTimeClockEdit type="apply" :isShowClose="true"></CorrectTimeClockEdit>
  </div>
</template>

<script>
import CorrectTimeClockEdit from "./correctTimeClockEdit";
export default {
  name: "apply",
  components:{
    CorrectTimeClockEdit,
  }
}
</script>

<style scoped>

</style>
