import request from '@/utils/request'

// 查询薪酬模板列表
export function listCbSalaryTemplate(query) {
    return request({
        url: '/cb/cbSalaryTemplate/findAll',
        method: 'post',
        data: query
    })
}

// 查询薪酬模板详细
export function getCbSalaryTemplate(id) {
    return request({
        url: '/cb/cbSalaryTemplate/findOne?id=' + id,
        method: 'post'
    })
}

// 新增薪酬模板
export function addCbSalaryTemplate(data) {
    return request({
        url: '/cb/cbSalaryTemplate/add',
        method: 'post',
        data: data
    })
}

// 修改薪酬模板
export function updateCbSalaryTemplate(data) {
    return request({
        url: '/cb/cbSalaryTemplate/edit',
        method: 'post',
        data: data
    })
}

// 删除薪酬模板
export function delCbSalaryTemplate(id) {
    return request({
        url: '/cb/cbSalaryTemplate/remove',
        method: 'post',
        data: id
    })
}

// 导出薪酬模板
export function exportCbSalaryTemplate(query) {
    return request({
        url: '/cb/cbSalaryTemplate/export',
        method: 'post',
        data: query
    })
}
