<template>
    <div>
        <el-form :model="queryParameters" ref="queryParameters" class="employ" label-width="100px">
			<el-row :gutter="24">
				<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
					<el-form-item label="公司">
						<el-select v-model="companySelect" clearable @clear="handleClearable" placeholder="请选择"
							collapse-tags ref="companySelected">
							<el-option :value="queryParameters.companyId" :label="companySelect" style="height: auto">
								<el-tree :data="companyOptions" :props="defaultProps" :expand-on-click-node="false"
									ref="tree" show-checkbox node-key="id" default-expand-all
									@node-click="handleNodeClick" @check="handleNodeClick" highlight-current/>
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
					<el-form-item label="起始时间">
						<el-date-picker v-model="queryParameters.startDate" value-format="yyyy-MM-dd 00:00:00"
							align="left" type="date" placeholder="选择日期">
						</el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
					<el-form-item label="终止时间">
						<el-date-picker v-model="queryParameters.endDate" value-format="yyyy-MM-dd 24:00:00"
							align="left" type="date" placeholder="选择日期">
						</el-date-picker>
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
					<el-form-item label="试卷名称">
						<el-input v-model="queryParameters.name" placeholder="培训计划名称" clearable />
					</el-form-item>
				</el-col>
				<el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6" class="mb0">
					<el-form-item label=" " style="margin-bottom:0px !important;">
						<el-button v-on:click="query" size="mini" icon="el-icon-search" plain
							type="primary">查询</el-button>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<el-row :gutter="10" class="mb8">
			<el-col :span="1.5" style="margin-top:15px;">
				<el-button type="primary" plain icon="el-icon-plus" size="mini" @click="handleAdd"
					v-permission="['/TrainingPlan/add']">新增 </el-button>
			</el-col>
			<el-col :span="1.5" style="margin-top:15px;">
				<el-button type="success" plain icon="el-icon-edit" size="mini" :disabled="!single"
					@click="handleUpdate2" v-permission="['/TrainingPlan/edit']">修改
				</el-button>
			</el-col>
			<el-col :span="1.5" style="margin-top:15px;">
				<el-button type="danger" plain icon="el-icon-delete" size="mini" :disabled="!multiple"
					@click="handleDelete" v-permission="['/TrainingPlan/delete']">删除
				</el-button>
			</el-col>
		</el-row>
		<el-row :gutter="24">
			<el-table v-loading="loading" :data="testPaperList" style="width: 100%;" max-height="100%"
				@selection-change="handleSelectionChange" stripe border>
				<el-table-column type="selection" width="55" align="center" />
				<el-table-column label="编号ID" align="center" prop="id" v-if="false" />
				<el-table-column label="公司名称" align="center" prop="companyName" min-width="150" sortable> </el-table-column>
				<el-table-column label="试卷名称" align="center" prop="testPaperName" sortable min-width="150"
					:show-overflow-tooltip="true" >
					 <template slot-scope="scope" >
						<el-button size="mini" type="text"
							@click="handleView(scope.row)">
							{{ scope.row.testPaperName }}
						</el-button>
					</template>
		  		</el-table-column>
				<el-table-column label="总分" align="center" prop="totalScore" min-width="150" sortable
					:show-overflow-tooltip="true" />
				<el-table-column label="创建者" align="center" prop="creator" min-width="150" sortable
					:show-overflow-tooltip="true" />
				<el-table-column label="创建日期" align="center" prop="createTime" sortable min-width="100">
					<template slot-scope="scope">
						<span>{{ parseTime(scope.row.createTime) }}</span>
					</template>
				</el-table-column>
				<el-table-column label="操作" align="center" min-width="150" fixed="right">
					<template slot-scope="scope">
						<el-button size="mini" class="btn baseManageBtn" title="编辑" type="text" icon="el-icon-edit"
							@click="handleUpdate(scope.row)" v-permission="['/TrainingPlan/edit']"></el-button>
						<el-button size="mini" class="btn baseManageBtn" title="删除" type="text" icon="el-icon-delete"
							@click="handleDelete2(scope.row)" v-permission="['/TrainingPlan/delete']"></el-button>
					</template>
				</el-table-column>
			</el-table>
		</el-row>
		<pagination v-show="total > 0" :total="total" :page.sync="queryParameters.pageNum"
			:limit.sync="queryParameters.pageSize" @pagination="query" />
		 <el-dialog :title="title" :visible.sync='showTestPaper' :width="uniApp?'90%':'70%'" :append-to-body='true' :close-on-click-modal="false" >
        	<test-paper v-if="showTestPaper" :selectTestPaperId="selectTestPaperId" :isNew="newTestPaper"   @close="closeTestPaper" ></test-paper>
    	</el-dialog>
		<el-dialog title="" :visible.sync='showTestingPaper' :width="uniApp?'90%':'70%'" :append-to-body='true' :close-on-click-modal="false" >
        	<testing-paper v-if="showTestingPaper" :selectTestPaperId="selectTestPaperId" :testCount="-1"  @close="closeTestingPaper" ></testing-paper>
    	</el-dialog>
    </div>
</template>

<script>
import permission from '@/directive/permission/index.js'; // 权限判断指令
import { getTreeselectCompany } from "@/api/org/company";
import Pagination from "@/components/Pagination";
import testPaper from "./testPaper.vue"
import testingPaper from "./testingPaper.vue"
import { Local } from '@/utils/storage.js'

import { findAllTestPapers,deleteTestPaper } from "@/api/training/trainingTestPaper"

export default {
    name: 'testPaperList',
    components: {
		Pagination,
		testPaper,
		testingPaper
	},
	directives: { permission },
    data() {
        return {
            companyList: [],
			total: 0,
			loading: false,
			companySelect: '',
			companyId:'',
			// 公司树选项
			companyOptions: [],
			defaultProps: {
				children: 'children',
				label: 'companyName',
			},
            queryParameters: {
				startDate: '',
				endDate: '',
				name: '',
				companyId: '',
				pageSize: 10,
				pageNum: 1,
			},
			where: {
				query: '',
				filters: [],
			},
			title:"",
			uniApp: false,
			showTestPaper:false,
			showTestingPaper:false,
			selectTestPaperId:'',
			newTestPaper:false,
			isTester:false,
			testPaperList:[],
			multiple:false,
			single:false,

			// 选中数组
     		 ids: [],
        };
    },

    mounted() {
        
    },
	created() {
		this.uniApp =  Local.get("isUniapp") ? true : false;
		this.load();
		this.query();
	},

    methods: {
		load() {
			getTreeselectCompany(null, (list, options) => {
				this.companyList = list;
				if (this.companyList.length > 1) {
					this.isCompnayVisible = true;
				}
				this.companyOptions = options;
			});
		},

        // 节点单击事件
		handleNodeClick(data) {
			this.queryParameters.companyId = data.id;
			this.companyId = data.id;
			this.companySelect = data.companyName;
			let selectArray = [];
			selectArray.push(this.companyId);
			this.$refs.tree.setCheckedKeys(selectArray);

			this.$refs.companySelected.blur();
		},
		handleClearable() {
			this.$refs.tree.setCheckedKeys([]);
			this.companyId = null;
			this.companySelect = '';
		},
		handleSelectionChange(selection){
			this.ids = selection.map((item) => item.id);
			this.single = selection.length == 1;
			this.multiple = selection.length>=1;
			this.selectTestPaperId=this.ids[0];
		},
		
		query(){
			let filter = {};
			if (this.companyId) {
				filter['companyId'] = {
					type: 'eq',
					value: this.companyId,
				};
			}
			if (this.queryParameters.name) {
				filter['testpapername'] = {
					type: 'search',
					value: this.queryParameters.name,
				};
			}
			

			if (this.queryParameters.startDate && this.queryParameters.endDate) {
				let tempDate = this.queryParameters.startDate.replace(/-/g, '/'); //必须把日期'-'转为'/'
				var startDateTimeStamp = new Date(tempDate).getTime();
				tempDate = this.queryParameters.endDate.replace(/-/g, '/');
				var endDateTimeStamp = new Date(tempDate).getTime();
				filter['createdTime'] = {
					type: 'timestamp',
					value: [startDateTimeStamp, endDateTimeStamp],
				};
			}

			const filters = this.parseFilters(filter);
			if (filters && filters.length > 0) {
				this.where.filters = filters;
			} else {
				// 如果过滤条件为空，设置空数组
				this.where.filters = [];
			}
			const params = {
				pageNum: this.queryParameters.pageNum,
				pageSize: this.queryParameters.pageSize,
				where: this.where,
				order: '',
			};
			this.loading = true;
            findAllTestPapers(params).then((response) => {
                this.testPaperList = response.data.records;
                this.total = response.data.total;
                this.loading=false;
            }).catch((err) => {
                this.loading=false;
                this.msgError('查询试卷失败，' + err);
			});
		},
		handleView(row) {
			this.showTestingPaper=true;
			this.newTestPaper = false;
			this.isTester=true;
			this.selectTestPaperId=row.id;
		},
		handleAdd(){
			this.title="增加新的试卷";
			this.showTestPaper=true;
			this.newTestPaper = true;
			this.isTester=false;
		},
		handleUpdate(){
			if(!this.selectTestPaperId){
				this.msgError('请先选择一份试卷!' );
				return;
			}
			this.title="更新试卷";
			this.showTestPaper=true;
			this.newTestPaper =false;
			this.isTester=false;
		},
		handleUpdate2(){
			
			this.title="更新试卷";
			this.showTestPaper=true;
			this.newTestPaper = false;
			this.isTester=false;
		},

		handleDelete(){
			let that = this;
			this.$confirm('确定删除选中的试卷?', "警告", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			}).then(function () {
				return deleteTestPaper(that.ids);
			}).then(() => {
				that.query();
				that.msgSuccess("删除成功");
			})
		},
		handleDelete2(row){
			let that = this;
			this.$confirm('确定删除选中的试卷?', "警告", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			}).then(function () {
				let ids = [row.id];
				return deleteTestPaper(ids);
			}).then(() => {
				that.query();
				that.msgSuccess("删除成功");
			})
		},
		closeTestPaper(params){
			this.showTestPaper = false;
			if (params.refresh) {
				this.query();
			}
		},
		closeTestingPaper(params){
			this.showTestingPaper = false;
			
		},
		parseTime(rowDateTime)
		{
			if(rowDateTime != null && rowDateTime.indexOf('T')> 0)
			{
				return rowDateTime.substr(0,10);
			}
			else{
				return "";
			}
		},
		
    },
};
</script>

<style lang="scss" scoped>

</style>