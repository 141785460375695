<template>
  <div class="app-container">
    <el-form :model="queryParams" ref="queryForm" :inline="true" v-show="showSearch" >
      <el-form-item label="打卡时间" :label-width="uniApp? '120px':'75px'" >
        <el-date-picker type="datetime"  clearable size="small" v-model="queryParams.clockTimeStart" value-format="yyyy-MM-dd HH:mm" placeholder="选择开始时间"/>
        至
        <el-date-picker type="datetime"   clearable size="small" v-model="queryParams.clockTimeEnd" value-format="yyyy-MM-dd HH:mm" placeholder="选择结束时间"/>
      </el-form-item>
      <el-form-item label="员工姓名" label-width="75px">
        <el-input v-model="queryParams.empName" placeholder="请输入员工姓名" clearable size="small" @keyup.enter.native="handleQuery"/>
      </el-form-item>
      <el-form-item label="打卡类型" label-width="75px">
        <el-select v-model="queryParams.type" placeholder="请选择打卡类型" clearable size="small">
          <el-option v-for="item in getDictDatas(DICT_TYPE.ATT_CLOCK_TIME_TYPE)" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="部门" label-width="75px">
        <treeselect v-model="queryParams.depId" :options="departmentOptions" :multiple="true" :flat="true"
                    :normalizer="normalizerDepartment" placeholder="请选择部门" style="min-width:205px;" />
      </el-form-item>
<!--      <el-form-item label="拍照人脸打卡是否通过" prop="photo">-->
<!--        <el-input-->
<!--            v-model="queryParams.photo"-->
<!--            placeholder="请输入拍照人脸打卡是否通过"-->
<!--            clearable-->
<!--            size="small"-->
<!--            @keyup.enter.native="handleQuery"-->
<!--        />-->
<!--      </el-form-item>-->
<!--      <el-form-item label="拍照人脸url" prop="photoAddr">-->
<!--        <el-input-->
<!--            v-model="queryParams.photoAddr"-->
<!--            placeholder="请输入拍照人脸url"-->
<!--            clearable-->
<!--            size="small"-->
<!--            @keyup.enter.native="handleQuery"-->
<!--        />-->
<!--      </el-form-item>-->
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" size="mini" @click="handleQuery">搜索</el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row :gutter="10" class="mb8">
      <el-col :span="1.5">
        <el-button type="primary" plain icon="el-icon-plus" size="mini"
            @click="handleAdd" v-permission="['/attendance/attClockTime/add']" >新增
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button type="danger" plain icon="el-icon-delete" size="mini" :disabled="multiple"
                   @click="handleDelete" v-permission="['/attendance/attClockTime/remove']" >删除
        </el-button>
      </el-col>
<!--      <el-col :span="1.5">-->
<!--        <el-button type="danger" plain icon="el-icon-edit" size="mini"-->
<!--                   @click="calAttStatic" v-permission="['/attendance/attClockTime/add']" >计算考勤统计-->
<!--        </el-button>-->
<!--      </el-col>-->
      <el-col :span="1.5">
        <el-button type="success" plain icon="el-icon-download" size="mini" @click="downloadModel" >下载模板
        </el-button>
      </el-col>
      <el-col :span="1.5">
        <el-upload v-permission="['/attendance/attClockTime/import']"
                   action
                   :name="form.url"
                   class="upload-demo"
                   ref="upload"
                   :limit="1"
                   :http-request="uploadFile"
                   :on-preview="handlePreview"
                   :on-remove="handleRemove"
                   :file-list="fileList"
                   accept=".csv"
                   :auto-upload="true">
          <el-button slot="trigger" size="mini" type="warning" plain>导入</el-button>
        </el-upload>
      </el-col>
      <right-toolbar :showSearch.sync="showSearch" @queryTable="getList"></right-toolbar>
    </el-row>

    <el-table v-loading="loading" :data="attClockTimeList" @selection-change="handleSelectionChange" stripe border>
      <el-table-column type="selection" width="55" align="center"/>
<!--      <el-table-column label="公司名称" align="center" prop="companyId"/>-->
      <el-table-column label="部门名称" align="center" prop="depName" width="200px">
        <template slot-scope="scope">{{ getDepartmentName(scope.row.depId)}}</template>
      </el-table-column>
      <el-table-column label="员工姓名" align="center" prop="empName" width="100px"/>
      <el-table-column label="类型" align="center" prop="type"/>
      <el-table-column label="打卡时间" align="center" prop="clockTime" width="180"/>
<!--      <el-table-column label="上下班" align="center" prop="clockOnOff"/>-->
      <el-table-column label="打卡距离(米)" align="center" prop="addr"/>
      <el-table-column label="wifi" align="center" prop="wifi"/>
      <el-table-column label="打卡机" align="center" prop="machine"/>
<!--      <el-table-column label="拍照人脸打卡是否通过" align="center" prop="photo"/>-->
<!--      <el-table-column label="拍照人脸url" align="center" prop="photoAddr"/>-->
      <el-table-column label="操作" align="center" class-name="small-padding fixed-width" width="110px">
        <template slot-scope="scope">
          <el-button size="mini" type="text" v-if="scope.row.type == '数据导入'"
              icon="el-icon-edit" @click="handleUpdate(scope.row)"
              v-permission="['/attendance/attClockTime/import']" >修改
          </el-button>
          <el-button size="mini" type="text"
              icon="el-icon-delete" @click="handleDelete(scope.row)"
              v-permission="['/attendance/attClockTime/remove']" >删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
        v-show="total>0"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="getList"
    />

    <!-- 添加或修改打卡记录对话框 -->
    <el-dialog :title="title" :visible.sync="open" append-to-body>
      <el-form ref="form" :model="form" :rules="rules" label-width="100px">
        <el-row :gutter="35">
<!--          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">-->
<!--            <el-form-item label="公司id" prop="companyId">-->
<!--              <el-input v-model="form.companyId" placeholder="请输入公司id"/>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
            <el-form-item label="员工姓名" prop="empName">
              <el-input v-if="form.id" :disabled="form.id>0" v-model="form.empName" placeholder="请输入员工姓名"/>
              <el-select v-if="!form.id" style="width: 100%" v-model="form.empId" filterable remote reserve-keyword placeholder="请输入员工姓名" :remote-method="searchUsers"
                         @change="selectEmploy" :loading="searchLoading">
                <el-option
                    v-for="item in employs"
                    :key="item.id"
                    :label="item.name+'('+item.workNum+')'"
                    :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
            <el-form-item label="打卡类型" prop="type">
              <el-select disabled v-model="form.type" placeholder="请选择打卡类型">
                <el-option v-for="item in getDictDatas(DICT_TYPE.ATT_CLOCK_TIME_TYPE)" :key="item.value" :label="item.label" :value="item.value" :disabled="item.disabled"/>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
            <el-form-item label="打卡时间" prop="clockTime">
              <el-date-picker clearable size="large"
                              v-model="form.clockTime"
                              type="datetime"
                              value-format="yyyy-MM-dd HH:mm:ss"
                              placeholder="选择打卡时间"
                              style="width:100%">
              </el-date-picker>
            </el-form-item>
          </el-col>
<!--          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">-->
<!--            <el-form-item label="上下班" prop="clockOnOff">-->
<!--              <el-select v-model="form.clockOnOff" placeholder="请选择上下班">-->
<!--                <el-option label="上班" value="上班"/>-->
<!--                <el-option label="下班" value="下班"/>-->
<!--              </el-select>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">-->
<!--            <el-form-item label="拍照人脸打卡是否通过" prop="photo">-->
<!--              <el-input v-model="form.photo" placeholder="请输入拍照人脸打卡是否通过"/>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
<!--          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">-->
<!--            <el-form-item label="拍照人脸url" prop="photoAddr">-->
<!--              <el-input v-model="form.photoAddr" placeholder="请输入拍照人脸url"/>-->
<!--            </el-form-item>-->
<!--          </el-col>-->
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm" v-permission="['/attendance/attClockTime/add', '/attendance/attClockTime/edit']">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
    <el-dialog title="计算考勤统计时间范围" :visible.sync="openStatistic" append-to-body>
      <el-form ref="formStatistic" :model="formStatistic" :rules="rulesStatistic" label-width="80px">
        <el-row :gutter="35">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
            <el-form-item label="开始时间" prop="startDate">
              <el-date-picker clearable size="small"
                              v-model="formStatistic.startDate"
                              type="date"
                              value-format="yyyy-MM-dd"
                              placeholder="选择考勤计算开始时间">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" class="mb20">
            <el-form-item label="结束时间" prop="endDate">
              <el-date-picker clearable size="small"
                              v-model="formStatistic.endDate"
                              type="date"
                              value-format="yyyy-MM-dd"
                              placeholder="选择考勤计算结束时间">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitStatistic" v-permission="['/attendance/attClockTime/add']">确 定</el-button>
        <el-button @click="cancelStatistic">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  listAttClockTime,
  getAttClockTime,
  delAttClockTime,
  addAttClockTime,
  updateAttClockTime,
  importAttClockTime,
  exportAttClockTime } from "@/api/attendance/attClockTime";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import RightToolbar from "@/components/RightToolbar"
import permission from '@/directive/permission/index.js' // 权限判断指令
import Pagination from "@/components/Pagination";
import {checkRole} from "@/utils/role";
import {getTreeselectDepartment} from "../../../../api/org/department";
import {fileDownload} from "@/utils/fileUtils";
import {download} from "@/api/comapi/comapi.js";
import { showLoading, hideLoading } from '@/utils/reqloading';
import {listEmploy} from "@/api/employ/employ";
import {updateStatisticClockTime} from "@/api/attendance/attStatisticDay";
import {Local} from '@/utils/storage.js'

export default {
  name: "AttClockTime",
  components: {
    Treeselect,
    RightToolbar,
    Pagination,
  },
  directives: { permission },
  data() {
    return {
      fileList:[],
      employs:[],
      searchLoading: false,
      // 遮罩层
      loading: true,
      // 查询参数
      where: {
        query: '',
        filters: []
      },
      templateClockTime:{
        url: '/attendance/clocktime.csv',
        name: '打卡记录模板.csv',
      },
      uniApp:false,

      // 部门管理树选项
      departmentList: [],
      departmentOptions: [],

      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 打卡记录表格数据
      attClockTimeList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: false,
      openStatistic: false,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        companyId: null,
        depId: null,
        empName: null,
        type: null,
        clockTimeStart: null,
        clockTimeEnd: null,
        addr: null,
        wifi: null,
        machine: null,
        photo: null,
        photoAddr: null,
      },
      // 表单参数
      form: {} ,
      formStatistic: {},
      rulesStatistic: {
        startDate: [
          {
            required: true,
            message: "开始时间不能为空", trigger: "blur" }
        ],
        endDate: [
          {
            required: true,
            message: "结束时间不能为空", trigger: "blur" }
        ],

      },
      // 表单校验
      rules: {
        clockTime: [
          {
            required: true,
            message: "打卡时间不能为空", trigger: "blur" }
        ],
        empName: [
          {
            required: true,
            message: "姓名不能为空", trigger: "blur" }
        ],
      }
    }
        ;
  },
  created() {
    this.queryParams.companyId = this.$store.getters.userInfo.companyId;
    getTreeselectDepartment(null,false, (list,options)=>{
      this.departmentList = list;
      this.departmentOptions = options;
    })
    this._filter = {};
    this.getList();
    this.uniApp=Local.get("isUniapp")?true:false;
  },
  methods: {
    calAttStatic(){
      this.openStatistic = true;
      // updateStatisticClockTime();
    },
    submitStatistic(){
      this.$refs["formStatistic"].validate(valid => {
        if (valid) {
          updateStatisticClockTime(this.formStatistic.startDate, this.formStatistic.endDate)
              .then(response => {
                this.openStatistic = false;
                this.msgSuccess("统计计算完成");
              });
        }
      });
    },
    cancelStatistic(){
      this.openStatistic = false;
    },
    /** 查询打卡记录列表 */
    getQuery(){
      this._filter = {};
      // 人事和经理可以查看所有人申请，员工只能看自己申请
      const role = ["Admin","GM","HRManager","CAdmin","Attence"];
      if(!checkRole(role)){
        this._filter['userId'] = {
          type: 'eq',
          value: this.$store.getters.userInfo.id,
        }
      }
      // if(this.queryParams.companyId) {
      //   this._filter['companyId'] = {
      //     type: 'eq',
      //     value: this.queryParams.companyId,
      //   }
      // }
      if(this.queryParams.empName) {
        this._filter['empName'] = {
          type: 'search',
          value: this.queryParams.empName,
        }
      }
      if(this.queryParams.type) {
        this._filter['type'] = {
          type: 'eq',
          value: this.queryParams.type,
        }
      }
      if(this.queryParams.clockTimeStart && this.queryParams.clockTimeEnd) {
        this._filter['clockTime'] = {
          type: 'timestamp',
          value: [this.queryParams.clockTimeStart,this.queryParams.clockTimeEnd],
        }
      }else if(this.queryParams.clockTimeStart) {
        this._filter['clockTime'] = {
          type: 'ge',
          value: this.queryParams.clockTimeStart,
        }
      }else if(this.queryParams.clockTimeEnd) {
        this._filter['clockTime'] = {
          type: 'le',
          value: this.queryParams.clockTimeEnd,
        }
      }
      if(this.queryParams.depId) {
        this._filter['depId'] = {
          type: 'in',
          value: this.queryParams.depId,
        }
      }
      if(this.queryParams.addr) {
        this._filter['addr'] = {
          type: 'search',
          value: this.queryParams.addr,
        }
      }
      if(this.queryParams.wifi) {
        this._filter['wifi'] = {
          type: 'search',
          value: this.queryParams.wifi,
        }
      }
      if(this.queryParams.machine) {
        this._filter['machine'] = {
          type: 'search',
          value: this.queryParams.machine,
        }
      }
      if(this.queryParams.photo) {
        this._filter['photo'] = {
          type: 'search',
          value: this.queryParams.photo,
        }
      }
      if(this.queryParams.photoAddr) {
        this._filter['photoAddr'] = {
          type: 'search',
          value: this.queryParams.photoAddr,
        }
      }
      const filters = this.parseFilters(this._filter)
      if (filters && filters.length > 0) {
        this.where.filters = filters
      } else {
        // 如果过滤条件为空，设置空数组
        this.where.filters = []
      }
      const params = {
        pageNum: this.queryParams.pageNum,
        pageSize: this.queryParams.pageSize,
        where: this.where,
        order: ' id desc '
      }

      return params;
    },
    getList() {
      this.loading = true;
      const params = this.getQuery();
      listAttClockTime(params).then(response => {
        this.attClockTimeList = response.data.records;
        this.total = response.data.total;
        this.loading = false;
      });
    },
    normalizerDepartment(node) {
      return this.normalizer(node);
    },
    getDepartmentName(id){
      //console.log(id);
      for(let i = 0; i < this.departmentList.length; i++){
        if(id == this.departmentList[i].id) return this.departmentList[i].name;
      }
      return "";
    },
    searchUsers(query){
      if (query !== '') {
        this.searchLoading = true;
        let filter = {};
        if(this.queryParams.companyId) {
          filter['companyId'] = {
            type: 'eq',
            value: this.queryParams.companyId,
          }
        }
        filter['name'] = {
          type: 'search',
          value: query,
        }
        const params = this.getQueryParam(filter, 1, 20, '');
        listEmploy(params).then(response=>{
          console.log(response.data.records);
          this.employs = response.data.records;
          this.searchLoading = false;
        });
      } else {
        this.employs = [];
      }
    },
    // handleRemoveUser(tag){
    //   this.selfFlow.userNames.splice(this.selfFlow.userNames.indexOf(tag), 1);
    // },
    selectEmploy(val){
      console.log(val)
      let employ = null;
      for(let i = 0; i < this.employs.length; i++){
        if(val == this.employs[i].id){
          employ = this.employs[i];
          break;
        }
      }
      if(null != employ){
        this.form.empId = employ.id;
        this.form.empName = employ.name;
        this.form.userId = employ.userId;
        this.form.companyId = employ.companyId;
        this.form.companyCode = employ.companyCode;
        this.form.depId = employ.departmentId;
      }
      // console.log(instanceId)
    },

    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {};
      this.resetForm("form");
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParams.pageNum = 1;
      this.getList();
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.resetForm("queryForm");
      this.handleQuery();
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map(item => item.id)
      this.single = selection.length !== 1
      this.multiple = !selection.length
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.form.type = '数据导入';
      this.form.companyId = this.queryParams.companyId;
      this.open = true;
      this.title = "添加打卡记录";
    },

    submitUpload() {
      this.$refs.upload.submit();
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },

    async uploadFile(params) {
        const _file = params.file;
        const formData = new FormData();
        formData.append("companyId",this.queryParams.companyId);
        formData.append('file', _file);
        showLoading();
        importAttClockTime(formData).then(response=>{
          console.log(response.code);
          this.fileList = [];
          this.getList();
          hideLoading();
        }).reject( ()=>{
          this.fileList = [];
          hideLoading();
        });
        //
    },

    /** 修改按钮操作 */
    handleUpdate(row) {
      this.reset();
      const id = row.id || this.ids
      getAttClockTime(id).then(response => {
        this.form = response.data;
        this.open = true;
        this.title = "修改打卡记录";
      });
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate(valid => {
            if(valid) {
              console.log(this.form);
              if (this.form.id != null) {
                updateAttClockTime(this.form).then(response => {
                  this.msgSuccess("修改成功");
                  this.open = false;
                  this.getList();
                });
              } else {
                addAttClockTime(this.form).then(response => {
                  this.msgSuccess("新增成功");
                  this.open = false;
                  this.getList();
                });
              }
            }
          }
      );
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      let ids = [];
      if(row.id){
        ids = [row.id];
      }else{
        ids = this.ids;
      }

      this.$confirm('是否确认删除选中的打卡记录数据?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        return delAttClockTime(ids);
      }).then(() => {
        this.getList();
        this.msgSuccess("删除成功");
      })
    },
    /** 导出按钮操作 */
    // handleExport() {
    //   const params = this.getQuery();
    //   this.$confirm('是否确认导出所有打卡记录数据项?', "警告", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning"
    //   }).then(function () {
    //     return exportAttClockTime(params);
    //   }).then(response => {
    //     this.download(response.msg);
    //   })
    // }
    downloadModel(){
      var a = document.createElement("a"); //创建一个<a></a>标签
      a.href = "/static/clocktimeTemplate.csv"; // 给a标签的href属性值加上地址，注意，这里是绝对路径，不用加 点.
      a.download = "打卡记录模板.csv"; //设置下载文件文件名，这里加上.xlsx指定文件类型，pdf文件就指定.fpd即可
      a.style.display = "none"; // 障眼法藏起来a标签
      document.body.appendChild(a); // 将a标签追加到文档对象中
      a.click(); // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
      a.remove(); // 一次性的，用完就删除a标签
      //fileDownload(this.templateClockTime,download);
    },
  }
};
</script>

<style scoped>

</style>
