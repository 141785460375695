import request from '@/utils/request'

export function findModels(query) {
    return request({
        url: '/bpm/model/findModels',
        method: 'get',
        params: query
    })
}

export function getModel(id) {
    return request({
        url: '/bpm/model/findOne?id=' + id,
        method: 'get'
    })
}

export function updateModel(data) {
    return request({
        url: '/bpm/model/update',
        method: 'PUT',
        data: data,
        // headers:{
        //   'Content-Type':'text/xml;charset=utf-8',
        // }
    })
}

export function updateModelState(id, state) {
    return request({
        url: '/bpm/model/updateState',
        method: 'put',
        data: {
            id,
            state
        }
    })
}

export function createModel(data) {
    return request({
        url: '/bpm/model/create',
        method: 'POST',
        data: data
    })
}

export function createSelfModel(data) {
    return request({
        url: '/bpm/model/createSelfModel',
        method: 'POST',
        data: data
    })
}

export function deleteModel(id) {
    return request({
        url: '/bpm/model/delete?id=' + id,
        method: 'DELETE'
    })
}

export function deployModel(id) {
    return request({
        url: '/bpm/model/deploy?id=' + id,
        method: 'POST'
    })
}
