<template>
  <div class="tableContent">
		<div id="printMe" ref="printMe">
			<div class="title">身 份 证 </div>
			<div>
				<table class="table">
				
						<tr >
						<!-- 6 col -->
						<td  class="imgPostion" style="border-bottom-style: none">
							<pdf style="display:inline-block" v-if="idCardFrontFileName!=null&&idCardFrontFileName.endsWith('.pdf')" :src="this.idCardFrontUrl" :page="1" class="imgSize"></pdf>
							<img v-else :src="this.idCardFrontUrl" class="imgSize"   />
						
						</td>
						
					</tr>
						<tr>
						<!-- 6 col -->
						<td  class="imgPostion" style="border-top-style: none">
							<pdf style="display:inline-block"  v-if="idCardBackFileName!=null&&idCardBackFileName.endsWith('.pdf')" :src="this.idCardBackUrl" :page="1" class="imgSize"></pdf>
							<img v-else :src="this.idCardBackUrl" class="imgSize" />
						</td>
						
					</tr>

				</table>
			</div>

		</div>
			<div style="text-align:center;" >
				<!-- <el-button type="success" plain icon="el-icon-printer" size="mini" v-print="printObj">打印</el-button> -->
				<el-button type="success" plain icon="el-icon-printer" size="mini" @click="printHandle">打印</el-button>
        		<el-button style="margin-top: 10px" type="primary" size="mini"  plain @click="cancel">关 闭</el-button>
			</div>
	</div>
</template>

<script>
import html2canvas from 'html2canvas'
import printJS from 'print-js'
import Pdf from 'vue-pdf';
export default {
  name: "IdCardPrinting",
  components: {
		Pdf,
	},
  props: {
    idCardFrontUrl: String,
	idCardFrontFileName:String,
    idCardBackUrl: String,
	idCardBackFileName:String
  },

  data(){
	
	//   let frontUrl = this.idCardFrontUrl;
	//   let frontFileName = this.idCardFrontFileName;
	//   let backUrl = this.idCardBackUrl;
	//   let backFileName = ths.idCardBackFileName;
    return {
      // 表单参数
 
      printObj: {
				id: 'printMe',
				popTitle: ' ',
				extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>',
			},
    };
  },
  created() {
   
  },
  methods: {
  
	   cancel() {
			this.$emit('close', { refresh: false, isSuccess: true });
		},
		printHandle() {
			html2canvas(this.$refs.printMe, {
					backgroundColor: null,
					useCORS: true,
					windowHeight: document.body.scrollHeight
			}).then((canvas) => {
					const url = canvas.toDataURL()
					this.img = url
					printJS({
						printable: url,
						type: 'image',
						documentTitle: ''
					})
			})
		},

    },

}

</script>

<style scoped>
.table {
	width: 100%;
	border-style: solid;
	border-collapse: collapse;
	overflow-y: scroll;
	border: 1;

}
.tableContent {
	width: 900px;
	margin: auto;
	margin-top: 20px;
}

.firstCol{
	width: 2%;
}
.secondCol{
	width:15%;
}
.thirdCol{
	width:25%;
}
.fourCol{
	width:10%;
}
.fiveCol{
	width:25%;
}
.sexCol{
	width:23%;
}

.tableList table,
table tr th,
table tr td {
	border: 1px solid rgb(12, 12, 12);
  	padding: 15px;
	
}

.title {
	font-size: 30px;
	width: 100%;
	text-align: center;
	margin-bottom: 10px;
}
.imgSize{
	width: 50%;
	height: 60%;
}
.imgPostion{
	text-align: center;;
}
</style>
