import request from '@/utils/request'

export function login(data) {
  console.log('login data');
  console.log(data);
  return request({
    url: '/login',
    method: 'post',
    params: {loginType:data.loginType, username: data.username, password: data.password, code: data.code }
  })
}

export function getInfo(token) {
  return request({
    url: '/user/userInfo', // '/vue-element-admin/user/info',
    method: 'post',
    params: { token }
  })
}

export function logout() {
  return request({
    url: '/vue-element-admin/user/logout',
    method: 'post'
  })
}

export function userFindAll(params) {
  return request({
    url: '/user/findAll',
    method: 'post',
    data: params
  })
}
